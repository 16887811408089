import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'domain-delservice-cancellation-modal',
    templateUrl: './domain_delservice_cancellation_modal.html'
})

export class DomainDelserviceCancellationModalComponent {
    @Input() isShow: boolean = false;

    @Output() onClickCloseButtonEvent = new EventEmitter();
    @Output() onClickNextButtonEvent = new EventEmitter();
    @Output() onClickContinueButtonEvent = new EventEmitter();

    public onClickCloseButton(): void {
        this.onClickCloseButtonEvent.emit();
    }

    public onClickNextButton(): void {
        this.onClickNextButtonEvent.emit();
    }

    public onClickContinueButton(): void {
        this.onClickContinueButtonEvent.emit();
    }
}