<div class="modal" modal [openFlg]="isShow" data-dismiss="modal" (click)="onClickOutsideCloseModal($event)">
    <div class="modal-Dialog is-Wide">
        <div class="modal-Dialog-Header text-Center">
            <h3 class="modal-Dialog-Header-Title is-Cancellation">
                <span class="modal-Dialog-Header-Title-Text">ご確認</span>
            </h3>
        </div><!-- /.modal-Dialog-Header -->
        <div class="modal-Dialog-Body pb60 pt60 pl100 pr100">
            <ng-container *ngIf="!isFreeDomainForever">
                <p class="mt0 text-Center size-15 mb20">ドメインを他社サービスへ移管された場合<br><span class="text-Bold">【脆弱性診断サービス】お名前.com ネットde診断</span>を<br>今後使用できなくなりますがよろしいでしょうか？</p>
                <div class="box-Border mb0">
                    <div class="box-Border-Inner pt14 pb14">
                        <p class="text-Bold text-Center">「お名前.com ネットde診断」のメリット</p>
                        <ul class="box-Flex list-Icon is-justifyContentCenter mb0">
                            <li class="mb0 mr20"><i class="icon icon-Checked mt2"></i>無料で何度でも使い放題</li>
                            <li class="mb0 mr20"><i class="icon icon-Checked mt2"></i>ワンクリックで診断開始</li>
                            <li><i class="icon icon-Checked mt2"></i>最短10分で完了</li>
                        </ul>
                    </div><!-- .box-Border-Inner -->
                </div><!-- /.box-Border -->
            </ng-container>
            <ng-container *ngIf="isFreeDomainForever">
                <p class="mt0 text-Center size-15 mb20">ドメインを他社サービスへ移管された場合</p>
                <p class="mt0 text-Center size-15 mb20">
                    <span class="text-Bold">ドメイン更新料金の永久無料</span>を
                </p>
                <p class="mt0 text-Center size-15">今後ご利用できなくなりますがよろしいでしょうか？</p>
            </ng-container>
        </div><!-- /.modal-Dialog-Body -->
        <div class="modal-Dialog-Footer">
            <ul class="list-Btn is-Right mb0">
                <li><button type="button" class="btn gtmclick" [attr.data-gtmvalue]="isFreeDomainForever ? 'transferout_2_fail_freef' : 'transferout_2_fail'" data-dismiss="modal" (click)="onClickClose()">戻る</button></li>
                <li><button type="button" class="btn is-Primary gtmclick" [attr.data-gtmvalue]="isFreeDomainForever ? 'transferout_2_success_freef' : 'transferout_2_success'" data-dismiss="modal" (click)="onClickShowAuthcode()">Authcodeを取得する</button></li>
            </ul><!-- /.list-Btn -->
        </div><!-- /.modal-Dialog-Footer -->
        <button type="button" class="modal-Close gtmclick" [attr.data-gtmvalue]="isFreeDomainForever ? 'transferout_2_close_freef' : 'transferout_2_close'" data-dismiss="modal" (click)="onClickClose()"><i class="icon icon-Clear gtmclick" [attr.data-gtmvalue]="isFreeDomainForever ? 'transferout_2_close_freef' : 'transferout_2_close'" aria-label="閉じる"></i></button>
    </div><!-- /.modal-Dialog -->
</div>
