import { createAction, props } from '@ngrx/store';

export const setShowPopUpM365Status = createAction(
    '[PopUp] Set Show PopUp M365 Status',
    props<{ value: boolean }>()
);

export const resetShowPopUpState = createAction(
    '[PopUp] Set Show PopUp State'
);
