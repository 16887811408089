import { Directive, ElementRef, Output, Input, EventEmitter, HostListener, OnDestroy } from '@angular/core';

@Directive({
    selector: '[dropdownFocusout]'
})
export class DropdownFocusoutDirective {
    private _el: HTMLElement;

    private parentElement: HTMLElement;

    @Input('parent')
    public parent: string;

    constructor(private _elementRef: ElementRef) {
        this._el = _elementRef.nativeElement;
    }
    @HostListener('mouseenter', ['$event'])
    public onMouseEnter(event: Event) {
        this.parentElement = document.querySelector(this.parent) as HTMLElement;
        this.parentElement.classList.add('is-Active');
        event.stopImmediatePropagation();
        event.stopPropagation();

        return false;
    }

    @HostListener('mouseleave', ['$event'])
    public onBlur(event: Event) {

        this._el.classList.remove('is-Show');
        if (!this.parentElement.classList.contains('.is-Current')) {
            this.parentElement.classList.remove('is-Active');
            this.parentElement.classList.remove('is-Open');
        }
        this._el.style.left = '';
        this._el.style.top = '';

        event.stopImmediatePropagation();
        event.stopPropagation();

        return false;
    }
}