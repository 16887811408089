import { Directive, ElementRef, Input, HostListener } from '@angular/core';

@Directive({
    selector: '[popup],[onamaePopup]'
})
export class PopupDirective {
    private _el: HTMLElement;

    private popupElement: HTMLElement;

    private arrowElement: HTMLElement;

    constructor(private _elementRef: ElementRef) {
        this._el = _elementRef.nativeElement;
    }

    @Input('placement')
    public placement: string;

    @Input('content')
    public content: string;

    @Input('option')
    public option: string;

    @Input('animation')
    public animation: string;

    @HostListener('mouseenter', ['$event'])
    @HostListener('focusin', ['$event'])
    public onMouseOver(event: Event) {
        if (this.popupElement) {
            return;
        }

        var positionClass = '';
        switch (this.placement) {
            case 'top':
                positionClass = 'is-Top';
                break;
            case 'left':
                positionClass = 'is-Left';
                break;
            case 'right':
                positionClass = 'is-Right';
                break;
            default:
                positionClass = 'is-Bottom';
                break;
        }

        var nodeElement = document.createElement('div');
        nodeElement.classList.add('popup');
        nodeElement.classList.add('is-Show');
        nodeElement.classList.add(positionClass);

        if (!!this.option) {
            nodeElement.classList.add(this.option);
        }

        if (!!this.animation) {
            if (this.animation == 'delayIn') {
                nodeElement.classList.add('is-DelayIn');
            }
        }

        var popupElementString: string = '<div class="popup-Arrow"></div><div class="popup-Content">' + this.content + '</div>';
        nodeElement.innerHTML = popupElementString;
        this.popupElement = document.body.appendChild(nodeElement) as HTMLElement;

        var thisRect = this._el.getBoundingClientRect();
        var targetRect = this.popupElement.getBoundingClientRect();

        var setLeft: number = 0;
        var setTop: number = 0;
        switch (this.placement) {
            case 'top':
                setLeft = thisRect.left - ((targetRect.width - thisRect.width) / 2);
                setTop = thisRect.top - targetRect.height + window.pageYOffset;

                break;
            case 'left':
                setLeft = thisRect.left - targetRect.width;
                setTop = thisRect.top + ((thisRect.height - targetRect.height) / 2) + window.pageYOffset;

                break;
            case 'right':
                setLeft = thisRect.left + thisRect.width;
                setTop = thisRect.top + ((thisRect.height - targetRect.height) / 2) + window.pageYOffset;
                break;
            default:
                setLeft = thisRect.left - ((targetRect.width - thisRect.width) / 2);
                setTop = thisRect.top + thisRect.height + window.pageYOffset;

                break;
        }

        this.popupElement.style.left = setLeft + 'px';
        this.popupElement.style.top = setTop + 'px';
    }

    @HostListener('click', ['$event'])
    @HostListener('mouseleave', ['$event'])
    @HostListener('focusout', ['$event'])
    public MouseOut(event: Event) {
        if (this.popupElement) {
            document.body.removeChild(this.popupElement);
            delete this.popupElement;
        }
    }
}