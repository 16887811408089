import {
    ActionReducer,
    ActionReducerMap,
    MetaReducer,
    createFeatureSelector,
    createSelector
} from '@ngrx/store';
import { environment } from '../../../../script/environments/environment';
import * as layoutReducer from './layout';
import * as layoutState from '../states/layout';

export const featureKey = 'sp';

export interface State {
    [layoutReducer.featureKey]: layoutState.LayoutState
}

export const reducers: ActionReducerMap<State> = {
    [layoutReducer.featureKey]: layoutReducer.reducer
};

const setState = createFeatureSelector<State>(featureKey);

export const getLayout = createSelector(
    setState,
    state => state[layoutReducer.featureKey]
);

export const metaReducers: MetaReducer<State>[] = !environment.production ? [] : [];
