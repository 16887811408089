<form #gmoPointForm="ngForm" novalidate>
    <div class="modal-Dialog">
        <div class="modal-Dialog-Header">
            <h3 class="modal-Dialog-Header-Title">GMOポイント設定</h3>
        </div>
        <div class="modal-Dialog-Body">
            <p>現在のポイント　{{viewModel.currentPointwithComma}}pt</p>
            <table class="tbl">
                <colgroup>
                    <col width="50">
                    <col width="200">
                    <col width="">
                </colgroup>
                <tbody>
                    <tr>
                        <th>
                            <label>
                                <input type="radio" class="gtmclick" data-gtmvalue="use_gmopoint_all" name="ChangePointRadio" [checked]="viewModel.useGmoPointType === viewModel.UseGmoPointType.All" (change)="viewModel.onChangeUseGmoPointType(viewModel.UseGmoPointType.All)">
                                <span class="form-Radio" tabindex="0"></span>
                            </label>
                        </th>
                        <td>すべてのポイントを使う</td>
                        <td></td>
                    </tr>
                    <tr>
                        <th>
                            <label>
                                <input type="radio" class="gtmclick" data-gtmvalue="use_gmopoint_part" name="ChangePointRadio" [checked]="viewModel.useGmoPointType === viewModel.UseGmoPointType.Part" (change)="viewModel.onChangeUseGmoPointType(viewModel.UseGmoPointType.Part)">
                                <span class="form-Radio" tabindex="0"></span>
                            </label>
                        </th>
                        <td>一部のポイントを使う</td>
                        <td>
                            <ng-container *ngIf="viewModel.useGmoPointType === viewModel.UseGmoPointType.Part">
                                <input type="text" class="form-Text is-Small gtmclick" data-gtmvalue="input_gmopoint" [class.form-Error]="(v_useGmoPoint.errors && (v_useGmoPoint.dirty || v_useGmoPoint.touched)) || viewModel.isValidOverCurrentPoint || viewModel.isValidOverPaymentPrice || viewModel.isValidOverOneTimeUsePointLimit"
                                       name="useGmoPoint" #v_useGmoPoint="ngModel" [(ngModel)]="viewModel.useGmoPoint"
                                       (ngModelChange)="viewModel.onChangeUsePoint($event)"
                                       required pattern="{{viewModel.rNumberPattern}}" />pt
                                <div *ngIf="(v_useGmoPoint.errors && (v_useGmoPoint.dirty || v_useGmoPoint.touched)) || viewModel.isValidOverCurrentPoint || viewModel.isValidOverPaymentPrice || viewModel.isValidOverOneTimeUsePointLimit">
                                    <label *ngIf="v_useGmoPoint.errors != null && v_useGmoPoint.errors.required" class="form-Error">
                                        必須項目です
                                    </label>
                                    <label *ngIf="v_useGmoPoint.errors != null && v_useGmoPoint.errors.pattern" class="form-Error">
                                        入力形式を確認してください
                                    </label>
                                    <label *ngIf="viewModel.isValidOverCurrentPoint" class="form-Error">
                                        設定されたポイントは現在の所持ポイントを超えています。
                                    </label>
                                    <label *ngIf="viewModel.isValidOverPaymentPrice" class="form-Error">
                                        設定されたポイントは請求予定料金を超えています。
                                    </label>
                                    <label *ngIf="viewModel.isValidOverOneTimeUsePointLimit" class="form-Error">
                                        1回のご請求で利用可能なポイントの上限は{{viewModel.oneTimeUsePointLimitwithComma}}ptまでとなります。
                                    </label>
                                </div>
                            </ng-container>
                        </td>
                    </tr>
                    <tr>
                        <th>
                            <label>
                                <input type="radio" class="gtmclick" data-gtmvalue="use_gmopoint_none" name="ChangePointRadio" [checked]="viewModel.useGmoPointType === viewModel.UseGmoPointType.None" (change)="viewModel.onChangeUseGmoPointType(viewModel.UseGmoPointType.None)">
                                <span class="form-Radio" tabindex="0"></span>
                            </label>
                        </th>
                        <td>ポイントを使わない</td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
            <section class="section">
                <h2 class="hdn-Lev3">ご注意事項</h2>
                <div class="box-Border is-Thick">
                    <div class="box-Border-Inner">
                        <ul class="list-Normal mb0">
                            <li>1回のご請求に利用可能なポイント数の上限は{{viewModel.oneTimeUsePointLimitwithComma}}ptまでとなります。</li>
                            <li>ご請求時点で、GMOポイントの残高が不足している場合、不足分を加算した料金で請求となります。</li>
                            <li>ご請求時点で、設定した利用予定ポイントにGMOポイントの残高が満たない場合、残りのポイントにて充当します。</li>
                            <li>ご請求時点で、GMOポイントの連携を解除されている場合はポイント利用は無効となります。</li>
                            <li>月額サービスをお申込みの場合、翌月以降の請求については、「GMOポイント利用設定」画面より利用ポイントの設定を行ってください。</li>
                        </ul>
                    </div>
                </div>
            </section>
        </div>
        <div class="modal-Dialog-Footer">
            <ul class="list-Btn is-Right mb0">
                <li><button type="button" class="btn gtmclick" data-gtmvalue="gmopointmodal_no" (click)="viewModel.onClickCancelButton()">キャンセル</button></li>
                <li><button type="button" class="btn is-Primary gtmclick" data-gtmvalue="gmopointmodal_yes" (click)="viewModel.onClickExecuteButton(gmoPointForm)">設定</button></li>
            </ul>
        </div>
        <button type="button" class="modal-Close gtmclick" data-gtmvalue="close_gmopointmodal" (click)="viewModel.onClickCancelButton()">
            <i class="icon icon-Clear" aria-label="閉じる"></i>
        </button>
    </div>
</form>