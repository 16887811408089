<section class="section" *ngIf="viewModel.isUsablePoint">
    <h3 class="hdn-Lev3 mb8">GMOポイント</h3>
    <table class="tbl mb60">
        <colgroup>
            <col width="204">
            <col width="">
        </colgroup>
        <tbody>
            <tr>
                <th class="is-ValignMiddle">GMOポイント</th>
                <td class="text-Right" *ngIf="viewModel.isGmoIdCollaborated">
                    <ng-container *ngIf="viewModel.hasPoint">
                        <ng-container *ngIf="viewModel.usePoint === '0'">
                            使用しない <button type="button" class="btn is-Xsmall ml16 gtmclick" data-gtmvalue="change_usepoint" (click)="viewModel.onChangeButtonClicked()">変更</button>
                        </ng-container>
                        <ng-container *ngIf="viewModel.usePoint !== '0'">
                            -{{viewModel.usePoint}}pt(円) <button type="button" class="btn is-Xsmall ml16 gtmclick" data-gtmvalue="change_usepoint" (click)="viewModel.onChangeButtonClicked()">変更</button>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="viewModel.hasPoint === false">
                        利用できるポイントがありません
                    </ng-container>
                </td>
                <td class="text-Right" *ngIf="viewModel.isGmoIdCollaborated === false">
                    GMOポイントと連携していません
                </td>
            </tr>
        </tbody>
    </table>
</section>

<section class="section">
    <h3 class="hdn-Lev3 mb8">ご請求情報</h3>
    <table class="tbl mb60">
        <colgroup>
            <col width="204">
            <col width="">
        </colgroup>
        <tbody>
            <tr>
                <th class="is-ValignMiddle">今回請求料金（税込）</th>
                <td class="text-Right"><h3>{{viewModel.totalPrice}}円</h3></td>
            </tr>
            <tr *ngIf="viewModel.isExistGrantPoint">
                <th class="is-ValignMiddle">獲得予定のGMOポイント</th>
                <td class="text-Right">{{viewModel.totalGrantPoint}}pt(円)</td>
            </tr>
        </tbody>
    </table>
</section>

<div class="modal" id="gmoPoint" modal [openFlg]="viewModel.isModalOpening">
    <gmo-point-modal></gmo-point-modal>
</div>