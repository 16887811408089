import { Component, AfterViewInit, ComponentRef } from '@angular/core';
import { Router } from '@angular/router';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { IFlexView, AbstractFlexView } from 'Content/script/promotion/common/flexview';

@Component({
    template: 
        `<div *ngIf="isShow" class="box-DomainAppeal is-Center is-Animated is-FixedTop js-box-DomainAppeal">
            <button type="button" class="box-DomainAppeal-Close" (click)="onCancel()"><i class="icon icon-Clear" aria-label="閉じる" (click)="onCancel()"></i></button>
            <span class="box-DomainAppeal-Icon">
                <i class="icon icon-domain"></i>
            </span>
            <div class="box-DomainAppeal-Body">
                <p class="box-DomainAppeal-Heading">利用されていないドメインがあります</p>
                <button type="button" class="btn is-Primary gtmclick" data-gtmvalue="navidomrenewesc_rsapp" (click)="onClick()">{{!abTestAdjustTextButtonValue? "ドメインを利用する": "ドメインを確認する"}}</button>
            </div>
        </div>`,
})

export class PromotionDomainUseOthersComponent implements AfterViewInit {

    isShow: boolean = true;
    isCancelButtonClicked = false;
    isMove: boolean = false;

    constructor(protected _router: Router) { }

    ngAfterViewInit() {
        this.isMove = true;
        var $boxDomainAppeal = $('.js-box-DomainAppeal');
        if($boxDomainAppeal.length === 0) {return;}
        var $header = $('.js-header');
        var $win = $(window);
        var headerY = $header.offset().top;
        var scrollY = 0;
        $win.on('scroll', function(e) {
            e.preventDefault();
            scrollY = $win.scrollTop();
            if (scrollY > headerY) {
                $boxDomainAppeal.css({position: 'fixed'});
            } else {
                $boxDomainAppeal.css({position: 'absolute'});
            }
        });
    }

    onClick() {
        this._router.navigateByUrl('/domain');    
    }

    onCancel() {
        this.isShow = false;
        this.isCancelButtonClicked = true;
    }

    public get abTestAdjustTextButtonValue(): boolean {
        return window.isABNew_AdjustTextButton;
    }
}


export class OthersFlexView extends AbstractFlexView<PromotionDomainUseOthersComponent> {

    protected _componentRef: ComponentRef<PromotionDomainUseOthersComponent> = null;

    public get canAttach(): boolean {
        if (this._componentRef) {
            if (this._componentRef.instance.isCancelButtonClicked) {
                return false;
            }
        }

        const el = window.document.getElementsByTagName('h1');
        if (el.length === 0) {
            return true;
        }
        return el[0].innerHTML !== 'ドメイン契約更新';
    }

    public attachDirective() {
        const el = window.document.getElementsByClassName('header-Bar');
        this._viewContainerRef.clear();
        this._componentRef = this._viewContainerRef.createComponent(this._factory);
        this._renderer.insertBefore(el[0].parentElement, this._componentRef.location.nativeElement, el[0]);
    }
}
