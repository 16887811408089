import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[navigationBtnLeft]'
})
export class NavigationBtnLeft {
    private _el: HTMLTableRowElement;

    constructor(private _elementRef: ElementRef) {
        this._el = _elementRef.nativeElement;
    }

    @HostListener('click', ['$event'])
    public onClick(event: Event) {
        const popup = document.querySelector('.scroll-Hidden-Popup') as HTMLElement;
        if (popup != undefined) {
            popup.parentNode.removeChild(popup);
        }
        const tblWrapDiv = document.querySelector('.tbl-Wrap-Scroll') as HTMLElement;
        let tblWrapScroll_width: number = 0;
        tblWrapDiv.scrollLeft = tblWrapScroll_width
        const navigationBtnRight = document.querySelector('.navigationBtnRight') as HTMLElement;
        const navigationBtnLeft = document.querySelector('.navigationBtnLeft') as HTMLElement;
        if (navigationBtnLeft.classList.contains('is-Active')) {
            navigationBtnLeft.classList.remove('is-Active');
        }
        if (!navigationBtnRight.classList.contains('is-Active')) {
            navigationBtnRight.classList.add('is-Active');
        }
    }

}