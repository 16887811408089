import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'page-size-list',
    template: `
    <div class="nav-Group-Right">
        <dl class="list-DefInline mt2" *ngIf="isDisplay">
            <dt>表示件数</dt>
            <dd>
                <span class="form-Select is-Auto">
                    <select name="pageSizeList" (change)="onChangePageSize($event)">
                        <option *ngFor="let pageSize of pageSizeList" [selected]="currentPageSize === pageSize" value="{{pageSize}}">{{pageSize}}</option>
                    </select>
                </span>
            </dd>
        </dl>
    </div>
    `
})

export class PageSizeListComponent {
    @Input() totalItem: number;
    @Input() pageSizeList: number[] = [5, 10, 20, 50, 100];
    @Input() currentPageSize: number = 5;
    @Input() isDisplay: boolean = true;

    @Output() onChangePageSizeEvent: EventEmitter<number> = new EventEmitter();

    public onChangePageSize(event: any): void {
        this.onChangePageSizeEvent.emit(event.target.value);
    }
}
