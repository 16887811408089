<ng-container *ngIf="viewModel.isShow">
    <div class="modal" id="mfa_modal" style="display: block;" modal [openFlg]="viewModel.isShow">
        <form #voaciCodeForm="ngForm" novalidate>
            <div class="modal-Dialog is-EmailAuthentication">
                <div class="modal-Dialog-Header">
                    <h3 class="modal-Dialog-Header-Title is-Size24 is-Center">{{viewModel.modalTitle}}</h3>
                </div>
                <div class="modal-Dialog-Body pt12 pr20 pl20 pb20">
                    <section class="section">
                        <p>{{viewModel.modalExplanation}}</p>
                        <div class="form-Container is-Center mt10">
                            <p *ngIf="(v_voaciCode.errors && v_voaciCode.dirty) && (v_voaciCode.errors != null && v_voaciCode.errors.required)" class="form-Error mb0">認証コードを入力してください。</p>
                            <p *ngIf="viewModel.isError" class="form-Error mb0">認証コードが誤っています。再度ご入力ください。</p>
                            <p *ngIf="viewModel.isRateLimitExceeded" class="form-Error mb0">現在、このリクエストにお応えできません。<br />しばらくしてからもう一度やり直してください。</p>
                            <p *ngIf="!viewModel.isTokenCreate" class="form-Error mb0">しばらくしてからもう一度やり直してください。</p>
                            <p *ngIf="viewModel.isTokenReissue && viewModel.isTokenCreate" class="form-Reissue mb0"><i class="icon icon-Checked"></i>再発行しました。</p>
                            <input type="text" name="authCodeInput" id="authCodeInput" class="form-Text mt10"
                                   [class.form-Error]="(v_voaciCode.errors && v_voaciCode.dirty)" [(ngModel)]="viewModel.voaciCode"
                                   #v_voaciCode="ngModel" required appAutofocus />
                        </div>
                        <ul class="list-Btn is-Vertical is-Center mt10 mb0">
                            <li><button type="button" [disabled]="viewModel.isProcessing" class="btn is-Primary gtmclick" [attr.data-gtmvalue]="viewModel.gtmValuePreFix + 'voaci_next'" (click)="viewModel.onClickNext(voaciCodeForm)">次へ</button></li>
                            <li><button type="button" [disabled]="viewModel.isProcessing" class="btn is-Small mt20 gtmclick" [attr.data-gtmvalue]="viewModel.gtmValuePreFix + 'voaci_reissue'" (click)="viewModel.onClickTokenCreate(v_voaciCode)">再発行</button></li>
                        </ul>
                    </section>
                </div>
                <button type="button" class="modal-Close" data-dismiss="modal" (click)="viewModel.onClickClose()"><i class="icon icon-Clear" aria-label="閉じる"></i></button>
            </div>
        </form>
    </div>
</ng-container>
