import { ABTestUtil } from 'Content/script/libs/abtest/ab-test.util';
import { HttpClient } from '@angular/common/http';
import {
    CustomizedEventsForABCookieService as ABCookieServiceEvents,
    IABCookie,
    IABParallelValue,
    IABCookiesBaggage,
} from "Content/script/libs/abtest/custom-event.util";

abstract class AbstractAloneAB {
    /**
     * 新旧をbooleanで返す。
     *  true: 新パターン（'1'以上の場合）：true,
     *  false: cookie値が'0'またはnullの場合：false
     **/
    public isNew: boolean;

    /**
     * 非同期処理を解決し、cookieの値(true or false)を返す
     *  true: 新パターン（'1'以上の場合）：true,
     *  false: cookie値が'0'またはnullの場合：false
     **/
    public abstract isNewAsync(): Promise<boolean> | boolean;

    /**
     * cookieの値を返すか、cookieが生成されていない場合はnullを返す。
     **/
    public value: string;

    /**
     * AA用、JSON形式の値{ cookieName: string, abValue: string }
     **/
    public valueAsJSON: IABCookie;

    /**
     * 非同期処理を解決し、cookieの値('0', '1', ...)を返す
     **/
    public abstract valueAsync(): Promise<string> | string;

    /**
     * カスタムイベントを起こしAAにcookie値を送信する
     **/
    public abstract dispatchEvent_sendCookiesBaggage<I>(params: I);

    /**
     * 任意のeventOrderNameでカスタムイベントを送信する
     **/
    public abstract dispatchEvent<I>(eventOrderName: string, params: I);
}


class ABEventOrders {
    public static SEND_COOKIESBAGGAGE = 'sendCookiesBaggage';
    public static SEND_COOKIESBAGGAGE_ON_INIT = 'sendCookiesBaggageOnInit';
}

export type TAloneAB = AbstractAloneAB;
abstract class AloneAB implements TAloneAB {

    protected _name: string = null;
    protected _eventName: string = null;

    public hasGotReplying: boolean = false; //trygetCookieByHttpAynk()を実行したら結果がNGでもtrue, tryget...を呼ぶ前にエラーが起こったらfalse
    public hasError: boolean = false;
    protected _cookieName: string = null;
    protected _value: string = null;
    protected _abCookie: IABCookie = null;
    protected _promise: Promise<IABCookie> = null;

    public constructor(
        name: string,
        protected _tiketId: string,
        protected _http: HttpClient,
    )
    {
        this._name = name + '_test' + this._getSeqNo();
        this._eventName = 'event_AB_' + this._name;
    }

    public init(): Promise<boolean> {

        return this._getPromise()
            .then(x => {
                return Promise.resolve(true);
            })
            .catch(y => {
                return Promise.resolve(false);
            });
    }

    public get isNew(): boolean {
        return (this.hasGotReplying && !this.hasError && (this._value || '0') !== '0');
    }

    public isNewAsync(): Promise<boolean> | boolean {
        if (this.hasGotReplying && !this.hasError) {
            return this.isNew;
        }
        else if (this.hasError) {
            return false;
        }
        else {
            return this._getPromise()
                .then(x => Promise.resolve(x.abValue !== '0'))
                .catch(y => Promise.resolve(false));
        }
    }

    public get value(): string {
            return this._value;
    }

    public get valueAsJSON(): IABCookie {
        return Object.assign({} as IABCookie, this._abCookie);
    }

    public valueAsync(): Promise<string> | string {
        if (this.hasGotReplying && !this.hasError) {
            return this._value;
        }
        else if (this.hasError) {
            return null;
        }
        else {
            return this._getPromise()
                .then(x => Promise.resolve(x.abValue))
                .catch(y => Promise.resolve(null));
        }
    }

    /**
     * cookie値が確定したときのイベントを発火させる。　※AAタグ送信処理は別途Launchに記述すること
     */
    public dispatchEvent_sendCookiesBaggage<I>(params: I) {
        this.dispatchEvent(ABEventOrders.SEND_COOKIESBAGGAGE, params);
    }

    /**
     * paramsにcookie情報を付与し、イベント送信する。
     * @param eventOrderName　…　任意の文字列。同一カスタムイベント内で処理の識別、AAタグ内で処理の分岐等に使う　  ※AAタグ送信処理は別途Launchに記述すること
     * @param params　…　任意のJSONオブジェクト     
     */
    public dispatchEvent<I>(eventOrderName: string, params: I) {
        if (this._value !== null) {
            const baggage = this._getCookiesBaggage(this.valueAsJSON);
            ABCookieServiceEvents.dispatchABCookieServiceEvent<I>(this._eventName, eventOrderName, baggage, params);
        }
    }

    protected _getPromise(): Promise<IABCookie> {

        if (!this._tiketId) {
            this.hasError = true;
            return Promise.reject({ cookieName: null, abValue: null } as IABCookie);
        }

        if (!this._promise) {
            this._promise = ABTestUtil.trygetAbValuByHttpAynk(this._tiketId, this._http)
                .then(x => {
                    this._cookieName = x.cookieName;
                    this._value = x.abValue;
                    this._abCookie = x;
                    this.hasGotReplying = true;
                    this._dispatchEvent_sendCookiesBaggageOnInit();
                    return Promise.resolve(x);
                })
                .catch(y => {
                    this.hasGotReplying = true;
                    this.hasError = true;
                    return Promise.reject({ cookieName: null, abValue: null } as IABCookie);
                });
        }
        return this._promise;
    }

    /**
     * cookie値が確定したときのイベントを発火させる。　※AAタグ送信処理は別途Launchに記述すること
     */
    protected _dispatchEvent_sendCookiesBaggageOnInit() {
        this.dispatchEvent(ABEventOrders.SEND_COOKIESBAGGAGE_ON_INIT, null);
    }

    /**
     * 子クラスで通し番号を返す
     */
    protected abstract _getSeqNo(): string;

    /**
     * Launch送信用のICookiesBaggageオブジェクトを生成する
     */
    protected abstract _getCookiesBaggage(cookie: IABCookie): IABCookiesBaggage;
}

/**
 * 複数ABでtest2以降がないときのダミーテストオブジェクト
 */
class EmptyAloneAB extends AloneAB {

    public constructor() {
        super('DummyObject', 'DummyObject', null);
    }

    public init(): Promise<boolean> {
        return Promise.resolve(false);
    }
    
    public get isNew(): boolean {
        return false;
    }

    public isNewAsync(): Promise<boolean> | boolean {
        return false;
    }

    public get value(): string {
        return null;
    }

    public get valueAsJSON(): IABCookie {
        return { cookieName: null, abValue: null, abDate: null };
    }

    public valueAsync(): Promise<string> | string {
        return null;
    }

    public dispatchEvent_sendCookiesBaggage<I>(params: I) {
        return;
    }

    public dispatchEvent<I>(eventOrderName: string, params: I) {
        return;
    }

    //protectedかつ他のパブリックメソッドから呼ばれていないので未使用
    protected _getPromise(): Promise<IABCookie> {
        return Promise.reject({ cookieName: null, abValue: null } as IABCookie);
    }

    protected _dispatchEvent_sendCookiesBaggageOnInit() {
        return;
    }

    protected _getSeqNo(): string {
        return '-1';
    }

    protected _getCookiesBaggage(cookie: IABCookie): IABCookiesBaggage {
        return {
            parallelValue: null,
            test1: null,
            test2: null,
        }
    }    
}

/**
 * 複数ABの中の1個めのテスト
 */
class AloneAB1 extends AloneAB {
    protected _getSeqNo(): string {
        return '1';
    }

    protected _getCookiesBaggage(cookie: IABCookie): IABCookiesBaggage {
        return {
            parallelValue: null,
            test1: cookie,
            test2: null,
        }
    }
}

/**
 * 複数ABの中の2個めのテスト
 */
class AloneAB2 extends AloneAB {
    protected _getSeqNo(): string {
        return '2';
    }

    protected _getCookiesBaggage(cookie: IABCookie): IABCookiesBaggage {
        return {
            parallelValue: null,
            test1: null,
            test2: cookie,
        }
    }
}

export enum ParallelABPattern {
    LEGACY_LEGACY = '0',
    NEW_LEGACY = '1',
    LEGACY_NEW = '2',
    NEW_NEW = '3',
}

abstract class AbstractParallelAB {

    /**
     * AA用、JSON形式の値
     **/
    public abstract get valueAsJSON(): IABCookiesBaggage;

    /**
     * 複数AB用のcookieを返す
     **/
    public abstract valueAsync(): string | Promise<string>;

    /**
     * Test1またはTest2のクッキーが有効ならtrue、両方無効ならfalseを返す
     */
    public abstract get isValidAny(): boolean;

    /**
     * カスタムイベントを起こしAAにcookie値を送信する
     **/
    public abstract dispatchEvent_sendCookiesBaggage<I>(params: I);

    /**
     * 任意のeventOrderNameでカスタムイベントを送信する
     **/
    public abstract dispatchEvent<I>(eventOrderName: string, params: I);

    /**
     * 非同期処理を解決する
     **/
    public abstract resolveAsyncAll();
}

export type TParallelAB = AbstractParallelAB;
export class ParallelAB extends AbstractParallelAB {

    protected _eventName: string = null;
    private _test1: AloneAB = null;
    private _test2: AloneAB = null;

    protected _isInitExecutedOnce: boolean = false;

    //デバッグ中にしか出ないエラー（出たら実装のどこかがおかしい）
    private _errors = {
        NoneOfConstructorParameter: '{0} … _ticketIdsのパラメータ設定が正しくありません。',
    };

    /**
     * @param _name: テスト名
     * @param _tiketIds: GMOチケットまたはPMSのID、location.pathも可
     * @param _http: http
     */
    public constructor(
        protected _name: string,
        protected _ticketIds: { tiketId1: string, tiketId2?: string },
        protected _http: HttpClient
    )
    {
        super();

        this._eventName = 'event_AB_' + this._name;

        if ((!this._ticketIds.tiketId1 && !this._ticketIds.tiketId2)) {
            throw new Error(this._errors.NoneOfConstructorParameter.replace('{0}', JSON.stringify(this._ticketIds)));
        }
    }

    /**
     * cookie値を生成（または取得）し、Launchへイベントを送信する。
     * ※AAタグ発火は別途、Launchのルールに記述すること
     */
    public async initAndDispatchFirst() {
        if (this._isInitExecutedOnce) {
            return;
        }

        this._test1 = new AloneAB1(this._name, this._ticketIds.tiketId1, this._http);
        await this._test1.init();

        if (this._ticketIds.tiketId2) {
            this._test2 = new AloneAB2(this._name, this._ticketIds.tiketId2, this._http);
            await this._test2.init();
        }
        else {
            this._test2 = new EmptyAloneAB();
            this._test2.init();
        }

        //cookie情報をLaunchに送信　　※AAタグ発火は別途Launchのルールに記述すること
        this._dispatchEvent_sendCookiesBaggageOnInit();

        this._isInitExecutedOnce = true;
    }

    /**
     * cookieの情報が全部入っている
     * IABCookiesBaggage {
     *     parallelValue: {parallelName: this._name, abValue: this.parallelValue }, … 複数ABのAB値
     *     test1: { cookieName: string,abValue: string }, … test1のAB値
     *     test2: { cookieName: string,abValue: string }, … test2のAB値
     * }
     */
    public get valueAsJSON(): IABCookiesBaggage {

        const baggage: IABCookiesBaggage = {
            parallelValue: this._parallelValueAsJSON,
            test1: this._test1.valueAsJSON,
            test2: this._test2.valueAsJSON,
        };

        return baggage;
    } 

    public get isValidAny(): boolean {
        return !(this._test1.value === null && this._test2.value === null);
    }

    /**
     * cookie値が確定したときのイベントを発火させる。　※AAタグ送信処理は別途Launchに記述すること
     */
    protected _dispatchEvent_sendCookiesBaggageOnInit() {
        this.dispatchEvent(ABEventOrders.SEND_COOKIESBAGGAGE_ON_INIT, null);
    }

    /**
     * cookie値が確定したときのイベントを発火させる。　※AAタグ送信処理は別途Launchに記述すること
     */
    public dispatchEvent_sendCookiesBaggage<I>(params: I) {
        this.dispatchEvent(ABEventOrders.SEND_COOKIESBAGGAGE, params);
    }

    /**
     * paramsにcookie情報を付与し、イベント送信する。
     * @param eventOrderName　…　任意の文字列。同一カスタムイベント内で処理の識別、AAタグ内で処理の分岐等に使う　  ※AAタグ送信処理は別途Launchに記述すること
     * @param params　…　任意のJSONオブジェクト     
     */
    public dispatchEvent<I>(eventOrderName: string, params: I) {
        if (this.isValidAny) {
            ABCookieServiceEvents.dispatchABCookieServiceEvent<I>(this._eventName, eventOrderName, this.valueAsJSON, params);
        }
    }

    /**
     * 全部のABテストについて、cookie値の非同期取得を行う
     **/
    public async resolveAsyncAll() {
        await this._test1.valueAsync();
        await this._test2.valueAsync();
    }

    /**
     * 複数ABのとき、テスト項目とABの組み合わせでParallelABPattern列挙体の値またはnullを返す。
     * テストが1個しか指定されていない場合、'0'または'1'しか戻らない。
     *
     * LEGACY_LEGACY '0':　テスト１[従来]　✕　テスト２[従来]
     * NEW_LEGACY    '1':　テスト１[新規]　✕　テスト２[従来]
     * LEGACY_NEW    '2':　テスト１[従来]　✕　テスト２[新規]
     * NEW_NEW       '3':　テスト１[新規]　✕　テスト２[新規]
     * null：（通信中などの理由で）でまだクッキー値が確定していない
     * 
     **/
    protected get _parallelValue(): ParallelABPattern | null {
        if (!this._test1.isNewAsync() && !this._test2.isNewAsync()) {
            return ParallelABPattern.LEGACY_LEGACY; //'0'
        }
        if (this._test1.isNewAsync() && !this._test2.isNewAsync()) {
            return ParallelABPattern.NEW_LEGACY;    //'1'
        }
        if (!this._test1.isNewAsync() && this._test2.isNewAsync()) {
            return ParallelABPattern.LEGACY_NEW;    //'2'
        }
        if (this._test1.isNewAsync() && this._test2.isNewAsync()) {
            return ParallelABPattern.NEW_NEW;       //'3'
        }
    }

    public valueAsync(): string | Promise<string> {
        return this._parallelValue;
    }

    protected get tiketId1Test(): AbstractAloneAB {
        return this._test1;
    }

    protected get tiketId2Test(): AbstractAloneAB {
        return this._test2;
    }

    /**
     * AA用、JSON形式の値{ parallelName: string, abValue: string }
     **/
    protected get _parallelValueAsJSON(): IABParallelValue {
        return {
            parallelName: this._name,
            abValue: this._parallelValue,
        };
    }
}
