import { Directive, Input, forwardRef } from '@angular/core';
import { NG_VALIDATORS, Validator, ValidatorFn, AbstractControl } from '@angular/forms';

/*
 * 指定行数以上入力されたらエラー
 */

@Directive({
    selector: '[maxRowsCount]',
    providers: [{ provide: NG_VALIDATORS, useExisting: forwardRef(() => MaxRowsCountValidator), multi: true }]
})

export class MaxRowsCountValidator implements Validator {
    @Input('maxRowsCount') count: string;

    validate(control: AbstractControl): { [key: string]: any } {
        const validatorFn = domainCountValidator(Number(this.count));
        return validatorFn(control);
    }
}
   
function domainCountValidator(count: number): ValidatorFn {

    return (control: AbstractControl): { [key: string]: any } | null => {
        if (!control.value) {
            return null;
        } else {
            const isOver = count < (control.value as string).replace(/\n|\r\n|\r/, '\n').trim().split('\n').length;
            return isOver ? { 'maxRowsCount': { value: control.value } } : null;
        }
    }
}