import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[tblWrapScrollHorizontal]'
})
export class TblWrapScrollHorizontal {
    private _el: HTMLTableRowElement;

    constructor(private _elementRef: ElementRef) {
        this._el = _elementRef.nativeElement;
    }
    @HostListener('scroll', ['$event'])
    public onScroll(event: Event) {
        const popup = document.querySelector('.scroll-Hidden-Popup') as HTMLElement;
        if (popup != undefined) {
            popup.parentNode.removeChild(popup);
        }
        const navigationBtnRight = document.querySelector('.navigationBtnRight') as HTMLElement;
        const navigationBtnLeft = document.querySelector('.navigationBtnLeft') as HTMLElement;
        let table_width: number = this._el.clientWidth;
        const tblWrapDiv = document.querySelector('.tbl-Wrap-Scroll') as HTMLElement;
        let tblWrapScroll_width: number = tblWrapDiv.scrollWidth;
        let rightSide_width = tblWrapScroll_width - table_width;
        if (this._el.scrollLeft === 0) {
            navigationBtnLeft.classList.remove('is-Active');
        } else if (this._el.scrollLeft == rightSide_width) {
            navigationBtnRight.classList.remove('is-Active');
        } else {
            if (!navigationBtnLeft.classList.contains('is-Active')) {
                navigationBtnLeft.classList.add('is-Active');
            }
            if (!navigationBtnRight.classList.contains('is-Active')) {
                navigationBtnRight.classList.add('is-Active');
            }
        }
    }

}