import { NgModule, ErrorHandler } from '@angular/core';

import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';

import { SpUiModule } from 'Content/sp/script/modules/ui.module';
import { SafePipeModule } from 'Content/script/modules/safe.module';
import { SpLayoutModule } from 'Content/sp/script/modules/layout.module';

// コンポーネント
import { AppSpComponent } from 'Content/sp/script/components/app/app.component';
import { SpRoutingModule } from 'Content/sp/script/routing/sp-routing.module';
import { SpNotifyModule } from 'Content/sp/script/modules/notify.module';

import { SpServiceContainer } from 'Content/sp/script/service/service_container';
import { StoreModule } from '@ngrx/store';
import { featureKey, reducers, metaReducers } from 'Content/sp/script/store/reducers';

@NgModule({
    imports: [
        BrowserModule,
        CommonModule,
        FormsModule,
        HttpClientModule,
        ReactiveFormsModule,
        SpUiModule,
        SpLayoutModule,
        SpNotifyModule,
        SafePipeModule,
        SpRoutingModule,
        StoreModule.forFeature(featureKey, reducers, { metaReducers })
    ],
    declarations: [
        AppSpComponent
    ],
    exports: [
        AppSpComponent
    ],
    providers: [
        SpServiceContainer
    ]
})

export class AppSpModule {
}
