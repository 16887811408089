/**
 * ページトラッキングサービス
 * 
 * 【概要】同一ページで発火したコンバージョン及びイベントを1個のページトラッキングでAAに送るようにする。
 * 　　　　そのために以下の実装を行っている。
 * 
 * 　　1.（基本）ローディングが非表示になったときにページトラッキングを発火（PageTracking）
 * 　　
 * 　　2.（基本から外れる画面）ローディングが無い画面や遅延ローディングが表示される画面のための、ダミーのローディング（AlterLoadingDiv, AlterLoadingNotPagesDiv）
 * 　　
 * 　　3.（モーダル）モーダルなど、本来ページでないコンテンツにページトラッキングを起こす（AlterLoadingNotPagesDiv, TriggerPageTrackingNotPages）
 * 　　
 * 　　4.（ドメイン更新一覧画面＋ワンクリックモーダル用）複数のページを検出する（duplicateDitectionDiv）
 * 　　
 * 　　5.（トラッキングの種類）通常のページとそれ以外のもので、起こすイベントの種類を変える（TriggerPageTracking, TriggerPageTrackingNotPages）
 * 　　
 * */

export class TrackingService {

    public constructor() { }

    /**
     * provideServiceForMainComponent()
     * 
     * メインコンポーネントのページトラッキング用
     * 
     * ※メインコンポーネントはapp.htmlの <div class="main-Content" ... のこと
     * */
    public provideServiceForMainComponent(): PageTrackingWrapper {
        return new PageTrackingWrapper();
    }

    /**
     * provideServiceForModalComponent()
     * 
     * メインコンポーネント内の子画面をページトラッキングするときに使用
     * */
    public provideServiceForModalComponent(): PageTrackingForModalWrapper {
        return new PageTrackingForModalWrapper();
    }
}

/**
 * PageTrackingクラスのラッパー(通常のページ)
 **/
class PageTrackingWrapper {

    public constructor() { }

    public create(): PageTracking {

        //代替ローディング
        const alterLoadingDiv = new AlterLoadingDiv();

        //ページトラッキングイベント（通常のページ用）
        const trigger = new TriggerPageTracking();

        return PageTracking.create(alterLoadingDiv, trigger);
    }

}

/**
 * PageTrackingクラスのラッパー（通常のページ以外）
 **/
class PageTrackingForModalWrapper {

    public constructor() { }

    public create(componentName: string): PageTracking {

        //代替ローディング
        const alterLoadingDiv = new AlterLoadingNotPagesDiv('modal');

        //ページトラッキングイベント（モーダルなどページ以外）
        const trigger = new TriggerPageTrackingNotPages(componentName);

        return PageTracking.create(alterLoadingDiv, trigger);
    }
}

/**
 * PageTrackingクラス
 */
export interface IPageTracking {

    /**
     * 処理の開始。ページトラッキングを送信したら自動的に終了する。
     * */
    start: () => void,

    /**
     * オブジェクトの破棄。ngOnDestroy()やdispose()等に必ず記述すること。
     * */
    dispose: () => void
}

//PageTracking._autoDispatch()の進捗
enum ProgressAutoDispatch {
    CONSTRUCTED,
    HAS_STARTED,
    FIRST_INTERVAL_OCCURED,
    HAS_FINISHED,
}
class PageTracking implements IPageTracking {

    private _timer: NodeJS.Timer = null;

    private _progress: ProgressAutoDispatch;

    private _alterLoadingDiv: IAlterLoadingDiv = null;
    private _dupDetectionDiv: DuplicateDitectionDiv = null;
    private _triggerPageTracking: ITrigger = null;

    private _triggerBeforeFirstInterval: ITrigger = null;
    private _triggerOnDeactivate: ITrigger = null;

    private constructor(alterLoadingDiv: IAlterLoadingDiv, triggerPageTracking: ITrigger) {

        this._alterLoadingDiv = alterLoadingDiv;
        this._triggerPageTracking = triggerPageTracking;

        this._triggerBeforeFirstInterval = new TriggerTrackingBeforeFirstInterval();
        this._triggerOnDeactivate = new TriggerTrackingOnDeactivate();

        this._progress = ProgressAutoDispatch.CONSTRUCTED;
    }

    public static create(alterLoadingDiv: IAlterLoadingDiv, triggerPageTracking: ITrigger): PageTracking {
        return new PageTracking(alterLoadingDiv, triggerPageTracking);
    }

    public start() {
        this._alterLoadingDiv.insert();
        this._autoDispatch(this._alterLoadingDiv, this._triggerPageTracking);
    }

    public dispose() {

        if (this._timer) {
            clearInterval(this._timer);
            this._timer = null;
        }

        //start()が実行されていないとき
        if (this._progress < ProgressAutoDispatch.HAS_STARTED) {
            return;
        }

        //_autoDispatch()内のインターバルがまだ一度も実行されていない
        if (this._progress < ProgressAutoDispatch.FIRST_INTERVAL_OCCURED) {
            this._triggerBeforeFirstInterval.fire();
        }

        //_autoDispatch()が動いたままのとき
        if (this._progress < ProgressAutoDispatch.HAS_FINISHED) {
            this._triggerOnDeactivate.fire();
        }

        this._alterLoadingDiv.remove();
        if (ProgressAutoDispatch.HAS_STARTED <= this._progress) {
            if (this._dupDetectionDiv) {
                this._dupDetectionDiv.remove();
            }
        }
    };

    /**
     * ページトラッキング送信処理（本体）
     * 
     * @param alterLoadingDiv
     * @param triggerPageTracking
     */
    private _autoDispatch(alterLoadingDiv: IAlterLoadingDiv, triggerPageTracking: ITrigger) {

        const INTERVAL = 100;
        const ALTERLOADING_TIMEOUT = 600;   //代替ローディングは0.6秒たったら消す
        const MAX_LOADING_WAIT = 60000;      //60秒ローディングが回り続けたら終了する

        const dupDetectionDiv = new DuplicateDitectionDiv();

        const getLoadingCountFn = TrackingServiceUtil.getLoadingCount;

        const execFirstIntervalFn = () => {
            this._progress = ProgressAutoDispatch.FIRST_INTERVAL_OCCURED;
            this._dupDetectionDiv = dupDetectionDiv;
        };

        const finishFn = () => {
            this._progress = ProgressAutoDispatch.HAS_FINISHED;
            clearInterval(this._timer);
            this._timer = null;
            triggerPageTracking.fire();
            alterLoadingDiv.remove();
            dupDetectionDiv.remove();
        };

        //エラーが出たら無条件で処理を終わらせる
        const errorHandlerFn = (error: any) => {
            console.error('Error occured at tracking service, See below.');
            console.error(error);
            this._progress = ProgressAutoDispatch.HAS_FINISHED;
            clearInterval(this._timer);
            this._timer = null;
        };

        let oldIsLoading: boolean = false;
        let timeout: number = 0;

        this._progress = ProgressAutoDispatch.HAS_STARTED;

        dupDetectionDiv.insert();

        this._timer = setInterval(

            () => {
                try {
                    //初回コールバックが実行されたことをPageTrackingクラスに教える
                    if (timeout === 0) {
                        execFirstIntervalFn();
                    }

                    //ローディングが60秒続いたら無条件で終了
                    if (MAX_LOADING_WAIT <= timeout) {
                        finishFn();
                        return;
                    }
                    timeout += INTERVAL;

                    //一時期モーダルが自動表示されることがあったが、その際モーダルの親画面のページトラッキングと、モーダル画面本体のページトラッキングを発火させるための処理
                    //(2022-04-06現在未使用)
                    if (dupDetectionDiv.hasDetected && dupDetectionDiv.isOldest) {
                        finishFn();
                        return;
                    }

                    //ローディングの数を取得
                    let recentLoadingCount = getLoadingCountFn();

                    //正規のローディングが回っているときは代替ローディングは不要
                    if (0 < recentLoadingCount) {
                        alterLoadingDiv.remove();
                    }

                    //タイムアウト時間を過ぎたら代替ローディングを削除
                    if (ALTERLOADING_TIMEOUT < timeout) {
                        alterLoadingDiv.remove();
                    }

                    //ローディングの数（代替ローディングを含む）を取得
                    recentLoadingCount += alterLoadingDiv.getElementsCount();

                    //ローディング中かどうか
                    const isLoading: boolean = 0 < recentLoadingCount;

                    //ローディングの状態が変化したかチェック
                    if (oldIsLoading !== isLoading) {
                        //ON→OFF …　ローティング中→終了に変化したタイミングでイベントを送信
                        if (!isLoading) {
                            finishFn();
                            return;
                        }
                        oldIsLoading = isLoading;
                    }
                    return;
                }
                catch(e) {
                    errorHandlerFn(e);
                }
            },
            INTERVAL
        );
    }
}

/**
 * 制御用に一時的に追加するDivタグのベースクラス
 * */
class DivElement {

    public element: HTMLDivElement;
    public get id(): string {
        return this._id;
    }

    public constructor(private _id: string, private _classNames: string[], private _attributes: { name: string, value: string }[]) {
        this.element = document.createElement('div');
        this.element.className = this._id + ' ' + this._classNames.join(' ');
        this._attributes.forEach(x => {
            this.element.setAttribute(x.name, x.value);
        });
    }

    public insert() {
        const mainContent = document.querySelector('#mainContent');
        if (mainContent) {
            mainContent.appendChild(this.element);
        }
    }

    public remove() {
        const elements = this.getElements();
        if (0 < elements.length) {
            //elements[0].remove()はIE未対応
            elements[0].parentNode.removeChild(elements[0]);
        }
    }

    public getElements(className?: string): HTMLCollectionOf<Element> {
        const target = className ? className : this._id;
        return document.getElementsByClassName(target);
    }

    public getElementsCount(className?: string): number {
        return this.getElements(className).length;
    }
}

/**
 * 代替ローディングクラスのインターフェース
 * */
interface IAlterLoadingDiv {
    id: string;
    insert(): void;
    remove(): void;
    getElementsCount(): number;
}


/**
 * 代替ローディングクラス（通常のページ）
 * */
class AlterLoadingDiv extends DivElement implements IAlterLoadingDiv {

    public constructor() {
        super(location.pathname.slice(1).replace(/\//g, "_") + '_' + TrackingServiceUtil.CLASSNAME_ALTERLOADING,
            [TrackingServiceUtil.CLASSNAME_ALTERLOADING],
            []
        );
    }
}

/**
 * 代替ローディングクラス（通常のページ以外）
 * */
class AlterLoadingNotPagesDiv extends DivElement implements IAlterLoadingDiv {

    public constructor(distinction: string) {
        super(distinction + '_' + location.pathname.slice(1).replace(/\//g, "_") + '_' + TrackingServiceUtil.CLASSNAME_ALTERLOADING,
            [TrackingServiceUtil.CLASSNAME_ALTERLOADING],
            []
        );
    }
}

/**
 * 処理中のページを検出する。
 * （モーダルがページトラッキング対応かつ自動で表示される場合、親画面とモーダルの２つのページトラッキングを処理しないといけないため）
 * */
class DuplicateDitectionDiv extends DivElement {

    private _pathName: string;
    private _attrName: string;
    private _attrValue: number;

    public constructor() {

        const pathname = location.pathname.slice(1).replace(/\//g, "_");
        const time = (new Date()).getTime();
        const attrName = 'data-detectionid';

        super(
            TrackingServiceUtil.CLASSNAME_DUPDETECTION + '_' + time.toString(),
            [TrackingServiceUtil.CLASSNAME_DUPDETECTION, pathname],
            [{ name: attrName, value: time.toString() }]
        );

        this._pathName = pathname;
        this._attrName = attrName;
        this._attrValue = time;
    }

    public get hasDetected(): boolean {
        const detections = this.getElements(TrackingServiceUtil.CLASSNAME_DUPDETECTION);
        return 1 < detections.length;
    }

    public get isOldest(): boolean {
        const temp = this.getElements(TrackingServiceUtil.CLASSNAME_DUPDETECTION);
        const detections: Element[] = [].slice.call(temp);
        return detections.filter(x => Number(x.getAttribute(this._attrName)) < this._attrValue).length === 0;
    }
}

/**
 * getAccountInfo()メソッドの実行結果を教えてもらう。Account_info_service.tsのみで使用。
 * */
export class ReceiveResultOfAccountInfo {

    public static ok() {
        //カスタムイベントを起こす
        (new FireDocument()).fire(TrackingServiceUtil.APPEVENT_OK_GETACCOUNTINFO);
    }

    public static error() {
        //カスタムイベントを起こす
        (new FireDocument()).fire(TrackingServiceUtil.APPEVENT_ERROR_GETACCOUNTINFO);
    }
}

/**
 * イベント発火クラスのインターフェース
 * */
interface ITrigger {
    fire(): void;
}

/**
 * window.documentでイベントを起こすクラス　※custom-event.util.tsに同様のものがあるが、発火させているのがbodyタグなので使わない
 * */
class FireDocument {

    private _fire(eventName: string, eventInit: any, initCustomEventDetailArg) {

        let event: CustomEvent;
        try {
            event = new CustomEvent(eventName, eventInit);
        } catch (ex) {
            event = window.document.createEvent('CustomEvent');
            event.initCustomEvent(eventName, false, true, initCustomEventDetailArg);
        }
        const dom = window.document;
        try {
            dom.dispatchEvent(event);
        } catch (ex) {
            console.log(eventName + 'のディスパッチに失敗しました');
        }
    }

    fire(eventName: string) {
        this._fire(eventName, { 'bubbles': false, 'cancelable': true }, void 0);
    }

    fireWithDetail(eventName: string, detail: { [key: string]: any }) {
        this._fire(eventName, { 'bubbles': false, 'cancelable': true, detail }, detail);
    }
}

/**
 * ページトラッキングイベントを発火させる（通常のページ）
 * */
class TriggerPageTracking implements ITrigger {
    public fire() {
        (new FireDocument()).fire('event_triggerPageTracking');
    }
}

/**
 * ページトラッキングイベントを発火させる（通常のページ以外）
 * */
class TriggerPageTrackingNotPages implements ITrigger {

    public constructor(private _componentName: string) { }

    public fire() {
        (new FireDocument()).fireWithDetail('event_triggerPageTrackingNotPages', { componentName: this._componentName });
    }
}

/*
 * 最初のインターバルより先にページ遷移が起きたときに発火させる
 * */
class TriggerTrackingBeforeFirstInterval implements ITrigger {
    public fire() {
        (new FireDocument()).fire('event_triggerPageTracking_beforeFirstInterval');
    }
}

/**
 * 通常のページトラッキングが発生しないままページ遷移が起きたときに発火させる
 * */
class TriggerTrackingOnDeactivate implements ITrigger {
    public fire() {
        (new FireDocument()).fire('event_triggerPageTracking_OnDeactivate');
    }
}

/**
 * Trackingクラス用の定数・関数
 **/
class TrackingServiceUtil {

    public static APPEVENT_ERROR_GETACCOUNTINFO = 'app_event_failure_getAccountInfo';
    public static APPEVENT_OK_GETACCOUNTINFO = 'app_event_ok_getAccountInfo';
    public static CLASSNAME_ALTERLOADING = 'alterLoading';
    public static CLASSNAME_DUPDETECTION = 'duplicateDetection';

    public static getLoadingCount = (): number => {

        const mainContent = document.querySelector('#mainContent');
        if (!mainContent) {
            return;
        }

        const recentLoadingCount = mainContent.getElementsByClassName('loading-Block').length
            + mainContent.getElementsByClassName('loading').length;

        return recentLoadingCount;
    }

    public static isLoading = (): boolean => {

        const loadingCount = TrackingServiceUtil.getLoadingCount();
        const alterLoadingCount = document.getElementsByClassName(TrackingServiceUtil.CLASSNAME_ALTERLOADING).length;

        return 0 < (loadingCount + alterLoadingCount);
    }
}
