<ng-container *ngIf="isShow">
    <div class="modal" id="modalDomainRelease" style="display: block;" modal [openFlg]="isShow">
        <div class="modal-Dialog">
            <div class="modal-Dialog-Header is-Note">
                <h3 class="modal-Dialog-Header-Title"><img src="/Content/images/common/icon-caution.svg" class="img-Caution" alt="(!)">解除の注意点とご確認</h3>
            </div><!-- /.modal-Dialog-Header -->
            <div class="modal-Dialog-Body">
                <div class="box-Note">
                    <p class="text-Center"><span class="text-Danger">解除された場合、ドメインを不正利用されるリスクがあります。</span><br>よろしいですか？</p>
                </div>
                <section class="section mt24">
                    <div class="box-Border is-Thick">
                        <div class="box-Border-Inner">
                            <ul class="list-Normal">
                                <li>廃止タイミングによっては、該当ドメイン名を利用された他Webサイトからのリンクや、検索エンジンによる評価に関する情報からアクセス数などが見込めることなどから、そのドメイン名が関係のない第三者に早期再取得が行われ悪用されるリスクに直面する可能性があります。</li>
                                <li>事態が起きる前に事前に回避するためにも、引き続き「ドメインあんしん廃止サービス」の設定を継続されることをお勧めいたします。</li>
                            </ul>
                        </div><!-- .box-Border-Inner -->
                    </div><!-- /.box-Border -->
                </section><!-- /.section -->
            </div><!-- /.modal-Dialog-Body -->
            <div class="modal-Dialog-Footer">
                <ul class="list-Btn is-Right mb0">
                    <li><button type="button" class="btn gtmclick" data-gtmvalue="modal_release" data-dismiss="modal"(click)="onClickNextButton()">解除する</button></li>
                    <li><button type="button" class="btn is-Primary gtmclick" data-gtmvalue="continuation" data-dismiss="modal" data-change="changed" (click)="onClickContinueButton()">契約を継続する</button></li>
                </ul><!-- /.list-Btn -->
            </div><!-- /.modal-Dialog-Footer -->
            <button type="button" class="modal-Close gtmclick" data-gtmvalue="release_close" data-dismiss="modal" (click)="onClickCloseButton()"><i class="icon icon-Clear gtmclick" data-gtmvalue="release_close" aria-label="閉じる"></i></button>
        </div><!-- /.modal-Dialog -->
    </div>
</ng-container>