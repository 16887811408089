import { Injectable } from '@angular/core';
import { NavigationStart, RoutesRecognized, NavigationEnd } from '@angular/router';


@Injectable()
export class RouterEventService {
    public EventNavigationStart: (event: NavigationStart) => void;
    public EventRoutesRecognized: (event: RoutesRecognized) => void;
    public EventNavigationEnd: (event: NavigationEnd) => void;
}
