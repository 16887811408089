﻿<div class="modal" id="domainCopy" modal [openFlg]="viewModel.isShowModal">
    <div class="modal-Dialog">
        <div class="modal-Dialog-Header">
            <h3 class="modal-Dialog-Header-Title">ドメイン担当者情報をコピー</h3>
        </div>
        <div class="modal-Dialog-Body">
            <section class="section">
                <form (ngSubmit)="viewModel.searchDomain(searchDomain)" #searchDomain="ngForm" novalidate>
                    <div class="dropdown-Main pt10 pb10">
                        <table class="tbl-Dotted is-ValignTop">
                            <colgroup>
                                <col width="92">
                                <col width="">
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th class="is-ValignTop"><strong>ドメイン名</strong></th>
                                    <td>
                                        <textarea name="domainNames" class="form-Textarea gtmclick" data-gtmvalue="s_input_domain_names" [class.form-Error]="(domainNames.errors && (domainNames.dirty || domainNames.touched))"
                                                  [(ngModel)]="viewModel.searchCondition.domainNames"
                                                  #domainNames="ngModel" maxByte="255" pattern="{{viewModel.rDomainPattern}}"></textarea>
                                        <label *ngIf="domainNames.errors != null && domainNames.errors.maxByte" class="form-Error">
                                            最大文字数：255
                                        </label>
                                        <label *ngIf="domainNames.errors != null && domainNames.errors.pattern" class="form-Error">
                                            入力形式を確認してください
                                        </label>
                                    </td>
                                </tr>
                                <tr>
                                    <th><strong>グループ名</strong></th>
                                    <td>
                                        <select name="groupName" class="gtmclick" [attr.data-gtmvalue]="'s_change_group_name_' + viewModel.searchCondition.domainGroupId" (change)="viewModel.onChangeSearchDomainGroup($event.target.value)">
                                            <option [selected]="viewModel.searchCondition.domainGroupId === ''" value="">グループ名を選択</option>
                                            <option *ngFor="let groupNameSelectItem of viewModel.searchCondition.groupNameSelectItems" [selected]="viewModel.searchCondition.domainGroupId === groupNameSelectItem.value" value="{{groupNameSelectItem.value}}">{{groupNameSelectItem.text}}</option>
                                        </select>
                                    </td>
                                </tr>
                                <tr>
                                    <th><strong>登録日</strong></th>
                                    <td>
                                        <div pikaday class="form-TextWithIcon">
                                            <input type="text" class="form-Text is-Small gtmclick" data-gtmvalue="s_input_created_date_from" [class.form-Error]="(createdDateFrom.errors && (createdDateFrom.dirty || createdDateFrom.touched))"
                                                   name="createdDateFrom" placeholder="指定なし" [(ngModel)]="viewModel.searchCondition.createdDateFrom" (change)="viewModel.onChangeCreatedDateFrom($event.target.value)"
                                                   #createdDateFrom="ngModel" date>
                                            <button type="button" class="form-TextWithIcon-Btn js-datepicker"><i class="icon icon-Calendar" aria-label="カレンダー"></i></button>
                                        </div> から
                                        <div pikaday class="form-TextWithIcon">
                                            <input type="text" class="form-Text is-Small gtmclick" data-gtmvalue="s_input_created_date_to" [class.form-Error]="(createdDateTo.errors && (createdDateTo.dirty || createdDateTo.touched))"
                                                   name="createdDateTo" placeholder="指定なし" [(ngModel)]="viewModel.searchCondition.createdDateTo" (change)="viewModel.onChangeCreatedDateTo($event.target.value)"
                                                   #createdDateTo="ngModel" date>
                                            <button type="button" class="form-TextWithIcon-Btn js-datepicker"><i class="icon icon-Calendar" aria-label="カレンダー"></i></button>
                                        </div> まで
                                        <label *ngIf="createdDateFrom.errors != null && createdDateFrom.errors.date" class="form-Error">
                                            入力形式を確認してください
                                        </label>
                                        <label *ngIf="createdDateTo.errors != null && createdDateTo.errors.date" class="form-Error">
                                            入力形式を確認してください
                                        </label>
                                    </td>
                                </tr>
                                <tr>
                                    <th><strong>登録期限日</strong></th>
                                    <td>
                                        <div pikaday class="form-TextWithIcon">
                                            <input type="text" class="form-Text is-Small  gtmclick" data-gtmvalue="s_input_expiration_date_from" [class.form-Error]="(expirationDateFrom.errors && (expirationDateFrom.dirty || expirationDateFrom.touched))"
                                                   name="expirationDateFrom" placeholder="指定なし" [(ngModel)]="viewModel.searchCondition.expirationDateFrom" (change)="viewModel.onChangeExpirationDateFrom($event.target.value)"
                                                   #expirationDateFrom="ngModel" date>
                                            <button type="button" class="form-TextWithIcon-Btn js-datepicker"><i class="icon icon-Calendar" aria-label="カレンダー"></i></button>
                                        </div> から
                                        <div pikaday class="form-TextWithIcon">
                                            <input type="text" class="form-Text is-Small gtmclick" data-gtmvalue="s_input_expiration_date_to" [class.form-Error]="(expirationDateTo.errors && (expirationDateTo.dirty || expirationDateTo.touched))"
                                                   name="expirationDateTo" placeholder="指定なし" [(ngModel)]="viewModel.searchCondition.expirationDateTo" (change)="viewModel.onChangeExpirationDateTo($event.target.value)"
                                                   #expirationDateTo="ngModel" date>
                                            <button type="button" class="form-TextWithIcon-Btn js-datepicker"><i class="icon icon-Calendar" aria-label="カレンダー"></i></button>
                                        </div> まで
                                        <label *ngIf="expirationDateFrom.errors != null && expirationDateFrom.errors.date" class="form-Error">
                                            入力形式を確認してください
                                        </label>
                                        <label *ngIf="expirationDateTo.errors != null && expirationDateTo.errors.date" class="form-Error">
                                            入力形式を確認してください
                                        </label>
                                    </td>
                                </tr>
                            </tbody>
                        </table><!-- /.tbl-Dotted -->
                        <ul class="list-Btn is-Right mb8">
                            <li><button tyle="button" class="btn is-Auto is-Small gtmclick" data-gtmvalue="s_clear" (click)="viewModel.clearSearchCondition()">クリア</button></li>
                            <li><button tyle="submit" class="btn is-Primary is-Auto is-Small gtmclick" data-gtmvalue="s_search">上記の条件で検索</button></li>
                        </ul><!-- /.list-Btn -->
                    </div>
                </form>

                <table class="tbl-Striped is-Hoverable">
                    <colgroup>
                        <col width="50">
                        <col width="">
                    </colgroup>
                    <thead>
                        <tr>
                            <th colspan="2">
                                ドメイン名<button type="button" class="btn-Icon ml8 gtmclick" [attr.data-gtmvalue]="'sort_' + viewModel.sortList['domainNameML']" [class.is-DESC]="viewModel.sortList['domainNameML'] === 1" (click)="viewModel.onClickSortDomain('domainNameML')"><i class="icon icon-DropDown"></i></button>
                            </th>
                        </tr>
                    </thead>
                    <tbody *ngIf="viewModel.domainList.length === 0">
                        <tr>
                            <td colspan="2" class="text-Center">対象のドメインはありません</td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="viewModel.domainList.length > 0">
                        <tr *ngFor="let domain of viewModel.domainList; let i = index;">
                            <td class="cell-Radio">
                                <label>
                                    <input type="radio" name="isChecked" [checked]="domain.isChecked === true" (change)="viewModel.checkedDomain(i)">
                                    <span class="form-Radio" tabindex="0"></span>
                                </label>
                            </td>
                            <td>
                                <strong>{{domain.domainNameMl}}</strong>
                            </td>
                        </tr>
                    </tbody>
                </table><!-- /.tbl-Striped -->
                <ul *ngIf="viewModel.pageNoTotal > 1" class="nav-Pagination mt8">
                    <li><a href="javascript:void(0)" class="gtmclick" data-gtmvalue="pager_prev" (click)="viewModel.onPageNoClick(viewModel.pageNo - 1)" [class.is-Disabled]="viewModel.pageNo == 1"><i class="icon icon-ArrowLeft"></i>前</a></li>
                    <li *ngFor="let pageIndex of viewModel.pageIndexs">
                        <a href="javascript:void(0)" class="gtmclick" [attr.data-gtmvalue]="'pager_' + pageNo" (click)="viewModel.onPageNoClick(pageIndex)" [class.is-Current]="pageIndex == viewModel.pageNo">{{pageIndex}}</a>
                    </li>
                    <li><a href="javascript:void(0)" class="gtmclick" data-gtmvalue="pager_next" (click)="viewModel.onPageNoClick(viewModel.pageNo + 1)" [class.is-Disabled]="viewModel.pageNo == viewModel.pageNoTotal">次<i class="icon icon-ArrowRight"></i></a></li>
                </ul>
            </section>
        </div><!-- /.modal-Dialog-Body -->
        <div class="modal-Dialog-Footer">
            <ul class="list-Btn is-Right mb0">
                <li><button tyle="button" class="btn gtmclick" data-gtmvalue="domaincopy_cancel" data-dismiss="modal" (click)="viewModel.onClickCancel()">キャンセル</button></li>
                <li><button tyle="button" class="btn is-Primary gtmclick" data-gtmvalue="domaincopy_ok"  (click)="viewModel.onClickCopyDomainInfo()">OK</button></li>
            </ul><!-- /.list-Btn -->
        </div><!-- /.modal-Dialog-Footer -->
    </div><!-- /.modal-Dialog -->
</div><!-- /.modal -->
