import { Component, Input, Output, OnChanges, EventEmitter, SimpleChanges } from '@angular/core';
import { NormalPaginationComponent } from 'Content/script/components/share/Organisms/pagination/normal_pagination.component';

@Component({
    selector: 'layout-pagination',
    template:
        `<ul *ngIf="totalPages > 1" [class]="'nav-Pagination ' + classNames">
            <li>
                <a href="javascript:void(0)" [class.is-Disabled]="currentPage === 1" (click)="onClickPageNo(currentPage - 1)"><i class="icon icon-ArrowLeft"></i>前</a>
            </li>
            <li *ngFor="let btnPageNo of pages">
                <a href="javascript:void(0)" (click)="onClickPageNo(btnPageNo)" [class.is-Current]="btnPageNo == currentPage">{{btnPageNo}}</a>
            </li>
            <li>
                <a href="javascript:void(0)" [class.is-Disabled]="currentPage === totalPages" (click)="onClickPageNo(currentPage + 1)">次<i class="icon icon-ArrowRight"></i></a>
            </li>
        </ul>`
})

export class LayoutPaginationComponent extends NormalPaginationComponent {

    @Input() classNames: string = '';

    private readonly targetProps = [
        'totalItem',
        'currentPage',
        'pageSize'
    ];

    ngOnChanges(changes: SimpleChanges) {
        for (let prop in changes) {
            if (this.targetProps.some(x => prop === x)) {
                if (!!this.totalItem && !!this.currentPage && !!this.pageSize) {
                    super.getPager();
                }
            }
        }
    }
}

