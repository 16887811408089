<div class="clearfix">
    <h1 class="hdn-Lev1 is-FloatLeft">ドメイン契約更新</h1>
</div>
<div class="box-Message mb24" *ngIf="viewModel.isShowNotice && viewModel.isDisplayCaution && viewModel.exprireDomainsForAbTest.length > 0">
    <div class="box-Message-Heading">
      <img src="{{CacheBusting('/Content/images/common/icon-caution.svg')}}" class="img-Caution gtmclick" alt="(!)">
      <p class="box-Message-Heading-Text">更新期限日まで2週間を切ると、銀行振込/コンビニ支払いではお手続きを承ることができません。<br></p>
      さらに期限を過ぎるとホームページやメールがご利用できなくなりますので、継続利用ボタンよりお早めに更新手続きをお済ませください。
    </div>
</div>
<div class="box-Message mb24" *ngIf="viewModel.isShowNotice && viewModel.isDisplayCaution && viewModel.exprireDomainsForAbTest.length === 0">
    <div class="box-Message-Heading" *ngIf="!viewModel.isShowButtonOverYear">
      <img src="{{CacheBusting('/Content/images/common/icon-caution.svg')}}" class="img-Caution gtmclick" alt="(!)">
      <p class="box-Message-Heading-Text">期限日確認後、更新手続きを失念したことによる利用停止についてのお問い合わせが急増しております。<br></p>
      ドメインを安心して運用する為、お早めの期限日延長手続きをおすすめします。
    </div>
    <div class="box-Message-Heading" *ngIf="viewModel.isShowButtonOverYear">
      <img src="{{CacheBusting('/Content/images/common/icon-caution.svg')}}" class="img-Caution gtmclick" alt="(!)">
      <p class="box-Message-Heading-Text">継続期限日が{{viewModel.currentMonth}}月以降のドメインについて、更新手続きを失念したことによる利用停止にご注意ください。<br></p>
      ドメインを安心して運用する為、お早めの期限日延長手続きをおすすめします。
    </div>
</div>
<div class="nav-Group mb8 list-Message-Wrap" *ngIf="viewModel.canDisplayRecoverRecommend">
    <button type="button" class="list-Message-Close" (click)="viewModel.onClickCloseRecoverRecommend()"><i class="icon icon-Clear gtmclick" data-gtmvalue="navi_domainsuspended_yellowdelete" aria-label="閉じる"></i></button>
    <div [innerHtml]="viewModel.recoverContents | safe:'html'"></div>
</div>

<div *ngIf="viewModel.isShowCouponDomainDetail && viewModel.gmoTaxedDiscount > 0">
    <ul class="list-Message is-Appeal">
          <li class="list-Message-item is-FullWidth">
          <span class="list-Message-Content">
              <i class="icon icon-InfoCircle"></i>
              <b class="text-Em">1回限り！更新2年値引きクーポン</b><br>
              <b class="text-Em">2年更新限定で利用できるクーポンです。</b>注文確認画面で5000円OFFのクーポンをご利用いただけます。2029/03/31 12:00:00まで <br>
              ※1回のご注文で、1回限りのご利用となります。１クーポンで複数回の注文にご利用はできませんのでご注意ください。<br>
              ※本クーポンは2年更新限定となります。2年更新が不可のドメインは利用することができません。
          </span>
      </li>
    </ul>
</div>
<div class="tbl-Wrap-Loading">
    <section class="section" style="position: relative;">
        <h2 class="hdn-Lev3 with-border" *ngIf="(!viewModel.isShowButtonOverYear && !viewModel.isNaviRenewFirstYear)">ドメインの選択</h2>
        <h2 class="hdn-Lev3 with-border" *ngIf="viewModel.isNaviRenewFirstYear">ドメイン継続利用</h2>
        <div class="nav-Group mb8">
            <div class="nav-Group-Left is-DisplayFlex is-AlignItemsCenter is-AlignItemFlexStart">
                <domain_search_dropdown class="is-DisplayFlex is-AlignItemsCenter is-AlignItemFlexStart"
                                        [isOwnedMoreThanFiveDomain] = "viewModel.isOwnedMoreThanFiveDomain">
                </domain_search_dropdown>
            </div>
            <div class="nav-Group-Right is-DisplayFlex is-AlignItemsCenter" *ngIf="!viewModel.hasDomainId && !viewModel.isHaveOnlyOneDomain && viewModel.isHasDomain">
                <dl class="list-DefInline mt2">
                    <dt>表示件数</dt>
                    <dd>
                        <span class="form-Select is-Auto">
                            <select name="pageSizeList" class="gtmclick" [attr.data-gtmvalue]="'change_pagesize_' + viewModel.pageSize" (change)="viewModel.onChangePageSize($event)">
                                <option *ngFor="let pageSize of viewModel.pageSizeList" [selected]="viewModel.pageSize === pageSize" value="{{pageSize}}">{{pageSize}}</option>
                            </select>
                        </span>
                    </dd>
                </dl>
            </div>
        </div>
        <table class="tbl-Striped is-Hoverable" [ngClass]="{ 'is-DomainUpdate': viewModel.isExistDefCheckedDomain || viewModel.hasRestoreDomain, 'has-Deadline': viewModel.isExistDefCheckedDomain || viewModel.hasRestoreDomain }" style="border-top:1px solid #d6d6d6;">
            <ng-container *ngIf="!viewModel.isShowButtonOverYear">
                <colgroup>
                  <!-- クーポンあり -->
                  <col width="50">
                  <col width="">
                  <col width="208">
                  <col width="30">
                  <col width="178">
                  <col width="80">
                  <col width="80" *ngIf="viewModel.hasCoupon">
                  <col width="70">
                </colgroup>
                <thead>
                  <tr>
                    <th class="cell-Checkbox">
                      <label *ngIf="!viewModel.hasDomainId && viewModel.isHasDomain">
                        <input type="checkbox" name="isCheckedDomainAll" class="gtmclick" [attr.data-gtmvalue]="'checked_domain_all_' + viewModel.renewDomainHeader.isCheckedDomainAll" [(ngModel)]="viewModel.renewDomainHeader.isCheckedDomainAll" (change)="viewModel.onChangeRenewDomainAll()" />
                        <span class="form-Checkbox is-Large" tabindex="0"></span>
                      </label>
                    </th>
                    <th>
                      ドメイン名
                      <button type="button" class="btn-Icon ml8 gtmclick" [attr.data-gtmvalue]="'sort_' + viewModel.sortList[viewModel.sortDomainNameKey]" [class.is-DESC]="viewModel.sortList[viewModel.sortDomainNameKey] === 1" (click)="viewModel.onClickSortDomain(viewModel.sortDomainNameKey)" *ngIf="!viewModel.hasDomainId && viewModel.isHasDomain">
                        <i class="icon icon-DropDown"></i>
                      </button>
                    </th>
                    <th class="text-Left">
                      <div [ngClass]="{ 'is-Relative':!viewModel.isHaveOnlyOneDomain && !viewModel.hasDomainId && viewModel.isHasDomain, 'is-DisplayInlineBlock':!viewModel.isHaveOnlyOneDomain && !viewModel.hasDomainId && viewModel.isHasDomain}">
                        <span class="tbl-Striped-Budge ml42" *ngIf="!viewModel.isHaveOnlyOneDomain && !viewModel.hasDomainId && viewModel.isHasDomain">年数一括選択はこちら</span>
                          <ng-container *ngIf="viewModel.isNaviRenewFirstYear">
                            継続年数
                          </ng-container>
                          <ng-container *ngIf="!viewModel.isNaviRenewFirstYear">
                            更新年数
                          </ng-container>
                        <span class="form-Select is-Auto" *ngIf="!viewModel.hasDomainId && viewModel.isHasDomain">
                          <select name="updateYearAll" class="gtmclick" [attr.data-gtmvalue]="'selected_year_all_' + viewModel.renewDomainHeader.updateYearAll" [(ngModel)]="viewModel.renewDomainHeader.updateYearAll" (change)="viewModel.onChangeUpdateYearAll()">
                            <option value="0">
                              選択
                            </option>
                            <option *ngFor="let updateYear of viewModel.updateYearList" value="{{updateYear}}">
                              {{updateYear}}年
                            </option>
                          </select>
                        </span>
                        </div>
                    </th>
                    <th colspan="2">
                      更新期限日
                      <button type="button" class="btn-Icon ml8 gtmclick" [attr.data-gtmvalue]="'sort_' + viewModel.sortList[viewModel.sortExpirationDateKey]" [class.is-DESC]="viewModel.sortList[viewModel.sortExpirationDateKey] === 1" (click)="viewModel.onClickSortDomain(viewModel.sortExpirationDateKey)" *ngIf="!viewModel.hasDomainId && viewModel.isHasDomain">
                        <i class="icon icon-DropDown"></i>
                      </button>
                    </th>
                    <th class="pr0">
                      <div [ngClass]="{ 'is-Relative':viewModel.isHaveOnlyOneDomain && !viewModel.hasDomainId, 'is-DisplayInlineBlock':viewModel.isHaveOnlyOneDomain && !viewModel.hasDomainId}">
                        <span class="tbl-Striped-Budge" *ngIf="viewModel.isHaveOnlyOneDomain && !viewModel.hasDomainId">継続利用の手続きはこちら</span>
                        期限の管理
                      </div>
                    </th>
                    <th class="text-Left pr0" *ngIf="viewModel.hasCoupon">
                      クーポン
                    </th>
                    <th class="text-Left">
                    </th>
                  </tr>
                </thead>
                <!-- ローディング中に表示する空テーブル -->
                <tbody *ngIf="viewModel.partLoading">
                  <tr *ngFor="let data of viewModel.emptyDomainData">
                    <td class="cell-Checkbox">
                      <label>
                        <input type="checkbox" />
                        <span class="form-Checkbox is-Large" tabindex="0"></span>
                      </label>
                    </td>
                    <td class="pr0">
                      <strong>{{data.domainName}}</strong>
                    </td>
                    <td colspan="2">
                      {{data.renewalDeadlineDate}}
                    </td>
                    <td class="text-Left"></td>
                    <td>
                      <span class="form-Select is-Auto">
                        <select>
                          <option>{{data.updataYear}}</option>
                        </select>
                      </span>
                    </td>
                  </tr>
                </tbody>

                <!-- データなし -->
                <tbody *ngIf="viewModel.renewDomains.length === 0 && !viewModel.partLoading">
                  <tr>
                    <td [attr.colspan]="(viewModel.hasCoupon) ? '8' : '7'" class="text-Center">対象のドメインはありません</td>
                  </tr>
                </tbody>
                <!-- データあり -->
                <tbody *ngIf="viewModel.renewDomains.length > 0 && !viewModel.partLoading">
                  <tr *ngFor="let renewDomain of viewModel.renewDomains; let i = index;"
                      [class.is-RowGrayout]="!renewDomain.isActiveDomain"
                      [class.is-Emergency]="(renewDomain.isDefCheckedDomain && !renewDomain.isAutoRenewActive && renewDomain.renewalDeadlineCount < 0) || renewDomain.isRestorableDomain"
                      [class.is-Active]="!renewDomain.isAutoRenewActive && renewDomain.renewalDeadlineCount >= 0 && renewDomain.isDefCheckedDomain"
                      (click)="viewModel.onClickRenewDomainOneRow($event,renewDomain.domainNameMl)">
                    <td class="cell-Checkbox">
                      <label *ngIf="renewDomain.isActiveDomain && renewDomain.canRsDiscountRenewDate && !renewDomain.isRestorableDomain">
                        <input type="checkbox" name="isCheckedDomain" class="gtmclick" [attr.data-gtmvalue]="'checked_domain_' + i + '_' + renewDomain.isCheckedDomain" [(ngModel)]="renewDomain.isCheckedDomain" />
                        <span class="form-Checkbox is-Large" tabindex="0"></span>
                      </label>
                    </td>
                    <td class="pr0">
                      <span *ngIf="renewDomain.isRsDiscount" class="tbl-Striped-Label" tooltip placement="right" content="詳しくはこちら" textalign="left"><a href="javascript:void(0)" (click)="viewModel.redirectHelpPage()">永久無料</a></span>
                      <ng-container *ngIf="!renewDomain.isAutoRenewActive; else isNOTABNew_domainnameml">
                        <div><span class="text-Bold" [class.text-Danger]="renewDomain.isDefCheckedDomain || renewDomain.isRestorableDomain">{{renewDomain.domainNameMl}}</span></div>
                      </ng-container>
                      <ng-template #isNOTABNew_domainnameml>
                        <div><strong>{{renewDomain.domainNameMl}}</strong></div>
                      </ng-template>
                    </td>
                    <!-- 更新年数 -->
                    <td>
                      <div *ngIf="renewDomain.isActiveDomain && renewDomain.canRsDiscountRenewDate">
                        <ng-container *ngIf="!renewDomain.isRsDiscount && !renewDomain.isRsPending; else elseblock">
                          <ng-container *ngIf="!viewModel.isShowCouponDomainDetail; else elseShowCouponBRsDiscount">
                            <span class="form-Select is-Auto" *ngIf="!renewDomain.isRestorableDomain">
                              <select name="selectedUpdateYear" class="gtmclick" [(ngModel)]="renewDomain.selectedUpdateYear" (click)="viewModel.onChangeUpdateYearB(i)">
                                <option *ngFor="let updateYearItem of renewDomain.updateYearItems" value="{{updateYearItem.year}}">
                                  {{updateYearItem.year}}年
                                </option>
                              </select>
                            </span>
                          </ng-container>
                          <ng-template #elseShowCouponBRsDiscount>
                            <ul *ngIf="!renewDomain.isRestorableDomain" class="nav-Bar is-NumberYears">
                              <li><button type="button" class="is-Active"> {{renewDomain.selectedUpdateYear}}年</button></li>
                            </ul>
                          </ng-template>
                        </ng-container>
                        <ng-template #elseblock>
                          <ul class="nav-Bar is-NumberYears">
                            <li><button type="button" [class.is-Active]="renewDomain.selectedUpdateYear === '1'"> 1年</button></li>
                          </ul><!-- /.nav-Bar -->
                        </ng-template>
                      </div>
                    </td>
                    <td *ngIf="(renewDomain.isDefCheckedDomain && !renewDomain.isAutoRenewActive) || renewDomain.isRestorableDomain" class="pr0">
                      <img *ngIf="renewDomain.renewalDeadlineCount >= 0 && !renewDomain.isRestorableDomain" src="{{CacheBusting('/Content/images/common/icon-caution.svg')}}" class="img-Caution" alt="(!)">
                      <img *ngIf="renewDomain.renewalDeadlineCount < 0 || renewDomain.isRestorableDomain" src="{{CacheBusting('/Content/images/common/icon-pause.svg')}}" class="img-Caution" alt="(!)">
                    </td>
                    <!-- 更新期限日 -->
                    <!-- デフォルト選択あり -->
                    <td *ngIf="renewDomain.isDefCheckedDomain && !renewDomain.isAutoRenewActive && !renewDomain.isRestorableDomain" class="text-Left pr0">
                        <span class="text-Danger">{{renewDomain.renewalDeadlineDate}}{{renewDomain.countDown.expirationTime}}<br>
                            <span class="text-Bold">（残{{renewDomain.countDown.dd}}日 {{renewDomain.countDown.hh}}:{{renewDomain.countDown.MM}}）</span>
                        </span>
                    </td>
                    <!-- デフォルト選択なし -->
                    <td *ngIf="!renewDomain.isDefCheckedDomain && !renewDomain.isAutoRenewActive && !renewDomain.isRestorableDomain" class="text-Left pr0" colspan="2">
                      <span>{{renewDomain.renewalDeadlineDate}} (残{{renewDomain.renewalDeadlineCount}}日）</span>
                    </td>
                    <!-- デフォルト選択なし かつ　廃止済みドメイン-->
                    <td *ngIf="renewDomain.isRestorableDomain" class="text-Left pr0">
                      <span class="text-Danger">{{renewDomain.renewalDeadlineDate}}<br>
                        <span class="text-Bold">（残0日 00:00）</span>
                      </span>
                    </td>
                    <td *ngIf="renewDomain.isAutoRenewActive && !renewDomain.isRestorableDomain" class="text-Left pr0" colspan="2">
                      {{renewDomain.renewalDeadlineDate}}
                    </td>
                    <td class="pr0">
                      <ng-container *ngIf="renewDomain.isActiveDomain && renewDomain.canRsDiscountRenewDate">
                        <ng-container *ngIf="!viewModel.isHaveOnlyOneDomain">
                          <a href="javascript:void(0)" class="btn is-Small is-Primary gtmclick" id="{{renewDomain.domainNameMl === viewModel.domainNameMlShowRibon && viewModel.isShowRibbon ? 'domainRenewBtnRs' : 'renewNormal' + i}}" (click)="viewModel.onClickRowConfirm(renewDomain.domainId)" [attr.data-gtmvalue]="'domain_renew_button_' + renewDomain.domainNameMl">継続利用</a>
                            <div class="floatingTooltip" floatingTooltip parent='#domainRenewBtnRs' distanceToMargin='59' *ngIf="renewDomain.domainNameMl === viewModel.domainNameMlShowRibon && viewModel.isShowRibbon">
                                <button class="floatingTooltip-Close gtmclick" aria-label="このお知らせを閉じる" (click)="viewModel.closeFloatingTooltip()" data-gtmvalue="navidomlist_rsballoon_delete"><span class="icon icon-Clear gtmclick" data-gtmvalue="navidomlist_rsballoon_delete"></span></button>
                                <div class="floatingTooltip-Inner box-Message is-Promotion">
                                    <h5 class="floatingTooltip-Attention">こちらのドメインは永久無料にすることができます</h5>
                                    <p class="floatingTooltip-Highlight">更新と同時にサーバー申込みで<br>
                                      <span class="size-15 text-Em">ドメインが永久に無料！</span>
                                    </p>
                                    <p class="box-Appeal is-NoBorder pt2 pb2 pl8 pr8 mb8 text-Em size-11 text-Center is-BgYellow" *ngIf="viewModel.totalPriceDomainRegistRS !== '0'">1年更新料金：<span class="text-Strike">{{viewModel.totalPriceDomainRegistRS}}円</span> → 永久無料</p>
                                    <h5 class="floatingTooltip-ListTitle mt8">さらにうれしい申し込む<span class="text-Primary">メリット</span></h5>
                                    <ul class="list-Icon">
                                        <li><i class="icon icon-Checked"></i>初期費用無料 & 月額最大2ヶ月無料<br><span class="highlight-blue">初回0円で利用開始</span></li>
                                        <li><i class="icon icon-Checked"></i>お申込みから利用開始まで<span class="highlight-blue">最短5分</span></li>
                                        <li><i class="icon icon-Checked"></i>ドメインとサーバーを<span class="highlight-blue">まとめて管理できる</span></li>
                                    </ul>
                                    <a href="javascript:void(0)" class="floatingTooltip-Link is-BgWhite" (click)="viewModel.openUrl('https://www.onamae.com/server/campaign/renewregist_navi/?btn_id=navi_renew_rswith_balloon_lp')">詳細はこちら</a>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="viewModel.isHaveOnlyOneDomain">
                          <div class="is-Relative is-DisplayInlineBlock">
                            <a href="javascript:void(0)" class="btn is-Small is-Primary gtmclick" (click)="viewModel.onClickRowConfirm(renewDomain.domainId)" [attr.data-gtmvalue]="'domain_renew_button_' + renewDomain.domainNameMl">次へ</a>
                          </div>
                        </ng-container>
                      </ng-container>
                    </td>
                    <td class="text-Left text-Danger" *ngIf="viewModel.hasCoupon">
                      <ng-container *ngIf="renewDomain.couponDiscountTaxInPrice !== '0'">
                        {{renewDomain.couponDiscountTaxInPrice}}円
                      </ng-container>
                    </td>
                    <!-- 未入金 -->
                    <td class="text-Left">
                      <a href="javascript:void(0)" *ngIf="renewDomain.isBillingWait" (click)="viewModel.onClickPaymentInvoice()"><span class="text-Danger">入金待ち</span></a>
                    </td>
                  </tr>
                </tbody>
            </ng-container>
            <ng-container *ngIf="viewModel.isShowButtonOverYear">
              <colgroup>
                <!-- クーポンあり -->
                <col width="50">
                <col width="">
                <col width="208">
                <col width="30">
                <col width="178">
                <col width="80">
                <col width="80">
                <col *ngIf="viewModel.hasCoupon" width="70">
              </colgroup>
              <thead>
                <tr>
                  <th class="cell-Checkbox">
                    <label *ngIf="!viewModel.hasDomainId && viewModel.isHasDomain">
                      <input type="checkbox" name="isCheckedDomainAll" class="gtmclick" [attr.data-gtmvalue]="'checked_domain_all_' + viewModel.renewDomainHeader.isCheckedDomainAll" [(ngModel)]="viewModel.renewDomainHeader.isCheckedDomainAll" (change)="viewModel.onChangeRenewDomainAll()" />
                      <span class="form-Checkbox is-Large" tabindex="0"></span>
                    </label>
                  </th>
                  <th>
                    ドメイン名
                    <button type="button" class="btn-Icon ml8 gtmclick" [attr.data-gtmvalue]="'sort_' + viewModel.sortList[viewModel.sortDomainNameKey]" [class.is-DESC]="viewModel.sortList[viewModel.sortDomainNameKey] === 1" (click)="viewModel.onClickSortDomain(viewModel.sortDomainNameKey)" *ngIf="!viewModel.hasDomainId && viewModel.isHasDomain">
                      <i class="icon icon-DropDown"></i>
                    </button>
                  </th>
                  <th class="text-Left">
                    <div [ngClass]="{ 'is-Relative':!viewModel.isHaveOnlyOneDomain && !viewModel.hasDomainId && viewModel.isHasDomain, 'is-DisplayInlineBlock':!viewModel.isHaveOnlyOneDomain && !viewModel.hasDomainId && viewModel.isHasDomain}">
                      <span class="tbl-Striped-Budge ml42" *ngIf="!viewModel.isHaveOnlyOneDomain && !viewModel.hasDomainId && viewModel.isHasDomain">年数一括選択はこちら</span>
                      継続年数
                      <span class="form-Select is-Auto" *ngIf="!viewModel.hasDomainId && viewModel.isHasDomain">
                        <select name="updateYearAll" class="gtmclick" [attr.data-gtmvalue]="'selected_year_all_' + viewModel.renewDomainHeader.updateYearAll" [(ngModel)]="viewModel.renewDomainHeader.updateYearAll" (change)="viewModel.onChangeUpdateYearAll()">
                          <option value="0">
                            選択
                          </option>
                          <option *ngFor="let updateYear of viewModel.updateYearList" value="{{updateYear}}">
                            {{updateYear}}年
                          </option>
                        </select>
                      </span>
                      </div>
                  </th>
                  <th colspan="2">
                    継続期限日
                    <button type="button" class="btn-Icon ml8 gtmclick" [attr.data-gtmvalue]="'sort_' + viewModel.sortList[viewModel.sortExpirationDateKey]" [class.is-DESC]="viewModel.sortList[viewModel.sortExpirationDateKey] === 1" (click)="viewModel.onClickSortDomain(viewModel.sortExpirationDateKey)" *ngIf="!viewModel.hasDomainId && viewModel.isHasDomain">
                      <i class="icon icon-DropDown"></i>
                    </button>
                  </th>
                  <th class="pr0">
                    <div [ngClass]="{ 'is-Relative':viewModel.isHaveOnlyOneDomain && !viewModel.hasDomainId, 'is-DisplayInlineBlock':viewModel.isHaveOnlyOneDomain && !viewModel.hasDomainId}">
                      <span class="tbl-Striped-Budge" *ngIf="viewModel.isHaveOnlyOneDomain && !viewModel.hasDomainId">継続利用の手続きはこちら</span>
                      期限の管理
                    </div>
                  </th>
                  <th class="text-Left pr0" *ngIf="viewModel.hasCoupon">
                    クーポン
                  </th>
                  <th class="text-Left">
                  </th>
                </tr>
              </thead>
              <!-- ローディング中に表示する空テーブル -->
              <tbody *ngIf="viewModel.partLoading">
                <tr *ngFor="let data of viewModel.emptyDomainData">
                  <td class="cell-Checkbox">
                    <label>
                      <input type="checkbox" />
                      <span class="form-Checkbox is-Large" tabindex="0"></span>
                    </label>
                  </td>
                  <td class="pr0">
                    <strong>{{data.domainName}}</strong>
                  </td>
                  <td colspan="2">
                    {{data.renewalDeadlineDate}}
                  </td>
                  <td class="text-Left"></td>
                  <td>
                    <span class="form-Select is-Auto">
                      <select>
                        <option>{{data.updataYear}}</option>
                      </select>
                    </span>
                  </td>
                </tr>
              </tbody>

              <!-- データなし -->
              <tbody *ngIf="viewModel.renewDomains.length === 0 && !viewModel.partLoading">
                <tr>
                  <td [attr.colspan]="(viewModel.hasCoupon) ? '8' : '7'" class="text-Center">対象のドメインはありません</td>
                </tr>
              </tbody>
              <!-- データあり -->
              <tbody *ngIf="viewModel.renewDomains.length > 0 && !viewModel.partLoading">
                <tr *ngFor="let renewDomain of viewModel.renewDomains; let i = index;"
                    [class.is-RowGrayout]="!renewDomain.isActiveDomain"
                    [class.is-Emergency]="(renewDomain.isDefCheckedDomain && !renewDomain.isAutoRenewActive && renewDomain.renewalDeadlineCount < 0) || renewDomain.isRestorableDomain"
                    [class.is-Active]="!renewDomain.isAutoRenewActive && renewDomain.renewalDeadlineCount >= 0 && renewDomain.renewalDeadlineCount <= 60"
                    (click)="viewModel.onClickRenewDomainOneRow($event,renewDomain.domainNameMl)">
                  <td class="cell-Checkbox">
                    <label *ngIf="renewDomain.isActiveDomain && renewDomain.canRsDiscountRenewDate && !renewDomain.isRestorableDomain">
                      <input type="checkbox" name="isCheckedDomain" class="gtmclick" [attr.data-gtmvalue]="'checked_domain_' + i + '_' + renewDomain.isCheckedDomain" [(ngModel)]="renewDomain.isCheckedDomain" />
                      <span class="form-Checkbox is-Large" tabindex="0"></span>
                    </label>
                  </td>
                  <td>
                    <span *ngIf="renewDomain.isRsDiscount" class="tbl-Striped-Label" tooltip placement="right" content="詳しくはこちら" textalign="left"><a href="javascript:void(0)" (click)="viewModel.redirectHelpPage()">永久無料</a></span>
                    <ng-container *ngIf="!renewDomain.isAutoRenewActive; else isNOTABNew_domainnameml">
                      <div><span class="text-Bold" [class.text-Danger]="renewDomain.renewalDeadlineCount <= 60 || renewDomain.isRestorableDomain">{{renewDomain.domainNameMl}}</span></div>
                    </ng-container>
                    <ng-template #isNOTABNew_domainnameml>
                      <div><strong>{{renewDomain.domainNameMl}}</strong></div>
                    </ng-template>
                  </td>
                  <td>
                    <div *ngIf="renewDomain.isActiveDomain && renewDomain.canRsDiscountRenewDate">
                      <ng-container *ngIf="!renewDomain.isRsDiscount && !renewDomain.isRsPending; else elseblock">
                        <ng-container *ngIf="!viewModel.isShowCouponDomainDetail; else elseShowCouponBRsDiscount">
                          <span class="form-Select is-Auto" *ngIf="!renewDomain.isRestorableDomain">
                            <select name="selectedUpdateYear" class="gtmclick" [(ngModel)]="renewDomain.selectedUpdateYear" (click)="viewModel.onChangeUpdateYearB(i)">
                              <option *ngFor="let updateYearItem of renewDomain.updateYearItems" value="{{updateYearItem.year}}">
                                {{updateYearItem.year}}年
                              </option>
                            </select>
                          </span>
                        </ng-container>
                        <ng-template #elseShowCouponBRsDiscount>
                          <ul *ngIf="!renewDomain.isRestorableDomain" class="nav-Bar is-NumberYears">
                            <li><button type="button" class="is-Active"> {{renewDomain.selectedUpdateYear}}年</button></li>
                          </ul>
                        </ng-template>
                      </ng-container>
                      <ng-template #elseblock>
                        <ul class="nav-Bar is-NumberYears">
                          <li><button type="button" [class.is-Active]="renewDomain.selectedUpdateYear === '1'"> 1年</button></li>
                        </ul><!-- /.nav-Bar -->
                      </ng-template>
                    </div>
                  </td>
                  <td *ngIf="(renewDomain.isDefCheckedDomain && !renewDomain.isAutoRenewActive) || renewDomain.isRestorableDomain" class="pr0">
                    <img *ngIf="renewDomain.renewalDeadlineCount >= 0 && !renewDomain.isRestorableDomain" src="{{CacheBusting('/Content/images/common/icon-caution.svg')}}" class="img-Caution" alt="(!)">
                    <img *ngIf="renewDomain.renewalDeadlineCount < 0 || renewDomain.isRestorableDomain" src="{{CacheBusting('/Content/images/common/icon-pause.svg')}}" class="img-Caution" alt="(!)">
                  </td>
                  <!-- 更新期限日 -->
                  <!-- デフォルト選択あり -->
                  <td *ngIf="renewDomain.isDefCheckedDomain && !renewDomain.isAutoRenewActive && !renewDomain.isRestorableDomain" class="text-Left pr0">
                    <span class="text-Danger">{{renewDomain.renewalDeadlineDate}}{{renewDomain.countDown.expirationTime}}<br>
	                    <span class="text-Bold">（残{{renewDomain.countDown.dd}}日 {{renewDomain.countDown.hh}}:{{renewDomain.countDown.MM}}）</span>
                    </span>
                  </td>
                  <!-- デフォルト選択なし -->
                  <td *ngIf="!renewDomain.isDefCheckedDomain  && !renewDomain.isAutoRenewActive && !renewDomain.isRestorableDomain" class="text-Left pr0" colspan="2">
                    <span [class.text-Danger]="renewDomain.renewalDeadlineCount <= 60">{{renewDomain.renewalDeadlineDate}} (残{{renewDomain.renewalDeadlineCount}}日）</span>
                  </td>
                  <!-- デフォルト選択なし かつ 廃止済みドメイン-->
                  <td *ngIf="renewDomain.isRestorableDomain" class="text-Left pr0">
                    <span class="text-Danger">{{renewDomain.renewalDeadlineDate}}<br>
                      <span class="text-Bold">（残0日 00:00）</span>
                    </span>
                  </td>
                  <td *ngIf="renewDomain.isAutoRenewActive && !renewDomain.isRestorableDomain" class="text-Left pr0" colspan="2">
                    {{renewDomain.renewalDeadlineDate}}
                  </td>
                  <!-- 未入金 -->
                  <td class="pr0">
                    <ng-container *ngIf="renewDomain.isActiveDomain && renewDomain.canRsDiscountRenewDate">
                      <ng-container *ngIf="!viewModel.isHaveOnlyOneDomain">
                        <a href="javascript:void(0)" class="btn is-Small is-Primary pt12 pb12 js-popupFixed-domainrenew-row gtmclick" id="{{renewDomain.domainNameMl === viewModel.domainNameMlShowRibon && viewModel.isShowRibbon ? 'domainRenewBtnRs' : 'renewNormal' + i}}" (click)="viewModel.onClickRowConfirm(renewDomain.domainId)" data-toggle="popupFixed" data-target="#popupFixed1" data-placement="right" [attr.data-gtmvalue]="'domain_renew_button_' + renewDomain.domainNameMl" data-realtimefired="true">継続利用</a>
                          <div class="floatingTooltip" floatingTooltip parent='#domainRenewBtnRs' distanceToMargin='59' *ngIf="renewDomain.domainNameMl === viewModel.domainNameMlShowRibon && viewModel.isShowRibbon">
                              <button class="floatingTooltip-Close gtmclick" aria-label="このお知らせを閉じる" data-gtmvalue="navidomlist_rsballoon_delete" (click)="viewModel.closeFloatingTooltip()"><span class="icon icon-Clear gtmclick" data-gtmvalue="navidomlist_rsballoon_delete"></span></button>
                              <div class="floatingTooltip-Inner box-Message is-Promotion">
                                  <h5 class="floatingTooltip-Attention">こちらのドメインは永久無料にすることができます</h5>
                                  <p class="floatingTooltip-Highlight">更新と同時にサーバー申込みで<br>
                                    <span class="size-15 text-Em">ドメインが永久に無料！</span>
                                  </p>
                                  <p class="box-Appeal is-NoBorder pt2 pb2 pl8 pr8 mb8 text-Em size-11 text-Center is-BgYellow" *ngIf="viewModel.totalPriceDomainRegistRS !== '0'">1年更新料金：<span class="text-Strike">{{viewModel.totalPriceDomainRegistRS}}円</span> → 永久無料</p>
                                  <h5 class="floatingTooltip-ListTitle mt8">さらにうれしい申し込む<span class="text-Primary">メリット</span></h5>
                                  <ul class="list-Icon">
                                      <li><i class="icon icon-Checked"></i>初期費用無料 & 月額最大2ヶ月無料<br><span class="highlight-blue">初回0円で利用開始</span></li>
                                      <li><i class="icon icon-Checked"></i>お申込みから利用開始まで<span class="highlight-blue">最短5分</span></li>
                                      <li><i class="icon icon-Checked"></i>ドメインとサーバーを<span class="highlight-blue">まとめて管理できる</span></li>
                                  </ul>
                                  <a href="javascript:void(0)" class="floatingTooltip-Link is-BgWhite" (click)="viewModel.openUrl('https://www.onamae.com/server/campaign/renewregist_navi/?btn_id=navi_renew_rswith_balloon_lp')">詳細はこちら</a>
                              </div>
                          </div>
                      </ng-container>
                      <ng-container *ngIf="viewModel.isHaveOnlyOneDomain">
                        <div class="is-Relative is-DisplayInlineBlock">
                          <a href="javascript:void(0)" class="btn is-Small is-Primary pt12 pb12 gtmclick" (click)="viewModel.onClickRowConfirm(renewDomain.domainId)" [attr.data-gtmvalue]="'domain_renew_button_' + renewDomain.domainNameMl">次へ</a>
                        </div>
                      </ng-container>
                    </ng-container>
                  </td>
                  <td class="text-Left text-Danger" *ngIf="viewModel.hasCoupon">
                    <ng-container *ngIf="renewDomain.couponDiscountTaxInPrice !== '0'">
                      {{renewDomain.couponDiscountTaxInPrice}}円
                    </ng-container>
                  </td>
                  <!-- 未入金 -->
                  <td class="text-Left">
                    <a href="javascript:void(0)" *ngIf="renewDomain.isBillingWait" (click)="viewModel.onClickPaymentInvoice()"><span class="text-Danger">入金待ち</span></a>
                  </td>
                </tr>
              </tbody>
            </ng-container>
        </table>
        <p></p>
        <ul *ngIf="viewModel.maxPage > 1" class="nav-Pagination mt8">
            <li>
                <a href="javascript:void(0)" class="gtmclick" data-gtmvalue="pager_prev" [class.is-Disabled]="viewModel.currentPage === 1" (click)="viewModel.onClickPageNo(viewModel.currentPage - 1)"><i class="icon icon-ArrowLeft"></i>前</a>
            </li>
            <li *ngFor="let pageNo of viewModel.pageNoList">
                <a href="javascript:void(0)" class="gtmclick" [attr.data-gtmvalue]="'pager_' + pageNo" (click)="viewModel.onClickPageNo(pageNo)" [class.is-Current]="pageNo == viewModel.currentPage">{{pageNo}}</a>
            </li>
            <li>
                <a href="javascript:void(0)" class="gtmclick" data-gtmvalue="pager_next" [class.is-Disabled]="viewModel.currentPage === viewModel.maxPage" (click)="viewModel.onClickPageNo(viewModel.currentPage + 1)">次<i class="icon icon-ArrowRight"></i></a>
            </li>
        </ul>
        <div class="loading-Block" *ngIf="viewModel.partLoading">
            <div class="loading-Particle is-Middle"></div>
        </div>
    </section>
    <div class="nav-Group mb24 mt24" *ngIf="viewModel.isDisplayFreeRegistDomain">
        <div>
            <ul class="list-Message is-Appeal">
                <li class="list-Message-item is-FullWidth" >
                    <span class="list-Message-Content">
                        <label>
                            <input type="checkbox" name="isCheckedFreeRegistDomain" [(ngModel)]="viewModel.isCheckedFreeRegistDomain" (change)="viewModel.onChangeFreeRegistDomain()" [disabled]="viewModel.isDisableFreeRegist ? true : null"/>
                            <span class="form-Checkbox is-Large" tabindex="0"></span>
                        </label>
                       チェックを入れてクレジット決済で次へ進むと「{{viewModel.freeRegistDomain}}」が無料で取得できます。
                    </span>
            	    <p class="mb0 pt10">
              		    無料で追加取得されたドメインはWhois情報公開代行・自動更新設定が無料で設定されます。お申込み後、設定はご自由に変更いただけます。
            	    </p>
              </li>
            </ul>
        </div>
    </div>

    <div class="mb24 mt24" *ngIf="viewModel.exprireDomainsForAbTest.length === 0 && viewModel.isShowButtonOverYear && viewModel.isShowNotice">
        <ul class="list-Message is-Appeal">
            <li class="list-Message-item is-FullWidth">
                <i class="icon icon-InfoCircle"></i>
                <span class="box-Message-Heading-Text size-13">更新期限まで2週間を切ると、銀行振込/コンビニ支払いではお手続きを承ることができません。余裕を持ってお手続きをお済ませください。</span>
            </li>
        </ul>
    </div>

    <!-- お支払い方法 -->
    <section class="section" style="position: relative;">
        <domain-renew-payment [isDisableBank]="viewModel.isDisallowPaymentBank"
                              [isDisableConvenience]="viewModel.isDisallowPaymentNetCvs"
                              (setPaymentMethodEvent)="viewModel.setPaymnetMethod($event)"
                              (setConveinienceFeeEvent)="viewModel.setConvenienceFee($event)"
                              (onPaymentMethodChangedSuccessEvent)="viewModel.openRenewDomains()"
                              (onCompleteAllProcessEvent)="viewModel.setIsComponentLoading($event)"
                              (onDecideCreditCardInfoEvent)="viewModel.onDecideCreditCardInfo($event)"
                              (onChangePaymentMethodEvent)="viewModel.onChangePaymentMethod($event)">
        </domain-renew-payment>
        <div class="loading-Block" *ngIf="viewModel.paymentLoading">
            <div class="loading-Particle is-Middle"></div>
        </div>
    </section>

    <p class="text-Center mt24" id="button-Renew">
        <primary-button [disabled]="viewModel.isDisableNextButton" data-gtmvalue="transition_conform_01" (clickEvent)="viewModel.onClickConfirm()">次へ</primary-button>
    </p>
    <p class="text-Center mt8">申し込み内容の確認画面が表示されます。</p>
    <div class="box-Floating" [class.is-Hide]="viewModel.isHideFloatingNextButton" *ngIf="!viewModel.isHaveOnlyOneDomain">
        <div class="box-Floating-Btn">
            <primary-button [disabled]="viewModel.isDisableNextButton" data-gtmvalue="transition_conform_01" (clickEvent)="viewModel.onClickConfirm()">次へ</primary-button>
            <p class="text-Center mt8">申し込み内容の確認画面が表示されます。</p>
        </div>
    </div>

    <!-- 確認画面モーダル　New -->
    <domain-renew-modal-new [isShow]="viewModel.isShowNewConfirmModal"
                            [isDisplayFreeRegist]="viewModel.isDisplayFreeRegistModal"
                            [isShowCoupon1500Yen]="viewModel.isABNew_AdjustRenewCouponCode"
                            [isABNew_AdjustShowHideOptionRenewModal]="viewModel.isABNew_AdjustShowHideOptionRenewModal"
                            [isShowCouponDomainDetail]="viewModel.isShowCouponDomainDetail"
                            [renewDomainsData]="viewModel.renewDomainsData"
                            [freeRegistDomainsData]="viewModel.freeRegistDomainData"
                            [freeRegistDomainId]="viewModel.freeRegistDomainId"
                            (onCloseButtonClickedEvent)="viewModel.onCloseConfirmModalButtonClicked($event)"
                            (noSessionEvent)="viewModel.noSession($event)"
                            (onErrorEvent)="viewModel.onError($event)"
                            (onUnExceptedErrorEvent)="viewModel.onUnExceptedError($event)"
                            (onCloseModaldEvent)="viewModel.onCloseModal($event)"
                            [isSelectedOnlyOneDomain]="viewModel.isSelectedOnlyOneDomain"
                            [isOpenModal]="viewModel.isOpenModal"
                            [gmoTaxedDiscount]="viewModel.gmoTaxedDiscount"
                            >
    </domain-renew-modal-new>
    <div *ngIf="viewModel.showModalConfirm" id="confirmModal" class="modal" style="z-index:10000; display: block;" data-gtmvalue="Navi_365_return_btn" (click)="viewModel.onClickOutsideCloseModalConfirm($event)">
        <div class="modal-Dialog" style="width:415px">
            <div class="modal-Dialog-Header">
                <h3 class="modal-Dialog-Header-Title">更新手続きをお忘れではございませんか？</h3>
            </div>
            <div class="modal-Dialog-Body">
                <p class="mb20">ドメインを一度失効すると、取り戻すことは極めて困難です。</p>
                <p class="mb20">また、失効したドメインが再取得専門業者に流用され、ブランドイメージを毀損するトラブルが急増しています。</p>
                <p class="mb20">ドメインの更新期限日をご確認のうえ、更新が必要なドメインはお早めにお手続きされることをお勧めいたします。</p>
            </div>
            <div class="modal-Dialog-Footer">
                <ul class="list-Btn is-Right mb0">
                    <li><button type="button" class="btn gtmclick" data-gtmvalue="Navi_365_out_btn" (click)="viewModel.onClickModalConfirm(true)">更新画面から移動する</button></li>
                    <li><button type="button" class="btn is-Primary gtmclick" data-gtmvalue="Navi_365_return_btn" (click)="viewModel.onClickModalConfirm(false)">更新画面に戻る</button></li>
                </ul>
            </div>
        </div>
    </div>

    <div id="confirmModal2" class="modal" modal [openFlg]="viewModel.isShowModalRecoverRecommend" (click)="viewModel.onClickOutsideCloseModalRecover($event)">
        <div class="modal-Dialog" style="width:415px">
            <div class="modal-Dialog-Header">
                <h3 class="modal-Dialog-Header-Title">お知らせの表示について</h3>
            </div>
            <div class="modal-Dialog-Body">
                <p class="mb20">削除したお知らせを次回から非表示にできます。</p>
                <p class="mb20">非表示にした場合は、再度確認することができなくなりますのでご注意ください</p>
            </div>
            <div class="modal-Dialog-Footer">
                <ul class="list-Btn is-Right mb0">
                    <li><button type="button" class="btn" (click)="viewModel.onClickModalRecoverModal(false)">再表示させる</button></li>
                    <li><button type="button" class="btn is-Primary" (click)="viewModel.onClickModalRecoverModal(true)">次回から表示しない</button></li>
                </ul>
            </div>
        </div>
    </div>
</div>

<loading [entireLoading]="viewModel.allloading"></loading>

