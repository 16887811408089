import { StringUtil } from "Content/script/libs/utils/string.util";
import { ContentType } from "Content/script/libs/common/domainRenew/ContentType";
import { IItemApplyingDto } from "Content/script/dtos/data_transfer_domain_renew_done_dto";
import { ApplyingDetailContentType } from "Content/script/components/domain/setting/renew/done/done.component";

export class ItemDetail {
    private _list = {
        ドメイン更新: ApplyingDetailContentType.DomainRenew,
        Whois情報公開代行: ApplyingDetailContentType.WhoisProxy,
        メール転送オプション: ApplyingDetailContentType.WhoisMailFwd,
        ドメインプロテクション: ApplyingDetailContentType.Protection,
        SSLサーバー証明書: ApplyingDetailContentType.Ssl,
        レンタルサーバー: ApplyingDetailContentType.Hosting,
        クーポン: ApplyingDetailContentType.Coupon,
        初期費用: ApplyingDetailContentType.HostingInitialCost,
        初回料金: ApplyingDetailContentType.HostingInitialCost,
        初期設定費用: ApplyingDetailContentType.HostingInitialSettingCost,
        月額料金: ApplyingDetailContentType.HostingMonthlyFee,
        ドメイン登録: ApplyingDetailContentType.DomainCreate,
        "月額費用※3ヶ月30%OFF": ApplyingDetailContentType.HostingInitialCostPatternB1,
        "月額費用初回※": ApplyingDetailContentType.HostingInitialCostPatternB2,
        "サービス維持調整費 ※": ApplyingDetailContentType.Surcharge,
        "※レンタルサーバー同時対象": ApplyingDetailContentType.DomainRenewWithRs
    };

    public constructor(
        public readonly isDiscount: boolean,
        public readonly content: string,
        public readonly totalPrice: string,
        public readonly totalUnTaxPrice: string,
        public readonly isNewApply: boolean,
        public readonly contentType: ContentType = ContentType.Other
    ) { }

    private _convertContentType(): ApplyingDetailContentType {
        return this._list[this.content] || null;
    }

    public convertToDto(): IItemApplyingDto {
        const contentType: ApplyingDetailContentType = this._convertContentType();

        if (!contentType) {
            throw new Error(`${this.content} is not domain option.`);
        }

        return {
            contentType: contentType,
            price: StringUtil.StringWithCommaToNumber(this.totalPrice),
            unTaxPrice: StringUtil.StringWithCommaToNumber(this.totalUnTaxPrice)
        } as IItemApplyingDto;
    }
}
