<!-- 支払い方法 -->
<ng-container *ngIf="viewModel.isDisableView === false">
    <h2 class="{{viewModel.h2Class}}">お支払い方法&nbsp;<a href="javascript:void(0)" class="ml16 gtmclick" data-gtmvalue="d_changed_payment" style="font-size:13px;font-weight:normal;" (click)="viewModel.onClickPaymentMethodChangeButton()">その他のお支払い方法</a></h2>
    <ng-container [ngSwitch]="viewModel.currentPaymentType">
        <!-- 登録済みのクレジットカード -->
        <table class="tbl" *ngSwitchCase="viewModel.PaymentType.RegisteredCreditCard">
            <colgroup>
                <col width="204">
                <col width="">
            </colgroup>
            <tbody>
                <tr>
                    <th class="is-ValignMiddle">お支払い方法</th>
                    <td>登録済みのクレジットカード<small *ngIf="viewModel.viewCreditCard.canUseCredit === false" class="text-Danger">※このカードは有効期限切れです。クレジットカードの情報を更新してください。</small></td>
                </tr>
                <tr>
                    <th class="is-ValignMiddle">カード番号</th>
                    <ng-container *ngIf="viewModel.paymentModal.registeredCreditCard.canUseCredit === false; else elseCreditNumber">
                        <td><span class="text-Danger">{{viewModel.viewCreditCard.creditNumber}}</span></td>
                    </ng-container>
                    <ng-template #elseCreditNumber>
                        <td>{{viewModel.viewCreditCard.creditNumber}}</td>
                    </ng-template>
                </tr>
                <tr>
                    <th class="is-ValignMiddle">有効期限</th>
                    <ng-container *ngIf="viewModel.paymentModal.registeredCreditCard.canUseCredit === false; else elseMonthYear">
                        <td><span class="text-Danger">{{viewModel.viewCreditCard.creditPeriodMonthYear}}</span></td>
                    </ng-container>
                    <ng-template #elseMonthYear>
                        <td>{{viewModel.viewCreditCard.creditPeriodMonthYear}}</td>
                    </ng-template>
                </tr>
            </tbody>
        </table>

        <!-- 新規クレジットカード -->
        <table class="tbl" *ngSwitchCase="viewModel.PaymentType.NewCreditCard">
            <colgroup>
                <col width="204">
                <col width="">
            </colgroup>
            <tbody>
                <tr>
                    <th class="is-ValignMiddle">お支払い方法</th>
                    <td>新規クレジットカード</td>
                </tr>
                <tr>
                    <th class="is-ValignMiddle">カード番号</th>
                    <td>{{viewModel.viewCreditCard.creditNumber}}</td>
                </tr>
                <tr>
                    <th class="is-ValignMiddle">有効期限</th>
                    <td>{{viewModel.viewCreditCard.creditPeriodMonthYear}}</td>
                </tr>
            </tbody>
        </table>

        <!-- コンビニエンスストア -->
        <table class="tbl" *ngSwitchCase="viewModel.PaymentType.Convenience">
            <colgroup>
                <col width="204">
                <col width="">
            </colgroup>
            <tbody>
                <tr>
                    <th rowspan="2" class="is-ValignMiddle">お支払い方法</th>
                    <td>コンビニエンスストア</td>
                </tr>
                <tr>
                    <td>
                        <span *ngIf="viewModel.viewConvenience.selectedConvenienceType === viewModel.ConvenienceType.SevenEleven">セブンイレブン</span>
                        <span *ngIf="viewModel.viewConvenience.selectedConvenienceType === viewModel.ConvenienceType.FamilyMart">ファミリーマート</span>
                        <span *ngIf="viewModel.viewConvenience.selectedConvenienceType === viewModel.ConvenienceType.Lawson">ローソン</span>
                        <span *ngIf="viewModel.viewConvenience.selectedConvenienceType === viewModel.ConvenienceType.SeicoMart">セイコーマート</span>
                        <p class="mt8 mb0">※1回のご請求につき{{viewModel.viewConvenience.convenienceFee}}円（税込）のお支払い手数料がかかります。</p>
                    </td>
                </tr>
                <tr *ngIf="viewModel.viewConvenience.selectedConvenienceType === viewModel.ConvenienceType.FamilyMart">
                    <th class="is-ValignMiddle">姓名全角カタカナ</th>
                    <td>{{viewModel.viewConvenience.payeeNameKana}}</td>
                </tr>
            </tbody>
        </table>

        <!-- 銀行振込 -->
        <table class="tbl" *ngSwitchCase="viewModel.PaymentType.Bank">
            <colgroup>
                <col width="204">
                <col width="">
            </colgroup>
            <tbody>
                <tr>
                    <th class="is-ValignMiddle">お支払い方法</th>
                    <td>銀行振込</td>
                </tr>
            </tbody>
        </table>
    </ng-container>

    <!-- 支払変更モーダル -->
    <div class="modal" modal [openFlg]="viewModel.paymentModal.isOpen">
        <form #paymentForm="ngForm" novalidate>
            <div class="modal-Dialog">
                <div class="modal-Dialog-Header">
                    <h3 class="modal-Dialog-Header-Title">お支払い方法の変更</h3>
                </div>
                <div class="modal-Dialog-Body pl0 pr0">
                    <ul class="list-Selection">
                        <!-- 登録済みのクレジットカード -->
                        <li class="list-Selection-Item" *ngIf="viewModel.paymentModal.registeredCreditCard.hasRegisteredCreditCard">
                            <input type="radio" name="paymentTypeDummy" class="gtmclick" data-gtmvalue="d_selected_regd_credit" id="paymentTypeDummy1" value="{{viewModel.PaymentType.RegisteredCreditCard}}" [checked]="viewModel.paymentModal.selectedPaymentType === viewModel.PaymentType.RegisteredCreditCard" (change)="viewModel.onChangePaymentMethodRadio(viewModel.PaymentType.RegisteredCreditCard)">
                            <label class="form-Radio" for="paymentTypeDummy1" tabindex="0"></label>
                            <label class="list-Selection-Item-Label" for="paymentTypeDummy1">登録済みのクレジットカード <small *ngIf="viewModel.paymentModal.registeredCreditCard.canUseCredit === false" class="text-Danger">※このカードは有効期限切れです。クレジットカードの情報を更新してください。</small></label>
                            <div class="list-Selection-Item-Content" *ngIf="viewModel.paymentModal.selectedPaymentType === viewModel.PaymentType.RegisteredCreditCard">
                                <table class="tbl-Border mt6">
                                    <colgroup>
                                        <col width="138">
                                        <col width="">
                                    </colgroup>
                                    <tbody>
                                        <ng-container *ngIf="viewModel.paymentModal.registeredCreditCard.canUseCredit === false; else elseBlock">
                                            <tr>
                                                <th>カード番号</th>
                                                <td><span class="text-Danger">{{viewModel.paymentModal.registeredCreditCard.creditNumber}}</span></td>
                                            </tr>
                                            <tr>
                                                <th>有効期限</th>
                                                <td><span class="text-Danger">{{viewModel.paymentModal.registeredCreditCard.creditPeriodMonthYear}}</span></td>
                                            </tr>
                                        </ng-container>
                                        <ng-template #elseBlock>
                                            <tr>
                                                <th>カード番号</th>
                                                <td>{{viewModel.paymentModal.registeredCreditCard.creditNumber}}</td>
                                            </tr>
                                            <tr>
                                                <th>有効期限</th>
                                                <td>{{viewModel.paymentModal.registeredCreditCard.creditPeriodMonthYear}}</td>
                                            </tr>
                                        </ng-template>
                                    </tbody>
                                </table>
                                <ul class="list-Normal">
                                    <li>お支払い方法は次回の請求から適用されます。<br>
                                        既にご請求済みの請求には適用されませんので、あらかじめご了承ください。</li>
                                    <li>1日から15日の間にクレジットカード情報をご変更いただいた場合、当月のご請求から変更後のクレジットカードが適用となる場合がございます。</li>
                                    <li>「クレジットカード」を選択された場合、更新忘れを防止する「自動更新」が設定されます。</li>
                                </ul>
                            </div>
                        </li>
                        <!-- 新規クレジットカード -->
                        <li class="list-Selection-Item">
                            <input type="radio" name="paymentTypeDummy" class="gtmclick" data-gtmvalue="d_selected_new_credit" id="paymentTypeDummy2" value="{{viewModel.PaymentType.NewCreditCard}}" [checked]="viewModel.paymentModal.selectedPaymentType === viewModel.PaymentType.NewCreditCard" (change)="viewModel.onChangePaymentMethodRadio(viewModel.PaymentType.NewCreditCard)">
                            <label class="form-Radio" for="paymentTypeDummy2" tabindex="0"></label>
                            <label class="list-Selection-Item-Label" for="paymentTypeDummy2">新しいクレジットカード </label>
                            <div class="list-Selection-Item-Content" *ngIf="viewModel.paymentModal.selectedPaymentType === viewModel.PaymentType.NewCreditCard">
                                <table class="tbl-Border">
                                    <colgroup>
                                        <col width="138">
                                        <col width="266">
                                        <col width="">
                                    </colgroup>
                                    <tbody>
                                        <tr>
                                            <th>カード番号</th>
                                            <td colspan="2">
                                              <input type="text" class="form-Text" data-gtmvalue="d_input_credit_number" [class.form-Error]="(!viewModel.isValidCreditCard || v_creditNumber.errors && (v_creditNumber.dirty || v_creditNumber.touched))"
                                                     name="creditNumber" [(ngModel)]="viewModel.paymentModal.inputNewCreditCard.creditNumber" maxlength="{{viewModel.creditCardLength}}" (keydown)="viewModel.onKeyDownCreditCard($event)" (input)="viewModel.onChangeCreditCard()"  #v_creditNumber="ngModel" required pattern="{{viewModel.paymentModal.inputNewCreditCard.rNumberPatternWithSpace}}">
                                              <ul class="list-Credit pl10">
                                                <li [class.is-Selected]="viewModel.isVisa"><img src="{{CacheBusting('/Content/images/common/credit-visa.png')}}" alt="VISA"></li>
                                                <li [class.is-Selected]="viewModel.isMasterCard"><img src="{{CacheBusting('/Content/images/common/credit-master.png')}}" alt="Master Card"></li>
                                                <li [class.is-Selected]="viewModel.isJCB"><img src="{{CacheBusting('/Content/images/common/credit-jcb.png')}}" alt="JCB"></li>
                                                <li [class.is-Selected]="viewModel.isDinersClub"><img src="{{CacheBusting('/Content/images/common/credit-diners.png')}}" alt="Diners Club"></li>
                                                <li [class.is-Selected]="viewModel.isAmericanExpress"><img src="{{CacheBusting('/Content/images/common/credit-amex.png')}}" alt="AMERICAN EXPRESS"></li>

                                              </ul>
                                              <div *ngIf="(!viewModel.isValidCreditCard || v_creditNumber.errors && (v_creditNumber.dirty || v_creditNumber.touched))">
                                                <label *ngIf="v_creditNumber.errors != null && v_creditNumber.errors.required" class="form-Error">
                                                  必須項目です
                                                </label>
                                                <label *ngIf="v_creditNumber.errors != null && v_creditNumber.errors.pattern" class="form-Error">
                                                  入力形式を確認してください
                                                </label>
                                                <label *ngIf="!viewModel.isValidCreditCard" class="form-Error">
                                                  有効なクレジットカード番号を入力してください。
                                                </label>
                                              </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>カード名義人</th>
                                            <td colspan="2">
                                                <input type="text" class="form-Text gtmclick" data-gtmvalue="d_input_credit_holder" placeholder="TARO ONAMAE" [class.form-Error]="(v_creditHolder.errors && (v_creditHolder.dirty || v_creditHolder.touched))"
                                                       name="creditHolder" [(ngModel)]="viewModel.paymentModal.inputNewCreditCard.creditHolder" #v_creditHolder="ngModel" required maxByte="50" pattern="{{viewModel.paymentModal.inputNewCreditCard.rCreditHolder}}">
                                                <div *ngIf="(v_creditHolder.errors && (v_creditHolder.dirty || v_creditHolder.touched))">
                                                    <label *ngIf="v_creditHolder.errors != null && v_creditHolder.errors.required" class="form-Error">
                                                        必須項目です
                                                    </label>
                                                    <label *ngIf="v_creditHolder.errors != null && v_creditHolder.errors.maxByte" class="form-Error">
                                                        最大文字数：50
                                                    </label>
                                                    <label *ngIf="v_creditHolder.errors != null && v_creditHolder.errors.pattern" class="form-Error">
                                                        入力形式を確認してください
                                                    </label>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>有効期限</th>
                                            <td colspan="2">
                                                <div class="form-SelectGroup is-Hyphen">
                                                    <span class="form-Select is-Auto">
                                                        <select name="creditPeriodMonth" class="gtmclick" data-gtmvalue="d_input_credit_month" [(ngModel)]="viewModel.paymentModal.inputNewCreditCard.creditPeriodMonth" (change)="viewModel.paymentModal.inputNewCreditCard.creditPeriodMonth=$event.target.value">
                                                            <option value="01">1</option>
                                                            <option value="02">2</option>
                                                            <option value="03">3</option>
                                                            <option value="04">4</option>
                                                            <option value="05">5</option>
                                                            <option value="06">6</option>
                                                            <option value="07">7</option>
                                                            <option value="08">8</option>
                                                            <option value="09">9</option>
                                                            <option value="10">10</option>
                                                            <option value="11">11</option>
                                                            <option value="12">12</option>
                                                        </select>
                                                    </span>
                                                    <span class="form-Select is-Auto">
                                                        <select name="creditPeriodYear" class="gtmclick" data-gtmvalue="d_input_credit_year" [(ngModel)]="viewModel.paymentModal.inputNewCreditCard.creditPeriodYear" (change)="viewModel.paymentModal.inputNewCreditCard.creditPeriodYear=$event.target.value">
                                                            <option *ngFor="let creditPeriodYearItem of viewModel.paymentModal.inputNewCreditCard.creditPeriodYearItems" [value]="creditPeriodYearItem.value">{{creditPeriodYearItem.text}}</option>
                                                        </select>
                                                    </span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>セキュリティコード</th>
                                            <td colspan="2">
                                                <input type="password" class="form-Text is-Small gtmclick" data-gtmvalue="d_input_security_code" placeholder="xxx" [class.form-Error]="(v_securityCode.errors && (v_securityCode.dirty || v_securityCode.touched))"
                                                       name="securityCode" [(ngModel)]="viewModel.paymentModal.inputNewCreditCard.securityCode" #v_securityCode="ngModel" required autocomplete="cc-csc" pattern="{{viewModel.paymentModal.inputNewCreditCard.rNumberPattern}}">
                                                <a href="javascript:void(0)" class="text-Nodeco link-DarkGray ml16" onamaePopup placement="bottom" content="{{viewModel.paymentModal.inputNewCreditCard.securityCodePopup}}">
                                                    <i class="icon icon-QuestionCircle mr4"></i>セキュリティコードについて
                                                </a>
                                                <div *ngIf="(v_securityCode.errors && (v_securityCode.dirty || v_securityCode.touched))">
                                                    <label *ngIf="v_securityCode.errors != null && v_securityCode.errors.required" class="form-Error">
                                                        必須項目です
                                                    </label>
                                                    <label *ngIf="v_securityCode.errors != null && v_securityCode.errors.pattern" class="form-Error">
                                                        入力形式を確認してください
                                                    </label>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <ul class="list-Normal">
                                    <li>お支払い方法は次回の請求から適用されます。<br>
                                        既にご請求済みの請求には適用されませんので、あらかじめご了承ください。</li>
                                    <li>1日から15日の間にクレジットカード情報をご変更いただいた場合、当月のご請求から変更後のクレジットカードが適用となる場合がございます。</li>
                                    <li>「クレジットカード」を選択された場合、更新忘れを防止する「自動更新」が設定されます。</li>
                                </ul>
                            </div>
                        </li>
                        <!-- コンビニエンスストア -->
                        <li *ngIf="viewModel.paymentModal.isDisableConvenience === false" class="list-Selection-Item">
                            <input type="radio" name="paymentTypeDummy" class="gtmclick" data-gtmvalue="d_selected_convenience" id="paymentTypeDummy3" value="{{viewModel.PaymentType.Convenience}}" [disabled]="viewModel.paymentModal.isDisableConvenience" [checked]="viewModel.paymentModal.selectedPaymentType === viewModel.PaymentType.Convenience" (change)="viewModel.onChangePaymentMethodRadio(viewModel.PaymentType.Convenience)">
                            <label class="form-Radio" for="paymentTypeDummy3" tabindex="0"></label>
                            <label class="list-Selection-Item-Label" for="paymentTypeDummy3">コンビニエンスストア </label>
                            <div class="list-Selection-Item-Content" *ngIf="viewModel.paymentModal.selectedPaymentType === viewModel.PaymentType.Convenience">
                                <ul class="list-Checkable mt16 mb0">
                                    <li>
                                        <input type="radio" id="convenienceCode11" name="convenienceCode" class="gtmclick" data-gtmvalue="d_selected_se" value="{{viewModel.ConvenienceType.SevenEleven}}" [checked]="viewModel.paymentModal.inputConvenience.selectedConvenienceType === viewModel.ConvenienceType.SevenEleven" (change)="viewModel.onChangePaymentMethodConvenienceRadio(viewModel.ConvenienceType.SevenEleven)">
                                        <label class="form-Radio is-Middle" for="convenienceCode11" tabindex="0"></label>
                                        <label for="convenienceCode11">セブンイレブン</label>
                                    </li>
                                    <li>
                                        <input type="radio" id="convenienceCode21" name="convenienceCode" class="gtmclick" data-gtmvalue="d_selected_fm" value="{{viewModel.ConvenienceType.FamilyMart}}" [checked]="viewModel.paymentModal.inputConvenience.selectedConvenienceType === viewModel.ConvenienceType.FamilyMart" (change)="viewModel.onChangePaymentMethodConvenienceRadio(viewModel.ConvenienceType.FamilyMart)">
                                        <label class="form-Radio is-Middle" for="convenienceCode21" tabindex="0"></label>
                                        <label for="convenienceCode21">ファミリーマート</label>
                                        <ng-container *ngIf="viewModel.paymentModal.inputConvenience.selectedConvenienceType === viewModel.ConvenienceType.FamilyMart">
                                            <input type="text" class="form-Text is-Small ml12 gtmclick" data-gtmvalue="d_input_name_kana" placeholder="姓名全角カタカナ" [class.form-Error]="(v_payeeNameKana.errors && (v_payeeNameKana.dirty || v_payeeNameKana.touched))"
                                                   name="payeeNameKana" [(ngModel)]="viewModel.paymentModal.inputConvenience.payeeNameKana" #v_payeeNameKana="ngModel" required max="14" pattern="{{viewModel.paymentModal.inputConvenience.rFullWidthKatakanaPattern}}">
                                            <div *ngIf="(v_payeeNameKana.errors && (v_payeeNameKana.dirty || v_payeeNameKana.touched))">
                                                <label *ngIf="v_payeeNameKana.errors != null && v_payeeNameKana.errors.required" class="form-Error">
                                                    必須項目です
                                                </label>
                                                <label *ngIf="v_payeeNameKana.errors != null && v_payeeNameKana.errors.max" class="form-Error">
                                                    最大文字数：14
                                                </label>
                                                <label *ngIf="v_payeeNameKana.errors != null && v_payeeNameKana.errors.pattern" class="form-Error">
                                                    入力形式を確認してください
                                                </label>
                                            </div>
                                        </ng-container>
                                    </li>
                                    <li>
                                        <input type="radio" id="convenienceCode31" name="convenienceCode" class="gtmclick" data-gtmvalue="d_selected_la" value="{{viewModel.ConvenienceType.Lawson}}" [checked]="viewModel.paymentModal.inputConvenience.selectedConvenienceType === viewModel.ConvenienceType.Lawson" (change)="viewModel.onChangePaymentMethodConvenienceRadio(viewModel.ConvenienceType.Lawson)">
                                        <label class="form-Radio is-Middle" for="convenienceCode31" tabindex="0"></label>
                                        <label for="convenienceCode31">ローソン</label>
                                    </li>
                                    <li>
                                        <input type="radio" id="convenienceCode32" name="convenienceCode" class="gtmclick" data-gtmvalue="d_selected_sm" value="{{viewModel.ConvenienceType.SeicoMart}}" [checked]="viewModel.paymentModal.inputConvenience.selectedConvenienceType === viewModel.ConvenienceType.SeicoMart" (change)="viewModel.onChangePaymentMethodConvenienceRadio(viewModel.ConvenienceType.SeicoMart)">
                                        <label class="form-Radio is-Middle" for="convenienceCode32" tabindex="0"></label>
                                        <label for="convenienceCode32">セイコーマート</label>
                                    </li>
                                </ul>
                                <ul class="list-Normal text-Small mb0 mt24">
                                    <li>ご請求内容詳細につきましてはご請求案内メールにてお知らせいたします。紙面による請求書発行はございません。</li>
                                    <li>コンビニエンスストアお支払い手数料として、1回のご請求につき165円（税込）を別途申し受けます。</li>
                                    <li>コンビニエンスストア支払いを選択されましても銀行振込でのお支払いが可能です。<br>
                                        ただし、銀行振込でお支払いの場合でも165円（税込）の手数料はお支払いいただくものとなります。</li>
                                    <li>お支払い方法は次回の請求から適用されます。<br>
                                        既にご請求済みの請求には適用されませんのであらかじめご了承ください。</li>
                                </ul>
                            </div>
                        </li>
                        <!-- 銀行振込 -->
                        <li *ngIf="viewModel.paymentModal.isDisableBank === false" class="list-Selection-Item">
                            <input type="radio" name="paymentTypeDummy" class="gtmclick" data-gtmvalue="d_selected_bank" id="paymentTypeDummy4" value="{{viewModel.PaymentType.Bank}}" [disabled]="viewModel.paymentModal.isDisableBank" [checked]="viewModel.paymentModal.selectedPaymentType === viewModel.PaymentType.Bank" (change)="viewModel.onChangePaymentMethodRadio(viewModel.PaymentType.Bank)">
                            <label class="form-Radio" for="paymentTypeDummy4" tabindex="0"></label>
                            <label class="list-Selection-Item-Label" for="paymentTypeDummy4">銀行振込 </label>
                            <div class="list-Selection-Item-Content" *ngIf="viewModel.paymentModal.selectedPaymentType === viewModel.PaymentType.Bank">
                                <ul class="list-Normal">
                                    <li>ご請求案内メールにてお知らせいたします。紙面による請求書発行はございません。</li>
                                    <li>お支払い方法は次回の請求から適用されます。<br>
                                        既にご請求済みの請求には適用されませんので、あらかじめご了承ください。</li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="modal-Dialog-Footer">
                    <ul class="list-Btn is-Right mb0">
                        <li><button type="button" class="btn gtmclick" data-gtmvalue="d_cancel" (click)="viewModel.onClickPaymentMethodModalCancelButton()">キャンセル</button></li>
                        <li><button type="button" class="btn is-Primary gtmclick" data-gtmvalue="d_updated" (click)="viewModel.onClickPaymentMethodModalUpdateButton(paymentForm)">更新する</button></li>
                    </ul>
                </div>
                <button type="button" class="modal-Close" (click)="viewModel.onClickPaymentMethodModalCloseButton()"><i class="icon icon-Clear gtmclick" data-gtmvalue="d_close" aria-label="閉じる"></i></button>
            </div>
        </form>
    </div>
</ng-container>
