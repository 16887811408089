<header class="header">
    <div class="headerContainer">
        <div class="headerLogoWrap">
            <a *ngIf="viewModel.loginName; else elseBlock" class="headerLogoLink gtmclick" href="javascript:void(0)" [onamaeRouterLink]="'/sp/top'" data-gtmvalue="header_top">
                <div class="headerLogo">ドメインとるならお名前.com by GMO</div>
            </a>
            <ng-template #elseBlock>
                <div class="headerLogoLink">
                    <div class="headerLogo">ドメインとるならお名前.com by GMO</div>
                </div>
            </ng-template>
        </div>
        <span class="headerHeading">お名前.com Navi</span>
        <ul class="headerFunction">
            <li class="headerFunction_item">
                <div class="headerFunctionButton is-support" (click)="viewModel.onShowGuideModal()" role="button">
                    <div class="headerFunctionButtonIcon">サポート</div>
                </div>
            </li>
            <li *ngIf="viewModel.isShowHeaderMenu" class="headerFunction_item">
                <div class="headerFunctionButton is-menu" (click)="viewModel.onOpenHeaderMenu()" role="button">
                    <div class="headerFunctionButtonIcon">メニュー</div>
                </div>
            </li>
        </ul>
        <nav *ngIf="viewModel.isShowHeaderMenu" class="navi" [class.is-active]="viewModel.isOpenHeaderMenu">
            <div class="naviOverlay" (click)="viewModel.onCloseHeaderMenu()" ></div>
            <div class="naviContents">
                <div class="naviHeader">
                    <p class="naviHeaderHeading">メインメニュー</p>
                    <p *ngIf="viewModel.loginName" class="naviHeaderId">
                        お名前ID<br>
                        {{ viewModel.loginName }}
                    </p>
                    <div class="naviHeaderButton" (click)="viewModel.onCloseHeaderMenu()" role="button">
                        <div class="naviHeaderButtonIcon">閉じる</div>
                    </div>
                </div>
                <ul class="naviList">
                    <li class="naviList_item" (click)="viewModel.onCloseHeaderMenu()" >
                        <a class="naviListHeading gtmclick" data-gtmvalue="rightmenu_top" href="javascript:void(0)" [onamaeRouterLink]="'/sp/top'">TOP<span class="icon icon-ArrowRight"></span></a>
                    </li>
                    <li class="naviList_item">
                        <p class="naviListSubHeading">サービス一覧</p>
                        <ul class="naviSubList">
                            <li class="naviSubList_item" (click)="viewModel.onCloseHeaderMenu()" >
                                <a class="naviSubListHeading gtmclick" data-gtmvalue="rightmenu_domain_pd_list" href="javascript:void(0)" [onamaeRouterLink]="'/domain'">ドメイン<span class="icon icon-ArrowRight"></span></a>
                            </li>
                            <li class="naviSubList_item" (click)="viewModel.onCloseHeaderMenu()" >
                                <a class="naviSubListHeading gtmclick" data-gtmvalue="rightmenu_rs_pd_list" href="javascript:void(0)" [onamaeRouterLink]="'/rs'">サーバー<span class="icon icon-ArrowRight"></span></a>
                            </li>
                            <li *ngIf="viewModel.hostingContractModels.length > 0" class="naviSubList_item" [class.is-hidden]="viewModel.isShowAllHostingContract">
                                <a class="naviSubListHeading" href="javascript:void(0)" (click)="viewModel.onShowAllHostingContract()">全て表示<span class="icon icon-Plus2"></span></a>
                            </li>
                            <li *ngFor="let model of viewModel.hostingContractModels" class="naviSubList_item" [class.is-hidden]="!viewModel.isShowAllHostingContract" (click)="viewModel.onCloseHeaderMenu()">
                                <a class="naviSubListHeading gtmclick" href="javascript:void(0)"  [onamaeRouterLink]="model.Link" [attr.data-gtmvalue]="model.GtmValue">{{ model.Label }}<span class="icon icon-ArrowRight"></span></a>
                            </li>
                        </ul>
                    </li>
                    <li class="naviList_item" (click)="viewModel.onCloseHeaderMenu()" >
                        <a class="naviListHeading gtmclick" data-gtmvalue="rightmenu_renew" href="javascript:void(0)" [onamaeRouterLink]="'/domain/setting/renew/list'">ドメイン更新<span class="icon icon-ArrowRight"></span></a></li>
                    <li class="naviList_item" (click)="viewModel.onCloseHeaderMenu()" >
                        <a class="naviListHeading gtmclick" data-gtmvalue="rightmenu_setting" href="javascript:void(0)" [onamaeRouterLink]="'/domain/setting'">ドメインお手続き一覧<span class="icon icon-ArrowRight"></span></a></li>
                    <li class="naviList_item" (click)="viewModel.onCloseHeaderMenu()" >
                        <a class="naviListHeading gtmclick" data-gtmvalue="rightmenu_serviceregist" href="javascript:void(0)" [onamaeRouterLink]="'/domain/service'">サービス新規お申込み<span class="icon icon-ArrowRight"></span></a>
                    </li>
                    <li class="naviList_item">
                        <a class="naviListHeading" [class.is-active]="viewModel.isOpenAccountMenu" href="javascript:void(0)" (click)="viewModel.onToggleAccountMenu()">会員情報<i [class]="(viewModel.isOpenAccountMenu ? 'icon icon-Minus' : 'icon icon-Plus2')"></i></a>
                        <div class="naviSubBlock" [class.is-active]="viewModel.isOpenAccountMenu">
                            <ul class="naviSubList" >
                                <li class="naviSubList_item" (click)="viewModel.onCloseHeaderMenu()">
                                    <a class="naviSubListHeading gtmclick" data-gtmvalue="rightmenu_accountinfo" href="javascript:void(0)" [onamaeRouterLink]="'/account/detail/edit'">会員情報の確認/変更<span class="icon icon-ArrowRight"></span></a>
                                </li>
                                <li class="naviSubList_item" (click)="viewModel.onCloseHeaderMenu()">
                                    <a class="naviSubListHeading gtmclick" data-gtmvalue="rightmenu_pwinfo" href="javascript:void(0)" [onamaeRouterLink]="'/account/password'">パスワードの変更<span class="icon icon-ArrowRight"></span></a>
                                </li>
                                <li class="naviSubList_item" (click)="viewModel.onCloseHeaderMenu()">
                                    <a class="naviSubListHeading gtmclick" data-gtmvalue="rightmenu_loginsecurity" href="javascript:void(0)" [onamaeRouterLink]="'/account/loginsecurity'">ログインセキュリティ<span class="icon icon-ArrowRight"></span></a>
                                </li>
                                <li class="naviSubList_item" (click)="viewModel.onCloseHeaderMenu()">
                                    <a class="naviSubListHeading gtmclick" data-gtmvalue="rightmenu_loginsecurity" href="javascript:void(0)" [onamaeRouterLink]="'/account/contact'">コンタクト情報の変更<span class="icon icon-ArrowRight"></span></a>
                                </li>
                                <!--<li class="naviSubList_item" (click)="viewModel.onCloseHeaderMenu()">
                                    <a class="naviSubListHeading" href="javascript:void(0)" [onamaeRouterLink]="'/account/autodiag'">ネットde診断の<br>自動診断機能の設定<span class="icon icon-ArrowRight"></span></a>
                                </li>-->
                            </ul>
                        </div>
                    </li>
                    <li *ngIf="!viewModel.isViewHiddenInvoice" class="naviList_item">
                        <a class="naviListHeading" [class.is-active]="viewModel.isOpenPaymentMenu" href="javascript:void(0)" (click)="viewModel.onTogglePaymentMenu()">お支払い・ご請求情報<i [class]="(viewModel.isOpenPaymentMenu ? 'icon icon-Minus' : 'icon icon-Plus2')"></i></a>
                        <div class="naviSubBlock" [class.is-active]="viewModel.isOpenPaymentMenu">
                            <p class="naviListSubHeading">ドメイン</p>
                            <ul class="naviSubList">
                                <li *ngIf="viewModel.isShowDomainPayment" class="naviSubList_item" (click)="viewModel.onCloseHeaderMenu()">
                                    <a class="naviSubListHeading gtmclick"  data-gtmvalue="rightmenu_paymentinfo_domain" href="javascript:void(0)" [onamaeRouterLink]="'/payment/detail/domain'">お支払い情報<span class="icon icon-ArrowRight"></span></a>
                                </li>
                                <li class="naviSubList_item" (click)="viewModel.onCloseHeaderMenu()" >
                                    <a class="naviSubListHeading gtmclick" data-gtmvalue="rightmenu_invoicelist_domain" href="javascript:void(0)" [onamaeRouterLink]="'/payment/invoice/list/domain'">ご請求情報<span class="icon icon-ArrowRight"></span></a>
                                </li>
                            </ul>
                            <p class="naviListSubHeading">サーバー</p>
                            <ul class="naviSubList">
                                <li class="naviSubList_item" (click)="viewModel.onCloseHeaderMenu()">
                                    <a class="naviSubListHeading gtmclick"  data-gtmvalue="rightmenu_paymentinfo_hosting" href="javascript:void(0)" [onamaeRouterLink]="'/payment/detail/hosting'">お支払い情報<span class="icon icon-ArrowRight"></span></a>
                                </li>
                                <li class="naviSubList_item"  (click)="viewModel.onCloseHeaderMenu()">
                                    <a class="naviSubListHeading gtmclick" data-gtmvalue="rightmenu_invoicelist_hosting" href="javascript:void(0)" [onamaeRouterLink]="'/payment/invoice/list/hosting'">ご請求情報<span class="icon icon-ArrowRight"></span></a>
                                </li>
                                <li class="naviSubList_item"  (click)="viewModel.onCloseHeaderMenu()">
                                    <a class="naviSubListHeading gtmclick" data-gtmvalue="rightmenu_onetimepaymentsinfo_hosting" href="javascript:void(0)" [onamaeRouterLink]="'/payment/lumpsum/list'">まとめ払い設定<span class="icon icon-ArrowRight"></span></a>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li class="naviList_item" (click)="viewModel.onCloseHeaderMenu()" >
                        <a class="naviListHeading gtmclick" data-gtmvalue="newnavi_top_header_allmenu_webacademy_bookmark" href="javascript:void(0)" [onamaeRouterLink]="'/webacademy/bookmark'">WEB学園お気に入り一覧<span class="icon icon-ArrowRight"></span></a>
                    </li>
                    <li class="naviList_item">
                        <div class="naviListFunction">
                            <a class="naviListFunctionHeading" href="javascript:void(0)" (click)="viewModel.onClickChangePcMode()">PC版を表示</a>
                            <a class="naviListFunctionHeading gtmclick" data-gtmvalue="rightmenu_logout" href="javascript:void(0)" (click)="viewModel.logout()">ログアウト</a>
                        </div>
                    </li>
                </ul>
            </div>
        </nav>
    </div>
</header>
<div sp-modal class="modal is-visible" [openFlg]="viewModel.isShowGuideModal">
    <div class="modalContent">
        <button type="button" class="modalClose" (click)="viewModel.onHideGuideModal()">
            <span class="icon icon-Clear" aria-label="閉じる"></span>
        </button>
        <div class="modalHeader bgColorGray">
            <h2 class="modalHeading1st">何かお困りですか？</h2>
            <form (ngSubmit)="viewModel.onSearchGuideButtonClicked(guideForm)" #guideForm="ngForm" novalidate>
                <div class="formField">
                    <input type="text" class="formField_input" name="guideWord" placeholder="何かお困りですか？"
                    [(ngModel)]="viewModel.guideWord" #guideWord="ngModel" required
                    pattern="{{viewModel.rGuideFormPattern}}">
                    <button type="submit" class="formFieldSearchBtn" [disabled]="guideForm.invalid"><span class="icon icon-Magnifier" aria-label="検索"></span></button>       
                </div>
                <div *ngIf="(guideWord.errors && (guideWord.dirty || guideWord.touched)) && guideWord.errors != null && guideWord.errors.pattern">
                    <p class="formNote">※入力形式を確認してください</p>
                </div>
            </form>
        </div>
        <div class="modalBody">
            <ul class="modalList">
                <li class="modalList_item">
                    <p class="modalText">Naviの操作方法などを知りたい場合</p>
                    <a href="javascript:void(0)" (click)="viewModel.openUrl('https://www.onamae.com/guide/')" class="button button-secondary button-link gtmclick" data-gtmvalue="header_guide">お名前.com Naviガイド<span class="icon icon-ArrowRight"></span></a>
                </li>
                <li class="modalList_item">
                    <p class="modalText">その他のことでお困りの場合</p>
                    <a href="javascript:void(0)" (click)="viewModel.openUrl('https://www.onamae.com/help/')" class="button button-secondary button-link gtmclick" data-gtmvalue="header_help">ヘルプ<span class="icon icon-ArrowRight"></span></a>
                </li>
            </ul>
        </div>
    </div>
</div>

<div *ngIf="viewModel.isLoading" class="loading" style="display:block">
    <div class="loading-Loader">
        <div class="loading-Loader-Blade"></div>
        <div class="loading-Loader-Blade"></div>
        <div class="loading-Loader-Blade"></div>
        <div class="loading-Loader-Blade"></div>
        <div class="loading-Loader-Blade"></div>
        <div class="loading-Loader-Blade"></div>
        <div class="loading-Loader-Blade"></div>
        <div class="loading-Loader-Blade"></div>
        <div class="loading-Loader-Blade"></div>
        <div class="loading-Loader-Blade"></div>
        <div class="loading-Loader-Blade"></div>
        <div class="loading-Loader-Blade"></div>
    </div>
</div>
