import {DomainTagType, DomainTagData} from "./domain_tag";

export class DomainTagBuilder {
    private readonly _prefix: string;
    private readonly _tagSeparator: string;
    private readonly _tagsBefore: string;

    private _tags: DomainTagData[] = [];

    public constructor(
        prefix: string = "",
        tagSeparator: string = "_",
        tagsBefore: string = "_") {
        this._prefix = prefix;
        this._tagSeparator = tagSeparator;
        this._tagsBefore = tagsBefore;
    }

    public addTag(type: DomainTagType, priority?: number): void {
        if (this._tags.some(x => x.isMatchType(type))) {
            return;
        }

        const createdTag = DomainTagData.create(type, priority);
        this._tags.push(createdTag);
    }

    public removeTag(type: DomainTagType): void {
        this._tags = this._tags.filter(x => !x.isMatchType(type));
    }

    public build(): string {
        if (this._tags.length === 0) {
            return this._prefix;
        }

        const sortedTags = this._tags.slice().sort((l, r) => l.priority - r.priority);
        var suffix = sortedTags.map(x => x.tagName).join(this._tagSeparator);

        return this._prefix + this._tagsBefore + suffix;
    }
}