import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
    selector: '[top-scroll]'
})
export class SpTopScrollDirective {
    private readonly BUTTON_ID: string = "sp-top-scroll-directive";

    constructor(private elementRef: ElementRef) {
        // スクロール読み込み時のみ表示させる「上へ戻る」ボタン
        const buttonPageTop = document.createElement("div");
        buttonPageTop.id = this.BUTTON_ID;
        buttonPageTop.classList.add("buttonPagetop");
        buttonPageTop.innerHTML = `<span class="icon icon-ArrowUp"></span>`;
        buttonPageTop.style.display = "none";
        this.elementRef.nativeElement.after(buttonPageTop);

        var pos = 0;
        $(window).on('scroll', function () {
            const button = $("#sp-top-scroll-directive");
            if (!button) {
                return;
            }

            if ($(this).scrollTop() < pos && $(this).scrollTop() != 0) {
                button.on('click', function () { window.scrollTo(0, 0) })
                button.fadeIn();
            } else {
                button.fadeOut();
            }
            pos = $(this).scrollTop();
        });
    }
}
