import { Store } from '@ngrx/store';

export class StoreService {

    private _store: Store;

    public constructor(store: Store) {
        this._store = store;
    }

    public get store(): Store {
        return this._store;
    }

}
