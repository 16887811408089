export class DropDownItemsDefine {
    public static readonly roleItemList: { value: string, text: string }[] = [
        { value: 'I', text: '個人' },
        { value: 'R', text: '組織' }
    ];

    public static readonly ccItemList: { value: string, text: string }[] = [
        { value: 'JP', text: 'Japan' },
        { value: 'US', text: 'United States' },
        { value: 'AF', text: 'Afghanistan' },
        { value: 'AX', text: 'Aland Islands' },
        { value: 'AL', text: 'Albania' },
        { value: 'DZ', text: 'Algeria' },
        { value: 'AS', text: 'American Samoa' },
        { value: 'AD', text: 'Andorra' },
        { value: 'AO', text: 'Angola' },
        { value: 'AI', text: 'Anguilla' },
        { value: 'AQ', text: 'Antarctica' },
        { value: 'AG', text: 'Antigua and Barbuda' },
        { value: 'AR', text: 'Argentina' },
        { value: 'AM', text: 'Armenia' },
        { value: 'AW', text: 'Aruba' },
        { value: 'AU', text: 'Australia' },
        { value: 'AT', text: 'Austria' },
        { value: 'AZ', text: 'Azerbaijan' },
        { value: 'BS', text: 'Bahamas' },
        { value: 'BH', text: 'Bahrain' },
        { value: 'BD', text: 'Bangladesh' },
        { value: 'BB', text: 'Barbados' },
        { value: 'BY', text: 'Belarus' },
        { value: 'BE', text: 'Belgium' },
        { value: 'BZ', text: 'Belize' },
        { value: 'BJ', text: 'Benin' },
        { value: 'BM', text: 'Bermuda' },
        { value: 'BT', text: 'Bhutan' },
        { value: 'BO', text: 'Bolivia, Plurinational State of' },
        { value: 'BQ', text: 'Bonaire, Saint Eustatius and Saba' },
        { value: 'BA', text: 'Bosnia and Herzegovina' },
        { value: 'BW', text: 'Botswana' },
        { value: 'BV', text: 'Bouvet Island' },
        { value: 'BR', text: 'Brazil' },
        { value: 'IO', text: 'British Indian Ocean Territory' },
        { value: 'BN', text: 'Brunei Darussalam' },
        { value: 'BG', text: 'Bulgaria' },
        { value: 'BF', text: 'Burkina Faso' },
        { value: 'BI', text: 'Burundi' },
        { value: 'KH', text: 'Cambodia' },
        { value: 'CM', text: 'Cameroon' },
        { value: 'CA', text: 'Canada' },
        { value: 'CV', text: 'Cape Verde' },
        { value: 'KY', text: 'Cayman Islands' },
        { value: 'CF', text: 'Central African Republic' },
        { value: 'TD', text: 'Chad' },
        { value: 'CL', text: 'Chile' },
        { value: 'CN', text: 'China' },
        { value: 'CX', text: 'Christmas Island' },
        { value: 'CC', text: 'Cocos (Keeling) Islands' },
        { value: 'CO', text: 'Colombia' },
        { value: 'KM', text: 'Comoros' },
        { value: 'CG', text: 'Congo' },
        { value: 'CD', text: 'Congo, the Democratic Republic of the' },
        { value: 'CK', text: 'Cook Islands' },
        { value: 'CR', text: 'Costa Rica' },
        { value: 'CI', text: "Cote d'Ivoire" },
        { value: 'HR', text: 'Croatia' },
        { value: 'CU', text: 'Cuba' },
        { value: 'CW', text: 'Curacao' },
        { value: 'CY', text: 'Cyprus' },
        { value: 'CZ', text: 'Czech Republic' },
        { value: 'DK', text: 'Denmark' },
        { value: 'DJ', text: 'Djibouti' },
        { value: 'DM', text: 'Dominica' },
        { value: 'DO', text: 'Dominican Republic' },
        { value: 'EC', text: 'Ecuador' },
        { value: 'EG', text: 'Egypt' },
        { value: 'SV', text: 'El Salvador' },
        { value: 'GQ', text: 'Equatorial Guinea' },
        { value: 'ER', text: 'Eritrea' },
        { value: 'EE', text: 'Estonia' },
        { value: 'ET', text: 'Ethiopia' },
        { value: 'FK', text: 'Falkland Islands (Malvinas)' },
        { value: 'FO', text: 'Faroe Islands' },
        { value: 'FJ', text: 'Fiji' },
        { value: 'FI', text: 'Finland' },
        { value: 'FR', text: 'France' },
        { value: 'GF', text: 'French Guiana' },
        { value: 'PF', text: 'French Polynesia' },
        { value: 'TF', text: 'French Southern Territories' },
        { value: 'GA', text: 'Gabon' },
        { value: 'GM', text: 'Gambia' },
        { value: 'GE', text: 'Georgia' },
        { value: 'DE', text: 'Germany' },
        { value: 'GH', text: 'Ghana' },
        { value: 'GI', text: 'Gibraltar' },
        { value: 'GR', text: 'Greece' },
        { value: 'GL', text: 'Greenland' },
        { value: 'GD', text: 'Grenada' },
        { value: 'GP', text: 'Guadeloupe' },
        { value: 'GU', text: 'Guam' },
        { value: 'GT', text: 'Guatemala' },
        { value: 'GG', text: 'Guernsey' },
        { value: 'GN', text: 'Guinea' },
        { value: 'GW', text: 'Guinea-Bissau' },
        { value: 'GY', text: 'Guyana' },
        { value: 'HT', text: 'Haiti' },
        { value: 'HM', text: 'Heard Island and McDonald Islands' },
        { value: 'VA', text: 'Holy See (Vatican City State)' },
        { value: 'HN', text: 'Honduras' },
        { value: 'HK', text: 'Hong Kong' },
        { value: 'HU', text: 'Hungary' },
        { value: 'IS', text: 'Iceland' },
        { value: 'IN', text: 'India' },
        { value: 'ID', text: 'Indonesia' },
        { value: 'IR', text: 'Iran, Islamic Republic of' },
        { value: 'IQ', text: 'Iraq' },
        { value: 'IE', text: 'Ireland' },
        { value: 'IM', text: 'Isle of Man' },
        { value: 'IL', text: 'Israel' },
        { value: 'IT', text: 'Italy' },
        { value: 'JM', text: 'Jamaica' },
        { value: 'JE', text: 'Jersey' },
        { value: 'JO', text: 'Jordan' },
        { value: 'KZ', text: 'Kazakhstan' },
        { value: 'KE', text: 'Kenya' },
        { value: 'KI', text: 'Kiribati' },
        { value: 'KP', text: "Korea, Democratic People's Republic of" },
        { value: 'KR', text: 'Korea, Republic of' },
        { value: 'KW', text: 'Kuwait' },
        { value: 'KG', text: 'Kyrgyzstan' },
        { value: 'LA', text: "Lao People's Democratic Republic" },
        { value: 'LV', text: 'Latvia' },
        { value: 'LB', text: 'Lebanon' },
        { value: 'LS', text: 'Lesotho' },
        { value: 'LR', text: 'Liberia' },
        { value: 'LY', text: 'Libya' },
        { value: 'LI', text: 'Liechtenstein' },
        { value: 'LT', text: 'Lithuania' },
        { value: 'LU', text: 'Luxembourg' },
        { value: 'MO', text: 'Macao' },
        { value: 'MK', text: 'Macedonia, the former Yugoslav Republic of' },
        { value: 'MG', text: 'Madagascar' },
        { value: 'MW', text: 'Malawi' },
        { value: 'MY', text: 'Malaysia' },
        { value: 'MV', text: 'Maldives' },
        { value: 'ML', text: 'Mali' },
        { value: 'MT', text: 'Malta' },
        { value: 'MH', text: 'Marshall Islands' },
        { value: 'MQ', text: 'Martinique' },
        { value: 'MR', text: 'Mauritania' },
        { value: 'MU', text: 'Mauritius' },
        { value: 'YT', text: 'Mayotte' },
        { value: 'MX', text: 'Mexico' },
        { value: 'FM', text: 'Micronesia, Federated States of' },
        { value: 'MD', text: 'Moldova, Republic of' },
        { value: 'MC', text: 'Monaco' },
        { value: 'MN', text: 'Mongolia' },
        { value: 'ME', text: 'Montenegro' },
        { value: 'MS', text: 'Montserrat' },
        { value: 'MA', text: 'Morocco' },
        { value: 'MZ', text: 'Mozambique' },
        { value: 'MM', text: 'Myanmar' },
        { value: 'NA', text: 'Namibia' },
        { value: 'NR', text: 'Nauru' },
        { value: 'NP', text: 'Nepal' },
        { value: 'NL', text: 'Netherlands' },
        { value: 'NC', text: 'New Caledonia' },
        { value: 'NZ', text: 'New Zealand' },
        { value: 'NI', text: 'Nicaragua' },
        { value: 'NE', text: 'Niger' },
        { value: 'NG', text: 'Nigeria' },
        { value: 'NU', text: 'Niue' },
        { value: 'NF', text: 'Norfolk Island' },
        { value: 'MP', text: 'Northern Mariana Islands' },
        { value: 'NO', text: 'Norway' },
        { value: 'OM', text: 'Oman' },
        { value: 'PK', text: 'Pakistan' },
        { value: 'PW', text: 'Palau' },
        { value: 'PS', text: 'Palestinian Territory, Occupied' },
        { value: 'PA', text: 'Panama' },
        { value: 'PG', text: 'Papua New Guinea' },
        { value: 'PY', text: 'Paraguay' },
        { value: 'PE', text: 'Peru' },
        { value: 'PH', text: 'Philippines' },
        { value: 'PN', text: 'Pitcairn' },
        { value: 'PL', text: 'Poland' },
        { value: 'PT', text: 'Portugal' },
        { value: 'PR', text: 'Puerto Rico' },
        { value: 'QA', text: 'Qatar' },
        { value: 'RE', text: 'Reunion' },
        { value: 'RO', text: 'Romania' },
        { value: 'RU', text: 'Russian Federation' },
        { value: 'RW', text: 'Rwanda' },
        { value: 'BL', text: 'Saint Barthelemy' },
        { value: 'SH', text: 'Saint Helena, Ascension and Tristan da Cunha' },
        { value: 'KN', text: 'Saint Kitts and Nevis' },
        { value: 'LC', text: 'Saint Lucia' },
        { value: 'MF', text: 'Saint Martin (French part)' },
        { value: 'PM', text: 'Saint Pierre and Miquelon' },
        { value: 'VC', text: 'Saint Vincent and the Grenadines' },
        { value: 'WS', text: 'Samoa' },
        { value: 'SM', text: 'San Marino' },
        { value: 'ST', text: 'Sao Tome and Principe' },
        { value: 'SA', text: 'Saudi Arabia' },
        { value: 'SN', text: 'Senegal' },
        { value: 'RS', text: 'Serbia' },
        { value: 'SC', text: 'Seychelles' },
        { value: 'SL', text: 'Sierra Leone' },
        { value: 'SG', text: 'Singapore' },
        { value: 'SX', text: 'Sint Maarten (Dutch part)' },
        { value: 'SK', text: 'Slovakia' },
        { value: 'SI', text: 'Slovenia' },
        { value: 'SB', text: 'Solomon Islands' },
        { value: 'SO', text: 'Somalia' },
        { value: 'ZA', text: 'South Africa' },
        { value: 'GS', text: 'South Georgia and the South Sandwich Islands' },
        { value: 'SS', text: 'South Sudan' },
        { value: 'ES', text: 'Spain' },
        { value: 'LK', text: 'Sri Lanka' },
        { value: 'SD', text: 'Sudan' },
        { value: 'SR', text: 'Suriname' },
        { value: 'SJ', text: 'Svalbard and Jan Mayen' },
        { value: 'SZ', text: 'Swaziland' },
        { value: 'SE', text: 'Sweden' },
        { value: 'CH', text: 'Switzerland' },
        { value: 'SY', text: 'Syrian Arab Republic' },
        { value: 'TW', text: 'Taiwan, Province of China' },
        { value: 'TJ', text: 'Tajikistan' },
        { value: 'TZ', text: 'Tanzania, United Republic of' },
        { value: 'TH', text: 'Thailand' },
        { value: 'TL', text: 'Timor-Leste' },
        { value: 'TG', text: 'Togo' },
        { value: 'TK', text: 'Tokelau' },
        { value: 'TO', text: 'Tonga' },
        { value: 'TT', text: 'Trinidad and Tobago' },
        { value: 'TN', text: 'Tunisia' },
        { value: 'TR', text: 'Turkey' },
        { value: 'TM', text: 'Turkmenistan' },
        { value: 'TC', text: 'Turks and Caicos Islands' },
        { value: 'TV', text: 'Tuvalu' },
        { value: 'UG', text: 'Uganda' },
        { value: 'UA', text: 'Ukraine' },
        { value: 'AE', text: 'United Arab Emirates' },
        { value: 'GB', text: 'United Kingdom' },
        { value: 'UM', text: 'United States Minor Outlying Islands' },
        { value: 'UY', text: 'Uruguay' },
        { value: 'UZ', text: 'Uzbekistan' },
        { value: 'VU', text: 'Vanuatu' },
        { value: 'VE', text: 'Venezuela, Bolivarian Republic of' },
        { value: 'VN', text: 'Viet Nam' },
        { value: 'VG', text: 'Virgin Islands, British' },
        { value: 'VI', text: 'Virgin Islands, U.S.' },
        { value: 'WF', text: 'Wallis and Futuna' },
        { value: 'EH', text: 'Western Sahara' },
        { value: 'YE', text: 'Yemen' },
        { value: 'ZM', text: 'Zambia' },
        { value: 'ZW', text: 'Zimbabwe' }
    ];

    public static readonly spJpItemList: { value: string, text: string }[] = [
        { value: '01', text: '北海道' },
        { value: '02', text: '青森県' },
        { value: '03', text: '岩手県' },
        { value: '04', text: '宮城県' },
        { value: '05', text: '秋田県' },
        { value: '06', text: '山形県' },
        { value: '07', text: '福島県' },
        { value: '08', text: '茨城県' },
        { value: '09', text: '栃木県' },
        { value: '10', text: '群馬県' },
        { value: '11', text: '埼玉県' },
        { value: '12', text: '千葉県' },
        { value: '13', text: '東京都' },
        { value: '14', text: '神奈川県' },
        { value: '15', text: '新潟県' },
        { value: '16', text: '富山県' },
        { value: '17', text: '石川県' },
        { value: '18', text: '福井県' },
        { value: '19', text: '山梨県' },
        { value: '20', text: '長野県' },
        { value: '21', text: '岐阜県' },
        { value: '22', text: '静岡県' },
        { value: '23', text: '愛知県' },
        { value: '24', text: '三重県' },
        { value: '25', text: '滋賀県' },
        { value: '26', text: '京都府' },
        { value: '27', text: '大阪府' },
        { value: '28', text: '兵庫県' },
        { value: '29', text: '奈良県' },
        { value: '30', text: '和歌山県' },
        { value: '31', text: '鳥取県' },
        { value: '32', text: '島根県' },
        { value: '33', text: '岡山県' },
        { value: '34', text: '広島県' },
        { value: '35', text: '山口県' },
        { value: '36', text: '徳島県' },
        { value: '37', text: '香川県' },
        { value: '38', text: '愛媛県' },
        { value: '39', text: '高知県' },
        { value: '40', text: '福岡県' },
        { value: '41', text: '佐賀県' },
        { value: '42', text: '長崎県' },
        { value: '43', text: '熊本県' },
        { value: '44', text: '大分県' },
        { value: '45', text: '宮崎県' },
        { value: '46', text: '鹿児島県' },
        { value: '47', text: '沖縄県' }
    ];

    public static readonly spAsciiItemList: { value: string, text: string }[] = [
        { value: '01', text: 'Hokkaido' },
        { value: '02', text: 'Aomori' },
        { value: '03', text: 'Iwate' },
        { value: '04', text: 'Miyagi' },
        { value: '05', text: 'Akita' },
        { value: '06', text: 'Yamagata' },
        { value: '07', text: 'Fukushima' },
        { value: '08', text: 'Ibaraki' },
        { value: '09', text: 'Tochigi' },
        { value: '10', text: 'Gunma' },
        { value: '11', text: 'Saitama' },
        { value: '12', text: 'Chiba' },
        { value: '13', text: 'Tokyo' },
        { value: '14', text: 'Kanagawa' },
        { value: '15', text: 'Niigata' },
        { value: '16', text: 'Toyama' },
        { value: '17', text: 'Ishikawa' },
        { value: '18', text: 'Fukui' },
        { value: '19', text: 'Yamanashi' },
        { value: '20', text: 'Nagano' },
        { value: '21', text: 'Gifu' },
        { value: '22', text: 'Shizuoka' },
        { value: '23', text: 'Aichi' },
        { value: '24', text: 'Mie' },
        { value: '25', text: 'Shiga' },
        { value: '26', text: 'Kyoto' },
        { value: '27', text: 'Osaka' },
        { value: '28', text: 'Hyogo' },
        { value: '29', text: 'Nara' },
        { value: '30', text: 'Wakayama' },
        { value: '31', text: 'Tottori' },
        { value: '32', text: 'Shimane' },
        { value: '33', text: 'Okayama' },
        { value: '34', text: 'Hiroshima' },
        { value: '35', text: 'Yamaguchi' },
        { value: '36', text: 'Tokushima' },
        { value: '37', text: 'Kagawa' },
        { value: '38', text: 'Ehime' },
        { value: '39', text: 'Kochi' },
        { value: '40', text: 'Fukuoka' },
        { value: '41', text: 'Saga' },
        { value: '42', text: 'Nagasaki' },
        { value: '43', text: 'Kumamoto' },
        { value: '44', text: 'Oita' },
        { value: '45', text: 'Miyazaki' },
        { value: '46', text: 'Kagoshima' },
        { value: '47', text: 'Okinawa' }
    ];

    public static readonly stateJpList: { value: string, text: string }[] = [
        { value: 'Hokkaido', text: '北海道' },
        { value: 'Aomori', text: '青森県' },
        { value: 'Iwate', text: '岩手県' },
        { value: 'Miyagi', text: '宮城県' },
        { value: 'Akita', text: '秋田県' },
        { value: 'Yamagata', text: '山形県' },
        { value: 'Fukushima', text: '福島県' },
        { value: 'Ibaraki', text: '茨城県' },
        { value: 'Tochigi', text: '栃木県' },
        { value: 'Gunma', text: '群馬県' },
        { value: 'Saitama', text: '埼玉県' },
        { value: 'Chiba', text: '千葉県' },
        { value: 'Tokyo', text: '東京都' },
        { value: 'Kanagawa', text: '神奈川県' },
        { value: 'Niigata', text: '新潟県' },
        { value: 'Toyama', text: '富山県' },
        { value: 'Ishikawa', text: '石川県' },
        { value: 'Fukui', text: '福井県' },
        { value: 'Yamanashi', text: '山梨県' },
        { value: 'Nagano', text: '長野県' },
        { value: 'Gifu', text: '岐阜県' },
        { value: 'Shizuoka', text: '静岡県' },
        { value: 'Aichi', text: '愛知県' },
        { value: 'Mie', text: '三重県' },
        { value: 'Shiga', text: '滋賀県' },
        { value: 'Kyoto', text: '京都府' },
        { value: 'Osaka', text: '大阪府' },
        { value: 'Hyogo', text: '兵庫県' },
        { value: 'Nara', text: '奈良県' },
        { value: 'Wakayama', text: '和歌山県' },
        { value: 'Tottori', text: '鳥取県' },
        { value: 'Shimane', text: '島根県' },
        { value: 'Okayama', text: '岡山県' },
        { value: 'Hiroshima', text: '広島県' },
        { value: 'Yamaguchi', text: '山口県' },
        { value: 'Tokushima', text: '徳島県' },
        { value: 'Kagawa', text: '香川県' },
        { value: 'Ehime', text: '愛媛県' },
        { value: 'Kochi', text: '高知県' },
        { value: 'Fukuoka', text: '福岡県' },
        { value: 'Saga', text: '佐賀県' },
        { value: 'Nagasaki', text: '長崎県' },
        { value: 'Kumamoto', text: '熊本県' },
        { value: 'Oita', text: '大分県' },
        { value: 'Miyazaki', text: '宮崎県' },
        { value: 'Kagoshima', text: '鹿児島県' },
        { value: 'Okinawa', text: '沖縄県' }
    ];

    public static readonly domainNewsItemList: { value: string, text: string }[] = [
        { value: '2', text: 'HTML版＋テキスト版での送付' },
        { value: '1', text: 'テキスト版のみでの送付' },
        { value: '0', text: '配信無し' }
    ];

    public static readonly sendRenewMailItemList: { value: string, text: string }[] = [
        { value: '1', text: '受け取る' },
        { value: '0', text: '受け取らない' }
    ];

    public static readonly smsExcludeItemList: { value: string, text: string }[] = [
        { value: '0', text: '受け取る' },
        { value: '1', text: '受け取らない' }
    ];

    public static readonly domainUpdateNewsItemList: { value: string, text: string }[] = [
        { value: '0', text: '送付する' },
        { value: '1', text: '送付しない' }
    ];

    public static readonly hostingSearchValues: { value: string, name: string }[] = [
        { value: '10', name: "ご入金待ち" },
        { value: '11', name: "課金待ち" },
        { value: '12', name: "課金失敗/再課金処理待ち" },
        { value: '13', name: '決済結果待ち' },
        { value: '20', name: 'お支払い済' },
        { value: '98', name: '返金済' }
    ];

    public static readonly mfaMethodList: { value: string, text: string }[] = [
        { value: '', text: '利用しない' },
        { value: '1', text: 'メールで認証する' },
        { value: '2', text: 'SMSで認証する' }
    ];

    public static readonly resionForExtensionList: { value: string, text: string }[] = [
        { value: '0', text: '本登録を失念していたため。' },
        { value: '1', text: '登記完了が予定日より遅れているため。' },
        { value: '2', text: '仮登録の仕組みを理解していなかったため。（登記済）' },
        { value: '3', text: '仮登録ではなく通常の登録が完了していると思っていたため。' }
    ];

    public static readonly dsrecordAlgList: { value: string, text: string }[] = [
        { value: '0', text: 'Delete DS' },
        { value: '1', text: 'RSA/MD5 (deprecated, see 5)' },
        { value: '2', text: 'Diffie-Hellman' },
        { value: '3', text: 'DSA/SHA1' },
        { value: '4', text: 'Reserved' },
        { value: '5', text: 'RSASHA1' },
        { value: '6', text: 'DSA-NSEC3-SHA1' },
        { value: '7', text: 'RSASHA1-NSEC3-SHA1' },
        { value: '8', text: 'RSASHA256' },
        { value: '9', text: 'Reserved' },
        { value: '10', text: 'RSA/SHA-512' },
        { value: '11', text: 'Reserved' },
        { value: '12', text: 'GOST R 34.10-2001' },
        { value: '13', text: 'ECDSA Curve P-256 with SHA-256' },
        { value: '14', text: 'ECDSA Curve P-384 with SHA-384' },
        { value: '15', text: 'Ed25519' },
        { value: '16', text: 'ED448' },
        { value: '17-122', text: 'Unassigned' },
        { value: '123-251', text: 'Reserved' },
        { value: '252', text: 'Reserved for Indirect Keys' },
        { value: '253', text: 'private algorithm' },
        { value: '254', text: 'private algorithm OID' },
        { value: '255', text: 'Reserved' }
    ];

    public static readonly dsrecordDigestTypeList: { value: string, text: string }[] = [
        { value: '0', text: 'Reserved' },
        { value: '1', text: 'SHA-1' },
        { value: '2', text: 'SHA-256' },
        { value: '3', text: 'GOST R 34.11-94' },
        { value: '4', text: 'SHA-384' },
        { value: '5-255', text: 'Unassigned' }
    ];

    public static readonly tmchStatusListJp: { value: string, text: string }[] = [
        { value: '', text: 'すべてのステータス' },
        { value: 'INITIAL', text: '申請受付' },
        { value: 'PENDING_REGIST', text: '申請待ち' },
        { value: 'TMCH_SUBMIT_MARK', text: '申請中' },
        { value: 'TMCH_SUBMIT_MARK_COMPLETE', text: '申請完了' },
        { value: 'DENY', text: '申請エラー' },
        { value: 'TMCH_MODIFICATION', text: '申請情報修正待ち' },
        { value: 'CANCEL', text: '申請拒否' }
    ]

    public static readonly classNumberTmch1: { value: Number, text: string, checked: boolean }[] = [
        { value: 1, text: '工業用、科学用又は農業用の化学品', checked: false },
        { value: 2, text: '塗料、着色料及び腐食の防止用の調製品', checked: false },
        { value: 3, text: '洗浄剤及び化粧品', checked: false },
        { value: 4, text: '工業用油、工業用油脂、燃料及び光剤', checked: false },
        { value: 5, text: '薬剤', checked: false },
        { value: 6, text: '卑金属及びその製品', checked: false },
        { value: 7, text: '加工機械、原動機(陸上の乗物用のものを除く。)その他の機械', checked: false },
        { value: 8, text: '手動工具', checked: false },
        { value: 9, text: '科学用、航海用、測量用、写真用、音響用、映像用、計量用、信号用、検査用、救命用、教育用、計算用又は情報処理用の機械器具、光学式の機械器具及び電気の伝導用、電気回路の開閉用、変圧用、蓄電用、電圧調整用又は電気制御用の機械器具', checked: false },
        { value: 10, text: '医療用機械器具及び医療用品', checked: false },
        { value: 11, text: '照明用、加熱用、蒸気発生用、調理用、冷却用、乾燥用、換気用、給水用又は衛生用の装置', checked: false },
        { value: 12, text: '乗物その他移動用の装置', checked: false },
        { value: 13, text: '火器及び火工品', checked: false },
        { value: 14, text: '貴金属、貴金属製品であって他の類に属しないもの、宝飾品及び時計', checked: false },
        { value: 15, text: '楽器', checked: false },
        { value: 16, text: '紙、紙製品及び事務用品', checked: false },
        { value: 17, text: '電気絶縁用、断熱用又は防音用の材料及び材料用のプラスチック', checked: false },
        { value: 18, text: '革及びその模造品、旅行用品並びに馬具', checked: false },
        { value: 19, text: '金属製でない建築材料', checked: false },
        { value: 20, text: '家具及びプラスチック製品であって他の類に属しないもの', checked: false },
        { value: 21, text: '家庭用又は台所用の手動式の器具、化粧用具、ガラス製品及び磁器製品', checked: false },
        { value: 22, text: 'ロープ製品、帆布製品、詰物用の材料及び織物用の原料繊維', checked: false },
        { value: 23, text: '織物用の糸', checked: false },

    ];

    public static readonly classNumberTmch2: { value: Number, text: string, checked: boolean }[] = [
        { value: 24, text: '織物及び家庭用の織物製カバー', checked: false },
        { value: 25, text: '被服及び履物', checked: false },
        { value: 26, text: '裁縫用品', checked: false, },
        { value: 27, text: '床敷物及び織物製でない壁掛け', checked: false },
        { value: 28, text: 'がん具、遊戯用具及び運動用具', checked: false },
        { value: 29, text: '動物性の食品及び加工した野菜その他の食用園芸作物', checked: false },
        { value: 30, text: '加工した植物性の食品(他の類に属するものを除く。)及び調味料', checked: false },
        { value: 31, text: '加工していない陸産物、生きている動植物及び飼料', checked: false },
        { value: 32, text: 'アルコールを含有しない飲料及びビール', checked: false },
        { value: 33, text: 'ビールを除くアルコール飲料', checked: false },
        { value: 34, text: 'たばこ、喫煙用具及びマッチ', checked: false },
        { value: 35, text: '広告、事業の管理又は運営及び事務処理及び小売又は卸売の業務において行われる顧客に対する便益の提供', checked: false },
        { value: 36, text: '金融、保険及び不動産の取引', checked: false },
        { value: 37, text: '建設、設置工事及び修理', checked: false },
        { value: 38, text: '電気通信', checked: false },
        { value: 39, text: '輸送、こん包及び保管並びに旅行の手配', checked: false },
        { value: 40, text: '物品の加工その他の処理', checked: false },
        { value: 41, text: '教育、訓練、娯楽、スポーツ及び文化活動', checked: false },
        { value: 42, text: '科学技術又は産業に関する調査研究及び設計並びに電子計算機又はソフトウェアの設計及び開発', checked: false },
        { value: 43, text: '飲食物の提供及び宿泊施設の提供', checked: false },
        { value: 44, text: '医療、動物の治療、人又は動物に関する衛生及び美容並びに農業、園芸又は林業に係る役務', checked: false },
        { value: 45, text: '冠婚葬祭に係る役務その他の個人の需要に応じて提供する役務(他の類に属するものを除く。)、警備及び法律事務', checked: false },
    ];
    public static readonly PhoneNumber: string[] = ['00-0000-0000', '01-0000-0000', '02-0000-0000', '03-0000-0000', '04-0000-0000', '05-0000-0000', '06-0000-0000', '07-0000-0000', '08-0000-0000', '09-0000-0000', '00-0000-0001', '00-0000-0002', '00-0000-0003', '00-0000-0004', '00-0000-0005', '00-0000-0006', '00-0000-0007', '00-0000-0008', '00-0000-0009', '0000000000', '0100000000', '0200000000', '0300000000', '0400000000', '0500000000', '0600000000', '0700000000', '0800000000', '0900000000', '0000000001', '0000000002', '0000000003', '0000000004', '0000000005', '0000000006', '0000000007', '0000000008', '0000000009'];
    public static readonly ZipCode: string[] = ['000-0000', '111-1111', '222-2222', '333-3333', '444-4444', '555-5555', '666-6666', '777-7777', '888-8888', '999-9999', '000-0001', '000-0002', '000-0003', '000-0004', '000-0005', '000-0006', '000-0007', '000-0008', '000-0009'];

    public static readonly domainParkingAccountTypeList: { value: string, text: string }[] = [
        { value: '1', text: '普通' },
        { value: '2', text: '当座' }
    ];
}
