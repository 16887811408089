import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { EIeraeNetDeShindanModalEVarType } from 'Content/script/libs/abtest/custom-event.util';
import { ServiceContainer } from 'Content/script/service/service_container';
import { docCookies } from '../../../../libs/third_party/cookies';
import { IeraeNetDeShindanService } from '../../../../service/ierae_net_de_shindan_service';

@Component({
    selector: 'new-rs-contract-tour-modal',
    templateUrl: './new_rs_contract_tour_modal.html'
})

export class NewRsContractTourModalComponent {
    @Input() isTopScreen: boolean;
    @Output() isShowInstructRibon = new EventEmitter();
    @Output() canShowTopChangeModal = new EventEmitter<string>();

    // 画面制御用
    public queueId: number;
    public isShowInstructModal: boolean = false;
    public isEventFired: boolean;

    /**
     * RSツアーモーダル表示
     */
    public get isShowInstruct(): boolean {
        let isShowInstruct: boolean = this.isTopScreen && this.isShowInstructModal;

        if (!this.isEventFired && isShowInstruct) {
            // GT#135078【お名前.com Navi】【ネットde診断】eventとeVarの再設置
            IeraeNetDeShindanService.dispatchModalEvent(EIeraeNetDeShindanModalEVarType.RsTourModalCount);

            this.isEventFired = true;
        }

        return isShowInstruct;
    }

    public constructor(
        private serviceContainer: ServiceContainer
    ) {
        this.queueId = serviceContainer.HubsService.multipleSendManager.GetComponentId();
        this.isEventFired = false;
    }

    public onclickCloseInstructModal(): void {
        this.isShowInstructModal = false;
        //'1'：チュートリアル表示
        sessionStorage.setItem('isShowInstructRibon', '1');
        this.isShowInstructRibon.emit();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        for (const prop in changes) {
            let change = changes[prop];

            // isTopScreenがfalse→trueに変更になった際にデータを取得しに行く
            if (prop == "isTopScreen" && change.currentValue == true) {
                this.getServerStatus();
            }
        }
    }

    private getServerStatus(): void {
        this.serviceContainer.HubsService.multipleSendManager.Send(
            this.queueId,
            this.serviceContainer.HubsService.hubsManager.topHubManager.GetServerPopupInfoAsync,
            null,
            (data: GetServerPopupInfoResponseHubDto) => {
                if (data.HasOneRs && data.Status != POPUP_STATUS.DISABLE && !docCookies.hasItem('RsloggedIn') && docCookies.getItem("transferType") !== "STAFF_LOGIN") {
                    this.canShowTopChangeModal.emit('1');
                    setTimeout(() => {
                        this.isShowInstructModal = true;
                    }, 500);
                } else {
                    this.canShowTopChangeModal.emit('2');
                }
            },
            (data: GetServerPopupInfoResponseHubDto) => {
                this.canShowTopChangeModal.emit('2');
            },
            () => {
                this.canShowTopChangeModal.emit('2');
            },
            null,
            () => {
                this.canShowTopChangeModal.emit('2');
            },
        );
    }

    public CacheBusting(uri: string): string {
        return window.CacheBusting(uri);
    }
}

export enum POPUP_STATUS {
    DOMAIN_SETTING = 0,
    WAITING_PHONE_AUTHENTICATION,
    WAITING_PAYMENT,
    WAITING_SERVER_SETUP,
    SETUP_DONE,
    LOGIN_PROMOTION,
    DISABLE
}
