import { Component, Output, EventEmitter, OnInit } from '@angular/core';
import { docCookies, FutureDate } from 'Content/script/libs/third_party/cookies';
import { ServiceContainer } from 'Content/script/service/service_container';
import { NgForm } from '@angular/forms';

/**
 * ナビゲーション
 */
@Component({
    selector: 'top-enquete-popup',
    templateUrl: './top_enquete_popup.html',
})

export class TopEnquetePopupComponent implements OnInit {

    public queueId: number;
    private readonly _evaluationRangeName: { [key: number]: string } = { [1]: 'excellent', [2]: 'good', [3]: 'bad' };
    private _isDisplayDomainCartPopup: boolean = false;
    @Output() noSessionEvent = new EventEmitter<any>();
    @Output() onErrorEvent = new EventEmitter<any>();
    @Output() onUnExceptedErrorEvent = new EventEmitter<any>();
    public questionnaireInfo: QuestionnaireInfo;
    public pages: QuestionnairePage[];
    public isOpen: boolean = false;
    public titleStyleDisplay: string = 'none';
    public actives: string[] = [];
    public isShowButtonNext = false;
    public isShowPager = false;
    public isValidateOther = false;
    public readonly EnquetePopupKey: string = "EnquetePopupForm";
    public isABNew_Survey: boolean = false;

    /**
     * コンストラクタ
     * @param router
     * @param route
     * @param serviceContainer
     */
    public constructor(private serviceContainer: ServiceContainer) {

        this.queueId = this.serviceContainer.HubsService.multipleSendManager.GetComponentId();
    }

    public async ngOnInit() {
        const AB = this.serviceContainer.ABCookieService;
        this.isABNew_Survey = await AB.AllServicesSurvey.tests.AllServicesSurvey.isNewAsync();
        this._getDomainCartStatus();
    }
    public CacheBusting(uri: string): string {
        return window.CacheBusting(uri);
    }

    /**
     * ドメインかご落ちポップアップが表示されているかチェック
     */
    private _getDomainCartStatus(): void {
        this.serviceContainer.HubsService.multipleSendManager.Send(
            this.queueId,
            this.serviceContainer.HubsService.hubsManager.domainHubManager.DomainCartList,
            null,
            (res: DomainCartListResponseHubDto) => {
                if (res.DomainList == null || res.DomainList.length == 0) {
                    return;
                }
                this._isDisplayDomainCartPopup = true;
            },
            null,
            null,
            () => {
                if (!this._isDisplayDomainCartPopup) {
                    this._requestGetQuestionnaireData();
                }
            },
            null,
        );
    }

    /**
     * アンケートデータ取得
     */
    private _requestGetQuestionnaireData(): void {

        let pattern: number = 0;
        if (this.isABNew_Survey) {
            pattern = 1;
        }

        var answerReqHub: QuestionnaireAnswerAllServicesRequestHubDto = {
            AnswerHubList: [],
            UserAgent: window.navigator.userAgent.toLowerCase(),
            Pattern: pattern,
            Screen: "Top"
        };
        this.serviceContainer.HubsService.multipleSendManager.Send(
            this.queueId,
            this.serviceContainer.HubsService.hubsManager.questionnaireHubManager.GetQuestionnaireInfoAllServices,
            answerReqHub,
            (resHub: QuestionnaireInfoAllServicesResponseHubDto) => {
                var pageNo = 0;
                var questionnaireArr: QuestionnairePage[] = [];
                resHub.QuestionnaireHubList.map(x => {
                    var questionnaire: QuestionnairePage = new QuestionnairePage();
                    questionnaire.id = x.Id;
                    questionnaire.content = x.Content;
                    questionnaire.order = x.Order;
                    questionnaire.questionNo = x.QuestionNo;
                    questionnaire.requiredFlg = x.RequiredFlg;
                    questionnaire.type = x.Type;

                    x.OptionHubList.map(y => {
                        var option: QuestionnaireOptionHasImage = new QuestionnaireOptionHasImage();
                        option.id = y.Id;
                        option.content = y.Content;
                        option.order = y.Order;
                        questionnaire.options.push(option);
                    });
                    if (questionnaire.type == QuestionnaireType.RadioButton || questionnaire.type == QuestionnaireType.RadioButtonWithOther) {
                        questionnaire.selecltedId = questionnaire.options[0].id;
                    }
                    if (questionnaire.type == QuestionnaireType.CheckBoxWithOther) {
                        var option: QuestionnaireOptionHasImage = new QuestionnaireOptionHasImage();
                        option.id = questionnaire.options[questionnaire.options.length - 1].id + 1;
                        option.content = 'その他';
                        option.order = questionnaire.options.length;
                        option.isOther = true;
                        questionnaire.options.push(option);
                    }
                    questionnaire.pageNo = pageNo++;
                    questionnaireArr.push(questionnaire);
                });

                questionnaireArr[0].isShow = true;
                if (!this.isABNew_Survey) {
                    //Aパターン
                    questionnaireArr[0].options.reverse();
                    questionnaireArr[0].options.map(x => {
                        x.image = `/Content/images/gmo/assets/${this._evaluationRangeName[x.order]}.svg`;

                    });
                } else {
                    //Bパターン
                    questionnaireArr[1].options.reverse();
                    questionnaireArr[1].options.map(x => {
                        x.image = `/Content/images/gmo/assets/${this._evaluationRangeName[x.order]}.svg`;

                    });
                }

                //最後のページ（アンケートではない）
                var lastPage = new QuestionnairePage();
                lastPage.type = QuestionnaireType.NotQuestionnaire;
                lastPage.pageNo = pageNo++;
                questionnaireArr.push(lastPage);

                this.questionnaireInfo = new QuestionnaireInfo(questionnaireArr);
                this.questionnaireInfo.title = resHub.Title;
                this.pages = this.questionnaireInfo.questionnaires;
                this.setActivePager(0);
                this.titleStyleDisplay = 'block';
                this.isOpen = true;
            },
            (resHub: QuestionnaireInfoAllServicesResponseHubDto) => {
                this.onErrorEvent.emit(resHub);
            },
            (resHub: QuestionnaireInfoAllServicesResponseHubDto) => {
                this.noSessionEvent.emit(resHub);
            },
            null,
            (resHub: QuestionnaireInfoAllServicesResponseHubDto) => {
                this.onUnExceptedErrorEvent.emit(resHub);
            }
        );
    }
    public onClickNext(form: NgForm) {
        if (form.valid === false && this.isValidateOther) {
            for (var key in form.controls) {
                form.controls[key].markAsDirty();
            }
            return;
        }

        event.stopPropagation();
        const pager = this.questionnaireInfo.qustionnairePager;
        const currentPage = pager.atIndex;
        const currentIndex = pager.currentIndex;
        //次のページへ遷移
        if (pager.hasNext) {
            pager.moveNext();
            this.setActivePager(currentIndex + 1);
        } else {
            this.onClickClose();
        }

        let pattern: number = 0;
        if (this.isABNew_Survey) {
            pattern = 1;
        }

        //アンケート送信
        if (currentPage.isQuestionnaire) {
            var answerReqHub: QuestionnaireAnswerAllServicesRequestHubDto = {
                AnswerHubList: [],
                UserAgent: window.navigator.userAgent.toLowerCase(),
                Pattern: pattern,
                Screen: "Top"
            };
            var answerList = this._getAnswerHubList(currentIndex);
            if (answerList) {
                answerReqHub.AnswerHubList = answerList;
                this._transmit(answerReqHub);
                currentPage.clearInputs();
            }
        }
    }
    public onClickClose() {
        if (event) {
            event.stopPropagation();
        }
        this.isOpen = false;
        this.questionnaireInfo.qustionnairePager.reset();
        docCookies.setItem(this.EnquetePopupKey, false, FutureDate.afterDays(31));
    }

    public onChangeRadio(questionnaire: QuestionnairePage, optionId: number) {
        questionnaire.selecltedId = optionId;
        questionnaire.hasOther = false;
    }

    public onChangeCheckBox() {
        const pager = this.questionnaireInfo.qustionnairePager;
        const currentPage = pager.atIndex;
        let numberCheckBoxChecked: number = 0;
        currentPage.hasOther = true;
        currentPage.options.forEach(x => {
            if (x.isCheck) {
                numberCheckBoxChecked++;
                this.isShowButtonNext = true;
                if (x.isOther) {
                    this.isValidateOther = true;
                } else {
                    this.isValidateOther = false;
                }
            }
            if (numberCheckBoxChecked == 0) {
                this.isShowButtonNext = false;
            }
        });
    }

    public setActivePager(index: number) {
        this.actives = ['', '', ''];
        if (index >= this.actives.length) {
            this.isShowPager = false
        } else {
            this.isShowPager = true;
            this.actives[index] = 'is-Active';
        }
    }

    private _getAnswerHubList(index: number): QuestionnaireAnswerHubDto[] {
        var answerHubList: QuestionnaireAnswerHubDto[] = [];
        var answer: QuestionnaireAnswerHubDto;
        var page = this.pages[index];
        switch (page.type) {
            case QuestionnaireType.CheckBox:
            case QuestionnaireType.CheckBoxWithOther:
                for (let option of page.options) {
                    if (option.isCheck) {
                        if (option.isOther) {
                            answer = {
                                QuestionId: page.id,
                                Type: page.type,
                                Option: '',
                                Other: page.otherText,
                            };
                        } else {
                            answer = {
                                QuestionId: page.id,
                                Type: page.type,
                                Option: (option.id === 0) ? option.content : option.id.toString(),
                                Other: 'no_other',
                            };
                        }
                        answerHubList.push(answer);
                    }
                }
                break;

            case QuestionnaireType.RadioButton:
            case QuestionnaireType.RadioButtonWithOther:
                for (let option of page.options) {
                    if (option.id === page.selecltedId) {
                        answer = {
                            QuestionId: page.id,
                            Type: page.type,
                            Option: (option.id === 0) ? option.content : option.id.toString(),
                            Other: 'no_other',
                        };
                        answerHubList.push(answer);
                    }
                }
                break;

            case QuestionnaireType.Text:
                if (page.text.length > 0) {
                    answer = {
                        QuestionId: page.id,
                        Type: page.type,
                        Option: page.text,
                        Other: 'no_other',
                    }
                    answerHubList.push(answer);
                }
                break;

            case QuestionnaireType.Textarea:
                if (page.textarea.length > 0) {
                    answer = {
                        QuestionId: page.id,
                        Type: page.type,
                        Option: page.textarea.replace(/<("[^"]*"|'[^']*'|[^'">])*>/g, '\\n'),
                        Other: 'no_other',
                    }
                    answerHubList.push(answer);
                }
                break;
        }

        return answerHubList;
    }

    private _transmit(requestHubDto: QuestionnaireAnswerAllServicesRequestHubDto) {
        this.serviceContainer.HubsService.multipleSendManager.Send(
            this.queueId,
            this.serviceContainer.HubsService.hubsManager.questionnaireHubManager.AnswerQuestionnaireAllServices,
            requestHubDto,
            (answerResHub: QuestionnaireAnswerAllServicesResponseHubDto) => {
            },
            (answerResHub: QuestionnaireAnswerAllServicesResponseHubDto) => {
                this.onErrorEvent.emit(answerResHub);
            },
            (answerResHub: QuestionnaireAnswerAllServicesResponseHubDto) => {
                this.noSessionEvent.emit(answerResHub);
            },
            null,
            (answerResHub: QuestionnaireAnswerAllServicesResponseHubDto) => {
                this.onUnExceptedErrorEvent.emit(answerResHub);
            }
        );
    }
}


class QuestionnaireIterator {
    protected readonly _arr: QuestionnairePage[] = [];
    protected _index: number = 0;

    public constructor(arr: QuestionnairePage[]) {
        this._arr = arr;
        this._index = 0;
    }

    public reset() {
        this._index = 0;
        this._arr.map(x => { x.isShow = false; });
        this._arr[0].isShow = true;
    }

    public get atIndex(): QuestionnairePage {
        return this._arr[this._index];
    }

    public get hasNext(): boolean {
        return (this._index + 1) < this._arr.length;
    }

    public get currentIndex(): number 
    {
        return this._index;    
    }

    public moveNext(): QuestionnairePage {
        const current = this._arr[this._index];
        if (this.hasNext) {
            const next = this._arr[++this._index];
            current.isShow = false;
            next.isShow = true;
            return next;
        }
        else {
            return current;
        }
    }
}

export class QuestionnaireInfo {
    public title: string;
    public readonly questionnaires: QuestionnairePage[];
    public readonly qustionnairePager: QuestionnaireIterator;

    public constructor(arr: QuestionnairePage[]) {
        this.title = '';
        this.questionnaires = arr;
        this.qustionnairePager = new QuestionnaireIterator(arr);
    }
}

export class QuestionnairePage {
    //アンケートDBから取得
    public id: number = 0;
    public questionNo: number = 0; // 1始まり
    public content: string = '';
    public requiredFlg: number = 0;
    public type: number = 0;
    public order: number = 0;
    public options: QuestionnaireOptionHasImage[] = [];
    public text: string = '';
    public textarea: string = '';
    public hasOther: boolean = false;
    public otherText: string = '';
    public selecltedId: number = 0;
    public isSelected: boolean = false;

    public isEitherSelect: string = '';

    //ビュー制御
    public isShow: boolean = false;
    public pageNo: number = 0;

    public constructor() { }

    public get isQuestionnaire(): boolean {
        return (this.type != QuestionnaireType.NotQuestionnaire);
    }

    public clearInputs() {
        this.options.map(x => {
            x.isCheck = false;
        });
        this.text = '';
        this.textarea = '';
        this.hasOther = false;
        this.otherText = '';
        this.selecltedId = 0;
        this.isSelected = false;
    }
}
 
export class QuestionnaireOptionHasImage {
    //アンケートDBから取得
    public id: number = 0;
    public content: string = '';
    public order: number = 0;
    public isCheck: boolean = false;
    public isOther: boolean = false;
    //アイコンイメージ
    public image: string = '';

    public constructor() { }
}

export class QuestionnaireAnswerOption {
    public optionId: number = 0;
    public content: string = '';

    public constructor() { }
}

export enum QuestionnaireType {
    NotQuestionnaire = -1,
    Either = 1,
    CheckBox = 2, 
    CheckBoxWithOther = 3,
    RadioButton = 4,
    RadioButtonWithOther = 5,
    Text = 6,
    Textarea = 7
}
