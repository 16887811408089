export class StringUtil {
    public static ReplaceThreePeriodReader(str: string, limitNum: number) {
        if (str.length <= limitNum)
            return str;

        str = str.slice(0, limitNum);
        str = str + "...";

        return str;
    }

    /**
     * カンマ付きの文字列からカンマを除外する
     * @param str
     */
    public static StringWithCommaToNumber(str: string): number {
        const result: number = str != null ? parseInt(str.split(',').join('').trim()) : 0;
        return result;
    }

    /**
     * 数値型からカンマ付きの文字列にする
     * @param num
     */
    public static NumberToStringWithComma(num: number): string {
        var result: string = new String(num).replace(/,/g, "");
        while (result != (result = result.replace(/^(-?\d+)(\d{3})/, "$1,$2")));
        return result;
    }

    /**
     * 値がnull又はundefinedの場合、置換文字を返す
     * @param str 値
     * @param repStr 置換文字
     */
    public static IfNull(str: string, repStr: string): string {
        return (str === null || str === undefined) ? repStr : str;
    }

    /**
    * 文字列の左側を0(ゼロ)でパディングする
    * @param str 値
    * @param digits ゼロを含めた桁数
    * 例：ZeroPaddging('11', 5)の戻り値は '00011'
    */
    public static ZeroPadding(str: string, digits: number): string {
        if (str.length >= digits) {
            return str;
        }

        return '0'.repeat(digits - str.length) + str;
    }

    /**
     * router.navigate()メソッド用のクエリパラメータ配列を返す
     * @param url
     */
    public static getQueryParams(url: string): { [key: string]: any } {

        let queryparams: { [key: string]: string } = {};

        const qpTemp = (url + '?').split('?')[1].split('&');
        qpTemp.forEach(x => {
            const arr = x.split('=');
            if (0 < arr.length && 0 < arr[0].length) {
                queryparams[arr[0]] = arr[1];
            }
        });

        return queryparams;
    }
}