<header class="header" role="banner">
    <div class="header-Bar">
        <div class="header-Logo2">
            <a>
                <img src="{{CacheBusting('/Content/images/common/logo.svg')}}" alt="ドメインとるならお名前.com by GMO">
            </a>
            <span class="header-Logo2-Text">お名前.com Navi</span>
        </div>
        <ul class="header-Function" *ngIf="hasLoginName">
            <li class="header-Function-Item">
                <span class="header-Function-Button">お名前ID：<strong>{{loginName}}</strong><i class="icon icon-ArrowDown"></i></span>
            </li>
        </ul>
    </div>
</header>
