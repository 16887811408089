import { ChangeDetectorRef, Component, Injectable, NgZone, ViewChild } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { BaseComponent } from 'Content/script/components/base_component';
import { DomainRenewPaymentComponent } from 'Content/script/components/share/Templates/domain_renew_payment/domain_renew_payment.component';
import { DomainConvenience, DomainPaymentType, DomainPaymentTypeText } from 'Content/script/components/share/domain_payment/domain_payment.component';
import { DomainSearchCondition, DomainSearchConditionConfig, DomainSearchDropdownComponent, DomainSearchDropdownViewModel } from 'Content/script/components/share/domain_search_dropdown/domain_search_dropdown.component';
import { PageView, PagerViewModel } from 'Content/script/components/view_models/pager_view_model';
import { CustomizedEvents } from 'Content/script/libs/abtest/custom-event.util';
import { LineNoAndPageNoAccessor } from 'Content/script/libs/common/lineno-pageno-accessor';
import { docCookies } from 'Content/script/libs/third_party/cookies';
import { DomainUtil } from 'Content/script/libs/utils/domain.util';
import { StringUtil } from 'Content/script/libs/utils/string.util';
import { NotifyMessage, NotifyType } from 'Content/script/service/notify_service';
import { ServiceContainer } from 'Content/script/service/service_container';
import { setViewKeep, setViewMode } from '../../../../../store/actions/view';
import { VIEWS, ViewState } from '../../../../../store/states/view';
import { Subscription, fromEvent } from 'rxjs';

declare var $: JQueryStatic;

@Injectable()
export class CanDeactivateListGuard  {
    constructor(private serviceContainer: ServiceContainer) { }

    canDeactivate(
        component: DomainSettingRenewListComponent,
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
        nextState: RouterStateSnapshot
    ): Promise<boolean> | boolean {

        // パスワード強制変更画面に遷移する場合は阻害しない
        if (nextState.url == "/password/change") {
            return true;
        }

        if (this.serviceContainer.ModalForDomainRenewService.isSkipDomainRenewPageModal) {
            this.serviceContainer.ModalForDomainRenewService.isSkipDomainRenewPageModal = false;
            component.setViewMode(false);
            return true;
        }

        if (component.viewModel.isSkipModalConfirm) {
            component.viewModel.isSkipModalConfirm = false;
            component.setViewMode(false);
            return true;
        }
        const result = component.viewModel.modalConfirmPromise().then((resolve) => {
            component.viewModel.showModalConfirm = false;
            if (resolve) {
                component.setViewMode(false);
            } else {
                component.setViewMode(false, VIEWS.PC);
            }
            return resolve
        });

        // 他画面遷移時に表示されるモーダルの表示制御のため参照している
        if (!component.viewModel.isHasDomain) {
            return true;
        }

        component.viewModel.showModalConfirm = true;

        if (component.viewModel.isReturnFromLoginSite) {
            CustomizedEvents.dispatchDomainUpdateBlockPopup();
        }

        return result;
    }
}

/**
 * ドメイン契約更新 一覧
 */
@Component({
    selector: 'domain-setting-renew-list',
    templateUrl: './list.html'
})

export class DomainSettingRenewListComponent extends BaseComponent {
    public viewModel: DomainSettingRenewListViewModel = null;
    private _lineNoAndPageNoAccessor = new LineNoAndPageNoAccessor();
    private _lineNoAndPageNo: LineCntAndPageNo = null;
    private _path: string = "";

    public LOGIN_REFERRER_PATH: string[] = [
        "/login",
        "/auth/signon",
        "/auth/singlesignon",
        "/sp/login",
    ];

    @ViewChild(DomainSearchDropdownComponent, { static: true })
    private _domainSearchDropdownComponent: DomainSearchDropdownComponent;

    @ViewChild(DomainRenewPaymentComponent, { static: true })
    private _domainRenewPaymentComponent: DomainRenewPaymentComponent;

    public constructor(router: Router, route: ActivatedRoute, serviceContainer: ServiceContainer, private _zone: NgZone, private _changeDetectorRef: ChangeDetectorRef, private store: Store<ViewState>) {
        super(router, route, serviceContainer);
        // 一旦、ビューモード切り替えを無効にしておく
        store.dispatch(setViewKeep({
            value: true
        }));
        this.enableFrequently("ドメイン契約更新", "/domain/setting/renew");
        this.serviceContainer.DomainCartService.HideView();
        this._path = location.pathname;
    }

    public setViewMode(keep: boolean, mode: string = null) {
        this.store.dispatch(setViewKeep({
            value: keep
        }));
        if (mode) {
            this.store.dispatch(setViewMode({
                value: mode
            }));
        }
    }

    // ドメイン更新画面強制表示の印象緩和のための注意文表示
    public get isDisplayCaution(): boolean {
        let referrer = window.ReferrerPath;
        return (this.LOGIN_REFERRER_PATH.indexOf(referrer) >= 0);
    }

    public Initialization() {
        const domainId: string = this.route.snapshot.params['domainId'];
        const transferData: IDataTransferDomainRenewDto = this.serviceContainer.DataTransferService.getData<IDataTransferDomainRenewDto>(this.serviceContainer.DataTransferService.DomainRenewKey);
        const lineNoAndPageNo = this._lineNoAndPageNoAccessor.getItem(this._path);

        this.viewModel = new DomainSettingRenewListViewModel(
            this.queueId, this.router,
            this.serviceContainer,
            domainId,
            transferData,
            this._domainSearchDropdownComponent.viewModel,
            this._domainRenewPaymentComponent,
            this._zone,
            this._changeDetectorRef,
            lineNoAndPageNo
        );
        this.viewModel.init();
        this.viewModel.requestMessages();
        this.viewModel.checkStatusNsToFireEvent();

        if (sessionStorage.getItem('receivedFirstDayRegistrationRs') !== 'y') {
            this.viewModel.fireEventRsStatistics();
        }
    }

    public Dispose() {
        if (this.viewModel) {
            this._lineNoAndPageNo = {
                path: this._path,
                LineCnt: this.viewModel.pageSize,
                PageNo: this.viewModel.currentPage
            };
            this._lineNoAndPageNoAccessor.setItem(this._lineNoAndPageNo, this._path);

            if (this.viewModel.renewDomains.length > 0) {
                this.viewModel.renewDomains.forEach((x) => { if (x.countDown) { x.countDown.clearInterval(); } });
            }
        }

        this.viewModel.unsubscribeScrollEvents();
        this.isDeleteDataTransfer = false;
        super.deleteAllProperty(this);
    }
}

class DomainSettingRenewListViewModel extends PagerViewModel {
    public readonly hasDomainId: boolean = this._domainId !== void 0 && this._domainId !== null;
    public isCloseModal: boolean = false;
    public pageSizeList: Array<number> = new Array<number>(5, 10, 20, 50, 100);
    public pageSize: number;
    public currentPage: number;
    public maxPage: number = 0;
    public pageNoList: number[] = [];
    public readonly sortDomainNameKey: string = 'domainNameML';
    public readonly sortExpirationDateKey: string = 'expirationDate';
    public sortPriorityList: string[] = [this.sortExpirationDateKey, this.sortDomainNameKey];
    public sortList: { [key: string]: number } = { [this.sortExpirationDateKey]: 1, [this.sortDomainNameKey]: 2 };

    private readonly _DEFAULT_PAGENO: number = 1;
    private readonly _DEFAULT_LINECNT: number = 5;
    public readonly FREE_DOMAIN_UNREGISTERED: string = '1';

    private _isRetrying: boolean = false;
    public exprireDomains: RenewDomainItem[] = [];
    public exprireDomainsForAbTest: RenewDomainItem[] = [];
    public exprireDomainsForAbcdTest: RenewDomainItem[] = [];
    public isDisableFreeRegist: boolean = false;
    /**
     * 一度に更新できるドメイン数の上限
     */
    private readonly DOMAIN_RENEW_LIMIT: number = 50;

    public isViewFee: boolean = false;
    public FreeDomainNotPriorityTld: string = "";
    public FreeDomainTldStr: string = "";
    public readonly DomainRenewType = DomainRenewType;
    public SelctedDomainRenewType: DomainRenewType = DomainRenewType.All;

    public readonly PaymentType = DomainPaymentType;
    public paymentType: DomainPaymentType = DomainPaymentType.None;
    public paymentTypeForFreeRegistDomain: DomainPaymentType = DomainPaymentType.None;
    // クレカ
    public registeredMaskedCreditNumber: string;

    // コンビニ情報
    public convenienceFee: number = 0;
    public convenienceUnTaxFee: number = 0;
    public conveniInfo: DomainRenewUpdatePaymentConveniInfoRequestHubDto;

    public readonly PointUsingType = PointUsingType;
    public gmoPointSetting: GmoPointSetting = new GmoPointSetting();

    public readonly updateYearList: string[] = ['1', '2', '3', '4', '5', '6', '7', '8', '9'];

    public hasCoupon: boolean = false;
    public isShowCouponDomainDetail: boolean = false;

    public isExistDefCheckedDomain: boolean = false;
    public hasRestoreDomain: boolean = false;

    public renewDomainHeader: RenewDomainHeader = new RenewDomainHeader(false, false, '0');
    public renewDomains: RenewDomainItem[] = [];

    /* 自動更新の支払い方法が設定されているか */
    private _hasSetAutoRenewPayment: boolean = false;

    public hostingAllowedDomainName: string = '';
    public hostingRecommendId: string = '';
    public isHostingUnpaid: boolean = false;

    public isOwnedMoreThanFiveDomain: boolean = false;
    public isOpenModal: boolean = false;
    public isSelectedOnlyOneDomain = false;
    public isCheckedMoreThanOneDomain = false;
    // -- SSL -- //
    public isOnamaeUser: boolean;

    // -- Guard -- //
    public isSkipModalConfirm: boolean = false; // 外部コンポーネントからもComponentRefを介して参照されています。　RsRecommendComponentを確認
    public showModalConfirm: boolean = false;
    public onClickCheckedDomain: boolean = true;
    public modalConfirmPromise: () => Promise<boolean> = () => {
        return new Promise<boolean>((resolve, reject) => {
            const isMovePromise = new Promise<boolean>((resolve, reject) => {
                this._modalResolveFn = resolve;
            });

            Promise.all([isMovePromise]).then((move) => {
                resolve(move[0]);
            });
        });
    };
    private _modalResolveFn: Function;
    private _canRecommendHosting: Array<{ domainName: string, hasRecommend: boolean }> = [{ domainName: '', hasRecommend: false }];

    public isDomainTableLoading: boolean = false;
    public isAllLoading: boolean = false;

    // コンポ―ネントのローディング状態
    public isComponentLoading: boolean = false;
    public isInitSearched: boolean = false;
    private _multiInquiryFlag = new Flag();

    private _zone: NgZone;
    private _changeDetectorRef;

    public isDisplayFreeRegistDomain: boolean = false;
    public isFireEvarForFreeDomain: boolean = false;
    public isCheckedFreeRegistDomain: boolean = false;
    public listFreeRegistDomain: FreeDomainInfo[] = [];
    private promiseGetFreeDomain: Promise<FreeDomainInfo[]>;
    public listGetFreeRegistDomainFromAPI: FreeDomainInfo[] = [];
    public freeRegistDomain: string = "";
    public freeRegistDomainId: string = "";
    public notificationFreeRegistDomain: string = '';

    //【GT#215782】ドメイン更新完了画面-更新訴求追加
    //【GT#218656】ドメイン更新完了画面内リコメンド枠挙動調整
    public renewDonePageData: IDataTransferDomainRenewDto;

    public freeRegistLoading: boolean = false;
    public isNaviRenewFirstYear: boolean = false;
    public isShowRibbon: boolean = false;
    public indexShowRibbon: number = 0;
    public LOGIN_REFERRER_PATH: string[] = [
        "/login",
        "/auth/signon",
        "/auth/singlesignon",
        "/sp/login",
    ];

    // 更新確認モーダル用データ群
    public renewDomainsData: IDataTransferDomainRenewDto;
    public freeRegistDomainData: DomainRenewCreateDomainRequestHubDto;
    private promiseGetDomainListCount: Promise<number>;
    public _domainIds: string = '';

    // ドメイン更新完了画面から遷移してきたか
    public isReturnFromDoneSite: boolean = false;

    public renewalDeadlineDateAfterRenew: string;

    public isButtonBeClicked: boolean = false;

    public domainIdButton: string;

    public isShowButtonOverYear: boolean = false;
    public isShowNotice: boolean = false;

    public currentMonth: string = '';

    public isReturnFromLoginSite: boolean = false;

    public isHaveOnlyOneDomain: boolean = false;

    // canDeactivateからも参照
    public isHasDomain: boolean = false;

    public isABNew_AdjustTldFreeRenew: boolean;
    public isShowNewConfirmModal: boolean;
    public isDisplayFreeRegistModal: boolean;
    public contactInfo: ContactOriginalInfoHubDto;

    public recoverContents: string;
    public canDisplayRecoverRecommend: boolean = false;
    public isShowModalRecoverRecommend: boolean = false;
    public isABNew_AdjustShowHideOptionRenewModal: boolean;
    public isABNew_AdjustRenewCouponCode: boolean = false;
    public gmoTaxedDiscount: number = 0;

    public domainNameMlShowRibon: string = '';
    public totalPriceDomainRegistRS: string = '0';
    private eventSubscription: Subscription;

    public constructor(
        queueId: number, router: Router, serviceContainer: ServiceContainer,
        private readonly _domainId: string,
        private readonly _transferData: IDataTransferDomainRenewDto,
        private _domainSearchDropdownViewModel: DomainSearchDropdownViewModel,
        private _domainRenewPaymentComponent: DomainRenewPaymentComponent,
        zone: NgZone,
        changeDetectorRef: ChangeDetectorRef,
        private _lineCntAndPageNo: LineCntAndPageNo
    ) {
        super(queueId, router, serviceContainer);

        this._zone = zone;
        this._changeDetectorRef = changeDetectorRef;

        this.isHideFloatingNextButton = true;

        this.renewDomainsData = null;

        this.isShowNewConfirmModal = false;
        if (!!this._lineCntAndPageNo) {
            this.pageSize = this._lineCntAndPageNo.LineCnt; 
            this.currentPage = this._lineCntAndPageNo.PageNo;
        } else {
            this.pageSize = this._DEFAULT_LINECNT;
            this.currentPage = this._DEFAULT_PAGENO;
        }

        this.conveniInfo = {
            ConveniCode: '',
            UserNameKana: ''
        };
    }


    public async init() {
        const AB = this.serviceContainer.ABCookieService;
        this.isABNew_AdjustTldFreeRenew = await AB.AdjustTldFreeRenew.tests.AdjustTldFreeRenew.isNewAsync();
        this.isABNew_AdjustShowHideOptionRenewModal = await AB.AdjustShowHideOptionRenewModal.tests.AdjustShowHideOptionRenewModal.isNewAsync();
        this.isABNew_AdjustRenewCouponCode = await this.serviceContainer.ABCookieService.AdjustRenewCouponCode.tests.AdjustRenewCouponCode.isNewAsync();
        this.isShowCouponDomainDetail = !!this.serviceContainer.DataTransferService.getData<boolean>(this.serviceContainer.DataTransferService.ApplyCouponDomainDetailKey);
        this.gmoponValidator(!!this._domainId && this.isShowCouponDomainDetail, this.isABNew_AdjustRenewCouponCode);
        
        if (this.isABNew_AdjustTldFreeRenew) {
            this._getInformationFreeTldDotOnline();
        } else {
            this._getInformationFreeTldDotShop();
        }

        this._setGmoCollaborationAndPoint();
        this.renewDonePageData = this.serviceContainer.DataTransferService.getData<IDataTransferDomainRenewDto>(this.serviceContainer.DataTransferService.DomainRenewFromDoneKey);
        const referrerPath = window.ReferrerPath;
        this.isReturnFromDoneSite = (referrerPath.includes("/domain/setting/renew/done"));
        this.isReturnFromLoginSite = (referrerPath.includes("/login") || referrerPath.includes("/sp/login"));

        if (this.isReturnFromDoneSite) {
            if (this.renewDonePageData != null) {
                for (var i = 0; i < this.renewDonePageData.validatedDomains.length; i++) {
                    if (i === this.renewDonePageData.validatedDomains.length - 1) {
                        this._domainIds += this.renewDonePageData.validatedDomains[i].domainId;
                    } else {
                        this._domainIds += this.renewDonePageData.validatedDomains[i].domainId + '\n';
                    }
                }
            }
            this._domainSearchDropdownViewModel.setCallBack(this._searchDomains);
        } else {
            if (this.hasDomainId) {
                this._domainSearchDropdownViewModel.setIsDisableView(true);
            } else {
                this._domainSearchDropdownViewModel.setCallBack(this._searchDomains);
            }
        }
        this._domainSearchDropdownViewModel.setIsDisableView(false);
        this._loadAccountInfo();
        this._requestGetRenewDomainsAndCoupon();
        /**
         * ドメイン更新専用ログアウト防止モーダルの「更新手続きを行う」ボタンのクリックイベントをリッスン
         * */
        let body = document.getElementsByTagName("body");
        body[0].addEventListener('onclick_withdrawal_prevention_modal_return_button', () => {
            this._setRenewDomainsSearchCondition();
            this._requestGetRenewDomains();
        });

        // スクロールイベントの設定
        this._setupScrollEventListeners();
    }

    /**
     * スクロールイベントの設定
     */
    private _setupScrollEventListeners(): void {
        this.unsubscribeScrollEvents();
        const scrollScreen$ = fromEvent<Event>(document, 'scroll');

        this.eventSubscription = new Subscription();
        this.eventSubscription.add(scrollScreen$.subscribe(() => this._handleScrollEvent()));
    }

    /**
     * 更新ボタンの位置を計算する
     */
    private _handleScrollEvent(): void {
        const mainButton = document.getElementById('button-Renew');

        if (mainButton) {
            const rect = mainButton.getBoundingClientRect();
            const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
            this.isHideFloatingNextButton = rect.bottom >= 0 && rect.top <= viewHeight; // 画面に更新ボタンが表示されているか確認する?
        }
    }

    /**
     * スクロールイベントをキャンセルする
     */
    public unsubscribeScrollEvents(): void {
        if (this.eventSubscription) {
            this.eventSubscription.unsubscribe();
        }
    }

    /**
     * 銀行振込可能か
     */
    public get isDisallowPaymentBank(): boolean {
        return this._getActiveDomains().filter(x => x.isCheckedDomain).some(x => x.isDisallowPaymentBank);
    }

    /**
     * コンビニ支払い可能か
     */
    public get isDisallowPaymentNetCvs(): boolean {
        return this._getActiveDomains().filter(x => x.isCheckedDomain).some(x => x.isDisallowPaymenNetCvs);
    }

    /**
     * 支払い方法取得
     */
    public setPaymnetMethod = (paymnetMethod: DomainPaymentType): void => {
        this.paymentType = paymnetMethod;
        this.paymentTypeForFreeRegistDomain = paymnetMethod;
        if (this.paymentTypeForFreeRegistDomain !== DomainPaymentType.RegisteredCreditCard && this.paymentTypeForFreeRegistDomain !== DomainPaymentType.NewCreditCard) {
            this.isDisplayFreeRegistModal = false;
            this.isCheckedFreeRegistDomain = false;
            this.isDisableFreeRegist = true;
        } else {
            this.isDisableFreeRegist = false;
        }
    }

    /**
     * GMOポイント連携状態・ポイント数取得
     */
    private _setGmoCollaborationAndPoint(): void {
        this.serviceContainer.HubsService.multipleSendManager.Send(
            this.queueId,
            this.serviceContainer.HubsService.hubsManager.domainRenewHubManager.GetGmoPointInfo,
            null,
            (resHubDto: DomainRenewGMOPointInfoResponseHubDto) => {
                this.gmoPointSetting.isCollaboration = resHubDto.IsGmoCollaboration;
                if (resHubDto.IsGmoCollaboration) {
                    this.gmoPointSetting.currentPoint = resHubDto.CurrentGmoPoint;
                }
                this.gmoPointSetting.useType = this.changedPointUsingType();
            },
            (resHubDto: DomainRenewGMOPointInfoResponseHubDto) => {
            },
            (resHubDto: DomainRenewGMOPointInfoResponseHubDto) => {
            },
            null,
            (resHubDto: DomainRenewGMOPointInfoResponseHubDto) => {
            }
        );
    }

    /**
     * GMOポイントの表示条件
     */
    private changedPointUsingType(): PointUsingType {
        if (this.gmoPointSetting.isCollaboration === false) {
            return PointUsingType.NotCollaboration;
        }

        if (this.gmoPointSetting.currentPoint !== '0' && this.gmoPointSetting.usePoint === '0') {
            return PointUsingType.NonUsePoint;
        }

        if (this.gmoPointSetting.currentPoint === '0') {
            return PointUsingType.NotHavePoint;
        }

        return PointUsingType.UsePoint;
    }

    /**
     * フローティングボタン表示制御
     */
    public isHideFloatingNextButton: boolean;

    // ドメイン一覧ローディング
    // 全体ローディングがかかるときは回さない
    public get partLoading(): boolean {
        return this.isDomainTableLoading && !this.allloading;
    }

    // 全体ローディング
    public get allloading(): boolean {
        return this.isAllLoading;
    }

    // 次へボタン有効化
    // ドメイン一覧、支払い方法、全体ローディングの処理を待つ
    public get isDisableNextButton(): boolean {
        return this.partLoading || this.isComponentLoading || this.allloading;
    }

    // 支払い方法ローディング
    public get paymentLoading(): boolean {
        return (this.isComponentLoading && !this.allloading);
    }

    // 支払い方法ローディング
    public get freeRegistLoadingAll(): boolean {
        return (this.freeRegistLoading && !this.allloading);
    }

    // 現在の表示件数に合わせた空ドメインリスト
    public get emptyDomainData(): EmptyData[] {
        let data: EmptyData[] = [];
        for (let index = 0; index < this.pageSize; index++) {
            data.push(new EmptyData());
        }
        return data;
    }

    // ドメイン更新画面強制表示の印象緩和のための注意文表示
    public get isDisplayCaution(): boolean {
        let referrer = window.ReferrerPath;
        return (this.LOGIN_REFERRER_PATH.indexOf(referrer) >= 0);
    }

    public setIsComponentLoading(isComponentLoading: boolean): void {
        this.isComponentLoading = isComponentLoading;
    }

    /**
     * コンビニ手数料取得
     */
    public setConvenienceFee(convenienceFee: any): void {
        this.convenienceFee = convenienceFee.convenienceFee;
        this.convenienceUnTaxFee = convenienceFee.convenienceUnTaxFee;
    }

    /**
     * ドメイン検索コンポーネントから検索
     */
    private _searchDomains = (): void => {
        this.currentPage = 1;
        // 検索条件取得
        let searchCondition: DomainSearchCondition;
        searchCondition = this._domainSearchDropdownViewModel.getDomainSearchCondition();
        let _isClickNewOptionSearch = this._domainSearchDropdownViewModel.getNewOptionSearch();
        let _isDeadlineWithin30Day: string = '0';
        let _hasConditionSearch: string = '0';
        if ((searchCondition.isDeadlineWithin30Day
            || searchCondition.createdDateFrom || searchCondition.createdDateTo
            || searchCondition.expirationDateFrom || searchCondition.expirationDateTo)
            && this.isOwnedMoreThanFiveDomain && !_isClickNewOptionSearch) {
            _isDeadlineWithin30Day = '1';
        }
        if (searchCondition.domainNames) {
            _hasConditionSearch = '1';
        }
        CustomizedEvents.dispatchDomainSearchModal({ isDeadlineWithin30Day: _isDeadlineWithin30Day, hasConditionSearch: _hasConditionSearch })
        this._requestGetRenewDomains();
    }

    /**
    * アカウントデータ取得
    */
    private _loadAccountInfo(): void {
        this.serviceContainer.HubsService.multipleSendManager.Send(
            this.queueId,
            this.serviceContainer.HubsService.hubsManager.accountHubManager.AccountDetail,
            null,
            (responseData: AccountDetailResponseHubDto) => {
                this.contactInfo = {
                    Fname: responseData.Fname,
                    Lname: responseData.Lname,
                    OrganizationML: responseData.OrganizationML,
                    Organization: responseData.Organization,
                    DivisionML: responseData.DivisionML,
                    Division: responseData.Division,
                    Cc: responseData.Cc,
                    SpML: responseData.SpML,
                    Sp: responseData.Sp,
                    CityML: responseData.CityML,
                    Pc: responseData.Pc,
                    City: responseData.City,
                    Street1TownML: responseData.Street1TownML,
                    Street1Town: responseData.Street1Town,
                    Street1NumberML: responseData.Street1NumberML,
                    Street1Number: responseData.Street1Number,
                    Street2ML: responseData.Street2ML,
                    Street2: responseData.Street2,
                    Role: responseData.Role,
                    Phone: responseData.Phone,
                    Fax: responseData.Fax,
                    Email: responseData.Email,
                } as ContactOriginalInfoHubDto
            },
            (responseData: AccountDetailResponseHubDto) => {
                // エラー
            },
            this.NoSession,
            (responseData: AccountDetailResponseHubDto) => {
            },
            this.UnExceptedError
        );
    }

    /**
     * 更新契約対象のドメイン一覧を取得します
     * ※クーポン対象表示対象かどうかを取得するので、初回時に１度のみコールしてください。
     */
    private _requestGetRenewDomainsAndCoupon(): void {
        this.isDomainTableLoading = true;
        this.isShowRibbon = false;
        this.domainNameMlShowRibon = '';
        this.totalPriceDomainRegistRS = '0';
        // 検索条件取得
        let searchCondition: DomainSearchCondition;
        searchCondition = this._domainSearchDropdownViewModel.getDomainSearchCondition();

        this._setRenewDomainsSearchCondition();

        const reqHubDto: DomainRenewListAndCouponRequestHubDto = {
            CurrentPage: this.currentPage,
            PageSize: this.pageSize,
            // セッションにクーポン表示の有無を保持しているので、SearchedTypeはサーバー側で判断
            SearchedType: null,
            SearchCondition: {
                DomainIds: this.getRequestDomainIds(),
                DomainNames: searchCondition.domainNames,
                GroupName: searchCondition.domainGroupName,
                CreatedDateFrom: searchCondition.createdDateFrom,
                CreatedDateTo: searchCondition.createdDateTo,
                ExpirationDateFrom: searchCondition.expirationDateFrom,
                ExpirationDateTo: searchCondition.expirationDateTo,
                WhoisProxyStatus: searchCondition.whoisProxyStatus,
                CheckedCoupon: false
            } as DomainRenewListSearchCondition,
            SortList: this.sortPriorityList.map(x => {
                return {
                    Key: x,
                    Type: this.sortList[x]
                } as DomainRenewListSortRequestHubDto;
            }),
        } as DomainRenewListAndCouponRequestHubDto;

        if (this.renewDomains.length > 0) {
            this.renewDomains.forEach((x) => { if (x.countDown) { x.countDown.clearInterval(); } });
        }

        this.serviceContainer.HubsService.multipleSendManager.Send(
            this.queueId,
            this.serviceContainer.HubsService.hubsManager.domainRenewHubManager.GetDomainRenewListAndCoupon,
            reqHubDto,
            async (resHubDto: DomainRenewListAndCouponResponseHubDto) => {
                //表示件数やページ番号がリクエスト時渡した値から変わっているときは更新期限の近いドメインを取得している
                this.pageSize = resHubDto.LineCnt;
                this.currentPage = resHubDto.PageNo;

                // 更新完了画面のリコメンドから遷移した場合、ページ件数をリセットします。
                const transferData: IDataTransferUnKeepPageCountDto = this.serviceContainer.DataTransferService.getData<IDataTransferUnKeepPageCountDto>(this.serviceContainer.DataTransferService.UnKeepPageCountKey);
                if (transferData) {
                    this._resetPageCount()
                }
                this.serviceContainer.DataTransferService.clear();
                this._retryRequestGetRenewDomainsAndCoupon(resHubDto);

                const domainRenewList = resHubDto.DomainRenewList.filter((value, index) => index < this.pageSize);
                this.isExistDefCheckedDomain = domainRenewList.some(x => x.IsDefCheckedDomain);
                this.hasRestoreDomain = domainRenewList.some(x => x.IsRestorableDomain);
                
                this.renewDomains = domainRenewList.map(x => {
                    const isCheckedDomain: boolean = ((this.hasDomainId && x.IsActiveDomain) || x.IsDefCheckedDomain) && !x.IsRestorableDomain && !x.IsRsPending;
                    const isEnableWhoisProduct: boolean = isCheckedDomain && x.CanApplyWhoisProduct && x.IsAppliedWhoisProxy === false && x.IsAppliedWhoisMailFwd === false;

                    let renewDomainItem = new RenewDomainItem(
                        x.IsActiveDomain,
                        isCheckedDomain,
                        isCheckedDomain,
                        x.DomainId,
                        x.DomainName,
                        x.DomainNameML,
                        x.Tld,
                        x.IsRegistryPremium,
                        x.CanSetAutoRenew,
                        x.IsDefCheckedDomain,
                        x.ExpirationDate,
                        x.RenewalDeadlineDate,
                        x.RenewalDeadlineCount,
                        '-',
                        '-',
                        x.DefRenewYear,
                        x.RenewYearProduct.map(y => {
                            return new UpdateYearItem(y.RenewYear, y.ProductId);
                        }),
                        x.IsAppliedWhoisProxy,
                        x.IsAppliedWhoisMailFwd,
                        x.IsAppliedSsl,
                        x.CanApplyWhoisProduct,
                        isEnableWhoisProduct,
                        false,
                        x.IsDomainProtect,
                        x.IsProtectedWhois,
                        x.IsProtectedAutoRenew,
                        x.IsAutoRenewActive,
                        x.CouponCode,
                        x.CouponDiscoutnUnTaxPrice,
                        x.CouponDiscountTaxInPrice,
                        x.CanApplyHosting,
                        x.IsRsPending,
                        x.IsDisallowPaymentBank,
                        x.IsDisallowPaymenNetCvs,
                        x.IsBillingWait,
                        x.CanApplySsl,
                        x.SslPrepaymentExpirationDate,
                        x.IsRsDiscount,
                        x.CanRsDiscountRenewDate,
                        false,
                        x.IsRestorableDomain
                    );
                    if (renewDomainItem.isDefCheckedDomain) {
                        renewDomainItem.setCountDown(this._zone, this._changeDetectorRef);
                    }
                    return renewDomainItem;
                });

                if (this.renewDomains.length > 0) {
                    this.isShowCouponDomainDetail = this.isShowCouponDomainDetail && this.renewDomains[0].couponCode.length == 0;

                    if (this.isShowCouponDomainDetail) {
                        this.renewDomains[0].selectedUpdateYear = this.renewDomains[0].updateYearItems.length > 1 ? "2" : this.renewDomains[0].selectedUpdateYear;
                    }
                }

                let domainCanShowRibonArr = this.renewDomains.filter(x => x.isActiveDomain && x.canRsDiscountRenewDate && !x.isRestorableDomain && this._filterRsTldDomain(x.domainNameMl));
                if (domainCanShowRibonArr.length > 0) {
                    this._getShowRibonStatusAndPrice(domainCanShowRibonArr);
                }

                this.isOnamaeUser = resHubDto.IsOnamaeUser;
                var listFirstDomainName = this.renewDomains.length > 0 ? this.renewDomains[0].domainNameMl : "";
                var listFirstDomainString = DomainUtil.ExtractionDomainString(listFirstDomainName);
                dataLayer.push({
                    'event': 'event_sendListFirstDomainNameMl',
                    'ListFirstDomain': listFirstDomainString
                });
                const pageView: PageView = super.getPager(resHubDto.TotalCount, this.currentPage, this.pageSize);
                this.maxPage = pageView.totalPages;
                this.pageNoList = pageView.pages;

                //ドメイン非保持アカウントの場合はここで終了
                //if (this.renewDomains.length === 0) {
                //    //this.isDomainTableLoading = false;
                //    this.isAllLoading = false;
                //}
                this._domainSearchDropdownViewModel.setDomainSearchConditionConfig(new DomainSearchConditionConfig(true, true, true, true, false, true, true));
                this._toggleDomainNameAll();
                this._toggleUpdateYearAll();

                this.hasCoupon = resHubDto.HasGmopon;

                // データの再取得が必要な場合後の処理を飛ばす
                if (this._isRetrying) {
                    return;
                }

                // データ表示後に更新ボタンの位置を再計算する
                this._handleScrollEvent();

                if (this.renewDomains.filter(x => x.isCheckedDomain).length > 1) {
                    this.isCheckedMoreThanOneDomain = true;
                } else {
                    this.isCheckedMoreThanOneDomain = false;
                }
                this.isDomainTableLoading = false;

                if (!this.isInitSearched) {
                    this.isInitSearched = true;

                    // Cookie削除
                    docCookies.removeItem('domainRenewalMsg', void (0), resHubDto.IssueCookieDomain);

                    if (resHubDto.IsIssueCookie) {
                        // 有効期限はフロントで取得する(翌月1日)
                        const now: Date = new Date();
                        const issueCookieExpires: Date = new Date(now.getFullYear(), now.getMonth() + 1, 1);

                        // Cookie書込
                        docCookies.setItem('domainRenewalMsg', resHubDto.IssueCookieValue, issueCookieExpires.toString(), void (0), resHubDto.IssueCookieDomain);
                    }

                    //ログイン率確認。遷移元が商材ログインページの場合、タグを発火する
                    const isProduct: boolean = (window.productFlg === "Product" || window.productFlg === "Staging");
                    const domainAdLoginUrl: string = isProduct ? "https://www.onamae.com/navi/login" : "https://www.onamae.test/navi/login";
                    const referrerPath = window.ReferrerPath || "";
                    const referrerAtSignOn = window.ReferrerAtSignOn || "";
                    if (referrerPath === '/auth/signon' && referrerAtSignOn.startsWith(domainAdLoginUrl)) {
                        CustomizedEvents.dispatchAdLoginPageToDomainSettingRenewList();
                    }

                    /**
                     * ドメイン更新完了画面から遷移している　　　　 & 支払い方法がクレカ以外であった時　→　ドメイン更新確認モーダルを開く
                     * ワンクリック更新の対象ドメインを保持している & カード情報を保持している　　　　　→　ワンクリックモーダルを開く
                    */
                    const isTargetPath = (referrerPath != "/login" && referrerPath != "/sp/login" && referrerPath != "/domain/setting/renew/list" && referrerPath != "/auth/signon" && referrerPath != "/auth/singlesignon");
                    if (this.renewDomains.length > 0 && isTargetPath) {
                        if (this.isReturnFromDoneSite &&
                            this.renewDonePageData !== null &&
                            this.renewDonePageData.paymentType !== this.PaymentType.RegisteredCreditCard) {
                            this.openRenewDomains();
                        }
                    }
                }
                if (this.renewDomains.length > 0) {
                    this.isDisplayFreeRegistDomain = false;
                    this.isCheckedFreeRegistDomain = false;
                    this._setDataFreeDomain();
                } else {
                    this.isDisplayFreeRegistDomain = false;
                    this.isCheckedFreeRegistDomain = false;
                    this.listFreeRegistDomain = [];
                    this.notificationFreeRegistDomain = this.FreeDomainNotPriorityTld;
                }

                this.exprireDomains = this.renewDomains.filter(x => x.isCheckedDomain === true);
                this.exprireDomainsForAbTest = this.renewDomains.filter(x => x.isCheckedDomain === true);
                let totalOwnedDomain: number = 0;
                if (this.hasDomainId) {
                    this._domainSearchDropdownViewModel.setIsDisableView(true);
                    totalOwnedDomain = await this._getDomainListCount();
                } else {
                    totalOwnedDomain = resHubDto.TotalCount;
                }

                //申込1年以内のドメインを保持するアカウントである場合タグ発火
                //「ドメインID」「個数」の付与理由はチケットを確認　http://gmo-ticket.internal-gmo/issues/234677?SOOM
                CustomizedEvents.dispatchHasDomainAppliedWithinOneYear({ proxyId: window.ProxyId, totalDomain: resHubDto.TotalCount, oneyearTotalDomain: resHubDto.AppliedWithinOneYear.TotalCount });
                if (totalOwnedDomain === 1) {
                    this.isHaveOnlyOneDomain = true;
                    const AB = this.serviceContainer.ABCookieService;
                    this._domainSearchDropdownViewModel.setIsDisableView(true);
                } else {
                    this.isHaveOnlyOneDomain = false;
                }
                if (totalOwnedDomain === 0) {
                    this.isHasDomain = false;
                    this._domainSearchDropdownViewModel.setIsDisableView(true);
                } else {
                    this.isHasDomain = true;
                }
                if (totalOwnedDomain === resHubDto.AppliedWithinOneYear.TotalCount) {
                    this.isNaviRenewFirstYear = true;
                } else {
                    this.isNaviRenewFirstYear = false;
                }
                if (totalOwnedDomain > 0 && resHubDto.AppliedWithinOneYear.TotalCount === 0) {
                    let currentDate = new Date();
                    currentDate.setMonth(currentDate.getMonth() + 2);
                    this.currentMonth = currentDate.getMonth().toString();
                    this.isShowButtonOverYear = true;
                }
                if (totalOwnedDomain < 5) {
                    this.isOwnedMoreThanFiveDomain = false;
                } else {
                    this.isOwnedMoreThanFiveDomain = true;
                }
                this.exprireDomainsForAbcdTest = this.renewDomains.filter(x => Number(x.renewalDeadlineCount) <= 60);
                if (!this.isShowButtonOverYear) {
                    this.isExistDefCheckedDomain = domainRenewList.some(x => x.IsDefCheckedDomain);
                } else {
                    this.isExistDefCheckedDomain = this.exprireDomainsForAbcdTest.length > 0;
                }
                this.isShowNotice = true;
            },
            (resHubDto: DomainRenewListAndCouponResponseHubDto) => {
                this.isSkipModalConfirm = true;
                this.Error(resHubDto);
            },
            (resHubDto: DomainRenewListAndCouponResponseHubDto) => {
                this.isSkipModalConfirm = true;
                this.NoSession(resHubDto);
            },
            null,
            (resHubDto: DomainRenewListAndCouponResponseHubDto) => {
                if (this._isRetrying) {
                    return;
                }
                this.isSkipModalConfirm = true;
                this.UnExceptedError(resHubDto);
            }
        );
    }


    private _filterRsTldDomain(domainNameML: string): boolean {
        let isValid = false;
        isValid = RegExp('(\\.com$)|(\\.net$)|(\\.org$)|(\\.biz$)|(\\.info$)|(\\.xyz$)|(\\.link$)|(\\.click$)|(\\.tokyo$)|(\\.blog$)|(\\.site$)|(\\.online$)|(\\.tech$)').test(domainNameML)
        return isValid;
    }

    /**
     * ドメインの情報が条件を満たす場合、フキダシが表示できる。
     * */
    private _getShowRibonStatusAndPrice(renewDomainChecked: RenewDomainItem[]): void {
        const request: DomainRenewShowPriceRibonRequestHubDto = {
            LineCnt: this.pageSize,
            PageNo: 1,
            RenewDomains: renewDomainChecked.map(x => {
                return {
                    DomainName: x.domainName,
                    DomainNameMl: x.domainNameMl,
                    ProductIdRenewOneYear: x.updateYearItems?.find(y => y.year === '1')?.productId ?? '',
                    Tld: x.tld
                } as DomainRenewShowRibonCheckDomainRequestHubDto
            }),
            SortList: this.sortPriorityList.map(x => {
                return {
                    Key: x,
                    Type: this.sortList[x]
                } as DomainRenewSortRequestHubDto;
            })
        };
        this.serviceContainer.HubsService.multipleSendManager.Send(
            this.queueId,
            this.serviceContainer.HubsService.hubsManager.domainRenewHubManager.DomainRenewShowRibon,
            request,
            (response: DomainRenewShowPriceRibonResponseHubDto) => {
                this.totalPriceDomainRegistRS = response.TotalPrice;
            },
            null,
            null,
            (response: DomainRenewShowPriceRibonResponseHubDto) => {
                this.domainNameMlShowRibon = response.DomainNameMl;
                this.isShowRibbon = response.IsShowRibon;
            },
            null
        );
    }

    public closeFloatingTooltip(): void {
        this.isShowRibbon = false;
    }

    private _getDomainListCount(): Promise<number> {
        // 検索条件取得
        let searchCondition: DomainSearchCondition;
        searchCondition = this._domainSearchDropdownViewModel.getDomainSearchCondition();


        // STANDARD = 0,       // 通常
        // INCLUDECOUPON = 1,  // レスポンスにクーポン情報を含める
        // ONLYCOUPON = 2      // クーポンがあるドメインのみ
        let searchedType = 'STANDARD';
        if (this.hasCoupon) {
            searchedType = 'INCLUDECOUPON';
        }

        const reqHubDto: DomainRenewListRequestHubDto = {
            CurrentPage: this.currentPage,
            PageSize: this.pageSize,
            SearchedType: searchedType,
            SearchCondition: {
                DomainIds: '',
                DomainNames: searchCondition.domainNames,
                GroupName: searchCondition.domainGroupName,
                CreatedDateFrom: searchCondition.createdDateFrom,
                CreatedDateTo: searchCondition.createdDateTo,
                ExpirationDateFrom: searchCondition.expirationDateFrom,
                ExpirationDateTo: searchCondition.expirationDateTo,
                WhoisProxyStatus: searchCondition.whoisProxyStatus,
                CheckedCoupon: false
            } as DomainRenewListSearchCondition,
            SortList: this.sortPriorityList.map(x => {
                return {
                    Key: x,
                    Type: this.sortList[x]
                } as DomainRenewListSortRequestHubDto;
            })
        } as DomainRenewListRequestHubDto;

        this.promiseGetDomainListCount = new Promise<number>((resolva, reject) => {
            this.serviceContainer.HubsService.multipleSendManager.Send(
                this.queueId,
                this.serviceContainer.HubsService.hubsManager.domainRenewHubManager.GetDomainRenewList,
                reqHubDto,
                (resHubDto: DomainRenewListResponseHubDto) => {
                    resolva(resHubDto.TotalCount)
                },
                (resHubDto: DomainRenewListResponseHubDto) => {
                    resolva(0)
                },
                (resHubDto: DomainRenewListResponseHubDto) => {
                    resolva(0)
                },
                null,
                (resHubDto: DomainRenewListResponseHubDto) => {
                    resolva(0)
                });
        });
        return this.promiseGetDomainListCount;
    }

    /**
     * 更新契約対象のドメイン一覧を取得します
     */
    private _requestGetRenewDomains(): void {
        this.isDomainTableLoading = true;
        this.isShowRibbon = false;
        this.totalPriceDomainRegistRS = '0';
        this.domainNameMlShowRibon = '';
        // 検索条件取得
        let searchCondition: DomainSearchCondition;
        searchCondition = this._domainSearchDropdownViewModel.getDomainSearchCondition();
        let _isClickNewOptionSearch = this._domainSearchDropdownViewModel.getNewOptionSearch();

        // STANDARD = 0,       // 通常
        // INCLUDECOUPON = 1,  // レスポンスにクーポン情報を含める
        // ONLYCOUPON = 2      // クーポンがあるドメインのみ
        let searchedType = 'STANDARD';
        if (this.hasCoupon) {
            searchedType = 'INCLUDECOUPON';
        }
        let _expirationDateTo = searchCondition.expirationDateTo;
        let _groupName = searchCondition.domainGroupName;
        let _createdDateFrom = searchCondition.createdDateFrom;
        let _createdDateTo = searchCondition.createdDateTo;
        let _expirationDateFrom = searchCondition.expirationDateFrom;
        let _whoisProxyStatus = searchCondition.whoisProxyStatus;
        if (_isClickNewOptionSearch) {
            _groupName = '';
            _createdDateFrom = '';
            _createdDateTo = '';
            _expirationDateFrom = '';
            _whoisProxyStatus = 'BOTH';
        }
        if (searchCondition.isDeadlineWithin30Day && this.isOwnedMoreThanFiveDomain && !_isClickNewOptionSearch) {
            _expirationDateTo = this._expirationDateTo();
        }
        const reqHubDto: DomainRenewListRequestHubDto = {
            CurrentPage: this.currentPage,
            PageSize: this.pageSize,
            SearchedType: searchedType,
            SearchCondition: {
                DomainIds: this.getRequestDomainIds(),
                DomainNames: searchCondition.domainNames,
                GroupName: _groupName,
                CreatedDateFrom: _createdDateFrom,
                CreatedDateTo: _createdDateTo,
                ExpirationDateFrom: _expirationDateFrom,
                ExpirationDateTo: _expirationDateTo,
                WhoisProxyStatus: _whoisProxyStatus,
                CheckedCoupon: false
            } as DomainRenewListSearchCondition,
            SortList: this.sortPriorityList.map(x => {
                return {
                    Key: x,
                    Type: this.sortList[x]
                } as DomainRenewListSortRequestHubDto;
            })
        } as DomainRenewListRequestHubDto;

        if (this.renewDomains.length > 0) {
            this.renewDomains.forEach((x) => { if (x.countDown) { x.countDown.clearInterval(); } });
        }

        this.serviceContainer.HubsService.multipleSendManager.Send(
            this.queueId,
            this.serviceContainer.HubsService.hubsManager.domainRenewHubManager.GetDomainRenewList,
            reqHubDto,
            (resHubDto: DomainRenewListResponseHubDto) => {
                this.renewDomains = resHubDto.DomainRenewList.map(x => {
                    const domainRenewList = resHubDto.DomainRenewList.filter((value, index) => index < this.pageSize);
                    if (!this.isShowButtonOverYear) {
                        this.isExistDefCheckedDomain = domainRenewList.some(x => x.IsDefCheckedDomain);
                    } else {
                        this.isExistDefCheckedDomain = domainRenewList.filter(x => Number(x.RenewalDeadlineCount) <= 60).length > 0;
                    }
                    this.hasRestoreDomain = domainRenewList.some(x => x.IsRestorableDomain);
                    const isCheckedDomain: boolean = ((this.hasDomainId && x.IsActiveDomain) || x.IsDefCheckedDomain) && !x.IsRsPending;
                    const isEnableWhoisProduct: boolean = isCheckedDomain && x.CanApplyWhoisProduct && x.IsAppliedWhoisProxy === false && x.IsAppliedWhoisMailFwd === false;

                    let renewDomainItem = new RenewDomainItem(
                        x.IsActiveDomain,
                        isCheckedDomain,
                        isCheckedDomain,
                        x.DomainId,
                        x.DomainName,
                        x.DomainNameML,
                        x.Tld,
                        x.IsRegistryPremium,
                        x.CanSetAutoRenew,
                        x.IsDefCheckedDomain,
                        x.ExpirationDate,
                        x.RenewalDeadlineDate,
                        x.RenewalDeadlineCount,
                        '-',
                        '-',
                        x.DefRenewYear,
                        x.RenewYearProduct.map(y => {
                            return new UpdateYearItem(y.RenewYear, y.ProductId);
                        }),
                        x.IsAppliedWhoisProxy,
                        x.IsAppliedWhoisMailFwd,
                        x.IsAppliedSsl,
                        x.CanApplyWhoisProduct,
                        isEnableWhoisProduct,
                        false,
                        x.IsDomainProtect,
                        x.IsProtectedWhois,
                        x.IsProtectedAutoRenew,
                        x.IsAutoRenewActive,
                        x.CouponCode,
                        x.CouponDiscoutnUnTaxPrice,
                        x.CouponDiscountTaxInPrice,
                        x.CanApplyHosting,
                        x.IsRsPending,
                        x.IsDisallowPaymentBank,
                        x.IsDisallowPaymenNetCvs,
                        x.IsBillingWait,
                        x.CanApplySsl,
                        x.SslPrepaymentExpirationDate,
                        x.IsRsDiscount,
                        x.CanRsDiscountRenewDate,
                        false,
                        x.IsRestorableDomain
                    );
                    if (renewDomainItem.isDefCheckedDomain) {
                        renewDomainItem.setCountDown(this._zone, this._changeDetectorRef);
                    }
                    return renewDomainItem;
                });

                let domainCanShowRibonArr = this.renewDomains.filter(x => x.isActiveDomain && x.canRsDiscountRenewDate && !x.isRestorableDomain && this._filterRsTldDomain(x.domainNameMl));
                if (domainCanShowRibonArr.length > 0) {
                    this._getShowRibonStatusAndPrice(domainCanShowRibonArr);
                }

                var listFirstDomainName = this.renewDomains.length > 0 ? this.renewDomains[0].domainNameMl : "";
                var listFirstDomainString = DomainUtil.ExtractionDomainString(listFirstDomainName);
                dataLayer.push({
                    'event': 'event_sendListFirstDomainNameMl',
                    'ListFirstDomain': listFirstDomainString
                });

                const pageView: PageView = super.getPager(resHubDto.TotalCount, this.currentPage, this.pageSize);
                this.maxPage = pageView.totalPages;
                this.pageNoList = pageView.pages;

                this.hostingAllowedDomainName = '';
                this.hostingRecommendId = '';

                this._toggleDomainNameAll();
                this._toggleUpdateYearAll();

                this.isDomainTableLoading = false;

                // データ表示後に更新ボタンの位置を再計算する
                this._handleScrollEvent();

                if (this.renewDomains.filter(x => x.isCheckedDomain).length > 1) {
                    this.isCheckedMoreThanOneDomain = true;
                } else {
                    this.isCheckedMoreThanOneDomain = false;
                }

                if (this.renewDomains.length > 0) {
                    this.isDisplayFreeRegistDomain = false;
                    this.isCheckedFreeRegistDomain = false;
                    this._setDataFreeDomain();
                } else {
                    this.isDisplayFreeRegistDomain = false;
                    this.isCheckedFreeRegistDomain = false;
                    this.listFreeRegistDomain = [];
                    this.notificationFreeRegistDomain = this.FreeDomainNotPriorityTld;
                }
            },
            (resHubDto: DomainRenewListResponseHubDto) => {
                this.renewDomains = [];
                const pageView: PageView = super.getPager(0, this.currentPage, this.pageSize);
                this.maxPage = pageView.totalPages;
                this.pageNoList = pageView.pages;

                this.isDomainTableLoading = false;
            },
            (resHubDto: DomainRenewListResponseHubDto) => {
                this.isSkipModalConfirm = true;
                this.NoSession(resHubDto);
            },
            null,
            (resHubDto: DomainRenewListResponseHubDto) => {
                this.isSkipModalConfirm = true;
                this.UnExceptedError(resHubDto);
            }
        );
    }

    /**
    * 日付の取得（当日）
   **/
    private _expirationDateFrom() {
        const todayYmd = new Date();
        const x = todayYmd.getFullYear()
            + '/' + ('0' + (todayYmd.getMonth() + 1)).slice(-2)
            + '/' + ('0' + todayYmd.getDate()).slice(-2)
        return (x)
    }

    /**
     * 日付の取得（30日後）
    **/
    private _expirationDateTo() {
        const todayYmd = new Date(this._expirationDateFrom());
        todayYmd.setDate(todayYmd.getDate() + 30);
        const y = todayYmd.getFullYear()
            + '/' + ('0' + (todayYmd.getMonth() + 1)).slice(-2)
            + '/' + ('0' + todayYmd.getDate()).slice(-2)
        return (y)
    }

    private getRequestDomainIds(): string {
        let _reqDomainIds: string = '';

        if (!!this._domainIds) {
            _reqDomainIds = this._domainIds;
            this._domainIds = '';
            return _reqDomainIds;
        }

        _reqDomainIds = this.hasDomainId ? this._domainId : '';
        return _reqDomainIds;
    }

    /**
     * 1．ホスティング商材の申し込み可/不可チェック
     * 2．選択されているドメインの支払い方法チェック
     * 3．ドメイン更新可能か検証
     * 4．ドメイン更新確認モーダルを開く
     * */
    public openRenewDomains(): void {
        this._requestCheckDomainRenewHostingOrder();
    }

    /**
     * ホスティング商材の申し込み可/不可チェック
     */
    private _requestCheckDomainRenewHostingOrder(): void {
        let targetDomains: RenewDomainItem[];
        if (this.isButtonBeClicked) {
            targetDomains = this.renewDomains.filter(x => x.domainId === this.domainIdButton);
        } else {
            targetDomains = this.renewDomains.filter(x => x.isCheckedDomain);
        }
        const reqHubDto: DomainRenewHostingOrderCheckRequestHubDto = {
            RenewDomains: targetDomains.map(x => {
                return {
                    DomainName: x.domainName,
                    Tld: x.tld
                } as DomainRenewHostingOrderCheckDomainRequestHubDto;
            })
        } as DomainRenewHostingOrderCheckRequestHubDto;

        this._multiInquiryFlag.raise();
        this.serviceContainer.HubsService.multipleSendManager.Send(
            this.queueId,
            this.serviceContainer.HubsService.hubsManager.domainRenewHubManager.CheckDomainRenewHostingOrder,
            reqHubDto,
            (resHubDto: DomainRenewHostingOrderCheckResponseHubDto) => {
                // RSオプション申し込み可否
                this.hostingAllowedDomainName = '';
                this.hostingRecommendId = '';
                if (resHubDto.IsAllowed) {
                    this.hostingAllowedDomainName = resHubDto.AllowedDomainName;
                    this.hostingRecommendId = resHubDto.HostingRecommendId;

                    const domainInfo: DomainRenewHostingInformation[] = resHubDto.DomainInfo;
                    this._canRecommendHosting = new Array<{ domainName: string, hasRecommend: boolean }>();
                    domainInfo.forEach(x => this._canRecommendHosting.push({ domainName: x.DomainName, hasRecommend: x.HasRecommend }));
                }

                this.isHostingUnpaid = resHubDto.IsUnpaid;
                this._multiInquiryFlag.down();

                this._checkCurrentPayment();
            },
            (resHubDto: DomainRenewHostingOrderCheckResponseHubDto) => {
                this._multiInquiryFlag.down();
                this.isSkipModalConfirm = true;
                this.Error(resHubDto);
            },
            (resHubDto: DomainRenewHostingOrderCheckResponseHubDto) => {
                this.isSkipModalConfirm = true;
                this.NoSession(resHubDto);
            },
            null,
            (resHubDto: DomainRenewHostingOrderCheckResponseHubDto) => {
                this.isSkipModalConfirm = true;
                this.UnExceptedError(resHubDto);
            }
        );
    }

    // 選択されているドメインの支払い方法チェック
    private _checkCurrentPayment(): void {
        this.conveniInfo = {
            ConveniCode: '',
            UserNameKana: ''
        };

        // 更新完了ページから遷移してきたときは支払い方法を引き継ぐ
        if (this.isReturnFromDoneSite &&
            this.renewDonePageData !== null) {

            switch (this.renewDonePageData.paymentType) {
                case DomainPaymentType.None:
                    // 支払方法指定なし
                    this.isDomainTableLoading = false;
                    this.isAllLoading = false;
                    return;

                case DomainPaymentType.RegisteredCreditCard:
                    // 登録済みクレジットカード
                    let canUseCredit = this._domainRenewPaymentComponent.canUseCredit();
                    if (canUseCredit === false) {
                        this.isDomainTableLoading = false;
                        this.isAllLoading = false;
                        return;
                    }

                    if (!this.renewDonePageData.registeredMaskedCreditNumber) {
                        return;
                    }
                    this.registeredMaskedCreditNumber = this.renewDonePageData.registeredMaskedCreditNumber;

                    break;

                case DomainPaymentType.Convenience:
                    // コンビニエンスストア
                    if (this.isDisallowPaymentNetCvs) {
                        this.isDomainTableLoading = false;
                        this.isAllLoading = false;
                        return;
                    }

                    if (!this.renewDonePageData.conveniInfo.ConveniCode) {
                        return;
                    }
                    this.conveniInfo.ConveniCode = this.renewDonePageData.conveniInfo.ConveniCode;
                    this.conveniInfo.UserNameKana = this.renewDonePageData.conveniInfo.UserNameKana;

                    break;

                case DomainPaymentType.Bank:
                    // 銀行
                    if (this.isDisallowPaymentBank) {
                        this.isDomainTableLoading = false;
                        this.isAllLoading = false;
                        return;
                    }
                    break;

                default:
                    // コンビニ以外は支払い方法があればいいので何もしない
                    break;
            }

            this.paymentType = this.renewDonePageData.paymentType;

            // これ以降、完了ページより引き継いだデータは不要
            this.renewDonePageData = null;

            this._requestValidRenewDomains();
            return;
        }

        switch (this.paymentType) {
            case DomainPaymentType.None:
                // 支払方法指定なし
                const notify = new NotifyMessage(this.serviceContainer.NotifyService.GetNotifyId(), NotifyType.Error, "お支払い方法を設定してください。");
                this.serviceContainer.NotifyService.AddNotifyMessages([notify]);
                this.isDomainTableLoading = false;
                this.isAllLoading = false;
                return;

            case DomainPaymentType.RegisteredCreditCard:
                // 登録済みクレジットカード
                let canUseCredit = this._domainRenewPaymentComponent.canUseCredit();
                if (canUseCredit === false) {
                    const notify = new NotifyMessage(this.serviceContainer.NotifyService.GetNotifyId(), NotifyType.Error, NotifyMessage.CREDIT_CARD_HAS_EXPIRED_NOTIFY);
                    this.serviceContainer.NotifyService.AddNotifyMessages([notify]);
                    this.isDomainTableLoading = false;
                    this.isAllLoading = false;
                    return;
                }

                this.registeredMaskedCreditNumber = this._domainRenewPaymentComponent.getRegisteredMaskedCreditNumber();

                break;

            case DomainPaymentType.Convenience:
                // コンビニエンスストア
                if (this.isDisallowPaymentNetCvs) {
                    const errors = this._getActiveDomains().filter(x => x.isCheckedDomain).filter(x => x.isDisallowPaymenNetCvs).map(x => '(' + x.domainNameMl + ') はコンビニでお支払いできません。選択を解除するか、お支払い方法を変更してお手続きください。');
                    const notify = new NotifyMessage(this.serviceContainer.NotifyService.GetNotifyId(), NotifyType.Error, '', errors);
                    this.serviceContainer.NotifyService.AddNotifyMessages([notify]);
                    this.isDomainTableLoading = false;
                    this.isAllLoading = false;
                    return;
                }

                const convenience: DomainConvenience = this._domainRenewPaymentComponent.getConvenienceDetail();
                this.conveniInfo.ConveniCode = convenience.selectedConvenienceType.toString();
                this.conveniInfo.UserNameKana = convenience.payeeNameKana;

                break;

            case DomainPaymentType.Bank:
                // 銀行
                if (this.isDisallowPaymentBank) {
                    const errors = this._getActiveDomains().filter(x => x.isCheckedDomain).filter(x => x.isDisallowPaymentBank).map(x => '(' + x.domainNameMl + ') は銀行振込でお支払いできません。選択を解除するか、お支払い方法を変更してお手続きください。');
                    const notify = new NotifyMessage(this.serviceContainer.NotifyService.GetNotifyId(), NotifyType.Error, '', errors);
                    this.serviceContainer.NotifyService.AddNotifyMessages([notify]);
                    this.isDomainTableLoading = false;
                    this.isAllLoading = false;
                    return;
                }
                break;

            default:
                // コンビニ以外は支払い方法があればいいので何もしない
                break;
        }

        this._requestValidRenewDomains();
    }

    /**
     * ドメイン更新可能か検証
     */
    private _requestValidRenewDomains(): void {
        let checkedRenewDomains: RenewDomainItem[];
        if (this.isButtonBeClicked) {
            checkedRenewDomains = this.renewDomains.filter(x => x.domainId === this.domainIdButton);
        } else {
            checkedRenewDomains = this.renewDomains.filter(x => x.isCheckedDomain);
        }
        this.isDomainTableLoading = true;

        const reqHubDto: DomainRenewUpdateValidationRequestHubDto = {
            PaymentType: this.paymentType.toString(),
            ConveniInfo: this.conveniInfo,
            HasSetAutoRenewPayment: this._hasSetAutoRenewPayment,
            RenewDomains: checkedRenewDomains.map(x => {
                return {
                    DomainId: x.domainId,
                    Period: x.updateYearItems.find(y => y.year === x.selectedUpdateYear).year,
                    CurExpDate: x.expirationDate,
                    CanSetAutoRenew: x.canSetAutoRenew,
                    IsAppliedWhoisProxy: x.isAppliedWhoisProxy,
                    IsWhoisProxy: false,
                    IsAppliedWhoisMailFwd: x.isAppliedWhoisMailFwd,
                    IsWhoisMailFwd: x.isAppliedWhoisMailFwd,
                    IsAppliedDomainProtect: x.isAppliedDomainProtect,
                    IsDomainProtect: x.isAppliedDomainProtect,
                    IsAppliedSsl: x.isAppliedSsl,
                    IsSsl: false,
                    IsProtectedWhois: x.isProtectedWhois,
                    IsProtectedAutoRenew: x.isProtectedAutoRenew,
                    IsAutoRenewActive: x.isAutoRenewActive,
                    IsRegistryPremium: x.isRegistryPremium,
                    CouponCode: x.couponCode,
                    DomainName: x.domainName,
                } as DomainRenewUpdateValidationDomainRequestHubDto;
            }),
            HostingRecommendId: this.hostingRecommendId,
            HostingDomainName: this.hostingAllowedDomainName,
            GmoPoint: this.gmoPointSetting.usePoint
        } as DomainRenewUpdateValidationRequestHubDto;

        this.serviceContainer.HubsService.multipleSendManager.Send(
            this.queueId,
            this.serviceContainer.HubsService.hubsManager.domainRenewHubManager.ValidUpdateDomainRenew,
            reqHubDto,
            (resHubDto: DomainRenewUpdateValidationResponseHubDto) => {
                const transferData: IDataTransferDomainRenewDto = {
                    unValidatedDomains: checkedRenewDomains.map(x => x.convertDto() as IDataTransferRenewDomainItemDto),
                    validatedDomains: checkedRenewDomains.map(x => {
                        return {
                            domainId: x.domainId,
                            domainName: x.domainName,
                            domainNameMl: x.domainNameMl,
                            tld: x.tld,
                            expirationDate: x.expirationDate,
                            isRegistryPremium: x.isRegistryPremium,
                            canSetAutoRenew: x.canSetAutoRenew,
                            updateYear: x.selectedUpdateYear,
                            updateYearProductId: x.updateYearItems.find(y => y.year === x.selectedUpdateYear).productId,
                            isAppliedWhoisProxy: x.isAppliedWhoisProxy,
                            isWhoisProxy: false,
                            isAppliedWhoisMailFwd: x.isAppliedWhoisMailFwd,
                            isWhoisMailFwd: x.isAppliedWhoisMailFwd,
                            IsAppliedDomainProtect: x.isAppliedDomainProtect,
                            isDomainProtection: x.isAppliedDomainProtect,
                            isAppliedSsl: x.isAppliedSsl,
                            isSsl: false,
                            isHosting: false,
                            isProtectedWhois: x.isProtectedWhois,
                            isProtectedAutoRenew: x.isProtectedAutoRenew,
                            isAutoRenewActive: x.isAutoRenewActive,
                            couponCode: x.couponCode,
                        } as IDataTransferSelectedDomainDto;
                    }),
                    hostingRecommendId: this.hostingRecommendId,
                    hostingDomainName: this.hostingAllowedDomainName,
                    isHostingUnpaid: this.isHostingUnpaid,
                    isDisallowPaymentBank: checkedRenewDomains.some(x => x.isDisallowPaymentBank),
                    isDisallowPaymenNetCvs: checkedRenewDomains.some(x => x.isDisallowPaymenNetCvs),
                    hasSetAutoRenewPayment: this._hasSetAutoRenewPayment,
                    gmoPointInfo: this.gmoPointSetting,
                    paymentType: this.paymentType,
                    conveniInfo: this.conveniInfo,
                    registeredMaskedCreditNumber: this.registeredMaskedCreditNumber,
                    hasCoupon: checkedRenewDomains.some(x => x.couponCode.length > 0),
                    isOnamaeUser: this.isOnamaeUser,
                    convenienceFee: StringUtil.NumberToStringWithComma(this.convenienceFee),
                    convenienceUnTaxFee: StringUtil.NumberToStringWithComma(this.convenienceUnTaxFee)
                } as IDataTransferDomainRenewDto;
                this.serviceContainer.DataTransferService.setData(transferData, this.serviceContainer.DataTransferService.DomainRenewKey);
                if (this.paymentTypeForFreeRegistDomain !== DomainPaymentType.RegisteredCreditCard && this.paymentTypeForFreeRegistDomain !== DomainPaymentType.NewCreditCard) {
                    this.isDisplayFreeRegistModal = false;
                    this.isCheckedFreeRegistDomain = false;
                    this.isDisableFreeRegist = true;
                }
                this.isDisplayFreeRegistModal = this.isCheckedFreeRegistDomain;
                this.freeRegistDomainData = {
                    ContactInfo: this.contactInfo,
                    PaymentType: this._getPaymentTypeText(this.paymentTypeForFreeRegistDomain),
                    ConveniCode: this.conveniInfo.ConveniCode,
                    DomainNames: [this.freeRegistDomain],
                    NewCard: this.paymentTypeForFreeRegistDomain == DomainPaymentType.NewCreditCard ? 'yes' : 'no',
                    Periods: ['1'],
                    Requester: 'TestClient',
                    GmoponCode: '',
                    NameServers: ['dns1.onamae.com', 'dns2.onamae.com'],
                    AutoRenews: ['yes'],
                    Proxyflg: 'yes',
                    FreeTld: this.FreeDomainTldStr
                } as DomainRenewCreateDomainRequestHubDto
                this.renewDomainsData = transferData;
                this.isSkipModalConfirm = true;
                this.isDomainTableLoading = false;
                this.isAllLoading = false;
                this.isShowNewConfirmModal = true;
                return;

            },
            (resHubDto: DomainRenewUpdateValidationResponseHubDto) => {
                this.isSkipModalConfirm = false;
                const notifyMessage: NotifyMessage = new NotifyMessage(this.serviceContainer.NotifyService.GetNotifyId(), NotifyType.Error, resHubDto.ErrorMessage);
                this.serviceContainer.NotifyService.AddNotifyMessages([notifyMessage]);
                this.isDomainTableLoading = false;
            },
            (resHubDto: DomainRenewUpdateValidationResponseHubDto) => {
                this.isSkipModalConfirm = true;
                this.NoSession(resHubDto);
            },
            () => {
                this.isAllLoading = false;
            },
            (resHubDto: DomainRenewUpdateValidationResponseHubDto) => {
                this.isSkipModalConfirm = true;
                this.UnExceptedError(resHubDto);
            }
        );
    }

    /**
     * domain_renew_payment.componentから受け取ったカード情報有無を元にAパターンフローかBパターンフローか判定する
     */
    public onDecideCreditCardInfo(hasRegisteredCreditCard: boolean): void {
        const referrerPath = window.ReferrerPath;
        const isTargetPath = (referrerPath != "/login" && referrerPath != "/sp/login" && referrerPath != "/domain/setting/renew/list" && referrerPath != "/auth/signon" && referrerPath != "/auth/singlesignon");
        if (!isTargetPath) {
            return;
        }

        // ドメイン更新完了画面から遷移してきた場合
        if (this.isReturnFromDoneSite &&
            this.renewDonePageData !== null &&
            this.renewDonePageData.paymentType !== this.PaymentType.RegisteredCreditCard) {
            return;
        }
    }
    public onChangePaymentMethod(paymentType: DomainPaymentType): void {
        this.paymentTypeForFreeRegistDomain = paymentType;
        if (this.paymentTypeForFreeRegistDomain !== DomainPaymentType.RegisteredCreditCard && this.paymentTypeForFreeRegistDomain !== DomainPaymentType.NewCreditCard) {
            this.isDisplayFreeRegistModal = false;
            this.isCheckedFreeRegistDomain = false;
            this.isDisableFreeRegist = true;
        } else {
            this.isDisableFreeRegist = false;
        }
    }
    /**
     * 表示の種類
     */
    public onClickDomainRenewType(type: DomainRenewType): void {
        this.SelctedDomainRenewType = type;
        this.currentPage = 1;
        this._domainSearchDropdownViewModel.getDomainSearchCondition().clear();
        this._requestGetRenewDomains();
    }

    /**
     * 表示件数変更
     */
    public onChangePageSize(event: Event): void {
        const currentElement: HTMLInputElement = event.currentTarget as HTMLInputElement
        this.pageSize = parseInt(currentElement.value);
        this.currentPage = 1;
        this._requestGetRenewDomains();
    }

    /**
     * ソート
     */
    public onClickSortDomain(sortKey: string): void {
        const index: number = this.sortPriorityList.indexOf(sortKey);
        this.sortPriorityList.splice(index, 1);
        this.sortPriorityList.unshift(sortKey);

        if (this.sortList[sortKey] === 1) {
            this.sortList[sortKey] = 2;
        } else {
            this.sortList[sortKey] = 1;
        }

        this._requestGetRenewDomains();
    }

    /**
     * ページャー変更
     */
    public onClickPageNo(pageNo: number): void {
        if (pageNo === this.currentPage || 0 >= pageNo || this.maxPage < pageNo) {
            return;
        }
        this.currentPage = pageNo;

        this._requestGetRenewDomains();
    }

    private _getActiveDomains(): RenewDomainItem[] {
        return this.renewDomains.filter(x => x.isActiveDomain && x.canRsDiscountRenewDate);
    }

    public _getCheckedDomains(): RenewDomainItem[] {
        return this._getActiveDomains().filter(x => x.isCheckedDomain);
    }

    /**
     * (すべての)ドメイン名のチェック時
     */
    public onChangeRenewDomainAll(): void {
        this._getActiveDomains().forEach(x => {
            if (!x.isRestorableDomain) {
                x.isCheckedDomain = this.renewDomainHeader.isCheckedDomainAll;
                // 永続無料対象ドメインで更新不可期間の際は除外
                if ((x.isRsDiscount && !x.canRsDiscountRenewDate)) {
                    x.isCheckedDomain = false;
                }
                x.isEnableWhoisProduct = x.isCheckedDomain && x.canApplyWhoisProduct && x.isAppliedWhoisProxy === false && x.isAppliedWhoisMailFwd === false;
                if (this.renewDomainHeader.isCheckedDomainAll === false) {
                    x.totalUnTaxPrice = '-';
                    this.exprireDomains = [];
                }
            }
        });

        this._toggleDomainNameAll();
        this._toggleUpdateYearAll();

        if (this.renewDomains.filter(x => x.isCheckedDomain).length > this.DOMAIN_RENEW_LIMIT) {
            this._showDomainLimitAlert();
        } else {
            this.serviceContainer.NotifyService.notifyMessageViewModel.notifyErrorMessageList = [];
        }
        if (this.renewDomains.filter(x => x.isCheckedDomain).length > 1) {
            this.isCheckedMoreThanOneDomain = true;
        } else {
            this.isCheckedMoreThanOneDomain = false;
        }
    }
    /**
     * ドメイン名のチェック時
     */
    public onChangeRenewDomain(domainNameMl: string): void {
        this._getActiveDomains().forEach(x => {
            if (x.isCheckedDomain === false) {
                x.totalUnTaxPrice = '-';
            }
        });

        this._toggleDomainNameAll();
        this._toggleUpdateYearAll();
        if (this.renewDomains.filter(x => x.isCheckedDomain).length > this.DOMAIN_RENEW_LIMIT) {
            if (this.serviceContainer.NotifyService.notifyMessageViewModel.notifyErrorMessageList.length == 0) {
                this._showDomainLimitAlert();
            } else {
                this._showDomainLimitAlert_noScroll();
            }
        } else {
            this.serviceContainer.NotifyService.notifyMessageViewModel.notifyErrorMessageList = [];
        }
        if (this.renewDomains.filter(x => x.isCheckedDomain).length > 1) {
            this.isCheckedMoreThanOneDomain = true;
        } else {
            this.isCheckedMoreThanOneDomain = false;
        }
    }

    /**
     * (すべての)契約年数選択時
     */
    public onChangeUpdateYearAll(): void {
        this.renewDomains.filter(x => x.isActiveDomain).forEach(x => {
            if ((x.isRsDiscount && !x.canRsDiscountRenewDate) || x.isRestorableDomain) {
                x.isCheckedDomain = false;
            } else {
                x.isCheckedDomain = true
            }
        });

        this._getActiveDomains()
            .filter(x => x.isCheckedDomain && x.updateYearItems.some(y => y.year === this.renewDomainHeader.updateYearAll))
            .forEach(x => {
                if (x.isRsDiscount) {
                    x.selectedUpdateYear = "1"
                } else {
                    x.selectedUpdateYear = this.renewDomainHeader.updateYearAll
                }
                if (parseInt(x.selectedUpdateYear) > 5) {
                    x.isShowMoreThan5RenewYear = true;
                }
            });
        this._toggleDomainNameAll();
    }

    public isShowCouponList(): boolean {
        return (this.SelctedDomainRenewType === this.DomainRenewType.Coupon);
    }
    public onChangeFreeRegistDomain(): void {
        this.isDisplayFreeRegistModal = this.isCheckedFreeRegistDomain;
        window.dataLayerPush('free_domain_regist_check');
    }

    /**
     * 全体ドメインの選択状態確認
     */
    private _toggleDomainNameAll(): void {
        this.renewDomainHeader.isCheckedDomainAll = this._getActiveDomains().length > 0 && this._getActiveDomains().every(x => x.isCheckedDomain);
    }
    private _getPaymentTypeText(paymentDomain: DomainPaymentType): string {
        let paymenTypeText = '';
        switch (paymentDomain) {
            case DomainPaymentType.RegisteredCreditCard:
            case DomainPaymentType.NewCreditCard:
                paymenTypeText = DomainPaymentTypeText.CreditCard;
                break;
            case DomainPaymentType.Bank:
                paymenTypeText = DomainPaymentTypeText.Bank;
                break;
            case DomainPaymentType.Convenience:
                paymenTypeText = DomainPaymentTypeText.Convenience;
                break;
            default:
        }
        return paymenTypeText;
    }

    private _displayFreeRegistDomain(): void {
        this.isDisplayFreeRegistDomain = this.listFreeRegistDomain.filter(x => x.statusFreeDomain === '1').length > 0;
        if (this.isDisplayFreeRegistDomain) {
            this.freeRegistDomain = this.listFreeRegistDomain.filter(x => x.statusFreeDomain === '1')[0].freeDomain;
            this.freeRegistDomainId = this.listFreeRegistDomain.filter(x => x.statusFreeDomain === '1')[0].domainId;
            this.isCheckedFreeRegistDomain = false;
            this.isDisplayFreeRegistModal = true;
        } else {
            this.freeRegistDomain = '';
            this.freeRegistDomainId = '';
            this.isCheckedFreeRegistDomain = false;
            this.isDisplayFreeRegistModal = false;
        }
    }

    private async _setDataFreeDomain(): Promise<void> {
        if (this.renewDomains.length > 0) {
            for (var i = 0; i < this.renewDomains.length; i++) {
                let renewDomain = this.renewDomains[i];
                let _splitString = this.renewDomains[i].domainNameMl.split(".");
                let _splitDomain = _splitString[0];
                let _alreadyCallAPI = false;
                if (this.listGetFreeRegistDomainFromAPI.length > 0) {
                    _alreadyCallAPI = this.listGetFreeRegistDomainFromAPI.filter(x => x.domainNameWithoutTlds == _splitDomain).length > 0
                } else {
                    _alreadyCallAPI = false;
                }
                let _tlds = [this.FreeDomainTldStr];
                const reqHubDto: DomainRenewFreeRegistDomainRequestHubDto = {
                    DomainName: _splitDomain,
                    Tlds: _tlds,
                };
                if (!this.isDisplayFreeRegistDomain) {
                    if (!_alreadyCallAPI) {
                        let _listFreeRegistDomainPromise = this._getDataFreeDomainFromApi(reqHubDto, _splitDomain, renewDomain.domainId, renewDomain.domainNameMl, renewDomain.isActiveDomain, renewDomain.canRsDiscountRenewDate);
                        await _listFreeRegistDomainPromise.then((_freeRegistDomainPromise) => {
                            if (_freeRegistDomainPromise !== null && _freeRegistDomainPromise !== void (0) && _freeRegistDomainPromise.length > 0) {
                                if (_freeRegistDomainPromise[0].domainId !== '') {
                                    this.listFreeRegistDomain = _freeRegistDomainPromise;
                                    this.notificationFreeRegistDomain = this.FreeDomainNotPriorityTld;
                                    this._displayFreeRegistDomain();
                                } else {
                                    if (i === this.renewDomains.length - 1) {
                                        this.notificationFreeRegistDomain = this.FreeDomainNotPriorityTld;
                                        this._displayFreeRegistDomain();
                                    }
                                }
                            }
                        });
                    } else {
                        let _filterFreeRegistDomain = this.listGetFreeRegistDomainFromAPI.filter(x => x.domainNameWithoutTlds == _splitDomain);
                        if (_filterFreeRegistDomain[0].statusFreeDomain !== '' && _filterFreeRegistDomain[0].statusFreeDomain === this.FREE_DOMAIN_UNREGISTERED && renewDomain.isActiveDomain && renewDomain.canRsDiscountRenewDate) {
                            this.listFreeRegistDomain = _filterFreeRegistDomain;
                            this.notificationFreeRegistDomain = this.FreeDomainNotPriorityTld;
                            this._displayFreeRegistDomain();
                        }
                    }
                }
            }
        }
    }

    private _getDataFreeDomainFromApi(reqHubDto: DomainRenewFreeRegistDomainRequestHubDto, _splitDomain: string, _domainId: string, _domainNameMl: string, _isActiveDomain: boolean, _canRsDiscountRenewDate: boolean): Promise<FreeDomainInfo[]> {
        let _listFreeRegistDomain: FreeDomainInfo[] = [];
        this.promiseGetFreeDomain = new Promise<FreeDomainInfo[]>((resolva, reject) => {
            this.serviceContainer.HubsService.multipleSendManager.Send(
                this.queueId,
                this.serviceContainer.HubsService.hubsManager.domainRenewHubManager.GetStatusOfFreeRegistDomain,
                reqHubDto,
                (resHubDto: DomainRenewFreeRegistDomainResponseHubDto) => {
                    if (resHubDto.List.length > 0) {
                        resHubDto.List.forEach((z) => {
                            if (z.DomainStatus !== '' && z.DomainStatus === this.FREE_DOMAIN_UNREGISTERED && _isActiveDomain && _canRsDiscountRenewDate) {
                                _listFreeRegistDomain.push({ domainId: _domainId, domainNameMl: _domainNameMl, domainNameWithoutTlds: _splitDomain, freeDomain: z.DomainName, statusFreeDomain: z.DomainStatus });
                            } else {
                                _listFreeRegistDomain.push({ domainId: '', domainNameMl: '', domainNameWithoutTlds: '', freeDomain: '', statusFreeDomain: '' });
                            }
                            this.listGetFreeRegistDomainFromAPI.push({ domainId: _domainId, domainNameMl: _domainNameMl, domainNameWithoutTlds: _splitDomain, freeDomain: z.DomainName, statusFreeDomain: z.DomainStatus });
                            resolva(_listFreeRegistDomain);
                        });
                    }
                },
                (resHubDto: DomainRenewFreeRegistDomainResponseHubDto) => {
                    resolva(null);
                },
                (resHubDto: DomainRenewFreeRegistDomainResponseHubDto) => {
                },
                null,
                (resHubDto: DomainRenewFreeRegistDomainResponseHubDto) => {
                }
            );
        });
        return this.promiseGetFreeDomain;
    }

    /**
     * 全体更新の選択状態確認
     */
    private _toggleUpdateYearAll(): void {
        this.renewDomainHeader.isActiveUpdateYearAll = this._getActiveDomains().some(x => x.isCheckedDomain);
    }

    /**
     * 確認ボタンを押下時
     */
    public onClickConfirm() {
        this.isButtonBeClicked = false;
        // サーバからレスポンスが返ってきていない場合は実行しない
        if (this._multiInquiryFlag.isRaise()) {
            return;
        }

        const checkedRenewDomains: RenewDomainItem[] = this.renewDomains.filter(x => x.isCheckedDomain);

        // ドメインにチェックが入っていない場合は実行しない
        if (checkedRenewDomains.length <= 0) {
            const notify: NotifyMessage = new NotifyMessage(this.serviceContainer.NotifyService.GetNotifyId(), NotifyType.Error, 'ドメインを選択してください。');
            this.serviceContainer.NotifyService.AddNotifyMessages([notify]);
            return;
        }

        if (checkedRenewDomains.filter(x => (x.isRsDiscount && !x.canRsDiscountRenewDate)).length > 0) {
            const notify: NotifyMessage = new NotifyMessage(this.serviceContainer.NotifyService.GetNotifyId(), NotifyType.Error, 'ドメインを選択してください。');
            this.serviceContainer.NotifyService.AddNotifyMessages([notify]);
            return;
        }

        // サーバーの通信料制限にかかってしまうため、申し込み可能件数は50件以下とする
        if (checkedRenewDomains.length > this.DOMAIN_RENEW_LIMIT) {
            this._showDomainLimitAlert();
            return;
        }

        if (checkedRenewDomains.length === 1) {
            this.isSelectedOnlyOneDomain = true;
        } else {
            this.isSelectedOnlyOneDomain = false;
        }
        this.isOpenModal = true;
        this.isAllLoading = true;

        // 選択されている支払い方法を適用
        this._domainRenewPaymentComponent.onDicidedPaymentMethod();
        this.gmoPointSetting.usePoint = "0";
        if (this._domainRenewPaymentComponent.currentForm && this._domainRenewPaymentComponent.currentForm.dirty) {
            this.isAllLoading = false;
        }
        window.dataLayerPush('domain_renew_next_button_' + checkedRenewDomains.length);
    }

    public onClickRowConfirm(domainId: string) {
        //廃止済みドメインはドメイン復旧画面へ遷移させる
        if (this.renewDomains.findIndex(x => x.domainId === domainId && x.isRestorableDomain) > -1) {
            this.router.navigateByUrl('/domain/setting/restore/' + domainId + '?btn_id=02_restore');
            return;
        }

        this.onClickCheckedDomain = false;
        this.isButtonBeClicked = true;
        this.domainIdButton = domainId;
        // サーバからレスポンスが返ってきていない場合は実行しない
        if (this._multiInquiryFlag.isRaise()) {
            return;
        }
        const checkedRenewDomains: RenewDomainItem[] = this.renewDomains.filter(x => x.domainId === domainId);
        if (checkedRenewDomains.length > 0) {
            checkedRenewDomains[0].isCheckedDomain = true;
        }
        if (checkedRenewDomains.filter(x => (x.isRsDiscount && !x.canRsDiscountRenewDate)).length > 0) {
            const notify: NotifyMessage = new NotifyMessage(this.serviceContainer.NotifyService.GetNotifyId(), NotifyType.Error, 'ドメインを選択してください。');
            this.serviceContainer.NotifyService.AddNotifyMessages([notify]);
            return;
        }
        this.isAllLoading = true;

        // 選択されている支払い方法を適用
        this._domainRenewPaymentComponent.onDicidedPaymentMethod();
        this.gmoPointSetting.usePoint = "0";
        if (this._domainRenewPaymentComponent.currentForm && this._domainRenewPaymentComponent.currentForm.dirty) {
            this.isAllLoading = false;
        }
        this.isSelectedOnlyOneDomain = true;
        this.isOpenModal = true;
    }

    /**
     * メッセージリスト取得
     */
    public requestMessages(): void {
        this.serviceContainer.HubsService.multipleSendManager.Send(
            this.queueId,
            this.serviceContainer.HubsService.hubsManager.domainHubManager.GetDomainMessages,
            null,
            (response: DomainMessageResponseHubDto) => {
                let _isDisabledRecoverRecommend = docCookies.getItem('isDisabledRecoverRecommend');
                for (var message of response.Messages) {
                    if (message.LocationId === 14) {
                        this.recoverContents = message.InnerHtml;
                        if (!_isDisabledRecoverRecommend) {
                            this.canDisplayRecoverRecommend = true;
                        }
                        continue;
                    }
                }
            },
            (response: DomainMessageResponseHubDto) => {
            },
            this.NoSession,
            null,
            this.UnExceptedError
        );
    }

    public onClickCloseRecoverRecommend(): void {
        this.isShowModalRecoverRecommend = true;
    }

    public onClickModalRecoverModal(isDisabledRecoverRecommend: boolean): void {
        this.canDisplayRecoverRecommend = false;
        this.isShowModalRecoverRecommend = false;
        if (isDisabledRecoverRecommend) {
            var expires = new Date('9999,11,31');
            docCookies.setItem('isDisabledRecoverRecommend', true, expires, "/");
        }
    }

    public onClickOutsideCloseModalRecover(event: Event) {
        if (event.currentTarget === event.target) {
            this.canDisplayRecoverRecommend = false;
            this.isShowModalRecoverRecommend = false;
        }
    }


    public onClickShowMoreThan5RenewYear(domainId: string) {
        this.onClickCheckedDomain = false;
        this.renewDomains.filter(x => x.domainId === domainId && x.isActiveDomain).forEach(x => {
            x.isShowMoreThan5RenewYear = true;
        });
    }

    public redirectHelpPage(): void {
        this.onClickCheckedDomain = false;
        window.open("https://help.onamae.com/answer/20328", "_blank", "noopener,noreferrer");
    }

    /**
     * ドメイン名のチェック時
     */
    public onClickRenewDomainOneRow(event: Event, domainNameMl: string): boolean {
        if (this.onClickCheckedDomain) {
            this.renewDomains.filter(x => x.domainNameMl == domainNameMl).forEach(x => {
                if (x.isActiveDomain && x.canRsDiscountRenewDate) {
                    x.isCheckedDomain = !x.isCheckedDomain;
                    this.onChangeRenewDomain(domainNameMl);
                }
            });
        } else {
            this.onClickCheckedDomain = true;
        }
        event.preventDefault();
        event.stopImmediatePropagation();
        return false;
    }
    /**
     * 離脱防止
     */
    public onClickModalConfirm(isMove: boolean) {
        this._modalResolveFn(isMove);
    }

    public onClickOutsideCloseModalConfirm(event: Event) {
        if (event.currentTarget === event.target) {
            this._modalResolveFn(false);
        }
    }

    /**
     * ドメインを50件以上選択していた時(スクロールあり)
     * */
    private _showDomainLimitAlert(): void {
        const notify: NotifyMessage = new NotifyMessage(this.serviceContainer.NotifyService.GetNotifyId(), NotifyType.Error, '一度に選択いただけるドメイン数は50件です。(現在' + this._checkedDomainCount + '件選択中)');
        this.serviceContainer.NotifyService.AddNotifyMessages([notify]);
        return;
    }
    /**
     * ドメインを50件以上選択していた時(スクロールなし)
     * */
    private _showDomainLimitAlert_noScroll(): void {
        const notify: NotifyMessage = new NotifyMessage(this.serviceContainer.NotifyService.GetNotifyId(), NotifyType.Error, '一度に選択いただけるドメイン数は50件です。(現在' + this._checkedDomainCount + '件選択中)');
        this.serviceContainer.NotifyService.AddNotifyMessagesWithoutScroll([notify]);
        return;
    }

    private get _checkedDomainCount(): number {
        return this.renewDomains.filter(x => x.isCheckedDomain).length;
    }

    /**
    * 単体更新年数選択時
    */
    public onChangeUpdateYear(value: string, index: number): void {
        if (this.renewDomains[index].isCheckedDomain) {
            this.onClickCheckedDomain = false;
        } else {
            this.onClickCheckedDomain = true;
        }
        this.renewDomains[index].selectedUpdateYear = value;
        this._toggleDomainNameAll();
        this._toggleUpdateYearAll();

    }

    /**
     * 申し込み確認モーダル(新デザイン)閉じるボタン
     * */
    public onCloseConfirmModalButtonClicked(tranferdataUpdateYear: IDataTransferUpdateYearModal[]): void {
        this.isSkipModalConfirm = false;
        this.isShowNewConfirmModal = false;
        this.updateYearFromModal(tranferdataUpdateYear);
    }

    public onCloseModal(tranferdataUpdateYear: IDataTransferUpdateYearModal[]): void {
        this.updateYearFromModal(tranferdataUpdateYear);
    }

    private updateYearFromModal(tranferdataUpdateYear: IDataTransferUpdateYearModal[]) {
        if (tranferdataUpdateYear.length > 0) {
            for (var i = 0; i < tranferdataUpdateYear.length; i++) {
                if (this.renewDomains.filter(x => x.domainId === tranferdataUpdateYear[i].domainId).length > 0) {
                    if (parseInt(tranferdataUpdateYear[i].year) > 5) {
                        this.renewDomains.find(x => x.domainId === tranferdataUpdateYear[i].domainId).isShowMoreThan5RenewYear = true;
                    }
                    this.renewDomains.find(x => x.domainId === tranferdataUpdateYear[i].domainId).selectedUpdateYear = tranferdataUpdateYear[i].year;
                }
            }
        }
    }

    /**
     * 申し込み確認モーダル(新デザイン)セッション切れ
     * @param resHubDto
     */
    public noSession(resHubDto: any): void {
        this.isSkipModalConfirm = true;
        this.isShowNewConfirmModal = false;
        this.NoSession(resHubDto);
    }

    /**
     * 申し込み確認モーダル(新デザイン) エラー
     * @param resHubDto
     */
    public onError(resHubDto: any): void {
        // メッセージを表示させるのみでエラー画面には遷移しないため
        this.isSkipModalConfirm = false;
        this.isShowNewConfirmModal = false;
        this.renewDomainsData = null;

        const errorMessage: string = resHubDto.ErrorMessage;
        const notify: NotifyMessage = new NotifyMessage(this.serviceContainer.NotifyService.GetNotifyId(), NotifyType.Error, errorMessage);
        this.serviceContainer.NotifyService.AddNotifyMessages([notify]);
    }

    /**
     * 申し込み確認モーダル(新デザイン) 予期せぬエラー
     * @param resHubDto
     */
    public onUnExceptedError(resHubDto: any): void {
        this.isSkipModalConfirm = true;
        this.isShowNewConfirmModal = false;
        this.onUnExceptedError(resHubDto);
    }

    /**
     * データ再取得判定
     */
    private _retryRequestGetRenewDomainsAndCoupon(resHubDto: DomainRenewListResponseHubDto): void {
        // 保持していたページNoのページがデータ数減少により消滅していた場合、
        // デフォルトの表示件数・ページNoでデータ取得し直す
        if (resHubDto.DomainRenewList.length === 0 && this.currentPage > 1) {
            this._isRetrying = true;
            this.pageSize = this._DEFAULT_LINECNT;
            this.currentPage = this._DEFAULT_PAGENO;
            this._requestGetRenewDomainsAndCoupon();
        } else {
            this._isRetrying = false;
        }
    }

    /**
     * ページ件数リセット（更新完了画面の訴求ボタンからの遷移を考慮）
    */
    private _resetPageCount() {
        this._isRetrying = true;
        this.currentPage = this._DEFAULT_PAGENO;
        this._requestGetRenewDomainsAndCoupon();
    }

    /**
     * 更新ドメイン一覧取得時の条件を設定（ログアウト時モーダルから遷移してきた場合のみ）
     */
    private _setRenewDomainsSearchCondition(): void {
        let _domianIds = this.serviceContainer.ModalForDomainRenewService.domainIds;

        if (_domianIds && _domianIds.length > 0) {
            this.currentPage = this._DEFAULT_PAGENO;
            this.pageSize = 50;

            _domianIds.forEach((x, i) => {
                if (i == _domianIds.length - 1) {
                    this._domainIds += x
                } else {
                    this._domainIds += x + '\n'
                }
            });

            this.serviceContainer.ModalForDomainRenewService.clearDomainIds();
        }
    }

    private _getInformationFreeTldDotOnline() {
        this.FreeDomainTldStr = "online";
        this.FreeDomainNotPriorityTld = ".online";

    }
    private _getInformationFreeTldDotShop() {
        this.FreeDomainTldStr = "shop";
        this.FreeDomainNotPriorityTld = ".shop";

    }

    public onClickPaymentInvoice() {
        window.dataLayerPush('renew_invoicecheck');
        this.router.navigate(['payment/invoice/list/domain']);
    }

    public checkStatusNsToFireEvent(): void {
        this.serviceContainer.DomainWithDefaultNameServerService.GetDomainWithDefaultNameServer().then((resHubDto) => {
            if (docCookies.getItem("transferType") !== "STAFF_LOGIN") {
                if (resHubDto.hasDomainWithDefaultNameServer) {
                    CustomizedEvents.dispatchEventFiringAddition({ eventValue: 'event494' });
                }
                if (resHubDto.hasDomainWithoutDefaultNameServer && !resHubDto.hasDomainWithDefaultNameServer) {
                    CustomizedEvents.dispatchEventFiringAddition({ eventValue: 'event495' });
                }
            }
        });
    }

    /**
    * GMOクーポンコードが使用可能か確認する
    */
    public gmoponValidator(checkCouponFlag: boolean, isShowCoupon1500Yen: boolean) {
        var gmoponRequest: GmoPonDomainRequestHubDto = {
            Screen: "Domain_Renew_Modal_new",
            CheckCouponFlag: checkCouponFlag,
            IsShowCoupon1500Yen: isShowCoupon1500Yen
        };
        this.serviceContainer.HubsService.multipleSendManager.Send(
            this.queueId,
            this.serviceContainer.HubsService.hubsManager.domainHubManager.GmoponValidator,
            gmoponRequest,
            (response: GmoponValidatorResponseHubDto) => {
                this.gmoTaxedDiscount = response.TaxedDiscountValue;
            },
            null,
            null,
            null,
            null
        );
    }
    /**
     * RS登録日に従って、eventが発火します。
     */
    public fireEventRsStatistics(): void {
        this.serviceContainer.HubsService.multipleSendManager.Send(
            this.queueId,
            this.serviceContainer.HubsService.hubsManager.accountHubManager.GetFirstDayRegistrationRs,
            null,
            (response: FirstDayRegistrationRsResponseHubDto) => {
                sessionStorage.setItem('receivedFirstDayRegistrationRs', 'y');
                if (response?.FirstDayRegistration != null) {
                    CustomizedEvents.dispatchFireEventRsStatistics({ eventValue: this._DateDiff(response.FirstDayRegistration) ? 'event579' : 'event580' })
                }
            },
            null,
            null,
            null,
            null
        );
    }

    /**
     * 現在の日付と比較して日付の差をチェックし、それを150日と比較します。
     * @param dateCheck
     */
    private _DateDiff(dateCheck: string): boolean {
        const currentDate = new Date();
        const dateConvert = new Date(dateCheck);
        const timeDiff = Math.abs(currentDate.getTime() - dateConvert.getTime());
        const diffInDays = Math.floor(timeDiff / (1000 * 3600 * 24));
        return diffInDays <= 150;
    }

    public onChangeUpdateYearB(index: number): void {
        this.onClickCheckedDomain = false;
        if ((this.renewDomains[index].isRsDiscount && !this.renewDomains[index].canRsDiscountRenewDate)) {
            this.renewDomains[index].isCheckedDomain = false;
        } else {
            this.renewDomains[index].isCheckedDomain = true;
        }

        this._toggleDomainNameAll();
        this._toggleUpdateYearAll();
    }
}

// 空のドメイン一覧用データ
class EmptyData {
    public constructor(
        public domainName: string = " - ",
        public renewalDeadlineDate: string = "----/--/-- (残 - 日）",
        public updataYear: string = "0",
    ) { }
}

export class RenewDomainHeader {
    public constructor(
        public isCheckedDomainAll: boolean,
        public isActiveUpdateYearAll: boolean,
        public updateYearAll: string,
    ) { }
}
class FreeDomainInfo {
    public constructor(
        public domainId: string = '',
        public domainNameMl: string = '',
        public domainNameWithoutTlds: string = '',
        public freeDomain: string = '',
        public statusFreeDomain: string = '',
    ) { }
}
export class RenewDomainItem {

    /** 更新期限をカウントダウンで表示 **/
    public countDown: TimerCountDownUntilDeadLine = null;

    public constructor(
        /** 選択不可能かどうか */
        public readonly isActiveDomain: boolean,
        /** 選択状態 */
        public isExpriedDomain: boolean,
        public isCheckedDomain: boolean,
        public readonly domainId: string,
        public readonly domainName: string,
        public readonly domainNameMl: string,
        public readonly tld: string,
        public readonly isRegistryPremium: boolean,
        public readonly canSetAutoRenew: boolean,
        /** 更新対象かどうか */
        public readonly isDefCheckedDomain: boolean,
        /** 更新期限日 yyyy/mm/dd */
        public readonly expirationDate: string,
        /** 更新可能な期限日 yyyy/mm/dd */
        public readonly renewalDeadlineDate: string,
        /** 新可能な期限日の日数 xxx */
        public readonly renewalDeadlineCount: string,
        /** 税抜合計金額 */
        public totalUnTaxPrice: string,
        /** 税込合計金額 */
        public totalTaxInPrice: string,
        /** 更新年数 */
        public selectedUpdateYear: string,
        /** 選択可能な更新年数 */
        public readonly updateYearItems: UpdateYearItem[],
        /** Whois情報公開代行を申請済みかどうか */
        public readonly isAppliedWhoisProxy: boolean,
        /** WHOISメール転送利用を申請済みかどうか */
        public readonly isAppliedWhoisMailFwd: boolean,
        /** SSL利用中か **/
        public readonly isAppliedSsl: boolean,
        /** Whois情報公開代行もしくはWhois情報公開代行(WHOISメール転送利用)の申し込み対象かどうか */
        public readonly canApplyWhoisProduct: boolean,
        /** Whois情報公開代行もしくはWhois情報公開代行(WHOISメール転送利用)のチェックボックスを表示するかどうか */
        public isEnableWhoisProduct: boolean,
        /** Whois情報公開代行もしくはWhois情報公開代行(WHOISメール転送利用)のチェック状態 */
        public isCheckedWhoisProduct: boolean,
        /** ドメインプロテクションを申し込み状態かどうか */
        public readonly isAppliedDomainProtect: boolean,
        /** Whoisがプロテクト状態かどうか */
        public readonly isProtectedWhois: boolean,
        /** 自動更新がプロテクト状態かどうか */
        public readonly isProtectedAutoRenew: boolean,
        /** 自動更新が有効かどうか　*/
        public readonly isAutoRenewActive: boolean,
        public readonly couponCode: string,
        public couponDiscoutnUnTaxPrice: string,
        public couponDiscountTaxInPrice: string,
        public readonly canApplyHosting: boolean,
        public readonly isRsPending: boolean,
        public readonly isDisallowPaymentBank: boolean,
        public readonly isDisallowPaymenNetCvs: boolean,
        /** 課金待ち状態かどうか */
        public readonly isBillingWait: boolean,
        /** SSL申込可能かどうか **/
        public readonly canApplySsl: boolean,
        /** SSL使用期限（利用中でなければ''） **/
        public readonly sslPrepaymentExpirationDate: string,
        /** ホスティングRS割引対象フラグ */
        public readonly isRsDiscount: boolean,
        /** ドメイン永続無料対象のドメイン更新可否フラグ */
        public readonly canRsDiscountRenewDate: boolean,
        public isShowMoreThan5RenewYear: boolean,
        /** 廃止済み(復旧可能)ドメインか*/
        public readonly isRestorableDomain: boolean
    ) { }

    public setCountDown(zone: NgZone, changeDetectorRef: ChangeDetectorRef) {
        this.countDown = new TimerCountDownUntilDeadLine(this.renewalDeadlineDate, this.tld);
        this.countDown.setInterval(zone, changeDetectorRef);
    }

    public convertDto(): IDataTransferRenewDomainItemDto {
        const items: IDataTransferUpdateYearItemDto[] = [];
        this.updateYearItems.forEach(x => items.push(x.convertDto()));

        var retVal: IDataTransferRenewDomainItemDto = {
            /** 選択不可能かどうか */
            isActiveDomain: this.isActiveDomain,
            /** 選択状態 */
            isCheckedDomain: this.isCheckedDomain,
            domainId: this.domainId,
            domainName: this.domainName,
            domainNameMl: this.domainNameMl,
            tld: this.tld,
            isRegistryPremium: this.isRegistryPremium,
            canSetAutoRenew: this.canSetAutoRenew,
            /** 更新対象かどうか */
            isDefCheckedDomain: this.isDefCheckedDomain,
            /** 更新期限日 yyyy/mm/dd */
            expirationDate: this.expirationDate,
            /** 更新可能な期限日 yyyy/mm/dd */
            renewalDeadlineDate: this.renewalDeadlineDate,
            /** 新可能な期限日の日数 xxx */
            renewalDeadlineCount: this.renewalDeadlineCount,
            /** 税抜合計金額 */
            totalUnTaxPrice: this.totalUnTaxPrice,
            /** 税込合計金額 */
            totalTaxInPrice: this.totalTaxInPrice,
            /** 更新年数 */
            selectedUpdateYear: this.selectedUpdateYear,
            /** 選択可能な更新年数 */
            updateYearItems: this.updateYearItems.map(x => x.convertDto() as IDataTransferUpdateYearItemDto),
            /** Whois情報公開代行を申請済みかどうか */
            isAppliedWhoisProxy: this.isAppliedWhoisProxy,
            /** WHOISメール転送利用を申請済みかどうか */
            isAppliedWhoisMailFwd: this.isAppliedWhoisMailFwd,
            /** SSL利用中か **/
            isAppliedSsl: this.isAppliedSsl,
            /** Whois情報公開代行もしくはWhois情報公開代行(WHOISメール転送利用)の申し込み対象かどうか */
            canApplyWhoisProduct: this.canApplyWhoisProduct,
            /** Whois情報公開代行もしくはWhois情報公開代行(WHOISメール転送利用)のチェックボックスを表示するかどうか */
            isEnableWhoisProduct: this.isEnableWhoisProduct,
            /** Whois情報公開代行もしくはWhois情報公開代行(WHOISメール転送利用)のチェック状態 */
            isCheckedWhoisProduct: this.isCheckedWhoisProduct,
            /** ドメインプロテクションを申し込み状態かどうか */
            isAppliedDomainProtect: this.isAppliedDomainProtect,
            /** Whoisがプロテクト状態かどうか */
            isProtectedWhois: this.isProtectedWhois,
            /** 自動更新がプロテクト状態かどうか */
            isProtectedAutoRenew: this.isProtectedAutoRenew,
            /** 自動更新が有効かどうか　*/
            isAutoRenewActive: this.isAutoRenewActive,
            couponCode: this.couponCode,
            couponDiscoutnUnTaxPrice: this.couponDiscoutnUnTaxPrice,
            couponDiscountTaxInPrice: this.couponDiscountTaxInPrice,
            canApplyHosting: this.canApplyHosting,
            isDisallowPaymentBank: this.isDisallowPaymentBank,
            isDisallowPaymenNetCvs: this.isDisallowPaymenNetCvs,
            /** 課金待ち状態かどうか */
            isBillingWait: this.isBillingWait,
            /** SSL申込可能かどうか **/
            canApplySsl: this.canApplySsl,
            /** SSL使用期限（利用中でなければ''） **/
            sslPrepaymentExpirationDate: this.sslPrepaymentExpirationDate
        } as IDataTransferRenewDomainItemDto;

        return retVal;
    }
}
export class UpdateYearItem {
    public constructor(
        public readonly year: string,
        public readonly productId: string,
    ) { }

    public convertDto(): IDataTransferUpdateYearItemDto {
        var retVal: IDataTransferUpdateYearItemDto = {
            year: this.year,
            productId: this.productId
        } as IDataTransferUpdateYearItemDto;

        return retVal;
    }
}

class GmoPointSetting {
    public useType: PointUsingType = PointUsingType.NotCollaboration;
    public isCollaboration: boolean = false;
    public isOpenedModal: boolean = false;
    public currentPoint: string = '0';
    public usePoint: string = '0';

    public constructor() { }

    public getUsePointWithComma(): string {
        return StringUtil.NumberToStringWithComma(Number(this.usePoint.split(',').join('').trim()));
    }
}

enum DomainRenewType {
    All,
    Coupon
}

enum PointUsingType {
    /** 連携していない */
    NotCollaboration,
    /** ポイントがない */
    NotHavePoint,
    /** ポイントが使用できない(クーポン) */
    NotUsePoint,
    /** ポイントを使用しない */
    NonUsePoint,
    /** ポイントを使用する */
    UsePoint
}

class Flag {
    protected _flag: boolean = false;
    protected _count: number = 0;

    public raise() {
        if (this._count == 0) {
            this._flag = true;
        }
        this._count++;
    }

    public down() {
        if (this._count == 0) {
            return;
        }

        this._count--;
        if (this._count == 0) {
            this._flag = false;
        }
    }

    public isRaise(): boolean {
        return this._count > 0;
    }
}

class TimerCountDownUntilDeadLine {
    protected _timerRewrite: NodeJS.Timer = null;
    protected _timerDetect: NodeJS.Timer = null;

    public deadline: Date = null;

    public expirationTime: string;

    protected readonly REMAINING_DAY_COUNT = '31';

    public dd: string = this.REMAINING_DAY_COUNT;
    public hh: string = '24';
    public MM: string = '60';
    public ss: string = '60';
    public mm: string = '999';

    public constructor(renewalDeadlineDate: string, tld: string) {
        const dateArr: Array<string> = renewalDeadlineDate.split('/');
        let hour = 0;
        let isOrganizationalTypeJp = DomainUtil.DetectionOrganizationalTypeJp(tld);
        let isGeneralUseJp = DomainUtil.DetectionGeneralUseJp(tld);

        if (isOrganizationalTypeJp) {
            this.expirationTime = " 19:00";
            hour = -5;
        }
        if (isGeneralUseJp) {
            this.expirationTime = " 22:00";
            hour = -2;
        }
        this.deadline = new Date(Number(dateArr[0]), Number(dateArr[1]) - 1, Number(dateArr[2]) + 1, hour, 0, 0);
    }

    public setInterval(zone: NgZone, changeDetectorRef: ChangeDetectorRef) {

        if (this._getDifferCount() < 1000) {
            this.dd = '0';
            this.hh = '00';
            this.MM = '00';
            this.ss = '00';
            this.mm = '000';
            return;
        }

        this._timerRewrite = setInterval(function (_this: TimerCountDownUntilDeadLine, _zone: NgZone) {
            let diff = _this._getDifferCount();

            const day = Math.floor(diff / 86400000);
            diff = diff % 86400000;
            const hour = Math.floor(diff / 3600000);
            diff = diff % 3600000;
            const min = Math.floor(diff / 60000);
            diff = diff % 60000;
            const sec = Math.floor(diff / 1000);
            diff = diff % 1000;
            const ms = diff;

            _this.mm = StringUtil.ZeroPadding(ms.toString(), 3);
            _zone.runOutsideAngular(() => {
                _this.MM = StringUtil.ZeroPadding(min.toString(), 2);
                _this.ss = StringUtil.ZeroPadding(sec.toString(), 2);
                _this.hh = StringUtil.ZeroPadding(hour.toString(), 2);
                _this.dd = day.toString();
            });
        }, 10, this, zone);

        this._timerDetect = setInterval(function (_this: TimerCountDownUntilDeadLine, _cdRef: ChangeDetectorRef) {
            if (_this.hasCompleted()) {
                _this.mm = '000';
                _this.clearInterval();
            }
            _cdRef.detectChanges();
        }, 1000, this, changeDetectorRef);
    }

    public clearInterval() {
        this._timerRewrite ? clearInterval(this._timerRewrite) : null;
        this._timerDetect ? clearInterval(this._timerDetect) : null;
    }

    public hasCompleted(): boolean {
        if (this.dd === this.REMAINING_DAY_COUNT) {
            return false;
        }

        return (this.dd + this.hh + this.MM + this.ss).replace(/0/g, '').length === 0;
    }

    protected _getDifferCount(): number {
        const current = new Date();
        return this.deadline.getTime() - current.getTime();

    }
}
