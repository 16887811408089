import { Component } from '@angular/core';

@Component({
    selector: 'message-information',
    template: `
<ul class="list-Message is-Appeal">
    <li class="list-Message-item">
        <span class="list-Message-Content">
            <i class="icon icon-InfoCircle"></i>
            <ng-content></ng-content>
        </span>
    </li>
</ul>`
})
export class MessageInformationComponent {
}