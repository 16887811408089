import { NgModule } from '@angular/core';
import { SpOnamaeRouterLinkDirective } from 'Content/sp/script/directives/custom-router-link.directive';
import { SpLoadingDirective } from 'Content/sp/script/directives/loading.directive';
import { SpModalDirective } from 'Content/sp/script/directives/modal.directive';
import { SpScrollLoadingDirective } from 'Content/sp/script/directives/scroll-loading.directive';
import { SpTopScrollDirective } from 'Content/sp/script/directives/top-scroll.directive';
import { SpPopupDirective } from 'Content/sp/script/directives/popup.directive';
import { SpShowCategoryDirective } from 'Content/sp/script/directives/show-category.directive';

@NgModule({
    declarations: [
        SpModalDirective,
        SpOnamaeRouterLinkDirective,
        SpOnamaeRouterLinkDirective,
        SpScrollLoadingDirective,
        SpTopScrollDirective,
        SpLoadingDirective,
        SpPopupDirective,
        SpShowCategoryDirective,
    ],
    exports: [
        SpModalDirective,
        SpOnamaeRouterLinkDirective,
        SpOnamaeRouterLinkDirective,
        SpScrollLoadingDirective,
        SpTopScrollDirective,
        SpLoadingDirective,
        SpPopupDirective,
        SpShowCategoryDirective,
    ]
})

export class SpUiModule { }
