/// <reference path='../../../definitions/window.d.ts' />

import { ComponentFactoryResolver, ViewContainerRef, Directive, Renderer2, OnChanges, AfterViewChecked, Input, SimpleChanges, Type } from '@angular/core';
import { AfterLoginBaseDirective } from 'Content/script/promotion/common/after_login_base_directive';
import { GetDomainWithDefaultNameServerService } from 'Content/script/promotion/domainuse/service/get_domain_with_default_name_server.service';
import { PromotionDomainUseDomainSettingRenewDoneComponent, DomainSettingRenewDoneFlexView } from 'Content/script/promotion/domainuse/component/domainsettingrenewdone';
import { PromotionDomainUseTopComponent, TopFlexView } from 'Content/script/promotion/domainuse/component/top';
import { PromotionDomainUseEmptyComponent, EmptyFlexView } from 'Content/script/promotion/domainuse/component/empty';
import { PromotionDomainUseOthersComponent, OthersFlexView } from 'Content/script/promotion/domainuse/component/others';
import { IFlexView } from 'Content/script/promotion/common/flexview';
import { ServiceContainer } from '../../../service/service_container';

@Directive({
    selector: '[promotion-domainuse]', 
})

export class PromotionDomainUseDirective extends AfterLoginBaseDirective {

    @Input('componentRef') _componentRef: any;

    protected _oldRouterPath: string = null;
    protected _isAfterLogin: boolean = false;

    //未利用ドメイン情報
    protected _resHubDto: DomainWithDefaultNameServerResponseHubDto = null; 

    //動的コンポーネントを管理
    protected _flexViewInstances: FlexViewInstances;
    protected _flexView: IFlexView;

    //未利用ドメイン取得ロジックを１回しか実行させないクラス　
    protected _runServiceAsyncOneTime: OneTimeExecuter;

    constructor(
        protected _viewContainerRef: ViewContainerRef,
        protected _resolver: ComponentFactoryResolver,
        protected _renderer: Renderer2,
        protected _service: GetDomainWithDefaultNameServerService
    ) {
        super();
        this._flexViewInstances = new FlexViewInstances(this._viewContainerRef, this._resolver, this._renderer);
        this._runServiceAsyncOneTime = new OneTimeExecuter(this._runServiceAsyncFn);
    }

    //ログイン後だけ動くngOnChangesイベント
    onChangesAfterLogin(changes: SimpleChanges) {
        this._isAfterLogin = true;
    }

    //ログイン後だけ動くngDoCheckイベント
    doCheckAfterLogin() {
        if (this._oldRouterPath === this._routerPath) {
            return;
        }

        if (!this._resHubDto) {

            //ドメイン更新一覧表示時は、ドメイン更新画面のロードが終了してからデータを取得する
            if (this._routerPath.startsWith('/domain/setting/renew/list')) {
                if (!!this._componentRef.viewModel) {
                    const renewDomainsRef = this._componentRef.viewModel.renewDomains || [];
                    if (0 < renewDomainsRef.length) {
                        this._runServiceAsyncOneTime.execute();
                    }
                }
            } else {
                this._runServiceAsyncOneTime.execute();
            }
            return;
        }

        if (this._resHubDto.DomainIds.length === 0) {
            return;
        }

        this._flexView = this._flexViewInstances.gett(this._referrerPath, this._routerPath, this._serviceContainer);
        this._oldRouterPath = this._routerPath;
    }

    //ログイン後だけ動くngAfterViewCheckedイベント
    afterViewCheckedAfterLogin() {
        if (!this._flexView) {
            return;
        }

        if (this._flexView.canAttach) {
            this._flexView.attachDirective();
            this._flexView = null;
        }
    }

    //未利用ドメイン取得ロジック　
    protected _runServiceAsyncFn: () => void = () => {
        const promise = this._service.runAsync();

        promise.then(response => {
            this._resHubDto = response;
        }).catch(() => {
            this._resHubDto = null;
        });
    }
}

class FlexViewInstances {

    protected static _map: { [key: string]: any } = {};

    constructor(
        protected _viewContainerRef: ViewContainerRef,
        protected _resolver: ComponentFactoryResolver,
        protected _renderer: Renderer2
    ) { };

    protected _set<T1, T2>(key: string, componentType: Type<T1>, flexViewType: Type<T2>) {
        if (!FlexViewInstances._map[key]) {
            FlexViewInstances._map[key] = new flexViewType(
                this._resolver.resolveComponentFactory(componentType),
                this._viewContainerRef,
                this._renderer
            );
        }
    }

    public gett(referrerPath: string, routerPath: string, serviceContainer: ServiceContainer): IFlexView {
        let key: string;

        if (routerPath.startsWith('/login') || routerPath.startsWith('/logout') || routerPath.startsWith('/error')) {
            key = 'Empty';
            this._set(key, PromotionDomainUseEmptyComponent, EmptyFlexView);
        }

        else if (routerPath.startsWith('/domain/setting/renew/done')) {
            key = 'DomainSettingRenewDone';
            this._set(key, PromotionDomainUseDomainSettingRenewDoneComponent, DomainSettingRenewDoneFlexView);
            this._performABTestAdjustTextButton(serviceContainer);
        }
         
        else if (routerPath.startsWith('/top')) {
            key = 'Top';
            this._set(key, PromotionDomainUseTopComponent, TopFlexView);
            this._performABTestAdjustTextButton(serviceContainer);
        }

        else if (referrerPath.startsWith('/domain/setting/renew/list')) {
            // ドメイン一覧に遷移する際は、ドメイン販促フローティングを除外
            if (!(routerPath ==='/domain')) {
                key = 'Others';
                this._set(key, PromotionDomainUseOthersComponent, OthersFlexView);
                this._performABTestAdjustTextButton(serviceContainer);
            }
        }

        else {
            key = 'Empty';
            this._set(key, PromotionDomainUseEmptyComponent, EmptyFlexView);
        }

        return FlexViewInstances._map[key];
    }

    private _performABTestAdjustTextButton(serviceContainer: ServiceContainer): void {
        serviceContainer.ABCookieService.AdjustTextButton.tests.AdjustTextButton.dispatchEvent_sendCookiesBaggage(null);
    }
}

//execute()を何回呼んでも_execOnceFn()を1回しか実行しないクラス
class OneTimeExecuter {
    public get hasDone(): boolean {
        return !this._execOnceFn;
    }

    private _args: any[];

    constructor(private _execOnceFn: (...args) => any, ...execOnceArgs) {
        this._args = execOnceArgs;
    }

    public execute() {
        if (this._execOnceFn) {
            this._args ? this._execOnceFn(...this._args) : this._execOnceFn();
            this._execOnceFn = null;
        }
    }
}
