import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
    selector: '[loading]'
})

export class SpLoadingDirective implements OnChanges {
    private _el: HTMLElement

    @Input() isLoading: boolean;

    // ぐるぐるを画面中央に固定します
    @Input() isFixed?: boolean = false;
    constructor(private _elementRef: ElementRef) {
        this._el = _elementRef.nativeElement;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        for (const prop in changes) {
            let change = changes[prop];
            if (prop == "isLoading") {
                if (change.currentValue) {
                    this._loadingOn();
                } else {
                    this._loadingOff();
                }
            }
        }
    }

    private _loadingOn() {
        let loading_Loader: HTMLElement = document.createElement("div");
        loading_Loader.classList.add("loading-Loader");

        for (let i = 0; i < 12; i++) {
            let loading_Loader_Blade: HTMLElement = document.createElement('div');
            loading_Loader_Blade.classList.add('loading-Loader-Blade');
            loading_Loader.appendChild(loading_Loader_Blade)
        }
       
        let loading: HTMLElement = document.createElement('div');
        loading.classList.add("loading");
        loading.id = "loading-directive-id";
        loading.appendChild(loading_Loader);
        let loading_Message: HTMLElement = document.createElement("div");
        loading_Message.classList.add("loading-Message");
        loading_Message.innerHTML = "ただいま画面遷移中です。<br>しばらくお待ちください。";
        loading.appendChild(loading_Message);

        if (this.isFixed) {
            loading.style.position = "fixed";
        }

        this._el.classList.add("loadingWrap");
        this._el.appendChild(loading);
    }

    private _loadingOff() {
        this._el.classList.remove("loadingWrap");
        let target = document.getElementById('loading-directive-id');
        if (!!target) target.remove();
    }
}
