/// <reference path='../../../definitions/window.d.ts' />

import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';

import { ServiceContainer } from 'Content/script/service/service_container';
import { NotifyMessage, NotifyType } from 'Content/script/service/notify_service';

import { MultiPaymentManager } from 'Content/script/managers/MultiPaymentManager';

import { AnalyticsUtil } from 'Content/script/libs/adobeanalytics/analytics.util';
import { ValidationRegexsDefine } from 'Content/script/libs/define/validation_regexs.define';
import { StringUtil } from 'Content/script/libs/utils/string.util';
import * as creditCardType from 'credit-card-type';
import { CreditCardListComponent } from '../Templates/creditcard_service/creditcard.component';
/**
 * ドメイン 支払方法 コンポーネント
 */
@Component({
    selector: 'domain-payment',
    templateUrl: './domain_payment.html'
})

export class DomainPaymentComponent {
    public viewModel: DomainPaymentViewModel;

    public constructor(private router: Router, private serviceContainer: ServiceContainer) {
        const queueId = serviceContainer.HubsService.multipleSendManager.GetComponentId();
        this.viewModel = new DomainPaymentViewModel(this.router, queueId, this.serviceContainer);
    }

    protected CacheBusting(uri: string): string {
        return window.CacheBusting(uri);
    }
}

export class DomainPaymentViewModel {
    private _setPaymnetMethodCallBack: (paymnetMethod: number) => void;
    private _partLoadingCallBack: (isPartLoading: boolean) => void;
    private _allLoadingCallBack: (isAllLoading: boolean) => void;
    private _setConvenienceFeeCallBack: (convenienceFee: number, convenienceUnTaxFee: number) => void;
    private _setLatestIsDisablePaymnetCallBack: () => boolean = null;

    public isDisableView: boolean;
    public h2Class: string;

    protected currentPaymentType: DomainPaymentType;

    protected viewCreditCard: DomainCreditCard;
    protected viewConvenience: DomainConvenience;

    protected paymentModal: DomainPaymentModal;

    protected readonly PaymentType = DomainPaymentType;
    protected readonly ConvenienceType = DomainConvenienceType;
    public isVisa: boolean = false;
    public isMasterCard: boolean = false;
    public isJCB: boolean = false;
    public isDinersClub: boolean = false;
    public isAmericanExpress: boolean = false;
    public creditCardLength: number = 19;
    public isValidCreditCard: boolean = true;
    public isPrettyCreditCard: boolean = false;

    public constructor(private router: Router, private queueId: number, private serviceContainer: ServiceContainer) {
        this._setPaymnetMethodCallBack = null;
        this._partLoadingCallBack = null;
        this._allLoadingCallBack = null;
        this._setConvenienceFeeCallBack = null;

        this.isDisableView = false;
        this.h2Class = (router.url === "/domain/setting/renew/list") ? "hdn-Lev3 with-border" : "hdn-Lev3";

        this.currentPaymentType = DomainPaymentType.None;

        this.viewCreditCard = new DomainCreditCard();
        this.viewConvenience = new DomainConvenience();

        this.paymentModal = new DomainPaymentModal();
    }

    /**
     * (外部公開用) コールバック関数を設定
     * @param setPaymnetMethodCallBack 支払い方法変更 ※親コンポーネントに支払い方法を通知するためなので設定以外の処理は禁止
     * @param partLoadingCallBack 部分ローディング
     * @param allLoadingCallBack 全体ローディング
     * @param setConvenienceFeeCallBack コンビニ支払い選択時手数料設定
     */
    public setCallBack(setPaymnetMethodCallBack: (paymnetMethod: number) => void, partLoadingCallBack: (isLoading: boolean) => void, allLoadingCallBack: (isLoading: boolean) => void, setConvenienceFeeCallBack: (convenienceFee: number, convenienceUnTaxFee: number) => void, setLatestIsDisablePaymnetCallBack?: () => boolean): void {
        this._setPaymnetMethodCallBack = setPaymnetMethodCallBack;
        this._partLoadingCallBack = partLoadingCallBack;
        this._allLoadingCallBack = allLoadingCallBack;
        this._setConvenienceFeeCallBack = setConvenienceFeeCallBack;
        if (setLatestIsDisablePaymnetCallBack) {
            this._setLatestIsDisablePaymnetCallBack = setLatestIsDisablePaymnetCallBack;
        }
    }

    /**
     * (外部公開用) 銀行振込/コンビニエンスストアの支払いが選択可能どうかを設定
     * @param disableBank
     * @param disableConvenience
     */
    public setIsDisablePaymnet(isDisableBank: boolean, isDisableConvenience: boolean): void {
        this.paymentModal.isDisableBank = isDisableBank;
        this.paymentModal.isDisableConvenience = isDisableConvenience;
    }

    /**
     * (外部公開用) 初期化
     */
    public init(): void {
        // 支払い方法が非表示時の際は実行しない
        if (this.isDisableView === false) {
            this._requestGetRolePayment();
        }
    }

    /**
     * (外部公開用) 入力されたクレジットカード情報を取得
     */
    public getNewCreditCardDetail(): DomainNewCreditCard {
        return this.paymentModal.inputNewCreditCard;
    }

    /**
     * (外部公開用) 入力されたコンビニエンス情報を取得
     */
    public getConvenienceDetail(): DomainConvenience {
        return this.paymentModal.inputConvenience;
    }

    /**
     * (外部公開用) 登録済みのマスクされたクレジットカード番号(下4桁)を取得
     */
    public getRegisteredMaskedCreditNumber(): string {
        return this.viewCreditCard.creditNumber;
    }

    /**
     * (外部公開用) 登録済みのクレジットカードの有効期限を取得
     */
    public getRegisteredCreditCardPeriodMonthYear(): string {
        return this.viewCreditCard.creditPeriodMonthYear;
    }

    /**
     * (外部公開用) クレジットカードが使用可能かどうか
     */
    public canUseCredit(): boolean {
        return this.viewCreditCard.canUseCredit;
    }
    private _setDefaultCreditValue(): void {
        this.isVisa = false;
        this.isMasterCard = false;
        this.isJCB = false;
        this.isDinersClub = false;
        this.isAmericanExpress = false;
        this.creditCardLength = 19;
        this.isValidCreditCard = false;

    }

    public onKeyDownCreditCard(event: KeyboardEvent) {
        if (event.key != 'Delete' && event.key != 'Backspace') {
            this.isPrettyCreditCard = true;
        } else {
            this.isPrettyCreditCard = false;
        }
    }

    public onChangeCreditCard() {
        let creditCardListComponent = new CreditCardListComponent();
        if (!this.paymentModal.inputNewCreditCard.creditNumber || this.paymentModal.inputNewCreditCard.creditNumber === '') {
            this._setDefaultCreditValue();
            return;
        }
        let cards = creditCardListComponent.filterCardType(this.paymentModal.inputNewCreditCard.creditNumber);

        if (cards.type.isVisa || cards.type.isMasterCard || cards.type.isJCB || cards.type.isDinersClub || cards.type.isAmericanExpress) {
            this.isVisa = cards.type.isVisa;
            this.isMasterCard = cards.type.isMasterCard;
            this.isJCB = cards.type.isJCB;
            this.isDinersClub = cards.type.isDinersClub;
            this.isAmericanExpress = cards.type.isAmericanExpress;
            this.creditCardLength = cards.length;
            this.isValidCreditCard = cards.isValidCard;
            if (this.isPrettyCreditCard) {
                this.paymentModal.inputNewCreditCard.creditNumber = creditCardListComponent.prettyCardNumber(this.paymentModal.inputNewCreditCard.creditNumber, cards.niceType);
            }
        } else {
            this._setDefaultCreditValue();
            if (this.isPrettyCreditCard) {
                this.paymentModal.inputNewCreditCard.creditNumber = creditCardListComponent.prettyCardNumber(this.paymentModal.inputNewCreditCard.creditNumber, creditCardType.types.VISA);
            }
        }

        // セキュリティコードのバリデーションの切り替え
        if (cards.type.isAmericanExpress) {
            this.paymentModal.inputNewCreditCard.rNumberPattern = ValidationRegexsDefine.rAmericanExpressCreditSecurityCode;
        } else {
            this.paymentModal.inputNewCreditCard.rNumberPattern = ValidationRegexsDefine.rNotAmericanExpressCreditSecurityCode;
        }
    }
    /**
     * 支払い方法を設定
     * @param paymnetMethod
     */
    private _setPaymnetMethod(paymnetMethod: DomainPaymentType): void {
        if (this._setPaymnetMethodCallBack) {
            this._setPaymnetMethodCallBack(paymnetMethod);
        }
    }

    /**
     * 部分ローディング処理
     * @param isLoading
     */
    private _partloading(isLoading: boolean): void {
        if (this._partLoadingCallBack) {
            this._partLoadingCallBack(isLoading);
        }
    }

    /**
     * 全体ローディング処理
     * @param isAllLoading
     */
    private _allloading(isAllLoading: boolean): void {
        if (this._allLoadingCallBack) {
            this._allLoadingCallBack(isAllLoading);
        }
    }

    /**
     * コンビニ手数料を設定
     * @param convenienceFee
     */
    private _setConvenienceFee(convenienceFee: number, convenienceUnTaxFee: number): void {
        if (this._setConvenienceFeeCallBack) {
            this._setConvenienceFeeCallBack(convenienceFee, convenienceUnTaxFee);
        }
    }

    /**
     * 支払い方法の権限取得
     */
    private _requestGetRolePayment(): void {
        this._partloading(true);

        this.serviceContainer.HubsService.multipleSendManager.Send(
            this.queueId,
            this.serviceContainer.HubsService.hubsManager.accountHubManager.GetRolePayment,
            null,
            (res: RolePaymentResponseHubDto) => {
                if (res.IsAllowMonthly === true) {
                    this.isDisableView = true;
                    this._setPaymentType(DomainPaymentType.Monthly);
                } else {
                    this._requestGetDomainRegisteredCreditCard();
                }
                this._partloading(false);
            },
            (res: RolePaymentResponseHubDto) => {
                this._partloading(false);
                // エラー
            },
            (res: RolePaymentResponseHubDto) => {
                // セッション切れ
                const queryparams = AnalyticsUtil.getQueryParamsOnFirstRender(window.RouterPath);
                this.router.navigate(['/login'], { queryParams: queryparams });
                return;
            },
            null,
            (res: RolePaymentResponseHubDto) => {
                // 致命的エラー
                this.router.navigateByUrl('/error/server');
                return;
            }
        );
    }

    /**
     * ご登録済みのクレジットカード情報を取得
     */
    private _requestGetDomainRegisteredCreditCard(): void {
        this._partloading(true);

        this.serviceContainer.HubsService.multipleSendManager.Send(
            this.queueId,
            this.serviceContainer.HubsService.hubsManager.invoiceHubManager.InvoiceDomainGetCreditCard,
            null,
            (res: InvoiceDomainGetCreditCardResponseHubDto) => {
                this.paymentModal.registeredCreditCard.hasRegisteredCreditCard = res.IsRegistered;
                this.paymentModal.registeredCreditCard.canUseCredit = res.CanUseCredit;
                this.paymentModal.registeredCreditCard.creditNumber = res.CreditNumber;
                this.paymentModal.registeredCreditCard.creditPeriodMonthYear = res.CreditPeriod;
                this._defaultCheckPaymentMethod(this.paymentModal.registeredCreditCard.hasRegisteredCreditCard);
                this._partloading(false);
            },
            (res: InvoiceDomainCreditCardDetailResponseHubDto) => {
                //エラー
                this.paymentModal.registeredCreditCard.hasRegisteredCreditCard = false;
                this._defaultCheckPaymentMethod(this.paymentModal.registeredCreditCard.hasRegisteredCreditCard);
                this._partloading(false);
            },
            (res: InvoiceDomainCreditCardDetailResponseHubDto) => {
                // セッション切れ
                const queryparams = AnalyticsUtil.getQueryParamsOnFirstRender(window.RouterPath);
                this.router.navigate(['/login'], { queryParams: queryparams });
                return;
            },
            null,
            (res: InvoiceDomainCreditCardDetailResponseHubDto) => {
                // 致命的エラー
                this.router.navigateByUrl('/error/server');
                return;
            }
        );
    }

    /**
     * 初回時のデフォルトの支払い方法を指定
     * @param hasRegisteredCreditCard
     */
    private _defaultCheckPaymentMethod(hasRegisteredCreditCard: boolean): void {
        // 支払い方法が初回の時のみ判定する
        if (this.paymentModal.selectedPaymentType === DomainPaymentType.None || this.paymentModal.selectedPaymentType === DomainPaymentType.NewCreditCard) {
            if (hasRegisteredCreditCard) {
                this._setPaymentType(DomainPaymentType.RegisteredCreditCard);
                this.viewCreditCard = this.paymentModal.registeredCreditCard;
            } else {
                this._setPaymentType(DomainPaymentType.NewCreditCard);
            }
        }
    }

    /**
     * 支払い方法を設定
     * @param paymentType
     */
    private _setPaymentType(paymentType: DomainPaymentType) {
        this._setPaymnetMethod(paymentType);
        this.currentPaymentType = paymentType;
        this.paymentModal.selectedPaymentType = paymentType;
    }

    /**
     * 新規クレジットカードを登録する
     */
    private _updateNewCreditCard(): void {
        this._allloading(true);

        const reqFormData: RequestFormData = {
            cardno: this.paymentModal.inputNewCreditCard.creditNumber.replaceAll(" ", ""),
            expire: this.paymentModal.inputNewCreditCard.creditPeriodYear + this.paymentModal.inputNewCreditCard.creditPeriodMonth,
            securitycode: this.paymentModal.inputNewCreditCard.securityCode,
            holdername: this.paymentModal.inputNewCreditCard.creditHolder,
            tokennumber: "1"
        } as RequestFormData;

        const manager = new MultiPaymentManager(window.OnamaeShopId);
        manager.Send(reqFormData, (res: getTokenResponse) => {
            const req: InvoiceDomainPaymentUpdateCreditCardRequestHubDto = {
                CreditCardToken: res.tokenObject.token[0],
                Num: this.paymentModal.inputNewCreditCard.creditNumber.replaceAll(" ", "").substring(0, 6) + this.paymentModal.inputNewCreditCard.creditNumber.replaceAll(" ", "").substring(this.paymentModal.inputNewCreditCard.creditNumber.replaceAll(" ", "").length - 4)
            } as InvoiceDomainPaymentUpdateCreditCardRequestHubDto;

            this.serviceContainer.HubsService.multipleSendManager.Send(
                this.queueId,
                this.serviceContainer.HubsService.hubsManager.invoiceHubManager.InvoiceDomainPaymentUpdateCreditCard,
                req,
                (res: InvoiceDomainPaymentUpdateCreditCardResponseHubDto) => {
                    this._setPaymnetMethod(this.paymentModal.selectedPaymentType);
                    this.currentPaymentType = this.paymentModal.selectedPaymentType;
                    this.paymentModal.isOpen = false;
                    const notify: NotifyMessage = new NotifyMessage(this.serviceContainer.NotifyService.GetNotifyId(), NotifyType.Info, "クレジットカード情報を更新しました。");
                    this.serviceContainer.NotifyService.AddNotifyMessages([notify]);
                    this._allloading(false);
                    this._requestGetDomainRegisteredCreditCard();
                },
                (res: InvoiceDomainPaymentUpdateCreditCardResponseHubDto) => {
                    // エラー
                    this.paymentModal.isOpen = false;
                    const notify: NotifyMessage = new NotifyMessage(this.serviceContainer.NotifyService.GetNotifyId(), NotifyType.Error, "クレジットカード情報の更新に失敗しました。");
                    this.serviceContainer.NotifyService.AddNotifyMessages([notify]);
                    this._allloading(false);
                },
                (res: InvoiceDomainPaymentUpdateCreditCardResponseHubDto) => {
                    // セッション切れ
                    const queryparams = AnalyticsUtil.getQueryParamsOnFirstRender(window.RouterPath);
                    this.router.navigate(['/login'], { queryParams: queryparams });
                    return;
                },
                null,
                (res: InvoiceDomainPaymentUpdateCreditCardResponseHubDto) => {
                    // 致命的エラー
                    this.router.navigateByUrl('/error/server');
                    return;
                }
            );
        });
    }

    /**
     * 支払い方法(コンビニ)の入力データを検証する
     */
    private _validateConveniencePaymnetMethod(): void {
        this._allloading(true);

        const paymentHubDto: DomainPaymentRequestHubDto = {
            PaymentType: parseInt(DomainPaymentType.Convenience.toString()),
            ConveniCode: this.paymentModal.inputConvenience.selectedConvenienceType.toString(),
            UserNameKana: this.paymentModal.inputConvenience.payeeNameKana,
            CrOrderId: '',
            CreditNumber: '',
            CreditHolder: '',
            CreditPeriodMonth: '',
            CreditPeriodYear: '',
            SecurityCode: '',
        };

        const req: DomainPaymnetValidatorRequestHubDto = {
            DomainPaymentRequestHubDto: paymentHubDto
        };

        this.serviceContainer.HubsService.multipleSendManager.Send(
            this.queueId,
            this.serviceContainer.HubsService.hubsManager.invoiceHubManager.DomainPaymentValidator,
            req,
            (res: DomainPaymnetValidatorResponseHubDto) => {
                this._setPaymnetMethod(this.paymentModal.selectedPaymentType);
                this.currentPaymentType = this.paymentModal.selectedPaymentType;
                this._setConvenienceFee(StringUtil.StringWithCommaToNumber(res.ConvenienceFee), StringUtil.StringWithCommaToNumber(res.ConvenienceUnTaxFee));
                this.paymentModal.inputConvenience.convenienceFee = res.ConvenienceFee;
                this.paymentModal.inputConvenience.convenienceUnTaxFee = res.ConvenienceUnTaxFee;
                this.paymentModal.inputConvenience.convenienceName = this._getConvenienceName(this.paymentModal.inputConvenience.selectedConvenienceType);
                this.viewConvenience = this.paymentModal.inputConvenience;
                this.paymentModal.isOpen = false;
                const notify = new NotifyMessage(this.serviceContainer.NotifyService.GetNotifyId(), NotifyType.Info, "支払い方法を変更しました。");
                this.serviceContainer.NotifyService.AddNotifyMessages([notify]);
                this._allloading(false);
            },
            (res: DomainPaymnetValidatorResponseHubDto) => {
                // エラー
                this.paymentModal.isOpen = false;
                const notify = new NotifyMessage(this.serviceContainer.NotifyService.GetNotifyId(), NotifyType.Error, "支払い方法を変更に失敗しました。");
                this.serviceContainer.NotifyService.AddNotifyMessages([notify]);
                this._allloading(false);
            },
            (res: DomainPaymnetValidatorResponseHubDto) => {
                // セッション切れ
                const queryparams = AnalyticsUtil.getQueryParamsOnFirstRender(window.RouterPath);
                this.router.navigate(['/login'], { queryParams: queryparams });
                return;
            },
            null,
            (res: DomainPaymnetValidatorResponseHubDto) => {
                // 致命的エラー
                this.router.navigateByUrl('/error/server');
                return;
            }
        );
    }

    /**
     * 支払い方法変更ボタンのイベント
     */
    protected onClickPaymentMethodChangeButton(): void {
        if (this._setLatestIsDisablePaymnetCallBack) {
            if (this._setLatestIsDisablePaymnetCallBack()) {
                this.paymentModal.selectedPaymentType = this.paymentModal.registeredCreditCard.hasRegisteredCreditCard ? this.PaymentType.RegisteredCreditCard : this.PaymentType.NewCreditCard;
            } else {
                this.paymentModal.selectedPaymentType = this.currentPaymentType;
            }
        }
        this.paymentModal.isOpen = true;
    }

    /**
     * 支払い方法種別変更時のイベント
     */
    protected onChangePaymentMethodRadio(value: DomainPaymentType): void {
        this.paymentModal.selectedPaymentType = value;
    }

    /**
     * コンビニ種別変更時のイベント
     */
    protected onChangePaymentMethodConvenienceRadio(value: DomainConvenienceType): void {
        this.paymentModal.inputConvenience.selectedConvenienceType = value;
    }

    /**
     * モーダルを閉じるボタンのイベント
     */
    protected onClickPaymentMethodModalCloseButton(): void {
        this.paymentModal.isOpen = false;
    }

    /**
     * モーダルをキャンセルボタンのイベント
     */
    protected onClickPaymentMethodModalCancelButton(): void {
        this.paymentModal.isOpen = false;
    }

    /**
     * モーダル内の更新ボタンのイベント
     */
    protected onClickPaymentMethodModalUpdateButton(form: NgForm): void {
        if (this.paymentModal.selectedPaymentType === DomainPaymentType.None) {
            return;
        }

        if (form.valid === false) {
            for (let key in form.controls) {
                form.controls[key].markAsDirty();
            }
            return;
        }

        switch (this.paymentModal.selectedPaymentType) {
            case DomainPaymentType.RegisteredCreditCard:
                this._setPaymnetMethod(this.paymentModal.selectedPaymentType);
                this.currentPaymentType = this.paymentModal.selectedPaymentType;
                this.viewCreditCard = this.paymentModal.registeredCreditCard;
                this.paymentModal.isOpen = false;
                this.serviceContainer.NotifyService.AddNotifyMessages([new NotifyMessage(this.serviceContainer.NotifyService.GetNotifyId(), NotifyType.Info, "支払い方法を変更しました。")]);
                break;
            case DomainPaymentType.NewCreditCard:
                this._updateNewCreditCard();
                break;
            case DomainPaymentType.Convenience:
                this._validateConveniencePaymnetMethod();
                break;
            default:
                this._setPaymnetMethod(this.paymentModal.selectedPaymentType);
                this.currentPaymentType = this.paymentModal.selectedPaymentType;
                this.paymentModal.isOpen = false;
                this.serviceContainer.NotifyService.AddNotifyMessages([new NotifyMessage(this.serviceContainer.NotifyService.GetNotifyId(), NotifyType.Info, "支払い方法を変更しました。")]);
                break;
        }
    }

    /**
     * コンビニエンス名を取得
     * @param convenienceType
     */
    private _getConvenienceName(convenienceType: DomainConvenienceType): string {
        switch (convenienceType) {
            case DomainConvenienceType.SevenEleven:
                return 'セブンイレブン';
            case DomainConvenienceType.FamilyMart:
                return 'ファミリーマート';
            case DomainConvenienceType.Lawson:
                return 'ローソン';
            case DomainConvenienceType.SeicoMart:
                return 'セイコーマート';
            default:
                return '-';
        }
    }
}

class DomainPaymentModal {
    public isOpen: boolean;

    public isDisableBank: boolean;
    public isDisableConvenience: boolean;

    public selectedPaymentType: DomainPaymentType;

    public registeredCreditCard: DomainCreditCard;
    public inputNewCreditCard: DomainNewCreditCard;
    public inputConvenience: DomainConvenience;

    public constructor() {
        this.isOpen = false;

        this.isDisableBank = false;
        this.isDisableConvenience = false;

        this.selectedPaymentType = DomainPaymentType.None;

        this.registeredCreditCard = new DomainCreditCard();
        this.inputNewCreditCard = new DomainNewCreditCard();
        this.inputConvenience = new DomainConvenience();
    }
}

class DomainCreditCard {
    public hasRegisteredCreditCard: boolean;
    public canUseCredit: boolean;
    public creditNumber: string;
    public creditPeriodMonthYear: string;

    public constructor() {
        this.hasRegisteredCreditCard = false;
        this.canUseCredit = true;
        this.creditNumber = '-';
        this.creditPeriodMonthYear = '-';
    }
}

export class DomainNewCreditCard {
    public creditNumber: string;
    public creditHolder: string;
    public creditPeriodMonth: string;
    public creditPeriodYear: string;
    public securityCode: string;

    public readonly securityCodePopup: string;
    public rNumberPattern: string;
    public readonly rCreditHolder: string;
    public readonly creditPeriodYearItems: { value: string, text: string }[];
    public readonly rNumberPatternWithSpace: string;

    public constructor() {
        const date: Date = new Date();
        const currentYearNumber: number = date.getFullYear();
        const currentYearString: string = currentYearNumber.toString();
        let creditPeriodYearItems: { value: string, text: string }[] = [{ value: currentYearString.substring(currentYearString.length - 2), text: currentYearString }];
        let year: string = '';
        for (let i = 1; i < 10; i++) {
            year = (currentYearNumber + i).toString();
            creditPeriodYearItems.push({ value: year.substring(year.length - 2), text: year });
        }

        this.creditNumber = '';
        this.creditHolder = '';
        this.creditPeriodMonth = '01';
        this.creditPeriodYear = (currentYearNumber + 1).toString().substring(year.length - 2);
        this.securityCode = '';
        this.securityCodePopup = '<div class="box-SecurityCode"><img src="' +
            window.CacheBusting('/Content/images/common/securitycode.png') +
            '" alt=""><p>セキュリティコードについて</p><p>裏面の署名欄に記載されているクレジットカード番号の後に記載された3桁の番号、または表面のクレジットカード番号の右上に記載されている4桁の番号を指します。</p></div>'

        this.rNumberPattern = ValidationRegexsDefine.rNotAmericanExpressCreditSecurityCode;
        this.rCreditHolder = ValidationRegexsDefine.rCreditHolder;
        this.rNumberPatternWithSpace = ValidationRegexsDefine.rNumberPatternWithSpace;
        this.creditPeriodYearItems = creditPeriodYearItems;
    }
}

export class DomainConvenience {
    public selectedConvenienceType: DomainConvenienceType;
    public payeeNameKana: string;
    public convenienceName: string;

    public convenienceFee: string;
    public convenienceUnTaxFee: string;

    public readonly rFullWidthKatakanaPattern: string;

    public constructor() {
        this.selectedConvenienceType = DomainConvenienceType.SevenEleven;
        this.payeeNameKana = '';
        this.convenienceName = '-';

        this.convenienceFee = '0';
        this.convenienceUnTaxFee = '0';

        this.rFullWidthKatakanaPattern = ValidationRegexsDefine.rFullWidthKatakanaPattern;
    }
}

export enum DomainPaymentType {
    None = 0,
    RegisteredCreditCard = 1,
    NewCreditCard = 2,
    Bank = 3,
    Convenience = 4,
    Monthly = 5,
}

export enum DomainConvenienceType {
    SevenEleven = 11,
    FamilyMart = 21,
    Lawson = 31,
    SeicoMart = 32
}

 //MEMO
export class DomainPaymentTypeText {
    static None: string = '';
    static CreditCard: string = 'credit';
    static Bank: string = 'bank';
    //static MonthlyCredit: string = 'deposit';
    static Convenience: string = 'netcvs';
    static Monthly: string = 'monthly';
}

