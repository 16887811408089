import { Directive, Input, forwardRef } from '@angular/core';
import { NG_VALIDATORS, Validator, ValidatorFn, AbstractControl } from '@angular/forms';

/*
 * 改行前に指定バイト数より多く入力されたらエラー
 */

@Directive({
    selector: '[maxRowBytes]',
    providers: [{ provide: NG_VALIDATORS, useExisting: forwardRef(() => MaxRowBytesValidator), multi: true }]
})

export class MaxRowBytesValidator implements Validator {
    @Input('maxRowBytes') maxBytes: string;

    validate(control: AbstractControl): { [key: string]: any } {
        const validatorFn = domainNameBytesValidator(Number(this.maxBytes));
        return validatorFn(control);
    }
}

function domainNameBytesValidator(bytes: number): ValidatorFn {

    return (control: AbstractControl): { [key: string]: any } | null => {
        if (!control.value) {
            return null;
        } else {
            const isOver = (control.value as string).replace(/\n|\r\n|\r/, '\n').trim().split('\n').some(x => encodeURIComponent(x).length > bytes);
            return isOver ? { 'maxRowBytes': { value: control.value } } : null;
        }
    }
}