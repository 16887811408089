import { ParallelAB, TParallelAB, TAloneAB } from 'Content/script/libs/abtest/ab_cookie_service.util';
import { HttpClient } from '@angular/common/http';

/**
 * ABテスト対象
 **/
interface IABTargets<T> {
    LoginCampaign: T,
    AdjustRsRecommendWithNameServer: T,
    AdjustTooltipDomain: T,
}

/** 各ABテスト対象に紐づく複数のテスト用のインターフェース */
interface ITests<T> {
    tests: T;
}

/**
 * 【お名前.com Navi】【全商材】Naviログイン増のためのプレゼントCP
 * https://ticket.g-system.io/issues/84736
 *
 * LoginCampaign_01　→　キャッシュデータを用いない「強制データ取得モード」切り替え用
 * LoginCampaign_02　→　訴求モーダルを50 : 50の比率で出す用（ABテストではない）
 */
interface ILoginCampaign {
    LoginCampaign_01: TAloneAB;
    LoginCampaign_02: TAloneAB;
}
class LoginCampaignAB extends ParallelAB implements ITests<ILoginCampaign> {
    public get tests(): ILoginCampaign {
        return {
            LoginCampaign_01: this.tiketId1Test,
            LoginCampaign_02: this.tiketId2Test,
        };
    }
}

interface IAdjustRsRecommendWithNameServer {
    AdjustRsRecommendWithNameServer: TAloneAB;
}
class AdjustRsRecommendWithNameServerAB extends ParallelAB implements ITests<IAdjustRsRecommendWithNameServer> {
    public get tests(): IAdjustRsRecommendWithNameServer {
        {
            return {
                AdjustRsRecommendWithNameServer: this.tiketId1Test
            };
        }
    }
}

interface IAdjustTooltipDomain {
    AdjustTooltipDomain: TAloneAB;
}
class AdjustTooltipDomainAB extends ParallelAB implements ITests<IAdjustTooltipDomain> {
    public get tests(): IAdjustTooltipDomain {
        {
            return {
                AdjustTooltipDomain: this.tiketId1Test
            };
        }
    }
}

/** オブジェクトの名前、イベント名などに使う。*/
class ABTargetNames implements IABTargets<string> {
    public readonly LoginCampaign = 'LoginCampaign';
    public readonly AdjustRsRecommendWithNameServer = 'AdjustRsRecommendWithNameServer';
    public readonly AdjustTooltipDomain = 'AdjustTooltipDomain';
}

/** クッキー名やパスの判定に使う */
enum Paths {
    SP_CAMPAIGN_PRESENT = "/sp/campaign/present"
    //TOP = '/top',
}

/** serviceContainerで公開されるServiceクラス
 * */
type TTests =
    ITests<
        | ILoginCampaign
        | IAdjustRsRecommendWithNameServer
        | IAdjustTooltipDomain
    >;
export class SpABCookieService implements IABTargets<TParallelAB | TTests> {

    protected key = new ABTargetNames();
    protected map: IABTargets<any>;

    constructor(protected _http: HttpClient) {

        this.map = {
            LoginCampaign: new LoginCampaignAB(
                this.key.LoginCampaign,
                { tiketId1: 'GT84736_1', tiketId2: 'GT84736_2' },
                this._http
            ),

            AdjustRsRecommendWithNameServer: new AdjustRsRecommendWithNameServerAB(
                this.key.AdjustRsRecommendWithNameServer,
                { tiketId1: 'GT182882' },
                this._http
            ),

            AdjustTooltipDomain: new AdjustTooltipDomainAB(
                this.key.AdjustTooltipDomain,
                { tiketId1: 'GT181536' },
                this._http
            )
        };
        //cookieの生成タイミングがログイン直後（＝MC-REXのロケーションIDが「1」）のときはここで初期化
        (this.map.LoginCampaign as ParallelAB).initAndDispatchFirst();
        (this.map.AdjustRsRecommendWithNameServer as ParallelAB).initAndDispatchFirst();
        (this.map.AdjustTooltipDomain as ParallelAB).initAndDispatchFirst();
    }

    /**
    * ticket1: 'GT84736'
    * @param path
    */
    public get LoginCampaign(): ITests<ILoginCampaign> {
        return this.map.LoginCampaign;
    }

    /**
    * ticket1: 'GT181536'
    * @param path
    */
    public get AdjustTooltipDomain(): ITests<IAdjustTooltipDomain> {
        return this.map.AdjustTooltipDomain;
    }

    /**
    * ticket1: 'GT182882'
    * @param path
    */
    public get AdjustRsRecommendWithNameServer(): ITests<IAdjustRsRecommendWithNameServer> {
        return this.map.AdjustRsRecommendWithNameServer;
    }

    /**
     * cookieの生成タイミングが契約管理画面遷移前（MC-REXのロケーションIDが「2」）のときはここで初期化
     * routerのNavigationStartイベント発生時に実行される(app.component.ts).
     * @param path 遷移先パス
     */
    public async onRaiseRouterEvent_RoutesRecognized(path: string) {
    }
}
