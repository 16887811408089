import { Component, Output, EventEmitter, Input } from '@angular/core';
import { ServiceContainer } from 'Content/script/service/service_container';
import { IPageTracking } from '../../../../service/tracking_service';
import { docCookies } from '../../../../libs/third_party/cookies';

@Component({
    selector: 'whoisprotect-cancel-enquete-coupon-popup',
    templateUrl: './whoisprotect_cancel_enquete_coupon_popup.html'
})

export class WhoisprotectCancelEnqueteCouponPopup{
    private _trackingService: IPageTracking;
    public queueId: number;

    public serviceName: string = '';
    public price: string = '';

    @Output() noSessionEvent = new EventEmitter<any>();
    @Output() onErrorEvent = new EventEmitter<any>();
    @Output() onUnExceptedErrorEvent = new EventEmitter<any>();
    @Output() onCloseModal = new EventEmitter<any>();
    @Output() onClickRedirect = new EventEmitter<any>();

    private _isShowCouponPopup: boolean = false;
    @Input()
    public get isShowCouponPopup(): boolean {
        return this._isShowCouponPopup;
    }
    public set isShowCouponPopup(v: boolean) {
        this._isShowCouponPopup = v;
        if (!this._isShowCouponPopup) {
            this._trackingService.dispose();
        } else {
            this.serviceName = docCookies.getItem('whoisprotectServiceName');
            this.price = docCookies.getItem('whoisprotectPrice');
        }
    }

    @Input()
    public domainId: string;
    @Input()
    public domainName: string;
    @Input()
    public expirationDate: string;

    public constructor(
        private serviceContainer: ServiceContainer
    ) {
        this.queueId = this.serviceContainer.HubsService.multipleSendManager.GetComponentId();
        this._trackingService = this.serviceContainer.TrackingService.provideServiceForModalComponent().create('DomainDetailModalComponent');
    }
    public onClickClose(): void {
        this.onCloseModal.emit();
        this.isShowCouponPopup = false;
    }

    public onClickRedirectConfirm(): void {
        window.dataLayerPush('newnavi_whoisprotect_cancelmodal_confirm');
        this.onClickRedirect.emit();
        this.isShowCouponPopup = false;
    }

    public onClickDomainRenew(): void {
        window.dataLayerPush('newnavi_whoisprotect_cancelmodal_coupondone');
        this.isShowCouponPopup = true;
        this.doRedirection("DOMAIN_RENEW_WHOIS_CANCEL");
    }

    /**************************
     * キャッシュバースティング
     * @param uri
     **************************/
    public CacheBusting(uri: string): string {
        return window.CacheBusting(uri);
    }

    /**
  * シングルサインオン
  * @param navClass
  */
    private doRedirection(navClass: string): void {
        var query: NavigationQueryHubDto = {
            QueryKey: "domainName",
            QueryValue: this.domainName
        };
        var request: NavigationRequestHubDto = {
            Host: "DOMAIN_NAVI",
            NavigationClass: navClass,
            NavigationParams: [query]
        };

        this.serviceContainer.HubsService.multipleSendManager.Send(
            this.queueId,
            this.serviceContainer.HubsService.hubsManager.navigationHubManager.TokenUrl,
            request,
            (response: NavigationResponseHubDto) => {
                window.open(response.TokenUrl, '_self');
            },
            (response: NavigationResponseHubDto) => {
            },
            (resHubDto: DomainRenewUpdateResponseHubDto) => {
                this.noSessionEvent.emit(resHubDto);
            },
            null,
            (resHubDto: DomainRenewUpdateResponseHubDto) => {
                this.onUnExceptedErrorEvent.emit(resHubDto);
            }
        );
    }
}
