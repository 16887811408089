import { Component, Input } from '@angular/core';

@Component({
    selector: 'tbl-col_group',
    templateUrl: './tbl_col_group.html'
})

export class TblColGroupComponent {
    @Input() public colItems: IColGroupItem;
}

export interface IColGroupItem {
    col: IColItem[];
}

export interface IColItem {
    width: string;
}