import { Directive, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Directive({
    selector: '[sp-modal]'
})
export class SpModalDirective implements OnChanges {
    private _el: HTMLElement;

    @Input('openFlg')
    public openFlg: boolean;

    @Output() onClose = new EventEmitter();

    private readonly bodyClass = "is-modal-enabled";
    private readonly isVisible = "is-visible";

    constructor(private _elementRef: ElementRef) {
        this._el = _elementRef.nativeElement;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        for (let prop in changes) {
            let change = changes[prop];
            if (prop === "openFlg") {
                if (change.currentValue === true) {
                    this.open();
                } else {
                    this.close();
                }
            }
        }
    }

    private open() {
        // モーダルの外をクリックされた場合閉じる
        this._el.addEventListener('click', (event: Event) => {
            let target = event.target as HTMLElement;
            if (this._el.className == target.className) {
                this.close();
            }
        });

        document.body.classList.add(this.bodyClass);
        this._el.classList.add(this.isVisible);
        this._el.style.opacity = "1";
        this._el.style.display = "block";
    }

    private close() {
        this._el.removeEventListener('click', (event: Event) => {
            let target = event.target as HTMLElement;
            if (this._el.className == target.className) {
                this.close();
            }
        });

        document.body.classList.remove(this.bodyClass);
        this._el.classList.remove(this.isVisible);
        this._el.style.opacity = "0";
        this._el.style.display = "none";

        this.onClose.emit();
    }
}
