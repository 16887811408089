import { Component, Input, Output, EventEmitter, DoCheck  } from '@angular/core';
import { Router } from "@angular/router";
import { NgForm, NgModel } from '@angular/forms';
import { ServiceContainer } from 'Content/script/service/service_container';
import { BaseViewModel, BaseComponent } from "Content/script/components/base_component";

@Component({
    selector: 'mfa_modal',
    templateUrl: './mfa_modal.html'
})

export class MfaModalComponent implements DoCheck {
    @Input('componentRef') _componentRef: any;

    public queueId: number;
    public viewModel: MfaModalViewModel;

    public constructor(
        private router: Router,
        private serviceContainer: ServiceContainer) {
        this.queueId = serviceContainer.HubsService.multipleSendManager.GetComponentId();

        this.viewModel = new MfaModalViewModel(
            this.queueId,
            router,
            serviceContainer);
    }

    ngDoCheck() {
        let _isShowMfaModal: boolean = false;
        let _mfaMethod: string;
        let _closeFunc: Function;
        let _passedMfaFunc: Function;

        const component = this._componentRef;
        if (this.viewModel.isShow || !component) {
            return;
        }

        if (!this.isMfaComponent(component)) {
            return;
        }

        switch (location.pathname) {
            case TargetPathName.Login:
            case TargetPathName.CheckMfaMethod:
            case TargetPathName.SetMfaMethod:
            case TargetPathName.Password:
            case TargetPathName.AccountUpdate:
                _isShowMfaModal = component.model.isShowMfaModal;
                _mfaMethod = component.model.mfaMethod;
                _closeFunc = component.model.onClickCloseMfaModal;
                _passedMfaFunc = component.model.passedMfa;
                break;
        }

        if (_isShowMfaModal && !!_mfaMethod) {
            this.viewModel.init(_mfaMethod, _closeFunc, _passedMfaFunc);
        }
    }

    private isMfaComponent(componentRef: any) {
        let model = componentRef.model || null;

        if (!model) {
            return false;
        }

        let flg = model.isShowMfaModal || null;

        return flg !== null;
    }
}

class MfaModalViewModel extends BaseViewModel {
    public isShow: boolean = false;
    public isError: boolean = false;
    public isRateLimitExceeded: boolean = false;
    public isTokenReissue: boolean = false;
    public isTokenCreate: boolean = true;
    public isProcessing: boolean = true;
    public modalTitle: string = "";
    public modalExplanation: string = "";
    public mfaCode: string = "";
    public gtmValuePreFix: string = "";
    public mfaMethod: string = "";
    private _onModalClose: Function;
    private _onPassedMfa: Function;
    private _isInit: boolean = true;

    constructor(
        queueId: number,
        router: Router,
        serviceContainer: ServiceContainer
    ) {
        super(queueId, router, serviceContainer);
    }

    public init(mfaMethod: string, modalCloseFunc: Function, passedMfaFunc: Function) {
        this.mfaMethod = mfaMethod;
        this.setTitleAndExplanation(mfaMethod);
        this._onModalClose = modalCloseFunc;
        this._onPassedMfa = passedMfaFunc;
        this._isInit = true;
        this.mfaTokenCreate();
        this.isShow = true;
    }

    public setTitleAndExplanation(mfaMethod: string) {
        switch (location.pathname) {
            case TargetPathName.Login:
                this.gtmValuePreFix = "login_";
                break;

            case TargetPathName.CheckMfaMethod:
                this.gtmValuePreFix = "verify_";
                break;

            case TargetPathName.SetMfaMethod:
                this.gtmValuePreFix = "configuration_";
                break;

            case TargetPathName.Password:
                this.gtmValuePreFix = "pwchange_";
                break;

            case TargetPathName.AccountUpdate:
                this.gtmValuePreFix = "accountupdate_";
                break;

            default:
                this.gtmValuePreFix = "";
                break;
        }

        switch (mfaMethod) {
            case "1":
                this.gtmValuePreFix = this.gtmValuePreFix + "mail_";
                this.modalTitle = "メール認証";
                this.modalExplanation = "ご登録いただいている連絡先メールアドレス宛に認証コードを送信しました。受信した認証コードをご入力ください。しばらくお待ちいただいても届かない場合は、再発行ボタンをクリックしてください。（認証コードの有効期限は10分です。）";
                break;

            case "2":
                this.gtmValuePreFix = this.gtmValuePreFix + "sms_";
                this.modalTitle = "SMS認証";
                this.modalExplanation = "ご登録いただいている携帯電話番号宛に認証コードを送信しました。受信した認証コードをご入力ください。しばらくお待ちいただいても届かない場合は、再発行ボタンをクリックしてください。（認証コードの有効期限は10分です。）";
                break;

            case "3":
                this.gtmValuePreFix = this.gtmValuePreFix + "";
                this.modalTitle = "音声認証";
                this.modalExplanation = "";
                break;

            case "4":
                this.gtmValuePreFix = this.gtmValuePreFix + "";
                this.modalTitle = "Google Authenticator";
                this.modalExplanation = "";
                break;

            default:
                this.gtmValuePreFix = "";
                this.modalTitle = "";
                this.modalExplanation = "";
                break;
        }
    }

    /**
     * 次へボタン
     */
    public onClickNext(form: NgForm) {
        this.isError = false;
        this.isRateLimitExceeded = false;
        this.isTokenReissue = false;
        this.isTokenCreate = true;
        if (form.valid === false) {
            for (let key in form.controls) {
                form.controls[key].markAsDirty();
            }
            return;
        }

        this.verifyMfaCode();

        form.resetForm();
    }

    /**
     * 認証処理
     */
    private verifyMfaCode() {
        this.isProcessing = true;

        var requestObject: VerifyMfaCodeRequestHubDto = {
            MfaCode: this.mfaCode
        };

        this.serviceContainer.HubsService.multipleSendManager.Send(
            this.queueId,
            this.serviceContainer.HubsService.hubsManager.authHubManager.VerifyMfaCode,
            requestObject,
            (responseObject: VerifyMfaCodeResponseHubDto) => {
                this._onModalClose();
                this.isShow = false;
                this._onPassedMfa();
            },
            (responseObject: VerifyMfaCodeResponseHubDto) => {
                if (responseObject.IsRateLimitExceeded) {
                    this.isRateLimitExceeded = true;
                }
                else {
                    this.isError = true;
                }
                this.isProcessing = false;
            },
            this.NoSession,
            null,
            this.UnExceptedError
        );
    }

    /**
     * 再発行ボタン
     */
    public onClickTokenCreate(model: NgModel) {
        this.isError = false;
        this.isRateLimitExceeded = false;
        this.isTokenReissue = false;
        this.isTokenCreate = true;
        model.reset();
        this.mfaTokenCreate();
    }

    /**
     * 認証トークン発行
     */
    private mfaTokenCreate() {
        this.isProcessing = true;

        var requestObject: MfaTokenCreateRequestHubDto = {
            MfaMethod: this.mfaMethod,
            IsVoaci: false,
            Destination: ''
        };

        this.serviceContainer.HubsService.multipleSendManager.Send(
            this.queueId,
            this.serviceContainer.HubsService.hubsManager.authHubManager.MfaTokenCreate,
            requestObject,
            (responseObject: MfaTokenCreateResponseHubDto) => {
                this.isProcessing = false;
                this.isTokenCreate = true;
            },
            (responseObject: MfaTokenCreateResponseHubDto) => {
                this.isProcessing = false;
                this.isTokenCreate = false;
            },
            this.NoSession,
            () => {
                if (!this._isInit) {
                    this.isTokenReissue = true;
                }
                this._isInit = false;
            },
            this.UnExceptedError
        );
    }

    /**
     * 閉じるボタン
     */
    public onClickClose() {
        this.isError = false;
        this.isRateLimitExceeded = false;
        this.isTokenReissue = false;
        this.isTokenCreate = true;
        this._onModalClose();
        this.isProcessing = true;
        this.isShow = false;
    }
}

enum TargetPathName {
    Login = '/login',
    CheckMfaMethod = '/account/loginsecurity/mfa/check',
    SetMfaMethod = '/account/loginsecurity/mfa/edit',
    Password = '/account/password',
    AccountUpdate = '/account/detail/confirm'
}
