import { Checker } from "Content/script/libs/shared/language_enhancement/checker"; 

/**
 * ドメインのタグについて統一ルールを考慮し、一か所でまとめて定義していますが、
 * 追加や仕様変更の際にはルールを本当に統一すべきか、画面ごとにルールを作るべきかを考慮し、
 * 適宜作り替えるようお願いします
 */
export enum DomainTagType {
    RenewDomain,
    DomainProtection,
    SdRentalServer,
    Ssl,
    Whois,
    WhoisMailTransferOption
}

export class DomainTagData {
    private readonly _tagType: DomainTagType;
    private readonly _tagName: string;
    private readonly _priority: number;

    public static create(type: DomainTagType, aPriority?: number): DomainTagData {
        const tagName = this._getTagName(type);
        const priority = Checker.nullOrUndefined(() => aPriority) ? this._getPriority(type) : aPriority;
        return new DomainTagData(type, tagName, priority);
    }

    private static _getTagName(tagType: DomainTagType): string {
        switch (tagType) {
            case DomainTagType.RenewDomain:             return "renewdomain"; 
            case DomainTagType.DomainProtection:        return "protection";
            case DomainTagType.SdRentalServer:          return "sd";
            case DomainTagType.Ssl:                     return "ssl";
            case DomainTagType.Whois:                   return "whois";
            case DomainTagType.WhoisMailTransferOption: return "whoisop";
            default: return ""; // タグで例外にしたくないので空文字を返却
        }
    }

    private static _getPriority(tagType: DomainTagType): number {
        switch (tagType) {
            case DomainTagType.RenewDomain:             return 0;
            case DomainTagType.DomainProtection:        return 1;
            case DomainTagType.SdRentalServer:          return 2;
            case DomainTagType.Ssl:                     return 3;
            case DomainTagType.Whois:                   return 4;
            case DomainTagType.WhoisMailTransferOption: return 5;
            default: return 9999;
        }
    }

    private constructor(tagType: DomainTagType, tagName: string, priority: number) {
        this._tagType = tagType;
        this._tagName = tagName;
        this._priority = priority;
    }

    public get priority(): number {
        return this._priority;
    }

    public get tagName(): string {
        return this._tagName;
    }

    public isMatchType(tagType: DomainTagType): boolean {
        return this._tagType === tagType;
    }
}
