import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomainConvenienceType, DomainPaymentType } from 'Content/script/components/share/domain_payment/domain_payment.component';
import { CustomizedEvents, ERenewRsModalEventType } from 'Content/script/libs/abtest/custom-event.util';
import { PurchasedInfoForAdobeAnalytics } from 'Content/script/libs/adobeanalytics/purchasedInfo';
import { ContentType } from 'Content/script/libs/common/domainRenew/ContentType';
import { GmoPointSetting, PointUsingType } from 'Content/script/libs/common/domainRenew/GmoPointSetting';
import { FutureDate, docCookies } from 'Content/script/libs/third_party/cookies';
import { StringUtil } from 'Content/script/libs/utils/string.util';
import { ServiceContainer } from 'Content/script/service/service_container';
import { IPageTracking } from 'Content/script/service/tracking_service';
import { IConveniInfoDto, IDataTransferDomainRenewDoneDto, IDomainApplyingDto, IFreeDomainApplyingDto, IServerApplyingDto} from '../../../../dtos/data_transfer_domain_renew_done_dto';
import { ItemDetail } from '../../../../libs/common/domainRenew/ItemDetail';
import { NotifyMessage, NotifyType } from '../../../../service/notify_service';
import { DomainTagType } from '../../../model/domain/tag/domain_tag';
import { DomainTagBuilder } from '../../../model/domain/tag/domain_tag_builder';
import { GmoPointModal2Component } from '../../gmo_point_modal/gmo_point_modal_2.component';

@Component({
    selector: 'domain-renew-modal-new',
    templateUrl: './domain_renew_modal_new.html'
})

export class DomainRenewModalNewComponent implements OnChanges ,OnDestroy {

    private _isShow: boolean = false;
    @Input()
    public get isShow(): boolean {
        return this._isShow;
    }
    public set isShow(v: boolean) {
        this._isShow = v;
        if (this._isShow) {
            this._trackingService.start();
            CustomizedEvents.dispatchDomainConfirmModalsLoaded({ ViewModelName: 'DomainRenewModalNewComponent' });
        }
        else {
            this._trackingService.dispose();
        }
    }
    private _isDisplayFreeRegist: boolean = false;
    @Input()
    public get isDisplayFreeRegist(): boolean {
        return this._isDisplayFreeRegist;
    }
    public set isDisplayFreeRegist(v: boolean) {
        this._isDisplayFreeRegist = v;
    }
    @Input() isSelectedOnlyOneDomain: boolean;
    @Input() isOpenModal: boolean;
    @Input() renewDomainsData: IDataTransferDomainRenewDto;
    @Input() freeRegistDomainsData: DomainRenewCreateDomainRequestHubDto;
    @Input() freeRegistDomainId: string;
    @Input() gmoTaxedDiscount: number;
    @Input() isABNew_AdjustShowHideOptionRenewModal: boolean;
    @Input() isShowCouponDomainDetail: boolean;
    @Input() isShowCoupon1500Yen: boolean = false;

    @Output() onCloseButtonClickedEvent = new EventEmitter<IDataTransferUpdateYearModal[]>();
    @Output() onCloseModaldEvent = new EventEmitter<IDataTransferUpdateYearModal[]>();
    @Output() noSessionEvent = new EventEmitter<any>();
    @Output() onErrorEvent = new EventEmitter<any>();
    @Output() onUnExceptedErrorEvent = new EventEmitter<any>();

    public readonly ContentType = ContentType;
    public isLoading: boolean;
    public domainFreeApply: IFreeDomainApplyingDto;
    public queueId: number;
    public domainId: string;
    public domains: DomainItem[];
    public domainsCheckedHosting: DomainItem[];
    public freeDomains: FreeDomainItem[];
    public serverItems: ServerItem[] = [];
    public updateYearItems: string[] = ['1', '2', '3', '4', '5', '6', '7', '8', '9'];
    public selectedUpdateYear: string;
    public domainNameMlFree: string = '';
    public domainNameMlWithRs: string = '';
    //GMOポイント
    protected gmoPointModalComponentRef: GmoPointModal2Component = null;
    public readonly PointUsingType = PointUsingType;
    public gmoPointSetting: GmoPointSetting;
    public gmoPointDiscountPrice: string;
    public useGmoPoint: number;

    private _isOpenModal: boolean = false;
    private _isFireEvent413: boolean = false;
    private _minExpireDate: number = 0;
    private _isRemoveDomain: boolean = false;
    public readonly PaymentType = DomainPaymentType;
    public paymentType: DomainPaymentType;
    public isShowPaymentType: boolean;

    // 料金
    public totalPriceCheckedHosting: string;
    public totalPrice: string;
    public totalPriceFreeRegist: string = '0';
    public totalPriceWithRenewCoupon: string = '0';
    public paymentPrice: string;
    public isShowSelectYear: boolean = false;
    public totalUnTaxPrice: string;
    public paymentUnTaxPrice: string;
    public preDiscountTotal: string = '0'; //割引前の合計金額

    // コンビニ
    public readonly ConvenienceType = DomainConvenienceType;
    public isViewFee: boolean = false;
    public convenienceFee: number = 0;
    public convenienceUnTaxFee: number = 0;
    public conveniInfo: DomainRenewUpdatePaymentConveniInfoRequestHubDto = {
        ConveniCode: '',
        UserNameKana: ''
    };
    public isDisplayFreeRegistModal: boolean = false;
    public isTotalPriceMoreThan5000: boolean = false;
    // クレカ
    public maskedCreditNumber: string = '0';

    //クーポン
    public hasCoupon: boolean;

    // SSL
    public isSslNewApply: boolean;

    // サービス維持調整費
    public isShowSurcharge: boolean;

    // オプションのチェック状態
    public isCheckedProtection: boolean;
    public isCheckedSsl: boolean;
    public isCheckedHosting: boolean;
    public isCheckedWhoisProxy: boolean;
    public isCheckedWhoisMailFwd: boolean;

    // オプション選択可/不可
    public isDisableProtection: boolean;
    public isDisableSsl: boolean;
    public isDisableHosting: boolean;
    public isDisableWhoisProxy: boolean;
    public isDisableWhoisMailFwd: boolean;

    //売り上げ計測用情報
    public purchasedInfoString: string;
    public confirmButtonTag: string;
    
    public isHoverCloseButton: boolean = false;
    //ページトラッキングサービス
    private _trackingService: IPageTracking;
    private promiseUpdatePrice: Promise<boolean>;
    public dataTransferUpdateYearModal: IDataTransferUpdateYearModal[] = [];

    public isProduct: boolean = (window.productFlg === "Product" || window.productFlg === "Staging" || window.productFlg === "Test");

    public nameServerList: DomainNameServerRenewResponseHubDto[] = [];
    public isExistRegistryPremiumDomain: boolean = false;

    public canShowNewModalCoupon: boolean = false;
    public isShowNewModalCoupon: boolean = false;
    public isApplyCoupon: boolean = false;
    public isFirstViewNewModalCoupon: boolean = false;

    public constructor(
        private router: Router,
        private route: ActivatedRoute,
        private serviceContainer: ServiceContainer
    ) {
        this.isLoading = false;

        this.queueId = this.serviceContainer.HubsService.multipleSendManager.GetComponentId();
        this.domainId = this.route.snapshot.params['domainId'];
        this.domains = [];
        this.domainsCheckedHosting = [];
        this.freeDomains = [];
        this.gmoPointSetting = new GmoPointSetting();
        this.gmoPointDiscountPrice = '0';

        this.paymentType = DomainPaymentType.None;
        this.isShowPaymentType = true;

        this.totalPrice = '0';
        this.paymentPrice = '0';
        this.totalUnTaxPrice = '0';
        this.paymentUnTaxPrice = '0';

        this._trackingService = this.serviceContainer.TrackingService.provideServiceForModalComponent().create('DomainRenewModalNewComponent');
        this._isRemoveDomain = false;
    }


    public ngOnChanges(changes: SimpleChanges): void {
        if (!!changes.renewDomainsData && !!changes.renewDomainsData.currentValue) {
            this._isOpenModal = this.isOpenModal;
            this.isDisplayFreeRegistModal = this.isDisplayFreeRegist;
            let unValidatedDomains: IDataTransferRenewDomainItemDto[] = this.renewDomainsData.unValidatedDomains;
            this.dataTransferUpdateYearModal = [];
            for (var i = 0; i < unValidatedDomains.length; i++) {
                this.dataTransferUpdateYearModal.push({ domainId: unValidatedDomains[i].domainId, year: unValidatedDomains[i].selectedUpdateYear });
            }
            this.selectedUpdateYear = unValidatedDomains[0].selectedUpdateYear;
            this.isCheckedWhoisProxy = unValidatedDomains.every(x => !x.canApplyWhoisProduct) ? false : unValidatedDomains.every(x => x.isAppliedWhoisProxy);
            this.isCheckedWhoisMailFwd = unValidatedDomains.every(x => !x.canApplyWhoisProduct) ? false : unValidatedDomains.every(x => x.isAppliedWhoisMailFwd);
            this.isCheckedProtection = unValidatedDomains.every(x => x.isAppliedDomainProtect);
            this.isCheckedSsl = false;
            this.isCheckedHosting = false;

            this.isDisableWhoisProxy = this._isDisableWhoisProxy();
            this.isDisableWhoisMailFwd = this._isDisableWhoisMailFwd();
            this.isDisableProtection = unValidatedDomains.every(x => x.isAppliedDomainProtect);
            this.isDisableSsl = !this.renewDomainsData.isOnamaeUser || unValidatedDomains.every(x => !x.canApplySsl || x.isAppliedSsl || x.domainName !== this.renewDomainsData.hostingDomainName);
            this.isDisableHosting = this.renewDomainsData.hostingDomainName.length === 0;

            if (unValidatedDomains.length > 0) {
                if (this.isDisableHosting) {
                    this.serviceContainer.ABCookieService.AdjustShowHideOptionRenewModal.tests.AdjustShowHideOptionRenewModal.dispatchEvent_sendCookiesBaggage(null);
                    CustomizedEvents.dispatchFireEventModalRenewRs(ERenewRsModalEventType.Event621);
                } else {
                    CustomizedEvents.dispatchFireEventModalRenewRs(ERenewRsModalEventType.Event622);
                }
            }
            if (this.isSelectedOnlyOneDomain     //ドメイン１つのみ
                && !unValidatedDomains[0].isAutoRenewActive     //自動更新OFF
                && parseInt(unValidatedDomains[0].renewalDeadlineCount) >= 0    //ドメイン更新期限30日以内
                && parseInt(unValidatedDomains[0].renewalDeadlineCount) <= 30   //ドメイン更新期限30日以内
                && unValidatedDomains[0].couponCode.length == 0     //その他のクーポンが存在していない
                && this.gmoTaxedDiscount > 0   //割引金額ある　→　GmoponValidatorで今回の施策のクーポンが使用可能かどうか
                && !this.isShowCouponDomainDetail) {
                this.canShowNewModalCoupon = true;
            }

            this.updatePrice();
            this._getNameServerWithCheckedRenewDomain();
            this.filterRenewDomainList();
        }
    }

    public get isShowPriceCoupon() {
        return this.isApplyCoupon && this.gmoTaxedDiscount > 0 && StringUtil.StringWithCommaToNumber(this.preDiscountTotal) > 0;
    }

    public get isShowPriceCouponDomainDetail() {
        return this.isShowCouponDomainDetail && this.gmoTaxedDiscount > 0 && !this.isCheckedHosting;
    }

    public CacheBusting(uri: string): string {
        return window.CacheBusting(uri);
    }

    public updataAfterRemoveDomain(): void {
        this._requestCheckDomainRenewHostingOrder()
    }

    private _filterRsTldDomain(domainNameML: string): boolean {
        let isValid = false;
        isValid = RegExp('(\\.com$)|(\\.net$)|(\\.org$)|(\\.biz$)|(\\.info$)|(\\.xyz$)|(\\.link$)|(\\.click$)|(\\.tokyo$)|(\\.blog$)|(\\.site$)|(\\.online$)|(\\.tech$)').test(domainNameML)
        return isValid;
    }

    /**
     * 選択されたドメインのネームサーバーリストを取得する
     * */
    private _getNameServerWithCheckedRenewDomain(): void {
        const request: DomainListRequestHubDto = {
            LineCnt: 100,
            PageNo: 1,
            DomainIds: null,
            DomainNames: this.renewDomainsData.validatedDomains.map(x => x.domainName).join('\n'),
            GroupName: '',
            CreatedDateFrom: '',
            CreatedDateTo: '',
            ExpirationDateFrom: '',
            ExpirationDateTo: '',
            SortList: [{ Key: 'domainNameML', Type: 1 }]
        };
        this.serviceContainer.HubsService.multipleSendManager.Send(
            this.queueId,
            this.serviceContainer.HubsService.hubsManager.domainHubManager.DomainNameServerWithRs,
            request,
            (response: DomainNameServerRenewListResponseHubDto) => {
                this.nameServerList = response.DomainList;
            },
            null,
            null,
            null,
            null
        );
    }

    /**
     * ホスティング商材の申し込み可/不可チェック
     */
    private _requestCheckDomainRenewHostingOrder(): void {
        this.isLoading = true;
        const reqHubDto: DomainRenewHostingOrderCheckRequestHubDto = {
            RenewDomains: this.renewDomainsData.validatedDomains.map(x => {
                return {
                    DomainName: x.domainName,
                    Tld: x.tld
                } as DomainRenewHostingOrderCheckDomainRequestHubDto;
            })
        } as DomainRenewHostingOrderCheckRequestHubDto;

        this.serviceContainer.HubsService.multipleSendManager.Send(
            this.queueId,
            this.serviceContainer.HubsService.hubsManager.domainRenewHubManager.CheckDomainRenewHostingOrder,
            reqHubDto,
            (resHubDto: DomainRenewHostingOrderCheckResponseHubDto) => {
                // RSオプション申し込み可否
                this.renewDomainsData.hostingDomainName = '';
                this.renewDomainsData.hostingRecommendId = '';
                if (resHubDto.IsAllowed) {
                    this.renewDomainsData.hostingDomainName = resHubDto.AllowedDomainName;
                    this.renewDomainsData.hostingRecommendId = resHubDto.HostingRecommendId;

                    const domainInfo: DomainRenewHostingInformation[] = resHubDto.DomainInfo;
                    let _canRecommendHosting = new Array<{ domainName: string, hasRecommend: boolean }>();
                    domainInfo.forEach(x => _canRecommendHosting.push({ domainName: x.DomainName, hasRecommend: x.HasRecommend }));
                }

                this.renewDomainsData.isHostingUnpaid = resHubDto.IsUnpaid;
                let unValidatedDomains: IDataTransferRenewDomainItemDto[] = this.renewDomainsData.unValidatedDomains;
                this.dataTransferUpdateYearModal = [];
                for (var i = 0; i < unValidatedDomains.length; i++) {
                    this.dataTransferUpdateYearModal.push({ domainId: unValidatedDomains[i].domainId, year: unValidatedDomains[i].selectedUpdateYear });
                }
                this.selectedUpdateYear = unValidatedDomains[0].selectedUpdateYear;
                this.isCheckedWhoisProxy = unValidatedDomains.every(x => !x.canApplyWhoisProduct) ? false : unValidatedDomains.every(x => x.isAppliedWhoisProxy);
                this.isCheckedWhoisMailFwd = unValidatedDomains.every(x => !x.canApplyWhoisProduct) ? false : unValidatedDomains.every(x => x.isAppliedWhoisMailFwd);
                this.isCheckedProtection = unValidatedDomains.every(x => x.isAppliedDomainProtect);
                this.isCheckedSsl = false;
                this.isCheckedHosting = false;
                
                this.isDisableWhoisProxy = this._isDisableWhoisProxy();
                this.isDisableWhoisMailFwd = this._isDisableWhoisMailFwd();
                this.isDisableProtection = unValidatedDomains.every(x => x.isAppliedDomainProtect);
                this.isDisableSsl = !this.renewDomainsData.isOnamaeUser || unValidatedDomains.every(x => !x.canApplySsl || x.isAppliedSsl || x.domainName !== this.renewDomainsData.hostingDomainName);
                this.isDisableHosting = this.renewDomainsData.hostingDomainName.length === 0;

                this.updatePrice();
                this.filterRenewDomainList();
            },
            (resHubDto: DomainRenewHostingOrderCheckResponseHubDto) => {
                this.isLoading = false;
                this._resetNewModalCoupon();
                this.onErrorEvent.emit(resHubDto);
                this.onCloseModaldEvent.emit(this.dataTransferUpdateYearModal);
            },
            (resHubDto: DomainRenewHostingOrderCheckResponseHubDto) => {
                this.noSessionEvent.emit(resHubDto);
            },
            null,
            (resHubDto: DomainRenewHostingOrderCheckResponseHubDto) => {
                this._resetNewModalCoupon();
                this.onUnExceptedErrorEvent.emit(resHubDto);
                this.onCloseModaldEvent.emit(this.dataTransferUpdateYearModal);
            }
        );
    }

    public ngOnDestroy() {
        this._trackingService.dispose();
    }

    public ngDoCheck() {
        this.adjustSpeechBubble();
    }
    /**
     * 吹き出しの配置調整
     * ngDoCheckから呼ばれて1回のみ実行
     */
    public adjustSpeechBubble(): void {
        var $trigger = $(".js-popupFixed");
        if ($trigger.length === 0) {
            return;
        }
        var $that = $trigger;
        var $target = $($that.attr("data-target"));
        var targetHeight = $target.outerHeight();
        var thatWidth = $that.outerWidth();
        var thatHeight = $that.outerHeight();
        var position = $trigger.position();
        var setLeft = 0;
        var setTop = 0;
        setLeft = position.left - 245;
        setTop = position.top - ((thatHeight + targetHeight) / 2) + 10;
        $target.css({
            left: setLeft,
            top: setTop
        });

        var $dismiss = $('[data-dismiss="popupFixed"]');

        var close = function () {
            $target.css('display', 'none');
        };

        $dismiss.on('click', function (e) {
            close();
        });
    }

    private _isDisableWhoisProxy(): boolean {
        let isDisable: boolean = false;

        // ドメインがすべてWhois非対応である
        isDisable = this.renewDomainsData.unValidatedDomains.every(x => !x.canApplyWhoisProduct);
        if (isDisable) {
            return true;
        }

        // ドメインがすべてWhois申し込み済みである
        isDisable = this.renewDomainsData.unValidatedDomains.every(x => x.isAppliedWhoisProxy);
        if (isDisable) {
            return true;
        }

        // Whois未申し込みのドメインがすべてWhois非対応である
        isDisable = this.renewDomainsData.unValidatedDomains.filter(x => !x.isAppliedWhoisProxy).every(x => !x.canApplyWhoisProduct);
        if (isDisable) {
            return true;
        }

        return isDisable;
    }

    private _isDisableWhoisMailFwd(): boolean {
        let isDisable: boolean = false;

        // ドメインがすべてWhois非対応である
        isDisable = this.renewDomainsData.unValidatedDomains.every(x => !x.canApplyWhoisProduct);
        if (isDisable) {
            return true;
        }

        // ドメインがすべてメール代行を申し込み済みである
        isDisable = this.renewDomainsData.unValidatedDomains.every(x => x.isAppliedWhoisMailFwd);
        if (isDisable) {
            return true;
        }

        // メール代行未申し込みのドメインがすべてWhois非対応である
        isDisable = this.renewDomainsData.unValidatedDomains.filter(x => !x.isAppliedWhoisMailFwd).every(x => !x.canApplyWhoisProduct);
        if (isDisable) {
            return true;
        }

        return isDisable;
    }

    /**
     * whois代行チェックボックス
     * */
    public onChangeWhoisProxyOption(): void {
        if (this.isCheckedWhoisProxy) {
            this.isDisableWhoisMailFwd = false;
        } else {
            this.isCheckedWhoisMailFwd = false;
        }
        this.updatePrice();
    }

    /**
     * whoisメール転送チェックボックス
     * */
    public onChangeWhoisMailFwdOption(): void {
        if (this.isCheckedWhoisMailFwd && !this.isDisableWhoisProxy) {
            this.isCheckedWhoisProxy = true;
        }

        this.updatePrice();
    }

    /**
     * ドメインプロテクションチェックボックス
     * */
    public onChangeProtectionOption(): void {
        this.updatePrice();
    }

    /**
     * SSL申請チェックボックス
     * */
    public onChangeSslOption(): void {
        if (this.isCheckedSsl) {
            this.isDisableHosting = true;
        } else {
            this.isDisableHosting = this.renewDomainsData.hostingDomainName.length === 0;
        }

        this.updatePrice();
    }

    /**
     * ホスティングチェックボックス
     * */
    public onChangeHostingOption(): void {
        if (this.isCheckedHosting) {
            this.isDisableSsl = true;
        } else {
            this.isDisableSsl = !this.renewDomainsData.isOnamaeUser || this.renewDomainsData.unValidatedDomains.every(x => !x.canApplySsl || x.isAppliedSsl || x.domainName !== this.renewDomainsData.hostingDomainName);
        }

        this.requestValidRenewDomains();
    }

    /**
     * GMOポイントモーダルインスタンス化。@ViewChild()の代わり。
     */
    public onInitGmoModal(componentRef: GmoPointModal2Component): void {
        this.gmoPointModalComponentRef = componentRef;
    }

    /**
     * GMOポイントモーダルオープン
     */
    public onClickOpenGmoModal(): void {
        if (this.totalPrice === '0' && this.gmoPointSetting.usePoint === '0') {
            const notify: NotifyMessage = new NotifyMessage(this.serviceContainer.NotifyService.GetNotifyId(), NotifyType.Error, '今回請求料金が発生していないため、GMOポイントの設定はできません。');
            this.serviceContainer.NotifyService.AddNotifyMessages([notify]);
        } else {
            var total_amount: number = StringUtil.StringWithCommaToNumber(this.totalPrice) + StringUtil.StringWithCommaToNumber(this.gmoPointSetting.usePoint);
            this.gmoPointModalComponentRef.setPrice(this.gmoPointSetting.currentPoint, total_amount.toString());
            this.gmoPointSetting.isOpenedModal = true;
        }
    }

    /**
     *GMOポイントモーダル確定
     * @param useGmoPoint
     */
    public onClickGmoModalDone(useGmoPoint: number) {
        this.gmoPointSetting.usePoint = StringUtil.NumberToStringWithComma(useGmoPoint);
        this.gmoPointSetting.isOpenedModal = false;

        //支払金額計算
        this.updatePrice();
    }

    /**
     * GMOポイントモーダルキャンセル
     */
    public onClickGmoModalCancel(event: any) {
        this.gmoPointSetting.isOpenedModal = false;
    }

    /**
     * オプション一覧　?アイコン
     * @param オプション名
     */
    public onClickQuestionCircle(target: string): void {
        let url: string = "";
        switch (target) {
            case 'whoisProxy':
            case 'whoisProxyMailFwd':
                url = 'https://www.onamae.com/service/whois/images2.html';
                break;
            case 'protection':
                url = 'https://www.onamae.com/service/domainprotection/images.html';
                break;
            case 'ssl':
                url = 'https://www.onamae.com/option/ssl/images/';
                break;
            case 'hosting':
                url = 'https://www.onamae.com/server/campaign/renewregist/';
                break;
            default:
                break;
        }
        this.openUrl(url);
    }

    /**
     * 確定ボタン
     */
    public onClickConfirm(): void {
        this.requestValidRenewDomains(true);
    }

    public onClickRemoveDomain(domainId: string): void {
        this._isRemoveDomain = true;
        var index = this.renewDomainsData.validatedDomains.findIndex(x => x.domainId == domainId);
        if (index !== -1) {
            this.renewDomainsData.validatedDomains.splice(index, 1);
            this.renewDomainsData.unValidatedDomains.splice(index, 1);
        }
        if (this.renewDomainsData.validatedDomains.length === 0) {
            this.isDisplayFreeRegistModal = false;
            this.domains = [];
            this.domainsCheckedHosting = [];
        } else {
            this.updataAfterRemoveDomain();
        }

    }

    public onChangeUpdateYear(domainId: string, _selectedUpdateYear: string): void {
        this.renewDomainsData.validatedDomains.find(x => x.domainId == domainId).updateYear = _selectedUpdateYear;
        this.renewDomainsData.unValidatedDomains.find(x => x.domainId == domainId).selectedUpdateYear = _selectedUpdateYear;
        this.renewDomainsData.validatedDomains.find(x => x.domainId == domainId).updateYearProductId = this.renewDomainsData.unValidatedDomains.find(x => x.domainId == domainId).updateYearItems.find(y => y.year === _selectedUpdateYear).productId;
        this.updatePrice();
        if (this.dataTransferUpdateYearModal.filter(x => x.domainId === domainId).length > 0) {
            this.dataTransferUpdateYearModal.find(x => x.domainId === domainId).year = _selectedUpdateYear;
        } else {
            this.dataTransferUpdateYearModal.push({ domainId: domainId, year: _selectedUpdateYear });
        }
    }

    private _resetNewModalCoupon() {
        this.canShowNewModalCoupon = false;
        this.isShowNewModalCoupon = false;
        this.isApplyCoupon = false;
        this.isFirstViewNewModalCoupon = false;
    }

    /**
     * 閉じるボタン
     * */
    public onCloseButtonClicked(): void {
        if (this.canShowNewModalCoupon
            && !this.isFirstViewNewModalCoupon
            && this.gmoTaxedDiscount > 0
            && StringUtil.StringWithCommaToNumber(this.preDiscountTotal) > 0)
        {
            this._isShow = false;
            this.isShowNewModalCoupon = true;
            this.isFirstViewNewModalCoupon = true;
            CustomizedEvents.dispatchFireEventModalRenewCoupon();
        } else {
            this._resetNewModalCoupon();
            this.onCloseButtonClickedEvent.emit(this.dataTransferUpdateYearModal);
        }
    }

    public onShowModalRenew(): void {
        this.isApplyCoupon = true;
        this.isShowNewModalCoupon = false;
        this._isShow = true;
        this.updatePrice();
    }

    public onCloseModalCoupon(): void {
        this._resetNewModalCoupon();
        this.onCloseButtonClickedEvent.emit(this.dataTransferUpdateYearModal);
    }

    /**
     * 料金計算
     * */
    private updatePrice(): void {
        this.isLoading = true;
        this.isShowSurcharge = false;
        this.freeDomains = [];
        this.domainNameMlFree = '';
        this.domainNameMlWithRs = '';
        if (this.freeRegistDomainsData && this.freeRegistDomainsData.DomainNames.length > 0) {
            let _tld = '';
            let _splitString = this.freeRegistDomainsData.DomainNames[0].split(".");
            if (_splitString.length > 0) {
                _tld = _splitString[1];
            }

            const freeDomainItem = new FreeDomainItem(
                this.freeRegistDomainsData.DomainNames[0],
                '0',
                [
                    new ItemDetail(false, 'ドメイン登録', '0', '0', false, ContentType.DomainRenew),
                    new ItemDetail(false, 'Whois情報公開代行', '0', '0', false, ContentType.Other),
                ],
                '1',
                _tld
            );
            freeDomainItem.isOpened = true;
            freeDomainItem.updateYear = '1';
            this.freeDomains.push(freeDomainItem);
        }
        this.gmoPointSetting = this.renewDomainsData.gmoPointInfo;
        this.paymentType = this.renewDomainsData.paymentType as DomainPaymentType;
        this.isShowPaymentType = this.paymentType != this.PaymentType.Monthly;
        this.conveniInfo = this.renewDomainsData.conveniInfo as DomainRenewUpdatePaymentConveniInfoRequestHubDto;
        this.maskedCreditNumber = this.renewDomainsData.registeredMaskedCreditNumber;
        this.hasCoupon = this.renewDomainsData.hasCoupon;
        this.convenienceFee = StringUtil.StringWithCommaToNumber(this.renewDomainsData.convenienceFee);
        this.convenienceUnTaxFee = StringUtil.StringWithCommaToNumber(this.renewDomainsData.convenienceUnTaxFee);
        // For Fire Evar84
        const reqHubDto = this._getRequestRenewDomain(false);
        if (this.domains.length === 1) {
            this.domains[0].isOpened = true;
        }
        this.serviceContainer.HubsService.multipleSendManager.Send(
            this.queueId,
            this.serviceContainer.HubsService.hubsManager.domainRenewHubManager.GetDomainRenewPrice,
            reqHubDto,
            (resHubDto: DomainRenewPriceResponseHubDto) => {
                this.setPurchasedInfo(resHubDto);
                let _domainHostingDiscountInitialCost = '0';
                let _domainHostingTotalPrice = '0';
                let _domainHostingDiscountPrice = '0';

                resHubDto.RenewDomains.forEach(x => {
                    let targetRenewDomain = this.domains.find(z => z.domainNameMl === x.DomainNameML);
                    targetRenewDomain.totalPrice = x.TotalPrice;

                    let details = [];
                    this.isSslNewApply = false;

                    //ドメイン更新
                    details.push(new ItemDetail(false, 'ドメイン更新', x.RenewPrice, x.RenewUnTaxPrice, false, ContentType.DomainRenew));

                    //オプション
                    if (targetRenewDomain.isWhoisProxy) {
                        var isNewApply = targetRenewDomain.isAppliedWhoisProxy ? false : true;
                        details.push(new ItemDetail(false, 'Whois情報公開代行', x.WhoisProxyPrice, x.WhoisProxyUnTaxPrice, isNewApply));
                    }

                    if (targetRenewDomain.isWhoisMailFwd) {
                        var isNewApply = targetRenewDomain.isAppliedWhoisMailFwd ? false : true;
                        details.push(new ItemDetail(false, 'メール転送オプション', x.WhoisMailFwdPrice, x.WhoisMailFwdUnTaxPrice, isNewApply));
                    }

                    if (targetRenewDomain.isDomainProtection) {
                        var isNewApply = targetRenewDomain.isAppliedDomainProtection ? false : true;
                        details.push(new ItemDetail(false, 'ドメインプロテクション', x.DomainProtectPrice, x.DomainProtectUnTaxPrice, isNewApply));
                    }

                    if (targetRenewDomain.isSsl) {
                        var isNewApply = targetRenewDomain.isAppliedSsl ? false : true;
                        details.push(new ItemDetail(false, 'SSLサーバー証明書', x.SslPrice, x.SslUnTaxPrice, isNewApply));
                        this.isSslNewApply = true;
                    }

                    if (x.SurchargePrice != "0") {
                        details.push(new ItemDetail(false, 'サービス維持調整費 ※', x.SurchargePrice, x.SurchargeUnTaxPrice, false));
                        this.isShowSurcharge = true;
                    }

                    if (targetRenewDomain.isHosting) {
                        // ホスティング同時は複数申し込み可能であっても必ず1つのみの申し込み
                        if (this.domains.length > 1) {
                            details.push(new ItemDetail(false, '※レンタルサーバー同時対象', '0', '0', true, ContentType.DomainRenewWithRs));
                        }
                        this.domainNameMlWithRs = x.DomainNameML;
                        if (this._filterRsTldDomain(x.DomainNameML)) {
                            this.domainNameMlFree = x.DomainNameML;
                        }
                    }

                    //クーポン
                    if (x.CouponDiscountPrice != "0") {
                        details.push(new ItemDetail(true, 'クーポン', x.CouponDiscountPrice, x.CouponDiscoutnUnTaxPrice, isNewApply, ContentType.Coupon));
                    }
                    targetRenewDomain.details = details;
                });

                this.serverItems = [];
                if (this.isCheckedHosting && this.renewDomainsData.hostingDomainName.length > 0) {
                    let _domainHostingDiscountInitialCostTitle = '初期設定費用';
                    let _domainHostingDiscountPriceTitle = '初回料金';
                    const serverItem = new ServerItem(
                        '申込み',
                        this.renewDomainsData.hostingDomainName,
                        this.renewDomainsData.hostingDomainName,
                        _domainHostingTotalPrice,
                        [
                            new ItemDetail(false, _domainHostingDiscountInitialCostTitle, _domainHostingDiscountInitialCost,'0', true),
                            new ItemDetail(false, _domainHostingDiscountPriceTitle, _domainHostingDiscountPrice, '0', true)
                        ]
                    );
                    this.serverItems.push(serverItem);
                }


                this.totalPrice = resHubDto.TotalPrice;
                this.totalUnTaxPrice = resHubDto.TotalUnTaxPrice;

                let totalPrice: number = StringUtil.StringWithCommaToNumber(this.totalPrice);
                let totalUnTaxPrice: number = StringUtil.StringWithCommaToNumber(this.totalUnTaxPrice);

                //GMOポイント
                if (this.gmoPointSetting.isCollaboration) {
                    this.gmoPointDiscountPrice = resHubDto.GmoPointDiscountPrice;
                    const gmoPointDiscountPrice: number = -1 * StringUtil.StringWithCommaToNumber(this.gmoPointDiscountPrice);
                    this.gmoPointSetting.usePoint = StringUtil.NumberToStringWithComma(gmoPointDiscountPrice);
                    this.useGmoPoint = gmoPointDiscountPrice;
                }
                this.gmoPointSetting.useType = this.changedPointUsingType();

                // コンビニ手数料
                this.isViewFee = false;
                if (this.paymentType === DomainPaymentType.Convenience && totalPrice > 0) {
                    this.isViewFee = true;
                    this.totalPrice = StringUtil.NumberToStringWithComma(totalPrice + this.convenienceFee);
                    totalPrice += this.convenienceFee;
                    this.totalUnTaxPrice = StringUtil.NumberToStringWithComma(totalUnTaxPrice + this.convenienceUnTaxFee);
                    totalUnTaxPrice += this.convenienceUnTaxFee;
                }
                const maximumTotalPriceForAbTest = 5000;
                this.paymentPrice = StringUtil.NumberToStringWithComma(totalPrice);
                this.paymentUnTaxPrice = StringUtil.NumberToStringWithComma(totalUnTaxPrice);
                //BtnId
                this.confirmButtonTag = this.createDomainTagBuilder().build();

                // １つでもクーポン適応対象が存在していたら
                this.hasCoupon = this.domains.some(x => x.details.some(z => z.isDiscount));
                if (this._isOpenModal) {
                    this.isTotalPriceMoreThan5000 = totalPrice >= maximumTotalPriceForAbTest;
                    this._isOpenModal = false;
                }

                if ((this.isShowPriceCoupon || this.isShowPriceCouponDomainDetail) && this.isDisableHosting) {
                    this.domains[0].details.push(new ItemDetail(true, 'クーポン', (-this.gmoTaxedDiscount).toString(), "0", false, ContentType.Coupon));
                }

                this.preDiscountTotal = this.totalPrice;
                if (this.isShowPriceCoupon || this.isShowPriceCouponDomainDetail) {
                    let totalPriceWithRenewCoupon = StringUtil.StringWithCommaToNumber(this.totalPrice) - this.gmoTaxedDiscount;
                    this.totalPriceWithRenewCoupon = StringUtil.NumberToStringWithComma(totalPriceWithRenewCoupon > 0 ? totalPriceWithRenewCoupon : 0);
                    this.totalPrice = StringUtil.NumberToStringWithComma(totalPriceWithRenewCoupon > 0 ? totalPriceWithRenewCoupon : 0);
                }

                this.isLoading = false;
            },
            (resHubDto: DomainRenewPriceResponseHubDto) => {
                this.isLoading = false;
                this._resetNewModalCoupon();
                this.onErrorEvent.emit(resHubDto);
                this.onCloseModaldEvent.emit(this.dataTransferUpdateYearModal);
            },
            (resHubDto: DomainRenewPriceResponseHubDto) => {
                this.noSessionEvent.emit(resHubDto);
            },
            (resHubDto: DomainRenewPriceResponseHubDto) => {
                this.isLoading = false;
            },
            (resHubDto: DomainRenewPriceResponseHubDto) => {
                this._resetNewModalCoupon();
                this.onUnExceptedErrorEvent.emit(resHubDto);
                this.onCloseModaldEvent.emit(this.dataTransferUpdateYearModal);
            }
        );

        if (!this.isCheckedHosting && !this.isDisableHosting) {
            const requestHubDto = this._getRequestRenewDomain(true);
            this.serviceContainer.HubsService.multipleSendManager.Send(
                this.queueId,
                this.serviceContainer.HubsService.hubsManager.domainRenewHubManager.GetDomainRenewPrice,
                requestHubDto,
                (resHubDto: DomainRenewPriceResponseHubDto) => {
                    resHubDto.RenewDomains.forEach(x => {
                        let targetRenewDomain = this.domainsCheckedHosting.find(z => z.domainNameMl === x.DomainNameML);
                        targetRenewDomain.totalPrice = x.TotalPrice;

                        let details = [];

                        //ドメイン更新
                        details.push(new ItemDetail(false, 'ドメイン更新', x.RenewPrice, x.RenewUnTaxPrice, false, ContentType.DomainRenew));

                        //オプション
                        if (targetRenewDomain.isWhoisProxy) {
                            var isNewApply = targetRenewDomain.isAppliedWhoisProxy ? false : true;
                            details.push(new ItemDetail(false, 'Whois情報公開代行', x.WhoisProxyPrice, x.WhoisProxyUnTaxPrice, isNewApply));
                        }
                        if (targetRenewDomain.isWhoisMailFwd) {
                            var isNewApply = targetRenewDomain.isAppliedWhoisMailFwd ? false : true;
                            details.push(new ItemDetail(false, 'メール転送オプション', x.WhoisMailFwdPrice, x.WhoisMailFwdUnTaxPrice, isNewApply));
                        }
                        if (targetRenewDomain.isDomainProtection) {
                            var isNewApply = targetRenewDomain.isAppliedDomainProtection ? false : true;
                            details.push(new ItemDetail(false, 'ドメインプロテクション', x.DomainProtectPrice, x.DomainProtectUnTaxPrice, isNewApply));
                        }
                        if (targetRenewDomain.isSsl) {
                            var isNewApply = targetRenewDomain.isAppliedSsl ? false : true;
                            details.push(new ItemDetail(false, 'SSLサーバー証明書', x.SslPrice, x.SslUnTaxPrice, isNewApply));
                        }
                        if (x.SurchargePrice != "0") {
                            details.push(new ItemDetail(false, 'サービス維持調整費 ※', x.SurchargePrice, x.SurchargeUnTaxPrice, false));
                        }

                        if (targetRenewDomain.isHosting) {
                            // ホスティング同時は複数申し込み可能であっても必ず1つのみの申し込み
                            if (this.domains.length > 1) {
                                details.push(new ItemDetail(false, '※レンタルサーバー同時対象', '0', '0', true, ContentType.DomainRenewWithRs));
                            }
                            this.domainNameMlWithRs = x.DomainNameML;
                            if (this._filterRsTldDomain(x.DomainNameML)) {
                                this.domainNameMlFree = x.DomainNameML;
                            }
                        }
                        //クーポン
                        if (x.CouponDiscountPrice != "0") {
                            details.push(new ItemDetail(true, 'クーポン', x.CouponDiscountPrice, x.CouponDiscoutnUnTaxPrice, isNewApply, ContentType.Coupon));
                        }
                        targetRenewDomain.details = details;
                    });

                    let totalPrice: number = StringUtil.StringWithCommaToNumber(resHubDto.TotalPrice);

                    if (this.isShowPriceCoupon) {
                        totalPrice = totalPrice - this.gmoTaxedDiscount;
                    }

                    // コンビニ手数料
                    if (this.paymentType === DomainPaymentType.Convenience && totalPrice > 0) {
                        totalPrice = totalPrice + this.convenienceFee;
                    }

                    this.totalPriceCheckedHosting = StringUtil.NumberToStringWithComma(totalPrice > 0 ? totalPrice : 0);
                },
                (resHubDto: DomainRenewPriceResponseHubDto) => {
                },
                (resHubDto: DomainRenewPriceResponseHubDto) => {
                },
                (resHubDto: DomainRenewPriceResponseHubDto) => {
                    this.isLoading = false;
                },
                (resHubDto: DomainRenewPriceResponseHubDto) => {
                }
            );
        }
    }

    private _getRequestRenewDomain(isPreviewHosting : boolean): DomainRenewPriceRequestHubDto{
        const unValidatedDomains = this.renewDomainsData.unValidatedDomains;
        let domains = this.renewDomainsData.validatedDomains.map(x => {
            return new DomainItem(
                x.domainId,
                x.domainName,
                x.domainNameMl,
                x.tld,
                x.expirationDate,
                x.isRegistryPremium,
                x.canSetAutoRenew,
                x.updateYear,
                x.updateYearProductId,
                x.isAppliedWhoisProxy,
                x.isAppliedWhoisProxy || (unValidatedDomains.find(u => u.domainNameMl === x.domainNameMl).canApplyWhoisProduct && this.isCheckedWhoisProxy), //x.isWhoisProxy
                x.isAppliedWhoisMailFwd,
                x.isAppliedWhoisMailFwd || (unValidatedDomains.find(u => u.domainNameMl === x.domainNameMl).canApplyWhoisProduct && this.isCheckedWhoisMailFwd), //x.isWhoisMailFwd
                x.IsAppliedDomainProtect,
                x.IsAppliedDomainProtect || this.isCheckedProtection, //x.isDomainProtection,
                x.isAppliedSsl,
                // SSLはホスティング未申込の場合はSSL申込可。ホスティング申込不可の場合はホスティングがついているかもしれないのでSSL申込不可。お名前一般ユーザかどうかについては確認済み。
                this.isCheckedSsl && !x.isAppliedSsl && x.domainName === this.renewDomainsData.hostingDomainName, //x.isSsl,
                (isPreviewHosting ? isPreviewHosting : this.isCheckedHosting) && x.domainName === this.renewDomainsData.hostingDomainName, //x.isHosting,
                x.isProtectedWhois,
                x.isProtectedAutoRenew,
                x.isAutoRenewActive,
                x.couponCode,
                '0',
                []
            );
        });

        this.isExistRegistryPremiumDomain = domains.some(x => x.isRegistryPremium);

        if (!isPreviewHosting) {
            this.domains = domains;
        } else {
            this.domainsCheckedHosting = domains;
        }

        let _isAppliedHosting = false;
        let _isAppliedProtection = false;
        if (domains.length === 1) {
            _isAppliedHosting = this.renewDomainsData.hostingDomainName.length === 0;
            if (this.renewDomainsData.unValidatedDomains.length > 0) {
                _isAppliedProtection = this.renewDomainsData.unValidatedDomains[0].isAppliedDomainProtect;
            }
        }

        const reqHubDto: DomainRenewPriceRequestHubDto = {
            GmoPoint: StringUtil.StringWithCommaToNumber(this.gmoPointSetting.usePoint),
            RenewDomains: domains
                .map(x => {
                    return {
                        DomainNameML: x.domainNameMl,
                        ProductId: x.updateYearProductId,
                        IsWhoisProxy: x.isWhoisProxy,
                        IsWhoisMailFwd: x.isWhoisMailFwd,
                        IsDomainProtect: x.isDomainProtection,
                        IsHostingOrder: x.isHosting,
                        IsSsl: x.isSsl,
                        CouponCode: x.couponCode,
                        isAppliedHosting: _isAppliedHosting,
                        isAppliedProtection: _isAppliedProtection,
                        IsRegistryPremium: x.isRegistryPremium
                    } as DomainRenewDomainRequestHubDto
                })
        } as DomainRenewPriceRequestHubDto;

        return reqHubDto;
    }

    public checkRsTldDomain(domainNameML: string): boolean{
        return this._filterRsTldDomain(domainNameML);
    }

    public showPopupPreview(): void {
        this.isCheckedHosting = false;
        this.updatePrice();
    }

    /**
     * ドメインを更新します
     */
    private requestExeRenewDomains(): void {
        this.isLoading = true;

        if (this._isRemoveDomain) {
            this._isFireEvent413 = false;
            this.filterRenewDomainList();
        }
        const reqHubDto: DomainRenewUpdateRequestHubDto = {
            PaymentType: this.paymentType.toString(),
            ConveniInfo: this.conveniInfo,
            HasSetAutoRenewPayment: this.renewDomainsData.hasSetAutoRenewPayment,
            RenewDomains: this.domains.map(x => {
                return {
                    DomainId: x.domainId,
                    Period: x.updateYear,
                    CurExpDate: x.expirationDate,
                    CanSetAutoRenew: x.canSetAutoRenew,
                    IsAppliedWhoisProxy: x.isAppliedWhoisProxy,
                    IsWhoisProxy: x.isWhoisProxy,
                    IsAppliedWhoisMailFwd: x.isAppliedWhoisMailFwd,
                    IsWhoisMailFwd: x.isWhoisMailFwd,
                    IsAppliedDomainProtect: x.isAppliedDomainProtection,
                    IsDomainProtect: x.isDomainProtection,
                    IsAppliedSsl: x.isAppliedSsl,
                    IsSsl: x.isSsl,
                    IsElectric: false,
                    IsProtectedWhois: x.isProtectedWhois,
                    IsProtectedAutoRenew: x.isProtectedAutoRenew,
                    IsRegistryPremium: x.isRegistryPremium,
                    CouponCode: x.couponCode,
                    DomainName: x.domainName,
                    ApplyRenewCoupon: this.isShowPriceCoupon && !this.isShowCoupon1500Yen ? "CouponRenew110Yen" : this.isShowPriceCoupon && this.isShowCoupon1500Yen ? "CouponRenew1500Yen" : this.isShowPriceCouponDomainDetail ? "CouponDomainDetail" : ""
                } as DomainRenewUpdateDomainRequestHubDto;
            }),
            HostingRecommendId: this.isCheckedHosting ? this.renewDomainsData.hostingRecommendId : '',
            HostingDomainName: this.isCheckedHosting ? this.renewDomainsData.hostingDomainName : '',
            GmoPoint: StringUtil.StringWithCommaToNumber(this.gmoPointSetting.usePoint).toString(),
            IsMobile: false
        } as DomainRenewUpdateRequestHubDto;

        this.serviceContainer.HubsService.multipleSendManager.Send(
            this.queueId,
            this.serviceContainer.HubsService.hubsManager.domainRenewHubManager.UpdateDomainRenew,
            reqHubDto,
            async (resHubDto: DomainRenewUpdateResponseHubDto) => {
                const conveniInfoDto = {
                    conveniCode: this.conveniInfo.ConveniCode,
                    userNameKana: this.conveniInfo.UserNameKana,
                    price: this.convenienceFee,
                    unTaxPrice: this.convenienceUnTaxFee
                } as IConveniInfoDto;

                // ドメインプロテクション導線を追加
                let _displayDomainRecommendedForProtecting: string = '';
                if (this.domains.some(x => x.isAppliedDomainProtection === false && x.isDomainProtection === false)) {
                    _displayDomainRecommendedForProtecting = this.domains.filter(x => x.isAppliedDomainProtection === false || x.isDomainProtection === false)[0].domainName;
                }

                // 無料ドメイン作成
                let isCreateDomainSuccess: boolean = false;
                let isFreeDomainRegist: boolean = this.freeRegistDomainsData && this.freeRegistDomainsData.DomainNames.length > 0 && this.freeRegistDomainsData.DomainNames[0] !== '' && this.isDisplayFreeRegistModal;
                if (isFreeDomainRegist) {
                    isCreateDomainSuccess = await this.createDomainPromise();
                }

                // Cookie削除
                docCookies.removeItem('domainRenewalMsg', void (0), this.isProduct ? "onamae.com" : "");

                let checkNameServer = false;
                if (this.isCheckedHosting) {
                    checkNameServer = this.nameServerList.find(x => x.DomainNameMl === this.domainNameMlWithRs).NameServer === "初期設定";
                } else {
                    checkNameServer = this.nameServerList.some(x => x.NameServer === "初期設定");
                }

                if (this.isShowPriceCoupon && !this.isDisableHosting) {
                    this.domains[0].details.push(new ItemDetail(true, 'クーポン', (-this.gmoTaxedDiscount).toString(), "0", false, ContentType.Coupon));
                }

                if (this.isShowPriceCouponDomainDetail && !this.isDisableHosting) {
                    this.domains[0].details.push(new ItemDetail(true, 'クーポン', (-this.gmoTaxedDiscount).toString(), "0", false, ContentType.Coupon));
                }

                const transferData: IDataTransferDomainRenewDoneDto = {
                    invoiceId: resHubDto.InvoiceId,
                    domainPaymentType: this.paymentType,
                    conveniInfo: this.paymentType === this.PaymentType.Convenience ? conveniInfoDto : null,
                    totalPrice: StringUtil.StringWithCommaToNumber(this.totalPrice),
                    totalUnTaxPrice: StringUtil.StringWithCommaToNumber(this.totalUnTaxPrice),
                    isGmoCollaboration: this.gmoPointSetting.isCollaboration,
                    gmoPoint: StringUtil.StringWithCommaToNumber(this.gmoPointSetting.usePoint),
                    hasCoupon: this.domains.some(x => x.couponCode.length > 0),
                    isSslNewApply: this.isSslNewApply,
                    isGmoElectricNewApply: false,
                    isHostingUnpaid: this.renewDomainsData.isHostingUnpaid,
                    domainApplyings: this.domains.map(x => x.convertToDto()),
                    serverApplyings: this.serverItems.map(x => x.convertToDto()),
                    isOnamaeUser: this.renewDomainsData.isOnamaeUser,
                    isShowDomainProtection: this.domains.some(x => x.isAppliedDomainProtection === false && x.isDomainProtection === false),
                    displayDomainRecommendedForProtecting: _displayDomainRecommendedForProtecting,
                    registeredMaskedCreditNumber: this.renewDomainsData.registeredMaskedCreditNumber,
                    domainFreeApplyings: this.freeDomains.map(x => x.convertToDto()),
                    hasFreeDomain: this.isDisplayFreeRegistModal,
                    isCreateFreeDomainSuccess: isCreateDomainSuccess,
                    freeDomainRegistNameMl: isFreeDomainRegist ? this.freeRegistDomainsData.DomainNames[0] : '',
                    isFireEvent413: this._isFireEvent413,
                    isFireEvent581: checkNameServer,
                    isFireEvent582: checkNameServer && this.isCheckedHosting,
                    domainExpirationDaysRemaining: this._minExpireDate,
                    isCheckedLine: false,
                };
                this.serviceContainer.DataTransferService.setData(transferData, this.serviceContainer.DataTransferService.DomainRenewDoneKey);
                
                //お名前.comで更新するドメイントランザクションが完了した後。、クーポン使用済みか判定用のクッキーを追加
                if (this.isShowPriceCouponDomainDetail) {
                    docCookies.setItem("IsApplyCouponDomainDetail", '1', FutureDate.afterDays(90), '/');
                }
                this.hasDomainId() ?
                    this.router.navigate(['/domain/setting/renew/done/' + this.domainId]) :
                    this.router.navigate(['/domain/setting/renew/done']);
            },
            (resHubDto: DomainRenewUpdateResponseHubDto) => {
                this.isLoading = false;
                this._resetNewModalCoupon();
                this.onErrorEvent.emit(resHubDto);
                this.onCloseModaldEvent.emit(this.dataTransferUpdateYearModal);
            },
            (resHubDto: DomainRenewUpdateResponseHubDto) => {
                this.noSessionEvent.emit(resHubDto);
            },
            null,
            (resHubDto: DomainRenewUpdateResponseHubDto) => {
                this._resetNewModalCoupon();
                this.onUnExceptedErrorEvent.emit(resHubDto);
                this.onCloseModaldEvent.emit(this.dataTransferUpdateYearModal);
            }
        );
    }

    /**
     * ドメイン更新可能か検証します
     * 
     * @param isRequestExeDomain
     *          true             :バリデーションが成功したらドメイン更新を実行
     *          falseまたは未定義:バリデーションのみ実行
     */
    private requestValidRenewDomains(isRequestExeDomain?: boolean): void {

        this.isLoading = true;

        const reqHubDto: DomainRenewUpdateValidationRequestHubDto = {
            PaymentType: this.paymentType.toString(),
            ConveniInfo: this.renewDomainsData.conveniInfo,
            HasSetAutoRenewPayment: this.renewDomainsData.hasSetAutoRenewPayment,
            RenewDomains: this.renewDomainsData.unValidatedDomains.map(x => {
                return {
                    DomainId: x.domainId,
                    Period: x.updateYearItems.find(y => y.year === x.selectedUpdateYear).year,
                    CurExpDate: x.expirationDate,
                    CanSetAutoRenew: x.canSetAutoRenew,
                    IsAppliedWhoisProxy: x.isAppliedWhoisProxy,
                    IsWhoisProxy: x.canApplyWhoisProduct && this.isCheckedWhoisProxy,
                    IsAppliedWhoisMailFwd: x.isAppliedWhoisMailFwd,
                    IsWhoisMailFwd: x.canApplyWhoisProduct && this.isCheckedWhoisMailFwd,
                    IsAppliedDomainProtect: x.isAppliedDomainProtect,
                    IsDomainProtect: x.isAppliedDomainProtect,
                    IsAppliedSsl: x.isAppliedSsl,
                    // SSLはホスティング未申込の場合はSSL申込可。ホスティング申込不可の場合はホスティングがついているかもしれないのでSSL申込不可。お名前一般ユーザかどうかについては確認済み。
                    IsSsl: this.isCheckedSsl && !x.isAppliedSsl && x.domainName === this.renewDomainsData.hostingDomainName,
                    IsProtectedWhois: this.isCheckedProtection,
                    IsProtectedAutoRenew: x.isProtectedAutoRenew,
                    IsAutoRenewActive: x.isAutoRenewActive,
                    IsRegistryPremium: x.isRegistryPremium,
                    CouponCode: x.couponCode,
                    DomainName: x.domainName
                } as DomainRenewUpdateValidationDomainRequestHubDto;
            }),
            HostingRecommendId: this.isCheckedHosting ? this.renewDomainsData.hostingRecommendId : '',
            HostingDomainName: this.isCheckedHosting ? this.renewDomainsData.hostingDomainName : '',
            GmoPoint: StringUtil.StringWithCommaToNumber(this.gmoPointSetting.usePoint).toString()
        } as DomainRenewUpdateValidationRequestHubDto;

        this.serviceContainer.HubsService.multipleSendManager.Send(
            this.queueId,
            this.serviceContainer.HubsService.hubsManager.domainRenewHubManager.ValidUpdateDomainRenew,
            reqHubDto,
            (resHubDto: DomainRenewUpdateValidationResponseHubDto) => {
                if (isRequestExeDomain) {
                    this.requestExeRenewDomains();
                } else {
                    this.updatePrice();
                }
            },
            (resHubDto: DomainRenewUpdateValidationResponseHubDto) => {
                this.isLoading = false;
                this._resetNewModalCoupon();
                this.onErrorEvent.emit(resHubDto);
                this.onCloseModaldEvent.emit(this.dataTransferUpdateYearModal);
            },
            (resHubDto: DomainRenewUpdateValidationResponseHubDto) => {
                this.noSessionEvent.emit(resHubDto);
            },
            null,
            (resHubDto: DomainRenewUpdateValidationResponseHubDto) => {
                this._resetNewModalCoupon();
                this.onUnExceptedErrorEvent.emit(resHubDto);
                this.onCloseModaldEvent.emit(this.dataTransferUpdateYearModal);
            }
        );
    }

    /**
     * ドメイン作成
     * @returns
     */
    private createDomainPromise(): Promise<boolean> {
        return new Promise<boolean>((resolva, reject) => {
            this.serviceContainer.HubsService.multipleSendManager.Send(
                this.queueId,
                this.serviceContainer.HubsService.hubsManager.domainRenewHubManager.CreateDomain,
                this.freeRegistDomainsData,
                (resHubDto: DomainRenewUpdateResponseHubDto) => {
                    resolva(true);
                },
                (resHubDto: DomainRenewUpdateResponseHubDto) => {
                    resolva(false);
                },
                (resHubDto: DomainRenewUpdateResponseHubDto) => {
                    resolva(false);
                },
                null,
                (resHubDto: DomainRenewUpdateResponseHubDto) => {
                    resolva(false);
                }
            );
        })
    }

    private filterRenewDomainList(): void {
        let today = new Date();
        let lastDayOfNextMonth = new Date(today.getFullYear(), today.getMonth() + 2, 0);
        let firstDayOfPreviousMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        let currenExpireDate = 0;
        for (var i = 0; i < this.domains.length; i++) {
            let _exprire = new Date(this.domains[i].expirationDate);
            if (!this._isFireEvent413) {
                this._isFireEvent413 = firstDayOfPreviousMonth.getTime() <= _exprire.getTime() && _exprire.getTime() <= lastDayOfNextMonth.getTime();
            }
            currenExpireDate = this.DateDiff(_exprire);
            if (i === 0 || (i > 0 && currenExpireDate < this._minExpireDate)){
                this._minExpireDate = currenExpireDate;
            }
        }
    }

    private DateDiff(dateCheck: Date): number {
        let currentDate = new Date();
        return Math.floor((Date.UTC(dateCheck.getFullYear(), dateCheck.getMonth(), dateCheck.getDate()) - Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate())) / (1000 * 60 * 60 * 24));
    }

    private hasDomainId(): boolean {
        return this.domainId !== void 0 && this.domainId !== null;
    }

    public get gtmTag(): string {
        return 'transition_apply' + this.confirmButtonTag + '_01'
    }

    /**
     * 確認画面用タグ生成
     */
    private createDomainTagBuilder(): DomainTagBuilder {
        const tagBuilder = new DomainTagBuilder();

        // ドメイン更新は常に存在
        tagBuilder.addTag(DomainTagType.RenewDomain);

        if (this.isCheckedWhoisProxy && !this.isDisableWhoisProxy) {
            tagBuilder.addTag(DomainTagType.Whois);
        }

        if (this.isCheckedWhoisMailFwd && !this.isDisableWhoisMailFwd) {
            tagBuilder.addTag(DomainTagType.WhoisMailTransferOption);
        }

        if (this.isCheckedProtection) {
            tagBuilder.addTag(DomainTagType.DomainProtection);
        }

        if (this.isCheckedHosting) {
            tagBuilder.addTag(DomainTagType.SdRentalServer);
        }

        if (this.isCheckedSsl) {
            tagBuilder.addTag(DomainTagType.Ssl);
        }

        return tagBuilder;
    }

    private setPurchasedInfo(resHubDto: DomainRenewPriceResponseHubDto): void {
        let _purchasedInfo = new PurchasedInfoForAdobeAnalytics();

        resHubDto.RenewDomains.forEach(x => {
            let targetRenewDomain = this.domains.find(z => z.domainNameMl === x.DomainNameML);
            let getTldFunc = function (t: string[]) { return '.' + (t[t.length - 1].trim()); };

            //ドメイン更新
            let tld = getTldFunc(targetRenewDomain.tld.split('.'));
            _purchasedInfo.add(tld, tld + targetRenewDomain.updateYear + '年更新', StringUtil.StringWithCommaToNumber(x.RenewUnTaxPrice));
            if (!targetRenewDomain.isAutoRenewActive && (this.paymentType === DomainPaymentType.NewCreditCard || this.paymentType === DomainPaymentType.RegisteredCreditCard)) {
                _purchasedInfo.add('自動更新', '自動更新', 0);
            }

            //オプション
            if (targetRenewDomain.isWhoisProxy) {
                _purchasedInfo.add('Whois情報公開代行', 'Whois情報公開代行', StringUtil.StringWithCommaToNumber(x.WhoisProxyUnTaxPrice));
            }
            if (targetRenewDomain.isWhoisMailFwd) {
                _purchasedInfo.add('Whois代行メール転送', 'Whois代行メール転送', StringUtil.StringWithCommaToNumber(x.WhoisMailFwdUnTaxPrice));
            }
            if (targetRenewDomain.isDomainProtection) {
                _purchasedInfo.add('ドメインプロテクション', 'ドメインプロテクション', StringUtil.StringWithCommaToNumber(x.DomainProtectUnTaxPrice));
            }
            if (targetRenewDomain.isSsl) {
                _purchasedInfo.add('SSLサーバー証明書', 'SSLサーバー証明書', StringUtil.StringWithCommaToNumber(x.SslUnTaxPrice));
            }
            if (targetRenewDomain.isHosting) {
                // ホスティング同時は複数申し込み可能であっても必ず1つのみの申し込み
                _purchasedInfo.add('SD同時登録', 'SD同時登録', 0);
            }
        });

        //売り上げ計測用の情報を生成(AdobeAnalytics用)
        this.purchasedInfoString = _purchasedInfo.getAsString();
    }

    /**
     * GMOポイントの表示条件
     */
    private changedPointUsingType(): PointUsingType {
        if (this.gmoPointSetting.isCollaboration === false) {
            return PointUsingType.NotCollaboration;
        }

        if (this.hasCoupon) {
            return PointUsingType.NotUsePoint;
        }

        if (this.gmoPointSetting.currentPoint !== '0' && this.gmoPointSetting.usePoint === '0') {
            return PointUsingType.NonUsePoint;
        }

        if (this.gmoPointSetting.currentPoint === '0') {
            return PointUsingType.NotHavePoint;
        }

        return PointUsingType.UsePoint;
    }

    public onMouseEnter(event: Event): void {
        this.isHoverCloseButton = true;
    }

    public onMouseLeave(event: Event): void {
        this.isHoverCloseButton = false;
    }

    public openUrl(url: string): void {
        window.open(url, '_blank', 'noopener,noreferrer');
    }
}

export class DomainItem {
    public isOpened: boolean = false;
    public constructor(
        public readonly domainId: string,
        public readonly domainName: string,
        public readonly domainNameMl: string,
        public readonly tld: string,
        public readonly expirationDate: string,
        public readonly isRegistryPremium: boolean,
        public readonly canSetAutoRenew: boolean,
        public readonly updateYear: string,
        public readonly updateYearProductId: string,
        public readonly isAppliedWhoisProxy: boolean,
        public readonly isWhoisProxy: boolean,
        public readonly isAppliedWhoisMailFwd: boolean,
        public readonly isWhoisMailFwd: boolean,
        public readonly isAppliedDomainProtection: boolean,
        public readonly isDomainProtection: boolean,
        public readonly isAppliedSsl: boolean,
        public readonly isSsl: boolean,
        public readonly isHosting: boolean,
        public readonly isProtectedWhois: boolean,
        public readonly isProtectedAutoRenew: boolean,
        public readonly isAutoRenewActive: boolean,
        public readonly couponCode: string,
        public totalPrice: string,
        public details: ItemDetail[]
    ) { }

    public convertToDto(): IDomainApplyingDto {
        return {
            domainNameMl: this.domainNameMl,
            domainName: this.domainName,
            tld: this.tld,
            updateYear: this.updateYear,
            items: this.details.map(x => x.convertToDto()),
        } as IDomainApplyingDto;
    }
}
class FreeDomainItem {
    public isOpened: boolean = false;

    public constructor(
        public readonly domainNameMl: string,
        public readonly totalPrice: string,
        public readonly details: ItemDetail[],
        public updateYear: string,
        public tld: string
    ) { }

    public convertToDto(): IFreeDomainApplyingDto {
        return {
            domainNameMl: this.domainNameMl,
            tld: this.tld,
            updateYear: this.updateYear,
            details: this.details.map(x => x.convertToDto()),
        } as IFreeDomainApplyingDto;
    }
}
export class ServerItem {
    public constructor(
        public readonly serverName: string,
        public readonly domainName: string,
        public readonly domainNameMl: string,
        public readonly totalPrice: string,
        public readonly details: ItemDetail[]
    ) { }

    public convertToDto(): IServerApplyingDto {
        return {
            serverName: this.serverName,
            domainName: this.domainName,
            domainNameMl: this.domainNameMl,
            priceYen: this.totalPrice,
            details: this.details.map(x => x.convertToDto())
        } as IServerApplyingDto;
    }
}
