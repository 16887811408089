export class LinkService {
    private accountInfo: LinkAccountInfo = null;

    public get hasAccountInfo(): boolean {
        return !!this.accountInfo;
    }

    public GetAccountInfo(): LinkAccountInfo {
        return this.accountInfo;
    }

    public register(accountInfo: LinkAccountInfo) {
        this.accountInfo = accountInfo;
    }

    public drop() {
        this.accountInfo = null;
    }
}

export class LinkAccountInfo {
    public constructor(public readonly loginName: string) {}
}