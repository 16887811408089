import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[showHideToggle]'
})
export class ShowHideToggleDirective {
    constructor(
        private elementRef: ElementRef
    ) { }

    @HostListener('click', ['$event'])
    public onClick(event : Event) {
        event.preventDefault();
        this._showHideButtonToggle();
    }

    private _showHideButtonToggle(): void {
        const parentElement = this.elementRef.nativeElement.parentElement;
        const targetElement = parentElement.querySelector('.is-ShowHideToggle');
        if (targetElement) {
            const hasClass = targetElement.classList.contains('is-Hide');
            if (hasClass) {
                targetElement.classList.remove('is-Hide');
            } else {
                targetElement.classList.add('is-Hide');
            }
        }
    }
}
