import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';

/**
 * 指定された要素にスクロールさせる
 * */
@Directive({
    selector: '[autoscroll]'
})
export class AutoScrollDirective implements OnChanges {
    private _el: HTMLElement;

    @Input('autoscroll')
    public autoscroll: boolean;

    /**
     * auto / smooth
     */
    @Input('behavior')
    public behavior: string = 'auto';

    /**
     * コンストラクタ
     * @param elementRef
     */
    public constructor(private elementRef: ElementRef) {
        this._el = elementRef.nativeElement;
    }

    /**
     * @inheritdoc
     * @param changes
     */
    public ngOnChanges(changes: SimpleChanges): void {
        for (const prop in changes) {
            let change = changes[prop];

            if (prop == "autoscroll") {
                if (change.currentValue) {
                    this.scroll();
                }
            }
        }
    }

    /**
     * scroll
     */
    private scroll(): void {
        let innerHeight = window.innerHeight;
        let elementHeight = this._el.clientHeight;
        let elementTop = this._el.getBoundingClientRect().top;

        const offset = (innerHeight - elementHeight) / 2;
        const scrollPosition = elementTop + window.pageYOffset - offset;

        window.scrollTo({
            top: scrollPosition,
            behavior: this.behavior as ScrollBehavior,
        });
    }
}
