import { Directive, ElementRef, Input, HostListener } from '@angular/core';

@Directive({
    selector: '[popup]'
})
export class SpPopupDirective {
    private _el: HTMLElement;

    private popupElement: HTMLElement;

    constructor(private _elementRef: ElementRef) {
        this._el = _elementRef.nativeElement;
    }
    private targetElement: HTMLElement;

    @Input('placement')
    public placement: string;

    @Input('target')
    public target: string;

    @Input('option')
    public option: string

    @Input('screen')
    public screen: string;

    @HostListener('document:click', ['$event.target'])
    public onClickInside(targetElement) {
        if (!this.targetElement) {
            this.targetElement = document.querySelector(this.target) as HTMLElement;
        }
        if (this.targetElement.classList.contains('is-show')) {

            const clickedInside = this.targetElement.contains(targetElement) || this._el.contains(targetElement);
            if (!clickedInside) {
                this.targetElement.classList.remove('is-show');
            }
        }
    }

    @HostListener('click', ['$event'])
    @HostListener('touchend', ['$event'])
    public onClick(event: Event) {
        var ADJUSTMENT_TOP = 0;
        var ADJUSTMENT_LEFT = 0;

        if (!this.targetElement) {
            this.targetElement = document.querySelector(this.target) as HTMLElement;
        }
        var positionClass = '';
        switch (this.placement) {
            case 'top':
                positionClass = 'is-top';
                break;
            case 'left':
                positionClass = 'is-left';
                break;
            case 'right':
                positionClass = 'is-right';
                break;
            default:
                positionClass = 'is-bottom';
                break;
        }

        this.targetElement.classList.add('is-show');
        this.targetElement.classList.add(positionClass);

        if (!!this.option) {
            this.targetElement.classList.add(this.option);
        }

        var thisRect = this._el.getBoundingClientRect();
        var targetRect = this.targetElement.getBoundingClientRect();

        var setLeft: number = 0;
        var setTop: number = 0;
        if (this.screen !== 'vps') {
            switch (this.placement) {
                case 'top':
                    ADJUSTMENT_TOP = 10;
                    ADJUSTMENT_LEFT = 5;
                    setLeft = thisRect.left - ((targetRect.width - thisRect.width) / 2) - ADJUSTMENT_LEFT;
                    setTop = thisRect.top - targetRect.height + window.pageYOffset - ADJUSTMENT_TOP;

                    break;
                case 'left':
                    setLeft = thisRect.left - targetRect.width;
                    setTop = thisRect.top + ((thisRect.height - targetRect.height) / 2) + window.pageYOffset;

                    break;
                case 'right':
                    setLeft = thisRect.left + thisRect.width;
                    setTop = thisRect.top + ((thisRect.height - targetRect.height) / 2) + window.pageYOffset;
                    break;
                default:
                    setLeft = thisRect.left - ((targetRect.width - thisRect.width) / 2);
                    setTop = thisRect.top + thisRect.height + window.pageYOffset;

                    break;
            }
            this.targetElement.style.left = setLeft + 'px';
            this.targetElement.style.top = setTop + 'px';
        } else {
            switch (this.placement) {
                case 'top':
                    ADJUSTMENT_TOP = 10;
                    ADJUSTMENT_LEFT = 5;
                    setLeft = thisRect.left - ((targetRect.width - thisRect.width) / 2) - ADJUSTMENT_LEFT;
                    setTop = thisRect.top - targetRect.height + window.pageYOffset - ADJUSTMENT_TOP;
                    break;
            }
            this.targetElement.style.position = "absolute";
            this.targetElement.style.left = setLeft + 'px';
            this.targetElement.style.top = setTop + 'px';
        }
        var clickEventType = ((window.ontouchstart !== null) ? 'click' : 'touchend')
        var popupClose = document.querySelector('.popupClose') as HTMLElement;
        popupClose.addEventListener(clickEventType, (event: MouseEvent) => {
            this.targetElement.classList.remove('is-show');
        });
        
    }

    @HostListener('window:touchmove', ['$event'])
    public onScroll(event) {
        if (!this.targetElement) {
            this.targetElement = document.querySelector(this.target) as HTMLElement;
        }
        if (this.targetElement.classList.contains('is-show')) {
            this.targetElement.classList.remove('is-show');
        }
    }
}
