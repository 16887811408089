import { Component, EventEmitter, Input, Output } from '@angular/core';
import { openUrlByBlank } from '../../../../libs/utils/windowUtility';

@Component({
    selector: 'note-cancel-item-service',
    template: `
<div class="section mt56">
    <h2 class="hdn-Lev3">ご注意事項</h2>
      <div class="box-Border is-Thick">
          <div class="box-Border-Inner">
              <ul class="list-Normal mb0"><span class="text-Danger"></span>
                  <li class="mb12">サービスのご解約は、<a href="javascript:void(0)" (click)="onclickAgreement()">お名前.com レンタルサーバーサービス会員規約</a>に基づき、最短で<span class="text-Danger">翌月の末日</span>より承ります。<br>お手続きの当月でのご解約は承ることができませんのでご注意ください。<br>【例】現在が2月の場合、最短の解約日は3月31日となります。2月中のご解約はできません。</li>
                  <li class="mb12"><span class="text-Danger">最低利用期間中</span>はご解約いただくことができません。<br>最低利用期間はキャンペーン等による無料期間を除く3ヶ月間です。詳細は <a href="javascript:void(0)" (click)="onclickAnswer()">こちら</a> にてご確認ください。</li>
                  <li [class.mb12]="isRsPlan">解約完了後、データは削除となり<span class="text-Danger">復旧はできません。</span></li>
                  <li *ngIf="isRsPlan">ドメインとRSプランの同時申込みにより、ドメイン更新料金永久無料特典が適用されている場合、RSプランの解約に伴い、特典が適用外となります。</li>
              </ul>
          </div><!-- /.box-Border-Inner -->
      </div><!-- /box-Border -->
  </div>
`
})
export class NoteCancelItemService {
    @Input() isRsPlan: boolean = false;

    public onclickAgreement() {
        window.open('https://www.onamae.com/server/rs/agreement/', '_blank', "noopener noreferrer");
    }
    public onclickAnswer() {
        window.open('https://help.onamae.com/answer/8950', '_blank', "noopener noreferrer");
    }
}
