import { createReducer, createFeatureSelector, createSelector, on } from '@ngrx/store';
import { DomainWithDefaultNameServerState } from '../states/domain-with-defaultname-server';
import { setDomainWithDefaultNameServer, resetDomainWithDefaultNameServer } from '../actions/domain-with-defaultname-server';

export const domainWithDefaultNameServerFeatureKey = 'domainWithDefaultNameServer';

const initialState: DomainWithDefaultNameServerState = {
    isInitialized: false,
    DomainIds: [],
    hasDomainWithDefaultNameServer: false,
    hasDomainWithoutDefaultNameServer: false
};

export const reducerDomainWithDefaultNameServer = createReducer(
    initialState,
    on(setDomainWithDefaultNameServer, (state: DomainWithDefaultNameServerState, newState: DomainWithDefaultNameServerState) => ({ ...state, ...newState })),
    on(resetDomainWithDefaultNameServer, (state: DomainWithDefaultNameServerState) => (initialState))
);


const getState = createFeatureSelector<DomainWithDefaultNameServerState>(domainWithDefaultNameServerFeatureKey);

export const getDomainWithDefaultNameServerState = createSelector(
    getState,
    state => state
);


