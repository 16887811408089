import { Directive, forwardRef } from '@angular/core';
import { NG_VALIDATORS, Validator, ValidatorFn, AbstractControl, Validators } from '@angular/forms';

const STREET_NUMBER_INVALID_VALIDATOR: any = {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => StreetNumberInvalidValidator),
    multi: true
};

@Directive({
    selector: '[streetNumberInvalid][formControlName],[streetNumberInvalid][formControl],[streetNumberInvalid][ngModel]',
    providers: [STREET_NUMBER_INVALID_VALIDATOR]
})

// 数字が先頭に入っていない場合はエラーを出す。#だけは、先頭に入っていても許可する。
export class StreetNumberInvalidValidator implements Validator {
    validate(c: AbstractControl): { [key: string]: any } {
        return CustomValidators.streetNumberInvalid(c);
    }
}

export class CustomValidators {

    static streetNumberInvalid(control: AbstractControl): { [key: string]: boolean } {
        if (isPresent(Validators.required(control))) return null;

        let v: string = control.value;
        
        // フォ－マット(数字のみ)の確認
        let rNumberPattern: string = '^(?!\\s)+[0-9]*$';
        // 先頭の一文字を取得
        let leadValue: string = v.length > 1 ? v.slice(0, 1) : v;
        // 数字または#以外の場合エラーにする
        if (new RegExp(rNumberPattern).test(leadValue) == false && leadValue !== '#') {
            return { 'streetNumberInvalid': true };
        }
        return null;
    }
}

export function isPresent(obj: any): boolean {
    return obj !== undefined && obj !== null;
}