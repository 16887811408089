<ng-container *ngIf="isDisableView === false">
    <form #paymentForm="ngForm" novalidate (click)="setCurrentPaymentForm(paymentForm)">
    <ul class="list-Selection is-BorderFrame">
        <!-- 登録済みのクレジットカード -->
        <li class="list-Selection-Item" *ngIf="isDisplayRegistedCreditCard">
            <input type="radio" name="paymentTypeDummy" class="gtmclick" data-gtmvalue="d_selected_regd_credit" id="paymentTypeDummy1" value="{{PaymentType.RegisteredCreditCard}}" [checked]="selectedPaymentType === PaymentType.RegisteredCreditCard" (change)="onChangePaymentMethodRadio(PaymentType.RegisteredCreditCard)">
            <label class="form-Radio" for="paymentTypeDummy1" tabindex="0"></label>
            <label class="list-Selection-Item-Label" for="paymentTypeDummy1">登録済みのクレジットカード <small *ngIf="registeredCreditCard.canUseCredit === false" class="text-Danger">※このカードは有効期限切れです。クレジットカードの情報を更新してください。</small></label>
            <div class="list-Selection-Item-Content" *ngIf="selectedPaymentType === PaymentType.RegisteredCreditCard">
                <table class="tbl-Border mt6">
                    <colgroup>
                        <col width="138">
                        <col width="">
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>カード番号</th>
                            <td><span [class.text-Danger]="registeredCreditCard.canUseCredit === false">{{registeredCreditCard.creditNumber}}</span></td>
                        </tr>
                        <tr>
                            <th>有効期限</th>
                            <td><span [class.text-Danger]="registeredCreditCard.canUseCredit === false">{{registeredCreditCard.creditPeriodMonthYear}}</span></td>
                        </tr>
                    </tbody>
                </table>
                <ul class="list-Normal">
                    <li>
                        「クレジットカード」でお申込みいただいた場合、更新忘れを防止する「<span class="text-Danger">自動更新</span>」が設定されます。
                    </li>
                </ul>
            </div>
        </li>
        <!-- 新規クレジットカード -->
        <li class="list-Selection-Item" *ngIf="isDisplayNewCreditCard">
            <input type="radio" name="paymentTypeDummy" class="gtmclick" data-gtmvalue="d_selected_new_credit" id="paymentTypeDummy2" value="{{PaymentType.NewCreditCard}}" [checked]="selectedPaymentType === PaymentType.NewCreditCard" (change)="onChangePaymentMethodRadio(PaymentType.NewCreditCard)">
            <label class="form-Radio" for="paymentTypeDummy2" tabindex="0"></label>
            <label class="list-Selection-Item-Label" for="paymentTypeDummy2">新しいクレジットカード </label>
            <div class="list-Selection-Item-Content" *ngIf="selectedPaymentType === PaymentType.NewCreditCard">
                <table class="tbl-Border">
                    <colgroup>
                        <col width="138">
                        <col width="266">
                        <col width="">
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>カード番号</th>
                            <td colspan="2">
                                <input type="text" class="form-Text gtmclick" data-gtmvalue="d_input_credit_number" [class.form-Error]="(!isValidCreditCard || v_creditNumber.errors && (v_creditNumber.dirty || v_creditNumber.touched))"
                                name="creditNumber" [(ngModel)]="inputNewCreditCard.creditNumber" #v_creditNumber="ngModel" [input]="inputNewCreditCard.creditNumber" required maxlength="{{creditCardLength}}" (keydown)="onKeyDownCreditCard($event)" (input)="onChangeCreditCard()" pattern="{{inputNewCreditCard.rNumberPatternWithSpace}}">
                                <ul class="list-Credit pl20">
                                    <li [class.is-Selected]="isVisa"><img src="{{CacheBusting('/Content/images/common/credit-visa.png')}}" alt="VISA"></li>
                                    <li [class.is-Selected]="isMasterCard"><img src="{{CacheBusting('/Content/images/common/credit-master.png')}}" alt="Master Card"></li>
                                    <li [class.is-Selected]="isJCB"><img src="{{CacheBusting('/Content/images/common/credit-jcb.png')}}" alt="JCB"></li>
                                    <li [class.is-Selected]="isDinersClub"><img src="{{CacheBusting('/Content/images/common/credit-diners.png')}}" alt="Diners Club"></li>
                                    <li [class.is-Selected]="isAmericanExpress"><img src="{{CacheBusting('/Content/images/common/credit-amex.png')}}" alt="AMERICAN EXPRESS"></li>
                                </ul>
                                <div *ngIf="(!isValidCreditCard || v_creditNumber.errors && (v_creditNumber.dirty || v_creditNumber.touched))">
                                    <label *ngIf="v_creditNumber.errors != null && v_creditNumber.errors.required" class="form-Error">
                                    必須項目です
                                    </label>
                                    <label *ngIf="v_creditNumber.errors != null && v_creditNumber.errors.pattern" class="form-Error">
                                    入力形式を確認してください
                                    </label>
                                    <label *ngIf="!isValidCreditCard" class="form-Error">
                                    有効なクレジットカード番号を入力してください。
                                    </label>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>カード名義人</th>
                            <td colspan="2">
                                <input type="text" class="form-Text gtmclick" data-gtmvalue="d_input_credit_holder" placeholder="TARO ONAMAE" [class.form-Error]="(v_creditHolder.errors && (v_creditHolder.dirty || v_creditHolder.touched))"
                                name="creditHolder" [(ngModel)]="inputNewCreditCard.creditHolder" #v_creditHolder="ngModel" required maxByte="50" pattern="{{inputNewCreditCard.rCreditHolder}}">
                                <div *ngIf="(v_creditHolder.errors && (v_creditHolder.dirty || v_creditHolder.touched))">
                                    <label *ngIf="v_creditHolder.errors != null && v_creditHolder.errors.required" class="form-Error">
                                    必須項目です
                                    </label>
                                    <label *ngIf="v_creditHolder.errors != null && v_creditHolder.errors.maxByte" class="form-Error">
                                    最大文字数：50
                                    </label>
                                    <label *ngIf="v_creditHolder.errors != null && v_creditHolder.errors.pattern" class="form-Error">
                                    入力形式を確認してください
                                    </label>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>有効期限</th>
                            <td colspan="2">
                                <div class="form-SelectGroup is-Hyphen">
                                    <span class="form-Select is-Auto">
                                        <select name="creditPeriodMonth" #v_creditPeriodMonth="ngModel" class="gtmclick" data-gtmvalue="d_input_credit_month" [class.form-Error]="(v_creditPeriodYear.errors && (v_creditPeriodYear.dirty || v_creditPeriodYear.touched)) || isCreditCardExpired" [(ngModel)]="inputNewCreditCard.creditPeriodMonth"  (change)="onchangeCreditPeriodMonth($event.target.value)">
                                        <option value="01">1</option>
                                        <option value="02">2</option>
                                        <option value="03">3</option>
                                        <option value="04">4</option>
                                        <option value="05">5</option>
                                        <option value="06">6</option>
                                        <option value="07">7</option>
                                        <option value="08">8</option>
                                        <option value="09">9</option>
                                        <option value="10">10</option>
                                        <option value="11">11</option>
                                        <option value="12">12</option>
                                        </select>
                                    </span>
                                    <span class="form-Select is-Auto">
                                    <select name="creditPeriodYear" [(ngModel)]="inputNewCreditCard.creditPeriodYear" #v_creditPeriodYear="ngModel" class="gtmclick" data-gtmvalue="d_input_credit_year" (change)="onchangeCreditPeriodYear($event.target.value)">
                                    <option *ngFor="let creditPeriodYearItem of inputNewCreditCard.creditPeriodYearItems" [value]="creditPeriodYearItem.value">{{creditPeriodYearItem.text}}</option>
                                    </select>
                                    </span>
                                </div>
                              
                                <div *ngIf="isCreditCardExpired">
                                  <label *ngIf="(v_creditPeriodYear.errors && (v_creditPeriodYear.dirty || v_creditPeriodYear.touched)) || (v_creditPeriodMonth.errors && (v_creditPeriodMonth.dirty || v_creditPeriodMonth.touched)) || isCreditCardExpired" class="form-Error">
                                    有効期限が切れています。
                                  </label>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>セキュリティコード</th>
                            <td colspan="2">
                                <input type="password" class="form-Text is-Small gtmclick" data-gtmvalue="d_input_security_code" placeholder="xxx" [class.form-Error]="(v_securityCode.errors && (v_securityCode.dirty || v_securityCode.touched))"
                                name="securityCode" [(ngModel)]="inputNewCreditCard.securityCode" #v_securityCode="ngModel" required autocomplete="cc-csc" pattern="{{inputNewCreditCard.rNumberPattern}}">
                                <a href="javascript:void(0)" class="text-Nodeco link-DarkGray ml16" onamaePopup placement="bottom" content="{{inputNewCreditCard.securityCodePopup}}">
                                <i class="icon icon-QuestionCircle mr4"></i>セキュリティコードについて
                                </a>
                                <div *ngIf="(v_securityCode.errors && (v_securityCode.dirty || v_securityCode.touched))">
                                    <label *ngIf="v_securityCode.errors != null && v_securityCode.errors.required" class="form-Error">
                                    必須項目です
                                    </label>
                                    <label *ngIf="v_securityCode.errors != null && v_securityCode.errors.pattern" class="form-Error">
                                    入力形式を確認してください
                                    </label>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <ul class="list-Normal">
                    <li>
                        「クレジットカード」を選択された場合、更新忘れを防止する「<span class="text-Danger">自動更新</span>」が設定されます。
                    </li>
                </ul>
            </div>
        </li>
        <!-- コンビニエンスストア -->
        <li *ngIf="isDisplayConvenience" class="list-Selection-Item">
            <input type="radio" name="paymentTypeDummy" class="gtmclick" data-gtmvalue="d_selected_convenience" id="paymentTypeDummy3" value="{{PaymentType.Convenience}}" [disabled]="isDisableConvenience" [checked]="selectedPaymentType === PaymentType.Convenience" (change)="onChangePaymentMethodRadio(PaymentType.Convenience)">
            <label class="form-Radio" for="paymentTypeDummy3" tabindex="0"></label>
            <label class="list-Selection-Item-Label" for="paymentTypeDummy3">コンビニエンスストア </label>
            <div class="list-Selection-Item-Content" *ngIf="selectedPaymentType === PaymentType.Convenience">
                <ul class="list-Checkable mt16 mb0">
                    <li>
                        <input type="radio" id="convenienceCode11" name="convenienceCode" class="gtmclick" data-gtmvalue="d_selected_se" value="{{ConvenienceType.SevenEleven}}" [checked]="inputConvenience.selectedConvenienceType === ConvenienceType.SevenEleven" (change)="onChangePaymentMethodConvenienceRadio(ConvenienceType.SevenEleven)">
                        <label class="form-Radio is-Middle" for="convenienceCode11" tabindex="0"></label>
                        <label for="convenienceCode11">セブンイレブン</label>
                    </li>
                    <li>
                        <input type="radio" id="convenienceCode21" name="convenienceCode" class="gtmclick" data-gtmvalue="d_selected_fm" value="{{ConvenienceType.FamilyMart}}" [checked]="inputConvenience.selectedConvenienceType === ConvenienceType.FamilyMart" (change)="onChangePaymentMethodConvenienceRadio(ConvenienceType.FamilyMart)">
                        <label class="form-Radio is-Middle" for="convenienceCode21" tabindex="0"></label>
                        <label for="convenienceCode21">ファミリーマート</label>
                        <ng-container *ngIf="inputConvenience.selectedConvenienceType === ConvenienceType.FamilyMart">
                            <input type="text" class="form-Text is-Small ml12 gtmclick" data-gtmvalue="d_input_name_kana" placeholder="姓名全角カタカナ" [class.form-Error]="(v_payeeNameKana.errors && (v_payeeNameKana.dirty || v_payeeNameKana.touched))"
                            name="payeeNameKana" [(ngModel)]="inputConvenience.payeeNameKana" #v_payeeNameKana="ngModel" required max="14" pattern="{{inputConvenience.rFullWidthKatakanaPattern}}">
                            <div *ngIf="(v_payeeNameKana.errors && (v_payeeNameKana.dirty || v_payeeNameKana.touched))">
                                <label *ngIf="v_payeeNameKana.errors != null && v_payeeNameKana.errors.required" class="form-Error">
                                必須項目です
                                </label>
                                <label *ngIf="v_payeeNameKana.errors != null && v_payeeNameKana.errors.max" class="form-Error">
                                最大文字数：14
                                </label>
                                <label *ngIf="v_payeeNameKana.errors != null && v_payeeNameKana.errors.pattern" class="form-Error">
                                入力形式を確認してください
                                </label>
                            </div>
                        </ng-container>
                    </li>
                    <li>
                        <input type="radio" id="convenienceCode31" name="convenienceCode" class="gtmclick" data-gtmvalue="d_selected_la" value="{{ConvenienceType.Lawson}}" [checked]="inputConvenience.selectedConvenienceType === ConvenienceType.Lawson" (change)="onChangePaymentMethodConvenienceRadio(ConvenienceType.Lawson)">
                        <label class="form-Radio is-Middle" for="convenienceCode31" tabindex="0"></label>
                        <label for="convenienceCode31">ローソン</label>
                    </li>
                    <li>
                        <input type="radio" id="convenienceCode32" name="convenienceCode" class="gtmclick" data-gtmvalue="d_selected_sm" value="{{ConvenienceType.SeicoMart}}" [checked]="inputConvenience.selectedConvenienceType === ConvenienceType.SeicoMart" (change)="onChangePaymentMethodConvenienceRadio(ConvenienceType.SeicoMart)">
                        <label class="form-Radio is-Middle" for="convenienceCode32" tabindex="0"></label>
                        <label for="convenienceCode32">セイコーマート</label>
                    </li>
                </ul>
                <ul class="list-Normal text-Small mb0 mt24">
                    <li>
                        店頭での入金確認後にドメイン更新が完了します。
                    </li>
                    <li>
                        ご請求内容詳細につきましてはご請求案内メールにてお知らせいたします。紙面による請求書発行はございません。
                    </li>
                    <li>
                        コンビニエンスストアお支払い手数料として、1回のご請求につき165円（税込）を別途申し受けます。
                    </li>
                </ul>
            </div>
        </li>
        <!-- 銀行振込 -->
        <li *ngIf="isDisplayBank" class="list-Selection-Item">
            <input type="radio" name="paymentTypeDummy" class="gtmclick" data-gtmvalue="d_selected_bank" id="paymentTypeDummy4" value="{{PaymentType.Bank}}" [disabled]="isDisableBank" [checked]="selectedPaymentType === PaymentType.Bank" (change)="onChangePaymentMethodRadio(PaymentType.Bank)">
            <label class="form-Radio" for="paymentTypeDummy4" tabindex="0"></label>
            <label class="list-Selection-Item-Label" for="paymentTypeDummy4">銀行振込 </label>
            <div class="list-Selection-Item-Content" *ngIf="selectedPaymentType === PaymentType.Bank">
                <ul class="list-Normal">
                    <li>
                        ご案内口座での入金確認後にドメイン更新が完了します。
                    </li>
                    <li>
                        請求書発行をご希望のお客様は銀行振込をご選択ください。
                    </li>
                    <li>
                        銀行振込手数料はお客様のご負担でお願いします。
                    </li>
                </ul>
            </div>
        </li>
    </ul>
    <p class="text-Right mt16" *ngIf="isDisplayOtherPaymentButton">
        <button type="button" class="btn is-Xsmall js-PaymentMethod" (click)="onOtherPaymentButtonClicked()"><i class="icon icon-Plus"></i>その他のお支払い方法はこちら</button>
    </p>
    </form>
</ng-container>
