import { NgModule } from '@angular/core';
import { AbsoluteDropdownDirective } from 'Content/script/directives/absolute-dropdown.directive';
import { AutofocusDirective } from 'Content/script/directives/autofocus.directive';
import { AutoScrollDirective } from 'Content/script/directives/autoscroll.directive';
import { AutoScrollWwwDirective } from 'Content/script/directives/autoscroll-www.directive';
import { OnamaeRouterLinkDirective } from 'Content/script/directives/custom-router-link.directive';
import { DropDownDirective } from 'Content/script/directives/dropdown.directive';
import { DropdownFocusoutDirective } from 'Content/script/directives/dropdown_focusout.directive';
import { DropdownFocusoutBDirective } from 'Content/script/directives/dropdown_focusout_b.directive';
import { DropDownHoverDirective } from 'Content/script/directives/dropdown_hover.directive';
import { DropDownHoverBDirective } from 'Content/script/directives/dropdown_hover_b.directive';
import { ModalDirective } from 'Content/script/directives/modal.directive';
import { NavigationBtnLeft } from 'Content/script/directives/navigation_btn_left.directive';
import { NavigationBtnRight } from 'Content/script/directives/navigation_btn_right.directive';
import { NetDeShindanDomainDetailTourDirective } from 'Content/script/directives/net-de-shindan-domain-detail-tour.directive';
import { NetDeShindanDomainDetailTourWwwDirective } from 'Content/script/directives/net-de-shindan-domain-detail-tour-www.directive';
import { NetDeShindanTourDirective } from 'Content/script/directives/net-de-shindan-tour.directive';
import { NetDeShindanDomainSelectTourDirective } from 'Content/script/directives/net-de-shindan-domain-select-tour.directive';
import { NetDeShindanSelectTourDirective } from 'Content/script/directives/net-de-shindan-select-tour.directive';
import { NetDeShindanDoneTourDirective } from 'Content/script/directives/net-de-shindan-done-tour.directive';
import { PikadayDirective } from 'Content/script/directives/pikaday.directive';
import { PopupDirective } from 'Content/script/directives/popup.directive';
import { TblWrapRefresh } from 'Content/script/directives/tbl_wrap_refresh.directive';
import { TblWrapRowHover } from 'Content/script/directives/tbl_wrap_row_hover.directive';
import { TblWrapScrollHorizontal } from 'Content/script/directives/tbl_wrap_scroll_horizontal.directive';
import { TblWrapScrollVertical } from 'Content/script/directives/tbl_wrap_scroll_vertical.directive';
import { ToolTipDirective } from 'Content/script/directives/tooltip.directive';
import { ShowHideToggleDirective } from 'Content/script/directives/show_hide_toggle.directive';
import { NumbersOnlyDirective } from 'Content/script/directives/numbers_only.directive';
import { FloatingTooltipDirective } from 'Content/script/directives/floating-tooltip.directive';

@NgModule({
    declarations: [
        AbsoluteDropdownDirective,
        AutofocusDirective,
        AutoScrollDirective,
        AutoScrollWwwDirective,
        DropDownDirective,
        PikadayDirective,
        ModalDirective,
        PopupDirective,
        ToolTipDirective,
        OnamaeRouterLinkDirective,
        DropDownHoverDirective,
        DropDownHoverBDirective,
        DropdownFocusoutDirective,
        DropdownFocusoutBDirective,
        TblWrapRowHover,
        NavigationBtnLeft,
        NavigationBtnRight,
        NetDeShindanDomainDetailTourDirective,
        NetDeShindanDomainDetailTourWwwDirective,
        NetDeShindanTourDirective,
        NetDeShindanDomainSelectTourDirective,
        NetDeShindanSelectTourDirective,
        NetDeShindanDoneTourDirective,
        TblWrapScrollHorizontal,
        TblWrapScrollVertical,
        TblWrapRefresh,
        ShowHideToggleDirective,
        NumbersOnlyDirective,
        FloatingTooltipDirective
    ],
    exports: [
        AbsoluteDropdownDirective,
        AutofocusDirective,
        AutoScrollDirective,
        AutoScrollWwwDirective,
        DropDownDirective,
        PikadayDirective,
        ModalDirective,
        PopupDirective,
        ToolTipDirective,
        OnamaeRouterLinkDirective,
        DropDownHoverDirective,
        DropDownHoverBDirective,
        DropdownFocusoutDirective,
        DropdownFocusoutBDirective,
        TblWrapRowHover,
        NavigationBtnLeft,
        NavigationBtnRight,
        NetDeShindanDomainDetailTourDirective,
        NetDeShindanDomainDetailTourWwwDirective,
        NetDeShindanTourDirective,
        NetDeShindanDomainSelectTourDirective,
        NetDeShindanSelectTourDirective,
        NetDeShindanDoneTourDirective,
        TblWrapScrollHorizontal,
        TblWrapScrollVertical,
        TblWrapRefresh,
        ShowHideToggleDirective,
        NumbersOnlyDirective,
        FloatingTooltipDirective
    ]
})

export class UiModule { }
