<div class="modal-Dialog is-Wide" *ngIf="isShowModalSurvey">
  <form #modalSurveyForm="ngForm" novalidate>
    <div class="modal-Dialog-Header is-Note">
      <h3 class="modal-Dialog-Header-Title pl0 text-Center">whois代行解除理由について教えてください。</h3>
    </div><!-- /.modal-Dialog-Header -->
    <div class="modal-Dialog-Body">
      <ng-container *ngIf="!isLoading && pages.length > 0">
        <p class="size-15 text-Bold mt24 mb0">01. {{pages[0].content}}<span class="form-Required">必須</span></p>
        <section class="section mt12">
          <div class="box-Border is-Thick">
            <ul class="list-Checkable is-Col4 is-Inline">
              <li *ngFor="let option of pages[0].options">
                <label>
                  <input type="radio" name="radio1" id="radio1" value="{{option.id}}" [(ngModel)]="option.isCheck" (change)="onChangeRadioQuestion1(pages[0],option.id)">
                  <span class="form-Radio" tabindex="0"></span>{{option.content}}
                </label>
                <ng-container *ngIf="option.isOther">
                  <input placeholder="移管先" type="text" class="form-Text is-Small mt8 ml30 is-Show" [class.form-Error]="isValidateOther1 && (v_otherText1.errors && (v_otherText1.dirty || v_otherText1.touched))"
                         name="otherText1" #v_otherText1="ngModel" [(ngModel)]="pages[0].otherText"
                         [required]="isValidateOther1" max="100">
                  <div *ngIf="isValidateOther1 && (v_otherText1.errors && (v_otherText1.dirty || v_otherText1.touched))">
                    <label *ngIf="v_otherText1.errors != null && v_otherText1.errors.required" class="form-Error ml30">
                      必須項目です
                    </label>
                    <label *ngIf="v_otherText1.errors != null && v_otherText1.errors.max" class="form-Error ml30">
                      最大文字数：100
                    </label>
                  </div>
                </ng-container>
              </li>
            </ul>
          </div><!-- /.box-Border -->
        </section>
      </ng-container>
      <ng-container *ngIf="!isLoading && pages.length > 1">
        <p class="size-15 text-Bold mt24 mb0" style="text-indent: -2.5em; padding-left: 2.5em;">02. 「01」で「移管申請のため」とお答えいただいた方へ。<br>現在検討中の移管先について教えてください。</p>
        <section class="section mt12">
          <div class="box-Border is-Thick">
            <ul class="list-Checkable is-Col4 is-Inline">
              <li *ngFor="let option of pages[1].options">
                <label>
                  <input type="radio" name="radio2" id="radio2" value="{{option.id}}" [(ngModel)]="option.isCheck" (change)="onChangeRadioQuestion2(pages[1],option.id)">
                  <span class="form-Radio" tabindex="0"></span>{{option.content}}
                </label>
                <ng-container *ngIf="option.isOther">
                  <span>
                    <input placeholder="移管先" type="text" class="form-Text is-Small mt8 ml30 is-Show" [class.form-Error]="isValidateOther2 && (v_otherText.errors && (v_otherText.dirty || v_otherText.touched))"
                           name="otherText" #v_otherText="ngModel" [(ngModel)]="pages[1].otherText"
                           [required]="isValidateOther2" max="100">
                  </span>
                  <div *ngIf="isValidateOther2 && (v_otherText.errors && (v_otherText.dirty || v_otherText.touched))">
                    <label *ngIf="v_otherText.errors != null && v_otherText.errors.required" class="form-Error ml30">
                      必須項目です
                    </label>
                    <label *ngIf="v_otherText.errors != null && v_otherText.errors.max" class="form-Error ml30">
                      最大文字数：100
                    </label>
                  </div>
                </ng-container>
              </li>
            </ul>
          </div><!-- /.box-Border -->
        </section><!-- /.section -->
      </ng-container>
    </div><!-- /.modal-Dialog-Body -->
    <div class="modal-Dialog-Footer">
      <ul class="list-Btn is-Right mb0">
        <li><button type="button" class="btn" data-dismiss="modal" (click)="onClickClose()">キャンセル</button></li>
        <li><button type="button" class="btn is-Primary" data-dismiss="modal" data-change="changed" [class.is-Disabled]="!isAnswered" (click)="onClickFinishSurvey(modalSurveyForm)">次へ</button></li>
      </ul><!-- /.list-Btn -->
    </div><!-- /.modal-Dialog-Footer -->
    <button type="button" class="modal-Close" data-dismiss="modal" (click)="onClickClose()"><i class="icon icon-Clear" aria-label="閉じる"></i></button>
  </form>
</div><!-- /.modal-Dialog -->

