import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SpNotifyComponent } from 'Content/sp/script/components/layout/notify/notify.component';

@NgModule({
    imports: [CommonModule],
    declarations: [SpNotifyComponent],
    exports: [SpNotifyComponent]
})
export class SpNotifyModule { }
