/// <reference path='../../../definitions/window.d.ts' />

import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { ServiceContainer } from 'Content/script/service/service_container';
import { DropDownItemsDefine } from 'Content/script/libs/define/drop_down_items.define';
import { StringUtil } from 'Content/script/libs/utils/string.util';

/**
 * コンタクト情報コピーモーダル
 */
@Component({
    selector: 'contact-copy-modal',
    templateUrl: './contact_copy_modal.html'
})

export class ContactCopyModalComponent {
    public viewModel: ContactCopyModalViewModel;

    public constructor(private router: Router, private serviceContainer: ServiceContainer) {
        const queueId = serviceContainer.HubsService.multipleSendManager.GetComponentId();
        this.viewModel = new ContactCopyModalViewModel(this.router, queueId, this.serviceContainer);
    }
}

export class ContactCopyModalViewModel {
    private _selectedCallBack: (contactInfo: ContactInfo) => void;
    private _allLoadingCallBack: (isAllLoading: boolean) => void;

    public isShowModal: boolean;

    public contactRecords: ContactRecord[];
    public isShowContactView: boolean;
    protected contactView: ContactInfo;

    public constructor(private router: Router, private queueId: number, private serviceContainer: ServiceContainer) {
        this._selectedCallBack = null;
        this._allLoadingCallBack = null;

        this.isShowModal = false;

        this.contactRecords = [];
        this.isShowContactView = false;
        this.contactView = new ContactInfo();
    }

    /**
     * コールバック関数を設定
     * @param selectedCallBack コンタクト情報選択時
     * @param allLoadingCallBack ローディング時
     */
    public setCallBack(selectedCallBack: (contactInfo: ContactInfo) => void, allLoadingCallBack: (isAllLoading: boolean) => void): void {
        this._selectedCallBack = selectedCallBack;
        this._allLoadingCallBack = allLoadingCallBack;
    }

    /**
     * モーダルの表示時の初期化処理
     */
    public showInitModal(): void {
        this._getContacts();
    }

    /**
     * コンタクト情報選択処理コールバック
     * @param contactInfo
     */
    private _selected(contactInfo: ContactInfo): void {
        if (this._selectedCallBack) {
            this._selectedCallBack(contactInfo);
        }
    }

    /**
     * 全体ローディング処理コールバック
     * @param isAllLoading
     */
    private _allLoading(isAllLoading: boolean): void {
        if (this._allLoadingCallBack) {
            this._allLoadingCallBack(isAllLoading);
        }
    }

    /**
     * コンタクト種別を取得
     * @param type
     */
    private _convertContactTypeToText(type: ContactType): string {
        switch (type) {
            case ContactType.Reg:
                return '登録者情報';
            case ContactType.Adm:
                return '管理担当者情報';
            case ContactType.Bil:
                return '経理担当者情報';
            case ContactType.Tec:
                return '技術担当者情報';
            case ContactType.Ago:
                return '公開連絡窓口情報';
            default:
                return '';
        }
    }

    /**
     * コンタクト情報一覧を取得
     */
    private _getContacts(): void {
        this._allLoading(true);

        this.serviceContainer.HubsService.multipleSendManager.Send(
            this.queueId,
            this.serviceContainer.HubsService.hubsManager.accountHubManager.ContactInfoList,
            null,
            (resHubDto: ContactInfoListResponseHubDto) => {
                this.contactRecords = [];
                for (let contactInfoGroup of resHubDto.ContactInfoGroupList) {
                    let contactRecord = new ContactRecord(contactInfoGroup.Name);

                    for (let type of contactInfoGroup.TypeList) {
                        contactRecord.contacts.find(x => {
                            if (this._convertStringToContactType(type) === x.contactType) {
                                return x.hasData = true;
                            }
                        });
                    }

                    this.contactRecords.push(contactRecord);
                }

                this.isShowModal = true;
                this._allLoading(false);
            },
            (resHubDto: ContactInfoListResponseHubDto) => {
                this.contactRecords = [];
                this.isShowModal = true;
                this._allLoading(false);
            },
            (resHubDto: ContactInfoListResponseHubDto) => {
                this.contactRecords = [];
                this.isShowModal = true;
                this._allLoading(false);
            },
            null,
            (resHubDto: ContactInfoListResponseHubDto) => {
                this.contactRecords = [];
                this.isShowModal = true;
                this._allLoading(false);
            }
        );
    }

    /**
     * コンタクト種類のコンバート (string -> ContactType)
     * @param contactType
     */
    private _convertStringToContactType(contactType: string): ContactType {
        switch (contactType) {
            case 'reg':
                return ContactType.Reg;
            case 'admin':
                return ContactType.Adm;
            case 'bil':
                return ContactType.Bil;
            case 'tech':
                return ContactType.Tec;
            case 'ago':
                return ContactType.Ago;
            default:
                return ContactType.Non;
        }
    }

    /**
     * コンタクト情報を選択
     * @param groupName
     * @param type
     */
    private _getContactSelected(groupName: string, type: ContactType): void {
        this._allLoading(true);

        const reqHubDto: ContactInfoDetailsRequestHubDto = {
            Name: groupName,
            TypeList: [this._convertContactTypeToString(type)]
        };

        this.serviceContainer.HubsService.multipleSendManager.Send(
            this.queueId,
            this.serviceContainer.HubsService.hubsManager.accountHubManager.ContactInfoDetails,
            reqHubDto,
            (resHubDto: ContactInfoDetailsResponseHubDto) => {
                const data: ContactInfoDetailResponseHubDto = resHubDto.ContactDetailList[0];
                let contact = new ContactInfo();
                contact.type = this._convertStringToContactType(data.Type);
                contact.role = data.Role;
                contact.lnameML = data.LnameML;
                contact.lname = data.Lname;
                contact.fnameML = data.FnameML;
                contact.fname = data.Fname;
                contact.organizationML = data.OrganizationML;
                contact.organization = data.Organization;
                contact.cc = data.Cc;
                contact.pcJp1 = data.PcJp1;
                contact.pcJp2 = data.PcJp2;
                contact.pc = data.Pc;
                contact.spJp = data.SpJp;
                contact.spML = data.SpML;
                contact.sp = data.Sp;
                contact.cityML = data.CityML;
                contact.city = data.City;
                contact.street1TownML = data.Street1TownML;
                contact.street1Town = data.Street1Town;
                contact.street1NumberML = data.Street1NumberML;
                contact.street1Number = data.Street1Number;
                contact.street2ML = data.Street2ML;
                contact.street2 = StringUtil.IfNull(data.Street2, '');
                contact.phone = data.Phone;
                contact.fax = StringUtil.IfNull(data.Fax, '');
                contact.email = data.Email;
                contact.typeText = this._convertContactTypeToText(contact.type);

                const roleItem: { value: string, text: string } = DropDownItemsDefine.roleItemList.find(item => {
                    return item.value === contact.role;
                });
                contact.roleText = roleItem !== void (0) ? roleItem.text : '';

                const ccItem: { value: string, text: string } = DropDownItemsDefine.ccItemList.find(item => {
                    return item.value === contact.cc;
                });
                contact.ccText = ccItem !== void (0) ? ccItem.text : '';

                const spJpItem: { value: string, text: string } = DropDownItemsDefine.spJpItemList.find(item => {
                    return item.value === contact.spJp;
                });
                contact.spJpText = spJpItem !== void (0) ? spJpItem.text : '';

                this.isShowModal = false;
                this._allLoading(false);

                this._selected(contact);
            },
            (resHubDto: ContactInfoDetailsResponseHubDto) => {
                this.isShowModal = false;
                this._allLoading(false);
            },
            (resHubDto: ContactInfoDetailsResponseHubDto) => {
                this.isShowModal = false;
                this._allLoading(false);
            },
            null,
            (resHubDto: ContactInfoDetailsResponseHubDto) => {
                this.isShowModal = false;
                this._allLoading(false);
            }
        );
    }

    /**
     * コンタクト情報を表示
     * @param groupName
     * @param type
     */
    private _getContactDisplay(groupName: string, type: ContactType): void {
        this._allLoading(true);

        const reqHubDto: ContactInfoDetailsRequestHubDto = {
            Name: groupName,
            TypeList: [this._convertContactTypeToString(type)]
        };

        this.serviceContainer.HubsService.multipleSendManager.Send(
            this.queueId,
            this.serviceContainer.HubsService.hubsManager.accountHubManager.ContactInfoDetails,
            reqHubDto,
            (resHubDto: ContactInfoDetailsResponseHubDto) => {
                const data: ContactInfoDetailResponseHubDto = resHubDto.ContactDetailList[0];
                this.contactView.type = this._convertStringToContactType(data.Type);
                this.contactView.role = data.Role;
                this.contactView.lnameML = data.LnameML;
                this.contactView.lname = data.Lname;
                this.contactView.fnameML = data.FnameML;
                this.contactView.fname = data.Fname;
                this.contactView.organizationML = data.OrganizationML;
                this.contactView.organization = data.Organization;
                this.contactView.cc = data.Cc;
                this.contactView.pcJp1 = data.PcJp1;
                this.contactView.pcJp2 = data.PcJp2;
                this.contactView.pc = data.Pc;
                this.contactView.spJp = data.SpJp;
                this.contactView.spML = data.SpML;
                this.contactView.sp = data.Sp;
                this.contactView.cityML = data.CityML;
                this.contactView.city = data.City;
                this.contactView.street1TownML = data.Street1TownML;
                this.contactView.street1Town = data.Street1Town;
                this.contactView.street1NumberML = data.Street1NumberML;
                this.contactView.street1Number = data.Street1Number;
                this.contactView.street2ML = data.Street2ML;
                this.contactView.street2 = data.Street2;
                this.contactView.phone = data.Phone;
                this.contactView.fax = data.Fax;
                this.contactView.email = data.Email;
                this.contactView.typeText = this._convertContactTypeToText(this.contactView.type);

                const roleItem: { value: string, text: string } = DropDownItemsDefine.roleItemList.find(item => {
                    return item.value === this.contactView.role;
                });
                this.contactView.roleText = roleItem !== void (0) ? roleItem.text : '';

                const ccItem: { value: string, text: string } = DropDownItemsDefine.ccItemList.find(item => {
                    return item.value === this.contactView.cc;
                });
                this.contactView.ccText = ccItem !== void (0) ? ccItem.text : '';

                const spJpItem: { value: string, text: string } = DropDownItemsDefine.spJpItemList.find(item => {
                    return item.value === this.contactView.spJp;
                });
                this.contactView.spJpText = spJpItem !== void (0) ? spJpItem.text : '';

                this.isShowContactView = true;
                this._allLoading(false);
            },
            (resHubDto: ContactInfoDetailsResponseHubDto) => {
                this.isShowContactView = false;
                this._allLoading(false);
            },
            (resHubDto: ContactInfoDetailsResponseHubDto) => {
                this.isShowContactView = false;
                this._allLoading(false);
            },
            null,
            (resHubDto: ContactInfoDetailsResponseHubDto) => {
                this.isShowContactView = false;
                this._allLoading(false);
            }
        );
    }

    /**
     * コンタクト種類のコンバート (ContactType -> string)
     * @param contactType
     */
    private _convertContactTypeToString(contactType: ContactType): string {
        switch (contactType) {
            case ContactType.Reg:
                return 'reg';
            case ContactType.Adm:
                return 'admin';
            case ContactType.Bil:
                return 'bil';
            case ContactType.Tec:
                return 'tech';
            case ContactType.Ago:
                return 'ago';
            default:
                return '';
        }
    }

    /**
     * コンタクト情報選択
     * @param groupName
     * @param type
     */
    public onClickDetailSelected(groupName: string, type: ContactType): void {
        this._getContactSelected(groupName, type)
    }

    /**
     * コンタクト情報表示
     * @param groupName
     * @param type
     */
    public onClickDetailDisplay(groupName: string, type: ContactType): void {
        this._getContactDisplay(groupName, type)
    }

    /**
     * キャンセル
     */
    public onClickCancel(): void {
        this.isShowModal = false;
    }
}

class ContactRecord {
    public constructor(
        public contactName: string = '',
        public contacts: ContactGroup[] = []
    ) {
        for (let i: ContactType = ContactType.Reg; i <= ContactType.Ago; i++) {
            let contactGroup = new ContactGroup(i);
            this.contacts.push(contactGroup);
        }
    }
}

class ContactGroup {
    public constructor(
        public contactType: ContactType,
        public hasData: boolean = false
    ) {
    }
}

export class ContactInfo {
    public constructor(
        public type: ContactType = ContactType.Non,
        public role: string = 'I',
        public lnameML: string = '',
        public lname: string = '',
        public fnameML: string = '',
        public fname: string = '',
        public organizationML: string = '',
        public organization: string = '',
        public cc: string = 'JP',
        public pcJp1: string = '',
        public pcJp2: string = '',
        public pc: string = '',
        public spJp: string = '01',
        public spML: string = '',
        public sp: string = '',
        public cityML: string = '',
        public city: string = '',
        public street1TownML: string = '',
        public street1Town: string = '',
        public street1NumberML: string = '',
        public street1Number: string = '',
        public street2ML: string = '',
        public street2: string = '',
        public phone: string = '',
        public fax: string = '',
        public email: string = '',
        public typeText: string = '',
        public roleText: string = '',
        public ccText: string = '',
        public spJpText: string = ''
    ) {
    }
}

export enum ContactType {
    Non = 0,
    Reg,
    Adm,
    Bil,
    Tec,
    Ago
}
