import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ServiceContainer } from "Content/script/service/service_container";
import { docCookies, FutureDate } from '../../../../libs/third_party/cookies';

@Component({
    selector: 'header-notification-appeal-banner',
    templateUrl: './header_notification_appeal_banner.html'
})

export class HeaderNotificationAppealBannerComponent implements OnChanges {
    @Input("routerPath") public routerPath: string;

    public isShowBannerHeader: boolean;
    private readonly cookieKey: string = "IsShowHeaderNotificationAppealBanner";
    public readonly NOT_DISPLAY_PATH: string[] = ["/","/login","/domain/setting/renew/list","/logout"];
    public linkForBanner: string = '';
    public constructor(
        public serviceContainer: ServiceContainer) {
        this.isShowBannerHeader = false;
    }
    public 
    public ngOnChanges(changes: SimpleChanges) {
        // routerPathの変更を検知して表示対象PathならviewModelのデータ取得メソッドを呼び出し
        let routerChange = changes["routerPath"];
        if (routerChange) {
            this.isShowBannerHeader = false;
            if (routerChange.currentValue) {
                let _isDisplayBannerFromCookie: string = this.isDisplayBannerFromCookie();
                this.isShowBannerHeader = this.NOT_DISPLAY_PATH.indexOf(routerChange.currentValue) == -1 && _isDisplayBannerFromCookie != "0";
            }
            if (routerChange.currentValue === "/domain/setting") {
                this.linkForBanner = "https://www.onamae.com/campaign/transferincp/?btn_id=Navitopheaderexsist1_transfercp";
            }else if (routerChange.currentValue === "/top") {
                this.linkForBanner = "https://www.onamae.com/campaign/transferincp/?btn_id=Navitopheaderexsist2_transfercp";
            } else {
                this.linkForBanner = "https://www.onamae.com/campaign/transferincp/?btn_id=Navitopheaderexsist_transfercp";
            }
        }
    }
    public isDisplayBannerFromCookie(): string {
        const data: string = JSON.parse(docCookies.getItem(this.cookieKey));
        return data;
    }

    public CacheBusting(uri: string): string {
        return window.CacheBusting(uri);
    }
    public onClickCloseBanner(): void {
        this.isShowBannerHeader = false;
        var expires = new Date();
        expires.setMonth(expires.getMonth() + 2);
        docCookies.setItem(this.cookieKey, "0", expires)
    }

}
