type TypeViews = {
    PC: string,
    SP: string
};

export const VIEWS: TypeViews = {
    PC: 'pc',
    SP: 'sp'
}

export interface ViewState {
    current: string,
    mode: string,
    keep: boolean,
    loading: boolean
}
