/// <reference path='../../definitions/window.d.ts' />

import { Component, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BaseAppComponent, BaseAppViewModel } from 'Content/common/script/components/app/base.component';
import { RouterEventService } from 'Content/common/script/service/router_event_service';
import { AnalyticsUtil } from 'Content/script/libs/adobeanalytics/analytics.util';
import { ValidationRegexsDefine } from 'Content/script/libs/define/validation_regexs.define';
import { FutureDate, docCookies } from 'Content/script/libs/third_party/cookies';
import { pcRoutes } from 'Content/script/routing/pc-routing.module';
import { DomainCartViewModel } from 'Content/script/service/domain_cart_service';
import { NotifyMessage, NotifyType } from 'Content/script/service/notify_service';
import { ServiceContainer } from 'Content/script/service/service_container';
import { IPageTracking } from 'Content/script/service/tracking_service';
import { setViewLoading } from '../../store/actions/view';
import { AccountInfoState } from '../../store/states/account-info';
import { ViewState } from '../../store/states/view';
import { Banner, CampaignBannerLocalStorageController } from '../campaign/present/common/campaign_banner.util';
import { CAMPAIGN_HIDDEN_LIST } from '../campaign/present/common/campaign_present.define';

@Component({
    selector: 'app-pc-root',
    templateUrl: './app.html',
    styles: [
        // ドメイン更新画面のスタイル
        `.floating_banner { position: fixed; right: 36px; bottom: 20px; width: 290px; max-height: 202px; border: #000000 solid 1px; border-radius: 6px; background-color: #ffffffff; }`,
        `.floating_banner .title { text-align: center }`,
        `.floating_banner .caution { width: 24px; height: 24px; vertical-align: middle; margin-right: 5px; }`,
        `.floating_banner .domain_list { width: 258px; max-height: 110px; overflow-x: auto; margin: 10px auto 10px auto; background-color: #f8f8f8; }`,
        `.center_buttom { margin: 10px auto 10px auto; text-align: center; }`,
        `.floating_banner .close { float: left; font-size: 20px; position: relative; width: 32px; height: 32px; border: 2px solid #000000; border-radius: 100%; background-color: #FFFFFF; left: 272px; top: -15px; }`,
        `.close::before { position: absolute; top: 0.2em; left: 0.6em; width: 0.2em; height: 1em; content: ""; background-color: #000000; transform: rotate(45deg); }`,
        `.close::after { position: absolute; top: 0.6em; left: 0.2em; width: 1em; height: 0.2em; content: ""; background-color: #000000; transform: rotate(225deg); }`
    ]
})
export class AppPcComponent extends BaseAppComponent implements OnInit, OnDestroy {
    @Input() public viewMode: string;

    public isAliveSession: boolean;

    public viewModel: PcAppViewModel;

    public domainCartViewModel: DomainCartViewModel;

    public isDisplayRsRecommend: boolean = false;

    public isAllLoading: boolean = false;

    private trackingService: IPageTracking = null;

    public isShowInstructRibon: boolean = false;
    public isABNew_RsRecommendWithNameServer: boolean = false;

    //0：初期値
    //1：会員情報変更モーダル非表示
    //2：会員情報変更モーダル表示
    public topChangeModalStatus: string = '0';

    private readonly MFAMODAL_SHOW_LIST: string[] = [
        "/login",
        "/account/loginsecurity/mfa/check",
        "/account/loginsecurity/mfa/edit",
        "/account/password",
        "/account/detail/confirm"
    ];

    private readonly VOACIMODAL_SHOW_LIST: string[] = [
        "/account/detail/edit",
        "/provisionalaccount/detail/edit"
    ];

    public get isTopScreen(): boolean {
        return location.pathname === '/top';
    }

    public get isTopShowTourMark(): boolean {
        return this.isShowInstructRibon && location.pathname === '/top' && !docCookies.hasItem('RsloggedIn') && docCookies.getItem("transferType") !== "STAFF_LOGIN";
    }

    public get isShowMfaModal(): boolean {
        return (this.MFAMODAL_SHOW_LIST.indexOf(location.pathname) !== -1);
    }

    public get isShowVoaciModal(): boolean {
        return this.VOACIMODAL_SHOW_LIST.some(url => location.pathname.startsWith(url));
    }

    public get serviceContena(): ServiceContainer {
        return this.serviceContainer;
    }

    protected _referrerPath: string;
    public get referrerPath(): string {
        return this._referrerPath;
    }

    protected _routerPath: string;
    public get routerPath(): string {
        return this._routerPath;
    }

    protected _componentRef: any;
    public get componentRef(): any {
        return this._componentRef;
    }

    /**
     * 会員情報変更モーダルが表示されている時、
     * RS訴求モーダルは非表示にする
     * @param isDisplayModal
     */
    public onChangeMemberInfoModalDisplayState(isDisplayModal: boolean) {
        this.isDisplayRsRecommend = !isDisplayModal;
    }

    public showInstructRibon() {
        this.isShowInstructRibon = true;
    }

    public setTopChangeModal(topChangeStatus: string) {
        this.topChangeModalStatus = topChangeStatus;
    }

    /**
     * 子コンポーネントからのローディング表示制御用
     */
    public doLoading() {
        this.isAllLoading = true;
    }

    public constructor(
        private router: Router,
        private serviceContainer: ServiceContainer,
        private routerEventService: RouterEventService,
        private ngZone: NgZone,
        private store: Store<ViewState>,
        private storeAccountInfo: Store<AccountInfoState>
    ) {
        super(router, serviceContainer, routerEventService, ngZone, storeAccountInfo);
        
        this.viewModel = new PcAppViewModel(
            serviceContainer.HubsService.multipleSendManager.GetComponentId(),
            serviceContainer,
            router
        );

        /**
         * ログイン画面以外の画面でリロードされた際の対応で必要となるのでpathnameでLayoutを決めている
         * IE9はハッシュステートなのでhashでLayoutを決めている
         */
        var urlSplit = location.pathname.split('/');
        if (window.browserType == 'IE9') {
            urlSplit = location.hash.split('/');
        }

        var layoutType = urlSplit.length > 1 && urlSplit[1] == '' ? 'login' : urlSplit[1];

        serviceContainer.LayoutService.DisplayGlobalNavi = (isShow: boolean) => {
            this.viewModel.HasGlobalNavi = isShow;
        }

        serviceContainer.LayoutService.DisplayFooter = (isShow: boolean) => {
            this.viewModel.HasFooter = isShow;
        }

        serviceContainer.LayoutService.DisplayFullWidth = (isFullWidth: boolean) => {
            this.viewModel.isFullWidth = isFullWidth;
        }

        serviceContainer.LayoutService.ChangeLayout = (layoutType: string) => {
            var findIndex = pcRoutes.findIndex((routing) => {
                if (layoutType == null) {
                    return false;
                }
                var searchUrl = layoutType;
                for (var index: number = 0; index < layoutType.length; index++) {
                    if (layoutType[index] == '?') {
                        searchUrl = layoutType.slice(0, index);
                    }
                }

                if (!isShowHeaderFooterFn(searchUrl)) {
                    return false;
                }

                if (location.pathname.startsWith("/domain/setting/protect/approval")){
                    return false;
                }
                if (location.pathname.startsWith("/domain/selldomain/create/seekbulk")) {
                    return false;
                }
                if (location.pathname.startsWith("/account/unsubscribe")) {
                    return false;
                }
                return routing.path == searchUrl;
            });

            this.viewModel.HasHeader = this.viewModel.HasFooter = findIndex != -1;
            this.viewModel.IsFakeHeader = layoutType === 'link';

            // ログインしていないときにヘッダーのリンクを有効にするかどうか
            let invalidHeaderLink: boolean = [
                'password',
                'mfareset'
            ].some(x => x === layoutType);

            invalidHeaderLink = invalidHeaderLink || location.pathname.startsWith('/account/unsubscribe');

            if (invalidHeaderLink) {
                this.viewModel.HasLinkOnHeaderWhenNoSession = false;
            } else {
                this.viewModel.HasLinkOnHeaderWhenNoSession = true;
            }
        }

        /***********************************
        * 全体ローディングの制御処理を定義
        ***********************************/
        serviceContainer.ModalControlService.ShowFullScreenLoading = () => {
            this.ngZone.run(() => {
                store.dispatch(setViewLoading({
                    value: true
                }));
            });
        };

        serviceContainer.ModalControlService.HideFullScreenLoading = (isForceHide: boolean = false) => {
            this.ngZone.run(() => {
                if (window.isFirstRender == false || isForceHide) {
                    store.dispatch(setViewLoading({
                        value: false
                    }));
                    window.scrollTo(0, 0);
                }
            });
        };

        /*****************************
        * 全体マスクの制御処理を定義
        *****************************/
        serviceContainer.ModalControlService.SwitchFullScreenMask = (isDisplay: boolean) => {
            this.ngZone.run(() => {
                this.viewModel.IsMask = isDisplay;
                if (isDisplay == false) {
                    this.viewModel.Destroy();
                }
                else
                {
                    this.viewModel.progressNumber = 0;
                    this.viewModel.clearNodeJsTimes();
                    this.viewModel.rotateRight = "rect(0px, 90px, 0px, 45px)";
                    this.viewModel.rotateLeft = "rect(90px, 45.5px, 90px, 0px)";
                }
            });
        };
        serviceContainer.ModalControlService.CssAngleCalculation = this.viewModel.CssAngleCalculation;

        serviceContainer.LayoutService.ChangeLayout(layoutType);

        // Angularでエラーのログを記録している
        // GT#5147 根本解決までSendLogを停止
        //window.errorCallBack = (error: any) => {
        //    var reqObj: SendLogRequestHubDto = {
        //        LogType: 0,
        //        Url: location.href,
        //        Message: error
        //    } as SendLogRequestHubDto;
        //    serviceContainer.HubsService.multipleSendManager.Send(
        //        this.queueId,
        //        serviceContainer.HubsService.hubsManager.loggerHubManager.SendLog,
        //        reqObj,
        //        (res: SendLogResponseHubDto) => {
        //        },
        //        (res: SendLogResponseHubDto) => {
        //        },
        //        (res: SendLogResponseHubDto) => {
        //        }
        //    );
        //    console.log(error);
        //}

        //window.onerror = (msg, url, line, col, error): void => {
        //    var reqObj: SendLogRequestHubDto = {
        //        LogType: 0,
        //        Url: location.href,
        //        Message: 'url: ' + url + '; line: ' + line + '; msg: ' + msg
        //    } as SendLogRequestHubDto;

        //    serviceContainer.HubsService.multipleSendManager.Send(
        //        this.queueId,
        //        serviceContainer.HubsService.hubsManager.loggerHubManager.SendLog,
        //        reqObj,
        //        (res: SendLogResponseHubDto) => {
        //        },
        //        (res: SendLogResponseHubDto) => {
        //        },
        //        (res: SendLogResponseHubDto) => {
        //        }
        //    );
        //};

        this.domainCartViewModel = serviceContainer.DomainCartService.viewModel;       
    }

    public async ngOnInit() {
        this._referrerPath = window.ReferrerPath;
        this._routerPath = window.RouterPath;
        this._onInit();
        this.store.dispatch(setViewLoading({
            value: true
        }));
        const AB = this.serviceContainer.ABCookieService;
        this.isABNew_RsRecommendWithNameServer = await AB.AdjustRsRecommendWithNameServer.tests.AdjustRsRecommendWithNameServer.isNewAsync();
        window.isABNew_AdjustTextButton = await AB.AdjustTextButton.tests.AdjustTextButton.isNewAsync();
    }

    public ngOnDestroy() {
        this._onDestroy();
    }

    // コンポ―ネントが入れ替わるたびに呼び出される
    public onActivate(componentRef) {

        // AdobeAnalyticsページトラッキング
        this.trackingService = this.serviceContainer.TrackingService.provideServiceForMainComponent().create();
        this.trackingService.start();

        // Cookieにスタッフツールログインのフラグがなければ帯を消す
        this.viewModel.isStaffLogin = (docCookies.getItem("transferType") == "STAFF_LOGIN");

        // かご落ち対策バナー表示/非表示の切り替え
        // ドメイン更新画面とログイン画面では表示しない
        if (location.pathname.startsWith("/domain/setting/renew/list") ||
            location.pathname.startsWith("/login") ||
            location.pathname.startsWith("/campaign/present") ||
            location.pathname.startsWith("/quick") ||
            location.pathname.startsWith("/account/unsubscribe")
        ) {
            this.serviceContainer.DomainCartService.HideView();
        } else {
            this.serviceContainer.DomainCartService.ShowView();
        }
        this.domainCartViewModel = this.serviceContainer.DomainCartService.viewModel;

        this._referrerPath = window.ReferrerPath;
        this._routerPath = window.RouterPath;
        this._componentRef = componentRef;
    }

    public onDeactivate(componentRef) {
        if (this.trackingService) {
            this.trackingService.dispose();
            this.trackingService = null;
        }
    }

    protected eventNavigationStart(event) {
        // noop.
    }

    protected eventRoutesRecognized(event) {
        this.serviceContainer.ABCookieService.onRaiseRouterEvent_RoutesRecognized(event.url);
    }

    protected eventNavigationEnd(event) {
        var url = event.urlAfterRedirects == '/' ? 'login' : event.urlAfterRedirects;

        var urlSplit = url.split('/');
        if (urlSplit.length > 1 && this.serviceContainer.LayoutService.ChangeLayout) {
            this.serviceContainer.LayoutService.ChangeLayout(urlSplit[1]);
        }

        if (this.serviceContainer.HeaderComponentService.UpdateNavigationName) {
            this.serviceContainer.HeaderComponentService.UpdateNavigationName(urlSplit[1]);
        }

        // ドメイン更新画面ではアンケート・サイドメニューを出さない
        let avoidPath = /\/domain\/setting\/renew\/list.*/;
        if (avoidPath.test(url)) {
            this.viewModel.questionnaireFlg = false;
        } else {
            this.viewModel.QuestionnaireCloseFormLoad();
        }
        if (this.serviceContainer.ModalControlService.SwitchFullScreenMask) {
            this.serviceContainer.ModalControlService.SwitchFullScreenMask(false);
        }

        window.IframePath = "";
    }

    /**
     * AccountInfo取得前の処理.
     */
    protected beforeAcquiringAccountInfo(currentState: AccountInfoState, newState: AccountInfoState) {
        if (currentState.LoginName === '' && newState.LoginName !== '') {
            AnalyticsUtil.getDomainEarlyExpiration(this.serviceContainer);    
        }
    }

    /**
     * AccountInfo取得後の処理.
     * PCの場合はheader.componentで処理.
     */
    protected afterAcquiringAccountInfo(result, data, errorType) {
        this.viewModel.loginCampaignBannerInit();
    }

    public CacheBusting(uri: string): string {
        return window.CacheBusting(uri);
    }
}

class PcAppViewModel extends BaseAppViewModel {
    public questionnaireFlg: boolean;
    public get questionnaireMinute(): string {
        return this.serviceContainer.NPSEnqueteService.answerMinute;
    }

    public LayoutType: string;
    public HasHeader: boolean;
    public IsFakeHeader: boolean;
    public HasFooter: boolean;
    
    public HasLinkOnHeaderWhenNoSession: boolean;

    public HasGlobalNavi: boolean;

    public isFullWidth: boolean;

    // 全体ローディングで使用するプロパティ
    public IsLoading: boolean = true;

    // ダイアログを表示する際に最後尾のスクロールを制御出来ないようにするプロパティ
    public IsModalOpen: boolean;

    public progressNumber: number;
    public rotateRight: string = "rect(0px, 90px, 0px, 45px)";
    public rotateLeft: string = "rect(90px, 45.5px, 90px, 0px)";

    // ログインCPバナー
    private readonly LOGIN_CAMPAIGN_BANNER_LOCATION_ID: string = "6";
    private readonly LOGIN_CAMPAIGN_PC_BANNER_KEY: string = "LOGIN_CAMPAIGN_PC_BANNER_KEY";
    public loginCampaignBanner: Banner;
    public isShowLoginCampaignBanner: boolean;
    public isHeaderLoading: boolean;
    public guideWord: string;
    public readonly rGuideFormPattern: string;

    public readonly QuestionnaireKey: string = "QuestionnaireCloseForm";

    public constructor(
        private queueId: number,
        private serviceContainer: ServiceContainer,
        private router: Router
    ) {
        super(serviceContainer);
        this.productFlg = window.productFlg;
        this.IsModalOpen = false;
        this.LayoutType = '';
        this.HasHeader = true;
        this.HasFooter = true;
        this.HasLinkOnHeaderWhenNoSession = true;
        this.HasGlobalNavi = true;
        this.isFullWidth = false;

        this.IsMask = false;
        this.progressNumber = 0;

        this.loginCampaignBanner = new Banner();
        this.isShowLoginCampaignBanner = false;

        this.guideWord = '';
        this.rGuideFormPattern = ValidationRegexsDefine.rGuideFormPattern;

        this.CssAngleCalculation(0, 0);

        this.QuestionnaireCloseFormLoad();
        this.isHeaderLoading = false;
    }

    public CssAngleCalculation = (beginPercent: number, afterPercent: number): void => {
        this.progressNumber = afterPercent;

        var range: number = Math.floor((180 / 100) * afterPercent);

        if (range <= 90) {
            this.rotateRight = "rect(0px, 90px, " + range + "px, 45px)";
        }
        else if (beginPercent < 50) // 50%以下の場合は50%進捗させてから続きの進捗を進せる
        {
            this.rotateRight = "rect(0px, 90px, 90px, 45px)";
            var timer: NodeJS.Timer = setTimeout(() => {
                this.rotateLeft = "rect(" + (180 - range) + "px, 45.5px, 90px, 0px)";
            }, 200);
            this.pushNodeJsTimes(timer);
        }
        else {
            this.rotateRight = "rect(0px, 90px, 90px, 45px)";
            this.rotateLeft = "rect(" + (180 - range) + "px, 45.5px, 90px, 0px)";
        }
    }

    public QuestionnaireLinkClick()
    {
        window.open(this.serviceContainer.NPSEnqueteService.URL);
    }

    // ローディングフラグが変更されたときのイベントコールバック
    public ChangeLoadingFlagHeaderEvent(value :boolean) : void {
        this.isHeaderLoading = value;
    }

    public QuestionnaireCloseFormLoad()
    {
        if (this.serviceContainer.NPSEnqueteService.isDuration) {
            //アンケート期間中でもお客さんに✕ボタンを押されたら（＝cookieにfalseがセットされていたら）アンケートを表示しない
            this.questionnaireFlg = !docCookies.getItem(this.QuestionnaireKey);
        }
        else {
            //アンケート期間以外はアンケートを表示しない
            this.questionnaireFlg = false;
        }
    }

    public QuestionnaireCloseFormClick() {
        this.questionnaireFlg = false;

        /*
        * 2ヶ月は表示しないようにする仕様なので31日 * 2にしました。
        */
        docCookies.setItem(this.QuestionnaireKey, false, FutureDate.afterDays(62));
    }

    public onSearchGuideButtonClicked(guideForm: NgForm): void {
        if(!this.guideWord) {
            return;
        }

        if (guideForm.invalid) {
            for (var key in guideForm.controls) {
                guideForm.controls[key].markAsDirty();
            }
            return;
        }

        try {
            let url = this.isProduct ? "https://www.onamae.com/guide/search/?keyword=" : "https://www.onamae.test/guide/search/?keyword=";
            let encodeUrl = encodeURI(url + this.guideWord + "&from=onamaenavi");
            this.openUrl(encodeUrl);
        } catch (error) {
            let notify: NotifyMessage = new NotifyMessage(this.serviceContainer.NotifyService.GetNotifyId(), NotifyType.Error, '不正な検索文字です');
            this.serviceContainer.NotifyService.AddNotifyMessages([notify]);
        }
    }

    public async loginCampaignBannerInit() {
        const AB = this.serviceContainer.ABCookieService;
        let isForceGet = await AB.LoginCampaign.tests.LoginCampaign_01.isNewAsync();

        // LocalStorageにCP情報保存済みの場合はそちらを使う
        if (CampaignBannerLocalStorageController.hasActiveCampaignData(this.LOGIN_CAMPAIGN_PC_BANNER_KEY) && !isForceGet) {
            this.getCampaignBannerFromLocalStorage();
            this.isShowLoginCampaignBanner = true;
        } else {
            this.getCampaignBannerFromServer();
        }
    }

    /**
    * LocalStorageにログインCPバナー保存
    */
    private saveCampaignBanner(source: Banner): void {
        CampaignBannerLocalStorageController.save(this.LOGIN_CAMPAIGN_PC_BANNER_KEY, source);
    }

    /**
     * LocalStorageからログインCPバナー取得
     */
    private getCampaignBannerFromLocalStorage(): void {
        let source: Banner = CampaignBannerLocalStorageController.get(this.LOGIN_CAMPAIGN_PC_BANNER_KEY);
        this.loginCampaignBanner.set(source);
    }

    /**
     * ログインCPバナー
     * */
    public getCampaignBannerFromServer(): void {
        this.serviceContainer.HubsService.multipleSendManager.Send(
            this.queueId,
            this.serviceContainer.HubsService.hubsManager.campaignHubManager.GetPCLoginCampaignBanner,
            null,
            (res: GetLoginCampaignBannerResponseHubDto) => {
                let bannerInfo: LoginCampaignBanner = res.Messages.find(x => x.LocationId == this.LOGIN_CAMPAIGN_BANNER_LOCATION_ID);
                if (!!bannerInfo) {
                    this.isShowLoginCampaignBanner = true;
                    this.loginCampaignBanner.set(bannerInfo);

                    // LocalStorageにバナー情報を保存
                    this.saveCampaignBanner(this.loginCampaignBanner);
                }              
            },
            () => { },
            () => { },
            () => { },
        );
    }

    /**
     * ログインCPバナー表示制御
     * */
    public get canDisplayLoginCampaignBanner(): boolean {
        let isHiddenPath = CAMPAIGN_HIDDEN_LIST.some(x => x.startsWith(location.pathname));
        return !isHiddenPath && !!this.loginCampaignBanner.image && this.isShowLoginCampaignBanner;
    }

    /**
     * キャンペーンバナーを閉じる
     * */
    public onLoginCampaignBannerCloseClicked(): void {
        this.isShowLoginCampaignBanner = false;
    }

    public openUrl(url:string) {
        window.open(url, '_blank', 'noopener,noreferrer');
    }
}

export function isShowHeaderFooterFn(rootMenu: string): boolean {

    if (rootMenu === 'auth' ||
        rootMenu === 'login' ||
        rootMenu === 'logout' ||
        rootMenu === 'controlpanel' ||
        rootMenu === 'error' ||
        rootMenu === 'provisionalaccount' ||
        rootMenu === 'provisionalpassword' ||
        rootMenu === 'not_found' ||
        rootMenu === 'withdrawal' ||
        rootMenu === 'password' ||
        rootMenu === 'phoneauth' ||
        rootMenu === 'cvs' ||
        rootMenu === 'link' ||
        rootMenu === 'mfareset' ||
        rootMenu === 'ownership' ||
        rootMenu === 'quick' 
    ) {
        return false;
    }

    return true;
}
