/**
 * お申込み内容のタイプ
 * */
export enum ContentType {
    // ドメイン更新
    DomainRenew,
    // クーポン
    Coupon,
    // その他(Whois、メール転送etc)
    Other,
    // ドメインをRSと一緒に更新する
    DomainRenewWithRs
}
