export class SessionService
{
    private CallBackUrl: string;

    public constructor()
    {
        this.CallBackUrl = "";
    }

    public GetCallBackUrl()
    {
        return this.CallBackUrl;
    }

    public SetCallBackUrl(callBackUrl: string)
    {
        if (callBackUrl == "/") {
            return;
        }

        this.CallBackUrl = callBackUrl;
    }
}
