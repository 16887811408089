/// <reference path='../../../definitions/window.d.ts' />

import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';

import { ServiceContainer } from 'Content/script/service/service_container';
import { ValidationRegexsDefine } from 'Content/script/libs/define/validation_regexs.define';
import { DropDownSelectItem, GetBulkOrderStatusDropdown, GetBulkOrderPaymentTypeIdDropdown } from 'Content/common/script/constants/dropdown_value';

/**
 * バルク申請 検索 ドロップダウン
 */
@Component({
    selector: 'bulk-order-search-dropdown',
    templateUrl: './bulk_order_search_dropdown.html'
})

export class BulkOderSearchDropdownComponent {
    public viewModel: BulkOderSearchDropdownViewModel;
    public constructor(private router: Router, private serviceContainer: ServiceContainer) {
        const queueId = serviceContainer.HubsService.multipleSendManager.GetComponentId();
        this.viewModel = new BulkOderSearchDropdownViewModel(this.router, queueId, this.serviceContainer);
    }
}

export class BulkOderSearchDropdownViewModel {
    private _searchCallBack: () => void;

    public isDisableView: boolean;

    protected searchConditionConfig: BulkOderSearchConditionConfig;

    protected searchCondition: BulkOderSearchCondition;

    protected readonly rNumberPattern: string = ValidationRegexsDefine.rNumberPattern;

    public constructor(
        private router: Router,
        private queueId: number,
        private serviceContainer: ServiceContainer
    ) {
        this._searchCallBack = null;

        this.isDisableView = false;

        this.searchConditionConfig = new BulkOderSearchConditionConfig();

        this.searchCondition = new BulkOderSearchCondition();

        this._getBulkOderStatus();

        this._getPaymentTypes();
    }

    /**
     * 検索時のコールバック関数を設定
     * @param searchCallBack 検索を実行した際のコールバック関数
     */
    public setCallBack(searchCallBack: () => void): void {
        this._searchCallBack = searchCallBack;
    }

    /**
     * 検索を無効にするかを指定
     * @param isDisableView
     */
    public setIsDisableView(isDisableView: boolean): void {
        this.isDisableView = isDisableView;
    }

    /**
    * 検索条件の対象を設定
    * @param searchConditionConfig
    */
    public setBulkOderSearchConditionConfig(searchConditionConfig: BulkOderSearchConditionConfig): void {
        this.searchConditionConfig = searchConditionConfig;
    }

    /**
     * 検索する条件を設定
     * @param searchCondition
     */
    public setBulkOderSearchCondition(searchCondition: BulkOderSearchCondition): void {
        this.searchCondition = searchCondition;
    }

    /**
     * 検索する条件を取得
     */
    public getBulkOderSearchCondition(): BulkOderSearchCondition {
        return this.searchCondition;
    }


    /**
     * 申請状況選択
     * @param event
     */
    public onChangeSearchStatus(event: Event): void {
        const currentElement: HTMLInputElement = event.currentTarget as HTMLInputElement
        const status: string = currentElement.value;
        this.searchCondition.status = status;
    }

    /**
     * 決済種別選択
     * @param event
     */
    public onChangeSearchPaymentType(event: Event): void {
        const currentElement: HTMLInputElement = event.currentTarget as HTMLInputElement
        const paymentType: string = currentElement.value;
        this.searchCondition.paymentTypeId = paymentType;
    }

    /**
     * カレンダー(申請日)
     * @param event
     */
    protected onChangeCreatedDateFrom(event: Event): void {
        const currentElement: HTMLInputElement = event.currentTarget as HTMLInputElement
        this.searchCondition.createdDateFrom = currentElement.value;
       
    }

    /**
     * カレンダー(申請日)
     * @param event
     */
    protected onChangeCreatedDateTo(event: Event): void {
        const currentElement: HTMLInputElement = event.currentTarget as HTMLInputElement
        this.searchCondition.createdDateTo = currentElement.value;
    }

    /**
     * カレンダー(登録完了日)
     * @param event
     */
    protected onChangeExpirationDateFrom(event: Event): void {
        const currentElement: HTMLInputElement = event.currentTarget as HTMLInputElement
        this.searchCondition.completeDateFrom = currentElement.value;
    }

    /**
     * カレンダー(登録完了日)
     * @param event
     */
    protected onChangeExpirationDateTo(event: Event): void {
        const currentElement: HTMLInputElement = event.currentTarget as HTMLInputElement
        this.searchCondition.completeDateTo = currentElement.value;
    }

    /**
     * クリア
     */
    protected onClickSearchConditionClear(): void {
        this.searchCondition.clear()
    }

    /**
     * 検索
     * @param form
     */
    protected onSubmitSearchConditionSearch(form: NgForm): void {
        if (form.valid === false) {
            for (let key in form.controls) {
                form.controls[key].markAsDirty();
            }
            return;
        }
        this._search();
    }

    protected onClickSearchButton(form: NgForm): void {
        if (form.valid === false) {
            for (let key in form.controls) {
                form.controls[key].markAsDirty();
            }
            return;
        }
        this._search();
    }

    /**
     * 検索
     * @param searchCondition
     */
    private _search(): void {
        if (this._searchCallBack) {
            this._searchCallBack();
        }
    }

    /**
     * 検索用に一括注文ステータスを取得する
     */
    private _getBulkOderStatus(): void {
        this.searchCondition.statusSelectItems = GetBulkOrderStatusDropdown();
        this.searchCondition.status = this.searchCondition.statusSelectItems[0].key;
    }

    /**
     * 検索決済種別を取得する
     */
    private _getPaymentTypes(): void {
        this.searchCondition.paymentTypeIdSelectItems = GetBulkOrderPaymentTypeIdDropdown();
        this.searchCondition.paymentTypeId = this.searchCondition.paymentTypeIdSelectItems[0].key;
    }
}

/**
 * 検索条件の設定
 */
export class BulkOderSearchConditionConfig {
    public constructor(
        public isEnableBulkOrderId: boolean = true,
        public isEnableOrderType: boolean = false,
        public isEnableStatus: boolean = true,
        public isEnablePaymentTypeId: boolean = true,
        public isEnableCreatedDate: boolean = true,
        public isEnableCompleteDate: boolean = false) {
    }
}

/**
 * 検索条件
 */
export class BulkOderSearchCondition {
    public constructor(
        public bulkOrderId: string = '',
        public orderType: string = '',
        public status: string = '',
        public statusSelectItems: DropDownSelectItem[] = [],
        public paymentTypeId: string = '',
        public paymentTypeIdSelectItems: DropDownSelectItem[] = [],
        public createdDateFrom: string = '',
        public createdDateTo: string = '',
        public completeDateFrom: string = '',
        public completeDateTo: string = '') {
    }

    public clear(): void {
        this.bulkOrderId = '';
        this.orderType = '';
        this.status = '';
        this.paymentTypeId = '';
        this.createdDateFrom = '';
        this.createdDateTo = '';
        this.completeDateFrom = '';
        this.completeDateTo = '';
    }
}
