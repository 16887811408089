import { Component, Output, EventEmitter, Input } from '@angular/core';
import { ValidationRegexsDefine } from 'Content/script/libs/define/validation_regexs.define';
import { NgForm } from '@angular/forms';

@Component({
    selector: 'domain-search-dropdown-for-apply-domain-deletion',
    templateUrl: './domain_search_dropdown_for_apply_domain_deletion.html'
})

export class DomainSearchDropdownForApplyDomainDeletionComponent {
    @Output() onDecideSearchConditionEvent: EventEmitter<DomainSearchConditionForApplyDomainDeletion> = new EventEmitter();

    public readonly rDomainPattern: string;
    public searchCondition: DomainSearchConditionForApplyDomainDeletion;

    public constructor() {
        this.rDomainPattern = ValidationRegexsDefine.rDomainPattern;
        this.searchCondition = new DomainSearchConditionForApplyDomainDeletion();
    }

    public onDecideSearchCondition(form: NgForm): void {
        if (!form.valid) {
            for (let key in form.controls) {
                form.controls[key].markAsDirty();
            }
            return;
        }

        this.onDecideSearchConditionEvent.emit(this.searchCondition);
    }

    public onClickSearchConditionClear(): void {
        this.searchCondition.clear();
    }
}

export class DomainSearchConditionForApplyDomainDeletion {
    public constructor(
        public domainNames: string = '',
        ) { }

    public clear() {
        this.domainNames = '';
    }
}
