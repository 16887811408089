import { docCookies } from 'Content/script/libs/third_party/cookies';
import { SessionChangeResponse } from "Content/script/definitions/session_change_response"

export class JsLoadService {
    private _isLoadedPdmp: boolean = false;

    public loadPdmp(response: SessionChangeResponse): void {
        if (window.productFlg !== 'Product' && window.productFlg !== 'Staging') {
            return;
        }

        if (this._isLoadedPdmp) {
            return;
        }

        this._isLoadedPdmp = true;

        var expires = new Date();
        expires.setFullYear(expires.getFullYear() + 1);
        docCookies.setItem(response.key, response.dmpvalue, expires, void (0), response.Domain);
    }
}
