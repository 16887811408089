import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { ServiceContainer } from 'Content/script/service/service_container';
import { ValidationRegexsDefine } from 'Content/script/libs/define/validation_regexs.define';

/**
 * ドメイン 検索 ドロップダウン
 */
@Component({
    selector: 'backorder_search_dropdown',
    templateUrl: './backorder_search_dropdown.html'
})

export class BackOrderSearchDropdownComponent {
    public viewModel: BackOrderSearchDropdownViewModel;
    public constructor(private router: Router, private serviceContainer: ServiceContainer) {
        const queueId = serviceContainer.HubsService.multipleSendManager.GetComponentId();
        this.viewModel = new BackOrderSearchDropdownViewModel(this.router, queueId, this.serviceContainer);
    }
}

export class BackOrderSearchDropdownViewModel {
    private _searchCallBack: () => void;

    public isDisableView: boolean;

    protected searchConditionConfig: BackOrderSearchConditionConfig;

    protected searchCondition: BackOrderSearchCondition;

    protected readonly rDomainPattern: string = ValidationRegexsDefine.rDomainPattern;

    protected isClickNewOptionSearch: boolean = false;

    public domainNameGtm: string = '';
    public groupNameGtm: string = '';
    public whoIsGtm: string = '';
    public createDateGtm: string = '';
    public expDateGtm: string = '';

    public constructor(
        private router: Router,
        private queueId: number,
        private serviceContainer: ServiceContainer
    ) {
        this._searchCallBack = null;

        this.isDisableView = false;

        this.searchConditionConfig = new BackOrderSearchConditionConfig();

        this.searchCondition = new BackOrderSearchCondition();
       
        this._getDomainGroupNames();

    }

    /**
     * 検索時のコールバック関数を設定
     * @param searchCallBack 検索を実行した際のコールバック関数
     */
    public setCallBack(searchCallBack: () => void): void {
        this._searchCallBack = searchCallBack;
    }

    /**
     * 検索を無効にするかを指定
     * @param isDisableView
     */
    public setIsDisableView(isDisableView: boolean): void {
        this.isDisableView = isDisableView;
    }

    /**
    * 検索条件の対象を設定
    * @param searchConditionConfig
    */
    public setDomainSearchConditionConfig(searchConditionConfig: BackOrderSearchConditionConfig): void {
        this.searchConditionConfig = searchConditionConfig;
        this.searchCondition.domainGroupId = searchConditionConfig.status;
        this.searchCondition.domainNames = searchConditionConfig.domainName;
    }

    /**
     * 検索する条件を設定
     * @param searchCondition
     */
    public setDomainSearchCondition(searchCondition: BackOrderSearchCondition): void {
        this.searchCondition = searchCondition;
    }

    /**
     * 検索する条件を取得
     */
    public getDomainSearchCondition(): BackOrderSearchCondition {
        return this.searchCondition;
    }

    /**
    * 検索する条件を取得
    */
    public getNewOptionSearch(): boolean {
        return this.isClickNewOptionSearch;
    }

    /**
     * 検索
     * @param searchCondition
     */
    private _search(): void {
        if (this._searchCallBack) {
            this._searchCallBack();
        }
    }

    /**
     * 検索用のグループ名を取得する
     */
    private _getDomainGroupNames(): void {
        const req: DomainGroupListRequestHubDto = {
            CurrentPage: 1,
            PageSize: 100,
            GroupName: ''
        };

        this.searchCondition.groupNameSelectItems = [
            new DomainSearchSelectItem('ALERT','メール通知'),
            new DomainSearchSelectItem('REDEMPTION_PERIOD','バックオーダー未申請'),
            new DomainSearchSelectItem('PENDING_DELETE','バックオーダー申請完了（復旧可能性あり）'),
            new DomainSearchSelectItem('PENDING_REGIST','バックオーダー申請完了'),
            new DomainSearchSelectItem('AUCTION','オークション開始'),
            new DomainSearchSelectItem('CANCEL','キャンセル'),
            new DomainSearchSelectItem('WAIT_BACKORDER_RESULT','バックオーダー申請中'),
            new DomainSearchSelectItem('PENDING_UPDATE','バックオーダー完了待ち'),
        ];

       
    }

    /**
     * グループ名選択
     * @param event
     */
    public onChangeSearchDomainGroup(event: Event): void {
        const currentElement: HTMLInputElement = event.currentTarget as HTMLInputElement
        const domainGroupId: string = currentElement.value;
        this.searchCondition.domainGroupId = domainGroupId;

        const groupNameSelectItems: DomainSearchSelectItem = this.searchCondition.groupNameSelectItems
            .find(groupNameSelectItem => {
                return groupNameSelectItem.value === domainGroupId
            });
        this.searchCondition.domainGroupName = groupNameSelectItems !== void 0 ? groupNameSelectItems.text : '';
        this.groupNameGtm = '';
        if (this.searchCondition.domainGroupName && this.searchCondition.domainGroupName !== '') {
            this.groupNameGtm = 'groupname';
        }
    }

    /**
     * クリア
     */
    protected onClickSearchConditionClear(): void {
        this.searchCondition.clear()
    }

    /**
     * 検索
     * @param form
     */
    protected onSubmitSearchConditionSearch(form: NgForm): void {
        if (form.valid === false) {
            for (let key in form.controls) {
                form.controls[key].markAsDirty();
            }
            return;
        }
        this.isClickNewOptionSearch = false;
        this._search();
    }

    protected onClickSearchButton(form: NgForm): void {
        if (form.valid === false) {
            for (let key in form.controls) {
                form.controls[key].markAsDirty();
            }
            return;
        }
        this.isClickNewOptionSearch = true;
        this._search();
    }

    protected onChangeSearchDomainNames() {
        this.domainNameGtm = '';
        if (this.searchCondition.domainNames) {
            this.domainNameGtm = 'domain' + this.searchCondition.domainNames.replace(/\n|\r\n|\r/, '\n').trim().split('\n').length.toString();
        }
        
    }
}

/**
 * 検索条件の設定
 */
export class BackOrderSearchConditionConfig {
    public constructor(
        public isEnableDomainNames: boolean = true,
        public isEnableGroupName: boolean = true,
        public domainName: string = '',
        public status: string = ''
        ) {
    }
}

/**
 * 検索条件
 */
export class BackOrderSearchCondition {
    public constructor(
        public domainNames: string = '',
        public domainGroupId: string = '',
        public domainGroupName: string = '',
        public groupNameSelectItems: DomainSearchSelectItem[] = [],
      ) {
    }

    public clear(): void {
        this.domainNames = '';
        this.domainGroupId = '';
        this.domainGroupName = '';
        
    }
}

class DomainSearchSelectItem {
    public constructor(public value: string, public text: string) { }
}
