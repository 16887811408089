/**
 * 外部JSへメッセージを通知するためのカスタムイベント定義
 * HubsManager.ts用
 */
function fire(eventName: string, eventInit: any, initCustomEventDetailArg) {
    let event: CustomEvent;
    try {
        event = new CustomEvent(eventName, eventInit);
    } catch (ex) {
        event = window.document.createEvent('CustomEvent');
        event.initCustomEvent(eventName, false, true, initCustomEventDetailArg);
    }
    const dom = window.document.getElementsByTagName('body')[0];
    try {
        dom.dispatchEvent(event);
    } catch (ex) {
        console.log(eventName + 'のディスパッチに失敗しました');
    }
}

function fireBody(eventName: string) {
    fire(eventName, { 'bubbles': false, 'cancelable': true }, void 0);
}

function fireBodyWithDetail(eventName: string, detail: { [key: string]: any }) {
    fire(eventName, { 'bubbles': false, 'cancelable': true, detail }, detail);
}

//イベント名のプレフィクス
const EVENT_HUBSMANAGER = 'event_HubsManager';

//タグ管理ツールに送信する情報
interface IHubsManagerCustorCallbackInfo<T1, T2> {
    btnId: string;
    pathName: string;

    hubName: string;
    methodName: string;
    reqHubDtoName: string;
    resHubDtoName: string;
    reqHubDto: T1,
    resHubDto: T2;

    callBackResult: string;
};

//GTM
declare var google_tag_manager: 
{
    'GTM-NZ4B77T': {
        dataLayer: { get: (s: string) => any }
    }
}

//タグ管理ツールに渡したくないHubDto内のパラメータ　　例）C#で[IgnoreLogProperty]がついてるパラメータ
//reviverについて　→　https://developer.mozilla.org/ja/docs/Web/JavaScript/Reference/Global_Objects/JSON/parse
const allowableDtoParamFn
    : (dtoParamName: string, value: any) => any
    = (dtoParamName: string, value: any) =>
    {
        switch (dtoParamName) {
            case 'AccountId':
            case 'Password':
                return null;

        }
        return value;
    }

//イベント発火処理（共通）
function fireEventHubsManagerCallback<T1, T2>(eventName: string, hubName: string, methodName: string, reqHubDtoName: string, resHubDtoName: string, reqHubDto: T1, resHubDto: T2) {
    
    try {
        const cloneReqHubDto = JSON.parse(JSON.stringify(reqHubDto || null), allowableDtoParamFn);
        const cloneResHubDto = JSON.parse(JSON.stringify(resHubDto || null), allowableDtoParamFn);

        const info: IHubsManagerCustorCallbackInfo<T1, T2> = {
            btnId: google_tag_manager["GTM-NZ4B77T"].dataLayer.get('CD006 | btn_id'),
            pathName: location.pathname,

            hubName: hubName,
            methodName: methodName,
            reqHubDtoName: reqHubDtoName,
            resHubDtoName: resHubDtoName,
            reqHubDto: cloneReqHubDto,
            resHubDto: cloneResHubDto,

            callBackResult: eventName.replace(EVENT_HUBSMANAGER, '')
        }

        fireBodyWithDetail(`${eventName}_${hubName}_${methodName}`, info);
    }
    catch (e) {
        //debugOut(`${eventName}, ${hubName}, ${methodName}, ${reqHubDtoName}, ${resHubDtoName}のイベント実行に失敗しました。`);
    }
}

//デバッグ出力用
function matchAppEnvironment<T>(_isProduct: T, _isNOTProduct: T): T {
    const isProduct: boolean = (window.productFlg === "Product" || window.productFlg === "Staging");

    if (isProduct) {
        return _isProduct;
    } else {
        return _isNOTProduct;
    }
}

//デバッグ出力用
function debugOut(msg: string) {
    if (matchAppEnvironment<string>(null, msg)) {
        console.log(msg);
    }
}

//*********************************************************************************************
//
//以下export
//
//*********************************************************************************************

//イベント名
export const EVENT_HUBSMANAGER_OK = EVENT_HUBSMANAGER + 'OK';
export const EVENT_HUBSMANAGER_ERROR = EVENT_HUBSMANAGER + 'Error';
export const EVENT_HUBSMANAGER_NOSESSION = EVENT_HUBSMANAGER + 'NoSession';
export const EVENT_HUBSMANAGER_COMPLETE = EVENT_HUBSMANAGER + 'Complete';
export const EVENT_HUBSMANAGER_UNEXPECTEDERROR = EVENT_HUBSMANAGER + 'UnExpectedError';

/**
 * 下記５つの関数のパラメータ
 *
 * @param hubName
 * @param methodName
 * @param reqHubDtoName
 * @param resHubDtoName
 * @param reqHubDto
 * @param resHubDto
 */

//OK
export function fireEventHubsManagerCallbackOK<T1, T2>(hubName: string, methodName: string, reqHubDtoName: string, resHubDtoName: string, reqHubDto: T1, resHubDto: T2) {
    fireEventHubsManagerCallback(EVENT_HUBSMANAGER_OK, hubName, methodName, reqHubDtoName ,resHubDtoName, reqHubDto, resHubDto);
}

//Error
export function fireEventHubsManagerCallbackError<T1, T2>(hubName: string, methodName: string, reqHubDtoName: string, resHubDtoName: string, reqHubDto: T1, resHubDto: T2) {
    fireEventHubsManagerCallback(EVENT_HUBSMANAGER_ERROR, hubName, methodName, reqHubDtoName ,resHubDtoName, reqHubDto, resHubDto);
}

//NoSession
export function fireEventHubsManagerCallbackNoSession<T1, T2>(hubName: string, methodName: string, reqHubDtoName: string, resHubDtoName: string, reqHubDto: T1, resHubDto: T2) {
    fireEventHubsManagerCallback(EVENT_HUBSMANAGER_NOSESSION, hubName, methodName, reqHubDtoName ,resHubDtoName, reqHubDto, resHubDto);
}

//Complete
export function fireEventHubsManagerCallbackComplete<T1, T2>(hubName: string, methodName: string, reqHubDtoName: string, resHubDtoName: string, reqHubDto: T1, resHubDto: T2) {
    fireEventHubsManagerCallback(EVENT_HUBSMANAGER_COMPLETE, hubName, methodName, reqHubDtoName ,resHubDtoName, reqHubDto, resHubDto);
}

//UnExpectedError
export function fireEventHubsManagerCallbackUnExpectedError<T1, T2>(hubName: string, methodName: string, reqHubDtoName: string, resHubDtoName: string, reqHubDto: T1, resHubDto: T2) {
    fireEventHubsManagerCallback(EVENT_HUBSMANAGER_UNEXPECTEDERROR, hubName, methodName, reqHubDtoName ,resHubDtoName, reqHubDto, resHubDto);
}
