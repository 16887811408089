import { Component, Input, OnInit } from '@angular/core';
import { SafeHtml, DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'tbl',
    templateUrl: './tbl.html'
})

export class TblComponent implements OnInit {
    public ngOnInit(): void {
    }

    constructor(private domSanitizer: DomSanitizer) { }

    private _tableRowItems: ITableRowItem;
    
    @Input()
    set tableRowItems(tableRowItems: ITableRowItem) {
        this._tableRowItems = tableRowItems;
    }
    get tableRowItems(): ITableRowItem {
        if(!this._tableRowItems) {
            return {
                row: [ {
                        data: [
                            { tableHeader: "", tableData: "", isBindHtml: false}
                        ]
                    }
                ]
            }
        }

        return this._tableRowItems;
    }

    private _colGroupItem: IColGroupItem;

    @Input()
    set colGroupItem(colGroupItem: IColGroupItem) {
        this._colGroupItem = colGroupItem;
    }
    get colGroupItem(): IColGroupItem {
        if(!this._colGroupItem) {
            return {
                col: [
                    { width: "206px" },
                    { width: "" }
                ]
            }
        }
        return this._colGroupItem;
    }

    // 親コンポーネントから渡されたhtmlをサニタイズしてinnerHtmlにバインド
    public getSafeHtml(html: string): SafeHtml {
        return this.domSanitizer.bypassSecurityTrustHtml(html);
    }

    public isEmpty(value: string): boolean {
        if(value == '' || value == null || value == undefined) {
            return true;
        }
        return false;
    }
}

export interface ITableRowItem {
    row: ITableDataItemList[];
}

export interface ITableDataItemList {
    data: ITableDataItem[];
}

export interface ITableDataItem {
    tableHeader: string;
    tableData: string;
    isBindHtml: boolean;
}

export interface IColGroupItem {
    col: IColItem[];
}

export interface IColItem {
    width: string;
}