/// <reference path='../../../../definitions/window.d.ts' />
/// <reference path='../../../../interface/interface_frequently_viewed_page.d.ts' />


import { Component, Input, Output, EventEmitter, OnInit, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { ServiceContainer } from 'Content/script/service/service_container';
import { NotifyMessage, NotifyType } from 'Content/script/service/notify_service';
import { MultiPaymentManager } from 'Content/script/managers/MultiPaymentManager';
import { AnalyticsUtil } from 'Content/script/libs/adobeanalytics/analytics.util';
import { ValidationRegexsDefine } from 'Content/script/libs/define/validation_regexs.define';
import { StringUtil } from 'Content/script/libs/utils/string.util';
import { CreditCardListComponent } from '../creditcard_service/creditcard.component';
import * as creditCardType from 'credit-card-type';

/**
 * かんたん系のドメイン支払い方法変更
 */
@Component({
    selector: 'quick-payment',
    templateUrl: './quick_payment.html'
})

export class QuickPaymentComponent implements OnInit {
    private _pendingInit: boolean;
    @Input()
    set pendingInit(pendingInit: boolean) {
        this._pendingInit = pendingInit;
        if (!this._pendingInit) {
            this._requestGetDomainPayment();
        }
    }
    get pendingInit(): boolean {
        return this._pendingInit;
    }
    private _isDisableBank: boolean;
    @Input()
    set isDisableBank(isDisable: boolean) {
        this._isDisableBank = isDisable;

        // 選択されている支払い方法を更新
        this.updateSelectedPaymentType();
    }
    get isDisableBank(): boolean {
        return this._isDisableBank;
    }

    private _isDisableConvenience: boolean;
    @Input()
    set isDisableConvenience(isDisable: boolean) {
        this._isDisableConvenience = isDisable;

        // 選択されている支払い方法を更新
        this.updateSelectedPaymentType();
    }
    get isDisableConvenience(): boolean {
        return this._isDisableConvenience;
    }

    private queueId: number;
    public isDisableView: boolean;   
    private _el: HTMLElement;
    public currentForm: NgForm;
    protected currentPaymentType: DomainPaymentType;
    protected viewCreditCard: DomainCreditCard;
    protected viewConvenience: DomainConvenience;
    protected readonly PaymentType = DomainPaymentType;
    protected readonly ConvenienceType = DomainConvenienceType;
    public selectedPaymentType: DomainPaymentType;
    public registeredCreditCard: DomainCreditCard;
    public inputNewCreditCard: DomainNewCreditCard;
    public inputConvenience: DomainConvenience;
    public isCreditCardExpired: boolean = false;
    public isVisa: boolean = false;
    public isMasterCard: boolean = false;
    public isJCB: boolean = false;
    public isDinersClub: boolean = false;
    public isAmericanExpress: boolean = false;
    public creditCardLength: number = 19;
    public isValidCreditCard: boolean = true;
    public isPrettyCreditCard: boolean = false;
    // GetRolePayment処理中フラグ
    private _isProcessGetRolePayment: boolean;
    private set isProcessGetRolePayment(isProcess: boolean) {
        this._isProcessGetRolePayment = isProcess;
        this.onCompleteProcess();
    }
    private get isProcessGetRolePayment(): boolean {
        return this._isProcessGetRolePayment;
    }

    // InvoiceDomainGetCreditCard処理中フラグ
    private _isProcessGetDomainRegisterdCreditCard: boolean;
    private set isProcessGetDomainRegisterdCreditCard(isProcess: boolean) {
        this._isProcessGetDomainRegisterdCreditCard = isProcess;
        this.onCompleteProcess();
    }
    private get isProcessGetDomainRegisterdCreditCard(): boolean {
        return this._isProcessGetDomainRegisterdCreditCard;
    }

    // InvoiceDomainPaymentUpdateCreditCard処理中フラグ
    private _isProcessUpdateNewCreditCard: boolean;
    private set isProcessUpdateNewCreditCard(isProcess: boolean) {
        this._isProcessUpdateNewCreditCard = isProcess;
        this.onCompleteProcess();
    }
    private get isProcessUpdateNewCreditCard(): boolean {
        return this._isProcessUpdateNewCreditCard;
    }

    // DomainPaymentValidator処理中フラグ
    private _isProcessValidateConveniencePaymnet: boolean;
    private set isProcessValidateConveniencePaymnet(isProcess: boolean) {
        this._isProcessValidateConveniencePaymnet = isProcess;
        this.onCompleteProcess();
    }
    private get isProcessValidateConveniencePaymnet(): boolean {
        return this._isProcessValidateConveniencePaymnet;
    }

    /**
     * その他のお支払方法はこちらボタンが押下されたか
     * */
    public isOtherPaymentButtonClicked: boolean;
    public isDisplayOtherPaymentButton: boolean;

    public constructor(private router: Router, private serviceContainer: ServiceContainer, el: ElementRef) {
        this.queueId = serviceContainer.HubsService.multipleSendManager.GetComponentId();
        this.isDisableView = false;        
        this._el = el.nativeElement;
        this.currentForm = null;
        this.currentPaymentType = DomainPaymentType.None;
        this.viewCreditCard = new DomainCreditCard();
        this.viewConvenience = new DomainConvenience();
        this.selectedPaymentType = DomainPaymentType.None;
        this.registeredCreditCard = new DomainCreditCard();
        this.inputNewCreditCard = new DomainNewCreditCard();
        this.inputConvenience = new DomainConvenience();
        this.isOtherPaymentButtonClicked = false;
        this.isDisplayOtherPaymentButton = true;
        this.isProcessGetRolePayment = false;
        this.isProcessGetDomainRegisterdCreditCard = false;
        this.isProcessUpdateNewCreditCard = false;
        this.isProcessValidateConveniencePaymnet = false;
    }

    protected CacheBusting(uri: string): string {
        return window.CacheBusting(uri);
    }

    /**
     * 支払い方法(登録済みクレジットカード)表示
     * */
    public get isDisplayRegistedCreditCard(): boolean {
        if (!this.isDisplayListItem) {
            return false;
        }

        return this.registeredCreditCard.hasRegisteredCreditCard;
    }

    /**
     * 支払い方法(新規クレジットカード)表示
     * */
    public get isDisplayNewCreditCard(): boolean {
        if (!this.isDisplayListItem) {
            return false;
        }

        // クレジットカードが登録されている　かつ
        // その他のお支払方法はこちらボタンが押下されていなければ非表示
        if (this.registeredCreditCard.hasRegisteredCreditCard && !this.isOtherPaymentButtonClicked) {
            return false;
        }
        return true;
    }

    /**
     * 支払い方法(コンビニエンスストア)表示
     * */
    public get isDisplayConvenience(): boolean {
        if (!this.isDisplayListItem) {
            return false;
        }

        // その他のお支払方法はこちらボタンが押下されていなければ非表示
        if (!this.isOtherPaymentButtonClicked) {
            return false;
        }
        return !this.isDisableConvenience;
    }

    /**
     * 支払い方法(銀行振込)表示
     * */
    public get isDisplayBank(): boolean {
        if (!this.isDisplayListItem) {
            return false;
        }

        // その他のお支払方法はこちらボタンが押下されていなければ非表示
        if (!this.isOtherPaymentButtonClicked) {
            return false;
        }
        return !this.isDisableBank;
    }

    /**
     * 初回表示時に走る処理フラグ
     * */
    public get isDisplayListItem(): boolean {
        if (this.isProcessGetRolePayment) {
            return false;
        }

        if (this.isProcessGetDomainRegisterdCreditCard) {
            return false;
        }

        return true;
    }

    // コンポーネントないの全ての処理が終了しているか見る
    public get isAllLoading(): boolean {
        return (
            this.isProcessGetRolePayment ||
            this.isProcessGetDomainRegisterdCreditCard ||
            this.isProcessUpdateNewCreditCard ||
            this.isProcessValidateConveniencePaymnet
            )
    }

    public ngOnInit() {
        // 支払い方法が非表示時の際は実行しない
        if (this.isDisableView === false && this.pendingInit === false) {
            this._requestGetDomainPayment();
        }
    }

    /**
     * (外部公開用) 支払い方法確定時イベント
     * ※外部での呼び出し用
     * */
    public onDicidedPaymentMethod() {
        if (this.selectedPaymentType === DomainPaymentType.None) {
            return;
        }

        // 支払い方法が月次払いの場合はバリデーションチェックしない
        if (this.selectedPaymentType != DomainPaymentType.Monthly) {
            // 画面側からngFormを受け取るために強制的にクリックイベントを発火させている
            let form = this._el.querySelector('form');
            form.click();

            if (!!this.currentForm && this.currentForm.valid === false) {
                for (let key in this.currentForm.controls) {
                    this.currentForm.controls[key].markAsDirty();
                }
                this.setScrollNewCreditCard();
                return;
            }
            if (this.selectedPaymentType === DomainPaymentType.NewCreditCard && !this.isValidCreditCard) {
                this.setScrollNewCreditCard();
                return;
            }
        }
        
        switch (this.selectedPaymentType) {
            case DomainPaymentType.RegisteredCreditCard:
                this.setPaymentMethod(this.selectedPaymentType);
                this.currentPaymentType = this.selectedPaymentType;
                this.viewCreditCard = this.registeredCreditCard;

                this.onPaymentMethodChangedSuccess();
                break;
            case DomainPaymentType.NewCreditCard:
                this._updateNewCreditCard();
                break;
            case DomainPaymentType.Convenience:
                this._validateConveniencePaymnetMethod();
                break;
            default:
                this.setPaymentMethod(this.selectedPaymentType);
                this.currentPaymentType = this.selectedPaymentType;

                this.onPaymentMethodChangedSuccess();

                break;
        }
    }

    public setScrollNewCreditCard() {
        const element: HTMLElement = document.querySelector('.list-Selection');
        const elementRect = element.getBoundingClientRect();
        const absoluteElementTop = elementRect.top + window.pageYOffset;
        const middle = absoluteElementTop - (window.innerHeight / 2) + elementRect.height / 2;
        window.scrollTo(0, middle);
    }

    /**
     * (外部公開用) 入力されたクレジットカード情報を取得
     */
    public getNewCreditCardDetail(): DomainNewCreditCard {
        return this.inputNewCreditCard;
    }

    /**
     * (外部公開用) 入力されたコンビニエンス情報を取得
     */
    public getConvenienceDetail(): DomainConvenience {
        return this.inputConvenience;
    }

    /**
     * (外部公開用) 登録済みのマスクされたクレジットカード番号(下4桁)を取得
     */
    public getRegisteredMaskedCreditNumber(): string {
        return this.viewCreditCard.creditNumber;
    }

    /**
     * (外部公開用) クレジットカードが使用可能かどうか
     */
    public canUseCredit(): boolean {
        return this.viewCreditCard.canUseCredit;
    }

    /**
     * 支払い方法変更
     * @param paymnetMethod
     */
    @Output() setPaymentMethodEvent = new EventEmitter<DomainPaymentType>();
    private setPaymentMethod(paymentType: DomainPaymentType): void {
        this.setPaymentMethodEvent.emit(paymentType);
    }

    /**
     * コンビニ手数料を設定
     * */
    @Output() setConveinienceFeeEvent = new EventEmitter<{ convenienceFee: number, convenienceUnTaxFee: number }>();
    private setConvenienceFee(convenienceFee: number, convenienceUnTaxFee: number): void {
        this.setConveinienceFeeEvent.emit({
            convenienceFee: convenienceFee,
            convenienceUnTaxFee: convenienceUnTaxFee
        });
    }

    /**
     * 支払い方法変更が成功したとき
     * */
    @Output() onPaymentMethodChangedSuccessEvent = new EventEmitter();
    private onPaymentMethodChangedSuccess(): void {
        this.onPaymentMethodChangedSuccessEvent.emit();
    }

    /**
     * 支払い方法変更が失敗したとき
     * */
    @Output() onPaymentMethodChangedFailEvent = new EventEmitter();
    private onPaymentMethodChangedFail(): void {
        this.onPaymentMethodChangedFailEvent.emit();
    }

    /**
     * Hubとの通信が終了するタイミングで必ず発火させること
     * 発火前には当該箇所のisProcessをfalseに設定すること
     * */
    @Output() onCompleteAllProcessEvent = new EventEmitter<boolean>();
    private onCompleteProcess(): void {
        this.onCompleteAllProcessEvent.emit(this.isAllLoading);
    }

    /**
     * 支払い方法がクレジットカードで確定したとき
     */
    @Output() onDecideCreditCardInfoEvent = new EventEmitter<boolean>();
    private onDecideCreditCardInfo(hasRegisteredCreditCard: boolean): void {
        this.onDecideCreditCardInfoEvent.emit(hasRegisteredCreditCard);
    }

    /**
    * Hubとの通信が終了するタイミングで必ず発火させること
    * 発火前には当該箇所のisProcessをfalseに設定すること
    * */
    @Output() onChangePaymentMethodEvent = new EventEmitter<DomainPaymentType>();
    private onChangePaymentMethod(paymentType: DomainPaymentType): void {
        this.onChangePaymentMethodEvent.emit(paymentType);
    }

    /**
     * 支払い方法の権限取得
     */
    private _requestGetDomainPayment() {
        this.isProcessGetRolePayment = true;
        this.isProcessGetDomainRegisterdCreditCard = true;

        this.serviceContainer.HubsService.multipleSendManager.Send(
            this.queueId,
            this.serviceContainer.HubsService.hubsManager.quickHubManager.GetDomainPaymentInfo,
            null,
            (res: InvoiceGetDomainPaymentInfoResponseHubDto) => {
                if (res.RolePayment.IsAllowMonthly) {
                    this.isDisableView = true;
                    this._setPaymentType(DomainPaymentType.Monthly);
                    this.onDecideCreditCardInfo(false);
                } else {
                    this.registeredCreditCard.hasRegisteredCreditCard = res.CreditCard.IsRegistered;
                    this.registeredCreditCard.canUseCredit = res.CreditCard.CanUseCredit;
                    this.registeredCreditCard.creditNumber = res.CreditCard.CreditNumber;
                    this.registeredCreditCard.creditPeriodMonthYear = res.CreditCard.CreditPeriod;
                    this._defaultCheckPaymentMethod(this.registeredCreditCard.hasRegisteredCreditCard);
                }
            },
            _ => {
                this.registeredCreditCard.hasRegisteredCreditCard = false;
                this._defaultCheckPaymentMethod(this.registeredCreditCard.hasRegisteredCreditCard);
            },
            _ => {
                const queryparams = AnalyticsUtil.getQueryParamsOnFirstRender(window.RouterPath);
                this.router.navigate(['/login'], { queryParams: queryparams });
                return;
            },
            _ => {
                this.isProcessGetRolePayment = false;
                this.isProcessGetDomainRegisterdCreditCard = false;
                this.onCompleteProcess();
            },
            _ => {
                this.router.navigateByUrl('/error/server');
            }
        );
    }

    /**
     * ご登録済みのクレジットカード情報を取得
     */
    private _requestGetDomainRegisteredCreditCard() {
        this.isProcessGetDomainRegisterdCreditCard = true;

        this.serviceContainer.HubsService.multipleSendManager.Send(
            this.queueId,
            this.serviceContainer.HubsService.hubsManager.quickHubManager.QuickInvoiceDomainGetCreditCard,
            null,
            (res: InvoiceDomainGetCreditCardResponseHubDto) => {
                this.registeredCreditCard.hasRegisteredCreditCard = res.IsRegistered;
                this.registeredCreditCard.canUseCredit = res.CanUseCredit;
                this.registeredCreditCard.creditNumber = res.CreditNumber;
                this.registeredCreditCard.creditPeriodMonthYear = res.CreditPeriod;
                this._defaultCheckPaymentMethod(this.registeredCreditCard.hasRegisteredCreditCard);
            },
            (res: InvoiceDomainGetCreditCardResponseHubDto) => {
                //エラー
                this.registeredCreditCard.hasRegisteredCreditCard = false;
                this._defaultCheckPaymentMethod(this.registeredCreditCard.hasRegisteredCreditCard);
            },
            (res: InvoiceDomainGetCreditCardResponseHubDto) => {
                // セッション切れ
                const queryparams = AnalyticsUtil.getQueryParamsOnFirstRender(window.RouterPath);
                this.router.navigate(['/login'], { queryParams: queryparams });
                return;
            },
            (res: InvoiceDomainCreditCardDetailResponseHubDto) => {
                this.isProcessGetDomainRegisterdCreditCard = false;
            },
            (res: InvoiceDomainCreditCardDetailResponseHubDto) => {
                // 致命的エラー
                this.router.navigateByUrl('/error/server');
                return;
            }
        );
    }

    /**
     * 初回時のデフォルトの支払い方法を指定
     * @param hasRegisteredCreditCard
     */
    private _defaultCheckPaymentMethod(hasRegisteredCreditCard: boolean): void {
        // 支払い方法が初回の時のみ判定する
        if (this.selectedPaymentType === DomainPaymentType.None || this.selectedPaymentType === DomainPaymentType.NewCreditCard) {
            if (hasRegisteredCreditCard) {
                this._setPaymentType(DomainPaymentType.RegisteredCreditCard);
                this.viewCreditCard = this.registeredCreditCard;
            } else {
                this._setPaymentType(DomainPaymentType.NewCreditCard);
            }
        }

        // 利用可能なクレジットカード情報を保持しているか親コンポーネントに通知
        this.onDecideCreditCardInfo(hasRegisteredCreditCard && this.registeredCreditCard.canUseCredit);
    }

    /**
     * 支払い方法を設定
     * @param paymentType
     */
    private _setPaymentType(paymentType: DomainPaymentType) {
        this.setPaymentMethod(paymentType);
        this.currentPaymentType = paymentType;
        this.selectedPaymentType = paymentType;
    }

    /**
     * 新規クレジットカードを登録する
     */
    private _updateNewCreditCard() {
        this.isProcessUpdateNewCreditCard = true;

        const reqFormData: RequestFormData = {
            cardno: this.inputNewCreditCard.creditNumber.replaceAll(" ", ""),
            expire: this.inputNewCreditCard.creditPeriodYear + this.inputNewCreditCard.creditPeriodMonth,
            securitycode: this.inputNewCreditCard.securityCode,
            holdername: this.inputNewCreditCard.creditHolder,
            tokennumber: "1"
        } as RequestFormData;

        const manager = new MultiPaymentManager(window.OnamaeShopId);
        manager.Send(reqFormData, (res: getTokenResponse) => {
            const req: InvoiceDomainPaymentUpdateCreditCardRequestHubDto = {
                CreditCardToken: res.tokenObject.token[0],
                Num: this.inputNewCreditCard.creditNumber.replaceAll(" ", "").substring(0, 6) + this.inputNewCreditCard.creditNumber.replaceAll(" ", "").substring(this.inputNewCreditCard.creditNumber.length - 4)
            } as InvoiceDomainPaymentUpdateCreditCardRequestHubDto;

            this.serviceContainer.HubsService.multipleSendManager.Send(
                this.queueId,
                this.serviceContainer.HubsService.hubsManager.quickHubManager.QuickInvoiceDomainPaymentUpdateCreditCard,
                req,
                (res: InvoiceDomainPaymentUpdateCreditCardResponseHubDto) => {
                    this.setPaymentMethod(this.selectedPaymentType);
                    this.currentPaymentType = this.selectedPaymentType;
                    this._requestGetDomainRegisteredCreditCard();

                    this.onPaymentMethodChangedSuccess();
                },
                (res: InvoiceDomainPaymentUpdateCreditCardResponseHubDto) => {
                    // エラー
                    const notify: NotifyMessage = new NotifyMessage(this.serviceContainer.NotifyService.GetNotifyId(), NotifyType.Error, "クレジットカード情報の更新に失敗しました。");
                    this.serviceContainer.NotifyService.AddNotifyMessages([notify]);

                    this.onPaymentMethodChangedFail();
                },
                (res: InvoiceDomainPaymentUpdateCreditCardResponseHubDto) => {
                    // セッション切れ
                    this.router.navigateByUrl('/login');
                    const queryparams = AnalyticsUtil.getQueryParamsOnFirstRender(window.RouterPath);
                    this.router.navigate(['/login'], { queryParams: queryparams });
                    return;
                },
                (res: InvoiceDomainPaymentUpdateCreditCardResponseHubDto) => {
                    this.isProcessUpdateNewCreditCard = false;
                },
                (res: InvoiceDomainPaymentUpdateCreditCardResponseHubDto) => {
                    // 致命的エラー
                    this.router.navigateByUrl('/error/server');
                    return;
                }
            );
        });
    }

    /**
     * 支払い方法(コンビニ)の入力データを検証する
     */
    private _validateConveniencePaymnetMethod() {
        this.isProcessValidateConveniencePaymnet = true;

        const paymentHubDto: DomainPaymentRequestHubDto = {
            PaymentType: parseInt(DomainPaymentType.Convenience.toString()),
            ConveniCode: this.inputConvenience.selectedConvenienceType.toString(),
            UserNameKana: this.inputConvenience.payeeNameKana,
            CrOrderId: '',
            CreditNumber: '',
            CreditHolder: '',
            CreditPeriodMonth: '',
            CreditPeriodYear: '',
            SecurityCode: '',
        };

        const req: DomainPaymnetValidatorRequestHubDto = {
            DomainPaymentRequestHubDto: paymentHubDto
        };

        this.serviceContainer.HubsService.multipleSendManager.Send(
            this.queueId,
            this.serviceContainer.HubsService.hubsManager.quickHubManager.DomainPaymentValidator,
            req,
            (res: DomainPaymnetValidatorResponseHubDto) => {
                this.setPaymentMethod(this.selectedPaymentType);
                this.currentPaymentType = this.selectedPaymentType;
                this.setConvenienceFee(StringUtil.StringWithCommaToNumber(res.ConvenienceFee), StringUtil.StringWithCommaToNumber(res.ConvenienceUnTaxFee));
                this.inputConvenience.convenienceFee = res.ConvenienceFee;
                this.inputConvenience.convenienceUnTaxFee = res.ConvenienceUnTaxFee;
                this.inputConvenience.convenienceName = this._getConvenienceName(this.inputConvenience.selectedConvenienceType);
                this.viewConvenience = this.inputConvenience;

                this.onPaymentMethodChangedSuccess();
            },
            (res: DomainPaymnetValidatorResponseHubDto) => {
                // エラー
                const notify = new NotifyMessage(this.serviceContainer.NotifyService.GetNotifyId(), NotifyType.Error, "支払い方法を変更に失敗しました。");
                this.serviceContainer.NotifyService.AddNotifyMessages([notify]);

                this.onPaymentMethodChangedFail();
            },
            (res: DomainPaymnetValidatorResponseHubDto) => {
                // セッション切れ
                const queryparams = AnalyticsUtil.getQueryParamsOnFirstRender(window.RouterPath);
                this.router.navigate(['/login'], { queryParams: queryparams });
                return;
            },
            (res: DomainPaymnetValidatorResponseHubDto) => {
                this.isProcessValidateConveniencePaymnet = false;
            },
            (res: DomainPaymnetValidatorResponseHubDto) => {
                // 致命的エラー
                this.router.navigateByUrl('/error/server');
                return;
            }
        );
    }

    /**
     * 支払い方法変更ボタンのイベント
     */
    protected onOtherPaymentButtonClicked(): void {
        if (this.isDisableBank || this.isDisableConvenience) {
            this.selectedPaymentType = this.registeredCreditCard.hasRegisteredCreditCard ? this.PaymentType.RegisteredCreditCard : this.PaymentType.NewCreditCard;
        } else {
            this.selectedPaymentType = this.currentPaymentType;
        }

        this.isOtherPaymentButtonClicked = true;

        // 自身は非表示にする
        this.isDisplayOtherPaymentButton = false;
    }

    /**
     * 支払い方法種別変更時のイベント
     */
    protected onChangePaymentMethodRadio(value: DomainPaymentType): void {
        this.selectedPaymentType = value;
        this.onChangePaymentMethod(value);
    }

    /**
     * コンビニ種別変更時のイベント
     */
    protected onChangePaymentMethodConvenienceRadio(value: DomainConvenienceType): void {
        this.inputConvenience.selectedConvenienceType = value;
    }

    /**
     * isDisableBankとisDisableConvenienceが変更されたとき
     * */
    private updateSelectedPaymentType(): void {
        if (this.selectedPaymentType == DomainPaymentType.Monthly) {
            return;
        }
        if (this.isDisableBank || this.isDisableConvenience) {
            this.selectedPaymentType = this.registeredCreditCard.hasRegisteredCreditCard ? this.PaymentType.RegisteredCreditCard : this.PaymentType.NewCreditCard;
        } else {
            this.selectedPaymentType = this.currentPaymentType;
        }
    }

    /**
     * 画面からngFormを受け取る
     * TODO:takahashi 要修正*/
    protected setCurrentPaymentForm(form: NgForm): void {
        this.currentForm = form;
    }

    /**
     * コンビニエンス名を取得
     * @param convenienceType
     */
    private _getConvenienceName(convenienceType: DomainConvenienceType): string {
        switch (convenienceType) {
            case DomainConvenienceType.SevenEleven:
                return 'セブンイレブン';
            case DomainConvenienceType.FamilyMart:
                return 'ファミリーマート';
            case DomainConvenienceType.Lawson:
                return 'ローソン';
            case DomainConvenienceType.SeicoMart:
                return 'セイコーマート';
            default:
                return '-';
        }
    }
    private _setDefaultCreditValue(): void {
        this.isVisa = false;
        this.isMasterCard = false;
        this.isJCB = false;
        this.isDinersClub = false;
        this.isAmericanExpress = false;
        this.creditCardLength = 19;
        this.isValidCreditCard = false;

    }

    /// <summary>
    /// クレジットカードの有効期限の確認
    /// </summary>
    /// <param name="creditExpire"></param>
    /// <returns></returns>
    private isValidCreditExpire(creditExpire: string): boolean {
        if (!creditExpire) {
            return false;
        }

        let yearStartIndex = creditExpire.includes("/") ? 3 : 2;
        let date: Date = new Date();
        // HACK: 2100年になった際に注意(APIのレスポンス自体改修が必要そう)
        let year = date.getFullYear().toString().substr(0, 2) + creditExpire.substr(yearStartIndex, 2);
        let month = creditExpire.substr(0, 2);
        var creditExpireDate = new Date(parseInt(year), parseInt(month), 0);

        var now: Date = new Date();

        return creditExpireDate.getTime() < now.getTime();
    }

    public onKeyDownCreditCard(event: KeyboardEvent) {
        if (event.key != 'Delete' && event.key != 'Backspace') {
            this.isPrettyCreditCard = true;
        } else {
            this.isPrettyCreditCard = false;
        }
    }

    public onchangeCreditPeriodMonth(creditPeriodMonth: string): void {
        this.inputNewCreditCard.creditPeriodMonth = creditPeriodMonth;
        this.isCreditCardExpired = this.isValidCreditExpire(this.inputNewCreditCard.creditPeriodMonth + this.inputNewCreditCard.creditPeriodYear);
    }

    public onchangeCreditPeriodYear(creditPeriodYear: string): void {
        this.inputNewCreditCard.creditPeriodYear = creditPeriodYear;
        this.isCreditCardExpired = this.isValidCreditExpire(this.inputNewCreditCard.creditPeriodMonth + this.inputNewCreditCard.creditPeriodYear);
    }

    public onChangeCreditCard() {
      
        let creditCardListComponent = new CreditCardListComponent();
        if (!this.inputNewCreditCard.creditNumber || this.inputNewCreditCard.creditNumber === '') {
            this._setDefaultCreditValue();
            return;
        }
        let cards = creditCardListComponent.filterCardType(this.inputNewCreditCard.creditNumber);
        
        if (cards.type.isVisa || cards.type.isMasterCard || cards.type.isJCB || cards.type.isDinersClub || cards.type.isAmericanExpress) {
            this.isVisa = cards.type.isVisa;
            this.isMasterCard = cards.type.isMasterCard;
            this.isJCB = cards.type.isJCB;
            this.isDinersClub = cards.type.isDinersClub;
            this.isAmericanExpress = cards.type.isAmericanExpress;
            this.creditCardLength = cards.length;
            this.isValidCreditCard = cards.isValidCard;
            if (this.isPrettyCreditCard) {
                this.inputNewCreditCard.creditNumber = creditCardListComponent.prettyCardNumber(this.inputNewCreditCard.creditNumber, cards.niceType);
            }
           
        } else {
            this._setDefaultCreditValue();
            if (this.isPrettyCreditCard) {
                this.inputNewCreditCard.creditNumber = creditCardListComponent.prettyCardNumber(this.inputNewCreditCard.creditNumber, creditCardType.types.VISA);
            }
        }

        // セキュリティコードのバリデーションの切り替え
        if (cards.type.isAmericanExpress) {
            this.inputNewCreditCard.rNumberPattern = ValidationRegexsDefine.rAmericanExpressCreditSecurityCode;
        } else {
            this.inputNewCreditCard.rNumberPattern = ValidationRegexsDefine.rNotAmericanExpressCreditSecurityCode;
        }
    }

}

export class DomainCreditCard {
    public hasRegisteredCreditCard: boolean;
    public canUseCredit: boolean;
    public creditNumber: string;
    public creditPeriodMonthYear: string;

    public constructor() {
        this.hasRegisteredCreditCard = false;
        this.canUseCredit = true;
        this.creditNumber = '-';
        this.creditPeriodMonthYear = '-';
    }
}

export class DomainNewCreditCard {
    public creditNumber: string;
    public creditHolder: string;
    public creditPeriodMonth: string;
    public creditPeriodYear: string;
    public securityCode: string;
    public readonly securityCodePopup: string;
    public rNumberPattern: string;
    public readonly rCreditHolder: string;
    public readonly rNumberPatternWithSpace: string;
    public readonly creditPeriodYearItems: { value: string, text: string }[];

    public constructor() {
        const date: Date = new Date();
        const currentYearNumber: number = date.getFullYear();
        const currentYearString: string = currentYearNumber.toString();
        let creditPeriodYearItems: { value: string, text: string }[] = [{ value: currentYearString.substring(currentYearString.length - 2), text: currentYearString }];
        let year: string = '';
        for (let i = 1; i < 10; i++) {
            year = (currentYearNumber + i).toString();
            creditPeriodYearItems.push({ value: year.substring(year.length - 2), text: year });
        }

        this.creditNumber = '';
        this.creditHolder = '';
        this.creditPeriodMonth = '01';
        this.creditPeriodYear = (currentYearNumber + 1).toString().substring(year.length - 2);
        this.securityCode = '';
        this.securityCodePopup = '<div class="box-SecurityCode"><img src="' +
            window.CacheBusting('/Content/images/common/securitycode.png') +
            '" alt=""><p>セキュリティコードについて</p><p>裏面の署名欄に記載されているクレジットカード番号の後に記載された3桁の番号、または表面のクレジットカード番号の右上に記載されている4桁の番号を指します。</p></div>';
        this.rNumberPattern = ValidationRegexsDefine.rNotAmericanExpressCreditSecurityCode;
        this.rCreditHolder = ValidationRegexsDefine.rCreditHolder;
        this.rNumberPatternWithSpace = ValidationRegexsDefine.rNumberPatternWithSpace;
        this.creditPeriodYearItems = creditPeriodYearItems;
    }
}

export class DomainConvenience {
    public selectedConvenienceType: DomainConvenienceType;
    public payeeNameKana: string;
    public convenienceName: string;
    public convenienceFee: string;
    public convenienceUnTaxFee: string;
    public readonly rFullWidthKatakanaPattern: string;
    public constructor() {
        this.selectedConvenienceType = DomainConvenienceType.SevenEleven;
        this.payeeNameKana = '';
        this.convenienceName = '-';
        this.convenienceFee = '0';
        this.convenienceUnTaxFee = '0';
        this.rFullWidthKatakanaPattern = ValidationRegexsDefine.rFullWidthKatakanaPattern;
    }
}

export enum DomainPaymentType {
    None = 0,
    RegisteredCreditCard = 1,
    NewCreditCard = 2,
    Bank = 3,
    Convenience = 4,
    Monthly = 5,
}

export enum DomainConvenienceType {
    SevenEleven = 11,
    FamilyMart = 21,
    Lawson = 31,
    SeicoMart = 32
}
