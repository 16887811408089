
export class LineNoAndPageNoAccessor {
    public readonly AccessKey: string;

    public constructor() {
        this.AccessKey = "PagingConditions";
    }

    public getItem(path: string): LineCntAndPageNo {
        var lineCntAndPageNos = this._getItem();
        var index = this._getDataIndex(lineCntAndPageNos, path);

        if (index != -1) {
            return lineCntAndPageNos[index];
        }
        return null;
    }

    public setItem(lineCntAndPageNo: LineCntAndPageNo, path: string): void {
        try {
            var lineCntAndPageNos = this._getItem();

            var index = this._getDataIndex(lineCntAndPageNos, path);

            if (index != -1) {
                lineCntAndPageNos[index].LineCnt = lineCntAndPageNo.LineCnt;
                lineCntAndPageNos[index].PageNo = lineCntAndPageNo.PageNo;
            }

            if (index === -1) {
                lineCntAndPageNos.push(lineCntAndPageNo);
            }

            var newLineCntAndPageNo = JSON.stringify(lineCntAndPageNos);
            localStorage.setItem(this.AccessKey, newLineCntAndPageNo);
        }
        catch (ex) {
            console.error("Exception(LineCntAndPageNo)", ex.message);
        }
    }

    public removeItem(): void {
        localStorage.removeItem(this.AccessKey);
    }

    private _getItem(): Array<LineCntAndPageNo> {
        var item = localStorage.getItem(this.AccessKey);
        var lineCntAndPageNos = JSON.parse(item);
        if (lineCntAndPageNos) {
            return lineCntAndPageNos;
        }
        return [];
    }

    private _getDataIndex(lineCntAndPageNos: Array<LineCntAndPageNo>, path: string) {
        var index: number = -1;
        for (var arrayIndex: number = 0; arrayIndex < lineCntAndPageNos.length; arrayIndex++) {
            if (lineCntAndPageNos[arrayIndex].path === path) {
                index = arrayIndex;
                break;
            }
        }
        return index;
    }
}
