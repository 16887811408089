import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'primary-button',
    template: `
<button class="btn is-Primary"
        [class.is-Large]="isLarge"
        [class.is-Disabled]="disabled"
        (click)="onClick()"
>
    <ng-content></ng-content>
</button>
`
})
export class PrimaryButtonComponent {
    @Input() type: string = "button";
    @Input() size: string = "l";
    @Input() disabled: boolean = false;

    @Output() clickEvent = new EventEmitter();

    public get isLarge(): boolean {
        return this.size === "l";
    }

    public onClick() {
        this.clickEvent.emit();
    }
}