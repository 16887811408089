import { Directive, ElementRef, Input, HostListener } from '@angular/core';

@Directive({
    selector: '[tooltip]'
})
export class ToolTipDirective {
    private _el: HTMLElement;

    private targetElement: HTMLElement;

    private popupElement: HTMLElement;

    private oldChild: Node;

    @Input('target')
    public target: string;

    @Input('placement')
    public placement: string;

    @Input('content')
    public content: string;

    @Input('textalign')
    public textalign: string;

    @Input('type')
    public type: string;

    @Input('comparewidth')
    public comparewidth: number;

    constructor(private _elementRef: ElementRef) {
        this._el = _elementRef.nativeElement;
    }

    @HostListener('focusin', ['$event'])
    @HostListener('mouseenter', ['$event'])
    public onMouseOver(event: Event) {
        if (this.oldChild) {
            return;
        }
        if (this.type == 'ellipsis') {
            if (this._el.offsetWidth < this.comparewidth) {
                return;
            }
        }
        var node = document.createElement('div');
        node.classList.add('tooltip');
        node.innerHTML = '<div class="tooltip-Arrow"></div><div class="tooltip-Inner">' + this.content + '</div>';
        this.oldChild = node;
        this.popupElement = document.body.appendChild(node) as HTMLElement;

        this.setPosition(this.popupElement);

        if (this.type == 'ellipsis') {
            this.popupElement.classList.add('is-Ellipsis');
        }
    }

    @HostListener('focusout', ['$event'])
    @HostListener('mouseleave', ['$event'])
    public MouseOut(event: Event) {
        if (this.oldChild) {
            document.body.removeChild(this.oldChild);
            delete this.oldChild;
        }
    }

    @HostListener('click', ['$event'])
    public onClick(event: Event) {
        if (this.oldChild && this._el.tagName.toLocaleLowerCase() === 'a') {
            document.body.removeChild(this.oldChild);
            delete this.oldChild;
        }
    }

    private setPosition(tooltip: HTMLElement) {
        tooltip.classList.add('is-Show');

        var placement = this.placement,
            textalign = this.textalign,
            textalignClass = '',
            positionClass = '',
            offset = this._el.getBoundingClientRect(),
            tooltipWidth = tooltip.offsetWidth,
            tooltipHeight = tooltip.offsetHeight,
            thisWidth = this._el.offsetWidth,
            thisHeight = this._el.offsetHeight,
            setLeft = 0,
            setTop = 0;

        if (textalign === 'left') {
            textalignClass = 'text-Left';
        } else if (textalign === 'right') {
            textalignClass = 'text-Right';
        }
        tooltip.classList.add(textalignClass);

        switch (placement) {
            case 'top':
                positionClass = 'is-Top';
                setLeft = offset.left + ((thisWidth - tooltipWidth) / 2);
                setTop = offset.top - tooltipHeight + window.pageYOffset;
                break;
            case 'left':
                positionClass = 'is-Left';
                setLeft = offset.left + (-tooltipWidth);
                setTop = offset.top + ((thisHeight - tooltipHeight) / 2) + window.pageYOffset;
                break;
            case 'right':
                positionClass = 'is-Right';
                setLeft = offset.left + thisWidth;
                setTop = offset.top + ((thisHeight - tooltipHeight) / 2) + window.pageYOffset;
                break;
            default:
                positionClass = 'is-Bottom';
                setLeft = offset.left + ((thisWidth - tooltipWidth) / 2);
                setTop = offset.top + thisHeight + window.pageYOffset;
                break;
        }
        tooltip.style.left = setLeft.toString() + 'px';
        tooltip.style.top = setTop.toString() + 'px';
        tooltip.classList.add(positionClass)
    }
}
