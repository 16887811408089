<table class="tbl">
    <colgroup>
        <ng-container *ngFor="let col of colGroupItem.col">
            <col [style.width]="col.width">
        </ng-container>
    </colgroup>
    <tbody>
        <ng-container *ngFor="let row of tableRowItems.row">
            <tr>
                <ng-container *ngFor="let data of row.data">
                    <th *ngIf="!isEmpty(data.tableHeader); else emptyTd">{{data.tableHeader}}</th>
                    <ng-template #emptyTd>
                        <td></td>
                    </ng-template>
                    
                    <td *ngIf="!data.isBindHtml; else htmlTemplate">{{data.tableData}}</td>
                    <ng-template #htmlTemplate>
                        <td><div [innerHtml]="getSafeHtml(data.tableData)"></div></td>
                    </ng-template>
                </ng-container>
            </tr>
        </ng-container>
    </tbody>
</table><!-- /.tbl -->