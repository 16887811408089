<div *ngIf="viewMode === 'sp'" loading [isLoading]="isAllLoading" [isFixed]="true">
    <div class="ng-loading-hide contents">
        <sp-normal-anchor *ngIf="viewModel.questionnaireFlg"
                          [questionnaireMinute]="viewModel.questionnaireMinute"
                          (questionnaireLink)="viewModel.QuestionnaireLinkClick()"
                          (questionnaireClose)="viewModel.QuestionnaireCloseFormClick()"></sp-normal-anchor>
        <sp-header></sp-header>
        <main id="main" class="main">
            <div id="mainContent" class="main_inner">
                <div class="boxNotification is-appealBannerCampaign" *ngIf="viewModel.canDisplayLoginCampaignBanner">
                    <div class="boxNotification_inner">
                        <a class="boxNotificationLink" href="javascript:void(0)" [onamaeRouterLink]="viewModel.loginCampaignBanner.link"><img [src]="viewModel.loginCampaignBanner.image" width="296" height="50" decoding="async" alt="毎月実施 豪華景品プレゼント"></a>
                        <button type="button" class="boxNotificationClose" (click)="viewModel.onLoginCampaignBannerCloseClicked()" [style.color]="viewModel.loginCampaignBanner.deleteButtonColor"><i class="icon icon-Clear"></i></button>
                    </div>
                </div>
                <notify_area></notify_area>
                <router-outlet (activate)="onActivate($event)" (deactivate)="onDeactivate($event)"></router-outlet>
            </div>
        </main>
        <sp-footer class="footerWrap"></sp-footer>
        <div class="buttonPagetop" *ngIf="showTopButton"><span class="icon icon-ArrowUp"></span></div>
    </div><!-- end .ng-loading-hide -->
    <sp-ns-default [routerPath]="routerPath"></sp-ns-default>
    <sp-rs-recommend (doLoading)="doLoading()"
                     [isABNew_RsRecommendWithNameServer]="isABNew_RsRecommendWithNameServer"
                     [routerPath]="routerPath"
                     ></sp-rs-recommend>
</div><!-- end sp view -->
