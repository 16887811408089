/// <reference path='../../../definitions/window.d.ts' />

import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router'

/**
 *
 */
@Component({
    selector: 'footer-menu',
    templateUrl: './footer.html'
})

export class FooterMenuComponent implements OnInit {
    public viewModel: FooterMenuViewModel;

    public constructor(private router: Router) {
        this.viewModel = new FooterMenuViewModel();
    }

    public ngOnInit() {
        // TODO
    }

    public onSubmit() {
    }
}

class FooterMenuViewModel {
    public year: number;

    public constructor() {
        this.year = new Date().getFullYear();
    }
}
