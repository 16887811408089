import { Directive, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Directive({
    selector: '[scroll-loading]'
})
export class SpScrollLoadingDirective implements OnChanges {
    @Input("scroll-loading") isLoading: boolean;
    @Input() totalCount: number;

    @Output() callBack = new EventEmitter();

    private infiniteScrollObserver: IntersectionObserver;
    private readonly LOADING_ID = "sp-scroll-loading-directive";

    constructor(private elementRef: ElementRef) {
        // スクロールのコールバックを登録
        this.infiniteScrollObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (!entry.isIntersecting) return;

                this.infiniteScrollObserver.unobserve(entry.target);
                this.callBack.emit();
            });
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        for(let prop in changes){
            let change = changes[prop];

            if (prop === "isLoading") {
                if (change.currentValue === true) {
                    this.addLoading();
                } else {
                    this.removeLoading();
                    setTimeout(() => {
                        let isLessTotalCount = this.elementRef.nativeElement.childElementCount < this.totalCount;
                        let hasElement = !!this.elementRef.nativeElement.lastElementChild;

                        if (isLessTotalCount && hasElement) {
                            this.infiniteScrollObserver.observe(this.elementRef.nativeElement.lastElementChild);
                        }
                    }, 500);
                }
            }
        }
    }

    private removeLoading(): void {
        let loading = document.getElementById(this.LOADING_ID);
        if (!!loading) {
            loading.remove();
        }
    }

    private addLoading(): void {
        let loading = document.createElement("div");
        loading.id = this.LOADING_ID;
        loading.classList.add("loadingWrap", "mt40");
        loading.style.height = "30px";
        loading.innerHTML = `<div class="loading">
                    <div class="loading-Loader">
                        <div class="loading-Loader-Blade"></div>
                        <div class="loading-Loader-Blade"></div>
                        <div class="loading-Loader-Blade"></div>
                        <div class="loading-Loader-Blade"></div>
                        <div class="loading-Loader-Blade"></div>
                        <div class="loading-Loader-Blade"></div>
                        <div class="loading-Loader-Blade"></div>
                        <div class="loading-Loader-Blade"></div>
                        <div class="loading-Loader-Blade"></div>
                        <div class="loading-Loader-Blade"></div>
                        <div class="loading-Loader-Blade"></div>
                        <div class="loading-Loader-Blade"></div>
                    </div>
                  </div>`;
        this.elementRef.nativeElement.after(loading);
    }
}
