import { Directive, ElementRef, Output, Input, EventEmitter, HostListener, OnDestroy } from '@angular/core';

@Directive({
    selector: '[dropdownHover]'
})
export class DropDownHoverDirective {
    private _el: HTMLElement;

    private targetElement: HTMLElement;

    private bodyEventLitner: EventListener;


    @Input('data-target')
    public target: string;

    constructor(private _elementRef: ElementRef) {
        this._el = _elementRef.nativeElement;

        this.bodyEventLitner = (event: MouseEvent) => {
            if (this.targetElement) {
                this.targetElement.classList.remove('is-Show');
                this._el.classList.remove("is-Open");

                this.targetElement.style.left = '';
                this.targetElement.style.top = '';
            }
        };
    }

    @HostListener('mouseenter', ['$event'])
    public onMouseEnter(event: Event) {
        if (!this.targetElement) {
            this.targetElement = document.querySelector(this.target) as HTMLElement;
            this.targetElement.addEventListener("mouseenter", (event: MouseEvent) => {
                event.stopImmediatePropagation();
                event.stopPropagation();
            });
        }
        var isOpenSelector = document.querySelectorAll('.is-Open');
        for (var index = 0; index < isOpenSelector.length; index++) {
            var element = isOpenSelector.item(index) as HTMLElement;
            if (element != this._el && element.hasAttribute('dropdownHover')) {
                isOpenSelector.item(index).classList.remove('is-Open');
            }
        }
        if (!this.targetElement.classList.contains('is-Show')) {
            this.targetElement.classList.add('is-Show');
            this._el.classList.add('is-Open');
        }

        var thisRect = this._el.getBoundingClientRect();
        this.targetElement.style.top = thisRect.top + thisRect.height + window.scrollY + 'px';
        var left = thisRect.left - ((this.targetElement.offsetWidth - this._el.offsetWidth) / 2);
        this.targetElement.style.left = left.toString() + 'px';

        event.stopImmediatePropagation();
        event.stopPropagation();

        return false;
    }

    @HostListener('mouseleave', ['$event'])
    public onMouseOut(event: Event) {
        if (!this.targetElement.classList.contains('is-Active')) {
            this.targetElement.classList.remove('is-Show');
            this._el.classList.remove('is-Open');
            this.targetElement.classList.remove('is-Current');
        }
        event.stopImmediatePropagation();
        event.stopPropagation();

        return false;
    }

}
