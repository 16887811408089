import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[tblWrapScrollVertical]'
})
export class TblWrapScrollVertical {
    private _el: HTMLTableRowElement;

    constructor(private _elementRef: ElementRef) {
        this._el = _elementRef.nativeElement;
    }
    @HostListener('scroll', ['$event'])
    public onScroll(event: Event) {
        const popup = document.querySelector('.scroll-Hidden-Popup') as HTMLElement;
        if (popup != undefined) {
            popup.parentNode.removeChild(popup);
        }
        this.scrollAlignHeight();
    }

    private scrollAlignHeight() {
        const tblWrapFixedDiv = document.querySelector('.tblWrapFixedTableTbody') as HTMLElement;
        let tblWrapScroll_height: number = this._el.scrollTop;
        tblWrapFixedDiv.scrollTo({ top: tblWrapScroll_height, behavior: 'smooth' });
    }
}