import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceContainer } from "Content/script/service/service_container";
import { CustomizedEvents } from '../../../../libs/abtest/custom-event.util';
import { docCookies, FutureDate } from '../../../../libs/third_party/cookies';

@Component({
    selector: 'ns-default',
    template: ''
})

export class NsDefaultComponent implements OnChanges {
    @Input("routerPath") public routerPath: string;

    public constructor(
        public router: Router,
        public route: ActivatedRoute,
        public serviceContainer: ServiceContainer) {
    }

    public ngOnChanges(changes: SimpleChanges) {
        let screenIndex = '-1';
        let routerChange = changes["routerPath"];
        if (routerChange && routerChange.currentValue != undefined) {
            if (routerChange.currentValue === "/" || routerChange.currentValue === "/login") {
                NsDefaultCookie.renewNsDefaultCookie(false);
            }
            if (!NsDefaultCookie.getNsDefaultCookie()) {
                if (routerChange.currentValue.includes('/domain/setting/renew/list')) {
                    screenIndex = '0';
                }
                if (routerChange.currentValue.includes("/top")) {
                    screenIndex = '1';
                }
                if (screenIndex === '0' || screenIndex === '1') {
                    this._checkAllNSDefaultDetail(screenIndex);
                }

            }
        }
    }
    private _checkAllNSDefaultDetail(screenIndex: string): void {

        this.serviceContainer.HubsService.multipleSendManager.Send(
            this.serviceContainer.HubsService.multipleSendManager.GetComponentId(),
            this.serviceContainer.HubsService.hubsManager.promotionHubManager.CheckAllNSDefault,
            null,
            (response: CheckAllNSDefaultResponseHubDto) => {
                if (response.IsAllNSDefault) {
                    CustomizedEvents.dispatchCheckAllNSDefault({ screenIndex: screenIndex });
                    NsDefaultCookie.renewNsDefaultCookie(true);
                }
            },
            null,
            null,
            (data: CheckAllNSDefaultResponseHubDto) => {
            },
            null
        );
    }
}

/**
 * 吹き出しの表示・非表示フラグ
 */
export class NsDefaultCookie {
    private static readonly cookieKey: string = "nsDefault";
    public static getNsDefaultCookie(): boolean {
        const data: boolean = JSON.parse(docCookies.getItem(this.cookieKey));
        return data;
    }

    public static renewNsDefaultCookie(status: boolean): void {
        const data: boolean = status;
        docCookies.setItem(this.cookieKey, data, FutureDate.afterDays(106))
    }
}
