import { Component, NgZone } from '@angular/core';
import { Router, NavigationStart, RoutesRecognized, NavigationEnd } from '@angular/router';
import { docCookies, FutureDate } from 'Content/script/libs/third_party/cookies';
import { BaseServiceContainer } from 'Content/common/script/service/base_service_container';
import { Store } from '@ngrx/store';
import { AccountInfoState } from '../../../../script/store/states/account-info';
import { setAccountInfo } from '../../../../script/store/actions/account-info';
import { Service } from '../../../../script/service/account_info_service';
import { RouterEventService } from 'Content/common/script/service/router_event_service';
import { initialAccountInfoState } from 'Content/script/store/reducers/account-info';
//import { Subject, Subscription } from 'rxjs';

@Component({
    template: ''
})
export abstract class BaseAppComponent {
    private _router: Router;
    private _serviceContainer: BaseServiceContainer;
    private _ngZone: NgZone;
    private _storeAccountInfo: Store<AccountInfoState>;

    //protected routerEvents: Subscription;

    //private destroy$: Subject<boolean> = new Subject<boolean>();

    protected isFirstRender: boolean = true;

    private accountInfo: AccountInfoState;

    public constructor(router: Router, serviceContainer: BaseServiceContainer, routerEventService: RouterEventService, ngZone: NgZone, storeAccountInfo: Store<AccountInfoState>) {
        this._router = router;
        this._serviceContainer = serviceContainer;
        this._ngZone = ngZone;
        this._storeAccountInfo = storeAccountInfo;
        this.accountInfo = initialAccountInfoState;

        serviceContainer.AccountInfoService.bindCallback((result, data, errorType) => {
            this.beforeAcquiringAccountInfo(this.accountInfo, data);
            storeAccountInfo.dispatch(setAccountInfo(data));
            this.afterAcquiringAccountInfo(result, data, errorType);
            // データを更新する
            this.accountInfo = data;
        });

        /*this.routerEvents = router.events.subscribe((event: NavigationStart | RoutesRecognized | NavigationEnd) => {
            if (event instanceof NavigationStart) {
                this.eventNavigationStart(event);
            }
            if (event instanceof RoutesRecognized) {
                this.eventRoutesRecognized(event);
            }
            if (event instanceof NavigationEnd) {
                this.eventNavigationEnd(event);
            }
        });*/

        routerEventService.EventNavigationStart = (event) => {
            this.eventNavigationStart(event);
        };

        routerEventService.EventRoutesRecognized = (event) => {
            this.eventRoutesRecognized(event);
        };

        routerEventService.EventNavigationEnd = (event) => {
            this.eventNavigationEnd(event);
        };
    }

    protected abstract eventNavigationStart(event: NavigationStart): void;
    protected abstract eventRoutesRecognized(event: RoutesRecognized): void;
    protected abstract eventNavigationEnd(event: NavigationEnd): void;

    public abstract onActivate(componentRef: any): void;
    public abstract onDeactivate(componentRef: any): void;

    protected abstract beforeAcquiringAccountInfo(currentState: AccountInfoState, newState: AccountInfoState): void;
    protected abstract afterAcquiringAccountInfo(result: boolean, data: AccountInfoState, errorType: Service.ErrorType): void;

    protected _onInit(): void {
        // noop.
    }
    protected _onDestroy(): void {
        // noop.
        /*
        if (this.routerEvents && this.routerEvents.unsubscribe) {
            this.routerEvents.unsubscribe();
            this.destroy$.next(true);
        }*/
    }
}

export abstract class BaseAppViewModel {
    public productFlg: string;
    public year: number;
    public isStaffLogin: boolean;
    public isProduct: boolean;
    public IsMask: boolean;

    public static nodeJsTimes: NodeJS.Timer[] = [];

    public constructor(serviceContainer: BaseServiceContainer) {
        this.productFlg = window.productFlg;
        this.year = new Date().getFullYear();

        this.isStaffLogin = (docCookies.hasItem("transferType") && docCookies.getItem("transferType") == "STAFF_LOGIN")
        this.isProduct = (this.productFlg == "Product" || this.productFlg == "Staging");
    }

    public pushNodeJsTimes(timer: NodeJS.Timer) {
        BaseAppViewModel.nodeJsTimes.push(timer);
    }

    public clearNodeJsTimes() {
        BaseAppViewModel.nodeJsTimes = [];
    }

    public Destroy() {
        for (var index: number = 0; index < BaseAppViewModel.nodeJsTimes.length; index++) {
            clearTimeout(BaseAppViewModel.nodeJsTimes[index]);
        }    
    }
}

