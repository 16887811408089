import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[tblWrapRowHover]'
})
export class TblWrapRowHover {
    private _el: HTMLTableRowElement;

    @Input()
    public target: string;

    constructor(private _elementRef: ElementRef) {
        this._el = _elementRef.nativeElement;
    }

    @HostListener('mouseenter', ['$event'])
    public onMouseOver(event: Event) {
        this.changeBgColor();
    }

    @HostListener('mouseleave', ['$event'])
    public MouseOut(event: Event) {
        this.changeBgColor();
    }

    private changeBgColor() {
        const tblWrapFixedTable = document.querySelector('.js-tbl-Wrap-Fixed') as HTMLTableElement;
        const tblWrapTable = document.querySelector('.js-tbl-Wrap') as HTMLTableElement;
        if (this.target === "tblwrap") {
            tblWrapFixedTable.rows[this._el.rowIndex].classList.toggle('is-Active');
        } else {
            tblWrapTable.rows[this._el.rowIndex].classList.toggle('is-Active');
        }
        
    }
}