<ng-container *ngFor="let notify of notifyMessageViewModel.notifyInfoMessageList">
    <div style="padding: 20px 12px 0;">
        <div *ngIf="notify.notifySubMessages.length === 0" class="alertBox alertBox-safe textAlignCenter">
          <span class="alertBoxText alertBoxText-withIcon"><span class="icon icon-Checked"></span>{{notify.notifyMessage}}</span>
            <button class="alertBoxClose" (click)="ButtonInfoClose(notify.notifyId)">
                <span class="icon icon-Clear" aria-label="閉じる"></span>
            </button>
        </div>
        <div *ngIf="notify.notifySubMessages.length > 0" class="alertBox alertBox-safe textAlignCenter">
            <span class="alertBoxText alertBoxText-withIcon">
               <span class="icon icon-Checked"></span>
                完了しました。
            </span>
            <button class="alertBoxClose" (click)="ButtonInfoClose(notify.notifyId)">
                <span class="icon icon-Clear" aria-label="閉じる"></span>
            </button>
            <ul *ngIf="notify.isShowNotifySubMessages">
                <li *ngFor="let notifySubMessage of notify.notifySubMessages" [innerHtml]="notifySubMessage"></li>
            </ul>
        </div>
    </div>
</ng-container>

<ng-container *ngFor="let notify of notifyMessageViewModel.notifyErrorMessageList">
    <div style="padding: 20px 12px 0;">
        <div *ngIf="notify.notifySubMessages.length === 0" class="alertBox alertBox-attention textAlignCenter">
            <span class="alertBoxText" [innerHtml]="notify.notifyMessage"></span>
            <button class="alertBoxClose gtmclick" data-gtmvalue="sp-alertbanner"  (click)="ButtonErrorClose(notify.notifyId)">
                <span class="icon icon-Clear" aria-label="閉じる"></span>
            </button>
        </div>
        <div *ngIf="notify.notifySubMessages.length > 0" class="alertBox alertBox-attention">
            <span class="alertBoxText">{{notify.notifyMessage ? notify.notifyMessage : notify.notifySubMessages.length + "件のお申込みに失敗しました。"}}</span>
            <button class="alertBoxClose" (click)="onClickShowError(notify.notifyId)">
                <span class="icon icon-Clear" aria-label="閉じる"></span>
            </button>
            <ul *ngIf="notify.isShowNotifySubMessages">
                <li *ngFor="let notifySubMessage of notify.notifySubMessages" [innerHtml]="notifySubMessage"></li>
            </ul>
        </div>
    </div>
</ng-container>
