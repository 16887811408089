class SummaryForAdobeAnalytics {
    public constructor(
        public readonly category: string,
        public readonly product: string,
        public quantity: number,
        public totalPrice: number
    ) { }
}

export class PurchasedInfoForAdobeAnalytics {

    private _arrSummary: SummaryForAdobeAnalytics[] = [];  //売り上げ計測用情報

    public constructor() { }

    // 売り上げ計測用情報をカテゴリとプロダクト毎に集計
    public add(category: string, product: string, price: number): void {
        var arr: SummaryForAdobeAnalytics[];

        arr = this._arrSummary.filter(x => {
            if (category === x.category && product === x.product) return x;
        });
        if (arr.length === 0) {
            this._arrSummary.push(new SummaryForAdobeAnalytics(category, product, 1, price));
        } else {
            arr[0].quantity++;
            arr[0].totalPrice += price;
        }
    }

    // 売り上げ計測用文字列を生成
    public getAsString(): string {
        return this._arrSummary.map(x => {
            return new Array(x.category + ';' + x.product + ';' + x.quantity.toString() + ';' + x.totalPrice.toString() + '.00');
        }).join(',');
    }
}