/// <reference path='../definitions/window.d.ts' />

import { ChangeDetectorRef, Component, OnInit, AfterViewChecked } from '@angular/core';
import { Router, Route, NavigationStart, RoutesRecognized, NavigationEnd, ChildrenOutletContexts } from '@angular/router';
import { docCookies } from 'Content/script/libs/third_party/cookies';
import { Store } from '@ngrx/store';
import { ViewState, VIEWS } from '../store/states/view';
import { setViewMode, setViewCurrent } from '../store/actions/view';
import { getViewState, getViewLoading } from '../store/reducers/view';

import { RouterEventService } from 'Content/common/script/service/router_event_service';

import { spRootRoutes } from 'Content/script/routing/root-routing.module';

interface StyleElements {
    pc: HTMLLinkElement,
    sp: HTMLLinkElement
}

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styles: [
        // テスト環境、仮本番環境か判断する為のスタイル
        `.check { position: fixed; left: 0px; display: inline; z-index: 1000; margin-left: 2px; margin-top: 4px; }`,
        `input:checked{ display: none; }`,
        `input:checked + div{ display: none; }`,
        `.line_blue { background-color: blue; width: 100%; position:fixed; opacity: 0.4; height: 20px; }`,
        `.line_red { background-color: red; width: 100%; position:fixed; opacity: 0.4; height: 20px; }`,
        // スタッフツール経由のログインの際に表示する帯のスタイル
        '.line_green { background-color: #88ca6a; width: 100%; position:fixed; opacity: 0.4; height: 20px; }',
        '.line_margin { background-color: #88ca6a; width: 100%; position:fixed; opacity: 0.4; height: 20px; margin-top: 20px; }',
        // アンケートのスタイル
        `.questionnaire_check { float: right; margin: 5px 2px; }`,
        `.questionnaire_link { cursor: pointer }`,
        `.questionnaire_line_red { background-color: red; width: 100%; opacity: 0.4; height: 20px; text-align: center; }`,
    ]
})
export class AppComponent implements OnInit, AfterViewChecked {

    public viewMode: string;
    private styleElements: StyleElements;
    private viewportElement: HTMLMetaElement;
    public viewModel: AppViewModel;

    public constructor(
        private router: Router,
        private childrenOutletContexts: ChildrenOutletContexts,
        private changeDetectorRef: ChangeDetectorRef,
        private store: Store<ViewState>,
        private routerEventService: RouterEventService
    ) {

        this.viewModel = new AppViewModel();

        const isMobileViewMode: boolean = window.isMobileViewMode;

        const headElement: HTMLHeadElement = document.head;

        this.viewportElement = headElement.querySelector('meta.viewport');

        this.styleElements = {
            pc: headElement.querySelector('link.pc'),
            sp: headElement.querySelector('link.sp')
        };

        router.events.subscribe((event: NavigationStart | RoutesRecognized | NavigationEnd) => {
            if (event instanceof NavigationStart) {

                window.ReferrerPath = location.pathname;
                window.RouterPath = event.url;

                //ビューモードを切り替える
                const eventUrlParse: URL = new URL(location.protocol + "//" + location.host + event.url);
                const eventUrlPathName: string = eventUrlParse.pathname.replace(/^\//, "");
                let viewMode: string = VIEWS.PC;
                if (isMobileViewMode) {
                    let isPathMatched: boolean = false;
                    spRootRoutes.forEach((spRoute: Route) => {
                        const routePath: string = spRoute.path;
                        const routePathMatch: string = spRoute.pathMatch;
                        if (routePath === eventUrlPathName && routePathMatch === 'full') {
                            isPathMatched = true;
                        }
                    });

                    if (isPathMatched || /^sp\//.test(eventUrlPathName)) {
                        viewMode = VIEWS.SP;
                    }
                }
                if (viewMode !== this.viewMode) {
                    store.dispatch(setViewMode({
                        value: viewMode
                    }));
                }
                if (routerEventService.EventNavigationStart) {
                    routerEventService.EventNavigationStart(event);
                }
            }
            if (event instanceof RoutesRecognized) {
                //ビューモードを切り替える
                if (isMobileViewMode) {
                    const urlAfterRedirect: URL = new URL(location.protocol + "//" + location.host + event.urlAfterRedirects);
                    const urlAfterRedirectPathName: string = urlAfterRedirect.pathname.replace(/^\//, "");
                    if (/^sp\//.test(urlAfterRedirectPathName) && this.viewMode === VIEWS.PC) {
                        store.dispatch(setViewMode({
                            value: VIEWS.SP
                        }));
                    }
                }
                
                if (routerEventService.EventRoutesRecognized) {
                    routerEventService.EventRoutesRecognized(event);
                }
            }
            if (event instanceof NavigationEnd) {
                // canonicalを更新する
                const currentUrl: string = location.protocol + "//" + location.host + location.pathname;
                const elementCanonical: HTMLLinkElement = headElement.querySelector('link[rel="canonical"]');
                if (elementCanonical) {
                    elementCanonical.href = currentUrl;
                } else {
                    let link: HTMLLinkElement = document.createElement('link');
                    link.setAttribute('rel', 'canonical');
                    link.setAttribute('href', currentUrl);
                    headElement.appendChild(link)
                }
                if (routerEventService.EventNavigationEnd) {
                    routerEventService.EventNavigationEnd(event);
                }
                // 最終的なビューをセット
                setTimeout(() => {
                    store.dispatch(setViewCurrent({
                        value: this.viewMode
                    }));
                }, 300)
            }
        });

        window.dataLayerPush = (pushData: string): void => {
            var domainRenewKey: string = "";
            if (window.IframePath != void (0) && window.IframePath != "") {
                domainRenewKey = "__" + window.IframePath;
            }

            if (window.IsGACheck != void (0) && window.IsGACheck) {
                console.log("ga: " + "newnavi_" + location.pathname.slice(1).replace(/\//g, "_") + "__" + pushData + domainRenewKey);
            }

            dataLayer.push({
                'event': 'event_cd006',
                'CD006 | btn_id': "newnavi_" + location.pathname.slice(1).replace(/\//g, "_") + "__" + pushData + domainRenewKey
            });
        }

        window.addEventListener('beforeunload', (event) => {
            var headerSearchWords = sessionStorage.getItem('headerSearchWordsKey');
            if (headerSearchWords) {
                dataLayer.push({
                    'event': 'event_sendHeaderSearchWords',
                    'headerSearchWords': headerSearchWords
                });
            }
        });

    }

    public ngOnInit() {
        this.viewMode = (window.isMobileViewMode ? VIEWS.SP : VIEWS.PC);
        // 初期表示要素を削除
        const elementInitialHeader: HTMLDivElement = document.querySelector("#initialHeader");
        elementInitialHeader.parentNode.removeChild(elementInitialHeader);
        const elementInitialLoading: HTMLDivElement = document.querySelector("#initialLoading");
        elementInitialLoading.parentNode.removeChild(elementInitialLoading);

        this.store.select(getViewLoading).subscribe((isLoading) => {
            if (this.viewModel.isLoading !== isLoading) {
                this.viewModel.isLoading = isLoading;
            }
        });

        this.store.select(getViewState).subscribe((state) => {
            this.switchViewMode(state);
        });
    }

    ngAfterViewChecked() {
        this.changeDetectorRef.detectChanges();
    }

    private switchViewMode(state: ViewState) {
        const keep: boolean = state.keep;
        const mode: string = state.mode;
        if (!keep) {
            // ビューポートの切り替え
            let contentViewport: string = 'width=1024';
            if (mode === VIEWS.SP) {
                contentViewport = 'width=device-width, initial-scale=1';
            }
            this.viewportElement.content = contentViewport;
            // CSSを切り替える
            Object.keys(this.styleElements).forEach(key => {
                const element: HTMLLinkElement = this.styleElements[key];
                if (key === mode) {
                    element.disabled = false;
                } else {
                    element.disabled = true;
                }
            });
            // モードが変わった場合はOutletをDeactivatedにする
            if (this.viewMode && this.viewMode !== mode) {
                this.childrenOutletContexts.onOutletDeactivated();
            }
            this.viewMode = mode;         
        } 
    }
}

class AppViewModel {
    public isLoading: boolean = false;

    public productFlg: string;
    public isStaffLogin: boolean;
    public isProduct: boolean;
    
    public constructor() {
        this.productFlg = window.productFlg;
        this.isStaffLogin = (docCookies.hasItem("transferType") && docCookies.getItem("transferType") == "STAFF_LOGIN")
        this.isProduct = (this.productFlg == "Product" || this.productFlg == "Staging");
    }

    public NoneDisplayEnvironmentClick()
    {
        this.productFlg = 'Product';
    }

    public NoneDisplayStaffToolLoginClick() {
        this.isStaffLogin = false;
    }
}
