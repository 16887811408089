import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[navigationBtnRight]'
})
export class NavigationBtnRight {
    private _el: HTMLTableRowElement;

    @Input()
    public target: string;

    constructor(private _elementRef: ElementRef) {
        this._el = _elementRef.nativeElement;
    }

    @HostListener('click', ['$event'])
    public onClick(event: Event) {

        const popup = document.querySelector('.scroll-Hidden-Popup') as HTMLElement;
        if (popup != undefined) {
            popup.parentNode.removeChild(popup)

        }
        const tblWrapDiv = document.querySelector('.tbl-Wrap-Scroll') as HTMLElement;
        let tblWrapScroll_width: number = tblWrapDiv.scrollWidth;
        tblWrapDiv.scrollLeft = tblWrapScroll_width;
        const navigationBtnRight = document.querySelector('.navigationBtnRight') as HTMLElement;
        const navigationBtnLeft = document.querySelector('.navigationBtnLeft') as HTMLElement;
        if (!navigationBtnLeft.classList.contains('is-Active')) {
            navigationBtnLeft.classList.add('is-Active');
        }
        if (navigationBtnRight.classList.contains('is-Active')) {
            navigationBtnRight.classList.remove('is-Active');
        }
    }

}