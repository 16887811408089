import { NgZone } from '@angular/core';
import { request } from 'http';

export class MultipleSendManager
{
    private static myInstance: MultipleSendManager;

    private readonly ngZone: NgZone;
    
    /*********************
     * コンストラクタ
     * @param connection
     *********************/
    private constructor(ngZone: NgZone)
    {
        this.ngZone = ngZone;
        ComponentManager.Initialize();
    }

    /********************************************
     * シングルトンのインスタンスを作成 or 取得
     * @param connection
     ********************************************/
    public static CreateInstance(ngZone: NgZone): MultipleSendManager
    {
        if (void (0) != MultipleSendManager.myInstance && null != MultipleSendManager.myInstance)
            return MultipleSendManager.myInstance;

        MultipleSendManager.myInstance = new MultipleSendManager(ngZone);

        return MultipleSendManager.myInstance;
    }



    /*******************************
     * Hubにリクエストを送信する
     * @param componentId
     * @param requestFunction
     * @param requestParam
     * @param successCallBack
     * @param errorCallBack
     * @param noSessionCallBack
     * @param completeCallBack
     * @param unexceptedCallBack
     *******************************/
    public Send = (componentId: number,
        requestFunction: Function,
        requestParam: Object,
        successCallBack: Function,
        errorCallBack: Function,
        noSessionCallBack: Function,
        completeCallBack: Function = null,
        unexceptedCallBack: Function = null,
        submaintenancemodeCallBack: Function = null
    ): void => {
        let requestObject = new RequestObject(
            this.ngZone,
            componentId,
            requestFunction,
            requestParam,
            successCallBack,
            errorCallBack,
            noSessionCallBack,
            completeCallBack,
            unexceptedCallBack,
            submaintenancemodeCallBack);

        if (null == requestParam) {
            requestFunction(requestObject.successCallBack,
                requestObject.errorCallBack,
                requestObject.noSessionCallBack,
                requestObject.completeCallBack,
                requestObject.unexceptedCallBack,
                requestObject.submaintenancemodeCallBack);
        } else {
            requestFunction(requestParam,
                requestObject.successCallBack,
                requestObject.errorCallBack,
                requestObject.noSessionCallBack,
                requestObject.completeCallBack,
                requestObject.unexceptedCallBack,
                requestObject.submaintenancemodeCallBack);
        }
    };

    /************************************
     * コンポーネントIDをユニークに作成
     ************************************/
    public GetComponentId(): number
    {
        return ComponentManager.CreateComponentId();
    }

    /****************************************************
     * ユニークIDで発行したコンポーネントIDを無効にする
     * @param targetComponentId
     ****************************************************/
    public DeleteComponentId(targetComponentId: number): void
    {
        return ComponentManager.DeleteComponentId(targetComponentId);
    }
}

class ComponentManager
{
    private static componentId: number = 1000;

    private static aliveComponentIds: Array<number> = new Array<number>();

    public static Initialize(): void
    {
        ComponentManager.aliveComponentIds.push(SystemComponentId.ServiceAccountInfo);
    }

    /************************************
     * ユニークなコンポーネントIDを作成
     ************************************/
    public static CreateComponentId() : number
    {
        ComponentManager.componentId += 1;
        ComponentManager.aliveComponentIds.push(ComponentManager.componentId);

        return ComponentManager.componentId;
    }

    /************************************************
     * 生存しているコンポーネントの配列から削除する
     * @param componentId
     ************************************************/
    public static DeleteComponentId(componentId: number): void
    {
        var hitIndex = ComponentManager.aliveComponentIds.indexOf(componentId);
        if (-1 < hitIndex)
        {
            ComponentManager.aliveComponentIds.splice(hitIndex, 1);
        }
    }

    /********************************************
     * コンポーネントが存在しているか確認を行う
     * @param componentId
     ********************************************/
    public static AliveComponentId(componentId: number): boolean
    {
        var hitIndex = ComponentManager.aliveComponentIds.indexOf(componentId);
        if (-1 < hitIndex)
            return true;

        console.log("コンポーネントが存在していません(ComponentId:" + componentId + ")");
        return false;
    }
}



/**
 * リクエストイベント
 * Angular 管轄外での非同期実行の可能性があるのでハンドラー類は NgZone 配下で実行するようにしている
 */
class RequestObject {
    
    public targetComponentId: number;

    // リクエスト処理を行う
    public requestFunction: Function;

    // リクエストオブジェクト
    public requestObject: Object;

    // 成功した場合のコールバック
    public successCallBack: Function;

    // 失敗(エラー)した場合のコールバック
    public errorCallBack: Function;

    // 成功 or 失敗のコールバック処理後に呼ばれるコールバック
    public completeCallBack: Function;

    public unexceptedCallBack: Function;

    public noSessionCallBack: Function;

    // メンテナンスモードの場合のコールバック
    public submaintenancemodeCallBack: Function;

    public constructor(
        private readonly ngZone: NgZone,
        targetComponentId: number,
        requestFunction: Function,
        requestObject: Object,
        successCallBack: Function,
        errorCallBack: Function,
        noSessionCallBack: Function,
        completeCallBack: Function = null,
        unexceptedCallBack: Function = null,
        submaintenancemodeCallBack: Function)
    {
        this.targetComponentId = targetComponentId;
        this.requestFunction = requestFunction;
        this.requestObject = requestObject;

        this.successCallBack = (responseObject: Object) => {
            // コンポーネントが生存していればコールバックを行う
            if (successCallBack != null && successCallBack != void (0)) {
                if (ComponentManager.AliveComponentId(this.targetComponentId))
                    ngZone.run(() => successCallBack(responseObject));
            }
        };

        this.errorCallBack = (responseObject: Object) => {
            // コンポーネントが生存していればコールバックを行う
            if (errorCallBack != null && errorCallBack != void (0)) {
                if (ComponentManager.AliveComponentId(this.targetComponentId))
                    ngZone.run(() => errorCallBack(responseObject));
            }
        };

        this.noSessionCallBack = (responseObject: Object) => {
            // コンポーネントが生存していればコールバックを行う
            if (noSessionCallBack != null && noSessionCallBack != void (0)) {
                if (ComponentManager.AliveComponentId(this.targetComponentId))
                    ngZone.run(() => noSessionCallBack(responseObject));
            }
        };

        this.completeCallBack = (responseObject: Object) => {
            // コンポーネントが生存していればコールバックを行う
            if (completeCallBack != null && completeCallBack != void (0)) {
                if (ComponentManager.AliveComponentId(this.targetComponentId))
                    ngZone.run(() => completeCallBack(responseObject));
            }
        };

        this.unexceptedCallBack = (responseObject: Object) => {
            // コンポーネントが生存していればコールバックを行う
            if (unexceptedCallBack != null && unexceptedCallBack != void (0)) {
                if (ComponentManager.AliveComponentId(this.targetComponentId))
                    ngZone.run(() => unexceptedCallBack(responseObject));
            }
        };

        this.submaintenancemodeCallBack = (responseObject: Object) => {
            if (submaintenancemodeCallBack != null && submaintenancemodeCallBack != void (0)) {
                if (ComponentManager.AliveComponentId(this.targetComponentId))
                    ngZone.run(() => submaintenancemodeCallBack(responseObject));
            }
        };
    }
}

export enum SystemComponentId {
    ServiceAccountInfo = 1
}
