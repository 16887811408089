import { Directive, Input, forwardRef, OnInit } from '@angular/core';
import { NG_VALIDATORS, Validator, ValidatorFn, AbstractControl, Validators } from '@angular/forms';
import { ValidationInvalidListDefine } from 'Content/script/libs/define/validation_invalid_list.define';

const PHONE_INVALID_VALIDATOR: any = {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => PhoneInvalidValidator),
    multi: true
};

@Directive({
    selector: '[phoneInvalid][formControlName],[phoneInvalid][formControl],[phoneInvalid][ngModel]',
    providers: [PHONE_INVALID_VALIDATOR]
})

// 電話番号に下記を入力した場合、エラー判定エラー判定にする
export class PhoneInvalidValidator implements Validator {

    validate(c: AbstractControl): { [key: string]: any } {
        return CustomValidators.phoneInvalid(c);
    }
}

export class CustomValidators {

    static phoneInvalid(control: AbstractControl): { [key: string]: boolean } {
        if (isPresent(Validators.required(control))) return null;

        let v: string = control.value;

        // 一致した場合エラーにする
        if (matchingInvalidPhoneNumber(v) === true) {
            return { 'phoneInvalid': true };
        }
        return null;
    }
}

export function isPresent(obj: any): boolean {
    return obj !== undefined && obj !== null;
}

export function matchingInvalidPhoneNumber(value: string): boolean {
    return ValidationInvalidListDefine.invalidPhoneNumberList.lastIndexOf(value) != -1;
}
