/// <reference path='../../../script/definitions/window.d.ts' />

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RedirectComponent } from '../../../script/components/redirect/redirect.component';

const isMobileViewMode: boolean = window.isMobileViewMode;

let routes: Routes = [];

if (isMobileViewMode) {
    routes = [
        { 
            path: 'sp',
            children: [
                { path: '', redirectTo: 'login', pathMatch: 'full' },
                { path: 'login', loadChildren: () => import('./sp-login-routing.module').then(m => m.SpLoginRoutingModule) },
                { path: 'logout', loadChildren: () => import('./sp-logout-routing.module').then(m => m.SpLogoutRoutingModule) },
                { path: 'auth', loadChildren: () => import('./sp-auth-routing.module').then(m => m.SpAuthRoutingModule) },
                { path: 'top', loadChildren: () => import('./sp-top-routing.module').then(m => m.SpTopRoutingModule) },
                { path: 'domain', loadChildren: () => import('./sp-domain-routing.module').then(m => m.SpDomainRoutingModule) },
                { path: 'account', loadChildren: () => import('./sp-account-routing.module').then(m => m.SpAccountRoutingModule) },
                { path: 'rs', loadChildren: () => import('./sp-sd-routing.module').then(m => m.SpSdRoutingModule) },
                { path: 'payment', loadChildren: () => import('./sp-payment-routing.module').then(m => m.SpPaymentRoutingModule) },
                { path: 'desktopcloud', loadChildren: () => import('./sp-desktopcloud-routing.module').then(m => m.SpDesktopCloudRoutingModule) },
                { path: 'mail', loadChildren: () => import('./sp-mail-routing.module').then(m => m.SpMailRoutingModule) },
                { path: 'vps', loadChildren: () => import('./sp-vps-routing.module').then(m => m.SpVpsRoutingModule) },
                { path: 'domain/setting', loadChildren: () => import('./sp-domain-setting-routing.module').then(m => m.SpDomainSettingRoutingModule) },
                { path: 'domain/configuration', loadChildren: () => import('./sp-domain-configuration-routing.module').then(m => m.SpDomainConfigurationRoutingModule) },
                { path: 'domain/configuration/:id', loadChildren: () => import('./sp-domain-configuration-routing.module').then(m => m.SpDomainConfigurationRoutingModule) },
                { path: 'domain/setting/renew', loadChildren: () => import('./sp-domain-setting-renew-routing.module').then(m => m.SpDomainSettingRenewRoutingModule) },
                { path: 'link', loadChildren: () => import('./sp-link-routing.module').then(m => m.SpLinkRoutingModule) },
                { path: 'campaign', loadChildren: () => import('./sp-campaign-routing.module').then(m => m.SpCampaingRoutingModule) },
                { path: 'webacademy', loadChildren: () => import('./sp-webacademy-routing.module').then(m => m.SpWebacademyRoutingModule) },
                { path: 'quick', loadChildren: () => import('./sp-quick-routing.module').then(m => m.SpQuickRoutingModule) },
                { path: '**', component: RedirectComponent }
            ]
        }
    ];
}

export const spRoutes: Routes = routes;

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    declarations: [],
    exports: [
        RouterModule,
    ]
})

export class SpRoutingModule {
}
