import { Router } from '@angular/router';
import { SpServiceContainer } from 'Content/sp/script/service/service_container';
import { Subscription } from 'rxjs';

export class SpBaseLayoutComponent {
    protected isInited: boolean = false;

    private _storeSubscribes: Subscription[] = [];

    public constructor() {
        // noop.
    }

    protected addStoreSubscribe(subscribe: Subscription): void {
        this._storeSubscribes.push(subscribe);
    }

    protected clearStoreSubscribes(): void {
        if (this._storeSubscribes) {
            this._storeSubscribes.forEach((subscribe) => {
                subscribe.unsubscribe();
            });
        }
        
        this._storeSubscribes = [];
    }
}

export abstract class SpBaseLayoutViewModel {
    private _queueId: number;
    private _router: Router;
    private _serviceContainer: SpServiceContainer;

    public isLoading: boolean;

    public isOpenAccountMenu: boolean;
    public isOpenPaymentMenu: boolean;

    public isShowDomainPayment: boolean;
    public isViewHiddenInvoice: boolean;

    public constructor(router: Router, serviceContainer: SpServiceContainer) {
        this._serviceContainer = serviceContainer;
        this._router = router;
        this._queueId = serviceContainer.HubsService.multipleSendManager.GetComponentId();
    }

    public onToggleAccountMenu(): void {
        if (this.isOpenAccountMenu) {
            this.isOpenAccountMenu = false;
        } else {
            this.isOpenAccountMenu = true;
        }
    }

    public onTogglePaymentMenu(): void {
        if (this.isOpenPaymentMenu) {
            this.isOpenPaymentMenu = false;
        } else {
            this.isOpenPaymentMenu = true;
        }
    }

    protected _logout(): void {
        this.isLoading = true;
        this._serviceContainer.HubsService.multipleSendManager.Send(
            this._queueId,
            this._serviceContainer.HubsService.hubsManager.authHubManager.Logout,
            null,
            (responseObject: LogoutResponseHubDto) => {
                this.isLoading = false;
                this._router.navigateByUrl('/logout');
                return;
            },
            (responseObject) => {
                this.isLoading = false;
                this._router.navigateByUrl('/logout');
                return;
            },
            (responseObject) => {
                this.isLoading = false;
                this._router.navigateByUrl('/logout');
                return;
            },
            null,
            (responseObject) => {
                this.isLoading = false;
                this._router.navigateByUrl('/error/server');
                return;
            }
        )
    }

    public abstract logout(): void
}
