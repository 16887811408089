/// <reference path='../../../../script/definitions/window.d.ts' />

import { Component, Input, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BaseAppComponent, BaseAppViewModel } from 'Content/common/script/components/app/base.component';
import { RouterEventService } from 'Content/common/script/service/router_event_service';
import { AnalyticsUtil } from 'Content/script/libs/adobeanalytics/analytics.util';
import { docCookies, FutureDate } from 'Content/script/libs/third_party/cookies';
import { IPageTracking } from 'Content/script/service/tracking_service';
import { setViewLoading } from 'Content/script/store/actions/view';
import { AccountInfoState } from 'Content/script/store/states/account-info';
import { ViewState } from 'Content/script/store/states/view';
import { SpFooterComponent } from 'Content/sp/script/components/layout/footer/footer.component';
import { SpHeaderComponent } from 'Content/sp/script/components/layout/header/header.component';
import { SpServiceContainer } from 'Content/sp/script/service/service_container';
import { Banner, CampaignBannerLocalStorageController } from '../../../../script/components/campaign/present/common/campaign_banner.util';
import { CAMPAIGN_HIDDEN_LIST } from '../../../../script/components/campaign/present/common/campaign_present.define';
import { Service } from '../../../../script/service/account_info_service';
import { spRoutes } from 'Content/sp/script/routing/sp-routing.module';


@Component({
    selector: 'app-sp-root',
    templateUrl: './app.html'
})
export class AppSpComponent extends BaseAppComponent implements OnInit, OnDestroy {
    @Input() public viewMode: string;

    @ViewChild(SpHeaderComponent, { static: true }) headerComponent: SpHeaderComponent;
    @ViewChild(SpFooterComponent, { static: true }) footerComponent: SpFooterComponent;

    private queueId: number;

    public isAliveSession: boolean;

    public viewModel: SpAppViewModel;

    private trackingService: IPageTracking = null;

    private isInited: boolean = false;
    public isABNew_RsRecommendWithNameServer: boolean;
    public isAllLoading: boolean = false;
    protected _referrerPath: string;
    public get referrerPath(): string {
        return this._referrerPath;
    }

    protected _routerPath: string;
    public get routerPath(): string {
        return this._routerPath;
    }

    protected _componentRef: any;
    public get componentRef(): any {
        return this._componentRef;
    }

    public constructor(
        private router: Router,
        private serviceContainer: SpServiceContainer,
        private routerEventService: RouterEventService,
        private ngZone: NgZone,
        private store: Store<ViewState>,
        private storeAccountInfo: Store<AccountInfoState>
    ) {
        super(router, serviceContainer, routerEventService, ngZone, storeAccountInfo);
        
        this.viewModel = new SpAppViewModel(serviceContainer.HubsService.multipleSendManager.GetComponentId(), this.serviceContainer);

        /***********************************
        * 全体ローディングの制御処理を定義
        ***********************************/
        serviceContainer.ModalControlService.ShowFullScreenLoading = () => {
            this.ngZone.run(() => {
                store.dispatch(setViewLoading({
                    value: true
                }));
            });
        };

        serviceContainer.ModalControlService.HideFullScreenLoading = (isForceHide: boolean = false) => {
            this.ngZone.run(() => {
                if (window.isFirstRender == false || isForceHide) { 
                    store.dispatch(setViewLoading({
                        value: false
                    }));
                    window.scrollTo(0, 0);
                }
            });
        };

        /*****************************
        * 全体マスクの制御処理を定義
        *****************************/
        serviceContainer.ModalControlService.SwitchFullScreenMask = (isDisplay: boolean) => {
            this.ngZone.run(() => {
                this.viewModel.IsMask = isDisplay;
                if (isDisplay == false) {
                    this.viewModel.Destroy();
                }
            });
        };

        serviceContainer.ModalControlService.CssAngleCalculation = this.viewModel.CssAngleCalculation;
    }

    public async ngOnInit() {
        this._referrerPath = window.ReferrerPath;
        this._routerPath = window.RouterPath;
        this.isInited = true;
        this.isABNew_RsRecommendWithNameServer = await this.serviceContainer.SpABCookieService.AdjustRsRecommendWithNameServer.tests.AdjustRsRecommendWithNameServer.isNewAsync();
        this._onInit();
        this.store.dispatch(setViewLoading({
            value: true
        }));
    }

    /**
     * 子コンポーネントからのローディング表示制御用
     */
    public doLoading() {
        this.isAllLoading = true;
    }

    public ngOnDestroy() {
        this.isInited = false;
        this._onDestroy();
    }

    // コンポ―ネントが入れ替わるたびに呼び出される
    public onActivate(componentRef) {
        // AdobeAnalyticsページトラッキング
        this.trackingService = this.serviceContainer.TrackingService.provideServiceForMainComponent().create();
        this.trackingService.start();

        // Cookieにスタッフツールログインのフラグがなければ帯を消す
        this.viewModel.isStaffLogin = (docCookies.getItem("transferType") == "STAFF_LOGIN");

        this._referrerPath = window.ReferrerPath;
        this._routerPath = window.RouterPath;
        this._componentRef = componentRef;
    }

    // コンポ―ネントが入れ替わるたびに呼び出される
    public onDeactivate(componentRef) {
        if (this.trackingService) {
            this.trackingService.dispose();
            this.trackingService = null;
        }
    }

    protected eventNavigationStart(event) {
        // noop.
    }

    protected eventRoutesRecognized(event) {
        this.serviceContainer.SpABCookieService.onRaiseRouterEvent_RoutesRecognized(event.url);
    }

    protected eventNavigationEnd(event) {
        if (this.serviceContainer.ModalControlService.SwitchFullScreenMask) {
            this.serviceContainer.ModalControlService.SwitchFullScreenMask(false);
        }
        if (this.isInited) {
            if (this.headerComponent) {
                this.headerComponent.reset();
            }
            if (this.footerComponent) {
                this.footerComponent.reset();
            }
        }

        var url = event.urlAfterRedirects == '/' ? 'login' : event.urlAfterRedirects;
        // ドメイン更新画面ではアンケート・サイドメニューを出さない
        let avoidPath = /\/domain\/setting\/renew\/list.*|\/quick\/whoisprotect.*/;
        if (avoidPath.test(url) || url.includes('/login')) {
            this.viewModel.questionnaireFlg = false;
        } else {
            this.viewModel.QuestionnaireCloseFormLoad();
        }

        window.IframePath = "";
    }


    /**
     * AccountInfo取得前の処理.
     */
    protected beforeAcquiringAccountInfo(currentState: AccountInfoState, newState: AccountInfoState) {
        if (currentState.LoginName === '' && newState.LoginName !== '') {
            AnalyticsUtil.getDomainEarlyExpiration(this.serviceContainer);
        }
    }

    /**
     * AccountInfo取得後の処理.
     */
    protected afterAcquiringAccountInfo(result, data, errorType) {
        if (result) {
            // 成功時の処理
            window.isFirstRender = false;

            this.serviceContainer.ModalControlService.HideFullScreenLoading();

            const accountInfo = this.serviceContainer.AccountInfoService.mappingAccountInfo(data);

            if (accountInfo.IsTmpRegist) {
                // 仮登録の場合は仮登録情報編集画面へ
                // 仮登録関連の画面の場合は遷移の制御をしない
                if (!this._isMatchedRouteUrlPath(this.router.url, 'provisionalaccount')) {
                    this.router.navigateByUrl('provisionalaccount/detail/edit');
                    return;
                }
            }

            // ログインCPバナー
            this.viewModel.loginCampaignBannerInit();
        } else {
            switch (errorType) {
                case Service.ErrorType.Error:
                    this.serviceContainer.SessionService.SetCallBackUrl(location.pathname);
                    this.router.navigateByUrl('/sp/login');
                    return;

                case Service.ErrorType.NoSession:
                    this.serviceContainer.SessionService.SetCallBackUrl(location.pathname);
                    const queryparams = AnalyticsUtil.getQueryParamsOnFirstRender(window.RouterPath);
                    this.router.navigate(['/sp/login'], { queryParams: queryparams });
                    return;

                case Service.ErrorType.UnExcepted:
                    location.href = '/error/server';
                    return;
            }
        }
    }

    private _isMatchedRouteUrlPath(routeUrl: string, matchPath: string, matchIndex: number = 0): boolean {
        const url: URL = new URL(location.protocol + "//" + location.host + routeUrl);
        const pathName: string = url.pathname.replace(/^\/(sp\/?)?/, "");

        const splitPathName: string[] = pathName.split("/");

        if (splitPathName.length >= (matchIndex + 1) && splitPathName[matchIndex] === matchPath) {
            return true;
        }

        return false;
    }

    public CacheBusting(uri: string): string {
        return window.CacheBusting(uri);
    }
}

class SpAppViewModel extends BaseAppViewModel {
    private readonly LOGIN_CAMPAIGN_BANNER_LOCATION_ID: string = "7";
    private readonly LOGIN_CAMPAIGN_SP_BANNER_KEY: string = "LOGIN_CAMPAIGN_SP_BANNER_KEY";

    public loginCampaignBanner: Banner;
    public isShowLoginCampaignBanner: boolean;

    public get questionnaireMinute(): string {
        return this.serviceContainer.SpNPSEnqueteService.answerMinute;
    }
    public questionnaireFlg: boolean;
    public readonly QuestionnaireKey: string = "SpQuestionnaireCloseForm";

    public constructor(
        private queueId: number,
        private serviceContainer: SpServiceContainer
    ) {
        super(serviceContainer);
        this.IsMask = false;
        this.CssAngleCalculation(0, 0);

        this.loginCampaignBanner = new Banner();
        this.isShowLoginCampaignBanner = false;
    }

    /**
    * NPSアンケートのリンクを遷移
    */
    public QuestionnaireLinkClick() {
        window.open(this.serviceContainer.SpNPSEnqueteService.URL);
    }

    /**
    * NPSアンケート表示の条件を確認
    */
    public QuestionnaireCloseFormLoad() {
        if (this.serviceContainer.SpNPSEnqueteService.isDuration) {
            //アンケート期間中でもお客さんに✕ボタンを押されたら（＝cookieにfalseがセットされていたら）アンケートを表示しない
            this.questionnaireFlg = !docCookies.getItem(this.QuestionnaireKey);
        }
        else {
            //アンケート期間以外はアンケートを表示しない
            this.questionnaireFlg = false;
        }
    }

    /**
    * NPSアンケートを閉める
    */
    public QuestionnaireCloseFormClick() {
        this.questionnaireFlg = false;

        /*
        * 2ヶ月は表示しないようにする仕様なので31日 * 2にしました。
        */
        docCookies.setItem(this.QuestionnaireKey, false, FutureDate.afterDays(62));
    }

    public async loginCampaignBannerInit() {
        const AB = this.serviceContainer.SpABCookieService;
        let isForceGet = await AB.LoginCampaign.tests.LoginCampaign_01.isNewAsync();
        
        // LocalStorageにCP情報保存済みの場合はそちらを使う
        if (CampaignBannerLocalStorageController.hasActiveCampaignData(this.LOGIN_CAMPAIGN_SP_BANNER_KEY) && !isForceGet) {
            this.getCampaignBannerFromLocalStorage();
            this.isShowLoginCampaignBanner = true;
        } else {
            this.getCampaignBannerFromServer();
        }
    }

    /**
     * ログインCPバナー表示制御
     */
    public get canDisplayLoginCampaignBanner(): boolean {
        let isHiddenPath = location.pathname ==='/' ? true : CAMPAIGN_HIDDEN_LIST.some(x => location.pathname.startsWith(x));
        return !isHiddenPath && !!this.loginCampaignBanner.image && this.isShowLoginCampaignBanner;
    }

    /**
     * LocalStorageにログインCPバナー保存
     */
    private saveCampaignBanner(source: Banner): void {
        CampaignBannerLocalStorageController.save(this.LOGIN_CAMPAIGN_SP_BANNER_KEY, source);
    }

    /**
     * LocalStorageからログインCPバナー取得
     */
    private getCampaignBannerFromLocalStorage(): void {
        let source: Banner = CampaignBannerLocalStorageController.get(this.LOGIN_CAMPAIGN_SP_BANNER_KEY);
        this.loginCampaignBanner.set(source);
    }

    /**
     * サーバーからログインCPバナー取得
     * */
    public getCampaignBannerFromServer(): void {
        this.serviceContainer.HubsService.multipleSendManager.Send(
            this.queueId,
            this.serviceContainer.HubsService.hubsManager.campaignHubManager.GetSPLoginCampaignBanner,
            null,
            (res: GetLoginCampaignBannerResponseHubDto) => {
                let bannerInfo: LoginCampaignBanner = res.Messages.find(x => x.LocationId == this.LOGIN_CAMPAIGN_BANNER_LOCATION_ID);
                if (!!bannerInfo) {
                    this.isShowLoginCampaignBanner = true;
                    this.loginCampaignBanner.set(bannerInfo);

                    // LocalStorageにバナー情報を保存
                    this.saveCampaignBanner(this.loginCampaignBanner);
                }
            },
            () => { },
            () => { },
            () => { },
        );
    }

    public onLoginCampaignBannerCloseClicked(): void {
        this.isShowLoginCampaignBanner = false;
    }

    public CssAngleCalculation = (beginPercent: number, afterPercent: number): void => {
    }
}
