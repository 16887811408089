<div class="box-FixedForm-Type2 js-box-FixedForm-Type2" *ngIf="isOpen">
    <form #allServicesForm="ngForm" novalidate>
        <div class="box-FixedForm-Type2">
            <div class="box-FixedForm-Type2-Content">
                <div class="box-FixedForm-Type2-Header">
                    <p class="box-FixedForm-Type2-Header-Heading">ご意見・ご要望</p>
                    <button type="button" class="box-FixedForm-Type2-Header-Close js-box-FixedForm-Type2-Header-Close" (click)="onClickClose()">
                        <i class="icon icon-Clear" aria-label="閉じる"></i>
                    </button>
                </div>
                <!-- Aパターン -->
                <ng-container *ngIf="!isABNew_Survey; else elseBlock">
                <div class="box-FixedForm-Type2-Body">
                    <ng-container *ngIf="pages.length > 0">
                        <div class="box-FixedForm-Type2-Container is-Evaluation js-box-FixedForm-Type2-Container" *ngIf="pages[0].isShow">
                            <p class="box-FixedForm-Type2-Container-Heading">お名前.com Naviの総合的な満足度はどれくらいですか？</p>
                            <ul class="box-FixedForm-Type2-Container-Evaluation">
                                <li *ngFor="let option of pages[0].options" class="box-FixedForm-Type2-Container-Evaluation-Item is-bad js-Evaluation-Item" [ngClass]="{'is-Excellent':(option.order == 1), 'is-good':(option.order == 2), 'is-bad':(option.order == 3)}">
                                    <a href="javascript:void(0)" (click)="onChangeRadio(pages[0], option.id); onClickNext(allServicesForm)">
                                        <img src="{{CacheBusting(option.image)}}" width="58" height="58" alt="">
                                        <p>{{option.content}}</p>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="pages.length > 1">
                        <div class="box-FixedForm-Type2-Container is-Question js-box-FixedForm-Type2-Container" *ngIf="pages[1].isShow">
                            <p class="box-FixedForm-Type2-Container-Heading">
                                {{pages[1].content}}
                            </p>
                            <ul class="box-FixedForm-Type2-Container-Question">
                                <li class="box-FixedForm-Type2-Container-Question-Item" *ngFor="let option of pages[1].options">
                                    <label for="checkbox1-{{option.id}}" class="js-Question-Checkbox">
                                        <input type="checkbox" name="checkbox{{option.id}}" id="checkbox1-{{option.id}}" value="{{option.id}}" [(ngModel)]="option.isCheck" (change)="onChangeCheckBox()">
                                        <span class="form-Checkbox" tabindex="0"></span>
                                        <span class="ml4">{{option.content}}</span>
                                    </label>
                                    <ng-container *ngIf="option.isOther">
                                        <span class="ml8">
                                            <input placeholder="別の回答を入力" type="text" class="form-Text" [class.form-Error]="(v_otherText.errors && (v_otherText.dirty || v_otherText.touched))"
                                                   name="otherText" #v_otherText="ngModel" [(ngModel)]="pages[1].otherText"
                                                   [required]="isValidateOther === true" max="1000">
                                        </span>
                                        <div *ngIf="isValidateOther && (v_otherText.errors && (v_otherText.dirty || v_otherText.touched))">
                                            <label *ngIf="v_otherText.errors != null && v_otherText.errors.required" class="form-Error">
                                                必須項目です
                                            </label>
                                            <label *ngIf="v_otherText.errors != null && v_otherText.errors.max" class="form-Error">
                                                最大文字数：1000
                                            </label>
                                        </div>
                                    </ng-container>
                                </li>
                            </ul>
                            <div class="box-FixedForm-Type2-Container-NextBtn js-box-FixedForm-Type2-Container-NextBtn" [class.is-VisibilityHiden]="!isShowButtonNext">
                                <button type="button" class="btn is-Small is-Primary" (click)="onClickNext(allServicesForm)">次へ</button>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="pages.length > 2">
                        <div class="box-FixedForm-Type2-Container is-FreeColumn js-box-FixedForm-Type2-Container" *ngIf="pages[2].isShow">
                            <p class="box-FixedForm-Type2-Container-Heading">{{pages[2].content}}</p>
                            <div class="box-FixedForm-Type2-Container-FreeColumn">
                                <textarea class="form-Textarea js-FreeColumn-Textarea"
                                          name="questionTypeSeven{{pages[2].questionNo}}" [(ngModel)]="pages[2].textarea"></textarea>
                            </div>
                            <div class="box-FixedForm-Type2-Container-SendBtn js-box-FixedForm-Type2-Container-SendBtn">
                                <button type="button" class="btn is-Small is-Primary" (click)="onClickNext(allServicesForm)">送信</button>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="pages.length > 3">
                        <div class="box-FixedForm-Type2-Container is-Done js-box-FixedForm-Type2-Container" *ngIf="pages[3].isShow">
                            <div class="box-FixedForm-Type2-Container-Done">
                                <i class="icon icon-Checked"></i>
                                <p class="mt6">貴重なご意見をいただき、ありがとうございました。今後のサービス改善の参考にさせていただきます。</p>
                                <ul class="list-Btn is-Center">
                                    <li><button type="button" class="btn is-Small js-done-Button" (click)="onClickClose()">閉じる</button></li>
                                </ul>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="isShowPager">
                        <ul class="box-FixedForm-Type2-Pager js-Pager">
                            <li *ngFor="let active of actives" class="box-FixedForm-Type2-Pager-Item js-Pager-Item {{active}}"></li>
                        </ul>
                    </ng-container>
                </div>
                </ng-container>
                <!-- Bパターン -->
                <ng-template #elseBlock>
                <div class="box-FixedForm-Type2-Body">
                    <ng-container *ngIf="pages.length > 0">
                        <div class="box-FixedForm-Type2-Container is-Question js-box-FixedForm-Type2-Container" *ngIf="pages[0].isShow">
                            <p class="box-FixedForm-Type2-Container-Heading">
                                {{pages[0].content}}
                            </p>
                            <ul class="box-FixedForm-Type2-Container-Question">
                                <li class="box-FixedForm-Type2-Container-Question-Item" *ngFor="let option of pages[0].options">
                                    <label for="checkbox1-{{option.id}}" class="js-Question-Checkbox">
                                        <input type="checkbox" name="checkbox{{option.id}}" id="checkbox1-{{option.id}}" value="{{option.id}}" [(ngModel)]="option.isCheck" (change)="onChangeCheckBox()">
                                        <span class="form-Checkbox" tabindex="0"></span>
                                        <span class="ml4">{{option.content}}</span>
                                    </label>
                                    <ng-container *ngIf="option.isOther">
                                        <span class="ml8">
                                            <input placeholder="別の回答を入力" type="text" class="form-Text" [class.form-Error]="(v_otherText.errors && (v_otherText.dirty || v_otherText.touched))"
                                                   name="otherText" #v_otherText="ngModel" [(ngModel)]="pages[0].otherText"
                                                   [required]="isValidateOther === true" max="1000">
                                        </span>
                                        <div *ngIf="isValidateOther && (v_otherText.errors && (v_otherText.dirty || v_otherText.touched))">
                                            <label *ngIf="v_otherText.errors != null && v_otherText.errors.required" class="form-Error">
                                                必須項目です
                                            </label>
                                            <label *ngIf="v_otherText.errors != null && v_otherText.errors.max" class="form-Error">
                                                最大文字数：1000
                                            </label>
                                        </div>
                                    </ng-container>
                                </li>
                            </ul>
                            <div class="box-FixedForm-Type2-Container-NextBtn js-box-FixedForm-Type2-Container-NextBtn" [class.is-VisibilityHiden]="!isShowButtonNext">
                                <button type="button" class="btn is-Small is-Primary" (click)="onClickNext(allServicesForm)">次へ</button>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="pages.length > 1">
                        <div class="box-FixedForm-Type2-Container is-Evaluation js-box-FixedForm-Type2-Container" *ngIf="pages[1].isShow">
                            <p class="box-FixedForm-Type2-Container-Heading">お名前.com Naviの総合的な満足度はどれくらいですか？</p>
                            <ul class="box-FixedForm-Type2-Container-Evaluation">
                                <li *ngFor="let option of pages[1].options" class="box-FixedForm-Type2-Container-Evaluation-Item is-bad js-Evaluation-Item" [ngClass]="{'is-Excellent':(option.order == 1), 'is-good':(option.order == 2), 'is-bad':(option.order == 3)}">
                                    <a href="javascript:void(0)" (click)="onChangeRadio(pages[1], option.id); onClickNext(allServicesForm)">
                                        <img src="{{CacheBusting(option.image)}}" width="58" height="58" alt="">
                                        <p>{{option.content}}</p>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="pages.length > 2">
                        <div class="box-FixedForm-Type2-Container is-FreeColumn js-box-FixedForm-Type2-Container" *ngIf="pages[2].isShow">
                            <p class="box-FixedForm-Type2-Container-Heading">{{pages[2].content}}</p>
                            <div class="box-FixedForm-Type2-Container-FreeColumn">
                                <textarea class="form-Textarea js-FreeColumn-Textarea"
                                          name="questionTypeSeven{{pages[2].questionNo}}" [(ngModel)]="pages[2].textarea"></textarea>
                            </div>
                            <div class="box-FixedForm-Type2-Container-SendBtn js-box-FixedForm-Type2-Container-SendBtn">
                                <button type="button" class="btn is-Small is-Primary" (click)="onClickNext(allServicesForm)">送信</button>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="pages.length > 3">
                        <div class="box-FixedForm-Type2-Container is-Done js-box-FixedForm-Type2-Container" *ngIf="pages[3].isShow">
                            <div class="box-FixedForm-Type2-Container-Done">
                                <i class="icon icon-Checked"></i>
                                <p class="mt6">貴重なご意見をいただき、ありがとうございました。今後のサービス改善の参考にさせていただきます。</p>
                                <ul class="list-Btn is-Center">
                                    <li><button type="button" class="btn is-Small js-done-Button" (click)="onClickClose()">閉じる</button></li>
                                </ul>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="isShowPager">
                        <ul class="box-FixedForm-Type2-Pager js-Pager">
                            <li *ngFor="let active of actives" class="box-FixedForm-Type2-Pager-Item js-Pager-Item {{active}}"></li>
                        </ul>
                    </ng-container>
                </div>
                </ng-template>
            </div>
        </div>
    </form>
</div>
