<ng-container *ngIf="viewModel.isDisableView === false" >
    <!-- 検索条件ボタン -->
    <button type="button" class="btn is-Small gtmclick" data-gtmvalue="s_conditions_search" dropdown align="left" target="#dropdownConditionsSearch" close="#search" hide="#additionSearch">検索条件<i class="icon icon-DropDown"></i></button>
    <!-- 検索ドロップダウン -->
    <form (ngSubmit)="viewModel.onSubmitSearchConditionSearch(searchDomain)" #searchDomain="ngForm" novalidate>
        <div class="dropdown is-Large" id="dropdownConditionsSearch">
            <div class="dropdown-Main pt10 pb10">
                <table class="tbl-Dotted is-ValignTop">
                    <colgroup>
                        <col width="108">
                    </colgroup>
                    <tbody>
                        <tr *ngIf="viewModel.searchConditionConfig.isEnableDomainNames">
                            <th class="is-ValignTop">
                              <ng-container *ngIf="viewModel.titleDomainSearch ; else domainSearchTemp"> <strong>{{viewModel.titleDomainSearch}}</strong></ng-container>
                              <ng-template #domainSearchTemp><strong>{{isSslSearch ? "コモンネーム":"ドメイン名"}}</strong></ng-template></th>
                            <td>
                               <textarea name="domainNames" class="form-Textarea" [class.form-Error]="(domainNames.errors && (domainNames.dirty || domainNames.touched))"
                                          [(ngModel)]="viewModel.searchCondition.domainNames"
                                          #domainNames="ngModel" pattern="{{viewModel.rDomainPattern}}" maxRowBytes="255" maxRowsCount="100" (ngModelChange)="viewModel.onChangeSearchDomainNames()"></textarea>
                                <label *ngIf="domainNames.errors != null && domainNames.errors.pattern" class="form-Error">
                                    入力形式を確認してください
                                </label>
                                <label *ngIf="domainNames.errors != null && domainNames.errors.maxRowBytes" class="form-Error">
                                    1ドメインにつき最大255文字までです。
                                </label>
                                <label *ngIf="domainNames.errors != null && domainNames.errors.maxRowsCount" class="form-Error">
                                    一度に検索できる上限を超えています。
                                </label>
                            </td>
                        </tr>
                        <tr *ngIf="viewModel.searchConditionConfig.isEnableGroupName">
                            <th><strong>グループ名</strong></th>
                            <td>
                                <select name="groupName" (change)="viewModel.onChangeSearchDomainGroup($event)">
                                    <option [selected]="viewModel.searchCondition.domainGroupId === ''" value="">グループ名を選択</option>
                                    <option *ngFor="let groupNameSelectItem of viewModel.searchCondition.groupNameSelectItems" [selected]="viewModel.searchCondition.domainGroupId === groupNameSelectItem.value" value="{{groupNameSelectItem.value}}">{{groupNameSelectItem.text}}</option>
                                </select>
                            </td>
                        </tr> 
                        <tr *ngIf="viewModel.searchConditionConfig.isEnableAnotherSearch">
                            <th><strong>{{viewModel.searchCondition.anotherSelectItem.text}}</strong></th>
                            <td>
                                <select name="anotherSelectItemValue" (change)="viewModel.onChangeSearchAnotherSelectItemStatus($event)">
                                    <option *ngFor="let item of viewModel.searchCondition.anotherSelectItem.listItem" [selected]="viewModel.searchCondition.anotherSelectItemValue === item.value" value="{{item.value}}">{{item.text}}</option>
                                </select>
                            </td>
                        </tr>
                        <tr *ngIf="viewModel.searchConditionConfig.isEnableWhoisProxyStatus">
                            <th><strong>Whois公開代行</strong></th>
                            <td>
                                <select name="whoisProxyStatus" (change)="viewModel.onChangeSearchWhoisProxyStatus($event)">
                                    <option *ngFor="let whoisProxyStatusSelectItem of viewModel.searchCondition.whoisProxyStatusSelectItems" [selected]="viewModel.searchCondition.whoisProxyStatus === whoisProxyStatusSelectItem.value" value="{{whoisProxyStatusSelectItem.value}}">{{whoisProxyStatusSelectItem.text}}</option>
                                </select>
                            </td>
                        </tr>
                        <tr *ngIf="viewModel.searchConditionConfig.isEnableCreatedDate">
                          <th>
                            <ng-container *ngIf="viewModel.titleCreateDateSearch ; else createDateSearchTemp"> <strong>{{viewModel.titleCreateDateSearch}}</strong></ng-container>
                            <ng-template #createDateSearchTemp><strong>{{isSslSearch ? "発行日":"登録日"}}</strong></ng-template>
                          </th>
                            <td>
                                <div pikaday class="form-TextWithIcon">
                                    <input type="text" class="form-Text is-Small" [class.form-Error]="(createdDateFrom.errors && (createdDateFrom.dirty || createdDateFrom.touched))"
                                           name="createdDateFrom" placeholder="指定なし" [(ngModel)]="viewModel.searchCondition.createdDateFrom" (change)="viewModel.onChangeCreatedDateFrom($event)"
                                           #createdDateFrom="ngModel" date>
                                    <button type="button" class="form-TextWithIcon-Btn js-datepicker"><i class="icon icon-Calendar" aria-label="カレンダー"></i></button>
                                </div> から
                                <div pikaday class="form-TextWithIcon">
                                    <input type="text" class="form-Text is-Small" [class.form-Error]="(createdDateTo.errors && (createdDateTo.dirty || createdDateTo.touched))"
                                           name="createdDateTo" placeholder="指定なし" [(ngModel)]="viewModel.searchCondition.createdDateTo" (change)="viewModel.onChangeCreatedDateTo($event)"
                                           #createdDateTo="ngModel" date>
                                    <button type="button" class="form-TextWithIcon-Btn js-datepicker"><i class="icon icon-Calendar" aria-label="カレンダー"></i></button>
                                </div> まで
                                <label *ngIf="createdDateFrom.errors != null && createdDateFrom.errors.date" class="form-Error">
                                    入力形式を確認してください
                                </label>
                                <label *ngIf="createdDateTo.errors != null && createdDateTo.errors.date" class="form-Error">
                                    入力形式を確認してください
                                </label>
                            </td>
                        </tr>
                        <tr *ngIf="viewModel.searchConditionConfig.isEnableExpirationDate">
                          <th>
                            <ng-container *ngIf="viewModel.titleExDateSearch ; else ExDateSearchTemp"> <strong>{{viewModel.titleExDateSearch}}</strong></ng-container>
                            <ng-template #ExDateSearchTemp><strong>{{isSslSearch ? "終了日":"登録期限日"}}</strong></ng-template>
                          </th>
                            <td>
                              <ng-container *ngIf="!viewModel.isDisableExDateFrom">
                                <div pikaday class="form-TextWithIcon">
                                  <input type="text" class="form-Text is-Small" [class.form-Error]="(expirationDateFrom.errors && (expirationDateFrom.dirty || expirationDateFrom.touched))"
                                         name="expirationDateFrom" placeholder="指定なし" [(ngModel)]="viewModel.searchCondition.expirationDateFrom" (change)="viewModel.onChangeExpirationDateFrom($event)"
                                         #expirationDateFrom="ngModel" date [disabled]="viewModel.isDisableExpirationDate">
                                  <button type="button" class="form-TextWithIcon-Btn js-datepicker" [disabled]="viewModel.isDisableExpirationDate"><i class="icon icon-Calendar" aria-label="カレンダー"></i></button>
                                </div> から
                                <label *ngIf="expirationDateFrom.errors != null && expirationDateFrom.errors.date" class="form-Error">
                                  入力形式を確認してください
                                </label>
                              </ng-container>
                              <ng-container *ngIf="!viewModel.isDisableExDateTo">
                                <div pikaday class="form-TextWithIcon">
                                  <input type="text" class="form-Text is-Small" [class.form-Error]="(expirationDateTo.errors && (expirationDateTo.dirty || expirationDateTo.touched))"
                                         name="expirationDateTo" placeholder="指定なし" [(ngModel)]="viewModel.searchCondition.expirationDateTo" (change)="viewModel.onChangeExpirationDateTo($event)"
                                         #expirationDateTo="ngModel" date [disabled]="viewModel.isDisableExpirationDate">
                                  <button type="button" class="form-TextWithIcon-Btn js-datepicker" [disabled]="viewModel.isDisableExpirationDate"><i class="icon icon-Calendar" aria-label="カレンダー"></i></button>
                                </div> まで
                                <label *ngIf="expirationDateTo.errors != null && expirationDateTo.errors.date" class="form-Error">
                                  入力形式を確認してください
                                </label>
                              </ng-container>
                            </td>
                        </tr>
                        <tr *ngIf="viewModel.searchConditionConfig.isEnableRequestDate">
                            <th><strong>申請日</strong></th>
                            <td>
                                <div pikaday class="form-TextWithIcon">
                                    <input type="text" class="form-Text is-Small gtmclick" data-gtmvalue="s_input_request_date_from" [class.form-Error]="(requestDateFrom.errors && (requestDateFrom.dirty || requestDateFrom.touched))"
                                           name="requestDateFrom" placeholder="指定なし" [(ngModel)]="viewModel.searchCondition.requestDateFrom" (change)="viewModel.onChangeRequestDateFrom($event)"
                                           #requestDateFrom="ngModel" date >
                                    <button type="button" class="form-TextWithIcon-Btn js-datepicker" ><i class="icon icon-Calendar" aria-label="カレンダー"></i></button>
                                </div> から
                                <div pikaday class="form-TextWithIcon">
                                    <input type="text" class="form-Text is-Small gtmclick" data-gtmvalue="s_input_request_date_to" [class.form-Error]="(requestDateTo.errors && (requestDateTo.dirty || requestDateTo.touched))"
                                           name="requestDateTo" placeholder="指定なし" [(ngModel)]="viewModel.searchCondition.requestDateTo" (change)="viewModel.onChangeRequestDateTo($event)"
                                           #requestDateTo="ngModel" date >
                                    <button type="button" class="form-TextWithIcon-Btn js-datepicker"><i class="icon icon-Calendar" aria-label="カレンダー" ></i></button>
                                </div> まで
                                <label *ngIf="requestDateFrom.errors != null && requestDateFrom.errors.date" class="form-Error">
                                    入力形式を確認してください
                                </label>
                                <label *ngIf="requestDateTo.errors != null && requestDateTo.errors.date" class="form-Error">
                                    入力形式を確認してください
                                </label>
                            </td>
                        </tr>
                        <tr *ngIf="viewModel.searchConditionConfig.isEnableNewOptionSearch && isOwnedMoreThanFiveDomain">
                            <th><strong>期限日30日以内</strong></th>
                            <td>
                                <label>
                                    <input type="checkbox" name="checkbox1" [(ngModel)]="viewModel.searchCondition.isDeadlineWithin30Day" [checked]="viewModel.searchCondition.isDeadlineWithin30Day" (change)="viewModel.onChangeCheckedDeadlineWithin30Day()">
                                    <span class="form-Checkbox mr8"></span>
                                </label>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="dropdown-Footer text-Center">
                <ul class="list-Btn is-Right mb0">
                    <li><button type="button" class="btn is-Auto is-Small gtmclick" data-gtmvalue="s_clear" (click)="viewModel.onClickSearchConditionClear()">クリア</button></li>
                    <li><button type="submit" class="btn is-Primary is-Auto is-Small gtmclick" [attr.data-gtmvalue]="'searchbox' 
                                + (viewModel.domainNameGtm === ''? '' : '_' + viewModel.domainNameGtm)
                                + (viewModel.groupNameGtm === ''? '' : '_' + viewModel.groupNameGtm)
                                + (viewModel.whoIsGtm === ''? '' : '_' + viewModel.whoIsGtm)
                                + (viewModel.createDateGtm === ''? '' : '_' + viewModel.createDateGtm)
                                + (viewModel.expDateGtm === ''? '' : '_' + viewModel.expDateGtm)
                                + (viewModel.domainNameGtm === '' && viewModel.groupNameGtm === '' && viewModel.whoIsGtm === '' && viewModel.createDateGtm === '' && viewModel.expDateGtm === '' ? '_btn':'')" id="search">上記の条件で検索</button></li>
                </ul>
            </div>
        </div>
    </form>
</ng-container>
