


















/// <reference path="../definitions/jquery.signalr.d.ts" />
/// <reference path="../definitions/jquery.d.ts" />

// import { QuickHubManager } from 'Content/script/managers/QuickHubManager.js';
// import { QuickRsRegistHubManager } from 'Content/script/managers/QuickRsRegistHubManager.js';
// import { DomainParkingHubManager } from 'Content/script/managers/DomainParkingHubManager.js';
// import { SellDomainHubManager } from 'Content/script/managers/SellDomainHubManager.js';
// import { DomainDeleteHubManager } from 'Content/script/managers/DomainDeleteHubManager.js';
// import { DomainDelServiceHubManager } from 'Content/script/managers/DomainDelServiceHubManager.js';
// import { DomainDsRecordHubManager } from 'Content/script/managers/DomainDsRecordHubManager.js';
// import { DomainMonitoringHubManager } from 'Content/script/managers/DomainMonitoringHubManager.js';
// import { DomainProtectHubManager } from 'Content/script/managers/DomainProtectHubManager.js';
// import { ItemHubManager } from 'Content/script/managers/ItemHubManager.js';
// import { SpecialCancellationHubManager } from 'Content/script/managers/SpecialCancellationHubManager.js';
// import { DomainRefundHubManager } from 'Content/script/managers/DomainRefundHubManager.js';
// import { LineHubManager } from 'Content/script/managers/LineHubManager.js';
// import { PromotionHubManager } from 'Content/script/managers/PromotionHubManager.js';
// import { CampaignHubManager } from 'Content/script/managers/CampaignHubManager.js';
// import { SecondhandDealerLicenseHubManager } from 'Content/script/managers/SecondhandDealerLicenseHubManager.js';
// import { DomainRenewHubManager } from 'Content/script/managers/DomainRenewHubManager.js';
// import { DomainWhoisHubManager } from 'Content/script/managers/DomainWhoisHubManager.js';
// import { QuestionnaireHubManager } from 'Content/script/managers/QuestionnaireHubManager.js';
// import { AccountHubManager } from 'Content/script/managers/AccountHubManager.js';
// import { GsHubManager } from 'Content/script/managers/GsHubManager.js';
// import { NavigationHubManager } from 'Content/script/managers/NavigationHubManager.js';
// import { DnsHubManager } from 'Content/script/managers/DnsHubManager.js';
// import { SslHubManager } from 'Content/script/managers/SslHubManager.js';
// import { SdWeeblyHubManager } from 'Content/script/managers/SdWeeblyHubManager.js';
// import { SystemLinkageHubManager } from 'Content/script/managers/SystemLinkageHubManager.js';
// import { InvoiceHubManager } from 'Content/script/managers/InvoiceHubManager.js';
// import { DesktopCloudHubManager } from 'Content/script/managers/DesktopCloudHubManager.js';
// import { TmchHubManager } from 'Content/script/managers/TmchHubManager.js';
// import { WebGakuenHubManager } from 'Content/script/managers/WebGakuenHubManager.js';
// import { DesktopCloudOptionHubManager } from 'Content/script/managers/DesktopCloudOptionHubManager.js';
// import { WpTemplateHubManager } from 'Content/script/managers/WpTemplateHubManager.js';
// import { SiteCreationHubManager } from 'Content/script/managers/SiteCreationHubManager.js';
// import { SslCouponHubManager } from 'Content/script/managers/SslCouponHubManager.js';
// import { TopHubManager } from 'Content/script/managers/TopHubManager.js';
// import { WpCloudHubManager } from 'Content/script/managers/WpCloudHubManager.js';
// import { MailMarketingHubManager } from 'Content/script/managers/MailMarketingHubManager.js';
// import { MailHubManager } from 'Content/script/managers/MailHubManager.js';
// import { DomainHubManager } from 'Content/script/managers/DomainHubManager.js';
// import { LoggerHubManager } from 'Content/script/managers/LoggerHubManager.js';
// import { AuthHubManager } from 'Content/script/managers/AuthHubManager.js';
// import { VpsHubManager } from 'Content/script/managers/VpsHubManager.js';
// import { Office365HubManager } from 'Content/script/managers/Office365HubManager.js';
// import { SdHubManager } from 'Content/script/managers/SdHubManager.js';


import { HttpClient } from '@angular/common/http';
import { fireEventHubsManagerCallbackOK, fireEventHubsManagerCallbackError, fireEventHubsManagerCallbackNoSession, fireEventHubsManagerCallbackComplete, fireEventHubsManagerCallbackUnExpectedError } from 'Content/script/managers/HubsManagerCustomEvent';

////////////////////
// available HubsManager //
////////////////////
//#region available HubsManager

declare var checkToken: any;

export class HubsManager {

	private static _hubsManager: HubsManager;


	public quickHubManager: QuickHubManager;

	public quickRsRegistHubManager: QuickRsRegistHubManager;

	public domainParkingHubManager: DomainParkingHubManager;

	public sellDomainHubManager: SellDomainHubManager;

	public domainDeleteHubManager: DomainDeleteHubManager;

	public domainDelServiceHubManager: DomainDelServiceHubManager;

	public domainDsRecordHubManager: DomainDsRecordHubManager;

	public domainMonitoringHubManager: DomainMonitoringHubManager;

	public domainProtectHubManager: DomainProtectHubManager;

	public itemHubManager: ItemHubManager;

	public specialCancellationHubManager: SpecialCancellationHubManager;

	public domainRefundHubManager: DomainRefundHubManager;

	public lineHubManager: LineHubManager;

	public promotionHubManager: PromotionHubManager;

	public campaignHubManager: CampaignHubManager;

	public secondhandDealerLicenseHubManager: SecondhandDealerLicenseHubManager;

	public domainRenewHubManager: DomainRenewHubManager;

	public domainWhoisHubManager: DomainWhoisHubManager;

	public questionnaireHubManager: QuestionnaireHubManager;

	public accountHubManager: AccountHubManager;

	public gsHubManager: GsHubManager;

	public navigationHubManager: NavigationHubManager;

	public dnsHubManager: DnsHubManager;

	public sslHubManager: SslHubManager;

	public sdWeeblyHubManager: SdWeeblyHubManager;

	public systemLinkageHubManager: SystemLinkageHubManager;

	public invoiceHubManager: InvoiceHubManager;

	public desktopCloudHubManager: DesktopCloudHubManager;

	public tmchHubManager: TmchHubManager;

	public webGakuenHubManager: WebGakuenHubManager;

	public desktopCloudOptionHubManager: DesktopCloudOptionHubManager;

	public wpTemplateHubManager: WpTemplateHubManager;

	public siteCreationHubManager: SiteCreationHubManager;

	public sslCouponHubManager: SslCouponHubManager;

	public topHubManager: TopHubManager;

	public wpCloudHubManager: WpCloudHubManager;

	public mailMarketingHubManager: MailMarketingHubManager;

	public mailHubManager: MailHubManager;

	public domainHubManager: DomainHubManager;

	public loggerHubManager: LoggerHubManager;

	public authHubManager: AuthHubManager;

	public vpsHubManager: VpsHubManager;

	public office365HubManager: Office365HubManager;

	public sdHubManager: SdHubManager;


    private constructor(http: HttpClient) {


		this.quickHubManager = new QuickHubManager(http);

		this.quickRsRegistHubManager = new QuickRsRegistHubManager(http);

		this.domainParkingHubManager = new DomainParkingHubManager(http);

		this.sellDomainHubManager = new SellDomainHubManager(http);

		this.domainDeleteHubManager = new DomainDeleteHubManager(http);

		this.domainDelServiceHubManager = new DomainDelServiceHubManager(http);

		this.domainDsRecordHubManager = new DomainDsRecordHubManager(http);

		this.domainMonitoringHubManager = new DomainMonitoringHubManager(http);

		this.domainProtectHubManager = new DomainProtectHubManager(http);

		this.itemHubManager = new ItemHubManager(http);

		this.specialCancellationHubManager = new SpecialCancellationHubManager(http);

		this.domainRefundHubManager = new DomainRefundHubManager(http);

		this.lineHubManager = new LineHubManager(http);

		this.promotionHubManager = new PromotionHubManager(http);

		this.campaignHubManager = new CampaignHubManager(http);

		this.secondhandDealerLicenseHubManager = new SecondhandDealerLicenseHubManager(http);

		this.domainRenewHubManager = new DomainRenewHubManager(http);

		this.domainWhoisHubManager = new DomainWhoisHubManager(http);

		this.questionnaireHubManager = new QuestionnaireHubManager(http);

		this.accountHubManager = new AccountHubManager(http);

		this.gsHubManager = new GsHubManager(http);

		this.navigationHubManager = new NavigationHubManager(http);

		this.dnsHubManager = new DnsHubManager(http);

		this.sslHubManager = new SslHubManager(http);

		this.sdWeeblyHubManager = new SdWeeblyHubManager(http);

		this.systemLinkageHubManager = new SystemLinkageHubManager(http);

		this.invoiceHubManager = new InvoiceHubManager(http);

		this.desktopCloudHubManager = new DesktopCloudHubManager(http);

		this.tmchHubManager = new TmchHubManager(http);

		this.webGakuenHubManager = new WebGakuenHubManager(http);

		this.desktopCloudOptionHubManager = new DesktopCloudOptionHubManager(http);

		this.wpTemplateHubManager = new WpTemplateHubManager(http);

		this.siteCreationHubManager = new SiteCreationHubManager(http);

		this.sslCouponHubManager = new SslCouponHubManager(http);

		this.topHubManager = new TopHubManager(http);

		this.wpCloudHubManager = new WpCloudHubManager(http);

		this.mailMarketingHubManager = new MailMarketingHubManager(http);

		this.mailHubManager = new MailHubManager(http);

		this.domainHubManager = new DomainHubManager(http);

		this.loggerHubManager = new LoggerHubManager(http);

		this.authHubManager = new AuthHubManager(http);

		this.vpsHubManager = new VpsHubManager(http);

		this.office365HubManager = new Office365HubManager(http);

		this.sdHubManager = new SdHubManager(http);

    }



	// シングルトンオブジェクトを作成
    public static CreateInstance(http: HttpClient): HubsManager
    {
        if (HubsManager._hubsManager == void (0) || HubsManager._hubsManager == null) {
            HubsManager._hubsManager = new HubsManager(http);
        }

        return HubsManager._hubsManager;
    }

    // シングルトンオブジェクトを取得
    public static GetInstance(): HubsManager {
        if (HubsManager._hubsManager == void (0) || HubsManager._hubsManager == null) {
            return null;
        }

        return HubsManager._hubsManager;
    }
}




export class QuickHubManager {
    /**
     * コンストラクタ
     */
    constructor(private http: HttpClient) {
    }

	// リソース破棄
    public Dispose = (): void => {
    }



    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public QuickOnetimePasswordValidator = (requestData: OnetimePasswordValidatorRequestHubDto, receiveCallBack: (data: OnetimePasswordValidatorResponseHubDto) => void, receiveErrorCallBack: (data: OnetimePasswordValidatorResponseHubDto) => void, noSessionCallBack: (data: OnetimePasswordValidatorResponseHubDto) => void, completeCallBack: (data: OnetimePasswordValidatorResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: OnetimePasswordValidatorResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: OnetimePasswordValidatorResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/QuickHub/QuickOnetimePasswordValidator';

        let responseData: OnetimePasswordValidatorResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('QuickHub', 'QuickOnetimePasswordValidator', 'OnetimePasswordValidatorRequestHubDto', 'OnetimePasswordValidatorResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('QuickHub', 'QuickOnetimePasswordValidator', 'OnetimePasswordValidatorRequestHubDto', 'OnetimePasswordValidatorResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('QuickHub', 'QuickOnetimePasswordValidator', 'OnetimePasswordValidatorRequestHubDto', 'OnetimePasswordValidatorResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public QuickSendMfaToken = (requestData: QuickAuthSendLoginMfaTokenRequestHubDto, receiveCallBack: (data: AuthSendLoginMfaTokenResponseHubDto) => void, receiveErrorCallBack: (data: AuthSendLoginMfaTokenResponseHubDto) => void, noSessionCallBack: (data: AuthSendLoginMfaTokenResponseHubDto) => void, completeCallBack: (data: AuthSendLoginMfaTokenResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: AuthSendLoginMfaTokenResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: AuthSendLoginMfaTokenResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/QuickHub/QuickSendMfaToken';

        let responseData: AuthSendLoginMfaTokenResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('QuickHub', 'QuickSendMfaToken', 'QuickAuthSendLoginMfaTokenRequestHubDto', 'AuthSendLoginMfaTokenResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('QuickHub', 'QuickSendMfaToken', 'QuickAuthSendLoginMfaTokenRequestHubDto', 'AuthSendLoginMfaTokenResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('QuickHub', 'QuickSendMfaToken', 'QuickAuthSendLoginMfaTokenRequestHubDto', 'AuthSendLoginMfaTokenResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public QuickVerifyMfaToken = (requestData: AuthVerifyLoginMfaTokenRequestHubDto, receiveCallBack: (data: QuickAuthVerifyMfaTokenResponseHubDto) => void, receiveErrorCallBack: (data: QuickAuthVerifyMfaTokenResponseHubDto) => void, noSessionCallBack: (data: QuickAuthVerifyMfaTokenResponseHubDto) => void, completeCallBack: (data: QuickAuthVerifyMfaTokenResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: QuickAuthVerifyMfaTokenResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: QuickAuthVerifyMfaTokenResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/QuickHub/QuickVerifyMfaToken';

        let responseData: QuickAuthVerifyMfaTokenResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('QuickHub', 'QuickVerifyMfaToken', 'AuthVerifyLoginMfaTokenRequestHubDto', 'QuickAuthVerifyMfaTokenResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('QuickHub', 'QuickVerifyMfaToken', 'AuthVerifyLoginMfaTokenRequestHubDto', 'QuickAuthVerifyMfaTokenResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('QuickHub', 'QuickVerifyMfaToken', 'AuthVerifyLoginMfaTokenRequestHubDto', 'QuickAuthVerifyMfaTokenResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public QuickInvoiceDomainGetCreditCard = (receiveCallBack: (data: InvoiceDomainGetCreditCardResponseHubDto) => void, receiveErrorCallBack: (data: InvoiceDomainGetCreditCardResponseHubDto) => void, noSessionCallBack: (data: InvoiceDomainGetCreditCardResponseHubDto) => void, completeCallBack: (data: InvoiceDomainGetCreditCardResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: InvoiceDomainGetCreditCardResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: InvoiceDomainGetCreditCardResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/QuickHub/QuickInvoiceDomainGetCreditCard';

        let responseData: InvoiceDomainGetCreditCardResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('QuickHub', 'QuickInvoiceDomainGetCreditCard', null, 'InvoiceDomainGetCreditCardResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('QuickHub', 'QuickInvoiceDomainGetCreditCard', null, 'InvoiceDomainGetCreditCardResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('QuickHub', 'QuickInvoiceDomainGetCreditCard', null, 'InvoiceDomainGetCreditCardResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public QuickInvoiceDomainPaymentUpdateCreditCard = (requestData: InvoiceDomainPaymentUpdateCreditCardRequestHubDto, receiveCallBack: (data: InvoiceDomainPaymentUpdateCreditCardResponseHubDto) => void, receiveErrorCallBack: (data: InvoiceDomainPaymentUpdateCreditCardResponseHubDto) => void, noSessionCallBack: (data: InvoiceDomainPaymentUpdateCreditCardResponseHubDto) => void, completeCallBack: (data: InvoiceDomainPaymentUpdateCreditCardResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: InvoiceDomainPaymentUpdateCreditCardResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: InvoiceDomainPaymentUpdateCreditCardResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/QuickHub/QuickInvoiceDomainPaymentUpdateCreditCard';

        let responseData: InvoiceDomainPaymentUpdateCreditCardResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('QuickHub', 'QuickInvoiceDomainPaymentUpdateCreditCard', 'InvoiceDomainPaymentUpdateCreditCardRequestHubDto', 'InvoiceDomainPaymentUpdateCreditCardResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('QuickHub', 'QuickInvoiceDomainPaymentUpdateCreditCard', 'InvoiceDomainPaymentUpdateCreditCardRequestHubDto', 'InvoiceDomainPaymentUpdateCreditCardResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('QuickHub', 'QuickInvoiceDomainPaymentUpdateCreditCard', 'InvoiceDomainPaymentUpdateCreditCardRequestHubDto', 'InvoiceDomainPaymentUpdateCreditCardResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetDomainPaymentInfo = (receiveCallBack: (data: InvoiceGetDomainPaymentInfoResponseHubDto) => void, receiveErrorCallBack: (data: InvoiceGetDomainPaymentInfoResponseHubDto) => void, noSessionCallBack: (data: InvoiceGetDomainPaymentInfoResponseHubDto) => void, completeCallBack: (data: InvoiceGetDomainPaymentInfoResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: InvoiceGetDomainPaymentInfoResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: InvoiceGetDomainPaymentInfoResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/QuickHub/GetDomainPaymentInfo';

        let responseData: InvoiceGetDomainPaymentInfoResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('QuickHub', 'GetDomainPaymentInfo', null, 'InvoiceGetDomainPaymentInfoResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('QuickHub', 'GetDomainPaymentInfo', null, 'InvoiceGetDomainPaymentInfoResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('QuickHub', 'GetDomainPaymentInfo', null, 'InvoiceGetDomainPaymentInfoResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public DomainPaymentValidator = (requestData: DomainPaymnetValidatorRequestHubDto, receiveCallBack: (data: DomainPaymnetValidatorResponseHubDto) => void, receiveErrorCallBack: (data: DomainPaymnetValidatorResponseHubDto) => void, noSessionCallBack: (data: DomainPaymnetValidatorResponseHubDto) => void, completeCallBack: (data: DomainPaymnetValidatorResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainPaymnetValidatorResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainPaymnetValidatorResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/QuickHub/DomainPaymentValidator';

        let responseData: DomainPaymnetValidatorResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('QuickHub', 'DomainPaymentValidator', 'DomainPaymnetValidatorRequestHubDto', 'DomainPaymnetValidatorResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('QuickHub', 'DomainPaymentValidator', 'DomainPaymnetValidatorRequestHubDto', 'DomainPaymnetValidatorResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('QuickHub', 'DomainPaymentValidator', 'DomainPaymnetValidatorRequestHubDto', 'DomainPaymnetValidatorResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public QuickDomainName = (requestData: DomainNameRequestHubDto, receiveCallBack: (data: DomainNameResponseHubDto) => void, receiveErrorCallBack: (data: DomainNameResponseHubDto) => void, noSessionCallBack: (data: DomainNameResponseHubDto) => void, completeCallBack: (data: DomainNameResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainNameResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainNameResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/QuickHub/QuickDomainName';

        let responseData: DomainNameResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('QuickHub', 'QuickDomainName', 'DomainNameRequestHubDto', 'DomainNameResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('QuickHub', 'QuickDomainName', 'DomainNameRequestHubDto', 'DomainNameResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('QuickHub', 'QuickDomainName', 'DomainNameRequestHubDto', 'DomainNameResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public QuickDomainProtect = (requestData: QuickOptionDomainRequestHubDto, receiveCallBack: (data: QuickOptionWhoisProtectResponseHubDto) => void, receiveErrorCallBack: (data: QuickOptionWhoisProtectResponseHubDto) => void, noSessionCallBack: (data: QuickOptionWhoisProtectResponseHubDto) => void, completeCallBack: (data: QuickOptionWhoisProtectResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: QuickOptionWhoisProtectResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: QuickOptionWhoisProtectResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/QuickHub/QuickDomainProtect';

        let responseData: QuickOptionWhoisProtectResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('QuickHub', 'QuickDomainProtect', 'QuickOptionDomainRequestHubDto', 'QuickOptionWhoisProtectResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('QuickHub', 'QuickDomainProtect', 'QuickOptionDomainRequestHubDto', 'QuickOptionWhoisProtectResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('QuickHub', 'QuickDomainProtect', 'QuickOptionDomainRequestHubDto', 'QuickOptionWhoisProtectResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public QuickDomainProtectPrice = (requestData: QuickOptionDomainRequestHubDto, receiveCallBack: (data: QuickProtectPriceResponseHubDto) => void, receiveErrorCallBack: (data: QuickProtectPriceResponseHubDto) => void, noSessionCallBack: (data: QuickProtectPriceResponseHubDto) => void, completeCallBack: (data: QuickProtectPriceResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: QuickProtectPriceResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: QuickProtectPriceResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/QuickHub/QuickDomainProtectPrice';

        let responseData: QuickProtectPriceResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('QuickHub', 'QuickDomainProtectPrice', 'QuickOptionDomainRequestHubDto', 'QuickProtectPriceResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('QuickHub', 'QuickDomainProtectPrice', 'QuickOptionDomainRequestHubDto', 'QuickProtectPriceResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('QuickHub', 'QuickDomainProtectPrice', 'QuickOptionDomainRequestHubDto', 'QuickProtectPriceResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public QuickWhoisProtectList = (requestData: QuickOptionDomainRequestHubDto, receiveCallBack: (data: QuickOptionWhoisProtectResponseHubDto) => void, receiveErrorCallBack: (data: QuickOptionWhoisProtectResponseHubDto) => void, noSessionCallBack: (data: QuickOptionWhoisProtectResponseHubDto) => void, completeCallBack: (data: QuickOptionWhoisProtectResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: QuickOptionWhoisProtectResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: QuickOptionWhoisProtectResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/QuickHub/QuickWhoisProtectList';

        let responseData: QuickOptionWhoisProtectResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('QuickHub', 'QuickWhoisProtectList', 'QuickOptionDomainRequestHubDto', 'QuickOptionWhoisProtectResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('QuickHub', 'QuickWhoisProtectList', 'QuickOptionDomainRequestHubDto', 'QuickOptionWhoisProtectResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('QuickHub', 'QuickWhoisProtectList', 'QuickOptionDomainRequestHubDto', 'QuickOptionWhoisProtectResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public QuickDomainWhoisProtectPrice = (requestData: QuickWhoisPirceRequestHubDto, receiveCallBack: (data: QuickWhoisPriceResponseHubDto) => void, receiveErrorCallBack: (data: QuickWhoisPriceResponseHubDto) => void, noSessionCallBack: (data: QuickWhoisPriceResponseHubDto) => void, completeCallBack: (data: QuickWhoisPriceResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: QuickWhoisPriceResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: QuickWhoisPriceResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/QuickHub/QuickDomainWhoisProtectPrice';

        let responseData: QuickWhoisPriceResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('QuickHub', 'QuickDomainWhoisProtectPrice', 'QuickWhoisPirceRequestHubDto', 'QuickWhoisPriceResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('QuickHub', 'QuickDomainWhoisProtectPrice', 'QuickWhoisPirceRequestHubDto', 'QuickWhoisPriceResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('QuickHub', 'QuickDomainWhoisProtectPrice', 'QuickWhoisPirceRequestHubDto', 'QuickWhoisPriceResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public QuickValidateWhoisProtect = (requestData: QuickWhoisProtectHubDto, receiveCallBack: (data: BaseResponseHubDto) => void, receiveErrorCallBack: (data: BaseResponseHubDto) => void, noSessionCallBack: (data: BaseResponseHubDto) => void, completeCallBack: (data: BaseResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: BaseResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: BaseResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/QuickHub/QuickValidateWhoisProtect';

        let responseData: BaseResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('QuickHub', 'QuickValidateWhoisProtect', 'QuickWhoisProtectHubDto', 'BaseResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('QuickHub', 'QuickValidateWhoisProtect', 'QuickWhoisProtectHubDto', 'BaseResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('QuickHub', 'QuickValidateWhoisProtect', 'QuickWhoisProtectHubDto', 'BaseResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public QuickBulkWhois = (requestData: QuickWhoisProtectHubDto, receiveCallBack: (data: BulkWhoisResponseHubDto) => void, receiveErrorCallBack: (data: BulkWhoisResponseHubDto) => void, noSessionCallBack: (data: BulkWhoisResponseHubDto) => void, completeCallBack: (data: BulkWhoisResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: BulkWhoisResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: BulkWhoisResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/QuickHub/QuickBulkWhois';

        let responseData: BulkWhoisResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('QuickHub', 'QuickBulkWhois', 'QuickWhoisProtectHubDto', 'BulkWhoisResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('QuickHub', 'QuickBulkWhois', 'QuickWhoisProtectHubDto', 'BulkWhoisResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('QuickHub', 'QuickBulkWhois', 'QuickWhoisProtectHubDto', 'BulkWhoisResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public QuickOnetimePasswordDomainList = (receiveCallBack: (data: QuickOnetimePasswordDomainResponseHubDto) => void, receiveErrorCallBack: (data: QuickOnetimePasswordDomainResponseHubDto) => void, noSessionCallBack: (data: QuickOnetimePasswordDomainResponseHubDto) => void, completeCallBack: (data: QuickOnetimePasswordDomainResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: QuickOnetimePasswordDomainResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: QuickOnetimePasswordDomainResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/QuickHub/QuickOnetimePasswordDomainList';

        let responseData: QuickOnetimePasswordDomainResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('QuickHub', 'QuickOnetimePasswordDomainList', null, 'QuickOnetimePasswordDomainResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('QuickHub', 'QuickOnetimePasswordDomainList', null, 'QuickOnetimePasswordDomainResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('QuickHub', 'QuickOnetimePasswordDomainList', null, 'QuickOnetimePasswordDomainResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public QuickWhoisMonitoringTextList = (requestData: QuickMonitoringTextListRequestHubDto, receiveCallBack: (data: MonitoringTextListResponseHubDto) => void, receiveErrorCallBack: (data: MonitoringTextListResponseHubDto) => void, noSessionCallBack: (data: MonitoringTextListResponseHubDto) => void, completeCallBack: (data: MonitoringTextListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: MonitoringTextListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: MonitoringTextListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/QuickHub/QuickWhoisMonitoringTextList';

        let responseData: MonitoringTextListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('QuickHub', 'QuickWhoisMonitoringTextList', 'QuickMonitoringTextListRequestHubDto', 'MonitoringTextListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('QuickHub', 'QuickWhoisMonitoringTextList', 'QuickMonitoringTextListRequestHubDto', 'MonitoringTextListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('QuickHub', 'QuickWhoisMonitoringTextList', 'QuickMonitoringTextListRequestHubDto', 'MonitoringTextListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public QuickPayBankInfo = (requestData: QuickBankInfoRequestHubDto, receiveCallBack: (data: QuickBankInfoResponseHubDto) => void, receiveErrorCallBack: (data: QuickBankInfoResponseHubDto) => void, noSessionCallBack: (data: QuickBankInfoResponseHubDto) => void, completeCallBack: (data: QuickBankInfoResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: QuickBankInfoResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: QuickBankInfoResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/QuickHub/QuickPayBankInfo';

        let responseData: QuickBankInfoResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('QuickHub', 'QuickPayBankInfo', 'QuickBankInfoRequestHubDto', 'QuickBankInfoResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('QuickHub', 'QuickPayBankInfo', 'QuickBankInfoRequestHubDto', 'QuickBankInfoResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('QuickHub', 'QuickPayBankInfo', 'QuickBankInfoRequestHubDto', 'QuickBankInfoResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetQuickRedirectUrl = (requestData: NavigationRequestHubDto, receiveCallBack: (data: QuickUrlDirectResponseHubDto) => void, receiveErrorCallBack: (data: QuickUrlDirectResponseHubDto) => void, noSessionCallBack: (data: QuickUrlDirectResponseHubDto) => void, completeCallBack: (data: QuickUrlDirectResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: QuickUrlDirectResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: QuickUrlDirectResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/QuickHub/GetQuickRedirectUrl';

        let responseData: QuickUrlDirectResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('QuickHub', 'GetQuickRedirectUrl', 'NavigationRequestHubDto', 'QuickUrlDirectResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('QuickHub', 'GetQuickRedirectUrl', 'NavigationRequestHubDto', 'QuickUrlDirectResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('QuickHub', 'GetQuickRedirectUrl', 'NavigationRequestHubDto', 'QuickUrlDirectResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public QuickGetPremiumDnsDomain = (receiveCallBack: (data: QuickGetPremiumDnsDomainResponseHubDto) => void, receiveErrorCallBack: (data: QuickGetPremiumDnsDomainResponseHubDto) => void, noSessionCallBack: (data: QuickGetPremiumDnsDomainResponseHubDto) => void, completeCallBack: (data: QuickGetPremiumDnsDomainResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: QuickGetPremiumDnsDomainResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: QuickGetPremiumDnsDomainResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/QuickHub/QuickGetPremiumDnsDomain';

        let responseData: QuickGetPremiumDnsDomainResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('QuickHub', 'QuickGetPremiumDnsDomain', null, 'QuickGetPremiumDnsDomainResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('QuickHub', 'QuickGetPremiumDnsDomain', null, 'QuickGetPremiumDnsDomainResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('QuickHub', 'QuickGetPremiumDnsDomain', null, 'QuickGetPremiumDnsDomainResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public QuickRegisterPremiumDns = (requestData: QuickRegisterPremiumDnsRequestHubDto, receiveCallBack: (data: QuickRegisterPremiumDnsResponseHubDto) => void, receiveErrorCallBack: (data: QuickRegisterPremiumDnsResponseHubDto) => void, noSessionCallBack: (data: QuickRegisterPremiumDnsResponseHubDto) => void, completeCallBack: (data: QuickRegisterPremiumDnsResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: QuickRegisterPremiumDnsResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: QuickRegisterPremiumDnsResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/QuickHub/QuickRegisterPremiumDns';

        let responseData: QuickRegisterPremiumDnsResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('QuickHub', 'QuickRegisterPremiumDns', 'QuickRegisterPremiumDnsRequestHubDto', 'QuickRegisterPremiumDnsResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('QuickHub', 'QuickRegisterPremiumDns', 'QuickRegisterPremiumDnsRequestHubDto', 'QuickRegisterPremiumDnsResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('QuickHub', 'QuickRegisterPremiumDns', 'QuickRegisterPremiumDnsRequestHubDto', 'QuickRegisterPremiumDnsResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public QuickMonitoringCreateValidator = (requestData: QuickMonitoringCreateValidatorRequestHubDto, receiveCallBack: (data: MonitoringCreateValidatorReponseHubDto) => void, receiveErrorCallBack: (data: MonitoringCreateValidatorReponseHubDto) => void, noSessionCallBack: (data: MonitoringCreateValidatorReponseHubDto) => void, completeCallBack: (data: MonitoringCreateValidatorReponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: MonitoringCreateValidatorReponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: MonitoringCreateValidatorReponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/QuickHub/QuickMonitoringCreateValidator';

        let responseData: MonitoringCreateValidatorReponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('QuickHub', 'QuickMonitoringCreateValidator', 'QuickMonitoringCreateValidatorRequestHubDto', 'MonitoringCreateValidatorReponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('QuickHub', 'QuickMonitoringCreateValidator', 'QuickMonitoringCreateValidatorRequestHubDto', 'MonitoringCreateValidatorReponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('QuickHub', 'QuickMonitoringCreateValidator', 'QuickMonitoringCreateValidatorRequestHubDto', 'MonitoringCreateValidatorReponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public QuickMonitoringCreate = (requestData: MonitoringCreateRequestHubDto, receiveCallBack: (data: MonitoringCreateReponseHubDto) => void, receiveErrorCallBack: (data: MonitoringCreateReponseHubDto) => void, noSessionCallBack: (data: MonitoringCreateReponseHubDto) => void, completeCallBack: (data: MonitoringCreateReponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: MonitoringCreateReponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: MonitoringCreateReponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/QuickHub/QuickMonitoringCreate';

        let responseData: MonitoringCreateReponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('QuickHub', 'QuickMonitoringCreate', 'MonitoringCreateRequestHubDto', 'MonitoringCreateReponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('QuickHub', 'QuickMonitoringCreate', 'MonitoringCreateRequestHubDto', 'MonitoringCreateReponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('QuickHub', 'QuickMonitoringCreate', 'MonitoringCreateRequestHubDto', 'MonitoringCreateReponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public QuickGetMonitoringConfirmInfo = (requestData: GetMonitoringConfirmInfoRequestHubDto, receiveCallBack: (data: GetMonitoringConfirmInfoResponseHubDto) => void, receiveErrorCallBack: (data: GetMonitoringConfirmInfoResponseHubDto) => void, noSessionCallBack: (data: GetMonitoringConfirmInfoResponseHubDto) => void, completeCallBack: (data: GetMonitoringConfirmInfoResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: GetMonitoringConfirmInfoResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: GetMonitoringConfirmInfoResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/QuickHub/QuickGetMonitoringConfirmInfo';

        let responseData: GetMonitoringConfirmInfoResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('QuickHub', 'QuickGetMonitoringConfirmInfo', 'GetMonitoringConfirmInfoRequestHubDto', 'GetMonitoringConfirmInfoResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('QuickHub', 'QuickGetMonitoringConfirmInfo', 'GetMonitoringConfirmInfoRequestHubDto', 'GetMonitoringConfirmInfoResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('QuickHub', 'QuickGetMonitoringConfirmInfo', 'GetMonitoringConfirmInfoRequestHubDto', 'GetMonitoringConfirmInfoResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public QuickNewCreditCardValidator = (requestData: NewCreditCardValidatorRequestHubDto, receiveCallBack: (data: DomainPaymnetValidatorResponseHubDto) => void, receiveErrorCallBack: (data: DomainPaymnetValidatorResponseHubDto) => void, noSessionCallBack: (data: DomainPaymnetValidatorResponseHubDto) => void, completeCallBack: (data: DomainPaymnetValidatorResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainPaymnetValidatorResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainPaymnetValidatorResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/QuickHub/QuickNewCreditCardValidator';

        let responseData: DomainPaymnetValidatorResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('QuickHub', 'QuickNewCreditCardValidator', 'NewCreditCardValidatorRequestHubDto', 'DomainPaymnetValidatorResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('QuickHub', 'QuickNewCreditCardValidator', 'NewCreditCardValidatorRequestHubDto', 'DomainPaymnetValidatorResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('QuickHub', 'QuickNewCreditCardValidator', 'NewCreditCardValidatorRequestHubDto', 'DomainPaymnetValidatorResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public QuickInvoiceDomainUpdateCreditCard = (requestData: InvoiceDomainUpdateCreditCardRequestHubDto, receiveCallBack: (data: InvoiceDomainUpdateCreditCardResponseHubDto) => void, receiveErrorCallBack: (data: InvoiceDomainUpdateCreditCardResponseHubDto) => void, noSessionCallBack: (data: InvoiceDomainUpdateCreditCardResponseHubDto) => void, completeCallBack: (data: InvoiceDomainUpdateCreditCardResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: InvoiceDomainUpdateCreditCardResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: InvoiceDomainUpdateCreditCardResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/QuickHub/QuickInvoiceDomainUpdateCreditCard';

        let responseData: InvoiceDomainUpdateCreditCardResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('QuickHub', 'QuickInvoiceDomainUpdateCreditCard', 'InvoiceDomainUpdateCreditCardRequestHubDto', 'InvoiceDomainUpdateCreditCardResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('QuickHub', 'QuickInvoiceDomainUpdateCreditCard', 'InvoiceDomainUpdateCreditCardRequestHubDto', 'InvoiceDomainUpdateCreditCardResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('QuickHub', 'QuickInvoiceDomainUpdateCreditCard', 'InvoiceDomainUpdateCreditCardRequestHubDto', 'InvoiceDomainUpdateCreditCardResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }

}



export class QuickRsRegistHubManager {
    /**
     * コンストラクタ
     */
    constructor(private http: HttpClient) {
    }

	// リソース破棄
    public Dispose = (): void => {
    }



    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public QuickRsregistList = (requestData: QuickRsregistListRequestHubDto, receiveCallBack: (data: DomainQuickRsRegistResponseHubDto) => void, receiveErrorCallBack: (data: DomainQuickRsRegistResponseHubDto) => void, noSessionCallBack: (data: DomainQuickRsRegistResponseHubDto) => void, completeCallBack: (data: DomainQuickRsRegistResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainQuickRsRegistResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainQuickRsRegistResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/QuickRsRegistHub/QuickRsregistList';

        let responseData: DomainQuickRsRegistResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('QuickRsRegistHub', 'QuickRsregistList', 'QuickRsregistListRequestHubDto', 'DomainQuickRsRegistResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('QuickRsRegistHub', 'QuickRsregistList', 'QuickRsregistListRequestHubDto', 'DomainQuickRsRegistResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('QuickRsRegistHub', 'QuickRsregistList', 'QuickRsregistListRequestHubDto', 'DomainQuickRsRegistResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public QuickPaymentAmount = (requestData: QuickHostingPaymentAmountRequestHubDto, receiveCallBack: (data: QuickHostingPaymentAmountResponseHubDto) => void, receiveErrorCallBack: (data: QuickHostingPaymentAmountResponseHubDto) => void, noSessionCallBack: (data: QuickHostingPaymentAmountResponseHubDto) => void, completeCallBack: (data: QuickHostingPaymentAmountResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: QuickHostingPaymentAmountResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: QuickHostingPaymentAmountResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/QuickRsRegistHub/QuickPaymentAmount';

        let responseData: QuickHostingPaymentAmountResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('QuickRsRegistHub', 'QuickPaymentAmount', 'QuickHostingPaymentAmountRequestHubDto', 'QuickHostingPaymentAmountResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('QuickRsRegistHub', 'QuickPaymentAmount', 'QuickHostingPaymentAmountRequestHubDto', 'QuickHostingPaymentAmountResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('QuickRsRegistHub', 'QuickPaymentAmount', 'QuickHostingPaymentAmountRequestHubDto', 'QuickHostingPaymentAmountResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public QuickPaymentHosting = (requestData: HostingPaymentRequestHubDto, receiveCallBack: (data: HostingPaymentResponseHubDto) => void, receiveErrorCallBack: (data: HostingPaymentResponseHubDto) => void, noSessionCallBack: (data: HostingPaymentResponseHubDto) => void, completeCallBack: (data: HostingPaymentResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: HostingPaymentResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: HostingPaymentResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/QuickRsRegistHub/QuickPaymentHosting';

        let responseData: HostingPaymentResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('QuickRsRegistHub', 'QuickPaymentHosting', 'HostingPaymentRequestHubDto', 'HostingPaymentResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('QuickRsRegistHub', 'QuickPaymentHosting', 'HostingPaymentRequestHubDto', 'HostingPaymentResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('QuickRsRegistHub', 'QuickPaymentHosting', 'HostingPaymentRequestHubDto', 'HostingPaymentResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetHostingPaymentMethodList = (receiveCallBack: (data: HostingPaymentMethodResponseHubDto) => void, receiveErrorCallBack: (data: HostingPaymentMethodResponseHubDto) => void, noSessionCallBack: (data: HostingPaymentMethodResponseHubDto) => void, completeCallBack: (data: HostingPaymentMethodResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: HostingPaymentMethodResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: HostingPaymentMethodResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/QuickRsRegistHub/GetHostingPaymentMethodList';

        let responseData: HostingPaymentMethodResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('QuickRsRegistHub', 'GetHostingPaymentMethodList', null, 'HostingPaymentMethodResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('QuickRsRegistHub', 'GetHostingPaymentMethodList', null, 'HostingPaymentMethodResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('QuickRsRegistHub', 'GetHostingPaymentMethodList', null, 'HostingPaymentMethodResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetHostingCreditInfo = (receiveCallBack: (data: QuickHostingCreditCardInfoResponseDto) => void, receiveErrorCallBack: (data: QuickHostingCreditCardInfoResponseDto) => void, noSessionCallBack: (data: QuickHostingCreditCardInfoResponseDto) => void, completeCallBack: (data: QuickHostingCreditCardInfoResponseDto) => void = null, receiveUnExceptedErrorCallBack: (data: QuickHostingCreditCardInfoResponseDto) => void, reciveSubMaintenanceModeCallBack:(data: QuickHostingCreditCardInfoResponseDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/QuickRsRegistHub/GetHostingCreditInfo';

        let responseData: QuickHostingCreditCardInfoResponseDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('QuickRsRegistHub', 'GetHostingCreditInfo', null, 'QuickHostingCreditCardInfoResponseDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('QuickRsRegistHub', 'GetHostingCreditInfo', null, 'QuickHostingCreditCardInfoResponseDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('QuickRsRegistHub', 'GetHostingCreditInfo', null, 'QuickHostingCreditCardInfoResponseDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public UpdateInvoiceCreditCardHostingPayment = (requestData: UpdateInvoiceCreditCardHostingPaymentRequestHubDto, receiveCallBack: (data: UpdateInvoiceCreditCardHostingPaymentResponseHubDto) => void, receiveErrorCallBack: (data: UpdateInvoiceCreditCardHostingPaymentResponseHubDto) => void, noSessionCallBack: (data: UpdateInvoiceCreditCardHostingPaymentResponseHubDto) => void, completeCallBack: (data: UpdateInvoiceCreditCardHostingPaymentResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: UpdateInvoiceCreditCardHostingPaymentResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: UpdateInvoiceCreditCardHostingPaymentResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/QuickRsRegistHub/UpdateInvoiceCreditCardHostingPayment';

        let responseData: UpdateInvoiceCreditCardHostingPaymentResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('QuickRsRegistHub', 'UpdateInvoiceCreditCardHostingPayment', 'UpdateInvoiceCreditCardHostingPaymentRequestHubDto', 'UpdateInvoiceCreditCardHostingPaymentResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('QuickRsRegistHub', 'UpdateInvoiceCreditCardHostingPayment', 'UpdateInvoiceCreditCardHostingPaymentRequestHubDto', 'UpdateInvoiceCreditCardHostingPaymentResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('QuickRsRegistHub', 'UpdateInvoiceCreditCardHostingPayment', 'UpdateInvoiceCreditCardHostingPaymentRequestHubDto', 'UpdateInvoiceCreditCardHostingPaymentResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }

}



export class DomainParkingHubManager {
    /**
     * コンストラクタ
     */
    constructor(private http: HttpClient) {
    }

	// リソース破棄
    public Dispose = (): void => {
    }



    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public DomainParkingCheckRegistered = (receiveCallBack: (data: DomainParkingCheckRegisteredResponseHubDto) => void, receiveErrorCallBack: (data: DomainParkingCheckRegisteredResponseHubDto) => void, noSessionCallBack: (data: DomainParkingCheckRegisteredResponseHubDto) => void, completeCallBack: (data: DomainParkingCheckRegisteredResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainParkingCheckRegisteredResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainParkingCheckRegisteredResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainParkingHub/DomainParkingCheckRegistered';

        let responseData: DomainParkingCheckRegisteredResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainParkingHub', 'DomainParkingCheckRegistered', null, 'DomainParkingCheckRegisteredResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainParkingHub', 'DomainParkingCheckRegistered', null, 'DomainParkingCheckRegisteredResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainParkingHub', 'DomainParkingCheckRegistered', null, 'DomainParkingCheckRegisteredResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public DomainParkingRegist = (requestData: DomainParkingRegistRequestHubDto, receiveCallBack: (data: DomainParkingRegistResponseHubDto) => void, receiveErrorCallBack: (data: DomainParkingRegistResponseHubDto) => void, noSessionCallBack: (data: DomainParkingRegistResponseHubDto) => void, completeCallBack: (data: DomainParkingRegistResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainParkingRegistResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainParkingRegistResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainParkingHub/DomainParkingRegist';

        let responseData: DomainParkingRegistResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainParkingHub', 'DomainParkingRegist', 'DomainParkingRegistRequestHubDto', 'DomainParkingRegistResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainParkingHub', 'DomainParkingRegist', 'DomainParkingRegistRequestHubDto', 'DomainParkingRegistResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainParkingHub', 'DomainParkingRegist', 'DomainParkingRegistRequestHubDto', 'DomainParkingRegistResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public DomainParkingCancel = (requestData: DomainParkingCancelRequestHubDto, receiveCallBack: (data: DomainParkingCancelResponseHubDto) => void, receiveErrorCallBack: (data: DomainParkingCancelResponseHubDto) => void, noSessionCallBack: (data: DomainParkingCancelResponseHubDto) => void, completeCallBack: (data: DomainParkingCancelResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainParkingCancelResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainParkingCancelResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainParkingHub/DomainParkingCancel';

        let responseData: DomainParkingCancelResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainParkingHub', 'DomainParkingCancel', 'DomainParkingCancelRequestHubDto', 'DomainParkingCancelResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainParkingHub', 'DomainParkingCancel', 'DomainParkingCancelRequestHubDto', 'DomainParkingCancelResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainParkingHub', 'DomainParkingCancel', 'DomainParkingCancelRequestHubDto', 'DomainParkingCancelResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public DomainParkingGetDomainList = (requestData: DomainParkingGetDomainListRequestHubDto, receiveCallBack: (data: DomainParkingGetDomainListResponseHubDto) => void, receiveErrorCallBack: (data: DomainParkingGetDomainListResponseHubDto) => void, noSessionCallBack: (data: DomainParkingGetDomainListResponseHubDto) => void, completeCallBack: (data: DomainParkingGetDomainListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainParkingGetDomainListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainParkingGetDomainListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainParkingHub/DomainParkingGetDomainList';

        let responseData: DomainParkingGetDomainListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainParkingHub', 'DomainParkingGetDomainList', 'DomainParkingGetDomainListRequestHubDto', 'DomainParkingGetDomainListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainParkingHub', 'DomainParkingGetDomainList', 'DomainParkingGetDomainListRequestHubDto', 'DomainParkingGetDomainListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainParkingHub', 'DomainParkingGetDomainList', 'DomainParkingGetDomainListRequestHubDto', 'DomainParkingGetDomainListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public DomainParkingDomainCreateValidator = (requestData: DomainParkingDomainCreateValidatorRequestHubDto, receiveCallBack: (data: DomainParkingDomainCreateValidatorResponseHubDto) => void, receiveErrorCallBack: (data: DomainParkingDomainCreateValidatorResponseHubDto) => void, noSessionCallBack: (data: DomainParkingDomainCreateValidatorResponseHubDto) => void, completeCallBack: (data: DomainParkingDomainCreateValidatorResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainParkingDomainCreateValidatorResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainParkingDomainCreateValidatorResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainParkingHub/DomainParkingDomainCreateValidator';

        let responseData: DomainParkingDomainCreateValidatorResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainParkingHub', 'DomainParkingDomainCreateValidator', 'DomainParkingDomainCreateValidatorRequestHubDto', 'DomainParkingDomainCreateValidatorResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainParkingHub', 'DomainParkingDomainCreateValidator', 'DomainParkingDomainCreateValidatorRequestHubDto', 'DomainParkingDomainCreateValidatorResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainParkingHub', 'DomainParkingDomainCreateValidator', 'DomainParkingDomainCreateValidatorRequestHubDto', 'DomainParkingDomainCreateValidatorResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public DomainParkingDomainCreate = (requestData: DomainParkingDomainCreateRequestHubDto, receiveCallBack: (data: DomainParkingDomainCreateResponseHubDto) => void, receiveErrorCallBack: (data: DomainParkingDomainCreateResponseHubDto) => void, noSessionCallBack: (data: DomainParkingDomainCreateResponseHubDto) => void, completeCallBack: (data: DomainParkingDomainCreateResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainParkingDomainCreateResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainParkingDomainCreateResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainParkingHub/DomainParkingDomainCreate';

        let responseData: DomainParkingDomainCreateResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainParkingHub', 'DomainParkingDomainCreate', 'DomainParkingDomainCreateRequestHubDto', 'DomainParkingDomainCreateResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainParkingHub', 'DomainParkingDomainCreate', 'DomainParkingDomainCreateRequestHubDto', 'DomainParkingDomainCreateResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainParkingHub', 'DomainParkingDomainCreate', 'DomainParkingDomainCreateRequestHubDto', 'DomainParkingDomainCreateResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public DomainParkingGetParkingDomainList = (requestData: DomainParkingGetDomainListRequestHubDto, receiveCallBack: (data: DomainParkingGetParkingDomainListResponseHubDto) => void, receiveErrorCallBack: (data: DomainParkingGetParkingDomainListResponseHubDto) => void, noSessionCallBack: (data: DomainParkingGetParkingDomainListResponseHubDto) => void, completeCallBack: (data: DomainParkingGetParkingDomainListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainParkingGetParkingDomainListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainParkingGetParkingDomainListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainParkingHub/DomainParkingGetParkingDomainList';

        let responseData: DomainParkingGetParkingDomainListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainParkingHub', 'DomainParkingGetParkingDomainList', 'DomainParkingGetDomainListRequestHubDto', 'DomainParkingGetParkingDomainListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainParkingHub', 'DomainParkingGetParkingDomainList', 'DomainParkingGetDomainListRequestHubDto', 'DomainParkingGetParkingDomainListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainParkingHub', 'DomainParkingGetParkingDomainList', 'DomainParkingGetDomainListRequestHubDto', 'DomainParkingGetParkingDomainListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public DomainParkingDomainDelete = (requestData: DomainParkingDomainDeleteRequestHubDto, receiveCallBack: (data: DomainParkingDomainDeleteResponseHubDto) => void, receiveErrorCallBack: (data: DomainParkingDomainDeleteResponseHubDto) => void, noSessionCallBack: (data: DomainParkingDomainDeleteResponseHubDto) => void, completeCallBack: (data: DomainParkingDomainDeleteResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainParkingDomainDeleteResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainParkingDomainDeleteResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainParkingHub/DomainParkingDomainDelete';

        let responseData: DomainParkingDomainDeleteResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainParkingHub', 'DomainParkingDomainDelete', 'DomainParkingDomainDeleteRequestHubDto', 'DomainParkingDomainDeleteResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainParkingHub', 'DomainParkingDomainDelete', 'DomainParkingDomainDeleteRequestHubDto', 'DomainParkingDomainDeleteResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainParkingHub', 'DomainParkingDomainDelete', 'DomainParkingDomainDeleteRequestHubDto', 'DomainParkingDomainDeleteResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public DomainParkingAccountInfo = (receiveCallBack: (data: DomainParkingAccountInfoResponseHubDto) => void, receiveErrorCallBack: (data: DomainParkingAccountInfoResponseHubDto) => void, noSessionCallBack: (data: DomainParkingAccountInfoResponseHubDto) => void, completeCallBack: (data: DomainParkingAccountInfoResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainParkingAccountInfoResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainParkingAccountInfoResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainParkingHub/DomainParkingAccountInfo';

        let responseData: DomainParkingAccountInfoResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainParkingHub', 'DomainParkingAccountInfo', null, 'DomainParkingAccountInfoResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainParkingHub', 'DomainParkingAccountInfo', null, 'DomainParkingAccountInfoResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainParkingHub', 'DomainParkingAccountInfo', null, 'DomainParkingAccountInfoResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public DomainParkingRealizeCreate = (requestData: DomainParkingRealizeCreateRequestHubDto, receiveCallBack: (data: DomainParkingRealizeResponseHubDto) => void, receiveErrorCallBack: (data: DomainParkingRealizeResponseHubDto) => void, noSessionCallBack: (data: DomainParkingRealizeResponseHubDto) => void, completeCallBack: (data: DomainParkingRealizeResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainParkingRealizeResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainParkingRealizeResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainParkingHub/DomainParkingRealizeCreate';

        let responseData: DomainParkingRealizeResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainParkingHub', 'DomainParkingRealizeCreate', 'DomainParkingRealizeCreateRequestHubDto', 'DomainParkingRealizeResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainParkingHub', 'DomainParkingRealizeCreate', 'DomainParkingRealizeCreateRequestHubDto', 'DomainParkingRealizeResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainParkingHub', 'DomainParkingRealizeCreate', 'DomainParkingRealizeCreateRequestHubDto', 'DomainParkingRealizeResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public DomainParkingGetRealizeList = (requestData: DomainParkingGetRealizeListRequestHubDto, receiveCallBack: (data: DomainParkingGetRealizeListResponseHubDto) => void, receiveErrorCallBack: (data: DomainParkingGetRealizeListResponseHubDto) => void, noSessionCallBack: (data: DomainParkingGetRealizeListResponseHubDto) => void, completeCallBack: (data: DomainParkingGetRealizeListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainParkingGetRealizeListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainParkingGetRealizeListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainParkingHub/DomainParkingGetRealizeList';

        let responseData: DomainParkingGetRealizeListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainParkingHub', 'DomainParkingGetRealizeList', 'DomainParkingGetRealizeListRequestHubDto', 'DomainParkingGetRealizeListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainParkingHub', 'DomainParkingGetRealizeList', 'DomainParkingGetRealizeListRequestHubDto', 'DomainParkingGetRealizeListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainParkingHub', 'DomainParkingGetRealizeList', 'DomainParkingGetRealizeListRequestHubDto', 'DomainParkingGetRealizeListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public DomainParkingGetDailyOriginForAccountList = (requestData: DomainParkingGetOriginForAccountListRequestHubDto, receiveCallBack: (data: DomainParkingGetOriginForAccountListResponseHubDto) => void, receiveErrorCallBack: (data: DomainParkingGetOriginForAccountListResponseHubDto) => void, noSessionCallBack: (data: DomainParkingGetOriginForAccountListResponseHubDto) => void, completeCallBack: (data: DomainParkingGetOriginForAccountListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainParkingGetOriginForAccountListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainParkingGetOriginForAccountListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainParkingHub/DomainParkingGetDailyOriginForAccountList';

        let responseData: DomainParkingGetOriginForAccountListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainParkingHub', 'DomainParkingGetDailyOriginForAccountList', 'DomainParkingGetOriginForAccountListRequestHubDto', 'DomainParkingGetOriginForAccountListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainParkingHub', 'DomainParkingGetDailyOriginForAccountList', 'DomainParkingGetOriginForAccountListRequestHubDto', 'DomainParkingGetOriginForAccountListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainParkingHub', 'DomainParkingGetDailyOriginForAccountList', 'DomainParkingGetOriginForAccountListRequestHubDto', 'DomainParkingGetOriginForAccountListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public DomainParkingGetMonthlyOriginForAccountList = (receiveCallBack: (data: DomainParkingGetOriginForAccountListResponseHubDto) => void, receiveErrorCallBack: (data: DomainParkingGetOriginForAccountListResponseHubDto) => void, noSessionCallBack: (data: DomainParkingGetOriginForAccountListResponseHubDto) => void, completeCallBack: (data: DomainParkingGetOriginForAccountListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainParkingGetOriginForAccountListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainParkingGetOriginForAccountListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainParkingHub/DomainParkingGetMonthlyOriginForAccountList';

        let responseData: DomainParkingGetOriginForAccountListResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainParkingHub', 'DomainParkingGetMonthlyOriginForAccountList', null, 'DomainParkingGetOriginForAccountListResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainParkingHub', 'DomainParkingGetMonthlyOriginForAccountList', null, 'DomainParkingGetOriginForAccountListResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainParkingHub', 'DomainParkingGetMonthlyOriginForAccountList', null, 'DomainParkingGetOriginForAccountListResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public DomainParkingDomainWithOriginList = (requestData: DomainParkingDomainWithOriginListRequestHubDto, receiveCallBack: (data: DomainParkingDomainWithOriginListResponseHubDto) => void, receiveErrorCallBack: (data: DomainParkingDomainWithOriginListResponseHubDto) => void, noSessionCallBack: (data: DomainParkingDomainWithOriginListResponseHubDto) => void, completeCallBack: (data: DomainParkingDomainWithOriginListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainParkingDomainWithOriginListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainParkingDomainWithOriginListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainParkingHub/DomainParkingDomainWithOriginList';

        let responseData: DomainParkingDomainWithOriginListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainParkingHub', 'DomainParkingDomainWithOriginList', 'DomainParkingDomainWithOriginListRequestHubDto', 'DomainParkingDomainWithOriginListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainParkingHub', 'DomainParkingDomainWithOriginList', 'DomainParkingDomainWithOriginListRequestHubDto', 'DomainParkingDomainWithOriginListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainParkingHub', 'DomainParkingDomainWithOriginList', 'DomainParkingDomainWithOriginListRequestHubDto', 'DomainParkingDomainWithOriginListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public DomainParkingGetDailyOriginForDomainList = (requestData: DomainParkingGetOriginForDomainListRequestHubDto, receiveCallBack: (data: DomainParkingGetOriginForDomainListResponseHubDto) => void, receiveErrorCallBack: (data: DomainParkingGetOriginForDomainListResponseHubDto) => void, noSessionCallBack: (data: DomainParkingGetOriginForDomainListResponseHubDto) => void, completeCallBack: (data: DomainParkingGetOriginForDomainListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainParkingGetOriginForDomainListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainParkingGetOriginForDomainListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainParkingHub/DomainParkingGetDailyOriginForDomainList';

        let responseData: DomainParkingGetOriginForDomainListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainParkingHub', 'DomainParkingGetDailyOriginForDomainList', 'DomainParkingGetOriginForDomainListRequestHubDto', 'DomainParkingGetOriginForDomainListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainParkingHub', 'DomainParkingGetDailyOriginForDomainList', 'DomainParkingGetOriginForDomainListRequestHubDto', 'DomainParkingGetOriginForDomainListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainParkingHub', 'DomainParkingGetDailyOriginForDomainList', 'DomainParkingGetOriginForDomainListRequestHubDto', 'DomainParkingGetOriginForDomainListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public DomainParkingGetMonthlyOriginForDomainList = (requestData: DomainParkingGetMonthlyOriginForDomainListRequestHubDto, receiveCallBack: (data: DomainParkingGetOriginForDomainListResponseHubDto) => void, receiveErrorCallBack: (data: DomainParkingGetOriginForDomainListResponseHubDto) => void, noSessionCallBack: (data: DomainParkingGetOriginForDomainListResponseHubDto) => void, completeCallBack: (data: DomainParkingGetOriginForDomainListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainParkingGetOriginForDomainListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainParkingGetOriginForDomainListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainParkingHub/DomainParkingGetMonthlyOriginForDomainList';

        let responseData: DomainParkingGetOriginForDomainListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainParkingHub', 'DomainParkingGetMonthlyOriginForDomainList', 'DomainParkingGetMonthlyOriginForDomainListRequestHubDto', 'DomainParkingGetOriginForDomainListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainParkingHub', 'DomainParkingGetMonthlyOriginForDomainList', 'DomainParkingGetMonthlyOriginForDomainListRequestHubDto', 'DomainParkingGetOriginForDomainListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainParkingHub', 'DomainParkingGetMonthlyOriginForDomainList', 'DomainParkingGetMonthlyOriginForDomainListRequestHubDto', 'DomainParkingGetOriginForDomainListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }

}



export class SellDomainHubManager {
    /**
     * コンストラクタ
     */
    constructor(private http: HttpClient) {
    }

	// リソース破棄
    public Dispose = (): void => {
    }



    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public DomainNameInCard = (requestData: SellDomainListRequestHubDto, receiveCallBack: (data: DomainCardListResponseHubDto) => void, receiveErrorCallBack: (data: DomainCardListResponseHubDto) => void, noSessionCallBack: (data: DomainCardListResponseHubDto) => void, completeCallBack: (data: DomainCardListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainCardListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainCardListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SellDomainHub/DomainNameInCard';

        let responseData: DomainCardListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SellDomainHub', 'DomainNameInCard', 'SellDomainListRequestHubDto', 'DomainCardListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SellDomainHub', 'DomainNameInCard', 'SellDomainListRequestHubDto', 'DomainCardListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SellDomainHub', 'DomainNameInCard', 'SellDomainListRequestHubDto', 'DomainCardListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetListSellDomain = (requestData: SellDomainListRequestHubDto, receiveCallBack: (data: DomainSelectListResponseHubDto) => void, receiveErrorCallBack: (data: DomainSelectListResponseHubDto) => void, noSessionCallBack: (data: DomainSelectListResponseHubDto) => void, completeCallBack: (data: DomainSelectListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainSelectListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainSelectListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SellDomainHub/GetListSellDomain';

        let responseData: DomainSelectListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SellDomainHub', 'GetListSellDomain', 'SellDomainListRequestHubDto', 'DomainSelectListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SellDomainHub', 'GetListSellDomain', 'SellDomainListRequestHubDto', 'DomainSelectListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SellDomainHub', 'GetListSellDomain', 'SellDomainListRequestHubDto', 'DomainSelectListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public SellDomainCreates = (requestData: SellDomainCreateRequestHubDto, receiveCallBack: (data: SellDomainCreateResponseHubDto) => void, receiveErrorCallBack: (data: SellDomainCreateResponseHubDto) => void, noSessionCallBack: (data: SellDomainCreateResponseHubDto) => void, completeCallBack: (data: SellDomainCreateResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SellDomainCreateResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SellDomainCreateResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SellDomainHub/SellDomainCreates';

        let responseData: SellDomainCreateResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SellDomainHub', 'SellDomainCreates', 'SellDomainCreateRequestHubDto', 'SellDomainCreateResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SellDomainHub', 'SellDomainCreates', 'SellDomainCreateRequestHubDto', 'SellDomainCreateResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SellDomainHub', 'SellDomainCreates', 'SellDomainCreateRequestHubDto', 'SellDomainCreateResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public SellDomainHistoryList = (requestData: SellDomainHistoryListRequestHubDto, receiveCallBack: (data: SellDomainHistoryListResponseHubDto) => void, receiveErrorCallBack: (data: SellDomainHistoryListResponseHubDto) => void, noSessionCallBack: (data: SellDomainHistoryListResponseHubDto) => void, completeCallBack: (data: SellDomainHistoryListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SellDomainHistoryListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SellDomainHistoryListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SellDomainHub/SellDomainHistoryList';

        let responseData: SellDomainHistoryListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SellDomainHub', 'SellDomainHistoryList', 'SellDomainHistoryListRequestHubDto', 'SellDomainHistoryListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SellDomainHub', 'SellDomainHistoryList', 'SellDomainHistoryListRequestHubDto', 'SellDomainHistoryListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SellDomainHub', 'SellDomainHistoryList', 'SellDomainHistoryListRequestHubDto', 'SellDomainHistoryListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public SellDomainAutoExtension = (requestData: SellDomainAutoExtensionReqeuestHubDto, receiveCallBack: (data: SellDomainAutoExtensionResponseHubDto) => void, receiveErrorCallBack: (data: SellDomainAutoExtensionResponseHubDto) => void, noSessionCallBack: (data: SellDomainAutoExtensionResponseHubDto) => void, completeCallBack: (data: SellDomainAutoExtensionResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SellDomainAutoExtensionResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SellDomainAutoExtensionResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SellDomainHub/SellDomainAutoExtension';

        let responseData: SellDomainAutoExtensionResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SellDomainHub', 'SellDomainAutoExtension', 'SellDomainAutoExtensionReqeuestHubDto', 'SellDomainAutoExtensionResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SellDomainHub', 'SellDomainAutoExtension', 'SellDomainAutoExtensionReqeuestHubDto', 'SellDomainAutoExtensionResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SellDomainHub', 'SellDomainAutoExtension', 'SellDomainAutoExtensionReqeuestHubDto', 'SellDomainAutoExtensionResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public SellDomainOfferList = (requestData: SellDomainOfferListRequestHubDto, receiveCallBack: (data: SellDomainOfferListResponseHubDto) => void, receiveErrorCallBack: (data: SellDomainOfferListResponseHubDto) => void, noSessionCallBack: (data: SellDomainOfferListResponseHubDto) => void, completeCallBack: (data: SellDomainOfferListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SellDomainOfferListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SellDomainOfferListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SellDomainHub/SellDomainOfferList';

        let responseData: SellDomainOfferListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SellDomainHub', 'SellDomainOfferList', 'SellDomainOfferListRequestHubDto', 'SellDomainOfferListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SellDomainHub', 'SellDomainOfferList', 'SellDomainOfferListRequestHubDto', 'SellDomainOfferListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SellDomainHub', 'SellDomainOfferList', 'SellDomainOfferListRequestHubDto', 'SellDomainOfferListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public SellDomainOfferApproval = (requestData: SellDomainOfferApprovalRequestHubDto, receiveCallBack: (data: SellDomainOfferApprovalResponseHubDto) => void, receiveErrorCallBack: (data: SellDomainOfferApprovalResponseHubDto) => void, noSessionCallBack: (data: SellDomainOfferApprovalResponseHubDto) => void, completeCallBack: (data: SellDomainOfferApprovalResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SellDomainOfferApprovalResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SellDomainOfferApprovalResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SellDomainHub/SellDomainOfferApproval';

        let responseData: SellDomainOfferApprovalResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SellDomainHub', 'SellDomainOfferApproval', 'SellDomainOfferApprovalRequestHubDto', 'SellDomainOfferApprovalResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SellDomainHub', 'SellDomainOfferApproval', 'SellDomainOfferApprovalRequestHubDto', 'SellDomainOfferApprovalResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SellDomainHub', 'SellDomainOfferApproval', 'SellDomainOfferApprovalRequestHubDto', 'SellDomainOfferApprovalResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public SellDomainSeekBulk = (requestData: SellDomainSeekBulkRequestHubDto, receiveCallBack: (data: SellDomainSeekBulkResponseHubDto) => void, receiveErrorCallBack: (data: SellDomainSeekBulkResponseHubDto) => void, noSessionCallBack: (data: SellDomainSeekBulkResponseHubDto) => void, completeCallBack: (data: SellDomainSeekBulkResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SellDomainSeekBulkResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SellDomainSeekBulkResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SellDomainHub/SellDomainSeekBulk';

        let responseData: SellDomainSeekBulkResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SellDomainHub', 'SellDomainSeekBulk', 'SellDomainSeekBulkRequestHubDto', 'SellDomainSeekBulkResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SellDomainHub', 'SellDomainSeekBulk', 'SellDomainSeekBulkRequestHubDto', 'SellDomainSeekBulkResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SellDomainHub', 'SellDomainSeekBulk', 'SellDomainSeekBulkRequestHubDto', 'SellDomainSeekBulkResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public SellDomainPayoutConfirm = (requestData: SellDomainPayoutConfirmHubDto, receiveCallBack: (data: PayoutConfirmResponseHubDto) => void, receiveErrorCallBack: (data: PayoutConfirmResponseHubDto) => void, noSessionCallBack: (data: PayoutConfirmResponseHubDto) => void, completeCallBack: (data: PayoutConfirmResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: PayoutConfirmResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: PayoutConfirmResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SellDomainHub/SellDomainPayoutConfirm';

        let responseData: PayoutConfirmResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SellDomainHub', 'SellDomainPayoutConfirm', 'SellDomainPayoutConfirmHubDto', 'PayoutConfirmResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SellDomainHub', 'SellDomainPayoutConfirm', 'SellDomainPayoutConfirmHubDto', 'PayoutConfirmResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SellDomainHub', 'SellDomainPayoutConfirm', 'SellDomainPayoutConfirmHubDto', 'PayoutConfirmResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public SellDomainPayoutInput = (receiveCallBack: (data: PayoutInputResponseHubDto) => void, receiveErrorCallBack: (data: PayoutInputResponseHubDto) => void, noSessionCallBack: (data: PayoutInputResponseHubDto) => void, completeCallBack: (data: PayoutInputResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: PayoutInputResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: PayoutInputResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SellDomainHub/SellDomainPayoutInput';

        let responseData: PayoutInputResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SellDomainHub', 'SellDomainPayoutInput', null, 'PayoutInputResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SellDomainHub', 'SellDomainPayoutInput', null, 'PayoutInputResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SellDomainHub', 'SellDomainPayoutInput', null, 'PayoutInputResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public SellDomainNavigateTransfer = (requestData: SellDomainRequestNavigateHubDto, receiveCallBack: (data: SellDomainResponseNavigateHubDto) => void, receiveErrorCallBack: (data: SellDomainResponseNavigateHubDto) => void, noSessionCallBack: (data: SellDomainResponseNavigateHubDto) => void, completeCallBack: (data: SellDomainResponseNavigateHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SellDomainResponseNavigateHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SellDomainResponseNavigateHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SellDomainHub/SellDomainNavigateTransfer';

        let responseData: SellDomainResponseNavigateHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SellDomainHub', 'SellDomainNavigateTransfer', 'SellDomainRequestNavigateHubDto', 'SellDomainResponseNavigateHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SellDomainHub', 'SellDomainNavigateTransfer', 'SellDomainRequestNavigateHubDto', 'SellDomainResponseNavigateHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SellDomainHub', 'SellDomainNavigateTransfer', 'SellDomainRequestNavigateHubDto', 'SellDomainResponseNavigateHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }

}



export class DomainDeleteHubManager {
    /**
     * コンストラクタ
     */
    constructor(private http: HttpClient) {
    }

	// リソース破棄
    public Dispose = (): void => {
    }



    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public DomainDeleteList = (requestData: DomainDeleteListRequestHubDto, receiveCallBack: (data: DomainDeleteListResponseHubDto) => void, receiveErrorCallBack: (data: DomainDeleteListResponseHubDto) => void, noSessionCallBack: (data: DomainDeleteListResponseHubDto) => void, completeCallBack: (data: DomainDeleteListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainDeleteListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainDeleteListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainDeleteHub/DomainDeleteList';

        let responseData: DomainDeleteListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainDeleteHub', 'DomainDeleteList', 'DomainDeleteListRequestHubDto', 'DomainDeleteListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainDeleteHub', 'DomainDeleteList', 'DomainDeleteListRequestHubDto', 'DomainDeleteListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainDeleteHub', 'DomainDeleteList', 'DomainDeleteListRequestHubDto', 'DomainDeleteListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public DomainDeleteValidator = (requestData: DomainDeleteRequestHubDto, receiveCallBack: (data: DomainDeleteResultResponseHubDto) => void, receiveErrorCallBack: (data: DomainDeleteResultResponseHubDto) => void, noSessionCallBack: (data: DomainDeleteResultResponseHubDto) => void, completeCallBack: (data: DomainDeleteResultResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainDeleteResultResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainDeleteResultResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainDeleteHub/DomainDeleteValidator';

        let responseData: DomainDeleteResultResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainDeleteHub', 'DomainDeleteValidator', 'DomainDeleteRequestHubDto', 'DomainDeleteResultResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainDeleteHub', 'DomainDeleteValidator', 'DomainDeleteRequestHubDto', 'DomainDeleteResultResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainDeleteHub', 'DomainDeleteValidator', 'DomainDeleteRequestHubDto', 'DomainDeleteResultResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public DeleteDomain = (requestData: DomainDeleteRequestHubDto, receiveCallBack: (data: DomainDeleteResultResponseHubDto) => void, receiveErrorCallBack: (data: DomainDeleteResultResponseHubDto) => void, noSessionCallBack: (data: DomainDeleteResultResponseHubDto) => void, completeCallBack: (data: DomainDeleteResultResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainDeleteResultResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainDeleteResultResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainDeleteHub/DeleteDomain';

        let responseData: DomainDeleteResultResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainDeleteHub', 'DeleteDomain', 'DomainDeleteRequestHubDto', 'DomainDeleteResultResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainDeleteHub', 'DeleteDomain', 'DomainDeleteRequestHubDto', 'DomainDeleteResultResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainDeleteHub', 'DeleteDomain', 'DomainDeleteRequestHubDto', 'DomainDeleteResultResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public DomainDeleteCancelList = (requestData: DomainDeleteListRequestHubDto, receiveCallBack: (data: DomainDeleteListResponseHubDto) => void, receiveErrorCallBack: (data: DomainDeleteListResponseHubDto) => void, noSessionCallBack: (data: DomainDeleteListResponseHubDto) => void, completeCallBack: (data: DomainDeleteListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainDeleteListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainDeleteListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainDeleteHub/DomainDeleteCancelList';

        let responseData: DomainDeleteListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainDeleteHub', 'DomainDeleteCancelList', 'DomainDeleteListRequestHubDto', 'DomainDeleteListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainDeleteHub', 'DomainDeleteCancelList', 'DomainDeleteListRequestHubDto', 'DomainDeleteListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainDeleteHub', 'DomainDeleteCancelList', 'DomainDeleteListRequestHubDto', 'DomainDeleteListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public DomainCancelDeleteValidator = (requestData: DomainDeleteRequestHubDto, receiveCallBack: (data: DomainDeleteResultResponseHubDto) => void, receiveErrorCallBack: (data: DomainDeleteResultResponseHubDto) => void, noSessionCallBack: (data: DomainDeleteResultResponseHubDto) => void, completeCallBack: (data: DomainDeleteResultResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainDeleteResultResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainDeleteResultResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainDeleteHub/DomainCancelDeleteValidator';

        let responseData: DomainDeleteResultResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainDeleteHub', 'DomainCancelDeleteValidator', 'DomainDeleteRequestHubDto', 'DomainDeleteResultResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainDeleteHub', 'DomainCancelDeleteValidator', 'DomainDeleteRequestHubDto', 'DomainDeleteResultResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainDeleteHub', 'DomainCancelDeleteValidator', 'DomainDeleteRequestHubDto', 'DomainDeleteResultResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public DeleteDomainCancelation = (requestData: DomainDeleteRequestHubDto, receiveCallBack: (data: DomainDeleteResultResponseHubDto) => void, receiveErrorCallBack: (data: DomainDeleteResultResponseHubDto) => void, noSessionCallBack: (data: DomainDeleteResultResponseHubDto) => void, completeCallBack: (data: DomainDeleteResultResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainDeleteResultResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainDeleteResultResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainDeleteHub/DeleteDomainCancelation';

        let responseData: DomainDeleteResultResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainDeleteHub', 'DeleteDomainCancelation', 'DomainDeleteRequestHubDto', 'DomainDeleteResultResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainDeleteHub', 'DeleteDomainCancelation', 'DomainDeleteRequestHubDto', 'DomainDeleteResultResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainDeleteHub', 'DeleteDomainCancelation', 'DomainDeleteRequestHubDto', 'DomainDeleteResultResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }

}



export class DomainDelServiceHubManager {
    /**
     * コンストラクタ
     */
    constructor(private http: HttpClient) {
    }

	// リソース破棄
    public Dispose = (): void => {
    }



    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public DomainDelServiceList = (requestData: DomainDelServiceListRequestHubDto, receiveCallBack: (data: DomainDelServiceListResponseHubDto) => void, receiveErrorCallBack: (data: DomainDelServiceListResponseHubDto) => void, noSessionCallBack: (data: DomainDelServiceListResponseHubDto) => void, completeCallBack: (data: DomainDelServiceListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainDelServiceListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainDelServiceListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainDelServiceHub/DomainDelServiceList';

        let responseData: DomainDelServiceListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainDelServiceHub', 'DomainDelServiceList', 'DomainDelServiceListRequestHubDto', 'DomainDelServiceListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainDelServiceHub', 'DomainDelServiceList', 'DomainDelServiceListRequestHubDto', 'DomainDelServiceListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainDelServiceHub', 'DomainDelServiceList', 'DomainDelServiceListRequestHubDto', 'DomainDelServiceListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public SecureDeletionCreateApplyValidator = (requestData: SecureDeletionCreateApplyValidatorRequestHubDto, receiveCallBack: (data: SecureDeletionCreateApplyValidatorResponseHubDto) => void, receiveErrorCallBack: (data: SecureDeletionCreateApplyValidatorResponseHubDto) => void, noSessionCallBack: (data: SecureDeletionCreateApplyValidatorResponseHubDto) => void, completeCallBack: (data: SecureDeletionCreateApplyValidatorResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SecureDeletionCreateApplyValidatorResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SecureDeletionCreateApplyValidatorResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainDelServiceHub/SecureDeletionCreateApplyValidator';

        let responseData: SecureDeletionCreateApplyValidatorResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainDelServiceHub', 'SecureDeletionCreateApplyValidator', 'SecureDeletionCreateApplyValidatorRequestHubDto', 'SecureDeletionCreateApplyValidatorResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainDelServiceHub', 'SecureDeletionCreateApplyValidator', 'SecureDeletionCreateApplyValidatorRequestHubDto', 'SecureDeletionCreateApplyValidatorResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainDelServiceHub', 'SecureDeletionCreateApplyValidator', 'SecureDeletionCreateApplyValidatorRequestHubDto', 'SecureDeletionCreateApplyValidatorResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public SecureDeletionCreateApply = (requestData: SecureDeletionCreateApplyRequestHubDto, receiveCallBack: (data: SecureDeletionCreateApplyResponseHubDto) => void, receiveErrorCallBack: (data: SecureDeletionCreateApplyResponseHubDto) => void, noSessionCallBack: (data: SecureDeletionCreateApplyResponseHubDto) => void, completeCallBack: (data: SecureDeletionCreateApplyResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SecureDeletionCreateApplyResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SecureDeletionCreateApplyResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainDelServiceHub/SecureDeletionCreateApply';

        let responseData: SecureDeletionCreateApplyResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainDelServiceHub', 'SecureDeletionCreateApply', 'SecureDeletionCreateApplyRequestHubDto', 'SecureDeletionCreateApplyResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainDelServiceHub', 'SecureDeletionCreateApply', 'SecureDeletionCreateApplyRequestHubDto', 'SecureDeletionCreateApplyResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainDelServiceHub', 'SecureDeletionCreateApply', 'SecureDeletionCreateApplyRequestHubDto', 'SecureDeletionCreateApplyResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public SecureDeletionReportList = (requestData: SecureDeletionReportListRequestHubDto, receiveCallBack: (data: SecureDeletionReportListResponseHubDto) => void, receiveErrorCallBack: (data: SecureDeletionReportListResponseHubDto) => void, noSessionCallBack: (data: SecureDeletionReportListResponseHubDto) => void, completeCallBack: (data: SecureDeletionReportListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SecureDeletionReportListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SecureDeletionReportListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainDelServiceHub/SecureDeletionReportList';

        let responseData: SecureDeletionReportListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainDelServiceHub', 'SecureDeletionReportList', 'SecureDeletionReportListRequestHubDto', 'SecureDeletionReportListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainDelServiceHub', 'SecureDeletionReportList', 'SecureDeletionReportListRequestHubDto', 'SecureDeletionReportListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainDelServiceHub', 'SecureDeletionReportList', 'SecureDeletionReportListRequestHubDto', 'SecureDeletionReportListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetContractedDeletionDomainList = (requestData: GetContractedDeletionDomainListRequestHubDto, receiveCallBack: (data: GetContractedDeletionDomainListResponseHubDto) => void, receiveErrorCallBack: (data: GetContractedDeletionDomainListResponseHubDto) => void, noSessionCallBack: (data: GetContractedDeletionDomainListResponseHubDto) => void, completeCallBack: (data: GetContractedDeletionDomainListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: GetContractedDeletionDomainListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: GetContractedDeletionDomainListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainDelServiceHub/GetContractedDeletionDomainList';

        let responseData: GetContractedDeletionDomainListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainDelServiceHub', 'GetContractedDeletionDomainList', 'GetContractedDeletionDomainListRequestHubDto', 'GetContractedDeletionDomainListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainDelServiceHub', 'GetContractedDeletionDomainList', 'GetContractedDeletionDomainListRequestHubDto', 'GetContractedDeletionDomainListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainDelServiceHub', 'GetContractedDeletionDomainList', 'GetContractedDeletionDomainListRequestHubDto', 'GetContractedDeletionDomainListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public DeleteSecureDeletion = (requestData: DeleteSecureDeletionRequestHubDto, receiveCallBack: (data: DeleteSecureDeletionResponseHubDto) => void, receiveErrorCallBack: (data: DeleteSecureDeletionResponseHubDto) => void, noSessionCallBack: (data: DeleteSecureDeletionResponseHubDto) => void, completeCallBack: (data: DeleteSecureDeletionResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DeleteSecureDeletionResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DeleteSecureDeletionResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainDelServiceHub/DeleteSecureDeletion';

        let responseData: DeleteSecureDeletionResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainDelServiceHub', 'DeleteSecureDeletion', 'DeleteSecureDeletionRequestHubDto', 'DeleteSecureDeletionResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainDelServiceHub', 'DeleteSecureDeletion', 'DeleteSecureDeletionRequestHubDto', 'DeleteSecureDeletionResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainDelServiceHub', 'DeleteSecureDeletion', 'DeleteSecureDeletionRequestHubDto', 'DeleteSecureDeletionResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }

}



export class DomainDsRecordHubManager {
    /**
     * コンストラクタ
     */
    constructor(private http: HttpClient) {
    }

	// リソース破棄
    public Dispose = (): void => {
    }



    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetDomainList = (requestData: DsRecordDomainListRequestHubDto, receiveCallBack: (data: DsRecordDomainListResponseHubDto) => void, receiveErrorCallBack: (data: DsRecordDomainListResponseHubDto) => void, noSessionCallBack: (data: DsRecordDomainListResponseHubDto) => void, completeCallBack: (data: DsRecordDomainListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DsRecordDomainListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DsRecordDomainListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainDsRecordHub/GetDomainList';

        let responseData: DsRecordDomainListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainDsRecordHub', 'GetDomainList', 'DsRecordDomainListRequestHubDto', 'DsRecordDomainListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainDsRecordHub', 'GetDomainList', 'DsRecordDomainListRequestHubDto', 'DsRecordDomainListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainDsRecordHub', 'GetDomainList', 'DsRecordDomainListRequestHubDto', 'DsRecordDomainListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetDsRecordList = (requestData: DsRecordListRequestHubDto, receiveCallBack: (data: DsRecordListResponseHubDto) => void, receiveErrorCallBack: (data: DsRecordListResponseHubDto) => void, noSessionCallBack: (data: DsRecordListResponseHubDto) => void, completeCallBack: (data: DsRecordListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DsRecordListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DsRecordListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainDsRecordHub/GetDsRecordList';

        let responseData: DsRecordListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainDsRecordHub', 'GetDsRecordList', 'DsRecordListRequestHubDto', 'DsRecordListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainDsRecordHub', 'GetDsRecordList', 'DsRecordListRequestHubDto', 'DsRecordListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainDsRecordHub', 'GetDsRecordList', 'DsRecordListRequestHubDto', 'DsRecordListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public AddDsRecord = (requestData: DsRecordAddRequestHubDto, receiveCallBack: (data: DsRecordEditResponseHubDto) => void, receiveErrorCallBack: (data: DsRecordEditResponseHubDto) => void, noSessionCallBack: (data: DsRecordEditResponseHubDto) => void, completeCallBack: (data: DsRecordEditResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DsRecordEditResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DsRecordEditResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainDsRecordHub/AddDsRecord';

        let responseData: DsRecordEditResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainDsRecordHub', 'AddDsRecord', 'DsRecordAddRequestHubDto', 'DsRecordEditResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainDsRecordHub', 'AddDsRecord', 'DsRecordAddRequestHubDto', 'DsRecordEditResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainDsRecordHub', 'AddDsRecord', 'DsRecordAddRequestHubDto', 'DsRecordEditResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public UpdateDsRecord = (requestData: DsRecordUpdateRequestHubDto, receiveCallBack: (data: DsRecordEditResponseHubDto) => void, receiveErrorCallBack: (data: DsRecordEditResponseHubDto) => void, noSessionCallBack: (data: DsRecordEditResponseHubDto) => void, completeCallBack: (data: DsRecordEditResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DsRecordEditResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DsRecordEditResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainDsRecordHub/UpdateDsRecord';

        let responseData: DsRecordEditResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainDsRecordHub', 'UpdateDsRecord', 'DsRecordUpdateRequestHubDto', 'DsRecordEditResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainDsRecordHub', 'UpdateDsRecord', 'DsRecordUpdateRequestHubDto', 'DsRecordEditResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainDsRecordHub', 'UpdateDsRecord', 'DsRecordUpdateRequestHubDto', 'DsRecordEditResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public DeleteDsRecord = (requestData: DsRecordDeleteRequestHubDto, receiveCallBack: (data: DsRecordEditResponseHubDto) => void, receiveErrorCallBack: (data: DsRecordEditResponseHubDto) => void, noSessionCallBack: (data: DsRecordEditResponseHubDto) => void, completeCallBack: (data: DsRecordEditResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DsRecordEditResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DsRecordEditResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainDsRecordHub/DeleteDsRecord';

        let responseData: DsRecordEditResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainDsRecordHub', 'DeleteDsRecord', 'DsRecordDeleteRequestHubDto', 'DsRecordEditResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainDsRecordHub', 'DeleteDsRecord', 'DsRecordDeleteRequestHubDto', 'DsRecordEditResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainDsRecordHub', 'DeleteDsRecord', 'DsRecordDeleteRequestHubDto', 'DsRecordEditResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }

}



export class DomainMonitoringHubManager {
    /**
     * コンストラクタ
     */
    constructor(private http: HttpClient) {
    }

	// リソース破棄
    public Dispose = (): void => {
    }



    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public MonitoringCreateValidator = (requestData: MonitoringCreateValidatorRequestHubDto, receiveCallBack: (data: MonitoringCreateValidatorReponseHubDto) => void, receiveErrorCallBack: (data: MonitoringCreateValidatorReponseHubDto) => void, noSessionCallBack: (data: MonitoringCreateValidatorReponseHubDto) => void, completeCallBack: (data: MonitoringCreateValidatorReponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: MonitoringCreateValidatorReponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: MonitoringCreateValidatorReponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainMonitoringHub/MonitoringCreateValidator';

        let responseData: MonitoringCreateValidatorReponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainMonitoringHub', 'MonitoringCreateValidator', 'MonitoringCreateValidatorRequestHubDto', 'MonitoringCreateValidatorReponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainMonitoringHub', 'MonitoringCreateValidator', 'MonitoringCreateValidatorRequestHubDto', 'MonitoringCreateValidatorReponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainMonitoringHub', 'MonitoringCreateValidator', 'MonitoringCreateValidatorRequestHubDto', 'MonitoringCreateValidatorReponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public MonitoringCreate = (requestData: MonitoringCreateRequestHubDto, receiveCallBack: (data: MonitoringCreateReponseHubDto) => void, receiveErrorCallBack: (data: MonitoringCreateReponseHubDto) => void, noSessionCallBack: (data: MonitoringCreateReponseHubDto) => void, completeCallBack: (data: MonitoringCreateReponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: MonitoringCreateReponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: MonitoringCreateReponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainMonitoringHub/MonitoringCreate';

        let responseData: MonitoringCreateReponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainMonitoringHub', 'MonitoringCreate', 'MonitoringCreateRequestHubDto', 'MonitoringCreateReponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainMonitoringHub', 'MonitoringCreate', 'MonitoringCreateRequestHubDto', 'MonitoringCreateReponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainMonitoringHub', 'MonitoringCreate', 'MonitoringCreateRequestHubDto', 'MonitoringCreateReponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetMonitoringConfirmInfo = (requestData: GetMonitoringConfirmInfoRequestHubDto, receiveCallBack: (data: GetMonitoringConfirmInfoResponseHubDto) => void, receiveErrorCallBack: (data: GetMonitoringConfirmInfoResponseHubDto) => void, noSessionCallBack: (data: GetMonitoringConfirmInfoResponseHubDto) => void, completeCallBack: (data: GetMonitoringConfirmInfoResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: GetMonitoringConfirmInfoResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: GetMonitoringConfirmInfoResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainMonitoringHub/GetMonitoringConfirmInfo';

        let responseData: GetMonitoringConfirmInfoResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainMonitoringHub', 'GetMonitoringConfirmInfo', 'GetMonitoringConfirmInfoRequestHubDto', 'GetMonitoringConfirmInfoResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainMonitoringHub', 'GetMonitoringConfirmInfo', 'GetMonitoringConfirmInfoRequestHubDto', 'GetMonitoringConfirmInfoResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainMonitoringHub', 'GetMonitoringConfirmInfo', 'GetMonitoringConfirmInfoRequestHubDto', 'GetMonitoringConfirmInfoResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetMonitoringTextList = (receiveCallBack: (data: GetMonitoringTextListResponseHubDto) => void, receiveErrorCallBack: (data: GetMonitoringTextListResponseHubDto) => void, noSessionCallBack: (data: GetMonitoringTextListResponseHubDto) => void, completeCallBack: (data: GetMonitoringTextListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: GetMonitoringTextListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: GetMonitoringTextListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainMonitoringHub/GetMonitoringTextList';

        let responseData: GetMonitoringTextListResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainMonitoringHub', 'GetMonitoringTextList', null, 'GetMonitoringTextListResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainMonitoringHub', 'GetMonitoringTextList', null, 'GetMonitoringTextListResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainMonitoringHub', 'GetMonitoringTextList', null, 'GetMonitoringTextListResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetAutoCreateDomainServiceSetting = (requestData: GetAutoCreateDomainServiceSettingRequestHubDto, receiveCallBack: (data: GetAutoCreateDomainServiceSettingResponseHubDto) => void, receiveErrorCallBack: (data: GetAutoCreateDomainServiceSettingResponseHubDto) => void, noSessionCallBack: (data: GetAutoCreateDomainServiceSettingResponseHubDto) => void, completeCallBack: (data: GetAutoCreateDomainServiceSettingResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: GetAutoCreateDomainServiceSettingResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: GetAutoCreateDomainServiceSettingResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainMonitoringHub/GetAutoCreateDomainServiceSetting';

        let responseData: GetAutoCreateDomainServiceSettingResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainMonitoringHub', 'GetAutoCreateDomainServiceSetting', 'GetAutoCreateDomainServiceSettingRequestHubDto', 'GetAutoCreateDomainServiceSettingResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainMonitoringHub', 'GetAutoCreateDomainServiceSetting', 'GetAutoCreateDomainServiceSettingRequestHubDto', 'GetAutoCreateDomainServiceSettingResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainMonitoringHub', 'GetAutoCreateDomainServiceSetting', 'GetAutoCreateDomainServiceSettingRequestHubDto', 'GetAutoCreateDomainServiceSettingResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetAutoCreatePlanList = (receiveCallBack: (data: GetAutoCreatePlanListResponseHubDto) => void, receiveErrorCallBack: (data: GetAutoCreatePlanListResponseHubDto) => void, noSessionCallBack: (data: GetAutoCreatePlanListResponseHubDto) => void, completeCallBack: (data: GetAutoCreatePlanListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: GetAutoCreatePlanListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: GetAutoCreatePlanListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainMonitoringHub/GetAutoCreatePlanList';

        let responseData: GetAutoCreatePlanListResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainMonitoringHub', 'GetAutoCreatePlanList', null, 'GetAutoCreatePlanListResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainMonitoringHub', 'GetAutoCreatePlanList', null, 'GetAutoCreatePlanListResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainMonitoringHub', 'GetAutoCreatePlanList', null, 'GetAutoCreatePlanListResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public AutoCreatePlanSettingChangeValidation = (requestData: AutoCreatePlanSettingChangeRequestHubDto, receiveCallBack: (data: AutoCreatePlanSettingChangeResponseHubDto) => void, receiveErrorCallBack: (data: AutoCreatePlanSettingChangeResponseHubDto) => void, noSessionCallBack: (data: AutoCreatePlanSettingChangeResponseHubDto) => void, completeCallBack: (data: AutoCreatePlanSettingChangeResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: AutoCreatePlanSettingChangeResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: AutoCreatePlanSettingChangeResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainMonitoringHub/AutoCreatePlanSettingChangeValidation';

        let responseData: AutoCreatePlanSettingChangeResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainMonitoringHub', 'AutoCreatePlanSettingChangeValidation', 'AutoCreatePlanSettingChangeRequestHubDto', 'AutoCreatePlanSettingChangeResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainMonitoringHub', 'AutoCreatePlanSettingChangeValidation', 'AutoCreatePlanSettingChangeRequestHubDto', 'AutoCreatePlanSettingChangeResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainMonitoringHub', 'AutoCreatePlanSettingChangeValidation', 'AutoCreatePlanSettingChangeRequestHubDto', 'AutoCreatePlanSettingChangeResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public AutoCreatePlanSettingChange = (requestData: AutoCreatePlanSettingChangeRequestHubDto, receiveCallBack: (data: AutoCreatePlanSettingChangeResponseHubDto) => void, receiveErrorCallBack: (data: AutoCreatePlanSettingChangeResponseHubDto) => void, noSessionCallBack: (data: AutoCreatePlanSettingChangeResponseHubDto) => void, completeCallBack: (data: AutoCreatePlanSettingChangeResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: AutoCreatePlanSettingChangeResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: AutoCreatePlanSettingChangeResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainMonitoringHub/AutoCreatePlanSettingChange';

        let responseData: AutoCreatePlanSettingChangeResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainMonitoringHub', 'AutoCreatePlanSettingChange', 'AutoCreatePlanSettingChangeRequestHubDto', 'AutoCreatePlanSettingChangeResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainMonitoringHub', 'AutoCreatePlanSettingChange', 'AutoCreatePlanSettingChangeRequestHubDto', 'AutoCreatePlanSettingChangeResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainMonitoringHub', 'AutoCreatePlanSettingChange', 'AutoCreatePlanSettingChangeRequestHubDto', 'AutoCreatePlanSettingChangeResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetMonitoringDomainList = (requestData: GetMonitoringDomainListRequestHubDto, receiveCallBack: (data: GetMonitoringDomainListResponseHubDto) => void, receiveErrorCallBack: (data: GetMonitoringDomainListResponseHubDto) => void, noSessionCallBack: (data: GetMonitoringDomainListResponseHubDto) => void, completeCallBack: (data: GetMonitoringDomainListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: GetMonitoringDomainListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: GetMonitoringDomainListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainMonitoringHub/GetMonitoringDomainList';

        let responseData: GetMonitoringDomainListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainMonitoringHub', 'GetMonitoringDomainList', 'GetMonitoringDomainListRequestHubDto', 'GetMonitoringDomainListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainMonitoringHub', 'GetMonitoringDomainList', 'GetMonitoringDomainListRequestHubDto', 'GetMonitoringDomainListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainMonitoringHub', 'GetMonitoringDomainList', 'GetMonitoringDomainListRequestHubDto', 'GetMonitoringDomainListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public DeleteDomainMonitoring = (requestData: DeleteDomainMonitoringRequestHubDto, receiveCallBack: (data: DeleteDomainMonitoringResponseHubDto) => void, receiveErrorCallBack: (data: DeleteDomainMonitoringResponseHubDto) => void, noSessionCallBack: (data: DeleteDomainMonitoringResponseHubDto) => void, completeCallBack: (data: DeleteDomainMonitoringResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DeleteDomainMonitoringResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DeleteDomainMonitoringResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainMonitoringHub/DeleteDomainMonitoring';

        let responseData: DeleteDomainMonitoringResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainMonitoringHub', 'DeleteDomainMonitoring', 'DeleteDomainMonitoringRequestHubDto', 'DeleteDomainMonitoringResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainMonitoringHub', 'DeleteDomainMonitoring', 'DeleteDomainMonitoringRequestHubDto', 'DeleteDomainMonitoringResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainMonitoringHub', 'DeleteDomainMonitoring', 'DeleteDomainMonitoringRequestHubDto', 'DeleteDomainMonitoringResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }

}



export class DomainProtectHubManager {
    /**
     * コンストラクタ
     */
    constructor(private http: HttpClient) {
    }

	// リソース破棄
    public Dispose = (): void => {
    }



    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ProtectDomainList = (requestData: DomainProtectDomainListRequestHubDto, receiveCallBack: (data: DomainProtectDomainListResponseHubDto) => void, receiveErrorCallBack: (data: DomainProtectDomainListResponseHubDto) => void, noSessionCallBack: (data: DomainProtectDomainListResponseHubDto) => void, completeCallBack: (data: DomainProtectDomainListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainProtectDomainListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainProtectDomainListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainProtectHub/ProtectDomainList';

        let responseData: DomainProtectDomainListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainProtectHub', 'ProtectDomainList', 'DomainProtectDomainListRequestHubDto', 'DomainProtectDomainListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainProtectHub', 'ProtectDomainList', 'DomainProtectDomainListRequestHubDto', 'DomainProtectDomainListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainProtectHub', 'ProtectDomainList', 'DomainProtectDomainListRequestHubDto', 'DomainProtectDomainListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ProtectPrice = (requestData: DomainProtectPriceRequestHubDto, receiveCallBack: (data: DomainProtectPriceResponseHubDto) => void, receiveErrorCallBack: (data: DomainProtectPriceResponseHubDto) => void, noSessionCallBack: (data: DomainProtectPriceResponseHubDto) => void, completeCallBack: (data: DomainProtectPriceResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainProtectPriceResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainProtectPriceResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainProtectHub/ProtectPrice';

        let responseData: DomainProtectPriceResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainProtectHub', 'ProtectPrice', 'DomainProtectPriceRequestHubDto', 'DomainProtectPriceResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainProtectHub', 'ProtectPrice', 'DomainProtectPriceRequestHubDto', 'DomainProtectPriceResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainProtectHub', 'ProtectPrice', 'DomainProtectPriceRequestHubDto', 'DomainProtectPriceResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ProtectValidator = (requestData: DomainProtectValidatorRequestHubDto, receiveCallBack: (data: DomainProtectValidatorResponseHubDto) => void, receiveErrorCallBack: (data: DomainProtectValidatorResponseHubDto) => void, noSessionCallBack: (data: DomainProtectValidatorResponseHubDto) => void, completeCallBack: (data: DomainProtectValidatorResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainProtectValidatorResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainProtectValidatorResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainProtectHub/ProtectValidator';

        let responseData: DomainProtectValidatorResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainProtectHub', 'ProtectValidator', 'DomainProtectValidatorRequestHubDto', 'DomainProtectValidatorResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainProtectHub', 'ProtectValidator', 'DomainProtectValidatorRequestHubDto', 'DomainProtectValidatorResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainProtectHub', 'ProtectValidator', 'DomainProtectValidatorRequestHubDto', 'DomainProtectValidatorResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ProtectApply = (requestData: DomainProtectRequestHubDto, receiveCallBack: (data: DomainProtectResponseHubDto) => void, receiveErrorCallBack: (data: DomainProtectResponseHubDto) => void, noSessionCallBack: (data: DomainProtectResponseHubDto) => void, completeCallBack: (data: DomainProtectResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainProtectResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainProtectResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainProtectHub/ProtectApply';

        let responseData: DomainProtectResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainProtectHub', 'ProtectApply', 'DomainProtectRequestHubDto', 'DomainProtectResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainProtectHub', 'ProtectApply', 'DomainProtectRequestHubDto', 'DomainProtectResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainProtectHub', 'ProtectApply', 'DomainProtectRequestHubDto', 'DomainProtectResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ProtectUpdateDomainList = (requestData: DomainProtectUpdateDomainListRequestHubDto, receiveCallBack: (data: DomainProtectUpdateDomainListResponseHubDto) => void, receiveErrorCallBack: (data: DomainProtectUpdateDomainListResponseHubDto) => void, noSessionCallBack: (data: DomainProtectUpdateDomainListResponseHubDto) => void, completeCallBack: (data: DomainProtectUpdateDomainListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainProtectUpdateDomainListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainProtectUpdateDomainListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainProtectHub/ProtectUpdateDomainList';

        let responseData: DomainProtectUpdateDomainListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainProtectHub', 'ProtectUpdateDomainList', 'DomainProtectUpdateDomainListRequestHubDto', 'DomainProtectUpdateDomainListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainProtectHub', 'ProtectUpdateDomainList', 'DomainProtectUpdateDomainListRequestHubDto', 'DomainProtectUpdateDomainListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainProtectHub', 'ProtectUpdateDomainList', 'DomainProtectUpdateDomainListRequestHubDto', 'DomainProtectUpdateDomainListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ProtectUpdateValidator = (requestData: DomainProtectUpdateValidatorRequestHubDto, receiveCallBack: (data: DomainProtectUpdateValidatorResponseHubDto) => void, receiveErrorCallBack: (data: DomainProtectUpdateValidatorResponseHubDto) => void, noSessionCallBack: (data: DomainProtectUpdateValidatorResponseHubDto) => void, completeCallBack: (data: DomainProtectUpdateValidatorResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainProtectUpdateValidatorResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainProtectUpdateValidatorResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainProtectHub/ProtectUpdateValidator';

        let responseData: DomainProtectUpdateValidatorResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainProtectHub', 'ProtectUpdateValidator', 'DomainProtectUpdateValidatorRequestHubDto', 'DomainProtectUpdateValidatorResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainProtectHub', 'ProtectUpdateValidator', 'DomainProtectUpdateValidatorRequestHubDto', 'DomainProtectUpdateValidatorResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainProtectHub', 'ProtectUpdateValidator', 'DomainProtectUpdateValidatorRequestHubDto', 'DomainProtectUpdateValidatorResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ProtectUpdate = (requestData: DomainProtectUpdateRequestHubDto, receiveCallBack: (data: DomainProtectUpdateResponseHubDto) => void, receiveErrorCallBack: (data: DomainProtectUpdateResponseHubDto) => void, noSessionCallBack: (data: DomainProtectUpdateResponseHubDto) => void, completeCallBack: (data: DomainProtectUpdateResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainProtectUpdateResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainProtectUpdateResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainProtectHub/ProtectUpdate';

        let responseData: DomainProtectUpdateResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainProtectHub', 'ProtectUpdate', 'DomainProtectUpdateRequestHubDto', 'DomainProtectUpdateResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainProtectHub', 'ProtectUpdate', 'DomainProtectUpdateRequestHubDto', 'DomainProtectUpdateResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainProtectHub', 'ProtectUpdate', 'DomainProtectUpdateRequestHubDto', 'DomainProtectUpdateResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ProtectUpdateInfo = (requestData: DomainProtectUpdateInfoRequestHubDto, receiveCallBack: (data: DomainProtectUpdateInfoResponseHubDto) => void, receiveErrorCallBack: (data: DomainProtectUpdateInfoResponseHubDto) => void, noSessionCallBack: (data: DomainProtectUpdateInfoResponseHubDto) => void, completeCallBack: (data: DomainProtectUpdateInfoResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainProtectUpdateInfoResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainProtectUpdateInfoResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainProtectHub/ProtectUpdateInfo';

        let responseData: DomainProtectUpdateInfoResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainProtectHub', 'ProtectUpdateInfo', 'DomainProtectUpdateInfoRequestHubDto', 'DomainProtectUpdateInfoResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainProtectHub', 'ProtectUpdateInfo', 'DomainProtectUpdateInfoRequestHubDto', 'DomainProtectUpdateInfoResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainProtectHub', 'ProtectUpdateInfo', 'DomainProtectUpdateInfoRequestHubDto', 'DomainProtectUpdateInfoResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ProtectUpdateApproval = (requestData: DomainProtectUpdateApprovalRequestHubDto, receiveCallBack: (data: DomainProtectUpdateApprovalResponseHubDto) => void, receiveErrorCallBack: (data: DomainProtectUpdateApprovalResponseHubDto) => void, noSessionCallBack: (data: DomainProtectUpdateApprovalResponseHubDto) => void, completeCallBack: (data: DomainProtectUpdateApprovalResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainProtectUpdateApprovalResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainProtectUpdateApprovalResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainProtectHub/ProtectUpdateApproval';

        let responseData: DomainProtectUpdateApprovalResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainProtectHub', 'ProtectUpdateApproval', 'DomainProtectUpdateApprovalRequestHubDto', 'DomainProtectUpdateApprovalResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainProtectHub', 'ProtectUpdateApproval', 'DomainProtectUpdateApprovalRequestHubDto', 'DomainProtectUpdateApprovalResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainProtectHub', 'ProtectUpdateApproval', 'DomainProtectUpdateApprovalRequestHubDto', 'DomainProtectUpdateApprovalResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }

}



export class ItemHubManager {
    /**
     * コンストラクタ
     */
    constructor(private http: HttpClient) {
    }

	// リソース破棄
    public Dispose = (): void => {
    }



    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public CheckExistItemPaymentFailure = (receiveCallBack: (data: CheckExistItemPaymentFailureResponseHubDto) => void, receiveErrorCallBack: (data: CheckExistItemPaymentFailureResponseHubDto) => void, noSessionCallBack: (data: CheckExistItemPaymentFailureResponseHubDto) => void, completeCallBack: (data: CheckExistItemPaymentFailureResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: CheckExistItemPaymentFailureResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: CheckExistItemPaymentFailureResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/ItemHub/CheckExistItemPaymentFailure';

        let responseData: CheckExistItemPaymentFailureResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('ItemHub', 'CheckExistItemPaymentFailure', null, 'CheckExistItemPaymentFailureResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('ItemHub', 'CheckExistItemPaymentFailure', null, 'CheckExistItemPaymentFailureResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('ItemHub', 'CheckExistItemPaymentFailure', null, 'CheckExistItemPaymentFailureResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public UpdateAllStatusesToApproval = (receiveCallBack: (data: UpdateAllStatusesToApprovalResponseHubDto) => void, receiveErrorCallBack: (data: UpdateAllStatusesToApprovalResponseHubDto) => void, noSessionCallBack: (data: UpdateAllStatusesToApprovalResponseHubDto) => void, completeCallBack: (data: UpdateAllStatusesToApprovalResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: UpdateAllStatusesToApprovalResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: UpdateAllStatusesToApprovalResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/ItemHub/UpdateAllStatusesToApproval';

        let responseData: UpdateAllStatusesToApprovalResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('ItemHub', 'UpdateAllStatusesToApproval', null, 'UpdateAllStatusesToApprovalResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('ItemHub', 'UpdateAllStatusesToApproval', null, 'UpdateAllStatusesToApprovalResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('ItemHub', 'UpdateAllStatusesToApproval', null, 'UpdateAllStatusesToApprovalResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }

}



export class SpecialCancellationHubManager {
    /**
     * コンストラクタ
     */
    constructor(private http: HttpClient) {
    }

	// リソース破棄
    public Dispose = (): void => {
    }



    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public SendSpecialCancellationInfo = (requestData: SpecialCancellationInfoRequestHubDto, receiveCallBack: (data: SpecialCancellationInfoResponseHubDto) => void, receiveErrorCallBack: (data: SpecialCancellationInfoResponseHubDto) => void, noSessionCallBack: (data: SpecialCancellationInfoResponseHubDto) => void, completeCallBack: (data: SpecialCancellationInfoResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SpecialCancellationInfoResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SpecialCancellationInfoResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SpecialCancellationHub/SendSpecialCancellationInfo';

        let responseData: SpecialCancellationInfoResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SpecialCancellationHub', 'SendSpecialCancellationInfo', 'SpecialCancellationInfoRequestHubDto', 'SpecialCancellationInfoResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SpecialCancellationHub', 'SendSpecialCancellationInfo', 'SpecialCancellationInfoRequestHubDto', 'SpecialCancellationInfoResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SpecialCancellationHub', 'SendSpecialCancellationInfo', 'SpecialCancellationInfoRequestHubDto', 'SpecialCancellationInfoResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }

}



export class DomainRefundHubManager {
    /**
     * コンストラクタ
     */
    constructor(private http: HttpClient) {
    }

	// リソース破棄
    public Dispose = (): void => {
    }



    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public SendDomainRefund = (requestData: DomainRefundRequestHubDto, receiveCallBack: (data: DomainRefundResponseHubDto) => void, receiveErrorCallBack: (data: DomainRefundResponseHubDto) => void, noSessionCallBack: (data: DomainRefundResponseHubDto) => void, completeCallBack: (data: DomainRefundResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainRefundResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainRefundResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainRefundHub/SendDomainRefund';

        let responseData: DomainRefundResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainRefundHub', 'SendDomainRefund', 'DomainRefundRequestHubDto', 'DomainRefundResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainRefundHub', 'SendDomainRefund', 'DomainRefundRequestHubDto', 'DomainRefundResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainRefundHub', 'SendDomainRefund', 'DomainRefundRequestHubDto', 'DomainRefundResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }

}



export class LineHubManager {
    /**
     * コンストラクタ
     */
    constructor(private http: HttpClient) {
    }

	// リソース破棄
    public Dispose = (): void => {
    }



    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public CreateLineAccount = (requestData: CreateLineAccountRequestHubDto, receiveCallBack: (data: CreateLineAccountResponseHubDto) => void, receiveErrorCallBack: (data: CreateLineAccountResponseHubDto) => void, noSessionCallBack: (data: CreateLineAccountResponseHubDto) => void, completeCallBack: (data: CreateLineAccountResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: CreateLineAccountResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: CreateLineAccountResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/LineHub/CreateLineAccount';

        let responseData: CreateLineAccountResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('LineHub', 'CreateLineAccount', 'CreateLineAccountRequestHubDto', 'CreateLineAccountResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('LineHub', 'CreateLineAccount', 'CreateLineAccountRequestHubDto', 'CreateLineAccountResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('LineHub', 'CreateLineAccount', 'CreateLineAccountRequestHubDto', 'CreateLineAccountResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }

}



export class PromotionHubManager {
    /**
     * コンストラクタ
     */
    constructor(private http: HttpClient) {
    }

	// リソース破棄
    public Dispose = (): void => {
    }



    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public DomainWithDefaultNameServer = (requestData: DomainWithDefaultNameServerRequestHubDto, receiveCallBack: (data: DomainWithDefaultNameServerResponseHubDto) => void, receiveErrorCallBack: (data: DomainWithDefaultNameServerResponseHubDto) => void, noSessionCallBack: (data: DomainWithDefaultNameServerResponseHubDto) => void, completeCallBack: (data: DomainWithDefaultNameServerResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainWithDefaultNameServerResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainWithDefaultNameServerResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/PromotionHub/DomainWithDefaultNameServer';

        let responseData: DomainWithDefaultNameServerResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('PromotionHub', 'DomainWithDefaultNameServer', 'DomainWithDefaultNameServerRequestHubDto', 'DomainWithDefaultNameServerResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('PromotionHub', 'DomainWithDefaultNameServer', 'DomainWithDefaultNameServerRequestHubDto', 'DomainWithDefaultNameServerResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('PromotionHub', 'DomainWithDefaultNameServer', 'DomainWithDefaultNameServerRequestHubDto', 'DomainWithDefaultNameServerResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public CheckAllNSDefault = (receiveCallBack: (data: CheckAllNSDefaultResponseHubDto) => void, receiveErrorCallBack: (data: CheckAllNSDefaultResponseHubDto) => void, noSessionCallBack: (data: CheckAllNSDefaultResponseHubDto) => void, completeCallBack: (data: CheckAllNSDefaultResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: CheckAllNSDefaultResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: CheckAllNSDefaultResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/PromotionHub/CheckAllNSDefault';

        let responseData: CheckAllNSDefaultResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('PromotionHub', 'CheckAllNSDefault', null, 'CheckAllNSDefaultResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('PromotionHub', 'CheckAllNSDefault', null, 'CheckAllNSDefaultResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('PromotionHub', 'CheckAllNSDefault', null, 'CheckAllNSDefaultResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetLoginCampaignPromotionType = (receiveCallBack: (data: GetLoginCampaignPromotionTypeResponseHubDto) => void, receiveErrorCallBack: (data: GetLoginCampaignPromotionTypeResponseHubDto) => void, noSessionCallBack: (data: GetLoginCampaignPromotionTypeResponseHubDto) => void, completeCallBack: (data: GetLoginCampaignPromotionTypeResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: GetLoginCampaignPromotionTypeResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: GetLoginCampaignPromotionTypeResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/PromotionHub/GetLoginCampaignPromotionType';

        let responseData: GetLoginCampaignPromotionTypeResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('PromotionHub', 'GetLoginCampaignPromotionType', null, 'GetLoginCampaignPromotionTypeResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('PromotionHub', 'GetLoginCampaignPromotionType', null, 'GetLoginCampaignPromotionTypeResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('PromotionHub', 'GetLoginCampaignPromotionType', null, 'GetLoginCampaignPromotionTypeResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }

}



export class CampaignHubManager {
    /**
     * コンストラクタ
     */
    constructor(private http: HttpClient) {
    }

	// リソース破棄
    public Dispose = (): void => {
    }



    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetCampaignMessages = (receiveCallBack: (data: CampaignMessageResponseHubDto) => void, receiveErrorCallBack: (data: CampaignMessageResponseHubDto) => void, noSessionCallBack: (data: CampaignMessageResponseHubDto) => void, completeCallBack: (data: CampaignMessageResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: CampaignMessageResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: CampaignMessageResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/CampaignHub/GetCampaignMessages';

        let responseData: CampaignMessageResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('CampaignHub', 'GetCampaignMessages', null, 'CampaignMessageResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('CampaignHub', 'GetCampaignMessages', null, 'CampaignMessageResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('CampaignHub', 'GetCampaignMessages', null, 'CampaignMessageResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetPCLoginCampaignBanner = (receiveCallBack: (data: GetLoginCampaignBannerResponseHubDto) => void, receiveErrorCallBack: (data: GetLoginCampaignBannerResponseHubDto) => void, noSessionCallBack: (data: GetLoginCampaignBannerResponseHubDto) => void, completeCallBack: (data: GetLoginCampaignBannerResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: GetLoginCampaignBannerResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: GetLoginCampaignBannerResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/CampaignHub/GetPCLoginCampaignBanner';

        let responseData: GetLoginCampaignBannerResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('CampaignHub', 'GetPCLoginCampaignBanner', null, 'GetLoginCampaignBannerResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('CampaignHub', 'GetPCLoginCampaignBanner', null, 'GetLoginCampaignBannerResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('CampaignHub', 'GetPCLoginCampaignBanner', null, 'GetLoginCampaignBannerResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetSPLoginCampaignBanner = (receiveCallBack: (data: GetLoginCampaignBannerResponseHubDto) => void, receiveErrorCallBack: (data: GetLoginCampaignBannerResponseHubDto) => void, noSessionCallBack: (data: GetLoginCampaignBannerResponseHubDto) => void, completeCallBack: (data: GetLoginCampaignBannerResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: GetLoginCampaignBannerResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: GetLoginCampaignBannerResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/CampaignHub/GetSPLoginCampaignBanner';

        let responseData: GetLoginCampaignBannerResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('CampaignHub', 'GetSPLoginCampaignBanner', null, 'GetLoginCampaignBannerResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('CampaignHub', 'GetSPLoginCampaignBanner', null, 'GetLoginCampaignBannerResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('CampaignHub', 'GetSPLoginCampaignBanner', null, 'GetLoginCampaignBannerResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetLoginCampaignInfo = (receiveCallBack: (data: GetLoginCampaignInfoResponseHubDto) => void, receiveErrorCallBack: (data: GetLoginCampaignInfoResponseHubDto) => void, noSessionCallBack: (data: GetLoginCampaignInfoResponseHubDto) => void, completeCallBack: (data: GetLoginCampaignInfoResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: GetLoginCampaignInfoResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: GetLoginCampaignInfoResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/CampaignHub/GetLoginCampaignInfo';

        let responseData: GetLoginCampaignInfoResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('CampaignHub', 'GetLoginCampaignInfo', null, 'GetLoginCampaignInfoResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('CampaignHub', 'GetLoginCampaignInfo', null, 'GetLoginCampaignInfoResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('CampaignHub', 'GetLoginCampaignInfo', null, 'GetLoginCampaignInfoResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public LoginCampaignEntry = (requestData: LoginCampaignEntryRequestHubDto, receiveCallBack: (data: LoginCampaignEntryResponseHubDto) => void, receiveErrorCallBack: (data: LoginCampaignEntryResponseHubDto) => void, noSessionCallBack: (data: LoginCampaignEntryResponseHubDto) => void, completeCallBack: (data: LoginCampaignEntryResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: LoginCampaignEntryResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: LoginCampaignEntryResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/CampaignHub/LoginCampaignEntry';

        let responseData: LoginCampaignEntryResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('CampaignHub', 'LoginCampaignEntry', 'LoginCampaignEntryRequestHubDto', 'LoginCampaignEntryResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('CampaignHub', 'LoginCampaignEntry', 'LoginCampaignEntryRequestHubDto', 'LoginCampaignEntryResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('CampaignHub', 'LoginCampaignEntry', 'LoginCampaignEntryRequestHubDto', 'LoginCampaignEntryResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }

}



export class SecondhandDealerLicenseHubManager {
    /**
     * コンストラクタ
     */
    constructor(private http: HttpClient) {
    }

	// リソース破棄
    public Dispose = (): void => {
    }



    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public SecondhandDealerLicenseList = (requestData: SecondhandDealerLicenseListRequestHubDto, receiveCallBack: (data: SecondhandDealerLicenseListResponseHubDto) => void, receiveErrorCallBack: (data: SecondhandDealerLicenseListResponseHubDto) => void, noSessionCallBack: (data: SecondhandDealerLicenseListResponseHubDto) => void, completeCallBack: (data: SecondhandDealerLicenseListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SecondhandDealerLicenseListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SecondhandDealerLicenseListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SecondhandDealerLicenseHub/SecondhandDealerLicenseList';

        let responseData: SecondhandDealerLicenseListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SecondhandDealerLicenseHub', 'SecondhandDealerLicenseList', 'SecondhandDealerLicenseListRequestHubDto', 'SecondhandDealerLicenseListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SecondhandDealerLicenseHub', 'SecondhandDealerLicenseList', 'SecondhandDealerLicenseListRequestHubDto', 'SecondhandDealerLicenseListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SecondhandDealerLicenseHub', 'SecondhandDealerLicenseList', 'SecondhandDealerLicenseListRequestHubDto', 'SecondhandDealerLicenseListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public SecondhandDealerLicenseAccountInfo = (receiveCallBack: (data: SecondhandDealerLicenseAccountInfoResponseHubDto) => void, receiveErrorCallBack: (data: SecondhandDealerLicenseAccountInfoResponseHubDto) => void, noSessionCallBack: (data: SecondhandDealerLicenseAccountInfoResponseHubDto) => void, completeCallBack: (data: SecondhandDealerLicenseAccountInfoResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SecondhandDealerLicenseAccountInfoResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SecondhandDealerLicenseAccountInfoResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SecondhandDealerLicenseHub/SecondhandDealerLicenseAccountInfo';

        let responseData: SecondhandDealerLicenseAccountInfoResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SecondhandDealerLicenseHub', 'SecondhandDealerLicenseAccountInfo', null, 'SecondhandDealerLicenseAccountInfoResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SecondhandDealerLicenseHub', 'SecondhandDealerLicenseAccountInfo', null, 'SecondhandDealerLicenseAccountInfoResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SecondhandDealerLicenseHub', 'SecondhandDealerLicenseAccountInfo', null, 'SecondhandDealerLicenseAccountInfoResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public SecondhandDealerLicenseWhoisInfo = (requestData: SecondhandDealerLicenseWhoisInfoRequestDto, receiveCallBack: (data: SecondhandDealerLicenseWhoisInfoResponseHubDto) => void, receiveErrorCallBack: (data: SecondhandDealerLicenseWhoisInfoResponseHubDto) => void, noSessionCallBack: (data: SecondhandDealerLicenseWhoisInfoResponseHubDto) => void, completeCallBack: (data: SecondhandDealerLicenseWhoisInfoResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SecondhandDealerLicenseWhoisInfoResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SecondhandDealerLicenseWhoisInfoResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SecondhandDealerLicenseHub/SecondhandDealerLicenseWhoisInfo';

        let responseData: SecondhandDealerLicenseWhoisInfoResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SecondhandDealerLicenseHub', 'SecondhandDealerLicenseWhoisInfo', 'SecondhandDealerLicenseWhoisInfoRequestDto', 'SecondhandDealerLicenseWhoisInfoResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SecondhandDealerLicenseHub', 'SecondhandDealerLicenseWhoisInfo', 'SecondhandDealerLicenseWhoisInfoRequestDto', 'SecondhandDealerLicenseWhoisInfoResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SecondhandDealerLicenseHub', 'SecondhandDealerLicenseWhoisInfo', 'SecondhandDealerLicenseWhoisInfoRequestDto', 'SecondhandDealerLicenseWhoisInfoResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }

}



export class DomainRenewHubManager {
    /**
     * コンストラクタ
     */
    constructor(private http: HttpClient) {
    }

	// リソース破棄
    public Dispose = (): void => {
    }



    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetDomainRenewListAndCoupon = (requestData: DomainRenewListAndCouponRequestHubDto, receiveCallBack: (data: DomainRenewListAndCouponResponseHubDto) => void, receiveErrorCallBack: (data: DomainRenewListAndCouponResponseHubDto) => void, noSessionCallBack: (data: DomainRenewListAndCouponResponseHubDto) => void, completeCallBack: (data: DomainRenewListAndCouponResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainRenewListAndCouponResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainRenewListAndCouponResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainRenewHub/GetDomainRenewListAndCoupon';

        let responseData: DomainRenewListAndCouponResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainRenewHub', 'GetDomainRenewListAndCoupon', 'DomainRenewListAndCouponRequestHubDto', 'DomainRenewListAndCouponResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainRenewHub', 'GetDomainRenewListAndCoupon', 'DomainRenewListAndCouponRequestHubDto', 'DomainRenewListAndCouponResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainRenewHub', 'GetDomainRenewListAndCoupon', 'DomainRenewListAndCouponRequestHubDto', 'DomainRenewListAndCouponResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetDomainEarlyExpiration = (receiveCallBack: (data: DomainRenewListResponseHubDto) => void, receiveErrorCallBack: (data: DomainRenewListResponseHubDto) => void, noSessionCallBack: (data: DomainRenewListResponseHubDto) => void, completeCallBack: (data: DomainRenewListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainRenewListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainRenewListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainRenewHub/GetDomainEarlyExpiration';

        let responseData: DomainRenewListResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainRenewHub', 'GetDomainEarlyExpiration', null, 'DomainRenewListResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainRenewHub', 'GetDomainEarlyExpiration', null, 'DomainRenewListResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainRenewHub', 'GetDomainEarlyExpiration', null, 'DomainRenewListResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetDomainRenewList = (requestData: DomainRenewListRequestHubDto, receiveCallBack: (data: DomainRenewListResponseHubDto) => void, receiveErrorCallBack: (data: DomainRenewListResponseHubDto) => void, noSessionCallBack: (data: DomainRenewListResponseHubDto) => void, completeCallBack: (data: DomainRenewListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainRenewListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainRenewListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainRenewHub/GetDomainRenewList';

        let responseData: DomainRenewListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainRenewHub', 'GetDomainRenewList', 'DomainRenewListRequestHubDto', 'DomainRenewListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainRenewHub', 'GetDomainRenewList', 'DomainRenewListRequestHubDto', 'DomainRenewListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainRenewHub', 'GetDomainRenewList', 'DomainRenewListRequestHubDto', 'DomainRenewListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public CheckDomainRenewHostingOrder = (requestData: DomainRenewHostingOrderCheckRequestHubDto, receiveCallBack: (data: DomainRenewHostingOrderCheckResponseHubDto) => void, receiveErrorCallBack: (data: DomainRenewHostingOrderCheckResponseHubDto) => void, noSessionCallBack: (data: DomainRenewHostingOrderCheckResponseHubDto) => void, completeCallBack: (data: DomainRenewHostingOrderCheckResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainRenewHostingOrderCheckResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainRenewHostingOrderCheckResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainRenewHub/CheckDomainRenewHostingOrder';

        let responseData: DomainRenewHostingOrderCheckResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainRenewHub', 'CheckDomainRenewHostingOrder', 'DomainRenewHostingOrderCheckRequestHubDto', 'DomainRenewHostingOrderCheckResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainRenewHub', 'CheckDomainRenewHostingOrder', 'DomainRenewHostingOrderCheckRequestHubDto', 'DomainRenewHostingOrderCheckResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainRenewHub', 'CheckDomainRenewHostingOrder', 'DomainRenewHostingOrderCheckRequestHubDto', 'DomainRenewHostingOrderCheckResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetDomainRenewPrice = (requestData: DomainRenewPriceRequestHubDto, receiveCallBack: (data: DomainRenewPriceResponseHubDto) => void, receiveErrorCallBack: (data: DomainRenewPriceResponseHubDto) => void, noSessionCallBack: (data: DomainRenewPriceResponseHubDto) => void, completeCallBack: (data: DomainRenewPriceResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainRenewPriceResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainRenewPriceResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainRenewHub/GetDomainRenewPrice';

        let responseData: DomainRenewPriceResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainRenewHub', 'GetDomainRenewPrice', 'DomainRenewPriceRequestHubDto', 'DomainRenewPriceResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainRenewHub', 'GetDomainRenewPrice', 'DomainRenewPriceRequestHubDto', 'DomainRenewPriceResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainRenewHub', 'GetDomainRenewPrice', 'DomainRenewPriceRequestHubDto', 'DomainRenewPriceResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ValidUpdateDomainRenew = (requestData: DomainRenewUpdateValidationRequestHubDto, receiveCallBack: (data: DomainRenewUpdateValidationResponseHubDto) => void, receiveErrorCallBack: (data: DomainRenewUpdateValidationResponseHubDto) => void, noSessionCallBack: (data: DomainRenewUpdateValidationResponseHubDto) => void, completeCallBack: (data: DomainRenewUpdateValidationResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainRenewUpdateValidationResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainRenewUpdateValidationResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainRenewHub/ValidUpdateDomainRenew';

        let responseData: DomainRenewUpdateValidationResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainRenewHub', 'ValidUpdateDomainRenew', 'DomainRenewUpdateValidationRequestHubDto', 'DomainRenewUpdateValidationResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainRenewHub', 'ValidUpdateDomainRenew', 'DomainRenewUpdateValidationRequestHubDto', 'DomainRenewUpdateValidationResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainRenewHub', 'ValidUpdateDomainRenew', 'DomainRenewUpdateValidationRequestHubDto', 'DomainRenewUpdateValidationResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public UpdateDomainRenew = (requestData: DomainRenewUpdateRequestHubDto, receiveCallBack: (data: DomainRenewUpdateResponseHubDto) => void, receiveErrorCallBack: (data: DomainRenewUpdateResponseHubDto) => void, noSessionCallBack: (data: DomainRenewUpdateResponseHubDto) => void, completeCallBack: (data: DomainRenewUpdateResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainRenewUpdateResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainRenewUpdateResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainRenewHub/UpdateDomainRenew';

        let responseData: DomainRenewUpdateResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainRenewHub', 'UpdateDomainRenew', 'DomainRenewUpdateRequestHubDto', 'DomainRenewUpdateResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainRenewHub', 'UpdateDomainRenew', 'DomainRenewUpdateRequestHubDto', 'DomainRenewUpdateResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainRenewHub', 'UpdateDomainRenew', 'DomainRenewUpdateRequestHubDto', 'DomainRenewUpdateResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetRestoreDomainList = (requestData: DomainRestoreListRequestHubDto, receiveCallBack: (data: DomainRestoreListResponseHubDto) => void, receiveErrorCallBack: (data: DomainRestoreListResponseHubDto) => void, noSessionCallBack: (data: DomainRestoreListResponseHubDto) => void, completeCallBack: (data: DomainRestoreListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainRestoreListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainRestoreListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainRenewHub/GetRestoreDomainList';

        let responseData: DomainRestoreListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainRenewHub', 'GetRestoreDomainList', 'DomainRestoreListRequestHubDto', 'DomainRestoreListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainRenewHub', 'GetRestoreDomainList', 'DomainRestoreListRequestHubDto', 'DomainRestoreListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainRenewHub', 'GetRestoreDomainList', 'DomainRestoreListRequestHubDto', 'DomainRestoreListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetRestoreDomainPrice = (requestData: DomainRestorePriceRequestHubDto, receiveCallBack: (data: DomainRestorePriceResponseHubDto) => void, receiveErrorCallBack: (data: DomainRestorePriceResponseHubDto) => void, noSessionCallBack: (data: DomainRestorePriceResponseHubDto) => void, completeCallBack: (data: DomainRestorePriceResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainRestorePriceResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainRestorePriceResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainRenewHub/GetRestoreDomainPrice';

        let responseData: DomainRestorePriceResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainRenewHub', 'GetRestoreDomainPrice', 'DomainRestorePriceRequestHubDto', 'DomainRestorePriceResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainRenewHub', 'GetRestoreDomainPrice', 'DomainRestorePriceRequestHubDto', 'DomainRestorePriceResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainRenewHub', 'GetRestoreDomainPrice', 'DomainRestorePriceRequestHubDto', 'DomainRestorePriceResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetRestoreDomainPriceAll = (requestData: DomainRestorePriceAllRequestHubDto, receiveCallBack: (data: DomainRestorePriceAllResponseHubDto) => void, receiveErrorCallBack: (data: DomainRestorePriceAllResponseHubDto) => void, noSessionCallBack: (data: DomainRestorePriceAllResponseHubDto) => void, completeCallBack: (data: DomainRestorePriceAllResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainRestorePriceAllResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainRestorePriceAllResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainRenewHub/GetRestoreDomainPriceAll';

        let responseData: DomainRestorePriceAllResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainRenewHub', 'GetRestoreDomainPriceAll', 'DomainRestorePriceAllRequestHubDto', 'DomainRestorePriceAllResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainRenewHub', 'GetRestoreDomainPriceAll', 'DomainRestorePriceAllRequestHubDto', 'DomainRestorePriceAllResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainRenewHub', 'GetRestoreDomainPriceAll', 'DomainRestorePriceAllRequestHubDto', 'DomainRestorePriceAllResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ValidationRestoreDomain = (requestData: DomainRestoreUpdateValidationRequestHubDto, receiveCallBack: (data: DomainRestoreUpdateValidationResponseHubDto) => void, receiveErrorCallBack: (data: DomainRestoreUpdateValidationResponseHubDto) => void, noSessionCallBack: (data: DomainRestoreUpdateValidationResponseHubDto) => void, completeCallBack: (data: DomainRestoreUpdateValidationResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainRestoreUpdateValidationResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainRestoreUpdateValidationResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainRenewHub/ValidationRestoreDomain';

        let responseData: DomainRestoreUpdateValidationResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainRenewHub', 'ValidationRestoreDomain', 'DomainRestoreUpdateValidationRequestHubDto', 'DomainRestoreUpdateValidationResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainRenewHub', 'ValidationRestoreDomain', 'DomainRestoreUpdateValidationRequestHubDto', 'DomainRestoreUpdateValidationResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainRenewHub', 'ValidationRestoreDomain', 'DomainRestoreUpdateValidationRequestHubDto', 'DomainRestoreUpdateValidationResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public UpdateRestoreDomain = (requestData: DomainRestoreUpdateRequestHubDto, receiveCallBack: (data: DomainRestoreUpdateResponseHubDto) => void, receiveErrorCallBack: (data: DomainRestoreUpdateResponseHubDto) => void, noSessionCallBack: (data: DomainRestoreUpdateResponseHubDto) => void, completeCallBack: (data: DomainRestoreUpdateResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainRestoreUpdateResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainRestoreUpdateResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainRenewHub/UpdateRestoreDomain';

        let responseData: DomainRestoreUpdateResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainRenewHub', 'UpdateRestoreDomain', 'DomainRestoreUpdateRequestHubDto', 'DomainRestoreUpdateResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainRenewHub', 'UpdateRestoreDomain', 'DomainRestoreUpdateRequestHubDto', 'DomainRestoreUpdateResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainRenewHub', 'UpdateRestoreDomain', 'DomainRestoreUpdateRequestHubDto', 'DomainRestoreUpdateResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public DomainRenewShowRibon = (requestData: DomainRenewShowPriceRibonRequestHubDto, receiveCallBack: (data: DomainRenewShowPriceRibonResponseHubDto) => void, receiveErrorCallBack: (data: DomainRenewShowPriceRibonResponseHubDto) => void, noSessionCallBack: (data: DomainRenewShowPriceRibonResponseHubDto) => void, completeCallBack: (data: DomainRenewShowPriceRibonResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainRenewShowPriceRibonResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainRenewShowPriceRibonResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainRenewHub/DomainRenewShowRibon';

        let responseData: DomainRenewShowPriceRibonResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainRenewHub', 'DomainRenewShowRibon', 'DomainRenewShowPriceRibonRequestHubDto', 'DomainRenewShowPriceRibonResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainRenewHub', 'DomainRenewShowRibon', 'DomainRenewShowPriceRibonRequestHubDto', 'DomainRenewShowPriceRibonResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainRenewHub', 'DomainRenewShowRibon', 'DomainRenewShowPriceRibonRequestHubDto', 'DomainRenewShowPriceRibonResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetGmoPointInfo = (receiveCallBack: (data: DomainRenewGMOPointInfoResponseHubDto) => void, receiveErrorCallBack: (data: DomainRenewGMOPointInfoResponseHubDto) => void, noSessionCallBack: (data: DomainRenewGMOPointInfoResponseHubDto) => void, completeCallBack: (data: DomainRenewGMOPointInfoResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainRenewGMOPointInfoResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainRenewGMOPointInfoResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainRenewHub/GetGmoPointInfo';

        let responseData: DomainRenewGMOPointInfoResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainRenewHub', 'GetGmoPointInfo', null, 'DomainRenewGMOPointInfoResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainRenewHub', 'GetGmoPointInfo', null, 'DomainRenewGMOPointInfoResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainRenewHub', 'GetGmoPointInfo', null, 'DomainRenewGMOPointInfoResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetMonitoringTextList = (receiveCallBack: (data: DomainRenewMonitoringTextListResponseHubDto) => void, receiveErrorCallBack: (data: DomainRenewMonitoringTextListResponseHubDto) => void, noSessionCallBack: (data: DomainRenewMonitoringTextListResponseHubDto) => void, completeCallBack: (data: DomainRenewMonitoringTextListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainRenewMonitoringTextListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainRenewMonitoringTextListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainRenewHub/GetMonitoringTextList';

        let responseData: DomainRenewMonitoringTextListResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainRenewHub', 'GetMonitoringTextList', null, 'DomainRenewMonitoringTextListResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainRenewHub', 'GetMonitoringTextList', null, 'DomainRenewMonitoringTextListResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainRenewHub', 'GetMonitoringTextList', null, 'DomainRenewMonitoringTextListResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetStatusOfFreeRegistDomain = (requestData: DomainRenewFreeRegistDomainRequestHubDto, receiveCallBack: (data: DomainRenewFreeRegistDomainResponseHubDto) => void, receiveErrorCallBack: (data: DomainRenewFreeRegistDomainResponseHubDto) => void, noSessionCallBack: (data: DomainRenewFreeRegistDomainResponseHubDto) => void, completeCallBack: (data: DomainRenewFreeRegistDomainResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainRenewFreeRegistDomainResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainRenewFreeRegistDomainResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainRenewHub/GetStatusOfFreeRegistDomain';

        let responseData: DomainRenewFreeRegistDomainResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainRenewHub', 'GetStatusOfFreeRegistDomain', 'DomainRenewFreeRegistDomainRequestHubDto', 'DomainRenewFreeRegistDomainResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainRenewHub', 'GetStatusOfFreeRegistDomain', 'DomainRenewFreeRegistDomainRequestHubDto', 'DomainRenewFreeRegistDomainResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainRenewHub', 'GetStatusOfFreeRegistDomain', 'DomainRenewFreeRegistDomainRequestHubDto', 'DomainRenewFreeRegistDomainResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public CreateDomain = (requestData: DomainRenewCreateDomainRequestHubDto, receiveCallBack: (data: DomainRenewCreateDomainResponseHubDto) => void, receiveErrorCallBack: (data: DomainRenewCreateDomainResponseHubDto) => void, noSessionCallBack: (data: DomainRenewCreateDomainResponseHubDto) => void, completeCallBack: (data: DomainRenewCreateDomainResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainRenewCreateDomainResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainRenewCreateDomainResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainRenewHub/CreateDomain';

        let responseData: DomainRenewCreateDomainResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainRenewHub', 'CreateDomain', 'DomainRenewCreateDomainRequestHubDto', 'DomainRenewCreateDomainResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainRenewHub', 'CreateDomain', 'DomainRenewCreateDomainRequestHubDto', 'DomainRenewCreateDomainResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainRenewHub', 'CreateDomain', 'DomainRenewCreateDomainRequestHubDto', 'DomainRenewCreateDomainResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }

}



export class DomainWhoisHubManager {
    /**
     * コンストラクタ
     */
    constructor(private http: HttpClient) {
    }

	// リソース破棄
    public Dispose = (): void => {
    }



    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public WhoisInfoUpdateList = (requestData: WhoisInfoUpdateListRequestHubDto, receiveCallBack: (data: WhoisInfoUpdateListResponseHubDto) => void, receiveErrorCallBack: (data: WhoisInfoUpdateListResponseHubDto) => void, noSessionCallBack: (data: WhoisInfoUpdateListResponseHubDto) => void, completeCallBack: (data: WhoisInfoUpdateListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: WhoisInfoUpdateListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: WhoisInfoUpdateListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainWhoisHub/WhoisInfoUpdateList';

        let responseData: WhoisInfoUpdateListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainWhoisHub', 'WhoisInfoUpdateList', 'WhoisInfoUpdateListRequestHubDto', 'WhoisInfoUpdateListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainWhoisHub', 'WhoisInfoUpdateList', 'WhoisInfoUpdateListRequestHubDto', 'WhoisInfoUpdateListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainWhoisHub', 'WhoisInfoUpdateList', 'WhoisInfoUpdateListRequestHubDto', 'WhoisInfoUpdateListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public WhoisInfoByDomainId = (requestData: WhoisInfoByDomainIdRequestHubDto, receiveCallBack: (data: WhoisInfoByDomainIdResponseHubDto) => void, receiveErrorCallBack: (data: WhoisInfoByDomainIdResponseHubDto) => void, noSessionCallBack: (data: WhoisInfoByDomainIdResponseHubDto) => void, completeCallBack: (data: WhoisInfoByDomainIdResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: WhoisInfoByDomainIdResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: WhoisInfoByDomainIdResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainWhoisHub/WhoisInfoByDomainId';

        let responseData: WhoisInfoByDomainIdResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainWhoisHub', 'WhoisInfoByDomainId', 'WhoisInfoByDomainIdRequestHubDto', 'WhoisInfoByDomainIdResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainWhoisHub', 'WhoisInfoByDomainId', 'WhoisInfoByDomainIdRequestHubDto', 'WhoisInfoByDomainIdResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainWhoisHub', 'WhoisInfoByDomainId', 'WhoisInfoByDomainIdRequestHubDto', 'WhoisInfoByDomainIdResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public WhoisContactChangeValidator = (requestData: WhoisChangeContactValidateRequestHubDto, receiveCallBack: (data: WhoisChangeContactValidateResponseHubDto) => void, receiveErrorCallBack: (data: WhoisChangeContactValidateResponseHubDto) => void, noSessionCallBack: (data: WhoisChangeContactValidateResponseHubDto) => void, completeCallBack: (data: WhoisChangeContactValidateResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: WhoisChangeContactValidateResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: WhoisChangeContactValidateResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainWhoisHub/WhoisContactChangeValidator';

        let responseData: WhoisChangeContactValidateResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainWhoisHub', 'WhoisContactChangeValidator', 'WhoisChangeContactValidateRequestHubDto', 'WhoisChangeContactValidateResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainWhoisHub', 'WhoisContactChangeValidator', 'WhoisChangeContactValidateRequestHubDto', 'WhoisChangeContactValidateResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainWhoisHub', 'WhoisContactChangeValidator', 'WhoisChangeContactValidateRequestHubDto', 'WhoisChangeContactValidateResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public WhoisContactChangeUpdate = (requestData: WhoisChangeContactUpdateRequestHubDto, receiveCallBack: (data: WhoisChangeContactUpdateResponseHubDto) => void, receiveErrorCallBack: (data: WhoisChangeContactUpdateResponseHubDto) => void, noSessionCallBack: (data: WhoisChangeContactUpdateResponseHubDto) => void, completeCallBack: (data: WhoisChangeContactUpdateResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: WhoisChangeContactUpdateResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: WhoisChangeContactUpdateResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainWhoisHub/WhoisContactChangeUpdate';

        let responseData: WhoisChangeContactUpdateResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainWhoisHub', 'WhoisContactChangeUpdate', 'WhoisChangeContactUpdateRequestHubDto', 'WhoisChangeContactUpdateResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainWhoisHub', 'WhoisContactChangeUpdate', 'WhoisChangeContactUpdateRequestHubDto', 'WhoisChangeContactUpdateResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainWhoisHub', 'WhoisContactChangeUpdate', 'WhoisChangeContactUpdateRequestHubDto', 'WhoisChangeContactUpdateResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public WhoisMailForwardDomainList = (requestData: WhoisMailForwardDomainListRequestHubDto, receiveCallBack: (data: WhoisMailForwardDomainListResponseHubDto) => void, receiveErrorCallBack: (data: WhoisMailForwardDomainListResponseHubDto) => void, noSessionCallBack: (data: WhoisMailForwardDomainListResponseHubDto) => void, completeCallBack: (data: WhoisMailForwardDomainListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: WhoisMailForwardDomainListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: WhoisMailForwardDomainListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainWhoisHub/WhoisMailForwardDomainList';

        let responseData: WhoisMailForwardDomainListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainWhoisHub', 'WhoisMailForwardDomainList', 'WhoisMailForwardDomainListRequestHubDto', 'WhoisMailForwardDomainListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainWhoisHub', 'WhoisMailForwardDomainList', 'WhoisMailForwardDomainListRequestHubDto', 'WhoisMailForwardDomainListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainWhoisHub', 'WhoisMailForwardDomainList', 'WhoisMailForwardDomainListRequestHubDto', 'WhoisMailForwardDomainListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public WhoisProtectList = (requestData: WhoisProtectListRequestHubDto, receiveCallBack: (data: WhoisProtectListResponseHubDto) => void, receiveErrorCallBack: (data: WhoisProtectListResponseHubDto) => void, noSessionCallBack: (data: WhoisProtectListResponseHubDto) => void, completeCallBack: (data: WhoisProtectListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: WhoisProtectListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: WhoisProtectListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainWhoisHub/WhoisProtectList';

        let responseData: WhoisProtectListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainWhoisHub', 'WhoisProtectList', 'WhoisProtectListRequestHubDto', 'WhoisProtectListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainWhoisHub', 'WhoisProtectList', 'WhoisProtectListRequestHubDto', 'WhoisProtectListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainWhoisHub', 'WhoisProtectList', 'WhoisProtectListRequestHubDto', 'WhoisProtectListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public WhoisProtectPrice = (requestData: WhoisProtectPriceRequestHubDto, receiveCallBack: (data: WhoisProtectPriceResponseHubDto) => void, receiveErrorCallBack: (data: WhoisProtectPriceResponseHubDto) => void, noSessionCallBack: (data: WhoisProtectPriceResponseHubDto) => void, completeCallBack: (data: WhoisProtectPriceResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: WhoisProtectPriceResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: WhoisProtectPriceResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainWhoisHub/WhoisProtectPrice';

        let responseData: WhoisProtectPriceResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainWhoisHub', 'WhoisProtectPrice', 'WhoisProtectPriceRequestHubDto', 'WhoisProtectPriceResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainWhoisHub', 'WhoisProtectPrice', 'WhoisProtectPriceRequestHubDto', 'WhoisProtectPriceResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainWhoisHub', 'WhoisProtectPrice', 'WhoisProtectPriceRequestHubDto', 'WhoisProtectPriceResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public WhoisProtectCancel = (requestData: WhoisProtectCancelRequestHubDto, receiveCallBack: (data: WhoisProtectCancelResponseHubDto) => void, receiveErrorCallBack: (data: WhoisProtectCancelResponseHubDto) => void, noSessionCallBack: (data: WhoisProtectCancelResponseHubDto) => void, completeCallBack: (data: WhoisProtectCancelResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: WhoisProtectCancelResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: WhoisProtectCancelResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainWhoisHub/WhoisProtectCancel';

        let responseData: WhoisProtectCancelResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainWhoisHub', 'WhoisProtectCancel', 'WhoisProtectCancelRequestHubDto', 'WhoisProtectCancelResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainWhoisHub', 'WhoisProtectCancel', 'WhoisProtectCancelRequestHubDto', 'WhoisProtectCancelResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainWhoisHub', 'WhoisProtectCancel', 'WhoisProtectCancelRequestHubDto', 'WhoisProtectCancelResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public WhoisProtectCancelValidator = (requestData: WhoisProtectCancelValidatorRequestHubDto, receiveCallBack: (data: WhoisProtectCancelValidatorResponseHubDto) => void, receiveErrorCallBack: (data: WhoisProtectCancelValidatorResponseHubDto) => void, noSessionCallBack: (data: WhoisProtectCancelValidatorResponseHubDto) => void, completeCallBack: (data: WhoisProtectCancelValidatorResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: WhoisProtectCancelValidatorResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: WhoisProtectCancelValidatorResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainWhoisHub/WhoisProtectCancelValidator';

        let responseData: WhoisProtectCancelValidatorResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainWhoisHub', 'WhoisProtectCancelValidator', 'WhoisProtectCancelValidatorRequestHubDto', 'WhoisProtectCancelValidatorResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainWhoisHub', 'WhoisProtectCancelValidator', 'WhoisProtectCancelValidatorRequestHubDto', 'WhoisProtectCancelValidatorResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainWhoisHub', 'WhoisProtectCancelValidator', 'WhoisProtectCancelValidatorRequestHubDto', 'WhoisProtectCancelValidatorResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public WhoisProtectApply = (requestData: WhoisProtectApplyRequestHubDto, receiveCallBack: (data: WhoisProtectApplyResponseHubDto) => void, receiveErrorCallBack: (data: WhoisProtectApplyResponseHubDto) => void, noSessionCallBack: (data: WhoisProtectApplyResponseHubDto) => void, completeCallBack: (data: WhoisProtectApplyResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: WhoisProtectApplyResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: WhoisProtectApplyResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainWhoisHub/WhoisProtectApply';

        let responseData: WhoisProtectApplyResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainWhoisHub', 'WhoisProtectApply', 'WhoisProtectApplyRequestHubDto', 'WhoisProtectApplyResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainWhoisHub', 'WhoisProtectApply', 'WhoisProtectApplyRequestHubDto', 'WhoisProtectApplyResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainWhoisHub', 'WhoisProtectApply', 'WhoisProtectApplyRequestHubDto', 'WhoisProtectApplyResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public WhoisProtectApplyValidator = (requestData: WhoisProtectApplyValidatorRequestHubDto, receiveCallBack: (data: WhoisProtectApplyValidatorResponseHubDto) => void, receiveErrorCallBack: (data: WhoisProtectApplyValidatorResponseHubDto) => void, noSessionCallBack: (data: WhoisProtectApplyValidatorResponseHubDto) => void, completeCallBack: (data: WhoisProtectApplyValidatorResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: WhoisProtectApplyValidatorResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: WhoisProtectApplyValidatorResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainWhoisHub/WhoisProtectApplyValidator';

        let responseData: WhoisProtectApplyValidatorResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainWhoisHub', 'WhoisProtectApplyValidator', 'WhoisProtectApplyValidatorRequestHubDto', 'WhoisProtectApplyValidatorResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainWhoisHub', 'WhoisProtectApplyValidator', 'WhoisProtectApplyValidatorRequestHubDto', 'WhoisProtectApplyValidatorResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainWhoisHub', 'WhoisProtectApplyValidator', 'WhoisProtectApplyValidatorRequestHubDto', 'WhoisProtectApplyValidatorResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public WhoisMailForwardApply = (requestData: WhoisMailForwardApplyRequestHubDto, receiveCallBack: (data: WhoisMailForwardApplyResponseHubDto) => void, receiveErrorCallBack: (data: WhoisMailForwardApplyResponseHubDto) => void, noSessionCallBack: (data: WhoisMailForwardApplyResponseHubDto) => void, completeCallBack: (data: WhoisMailForwardApplyResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: WhoisMailForwardApplyResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: WhoisMailForwardApplyResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainWhoisHub/WhoisMailForwardApply';

        let responseData: WhoisMailForwardApplyResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainWhoisHub', 'WhoisMailForwardApply', 'WhoisMailForwardApplyRequestHubDto', 'WhoisMailForwardApplyResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainWhoisHub', 'WhoisMailForwardApply', 'WhoisMailForwardApplyRequestHubDto', 'WhoisMailForwardApplyResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainWhoisHub', 'WhoisMailForwardApply', 'WhoisMailForwardApplyRequestHubDto', 'WhoisMailForwardApplyResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public WhoisMailForwardApplyValidator = (requestData: WhoisMailForwardApplyValidatorRequestHubDto, receiveCallBack: (data: WhoisMailForwardApplyValidatorResponseHubDto) => void, receiveErrorCallBack: (data: WhoisMailForwardApplyValidatorResponseHubDto) => void, noSessionCallBack: (data: WhoisMailForwardApplyValidatorResponseHubDto) => void, completeCallBack: (data: WhoisMailForwardApplyValidatorResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: WhoisMailForwardApplyValidatorResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: WhoisMailForwardApplyValidatorResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainWhoisHub/WhoisMailForwardApplyValidator';

        let responseData: WhoisMailForwardApplyValidatorResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainWhoisHub', 'WhoisMailForwardApplyValidator', 'WhoisMailForwardApplyValidatorRequestHubDto', 'WhoisMailForwardApplyValidatorResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainWhoisHub', 'WhoisMailForwardApplyValidator', 'WhoisMailForwardApplyValidatorRequestHubDto', 'WhoisMailForwardApplyValidatorResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainWhoisHub', 'WhoisMailForwardApplyValidator', 'WhoisMailForwardApplyValidatorRequestHubDto', 'WhoisMailForwardApplyValidatorResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public OrgJpDomainNameChangeList = (requestData: OrgJpDomainNameChangeListRequestHubDto, receiveCallBack: (data: OrgJpDomainNameChangeListResponseHubDto) => void, receiveErrorCallBack: (data: OrgJpDomainNameChangeListResponseHubDto) => void, noSessionCallBack: (data: OrgJpDomainNameChangeListResponseHubDto) => void, completeCallBack: (data: OrgJpDomainNameChangeListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: OrgJpDomainNameChangeListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: OrgJpDomainNameChangeListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainWhoisHub/OrgJpDomainNameChangeList';

        let responseData: OrgJpDomainNameChangeListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainWhoisHub', 'OrgJpDomainNameChangeList', 'OrgJpDomainNameChangeListRequestHubDto', 'OrgJpDomainNameChangeListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainWhoisHub', 'OrgJpDomainNameChangeList', 'OrgJpDomainNameChangeListRequestHubDto', 'OrgJpDomainNameChangeListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainWhoisHub', 'OrgJpDomainNameChangeList', 'OrgJpDomainNameChangeListRequestHubDto', 'OrgJpDomainNameChangeListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public OrgJpDomainNameChangeValidator = (requestData: OrgJpDomainNameChangeRequestHubDto, receiveCallBack: (data: OrgJpDomainNameChangeResponseHubDto) => void, receiveErrorCallBack: (data: OrgJpDomainNameChangeResponseHubDto) => void, noSessionCallBack: (data: OrgJpDomainNameChangeResponseHubDto) => void, completeCallBack: (data: OrgJpDomainNameChangeResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: OrgJpDomainNameChangeResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: OrgJpDomainNameChangeResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainWhoisHub/OrgJpDomainNameChangeValidator';

        let responseData: OrgJpDomainNameChangeResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainWhoisHub', 'OrgJpDomainNameChangeValidator', 'OrgJpDomainNameChangeRequestHubDto', 'OrgJpDomainNameChangeResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainWhoisHub', 'OrgJpDomainNameChangeValidator', 'OrgJpDomainNameChangeRequestHubDto', 'OrgJpDomainNameChangeResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainWhoisHub', 'OrgJpDomainNameChangeValidator', 'OrgJpDomainNameChangeRequestHubDto', 'OrgJpDomainNameChangeResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public OrgJpDomainNameChange = (requestData: OrgJpDomainNameChangeRequestHubDto, receiveCallBack: (data: OrgJpDomainNameChangeResponseHubDto) => void, receiveErrorCallBack: (data: OrgJpDomainNameChangeResponseHubDto) => void, noSessionCallBack: (data: OrgJpDomainNameChangeResponseHubDto) => void, completeCallBack: (data: OrgJpDomainNameChangeResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: OrgJpDomainNameChangeResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: OrgJpDomainNameChangeResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainWhoisHub/OrgJpDomainNameChange';

        let responseData: OrgJpDomainNameChangeResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainWhoisHub', 'OrgJpDomainNameChange', 'OrgJpDomainNameChangeRequestHubDto', 'OrgJpDomainNameChangeResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainWhoisHub', 'OrgJpDomainNameChange', 'OrgJpDomainNameChangeRequestHubDto', 'OrgJpDomainNameChangeResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainWhoisHub', 'OrgJpDomainNameChange', 'OrgJpDomainNameChangeRequestHubDto', 'OrgJpDomainNameChangeResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public OrgJpDomainOrganizationChangeList = (requestData: OrgJpDomainOrganizationChangeListRequestHubDto, receiveCallBack: (data: OrgJpDomainOrganizationChangeListResponseHubDto) => void, receiveErrorCallBack: (data: OrgJpDomainOrganizationChangeListResponseHubDto) => void, noSessionCallBack: (data: OrgJpDomainOrganizationChangeListResponseHubDto) => void, completeCallBack: (data: OrgJpDomainOrganizationChangeListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: OrgJpDomainOrganizationChangeListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: OrgJpDomainOrganizationChangeListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainWhoisHub/OrgJpDomainOrganizationChangeList';

        let responseData: OrgJpDomainOrganizationChangeListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainWhoisHub', 'OrgJpDomainOrganizationChangeList', 'OrgJpDomainOrganizationChangeListRequestHubDto', 'OrgJpDomainOrganizationChangeListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainWhoisHub', 'OrgJpDomainOrganizationChangeList', 'OrgJpDomainOrganizationChangeListRequestHubDto', 'OrgJpDomainOrganizationChangeListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainWhoisHub', 'OrgJpDomainOrganizationChangeList', 'OrgJpDomainOrganizationChangeListRequestHubDto', 'OrgJpDomainOrganizationChangeListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public OrgJpDomainOrganizationGetDomainInfo = (requestData: OrgJpDomainOrganizationDomainInfoRequestHubDto, receiveCallBack: (data: OrgJpDomainOrganizationDomainInfoResponseHubDto) => void, receiveErrorCallBack: (data: OrgJpDomainOrganizationDomainInfoResponseHubDto) => void, noSessionCallBack: (data: OrgJpDomainOrganizationDomainInfoResponseHubDto) => void, completeCallBack: (data: OrgJpDomainOrganizationDomainInfoResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: OrgJpDomainOrganizationDomainInfoResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: OrgJpDomainOrganizationDomainInfoResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainWhoisHub/OrgJpDomainOrganizationGetDomainInfo';

        let responseData: OrgJpDomainOrganizationDomainInfoResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainWhoisHub', 'OrgJpDomainOrganizationGetDomainInfo', 'OrgJpDomainOrganizationDomainInfoRequestHubDto', 'OrgJpDomainOrganizationDomainInfoResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainWhoisHub', 'OrgJpDomainOrganizationGetDomainInfo', 'OrgJpDomainOrganizationDomainInfoRequestHubDto', 'OrgJpDomainOrganizationDomainInfoResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainWhoisHub', 'OrgJpDomainOrganizationGetDomainInfo', 'OrgJpDomainOrganizationDomainInfoRequestHubDto', 'OrgJpDomainOrganizationDomainInfoResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetOrgJpOrganTypeList = (requestData: OrgJpOrganTypeListRequestHubDto, receiveCallBack: (data: OrgJpOrganTypeListResponseHubDto) => void, receiveErrorCallBack: (data: OrgJpOrganTypeListResponseHubDto) => void, noSessionCallBack: (data: OrgJpOrganTypeListResponseHubDto) => void, completeCallBack: (data: OrgJpOrganTypeListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: OrgJpOrganTypeListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: OrgJpOrganTypeListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainWhoisHub/GetOrgJpOrganTypeList';

        let responseData: OrgJpOrganTypeListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainWhoisHub', 'GetOrgJpOrganTypeList', 'OrgJpOrganTypeListRequestHubDto', 'OrgJpOrganTypeListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainWhoisHub', 'GetOrgJpOrganTypeList', 'OrgJpOrganTypeListRequestHubDto', 'OrgJpOrganTypeListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainWhoisHub', 'GetOrgJpOrganTypeList', 'OrgJpOrganTypeListRequestHubDto', 'OrgJpOrganTypeListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public OrgJpDomainOrganizationChangeValidator = (requestData: OrgJpDomainOrganizationValidateRequestHubDto, receiveCallBack: (data: OrgJpDomainOrganizationValidateResponseHubDto) => void, receiveErrorCallBack: (data: OrgJpDomainOrganizationValidateResponseHubDto) => void, noSessionCallBack: (data: OrgJpDomainOrganizationValidateResponseHubDto) => void, completeCallBack: (data: OrgJpDomainOrganizationValidateResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: OrgJpDomainOrganizationValidateResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: OrgJpDomainOrganizationValidateResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainWhoisHub/OrgJpDomainOrganizationChangeValidator';

        let responseData: OrgJpDomainOrganizationValidateResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainWhoisHub', 'OrgJpDomainOrganizationChangeValidator', 'OrgJpDomainOrganizationValidateRequestHubDto', 'OrgJpDomainOrganizationValidateResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainWhoisHub', 'OrgJpDomainOrganizationChangeValidator', 'OrgJpDomainOrganizationValidateRequestHubDto', 'OrgJpDomainOrganizationValidateResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainWhoisHub', 'OrgJpDomainOrganizationChangeValidator', 'OrgJpDomainOrganizationValidateRequestHubDto', 'OrgJpDomainOrganizationValidateResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public OrgJpDomainOrganizationChange = (requestData: OrgJpDomainOrganizationChangeRequestHubDto, receiveCallBack: (data: OrgJpDomainOrganizationChangeResponseHubDto) => void, receiveErrorCallBack: (data: OrgJpDomainOrganizationChangeResponseHubDto) => void, noSessionCallBack: (data: OrgJpDomainOrganizationChangeResponseHubDto) => void, completeCallBack: (data: OrgJpDomainOrganizationChangeResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: OrgJpDomainOrganizationChangeResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: OrgJpDomainOrganizationChangeResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainWhoisHub/OrgJpDomainOrganizationChange';

        let responseData: OrgJpDomainOrganizationChangeResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainWhoisHub', 'OrgJpDomainOrganizationChange', 'OrgJpDomainOrganizationChangeRequestHubDto', 'OrgJpDomainOrganizationChangeResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainWhoisHub', 'OrgJpDomainOrganizationChange', 'OrgJpDomainOrganizationChangeRequestHubDto', 'OrgJpDomainOrganizationChangeResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainWhoisHub', 'OrgJpDomainOrganizationChange', 'OrgJpDomainOrganizationChangeRequestHubDto', 'OrgJpDomainOrganizationChangeResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public OrgJpDomainTransferChangeList = (requestData: OrgJpDomainTransferChangeListRequestHubDto, receiveCallBack: (data: OrgJpDomainTransferChangeListResponseHubDto) => void, receiveErrorCallBack: (data: OrgJpDomainTransferChangeListResponseHubDto) => void, noSessionCallBack: (data: OrgJpDomainTransferChangeListResponseHubDto) => void, completeCallBack: (data: OrgJpDomainTransferChangeListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: OrgJpDomainTransferChangeListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: OrgJpDomainTransferChangeListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainWhoisHub/OrgJpDomainTransferChangeList';

        let responseData: OrgJpDomainTransferChangeListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainWhoisHub', 'OrgJpDomainTransferChangeList', 'OrgJpDomainTransferChangeListRequestHubDto', 'OrgJpDomainTransferChangeListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainWhoisHub', 'OrgJpDomainTransferChangeList', 'OrgJpDomainTransferChangeListRequestHubDto', 'OrgJpDomainTransferChangeListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainWhoisHub', 'OrgJpDomainTransferChangeList', 'OrgJpDomainTransferChangeListRequestHubDto', 'OrgJpDomainTransferChangeListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public OrgJpDomainTransferGetDomainInfo = (requestData: OrgJpDomainTransferDomainInfoRequestHubDto, receiveCallBack: (data: OrgJpDomainTransferDomainInfoResponseHubDto) => void, receiveErrorCallBack: (data: OrgJpDomainTransferDomainInfoResponseHubDto) => void, noSessionCallBack: (data: OrgJpDomainTransferDomainInfoResponseHubDto) => void, completeCallBack: (data: OrgJpDomainTransferDomainInfoResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: OrgJpDomainTransferDomainInfoResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: OrgJpDomainTransferDomainInfoResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainWhoisHub/OrgJpDomainTransferGetDomainInfo';

        let responseData: OrgJpDomainTransferDomainInfoResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainWhoisHub', 'OrgJpDomainTransferGetDomainInfo', 'OrgJpDomainTransferDomainInfoRequestHubDto', 'OrgJpDomainTransferDomainInfoResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainWhoisHub', 'OrgJpDomainTransferGetDomainInfo', 'OrgJpDomainTransferDomainInfoRequestHubDto', 'OrgJpDomainTransferDomainInfoResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainWhoisHub', 'OrgJpDomainTransferGetDomainInfo', 'OrgJpDomainTransferDomainInfoRequestHubDto', 'OrgJpDomainTransferDomainInfoResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public OrgJpDomainTransferChangeValidator = (requestData: OrgJpDomainTransferValidateRequestHubDto, receiveCallBack: (data: OrgJpDomainTransferValidateResponseHubDto) => void, receiveErrorCallBack: (data: OrgJpDomainTransferValidateResponseHubDto) => void, noSessionCallBack: (data: OrgJpDomainTransferValidateResponseHubDto) => void, completeCallBack: (data: OrgJpDomainTransferValidateResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: OrgJpDomainTransferValidateResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: OrgJpDomainTransferValidateResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainWhoisHub/OrgJpDomainTransferChangeValidator';

        let responseData: OrgJpDomainTransferValidateResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainWhoisHub', 'OrgJpDomainTransferChangeValidator', 'OrgJpDomainTransferValidateRequestHubDto', 'OrgJpDomainTransferValidateResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainWhoisHub', 'OrgJpDomainTransferChangeValidator', 'OrgJpDomainTransferValidateRequestHubDto', 'OrgJpDomainTransferValidateResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainWhoisHub', 'OrgJpDomainTransferChangeValidator', 'OrgJpDomainTransferValidateRequestHubDto', 'OrgJpDomainTransferValidateResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public OrgJpDomainTransferChange = (requestData: OrgJpDomainTransferChangeRequestHubDto, receiveCallBack: (data: OrgJpDomainTransferChangeResponseHubDto) => void, receiveErrorCallBack: (data: OrgJpDomainTransferChangeResponseHubDto) => void, noSessionCallBack: (data: OrgJpDomainTransferChangeResponseHubDto) => void, completeCallBack: (data: OrgJpDomainTransferChangeResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: OrgJpDomainTransferChangeResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: OrgJpDomainTransferChangeResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainWhoisHub/OrgJpDomainTransferChange';

        let responseData: OrgJpDomainTransferChangeResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainWhoisHub', 'OrgJpDomainTransferChange', 'OrgJpDomainTransferChangeRequestHubDto', 'OrgJpDomainTransferChangeResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainWhoisHub', 'OrgJpDomainTransferChange', 'OrgJpDomainTransferChangeRequestHubDto', 'OrgJpDomainTransferChangeResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainWhoisHub', 'OrgJpDomainTransferChange', 'OrgJpDomainTransferChangeRequestHubDto', 'OrgJpDomainTransferChangeResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public JpDomainContactChangeList = (requestData: JpDomainContactChangeListRequestHubDto, receiveCallBack: (data: JpDomainContactChangeListResponseHubDto) => void, receiveErrorCallBack: (data: JpDomainContactChangeListResponseHubDto) => void, noSessionCallBack: (data: JpDomainContactChangeListResponseHubDto) => void, completeCallBack: (data: JpDomainContactChangeListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: JpDomainContactChangeListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: JpDomainContactChangeListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainWhoisHub/JpDomainContactChangeList';

        let responseData: JpDomainContactChangeListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainWhoisHub', 'JpDomainContactChangeList', 'JpDomainContactChangeListRequestHubDto', 'JpDomainContactChangeListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainWhoisHub', 'JpDomainContactChangeList', 'JpDomainContactChangeListRequestHubDto', 'JpDomainContactChangeListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainWhoisHub', 'JpDomainContactChangeList', 'JpDomainContactChangeListRequestHubDto', 'JpDomainContactChangeListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public JpDomainContactChangeValidator = (requestData: JpDomainContactValidateRequestHubDto, receiveCallBack: (data: JpDomainContactValidateResponseHubDto) => void, receiveErrorCallBack: (data: JpDomainContactValidateResponseHubDto) => void, noSessionCallBack: (data: JpDomainContactValidateResponseHubDto) => void, completeCallBack: (data: JpDomainContactValidateResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: JpDomainContactValidateResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: JpDomainContactValidateResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainWhoisHub/JpDomainContactChangeValidator';

        let responseData: JpDomainContactValidateResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainWhoisHub', 'JpDomainContactChangeValidator', 'JpDomainContactValidateRequestHubDto', 'JpDomainContactValidateResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainWhoisHub', 'JpDomainContactChangeValidator', 'JpDomainContactValidateRequestHubDto', 'JpDomainContactValidateResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainWhoisHub', 'JpDomainContactChangeValidator', 'JpDomainContactValidateRequestHubDto', 'JpDomainContactValidateResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public JpDomainContactChange = (requestData: JpDomainContactChangeRequestHubDto, receiveCallBack: (data: JpDomainContactChangeResponseHubDto) => void, receiveErrorCallBack: (data: JpDomainContactChangeResponseHubDto) => void, noSessionCallBack: (data: JpDomainContactChangeResponseHubDto) => void, completeCallBack: (data: JpDomainContactChangeResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: JpDomainContactChangeResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: JpDomainContactChangeResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainWhoisHub/JpDomainContactChange';

        let responseData: JpDomainContactChangeResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainWhoisHub', 'JpDomainContactChange', 'JpDomainContactChangeRequestHubDto', 'JpDomainContactChangeResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainWhoisHub', 'JpDomainContactChange', 'JpDomainContactChangeRequestHubDto', 'JpDomainContactChangeResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainWhoisHub', 'JpDomainContactChange', 'JpDomainContactChangeRequestHubDto', 'JpDomainContactChangeResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public OrgJpDomainUpdatePrice = (requestData: OrgJpDomainUpdatePriceRequestHubDto, receiveCallBack: (data: OrgJpDomainUpdatePriceResponseHubDto) => void, receiveErrorCallBack: (data: OrgJpDomainUpdatePriceResponseHubDto) => void, noSessionCallBack: (data: OrgJpDomainUpdatePriceResponseHubDto) => void, completeCallBack: (data: OrgJpDomainUpdatePriceResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: OrgJpDomainUpdatePriceResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: OrgJpDomainUpdatePriceResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainWhoisHub/OrgJpDomainUpdatePrice';

        let responseData: OrgJpDomainUpdatePriceResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainWhoisHub', 'OrgJpDomainUpdatePrice', 'OrgJpDomainUpdatePriceRequestHubDto', 'OrgJpDomainUpdatePriceResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainWhoisHub', 'OrgJpDomainUpdatePrice', 'OrgJpDomainUpdatePriceRequestHubDto', 'OrgJpDomainUpdatePriceResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainWhoisHub', 'OrgJpDomainUpdatePrice', 'OrgJpDomainUpdatePriceRequestHubDto', 'OrgJpDomainUpdatePriceResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public CojpDefineList = (requestData: CojpDefineListRequestHubDto, receiveCallBack: (data: CojpDefineListResponseHubDto) => void, receiveErrorCallBack: (data: CojpDefineListResponseHubDto) => void, noSessionCallBack: (data: CojpDefineListResponseHubDto) => void, completeCallBack: (data: CojpDefineListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: CojpDefineListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: CojpDefineListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainWhoisHub/CojpDefineList';

        let responseData: CojpDefineListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainWhoisHub', 'CojpDefineList', 'CojpDefineListRequestHubDto', 'CojpDefineListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainWhoisHub', 'CojpDefineList', 'CojpDefineListRequestHubDto', 'CojpDefineListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainWhoisHub', 'CojpDefineList', 'CojpDefineListRequestHubDto', 'CojpDefineListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public CojpDefineInfo = (requestData: CojpDefineInfoRequestHubDto, receiveCallBack: (data: CojpDefineInfoResponseHubDto) => void, receiveErrorCallBack: (data: CojpDefineInfoResponseHubDto) => void, noSessionCallBack: (data: CojpDefineInfoResponseHubDto) => void, completeCallBack: (data: CojpDefineInfoResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: CojpDefineInfoResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: CojpDefineInfoResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainWhoisHub/CojpDefineInfo';

        let responseData: CojpDefineInfoResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainWhoisHub', 'CojpDefineInfo', 'CojpDefineInfoRequestHubDto', 'CojpDefineInfoResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainWhoisHub', 'CojpDefineInfo', 'CojpDefineInfoRequestHubDto', 'CojpDefineInfoResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainWhoisHub', 'CojpDefineInfo', 'CojpDefineInfoRequestHubDto', 'CojpDefineInfoResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public CojpDomainDefine = (requestData: CojpDefineRequestHubDto, receiveCallBack: (data: CojpDefineResponseHubDto) => void, receiveErrorCallBack: (data: CojpDefineResponseHubDto) => void, noSessionCallBack: (data: CojpDefineResponseHubDto) => void, completeCallBack: (data: CojpDefineResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: CojpDefineResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: CojpDefineResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainWhoisHub/CojpDomainDefine';

        let responseData: CojpDefineResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainWhoisHub', 'CojpDomainDefine', 'CojpDefineRequestHubDto', 'CojpDefineResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainWhoisHub', 'CojpDomainDefine', 'CojpDefineRequestHubDto', 'CojpDefineResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainWhoisHub', 'CojpDomainDefine', 'CojpDefineRequestHubDto', 'CojpDefineResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public CojpOrganTypeList = (receiveCallBack: (data: CojpOrganTypeListResponseHubDto) => void, receiveErrorCallBack: (data: CojpOrganTypeListResponseHubDto) => void, noSessionCallBack: (data: CojpOrganTypeListResponseHubDto) => void, completeCallBack: (data: CojpOrganTypeListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: CojpOrganTypeListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: CojpOrganTypeListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainWhoisHub/CojpOrganTypeList';

        let responseData: CojpOrganTypeListResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainWhoisHub', 'CojpOrganTypeList', null, 'CojpOrganTypeListResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainWhoisHub', 'CojpOrganTypeList', null, 'CojpOrganTypeListResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainWhoisHub', 'CojpOrganTypeList', null, 'CojpOrganTypeListResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetJpDomainRevisionDomains = (requestData: JpDomainRevisionDomainsRequestHubDto, receiveCallBack: (data: JpDomainRevisionDomainsResponseHubDto) => void, receiveErrorCallBack: (data: JpDomainRevisionDomainsResponseHubDto) => void, noSessionCallBack: (data: JpDomainRevisionDomainsResponseHubDto) => void, completeCallBack: (data: JpDomainRevisionDomainsResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: JpDomainRevisionDomainsResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: JpDomainRevisionDomainsResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainWhoisHub/GetJpDomainRevisionDomains';

        let responseData: JpDomainRevisionDomainsResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainWhoisHub', 'GetJpDomainRevisionDomains', 'JpDomainRevisionDomainsRequestHubDto', 'JpDomainRevisionDomainsResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainWhoisHub', 'GetJpDomainRevisionDomains', 'JpDomainRevisionDomainsRequestHubDto', 'JpDomainRevisionDomainsResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainWhoisHub', 'GetJpDomainRevisionDomains', 'JpDomainRevisionDomainsRequestHubDto', 'JpDomainRevisionDomainsResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetJpDomainRevisionOrganizationTypeItems = (requestData: JpDomainRevisionOrganizationTypeItemsRequestHubDto, receiveCallBack: (data: JpDomainRevisionOrganizationTypeItemsResponseHubDto) => void, receiveErrorCallBack: (data: JpDomainRevisionOrganizationTypeItemsResponseHubDto) => void, noSessionCallBack: (data: JpDomainRevisionOrganizationTypeItemsResponseHubDto) => void, completeCallBack: (data: JpDomainRevisionOrganizationTypeItemsResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: JpDomainRevisionOrganizationTypeItemsResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: JpDomainRevisionOrganizationTypeItemsResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainWhoisHub/GetJpDomainRevisionOrganizationTypeItems';

        let responseData: JpDomainRevisionOrganizationTypeItemsResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainWhoisHub', 'GetJpDomainRevisionOrganizationTypeItems', 'JpDomainRevisionOrganizationTypeItemsRequestHubDto', 'JpDomainRevisionOrganizationTypeItemsResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainWhoisHub', 'GetJpDomainRevisionOrganizationTypeItems', 'JpDomainRevisionOrganizationTypeItemsRequestHubDto', 'JpDomainRevisionOrganizationTypeItemsResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainWhoisHub', 'GetJpDomainRevisionOrganizationTypeItems', 'JpDomainRevisionOrganizationTypeItemsRequestHubDto', 'JpDomainRevisionOrganizationTypeItemsResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetJpDomainRevisionContact = (requestData: JpDomainRevisionContactRequestHubDto, receiveCallBack: (data: JpDomainRevisionContactResponseHubDto) => void, receiveErrorCallBack: (data: JpDomainRevisionContactResponseHubDto) => void, noSessionCallBack: (data: JpDomainRevisionContactResponseHubDto) => void, completeCallBack: (data: JpDomainRevisionContactResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: JpDomainRevisionContactResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: JpDomainRevisionContactResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainWhoisHub/GetJpDomainRevisionContact';

        let responseData: JpDomainRevisionContactResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainWhoisHub', 'GetJpDomainRevisionContact', 'JpDomainRevisionContactRequestHubDto', 'JpDomainRevisionContactResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainWhoisHub', 'GetJpDomainRevisionContact', 'JpDomainRevisionContactRequestHubDto', 'JpDomainRevisionContactResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainWhoisHub', 'GetJpDomainRevisionContact', 'JpDomainRevisionContactRequestHubDto', 'JpDomainRevisionContactResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ValidateJpDomainRevisionContact = (requestData: JpDomainRevisionValidateContactRequestHubDto, receiveCallBack: (data: JpDomainRevisionValidateContactResponseHubDto) => void, receiveErrorCallBack: (data: JpDomainRevisionValidateContactResponseHubDto) => void, noSessionCallBack: (data: JpDomainRevisionValidateContactResponseHubDto) => void, completeCallBack: (data: JpDomainRevisionValidateContactResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: JpDomainRevisionValidateContactResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: JpDomainRevisionValidateContactResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainWhoisHub/ValidateJpDomainRevisionContact';

        let responseData: JpDomainRevisionValidateContactResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainWhoisHub', 'ValidateJpDomainRevisionContact', 'JpDomainRevisionValidateContactRequestHubDto', 'JpDomainRevisionValidateContactResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainWhoisHub', 'ValidateJpDomainRevisionContact', 'JpDomainRevisionValidateContactRequestHubDto', 'JpDomainRevisionValidateContactResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainWhoisHub', 'ValidateJpDomainRevisionContact', 'JpDomainRevisionValidateContactRequestHubDto', 'JpDomainRevisionValidateContactResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public UpdateJpDomainRevisionContact = (requestData: JpDomainRevisionUpdateContactRequestHubDto, receiveCallBack: (data: JpDomainRevisionUpdateContactResponseHubDto) => void, receiveErrorCallBack: (data: JpDomainRevisionUpdateContactResponseHubDto) => void, noSessionCallBack: (data: JpDomainRevisionUpdateContactResponseHubDto) => void, completeCallBack: (data: JpDomainRevisionUpdateContactResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: JpDomainRevisionUpdateContactResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: JpDomainRevisionUpdateContactResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainWhoisHub/UpdateJpDomainRevisionContact';

        let responseData: JpDomainRevisionUpdateContactResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainWhoisHub', 'UpdateJpDomainRevisionContact', 'JpDomainRevisionUpdateContactRequestHubDto', 'JpDomainRevisionUpdateContactResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainWhoisHub', 'UpdateJpDomainRevisionContact', 'JpDomainRevisionUpdateContactRequestHubDto', 'JpDomainRevisionUpdateContactResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainWhoisHub', 'UpdateJpDomainRevisionContact', 'JpDomainRevisionUpdateContactRequestHubDto', 'JpDomainRevisionUpdateContactResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetWhoisChangeOwnershipDomains = (requestData: WhoisChangeOwnershipDomainsRequestHubDto, receiveCallBack: (data: WhoisChangeOwnershipDomainsResponseHubDto) => void, receiveErrorCallBack: (data: WhoisChangeOwnershipDomainsResponseHubDto) => void, noSessionCallBack: (data: WhoisChangeOwnershipDomainsResponseHubDto) => void, completeCallBack: (data: WhoisChangeOwnershipDomainsResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: WhoisChangeOwnershipDomainsResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: WhoisChangeOwnershipDomainsResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainWhoisHub/GetWhoisChangeOwnershipDomains';

        let responseData: WhoisChangeOwnershipDomainsResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainWhoisHub', 'GetWhoisChangeOwnershipDomains', 'WhoisChangeOwnershipDomainsRequestHubDto', 'WhoisChangeOwnershipDomainsResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainWhoisHub', 'GetWhoisChangeOwnershipDomains', 'WhoisChangeOwnershipDomainsRequestHubDto', 'WhoisChangeOwnershipDomainsResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainWhoisHub', 'GetWhoisChangeOwnershipDomains', 'WhoisChangeOwnershipDomainsRequestHubDto', 'WhoisChangeOwnershipDomainsResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ValidateWhoisChangeOwnershipContact = (requestData: WhoisChangeOwnershipValidateContactRequestHubDto, receiveCallBack: (data: WhoisChangeOwnershipValidateContactResponseHubDto) => void, receiveErrorCallBack: (data: WhoisChangeOwnershipValidateContactResponseHubDto) => void, noSessionCallBack: (data: WhoisChangeOwnershipValidateContactResponseHubDto) => void, completeCallBack: (data: WhoisChangeOwnershipValidateContactResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: WhoisChangeOwnershipValidateContactResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: WhoisChangeOwnershipValidateContactResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainWhoisHub/ValidateWhoisChangeOwnershipContact';

        let responseData: WhoisChangeOwnershipValidateContactResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainWhoisHub', 'ValidateWhoisChangeOwnershipContact', 'WhoisChangeOwnershipValidateContactRequestHubDto', 'WhoisChangeOwnershipValidateContactResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainWhoisHub', 'ValidateWhoisChangeOwnershipContact', 'WhoisChangeOwnershipValidateContactRequestHubDto', 'WhoisChangeOwnershipValidateContactResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainWhoisHub', 'ValidateWhoisChangeOwnershipContact', 'WhoisChangeOwnershipValidateContactRequestHubDto', 'WhoisChangeOwnershipValidateContactResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public UpdateWhoisChangeOwnershipContact = (requestData: WhoisChangeOwnershipUpdateContactRequestHubDto, receiveCallBack: (data: WhoisChangeOwnershipUpdateContactResponseHubDto) => void, receiveErrorCallBack: (data: WhoisChangeOwnershipUpdateContactResponseHubDto) => void, noSessionCallBack: (data: WhoisChangeOwnershipUpdateContactResponseHubDto) => void, completeCallBack: (data: WhoisChangeOwnershipUpdateContactResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: WhoisChangeOwnershipUpdateContactResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: WhoisChangeOwnershipUpdateContactResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainWhoisHub/UpdateWhoisChangeOwnershipContact';

        let responseData: WhoisChangeOwnershipUpdateContactResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainWhoisHub', 'UpdateWhoisChangeOwnershipContact', 'WhoisChangeOwnershipUpdateContactRequestHubDto', 'WhoisChangeOwnershipUpdateContactResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainWhoisHub', 'UpdateWhoisChangeOwnershipContact', 'WhoisChangeOwnershipUpdateContactRequestHubDto', 'WhoisChangeOwnershipUpdateContactResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainWhoisHub', 'UpdateWhoisChangeOwnershipContact', 'WhoisChangeOwnershipUpdateContactRequestHubDto', 'WhoisChangeOwnershipUpdateContactResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetEvidenceRequestList = (requestData: EvidenceRequestListRequestHubDto, receiveCallBack: (data: EvidenceRequestListResponseHubDto) => void, receiveErrorCallBack: (data: EvidenceRequestListResponseHubDto) => void, noSessionCallBack: (data: EvidenceRequestListResponseHubDto) => void, completeCallBack: (data: EvidenceRequestListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: EvidenceRequestListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: EvidenceRequestListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainWhoisHub/GetEvidenceRequestList';

        let responseData: EvidenceRequestListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainWhoisHub', 'GetEvidenceRequestList', 'EvidenceRequestListRequestHubDto', 'EvidenceRequestListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainWhoisHub', 'GetEvidenceRequestList', 'EvidenceRequestListRequestHubDto', 'EvidenceRequestListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainWhoisHub', 'GetEvidenceRequestList', 'EvidenceRequestListRequestHubDto', 'EvidenceRequestListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public WhoisEvidenceOverallInfo = (requestData: WhoisEvidenceOverallInfoRequestHubDto, receiveCallBack: (data: WhoisEvidenceOverallInfoResponseHubDto) => void, receiveErrorCallBack: (data: WhoisEvidenceOverallInfoResponseHubDto) => void, noSessionCallBack: (data: WhoisEvidenceOverallInfoResponseHubDto) => void, completeCallBack: (data: WhoisEvidenceOverallInfoResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: WhoisEvidenceOverallInfoResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: WhoisEvidenceOverallInfoResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainWhoisHub/WhoisEvidenceOverallInfo';

        let responseData: WhoisEvidenceOverallInfoResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainWhoisHub', 'WhoisEvidenceOverallInfo', 'WhoisEvidenceOverallInfoRequestHubDto', 'WhoisEvidenceOverallInfoResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainWhoisHub', 'WhoisEvidenceOverallInfo', 'WhoisEvidenceOverallInfoRequestHubDto', 'WhoisEvidenceOverallInfoResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainWhoisHub', 'WhoisEvidenceOverallInfo', 'WhoisEvidenceOverallInfoRequestHubDto', 'WhoisEvidenceOverallInfoResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public CompleteEvidenceRequest = (requestData: EvidenceRequestCompleteRequestHubDto, receiveCallBack: (data: EvidenceRequestCompleteResponseHubDto) => void, receiveErrorCallBack: (data: EvidenceRequestCompleteResponseHubDto) => void, noSessionCallBack: (data: EvidenceRequestCompleteResponseHubDto) => void, completeCallBack: (data: EvidenceRequestCompleteResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: EvidenceRequestCompleteResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: EvidenceRequestCompleteResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainWhoisHub/CompleteEvidenceRequest';

        let responseData: EvidenceRequestCompleteResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainWhoisHub', 'CompleteEvidenceRequest', 'EvidenceRequestCompleteRequestHubDto', 'EvidenceRequestCompleteResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainWhoisHub', 'CompleteEvidenceRequest', 'EvidenceRequestCompleteRequestHubDto', 'EvidenceRequestCompleteResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainWhoisHub', 'CompleteEvidenceRequest', 'EvidenceRequestCompleteRequestHubDto', 'EvidenceRequestCompleteResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetRegDomainInfo = (requestData: DomainInfoRequestHubDto, receiveCallBack: (data: DomainInfoResponseHubDto) => void, receiveErrorCallBack: (data: DomainInfoResponseHubDto) => void, noSessionCallBack: (data: DomainInfoResponseHubDto) => void, completeCallBack: (data: DomainInfoResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainInfoResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainInfoResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainWhoisHub/GetRegDomainInfo';

        let responseData: DomainInfoResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainWhoisHub', 'GetRegDomainInfo', 'DomainInfoRequestHubDto', 'DomainInfoResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainWhoisHub', 'GetRegDomainInfo', 'DomainInfoRequestHubDto', 'DomainInfoResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainWhoisHub', 'GetRegDomainInfo', 'DomainInfoRequestHubDto', 'DomainInfoResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetAdmDomainInfo = (requestData: DomainInfoRequestHubDto, receiveCallBack: (data: DomainInfoResponseHubDto) => void, receiveErrorCallBack: (data: DomainInfoResponseHubDto) => void, noSessionCallBack: (data: DomainInfoResponseHubDto) => void, completeCallBack: (data: DomainInfoResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainInfoResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainInfoResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainWhoisHub/GetAdmDomainInfo';

        let responseData: DomainInfoResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainWhoisHub', 'GetAdmDomainInfo', 'DomainInfoRequestHubDto', 'DomainInfoResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainWhoisHub', 'GetAdmDomainInfo', 'DomainInfoRequestHubDto', 'DomainInfoResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainWhoisHub', 'GetAdmDomainInfo', 'DomainInfoRequestHubDto', 'DomainInfoResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetBilDomainInfo = (requestData: DomainInfoRequestHubDto, receiveCallBack: (data: DomainInfoResponseHubDto) => void, receiveErrorCallBack: (data: DomainInfoResponseHubDto) => void, noSessionCallBack: (data: DomainInfoResponseHubDto) => void, completeCallBack: (data: DomainInfoResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainInfoResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainInfoResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainWhoisHub/GetBilDomainInfo';

        let responseData: DomainInfoResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainWhoisHub', 'GetBilDomainInfo', 'DomainInfoRequestHubDto', 'DomainInfoResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainWhoisHub', 'GetBilDomainInfo', 'DomainInfoRequestHubDto', 'DomainInfoResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainWhoisHub', 'GetBilDomainInfo', 'DomainInfoRequestHubDto', 'DomainInfoResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetTecDomainInfo = (requestData: DomainInfoRequestHubDto, receiveCallBack: (data: DomainInfoResponseHubDto) => void, receiveErrorCallBack: (data: DomainInfoResponseHubDto) => void, noSessionCallBack: (data: DomainInfoResponseHubDto) => void, completeCallBack: (data: DomainInfoResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainInfoResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainInfoResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainWhoisHub/GetTecDomainInfo';

        let responseData: DomainInfoResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainWhoisHub', 'GetTecDomainInfo', 'DomainInfoRequestHubDto', 'DomainInfoResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainWhoisHub', 'GetTecDomainInfo', 'DomainInfoRequestHubDto', 'DomainInfoResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainWhoisHub', 'GetTecDomainInfo', 'DomainInfoRequestHubDto', 'DomainInfoResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetAgoDomainInfo = (requestData: DomainInfoRequestHubDto, receiveCallBack: (data: DomainInfoResponseHubDto) => void, receiveErrorCallBack: (data: DomainInfoResponseHubDto) => void, noSessionCallBack: (data: DomainInfoResponseHubDto) => void, completeCallBack: (data: DomainInfoResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainInfoResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainInfoResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainWhoisHub/GetAgoDomainInfo';

        let responseData: DomainInfoResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainWhoisHub', 'GetAgoDomainInfo', 'DomainInfoRequestHubDto', 'DomainInfoResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainWhoisHub', 'GetAgoDomainInfo', 'DomainInfoRequestHubDto', 'DomainInfoResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainWhoisHub', 'GetAgoDomainInfo', 'DomainInfoRequestHubDto', 'DomainInfoResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetRecommendWhoisChangeMessages = (receiveCallBack: (data: WhoisChangeMessageResponseHubDto) => void, receiveErrorCallBack: (data: WhoisChangeMessageResponseHubDto) => void, noSessionCallBack: (data: WhoisChangeMessageResponseHubDto) => void, completeCallBack: (data: WhoisChangeMessageResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: WhoisChangeMessageResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: WhoisChangeMessageResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainWhoisHub/GetRecommendWhoisChangeMessages';

        let responseData: WhoisChangeMessageResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainWhoisHub', 'GetRecommendWhoisChangeMessages', null, 'WhoisChangeMessageResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainWhoisHub', 'GetRecommendWhoisChangeMessages', null, 'WhoisChangeMessageResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainWhoisHub', 'GetRecommendWhoisChangeMessages', null, 'WhoisChangeMessageResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }

}



export class QuestionnaireHubManager {
    /**
     * コンストラクタ
     */
    constructor(private http: HttpClient) {
    }

	// リソース破棄
    public Dispose = (): void => {
    }



    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetQuestionnaireInfoAccount = (receiveCallBack: (data: QuestionnaireInfoAccountResponseHubDto) => void, receiveErrorCallBack: (data: QuestionnaireInfoAccountResponseHubDto) => void, noSessionCallBack: (data: QuestionnaireInfoAccountResponseHubDto) => void, completeCallBack: (data: QuestionnaireInfoAccountResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: QuestionnaireInfoAccountResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: QuestionnaireInfoAccountResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/QuestionnaireHub/GetQuestionnaireInfoAccount';

        let responseData: QuestionnaireInfoAccountResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('QuestionnaireHub', 'GetQuestionnaireInfoAccount', null, 'QuestionnaireInfoAccountResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('QuestionnaireHub', 'GetQuestionnaireInfoAccount', null, 'QuestionnaireInfoAccountResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('QuestionnaireHub', 'GetQuestionnaireInfoAccount', null, 'QuestionnaireInfoAccountResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public AnswerQuestionnaireAccount = (requestData: QuestionnaireAnswerAccountRequestHubDto, receiveCallBack: (data: QuestionnaireAnswerAccountResponseHubDto) => void, receiveErrorCallBack: (data: QuestionnaireAnswerAccountResponseHubDto) => void, noSessionCallBack: (data: QuestionnaireAnswerAccountResponseHubDto) => void, completeCallBack: (data: QuestionnaireAnswerAccountResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: QuestionnaireAnswerAccountResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: QuestionnaireAnswerAccountResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/QuestionnaireHub/AnswerQuestionnaireAccount';

        let responseData: QuestionnaireAnswerAccountResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('QuestionnaireHub', 'AnswerQuestionnaireAccount', 'QuestionnaireAnswerAccountRequestHubDto', 'QuestionnaireAnswerAccountResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('QuestionnaireHub', 'AnswerQuestionnaireAccount', 'QuestionnaireAnswerAccountRequestHubDto', 'QuestionnaireAnswerAccountResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('QuestionnaireHub', 'AnswerQuestionnaireAccount', 'QuestionnaireAnswerAccountRequestHubDto', 'QuestionnaireAnswerAccountResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetQuestionnaireInfoSd = (requestData: QuestionnaireInfoRequestHubDto, receiveCallBack: (data: QuestionnaireInfoSdResponseHubDto) => void, receiveErrorCallBack: (data: QuestionnaireInfoSdResponseHubDto) => void, noSessionCallBack: (data: QuestionnaireInfoSdResponseHubDto) => void, completeCallBack: (data: QuestionnaireInfoSdResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: QuestionnaireInfoSdResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: QuestionnaireInfoSdResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/QuestionnaireHub/GetQuestionnaireInfoSd';

        let responseData: QuestionnaireInfoSdResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('QuestionnaireHub', 'GetQuestionnaireInfoSd', 'QuestionnaireInfoRequestHubDto', 'QuestionnaireInfoSdResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('QuestionnaireHub', 'GetQuestionnaireInfoSd', 'QuestionnaireInfoRequestHubDto', 'QuestionnaireInfoSdResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('QuestionnaireHub', 'GetQuestionnaireInfoSd', 'QuestionnaireInfoRequestHubDto', 'QuestionnaireInfoSdResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public AnswerQuestionnaireSd = (requestData: QuestionnaireAnswerSdRequestHubDto, receiveCallBack: (data: QuestionnaireAnswerSdResponseHubDto) => void, receiveErrorCallBack: (data: QuestionnaireAnswerSdResponseHubDto) => void, noSessionCallBack: (data: QuestionnaireAnswerSdResponseHubDto) => void, completeCallBack: (data: QuestionnaireAnswerSdResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: QuestionnaireAnswerSdResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: QuestionnaireAnswerSdResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/QuestionnaireHub/AnswerQuestionnaireSd';

        let responseData: QuestionnaireAnswerSdResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('QuestionnaireHub', 'AnswerQuestionnaireSd', 'QuestionnaireAnswerSdRequestHubDto', 'QuestionnaireAnswerSdResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('QuestionnaireHub', 'AnswerQuestionnaireSd', 'QuestionnaireAnswerSdRequestHubDto', 'QuestionnaireAnswerSdResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('QuestionnaireHub', 'AnswerQuestionnaireSd', 'QuestionnaireAnswerSdRequestHubDto', 'QuestionnaireAnswerSdResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetQuestionnaireInfoMail = (requestData: QuestionnaireInfoRequestHubDto, receiveCallBack: (data: QuestionnaireInfoMailResponseHubDto) => void, receiveErrorCallBack: (data: QuestionnaireInfoMailResponseHubDto) => void, noSessionCallBack: (data: QuestionnaireInfoMailResponseHubDto) => void, completeCallBack: (data: QuestionnaireInfoMailResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: QuestionnaireInfoMailResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: QuestionnaireInfoMailResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/QuestionnaireHub/GetQuestionnaireInfoMail';

        let responseData: QuestionnaireInfoMailResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('QuestionnaireHub', 'GetQuestionnaireInfoMail', 'QuestionnaireInfoRequestHubDto', 'QuestionnaireInfoMailResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('QuestionnaireHub', 'GetQuestionnaireInfoMail', 'QuestionnaireInfoRequestHubDto', 'QuestionnaireInfoMailResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('QuestionnaireHub', 'GetQuestionnaireInfoMail', 'QuestionnaireInfoRequestHubDto', 'QuestionnaireInfoMailResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public AnswerQuestionnaireMail = (requestData: QuestionnaireAnswerMailRequestHubDto, receiveCallBack: (data: QuestionnaireAnswerMailResponseHubDto) => void, receiveErrorCallBack: (data: QuestionnaireAnswerMailResponseHubDto) => void, noSessionCallBack: (data: QuestionnaireAnswerMailResponseHubDto) => void, completeCallBack: (data: QuestionnaireAnswerMailResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: QuestionnaireAnswerMailResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: QuestionnaireAnswerMailResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/QuestionnaireHub/AnswerQuestionnaireMail';

        let responseData: QuestionnaireAnswerMailResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('QuestionnaireHub', 'AnswerQuestionnaireMail', 'QuestionnaireAnswerMailRequestHubDto', 'QuestionnaireAnswerMailResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('QuestionnaireHub', 'AnswerQuestionnaireMail', 'QuestionnaireAnswerMailRequestHubDto', 'QuestionnaireAnswerMailResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('QuestionnaireHub', 'AnswerQuestionnaireMail', 'QuestionnaireAnswerMailRequestHubDto', 'QuestionnaireAnswerMailResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetQuestionnaireInfoDesktopCloud = (requestData: QuestionnaireInfoRequestHubDto, receiveCallBack: (data: QuestionnaireInfoDesktopCloudResponseHubDto) => void, receiveErrorCallBack: (data: QuestionnaireInfoDesktopCloudResponseHubDto) => void, noSessionCallBack: (data: QuestionnaireInfoDesktopCloudResponseHubDto) => void, completeCallBack: (data: QuestionnaireInfoDesktopCloudResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: QuestionnaireInfoDesktopCloudResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: QuestionnaireInfoDesktopCloudResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/QuestionnaireHub/GetQuestionnaireInfoDesktopCloud';

        let responseData: QuestionnaireInfoDesktopCloudResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('QuestionnaireHub', 'GetQuestionnaireInfoDesktopCloud', 'QuestionnaireInfoRequestHubDto', 'QuestionnaireInfoDesktopCloudResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('QuestionnaireHub', 'GetQuestionnaireInfoDesktopCloud', 'QuestionnaireInfoRequestHubDto', 'QuestionnaireInfoDesktopCloudResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('QuestionnaireHub', 'GetQuestionnaireInfoDesktopCloud', 'QuestionnaireInfoRequestHubDto', 'QuestionnaireInfoDesktopCloudResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public AnswerQuestionnaireDesktopCloud = (requestData: QuestionnaireAnswerDesktopCloudRequestHubDto, receiveCallBack: (data: QuestionnaireAnswerDesktopCloudResponseHubDto) => void, receiveErrorCallBack: (data: QuestionnaireAnswerDesktopCloudResponseHubDto) => void, noSessionCallBack: (data: QuestionnaireAnswerDesktopCloudResponseHubDto) => void, completeCallBack: (data: QuestionnaireAnswerDesktopCloudResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: QuestionnaireAnswerDesktopCloudResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: QuestionnaireAnswerDesktopCloudResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/QuestionnaireHub/AnswerQuestionnaireDesktopCloud';

        let responseData: QuestionnaireAnswerDesktopCloudResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('QuestionnaireHub', 'AnswerQuestionnaireDesktopCloud', 'QuestionnaireAnswerDesktopCloudRequestHubDto', 'QuestionnaireAnswerDesktopCloudResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('QuestionnaireHub', 'AnswerQuestionnaireDesktopCloud', 'QuestionnaireAnswerDesktopCloudRequestHubDto', 'QuestionnaireAnswerDesktopCloudResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('QuestionnaireHub', 'AnswerQuestionnaireDesktopCloud', 'QuestionnaireAnswerDesktopCloudRequestHubDto', 'QuestionnaireAnswerDesktopCloudResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetQuestionnaireInfoAllServices = (requestData: QuestionnaireAnswerAllServicesRequestHubDto, receiveCallBack: (data: QuestionnaireInfoAllServicesResponseHubDto) => void, receiveErrorCallBack: (data: QuestionnaireInfoAllServicesResponseHubDto) => void, noSessionCallBack: (data: QuestionnaireInfoAllServicesResponseHubDto) => void, completeCallBack: (data: QuestionnaireInfoAllServicesResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: QuestionnaireInfoAllServicesResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: QuestionnaireInfoAllServicesResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/QuestionnaireHub/GetQuestionnaireInfoAllServices';

        let responseData: QuestionnaireInfoAllServicesResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('QuestionnaireHub', 'GetQuestionnaireInfoAllServices', 'QuestionnaireAnswerAllServicesRequestHubDto', 'QuestionnaireInfoAllServicesResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('QuestionnaireHub', 'GetQuestionnaireInfoAllServices', 'QuestionnaireAnswerAllServicesRequestHubDto', 'QuestionnaireInfoAllServicesResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('QuestionnaireHub', 'GetQuestionnaireInfoAllServices', 'QuestionnaireAnswerAllServicesRequestHubDto', 'QuestionnaireInfoAllServicesResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public AnswerQuestionnaireAllServices = (requestData: QuestionnaireAnswerAllServicesRequestHubDto, receiveCallBack: (data: QuestionnaireAnswerAllServicesResponseHubDto) => void, receiveErrorCallBack: (data: QuestionnaireAnswerAllServicesResponseHubDto) => void, noSessionCallBack: (data: QuestionnaireAnswerAllServicesResponseHubDto) => void, completeCallBack: (data: QuestionnaireAnswerAllServicesResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: QuestionnaireAnswerAllServicesResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: QuestionnaireAnswerAllServicesResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/QuestionnaireHub/AnswerQuestionnaireAllServices';

        let responseData: QuestionnaireAnswerAllServicesResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('QuestionnaireHub', 'AnswerQuestionnaireAllServices', 'QuestionnaireAnswerAllServicesRequestHubDto', 'QuestionnaireAnswerAllServicesResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('QuestionnaireHub', 'AnswerQuestionnaireAllServices', 'QuestionnaireAnswerAllServicesRequestHubDto', 'QuestionnaireAnswerAllServicesResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('QuestionnaireHub', 'AnswerQuestionnaireAllServices', 'QuestionnaireAnswerAllServicesRequestHubDto', 'QuestionnaireAnswerAllServicesResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetQuestionnaireInfoServiceImprovement = (receiveCallBack: (data: QuestionnaireInfoServiceImprovementResponseHubDto) => void, receiveErrorCallBack: (data: QuestionnaireInfoServiceImprovementResponseHubDto) => void, noSessionCallBack: (data: QuestionnaireInfoServiceImprovementResponseHubDto) => void, completeCallBack: (data: QuestionnaireInfoServiceImprovementResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: QuestionnaireInfoServiceImprovementResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: QuestionnaireInfoServiceImprovementResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/QuestionnaireHub/GetQuestionnaireInfoServiceImprovement';

        let responseData: QuestionnaireInfoServiceImprovementResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('QuestionnaireHub', 'GetQuestionnaireInfoServiceImprovement', null, 'QuestionnaireInfoServiceImprovementResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('QuestionnaireHub', 'GetQuestionnaireInfoServiceImprovement', null, 'QuestionnaireInfoServiceImprovementResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('QuestionnaireHub', 'GetQuestionnaireInfoServiceImprovement', null, 'QuestionnaireInfoServiceImprovementResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public AnswerQuestionnaireServiceImprovement = (requestData: QuestionnaireAnswerQuestionnaireServiceImprovementRequestHubDto, receiveCallBack: (data: QuestionnaireAnswerServiceImprovementResponseHubDto) => void, receiveErrorCallBack: (data: QuestionnaireAnswerServiceImprovementResponseHubDto) => void, noSessionCallBack: (data: QuestionnaireAnswerServiceImprovementResponseHubDto) => void, completeCallBack: (data: QuestionnaireAnswerServiceImprovementResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: QuestionnaireAnswerServiceImprovementResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: QuestionnaireAnswerServiceImprovementResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/QuestionnaireHub/AnswerQuestionnaireServiceImprovement';

        let responseData: QuestionnaireAnswerServiceImprovementResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('QuestionnaireHub', 'AnswerQuestionnaireServiceImprovement', 'QuestionnaireAnswerQuestionnaireServiceImprovementRequestHubDto', 'QuestionnaireAnswerServiceImprovementResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('QuestionnaireHub', 'AnswerQuestionnaireServiceImprovement', 'QuestionnaireAnswerQuestionnaireServiceImprovementRequestHubDto', 'QuestionnaireAnswerServiceImprovementResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('QuestionnaireHub', 'AnswerQuestionnaireServiceImprovement', 'QuestionnaireAnswerQuestionnaireServiceImprovementRequestHubDto', 'QuestionnaireAnswerServiceImprovementResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }

}



export class AccountHubManager {
    /**
     * コンストラクタ
     */
    constructor(private http: HttpClient) {
    }

	// リソース破棄
    public Dispose = (): void => {
    }



    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public AccountValidate = (requestData: AccountValidateRequestHubDto, receiveCallBack: (data: AccountValidateResponseHubDto) => void, receiveErrorCallBack: (data: AccountValidateResponseHubDto) => void, noSessionCallBack: (data: AccountValidateResponseHubDto) => void, completeCallBack: (data: AccountValidateResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: AccountValidateResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: AccountValidateResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/AccountHub/AccountValidate';

        let responseData: AccountValidateResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('AccountHub', 'AccountValidate', 'AccountValidateRequestHubDto', 'AccountValidateResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('AccountHub', 'AccountValidate', 'AccountValidateRequestHubDto', 'AccountValidateResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('AccountHub', 'AccountValidate', 'AccountValidateRequestHubDto', 'AccountValidateResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public AccountDetail = (receiveCallBack: (data: AccountDetailResponseHubDto) => void, receiveErrorCallBack: (data: AccountDetailResponseHubDto) => void, noSessionCallBack: (data: AccountDetailResponseHubDto) => void, completeCallBack: (data: AccountDetailResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: AccountDetailResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: AccountDetailResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/AccountHub/AccountDetail';

        let responseData: AccountDetailResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('AccountHub', 'AccountDetail', null, 'AccountDetailResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('AccountHub', 'AccountDetail', null, 'AccountDetailResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('AccountHub', 'AccountDetail', null, 'AccountDetailResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public AccountDetailForVoaci = (receiveCallBack: (data: AccountDetailResponseHubDto) => void, receiveErrorCallBack: (data: AccountDetailResponseHubDto) => void, noSessionCallBack: (data: AccountDetailResponseHubDto) => void, completeCallBack: (data: AccountDetailResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: AccountDetailResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: AccountDetailResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/AccountHub/AccountDetailForVoaci';

        let responseData: AccountDetailResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('AccountHub', 'AccountDetailForVoaci', null, 'AccountDetailResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('AccountHub', 'AccountDetailForVoaci', null, 'AccountDetailResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('AccountHub', 'AccountDetailForVoaci', null, 'AccountDetailResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GmoIdConnectionStatusValidator = (receiveCallBack: (data: GmoConnectionStatusResponseHubDto) => void, receiveErrorCallBack: (data: GmoConnectionStatusResponseHubDto) => void, noSessionCallBack: (data: GmoConnectionStatusResponseHubDto) => void, completeCallBack: (data: GmoConnectionStatusResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: GmoConnectionStatusResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: GmoConnectionStatusResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/AccountHub/GmoIdConnectionStatusValidator';

        let responseData: GmoConnectionStatusResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('AccountHub', 'GmoIdConnectionStatusValidator', null, 'GmoConnectionStatusResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('AccountHub', 'GmoIdConnectionStatusValidator', null, 'GmoConnectionStatusResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('AccountHub', 'GmoIdConnectionStatusValidator', null, 'GmoConnectionStatusResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public AccountLayoutData = (receiveCallBack: (data: AccountLayoutDataResponseHubDto) => void, receiveErrorCallBack: (data: AccountLayoutDataResponseHubDto) => void, noSessionCallBack: (data: AccountLayoutDataResponseHubDto) => void, completeCallBack: (data: AccountLayoutDataResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: AccountLayoutDataResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: AccountLayoutDataResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/AccountHub/AccountLayoutData';

        let responseData: AccountLayoutDataResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('AccountHub', 'AccountLayoutData', null, 'AccountLayoutDataResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('AccountHub', 'AccountLayoutData', null, 'AccountLayoutDataResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('AccountHub', 'AccountLayoutData', null, 'AccountLayoutDataResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public AccountCreateValidator = (requestData: AccountCreateValidatorRequestHubDto, receiveCallBack: (data: AccountCreateValidatorResponseHubDto) => void, receiveErrorCallBack: (data: AccountCreateValidatorResponseHubDto) => void, noSessionCallBack: (data: AccountCreateValidatorResponseHubDto) => void, completeCallBack: (data: AccountCreateValidatorResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: AccountCreateValidatorResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: AccountCreateValidatorResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/AccountHub/AccountCreateValidator';

        let responseData: AccountCreateValidatorResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('AccountHub', 'AccountCreateValidator', 'AccountCreateValidatorRequestHubDto', 'AccountCreateValidatorResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('AccountHub', 'AccountCreateValidator', 'AccountCreateValidatorRequestHubDto', 'AccountCreateValidatorResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('AccountHub', 'AccountCreateValidator', 'AccountCreateValidatorRequestHubDto', 'AccountCreateValidatorResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public AccountCreate = (requestData: AccountCreateRequestHubDto, receiveCallBack: (data: AccountCreateResponseHubDto) => void, receiveErrorCallBack: (data: AccountCreateResponseHubDto) => void, noSessionCallBack: (data: AccountCreateResponseHubDto) => void, completeCallBack: (data: AccountCreateResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: AccountCreateResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: AccountCreateResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/AccountHub/AccountCreate';

        let responseData: AccountCreateResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('AccountHub', 'AccountCreate', 'AccountCreateRequestHubDto', 'AccountCreateResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('AccountHub', 'AccountCreate', 'AccountCreateRequestHubDto', 'AccountCreateResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('AccountHub', 'AccountCreate', 'AccountCreateRequestHubDto', 'AccountCreateResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public UpdateValidatorAccount = (requestData: AccountUpdateValidatorRequestHubDto, receiveCallBack: (data: AccountUpdateValidatorResponseHubDto) => void, receiveErrorCallBack: (data: AccountUpdateValidatorResponseHubDto) => void, noSessionCallBack: (data: AccountUpdateValidatorResponseHubDto) => void, completeCallBack: (data: AccountUpdateValidatorResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: AccountUpdateValidatorResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: AccountUpdateValidatorResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/AccountHub/UpdateValidatorAccount';

        let responseData: AccountUpdateValidatorResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('AccountHub', 'UpdateValidatorAccount', 'AccountUpdateValidatorRequestHubDto', 'AccountUpdateValidatorResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('AccountHub', 'UpdateValidatorAccount', 'AccountUpdateValidatorRequestHubDto', 'AccountUpdateValidatorResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('AccountHub', 'UpdateValidatorAccount', 'AccountUpdateValidatorRequestHubDto', 'AccountUpdateValidatorResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public UpdateAccount = (requestData: AccountUpdateRequestHubDto, receiveCallBack: (data: AccountUpdateResponseHubDto) => void, receiveErrorCallBack: (data: AccountUpdateResponseHubDto) => void, noSessionCallBack: (data: AccountUpdateResponseHubDto) => void, completeCallBack: (data: AccountUpdateResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: AccountUpdateResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: AccountUpdateResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/AccountHub/UpdateAccount';

        let responseData: AccountUpdateResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('AccountHub', 'UpdateAccount', 'AccountUpdateRequestHubDto', 'AccountUpdateResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('AccountHub', 'UpdateAccount', 'AccountUpdateRequestHubDto', 'AccountUpdateResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('AccountHub', 'UpdateAccount', 'AccountUpdateRequestHubDto', 'AccountUpdateResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public UpdateAutoNetDeShindanFlg = (requestData: UpdateAutoNetDeShindanFlgRequestHubDto, receiveCallBack: (data: AccountUpdateResponseHubDto) => void, receiveErrorCallBack: (data: AccountUpdateResponseHubDto) => void, noSessionCallBack: (data: AccountUpdateResponseHubDto) => void, completeCallBack: (data: AccountUpdateResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: AccountUpdateResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: AccountUpdateResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/AccountHub/UpdateAutoNetDeShindanFlg';

        let responseData: AccountUpdateResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('AccountHub', 'UpdateAutoNetDeShindanFlg', 'UpdateAutoNetDeShindanFlgRequestHubDto', 'AccountUpdateResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('AccountHub', 'UpdateAutoNetDeShindanFlg', 'UpdateAutoNetDeShindanFlgRequestHubDto', 'AccountUpdateResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('AccountHub', 'UpdateAutoNetDeShindanFlg', 'UpdateAutoNetDeShindanFlgRequestHubDto', 'AccountUpdateResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public UpdateAccountForVoaci = (requestData: AccountUpdateRequestHubDto, receiveCallBack: (data: AccountUpdateResponseHubDto) => void, receiveErrorCallBack: (data: AccountUpdateResponseHubDto) => void, noSessionCallBack: (data: AccountUpdateResponseHubDto) => void, completeCallBack: (data: AccountUpdateResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: AccountUpdateResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: AccountUpdateResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/AccountHub/UpdateAccountForVoaci';

        let responseData: AccountUpdateResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('AccountHub', 'UpdateAccountForVoaci', 'AccountUpdateRequestHubDto', 'AccountUpdateResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('AccountHub', 'UpdateAccountForVoaci', 'AccountUpdateRequestHubDto', 'AccountUpdateResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('AccountHub', 'UpdateAccountForVoaci', 'AccountUpdateRequestHubDto', 'AccountUpdateResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public SetLoginAlert = (requestData: SetLoginAlertRequestHubDto, receiveCallBack: (data: SetLoginAlertResponseHubDto) => void, receiveErrorCallBack: (data: SetLoginAlertResponseHubDto) => void, noSessionCallBack: (data: SetLoginAlertResponseHubDto) => void, completeCallBack: (data: SetLoginAlertResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SetLoginAlertResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SetLoginAlertResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/AccountHub/SetLoginAlert';

        let responseData: SetLoginAlertResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('AccountHub', 'SetLoginAlert', 'SetLoginAlertRequestHubDto', 'SetLoginAlertResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('AccountHub', 'SetLoginAlert', 'SetLoginAlertRequestHubDto', 'SetLoginAlertResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('AccountHub', 'SetLoginAlert', 'SetLoginAlertRequestHubDto', 'SetLoginAlertResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public SetParkingMail = (requestData: SetParkingMailRequestHubDto, receiveCallBack: (data: SetParkingMailResponseHubDto) => void, receiveErrorCallBack: (data: SetParkingMailResponseHubDto) => void, noSessionCallBack: (data: SetParkingMailResponseHubDto) => void, completeCallBack: (data: SetParkingMailResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SetParkingMailResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SetParkingMailResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/AccountHub/SetParkingMail';

        let responseData: SetParkingMailResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('AccountHub', 'SetParkingMail', 'SetParkingMailRequestHubDto', 'SetParkingMailResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('AccountHub', 'SetParkingMail', 'SetParkingMailRequestHubDto', 'SetParkingMailResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('AccountHub', 'SetParkingMail', 'SetParkingMailRequestHubDto', 'SetParkingMailResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public CancelGmoIdLinkage = (receiveCallBack: (data: CancelGmoIdLinkageResponseHubDto) => void, receiveErrorCallBack: (data: CancelGmoIdLinkageResponseHubDto) => void, noSessionCallBack: (data: CancelGmoIdLinkageResponseHubDto) => void, completeCallBack: (data: CancelGmoIdLinkageResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: CancelGmoIdLinkageResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: CancelGmoIdLinkageResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/AccountHub/CancelGmoIdLinkage';

        let responseData: CancelGmoIdLinkageResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('AccountHub', 'CancelGmoIdLinkage', null, 'CancelGmoIdLinkageResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('AccountHub', 'CancelGmoIdLinkage', null, 'CancelGmoIdLinkageResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('AccountHub', 'CancelGmoIdLinkage', null, 'CancelGmoIdLinkageResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ChangePassword = (requestData: ChangePasswordRequestHubDto, receiveCallBack: (data: ChangePasswordResponseHubDto) => void, receiveErrorCallBack: (data: ChangePasswordResponseHubDto) => void, noSessionCallBack: (data: ChangePasswordResponseHubDto) => void, completeCallBack: (data: ChangePasswordResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: ChangePasswordResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: ChangePasswordResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/AccountHub/ChangePassword';

        let responseData: ChangePasswordResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('AccountHub', 'ChangePassword', 'ChangePasswordRequestHubDto', 'ChangePasswordResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('AccountHub', 'ChangePassword', 'ChangePasswordRequestHubDto', 'ChangePasswordResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('AccountHub', 'ChangePassword', 'ChangePasswordRequestHubDto', 'ChangePasswordResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetChangePasswordWithUsedDomainInfo = (receiveCallBack: (data: AccountGetChangePasswordWithUsedDomainInfoResponseHubDto) => void, receiveErrorCallBack: (data: AccountGetChangePasswordWithUsedDomainInfoResponseHubDto) => void, noSessionCallBack: (data: AccountGetChangePasswordWithUsedDomainInfoResponseHubDto) => void, completeCallBack: (data: AccountGetChangePasswordWithUsedDomainInfoResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: AccountGetChangePasswordWithUsedDomainInfoResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: AccountGetChangePasswordWithUsedDomainInfoResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/AccountHub/GetChangePasswordWithUsedDomainInfo';

        let responseData: AccountGetChangePasswordWithUsedDomainInfoResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('AccountHub', 'GetChangePasswordWithUsedDomainInfo', null, 'AccountGetChangePasswordWithUsedDomainInfoResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('AccountHub', 'GetChangePasswordWithUsedDomainInfo', null, 'AccountGetChangePasswordWithUsedDomainInfoResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('AccountHub', 'GetChangePasswordWithUsedDomainInfo', null, 'AccountGetChangePasswordWithUsedDomainInfoResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ChangePasswordWithUsedDomain = (requestData: AccountChangePasswordWithUsedDomainRequestHubDto, receiveCallBack: (data: AccountChangePasswordWithUsedDomainResponseHubDto) => void, receiveErrorCallBack: (data: AccountChangePasswordWithUsedDomainResponseHubDto) => void, noSessionCallBack: (data: AccountChangePasswordWithUsedDomainResponseHubDto) => void, completeCallBack: (data: AccountChangePasswordWithUsedDomainResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: AccountChangePasswordWithUsedDomainResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: AccountChangePasswordWithUsedDomainResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/AccountHub/ChangePasswordWithUsedDomain';

        let responseData: AccountChangePasswordWithUsedDomainResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('AccountHub', 'ChangePasswordWithUsedDomain', 'AccountChangePasswordWithUsedDomainRequestHubDto', 'AccountChangePasswordWithUsedDomainResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('AccountHub', 'ChangePasswordWithUsedDomain', 'AccountChangePasswordWithUsedDomainRequestHubDto', 'AccountChangePasswordWithUsedDomainResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('AccountHub', 'ChangePasswordWithUsedDomain', 'AccountChangePasswordWithUsedDomainRequestHubDto', 'AccountChangePasswordWithUsedDomainResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public SendPasswordReminderMailWhenNoDomain = (receiveCallBack: (data: AccountSendPasswordReminderMailWhenNoDomainResponseHubDto) => void, receiveErrorCallBack: (data: AccountSendPasswordReminderMailWhenNoDomainResponseHubDto) => void, noSessionCallBack: (data: AccountSendPasswordReminderMailWhenNoDomainResponseHubDto) => void, completeCallBack: (data: AccountSendPasswordReminderMailWhenNoDomainResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: AccountSendPasswordReminderMailWhenNoDomainResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: AccountSendPasswordReminderMailWhenNoDomainResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/AccountHub/SendPasswordReminderMailWhenNoDomain';

        let responseData: AccountSendPasswordReminderMailWhenNoDomainResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('AccountHub', 'SendPasswordReminderMailWhenNoDomain', null, 'AccountSendPasswordReminderMailWhenNoDomainResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('AccountHub', 'SendPasswordReminderMailWhenNoDomain', null, 'AccountSendPasswordReminderMailWhenNoDomainResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('AccountHub', 'SendPasswordReminderMailWhenNoDomain', null, 'AccountSendPasswordReminderMailWhenNoDomainResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public AccountCheckResign = (receiveCallBack: (data: AccountCheckResignResponseHubDto) => void, receiveErrorCallBack: (data: AccountCheckResignResponseHubDto) => void, noSessionCallBack: (data: AccountCheckResignResponseHubDto) => void, completeCallBack: (data: AccountCheckResignResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: AccountCheckResignResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: AccountCheckResignResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/AccountHub/AccountCheckResign';

        let responseData: AccountCheckResignResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('AccountHub', 'AccountCheckResign', null, 'AccountCheckResignResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('AccountHub', 'AccountCheckResign', null, 'AccountCheckResignResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('AccountHub', 'AccountCheckResign', null, 'AccountCheckResignResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public AccountDelete = (receiveCallBack: (data: AccountDeleteResponseHubDto) => void, receiveErrorCallBack: (data: AccountDeleteResponseHubDto) => void, noSessionCallBack: (data: AccountDeleteResponseHubDto) => void, completeCallBack: (data: AccountDeleteResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: AccountDeleteResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: AccountDeleteResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/AccountHub/AccountDelete';

        let responseData: AccountDeleteResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('AccountHub', 'AccountDelete', null, 'AccountDeleteResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('AccountHub', 'AccountDelete', null, 'AccountDeleteResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('AccountHub', 'AccountDelete', null, 'AccountDeleteResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetRolePayment = (receiveCallBack: (data: RolePaymentResponseHubDto) => void, receiveErrorCallBack: (data: RolePaymentResponseHubDto) => void, noSessionCallBack: (data: RolePaymentResponseHubDto) => void, completeCallBack: (data: RolePaymentResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: RolePaymentResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: RolePaymentResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/AccountHub/GetRolePayment';

        let responseData: RolePaymentResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('AccountHub', 'GetRolePayment', null, 'RolePaymentResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('AccountHub', 'GetRolePayment', null, 'RolePaymentResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('AccountHub', 'GetRolePayment', null, 'RolePaymentResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public DecisionShowDomainSettingsIcon = (receiveCallBack: (data: DecisionShowDomainSettingsIconResponseHubDto) => void, receiveErrorCallBack: (data: DecisionShowDomainSettingsIconResponseHubDto) => void, noSessionCallBack: (data: DecisionShowDomainSettingsIconResponseHubDto) => void, completeCallBack: (data: DecisionShowDomainSettingsIconResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DecisionShowDomainSettingsIconResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DecisionShowDomainSettingsIconResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/AccountHub/DecisionShowDomainSettingsIcon';

        let responseData: DecisionShowDomainSettingsIconResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('AccountHub', 'DecisionShowDomainSettingsIcon', null, 'DecisionShowDomainSettingsIconResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('AccountHub', 'DecisionShowDomainSettingsIcon', null, 'DecisionShowDomainSettingsIconResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('AccountHub', 'DecisionShowDomainSettingsIcon', null, 'DecisionShowDomainSettingsIconResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public DecisionShowPaymentPage = (receiveCallBack: (data: DecisionShowPaymentPageResponseHubDto) => void, receiveErrorCallBack: (data: DecisionShowPaymentPageResponseHubDto) => void, noSessionCallBack: (data: DecisionShowPaymentPageResponseHubDto) => void, completeCallBack: (data: DecisionShowPaymentPageResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DecisionShowPaymentPageResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DecisionShowPaymentPageResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/AccountHub/DecisionShowPaymentPage';

        let responseData: DecisionShowPaymentPageResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('AccountHub', 'DecisionShowPaymentPage', null, 'DecisionShowPaymentPageResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('AccountHub', 'DecisionShowPaymentPage', null, 'DecisionShowPaymentPageResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('AccountHub', 'DecisionShowPaymentPage', null, 'DecisionShowPaymentPageResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetDesktopCloudBelongToAccount = (receiveCallBack: (data: GetHostingItemsResponseHubDto) => void, receiveErrorCallBack: (data: GetHostingItemsResponseHubDto) => void, noSessionCallBack: (data: GetHostingItemsResponseHubDto) => void, completeCallBack: (data: GetHostingItemsResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: GetHostingItemsResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: GetHostingItemsResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/AccountHub/GetDesktopCloudBelongToAccount';

        let responseData: GetHostingItemsResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('AccountHub', 'GetDesktopCloudBelongToAccount', null, 'GetHostingItemsResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('AccountHub', 'GetDesktopCloudBelongToAccount', null, 'GetHostingItemsResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('AccountHub', 'GetDesktopCloudBelongToAccount', null, 'GetHostingItemsResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetRsBelongToAccount = (receiveCallBack: (data: GetHostingItemsResponseHubDto) => void, receiveErrorCallBack: (data: GetHostingItemsResponseHubDto) => void, noSessionCallBack: (data: GetHostingItemsResponseHubDto) => void, completeCallBack: (data: GetHostingItemsResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: GetHostingItemsResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: GetHostingItemsResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/AccountHub/GetRsBelongToAccount';

        let responseData: GetHostingItemsResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('AccountHub', 'GetRsBelongToAccount', null, 'GetHostingItemsResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('AccountHub', 'GetRsBelongToAccount', null, 'GetHostingItemsResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('AccountHub', 'GetRsBelongToAccount', null, 'GetHostingItemsResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetFirstDayRegistrationRs = (receiveCallBack: (data: FirstDayRegistrationRsResponseHubDto) => void, receiveErrorCallBack: (data: FirstDayRegistrationRsResponseHubDto) => void, noSessionCallBack: (data: FirstDayRegistrationRsResponseHubDto) => void, completeCallBack: (data: FirstDayRegistrationRsResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: FirstDayRegistrationRsResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: FirstDayRegistrationRsResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/AccountHub/GetFirstDayRegistrationRs';

        let responseData: FirstDayRegistrationRsResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('AccountHub', 'GetFirstDayRegistrationRs', null, 'FirstDayRegistrationRsResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('AccountHub', 'GetFirstDayRegistrationRs', null, 'FirstDayRegistrationRsResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('AccountHub', 'GetFirstDayRegistrationRs', null, 'FirstDayRegistrationRsResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetRoleInformationAPIDownload = (receiveCallBack: (data: RoleInfoAPIDownloadResponseHubDto) => void, receiveErrorCallBack: (data: RoleInfoAPIDownloadResponseHubDto) => void, noSessionCallBack: (data: RoleInfoAPIDownloadResponseHubDto) => void, completeCallBack: (data: RoleInfoAPIDownloadResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: RoleInfoAPIDownloadResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: RoleInfoAPIDownloadResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/AccountHub/GetRoleInformationAPIDownload';

        let responseData: RoleInfoAPIDownloadResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('AccountHub', 'GetRoleInformationAPIDownload', null, 'RoleInfoAPIDownloadResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('AccountHub', 'GetRoleInformationAPIDownload', null, 'RoleInfoAPIDownloadResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('AccountHub', 'GetRoleInformationAPIDownload', null, 'RoleInfoAPIDownloadResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetRoleInformationDomainDelete = (receiveCallBack: (data: RoleInfoDeleteDomainResponseHubDto) => void, receiveErrorCallBack: (data: RoleInfoDeleteDomainResponseHubDto) => void, noSessionCallBack: (data: RoleInfoDeleteDomainResponseHubDto) => void, completeCallBack: (data: RoleInfoDeleteDomainResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: RoleInfoDeleteDomainResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: RoleInfoDeleteDomainResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/AccountHub/GetRoleInformationDomainDelete';

        let responseData: RoleInfoDeleteDomainResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('AccountHub', 'GetRoleInformationDomainDelete', null, 'RoleInfoDeleteDomainResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('AccountHub', 'GetRoleInformationDomainDelete', null, 'RoleInfoDeleteDomainResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('AccountHub', 'GetRoleInformationDomainDelete', null, 'RoleInfoDeleteDomainResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ContactCreate = (requestData: ContactCreateRequestHubDto, receiveCallBack: (data: ContactCreateResponseHubDto) => void, receiveErrorCallBack: (data: ContactCreateResponseHubDto) => void, noSessionCallBack: (data: ContactCreateResponseHubDto) => void, completeCallBack: (data: ContactCreateResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: ContactCreateResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: ContactCreateResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/AccountHub/ContactCreate';

        let responseData: ContactCreateResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('AccountHub', 'ContactCreate', 'ContactCreateRequestHubDto', 'ContactCreateResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('AccountHub', 'ContactCreate', 'ContactCreateRequestHubDto', 'ContactCreateResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('AccountHub', 'ContactCreate', 'ContactCreateRequestHubDto', 'ContactCreateResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ContactUpdate = (requestData: ContactUpdateRequestHubDto, receiveCallBack: (data: ContactUpdateResponseHubDto) => void, receiveErrorCallBack: (data: ContactUpdateResponseHubDto) => void, noSessionCallBack: (data: ContactUpdateResponseHubDto) => void, completeCallBack: (data: ContactUpdateResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: ContactUpdateResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: ContactUpdateResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/AccountHub/ContactUpdate';

        let responseData: ContactUpdateResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('AccountHub', 'ContactUpdate', 'ContactUpdateRequestHubDto', 'ContactUpdateResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('AccountHub', 'ContactUpdate', 'ContactUpdateRequestHubDto', 'ContactUpdateResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('AccountHub', 'ContactUpdate', 'ContactUpdateRequestHubDto', 'ContactUpdateResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ContactDelete = (requestData: ContactDeleteRequestHubDto, receiveCallBack: (data: ContactDeleteResponseHubDto) => void, receiveErrorCallBack: (data: ContactDeleteResponseHubDto) => void, noSessionCallBack: (data: ContactDeleteResponseHubDto) => void, completeCallBack: (data: ContactDeleteResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: ContactDeleteResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: ContactDeleteResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/AccountHub/ContactDelete';

        let responseData: ContactDeleteResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('AccountHub', 'ContactDelete', 'ContactDeleteRequestHubDto', 'ContactDeleteResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('AccountHub', 'ContactDelete', 'ContactDeleteRequestHubDto', 'ContactDeleteResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('AccountHub', 'ContactDelete', 'ContactDeleteRequestHubDto', 'ContactDeleteResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ContactInfoNsSetting = (receiveCallBack: (data: ContactInfoNsSettingResponseHubDto) => void, receiveErrorCallBack: (data: ContactInfoNsSettingResponseHubDto) => void, noSessionCallBack: (data: ContactInfoNsSettingResponseHubDto) => void, completeCallBack: (data: ContactInfoNsSettingResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: ContactInfoNsSettingResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: ContactInfoNsSettingResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/AccountHub/ContactInfoNsSetting';

        let responseData: ContactInfoNsSettingResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('AccountHub', 'ContactInfoNsSetting', null, 'ContactInfoNsSettingResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('AccountHub', 'ContactInfoNsSetting', null, 'ContactInfoNsSettingResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('AccountHub', 'ContactInfoNsSetting', null, 'ContactInfoNsSettingResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ContactInfoList = (receiveCallBack: (data: ContactInfoListResponseHubDto) => void, receiveErrorCallBack: (data: ContactInfoListResponseHubDto) => void, noSessionCallBack: (data: ContactInfoListResponseHubDto) => void, completeCallBack: (data: ContactInfoListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: ContactInfoListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: ContactInfoListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/AccountHub/ContactInfoList';

        let responseData: ContactInfoListResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('AccountHub', 'ContactInfoList', null, 'ContactInfoListResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('AccountHub', 'ContactInfoList', null, 'ContactInfoListResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('AccountHub', 'ContactInfoList', null, 'ContactInfoListResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ContactInfoDetails = (requestData: ContactInfoDetailsRequestHubDto, receiveCallBack: (data: ContactInfoDetailsResponseHubDto) => void, receiveErrorCallBack: (data: ContactInfoDetailsResponseHubDto) => void, noSessionCallBack: (data: ContactInfoDetailsResponseHubDto) => void, completeCallBack: (data: ContactInfoDetailsResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: ContactInfoDetailsResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: ContactInfoDetailsResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/AccountHub/ContactInfoDetails';

        let responseData: ContactInfoDetailsResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('AccountHub', 'ContactInfoDetails', 'ContactInfoDetailsRequestHubDto', 'ContactInfoDetailsResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('AccountHub', 'ContactInfoDetails', 'ContactInfoDetailsRequestHubDto', 'ContactInfoDetailsResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('AccountHub', 'ContactInfoDetails', 'ContactInfoDetailsRequestHubDto', 'ContactInfoDetailsResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ContactAccountInfo = (receiveCallBack: (data: ContactAccountInfoResponseHubDto) => void, receiveErrorCallBack: (data: ContactAccountInfoResponseHubDto) => void, noSessionCallBack: (data: ContactAccountInfoResponseHubDto) => void, completeCallBack: (data: ContactAccountInfoResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: ContactAccountInfoResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: ContactAccountInfoResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/AccountHub/ContactAccountInfo';

        let responseData: ContactAccountInfoResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('AccountHub', 'ContactAccountInfo', null, 'ContactAccountInfoResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('AccountHub', 'ContactAccountInfo', null, 'ContactAccountInfoResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('AccountHub', 'ContactAccountInfo', null, 'ContactAccountInfoResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public LineAccountDelete = (receiveCallBack: (data: LineAccountDeleteResponseHubDto) => void, receiveErrorCallBack: (data: LineAccountDeleteResponseHubDto) => void, noSessionCallBack: (data: LineAccountDeleteResponseHubDto) => void, completeCallBack: (data: LineAccountDeleteResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: LineAccountDeleteResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: LineAccountDeleteResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/AccountHub/LineAccountDelete';

        let responseData: LineAccountDeleteResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('AccountHub', 'LineAccountDelete', null, 'LineAccountDeleteResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('AccountHub', 'LineAccountDelete', null, 'LineAccountDeleteResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('AccountHub', 'LineAccountDelete', null, 'LineAccountDeleteResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public LineAccountInfo = (receiveCallBack: (data: LineAccountInfoResponseHubDto) => void, receiveErrorCallBack: (data: LineAccountInfoResponseHubDto) => void, noSessionCallBack: (data: LineAccountInfoResponseHubDto) => void, completeCallBack: (data: LineAccountInfoResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: LineAccountInfoResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: LineAccountInfoResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/AccountHub/LineAccountInfo';

        let responseData: LineAccountInfoResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('AccountHub', 'LineAccountInfo', null, 'LineAccountInfoResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('AccountHub', 'LineAccountInfo', null, 'LineAccountInfoResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('AccountHub', 'LineAccountInfo', null, 'LineAccountInfoResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public Unsubscribe = (requestData: UnsubscribeRequestHubDto, receiveCallBack: (data: UnsubscribeResponseHubDto) => void, receiveErrorCallBack: (data: UnsubscribeResponseHubDto) => void, noSessionCallBack: (data: UnsubscribeResponseHubDto) => void, completeCallBack: (data: UnsubscribeResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: UnsubscribeResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: UnsubscribeResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/AccountHub/Unsubscribe';

        let responseData: UnsubscribeResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('AccountHub', 'Unsubscribe', 'UnsubscribeRequestHubDto', 'UnsubscribeResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('AccountHub', 'Unsubscribe', 'UnsubscribeRequestHubDto', 'UnsubscribeResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('AccountHub', 'Unsubscribe', 'UnsubscribeRequestHubDto', 'UnsubscribeResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }

}



export class GsHubManager {
    /**
     * コンストラクタ
     */
    constructor(private http: HttpClient) {
    }

	// リソース破棄
    public Dispose = (): void => {
    }



    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GsList = (requestData: GsListRequestHubDto, receiveCallBack: (data: GsListResponseHubDto) => void, receiveErrorCallBack: (data: GsListResponseHubDto) => void, noSessionCallBack: (data: GsListResponseHubDto) => void, completeCallBack: (data: GsListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: GsListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: GsListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/GsHub/GsList';

        let responseData: GsListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('GsHub', 'GsList', 'GsListRequestHubDto', 'GsListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('GsHub', 'GsList', 'GsListRequestHubDto', 'GsListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('GsHub', 'GsList', 'GsListRequestHubDto', 'GsListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetGsDetail = (requestData: GsDetailRequestHubDto, receiveCallBack: (data: GsDetailResponseHubDto) => void, receiveErrorCallBack: (data: GsDetailResponseHubDto) => void, noSessionCallBack: (data: GsDetailResponseHubDto) => void, completeCallBack: (data: GsDetailResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: GsDetailResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: GsDetailResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/GsHub/GetGsDetail';

        let responseData: GsDetailResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('GsHub', 'GetGsDetail', 'GsDetailRequestHubDto', 'GsDetailResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('GsHub', 'GetGsDetail', 'GsDetailRequestHubDto', 'GsDetailResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('GsHub', 'GetGsDetail', 'GsDetailRequestHubDto', 'GsDetailResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetGsDetailControlPanel = (requestData: GsDetailControlPanelRequestHubDto, receiveCallBack: (data: GsDetailControlPanelResponseHubDto) => void, receiveErrorCallBack: (data: GsDetailControlPanelResponseHubDto) => void, noSessionCallBack: (data: GsDetailControlPanelResponseHubDto) => void, completeCallBack: (data: GsDetailControlPanelResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: GsDetailControlPanelResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: GsDetailControlPanelResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/GsHub/GetGsDetailControlPanel';

        let responseData: GsDetailControlPanelResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('GsHub', 'GetGsDetailControlPanel', 'GsDetailControlPanelRequestHubDto', 'GsDetailControlPanelResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('GsHub', 'GetGsDetailControlPanel', 'GsDetailControlPanelRequestHubDto', 'GsDetailControlPanelResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('GsHub', 'GetGsDetailControlPanel', 'GsDetailControlPanelRequestHubDto', 'GsDetailControlPanelResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetServerSettingInformation = (requestData: GsServerSettingInformationRequestHubDto, receiveCallBack: (data: GsServerSettingInformationResponseHubDto) => void, receiveErrorCallBack: (data: GsServerSettingInformationResponseHubDto) => void, noSessionCallBack: (data: GsServerSettingInformationResponseHubDto) => void, completeCallBack: (data: GsServerSettingInformationResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: GsServerSettingInformationResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: GsServerSettingInformationResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/GsHub/GetServerSettingInformation';

        let responseData: GsServerSettingInformationResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('GsHub', 'GetServerSettingInformation', 'GsServerSettingInformationRequestHubDto', 'GsServerSettingInformationResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('GsHub', 'GetServerSettingInformation', 'GsServerSettingInformationRequestHubDto', 'GsServerSettingInformationResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('GsHub', 'GetServerSettingInformation', 'GsServerSettingInformationRequestHubDto', 'GsServerSettingInformationResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetGsDetailOption = (requestData: GsDetailOptionRequestHubDto, receiveCallBack: (data: GsDetailOptionResponseHubDto) => void, receiveErrorCallBack: (data: GsDetailOptionResponseHubDto) => void, noSessionCallBack: (data: GsDetailOptionResponseHubDto) => void, completeCallBack: (data: GsDetailOptionResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: GsDetailOptionResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: GsDetailOptionResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/GsHub/GetGsDetailOption';

        let responseData: GsDetailOptionResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('GsHub', 'GetGsDetailOption', 'GsDetailOptionRequestHubDto', 'GsDetailOptionResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('GsHub', 'GetGsDetailOption', 'GsDetailOptionRequestHubDto', 'GsDetailOptionResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('GsHub', 'GetGsDetailOption', 'GsDetailOptionRequestHubDto', 'GsDetailOptionResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetGsDetailCancelOptionDetail = (requestData: GsDetailCancelOptionDetailRequestHubDto, receiveCallBack: (data: GsDetailCancelOptionDetailResponseHubDto) => void, receiveErrorCallBack: (data: GsDetailCancelOptionDetailResponseHubDto) => void, noSessionCallBack: (data: GsDetailCancelOptionDetailResponseHubDto) => void, completeCallBack: (data: GsDetailCancelOptionDetailResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: GsDetailCancelOptionDetailResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: GsDetailCancelOptionDetailResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/GsHub/GetGsDetailCancelOptionDetail';

        let responseData: GsDetailCancelOptionDetailResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('GsHub', 'GetGsDetailCancelOptionDetail', 'GsDetailCancelOptionDetailRequestHubDto', 'GsDetailCancelOptionDetailResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('GsHub', 'GetGsDetailCancelOptionDetail', 'GsDetailCancelOptionDetailRequestHubDto', 'GsDetailCancelOptionDetailResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('GsHub', 'GetGsDetailCancelOptionDetail', 'GsDetailCancelOptionDetailRequestHubDto', 'GsDetailCancelOptionDetailResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public RevokeGsDetailCancelOption = (requestData: GsDetailRevokeCancelOptionRequestHubDto, receiveCallBack: (data: GsDetailRevokeCancelOptionResponseHubDto) => void, receiveErrorCallBack: (data: GsDetailRevokeCancelOptionResponseHubDto) => void, noSessionCallBack: (data: GsDetailRevokeCancelOptionResponseHubDto) => void, completeCallBack: (data: GsDetailRevokeCancelOptionResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: GsDetailRevokeCancelOptionResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: GsDetailRevokeCancelOptionResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/GsHub/RevokeGsDetailCancelOption';

        let responseData: GsDetailRevokeCancelOptionResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('GsHub', 'RevokeGsDetailCancelOption', 'GsDetailRevokeCancelOptionRequestHubDto', 'GsDetailRevokeCancelOptionResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('GsHub', 'RevokeGsDetailCancelOption', 'GsDetailRevokeCancelOptionRequestHubDto', 'GsDetailRevokeCancelOptionResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('GsHub', 'RevokeGsDetailCancelOption', 'GsDetailRevokeCancelOptionRequestHubDto', 'GsDetailRevokeCancelOptionResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public RevokeGsDetailCancelPlan = (requestData: GsDetailRevokeCancelPlanRequestHubDto, receiveCallBack: (data: GsDetailRevokeCancelPlanResponseHubDto) => void, receiveErrorCallBack: (data: GsDetailRevokeCancelPlanResponseHubDto) => void, noSessionCallBack: (data: GsDetailRevokeCancelPlanResponseHubDto) => void, completeCallBack: (data: GsDetailRevokeCancelPlanResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: GsDetailRevokeCancelPlanResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: GsDetailRevokeCancelPlanResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/GsHub/RevokeGsDetailCancelPlan';

        let responseData: GsDetailRevokeCancelPlanResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('GsHub', 'RevokeGsDetailCancelPlan', 'GsDetailRevokeCancelPlanRequestHubDto', 'GsDetailRevokeCancelPlanResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('GsHub', 'RevokeGsDetailCancelPlan', 'GsDetailRevokeCancelPlanRequestHubDto', 'GsDetailRevokeCancelPlanResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('GsHub', 'RevokeGsDetailCancelPlan', 'GsDetailRevokeCancelPlanRequestHubDto', 'GsDetailRevokeCancelPlanResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ValidateGsDetailChangePlan = (requestData: GsDetailValidateChangePlanRequestHubDto, receiveCallBack: (data: GsDetailValidateChangePlanResponseHubDto) => void, receiveErrorCallBack: (data: GsDetailValidateChangePlanResponseHubDto) => void, noSessionCallBack: (data: GsDetailValidateChangePlanResponseHubDto) => void, completeCallBack: (data: GsDetailValidateChangePlanResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: GsDetailValidateChangePlanResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: GsDetailValidateChangePlanResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/GsHub/ValidateGsDetailChangePlan';

        let responseData: GsDetailValidateChangePlanResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('GsHub', 'ValidateGsDetailChangePlan', 'GsDetailValidateChangePlanRequestHubDto', 'GsDetailValidateChangePlanResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('GsHub', 'ValidateGsDetailChangePlan', 'GsDetailValidateChangePlanRequestHubDto', 'GsDetailValidateChangePlanResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('GsHub', 'ValidateGsDetailChangePlan', 'GsDetailValidateChangePlanRequestHubDto', 'GsDetailValidateChangePlanResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ValidateGsDetailCancelPlan = (requestData: GsDetailValidateCancelPlanRequestHubDto, receiveCallBack: (data: GsDetailValidateCancelPlanResponseHubDto) => void, receiveErrorCallBack: (data: GsDetailValidateCancelPlanResponseHubDto) => void, noSessionCallBack: (data: GsDetailValidateCancelPlanResponseHubDto) => void, completeCallBack: (data: GsDetailValidateCancelPlanResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: GsDetailValidateCancelPlanResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: GsDetailValidateCancelPlanResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/GsHub/ValidateGsDetailCancelPlan';

        let responseData: GsDetailValidateCancelPlanResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('GsHub', 'ValidateGsDetailCancelPlan', 'GsDetailValidateCancelPlanRequestHubDto', 'GsDetailValidateCancelPlanResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('GsHub', 'ValidateGsDetailCancelPlan', 'GsDetailValidateCancelPlanRequestHubDto', 'GsDetailValidateCancelPlanResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('GsHub', 'ValidateGsDetailCancelPlan', 'GsDetailValidateCancelPlanRequestHubDto', 'GsDetailValidateCancelPlanResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ValidateGsDetailRevokeCancelPlan = (requestData: GsDetailValidateRevokeCancelPlanRequestHubDto, receiveCallBack: (data: GsDetailValidateRevokeCancelPlanResponseHubDto) => void, receiveErrorCallBack: (data: GsDetailValidateRevokeCancelPlanResponseHubDto) => void, noSessionCallBack: (data: GsDetailValidateRevokeCancelPlanResponseHubDto) => void, completeCallBack: (data: GsDetailValidateRevokeCancelPlanResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: GsDetailValidateRevokeCancelPlanResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: GsDetailValidateRevokeCancelPlanResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/GsHub/ValidateGsDetailRevokeCancelPlan';

        let responseData: GsDetailValidateRevokeCancelPlanResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('GsHub', 'ValidateGsDetailRevokeCancelPlan', 'GsDetailValidateRevokeCancelPlanRequestHubDto', 'GsDetailValidateRevokeCancelPlanResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('GsHub', 'ValidateGsDetailRevokeCancelPlan', 'GsDetailValidateRevokeCancelPlanRequestHubDto', 'GsDetailValidateRevokeCancelPlanResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('GsHub', 'ValidateGsDetailRevokeCancelPlan', 'GsDetailValidateRevokeCancelPlanRequestHubDto', 'GsDetailValidateRevokeCancelPlanResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ValidateGsDetailAddOption = (requestData: GsDetailValidateAddOptionRequestHubDto, receiveCallBack: (data: GsDetailValidateAddOptionResponseHubDto) => void, receiveErrorCallBack: (data: GsDetailValidateAddOptionResponseHubDto) => void, noSessionCallBack: (data: GsDetailValidateAddOptionResponseHubDto) => void, completeCallBack: (data: GsDetailValidateAddOptionResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: GsDetailValidateAddOptionResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: GsDetailValidateAddOptionResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/GsHub/ValidateGsDetailAddOption';

        let responseData: GsDetailValidateAddOptionResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('GsHub', 'ValidateGsDetailAddOption', 'GsDetailValidateAddOptionRequestHubDto', 'GsDetailValidateAddOptionResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('GsHub', 'ValidateGsDetailAddOption', 'GsDetailValidateAddOptionRequestHubDto', 'GsDetailValidateAddOptionResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('GsHub', 'ValidateGsDetailAddOption', 'GsDetailValidateAddOptionRequestHubDto', 'GsDetailValidateAddOptionResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ValidateGsDetailChangeOption = (requestData: GsDetailValidateChangeOptionRequestHubDto, receiveCallBack: (data: GsDetailValidateChangeOptionResponseHubDto) => void, receiveErrorCallBack: (data: GsDetailValidateChangeOptionResponseHubDto) => void, noSessionCallBack: (data: GsDetailValidateChangeOptionResponseHubDto) => void, completeCallBack: (data: GsDetailValidateChangeOptionResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: GsDetailValidateChangeOptionResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: GsDetailValidateChangeOptionResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/GsHub/ValidateGsDetailChangeOption';

        let responseData: GsDetailValidateChangeOptionResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('GsHub', 'ValidateGsDetailChangeOption', 'GsDetailValidateChangeOptionRequestHubDto', 'GsDetailValidateChangeOptionResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('GsHub', 'ValidateGsDetailChangeOption', 'GsDetailValidateChangeOptionRequestHubDto', 'GsDetailValidateChangeOptionResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('GsHub', 'ValidateGsDetailChangeOption', 'GsDetailValidateChangeOptionRequestHubDto', 'GsDetailValidateChangeOptionResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ValidateGsDetailCancelOption = (requestData: GsDetailValidateCancelOptionRequestHubDto, receiveCallBack: (data: GsDetailValidateCancelOptionResponseHubDto) => void, receiveErrorCallBack: (data: GsDetailValidateCancelOptionResponseHubDto) => void, noSessionCallBack: (data: GsDetailValidateCancelOptionResponseHubDto) => void, completeCallBack: (data: GsDetailValidateCancelOptionResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: GsDetailValidateCancelOptionResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: GsDetailValidateCancelOptionResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/GsHub/ValidateGsDetailCancelOption';

        let responseData: GsDetailValidateCancelOptionResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('GsHub', 'ValidateGsDetailCancelOption', 'GsDetailValidateCancelOptionRequestHubDto', 'GsDetailValidateCancelOptionResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('GsHub', 'ValidateGsDetailCancelOption', 'GsDetailValidateCancelOptionRequestHubDto', 'GsDetailValidateCancelOptionResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('GsHub', 'ValidateGsDetailCancelOption', 'GsDetailValidateCancelOptionRequestHubDto', 'GsDetailValidateCancelOptionResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ValidateGsDetailRevokeCancelOption = (requestData: GsDetailValidateRevokeCancelOptionRequestHubDto, receiveCallBack: (data: GsDetailValidateRevokeCancelOptionResponseHubDto) => void, receiveErrorCallBack: (data: GsDetailValidateRevokeCancelOptionResponseHubDto) => void, noSessionCallBack: (data: GsDetailValidateRevokeCancelOptionResponseHubDto) => void, completeCallBack: (data: GsDetailValidateRevokeCancelOptionResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: GsDetailValidateRevokeCancelOptionResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: GsDetailValidateRevokeCancelOptionResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/GsHub/ValidateGsDetailRevokeCancelOption';

        let responseData: GsDetailValidateRevokeCancelOptionResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('GsHub', 'ValidateGsDetailRevokeCancelOption', 'GsDetailValidateRevokeCancelOptionRequestHubDto', 'GsDetailValidateRevokeCancelOptionResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('GsHub', 'ValidateGsDetailRevokeCancelOption', 'GsDetailValidateRevokeCancelOptionRequestHubDto', 'GsDetailValidateRevokeCancelOptionResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('GsHub', 'ValidateGsDetailRevokeCancelOption', 'GsDetailValidateRevokeCancelOptionRequestHubDto', 'GsDetailValidateRevokeCancelOptionResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetGsPlan = (requestData: GsPlanRequestHubDto, receiveCallBack: (data: GsPlanResponseHubDto) => void, receiveErrorCallBack: (data: GsPlanResponseHubDto) => void, noSessionCallBack: (data: GsPlanResponseHubDto) => void, completeCallBack: (data: GsPlanResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: GsPlanResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: GsPlanResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/GsHub/GetGsPlan';

        let responseData: GsPlanResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('GsHub', 'GetGsPlan', 'GsPlanRequestHubDto', 'GsPlanResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('GsHub', 'GetGsPlan', 'GsPlanRequestHubDto', 'GsPlanResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('GsHub', 'GetGsPlan', 'GsPlanRequestHubDto', 'GsPlanResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetGsPlanChangedDetail = (requestData: GsPlanChangedDetailRequestHubDto, receiveCallBack: (data: GsPlanChangedDetailResponseHubDto) => void, receiveErrorCallBack: (data: GsPlanChangedDetailResponseHubDto) => void, noSessionCallBack: (data: GsPlanChangedDetailResponseHubDto) => void, completeCallBack: (data: GsPlanChangedDetailResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: GsPlanChangedDetailResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: GsPlanChangedDetailResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/GsHub/GetGsPlanChangedDetail';

        let responseData: GsPlanChangedDetailResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('GsHub', 'GetGsPlanChangedDetail', 'GsPlanChangedDetailRequestHubDto', 'GsPlanChangedDetailResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('GsHub', 'GetGsPlanChangedDetail', 'GsPlanChangedDetailRequestHubDto', 'GsPlanChangedDetailResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('GsHub', 'GetGsPlanChangedDetail', 'GsPlanChangedDetailRequestHubDto', 'GsPlanChangedDetailResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ChangeGsPlan = (requestData: GsPlanChangeRequestHubDto, receiveCallBack: (data: GsPlanChangeResponseHubDto) => void, receiveErrorCallBack: (data: GsPlanChangeResponseHubDto) => void, noSessionCallBack: (data: GsPlanChangeResponseHubDto) => void, completeCallBack: (data: GsPlanChangeResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: GsPlanChangeResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: GsPlanChangeResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/GsHub/ChangeGsPlan';

        let responseData: GsPlanChangeResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('GsHub', 'ChangeGsPlan', 'GsPlanChangeRequestHubDto', 'GsPlanChangeResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('GsHub', 'ChangeGsPlan', 'GsPlanChangeRequestHubDto', 'GsPlanChangeResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('GsHub', 'ChangeGsPlan', 'GsPlanChangeRequestHubDto', 'GsPlanChangeResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetGsCancelPlanData = (requestData: GsPlanCancelDataRequestHubDto, receiveCallBack: (data: GsPlanCancelDataResponseHubDto) => void, receiveErrorCallBack: (data: GsPlanCancelDataResponseHubDto) => void, noSessionCallBack: (data: GsPlanCancelDataResponseHubDto) => void, completeCallBack: (data: GsPlanCancelDataResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: GsPlanCancelDataResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: GsPlanCancelDataResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/GsHub/GetGsCancelPlanData';

        let responseData: GsPlanCancelDataResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('GsHub', 'GetGsCancelPlanData', 'GsPlanCancelDataRequestHubDto', 'GsPlanCancelDataResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('GsHub', 'GetGsCancelPlanData', 'GsPlanCancelDataRequestHubDto', 'GsPlanCancelDataResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('GsHub', 'GetGsCancelPlanData', 'GsPlanCancelDataRequestHubDto', 'GsPlanCancelDataResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public CancelGsPlan = (requestData: GsPlanCancelRequestHubDto, receiveCallBack: (data: GsPlanCancelResponseHubDto) => void, receiveErrorCallBack: (data: GsPlanCancelResponseHubDto) => void, noSessionCallBack: (data: GsPlanCancelResponseHubDto) => void, completeCallBack: (data: GsPlanCancelResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: GsPlanCancelResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: GsPlanCancelResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/GsHub/CancelGsPlan';

        let responseData: GsPlanCancelResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('GsHub', 'CancelGsPlan', 'GsPlanCancelRequestHubDto', 'GsPlanCancelResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('GsHub', 'CancelGsPlan', 'GsPlanCancelRequestHubDto', 'GsPlanCancelResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('GsHub', 'CancelGsPlan', 'GsPlanCancelRequestHubDto', 'GsPlanCancelResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetGsAddOptions = (requestData: GsOptionAddGetRequestHubDto, receiveCallBack: (data: HostingAddOptionSelectListResponseHubDto) => void, receiveErrorCallBack: (data: HostingAddOptionSelectListResponseHubDto) => void, noSessionCallBack: (data: HostingAddOptionSelectListResponseHubDto) => void, completeCallBack: (data: HostingAddOptionSelectListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: HostingAddOptionSelectListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: HostingAddOptionSelectListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/GsHub/GetGsAddOptions';

        let responseData: HostingAddOptionSelectListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('GsHub', 'GetGsAddOptions', 'GsOptionAddGetRequestHubDto', 'HostingAddOptionSelectListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('GsHub', 'GetGsAddOptions', 'GsOptionAddGetRequestHubDto', 'HostingAddOptionSelectListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('GsHub', 'GetGsAddOptions', 'GsOptionAddGetRequestHubDto', 'HostingAddOptionSelectListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetAddOptionSslDomains = (requestData: GsAddOptionGetSslDomainsRequestHubDto, receiveCallBack: (data: GsAddOptionGetSslDomainsResponseHubDto) => void, receiveErrorCallBack: (data: GsAddOptionGetSslDomainsResponseHubDto) => void, noSessionCallBack: (data: GsAddOptionGetSslDomainsResponseHubDto) => void, completeCallBack: (data: GsAddOptionGetSslDomainsResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: GsAddOptionGetSslDomainsResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: GsAddOptionGetSslDomainsResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/GsHub/GetAddOptionSslDomains';

        let responseData: GsAddOptionGetSslDomainsResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('GsHub', 'GetAddOptionSslDomains', 'GsAddOptionGetSslDomainsRequestHubDto', 'GsAddOptionGetSslDomainsResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('GsHub', 'GetAddOptionSslDomains', 'GsAddOptionGetSslDomainsRequestHubDto', 'GsAddOptionGetSslDomainsResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('GsHub', 'GetAddOptionSslDomains', 'GsAddOptionGetSslDomainsRequestHubDto', 'GsAddOptionGetSslDomainsResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ValidateAddOptionSslInfo = (requestData: GsAddOptionValidateSslInfoRequestHubDto, receiveCallBack: (data: GsAddOptionValidateSslInfoResponseHubDto) => void, receiveErrorCallBack: (data: GsAddOptionValidateSslInfoResponseHubDto) => void, noSessionCallBack: (data: GsAddOptionValidateSslInfoResponseHubDto) => void, completeCallBack: (data: GsAddOptionValidateSslInfoResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: GsAddOptionValidateSslInfoResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: GsAddOptionValidateSslInfoResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/GsHub/ValidateAddOptionSslInfo';

        let responseData: GsAddOptionValidateSslInfoResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('GsHub', 'ValidateAddOptionSslInfo', 'GsAddOptionValidateSslInfoRequestHubDto', 'GsAddOptionValidateSslInfoResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('GsHub', 'ValidateAddOptionSslInfo', 'GsAddOptionValidateSslInfoRequestHubDto', 'GsAddOptionValidateSslInfoResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('GsHub', 'ValidateAddOptionSslInfo', 'GsAddOptionValidateSslInfoRequestHubDto', 'GsAddOptionValidateSslInfoResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ValidateAddOptionWordpress = (requestData: HostingOptionWpSiteInfoRequestHubDto, receiveCallBack: (data: GsAddOptionValidateWordpressResponseHubDto) => void, receiveErrorCallBack: (data: GsAddOptionValidateWordpressResponseHubDto) => void, noSessionCallBack: (data: GsAddOptionValidateWordpressResponseHubDto) => void, completeCallBack: (data: GsAddOptionValidateWordpressResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: GsAddOptionValidateWordpressResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: GsAddOptionValidateWordpressResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/GsHub/ValidateAddOptionWordpress';

        let responseData: GsAddOptionValidateWordpressResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('GsHub', 'ValidateAddOptionWordpress', 'HostingOptionWpSiteInfoRequestHubDto', 'GsAddOptionValidateWordpressResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('GsHub', 'ValidateAddOptionWordpress', 'HostingOptionWpSiteInfoRequestHubDto', 'GsAddOptionValidateWordpressResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('GsHub', 'ValidateAddOptionWordpress', 'HostingOptionWpSiteInfoRequestHubDto', 'GsAddOptionValidateWordpressResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetAddOptionSitelockDomains = (requestData: GsAddOptionSiteLockDomainsGetRequestHubDto, receiveCallBack: (data: GsAddOptionGetSitelockDomainsResponseHubDto) => void, receiveErrorCallBack: (data: GsAddOptionGetSitelockDomainsResponseHubDto) => void, noSessionCallBack: (data: GsAddOptionGetSitelockDomainsResponseHubDto) => void, completeCallBack: (data: GsAddOptionGetSitelockDomainsResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: GsAddOptionGetSitelockDomainsResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: GsAddOptionGetSitelockDomainsResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/GsHub/GetAddOptionSitelockDomains';

        let responseData: GsAddOptionGetSitelockDomainsResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('GsHub', 'GetAddOptionSitelockDomains', 'GsAddOptionSiteLockDomainsGetRequestHubDto', 'GsAddOptionGetSitelockDomainsResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('GsHub', 'GetAddOptionSitelockDomains', 'GsAddOptionSiteLockDomainsGetRequestHubDto', 'GsAddOptionGetSitelockDomainsResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('GsHub', 'GetAddOptionSitelockDomains', 'GsAddOptionSiteLockDomainsGetRequestHubDto', 'GsAddOptionGetSitelockDomainsResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetAddOptionWpCloudIpInfo = (requestData: GsAddOptionGetWpCloudIpInfoRequestHubDto, receiveCallBack: (data: GsAddOptionGetWpCloudIpInfosResponseHubDto) => void, receiveErrorCallBack: (data: GsAddOptionGetWpCloudIpInfosResponseHubDto) => void, noSessionCallBack: (data: GsAddOptionGetWpCloudIpInfosResponseHubDto) => void, completeCallBack: (data: GsAddOptionGetWpCloudIpInfosResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: GsAddOptionGetWpCloudIpInfosResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: GsAddOptionGetWpCloudIpInfosResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/GsHub/GetAddOptionWpCloudIpInfo';

        let responseData: GsAddOptionGetWpCloudIpInfosResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('GsHub', 'GetAddOptionWpCloudIpInfo', 'GsAddOptionGetWpCloudIpInfoRequestHubDto', 'GsAddOptionGetWpCloudIpInfosResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('GsHub', 'GetAddOptionWpCloudIpInfo', 'GsAddOptionGetWpCloudIpInfoRequestHubDto', 'GsAddOptionGetWpCloudIpInfosResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('GsHub', 'GetAddOptionWpCloudIpInfo', 'GsAddOptionGetWpCloudIpInfoRequestHubDto', 'GsAddOptionGetWpCloudIpInfosResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetAddOptionCountriesAndPrefectures = (receiveCallBack: (data: HostingCountriesAndPrefecturesResponseHubDto) => void, receiveErrorCallBack: (data: HostingCountriesAndPrefecturesResponseHubDto) => void, noSessionCallBack: (data: HostingCountriesAndPrefecturesResponseHubDto) => void, completeCallBack: (data: HostingCountriesAndPrefecturesResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: HostingCountriesAndPrefecturesResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: HostingCountriesAndPrefecturesResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/GsHub/GetAddOptionCountriesAndPrefectures';

        let responseData: HostingCountriesAndPrefecturesResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('GsHub', 'GetAddOptionCountriesAndPrefectures', null, 'HostingCountriesAndPrefecturesResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('GsHub', 'GetAddOptionCountriesAndPrefectures', null, 'HostingCountriesAndPrefecturesResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('GsHub', 'GetAddOptionCountriesAndPrefectures', null, 'HostingCountriesAndPrefecturesResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetGsAddOptionDetail = (requestData: HostingAddOptionRequestHubDto, receiveCallBack: (data: GsOptionAddDetailGetResponseHubDto) => void, receiveErrorCallBack: (data: GsOptionAddDetailGetResponseHubDto) => void, noSessionCallBack: (data: GsOptionAddDetailGetResponseHubDto) => void, completeCallBack: (data: GsOptionAddDetailGetResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: GsOptionAddDetailGetResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: GsOptionAddDetailGetResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/GsHub/GetGsAddOptionDetail';

        let responseData: GsOptionAddDetailGetResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('GsHub', 'GetGsAddOptionDetail', 'HostingAddOptionRequestHubDto', 'GsOptionAddDetailGetResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('GsHub', 'GetGsAddOptionDetail', 'HostingAddOptionRequestHubDto', 'GsOptionAddDetailGetResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('GsHub', 'GetGsAddOptionDetail', 'HostingAddOptionRequestHubDto', 'GsOptionAddDetailGetResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public AddGsAddOptions = (requestData: HostingAddOptionRequestHubDto, receiveCallBack: (data: GsOptionAddResponseHubDto) => void, receiveErrorCallBack: (data: GsOptionAddResponseHubDto) => void, noSessionCallBack: (data: GsOptionAddResponseHubDto) => void, completeCallBack: (data: GsOptionAddResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: GsOptionAddResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: GsOptionAddResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/GsHub/AddGsAddOptions';

        let responseData: GsOptionAddResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('GsHub', 'AddGsAddOptions', 'HostingAddOptionRequestHubDto', 'GsOptionAddResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('GsHub', 'AddGsAddOptions', 'HostingAddOptionRequestHubDto', 'GsOptionAddResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('GsHub', 'AddGsAddOptions', 'HostingAddOptionRequestHubDto', 'GsOptionAddResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetGsChangeOptions = (requestData: GsOptionChangePlansGetRequestHubDto, receiveCallBack: (data: GsOptionChangePlansGetResponseHubDto) => void, receiveErrorCallBack: (data: GsOptionChangePlansGetResponseHubDto) => void, noSessionCallBack: (data: GsOptionChangePlansGetResponseHubDto) => void, completeCallBack: (data: GsOptionChangePlansGetResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: GsOptionChangePlansGetResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: GsOptionChangePlansGetResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/GsHub/GetGsChangeOptions';

        let responseData: GsOptionChangePlansGetResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('GsHub', 'GetGsChangeOptions', 'GsOptionChangePlansGetRequestHubDto', 'GsOptionChangePlansGetResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('GsHub', 'GetGsChangeOptions', 'GsOptionChangePlansGetRequestHubDto', 'GsOptionChangePlansGetResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('GsHub', 'GetGsChangeOptions', 'GsOptionChangePlansGetRequestHubDto', 'GsOptionChangePlansGetResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetGsChangeOptionDetail = (requestData: GsOptionChangePlansDetailGetRequestHubDto, receiveCallBack: (data: GsOptionChangePlansDetailGetResponseHubDto) => void, receiveErrorCallBack: (data: GsOptionChangePlansDetailGetResponseHubDto) => void, noSessionCallBack: (data: GsOptionChangePlansDetailGetResponseHubDto) => void, completeCallBack: (data: GsOptionChangePlansDetailGetResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: GsOptionChangePlansDetailGetResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: GsOptionChangePlansDetailGetResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/GsHub/GetGsChangeOptionDetail';

        let responseData: GsOptionChangePlansDetailGetResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('GsHub', 'GetGsChangeOptionDetail', 'GsOptionChangePlansDetailGetRequestHubDto', 'GsOptionChangePlansDetailGetResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('GsHub', 'GetGsChangeOptionDetail', 'GsOptionChangePlansDetailGetRequestHubDto', 'GsOptionChangePlansDetailGetResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('GsHub', 'GetGsChangeOptionDetail', 'GsOptionChangePlansDetailGetRequestHubDto', 'GsOptionChangePlansDetailGetResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ChangeGsChangeOption = (requestData: GsOptionChangePlanRequestHubDto, receiveCallBack: (data: GsOptionChangePlanResponseHubDto) => void, receiveErrorCallBack: (data: GsOptionChangePlanResponseHubDto) => void, noSessionCallBack: (data: GsOptionChangePlanResponseHubDto) => void, completeCallBack: (data: GsOptionChangePlanResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: GsOptionChangePlanResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: GsOptionChangePlanResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/GsHub/ChangeGsChangeOption';

        let responseData: GsOptionChangePlanResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('GsHub', 'ChangeGsChangeOption', 'GsOptionChangePlanRequestHubDto', 'GsOptionChangePlanResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('GsHub', 'ChangeGsChangeOption', 'GsOptionChangePlanRequestHubDto', 'GsOptionChangePlanResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('GsHub', 'ChangeGsChangeOption', 'GsOptionChangePlanRequestHubDto', 'GsOptionChangePlanResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetGsCancelOptionDetail = (requestData: GsOptionCancelDetailRequestHubDto, receiveCallBack: (data: GsOptionCancelDetailResponseHubDto) => void, receiveErrorCallBack: (data: GsOptionCancelDetailResponseHubDto) => void, noSessionCallBack: (data: GsOptionCancelDetailResponseHubDto) => void, completeCallBack: (data: GsOptionCancelDetailResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: GsOptionCancelDetailResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: GsOptionCancelDetailResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/GsHub/GetGsCancelOptionDetail';

        let responseData: GsOptionCancelDetailResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('GsHub', 'GetGsCancelOptionDetail', 'GsOptionCancelDetailRequestHubDto', 'GsOptionCancelDetailResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('GsHub', 'GetGsCancelOptionDetail', 'GsOptionCancelDetailRequestHubDto', 'GsOptionCancelDetailResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('GsHub', 'GetGsCancelOptionDetail', 'GsOptionCancelDetailRequestHubDto', 'GsOptionCancelDetailResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public CancelGsCancelOption = (requestData: GsOptionCancelRequestHubDto, receiveCallBack: (data: GsOptionCancelResponseHubDto) => void, receiveErrorCallBack: (data: GsOptionCancelResponseHubDto) => void, noSessionCallBack: (data: GsOptionCancelResponseHubDto) => void, completeCallBack: (data: GsOptionCancelResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: GsOptionCancelResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: GsOptionCancelResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/GsHub/CancelGsCancelOption';

        let responseData: GsOptionCancelResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('GsHub', 'CancelGsCancelOption', 'GsOptionCancelRequestHubDto', 'GsOptionCancelResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('GsHub', 'CancelGsCancelOption', 'GsOptionCancelRequestHubDto', 'GsOptionCancelResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('GsHub', 'CancelGsCancelOption', 'GsOptionCancelRequestHubDto', 'GsOptionCancelResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetServerRedirection = (requestData: ServerRedirectionRequestHubDto, receiveCallBack: (data: ServerRedirectionResponseHubDto) => void, receiveErrorCallBack: (data: ServerRedirectionResponseHubDto) => void, noSessionCallBack: (data: ServerRedirectionResponseHubDto) => void, completeCallBack: (data: ServerRedirectionResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: ServerRedirectionResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: ServerRedirectionResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/GsHub/GetServerRedirection';

        let responseData: ServerRedirectionResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('GsHub', 'GetServerRedirection', 'ServerRedirectionRequestHubDto', 'ServerRedirectionResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('GsHub', 'GetServerRedirection', 'ServerRedirectionRequestHubDto', 'ServerRedirectionResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('GsHub', 'GetServerRedirection', 'ServerRedirectionRequestHubDto', 'ServerRedirectionResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }

}



export class NavigationHubManager {
    /**
     * コンストラクタ
     */
    constructor(private http: HttpClient) {
    }

	// リソース破棄
    public Dispose = (): void => {
    }



    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public TokenUrl = (requestData: NavigationRequestHubDto, receiveCallBack: (data: NavigationResponseHubDto) => void, receiveErrorCallBack: (data: NavigationResponseHubDto) => void, noSessionCallBack: (data: NavigationResponseHubDto) => void, completeCallBack: (data: NavigationResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: NavigationResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: NavigationResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/NavigationHub/TokenUrl';

        let responseData: NavigationResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('NavigationHub', 'TokenUrl', 'NavigationRequestHubDto', 'NavigationResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('NavigationHub', 'TokenUrl', 'NavigationRequestHubDto', 'NavigationResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('NavigationHub', 'TokenUrl', 'NavigationRequestHubDto', 'NavigationResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetServerOrderRedirection = (requestData: ServerOrderRedirectionRequestHubDto, receiveCallBack: (data: ServerOrderRedirectionResponseHubDto) => void, receiveErrorCallBack: (data: ServerOrderRedirectionResponseHubDto) => void, noSessionCallBack: (data: ServerOrderRedirectionResponseHubDto) => void, completeCallBack: (data: ServerOrderRedirectionResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: ServerOrderRedirectionResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: ServerOrderRedirectionResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/NavigationHub/GetServerOrderRedirection';

        let responseData: ServerOrderRedirectionResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('NavigationHub', 'GetServerOrderRedirection', 'ServerOrderRedirectionRequestHubDto', 'ServerOrderRedirectionResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('NavigationHub', 'GetServerOrderRedirection', 'ServerOrderRedirectionRequestHubDto', 'ServerOrderRedirectionResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('NavigationHub', 'GetServerOrderRedirection', 'ServerOrderRedirectionRequestHubDto', 'ServerOrderRedirectionResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetDomainOrderRedirection = (requestData: NavigationRequestHubDto, receiveCallBack: (data: NavigationResponseHubDto) => void, receiveErrorCallBack: (data: NavigationResponseHubDto) => void, noSessionCallBack: (data: NavigationResponseHubDto) => void, completeCallBack: (data: NavigationResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: NavigationResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: NavigationResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/NavigationHub/GetDomainOrderRedirection';

        let responseData: NavigationResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('NavigationHub', 'GetDomainOrderRedirection', 'NavigationRequestHubDto', 'NavigationResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('NavigationHub', 'GetDomainOrderRedirection', 'NavigationRequestHubDto', 'NavigationResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('NavigationHub', 'GetDomainOrderRedirection', 'NavigationRequestHubDto', 'NavigationResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetNewDomainUrl = (requestData: NavigationGetNewDomainUrlRequestHubDto, receiveCallBack: (data: NavigationResponseHubDto) => void, receiveErrorCallBack: (data: NavigationResponseHubDto) => void, noSessionCallBack: (data: NavigationResponseHubDto) => void, completeCallBack: (data: NavigationResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: NavigationResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: NavigationResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/NavigationHub/GetNewDomainUrl';

        let responseData: NavigationResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('NavigationHub', 'GetNewDomainUrl', 'NavigationGetNewDomainUrlRequestHubDto', 'NavigationResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('NavigationHub', 'GetNewDomainUrl', 'NavigationGetNewDomainUrlRequestHubDto', 'NavigationResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('NavigationHub', 'GetNewDomainUrl', 'NavigationGetNewDomainUrlRequestHubDto', 'NavigationResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetServerRelateRedirection = (requestData: ServerRelateRedirectionRequestHubDto, receiveCallBack: (data: ServerRelateRedirectionResponseHubDto) => void, receiveErrorCallBack: (data: ServerRelateRedirectionResponseHubDto) => void, noSessionCallBack: (data: ServerRelateRedirectionResponseHubDto) => void, completeCallBack: (data: ServerRelateRedirectionResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: ServerRelateRedirectionResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: ServerRelateRedirectionResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/NavigationHub/GetServerRelateRedirection';

        let responseData: ServerRelateRedirectionResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('NavigationHub', 'GetServerRelateRedirection', 'ServerRelateRedirectionRequestHubDto', 'ServerRelateRedirectionResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('NavigationHub', 'GetServerRelateRedirection', 'ServerRelateRedirectionRequestHubDto', 'ServerRelateRedirectionResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('NavigationHub', 'GetServerRelateRedirection', 'ServerRelateRedirectionRequestHubDto', 'ServerRelateRedirectionResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetServerIntegrateRedirection = (requestData: ServerIntegrateRedirectionRequestHubDto, receiveCallBack: (data: ServerIntegrateRedirectionResponseHubDto) => void, receiveErrorCallBack: (data: ServerIntegrateRedirectionResponseHubDto) => void, noSessionCallBack: (data: ServerIntegrateRedirectionResponseHubDto) => void, completeCallBack: (data: ServerIntegrateRedirectionResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: ServerIntegrateRedirectionResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: ServerIntegrateRedirectionResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/NavigationHub/GetServerIntegrateRedirection';

        let responseData: ServerIntegrateRedirectionResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('NavigationHub', 'GetServerIntegrateRedirection', 'ServerIntegrateRedirectionRequestHubDto', 'ServerIntegrateRedirectionResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('NavigationHub', 'GetServerIntegrateRedirection', 'ServerIntegrateRedirectionRequestHubDto', 'ServerIntegrateRedirectionResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('NavigationHub', 'GetServerIntegrateRedirection', 'ServerIntegrateRedirectionRequestHubDto', 'ServerIntegrateRedirectionResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetEvSslUrl = (receiveCallBack: (data: ServerRedirectionResponseHubDto) => void, receiveErrorCallBack: (data: ServerRedirectionResponseHubDto) => void, noSessionCallBack: (data: ServerRedirectionResponseHubDto) => void, completeCallBack: (data: ServerRedirectionResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: ServerRedirectionResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: ServerRedirectionResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/NavigationHub/GetEvSslUrl';

        let responseData: ServerRedirectionResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('NavigationHub', 'GetEvSslUrl', null, 'ServerRedirectionResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('NavigationHub', 'GetEvSslUrl', null, 'ServerRedirectionResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('NavigationHub', 'GetEvSslUrl', null, 'ServerRedirectionResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetRsEvSslUrl = (receiveCallBack: (data: ServerRedirectionResponseHubDto) => void, receiveErrorCallBack: (data: ServerRedirectionResponseHubDto) => void, noSessionCallBack: (data: ServerRedirectionResponseHubDto) => void, completeCallBack: (data: ServerRedirectionResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: ServerRedirectionResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: ServerRedirectionResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/NavigationHub/GetRsEvSslUrl';

        let responseData: ServerRedirectionResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('NavigationHub', 'GetRsEvSslUrl', null, 'ServerRedirectionResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('NavigationHub', 'GetRsEvSslUrl', null, 'ServerRedirectionResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('NavigationHub', 'GetRsEvSslUrl', null, 'ServerRedirectionResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetWpThemeUrl = (requestData: NavigationRequestHubDto, receiveCallBack: (data: NavigationResponseHubDto) => void, receiveErrorCallBack: (data: NavigationResponseHubDto) => void, noSessionCallBack: (data: NavigationResponseHubDto) => void, completeCallBack: (data: NavigationResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: NavigationResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: NavigationResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/NavigationHub/GetWpThemeUrl';

        let responseData: NavigationResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('NavigationHub', 'GetWpThemeUrl', 'NavigationRequestHubDto', 'NavigationResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('NavigationHub', 'GetWpThemeUrl', 'NavigationRequestHubDto', 'NavigationResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('NavigationHub', 'GetWpThemeUrl', 'NavigationRequestHubDto', 'NavigationResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }

}



export class DnsHubManager {
    /**
     * コンストラクタ
     */
    constructor(private http: HttpClient) {
    }

	// リソース破棄
    public Dispose = (): void => {
    }



    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public DnsSdList = (requestData: DnsSdListRequestHubDto, receiveCallBack: (data: DnsSdListResponseHubDto) => void, receiveErrorCallBack: (data: DnsSdListResponseHubDto) => void, noSessionCallBack: (data: DnsSdListResponseHubDto) => void, completeCallBack: (data: DnsSdListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DnsSdListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DnsSdListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DnsHub/DnsSdList';

        let responseData: DnsSdListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DnsHub', 'DnsSdList', 'DnsSdListRequestHubDto', 'DnsSdListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DnsHub', 'DnsSdList', 'DnsSdListRequestHubDto', 'DnsSdListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DnsHub', 'DnsSdList', 'DnsSdListRequestHubDto', 'DnsSdListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }

}



export class SslHubManager {
    /**
     * コンストラクタ
     */
    constructor(private http: HttpClient) {
    }

	// リソース破棄
    public Dispose = (): void => {
    }



    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public SslSdList = (requestData: SslSdListRequestHubDto, receiveCallBack: (data: SslSdListResponseHubDto) => void, receiveErrorCallBack: (data: SslSdListResponseHubDto) => void, noSessionCallBack: (data: SslSdListResponseHubDto) => void, completeCallBack: (data: SslSdListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SslSdListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SslSdListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SslHub/SslSdList';

        let responseData: SslSdListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SslHub', 'SslSdList', 'SslSdListRequestHubDto', 'SslSdListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SslHub', 'SslSdList', 'SslSdListRequestHubDto', 'SslSdListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SslHub', 'SslSdList', 'SslSdListRequestHubDto', 'SslSdListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public SslDomainList = (requestData: SslDomainListRequestHubDto, receiveCallBack: (data: SslDomainListResponseHubDto) => void, receiveErrorCallBack: (data: SslDomainListResponseHubDto) => void, noSessionCallBack: (data: SslDomainListResponseHubDto) => void, completeCallBack: (data: SslDomainListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SslDomainListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SslDomainListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SslHub/SslDomainList';

        let responseData: SslDomainListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SslHub', 'SslDomainList', 'SslDomainListRequestHubDto', 'SslDomainListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SslHub', 'SslDomainList', 'SslDomainListRequestHubDto', 'SslDomainListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SslHub', 'SslDomainList', 'SslDomainListRequestHubDto', 'SslDomainListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public SslNewInput1Data = (requestData: SslNewInput1DataRequestHubDto, receiveCallBack: (data: SslNewInput1DataResponseHubDto) => void, receiveErrorCallBack: (data: SslNewInput1DataResponseHubDto) => void, noSessionCallBack: (data: SslNewInput1DataResponseHubDto) => void, completeCallBack: (data: SslNewInput1DataResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SslNewInput1DataResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SslNewInput1DataResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SslHub/SslNewInput1Data';

        let responseData: SslNewInput1DataResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SslHub', 'SslNewInput1Data', 'SslNewInput1DataRequestHubDto', 'SslNewInput1DataResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SslHub', 'SslNewInput1Data', 'SslNewInput1DataRequestHubDto', 'SslNewInput1DataResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SslHub', 'SslNewInput1Data', 'SslNewInput1DataRequestHubDto', 'SslNewInput1DataResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public SslNewProductList = (requestData: SslNewInput1DataRequestHubDto, receiveCallBack: (data: SslNewProductListResponseHubDto) => void, receiveErrorCallBack: (data: SslNewProductListResponseHubDto) => void, noSessionCallBack: (data: SslNewProductListResponseHubDto) => void, completeCallBack: (data: SslNewProductListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SslNewProductListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SslNewProductListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SslHub/SslNewProductList';

        let responseData: SslNewProductListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SslHub', 'SslNewProductList', 'SslNewInput1DataRequestHubDto', 'SslNewProductListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SslHub', 'SslNewProductList', 'SslNewInput1DataRequestHubDto', 'SslNewProductListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SslHub', 'SslNewProductList', 'SslNewInput1DataRequestHubDto', 'SslNewProductListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public SslNewGetPrice = (requestData: SslNewInput1DataRequestHubDto, receiveCallBack: (data: SslNewGetPriceResponseHubDto) => void, receiveErrorCallBack: (data: SslNewGetPriceResponseHubDto) => void, noSessionCallBack: (data: SslNewGetPriceResponseHubDto) => void, completeCallBack: (data: SslNewGetPriceResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SslNewGetPriceResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SslNewGetPriceResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SslHub/SslNewGetPrice';

        let responseData: SslNewGetPriceResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SslHub', 'SslNewGetPrice', 'SslNewInput1DataRequestHubDto', 'SslNewGetPriceResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SslHub', 'SslNewGetPrice', 'SslNewInput1DataRequestHubDto', 'SslNewGetPriceResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SslHub', 'SslNewGetPrice', 'SslNewInput1DataRequestHubDto', 'SslNewGetPriceResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public SslNewCheckApi = (requestData: SslNewInput1DataRequestHubDto, receiveCallBack: (data: SslNewCheckApiResponseHubDto) => void, receiveErrorCallBack: (data: SslNewCheckApiResponseHubDto) => void, noSessionCallBack: (data: SslNewCheckApiResponseHubDto) => void, completeCallBack: (data: SslNewCheckApiResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SslNewCheckApiResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SslNewCheckApiResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SslHub/SslNewCheckApi';

        let responseData: SslNewCheckApiResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SslHub', 'SslNewCheckApi', 'SslNewInput1DataRequestHubDto', 'SslNewCheckApiResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SslHub', 'SslNewCheckApi', 'SslNewInput1DataRequestHubDto', 'SslNewCheckApiResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SslHub', 'SslNewCheckApi', 'SslNewInput1DataRequestHubDto', 'SslNewCheckApiResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public SslNewInput2Data = (requestData: SslNewInput1DataRequestHubDto, receiveCallBack: (data: SslNewInput2DataResponseHubDto) => void, receiveErrorCallBack: (data: SslNewInput2DataResponseHubDto) => void, noSessionCallBack: (data: SslNewInput2DataResponseHubDto) => void, completeCallBack: (data: SslNewInput2DataResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SslNewInput2DataResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SslNewInput2DataResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SslHub/SslNewInput2Data';

        let responseData: SslNewInput2DataResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SslHub', 'SslNewInput2Data', 'SslNewInput1DataRequestHubDto', 'SslNewInput2DataResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SslHub', 'SslNewInput2Data', 'SslNewInput1DataRequestHubDto', 'SslNewInput2DataResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SslHub', 'SslNewInput2Data', 'SslNewInput1DataRequestHubDto', 'SslNewInput2DataResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public SslNewConfirmLoading = (requestData: SslNewConfirmLoadingRequestHubDto, receiveCallBack: (data: SslNewConfirmLoadingResponseHubDto) => void, receiveErrorCallBack: (data: SslNewConfirmLoadingResponseHubDto) => void, noSessionCallBack: (data: SslNewConfirmLoadingResponseHubDto) => void, completeCallBack: (data: SslNewConfirmLoadingResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SslNewConfirmLoadingResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SslNewConfirmLoadingResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SslHub/SslNewConfirmLoading';

        let responseData: SslNewConfirmLoadingResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SslHub', 'SslNewConfirmLoading', 'SslNewConfirmLoadingRequestHubDto', 'SslNewConfirmLoadingResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SslHub', 'SslNewConfirmLoading', 'SslNewConfirmLoadingRequestHubDto', 'SslNewConfirmLoadingResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SslHub', 'SslNewConfirmLoading', 'SslNewConfirmLoadingRequestHubDto', 'SslNewConfirmLoadingResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public SslNvSslListByPage = (requestData: SslNvSslListByPageRequestHubDto, receiveCallBack: (data: SslNvSslListByPageResponseHubDto) => void, receiveErrorCallBack: (data: SslNvSslListByPageResponseHubDto) => void, noSessionCallBack: (data: SslNvSslListByPageResponseHubDto) => void, completeCallBack: (data: SslNvSslListByPageResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SslNvSslListByPageResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SslNvSslListByPageResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SslHub/SslNvSslListByPage';

        let responseData: SslNvSslListByPageResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SslHub', 'SslNvSslListByPage', 'SslNvSslListByPageRequestHubDto', 'SslNvSslListByPageResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SslHub', 'SslNvSslListByPage', 'SslNvSslListByPageRequestHubDto', 'SslNvSslListByPageResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SslHub', 'SslNvSslListByPage', 'SslNvSslListByPageRequestHubDto', 'SslNvSslListByPageResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public SslNvSslListByPageWithoutCheckMaintenance = (requestData: SslNvSslListByPageRequestHubDto, receiveCallBack: (data: SslNvSslListByPageResponseHubDto) => void, receiveErrorCallBack: (data: SslNvSslListByPageResponseHubDto) => void, noSessionCallBack: (data: SslNvSslListByPageResponseHubDto) => void, completeCallBack: (data: SslNvSslListByPageResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SslNvSslListByPageResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SslNvSslListByPageResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SslHub/SslNvSslListByPageWithoutCheckMaintenance';

        let responseData: SslNvSslListByPageResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SslHub', 'SslNvSslListByPageWithoutCheckMaintenance', 'SslNvSslListByPageRequestHubDto', 'SslNvSslListByPageResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SslHub', 'SslNvSslListByPageWithoutCheckMaintenance', 'SslNvSslListByPageRequestHubDto', 'SslNvSslListByPageResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SslHub', 'SslNvSslListByPageWithoutCheckMaintenance', 'SslNvSslListByPageRequestHubDto', 'SslNvSslListByPageResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public SslNvConfirm = (requestData: SslNvConfirmRequestHubDto, receiveCallBack: (data: SslNvConfirmResponseHubDto) => void, receiveErrorCallBack: (data: SslNvConfirmResponseHubDto) => void, noSessionCallBack: (data: SslNvConfirmResponseHubDto) => void, completeCallBack: (data: SslNvConfirmResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SslNvConfirmResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SslNvConfirmResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SslHub/SslNvConfirm';

        let responseData: SslNvConfirmResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SslHub', 'SslNvConfirm', 'SslNvConfirmRequestHubDto', 'SslNvConfirmResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SslHub', 'SslNvConfirm', 'SslNvConfirmRequestHubDto', 'SslNvConfirmResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SslHub', 'SslNvConfirm', 'SslNvConfirmRequestHubDto', 'SslNvConfirmResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public SslRenewInput = (requestData: SslRenewGetInputRequestDto, receiveCallBack: (data: SslRenewGetInputResponseHubDto) => void, receiveErrorCallBack: (data: SslRenewGetInputResponseHubDto) => void, noSessionCallBack: (data: SslRenewGetInputResponseHubDto) => void, completeCallBack: (data: SslRenewGetInputResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SslRenewGetInputResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SslRenewGetInputResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SslHub/SslRenewInput';

        let responseData: SslRenewGetInputResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SslHub', 'SslRenewInput', 'SslRenewGetInputRequestDto', 'SslRenewGetInputResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SslHub', 'SslRenewInput', 'SslRenewGetInputRequestDto', 'SslRenewGetInputResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SslHub', 'SslRenewInput', 'SslRenewGetInputRequestDto', 'SslRenewGetInputResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public SslRenewConfirm = (requestData: SslRenewConfirmRequestHubDto, receiveCallBack: (data: SslRenewGetConfirmResponseHubDto) => void, receiveErrorCallBack: (data: SslRenewGetConfirmResponseHubDto) => void, noSessionCallBack: (data: SslRenewGetConfirmResponseHubDto) => void, completeCallBack: (data: SslRenewGetConfirmResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SslRenewGetConfirmResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SslRenewGetConfirmResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SslHub/SslRenewConfirm';

        let responseData: SslRenewGetConfirmResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SslHub', 'SslRenewConfirm', 'SslRenewConfirmRequestHubDto', 'SslRenewGetConfirmResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SslHub', 'SslRenewConfirm', 'SslRenewConfirmRequestHubDto', 'SslRenewGetConfirmResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SslHub', 'SslRenewConfirm', 'SslRenewConfirmRequestHubDto', 'SslRenewGetConfirmResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }

}



export class SdWeeblyHubManager {
    /**
     * コンストラクタ
     */
    constructor(private http: HttpClient) {
    }

	// リソース破棄
    public Dispose = (): void => {
    }



    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public SdWeeblyList = (requestData: SdWeeblyListRequestHubDto, receiveCallBack: (data: SdWeeblyListResponseHubDto) => void, receiveErrorCallBack: (data: SdWeeblyListResponseHubDto) => void, noSessionCallBack: (data: SdWeeblyListResponseHubDto) => void, completeCallBack: (data: SdWeeblyListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdWeeblyListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdWeeblyListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdWeeblyHub/SdWeeblyList';

        let responseData: SdWeeblyListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdWeeblyHub', 'SdWeeblyList', 'SdWeeblyListRequestHubDto', 'SdWeeblyListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdWeeblyHub', 'SdWeeblyList', 'SdWeeblyListRequestHubDto', 'SdWeeblyListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdWeeblyHub', 'SdWeeblyList', 'SdWeeblyListRequestHubDto', 'SdWeeblyListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetSdWeeblyDetail = (requestData: SdWeeblyDetailRequestHubDto, receiveCallBack: (data: SdWeeblyDetailResponseHubDto) => void, receiveErrorCallBack: (data: SdWeeblyDetailResponseHubDto) => void, noSessionCallBack: (data: SdWeeblyDetailResponseHubDto) => void, completeCallBack: (data: SdWeeblyDetailResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdWeeblyDetailResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdWeeblyDetailResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdWeeblyHub/GetSdWeeblyDetail';

        let responseData: SdWeeblyDetailResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdWeeblyHub', 'GetSdWeeblyDetail', 'SdWeeblyDetailRequestHubDto', 'SdWeeblyDetailResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdWeeblyHub', 'GetSdWeeblyDetail', 'SdWeeblyDetailRequestHubDto', 'SdWeeblyDetailResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdWeeblyHub', 'GetSdWeeblyDetail', 'SdWeeblyDetailRequestHubDto', 'SdWeeblyDetailResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetSdWeeblyDetailControlPanel = (requestData: SdWeeblyDetailControlPanelRequestHubDto, receiveCallBack: (data: SdWeeblyDetailControlPanelResponseHubDto) => void, receiveErrorCallBack: (data: SdWeeblyDetailControlPanelResponseHubDto) => void, noSessionCallBack: (data: SdWeeblyDetailControlPanelResponseHubDto) => void, completeCallBack: (data: SdWeeblyDetailControlPanelResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdWeeblyDetailControlPanelResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdWeeblyDetailControlPanelResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdWeeblyHub/GetSdWeeblyDetailControlPanel';

        let responseData: SdWeeblyDetailControlPanelResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdWeeblyHub', 'GetSdWeeblyDetailControlPanel', 'SdWeeblyDetailControlPanelRequestHubDto', 'SdWeeblyDetailControlPanelResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdWeeblyHub', 'GetSdWeeblyDetailControlPanel', 'SdWeeblyDetailControlPanelRequestHubDto', 'SdWeeblyDetailControlPanelResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdWeeblyHub', 'GetSdWeeblyDetailControlPanel', 'SdWeeblyDetailControlPanelRequestHubDto', 'SdWeeblyDetailControlPanelResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetSdWeeblyDetailOption = (requestData: SdWeeblyDetailOptionRequestHubDto, receiveCallBack: (data: SdWeeblyDetailOptionResponseHubDto) => void, receiveErrorCallBack: (data: SdWeeblyDetailOptionResponseHubDto) => void, noSessionCallBack: (data: SdWeeblyDetailOptionResponseHubDto) => void, completeCallBack: (data: SdWeeblyDetailOptionResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdWeeblyDetailOptionResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdWeeblyDetailOptionResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdWeeblyHub/GetSdWeeblyDetailOption';

        let responseData: SdWeeblyDetailOptionResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdWeeblyHub', 'GetSdWeeblyDetailOption', 'SdWeeblyDetailOptionRequestHubDto', 'SdWeeblyDetailOptionResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdWeeblyHub', 'GetSdWeeblyDetailOption', 'SdWeeblyDetailOptionRequestHubDto', 'SdWeeblyDetailOptionResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdWeeblyHub', 'GetSdWeeblyDetailOption', 'SdWeeblyDetailOptionRequestHubDto', 'SdWeeblyDetailOptionResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public RevokeSdWeeblyDetailCancelPlan = (requestData: SdWeeblyDetailCancelPlanRevokeRequestHubDto, receiveCallBack: (data: SdWeeblyDetailCancelPlanRevokeResponseHubDto) => void, receiveErrorCallBack: (data: SdWeeblyDetailCancelPlanRevokeResponseHubDto) => void, noSessionCallBack: (data: SdWeeblyDetailCancelPlanRevokeResponseHubDto) => void, completeCallBack: (data: SdWeeblyDetailCancelPlanRevokeResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdWeeblyDetailCancelPlanRevokeResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdWeeblyDetailCancelPlanRevokeResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdWeeblyHub/RevokeSdWeeblyDetailCancelPlan';

        let responseData: SdWeeblyDetailCancelPlanRevokeResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdWeeblyHub', 'RevokeSdWeeblyDetailCancelPlan', 'SdWeeblyDetailCancelPlanRevokeRequestHubDto', 'SdWeeblyDetailCancelPlanRevokeResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdWeeblyHub', 'RevokeSdWeeblyDetailCancelPlan', 'SdWeeblyDetailCancelPlanRevokeRequestHubDto', 'SdWeeblyDetailCancelPlanRevokeResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdWeeblyHub', 'RevokeSdWeeblyDetailCancelPlan', 'SdWeeblyDetailCancelPlanRevokeRequestHubDto', 'SdWeeblyDetailCancelPlanRevokeResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetSdWeeblyDetailCancelOptionDetail = (requestData: SdWeeblyDetailCancelOptionDetailRequestHubDto, receiveCallBack: (data: SdWeeblyDetailCancelOptionDetailResponseHubDto) => void, receiveErrorCallBack: (data: SdWeeblyDetailCancelOptionDetailResponseHubDto) => void, noSessionCallBack: (data: SdWeeblyDetailCancelOptionDetailResponseHubDto) => void, completeCallBack: (data: SdWeeblyDetailCancelOptionDetailResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdWeeblyDetailCancelOptionDetailResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdWeeblyDetailCancelOptionDetailResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdWeeblyHub/GetSdWeeblyDetailCancelOptionDetail';

        let responseData: SdWeeblyDetailCancelOptionDetailResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdWeeblyHub', 'GetSdWeeblyDetailCancelOptionDetail', 'SdWeeblyDetailCancelOptionDetailRequestHubDto', 'SdWeeblyDetailCancelOptionDetailResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdWeeblyHub', 'GetSdWeeblyDetailCancelOptionDetail', 'SdWeeblyDetailCancelOptionDetailRequestHubDto', 'SdWeeblyDetailCancelOptionDetailResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdWeeblyHub', 'GetSdWeeblyDetailCancelOptionDetail', 'SdWeeblyDetailCancelOptionDetailRequestHubDto', 'SdWeeblyDetailCancelOptionDetailResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public RevokeSdWeeblyDetailCancelOption = (requestData: SdWeeblyDetailCancelOptionRevokeRequestHubDto, receiveCallBack: (data: SdWeeblyDetailCancelOptionRevokeResponseHubDto) => void, receiveErrorCallBack: (data: SdWeeblyDetailCancelOptionRevokeResponseHubDto) => void, noSessionCallBack: (data: SdWeeblyDetailCancelOptionRevokeResponseHubDto) => void, completeCallBack: (data: SdWeeblyDetailCancelOptionRevokeResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdWeeblyDetailCancelOptionRevokeResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdWeeblyDetailCancelOptionRevokeResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdWeeblyHub/RevokeSdWeeblyDetailCancelOption';

        let responseData: SdWeeblyDetailCancelOptionRevokeResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdWeeblyHub', 'RevokeSdWeeblyDetailCancelOption', 'SdWeeblyDetailCancelOptionRevokeRequestHubDto', 'SdWeeblyDetailCancelOptionRevokeResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdWeeblyHub', 'RevokeSdWeeblyDetailCancelOption', 'SdWeeblyDetailCancelOptionRevokeRequestHubDto', 'SdWeeblyDetailCancelOptionRevokeResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdWeeblyHub', 'RevokeSdWeeblyDetailCancelOption', 'SdWeeblyDetailCancelOptionRevokeRequestHubDto', 'SdWeeblyDetailCancelOptionRevokeResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ChangeSdWeeblyDetailSubDomain = (requestData: SdWeeblyDetailSubDomainChangeRequestHubDto, receiveCallBack: (data: SdWeeblyDetailSubDomainChangeResponseHubDto) => void, receiveErrorCallBack: (data: SdWeeblyDetailSubDomainChangeResponseHubDto) => void, noSessionCallBack: (data: SdWeeblyDetailSubDomainChangeResponseHubDto) => void, completeCallBack: (data: SdWeeblyDetailSubDomainChangeResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdWeeblyDetailSubDomainChangeResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdWeeblyDetailSubDomainChangeResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdWeeblyHub/ChangeSdWeeblyDetailSubDomain';

        let responseData: SdWeeblyDetailSubDomainChangeResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdWeeblyHub', 'ChangeSdWeeblyDetailSubDomain', 'SdWeeblyDetailSubDomainChangeRequestHubDto', 'SdWeeblyDetailSubDomainChangeResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdWeeblyHub', 'ChangeSdWeeblyDetailSubDomain', 'SdWeeblyDetailSubDomainChangeRequestHubDto', 'SdWeeblyDetailSubDomainChangeResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdWeeblyHub', 'ChangeSdWeeblyDetailSubDomain', 'SdWeeblyDetailSubDomainChangeRequestHubDto', 'SdWeeblyDetailSubDomainChangeResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetSdWeeblyDetailDomain = (requestData: SdWeeblyDetailDomainRequestHubDto, receiveCallBack: (data: SdWeeblyDetailDomainResponseHubDto) => void, receiveErrorCallBack: (data: SdWeeblyDetailDomainResponseHubDto) => void, noSessionCallBack: (data: SdWeeblyDetailDomainResponseHubDto) => void, completeCallBack: (data: SdWeeblyDetailDomainResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdWeeblyDetailDomainResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdWeeblyDetailDomainResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdWeeblyHub/GetSdWeeblyDetailDomain';

        let responseData: SdWeeblyDetailDomainResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdWeeblyHub', 'GetSdWeeblyDetailDomain', 'SdWeeblyDetailDomainRequestHubDto', 'SdWeeblyDetailDomainResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdWeeblyHub', 'GetSdWeeblyDetailDomain', 'SdWeeblyDetailDomainRequestHubDto', 'SdWeeblyDetailDomainResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdWeeblyHub', 'GetSdWeeblyDetailDomain', 'SdWeeblyDetailDomainRequestHubDto', 'SdWeeblyDetailDomainResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public CancelSdWeeblyDetailDomain = (requestData: SdWeeblyDetailDomainCancelRequestHubDto, receiveCallBack: (data: SdWeeblyDetailDomainCancelResponseHubDto) => void, receiveErrorCallBack: (data: SdWeeblyDetailDomainCancelResponseHubDto) => void, noSessionCallBack: (data: SdWeeblyDetailDomainCancelResponseHubDto) => void, completeCallBack: (data: SdWeeblyDetailDomainCancelResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdWeeblyDetailDomainCancelResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdWeeblyDetailDomainCancelResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdWeeblyHub/CancelSdWeeblyDetailDomain';

        let responseData: SdWeeblyDetailDomainCancelResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdWeeblyHub', 'CancelSdWeeblyDetailDomain', 'SdWeeblyDetailDomainCancelRequestHubDto', 'SdWeeblyDetailDomainCancelResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdWeeblyHub', 'CancelSdWeeblyDetailDomain', 'SdWeeblyDetailDomainCancelRequestHubDto', 'SdWeeblyDetailDomainCancelResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdWeeblyHub', 'CancelSdWeeblyDetailDomain', 'SdWeeblyDetailDomainCancelRequestHubDto', 'SdWeeblyDetailDomainCancelResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ValidateSdWeeblyDetailChangePlan = (requestData: SdWeeblyDetailValidateChangePlanRequestHubDto, receiveCallBack: (data: SdWeeblyDetailValidateChangePlanResponseHubDto) => void, receiveErrorCallBack: (data: SdWeeblyDetailValidateChangePlanResponseHubDto) => void, noSessionCallBack: (data: SdWeeblyDetailValidateChangePlanResponseHubDto) => void, completeCallBack: (data: SdWeeblyDetailValidateChangePlanResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdWeeblyDetailValidateChangePlanResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdWeeblyDetailValidateChangePlanResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdWeeblyHub/ValidateSdWeeblyDetailChangePlan';

        let responseData: SdWeeblyDetailValidateChangePlanResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdWeeblyHub', 'ValidateSdWeeblyDetailChangePlan', 'SdWeeblyDetailValidateChangePlanRequestHubDto', 'SdWeeblyDetailValidateChangePlanResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdWeeblyHub', 'ValidateSdWeeblyDetailChangePlan', 'SdWeeblyDetailValidateChangePlanRequestHubDto', 'SdWeeblyDetailValidateChangePlanResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdWeeblyHub', 'ValidateSdWeeblyDetailChangePlan', 'SdWeeblyDetailValidateChangePlanRequestHubDto', 'SdWeeblyDetailValidateChangePlanResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ValidateSdWeeblyDetailCancelPlan = (requestData: SdWeeblyDetailValidateCancelPlanRequestHubDto, receiveCallBack: (data: SdWeeblyDetailValidateCancelPlanResponseHubDto) => void, receiveErrorCallBack: (data: SdWeeblyDetailValidateCancelPlanResponseHubDto) => void, noSessionCallBack: (data: SdWeeblyDetailValidateCancelPlanResponseHubDto) => void, completeCallBack: (data: SdWeeblyDetailValidateCancelPlanResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdWeeblyDetailValidateCancelPlanResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdWeeblyDetailValidateCancelPlanResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdWeeblyHub/ValidateSdWeeblyDetailCancelPlan';

        let responseData: SdWeeblyDetailValidateCancelPlanResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdWeeblyHub', 'ValidateSdWeeblyDetailCancelPlan', 'SdWeeblyDetailValidateCancelPlanRequestHubDto', 'SdWeeblyDetailValidateCancelPlanResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdWeeblyHub', 'ValidateSdWeeblyDetailCancelPlan', 'SdWeeblyDetailValidateCancelPlanRequestHubDto', 'SdWeeblyDetailValidateCancelPlanResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdWeeblyHub', 'ValidateSdWeeblyDetailCancelPlan', 'SdWeeblyDetailValidateCancelPlanRequestHubDto', 'SdWeeblyDetailValidateCancelPlanResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ValidateSdWeeblyDetailRevokeCancelPlan = (requestData: SdWeeblyDetailValidateRevokeCancelPlanRequestHubDto, receiveCallBack: (data: SdWeeblyDetailValidateRevokeCancelPlanResponseHubDto) => void, receiveErrorCallBack: (data: SdWeeblyDetailValidateRevokeCancelPlanResponseHubDto) => void, noSessionCallBack: (data: SdWeeblyDetailValidateRevokeCancelPlanResponseHubDto) => void, completeCallBack: (data: SdWeeblyDetailValidateRevokeCancelPlanResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdWeeblyDetailValidateRevokeCancelPlanResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdWeeblyDetailValidateRevokeCancelPlanResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdWeeblyHub/ValidateSdWeeblyDetailRevokeCancelPlan';

        let responseData: SdWeeblyDetailValidateRevokeCancelPlanResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdWeeblyHub', 'ValidateSdWeeblyDetailRevokeCancelPlan', 'SdWeeblyDetailValidateRevokeCancelPlanRequestHubDto', 'SdWeeblyDetailValidateRevokeCancelPlanResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdWeeblyHub', 'ValidateSdWeeblyDetailRevokeCancelPlan', 'SdWeeblyDetailValidateRevokeCancelPlanRequestHubDto', 'SdWeeblyDetailValidateRevokeCancelPlanResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdWeeblyHub', 'ValidateSdWeeblyDetailRevokeCancelPlan', 'SdWeeblyDetailValidateRevokeCancelPlanRequestHubDto', 'SdWeeblyDetailValidateRevokeCancelPlanResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ValidateSdWeeblyDetailAddOption = (requestData: SdWeeblyDetailValidateAddOptionRequestHubDto, receiveCallBack: (data: SdWeeblyDetailValidateAddOptionResponseHubDto) => void, receiveErrorCallBack: (data: SdWeeblyDetailValidateAddOptionResponseHubDto) => void, noSessionCallBack: (data: SdWeeblyDetailValidateAddOptionResponseHubDto) => void, completeCallBack: (data: SdWeeblyDetailValidateAddOptionResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdWeeblyDetailValidateAddOptionResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdWeeblyDetailValidateAddOptionResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdWeeblyHub/ValidateSdWeeblyDetailAddOption';

        let responseData: SdWeeblyDetailValidateAddOptionResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdWeeblyHub', 'ValidateSdWeeblyDetailAddOption', 'SdWeeblyDetailValidateAddOptionRequestHubDto', 'SdWeeblyDetailValidateAddOptionResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdWeeblyHub', 'ValidateSdWeeblyDetailAddOption', 'SdWeeblyDetailValidateAddOptionRequestHubDto', 'SdWeeblyDetailValidateAddOptionResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdWeeblyHub', 'ValidateSdWeeblyDetailAddOption', 'SdWeeblyDetailValidateAddOptionRequestHubDto', 'SdWeeblyDetailValidateAddOptionResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ValidateSdWeeblyDetailChangeOption = (requestData: SdWeeblyDetailValidateChangeOptionRequestHubDto, receiveCallBack: (data: SdWeeblyDetailValidateChangeOptionResponseHubDto) => void, receiveErrorCallBack: (data: SdWeeblyDetailValidateChangeOptionResponseHubDto) => void, noSessionCallBack: (data: SdWeeblyDetailValidateChangeOptionResponseHubDto) => void, completeCallBack: (data: SdWeeblyDetailValidateChangeOptionResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdWeeblyDetailValidateChangeOptionResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdWeeblyDetailValidateChangeOptionResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdWeeblyHub/ValidateSdWeeblyDetailChangeOption';

        let responseData: SdWeeblyDetailValidateChangeOptionResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdWeeblyHub', 'ValidateSdWeeblyDetailChangeOption', 'SdWeeblyDetailValidateChangeOptionRequestHubDto', 'SdWeeblyDetailValidateChangeOptionResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdWeeblyHub', 'ValidateSdWeeblyDetailChangeOption', 'SdWeeblyDetailValidateChangeOptionRequestHubDto', 'SdWeeblyDetailValidateChangeOptionResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdWeeblyHub', 'ValidateSdWeeblyDetailChangeOption', 'SdWeeblyDetailValidateChangeOptionRequestHubDto', 'SdWeeblyDetailValidateChangeOptionResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ValidateSdWeeblyDetailCancelOption = (requestData: SdWeeblyDetailValidateCancelOptionRequestHubDto, receiveCallBack: (data: SdWeeblyDetailValidateCancelOptionResponseHubDto) => void, receiveErrorCallBack: (data: SdWeeblyDetailValidateCancelOptionResponseHubDto) => void, noSessionCallBack: (data: SdWeeblyDetailValidateCancelOptionResponseHubDto) => void, completeCallBack: (data: SdWeeblyDetailValidateCancelOptionResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdWeeblyDetailValidateCancelOptionResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdWeeblyDetailValidateCancelOptionResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdWeeblyHub/ValidateSdWeeblyDetailCancelOption';

        let responseData: SdWeeblyDetailValidateCancelOptionResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdWeeblyHub', 'ValidateSdWeeblyDetailCancelOption', 'SdWeeblyDetailValidateCancelOptionRequestHubDto', 'SdWeeblyDetailValidateCancelOptionResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdWeeblyHub', 'ValidateSdWeeblyDetailCancelOption', 'SdWeeblyDetailValidateCancelOptionRequestHubDto', 'SdWeeblyDetailValidateCancelOptionResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdWeeblyHub', 'ValidateSdWeeblyDetailCancelOption', 'SdWeeblyDetailValidateCancelOptionRequestHubDto', 'SdWeeblyDetailValidateCancelOptionResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ValidateSdWeeblyDetailRevokeCancelOption = (requestData: SdWeeblyDetailValidateRevokeCancelOptionRequestHubDto, receiveCallBack: (data: SdWeeblyDetailValidateRevokeCancelOptionResponseHubDto) => void, receiveErrorCallBack: (data: SdWeeblyDetailValidateRevokeCancelOptionResponseHubDto) => void, noSessionCallBack: (data: SdWeeblyDetailValidateRevokeCancelOptionResponseHubDto) => void, completeCallBack: (data: SdWeeblyDetailValidateRevokeCancelOptionResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdWeeblyDetailValidateRevokeCancelOptionResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdWeeblyDetailValidateRevokeCancelOptionResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdWeeblyHub/ValidateSdWeeblyDetailRevokeCancelOption';

        let responseData: SdWeeblyDetailValidateRevokeCancelOptionResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdWeeblyHub', 'ValidateSdWeeblyDetailRevokeCancelOption', 'SdWeeblyDetailValidateRevokeCancelOptionRequestHubDto', 'SdWeeblyDetailValidateRevokeCancelOptionResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdWeeblyHub', 'ValidateSdWeeblyDetailRevokeCancelOption', 'SdWeeblyDetailValidateRevokeCancelOptionRequestHubDto', 'SdWeeblyDetailValidateRevokeCancelOptionResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdWeeblyHub', 'ValidateSdWeeblyDetailRevokeCancelOption', 'SdWeeblyDetailValidateRevokeCancelOptionRequestHubDto', 'SdWeeblyDetailValidateRevokeCancelOptionResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ValidateSdWeeblyDetailCancelDomain = (requestData: SdWeeblyDetailValidateCancelDomainRequestHubDto, receiveCallBack: (data: SdWeeblyDetailValidateCancelDomainResponseHubDto) => void, receiveErrorCallBack: (data: SdWeeblyDetailValidateCancelDomainResponseHubDto) => void, noSessionCallBack: (data: SdWeeblyDetailValidateCancelDomainResponseHubDto) => void, completeCallBack: (data: SdWeeblyDetailValidateCancelDomainResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdWeeblyDetailValidateCancelDomainResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdWeeblyDetailValidateCancelDomainResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdWeeblyHub/ValidateSdWeeblyDetailCancelDomain';

        let responseData: SdWeeblyDetailValidateCancelDomainResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdWeeblyHub', 'ValidateSdWeeblyDetailCancelDomain', 'SdWeeblyDetailValidateCancelDomainRequestHubDto', 'SdWeeblyDetailValidateCancelDomainResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdWeeblyHub', 'ValidateSdWeeblyDetailCancelDomain', 'SdWeeblyDetailValidateCancelDomainRequestHubDto', 'SdWeeblyDetailValidateCancelDomainResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdWeeblyHub', 'ValidateSdWeeblyDetailCancelDomain', 'SdWeeblyDetailValidateCancelDomainRequestHubDto', 'SdWeeblyDetailValidateCancelDomainResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetSdWeeblyPlan = (requestData: SdWeeblyPlanRequestHubDto, receiveCallBack: (data: SdWeeblyPlanResponseHubDto) => void, receiveErrorCallBack: (data: SdWeeblyPlanResponseHubDto) => void, noSessionCallBack: (data: SdWeeblyPlanResponseHubDto) => void, completeCallBack: (data: SdWeeblyPlanResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdWeeblyPlanResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdWeeblyPlanResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdWeeblyHub/GetSdWeeblyPlan';

        let responseData: SdWeeblyPlanResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdWeeblyHub', 'GetSdWeeblyPlan', 'SdWeeblyPlanRequestHubDto', 'SdWeeblyPlanResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdWeeblyHub', 'GetSdWeeblyPlan', 'SdWeeblyPlanRequestHubDto', 'SdWeeblyPlanResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdWeeblyHub', 'GetSdWeeblyPlan', 'SdWeeblyPlanRequestHubDto', 'SdWeeblyPlanResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetSdWeeblyPlanChangedDetail = (requestData: SdWeeblyPlanChangedDetailRequestHubDto, receiveCallBack: (data: SdWeeblyPlanChangedDetailResponseHubDto) => void, receiveErrorCallBack: (data: SdWeeblyPlanChangedDetailResponseHubDto) => void, noSessionCallBack: (data: SdWeeblyPlanChangedDetailResponseHubDto) => void, completeCallBack: (data: SdWeeblyPlanChangedDetailResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdWeeblyPlanChangedDetailResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdWeeblyPlanChangedDetailResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdWeeblyHub/GetSdWeeblyPlanChangedDetail';

        let responseData: SdWeeblyPlanChangedDetailResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdWeeblyHub', 'GetSdWeeblyPlanChangedDetail', 'SdWeeblyPlanChangedDetailRequestHubDto', 'SdWeeblyPlanChangedDetailResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdWeeblyHub', 'GetSdWeeblyPlanChangedDetail', 'SdWeeblyPlanChangedDetailRequestHubDto', 'SdWeeblyPlanChangedDetailResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdWeeblyHub', 'GetSdWeeblyPlanChangedDetail', 'SdWeeblyPlanChangedDetailRequestHubDto', 'SdWeeblyPlanChangedDetailResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ChangeSdWeeblyPlan = (requestData: SdWeeblyPlanChangeRequestHubDto, receiveCallBack: (data: SdWeeblyPlanChangeResponseHubDto) => void, receiveErrorCallBack: (data: SdWeeblyPlanChangeResponseHubDto) => void, noSessionCallBack: (data: SdWeeblyPlanChangeResponseHubDto) => void, completeCallBack: (data: SdWeeblyPlanChangeResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdWeeblyPlanChangeResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdWeeblyPlanChangeResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdWeeblyHub/ChangeSdWeeblyPlan';

        let responseData: SdWeeblyPlanChangeResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdWeeblyHub', 'ChangeSdWeeblyPlan', 'SdWeeblyPlanChangeRequestHubDto', 'SdWeeblyPlanChangeResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdWeeblyHub', 'ChangeSdWeeblyPlan', 'SdWeeblyPlanChangeRequestHubDto', 'SdWeeblyPlanChangeResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdWeeblyHub', 'ChangeSdWeeblyPlan', 'SdWeeblyPlanChangeRequestHubDto', 'SdWeeblyPlanChangeResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetSdWeeblyCancelPlanData = (requestData: SdWeeblyPlanCancelDataRequestHubDto, receiveCallBack: (data: SdWeeblyPlanCancelDataResponseHubDto) => void, receiveErrorCallBack: (data: SdWeeblyPlanCancelDataResponseHubDto) => void, noSessionCallBack: (data: SdWeeblyPlanCancelDataResponseHubDto) => void, completeCallBack: (data: SdWeeblyPlanCancelDataResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdWeeblyPlanCancelDataResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdWeeblyPlanCancelDataResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdWeeblyHub/GetSdWeeblyCancelPlanData';

        let responseData: SdWeeblyPlanCancelDataResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdWeeblyHub', 'GetSdWeeblyCancelPlanData', 'SdWeeblyPlanCancelDataRequestHubDto', 'SdWeeblyPlanCancelDataResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdWeeblyHub', 'GetSdWeeblyCancelPlanData', 'SdWeeblyPlanCancelDataRequestHubDto', 'SdWeeblyPlanCancelDataResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdWeeblyHub', 'GetSdWeeblyCancelPlanData', 'SdWeeblyPlanCancelDataRequestHubDto', 'SdWeeblyPlanCancelDataResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public CancelSdWeeblyPlan = (requestData: SdWeeblyPlanCancelRequestHubDto, receiveCallBack: (data: SdWeeblyPlanCancelResponseHubDto) => void, receiveErrorCallBack: (data: SdWeeblyPlanCancelResponseHubDto) => void, noSessionCallBack: (data: SdWeeblyPlanCancelResponseHubDto) => void, completeCallBack: (data: SdWeeblyPlanCancelResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdWeeblyPlanCancelResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdWeeblyPlanCancelResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdWeeblyHub/CancelSdWeeblyPlan';

        let responseData: SdWeeblyPlanCancelResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdWeeblyHub', 'CancelSdWeeblyPlan', 'SdWeeblyPlanCancelRequestHubDto', 'SdWeeblyPlanCancelResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdWeeblyHub', 'CancelSdWeeblyPlan', 'SdWeeblyPlanCancelRequestHubDto', 'SdWeeblyPlanCancelResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdWeeblyHub', 'CancelSdWeeblyPlan', 'SdWeeblyPlanCancelRequestHubDto', 'SdWeeblyPlanCancelResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetSdWeeblyAddOptions = (requestData: SdWeeblyOptionAddGetRequestHubDto, receiveCallBack: (data: SdWeeblyOptionAddSelectListResponseHubDto) => void, receiveErrorCallBack: (data: SdWeeblyOptionAddSelectListResponseHubDto) => void, noSessionCallBack: (data: SdWeeblyOptionAddSelectListResponseHubDto) => void, completeCallBack: (data: SdWeeblyOptionAddSelectListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdWeeblyOptionAddSelectListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdWeeblyOptionAddSelectListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdWeeblyHub/GetSdWeeblyAddOptions';

        let responseData: SdWeeblyOptionAddSelectListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdWeeblyHub', 'GetSdWeeblyAddOptions', 'SdWeeblyOptionAddGetRequestHubDto', 'SdWeeblyOptionAddSelectListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdWeeblyHub', 'GetSdWeeblyAddOptions', 'SdWeeblyOptionAddGetRequestHubDto', 'SdWeeblyOptionAddSelectListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdWeeblyHub', 'GetSdWeeblyAddOptions', 'SdWeeblyOptionAddGetRequestHubDto', 'SdWeeblyOptionAddSelectListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetSdWeeblyAddOptionSSLDomains = (requestData: SdWeeblyOptionAddSslDomainsGetRequestHubDto, receiveCallBack: (data: SdWeeblyOptionAddSslDomainsGetResponseHubDto) => void, receiveErrorCallBack: (data: SdWeeblyOptionAddSslDomainsGetResponseHubDto) => void, noSessionCallBack: (data: SdWeeblyOptionAddSslDomainsGetResponseHubDto) => void, completeCallBack: (data: SdWeeblyOptionAddSslDomainsGetResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdWeeblyOptionAddSslDomainsGetResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdWeeblyOptionAddSslDomainsGetResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdWeeblyHub/GetSdWeeblyAddOptionSSLDomains';

        let responseData: SdWeeblyOptionAddSslDomainsGetResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdWeeblyHub', 'GetSdWeeblyAddOptionSSLDomains', 'SdWeeblyOptionAddSslDomainsGetRequestHubDto', 'SdWeeblyOptionAddSslDomainsGetResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdWeeblyHub', 'GetSdWeeblyAddOptionSSLDomains', 'SdWeeblyOptionAddSslDomainsGetRequestHubDto', 'SdWeeblyOptionAddSslDomainsGetResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdWeeblyHub', 'GetSdWeeblyAddOptionSSLDomains', 'SdWeeblyOptionAddSslDomainsGetRequestHubDto', 'SdWeeblyOptionAddSslDomainsGetResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetSdWeeblyAddOptionSiteLockDomains = (requestData: SdWeeblyOptionAddSiteLockDomainsGetRequestHubDto, receiveCallBack: (data: SdWeeblyOptionAddSiteLockDomainsGetResponseHubDto) => void, receiveErrorCallBack: (data: SdWeeblyOptionAddSiteLockDomainsGetResponseHubDto) => void, noSessionCallBack: (data: SdWeeblyOptionAddSiteLockDomainsGetResponseHubDto) => void, completeCallBack: (data: SdWeeblyOptionAddSiteLockDomainsGetResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdWeeblyOptionAddSiteLockDomainsGetResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdWeeblyOptionAddSiteLockDomainsGetResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdWeeblyHub/GetSdWeeblyAddOptionSiteLockDomains';

        let responseData: SdWeeblyOptionAddSiteLockDomainsGetResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdWeeblyHub', 'GetSdWeeblyAddOptionSiteLockDomains', 'SdWeeblyOptionAddSiteLockDomainsGetRequestHubDto', 'SdWeeblyOptionAddSiteLockDomainsGetResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdWeeblyHub', 'GetSdWeeblyAddOptionSiteLockDomains', 'SdWeeblyOptionAddSiteLockDomainsGetRequestHubDto', 'SdWeeblyOptionAddSiteLockDomainsGetResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdWeeblyHub', 'GetSdWeeblyAddOptionSiteLockDomains', 'SdWeeblyOptionAddSiteLockDomainsGetRequestHubDto', 'SdWeeblyOptionAddSiteLockDomainsGetResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetSdWeeblyAddOptionWeeblyDomains = (requestData: SdWeeblyOptionAddWeeblyDomainsGetRequestHubDto, receiveCallBack: (data: SdWeeblyOptionAddWeeblyDomainsGetResponseHubDto) => void, receiveErrorCallBack: (data: SdWeeblyOptionAddWeeblyDomainsGetResponseHubDto) => void, noSessionCallBack: (data: SdWeeblyOptionAddWeeblyDomainsGetResponseHubDto) => void, completeCallBack: (data: SdWeeblyOptionAddWeeblyDomainsGetResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdWeeblyOptionAddWeeblyDomainsGetResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdWeeblyOptionAddWeeblyDomainsGetResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdWeeblyHub/GetSdWeeblyAddOptionWeeblyDomains';

        let responseData: SdWeeblyOptionAddWeeblyDomainsGetResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdWeeblyHub', 'GetSdWeeblyAddOptionWeeblyDomains', 'SdWeeblyOptionAddWeeblyDomainsGetRequestHubDto', 'SdWeeblyOptionAddWeeblyDomainsGetResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdWeeblyHub', 'GetSdWeeblyAddOptionWeeblyDomains', 'SdWeeblyOptionAddWeeblyDomainsGetRequestHubDto', 'SdWeeblyOptionAddWeeblyDomainsGetResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdWeeblyHub', 'GetSdWeeblyAddOptionWeeblyDomains', 'SdWeeblyOptionAddWeeblyDomainsGetRequestHubDto', 'SdWeeblyOptionAddWeeblyDomainsGetResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetSdWeeblyAddOptionSslInfoValidation = (requestData: SdWeeblyOptionAddSslInfoValidationRequestHubDto, receiveCallBack: (data: SdWeeblyOptionAddSslInfoValidationResponseHubDto) => void, receiveErrorCallBack: (data: SdWeeblyOptionAddSslInfoValidationResponseHubDto) => void, noSessionCallBack: (data: SdWeeblyOptionAddSslInfoValidationResponseHubDto) => void, completeCallBack: (data: SdWeeblyOptionAddSslInfoValidationResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdWeeblyOptionAddSslInfoValidationResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdWeeblyOptionAddSslInfoValidationResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdWeeblyHub/GetSdWeeblyAddOptionSslInfoValidation';

        let responseData: SdWeeblyOptionAddSslInfoValidationResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdWeeblyHub', 'GetSdWeeblyAddOptionSslInfoValidation', 'SdWeeblyOptionAddSslInfoValidationRequestHubDto', 'SdWeeblyOptionAddSslInfoValidationResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdWeeblyHub', 'GetSdWeeblyAddOptionSslInfoValidation', 'SdWeeblyOptionAddSslInfoValidationRequestHubDto', 'SdWeeblyOptionAddSslInfoValidationResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdWeeblyHub', 'GetSdWeeblyAddOptionSslInfoValidation', 'SdWeeblyOptionAddSslInfoValidationRequestHubDto', 'SdWeeblyOptionAddSslInfoValidationResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetSdWeeblyAddOptionSiteLockInfoValidation = (requestData: SdWeeblyOptionAddSiteLockInfoValidationRequestHubDto, receiveCallBack: (data: SdWeeblyOptionAddSiteLockInfoValidationResponseHubDto) => void, receiveErrorCallBack: (data: SdWeeblyOptionAddSiteLockInfoValidationResponseHubDto) => void, noSessionCallBack: (data: SdWeeblyOptionAddSiteLockInfoValidationResponseHubDto) => void, completeCallBack: (data: SdWeeblyOptionAddSiteLockInfoValidationResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdWeeblyOptionAddSiteLockInfoValidationResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdWeeblyOptionAddSiteLockInfoValidationResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdWeeblyHub/GetSdWeeblyAddOptionSiteLockInfoValidation';

        let responseData: SdWeeblyOptionAddSiteLockInfoValidationResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdWeeblyHub', 'GetSdWeeblyAddOptionSiteLockInfoValidation', 'SdWeeblyOptionAddSiteLockInfoValidationRequestHubDto', 'SdWeeblyOptionAddSiteLockInfoValidationResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdWeeblyHub', 'GetSdWeeblyAddOptionSiteLockInfoValidation', 'SdWeeblyOptionAddSiteLockInfoValidationRequestHubDto', 'SdWeeblyOptionAddSiteLockInfoValidationResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdWeeblyHub', 'GetSdWeeblyAddOptionSiteLockInfoValidation', 'SdWeeblyOptionAddSiteLockInfoValidationRequestHubDto', 'SdWeeblyOptionAddSiteLockInfoValidationResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetSdWeeblyAlphaSslCsrInfo = (requestData: HostingItemRequestHubDto, receiveCallBack: (data: HostingOptionAlphaSslCsrResponseHubDto) => void, receiveErrorCallBack: (data: HostingOptionAlphaSslCsrResponseHubDto) => void, noSessionCallBack: (data: HostingOptionAlphaSslCsrResponseHubDto) => void, completeCallBack: (data: HostingOptionAlphaSslCsrResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: HostingOptionAlphaSslCsrResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: HostingOptionAlphaSslCsrResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdWeeblyHub/GetSdWeeblyAlphaSslCsrInfo';

        let responseData: HostingOptionAlphaSslCsrResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdWeeblyHub', 'GetSdWeeblyAlphaSslCsrInfo', 'HostingItemRequestHubDto', 'HostingOptionAlphaSslCsrResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdWeeblyHub', 'GetSdWeeblyAlphaSslCsrInfo', 'HostingItemRequestHubDto', 'HostingOptionAlphaSslCsrResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdWeeblyHub', 'GetSdWeeblyAlphaSslCsrInfo', 'HostingItemRequestHubDto', 'HostingOptionAlphaSslCsrResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetSdWeeblyAddOptionDetail = (requestData: SdWeeblyOptionAddDetailGetRequestHubDto, receiveCallBack: (data: SdWeeblyOptionAddDetailGetResponseHubDto) => void, receiveErrorCallBack: (data: SdWeeblyOptionAddDetailGetResponseHubDto) => void, noSessionCallBack: (data: SdWeeblyOptionAddDetailGetResponseHubDto) => void, completeCallBack: (data: SdWeeblyOptionAddDetailGetResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdWeeblyOptionAddDetailGetResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdWeeblyOptionAddDetailGetResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdWeeblyHub/GetSdWeeblyAddOptionDetail';

        let responseData: SdWeeblyOptionAddDetailGetResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdWeeblyHub', 'GetSdWeeblyAddOptionDetail', 'SdWeeblyOptionAddDetailGetRequestHubDto', 'SdWeeblyOptionAddDetailGetResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdWeeblyHub', 'GetSdWeeblyAddOptionDetail', 'SdWeeblyOptionAddDetailGetRequestHubDto', 'SdWeeblyOptionAddDetailGetResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdWeeblyHub', 'GetSdWeeblyAddOptionDetail', 'SdWeeblyOptionAddDetailGetRequestHubDto', 'SdWeeblyOptionAddDetailGetResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public AddSdWeeblyAddOptions = (requestData: SdWeeblyOptionAddRequestHubDto, receiveCallBack: (data: SdWeeblyOptionAddResponseHubDto) => void, receiveErrorCallBack: (data: SdWeeblyOptionAddResponseHubDto) => void, noSessionCallBack: (data: SdWeeblyOptionAddResponseHubDto) => void, completeCallBack: (data: SdWeeblyOptionAddResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdWeeblyOptionAddResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdWeeblyOptionAddResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdWeeblyHub/AddSdWeeblyAddOptions';

        let responseData: SdWeeblyOptionAddResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdWeeblyHub', 'AddSdWeeblyAddOptions', 'SdWeeblyOptionAddRequestHubDto', 'SdWeeblyOptionAddResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdWeeblyHub', 'AddSdWeeblyAddOptions', 'SdWeeblyOptionAddRequestHubDto', 'SdWeeblyOptionAddResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdWeeblyHub', 'AddSdWeeblyAddOptions', 'SdWeeblyOptionAddRequestHubDto', 'SdWeeblyOptionAddResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetSdWeeblyChangeOptionDetail = (requestData: SdWeeblyOptionChangePlansDetailGetRequestHubDto, receiveCallBack: (data: SdWeeblyOptionChangePlansDetailGetResponseHubDto) => void, receiveErrorCallBack: (data: SdWeeblyOptionChangePlansDetailGetResponseHubDto) => void, noSessionCallBack: (data: SdWeeblyOptionChangePlansDetailGetResponseHubDto) => void, completeCallBack: (data: SdWeeblyOptionChangePlansDetailGetResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdWeeblyOptionChangePlansDetailGetResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdWeeblyOptionChangePlansDetailGetResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdWeeblyHub/GetSdWeeblyChangeOptionDetail';

        let responseData: SdWeeblyOptionChangePlansDetailGetResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdWeeblyHub', 'GetSdWeeblyChangeOptionDetail', 'SdWeeblyOptionChangePlansDetailGetRequestHubDto', 'SdWeeblyOptionChangePlansDetailGetResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdWeeblyHub', 'GetSdWeeblyChangeOptionDetail', 'SdWeeblyOptionChangePlansDetailGetRequestHubDto', 'SdWeeblyOptionChangePlansDetailGetResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdWeeblyHub', 'GetSdWeeblyChangeOptionDetail', 'SdWeeblyOptionChangePlansDetailGetRequestHubDto', 'SdWeeblyOptionChangePlansDetailGetResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ChangeSdWeeblyChangeOption = (requestData: SdWeeblyOptionChangePlanRequestHubDto, receiveCallBack: (data: SdWeeblyOptionChangePlanResponseHubDto) => void, receiveErrorCallBack: (data: SdWeeblyOptionChangePlanResponseHubDto) => void, noSessionCallBack: (data: SdWeeblyOptionChangePlanResponseHubDto) => void, completeCallBack: (data: SdWeeblyOptionChangePlanResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdWeeblyOptionChangePlanResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdWeeblyOptionChangePlanResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdWeeblyHub/ChangeSdWeeblyChangeOption';

        let responseData: SdWeeblyOptionChangePlanResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdWeeblyHub', 'ChangeSdWeeblyChangeOption', 'SdWeeblyOptionChangePlanRequestHubDto', 'SdWeeblyOptionChangePlanResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdWeeblyHub', 'ChangeSdWeeblyChangeOption', 'SdWeeblyOptionChangePlanRequestHubDto', 'SdWeeblyOptionChangePlanResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdWeeblyHub', 'ChangeSdWeeblyChangeOption', 'SdWeeblyOptionChangePlanRequestHubDto', 'SdWeeblyOptionChangePlanResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetSdWeeblyCancelOptionDetail = (requestData: SdWeeblyCancelOptionDetailRequestHubDto, receiveCallBack: (data: SdWeeblyOptionCancelDetailResponseHubDto) => void, receiveErrorCallBack: (data: SdWeeblyOptionCancelDetailResponseHubDto) => void, noSessionCallBack: (data: SdWeeblyOptionCancelDetailResponseHubDto) => void, completeCallBack: (data: SdWeeblyOptionCancelDetailResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdWeeblyOptionCancelDetailResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdWeeblyOptionCancelDetailResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdWeeblyHub/GetSdWeeblyCancelOptionDetail';

        let responseData: SdWeeblyOptionCancelDetailResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdWeeblyHub', 'GetSdWeeblyCancelOptionDetail', 'SdWeeblyCancelOptionDetailRequestHubDto', 'SdWeeblyOptionCancelDetailResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdWeeblyHub', 'GetSdWeeblyCancelOptionDetail', 'SdWeeblyCancelOptionDetailRequestHubDto', 'SdWeeblyOptionCancelDetailResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdWeeblyHub', 'GetSdWeeblyCancelOptionDetail', 'SdWeeblyCancelOptionDetailRequestHubDto', 'SdWeeblyOptionCancelDetailResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public CancelSdWeeblyCancelOption = (requestData: SdWeeblyCancelOptionCancelRequestHubDto, receiveCallBack: (data: SdWeeblyOptionCancelResponseHubDto) => void, receiveErrorCallBack: (data: SdWeeblyOptionCancelResponseHubDto) => void, noSessionCallBack: (data: SdWeeblyOptionCancelResponseHubDto) => void, completeCallBack: (data: SdWeeblyOptionCancelResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdWeeblyOptionCancelResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdWeeblyOptionCancelResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdWeeblyHub/CancelSdWeeblyCancelOption';

        let responseData: SdWeeblyOptionCancelResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdWeeblyHub', 'CancelSdWeeblyCancelOption', 'SdWeeblyCancelOptionCancelRequestHubDto', 'SdWeeblyOptionCancelResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdWeeblyHub', 'CancelSdWeeblyCancelOption', 'SdWeeblyCancelOptionCancelRequestHubDto', 'SdWeeblyOptionCancelResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdWeeblyHub', 'CancelSdWeeblyCancelOption', 'SdWeeblyCancelOptionCancelRequestHubDto', 'SdWeeblyOptionCancelResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetSdWeeblyAddDomain = (requestData: SdWeeblyAddDomainGetRequestHubDto, receiveCallBack: (data: SdWeeblyAddDomainGetResponseHubDto) => void, receiveErrorCallBack: (data: SdWeeblyAddDomainGetResponseHubDto) => void, noSessionCallBack: (data: SdWeeblyAddDomainGetResponseHubDto) => void, completeCallBack: (data: SdWeeblyAddDomainGetResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdWeeblyAddDomainGetResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdWeeblyAddDomainGetResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdWeeblyHub/GetSdWeeblyAddDomain';

        let responseData: SdWeeblyAddDomainGetResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdWeeblyHub', 'GetSdWeeblyAddDomain', 'SdWeeblyAddDomainGetRequestHubDto', 'SdWeeblyAddDomainGetResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdWeeblyHub', 'GetSdWeeblyAddDomain', 'SdWeeblyAddDomainGetRequestHubDto', 'SdWeeblyAddDomainGetResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdWeeblyHub', 'GetSdWeeblyAddDomain', 'SdWeeblyAddDomainGetRequestHubDto', 'SdWeeblyAddDomainGetResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ValidateSdWeeblyAddDomainAccount = (requestData: SdWeeblyAddDomainAccountValidateRequestHubDto, receiveCallBack: (data: SdWeeblyAddDomainAccountValidateResponseHubDto) => void, receiveErrorCallBack: (data: SdWeeblyAddDomainAccountValidateResponseHubDto) => void, noSessionCallBack: (data: SdWeeblyAddDomainAccountValidateResponseHubDto) => void, completeCallBack: (data: SdWeeblyAddDomainAccountValidateResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdWeeblyAddDomainAccountValidateResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdWeeblyAddDomainAccountValidateResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdWeeblyHub/ValidateSdWeeblyAddDomainAccount';

        let responseData: SdWeeblyAddDomainAccountValidateResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdWeeblyHub', 'ValidateSdWeeblyAddDomainAccount', 'SdWeeblyAddDomainAccountValidateRequestHubDto', 'SdWeeblyAddDomainAccountValidateResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdWeeblyHub', 'ValidateSdWeeblyAddDomainAccount', 'SdWeeblyAddDomainAccountValidateRequestHubDto', 'SdWeeblyAddDomainAccountValidateResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdWeeblyHub', 'ValidateSdWeeblyAddDomainAccount', 'SdWeeblyAddDomainAccountValidateRequestHubDto', 'SdWeeblyAddDomainAccountValidateResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public AddSdWeeblyAddOnamaeDomain = (requestData: SdWeeblyAddDomainOnamaeAddRequestHubDto, receiveCallBack: (data: SdWeeblyAddDomainOnamaeAddResponseHubDto) => void, receiveErrorCallBack: (data: SdWeeblyAddDomainOnamaeAddResponseHubDto) => void, noSessionCallBack: (data: SdWeeblyAddDomainOnamaeAddResponseHubDto) => void, completeCallBack: (data: SdWeeblyAddDomainOnamaeAddResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdWeeblyAddDomainOnamaeAddResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdWeeblyAddDomainOnamaeAddResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdWeeblyHub/AddSdWeeblyAddOnamaeDomain';

        let responseData: SdWeeblyAddDomainOnamaeAddResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdWeeblyHub', 'AddSdWeeblyAddOnamaeDomain', 'SdWeeblyAddDomainOnamaeAddRequestHubDto', 'SdWeeblyAddDomainOnamaeAddResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdWeeblyHub', 'AddSdWeeblyAddOnamaeDomain', 'SdWeeblyAddDomainOnamaeAddRequestHubDto', 'SdWeeblyAddDomainOnamaeAddResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdWeeblyHub', 'AddSdWeeblyAddOnamaeDomain', 'SdWeeblyAddDomainOnamaeAddRequestHubDto', 'SdWeeblyAddDomainOnamaeAddResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetSdWeeblyAddOnamaeDomain = (requestData: SdWeeblyAddDomainOnamaeGetRequestHubDto, receiveCallBack: (data: SdWeeblyAddDomainOnamaeGetResponseHubDto) => void, receiveErrorCallBack: (data: SdWeeblyAddDomainOnamaeGetResponseHubDto) => void, noSessionCallBack: (data: SdWeeblyAddDomainOnamaeGetResponseHubDto) => void, completeCallBack: (data: SdWeeblyAddDomainOnamaeGetResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdWeeblyAddDomainOnamaeGetResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdWeeblyAddDomainOnamaeGetResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdWeeblyHub/GetSdWeeblyAddOnamaeDomain';

        let responseData: SdWeeblyAddDomainOnamaeGetResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdWeeblyHub', 'GetSdWeeblyAddOnamaeDomain', 'SdWeeblyAddDomainOnamaeGetRequestHubDto', 'SdWeeblyAddDomainOnamaeGetResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdWeeblyHub', 'GetSdWeeblyAddOnamaeDomain', 'SdWeeblyAddDomainOnamaeGetRequestHubDto', 'SdWeeblyAddDomainOnamaeGetResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdWeeblyHub', 'GetSdWeeblyAddOnamaeDomain', 'SdWeeblyAddDomainOnamaeGetRequestHubDto', 'SdWeeblyAddDomainOnamaeGetResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ValidationSdWeeblyAddOnamaeDomain = (requestData: SdWeeblyAddDomainOnamaeValidationRequestHubDto, receiveCallBack: (data: SdWeeblyAddDomainOnamaeValidationResponseHubDto) => void, receiveErrorCallBack: (data: SdWeeblyAddDomainOnamaeValidationResponseHubDto) => void, noSessionCallBack: (data: SdWeeblyAddDomainOnamaeValidationResponseHubDto) => void, completeCallBack: (data: SdWeeblyAddDomainOnamaeValidationResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdWeeblyAddDomainOnamaeValidationResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdWeeblyAddDomainOnamaeValidationResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdWeeblyHub/ValidationSdWeeblyAddOnamaeDomain';

        let responseData: SdWeeblyAddDomainOnamaeValidationResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdWeeblyHub', 'ValidationSdWeeblyAddOnamaeDomain', 'SdWeeblyAddDomainOnamaeValidationRequestHubDto', 'SdWeeblyAddDomainOnamaeValidationResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdWeeblyHub', 'ValidationSdWeeblyAddOnamaeDomain', 'SdWeeblyAddDomainOnamaeValidationRequestHubDto', 'SdWeeblyAddDomainOnamaeValidationResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdWeeblyHub', 'ValidationSdWeeblyAddOnamaeDomain', 'SdWeeblyAddDomainOnamaeValidationRequestHubDto', 'SdWeeblyAddDomainOnamaeValidationResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public AddSdWeeblyAddOtherCompanyDomain = (requestData: SdWeeblyAddDomainOtherCompanyAddRequestHubDto, receiveCallBack: (data: SdWeeblyAddDomainOtherCompanyAddResponseHubDto) => void, receiveErrorCallBack: (data: SdWeeblyAddDomainOtherCompanyAddResponseHubDto) => void, noSessionCallBack: (data: SdWeeblyAddDomainOtherCompanyAddResponseHubDto) => void, completeCallBack: (data: SdWeeblyAddDomainOtherCompanyAddResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdWeeblyAddDomainOtherCompanyAddResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdWeeblyAddDomainOtherCompanyAddResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdWeeblyHub/AddSdWeeblyAddOtherCompanyDomain';

        let responseData: SdWeeblyAddDomainOtherCompanyAddResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdWeeblyHub', 'AddSdWeeblyAddOtherCompanyDomain', 'SdWeeblyAddDomainOtherCompanyAddRequestHubDto', 'SdWeeblyAddDomainOtherCompanyAddResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdWeeblyHub', 'AddSdWeeblyAddOtherCompanyDomain', 'SdWeeblyAddDomainOtherCompanyAddRequestHubDto', 'SdWeeblyAddDomainOtherCompanyAddResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdWeeblyHub', 'AddSdWeeblyAddOtherCompanyDomain', 'SdWeeblyAddDomainOtherCompanyAddRequestHubDto', 'SdWeeblyAddDomainOtherCompanyAddResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ValidationSdWeeblyAddOtherCompanyDomain = (requestData: SdWeeblyAddDomainOtherCompanyValidationRequestHubDto, receiveCallBack: (data: SdWeeblyAddDomainOtherCompanyValidationResponseHubDto) => void, receiveErrorCallBack: (data: SdWeeblyAddDomainOtherCompanyValidationResponseHubDto) => void, noSessionCallBack: (data: SdWeeblyAddDomainOtherCompanyValidationResponseHubDto) => void, completeCallBack: (data: SdWeeblyAddDomainOtherCompanyValidationResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdWeeblyAddDomainOtherCompanyValidationResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdWeeblyAddDomainOtherCompanyValidationResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdWeeblyHub/ValidationSdWeeblyAddOtherCompanyDomain';

        let responseData: SdWeeblyAddDomainOtherCompanyValidationResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdWeeblyHub', 'ValidationSdWeeblyAddOtherCompanyDomain', 'SdWeeblyAddDomainOtherCompanyValidationRequestHubDto', 'SdWeeblyAddDomainOtherCompanyValidationResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdWeeblyHub', 'ValidationSdWeeblyAddOtherCompanyDomain', 'SdWeeblyAddDomainOtherCompanyValidationRequestHubDto', 'SdWeeblyAddDomainOtherCompanyValidationResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdWeeblyHub', 'ValidationSdWeeblyAddOtherCompanyDomain', 'SdWeeblyAddDomainOtherCompanyValidationRequestHubDto', 'SdWeeblyAddDomainOtherCompanyValidationResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetServerRedirection = (requestData: ServerRedirectionRequestHubDto, receiveCallBack: (data: ServerRedirectionResponseHubDto) => void, receiveErrorCallBack: (data: ServerRedirectionResponseHubDto) => void, noSessionCallBack: (data: ServerRedirectionResponseHubDto) => void, completeCallBack: (data: ServerRedirectionResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: ServerRedirectionResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: ServerRedirectionResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdWeeblyHub/GetServerRedirection';

        let responseData: ServerRedirectionResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdWeeblyHub', 'GetServerRedirection', 'ServerRedirectionRequestHubDto', 'ServerRedirectionResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdWeeblyHub', 'GetServerRedirection', 'ServerRedirectionRequestHubDto', 'ServerRedirectionResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdWeeblyHub', 'GetServerRedirection', 'ServerRedirectionRequestHubDto', 'ServerRedirectionResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }

}



export class SystemLinkageHubManager {
    /**
     * コンストラクタ
     */
    constructor(private http: HttpClient) {
    }

	// リソース破棄
    public Dispose = (): void => {
    }



    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public AddDomainCheckToken = (requestData: SystemLinkageAddDomainCheckTokenRequestHubDto, receiveCallBack: (data: SystemLinkageAddDomainCheckTokenResponseHubDto) => void, receiveErrorCallBack: (data: SystemLinkageAddDomainCheckTokenResponseHubDto) => void, noSessionCallBack: (data: SystemLinkageAddDomainCheckTokenResponseHubDto) => void, completeCallBack: (data: SystemLinkageAddDomainCheckTokenResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SystemLinkageAddDomainCheckTokenResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SystemLinkageAddDomainCheckTokenResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SystemLinkageHub/AddDomainCheckToken';

        let responseData: SystemLinkageAddDomainCheckTokenResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SystemLinkageHub', 'AddDomainCheckToken', 'SystemLinkageAddDomainCheckTokenRequestHubDto', 'SystemLinkageAddDomainCheckTokenResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SystemLinkageHub', 'AddDomainCheckToken', 'SystemLinkageAddDomainCheckTokenRequestHubDto', 'SystemLinkageAddDomainCheckTokenResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SystemLinkageHub', 'AddDomainCheckToken', 'SystemLinkageAddDomainCheckTokenRequestHubDto', 'SystemLinkageAddDomainCheckTokenResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public AddDomainOtherCompanyValidation = (requestData: SystemLinkageAddDomainOtherCompanyValidationRequestHubDto, receiveCallBack: (data: SystemLinkageAddDomainOtherCompanyValidationResponseHubDto) => void, receiveErrorCallBack: (data: SystemLinkageAddDomainOtherCompanyValidationResponseHubDto) => void, noSessionCallBack: (data: SystemLinkageAddDomainOtherCompanyValidationResponseHubDto) => void, completeCallBack: (data: SystemLinkageAddDomainOtherCompanyValidationResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SystemLinkageAddDomainOtherCompanyValidationResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SystemLinkageAddDomainOtherCompanyValidationResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SystemLinkageHub/AddDomainOtherCompanyValidation';

        let responseData: SystemLinkageAddDomainOtherCompanyValidationResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SystemLinkageHub', 'AddDomainOtherCompanyValidation', 'SystemLinkageAddDomainOtherCompanyValidationRequestHubDto', 'SystemLinkageAddDomainOtherCompanyValidationResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SystemLinkageHub', 'AddDomainOtherCompanyValidation', 'SystemLinkageAddDomainOtherCompanyValidationRequestHubDto', 'SystemLinkageAddDomainOtherCompanyValidationResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SystemLinkageHub', 'AddDomainOtherCompanyValidation', 'SystemLinkageAddDomainOtherCompanyValidationRequestHubDto', 'SystemLinkageAddDomainOtherCompanyValidationResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public AddDomainOtherCompanyConfirm = (requestData: SystemLinkageAddDomainOtherCompanyConfirmRequestHubDto, receiveCallBack: (data: SystemLinkageAddDomainOtherCompanyConfirmResponseHubDto) => void, receiveErrorCallBack: (data: SystemLinkageAddDomainOtherCompanyConfirmResponseHubDto) => void, noSessionCallBack: (data: SystemLinkageAddDomainOtherCompanyConfirmResponseHubDto) => void, completeCallBack: (data: SystemLinkageAddDomainOtherCompanyConfirmResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SystemLinkageAddDomainOtherCompanyConfirmResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SystemLinkageAddDomainOtherCompanyConfirmResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SystemLinkageHub/AddDomainOtherCompanyConfirm';

        let responseData: SystemLinkageAddDomainOtherCompanyConfirmResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SystemLinkageHub', 'AddDomainOtherCompanyConfirm', 'SystemLinkageAddDomainOtherCompanyConfirmRequestHubDto', 'SystemLinkageAddDomainOtherCompanyConfirmResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SystemLinkageHub', 'AddDomainOtherCompanyConfirm', 'SystemLinkageAddDomainOtherCompanyConfirmRequestHubDto', 'SystemLinkageAddDomainOtherCompanyConfirmResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SystemLinkageHub', 'AddDomainOtherCompanyConfirm', 'SystemLinkageAddDomainOtherCompanyConfirmRequestHubDto', 'SystemLinkageAddDomainOtherCompanyConfirmResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public AddDomainOtherCompanyAdd = (requestData: SystemLinkageAddDomainOtherCompanyAddRequestHubDto, receiveCallBack: (data: SystemLinkageAddDomainOtherCompanyAddResponseHubDto) => void, receiveErrorCallBack: (data: SystemLinkageAddDomainOtherCompanyAddResponseHubDto) => void, noSessionCallBack: (data: SystemLinkageAddDomainOtherCompanyAddResponseHubDto) => void, completeCallBack: (data: SystemLinkageAddDomainOtherCompanyAddResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SystemLinkageAddDomainOtherCompanyAddResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SystemLinkageAddDomainOtherCompanyAddResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SystemLinkageHub/AddDomainOtherCompanyAdd';

        let responseData: SystemLinkageAddDomainOtherCompanyAddResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SystemLinkageHub', 'AddDomainOtherCompanyAdd', 'SystemLinkageAddDomainOtherCompanyAddRequestHubDto', 'SystemLinkageAddDomainOtherCompanyAddResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SystemLinkageHub', 'AddDomainOtherCompanyAdd', 'SystemLinkageAddDomainOtherCompanyAddRequestHubDto', 'SystemLinkageAddDomainOtherCompanyAddResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SystemLinkageHub', 'AddDomainOtherCompanyAdd', 'SystemLinkageAddDomainOtherCompanyAddRequestHubDto', 'SystemLinkageAddDomainOtherCompanyAddResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public AddDomainOnamaeGetDomains = (requestData: SystemLinkageAddDomainOnamaeGetDomainsRequestHubDto, receiveCallBack: (data: SystemLinkageAddDomainOnamaeGetDomainsResponseHubDto) => void, receiveErrorCallBack: (data: SystemLinkageAddDomainOnamaeGetDomainsResponseHubDto) => void, noSessionCallBack: (data: SystemLinkageAddDomainOnamaeGetDomainsResponseHubDto) => void, completeCallBack: (data: SystemLinkageAddDomainOnamaeGetDomainsResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SystemLinkageAddDomainOnamaeGetDomainsResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SystemLinkageAddDomainOnamaeGetDomainsResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SystemLinkageHub/AddDomainOnamaeGetDomains';

        let responseData: SystemLinkageAddDomainOnamaeGetDomainsResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SystemLinkageHub', 'AddDomainOnamaeGetDomains', 'SystemLinkageAddDomainOnamaeGetDomainsRequestHubDto', 'SystemLinkageAddDomainOnamaeGetDomainsResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SystemLinkageHub', 'AddDomainOnamaeGetDomains', 'SystemLinkageAddDomainOnamaeGetDomainsRequestHubDto', 'SystemLinkageAddDomainOnamaeGetDomainsResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SystemLinkageHub', 'AddDomainOnamaeGetDomains', 'SystemLinkageAddDomainOnamaeGetDomainsRequestHubDto', 'SystemLinkageAddDomainOnamaeGetDomainsResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public AddDomainOnamaeLoginAnotherAccount = (requestData: SystemLinkageAddDomainOnamaeLoginAnotherAccountRequestHubDto, receiveCallBack: (data: SystemLinkageAddDomainOnamaeLoginAnotherAccountResponseHubDto) => void, receiveErrorCallBack: (data: SystemLinkageAddDomainOnamaeLoginAnotherAccountResponseHubDto) => void, noSessionCallBack: (data: SystemLinkageAddDomainOnamaeLoginAnotherAccountResponseHubDto) => void, completeCallBack: (data: SystemLinkageAddDomainOnamaeLoginAnotherAccountResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SystemLinkageAddDomainOnamaeLoginAnotherAccountResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SystemLinkageAddDomainOnamaeLoginAnotherAccountResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SystemLinkageHub/AddDomainOnamaeLoginAnotherAccount';

        let responseData: SystemLinkageAddDomainOnamaeLoginAnotherAccountResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SystemLinkageHub', 'AddDomainOnamaeLoginAnotherAccount', 'SystemLinkageAddDomainOnamaeLoginAnotherAccountRequestHubDto', 'SystemLinkageAddDomainOnamaeLoginAnotherAccountResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SystemLinkageHub', 'AddDomainOnamaeLoginAnotherAccount', 'SystemLinkageAddDomainOnamaeLoginAnotherAccountRequestHubDto', 'SystemLinkageAddDomainOnamaeLoginAnotherAccountResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SystemLinkageHub', 'AddDomainOnamaeLoginAnotherAccount', 'SystemLinkageAddDomainOnamaeLoginAnotherAccountRequestHubDto', 'SystemLinkageAddDomainOnamaeLoginAnotherAccountResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public AddDomainOnamaeValidation = (requestData: SystemLinkageAddDomainOnamaeValidationRequestHubDto, receiveCallBack: (data: SystemLinkageAddDomainOnamaeValidationResponseHubDto) => void, receiveErrorCallBack: (data: SystemLinkageAddDomainOnamaeValidationResponseHubDto) => void, noSessionCallBack: (data: SystemLinkageAddDomainOnamaeValidationResponseHubDto) => void, completeCallBack: (data: SystemLinkageAddDomainOnamaeValidationResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SystemLinkageAddDomainOnamaeValidationResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SystemLinkageAddDomainOnamaeValidationResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SystemLinkageHub/AddDomainOnamaeValidation';

        let responseData: SystemLinkageAddDomainOnamaeValidationResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SystemLinkageHub', 'AddDomainOnamaeValidation', 'SystemLinkageAddDomainOnamaeValidationRequestHubDto', 'SystemLinkageAddDomainOnamaeValidationResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SystemLinkageHub', 'AddDomainOnamaeValidation', 'SystemLinkageAddDomainOnamaeValidationRequestHubDto', 'SystemLinkageAddDomainOnamaeValidationResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SystemLinkageHub', 'AddDomainOnamaeValidation', 'SystemLinkageAddDomainOnamaeValidationRequestHubDto', 'SystemLinkageAddDomainOnamaeValidationResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public AddDomainOnamaeConfirm = (requestData: SystemLinkageAddDomainOnamaeConfirmRequestHubDto, receiveCallBack: (data: SystemLinkageAddDomainOnamaeConfirmResponseHubDto) => void, receiveErrorCallBack: (data: SystemLinkageAddDomainOnamaeConfirmResponseHubDto) => void, noSessionCallBack: (data: SystemLinkageAddDomainOnamaeConfirmResponseHubDto) => void, completeCallBack: (data: SystemLinkageAddDomainOnamaeConfirmResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SystemLinkageAddDomainOnamaeConfirmResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SystemLinkageAddDomainOnamaeConfirmResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SystemLinkageHub/AddDomainOnamaeConfirm';

        let responseData: SystemLinkageAddDomainOnamaeConfirmResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SystemLinkageHub', 'AddDomainOnamaeConfirm', 'SystemLinkageAddDomainOnamaeConfirmRequestHubDto', 'SystemLinkageAddDomainOnamaeConfirmResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SystemLinkageHub', 'AddDomainOnamaeConfirm', 'SystemLinkageAddDomainOnamaeConfirmRequestHubDto', 'SystemLinkageAddDomainOnamaeConfirmResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SystemLinkageHub', 'AddDomainOnamaeConfirm', 'SystemLinkageAddDomainOnamaeConfirmRequestHubDto', 'SystemLinkageAddDomainOnamaeConfirmResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public AddDomainOnamaeAdd = (requestData: SystemLinkageAddDomainOnamaeAddRequestHubDto, receiveCallBack: (data: SystemLinkageAddDomainOnamaeAddResponseHubDto) => void, receiveErrorCallBack: (data: SystemLinkageAddDomainOnamaeAddResponseHubDto) => void, noSessionCallBack: (data: SystemLinkageAddDomainOnamaeAddResponseHubDto) => void, completeCallBack: (data: SystemLinkageAddDomainOnamaeAddResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SystemLinkageAddDomainOnamaeAddResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SystemLinkageAddDomainOnamaeAddResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SystemLinkageHub/AddDomainOnamaeAdd';

        let responseData: SystemLinkageAddDomainOnamaeAddResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SystemLinkageHub', 'AddDomainOnamaeAdd', 'SystemLinkageAddDomainOnamaeAddRequestHubDto', 'SystemLinkageAddDomainOnamaeAddResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SystemLinkageHub', 'AddDomainOnamaeAdd', 'SystemLinkageAddDomainOnamaeAddRequestHubDto', 'SystemLinkageAddDomainOnamaeAddResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SystemLinkageHub', 'AddDomainOnamaeAdd', 'SystemLinkageAddDomainOnamaeAddRequestHubDto', 'SystemLinkageAddDomainOnamaeAddResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public AddOptionCheckToken = (requestData: SystemLinkageAddOptionCheckTokenRequestHubDto, receiveCallBack: (data: SystemLinkageAddOptionCheckTokenResponseHubDto) => void, receiveErrorCallBack: (data: SystemLinkageAddOptionCheckTokenResponseHubDto) => void, noSessionCallBack: (data: SystemLinkageAddOptionCheckTokenResponseHubDto) => void, completeCallBack: (data: SystemLinkageAddOptionCheckTokenResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SystemLinkageAddOptionCheckTokenResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SystemLinkageAddOptionCheckTokenResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SystemLinkageHub/AddOptionCheckToken';

        let responseData: SystemLinkageAddOptionCheckTokenResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SystemLinkageHub', 'AddOptionCheckToken', 'SystemLinkageAddOptionCheckTokenRequestHubDto', 'SystemLinkageAddOptionCheckTokenResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SystemLinkageHub', 'AddOptionCheckToken', 'SystemLinkageAddOptionCheckTokenRequestHubDto', 'SystemLinkageAddOptionCheckTokenResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SystemLinkageHub', 'AddOptionCheckToken', 'SystemLinkageAddOptionCheckTokenRequestHubDto', 'SystemLinkageAddOptionCheckTokenResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public AddOptionFork = (requestData: SystemLinkageAddOptionForkRequestHubDto, receiveCallBack: (data: SystemLinkageAddOptionForkResponseHubDto) => void, receiveErrorCallBack: (data: SystemLinkageAddOptionForkResponseHubDto) => void, noSessionCallBack: (data: SystemLinkageAddOptionForkResponseHubDto) => void, completeCallBack: (data: SystemLinkageAddOptionForkResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SystemLinkageAddOptionForkResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SystemLinkageAddOptionForkResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SystemLinkageHub/AddOptionFork';

        let responseData: SystemLinkageAddOptionForkResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SystemLinkageHub', 'AddOptionFork', 'SystemLinkageAddOptionForkRequestHubDto', 'SystemLinkageAddOptionForkResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SystemLinkageHub', 'AddOptionFork', 'SystemLinkageAddOptionForkRequestHubDto', 'SystemLinkageAddOptionForkResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SystemLinkageHub', 'AddOptionFork', 'SystemLinkageAddOptionForkRequestHubDto', 'SystemLinkageAddOptionForkResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetAddOptionDetail = (requestData: SystemLinkageAddOptionRequestHubDto, receiveCallBack: (data: SystemLinkageOptionAddDetailGetResponseHubDto) => void, receiveErrorCallBack: (data: SystemLinkageOptionAddDetailGetResponseHubDto) => void, noSessionCallBack: (data: SystemLinkageOptionAddDetailGetResponseHubDto) => void, completeCallBack: (data: SystemLinkageOptionAddDetailGetResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SystemLinkageOptionAddDetailGetResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SystemLinkageOptionAddDetailGetResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SystemLinkageHub/GetAddOptionDetail';

        let responseData: SystemLinkageOptionAddDetailGetResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SystemLinkageHub', 'GetAddOptionDetail', 'SystemLinkageAddOptionRequestHubDto', 'SystemLinkageOptionAddDetailGetResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SystemLinkageHub', 'GetAddOptionDetail', 'SystemLinkageAddOptionRequestHubDto', 'SystemLinkageOptionAddDetailGetResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SystemLinkageHub', 'GetAddOptionDetail', 'SystemLinkageAddOptionRequestHubDto', 'SystemLinkageOptionAddDetailGetResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetAddOptionSslDomains = (requestData: SystemLinkageAddOptionGetSslDomainsRequestHubDto, receiveCallBack: (data: SystemLinkageAddOptionGetSslDomainsResponseHubDto) => void, receiveErrorCallBack: (data: SystemLinkageAddOptionGetSslDomainsResponseHubDto) => void, noSessionCallBack: (data: SystemLinkageAddOptionGetSslDomainsResponseHubDto) => void, completeCallBack: (data: SystemLinkageAddOptionGetSslDomainsResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SystemLinkageAddOptionGetSslDomainsResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SystemLinkageAddOptionGetSslDomainsResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SystemLinkageHub/GetAddOptionSslDomains';

        let responseData: SystemLinkageAddOptionGetSslDomainsResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SystemLinkageHub', 'GetAddOptionSslDomains', 'SystemLinkageAddOptionGetSslDomainsRequestHubDto', 'SystemLinkageAddOptionGetSslDomainsResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SystemLinkageHub', 'GetAddOptionSslDomains', 'SystemLinkageAddOptionGetSslDomainsRequestHubDto', 'SystemLinkageAddOptionGetSslDomainsResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SystemLinkageHub', 'GetAddOptionSslDomains', 'SystemLinkageAddOptionGetSslDomainsRequestHubDto', 'SystemLinkageAddOptionGetSslDomainsResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetAddOptionSitelockDomains = (requestData: SystemLinkageAddOptionGetSiteLockDomainsRequestHubDto, receiveCallBack: (data: SystemLinkageAddOptionGetSitelockDomainsResponseHubDto) => void, receiveErrorCallBack: (data: SystemLinkageAddOptionGetSitelockDomainsResponseHubDto) => void, noSessionCallBack: (data: SystemLinkageAddOptionGetSitelockDomainsResponseHubDto) => void, completeCallBack: (data: SystemLinkageAddOptionGetSitelockDomainsResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SystemLinkageAddOptionGetSitelockDomainsResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SystemLinkageAddOptionGetSitelockDomainsResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SystemLinkageHub/GetAddOptionSitelockDomains';

        let responseData: SystemLinkageAddOptionGetSitelockDomainsResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SystemLinkageHub', 'GetAddOptionSitelockDomains', 'SystemLinkageAddOptionGetSiteLockDomainsRequestHubDto', 'SystemLinkageAddOptionGetSitelockDomainsResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SystemLinkageHub', 'GetAddOptionSitelockDomains', 'SystemLinkageAddOptionGetSiteLockDomainsRequestHubDto', 'SystemLinkageAddOptionGetSitelockDomainsResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SystemLinkageHub', 'GetAddOptionSitelockDomains', 'SystemLinkageAddOptionGetSiteLockDomainsRequestHubDto', 'SystemLinkageAddOptionGetSitelockDomainsResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetAddOptionWeeblyDomains = (requestData: SystemLinkageAddOptionGetWeeblyDomainsRequestHubDto, receiveCallBack: (data: SystemLinkageAddOptionGetWeeblyDomainsResponseHubDto) => void, receiveErrorCallBack: (data: SystemLinkageAddOptionGetWeeblyDomainsResponseHubDto) => void, noSessionCallBack: (data: SystemLinkageAddOptionGetWeeblyDomainsResponseHubDto) => void, completeCallBack: (data: SystemLinkageAddOptionGetWeeblyDomainsResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SystemLinkageAddOptionGetWeeblyDomainsResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SystemLinkageAddOptionGetWeeblyDomainsResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SystemLinkageHub/GetAddOptionWeeblyDomains';

        let responseData: SystemLinkageAddOptionGetWeeblyDomainsResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SystemLinkageHub', 'GetAddOptionWeeblyDomains', 'SystemLinkageAddOptionGetWeeblyDomainsRequestHubDto', 'SystemLinkageAddOptionGetWeeblyDomainsResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SystemLinkageHub', 'GetAddOptionWeeblyDomains', 'SystemLinkageAddOptionGetWeeblyDomainsRequestHubDto', 'SystemLinkageAddOptionGetWeeblyDomainsResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SystemLinkageHub', 'GetAddOptionWeeblyDomains', 'SystemLinkageAddOptionGetWeeblyDomainsRequestHubDto', 'SystemLinkageAddOptionGetWeeblyDomainsResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetAddOptionCountriesAndPrefectures = (receiveCallBack: (data: HostingCountriesAndPrefecturesResponseHubDto) => void, receiveErrorCallBack: (data: HostingCountriesAndPrefecturesResponseHubDto) => void, noSessionCallBack: (data: HostingCountriesAndPrefecturesResponseHubDto) => void, completeCallBack: (data: HostingCountriesAndPrefecturesResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: HostingCountriesAndPrefecturesResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: HostingCountriesAndPrefecturesResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SystemLinkageHub/GetAddOptionCountriesAndPrefectures';

        let responseData: HostingCountriesAndPrefecturesResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SystemLinkageHub', 'GetAddOptionCountriesAndPrefectures', null, 'HostingCountriesAndPrefecturesResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SystemLinkageHub', 'GetAddOptionCountriesAndPrefectures', null, 'HostingCountriesAndPrefecturesResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SystemLinkageHub', 'GetAddOptionCountriesAndPrefectures', null, 'HostingCountriesAndPrefecturesResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ValidateAddOptionSslInfo = (requestData: SystemLinkageAddOptionValidateSslInfoRequestHubDto, receiveCallBack: (data: SystemLinkageAddOptionValidateSslInfoResponseHubDto) => void, receiveErrorCallBack: (data: SystemLinkageAddOptionValidateSslInfoResponseHubDto) => void, noSessionCallBack: (data: SystemLinkageAddOptionValidateSslInfoResponseHubDto) => void, completeCallBack: (data: SystemLinkageAddOptionValidateSslInfoResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SystemLinkageAddOptionValidateSslInfoResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SystemLinkageAddOptionValidateSslInfoResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SystemLinkageHub/ValidateAddOptionSslInfo';

        let responseData: SystemLinkageAddOptionValidateSslInfoResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SystemLinkageHub', 'ValidateAddOptionSslInfo', 'SystemLinkageAddOptionValidateSslInfoRequestHubDto', 'SystemLinkageAddOptionValidateSslInfoResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SystemLinkageHub', 'ValidateAddOptionSslInfo', 'SystemLinkageAddOptionValidateSslInfoRequestHubDto', 'SystemLinkageAddOptionValidateSslInfoResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SystemLinkageHub', 'ValidateAddOptionSslInfo', 'SystemLinkageAddOptionValidateSslInfoRequestHubDto', 'SystemLinkageAddOptionValidateSslInfoResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetAddOptionPaymentDetail = (requestData: SystemLinkageAddOptionPaymentDetailRequestHubDto, receiveCallBack: (data: SystemLinkageOptionAddConfirmPaymentDetailResponseHubDto) => void, receiveErrorCallBack: (data: SystemLinkageOptionAddConfirmPaymentDetailResponseHubDto) => void, noSessionCallBack: (data: SystemLinkageOptionAddConfirmPaymentDetailResponseHubDto) => void, completeCallBack: (data: SystemLinkageOptionAddConfirmPaymentDetailResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SystemLinkageOptionAddConfirmPaymentDetailResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SystemLinkageOptionAddConfirmPaymentDetailResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SystemLinkageHub/GetAddOptionPaymentDetail';

        let responseData: SystemLinkageOptionAddConfirmPaymentDetailResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SystemLinkageHub', 'GetAddOptionPaymentDetail', 'SystemLinkageAddOptionPaymentDetailRequestHubDto', 'SystemLinkageOptionAddConfirmPaymentDetailResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SystemLinkageHub', 'GetAddOptionPaymentDetail', 'SystemLinkageAddOptionPaymentDetailRequestHubDto', 'SystemLinkageOptionAddConfirmPaymentDetailResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SystemLinkageHub', 'GetAddOptionPaymentDetail', 'SystemLinkageAddOptionPaymentDetailRequestHubDto', 'SystemLinkageOptionAddConfirmPaymentDetailResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public AddOptionAdd = (requestData: SystemLinkageAddOptionRequestHubDto, receiveCallBack: (data: SystemLinkageAddOptionResponseHubDto) => void, receiveErrorCallBack: (data: SystemLinkageAddOptionResponseHubDto) => void, noSessionCallBack: (data: SystemLinkageAddOptionResponseHubDto) => void, completeCallBack: (data: SystemLinkageAddOptionResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SystemLinkageAddOptionResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SystemLinkageAddOptionResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SystemLinkageHub/AddOptionAdd';

        let responseData: SystemLinkageAddOptionResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SystemLinkageHub', 'AddOptionAdd', 'SystemLinkageAddOptionRequestHubDto', 'SystemLinkageAddOptionResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SystemLinkageHub', 'AddOptionAdd', 'SystemLinkageAddOptionRequestHubDto', 'SystemLinkageAddOptionResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SystemLinkageHub', 'AddOptionAdd', 'SystemLinkageAddOptionRequestHubDto', 'SystemLinkageAddOptionResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ChangeOptionCheckToken = (requestData: SystemLinkageOptionChangeCheckTokenRequestHubDto, receiveCallBack: (data: SystemLinkageOptionChangeCheckTokenResponseHubDto) => void, receiveErrorCallBack: (data: SystemLinkageOptionChangeCheckTokenResponseHubDto) => void, noSessionCallBack: (data: SystemLinkageOptionChangeCheckTokenResponseHubDto) => void, completeCallBack: (data: SystemLinkageOptionChangeCheckTokenResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SystemLinkageOptionChangeCheckTokenResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SystemLinkageOptionChangeCheckTokenResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SystemLinkageHub/ChangeOptionCheckToken';

        let responseData: SystemLinkageOptionChangeCheckTokenResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SystemLinkageHub', 'ChangeOptionCheckToken', 'SystemLinkageOptionChangeCheckTokenRequestHubDto', 'SystemLinkageOptionChangeCheckTokenResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SystemLinkageHub', 'ChangeOptionCheckToken', 'SystemLinkageOptionChangeCheckTokenRequestHubDto', 'SystemLinkageOptionChangeCheckTokenResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SystemLinkageHub', 'ChangeOptionCheckToken', 'SystemLinkageOptionChangeCheckTokenRequestHubDto', 'SystemLinkageOptionChangeCheckTokenResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetChangeOptions = (requestData: SystemLinkageOptionChangePlansGetRequestHubDto, receiveCallBack: (data: SystemLinkageOptionChangePlansGetResponseHubDto) => void, receiveErrorCallBack: (data: SystemLinkageOptionChangePlansGetResponseHubDto) => void, noSessionCallBack: (data: SystemLinkageOptionChangePlansGetResponseHubDto) => void, completeCallBack: (data: SystemLinkageOptionChangePlansGetResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SystemLinkageOptionChangePlansGetResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SystemLinkageOptionChangePlansGetResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SystemLinkageHub/GetChangeOptions';

        let responseData: SystemLinkageOptionChangePlansGetResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SystemLinkageHub', 'GetChangeOptions', 'SystemLinkageOptionChangePlansGetRequestHubDto', 'SystemLinkageOptionChangePlansGetResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SystemLinkageHub', 'GetChangeOptions', 'SystemLinkageOptionChangePlansGetRequestHubDto', 'SystemLinkageOptionChangePlansGetResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SystemLinkageHub', 'GetChangeOptions', 'SystemLinkageOptionChangePlansGetRequestHubDto', 'SystemLinkageOptionChangePlansGetResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetChangeOptionDetail = (requestData: SystemLinkageOptionChangePlansDetailGetRequestHubDto, receiveCallBack: (data: SystemLinkageOptionChangePlansDetailGetResponseHubDto) => void, receiveErrorCallBack: (data: SystemLinkageOptionChangePlansDetailGetResponseHubDto) => void, noSessionCallBack: (data: SystemLinkageOptionChangePlansDetailGetResponseHubDto) => void, completeCallBack: (data: SystemLinkageOptionChangePlansDetailGetResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SystemLinkageOptionChangePlansDetailGetResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SystemLinkageOptionChangePlansDetailGetResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SystemLinkageHub/GetChangeOptionDetail';

        let responseData: SystemLinkageOptionChangePlansDetailGetResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SystemLinkageHub', 'GetChangeOptionDetail', 'SystemLinkageOptionChangePlansDetailGetRequestHubDto', 'SystemLinkageOptionChangePlansDetailGetResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SystemLinkageHub', 'GetChangeOptionDetail', 'SystemLinkageOptionChangePlansDetailGetRequestHubDto', 'SystemLinkageOptionChangePlansDetailGetResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SystemLinkageHub', 'GetChangeOptionDetail', 'SystemLinkageOptionChangePlansDetailGetRequestHubDto', 'SystemLinkageOptionChangePlansDetailGetResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ChangeOption = (requestData: SystemLinkageOptionChangePlanRequestHubDto, receiveCallBack: (data: SystemLinkageOptionChangePlanResponseHubDto) => void, receiveErrorCallBack: (data: SystemLinkageOptionChangePlanResponseHubDto) => void, noSessionCallBack: (data: SystemLinkageOptionChangePlanResponseHubDto) => void, completeCallBack: (data: SystemLinkageOptionChangePlanResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SystemLinkageOptionChangePlanResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SystemLinkageOptionChangePlanResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SystemLinkageHub/ChangeOption';

        let responseData: SystemLinkageOptionChangePlanResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SystemLinkageHub', 'ChangeOption', 'SystemLinkageOptionChangePlanRequestHubDto', 'SystemLinkageOptionChangePlanResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SystemLinkageHub', 'ChangeOption', 'SystemLinkageOptionChangePlanRequestHubDto', 'SystemLinkageOptionChangePlanResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SystemLinkageHub', 'ChangeOption', 'SystemLinkageOptionChangePlanRequestHubDto', 'SystemLinkageOptionChangePlanResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public CancelOptionCheckToken = (requestData: SystemLinkageCancelOptionCheckTokenRequestHubDto, receiveCallBack: (data: SystemLinkageCancelOptionCheckTokenResponseHubDto) => void, receiveErrorCallBack: (data: SystemLinkageCancelOptionCheckTokenResponseHubDto) => void, noSessionCallBack: (data: SystemLinkageCancelOptionCheckTokenResponseHubDto) => void, completeCallBack: (data: SystemLinkageCancelOptionCheckTokenResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SystemLinkageCancelOptionCheckTokenResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SystemLinkageCancelOptionCheckTokenResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SystemLinkageHub/CancelOptionCheckToken';

        let responseData: SystemLinkageCancelOptionCheckTokenResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SystemLinkageHub', 'CancelOptionCheckToken', 'SystemLinkageCancelOptionCheckTokenRequestHubDto', 'SystemLinkageCancelOptionCheckTokenResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SystemLinkageHub', 'CancelOptionCheckToken', 'SystemLinkageCancelOptionCheckTokenRequestHubDto', 'SystemLinkageCancelOptionCheckTokenResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SystemLinkageHub', 'CancelOptionCheckToken', 'SystemLinkageCancelOptionCheckTokenRequestHubDto', 'SystemLinkageCancelOptionCheckTokenResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public CancelOptionGetDetail = (requestData: SystemLinkageOptionCancelDetailRequestHubDto, receiveCallBack: (data: SystemLinkageOptionCancelDetailResponseHubDto) => void, receiveErrorCallBack: (data: SystemLinkageOptionCancelDetailResponseHubDto) => void, noSessionCallBack: (data: SystemLinkageOptionCancelDetailResponseHubDto) => void, completeCallBack: (data: SystemLinkageOptionCancelDetailResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SystemLinkageOptionCancelDetailResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SystemLinkageOptionCancelDetailResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SystemLinkageHub/CancelOptionGetDetail';

        let responseData: SystemLinkageOptionCancelDetailResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SystemLinkageHub', 'CancelOptionGetDetail', 'SystemLinkageOptionCancelDetailRequestHubDto', 'SystemLinkageOptionCancelDetailResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SystemLinkageHub', 'CancelOptionGetDetail', 'SystemLinkageOptionCancelDetailRequestHubDto', 'SystemLinkageOptionCancelDetailResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SystemLinkageHub', 'CancelOptionGetDetail', 'SystemLinkageOptionCancelDetailRequestHubDto', 'SystemLinkageOptionCancelDetailResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public CancelOption = (requestData: SystemLinkageCancelOptionRequestHubDto, receiveCallBack: (data: SystemLinkageCancelOptionResponseHubDto) => void, receiveErrorCallBack: (data: SystemLinkageCancelOptionResponseHubDto) => void, noSessionCallBack: (data: SystemLinkageCancelOptionResponseHubDto) => void, completeCallBack: (data: SystemLinkageCancelOptionResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SystemLinkageCancelOptionResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SystemLinkageCancelOptionResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SystemLinkageHub/CancelOption';

        let responseData: SystemLinkageCancelOptionResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SystemLinkageHub', 'CancelOption', 'SystemLinkageCancelOptionRequestHubDto', 'SystemLinkageCancelOptionResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SystemLinkageHub', 'CancelOption', 'SystemLinkageCancelOptionRequestHubDto', 'SystemLinkageCancelOptionResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SystemLinkageHub', 'CancelOption', 'SystemLinkageCancelOptionRequestHubDto', 'SystemLinkageCancelOptionResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }

}



export class InvoiceHubManager {
    /**
     * コンストラクタ
     */
    constructor(private http: HttpClient) {
    }

	// リソース破棄
    public Dispose = (): void => {
    }



    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public UpdatePaymentPointSettingHostingPoint = (requestData: PaymentPointSettingHostingDetailUpdatePointRequestHubDto, receiveCallBack: (data: PaymentPointSettingHostingDetailUpdatePointResponseHubDto) => void, receiveErrorCallBack: (data: PaymentPointSettingHostingDetailUpdatePointResponseHubDto) => void, noSessionCallBack: (data: PaymentPointSettingHostingDetailUpdatePointResponseHubDto) => void, completeCallBack: (data: PaymentPointSettingHostingDetailUpdatePointResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: PaymentPointSettingHostingDetailUpdatePointResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: PaymentPointSettingHostingDetailUpdatePointResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/InvoiceHub/UpdatePaymentPointSettingHostingPoint';

        let responseData: PaymentPointSettingHostingDetailUpdatePointResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('InvoiceHub', 'UpdatePaymentPointSettingHostingPoint', 'PaymentPointSettingHostingDetailUpdatePointRequestHubDto', 'PaymentPointSettingHostingDetailUpdatePointResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('InvoiceHub', 'UpdatePaymentPointSettingHostingPoint', 'PaymentPointSettingHostingDetailUpdatePointRequestHubDto', 'PaymentPointSettingHostingDetailUpdatePointResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('InvoiceHub', 'UpdatePaymentPointSettingHostingPoint', 'PaymentPointSettingHostingDetailUpdatePointRequestHubDto', 'PaymentPointSettingHostingDetailUpdatePointResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public DomainPaymentValidator = (requestData: DomainPaymnetValidatorRequestHubDto, receiveCallBack: (data: DomainPaymnetValidatorResponseHubDto) => void, receiveErrorCallBack: (data: DomainPaymnetValidatorResponseHubDto) => void, noSessionCallBack: (data: DomainPaymnetValidatorResponseHubDto) => void, completeCallBack: (data: DomainPaymnetValidatorResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainPaymnetValidatorResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainPaymnetValidatorResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/InvoiceHub/DomainPaymentValidator';

        let responseData: DomainPaymnetValidatorResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('InvoiceHub', 'DomainPaymentValidator', 'DomainPaymnetValidatorRequestHubDto', 'DomainPaymnetValidatorResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('InvoiceHub', 'DomainPaymentValidator', 'DomainPaymnetValidatorRequestHubDto', 'DomainPaymnetValidatorResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('InvoiceHub', 'DomainPaymentValidator', 'DomainPaymnetValidatorRequestHubDto', 'DomainPaymnetValidatorResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public InvoiceCancel = (requestData: InvoiceCancelRequestHubDto, receiveCallBack: (data: InvoiceCancelResponseHubDto) => void, receiveErrorCallBack: (data: InvoiceCancelResponseHubDto) => void, noSessionCallBack: (data: InvoiceCancelResponseHubDto) => void, completeCallBack: (data: InvoiceCancelResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: InvoiceCancelResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: InvoiceCancelResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/InvoiceHub/InvoiceCancel';

        let responseData: InvoiceCancelResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('InvoiceHub', 'InvoiceCancel', 'InvoiceCancelRequestHubDto', 'InvoiceCancelResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('InvoiceHub', 'InvoiceCancel', 'InvoiceCancelRequestHubDto', 'InvoiceCancelResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('InvoiceHub', 'InvoiceCancel', 'InvoiceCancelRequestHubDto', 'InvoiceCancelResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public InvoiceCancelableDomainHistoryList = (requestData: InvoiceDomainHistoryListRequestHubDto, receiveCallBack: (data: InvoiceDomainHistoryListResponseHubDto) => void, receiveErrorCallBack: (data: InvoiceDomainHistoryListResponseHubDto) => void, noSessionCallBack: (data: InvoiceDomainHistoryListResponseHubDto) => void, completeCallBack: (data: InvoiceDomainHistoryListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: InvoiceDomainHistoryListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: InvoiceDomainHistoryListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/InvoiceHub/InvoiceCancelableDomainHistoryList';

        let responseData: InvoiceDomainHistoryListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('InvoiceHub', 'InvoiceCancelableDomainHistoryList', 'InvoiceDomainHistoryListRequestHubDto', 'InvoiceDomainHistoryListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('InvoiceHub', 'InvoiceCancelableDomainHistoryList', 'InvoiceDomainHistoryListRequestHubDto', 'InvoiceDomainHistoryListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('InvoiceHub', 'InvoiceCancelableDomainHistoryList', 'InvoiceDomainHistoryListRequestHubDto', 'InvoiceDomainHistoryListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetPaymentLumpsumList = (receiveCallBack: (data: PaymentLumpsumGetListResponseHubDto) => void, receiveErrorCallBack: (data: PaymentLumpsumGetListResponseHubDto) => void, noSessionCallBack: (data: PaymentLumpsumGetListResponseHubDto) => void, completeCallBack: (data: PaymentLumpsumGetListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: PaymentLumpsumGetListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: PaymentLumpsumGetListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/InvoiceHub/GetPaymentLumpsumList';

        let responseData: PaymentLumpsumGetListResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('InvoiceHub', 'GetPaymentLumpsumList', null, 'PaymentLumpsumGetListResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('InvoiceHub', 'GetPaymentLumpsumList', null, 'PaymentLumpsumGetListResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('InvoiceHub', 'GetPaymentLumpsumList', null, 'PaymentLumpsumGetListResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetPaymentLumpsumListByCategory = (requestData: PaymentLumpsumGetListByCategoryRequestHubDto, receiveCallBack: (data: PaymentLumpsumGetListByCategoryResponseHubDto) => void, receiveErrorCallBack: (data: PaymentLumpsumGetListByCategoryResponseHubDto) => void, noSessionCallBack: (data: PaymentLumpsumGetListByCategoryResponseHubDto) => void, completeCallBack: (data: PaymentLumpsumGetListByCategoryResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: PaymentLumpsumGetListByCategoryResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: PaymentLumpsumGetListByCategoryResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/InvoiceHub/GetPaymentLumpsumListByCategory';

        let responseData: PaymentLumpsumGetListByCategoryResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('InvoiceHub', 'GetPaymentLumpsumListByCategory', 'PaymentLumpsumGetListByCategoryRequestHubDto', 'PaymentLumpsumGetListByCategoryResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('InvoiceHub', 'GetPaymentLumpsumListByCategory', 'PaymentLumpsumGetListByCategoryRequestHubDto', 'PaymentLumpsumGetListByCategoryResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('InvoiceHub', 'GetPaymentLumpsumListByCategory', 'PaymentLumpsumGetListByCategoryRequestHubDto', 'PaymentLumpsumGetListByCategoryResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ValidatePaymentLumpsumChangeToLumpsum = (requestData: PaymentLumpsumValidateChangeToLumpsumRequestHubDto, receiveCallBack: (data: PaymentLumpsumValidateChangeToLumpsumResponseHubDto) => void, receiveErrorCallBack: (data: PaymentLumpsumValidateChangeToLumpsumResponseHubDto) => void, noSessionCallBack: (data: PaymentLumpsumValidateChangeToLumpsumResponseHubDto) => void, completeCallBack: (data: PaymentLumpsumValidateChangeToLumpsumResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: PaymentLumpsumValidateChangeToLumpsumResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: PaymentLumpsumValidateChangeToLumpsumResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/InvoiceHub/ValidatePaymentLumpsumChangeToLumpsum';

        let responseData: PaymentLumpsumValidateChangeToLumpsumResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('InvoiceHub', 'ValidatePaymentLumpsumChangeToLumpsum', 'PaymentLumpsumValidateChangeToLumpsumRequestHubDto', 'PaymentLumpsumValidateChangeToLumpsumResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('InvoiceHub', 'ValidatePaymentLumpsumChangeToLumpsum', 'PaymentLumpsumValidateChangeToLumpsumRequestHubDto', 'PaymentLumpsumValidateChangeToLumpsumResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('InvoiceHub', 'ValidatePaymentLumpsumChangeToLumpsum', 'PaymentLumpsumValidateChangeToLumpsumRequestHubDto', 'PaymentLumpsumValidateChangeToLumpsumResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetPaymentLumpsumChangeToLumpsumDetails = (requestData: PaymentLumpsumGetChangeToLumpsumDetailsRequestHubDto, receiveCallBack: (data: PaymentLumpsumGetChangeToLumpsumDetailsResponseHubDto) => void, receiveErrorCallBack: (data: PaymentLumpsumGetChangeToLumpsumDetailsResponseHubDto) => void, noSessionCallBack: (data: PaymentLumpsumGetChangeToLumpsumDetailsResponseHubDto) => void, completeCallBack: (data: PaymentLumpsumGetChangeToLumpsumDetailsResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: PaymentLumpsumGetChangeToLumpsumDetailsResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: PaymentLumpsumGetChangeToLumpsumDetailsResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/InvoiceHub/GetPaymentLumpsumChangeToLumpsumDetails';

        let responseData: PaymentLumpsumGetChangeToLumpsumDetailsResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('InvoiceHub', 'GetPaymentLumpsumChangeToLumpsumDetails', 'PaymentLumpsumGetChangeToLumpsumDetailsRequestHubDto', 'PaymentLumpsumGetChangeToLumpsumDetailsResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('InvoiceHub', 'GetPaymentLumpsumChangeToLumpsumDetails', 'PaymentLumpsumGetChangeToLumpsumDetailsRequestHubDto', 'PaymentLumpsumGetChangeToLumpsumDetailsResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('InvoiceHub', 'GetPaymentLumpsumChangeToLumpsumDetails', 'PaymentLumpsumGetChangeToLumpsumDetailsRequestHubDto', 'PaymentLumpsumGetChangeToLumpsumDetailsResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetPaymentLumpsumChangeToLumpsumConfirms = (requestData: PaymentLumpsumChangeToLumpsumRequestHubDto, receiveCallBack: (data: PaymentLumpsumGetChangeToLumpsumConfirmsResponseHubDto) => void, receiveErrorCallBack: (data: PaymentLumpsumGetChangeToLumpsumConfirmsResponseHubDto) => void, noSessionCallBack: (data: PaymentLumpsumGetChangeToLumpsumConfirmsResponseHubDto) => void, completeCallBack: (data: PaymentLumpsumGetChangeToLumpsumConfirmsResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: PaymentLumpsumGetChangeToLumpsumConfirmsResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: PaymentLumpsumGetChangeToLumpsumConfirmsResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/InvoiceHub/GetPaymentLumpsumChangeToLumpsumConfirms';

        let responseData: PaymentLumpsumGetChangeToLumpsumConfirmsResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('InvoiceHub', 'GetPaymentLumpsumChangeToLumpsumConfirms', 'PaymentLumpsumChangeToLumpsumRequestHubDto', 'PaymentLumpsumGetChangeToLumpsumConfirmsResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('InvoiceHub', 'GetPaymentLumpsumChangeToLumpsumConfirms', 'PaymentLumpsumChangeToLumpsumRequestHubDto', 'PaymentLumpsumGetChangeToLumpsumConfirmsResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('InvoiceHub', 'GetPaymentLumpsumChangeToLumpsumConfirms', 'PaymentLumpsumChangeToLumpsumRequestHubDto', 'PaymentLumpsumGetChangeToLumpsumConfirmsResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public DoPaymentLumpsumChangeToLumpsumPayment = (requestData: PaymentLumpsumChangeToLumpsumRequestHubDto, receiveCallBack: (data: PaymentLumpsumChangeToLumpsumResponseHubDto) => void, receiveErrorCallBack: (data: PaymentLumpsumChangeToLumpsumResponseHubDto) => void, noSessionCallBack: (data: PaymentLumpsumChangeToLumpsumResponseHubDto) => void, completeCallBack: (data: PaymentLumpsumChangeToLumpsumResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: PaymentLumpsumChangeToLumpsumResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: PaymentLumpsumChangeToLumpsumResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/InvoiceHub/DoPaymentLumpsumChangeToLumpsumPayment';

        let responseData: PaymentLumpsumChangeToLumpsumResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('InvoiceHub', 'DoPaymentLumpsumChangeToLumpsumPayment', 'PaymentLumpsumChangeToLumpsumRequestHubDto', 'PaymentLumpsumChangeToLumpsumResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('InvoiceHub', 'DoPaymentLumpsumChangeToLumpsumPayment', 'PaymentLumpsumChangeToLumpsumRequestHubDto', 'PaymentLumpsumChangeToLumpsumResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('InvoiceHub', 'DoPaymentLumpsumChangeToLumpsumPayment', 'PaymentLumpsumChangeToLumpsumRequestHubDto', 'PaymentLumpsumChangeToLumpsumResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ValidatePaymentChangeAutoRenew = (requestData: PaymentLumpsumChangeAutoRenewRequestHubDto, receiveCallBack: (data: PaymentLumpsumValidateChangeAutoRenewResponseHubDto) => void, receiveErrorCallBack: (data: PaymentLumpsumValidateChangeAutoRenewResponseHubDto) => void, noSessionCallBack: (data: PaymentLumpsumValidateChangeAutoRenewResponseHubDto) => void, completeCallBack: (data: PaymentLumpsumValidateChangeAutoRenewResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: PaymentLumpsumValidateChangeAutoRenewResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: PaymentLumpsumValidateChangeAutoRenewResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/InvoiceHub/ValidatePaymentChangeAutoRenew';

        let responseData: PaymentLumpsumValidateChangeAutoRenewResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('InvoiceHub', 'ValidatePaymentChangeAutoRenew', 'PaymentLumpsumChangeAutoRenewRequestHubDto', 'PaymentLumpsumValidateChangeAutoRenewResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('InvoiceHub', 'ValidatePaymentChangeAutoRenew', 'PaymentLumpsumChangeAutoRenewRequestHubDto', 'PaymentLumpsumValidateChangeAutoRenewResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('InvoiceHub', 'ValidatePaymentChangeAutoRenew', 'PaymentLumpsumChangeAutoRenewRequestHubDto', 'PaymentLumpsumValidateChangeAutoRenewResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ValidatePaymentToggleAutoRenew = (requestData: PaymentLumpsumChangeAutoRenewToggleRequestHubDto, receiveCallBack: (data: PaymentLumpsumValidateChangeAutoRenewResponseHubDto) => void, receiveErrorCallBack: (data: PaymentLumpsumValidateChangeAutoRenewResponseHubDto) => void, noSessionCallBack: (data: PaymentLumpsumValidateChangeAutoRenewResponseHubDto) => void, completeCallBack: (data: PaymentLumpsumValidateChangeAutoRenewResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: PaymentLumpsumValidateChangeAutoRenewResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: PaymentLumpsumValidateChangeAutoRenewResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/InvoiceHub/ValidatePaymentToggleAutoRenew';

        let responseData: PaymentLumpsumValidateChangeAutoRenewResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('InvoiceHub', 'ValidatePaymentToggleAutoRenew', 'PaymentLumpsumChangeAutoRenewToggleRequestHubDto', 'PaymentLumpsumValidateChangeAutoRenewResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('InvoiceHub', 'ValidatePaymentToggleAutoRenew', 'PaymentLumpsumChangeAutoRenewToggleRequestHubDto', 'PaymentLumpsumValidateChangeAutoRenewResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('InvoiceHub', 'ValidatePaymentToggleAutoRenew', 'PaymentLumpsumChangeAutoRenewToggleRequestHubDto', 'PaymentLumpsumValidateChangeAutoRenewResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public DoPaymentChangeAutoRenew = (requestData: PaymentLumpsumChangeAutoRenewRequestHubDto, receiveCallBack: (data: PaymentLumpsumChangeAutoRenewResponseHubDto) => void, receiveErrorCallBack: (data: PaymentLumpsumChangeAutoRenewResponseHubDto) => void, noSessionCallBack: (data: PaymentLumpsumChangeAutoRenewResponseHubDto) => void, completeCallBack: (data: PaymentLumpsumChangeAutoRenewResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: PaymentLumpsumChangeAutoRenewResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: PaymentLumpsumChangeAutoRenewResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/InvoiceHub/DoPaymentChangeAutoRenew';

        let responseData: PaymentLumpsumChangeAutoRenewResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('InvoiceHub', 'DoPaymentChangeAutoRenew', 'PaymentLumpsumChangeAutoRenewRequestHubDto', 'PaymentLumpsumChangeAutoRenewResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('InvoiceHub', 'DoPaymentChangeAutoRenew', 'PaymentLumpsumChangeAutoRenewRequestHubDto', 'PaymentLumpsumChangeAutoRenewResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('InvoiceHub', 'DoPaymentChangeAutoRenew', 'PaymentLumpsumChangeAutoRenewRequestHubDto', 'PaymentLumpsumChangeAutoRenewResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public CheckInvoiceUnpaid = (requestData: InvoiceHistoryIssueListRequestHubDto, receiveCallBack: (data: UnpaidInvoiceResponseHubDto) => void, receiveErrorCallBack: (data: UnpaidInvoiceResponseHubDto) => void, noSessionCallBack: (data: UnpaidInvoiceResponseHubDto) => void, completeCallBack: (data: UnpaidInvoiceResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: UnpaidInvoiceResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: UnpaidInvoiceResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/InvoiceHub/CheckInvoiceUnpaid';

        let responseData: UnpaidInvoiceResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('InvoiceHub', 'CheckInvoiceUnpaid', 'InvoiceHistoryIssueListRequestHubDto', 'UnpaidInvoiceResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('InvoiceHub', 'CheckInvoiceUnpaid', 'InvoiceHistoryIssueListRequestHubDto', 'UnpaidInvoiceResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('InvoiceHub', 'CheckInvoiceUnpaid', 'InvoiceHistoryIssueListRequestHubDto', 'UnpaidInvoiceResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetBankNameList = (requestData: GetBankNameRequestHubDto, receiveCallBack: (data: GetBankNameResponseHubDto) => void, receiveErrorCallBack: (data: GetBankNameResponseHubDto) => void, noSessionCallBack: (data: GetBankNameResponseHubDto) => void, completeCallBack: (data: GetBankNameResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: GetBankNameResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: GetBankNameResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/InvoiceHub/GetBankNameList';

        let responseData: GetBankNameResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('InvoiceHub', 'GetBankNameList', 'GetBankNameRequestHubDto', 'GetBankNameResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('InvoiceHub', 'GetBankNameList', 'GetBankNameRequestHubDto', 'GetBankNameResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('InvoiceHub', 'GetBankNameList', 'GetBankNameRequestHubDto', 'GetBankNameResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetHostingAutoRenewMessageAsync = (receiveCallBack: (data: HostingAutoRenewMessageResponseHubDto) => void, receiveErrorCallBack: (data: HostingAutoRenewMessageResponseHubDto) => void, noSessionCallBack: (data: HostingAutoRenewMessageResponseHubDto) => void, completeCallBack: (data: HostingAutoRenewMessageResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: HostingAutoRenewMessageResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: HostingAutoRenewMessageResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/InvoiceHub/GetHostingAutoRenewMessageAsync';

        let responseData: HostingAutoRenewMessageResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('InvoiceHub', 'GetHostingAutoRenewMessageAsync', null, 'HostingAutoRenewMessageResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('InvoiceHub', 'GetHostingAutoRenewMessageAsync', null, 'HostingAutoRenewMessageResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('InvoiceHub', 'GetHostingAutoRenewMessageAsync', null, 'HostingAutoRenewMessageResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public CheckCreditCardInfoUpdateRequiredAsync = (receiveCallBack: (data: CheckCreditCardInfoUpdateRequiredResponseHubDto) => void, receiveErrorCallBack: (data: CheckCreditCardInfoUpdateRequiredResponseHubDto) => void, noSessionCallBack: (data: CheckCreditCardInfoUpdateRequiredResponseHubDto) => void, completeCallBack: (data: CheckCreditCardInfoUpdateRequiredResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: CheckCreditCardInfoUpdateRequiredResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: CheckCreditCardInfoUpdateRequiredResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/InvoiceHub/CheckCreditCardInfoUpdateRequiredAsync';

        let responseData: CheckCreditCardInfoUpdateRequiredResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('InvoiceHub', 'CheckCreditCardInfoUpdateRequiredAsync', null, 'CheckCreditCardInfoUpdateRequiredResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('InvoiceHub', 'CheckCreditCardInfoUpdateRequiredAsync', null, 'CheckCreditCardInfoUpdateRequiredResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('InvoiceHub', 'CheckCreditCardInfoUpdateRequiredAsync', null, 'CheckCreditCardInfoUpdateRequiredResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetRefundInvoiceHostingByPage = (requestData: RefundInvoiceListRequestHubDto, receiveCallBack: (data: InvoiceRefundByPageResponseHubDto) => void, receiveErrorCallBack: (data: InvoiceRefundByPageResponseHubDto) => void, noSessionCallBack: (data: InvoiceRefundByPageResponseHubDto) => void, completeCallBack: (data: InvoiceRefundByPageResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: InvoiceRefundByPageResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: InvoiceRefundByPageResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/InvoiceHub/GetRefundInvoiceHostingByPage';

        let responseData: InvoiceRefundByPageResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('InvoiceHub', 'GetRefundInvoiceHostingByPage', 'RefundInvoiceListRequestHubDto', 'InvoiceRefundByPageResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('InvoiceHub', 'GetRefundInvoiceHostingByPage', 'RefundInvoiceListRequestHubDto', 'InvoiceRefundByPageResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('InvoiceHub', 'GetRefundInvoiceHostingByPage', 'RefundInvoiceListRequestHubDto', 'InvoiceRefundByPageResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetHostingInvoiceSummaryInfo = (requestData: InvoiceHostingSummaryInfoRequestHubDto, receiveCallBack: (data: InvoiceDomainSummaryInfoResponseHubDto) => void, receiveErrorCallBack: (data: InvoiceDomainSummaryInfoResponseHubDto) => void, noSessionCallBack: (data: InvoiceDomainSummaryInfoResponseHubDto) => void, completeCallBack: (data: InvoiceDomainSummaryInfoResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: InvoiceDomainSummaryInfoResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: InvoiceDomainSummaryInfoResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/InvoiceHub/GetHostingInvoiceSummaryInfo';

        let responseData: InvoiceDomainSummaryInfoResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('InvoiceHub', 'GetHostingInvoiceSummaryInfo', 'InvoiceHostingSummaryInfoRequestHubDto', 'InvoiceDomainSummaryInfoResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('InvoiceHub', 'GetHostingInvoiceSummaryInfo', 'InvoiceHostingSummaryInfoRequestHubDto', 'InvoiceDomainSummaryInfoResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('InvoiceHub', 'GetHostingInvoiceSummaryInfo', 'InvoiceHostingSummaryInfoRequestHubDto', 'InvoiceDomainSummaryInfoResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public InvoiceDomainHistoryList = (requestData: InvoiceDomainHistoryListRequestHubDto, receiveCallBack: (data: InvoiceDomainHistoryListResponseHubDto) => void, receiveErrorCallBack: (data: InvoiceDomainHistoryListResponseHubDto) => void, noSessionCallBack: (data: InvoiceDomainHistoryListResponseHubDto) => void, completeCallBack: (data: InvoiceDomainHistoryListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: InvoiceDomainHistoryListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: InvoiceDomainHistoryListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/InvoiceHub/InvoiceDomainHistoryList';

        let responseData: InvoiceDomainHistoryListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('InvoiceHub', 'InvoiceDomainHistoryList', 'InvoiceDomainHistoryListRequestHubDto', 'InvoiceDomainHistoryListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('InvoiceHub', 'InvoiceDomainHistoryList', 'InvoiceDomainHistoryListRequestHubDto', 'InvoiceDomainHistoryListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('InvoiceHub', 'InvoiceDomainHistoryList', 'InvoiceDomainHistoryListRequestHubDto', 'InvoiceDomainHistoryListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public TotalInvoicePriceDomain = (receiveCallBack: (data: TotalInvoicePriceDomainResponseHubDto) => void, receiveErrorCallBack: (data: TotalInvoicePriceDomainResponseHubDto) => void, noSessionCallBack: (data: TotalInvoicePriceDomainResponseHubDto) => void, completeCallBack: (data: TotalInvoicePriceDomainResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: TotalInvoicePriceDomainResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: TotalInvoicePriceDomainResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/InvoiceHub/TotalInvoicePriceDomain';

        let responseData: TotalInvoicePriceDomainResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('InvoiceHub', 'TotalInvoicePriceDomain', null, 'TotalInvoicePriceDomainResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('InvoiceHub', 'TotalInvoicePriceDomain', null, 'TotalInvoicePriceDomainResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('InvoiceHub', 'TotalInvoicePriceDomain', null, 'TotalInvoicePriceDomainResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public RefundDomainHistoryList = (requestData: InvoiceDomainRefundHistoryListRequestHubDto, receiveCallBack: (data: InvoiceDomainHistoryListResponseHubDto) => void, receiveErrorCallBack: (data: InvoiceDomainHistoryListResponseHubDto) => void, noSessionCallBack: (data: InvoiceDomainHistoryListResponseHubDto) => void, completeCallBack: (data: InvoiceDomainHistoryListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: InvoiceDomainHistoryListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: InvoiceDomainHistoryListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/InvoiceHub/RefundDomainHistoryList';

        let responseData: InvoiceDomainHistoryListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('InvoiceHub', 'RefundDomainHistoryList', 'InvoiceDomainRefundHistoryListRequestHubDto', 'InvoiceDomainHistoryListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('InvoiceHub', 'RefundDomainHistoryList', 'InvoiceDomainRefundHistoryListRequestHubDto', 'InvoiceDomainHistoryListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('InvoiceHub', 'RefundDomainHistoryList', 'InvoiceDomainRefundHistoryListRequestHubDto', 'InvoiceDomainHistoryListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public InvoiceServerHistoryList = (requestData: InvoiceServerHistoryListRequestHubDto, receiveCallBack: (data: InvoiceServerHistoryListResponseHubDto) => void, receiveErrorCallBack: (data: InvoiceServerHistoryListResponseHubDto) => void, noSessionCallBack: (data: InvoiceServerHistoryListResponseHubDto) => void, completeCallBack: (data: InvoiceServerHistoryListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: InvoiceServerHistoryListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: InvoiceServerHistoryListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/InvoiceHub/InvoiceServerHistoryList';

        let responseData: InvoiceServerHistoryListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('InvoiceHub', 'InvoiceServerHistoryList', 'InvoiceServerHistoryListRequestHubDto', 'InvoiceServerHistoryListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('InvoiceHub', 'InvoiceServerHistoryList', 'InvoiceServerHistoryListRequestHubDto', 'InvoiceServerHistoryListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('InvoiceHub', 'InvoiceServerHistoryList', 'InvoiceServerHistoryListRequestHubDto', 'InvoiceServerHistoryListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public TotalInvoicePriceServer = (receiveCallBack: (data: TotalInvoicePriceServerResponseHubDto) => void, receiveErrorCallBack: (data: TotalInvoicePriceServerResponseHubDto) => void, noSessionCallBack: (data: TotalInvoicePriceServerResponseHubDto) => void, completeCallBack: (data: TotalInvoicePriceServerResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: TotalInvoicePriceServerResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: TotalInvoicePriceServerResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/InvoiceHub/TotalInvoicePriceServer';

        let responseData: TotalInvoicePriceServerResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('InvoiceHub', 'TotalInvoicePriceServer', null, 'TotalInvoicePriceServerResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('InvoiceHub', 'TotalInvoicePriceServer', null, 'TotalInvoicePriceServerResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('InvoiceHub', 'TotalInvoicePriceServer', null, 'TotalInvoicePriceServerResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public InvoiceDomainCreditCardDetail = (requestData: InvoiceDomainCreditCardDetailRequestHubDto, receiveCallBack: (data: InvoiceDomainCreditCardDetailResponseHubDto) => void, receiveErrorCallBack: (data: InvoiceDomainCreditCardDetailResponseHubDto) => void, noSessionCallBack: (data: InvoiceDomainCreditCardDetailResponseHubDto) => void, completeCallBack: (data: InvoiceDomainCreditCardDetailResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: InvoiceDomainCreditCardDetailResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: InvoiceDomainCreditCardDetailResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/InvoiceHub/InvoiceDomainCreditCardDetail';

        let responseData: InvoiceDomainCreditCardDetailResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('InvoiceHub', 'InvoiceDomainCreditCardDetail', 'InvoiceDomainCreditCardDetailRequestHubDto', 'InvoiceDomainCreditCardDetailResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('InvoiceHub', 'InvoiceDomainCreditCardDetail', 'InvoiceDomainCreditCardDetailRequestHubDto', 'InvoiceDomainCreditCardDetailResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('InvoiceHub', 'InvoiceDomainCreditCardDetail', 'InvoiceDomainCreditCardDetailRequestHubDto', 'InvoiceDomainCreditCardDetailResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public InvoiceDomainConvenienceDetail = (requestData: InvoiceDomainConvenienceDetailRequestHubDto, receiveCallBack: (data: InvoiceDomainConvenienceDetailResponseHubDto) => void, receiveErrorCallBack: (data: InvoiceDomainConvenienceDetailResponseHubDto) => void, noSessionCallBack: (data: InvoiceDomainConvenienceDetailResponseHubDto) => void, completeCallBack: (data: InvoiceDomainConvenienceDetailResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: InvoiceDomainConvenienceDetailResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: InvoiceDomainConvenienceDetailResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/InvoiceHub/InvoiceDomainConvenienceDetail';

        let responseData: InvoiceDomainConvenienceDetailResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('InvoiceHub', 'InvoiceDomainConvenienceDetail', 'InvoiceDomainConvenienceDetailRequestHubDto', 'InvoiceDomainConvenienceDetailResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('InvoiceHub', 'InvoiceDomainConvenienceDetail', 'InvoiceDomainConvenienceDetailRequestHubDto', 'InvoiceDomainConvenienceDetailResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('InvoiceHub', 'InvoiceDomainConvenienceDetail', 'InvoiceDomainConvenienceDetailRequestHubDto', 'InvoiceDomainConvenienceDetailResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public InvoiceDomainConvenienceDetailForPayee = (requestData: InvoiceDomainConvenienceDetailRequestHubDto, receiveCallBack: (data: InvoiceDomainConvenienceDetailResponseHubDto) => void, receiveErrorCallBack: (data: InvoiceDomainConvenienceDetailResponseHubDto) => void, noSessionCallBack: (data: InvoiceDomainConvenienceDetailResponseHubDto) => void, completeCallBack: (data: InvoiceDomainConvenienceDetailResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: InvoiceDomainConvenienceDetailResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: InvoiceDomainConvenienceDetailResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/InvoiceHub/InvoiceDomainConvenienceDetailForPayee';

        let responseData: InvoiceDomainConvenienceDetailResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('InvoiceHub', 'InvoiceDomainConvenienceDetailForPayee', 'InvoiceDomainConvenienceDetailRequestHubDto', 'InvoiceDomainConvenienceDetailResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('InvoiceHub', 'InvoiceDomainConvenienceDetailForPayee', 'InvoiceDomainConvenienceDetailRequestHubDto', 'InvoiceDomainConvenienceDetailResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('InvoiceHub', 'InvoiceDomainConvenienceDetailForPayee', 'InvoiceDomainConvenienceDetailRequestHubDto', 'InvoiceDomainConvenienceDetailResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public InvoiceDomainBankDetail = (requestData: InvoiceDomainBankDetailRequestHubDto, receiveCallBack: (data: InvoiceDomainBankDetailResponseHubDto) => void, receiveErrorCallBack: (data: InvoiceDomainBankDetailResponseHubDto) => void, noSessionCallBack: (data: InvoiceDomainBankDetailResponseHubDto) => void, completeCallBack: (data: InvoiceDomainBankDetailResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: InvoiceDomainBankDetailResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: InvoiceDomainBankDetailResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/InvoiceHub/InvoiceDomainBankDetail';

        let responseData: InvoiceDomainBankDetailResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('InvoiceHub', 'InvoiceDomainBankDetail', 'InvoiceDomainBankDetailRequestHubDto', 'InvoiceDomainBankDetailResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('InvoiceHub', 'InvoiceDomainBankDetail', 'InvoiceDomainBankDetailRequestHubDto', 'InvoiceDomainBankDetailResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('InvoiceHub', 'InvoiceDomainBankDetail', 'InvoiceDomainBankDetailRequestHubDto', 'InvoiceDomainBankDetailResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public InvoiceDomainMonthlyDetail = (requestData: InvoiceDomainMonthlyDetailRequestHubDto, receiveCallBack: (data: InvoiceDomainMonthlyDetailResponseHubDto) => void, receiveErrorCallBack: (data: InvoiceDomainMonthlyDetailResponseHubDto) => void, noSessionCallBack: (data: InvoiceDomainMonthlyDetailResponseHubDto) => void, completeCallBack: (data: InvoiceDomainMonthlyDetailResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: InvoiceDomainMonthlyDetailResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: InvoiceDomainMonthlyDetailResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/InvoiceHub/InvoiceDomainMonthlyDetail';

        let responseData: InvoiceDomainMonthlyDetailResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('InvoiceHub', 'InvoiceDomainMonthlyDetail', 'InvoiceDomainMonthlyDetailRequestHubDto', 'InvoiceDomainMonthlyDetailResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('InvoiceHub', 'InvoiceDomainMonthlyDetail', 'InvoiceDomainMonthlyDetailRequestHubDto', 'InvoiceDomainMonthlyDetailResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('InvoiceHub', 'InvoiceDomainMonthlyDetail', 'InvoiceDomainMonthlyDetailRequestHubDto', 'InvoiceDomainMonthlyDetailResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public InvoiceDomainAmazonPayDetail = (requestData: InvoiceDomainAmazonPayDetailRequestHubDto, receiveCallBack: (data: InvoiceDomainAmazonPayDetailResponseHubDto) => void, receiveErrorCallBack: (data: InvoiceDomainAmazonPayDetailResponseHubDto) => void, noSessionCallBack: (data: InvoiceDomainAmazonPayDetailResponseHubDto) => void, completeCallBack: (data: InvoiceDomainAmazonPayDetailResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: InvoiceDomainAmazonPayDetailResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: InvoiceDomainAmazonPayDetailResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/InvoiceHub/InvoiceDomainAmazonPayDetail';

        let responseData: InvoiceDomainAmazonPayDetailResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('InvoiceHub', 'InvoiceDomainAmazonPayDetail', 'InvoiceDomainAmazonPayDetailRequestHubDto', 'InvoiceDomainAmazonPayDetailResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('InvoiceHub', 'InvoiceDomainAmazonPayDetail', 'InvoiceDomainAmazonPayDetailRequestHubDto', 'InvoiceDomainAmazonPayDetailResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('InvoiceHub', 'InvoiceDomainAmazonPayDetail', 'InvoiceDomainAmazonPayDetailRequestHubDto', 'InvoiceDomainAmazonPayDetailResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public InvoiceDomainFreeDetail = (requestData: InvoiceDomainFreeDetailRequestHubDto, receiveCallBack: (data: InvoiceDomainFreeDetailResponseHubDto) => void, receiveErrorCallBack: (data: InvoiceDomainFreeDetailResponseHubDto) => void, noSessionCallBack: (data: InvoiceDomainFreeDetailResponseHubDto) => void, completeCallBack: (data: InvoiceDomainFreeDetailResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: InvoiceDomainFreeDetailResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: InvoiceDomainFreeDetailResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/InvoiceHub/InvoiceDomainFreeDetail';

        let responseData: InvoiceDomainFreeDetailResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('InvoiceHub', 'InvoiceDomainFreeDetail', 'InvoiceDomainFreeDetailRequestHubDto', 'InvoiceDomainFreeDetailResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('InvoiceHub', 'InvoiceDomainFreeDetail', 'InvoiceDomainFreeDetailRequestHubDto', 'InvoiceDomainFreeDetailResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('InvoiceHub', 'InvoiceDomainFreeDetail', 'InvoiceDomainFreeDetailRequestHubDto', 'InvoiceDomainFreeDetailResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public InvoiceDomainSummaryInfoForInvoice = (requestData: InvoiceDomainSummaryInfoRequestHubDto, receiveCallBack: (data: InvoiceDomainSummaryInfoResponseHubDto) => void, receiveErrorCallBack: (data: InvoiceDomainSummaryInfoResponseHubDto) => void, noSessionCallBack: (data: InvoiceDomainSummaryInfoResponseHubDto) => void, completeCallBack: (data: InvoiceDomainSummaryInfoResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: InvoiceDomainSummaryInfoResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: InvoiceDomainSummaryInfoResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/InvoiceHub/InvoiceDomainSummaryInfoForInvoice';

        let responseData: InvoiceDomainSummaryInfoResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('InvoiceHub', 'InvoiceDomainSummaryInfoForInvoice', 'InvoiceDomainSummaryInfoRequestHubDto', 'InvoiceDomainSummaryInfoResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('InvoiceHub', 'InvoiceDomainSummaryInfoForInvoice', 'InvoiceDomainSummaryInfoRequestHubDto', 'InvoiceDomainSummaryInfoResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('InvoiceHub', 'InvoiceDomainSummaryInfoForInvoice', 'InvoiceDomainSummaryInfoRequestHubDto', 'InvoiceDomainSummaryInfoResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public InvoiceDomainSummaryInfoForRefund = (requestData: InvoiceDomainSummaryInfoRequestHubDto, receiveCallBack: (data: InvoiceDomainSummaryInfoResponseHubDto) => void, receiveErrorCallBack: (data: InvoiceDomainSummaryInfoResponseHubDto) => void, noSessionCallBack: (data: InvoiceDomainSummaryInfoResponseHubDto) => void, completeCallBack: (data: InvoiceDomainSummaryInfoResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: InvoiceDomainSummaryInfoResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: InvoiceDomainSummaryInfoResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/InvoiceHub/InvoiceDomainSummaryInfoForRefund';

        let responseData: InvoiceDomainSummaryInfoResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('InvoiceHub', 'InvoiceDomainSummaryInfoForRefund', 'InvoiceDomainSummaryInfoRequestHubDto', 'InvoiceDomainSummaryInfoResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('InvoiceHub', 'InvoiceDomainSummaryInfoForRefund', 'InvoiceDomainSummaryInfoRequestHubDto', 'InvoiceDomainSummaryInfoResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('InvoiceHub', 'InvoiceDomainSummaryInfoForRefund', 'InvoiceDomainSummaryInfoRequestHubDto', 'InvoiceDomainSummaryInfoResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public InvoiceDomainInvoiceDetailList = (requestData: InvoiceDomainDetailListRequestHubDto, receiveCallBack: (data: InvoiceDomainDetailListResponseHubDto) => void, receiveErrorCallBack: (data: InvoiceDomainDetailListResponseHubDto) => void, noSessionCallBack: (data: InvoiceDomainDetailListResponseHubDto) => void, completeCallBack: (data: InvoiceDomainDetailListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: InvoiceDomainDetailListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: InvoiceDomainDetailListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/InvoiceHub/InvoiceDomainInvoiceDetailList';

        let responseData: InvoiceDomainDetailListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('InvoiceHub', 'InvoiceDomainInvoiceDetailList', 'InvoiceDomainDetailListRequestHubDto', 'InvoiceDomainDetailListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('InvoiceHub', 'InvoiceDomainInvoiceDetailList', 'InvoiceDomainDetailListRequestHubDto', 'InvoiceDomainDetailListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('InvoiceHub', 'InvoiceDomainInvoiceDetailList', 'InvoiceDomainDetailListRequestHubDto', 'InvoiceDomainDetailListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public InvoiceDomainDiscountDetailList = (requestData: InvoiceDomainDetailListRequestHubDto, receiveCallBack: (data: InvoiceDomainDetailListResponseHubDto) => void, receiveErrorCallBack: (data: InvoiceDomainDetailListResponseHubDto) => void, noSessionCallBack: (data: InvoiceDomainDetailListResponseHubDto) => void, completeCallBack: (data: InvoiceDomainDetailListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: InvoiceDomainDetailListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: InvoiceDomainDetailListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/InvoiceHub/InvoiceDomainDiscountDetailList';

        let responseData: InvoiceDomainDetailListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('InvoiceHub', 'InvoiceDomainDiscountDetailList', 'InvoiceDomainDetailListRequestHubDto', 'InvoiceDomainDetailListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('InvoiceHub', 'InvoiceDomainDiscountDetailList', 'InvoiceDomainDetailListRequestHubDto', 'InvoiceDomainDetailListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('InvoiceHub', 'InvoiceDomainDiscountDetailList', 'InvoiceDomainDetailListRequestHubDto', 'InvoiceDomainDetailListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public InvoiceDomainGetCreditCard = (receiveCallBack: (data: InvoiceDomainGetCreditCardResponseHubDto) => void, receiveErrorCallBack: (data: InvoiceDomainGetCreditCardResponseHubDto) => void, noSessionCallBack: (data: InvoiceDomainGetCreditCardResponseHubDto) => void, completeCallBack: (data: InvoiceDomainGetCreditCardResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: InvoiceDomainGetCreditCardResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: InvoiceDomainGetCreditCardResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/InvoiceHub/InvoiceDomainGetCreditCard';

        let responseData: InvoiceDomainGetCreditCardResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('InvoiceHub', 'InvoiceDomainGetCreditCard', null, 'InvoiceDomainGetCreditCardResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('InvoiceHub', 'InvoiceDomainGetCreditCard', null, 'InvoiceDomainGetCreditCardResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('InvoiceHub', 'InvoiceDomainGetCreditCard', null, 'InvoiceDomainGetCreditCardResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public InvoiceDomainChangePaymentNewCreditCard = (requestData: InvoiceDomainChangePaymentNewCreditCardRequestHubDto, receiveCallBack: (data: InvoiceDomainChangePaymentNewCreditCardResponseHubDto) => void, receiveErrorCallBack: (data: InvoiceDomainChangePaymentNewCreditCardResponseHubDto) => void, noSessionCallBack: (data: InvoiceDomainChangePaymentNewCreditCardResponseHubDto) => void, completeCallBack: (data: InvoiceDomainChangePaymentNewCreditCardResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: InvoiceDomainChangePaymentNewCreditCardResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: InvoiceDomainChangePaymentNewCreditCardResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/InvoiceHub/InvoiceDomainChangePaymentNewCreditCard';

        let responseData: InvoiceDomainChangePaymentNewCreditCardResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('InvoiceHub', 'InvoiceDomainChangePaymentNewCreditCard', 'InvoiceDomainChangePaymentNewCreditCardRequestHubDto', 'InvoiceDomainChangePaymentNewCreditCardResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('InvoiceHub', 'InvoiceDomainChangePaymentNewCreditCard', 'InvoiceDomainChangePaymentNewCreditCardRequestHubDto', 'InvoiceDomainChangePaymentNewCreditCardResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('InvoiceHub', 'InvoiceDomainChangePaymentNewCreditCard', 'InvoiceDomainChangePaymentNewCreditCardRequestHubDto', 'InvoiceDomainChangePaymentNewCreditCardResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public InvoiceDomainChangePaymentRegisteredCreditCard = (requestData: InvoiceDomainChangePaymentRegisteredCreditCardRequestHubDto, receiveCallBack: (data: InvoiceDomainChangePaymentRegisteredCreditCardResponseHubDto) => void, receiveErrorCallBack: (data: InvoiceDomainChangePaymentRegisteredCreditCardResponseHubDto) => void, noSessionCallBack: (data: InvoiceDomainChangePaymentRegisteredCreditCardResponseHubDto) => void, completeCallBack: (data: InvoiceDomainChangePaymentRegisteredCreditCardResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: InvoiceDomainChangePaymentRegisteredCreditCardResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: InvoiceDomainChangePaymentRegisteredCreditCardResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/InvoiceHub/InvoiceDomainChangePaymentRegisteredCreditCard';

        let responseData: InvoiceDomainChangePaymentRegisteredCreditCardResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('InvoiceHub', 'InvoiceDomainChangePaymentRegisteredCreditCard', 'InvoiceDomainChangePaymentRegisteredCreditCardRequestHubDto', 'InvoiceDomainChangePaymentRegisteredCreditCardResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('InvoiceHub', 'InvoiceDomainChangePaymentRegisteredCreditCard', 'InvoiceDomainChangePaymentRegisteredCreditCardRequestHubDto', 'InvoiceDomainChangePaymentRegisteredCreditCardResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('InvoiceHub', 'InvoiceDomainChangePaymentRegisteredCreditCard', 'InvoiceDomainChangePaymentRegisteredCreditCardRequestHubDto', 'InvoiceDomainChangePaymentRegisteredCreditCardResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public InvoiceDomainProductDetails = (requestData: InvoiceDomainProductDetailsRequestHubDto, receiveCallBack: (data: InvoiceDomainProductDetailsResponseHubDto) => void, receiveErrorCallBack: (data: InvoiceDomainProductDetailsResponseHubDto) => void, noSessionCallBack: (data: InvoiceDomainProductDetailsResponseHubDto) => void, completeCallBack: (data: InvoiceDomainProductDetailsResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: InvoiceDomainProductDetailsResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: InvoiceDomainProductDetailsResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/InvoiceHub/InvoiceDomainProductDetails';

        let responseData: InvoiceDomainProductDetailsResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('InvoiceHub', 'InvoiceDomainProductDetails', 'InvoiceDomainProductDetailsRequestHubDto', 'InvoiceDomainProductDetailsResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('InvoiceHub', 'InvoiceDomainProductDetails', 'InvoiceDomainProductDetailsRequestHubDto', 'InvoiceDomainProductDetailsResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('InvoiceHub', 'InvoiceDomainProductDetails', 'InvoiceDomainProductDetailsRequestHubDto', 'InvoiceDomainProductDetailsResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public InvoiceDomainPaymentConvenienceDetail = (requestData: InvoiceDomainPaymentConvenienceDetailRequestHubDto, receiveCallBack: (data: InvoiceDomainPaymentConvenienceDetailResponseHubDto) => void, receiveErrorCallBack: (data: InvoiceDomainPaymentConvenienceDetailResponseHubDto) => void, noSessionCallBack: (data: InvoiceDomainPaymentConvenienceDetailResponseHubDto) => void, completeCallBack: (data: InvoiceDomainPaymentConvenienceDetailResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: InvoiceDomainPaymentConvenienceDetailResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: InvoiceDomainPaymentConvenienceDetailResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/InvoiceHub/InvoiceDomainPaymentConvenienceDetail';

        let responseData: InvoiceDomainPaymentConvenienceDetailResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('InvoiceHub', 'InvoiceDomainPaymentConvenienceDetail', 'InvoiceDomainPaymentConvenienceDetailRequestHubDto', 'InvoiceDomainPaymentConvenienceDetailResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('InvoiceHub', 'InvoiceDomainPaymentConvenienceDetail', 'InvoiceDomainPaymentConvenienceDetailRequestHubDto', 'InvoiceDomainPaymentConvenienceDetailResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('InvoiceHub', 'InvoiceDomainPaymentConvenienceDetail', 'InvoiceDomainPaymentConvenienceDetailRequestHubDto', 'InvoiceDomainPaymentConvenienceDetailResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetPayeeBankInfo = (requestData: GetPayeeBankInfoRequestHubDto, receiveCallBack: (data: GetPayeeBankInfoResponseHubDto) => void, receiveErrorCallBack: (data: GetPayeeBankInfoResponseHubDto) => void, noSessionCallBack: (data: GetPayeeBankInfoResponseHubDto) => void, completeCallBack: (data: GetPayeeBankInfoResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: GetPayeeBankInfoResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: GetPayeeBankInfoResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/InvoiceHub/GetPayeeBankInfo';

        let responseData: GetPayeeBankInfoResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('InvoiceHub', 'GetPayeeBankInfo', 'GetPayeeBankInfoRequestHubDto', 'GetPayeeBankInfoResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('InvoiceHub', 'GetPayeeBankInfo', 'GetPayeeBankInfoRequestHubDto', 'GetPayeeBankInfoResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('InvoiceHub', 'GetPayeeBankInfo', 'GetPayeeBankInfoRequestHubDto', 'GetPayeeBankInfoResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetDomainPaymentInfo = (receiveCallBack: (data: InvoiceGetDomainPaymentInfoResponseHubDto) => void, receiveErrorCallBack: (data: InvoiceGetDomainPaymentInfoResponseHubDto) => void, noSessionCallBack: (data: InvoiceGetDomainPaymentInfoResponseHubDto) => void, completeCallBack: (data: InvoiceGetDomainPaymentInfoResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: InvoiceGetDomainPaymentInfoResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: InvoiceGetDomainPaymentInfoResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/InvoiceHub/GetDomainPaymentInfo';

        let responseData: InvoiceGetDomainPaymentInfoResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('InvoiceHub', 'GetDomainPaymentInfo', null, 'InvoiceGetDomainPaymentInfoResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('InvoiceHub', 'GetDomainPaymentInfo', null, 'InvoiceGetDomainPaymentInfoResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('InvoiceHub', 'GetDomainPaymentInfo', null, 'InvoiceGetDomainPaymentInfoResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public InvoiceServerCreditCardDetail = (requestData: InvoiceServerCreditCardDetailRequestHubDto, receiveCallBack: (data: InvoiceServerCreditCardDetailResponseHubDto) => void, receiveErrorCallBack: (data: InvoiceServerCreditCardDetailResponseHubDto) => void, noSessionCallBack: (data: InvoiceServerCreditCardDetailResponseHubDto) => void, completeCallBack: (data: InvoiceServerCreditCardDetailResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: InvoiceServerCreditCardDetailResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: InvoiceServerCreditCardDetailResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/InvoiceHub/InvoiceServerCreditCardDetail';

        let responseData: InvoiceServerCreditCardDetailResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('InvoiceHub', 'InvoiceServerCreditCardDetail', 'InvoiceServerCreditCardDetailRequestHubDto', 'InvoiceServerCreditCardDetailResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('InvoiceHub', 'InvoiceServerCreditCardDetail', 'InvoiceServerCreditCardDetailRequestHubDto', 'InvoiceServerCreditCardDetailResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('InvoiceHub', 'InvoiceServerCreditCardDetail', 'InvoiceServerCreditCardDetailRequestHubDto', 'InvoiceServerCreditCardDetailResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public InvoiceServerConvenienceDetail = (requestData: InvoiceServerConvenienceDetailRequestHubDto, receiveCallBack: (data: InvoiceServerConvenienceDetailResponseHubDto) => void, receiveErrorCallBack: (data: InvoiceServerConvenienceDetailResponseHubDto) => void, noSessionCallBack: (data: InvoiceServerConvenienceDetailResponseHubDto) => void, completeCallBack: (data: InvoiceServerConvenienceDetailResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: InvoiceServerConvenienceDetailResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: InvoiceServerConvenienceDetailResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/InvoiceHub/InvoiceServerConvenienceDetail';

        let responseData: InvoiceServerConvenienceDetailResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('InvoiceHub', 'InvoiceServerConvenienceDetail', 'InvoiceServerConvenienceDetailRequestHubDto', 'InvoiceServerConvenienceDetailResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('InvoiceHub', 'InvoiceServerConvenienceDetail', 'InvoiceServerConvenienceDetailRequestHubDto', 'InvoiceServerConvenienceDetailResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('InvoiceHub', 'InvoiceServerConvenienceDetail', 'InvoiceServerConvenienceDetailRequestHubDto', 'InvoiceServerConvenienceDetailResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public InvoiceServerBankTransferDetail = (requestData: InvoiceServerBankTransferDetailRequestHubDto, receiveCallBack: (data: InvoiceServerBankTransferDetailResponseHubDto) => void, receiveErrorCallBack: (data: InvoiceServerBankTransferDetailResponseHubDto) => void, noSessionCallBack: (data: InvoiceServerBankTransferDetailResponseHubDto) => void, completeCallBack: (data: InvoiceServerBankTransferDetailResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: InvoiceServerBankTransferDetailResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: InvoiceServerBankTransferDetailResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/InvoiceHub/InvoiceServerBankTransferDetail';

        let responseData: InvoiceServerBankTransferDetailResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('InvoiceHub', 'InvoiceServerBankTransferDetail', 'InvoiceServerBankTransferDetailRequestHubDto', 'InvoiceServerBankTransferDetailResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('InvoiceHub', 'InvoiceServerBankTransferDetail', 'InvoiceServerBankTransferDetailRequestHubDto', 'InvoiceServerBankTransferDetailResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('InvoiceHub', 'InvoiceServerBankTransferDetail', 'InvoiceServerBankTransferDetailRequestHubDto', 'InvoiceServerBankTransferDetailResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public InvoiceServerCvsDetail = (requestData: InvoiceServerCvsDetailRequestHubDto, receiveCallBack: (data: InvoiceServerCvsDetailResponseHubDto) => void, receiveErrorCallBack: (data: InvoiceServerCvsDetailResponseHubDto) => void, noSessionCallBack: (data: InvoiceServerCvsDetailResponseHubDto) => void, completeCallBack: (data: InvoiceServerCvsDetailResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: InvoiceServerCvsDetailResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: InvoiceServerCvsDetailResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/InvoiceHub/InvoiceServerCvsDetail';

        let responseData: InvoiceServerCvsDetailResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('InvoiceHub', 'InvoiceServerCvsDetail', 'InvoiceServerCvsDetailRequestHubDto', 'InvoiceServerCvsDetailResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('InvoiceHub', 'InvoiceServerCvsDetail', 'InvoiceServerCvsDetailRequestHubDto', 'InvoiceServerCvsDetailResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('InvoiceHub', 'InvoiceServerCvsDetail', 'InvoiceServerCvsDetailRequestHubDto', 'InvoiceServerCvsDetailResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public InvoiceServerBankDetail = (requestData: InvoiceServerBankDetailRequestHubDto, receiveCallBack: (data: InvoiceServerBankDetailResponseHubDto) => void, receiveErrorCallBack: (data: InvoiceServerBankDetailResponseHubDto) => void, noSessionCallBack: (data: InvoiceServerBankDetailResponseHubDto) => void, completeCallBack: (data: InvoiceServerBankDetailResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: InvoiceServerBankDetailResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: InvoiceServerBankDetailResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/InvoiceHub/InvoiceServerBankDetail';

        let responseData: InvoiceServerBankDetailResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('InvoiceHub', 'InvoiceServerBankDetail', 'InvoiceServerBankDetailRequestHubDto', 'InvoiceServerBankDetailResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('InvoiceHub', 'InvoiceServerBankDetail', 'InvoiceServerBankDetailRequestHubDto', 'InvoiceServerBankDetailResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('InvoiceHub', 'InvoiceServerBankDetail', 'InvoiceServerBankDetailRequestHubDto', 'InvoiceServerBankDetailResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public InvoiceServerAmazonPayDetail = (requestData: InvoiceServerAmazonPayDetailRequestHubDto, receiveCallBack: (data: InvoiceServerAmazonPayDetailResponseHubDto) => void, receiveErrorCallBack: (data: InvoiceServerAmazonPayDetailResponseHubDto) => void, noSessionCallBack: (data: InvoiceServerAmazonPayDetailResponseHubDto) => void, completeCallBack: (data: InvoiceServerAmazonPayDetailResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: InvoiceServerAmazonPayDetailResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: InvoiceServerAmazonPayDetailResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/InvoiceHub/InvoiceServerAmazonPayDetail';

        let responseData: InvoiceServerAmazonPayDetailResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('InvoiceHub', 'InvoiceServerAmazonPayDetail', 'InvoiceServerAmazonPayDetailRequestHubDto', 'InvoiceServerAmazonPayDetailResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('InvoiceHub', 'InvoiceServerAmazonPayDetail', 'InvoiceServerAmazonPayDetailRequestHubDto', 'InvoiceServerAmazonPayDetailResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('InvoiceHub', 'InvoiceServerAmazonPayDetail', 'InvoiceServerAmazonPayDetailRequestHubDto', 'InvoiceServerAmazonPayDetailResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public InvoiceServerRePayment = (requestData: InvoiceServerRePaymentRequestHubDto, receiveCallBack: (data: InvoiceServerRePaymentResponseHubDto) => void, receiveErrorCallBack: (data: InvoiceServerRePaymentResponseHubDto) => void, noSessionCallBack: (data: InvoiceServerRePaymentResponseHubDto) => void, completeCallBack: (data: InvoiceServerRePaymentResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: InvoiceServerRePaymentResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: InvoiceServerRePaymentResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/InvoiceHub/InvoiceServerRePayment';

        let responseData: InvoiceServerRePaymentResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('InvoiceHub', 'InvoiceServerRePayment', 'InvoiceServerRePaymentRequestHubDto', 'InvoiceServerRePaymentResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('InvoiceHub', 'InvoiceServerRePayment', 'InvoiceServerRePaymentRequestHubDto', 'InvoiceServerRePaymentResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('InvoiceHub', 'InvoiceServerRePayment', 'InvoiceServerRePaymentRequestHubDto', 'InvoiceServerRePaymentResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public InvoiceServerProductDetails = (requestData: InvoiceServerProductDetailsRequestHubDto, receiveCallBack: (data: InvoiceServerProductDetailsResponseHubDto) => void, receiveErrorCallBack: (data: InvoiceServerProductDetailsResponseHubDto) => void, noSessionCallBack: (data: InvoiceServerProductDetailsResponseHubDto) => void, completeCallBack: (data: InvoiceServerProductDetailsResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: InvoiceServerProductDetailsResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: InvoiceServerProductDetailsResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/InvoiceHub/InvoiceServerProductDetails';

        let responseData: InvoiceServerProductDetailsResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('InvoiceHub', 'InvoiceServerProductDetails', 'InvoiceServerProductDetailsRequestHubDto', 'InvoiceServerProductDetailsResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('InvoiceHub', 'InvoiceServerProductDetails', 'InvoiceServerProductDetailsRequestHubDto', 'InvoiceServerProductDetailsResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('InvoiceHub', 'InvoiceServerProductDetails', 'InvoiceServerProductDetailsRequestHubDto', 'InvoiceServerProductDetailsResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public InvoiceServerProductDetailsInvoiceStyle = (requestData: InvoiceServerProductDetailsInvoiceStyleRequestHubDto, receiveCallBack: (data: InvoiceServerProductDetailsInvoiceStyleResponseHubDto) => void, receiveErrorCallBack: (data: InvoiceServerProductDetailsInvoiceStyleResponseHubDto) => void, noSessionCallBack: (data: InvoiceServerProductDetailsInvoiceStyleResponseHubDto) => void, completeCallBack: (data: InvoiceServerProductDetailsInvoiceStyleResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: InvoiceServerProductDetailsInvoiceStyleResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: InvoiceServerProductDetailsInvoiceStyleResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/InvoiceHub/InvoiceServerProductDetailsInvoiceStyle';

        let responseData: InvoiceServerProductDetailsInvoiceStyleResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('InvoiceHub', 'InvoiceServerProductDetailsInvoiceStyle', 'InvoiceServerProductDetailsInvoiceStyleRequestHubDto', 'InvoiceServerProductDetailsInvoiceStyleResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('InvoiceHub', 'InvoiceServerProductDetailsInvoiceStyle', 'InvoiceServerProductDetailsInvoiceStyleRequestHubDto', 'InvoiceServerProductDetailsInvoiceStyleResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('InvoiceHub', 'InvoiceServerProductDetailsInvoiceStyle', 'InvoiceServerProductDetailsInvoiceStyleRequestHubDto', 'InvoiceServerProductDetailsInvoiceStyleResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public InvoiceCvsServerProductDetails = (requestData: InvoiceServerProductDetailsRequestHubDto, receiveCallBack: (data: InvoiceServerProductDetailsResponseHubDto) => void, receiveErrorCallBack: (data: InvoiceServerProductDetailsResponseHubDto) => void, noSessionCallBack: (data: InvoiceServerProductDetailsResponseHubDto) => void, completeCallBack: (data: InvoiceServerProductDetailsResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: InvoiceServerProductDetailsResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: InvoiceServerProductDetailsResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/InvoiceHub/InvoiceCvsServerProductDetails';

        let responseData: InvoiceServerProductDetailsResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('InvoiceHub', 'InvoiceCvsServerProductDetails', 'InvoiceServerProductDetailsRequestHubDto', 'InvoiceServerProductDetailsResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('InvoiceHub', 'InvoiceCvsServerProductDetails', 'InvoiceServerProductDetailsRequestHubDto', 'InvoiceServerProductDetailsResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('InvoiceHub', 'InvoiceCvsServerProductDetails', 'InvoiceServerProductDetailsRequestHubDto', 'InvoiceServerProductDetailsResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public InvoiceCvsServerProductDetailsInvoiceStyle = (requestData: InvoiceServerProductDetailsInvoiceStyleRequestHubDto, receiveCallBack: (data: InvoiceServerProductDetailsInvoiceStyleResponseHubDto) => void, receiveErrorCallBack: (data: InvoiceServerProductDetailsInvoiceStyleResponseHubDto) => void, noSessionCallBack: (data: InvoiceServerProductDetailsInvoiceStyleResponseHubDto) => void, completeCallBack: (data: InvoiceServerProductDetailsInvoiceStyleResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: InvoiceServerProductDetailsInvoiceStyleResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: InvoiceServerProductDetailsInvoiceStyleResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/InvoiceHub/InvoiceCvsServerProductDetailsInvoiceStyle';

        let responseData: InvoiceServerProductDetailsInvoiceStyleResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('InvoiceHub', 'InvoiceCvsServerProductDetailsInvoiceStyle', 'InvoiceServerProductDetailsInvoiceStyleRequestHubDto', 'InvoiceServerProductDetailsInvoiceStyleResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('InvoiceHub', 'InvoiceCvsServerProductDetailsInvoiceStyle', 'InvoiceServerProductDetailsInvoiceStyleRequestHubDto', 'InvoiceServerProductDetailsInvoiceStyleResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('InvoiceHub', 'InvoiceCvsServerProductDetailsInvoiceStyle', 'InvoiceServerProductDetailsInvoiceStyleRequestHubDto', 'InvoiceServerProductDetailsInvoiceStyleResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public InvoiceServerPaymentConvenienceDetail = (requestData: InvoiceServerPaymentConvenienceDetailRequestHubDto, receiveCallBack: (data: InvoiceServerPaymentConvenienceDetailResponseHubDto) => void, receiveErrorCallBack: (data: InvoiceServerPaymentConvenienceDetailResponseHubDto) => void, noSessionCallBack: (data: InvoiceServerPaymentConvenienceDetailResponseHubDto) => void, completeCallBack: (data: InvoiceServerPaymentConvenienceDetailResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: InvoiceServerPaymentConvenienceDetailResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: InvoiceServerPaymentConvenienceDetailResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/InvoiceHub/InvoiceServerPaymentConvenienceDetail';

        let responseData: InvoiceServerPaymentConvenienceDetailResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('InvoiceHub', 'InvoiceServerPaymentConvenienceDetail', 'InvoiceServerPaymentConvenienceDetailRequestHubDto', 'InvoiceServerPaymentConvenienceDetailResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('InvoiceHub', 'InvoiceServerPaymentConvenienceDetail', 'InvoiceServerPaymentConvenienceDetailRequestHubDto', 'InvoiceServerPaymentConvenienceDetailResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('InvoiceHub', 'InvoiceServerPaymentConvenienceDetail', 'InvoiceServerPaymentConvenienceDetailRequestHubDto', 'InvoiceServerPaymentConvenienceDetailResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public InvoiceCvsServerPaymentDetail = (requestData: InvoiceServerPaymentConvenienceDetailRequestHubDto, receiveCallBack: (data: InvoiceServerPaymentConvenienceDetailResponseHubDto) => void, receiveErrorCallBack: (data: InvoiceServerPaymentConvenienceDetailResponseHubDto) => void, noSessionCallBack: (data: InvoiceServerPaymentConvenienceDetailResponseHubDto) => void, completeCallBack: (data: InvoiceServerPaymentConvenienceDetailResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: InvoiceServerPaymentConvenienceDetailResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: InvoiceServerPaymentConvenienceDetailResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/InvoiceHub/InvoiceCvsServerPaymentDetail';

        let responseData: InvoiceServerPaymentConvenienceDetailResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('InvoiceHub', 'InvoiceCvsServerPaymentDetail', 'InvoiceServerPaymentConvenienceDetailRequestHubDto', 'InvoiceServerPaymentConvenienceDetailResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('InvoiceHub', 'InvoiceCvsServerPaymentDetail', 'InvoiceServerPaymentConvenienceDetailRequestHubDto', 'InvoiceServerPaymentConvenienceDetailResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('InvoiceHub', 'InvoiceCvsServerPaymentDetail', 'InvoiceServerPaymentConvenienceDetailRequestHubDto', 'InvoiceServerPaymentConvenienceDetailResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public InvoiceServerPaymentConvenienceReacquisition = (requestData: InvoiceServerPaymentConvenienceReacquisitionRequestHubDto, receiveCallBack: (data: InvoiceServerPaymentConvenienceReacquisitionResponseHubDto) => void, receiveErrorCallBack: (data: InvoiceServerPaymentConvenienceReacquisitionResponseHubDto) => void, noSessionCallBack: (data: InvoiceServerPaymentConvenienceReacquisitionResponseHubDto) => void, completeCallBack: (data: InvoiceServerPaymentConvenienceReacquisitionResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: InvoiceServerPaymentConvenienceReacquisitionResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: InvoiceServerPaymentConvenienceReacquisitionResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/InvoiceHub/InvoiceServerPaymentConvenienceReacquisition';

        let responseData: InvoiceServerPaymentConvenienceReacquisitionResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('InvoiceHub', 'InvoiceServerPaymentConvenienceReacquisition', 'InvoiceServerPaymentConvenienceReacquisitionRequestHubDto', 'InvoiceServerPaymentConvenienceReacquisitionResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('InvoiceHub', 'InvoiceServerPaymentConvenienceReacquisition', 'InvoiceServerPaymentConvenienceReacquisitionRequestHubDto', 'InvoiceServerPaymentConvenienceReacquisitionResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('InvoiceHub', 'InvoiceServerPaymentConvenienceReacquisition', 'InvoiceServerPaymentConvenienceReacquisitionRequestHubDto', 'InvoiceServerPaymentConvenienceReacquisitionResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public InvoiceCvsServerPaymentReacquisition = (requestData: InvoiceServerPaymentConvenienceReacquisitionRequestHubDto, receiveCallBack: (data: InvoiceServerPaymentConvenienceReacquisitionResponseHubDto) => void, receiveErrorCallBack: (data: InvoiceServerPaymentConvenienceReacquisitionResponseHubDto) => void, noSessionCallBack: (data: InvoiceServerPaymentConvenienceReacquisitionResponseHubDto) => void, completeCallBack: (data: InvoiceServerPaymentConvenienceReacquisitionResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: InvoiceServerPaymentConvenienceReacquisitionResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: InvoiceServerPaymentConvenienceReacquisitionResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/InvoiceHub/InvoiceCvsServerPaymentReacquisition';

        let responseData: InvoiceServerPaymentConvenienceReacquisitionResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('InvoiceHub', 'InvoiceCvsServerPaymentReacquisition', 'InvoiceServerPaymentConvenienceReacquisitionRequestHubDto', 'InvoiceServerPaymentConvenienceReacquisitionResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('InvoiceHub', 'InvoiceCvsServerPaymentReacquisition', 'InvoiceServerPaymentConvenienceReacquisitionRequestHubDto', 'InvoiceServerPaymentConvenienceReacquisitionResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('InvoiceHub', 'InvoiceCvsServerPaymentReacquisition', 'InvoiceServerPaymentConvenienceReacquisitionRequestHubDto', 'InvoiceServerPaymentConvenienceReacquisitionResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public InvoiceDomainHistoryIssueList = (requestData: InvoiceDomainHistoryIssueListRequestHubDto, receiveCallBack: (data: InvoiceDomainHistoryIssueListResponseHubDto) => void, receiveErrorCallBack: (data: InvoiceDomainHistoryIssueListResponseHubDto) => void, noSessionCallBack: (data: InvoiceDomainHistoryIssueListResponseHubDto) => void, completeCallBack: (data: InvoiceDomainHistoryIssueListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: InvoiceDomainHistoryIssueListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: InvoiceDomainHistoryIssueListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/InvoiceHub/InvoiceDomainHistoryIssueList';

        let responseData: InvoiceDomainHistoryIssueListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('InvoiceHub', 'InvoiceDomainHistoryIssueList', 'InvoiceDomainHistoryIssueListRequestHubDto', 'InvoiceDomainHistoryIssueListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('InvoiceHub', 'InvoiceDomainHistoryIssueList', 'InvoiceDomainHistoryIssueListRequestHubDto', 'InvoiceDomainHistoryIssueListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('InvoiceHub', 'InvoiceDomainHistoryIssueList', 'InvoiceDomainHistoryIssueListRequestHubDto', 'InvoiceDomainHistoryIssueListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public InvoiceDomainIssueSendMail = (requestData: InvoiceDomainIssueSendMailRequestHubDto, receiveCallBack: (data: InvoiceDomainIssueSendMailResponseHubDto) => void, receiveErrorCallBack: (data: InvoiceDomainIssueSendMailResponseHubDto) => void, noSessionCallBack: (data: InvoiceDomainIssueSendMailResponseHubDto) => void, completeCallBack: (data: InvoiceDomainIssueSendMailResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: InvoiceDomainIssueSendMailResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: InvoiceDomainIssueSendMailResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/InvoiceHub/InvoiceDomainIssueSendMail';

        let responseData: InvoiceDomainIssueSendMailResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('InvoiceHub', 'InvoiceDomainIssueSendMail', 'InvoiceDomainIssueSendMailRequestHubDto', 'InvoiceDomainIssueSendMailResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('InvoiceHub', 'InvoiceDomainIssueSendMail', 'InvoiceDomainIssueSendMailRequestHubDto', 'InvoiceDomainIssueSendMailResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('InvoiceHub', 'InvoiceDomainIssueSendMail', 'InvoiceDomainIssueSendMailRequestHubDto', 'InvoiceDomainIssueSendMailResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public RefundDomainHistoryIssueList = (requestData: InvoiceDomainRefundHistoryListRequestHubDto, receiveCallBack: (data: InvoiceDomainHistoryListResponseHubDto) => void, receiveErrorCallBack: (data: InvoiceDomainHistoryListResponseHubDto) => void, noSessionCallBack: (data: InvoiceDomainHistoryListResponseHubDto) => void, completeCallBack: (data: InvoiceDomainHistoryListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: InvoiceDomainHistoryListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: InvoiceDomainHistoryListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/InvoiceHub/RefundDomainHistoryIssueList';

        let responseData: InvoiceDomainHistoryListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('InvoiceHub', 'RefundDomainHistoryIssueList', 'InvoiceDomainRefundHistoryListRequestHubDto', 'InvoiceDomainHistoryListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('InvoiceHub', 'RefundDomainHistoryIssueList', 'InvoiceDomainRefundHistoryListRequestHubDto', 'InvoiceDomainHistoryListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('InvoiceHub', 'RefundDomainHistoryIssueList', 'InvoiceDomainRefundHistoryListRequestHubDto', 'InvoiceDomainHistoryListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public InvoiceServerHistoryIssueList = (requestData: InvoiceServerHistoryIssueListRequestHubDto, receiveCallBack: (data: InvoiceServerHistoryIssueListResponseHubDto) => void, receiveErrorCallBack: (data: InvoiceServerHistoryIssueListResponseHubDto) => void, noSessionCallBack: (data: InvoiceServerHistoryIssueListResponseHubDto) => void, completeCallBack: (data: InvoiceServerHistoryIssueListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: InvoiceServerHistoryIssueListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: InvoiceServerHistoryIssueListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/InvoiceHub/InvoiceServerHistoryIssueList';

        let responseData: InvoiceServerHistoryIssueListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('InvoiceHub', 'InvoiceServerHistoryIssueList', 'InvoiceServerHistoryIssueListRequestHubDto', 'InvoiceServerHistoryIssueListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('InvoiceHub', 'InvoiceServerHistoryIssueList', 'InvoiceServerHistoryIssueListRequestHubDto', 'InvoiceServerHistoryIssueListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('InvoiceHub', 'InvoiceServerHistoryIssueList', 'InvoiceServerHistoryIssueListRequestHubDto', 'InvoiceServerHistoryIssueListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public InvoiceServerIssueSendMail = (requestData: InvoiceServerIssueSendMailRequestHubDto, receiveCallBack: (data: InvoiceServerIssueSendMailResponseHubDto) => void, receiveErrorCallBack: (data: InvoiceServerIssueSendMailResponseHubDto) => void, noSessionCallBack: (data: InvoiceServerIssueSendMailResponseHubDto) => void, completeCallBack: (data: InvoiceServerIssueSendMailResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: InvoiceServerIssueSendMailResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: InvoiceServerIssueSendMailResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/InvoiceHub/InvoiceServerIssueSendMail';

        let responseData: InvoiceServerIssueSendMailResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('InvoiceHub', 'InvoiceServerIssueSendMail', 'InvoiceServerIssueSendMailRequestHubDto', 'InvoiceServerIssueSendMailResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('InvoiceHub', 'InvoiceServerIssueSendMail', 'InvoiceServerIssueSendMailRequestHubDto', 'InvoiceServerIssueSendMailResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('InvoiceHub', 'InvoiceServerIssueSendMail', 'InvoiceServerIssueSendMailRequestHubDto', 'InvoiceServerIssueSendMailResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public InvoiceDomainHistoryResendMailList = (requestData: InvoiceDomainHistoryResendMailListRequestHubDto, receiveCallBack: (data: InvoiceDomainHistoryResendMailListResponseHubDto) => void, receiveErrorCallBack: (data: InvoiceDomainHistoryResendMailListResponseHubDto) => void, noSessionCallBack: (data: InvoiceDomainHistoryResendMailListResponseHubDto) => void, completeCallBack: (data: InvoiceDomainHistoryResendMailListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: InvoiceDomainHistoryResendMailListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: InvoiceDomainHistoryResendMailListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/InvoiceHub/InvoiceDomainHistoryResendMailList';

        let responseData: InvoiceDomainHistoryResendMailListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('InvoiceHub', 'InvoiceDomainHistoryResendMailList', 'InvoiceDomainHistoryResendMailListRequestHubDto', 'InvoiceDomainHistoryResendMailListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('InvoiceHub', 'InvoiceDomainHistoryResendMailList', 'InvoiceDomainHistoryResendMailListRequestHubDto', 'InvoiceDomainHistoryResendMailListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('InvoiceHub', 'InvoiceDomainHistoryResendMailList', 'InvoiceDomainHistoryResendMailListRequestHubDto', 'InvoiceDomainHistoryResendMailListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public InvoiceDomainResendMail = (requestData: InvoiceDomainResendMailRequestHubDto, receiveCallBack: (data: InvoiceDomainResendMailResponseHubDto) => void, receiveErrorCallBack: (data: InvoiceDomainResendMailResponseHubDto) => void, noSessionCallBack: (data: InvoiceDomainResendMailResponseHubDto) => void, completeCallBack: (data: InvoiceDomainResendMailResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: InvoiceDomainResendMailResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: InvoiceDomainResendMailResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/InvoiceHub/InvoiceDomainResendMail';

        let responseData: InvoiceDomainResendMailResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('InvoiceHub', 'InvoiceDomainResendMail', 'InvoiceDomainResendMailRequestHubDto', 'InvoiceDomainResendMailResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('InvoiceHub', 'InvoiceDomainResendMail', 'InvoiceDomainResendMailRequestHubDto', 'InvoiceDomainResendMailResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('InvoiceHub', 'InvoiceDomainResendMail', 'InvoiceDomainResendMailRequestHubDto', 'InvoiceDomainResendMailResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public RefundDomainHistoryResendMailList = (requestData: InvoiceDomainRefundHistoryListRequestHubDto, receiveCallBack: (data: InvoiceDomainHistoryListResponseHubDto) => void, receiveErrorCallBack: (data: InvoiceDomainHistoryListResponseHubDto) => void, noSessionCallBack: (data: InvoiceDomainHistoryListResponseHubDto) => void, completeCallBack: (data: InvoiceDomainHistoryListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: InvoiceDomainHistoryListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: InvoiceDomainHistoryListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/InvoiceHub/RefundDomainHistoryResendMailList';

        let responseData: InvoiceDomainHistoryListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('InvoiceHub', 'RefundDomainHistoryResendMailList', 'InvoiceDomainRefundHistoryListRequestHubDto', 'InvoiceDomainHistoryListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('InvoiceHub', 'RefundDomainHistoryResendMailList', 'InvoiceDomainRefundHistoryListRequestHubDto', 'InvoiceDomainHistoryListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('InvoiceHub', 'RefundDomainHistoryResendMailList', 'InvoiceDomainRefundHistoryListRequestHubDto', 'InvoiceDomainHistoryListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public InvoiceServerHistoryResendMailList = (requestData: InvoiceServerHistoryResendMailListRequestHubDto, receiveCallBack: (data: InvoiceServerHistoryResendMailListResponseHubDto) => void, receiveErrorCallBack: (data: InvoiceServerHistoryResendMailListResponseHubDto) => void, noSessionCallBack: (data: InvoiceServerHistoryResendMailListResponseHubDto) => void, completeCallBack: (data: InvoiceServerHistoryResendMailListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: InvoiceServerHistoryResendMailListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: InvoiceServerHistoryResendMailListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/InvoiceHub/InvoiceServerHistoryResendMailList';

        let responseData: InvoiceServerHistoryResendMailListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('InvoiceHub', 'InvoiceServerHistoryResendMailList', 'InvoiceServerHistoryResendMailListRequestHubDto', 'InvoiceServerHistoryResendMailListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('InvoiceHub', 'InvoiceServerHistoryResendMailList', 'InvoiceServerHistoryResendMailListRequestHubDto', 'InvoiceServerHistoryResendMailListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('InvoiceHub', 'InvoiceServerHistoryResendMailList', 'InvoiceServerHistoryResendMailListRequestHubDto', 'InvoiceServerHistoryResendMailListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public InvoiceServerResendMail = (requestData: InvoiceServerResendMailRequestHubDto, receiveCallBack: (data: InvoiceServerResendMailResponseHubDto) => void, receiveErrorCallBack: (data: InvoiceServerResendMailResponseHubDto) => void, noSessionCallBack: (data: InvoiceServerResendMailResponseHubDto) => void, completeCallBack: (data: InvoiceServerResendMailResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: InvoiceServerResendMailResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: InvoiceServerResendMailResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/InvoiceHub/InvoiceServerResendMail';

        let responseData: InvoiceServerResendMailResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('InvoiceHub', 'InvoiceServerResendMail', 'InvoiceServerResendMailRequestHubDto', 'InvoiceServerResendMailResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('InvoiceHub', 'InvoiceServerResendMail', 'InvoiceServerResendMailRequestHubDto', 'InvoiceServerResendMailResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('InvoiceHub', 'InvoiceServerResendMail', 'InvoiceServerResendMailRequestHubDto', 'InvoiceServerResendMailResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public InvoiceLiquidateCreditCardDetail = (requestData: InvoiceLiquidateCreditCardDetailRequestHubDto, receiveCallBack: (data: InvoiceLiquidateCreditCardDetailResponseHubDto) => void, receiveErrorCallBack: (data: InvoiceLiquidateCreditCardDetailResponseHubDto) => void, noSessionCallBack: (data: InvoiceLiquidateCreditCardDetailResponseHubDto) => void, completeCallBack: (data: InvoiceLiquidateCreditCardDetailResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: InvoiceLiquidateCreditCardDetailResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: InvoiceLiquidateCreditCardDetailResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/InvoiceHub/InvoiceLiquidateCreditCardDetail';

        let responseData: InvoiceLiquidateCreditCardDetailResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('InvoiceHub', 'InvoiceLiquidateCreditCardDetail', 'InvoiceLiquidateCreditCardDetailRequestHubDto', 'InvoiceLiquidateCreditCardDetailResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('InvoiceHub', 'InvoiceLiquidateCreditCardDetail', 'InvoiceLiquidateCreditCardDetailRequestHubDto', 'InvoiceLiquidateCreditCardDetailResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('InvoiceHub', 'InvoiceLiquidateCreditCardDetail', 'InvoiceLiquidateCreditCardDetailRequestHubDto', 'InvoiceLiquidateCreditCardDetailResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public InvoiceLiquidateProductDetails = (requestData: InvoiceLiquidateProductDetailsRequestHubDto, receiveCallBack: (data: InvoiceLiquidateProductDetailsResponseHubDto) => void, receiveErrorCallBack: (data: InvoiceLiquidateProductDetailsResponseHubDto) => void, noSessionCallBack: (data: InvoiceLiquidateProductDetailsResponseHubDto) => void, completeCallBack: (data: InvoiceLiquidateProductDetailsResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: InvoiceLiquidateProductDetailsResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: InvoiceLiquidateProductDetailsResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/InvoiceHub/InvoiceLiquidateProductDetails';

        let responseData: InvoiceLiquidateProductDetailsResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('InvoiceHub', 'InvoiceLiquidateProductDetails', 'InvoiceLiquidateProductDetailsRequestHubDto', 'InvoiceLiquidateProductDetailsResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('InvoiceHub', 'InvoiceLiquidateProductDetails', 'InvoiceLiquidateProductDetailsRequestHubDto', 'InvoiceLiquidateProductDetailsResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('InvoiceHub', 'InvoiceLiquidateProductDetails', 'InvoiceLiquidateProductDetailsRequestHubDto', 'InvoiceLiquidateProductDetailsResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public InvoiceAdjustment = (requestData: InvoiceAdjustmentRequestHubDto, receiveCallBack: (data: InvoiceAdjustmentResponseHubDto) => void, receiveErrorCallBack: (data: InvoiceAdjustmentResponseHubDto) => void, noSessionCallBack: (data: InvoiceAdjustmentResponseHubDto) => void, completeCallBack: (data: InvoiceAdjustmentResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: InvoiceAdjustmentResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: InvoiceAdjustmentResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/InvoiceHub/InvoiceAdjustment';

        let responseData: InvoiceAdjustmentResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('InvoiceHub', 'InvoiceAdjustment', 'InvoiceAdjustmentRequestHubDto', 'InvoiceAdjustmentResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('InvoiceHub', 'InvoiceAdjustment', 'InvoiceAdjustmentRequestHubDto', 'InvoiceAdjustmentResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('InvoiceHub', 'InvoiceAdjustment', 'InvoiceAdjustmentRequestHubDto', 'InvoiceAdjustmentResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public InvoiceDomainUpdateCreditCard = (requestData: InvoiceDomainUpdateCreditCardRequestHubDto, receiveCallBack: (data: InvoiceDomainUpdateCreditCardResponseHubDto) => void, receiveErrorCallBack: (data: InvoiceDomainUpdateCreditCardResponseHubDto) => void, noSessionCallBack: (data: InvoiceDomainUpdateCreditCardResponseHubDto) => void, completeCallBack: (data: InvoiceDomainUpdateCreditCardResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: InvoiceDomainUpdateCreditCardResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: InvoiceDomainUpdateCreditCardResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/InvoiceHub/InvoiceDomainUpdateCreditCard';

        let responseData: InvoiceDomainUpdateCreditCardResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('InvoiceHub', 'InvoiceDomainUpdateCreditCard', 'InvoiceDomainUpdateCreditCardRequestHubDto', 'InvoiceDomainUpdateCreditCardResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('InvoiceHub', 'InvoiceDomainUpdateCreditCard', 'InvoiceDomainUpdateCreditCardRequestHubDto', 'InvoiceDomainUpdateCreditCardResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('InvoiceHub', 'InvoiceDomainUpdateCreditCard', 'InvoiceDomainUpdateCreditCardRequestHubDto', 'InvoiceDomainUpdateCreditCardResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public HostingBillContactInfoDetails = (receiveCallBack: (data: InvoiceHostingBillContactResponseHubDto) => void, receiveErrorCallBack: (data: InvoiceHostingBillContactResponseHubDto) => void, noSessionCallBack: (data: InvoiceHostingBillContactResponseHubDto) => void, completeCallBack: (data: InvoiceHostingBillContactResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: InvoiceHostingBillContactResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: InvoiceHostingBillContactResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/InvoiceHub/HostingBillContactInfoDetails';

        let responseData: InvoiceHostingBillContactResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('InvoiceHub', 'HostingBillContactInfoDetails', null, 'InvoiceHostingBillContactResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('InvoiceHub', 'HostingBillContactInfoDetails', null, 'InvoiceHostingBillContactResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('InvoiceHub', 'HostingBillContactInfoDetails', null, 'InvoiceHostingBillContactResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public UpdateHostingBillContact = (requestData: InvoiceHostingUpdateBillContactRequestHubDto, receiveCallBack: (data: InvoiceHostingUpdateBillContactResponseHubDto) => void, receiveErrorCallBack: (data: InvoiceHostingUpdateBillContactResponseHubDto) => void, noSessionCallBack: (data: InvoiceHostingUpdateBillContactResponseHubDto) => void, completeCallBack: (data: InvoiceHostingUpdateBillContactResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: InvoiceHostingUpdateBillContactResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: InvoiceHostingUpdateBillContactResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/InvoiceHub/UpdateHostingBillContact';

        let responseData: InvoiceHostingUpdateBillContactResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('InvoiceHub', 'UpdateHostingBillContact', 'InvoiceHostingUpdateBillContactRequestHubDto', 'InvoiceHostingUpdateBillContactResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('InvoiceHub', 'UpdateHostingBillContact', 'InvoiceHostingUpdateBillContactRequestHubDto', 'InvoiceHostingUpdateBillContactResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('InvoiceHub', 'UpdateHostingBillContact', 'InvoiceHostingUpdateBillContactRequestHubDto', 'InvoiceHostingUpdateBillContactResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ValidateHostingBillingContact = (requestData: ValidateHostingBillingContactRequestHubDto, receiveCallBack: (data: ValidateBillingContactResultListResponseHubDto) => void, receiveErrorCallBack: (data: ValidateBillingContactResultListResponseHubDto) => void, noSessionCallBack: (data: ValidateBillingContactResultListResponseHubDto) => void, completeCallBack: (data: ValidateBillingContactResultListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: ValidateBillingContactResultListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: ValidateBillingContactResultListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/InvoiceHub/ValidateHostingBillingContact';

        let responseData: ValidateBillingContactResultListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('InvoiceHub', 'ValidateHostingBillingContact', 'ValidateHostingBillingContactRequestHubDto', 'ValidateBillingContactResultListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('InvoiceHub', 'ValidateHostingBillingContact', 'ValidateHostingBillingContactRequestHubDto', 'ValidateBillingContactResultListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('InvoiceHub', 'ValidateHostingBillingContact', 'ValidateHostingBillingContactRequestHubDto', 'ValidateBillingContactResultListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public InvoiceDomainPaymentUpdateCreditCard = (requestData: InvoiceDomainPaymentUpdateCreditCardRequestHubDto, receiveCallBack: (data: InvoiceDomainPaymentUpdateCreditCardResponseHubDto) => void, receiveErrorCallBack: (data: InvoiceDomainPaymentUpdateCreditCardResponseHubDto) => void, noSessionCallBack: (data: InvoiceDomainPaymentUpdateCreditCardResponseHubDto) => void, completeCallBack: (data: InvoiceDomainPaymentUpdateCreditCardResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: InvoiceDomainPaymentUpdateCreditCardResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: InvoiceDomainPaymentUpdateCreditCardResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/InvoiceHub/InvoiceDomainPaymentUpdateCreditCard';

        let responseData: InvoiceDomainPaymentUpdateCreditCardResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('InvoiceHub', 'InvoiceDomainPaymentUpdateCreditCard', 'InvoiceDomainPaymentUpdateCreditCardRequestHubDto', 'InvoiceDomainPaymentUpdateCreditCardResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('InvoiceHub', 'InvoiceDomainPaymentUpdateCreditCard', 'InvoiceDomainPaymentUpdateCreditCardRequestHubDto', 'InvoiceDomainPaymentUpdateCreditCardResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('InvoiceHub', 'InvoiceDomainPaymentUpdateCreditCard', 'InvoiceDomainPaymentUpdateCreditCardRequestHubDto', 'InvoiceDomainPaymentUpdateCreditCardResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public InvoiceDomainPaymentDeleteCreditCard = (receiveCallBack: (data: InvoiceDomainPaymentDeleteCreditCardResponseHubDto) => void, receiveErrorCallBack: (data: InvoiceDomainPaymentDeleteCreditCardResponseHubDto) => void, noSessionCallBack: (data: InvoiceDomainPaymentDeleteCreditCardResponseHubDto) => void, completeCallBack: (data: InvoiceDomainPaymentDeleteCreditCardResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: InvoiceDomainPaymentDeleteCreditCardResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: InvoiceDomainPaymentDeleteCreditCardResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/InvoiceHub/InvoiceDomainPaymentDeleteCreditCard';

        let responseData: InvoiceDomainPaymentDeleteCreditCardResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('InvoiceHub', 'InvoiceDomainPaymentDeleteCreditCard', null, 'InvoiceDomainPaymentDeleteCreditCardResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('InvoiceHub', 'InvoiceDomainPaymentDeleteCreditCard', null, 'InvoiceDomainPaymentDeleteCreditCardResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('InvoiceHub', 'InvoiceDomainPaymentDeleteCreditCard', null, 'InvoiceDomainPaymentDeleteCreditCardResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public InvoiceDomainGetAutoRenewPaymentType = (receiveCallBack: (data: InvoiceDomainGetAutoRenewPaymentTypeResponseHubDto) => void, receiveErrorCallBack: (data: InvoiceDomainGetAutoRenewPaymentTypeResponseHubDto) => void, noSessionCallBack: (data: InvoiceDomainGetAutoRenewPaymentTypeResponseHubDto) => void, completeCallBack: (data: InvoiceDomainGetAutoRenewPaymentTypeResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: InvoiceDomainGetAutoRenewPaymentTypeResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: InvoiceDomainGetAutoRenewPaymentTypeResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/InvoiceHub/InvoiceDomainGetAutoRenewPaymentType';

        let responseData: InvoiceDomainGetAutoRenewPaymentTypeResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('InvoiceHub', 'InvoiceDomainGetAutoRenewPaymentType', null, 'InvoiceDomainGetAutoRenewPaymentTypeResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('InvoiceHub', 'InvoiceDomainGetAutoRenewPaymentType', null, 'InvoiceDomainGetAutoRenewPaymentTypeResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('InvoiceHub', 'InvoiceDomainGetAutoRenewPaymentType', null, 'InvoiceDomainGetAutoRenewPaymentTypeResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public InvoiceDomainSetAutoRenewPaymentType = (requestData: InvoiceDomainSetAutoRenewPaymentTypeRequestHubDto, receiveCallBack: (data: InvoiceDomainSetAutoRenewPaymentTypeResponseHubDto) => void, receiveErrorCallBack: (data: InvoiceDomainSetAutoRenewPaymentTypeResponseHubDto) => void, noSessionCallBack: (data: InvoiceDomainSetAutoRenewPaymentTypeResponseHubDto) => void, completeCallBack: (data: InvoiceDomainSetAutoRenewPaymentTypeResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: InvoiceDomainSetAutoRenewPaymentTypeResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: InvoiceDomainSetAutoRenewPaymentTypeResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/InvoiceHub/InvoiceDomainSetAutoRenewPaymentType';

        let responseData: InvoiceDomainSetAutoRenewPaymentTypeResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('InvoiceHub', 'InvoiceDomainSetAutoRenewPaymentType', 'InvoiceDomainSetAutoRenewPaymentTypeRequestHubDto', 'InvoiceDomainSetAutoRenewPaymentTypeResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('InvoiceHub', 'InvoiceDomainSetAutoRenewPaymentType', 'InvoiceDomainSetAutoRenewPaymentTypeRequestHubDto', 'InvoiceDomainSetAutoRenewPaymentTypeResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('InvoiceHub', 'InvoiceDomainSetAutoRenewPaymentType', 'InvoiceDomainSetAutoRenewPaymentTypeRequestHubDto', 'InvoiceDomainSetAutoRenewPaymentTypeResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public InvoiceHostingPaymentDetail = (receiveCallBack: (data: InvoiceHostingPaymentDetailResponseHubDto) => void, receiveErrorCallBack: (data: InvoiceHostingPaymentDetailResponseHubDto) => void, noSessionCallBack: (data: InvoiceHostingPaymentDetailResponseHubDto) => void, completeCallBack: (data: InvoiceHostingPaymentDetailResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: InvoiceHostingPaymentDetailResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: InvoiceHostingPaymentDetailResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/InvoiceHub/InvoiceHostingPaymentDetail';

        let responseData: InvoiceHostingPaymentDetailResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('InvoiceHub', 'InvoiceHostingPaymentDetail', null, 'InvoiceHostingPaymentDetailResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('InvoiceHub', 'InvoiceHostingPaymentDetail', null, 'InvoiceHostingPaymentDetailResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('InvoiceHub', 'InvoiceHostingPaymentDetail', null, 'InvoiceHostingPaymentDetailResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public InvoiceHostingChangePaymentInfo = (receiveCallBack: (data: ChangePaymentMethodInfoResponseHubDto) => void, receiveErrorCallBack: (data: ChangePaymentMethodInfoResponseHubDto) => void, noSessionCallBack: (data: ChangePaymentMethodInfoResponseHubDto) => void, completeCallBack: (data: ChangePaymentMethodInfoResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: ChangePaymentMethodInfoResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: ChangePaymentMethodInfoResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/InvoiceHub/InvoiceHostingChangePaymentInfo';

        let responseData: ChangePaymentMethodInfoResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('InvoiceHub', 'InvoiceHostingChangePaymentInfo', null, 'ChangePaymentMethodInfoResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('InvoiceHub', 'InvoiceHostingChangePaymentInfo', null, 'ChangePaymentMethodInfoResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('InvoiceHub', 'InvoiceHostingChangePaymentInfo', null, 'ChangePaymentMethodInfoResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public UpdateInvoiceHostingPayment = (requestData: UpdateHostingPaymentMethodRequestHubDto, receiveCallBack: (data: UpdateHostingPaymentMethodResponseHubDto) => void, receiveErrorCallBack: (data: UpdateHostingPaymentMethodResponseHubDto) => void, noSessionCallBack: (data: UpdateHostingPaymentMethodResponseHubDto) => void, completeCallBack: (data: UpdateHostingPaymentMethodResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: UpdateHostingPaymentMethodResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: UpdateHostingPaymentMethodResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/InvoiceHub/UpdateInvoiceHostingPayment';

        let responseData: UpdateHostingPaymentMethodResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('InvoiceHub', 'UpdateInvoiceHostingPayment', 'UpdateHostingPaymentMethodRequestHubDto', 'UpdateHostingPaymentMethodResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('InvoiceHub', 'UpdateInvoiceHostingPayment', 'UpdateHostingPaymentMethodRequestHubDto', 'UpdateHostingPaymentMethodResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('InvoiceHub', 'UpdateInvoiceHostingPayment', 'UpdateHostingPaymentMethodRequestHubDto', 'UpdateHostingPaymentMethodResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public UpdateInvoiceCreditCardHostingPayment = (requestData: UpdateInvoiceCreditCardHostingPaymentRequestHubDto, receiveCallBack: (data: UpdateInvoiceCreditCardHostingPaymentResponseHubDto) => void, receiveErrorCallBack: (data: UpdateInvoiceCreditCardHostingPaymentResponseHubDto) => void, noSessionCallBack: (data: UpdateInvoiceCreditCardHostingPaymentResponseHubDto) => void, completeCallBack: (data: UpdateInvoiceCreditCardHostingPaymentResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: UpdateInvoiceCreditCardHostingPaymentResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: UpdateInvoiceCreditCardHostingPaymentResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/InvoiceHub/UpdateInvoiceCreditCardHostingPayment';

        let responseData: UpdateInvoiceCreditCardHostingPaymentResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('InvoiceHub', 'UpdateInvoiceCreditCardHostingPayment', 'UpdateInvoiceCreditCardHostingPaymentRequestHubDto', 'UpdateInvoiceCreditCardHostingPaymentResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('InvoiceHub', 'UpdateInvoiceCreditCardHostingPayment', 'UpdateInvoiceCreditCardHostingPaymentRequestHubDto', 'UpdateInvoiceCreditCardHostingPaymentResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('InvoiceHub', 'UpdateInvoiceCreditCardHostingPayment', 'UpdateInvoiceCreditCardHostingPaymentRequestHubDto', 'UpdateInvoiceCreditCardHostingPaymentResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ValidateInputPaymentInfo = (requestData: ValidateHostingPaymentMethodRequestHubDto, receiveCallBack: (data: ValidateHostingPaymentMethodResponseHubDto) => void, receiveErrorCallBack: (data: ValidateHostingPaymentMethodResponseHubDto) => void, noSessionCallBack: (data: ValidateHostingPaymentMethodResponseHubDto) => void, completeCallBack: (data: ValidateHostingPaymentMethodResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: ValidateHostingPaymentMethodResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: ValidateHostingPaymentMethodResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/InvoiceHub/ValidateInputPaymentInfo';

        let responseData: ValidateHostingPaymentMethodResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('InvoiceHub', 'ValidateInputPaymentInfo', 'ValidateHostingPaymentMethodRequestHubDto', 'ValidateHostingPaymentMethodResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('InvoiceHub', 'ValidateInputPaymentInfo', 'ValidateHostingPaymentMethodRequestHubDto', 'ValidateHostingPaymentMethodResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('InvoiceHub', 'ValidateInputPaymentInfo', 'ValidateHostingPaymentMethodRequestHubDto', 'ValidateHostingPaymentMethodResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetPaymentPointSettingHostingDetail = (requestData: PaymentPointSettingHostingDetailRequestHubDto, receiveCallBack: (data: PaymentPointSettingHostingDetailResponseHubDto) => void, receiveErrorCallBack: (data: PaymentPointSettingHostingDetailResponseHubDto) => void, noSessionCallBack: (data: PaymentPointSettingHostingDetailResponseHubDto) => void, completeCallBack: (data: PaymentPointSettingHostingDetailResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: PaymentPointSettingHostingDetailResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: PaymentPointSettingHostingDetailResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/InvoiceHub/GetPaymentPointSettingHostingDetail';

        let responseData: PaymentPointSettingHostingDetailResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('InvoiceHub', 'GetPaymentPointSettingHostingDetail', 'PaymentPointSettingHostingDetailRequestHubDto', 'PaymentPointSettingHostingDetailResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('InvoiceHub', 'GetPaymentPointSettingHostingDetail', 'PaymentPointSettingHostingDetailRequestHubDto', 'PaymentPointSettingHostingDetailResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('InvoiceHub', 'GetPaymentPointSettingHostingDetail', 'PaymentPointSettingHostingDetailRequestHubDto', 'PaymentPointSettingHostingDetailResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }

}



export class DesktopCloudHubManager {
    /**
     * コンストラクタ
     */
    constructor(private http: HttpClient) {
    }

	// リソース破棄
    public Dispose = (): void => {
    }



    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public DesktopCloudList = (requestData: DesktopCloudListRequestHubDto, receiveCallBack: (data: DesktopCloudListResponseHubDto) => void, receiveErrorCallBack: (data: DesktopCloudListResponseHubDto) => void, noSessionCallBack: (data: DesktopCloudListResponseHubDto) => void, completeCallBack: (data: DesktopCloudListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DesktopCloudListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DesktopCloudListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DesktopCloudHub/DesktopCloudList';

        let responseData: DesktopCloudListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DesktopCloudHub', 'DesktopCloudList', 'DesktopCloudListRequestHubDto', 'DesktopCloudListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DesktopCloudHub', 'DesktopCloudList', 'DesktopCloudListRequestHubDto', 'DesktopCloudListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DesktopCloudHub', 'DesktopCloudList', 'DesktopCloudListRequestHubDto', 'DesktopCloudListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetDesktopCloudDetail = (requestData: DesktopCloudDetailRequestHubDto, receiveCallBack: (data: DesktopCloudDetailResponseHubDto) => void, receiveErrorCallBack: (data: DesktopCloudDetailResponseHubDto) => void, noSessionCallBack: (data: DesktopCloudDetailResponseHubDto) => void, completeCallBack: (data: DesktopCloudDetailResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DesktopCloudDetailResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DesktopCloudDetailResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DesktopCloudHub/GetDesktopCloudDetail';

        let responseData: DesktopCloudDetailResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DesktopCloudHub', 'GetDesktopCloudDetail', 'DesktopCloudDetailRequestHubDto', 'DesktopCloudDetailResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DesktopCloudHub', 'GetDesktopCloudDetail', 'DesktopCloudDetailRequestHubDto', 'DesktopCloudDetailResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DesktopCloudHub', 'GetDesktopCloudDetail', 'DesktopCloudDetailRequestHubDto', 'DesktopCloudDetailResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetDesktopCloudDetailControlPanel = (requestData: DesktopCloudDetailControlPanelRequestHubDto, receiveCallBack: (data: DesktopCloudDetailControlPanelResponseHubDto) => void, receiveErrorCallBack: (data: DesktopCloudDetailControlPanelResponseHubDto) => void, noSessionCallBack: (data: DesktopCloudDetailControlPanelResponseHubDto) => void, completeCallBack: (data: DesktopCloudDetailControlPanelResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DesktopCloudDetailControlPanelResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DesktopCloudDetailControlPanelResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DesktopCloudHub/GetDesktopCloudDetailControlPanel';

        let responseData: DesktopCloudDetailControlPanelResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DesktopCloudHub', 'GetDesktopCloudDetailControlPanel', 'DesktopCloudDetailControlPanelRequestHubDto', 'DesktopCloudDetailControlPanelResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DesktopCloudHub', 'GetDesktopCloudDetailControlPanel', 'DesktopCloudDetailControlPanelRequestHubDto', 'DesktopCloudDetailControlPanelResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DesktopCloudHub', 'GetDesktopCloudDetailControlPanel', 'DesktopCloudDetailControlPanelRequestHubDto', 'DesktopCloudDetailControlPanelResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetDesktopCloudDetailRemoteDesktop = (requestData: DesktopCloudDetailRemoteDesktopRequestHubDto, receiveCallBack: (data: DesktopCloudDetailRemoteDesktopResponseHubDto) => void, receiveErrorCallBack: (data: DesktopCloudDetailRemoteDesktopResponseHubDto) => void, noSessionCallBack: (data: DesktopCloudDetailRemoteDesktopResponseHubDto) => void, completeCallBack: (data: DesktopCloudDetailRemoteDesktopResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DesktopCloudDetailRemoteDesktopResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DesktopCloudDetailRemoteDesktopResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DesktopCloudHub/GetDesktopCloudDetailRemoteDesktop';

        let responseData: DesktopCloudDetailRemoteDesktopResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DesktopCloudHub', 'GetDesktopCloudDetailRemoteDesktop', 'DesktopCloudDetailRemoteDesktopRequestHubDto', 'DesktopCloudDetailRemoteDesktopResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DesktopCloudHub', 'GetDesktopCloudDetailRemoteDesktop', 'DesktopCloudDetailRemoteDesktopRequestHubDto', 'DesktopCloudDetailRemoteDesktopResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DesktopCloudHub', 'GetDesktopCloudDetailRemoteDesktop', 'DesktopCloudDetailRemoteDesktopRequestHubDto', 'DesktopCloudDetailRemoteDesktopResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetDesktopCloud2DetailRemoteDesktop = (requestData: DesktopCloudDetailRemoteDesktopRequestHubDto, receiveCallBack: (data: DesktopCloud2DetailRemoteDesktopResponseHubDto) => void, receiveErrorCallBack: (data: DesktopCloud2DetailRemoteDesktopResponseHubDto) => void, noSessionCallBack: (data: DesktopCloud2DetailRemoteDesktopResponseHubDto) => void, completeCallBack: (data: DesktopCloud2DetailRemoteDesktopResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DesktopCloud2DetailRemoteDesktopResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DesktopCloud2DetailRemoteDesktopResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DesktopCloudHub/GetDesktopCloud2DetailRemoteDesktop';

        let responseData: DesktopCloud2DetailRemoteDesktopResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DesktopCloudHub', 'GetDesktopCloud2DetailRemoteDesktop', 'DesktopCloudDetailRemoteDesktopRequestHubDto', 'DesktopCloud2DetailRemoteDesktopResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DesktopCloudHub', 'GetDesktopCloud2DetailRemoteDesktop', 'DesktopCloudDetailRemoteDesktopRequestHubDto', 'DesktopCloud2DetailRemoteDesktopResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DesktopCloudHub', 'GetDesktopCloud2DetailRemoteDesktop', 'DesktopCloudDetailRemoteDesktopRequestHubDto', 'DesktopCloud2DetailRemoteDesktopResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetDesktopCloudDetailOption = (requestData: DesktopCloudDetailOptionRequestHubDto, receiveCallBack: (data: DesktopCloudDetailOptionResponseHubDto) => void, receiveErrorCallBack: (data: DesktopCloudDetailOptionResponseHubDto) => void, noSessionCallBack: (data: DesktopCloudDetailOptionResponseHubDto) => void, completeCallBack: (data: DesktopCloudDetailOptionResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DesktopCloudDetailOptionResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DesktopCloudDetailOptionResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DesktopCloudHub/GetDesktopCloudDetailOption';

        let responseData: DesktopCloudDetailOptionResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DesktopCloudHub', 'GetDesktopCloudDetailOption', 'DesktopCloudDetailOptionRequestHubDto', 'DesktopCloudDetailOptionResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DesktopCloudHub', 'GetDesktopCloudDetailOption', 'DesktopCloudDetailOptionRequestHubDto', 'DesktopCloudDetailOptionResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DesktopCloudHub', 'GetDesktopCloudDetailOption', 'DesktopCloudDetailOptionRequestHubDto', 'DesktopCloudDetailOptionResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public RevokeDesktopCloudDetailCancelPlan = (requestData: DesktopCloudDetailCancelPlanRevokeRequestHubDto, receiveCallBack: (data: DesktopCloudDetailCancelPlanRevokeResponseHubDto) => void, receiveErrorCallBack: (data: DesktopCloudDetailCancelPlanRevokeResponseHubDto) => void, noSessionCallBack: (data: DesktopCloudDetailCancelPlanRevokeResponseHubDto) => void, completeCallBack: (data: DesktopCloudDetailCancelPlanRevokeResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DesktopCloudDetailCancelPlanRevokeResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DesktopCloudDetailCancelPlanRevokeResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DesktopCloudHub/RevokeDesktopCloudDetailCancelPlan';

        let responseData: DesktopCloudDetailCancelPlanRevokeResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DesktopCloudHub', 'RevokeDesktopCloudDetailCancelPlan', 'DesktopCloudDetailCancelPlanRevokeRequestHubDto', 'DesktopCloudDetailCancelPlanRevokeResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DesktopCloudHub', 'RevokeDesktopCloudDetailCancelPlan', 'DesktopCloudDetailCancelPlanRevokeRequestHubDto', 'DesktopCloudDetailCancelPlanRevokeResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DesktopCloudHub', 'RevokeDesktopCloudDetailCancelPlan', 'DesktopCloudDetailCancelPlanRevokeRequestHubDto', 'DesktopCloudDetailCancelPlanRevokeResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetDesktopCloudDetailCancelOptionDetail = (requestData: DesktopCloudDetailCancelOptionDetailRequestHubDto, receiveCallBack: (data: DesktopCloudDetailCancelOptionDetailResponseHubDto) => void, receiveErrorCallBack: (data: DesktopCloudDetailCancelOptionDetailResponseHubDto) => void, noSessionCallBack: (data: DesktopCloudDetailCancelOptionDetailResponseHubDto) => void, completeCallBack: (data: DesktopCloudDetailCancelOptionDetailResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DesktopCloudDetailCancelOptionDetailResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DesktopCloudDetailCancelOptionDetailResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DesktopCloudHub/GetDesktopCloudDetailCancelOptionDetail';

        let responseData: DesktopCloudDetailCancelOptionDetailResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DesktopCloudHub', 'GetDesktopCloudDetailCancelOptionDetail', 'DesktopCloudDetailCancelOptionDetailRequestHubDto', 'DesktopCloudDetailCancelOptionDetailResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DesktopCloudHub', 'GetDesktopCloudDetailCancelOptionDetail', 'DesktopCloudDetailCancelOptionDetailRequestHubDto', 'DesktopCloudDetailCancelOptionDetailResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DesktopCloudHub', 'GetDesktopCloudDetailCancelOptionDetail', 'DesktopCloudDetailCancelOptionDetailRequestHubDto', 'DesktopCloudDetailCancelOptionDetailResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public RevokeDesktopCloudDetailCancelOption = (requestData: DesktopCloudDetailCancelOptionRevokeRequestHubDto, receiveCallBack: (data: DesktopCloudDetailCancelOptionRevokeResponseHubDto) => void, receiveErrorCallBack: (data: DesktopCloudDetailCancelOptionRevokeResponseHubDto) => void, noSessionCallBack: (data: DesktopCloudDetailCancelOptionRevokeResponseHubDto) => void, completeCallBack: (data: DesktopCloudDetailCancelOptionRevokeResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DesktopCloudDetailCancelOptionRevokeResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DesktopCloudDetailCancelOptionRevokeResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DesktopCloudHub/RevokeDesktopCloudDetailCancelOption';

        let responseData: DesktopCloudDetailCancelOptionRevokeResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DesktopCloudHub', 'RevokeDesktopCloudDetailCancelOption', 'DesktopCloudDetailCancelOptionRevokeRequestHubDto', 'DesktopCloudDetailCancelOptionRevokeResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DesktopCloudHub', 'RevokeDesktopCloudDetailCancelOption', 'DesktopCloudDetailCancelOptionRevokeRequestHubDto', 'DesktopCloudDetailCancelOptionRevokeResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DesktopCloudHub', 'RevokeDesktopCloudDetailCancelOption', 'DesktopCloudDetailCancelOptionRevokeRequestHubDto', 'DesktopCloudDetailCancelOptionRevokeResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ValidateDesktopCloudDetailChangePlan = (requestData: DesktopCloudDetailValidateChangePlanRequestHubDto, receiveCallBack: (data: DesktopCloudDetailValidateChangePlanResponseHubDto) => void, receiveErrorCallBack: (data: DesktopCloudDetailValidateChangePlanResponseHubDto) => void, noSessionCallBack: (data: DesktopCloudDetailValidateChangePlanResponseHubDto) => void, completeCallBack: (data: DesktopCloudDetailValidateChangePlanResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DesktopCloudDetailValidateChangePlanResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DesktopCloudDetailValidateChangePlanResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DesktopCloudHub/ValidateDesktopCloudDetailChangePlan';

        let responseData: DesktopCloudDetailValidateChangePlanResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DesktopCloudHub', 'ValidateDesktopCloudDetailChangePlan', 'DesktopCloudDetailValidateChangePlanRequestHubDto', 'DesktopCloudDetailValidateChangePlanResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DesktopCloudHub', 'ValidateDesktopCloudDetailChangePlan', 'DesktopCloudDetailValidateChangePlanRequestHubDto', 'DesktopCloudDetailValidateChangePlanResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DesktopCloudHub', 'ValidateDesktopCloudDetailChangePlan', 'DesktopCloudDetailValidateChangePlanRequestHubDto', 'DesktopCloudDetailValidateChangePlanResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ValidateDesktopCloudDetailRevokeCancelPlan = (requestData: DesktopCloudDetailValidateRevokeCancelPlanRequestHubDto, receiveCallBack: (data: DesktopCloudDetailValidateRevokeCancelPlanResponseHubDto) => void, receiveErrorCallBack: (data: DesktopCloudDetailValidateRevokeCancelPlanResponseHubDto) => void, noSessionCallBack: (data: DesktopCloudDetailValidateRevokeCancelPlanResponseHubDto) => void, completeCallBack: (data: DesktopCloudDetailValidateRevokeCancelPlanResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DesktopCloudDetailValidateRevokeCancelPlanResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DesktopCloudDetailValidateRevokeCancelPlanResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DesktopCloudHub/ValidateDesktopCloudDetailRevokeCancelPlan';

        let responseData: DesktopCloudDetailValidateRevokeCancelPlanResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DesktopCloudHub', 'ValidateDesktopCloudDetailRevokeCancelPlan', 'DesktopCloudDetailValidateRevokeCancelPlanRequestHubDto', 'DesktopCloudDetailValidateRevokeCancelPlanResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DesktopCloudHub', 'ValidateDesktopCloudDetailRevokeCancelPlan', 'DesktopCloudDetailValidateRevokeCancelPlanRequestHubDto', 'DesktopCloudDetailValidateRevokeCancelPlanResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DesktopCloudHub', 'ValidateDesktopCloudDetailRevokeCancelPlan', 'DesktopCloudDetailValidateRevokeCancelPlanRequestHubDto', 'DesktopCloudDetailValidateRevokeCancelPlanResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ValidateDesktopCloudDetailAddOption = (requestData: DesktopCloudDetailValidateAddOptionRequestHubDto, receiveCallBack: (data: DesktopCloudDetailValidateAddOptionResponseHubDto) => void, receiveErrorCallBack: (data: DesktopCloudDetailValidateAddOptionResponseHubDto) => void, noSessionCallBack: (data: DesktopCloudDetailValidateAddOptionResponseHubDto) => void, completeCallBack: (data: DesktopCloudDetailValidateAddOptionResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DesktopCloudDetailValidateAddOptionResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DesktopCloudDetailValidateAddOptionResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DesktopCloudHub/ValidateDesktopCloudDetailAddOption';

        let responseData: DesktopCloudDetailValidateAddOptionResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DesktopCloudHub', 'ValidateDesktopCloudDetailAddOption', 'DesktopCloudDetailValidateAddOptionRequestHubDto', 'DesktopCloudDetailValidateAddOptionResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DesktopCloudHub', 'ValidateDesktopCloudDetailAddOption', 'DesktopCloudDetailValidateAddOptionRequestHubDto', 'DesktopCloudDetailValidateAddOptionResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DesktopCloudHub', 'ValidateDesktopCloudDetailAddOption', 'DesktopCloudDetailValidateAddOptionRequestHubDto', 'DesktopCloudDetailValidateAddOptionResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ValidateDesktopCloudDetailChangeOption = (requestData: DesktopCloudDetailValidateChangeOptionRequestHubDto, receiveCallBack: (data: DesktopCloudDetailValidateChangeOptionResponseHubDto) => void, receiveErrorCallBack: (data: DesktopCloudDetailValidateChangeOptionResponseHubDto) => void, noSessionCallBack: (data: DesktopCloudDetailValidateChangeOptionResponseHubDto) => void, completeCallBack: (data: DesktopCloudDetailValidateChangeOptionResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DesktopCloudDetailValidateChangeOptionResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DesktopCloudDetailValidateChangeOptionResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DesktopCloudHub/ValidateDesktopCloudDetailChangeOption';

        let responseData: DesktopCloudDetailValidateChangeOptionResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DesktopCloudHub', 'ValidateDesktopCloudDetailChangeOption', 'DesktopCloudDetailValidateChangeOptionRequestHubDto', 'DesktopCloudDetailValidateChangeOptionResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DesktopCloudHub', 'ValidateDesktopCloudDetailChangeOption', 'DesktopCloudDetailValidateChangeOptionRequestHubDto', 'DesktopCloudDetailValidateChangeOptionResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DesktopCloudHub', 'ValidateDesktopCloudDetailChangeOption', 'DesktopCloudDetailValidateChangeOptionRequestHubDto', 'DesktopCloudDetailValidateChangeOptionResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ValidateDesktopCloudDetailCancelOption = (requestData: DesktopCloudDetailValidateCancelOptionRequestHubDto, receiveCallBack: (data: DesktopCloudDetailValidateCancelOptionResponseHubDto) => void, receiveErrorCallBack: (data: DesktopCloudDetailValidateCancelOptionResponseHubDto) => void, noSessionCallBack: (data: DesktopCloudDetailValidateCancelOptionResponseHubDto) => void, completeCallBack: (data: DesktopCloudDetailValidateCancelOptionResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DesktopCloudDetailValidateCancelOptionResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DesktopCloudDetailValidateCancelOptionResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DesktopCloudHub/ValidateDesktopCloudDetailCancelOption';

        let responseData: DesktopCloudDetailValidateCancelOptionResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DesktopCloudHub', 'ValidateDesktopCloudDetailCancelOption', 'DesktopCloudDetailValidateCancelOptionRequestHubDto', 'DesktopCloudDetailValidateCancelOptionResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DesktopCloudHub', 'ValidateDesktopCloudDetailCancelOption', 'DesktopCloudDetailValidateCancelOptionRequestHubDto', 'DesktopCloudDetailValidateCancelOptionResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DesktopCloudHub', 'ValidateDesktopCloudDetailCancelOption', 'DesktopCloudDetailValidateCancelOptionRequestHubDto', 'DesktopCloudDetailValidateCancelOptionResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ValidateDesktopCloudDetailRevokeCancelOption = (requestData: DesktopCloudDetailValidateRevokeCancelOptionRequestHubDto, receiveCallBack: (data: DesktopCloudDetailValidateRevokeCancelOptionResponseHubDto) => void, receiveErrorCallBack: (data: DesktopCloudDetailValidateRevokeCancelOptionResponseHubDto) => void, noSessionCallBack: (data: DesktopCloudDetailValidateRevokeCancelOptionResponseHubDto) => void, completeCallBack: (data: DesktopCloudDetailValidateRevokeCancelOptionResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DesktopCloudDetailValidateRevokeCancelOptionResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DesktopCloudDetailValidateRevokeCancelOptionResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DesktopCloudHub/ValidateDesktopCloudDetailRevokeCancelOption';

        let responseData: DesktopCloudDetailValidateRevokeCancelOptionResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DesktopCloudHub', 'ValidateDesktopCloudDetailRevokeCancelOption', 'DesktopCloudDetailValidateRevokeCancelOptionRequestHubDto', 'DesktopCloudDetailValidateRevokeCancelOptionResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DesktopCloudHub', 'ValidateDesktopCloudDetailRevokeCancelOption', 'DesktopCloudDetailValidateRevokeCancelOptionRequestHubDto', 'DesktopCloudDetailValidateRevokeCancelOptionResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DesktopCloudHub', 'ValidateDesktopCloudDetailRevokeCancelOption', 'DesktopCloudDetailValidateRevokeCancelOptionRequestHubDto', 'DesktopCloudDetailValidateRevokeCancelOptionResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetDesktopCloudPlan = (requestData: DesktopCloudPlanRequestHubDto, receiveCallBack: (data: DesktopCloudPlanResponseHubDto) => void, receiveErrorCallBack: (data: DesktopCloudPlanResponseHubDto) => void, noSessionCallBack: (data: DesktopCloudPlanResponseHubDto) => void, completeCallBack: (data: DesktopCloudPlanResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DesktopCloudPlanResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DesktopCloudPlanResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DesktopCloudHub/GetDesktopCloudPlan';

        let responseData: DesktopCloudPlanResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DesktopCloudHub', 'GetDesktopCloudPlan', 'DesktopCloudPlanRequestHubDto', 'DesktopCloudPlanResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DesktopCloudHub', 'GetDesktopCloudPlan', 'DesktopCloudPlanRequestHubDto', 'DesktopCloudPlanResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DesktopCloudHub', 'GetDesktopCloudPlan', 'DesktopCloudPlanRequestHubDto', 'DesktopCloudPlanResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetDesktopCloudPlanChangedDetail = (requestData: DesktopCloudPlanChangedDetailRequestHubDto, receiveCallBack: (data: DesktopCloudPlanChangedDetailResponseHubDto) => void, receiveErrorCallBack: (data: DesktopCloudPlanChangedDetailResponseHubDto) => void, noSessionCallBack: (data: DesktopCloudPlanChangedDetailResponseHubDto) => void, completeCallBack: (data: DesktopCloudPlanChangedDetailResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DesktopCloudPlanChangedDetailResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DesktopCloudPlanChangedDetailResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DesktopCloudHub/GetDesktopCloudPlanChangedDetail';

        let responseData: DesktopCloudPlanChangedDetailResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DesktopCloudHub', 'GetDesktopCloudPlanChangedDetail', 'DesktopCloudPlanChangedDetailRequestHubDto', 'DesktopCloudPlanChangedDetailResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DesktopCloudHub', 'GetDesktopCloudPlanChangedDetail', 'DesktopCloudPlanChangedDetailRequestHubDto', 'DesktopCloudPlanChangedDetailResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DesktopCloudHub', 'GetDesktopCloudPlanChangedDetail', 'DesktopCloudPlanChangedDetailRequestHubDto', 'DesktopCloudPlanChangedDetailResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ChangeDesktopCloudPlan = (requestData: DesktopCloudPlanChangeRequestHubDto, receiveCallBack: (data: DesktopCloudPlanChangeResponseHubDto) => void, receiveErrorCallBack: (data: DesktopCloudPlanChangeResponseHubDto) => void, noSessionCallBack: (data: DesktopCloudPlanChangeResponseHubDto) => void, completeCallBack: (data: DesktopCloudPlanChangeResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DesktopCloudPlanChangeResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DesktopCloudPlanChangeResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DesktopCloudHub/ChangeDesktopCloudPlan';

        let responseData: DesktopCloudPlanChangeResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DesktopCloudHub', 'ChangeDesktopCloudPlan', 'DesktopCloudPlanChangeRequestHubDto', 'DesktopCloudPlanChangeResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DesktopCloudHub', 'ChangeDesktopCloudPlan', 'DesktopCloudPlanChangeRequestHubDto', 'DesktopCloudPlanChangeResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DesktopCloudHub', 'ChangeDesktopCloudPlan', 'DesktopCloudPlanChangeRequestHubDto', 'DesktopCloudPlanChangeResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetDesktopCloudCancelPlanData = (requestData: DesktopCloudPlanCancelDataRequestHubDto, receiveCallBack: (data: DesktopCloudPlanCancelDataResponseHubDto) => void, receiveErrorCallBack: (data: DesktopCloudPlanCancelDataResponseHubDto) => void, noSessionCallBack: (data: DesktopCloudPlanCancelDataResponseHubDto) => void, completeCallBack: (data: DesktopCloudPlanCancelDataResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DesktopCloudPlanCancelDataResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DesktopCloudPlanCancelDataResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DesktopCloudHub/GetDesktopCloudCancelPlanData';

        let responseData: DesktopCloudPlanCancelDataResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DesktopCloudHub', 'GetDesktopCloudCancelPlanData', 'DesktopCloudPlanCancelDataRequestHubDto', 'DesktopCloudPlanCancelDataResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DesktopCloudHub', 'GetDesktopCloudCancelPlanData', 'DesktopCloudPlanCancelDataRequestHubDto', 'DesktopCloudPlanCancelDataResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DesktopCloudHub', 'GetDesktopCloudCancelPlanData', 'DesktopCloudPlanCancelDataRequestHubDto', 'DesktopCloudPlanCancelDataResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ValidateDesktopCloudDetailCancelPlan = (requestData: DesktopCloudDetailValidateCancelPlanRequestHubDto, receiveCallBack: (data: DesktopCloudDetailValidateCancelPlanResponseHubDto) => void, receiveErrorCallBack: (data: DesktopCloudDetailValidateCancelPlanResponseHubDto) => void, noSessionCallBack: (data: DesktopCloudDetailValidateCancelPlanResponseHubDto) => void, completeCallBack: (data: DesktopCloudDetailValidateCancelPlanResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DesktopCloudDetailValidateCancelPlanResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DesktopCloudDetailValidateCancelPlanResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DesktopCloudHub/ValidateDesktopCloudDetailCancelPlan';

        let responseData: DesktopCloudDetailValidateCancelPlanResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DesktopCloudHub', 'ValidateDesktopCloudDetailCancelPlan', 'DesktopCloudDetailValidateCancelPlanRequestHubDto', 'DesktopCloudDetailValidateCancelPlanResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DesktopCloudHub', 'ValidateDesktopCloudDetailCancelPlan', 'DesktopCloudDetailValidateCancelPlanRequestHubDto', 'DesktopCloudDetailValidateCancelPlanResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DesktopCloudHub', 'ValidateDesktopCloudDetailCancelPlan', 'DesktopCloudDetailValidateCancelPlanRequestHubDto', 'DesktopCloudDetailValidateCancelPlanResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public CancelDesktopCloudPlan = (requestData: DesktopCloudPlanCancelRequestHubDto, receiveCallBack: (data: DesktopCloudPlanCancelResponseHubDto) => void, receiveErrorCallBack: (data: DesktopCloudPlanCancelResponseHubDto) => void, noSessionCallBack: (data: DesktopCloudPlanCancelResponseHubDto) => void, completeCallBack: (data: DesktopCloudPlanCancelResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DesktopCloudPlanCancelResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DesktopCloudPlanCancelResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DesktopCloudHub/CancelDesktopCloudPlan';

        let responseData: DesktopCloudPlanCancelResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DesktopCloudHub', 'CancelDesktopCloudPlan', 'DesktopCloudPlanCancelRequestHubDto', 'DesktopCloudPlanCancelResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DesktopCloudHub', 'CancelDesktopCloudPlan', 'DesktopCloudPlanCancelRequestHubDto', 'DesktopCloudPlanCancelResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DesktopCloudHub', 'CancelDesktopCloudPlan', 'DesktopCloudPlanCancelRequestHubDto', 'DesktopCloudPlanCancelResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetDesktopCloudAddOptions = (requestData: DesktopCloudOptionAddGetRequestHubDto, receiveCallBack: (data: DesktopCloudOptionAddSelectListResponseHubDto) => void, receiveErrorCallBack: (data: DesktopCloudOptionAddSelectListResponseHubDto) => void, noSessionCallBack: (data: DesktopCloudOptionAddSelectListResponseHubDto) => void, completeCallBack: (data: DesktopCloudOptionAddSelectListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DesktopCloudOptionAddSelectListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DesktopCloudOptionAddSelectListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DesktopCloudHub/GetDesktopCloudAddOptions';

        let responseData: DesktopCloudOptionAddSelectListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DesktopCloudHub', 'GetDesktopCloudAddOptions', 'DesktopCloudOptionAddGetRequestHubDto', 'DesktopCloudOptionAddSelectListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DesktopCloudHub', 'GetDesktopCloudAddOptions', 'DesktopCloudOptionAddGetRequestHubDto', 'DesktopCloudOptionAddSelectListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DesktopCloudHub', 'GetDesktopCloudAddOptions', 'DesktopCloudOptionAddGetRequestHubDto', 'DesktopCloudOptionAddSelectListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetDesktopCloudAddOptionDetail = (requestData: DesktopCloudOptionAddDetailGetRequestHubDto, receiveCallBack: (data: DesktopCloudOptionAddDetailGetResponseHubDto) => void, receiveErrorCallBack: (data: DesktopCloudOptionAddDetailGetResponseHubDto) => void, noSessionCallBack: (data: DesktopCloudOptionAddDetailGetResponseHubDto) => void, completeCallBack: (data: DesktopCloudOptionAddDetailGetResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DesktopCloudOptionAddDetailGetResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DesktopCloudOptionAddDetailGetResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DesktopCloudHub/GetDesktopCloudAddOptionDetail';

        let responseData: DesktopCloudOptionAddDetailGetResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DesktopCloudHub', 'GetDesktopCloudAddOptionDetail', 'DesktopCloudOptionAddDetailGetRequestHubDto', 'DesktopCloudOptionAddDetailGetResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DesktopCloudHub', 'GetDesktopCloudAddOptionDetail', 'DesktopCloudOptionAddDetailGetRequestHubDto', 'DesktopCloudOptionAddDetailGetResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DesktopCloudHub', 'GetDesktopCloudAddOptionDetail', 'DesktopCloudOptionAddDetailGetRequestHubDto', 'DesktopCloudOptionAddDetailGetResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public AddDesktopCloudAddOptions = (requestData: DesktopCloudOptionAddRequestHubDto, receiveCallBack: (data: DesktopCloudOptionAddResponseHubDto) => void, receiveErrorCallBack: (data: DesktopCloudOptionAddResponseHubDto) => void, noSessionCallBack: (data: DesktopCloudOptionAddResponseHubDto) => void, completeCallBack: (data: DesktopCloudOptionAddResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DesktopCloudOptionAddResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DesktopCloudOptionAddResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DesktopCloudHub/AddDesktopCloudAddOptions';

        let responseData: DesktopCloudOptionAddResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DesktopCloudHub', 'AddDesktopCloudAddOptions', 'DesktopCloudOptionAddRequestHubDto', 'DesktopCloudOptionAddResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DesktopCloudHub', 'AddDesktopCloudAddOptions', 'DesktopCloudOptionAddRequestHubDto', 'DesktopCloudOptionAddResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DesktopCloudHub', 'AddDesktopCloudAddOptions', 'DesktopCloudOptionAddRequestHubDto', 'DesktopCloudOptionAddResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetDesktopCloudCancelOptionDetail = (requestData: DesktopCloudOptionCancelDetailRequestHubDto, receiveCallBack: (data: DesktopCloudOptionCancelDetailResponseHubDto) => void, receiveErrorCallBack: (data: DesktopCloudOptionCancelDetailResponseHubDto) => void, noSessionCallBack: (data: DesktopCloudOptionCancelDetailResponseHubDto) => void, completeCallBack: (data: DesktopCloudOptionCancelDetailResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DesktopCloudOptionCancelDetailResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DesktopCloudOptionCancelDetailResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DesktopCloudHub/GetDesktopCloudCancelOptionDetail';

        let responseData: DesktopCloudOptionCancelDetailResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DesktopCloudHub', 'GetDesktopCloudCancelOptionDetail', 'DesktopCloudOptionCancelDetailRequestHubDto', 'DesktopCloudOptionCancelDetailResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DesktopCloudHub', 'GetDesktopCloudCancelOptionDetail', 'DesktopCloudOptionCancelDetailRequestHubDto', 'DesktopCloudOptionCancelDetailResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DesktopCloudHub', 'GetDesktopCloudCancelOptionDetail', 'DesktopCloudOptionCancelDetailRequestHubDto', 'DesktopCloudOptionCancelDetailResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public CancelDesktopCloudCancelOption = (requestData: DesktopCloudOptionCancelRequestHubDto, receiveCallBack: (data: DesktopCloudOptionCancelResponseHubDto) => void, receiveErrorCallBack: (data: DesktopCloudOptionCancelResponseHubDto) => void, noSessionCallBack: (data: DesktopCloudOptionCancelResponseHubDto) => void, completeCallBack: (data: DesktopCloudOptionCancelResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DesktopCloudOptionCancelResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DesktopCloudOptionCancelResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DesktopCloudHub/CancelDesktopCloudCancelOption';

        let responseData: DesktopCloudOptionCancelResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DesktopCloudHub', 'CancelDesktopCloudCancelOption', 'DesktopCloudOptionCancelRequestHubDto', 'DesktopCloudOptionCancelResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DesktopCloudHub', 'CancelDesktopCloudCancelOption', 'DesktopCloudOptionCancelRequestHubDto', 'DesktopCloudOptionCancelResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DesktopCloudHub', 'CancelDesktopCloudCancelOption', 'DesktopCloudOptionCancelRequestHubDto', 'DesktopCloudOptionCancelResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetDesktopCloudMessages = (receiveCallBack: (data: DesktopCloudMessageResponseHubDto) => void, receiveErrorCallBack: (data: DesktopCloudMessageResponseHubDto) => void, noSessionCallBack: (data: DesktopCloudMessageResponseHubDto) => void, completeCallBack: (data: DesktopCloudMessageResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DesktopCloudMessageResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DesktopCloudMessageResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DesktopCloudHub/GetDesktopCloudMessages';

        let responseData: DesktopCloudMessageResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DesktopCloudHub', 'GetDesktopCloudMessages', null, 'DesktopCloudMessageResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DesktopCloudHub', 'GetDesktopCloudMessages', null, 'DesktopCloudMessageResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DesktopCloudHub', 'GetDesktopCloudMessages', null, 'DesktopCloudMessageResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public DesktopCloudSwitchIdValidation = (requestData: DesktopCloudSwitchIdValidationRequestHubDto, receiveCallBack: (data: DesktopCloudSwitchIdValidationResponseHubDto) => void, receiveErrorCallBack: (data: DesktopCloudSwitchIdValidationResponseHubDto) => void, noSessionCallBack: (data: DesktopCloudSwitchIdValidationResponseHubDto) => void, completeCallBack: (data: DesktopCloudSwitchIdValidationResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DesktopCloudSwitchIdValidationResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DesktopCloudSwitchIdValidationResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DesktopCloudHub/DesktopCloudSwitchIdValidation';

        let responseData: DesktopCloudSwitchIdValidationResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DesktopCloudHub', 'DesktopCloudSwitchIdValidation', 'DesktopCloudSwitchIdValidationRequestHubDto', 'DesktopCloudSwitchIdValidationResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DesktopCloudHub', 'DesktopCloudSwitchIdValidation', 'DesktopCloudSwitchIdValidationRequestHubDto', 'DesktopCloudSwitchIdValidationResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DesktopCloudHub', 'DesktopCloudSwitchIdValidation', 'DesktopCloudSwitchIdValidationRequestHubDto', 'DesktopCloudSwitchIdValidationResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public DesktopCloudSwitchId = (requestData: DesktopCloudSwitchIdRequestHubDto, receiveCallBack: (data: DesktopCloudSwitchIdResponseHubDto) => void, receiveErrorCallBack: (data: DesktopCloudSwitchIdResponseHubDto) => void, noSessionCallBack: (data: DesktopCloudSwitchIdResponseHubDto) => void, completeCallBack: (data: DesktopCloudSwitchIdResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DesktopCloudSwitchIdResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DesktopCloudSwitchIdResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DesktopCloudHub/DesktopCloudSwitchId';

        let responseData: DesktopCloudSwitchIdResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DesktopCloudHub', 'DesktopCloudSwitchId', 'DesktopCloudSwitchIdRequestHubDto', 'DesktopCloudSwitchIdResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DesktopCloudHub', 'DesktopCloudSwitchId', 'DesktopCloudSwitchIdRequestHubDto', 'DesktopCloudSwitchIdResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DesktopCloudHub', 'DesktopCloudSwitchId', 'DesktopCloudSwitchIdRequestHubDto', 'DesktopCloudSwitchIdResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetServerRedirection = (requestData: ServerRedirectionRequestHubDto, receiveCallBack: (data: ServerRedirectionResponseHubDto) => void, receiveErrorCallBack: (data: ServerRedirectionResponseHubDto) => void, noSessionCallBack: (data: ServerRedirectionResponseHubDto) => void, completeCallBack: (data: ServerRedirectionResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: ServerRedirectionResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: ServerRedirectionResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DesktopCloudHub/GetServerRedirection';

        let responseData: ServerRedirectionResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DesktopCloudHub', 'GetServerRedirection', 'ServerRedirectionRequestHubDto', 'ServerRedirectionResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DesktopCloudHub', 'GetServerRedirection', 'ServerRedirectionRequestHubDto', 'ServerRedirectionResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DesktopCloudHub', 'GetServerRedirection', 'ServerRedirectionRequestHubDto', 'ServerRedirectionResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ApplyDiscountToBasePlan = (requestData: DesktopCloudDiscountRequestHubDto, receiveCallBack: (data: DesktopCloudDetailValidateCancelPlanResponseHubDto) => void, receiveErrorCallBack: (data: DesktopCloudDetailValidateCancelPlanResponseHubDto) => void, noSessionCallBack: (data: DesktopCloudDetailValidateCancelPlanResponseHubDto) => void, completeCallBack: (data: DesktopCloudDetailValidateCancelPlanResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DesktopCloudDetailValidateCancelPlanResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DesktopCloudDetailValidateCancelPlanResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DesktopCloudHub/ApplyDiscountToBasePlan';

        let responseData: DesktopCloudDetailValidateCancelPlanResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DesktopCloudHub', 'ApplyDiscountToBasePlan', 'DesktopCloudDiscountRequestHubDto', 'DesktopCloudDetailValidateCancelPlanResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DesktopCloudHub', 'ApplyDiscountToBasePlan', 'DesktopCloudDiscountRequestHubDto', 'DesktopCloudDetailValidateCancelPlanResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DesktopCloudHub', 'ApplyDiscountToBasePlan', 'DesktopCloudDiscountRequestHubDto', 'DesktopCloudDetailValidateCancelPlanResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetCashBackList = (requestData: DesktopCloudCashBackRequestHubDto, receiveCallBack: (data: DesktopCloudCashBackResponseHubDto) => void, receiveErrorCallBack: (data: DesktopCloudCashBackResponseHubDto) => void, noSessionCallBack: (data: DesktopCloudCashBackResponseHubDto) => void, completeCallBack: (data: DesktopCloudCashBackResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DesktopCloudCashBackResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DesktopCloudCashBackResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DesktopCloudHub/GetCashBackList';

        let responseData: DesktopCloudCashBackResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DesktopCloudHub', 'GetCashBackList', 'DesktopCloudCashBackRequestHubDto', 'DesktopCloudCashBackResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DesktopCloudHub', 'GetCashBackList', 'DesktopCloudCashBackRequestHubDto', 'DesktopCloudCashBackResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DesktopCloudHub', 'GetCashBackList', 'DesktopCloudCashBackRequestHubDto', 'DesktopCloudCashBackResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ValidateCashBack = (requestData: DesktopCloudCashBackRequestHubDto, receiveCallBack: (data: DesktopCloudCashBackValidateResponseHubDto) => void, receiveErrorCallBack: (data: DesktopCloudCashBackValidateResponseHubDto) => void, noSessionCallBack: (data: DesktopCloudCashBackValidateResponseHubDto) => void, completeCallBack: (data: DesktopCloudCashBackValidateResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DesktopCloudCashBackValidateResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DesktopCloudCashBackValidateResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DesktopCloudHub/ValidateCashBack';

        let responseData: DesktopCloudCashBackValidateResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DesktopCloudHub', 'ValidateCashBack', 'DesktopCloudCashBackRequestHubDto', 'DesktopCloudCashBackValidateResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DesktopCloudHub', 'ValidateCashBack', 'DesktopCloudCashBackRequestHubDto', 'DesktopCloudCashBackValidateResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DesktopCloudHub', 'ValidateCashBack', 'DesktopCloudCashBackRequestHubDto', 'DesktopCloudCashBackValidateResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ApplyCashBack = (requestData: DesktopCloudCashBackRequestHubDto, receiveCallBack: (data: DesktopCloudCashBackApplyResponseHubDto) => void, receiveErrorCallBack: (data: DesktopCloudCashBackApplyResponseHubDto) => void, noSessionCallBack: (data: DesktopCloudCashBackApplyResponseHubDto) => void, completeCallBack: (data: DesktopCloudCashBackApplyResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DesktopCloudCashBackApplyResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DesktopCloudCashBackApplyResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DesktopCloudHub/ApplyCashBack';

        let responseData: DesktopCloudCashBackApplyResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DesktopCloudHub', 'ApplyCashBack', 'DesktopCloudCashBackRequestHubDto', 'DesktopCloudCashBackApplyResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DesktopCloudHub', 'ApplyCashBack', 'DesktopCloudCashBackRequestHubDto', 'DesktopCloudCashBackApplyResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DesktopCloudHub', 'ApplyCashBack', 'DesktopCloudCashBackRequestHubDto', 'DesktopCloudCashBackApplyResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }

}



export class TmchHubManager {
    /**
     * コンストラクタ
     */
    constructor(private http: HttpClient) {
    }

	// リソース破棄
    public Dispose = (): void => {
    }



    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public TmchItemList = (requestData: TmchItemListRequestHubDto, receiveCallBack: (data: TmchItemListResponseHubDto) => void, receiveErrorCallBack: (data: TmchItemListResponseHubDto) => void, noSessionCallBack: (data: TmchItemListResponseHubDto) => void, completeCallBack: (data: TmchItemListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: TmchItemListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: TmchItemListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/TmchHub/TmchItemList';

        let responseData: TmchItemListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('TmchHub', 'TmchItemList', 'TmchItemListRequestHubDto', 'TmchItemListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('TmchHub', 'TmchItemList', 'TmchItemListRequestHubDto', 'TmchItemListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('TmchHub', 'TmchItemList', 'TmchItemListRequestHubDto', 'TmchItemListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public TmchItemInfo = (requestData: TmchItemInfoRequestHubDto, receiveCallBack: (data: TmchItemInfoResponseHubDto) => void, receiveErrorCallBack: (data: TmchItemInfoResponseHubDto) => void, noSessionCallBack: (data: TmchItemInfoResponseHubDto) => void, completeCallBack: (data: TmchItemInfoResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: TmchItemInfoResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: TmchItemInfoResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/TmchHub/TmchItemInfo';

        let responseData: TmchItemInfoResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('TmchHub', 'TmchItemInfo', 'TmchItemInfoRequestHubDto', 'TmchItemInfoResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('TmchHub', 'TmchItemInfo', 'TmchItemInfoRequestHubDto', 'TmchItemInfoResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('TmchHub', 'TmchItemInfo', 'TmchItemInfoRequestHubDto', 'TmchItemInfoResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public TmchItemCorrect = (requestData: TmchItemCorrectRequestHubDto, receiveCallBack: (data: TmchItemCorrectResponseHubDto) => void, receiveErrorCallBack: (data: TmchItemCorrectResponseHubDto) => void, noSessionCallBack: (data: TmchItemCorrectResponseHubDto) => void, completeCallBack: (data: TmchItemCorrectResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: TmchItemCorrectResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: TmchItemCorrectResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/TmchHub/TmchItemCorrect';

        let responseData: TmchItemCorrectResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('TmchHub', 'TmchItemCorrect', 'TmchItemCorrectRequestHubDto', 'TmchItemCorrectResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('TmchHub', 'TmchItemCorrect', 'TmchItemCorrectRequestHubDto', 'TmchItemCorrectResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('TmchHub', 'TmchItemCorrect', 'TmchItemCorrectRequestHubDto', 'TmchItemCorrectResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public TmchItemRenewInput = (requestData: TmchRenewRequestHubDto, receiveCallBack: (data: TmchRenewResponseHubDto) => void, receiveErrorCallBack: (data: TmchRenewResponseHubDto) => void, noSessionCallBack: (data: TmchRenewResponseHubDto) => void, completeCallBack: (data: TmchRenewResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: TmchRenewResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: TmchRenewResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/TmchHub/TmchItemRenewInput';

        let responseData: TmchRenewResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('TmchHub', 'TmchItemRenewInput', 'TmchRenewRequestHubDto', 'TmchRenewResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('TmchHub', 'TmchItemRenewInput', 'TmchRenewRequestHubDto', 'TmchRenewResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('TmchHub', 'TmchItemRenewInput', 'TmchRenewRequestHubDto', 'TmchRenewResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetTmchPrice = (requestData: TmchTransactionRequestHubDto, receiveCallBack: (data: TmchRenewTransactionResponseHubDto) => void, receiveErrorCallBack: (data: TmchRenewTransactionResponseHubDto) => void, noSessionCallBack: (data: TmchRenewTransactionResponseHubDto) => void, completeCallBack: (data: TmchRenewTransactionResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: TmchRenewTransactionResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: TmchRenewTransactionResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/TmchHub/GetTmchPrice';

        let responseData: TmchRenewTransactionResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('TmchHub', 'GetTmchPrice', 'TmchTransactionRequestHubDto', 'TmchRenewTransactionResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('TmchHub', 'GetTmchPrice', 'TmchTransactionRequestHubDto', 'TmchRenewTransactionResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('TmchHub', 'GetTmchPrice', 'TmchTransactionRequestHubDto', 'TmchRenewTransactionResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public TmchItemRenew = (requestData: TmchItemRenewRequestHubDto, receiveCallBack: (data: TmchItemRenewTransactionResponseHubDto) => void, receiveErrorCallBack: (data: TmchItemRenewTransactionResponseHubDto) => void, noSessionCallBack: (data: TmchItemRenewTransactionResponseHubDto) => void, completeCallBack: (data: TmchItemRenewTransactionResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: TmchItemRenewTransactionResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: TmchItemRenewTransactionResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/TmchHub/TmchItemRenew';

        let responseData: TmchItemRenewTransactionResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('TmchHub', 'TmchItemRenew', 'TmchItemRenewRequestHubDto', 'TmchItemRenewTransactionResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('TmchHub', 'TmchItemRenew', 'TmchItemRenewRequestHubDto', 'TmchItemRenewTransactionResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('TmchHub', 'TmchItemRenew', 'TmchItemRenewRequestHubDto', 'TmchItemRenewTransactionResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public TmchRenewInvoice = (requestData: TmchRenewInvoiceRequestHubDto, receiveCallBack: (data: TmchRenewTransactionResponseHubDto) => void, receiveErrorCallBack: (data: TmchRenewTransactionResponseHubDto) => void, noSessionCallBack: (data: TmchRenewTransactionResponseHubDto) => void, completeCallBack: (data: TmchRenewTransactionResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: TmchRenewTransactionResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: TmchRenewTransactionResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/TmchHub/TmchRenewInvoice';

        let responseData: TmchRenewTransactionResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('TmchHub', 'TmchRenewInvoice', 'TmchRenewInvoiceRequestHubDto', 'TmchRenewTransactionResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('TmchHub', 'TmchRenewInvoice', 'TmchRenewInvoiceRequestHubDto', 'TmchRenewTransactionResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('TmchHub', 'TmchRenewInvoice', 'TmchRenewInvoiceRequestHubDto', 'TmchRenewTransactionResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }

}



export class WebGakuenHubManager {
    /**
     * コンストラクタ
     */
    constructor(private http: HttpClient) {
    }

	// リソース破棄
    public Dispose = (): void => {
    }



    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetList = (requestData: WebGakuenGetListRequestHubDto, receiveCallBack: (data: WebGakuenGetListResponseHubDto) => void, receiveErrorCallBack: (data: WebGakuenGetListResponseHubDto) => void, noSessionCallBack: (data: WebGakuenGetListResponseHubDto) => void, completeCallBack: (data: WebGakuenGetListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: WebGakuenGetListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: WebGakuenGetListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/WebGakuenHub/GetList';

        let responseData: WebGakuenGetListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('WebGakuenHub', 'GetList', 'WebGakuenGetListRequestHubDto', 'WebGakuenGetListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('WebGakuenHub', 'GetList', 'WebGakuenGetListRequestHubDto', 'WebGakuenGetListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('WebGakuenHub', 'GetList', 'WebGakuenGetListRequestHubDto', 'WebGakuenGetListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }

}



export class DesktopCloudOptionHubManager {
    /**
     * コンストラクタ
     */
    constructor(private http: HttpClient) {
    }

	// リソース破棄
    public Dispose = (): void => {
    }



    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public DesktopCloudOptionList = (requestData: DesktopCloudOptionListRequestHubDto, receiveCallBack: (data: DesktopCloudOptionListResponseHubDto) => void, receiveErrorCallBack: (data: DesktopCloudOptionListResponseHubDto) => void, noSessionCallBack: (data: DesktopCloudOptionListResponseHubDto) => void, completeCallBack: (data: DesktopCloudOptionListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DesktopCloudOptionListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DesktopCloudOptionListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DesktopCloudOptionHub/DesktopCloudOptionList';

        let responseData: DesktopCloudOptionListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DesktopCloudOptionHub', 'DesktopCloudOptionList', 'DesktopCloudOptionListRequestHubDto', 'DesktopCloudOptionListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DesktopCloudOptionHub', 'DesktopCloudOptionList', 'DesktopCloudOptionListRequestHubDto', 'DesktopCloudOptionListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DesktopCloudOptionHub', 'DesktopCloudOptionList', 'DesktopCloudOptionListRequestHubDto', 'DesktopCloudOptionListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }

}



export class WpTemplateHubManager {
    /**
     * コンストラクタ
     */
    constructor(private http: HttpClient) {
    }

	// リソース破棄
    public Dispose = (): void => {
    }



    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public WpTemplateList = (requestData: WpTemplateListRequestHubDto, receiveCallBack: (data: WpTemplateListResponseHubDto) => void, receiveErrorCallBack: (data: WpTemplateListResponseHubDto) => void, noSessionCallBack: (data: WpTemplateListResponseHubDto) => void, completeCallBack: (data: WpTemplateListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: WpTemplateListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: WpTemplateListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/WpTemplateHub/WpTemplateList';

        let responseData: WpTemplateListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('WpTemplateHub', 'WpTemplateList', 'WpTemplateListRequestHubDto', 'WpTemplateListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('WpTemplateHub', 'WpTemplateList', 'WpTemplateListRequestHubDto', 'WpTemplateListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('WpTemplateHub', 'WpTemplateList', 'WpTemplateListRequestHubDto', 'WpTemplateListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }

}



export class SiteCreationHubManager {
    /**
     * コンストラクタ
     */
    constructor(private http: HttpClient) {
    }

	// リソース破棄
    public Dispose = (): void => {
    }



    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public SiteCreationList = (requestData: SiteCreationListRequestHubDto, receiveCallBack: (data: SiteCreationListResponseHubDto) => void, receiveErrorCallBack: (data: SiteCreationListResponseHubDto) => void, noSessionCallBack: (data: SiteCreationListResponseHubDto) => void, completeCallBack: (data: SiteCreationListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SiteCreationListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SiteCreationListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SiteCreationHub/SiteCreationList';

        let responseData: SiteCreationListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SiteCreationHub', 'SiteCreationList', 'SiteCreationListRequestHubDto', 'SiteCreationListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SiteCreationHub', 'SiteCreationList', 'SiteCreationListRequestHubDto', 'SiteCreationListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SiteCreationHub', 'SiteCreationList', 'SiteCreationListRequestHubDto', 'SiteCreationListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetSiteCreationDetail = (requestData: SiteCreationDetailRequestHubDto, receiveCallBack: (data: SiteCreationDetailResponseHubDto) => void, receiveErrorCallBack: (data: SiteCreationDetailResponseHubDto) => void, noSessionCallBack: (data: SiteCreationDetailResponseHubDto) => void, completeCallBack: (data: SiteCreationDetailResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SiteCreationDetailResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SiteCreationDetailResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SiteCreationHub/GetSiteCreationDetail';

        let responseData: SiteCreationDetailResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SiteCreationHub', 'GetSiteCreationDetail', 'SiteCreationDetailRequestHubDto', 'SiteCreationDetailResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SiteCreationHub', 'GetSiteCreationDetail', 'SiteCreationDetailRequestHubDto', 'SiteCreationDetailResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SiteCreationHub', 'GetSiteCreationDetail', 'SiteCreationDetailRequestHubDto', 'SiteCreationDetailResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetSiteCreationDetailControlPanel = (requestData: SiteCreationDetailControlPanelRequestHubDto, receiveCallBack: (data: SiteCreationDetailControlPanelResponseHubDto) => void, receiveErrorCallBack: (data: SiteCreationDetailControlPanelResponseHubDto) => void, noSessionCallBack: (data: SiteCreationDetailControlPanelResponseHubDto) => void, completeCallBack: (data: SiteCreationDetailControlPanelResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SiteCreationDetailControlPanelResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SiteCreationDetailControlPanelResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SiteCreationHub/GetSiteCreationDetailControlPanel';

        let responseData: SiteCreationDetailControlPanelResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SiteCreationHub', 'GetSiteCreationDetailControlPanel', 'SiteCreationDetailControlPanelRequestHubDto', 'SiteCreationDetailControlPanelResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SiteCreationHub', 'GetSiteCreationDetailControlPanel', 'SiteCreationDetailControlPanelRequestHubDto', 'SiteCreationDetailControlPanelResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SiteCreationHub', 'GetSiteCreationDetailControlPanel', 'SiteCreationDetailControlPanelRequestHubDto', 'SiteCreationDetailControlPanelResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetSiteCreationDetailOption = (requestData: SiteCreationDetailOptionRequestHubDto, receiveCallBack: (data: SiteCreationDetailOptionResponseHubDto) => void, receiveErrorCallBack: (data: SiteCreationDetailOptionResponseHubDto) => void, noSessionCallBack: (data: SiteCreationDetailOptionResponseHubDto) => void, completeCallBack: (data: SiteCreationDetailOptionResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SiteCreationDetailOptionResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SiteCreationDetailOptionResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SiteCreationHub/GetSiteCreationDetailOption';

        let responseData: SiteCreationDetailOptionResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SiteCreationHub', 'GetSiteCreationDetailOption', 'SiteCreationDetailOptionRequestHubDto', 'SiteCreationDetailOptionResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SiteCreationHub', 'GetSiteCreationDetailOption', 'SiteCreationDetailOptionRequestHubDto', 'SiteCreationDetailOptionResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SiteCreationHub', 'GetSiteCreationDetailOption', 'SiteCreationDetailOptionRequestHubDto', 'SiteCreationDetailOptionResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public RevokeSiteCreationDetailCancelPlan = (requestData: SiteCreationDetailRevokeCancelPlanRequestHubDto, receiveCallBack: (data: SiteCreationDetailRevokeCancelPlanResponseHubDto) => void, receiveErrorCallBack: (data: SiteCreationDetailRevokeCancelPlanResponseHubDto) => void, noSessionCallBack: (data: SiteCreationDetailRevokeCancelPlanResponseHubDto) => void, completeCallBack: (data: SiteCreationDetailRevokeCancelPlanResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SiteCreationDetailRevokeCancelPlanResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SiteCreationDetailRevokeCancelPlanResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SiteCreationHub/RevokeSiteCreationDetailCancelPlan';

        let responseData: SiteCreationDetailRevokeCancelPlanResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SiteCreationHub', 'RevokeSiteCreationDetailCancelPlan', 'SiteCreationDetailRevokeCancelPlanRequestHubDto', 'SiteCreationDetailRevokeCancelPlanResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SiteCreationHub', 'RevokeSiteCreationDetailCancelPlan', 'SiteCreationDetailRevokeCancelPlanRequestHubDto', 'SiteCreationDetailRevokeCancelPlanResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SiteCreationHub', 'RevokeSiteCreationDetailCancelPlan', 'SiteCreationDetailRevokeCancelPlanRequestHubDto', 'SiteCreationDetailRevokeCancelPlanResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ValidateSiteCreationDetailCancelPlan = (requestData: SiteCreationDetailValidateCancelPlanRequestHubDto, receiveCallBack: (data: SiteCreationDetailValidateCancelPlanResponseHubDto) => void, receiveErrorCallBack: (data: SiteCreationDetailValidateCancelPlanResponseHubDto) => void, noSessionCallBack: (data: SiteCreationDetailValidateCancelPlanResponseHubDto) => void, completeCallBack: (data: SiteCreationDetailValidateCancelPlanResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SiteCreationDetailValidateCancelPlanResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SiteCreationDetailValidateCancelPlanResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SiteCreationHub/ValidateSiteCreationDetailCancelPlan';

        let responseData: SiteCreationDetailValidateCancelPlanResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SiteCreationHub', 'ValidateSiteCreationDetailCancelPlan', 'SiteCreationDetailValidateCancelPlanRequestHubDto', 'SiteCreationDetailValidateCancelPlanResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SiteCreationHub', 'ValidateSiteCreationDetailCancelPlan', 'SiteCreationDetailValidateCancelPlanRequestHubDto', 'SiteCreationDetailValidateCancelPlanResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SiteCreationHub', 'ValidateSiteCreationDetailCancelPlan', 'SiteCreationDetailValidateCancelPlanRequestHubDto', 'SiteCreationDetailValidateCancelPlanResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ValidateSiteCreationDetailRevokeCancelPlan = (requestData: SiteCreationDetailValidateRevokeCancelPlanRequestHubDto, receiveCallBack: (data: SiteCreationDetailValidateRevokeCancelPlanResponseHubDto) => void, receiveErrorCallBack: (data: SiteCreationDetailValidateRevokeCancelPlanResponseHubDto) => void, noSessionCallBack: (data: SiteCreationDetailValidateRevokeCancelPlanResponseHubDto) => void, completeCallBack: (data: SiteCreationDetailValidateRevokeCancelPlanResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SiteCreationDetailValidateRevokeCancelPlanResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SiteCreationDetailValidateRevokeCancelPlanResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SiteCreationHub/ValidateSiteCreationDetailRevokeCancelPlan';

        let responseData: SiteCreationDetailValidateRevokeCancelPlanResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SiteCreationHub', 'ValidateSiteCreationDetailRevokeCancelPlan', 'SiteCreationDetailValidateRevokeCancelPlanRequestHubDto', 'SiteCreationDetailValidateRevokeCancelPlanResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SiteCreationHub', 'ValidateSiteCreationDetailRevokeCancelPlan', 'SiteCreationDetailValidateRevokeCancelPlanRequestHubDto', 'SiteCreationDetailValidateRevokeCancelPlanResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SiteCreationHub', 'ValidateSiteCreationDetailRevokeCancelPlan', 'SiteCreationDetailValidateRevokeCancelPlanRequestHubDto', 'SiteCreationDetailValidateRevokeCancelPlanResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetSiteCreationCancelPlanData = (requestData: SiteCreationPlanCancelDataRequestHubDto, receiveCallBack: (data: SiteCreationPlanCancelDataResponseHubDto) => void, receiveErrorCallBack: (data: SiteCreationPlanCancelDataResponseHubDto) => void, noSessionCallBack: (data: SiteCreationPlanCancelDataResponseHubDto) => void, completeCallBack: (data: SiteCreationPlanCancelDataResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SiteCreationPlanCancelDataResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SiteCreationPlanCancelDataResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SiteCreationHub/GetSiteCreationCancelPlanData';

        let responseData: SiteCreationPlanCancelDataResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SiteCreationHub', 'GetSiteCreationCancelPlanData', 'SiteCreationPlanCancelDataRequestHubDto', 'SiteCreationPlanCancelDataResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SiteCreationHub', 'GetSiteCreationCancelPlanData', 'SiteCreationPlanCancelDataRequestHubDto', 'SiteCreationPlanCancelDataResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SiteCreationHub', 'GetSiteCreationCancelPlanData', 'SiteCreationPlanCancelDataRequestHubDto', 'SiteCreationPlanCancelDataResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public CancelSiteCreationPlan = (requestData: SiteCreationPlanCancelRequestHubDto, receiveCallBack: (data: SiteCreationPlanCancelResponseHubDto) => void, receiveErrorCallBack: (data: SiteCreationPlanCancelResponseHubDto) => void, noSessionCallBack: (data: SiteCreationPlanCancelResponseHubDto) => void, completeCallBack: (data: SiteCreationPlanCancelResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SiteCreationPlanCancelResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SiteCreationPlanCancelResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SiteCreationHub/CancelSiteCreationPlan';

        let responseData: SiteCreationPlanCancelResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SiteCreationHub', 'CancelSiteCreationPlan', 'SiteCreationPlanCancelRequestHubDto', 'SiteCreationPlanCancelResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SiteCreationHub', 'CancelSiteCreationPlan', 'SiteCreationPlanCancelRequestHubDto', 'SiteCreationPlanCancelResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SiteCreationHub', 'CancelSiteCreationPlan', 'SiteCreationPlanCancelRequestHubDto', 'SiteCreationPlanCancelResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetServerRedirection = (requestData: ServerRedirectionRequestHubDto, receiveCallBack: (data: ServerRedirectionResponseHubDto) => void, receiveErrorCallBack: (data: ServerRedirectionResponseHubDto) => void, noSessionCallBack: (data: ServerRedirectionResponseHubDto) => void, completeCallBack: (data: ServerRedirectionResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: ServerRedirectionResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: ServerRedirectionResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SiteCreationHub/GetServerRedirection';

        let responseData: ServerRedirectionResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SiteCreationHub', 'GetServerRedirection', 'ServerRedirectionRequestHubDto', 'ServerRedirectionResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SiteCreationHub', 'GetServerRedirection', 'ServerRedirectionRequestHubDto', 'ServerRedirectionResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SiteCreationHub', 'GetServerRedirection', 'ServerRedirectionRequestHubDto', 'ServerRedirectionResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }

}



export class SslCouponHubManager {
    /**
     * コンストラクタ
     */
    constructor(private http: HttpClient) {
    }

	// リソース破棄
    public Dispose = (): void => {
    }



    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public SslCouponList = (requestData: SslCouponListRequestHubDto, receiveCallBack: (data: SslCouponListResponseHubDto) => void, receiveErrorCallBack: (data: SslCouponListResponseHubDto) => void, noSessionCallBack: (data: SslCouponListResponseHubDto) => void, completeCallBack: (data: SslCouponListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SslCouponListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SslCouponListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SslCouponHub/SslCouponList';

        let responseData: SslCouponListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SslCouponHub', 'SslCouponList', 'SslCouponListRequestHubDto', 'SslCouponListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SslCouponHub', 'SslCouponList', 'SslCouponListRequestHubDto', 'SslCouponListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SslCouponHub', 'SslCouponList', 'SslCouponListRequestHubDto', 'SslCouponListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }

}



export class TopHubManager {
    /**
     * コンストラクタ
     */
    constructor(private http: HttpClient) {
    }

	// リソース破棄
    public Dispose = (): void => {
    }



    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetDomainContractQuantity = (receiveCallBack: (data: DomainContractQuantityResponseHubDto) => void, receiveErrorCallBack: (data: DomainContractQuantityResponseHubDto) => void, noSessionCallBack: (data: DomainContractQuantityResponseHubDto) => void, completeCallBack: (data: DomainContractQuantityResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainContractQuantityResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainContractQuantityResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/TopHub/GetDomainContractQuantity';

        let responseData: DomainContractQuantityResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('TopHub', 'GetDomainContractQuantity', null, 'DomainContractQuantityResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('TopHub', 'GetDomainContractQuantity', null, 'DomainContractQuantityResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('TopHub', 'GetDomainContractQuantity', null, 'DomainContractQuantityResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetHostingContractQuantity = (receiveCallBack: (data: HostingContractQuantityListResponseHubDto) => void, receiveErrorCallBack: (data: HostingContractQuantityListResponseHubDto) => void, noSessionCallBack: (data: HostingContractQuantityListResponseHubDto) => void, completeCallBack: (data: HostingContractQuantityListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: HostingContractQuantityListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: HostingContractQuantityListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/TopHub/GetHostingContractQuantity';

        let responseData: HostingContractQuantityListResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('TopHub', 'GetHostingContractQuantity', null, 'HostingContractQuantityListResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('TopHub', 'GetHostingContractQuantity', null, 'HostingContractQuantityListResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('TopHub', 'GetHostingContractQuantity', null, 'HostingContractQuantityListResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetBannerHtml = (receiveCallBack: (data: BannerMessageResponseHubDto) => void, receiveErrorCallBack: (data: BannerMessageResponseHubDto) => void, noSessionCallBack: (data: BannerMessageResponseHubDto) => void, completeCallBack: (data: BannerMessageResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: BannerMessageResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: BannerMessageResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/TopHub/GetBannerHtml';

        let responseData: BannerMessageResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('TopHub', 'GetBannerHtml', null, 'BannerMessageResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('TopHub', 'GetBannerHtml', null, 'BannerMessageResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('TopHub', 'GetBannerHtml', null, 'BannerMessageResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetNPSEnqueteInfo = (receiveCallBack: (data: NPSEnqueteInfoResponseHubDto) => void, receiveErrorCallBack: (data: NPSEnqueteInfoResponseHubDto) => void, noSessionCallBack: (data: NPSEnqueteInfoResponseHubDto) => void, completeCallBack: (data: NPSEnqueteInfoResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: NPSEnqueteInfoResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: NPSEnqueteInfoResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/TopHub/GetNPSEnqueteInfo';

        let responseData: NPSEnqueteInfoResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('TopHub', 'GetNPSEnqueteInfo', null, 'NPSEnqueteInfoResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('TopHub', 'GetNPSEnqueteInfo', null, 'NPSEnqueteInfoResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('TopHub', 'GetNPSEnqueteInfo', null, 'NPSEnqueteInfoResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetTotalUnreadCount = (requestData: MessageListRequestHubDto, receiveCallBack: (data: TotalUnreadCountResponseHubDto) => void, receiveErrorCallBack: (data: TotalUnreadCountResponseHubDto) => void, noSessionCallBack: (data: TotalUnreadCountResponseHubDto) => void, completeCallBack: (data: TotalUnreadCountResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: TotalUnreadCountResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: TotalUnreadCountResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/TopHub/GetTotalUnreadCount';

        let responseData: TotalUnreadCountResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('TopHub', 'GetTotalUnreadCount', 'MessageListRequestHubDto', 'TotalUnreadCountResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('TopHub', 'GetTotalUnreadCount', 'MessageListRequestHubDto', 'TotalUnreadCountResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('TopHub', 'GetTotalUnreadCount', 'MessageListRequestHubDto', 'TotalUnreadCountResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetTotalUnreadCountByCategory = (requestData: MessageListRequestHubDto, receiveCallBack: (data: MessageListResponseHubDto) => void, receiveErrorCallBack: (data: MessageListResponseHubDto) => void, noSessionCallBack: (data: MessageListResponseHubDto) => void, completeCallBack: (data: MessageListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: MessageListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: MessageListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/TopHub/GetTotalUnreadCountByCategory';

        let responseData: MessageListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('TopHub', 'GetTotalUnreadCountByCategory', 'MessageListRequestHubDto', 'MessageListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('TopHub', 'GetTotalUnreadCountByCategory', 'MessageListRequestHubDto', 'MessageListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('TopHub', 'GetTotalUnreadCountByCategory', 'MessageListRequestHubDto', 'MessageListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetImportantNoticesMessageList = (requestData: MessageListRequestHubDto, receiveCallBack: (data: MessageListResponseHubDto) => void, receiveErrorCallBack: (data: MessageListResponseHubDto) => void, noSessionCallBack: (data: MessageListResponseHubDto) => void, completeCallBack: (data: MessageListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: MessageListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: MessageListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/TopHub/GetImportantNoticesMessageList';

        let responseData: MessageListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('TopHub', 'GetImportantNoticesMessageList', 'MessageListRequestHubDto', 'MessageListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('TopHub', 'GetImportantNoticesMessageList', 'MessageListRequestHubDto', 'MessageListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('TopHub', 'GetImportantNoticesMessageList', 'MessageListRequestHubDto', 'MessageListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetDealsMessageList = (requestData: MessageListRequestHubDto, receiveCallBack: (data: MessageListResponseHubDto) => void, receiveErrorCallBack: (data: MessageListResponseHubDto) => void, noSessionCallBack: (data: MessageListResponseHubDto) => void, completeCallBack: (data: MessageListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: MessageListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: MessageListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/TopHub/GetDealsMessageList';

        let responseData: MessageListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('TopHub', 'GetDealsMessageList', 'MessageListRequestHubDto', 'MessageListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('TopHub', 'GetDealsMessageList', 'MessageListRequestHubDto', 'MessageListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('TopHub', 'GetDealsMessageList', 'MessageListRequestHubDto', 'MessageListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetDomainMaintenanceMessageList = (requestData: MessageListRequestHubDto, receiveCallBack: (data: MessageListResponseHubDto) => void, receiveErrorCallBack: (data: MessageListResponseHubDto) => void, noSessionCallBack: (data: MessageListResponseHubDto) => void, completeCallBack: (data: MessageListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: MessageListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: MessageListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/TopHub/GetDomainMaintenanceMessageList';

        let responseData: MessageListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('TopHub', 'GetDomainMaintenanceMessageList', 'MessageListRequestHubDto', 'MessageListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('TopHub', 'GetDomainMaintenanceMessageList', 'MessageListRequestHubDto', 'MessageListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('TopHub', 'GetDomainMaintenanceMessageList', 'MessageListRequestHubDto', 'MessageListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetServerMaintenanceMessageList = (requestData: MessageListRequestHubDto, receiveCallBack: (data: MessageListResponseHubDto) => void, receiveErrorCallBack: (data: MessageListResponseHubDto) => void, noSessionCallBack: (data: MessageListResponseHubDto) => void, completeCallBack: (data: MessageListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: MessageListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: MessageListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/TopHub/GetServerMaintenanceMessageList';

        let responseData: MessageListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('TopHub', 'GetServerMaintenanceMessageList', 'MessageListRequestHubDto', 'MessageListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('TopHub', 'GetServerMaintenanceMessageList', 'MessageListRequestHubDto', 'MessageListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('TopHub', 'GetServerMaintenanceMessageList', 'MessageListRequestHubDto', 'MessageListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetDomainSystemFailureMessageList = (requestData: MessageListRequestHubDto, receiveCallBack: (data: MessageListResponseHubDto) => void, receiveErrorCallBack: (data: MessageListResponseHubDto) => void, noSessionCallBack: (data: MessageListResponseHubDto) => void, completeCallBack: (data: MessageListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: MessageListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: MessageListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/TopHub/GetDomainSystemFailureMessageList';

        let responseData: MessageListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('TopHub', 'GetDomainSystemFailureMessageList', 'MessageListRequestHubDto', 'MessageListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('TopHub', 'GetDomainSystemFailureMessageList', 'MessageListRequestHubDto', 'MessageListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('TopHub', 'GetDomainSystemFailureMessageList', 'MessageListRequestHubDto', 'MessageListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetServerSystemFailureMessageList = (requestData: MessageListRequestHubDto, receiveCallBack: (data: MessageListResponseHubDto) => void, receiveErrorCallBack: (data: MessageListResponseHubDto) => void, noSessionCallBack: (data: MessageListResponseHubDto) => void, completeCallBack: (data: MessageListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: MessageListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: MessageListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/TopHub/GetServerSystemFailureMessageList';

        let responseData: MessageListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('TopHub', 'GetServerSystemFailureMessageList', 'MessageListRequestHubDto', 'MessageListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('TopHub', 'GetServerSystemFailureMessageList', 'MessageListRequestHubDto', 'MessageListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('TopHub', 'GetServerSystemFailureMessageList', 'MessageListRequestHubDto', 'MessageListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetMessageDetail = (requestData: MessageDetailRequestHubDto, receiveCallBack: (data: MessageDetailResponseHubDto) => void, receiveErrorCallBack: (data: MessageDetailResponseHubDto) => void, noSessionCallBack: (data: MessageDetailResponseHubDto) => void, completeCallBack: (data: MessageDetailResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: MessageDetailResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: MessageDetailResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/TopHub/GetMessageDetail';

        let responseData: MessageDetailResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('TopHub', 'GetMessageDetail', 'MessageDetailRequestHubDto', 'MessageDetailResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('TopHub', 'GetMessageDetail', 'MessageDetailRequestHubDto', 'MessageDetailResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('TopHub', 'GetMessageDetail', 'MessageDetailRequestHubDto', 'MessageDetailResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GmoUrls = (requestData: GmoUrlsRequestHubDto, receiveCallBack: (data: GmoUrlsResponseHubDto) => void, receiveErrorCallBack: (data: GmoUrlsResponseHubDto) => void, noSessionCallBack: (data: GmoUrlsResponseHubDto) => void, completeCallBack: (data: GmoUrlsResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: GmoUrlsResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: GmoUrlsResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/TopHub/GmoUrls';

        let responseData: GmoUrlsResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('TopHub', 'GmoUrls', 'GmoUrlsRequestHubDto', 'GmoUrlsResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('TopHub', 'GmoUrls', 'GmoUrlsRequestHubDto', 'GmoUrlsResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('TopHub', 'GmoUrls', 'GmoUrlsRequestHubDto', 'GmoUrlsResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GmoPoint = (requestData: GmoPointRequestHubDto, receiveCallBack: (data: GmoPointResponseHubDto) => void, receiveErrorCallBack: (data: GmoPointResponseHubDto) => void, noSessionCallBack: (data: GmoPointResponseHubDto) => void, completeCallBack: (data: GmoPointResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: GmoPointResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: GmoPointResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/TopHub/GmoPoint';

        let responseData: GmoPointResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('TopHub', 'GmoPoint', 'GmoPointRequestHubDto', 'GmoPointResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('TopHub', 'GmoPoint', 'GmoPointRequestHubDto', 'GmoPointResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('TopHub', 'GmoPoint', 'GmoPointRequestHubDto', 'GmoPointResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ConnectionStatus = (receiveCallBack: (data: ConnectionStatusResponseHubDto) => void, receiveErrorCallBack: (data: ConnectionStatusResponseHubDto) => void, noSessionCallBack: (data: ConnectionStatusResponseHubDto) => void, completeCallBack: (data: ConnectionStatusResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: ConnectionStatusResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: ConnectionStatusResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/TopHub/ConnectionStatus';

        let responseData: ConnectionStatusResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('TopHub', 'ConnectionStatus', null, 'ConnectionStatusResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('TopHub', 'ConnectionStatus', null, 'ConnectionStatusResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('TopHub', 'ConnectionStatus', null, 'ConnectionStatusResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetDomainMightExpireList = (receiveCallBack: (data: DomainMightExpireResponseHubDto) => void, receiveErrorCallBack: (data: DomainMightExpireResponseHubDto) => void, noSessionCallBack: (data: DomainMightExpireResponseHubDto) => void, completeCallBack: (data: DomainMightExpireResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainMightExpireResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainMightExpireResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/TopHub/GetDomainMightExpireList';

        let responseData: DomainMightExpireResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('TopHub', 'GetDomainMightExpireList', null, 'DomainMightExpireResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('TopHub', 'GetDomainMightExpireList', null, 'DomainMightExpireResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('TopHub', 'GetDomainMightExpireList', null, 'DomainMightExpireResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetServerPopupInfoAsync = (receiveCallBack: (data: GetServerPopupInfoResponseHubDto) => void, receiveErrorCallBack: (data: GetServerPopupInfoResponseHubDto) => void, noSessionCallBack: (data: GetServerPopupInfoResponseHubDto) => void, completeCallBack: (data: GetServerPopupInfoResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: GetServerPopupInfoResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: GetServerPopupInfoResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/TopHub/GetServerPopupInfoAsync';

        let responseData: GetServerPopupInfoResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('TopHub', 'GetServerPopupInfoAsync', null, 'GetServerPopupInfoResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('TopHub', 'GetServerPopupInfoAsync', null, 'GetServerPopupInfoResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('TopHub', 'GetServerPopupInfoAsync', null, 'GetServerPopupInfoResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetDomainInfoForTopPageAsync = (receiveCallBack: (data: TopPageDomainInfoResponseHubDto) => void, receiveErrorCallBack: (data: TopPageDomainInfoResponseHubDto) => void, noSessionCallBack: (data: TopPageDomainInfoResponseHubDto) => void, completeCallBack: (data: TopPageDomainInfoResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: TopPageDomainInfoResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: TopPageDomainInfoResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/TopHub/GetDomainInfoForTopPageAsync';

        let responseData: TopPageDomainInfoResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('TopHub', 'GetDomainInfoForTopPageAsync', null, 'TopPageDomainInfoResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('TopHub', 'GetDomainInfoForTopPageAsync', null, 'TopPageDomainInfoResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('TopHub', 'GetDomainInfoForTopPageAsync', null, 'TopPageDomainInfoResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetRsRecommendModalCondition = (receiveCallBack: (data: GetRsRecommendModalConditionResponse) => void, receiveErrorCallBack: (data: GetRsRecommendModalConditionResponse) => void, noSessionCallBack: (data: GetRsRecommendModalConditionResponse) => void, completeCallBack: (data: GetRsRecommendModalConditionResponse) => void = null, receiveUnExceptedErrorCallBack: (data: GetRsRecommendModalConditionResponse) => void, reciveSubMaintenanceModeCallBack:(data: GetRsRecommendModalConditionResponse) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/TopHub/GetRsRecommendModalCondition';

        let responseData: GetRsRecommendModalConditionResponse = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('TopHub', 'GetRsRecommendModalCondition', null, 'GetRsRecommendModalConditionResponse', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('TopHub', 'GetRsRecommendModalCondition', null, 'GetRsRecommendModalConditionResponse', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('TopHub', 'GetRsRecommendModalCondition', null, 'GetRsRecommendModalConditionResponse', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetRSRecommendationInfoForRSMailUser = (receiveCallBack: (data: GetRSRecommendationInfoForRSMailUserResponseHubDto) => void, receiveErrorCallBack: (data: GetRSRecommendationInfoForRSMailUserResponseHubDto) => void, noSessionCallBack: (data: GetRSRecommendationInfoForRSMailUserResponseHubDto) => void, completeCallBack: (data: GetRSRecommendationInfoForRSMailUserResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: GetRSRecommendationInfoForRSMailUserResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: GetRSRecommendationInfoForRSMailUserResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/TopHub/GetRSRecommendationInfoForRSMailUser';

        let responseData: GetRSRecommendationInfoForRSMailUserResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('TopHub', 'GetRSRecommendationInfoForRSMailUser', null, 'GetRSRecommendationInfoForRSMailUserResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('TopHub', 'GetRSRecommendationInfoForRSMailUser', null, 'GetRSRecommendationInfoForRSMailUserResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('TopHub', 'GetRSRecommendationInfoForRSMailUser', null, 'GetRSRecommendationInfoForRSMailUserResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public SearchOnamaeHelpArticles = (requestData: SearchHelpArticlesRequestHubDto, receiveCallBack: (data: SearchHelpArticlesResponseHubDto) => void, receiveErrorCallBack: (data: SearchHelpArticlesResponseHubDto) => void, noSessionCallBack: (data: SearchHelpArticlesResponseHubDto) => void, completeCallBack: (data: SearchHelpArticlesResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SearchHelpArticlesResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SearchHelpArticlesResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/TopHub/SearchOnamaeHelpArticles';

        let responseData: SearchHelpArticlesResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('TopHub', 'SearchOnamaeHelpArticles', 'SearchHelpArticlesRequestHubDto', 'SearchHelpArticlesResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('TopHub', 'SearchOnamaeHelpArticles', 'SearchHelpArticlesRequestHubDto', 'SearchHelpArticlesResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('TopHub', 'SearchOnamaeHelpArticles', 'SearchHelpArticlesRequestHubDto', 'SearchHelpArticlesResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetTopMessages = (receiveCallBack: (data: TopMessageResponseHubDto) => void, receiveErrorCallBack: (data: TopMessageResponseHubDto) => void, noSessionCallBack: (data: TopMessageResponseHubDto) => void, completeCallBack: (data: TopMessageResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: TopMessageResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: TopMessageResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/TopHub/GetTopMessages';

        let responseData: TopMessageResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('TopHub', 'GetTopMessages', null, 'TopMessageResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('TopHub', 'GetTopMessages', null, 'TopMessageResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('TopHub', 'GetTopMessages', null, 'TopMessageResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetTopMessagesBanner = (receiveCallBack: (data: TopMessageResponseHubDto) => void, receiveErrorCallBack: (data: TopMessageResponseHubDto) => void, noSessionCallBack: (data: TopMessageResponseHubDto) => void, completeCallBack: (data: TopMessageResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: TopMessageResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: TopMessageResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/TopHub/GetTopMessagesBanner';

        let responseData: TopMessageResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('TopHub', 'GetTopMessagesBanner', null, 'TopMessageResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('TopHub', 'GetTopMessagesBanner', null, 'TopMessageResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('TopHub', 'GetTopMessagesBanner', null, 'TopMessageResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public CheckUsingHostingService = (receiveCallBack: (data: CheckUsingHostingServiceResponse) => void, receiveErrorCallBack: (data: CheckUsingHostingServiceResponse) => void, noSessionCallBack: (data: CheckUsingHostingServiceResponse) => void, completeCallBack: (data: CheckUsingHostingServiceResponse) => void = null, receiveUnExceptedErrorCallBack: (data: CheckUsingHostingServiceResponse) => void, reciveSubMaintenanceModeCallBack:(data: CheckUsingHostingServiceResponse) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/TopHub/CheckUsingHostingService';

        let responseData: CheckUsingHostingServiceResponse = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('TopHub', 'CheckUsingHostingService', null, 'CheckUsingHostingServiceResponse', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('TopHub', 'CheckUsingHostingService', null, 'CheckUsingHostingServiceResponse', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('TopHub', 'CheckUsingHostingService', null, 'CheckUsingHostingServiceResponse', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }

}



export class WpCloudHubManager {
    /**
     * コンストラクタ
     */
    constructor(private http: HttpClient) {
    }

	// リソース破棄
    public Dispose = (): void => {
    }



    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public WPCloudList = (requestData: WpCloudListRequestHubDto, receiveCallBack: (data: WpCloudListResponseHubDto) => void, receiveErrorCallBack: (data: WpCloudListResponseHubDto) => void, noSessionCallBack: (data: WpCloudListResponseHubDto) => void, completeCallBack: (data: WpCloudListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: WpCloudListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: WpCloudListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/WpCloudHub/WPCloudList';

        let responseData: WpCloudListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('WpCloudHub', 'WPCloudList', 'WpCloudListRequestHubDto', 'WpCloudListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('WpCloudHub', 'WPCloudList', 'WpCloudListRequestHubDto', 'WpCloudListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('WpCloudHub', 'WPCloudList', 'WpCloudListRequestHubDto', 'WpCloudListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetWpCloudDetail = (requestData: WpCloudDetailRequestHubDto, receiveCallBack: (data: WpCloudDetailResponseHubDto) => void, receiveErrorCallBack: (data: WpCloudDetailResponseHubDto) => void, noSessionCallBack: (data: WpCloudDetailResponseHubDto) => void, completeCallBack: (data: WpCloudDetailResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: WpCloudDetailResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: WpCloudDetailResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/WpCloudHub/GetWpCloudDetail';

        let responseData: WpCloudDetailResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('WpCloudHub', 'GetWpCloudDetail', 'WpCloudDetailRequestHubDto', 'WpCloudDetailResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('WpCloudHub', 'GetWpCloudDetail', 'WpCloudDetailRequestHubDto', 'WpCloudDetailResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('WpCloudHub', 'GetWpCloudDetail', 'WpCloudDetailRequestHubDto', 'WpCloudDetailResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetWpCloudDetailControlPanel = (requestData: WpCloudDetailControlPanelRequestHubDto, receiveCallBack: (data: WpCloudDetailControlPanelResponseHubDto) => void, receiveErrorCallBack: (data: WpCloudDetailControlPanelResponseHubDto) => void, noSessionCallBack: (data: WpCloudDetailControlPanelResponseHubDto) => void, completeCallBack: (data: WpCloudDetailControlPanelResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: WpCloudDetailControlPanelResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: WpCloudDetailControlPanelResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/WpCloudHub/GetWpCloudDetailControlPanel';

        let responseData: WpCloudDetailControlPanelResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('WpCloudHub', 'GetWpCloudDetailControlPanel', 'WpCloudDetailControlPanelRequestHubDto', 'WpCloudDetailControlPanelResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('WpCloudHub', 'GetWpCloudDetailControlPanel', 'WpCloudDetailControlPanelRequestHubDto', 'WpCloudDetailControlPanelResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('WpCloudHub', 'GetWpCloudDetailControlPanel', 'WpCloudDetailControlPanelRequestHubDto', 'WpCloudDetailControlPanelResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetWpCloudDetailOption = (requestData: WpCloudDetailOptionRequestHubDto, receiveCallBack: (data: WpCloudDetailOptionResponseHubDto) => void, receiveErrorCallBack: (data: WpCloudDetailOptionResponseHubDto) => void, noSessionCallBack: (data: WpCloudDetailOptionResponseHubDto) => void, completeCallBack: (data: WpCloudDetailOptionResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: WpCloudDetailOptionResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: WpCloudDetailOptionResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/WpCloudHub/GetWpCloudDetailOption';

        let responseData: WpCloudDetailOptionResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('WpCloudHub', 'GetWpCloudDetailOption', 'WpCloudDetailOptionRequestHubDto', 'WpCloudDetailOptionResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('WpCloudHub', 'GetWpCloudDetailOption', 'WpCloudDetailOptionRequestHubDto', 'WpCloudDetailOptionResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('WpCloudHub', 'GetWpCloudDetailOption', 'WpCloudDetailOptionRequestHubDto', 'WpCloudDetailOptionResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetWpCloudDetailCancelOptionDetail = (requestData: WpCloudDetailCancelOptionDetailRequestHubDto, receiveCallBack: (data: WpCloudDetailCancelOptionDetailResponseHubDto) => void, receiveErrorCallBack: (data: WpCloudDetailCancelOptionDetailResponseHubDto) => void, noSessionCallBack: (data: WpCloudDetailCancelOptionDetailResponseHubDto) => void, completeCallBack: (data: WpCloudDetailCancelOptionDetailResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: WpCloudDetailCancelOptionDetailResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: WpCloudDetailCancelOptionDetailResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/WpCloudHub/GetWpCloudDetailCancelOptionDetail';

        let responseData: WpCloudDetailCancelOptionDetailResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('WpCloudHub', 'GetWpCloudDetailCancelOptionDetail', 'WpCloudDetailCancelOptionDetailRequestHubDto', 'WpCloudDetailCancelOptionDetailResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('WpCloudHub', 'GetWpCloudDetailCancelOptionDetail', 'WpCloudDetailCancelOptionDetailRequestHubDto', 'WpCloudDetailCancelOptionDetailResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('WpCloudHub', 'GetWpCloudDetailCancelOptionDetail', 'WpCloudDetailCancelOptionDetailRequestHubDto', 'WpCloudDetailCancelOptionDetailResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public RevokeWpCloudDetailCancelOption = (requestData: WpCloudDetailRevokeCancelOptionRequestHubDto, receiveCallBack: (data: WpCloudDetailRevokeCancelOptionResponseHubDto) => void, receiveErrorCallBack: (data: WpCloudDetailRevokeCancelOptionResponseHubDto) => void, noSessionCallBack: (data: WpCloudDetailRevokeCancelOptionResponseHubDto) => void, completeCallBack: (data: WpCloudDetailRevokeCancelOptionResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: WpCloudDetailRevokeCancelOptionResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: WpCloudDetailRevokeCancelOptionResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/WpCloudHub/RevokeWpCloudDetailCancelOption';

        let responseData: WpCloudDetailRevokeCancelOptionResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('WpCloudHub', 'RevokeWpCloudDetailCancelOption', 'WpCloudDetailRevokeCancelOptionRequestHubDto', 'WpCloudDetailRevokeCancelOptionResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('WpCloudHub', 'RevokeWpCloudDetailCancelOption', 'WpCloudDetailRevokeCancelOptionRequestHubDto', 'WpCloudDetailRevokeCancelOptionResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('WpCloudHub', 'RevokeWpCloudDetailCancelOption', 'WpCloudDetailRevokeCancelOptionRequestHubDto', 'WpCloudDetailRevokeCancelOptionResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public RevokeWpCloudDetailCancelPlan = (requestData: WpCloudDetailRevokeCancelPlanRequestHubDto, receiveCallBack: (data: WpCloudDetailRevokeCancelPlanResponseHubDto) => void, receiveErrorCallBack: (data: WpCloudDetailRevokeCancelPlanResponseHubDto) => void, noSessionCallBack: (data: WpCloudDetailRevokeCancelPlanResponseHubDto) => void, completeCallBack: (data: WpCloudDetailRevokeCancelPlanResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: WpCloudDetailRevokeCancelPlanResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: WpCloudDetailRevokeCancelPlanResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/WpCloudHub/RevokeWpCloudDetailCancelPlan';

        let responseData: WpCloudDetailRevokeCancelPlanResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('WpCloudHub', 'RevokeWpCloudDetailCancelPlan', 'WpCloudDetailRevokeCancelPlanRequestHubDto', 'WpCloudDetailRevokeCancelPlanResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('WpCloudHub', 'RevokeWpCloudDetailCancelPlan', 'WpCloudDetailRevokeCancelPlanRequestHubDto', 'WpCloudDetailRevokeCancelPlanResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('WpCloudHub', 'RevokeWpCloudDetailCancelPlan', 'WpCloudDetailRevokeCancelPlanRequestHubDto', 'WpCloudDetailRevokeCancelPlanResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetDomainList = (requestData: WpCloudDetailDomainListRequestHubDto, receiveCallBack: (data: WpCloudDetailDomainListResponseHubDto) => void, receiveErrorCallBack: (data: WpCloudDetailDomainListResponseHubDto) => void, noSessionCallBack: (data: WpCloudDetailDomainListResponseHubDto) => void, completeCallBack: (data: WpCloudDetailDomainListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: WpCloudDetailDomainListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: WpCloudDetailDomainListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/WpCloudHub/GetDomainList';

        let responseData: WpCloudDetailDomainListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('WpCloudHub', 'GetDomainList', 'WpCloudDetailDomainListRequestHubDto', 'WpCloudDetailDomainListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('WpCloudHub', 'GetDomainList', 'WpCloudDetailDomainListRequestHubDto', 'WpCloudDetailDomainListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('WpCloudHub', 'GetDomainList', 'WpCloudDetailDomainListRequestHubDto', 'WpCloudDetailDomainListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ValidateWpCloudDetailCancelPlan = (requestData: WpCloudDetailValidateCancelPlanRequestHubDto, receiveCallBack: (data: WpCloudDetailValidateCancelPlanResponseHubDto) => void, receiveErrorCallBack: (data: WpCloudDetailValidateCancelPlanResponseHubDto) => void, noSessionCallBack: (data: WpCloudDetailValidateCancelPlanResponseHubDto) => void, completeCallBack: (data: WpCloudDetailValidateCancelPlanResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: WpCloudDetailValidateCancelPlanResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: WpCloudDetailValidateCancelPlanResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/WpCloudHub/ValidateWpCloudDetailCancelPlan';

        let responseData: WpCloudDetailValidateCancelPlanResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('WpCloudHub', 'ValidateWpCloudDetailCancelPlan', 'WpCloudDetailValidateCancelPlanRequestHubDto', 'WpCloudDetailValidateCancelPlanResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('WpCloudHub', 'ValidateWpCloudDetailCancelPlan', 'WpCloudDetailValidateCancelPlanRequestHubDto', 'WpCloudDetailValidateCancelPlanResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('WpCloudHub', 'ValidateWpCloudDetailCancelPlan', 'WpCloudDetailValidateCancelPlanRequestHubDto', 'WpCloudDetailValidateCancelPlanResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ValidateWpCloudDetailRevokeCancelPlan = (requestData: WpCloudDetailValidateRevokeCancelPlanRequestHubDto, receiveCallBack: (data: WpCloudDetailValidateRevokeCancelPlanResponseHubDto) => void, receiveErrorCallBack: (data: WpCloudDetailValidateRevokeCancelPlanResponseHubDto) => void, noSessionCallBack: (data: WpCloudDetailValidateRevokeCancelPlanResponseHubDto) => void, completeCallBack: (data: WpCloudDetailValidateRevokeCancelPlanResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: WpCloudDetailValidateRevokeCancelPlanResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: WpCloudDetailValidateRevokeCancelPlanResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/WpCloudHub/ValidateWpCloudDetailRevokeCancelPlan';

        let responseData: WpCloudDetailValidateRevokeCancelPlanResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('WpCloudHub', 'ValidateWpCloudDetailRevokeCancelPlan', 'WpCloudDetailValidateRevokeCancelPlanRequestHubDto', 'WpCloudDetailValidateRevokeCancelPlanResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('WpCloudHub', 'ValidateWpCloudDetailRevokeCancelPlan', 'WpCloudDetailValidateRevokeCancelPlanRequestHubDto', 'WpCloudDetailValidateRevokeCancelPlanResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('WpCloudHub', 'ValidateWpCloudDetailRevokeCancelPlan', 'WpCloudDetailValidateRevokeCancelPlanRequestHubDto', 'WpCloudDetailValidateRevokeCancelPlanResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ValidateWpCloudDetailAddOption = (requestData: WpCloudDetailValidateAddOptionRequestHubDto, receiveCallBack: (data: WpCloudDetailValidateAddOptionResponseHubDto) => void, receiveErrorCallBack: (data: WpCloudDetailValidateAddOptionResponseHubDto) => void, noSessionCallBack: (data: WpCloudDetailValidateAddOptionResponseHubDto) => void, completeCallBack: (data: WpCloudDetailValidateAddOptionResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: WpCloudDetailValidateAddOptionResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: WpCloudDetailValidateAddOptionResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/WpCloudHub/ValidateWpCloudDetailAddOption';

        let responseData: WpCloudDetailValidateAddOptionResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('WpCloudHub', 'ValidateWpCloudDetailAddOption', 'WpCloudDetailValidateAddOptionRequestHubDto', 'WpCloudDetailValidateAddOptionResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('WpCloudHub', 'ValidateWpCloudDetailAddOption', 'WpCloudDetailValidateAddOptionRequestHubDto', 'WpCloudDetailValidateAddOptionResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('WpCloudHub', 'ValidateWpCloudDetailAddOption', 'WpCloudDetailValidateAddOptionRequestHubDto', 'WpCloudDetailValidateAddOptionResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ValidateWpCloudDetailCancelOption = (requestData: WpCloudDetailValidateCancelOptionRequestHubDto, receiveCallBack: (data: WpCloudDetailValidateCancelOptionResponseHubDto) => void, receiveErrorCallBack: (data: WpCloudDetailValidateCancelOptionResponseHubDto) => void, noSessionCallBack: (data: WpCloudDetailValidateCancelOptionResponseHubDto) => void, completeCallBack: (data: WpCloudDetailValidateCancelOptionResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: WpCloudDetailValidateCancelOptionResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: WpCloudDetailValidateCancelOptionResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/WpCloudHub/ValidateWpCloudDetailCancelOption';

        let responseData: WpCloudDetailValidateCancelOptionResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('WpCloudHub', 'ValidateWpCloudDetailCancelOption', 'WpCloudDetailValidateCancelOptionRequestHubDto', 'WpCloudDetailValidateCancelOptionResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('WpCloudHub', 'ValidateWpCloudDetailCancelOption', 'WpCloudDetailValidateCancelOptionRequestHubDto', 'WpCloudDetailValidateCancelOptionResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('WpCloudHub', 'ValidateWpCloudDetailCancelOption', 'WpCloudDetailValidateCancelOptionRequestHubDto', 'WpCloudDetailValidateCancelOptionResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ValidateWpCloudDetailRevokeCancelOption = (requestData: WpCloudDetailValidateRevokeCancelOptionRequestHubDto, receiveCallBack: (data: WpCloudDetailValidateRevokeCancelOptionResponseHubDto) => void, receiveErrorCallBack: (data: WpCloudDetailValidateRevokeCancelOptionResponseHubDto) => void, noSessionCallBack: (data: WpCloudDetailValidateRevokeCancelOptionResponseHubDto) => void, completeCallBack: (data: WpCloudDetailValidateRevokeCancelOptionResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: WpCloudDetailValidateRevokeCancelOptionResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: WpCloudDetailValidateRevokeCancelOptionResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/WpCloudHub/ValidateWpCloudDetailRevokeCancelOption';

        let responseData: WpCloudDetailValidateRevokeCancelOptionResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('WpCloudHub', 'ValidateWpCloudDetailRevokeCancelOption', 'WpCloudDetailValidateRevokeCancelOptionRequestHubDto', 'WpCloudDetailValidateRevokeCancelOptionResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('WpCloudHub', 'ValidateWpCloudDetailRevokeCancelOption', 'WpCloudDetailValidateRevokeCancelOptionRequestHubDto', 'WpCloudDetailValidateRevokeCancelOptionResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('WpCloudHub', 'ValidateWpCloudDetailRevokeCancelOption', 'WpCloudDetailValidateRevokeCancelOptionRequestHubDto', 'WpCloudDetailValidateRevokeCancelOptionResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetWpCloudCancelPlanData = (requestData: WpCloudPlanCancelDataRequestHubDto, receiveCallBack: (data: WpCloudPlanCancelDataResponseHubDto) => void, receiveErrorCallBack: (data: WpCloudPlanCancelDataResponseHubDto) => void, noSessionCallBack: (data: WpCloudPlanCancelDataResponseHubDto) => void, completeCallBack: (data: WpCloudPlanCancelDataResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: WpCloudPlanCancelDataResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: WpCloudPlanCancelDataResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/WpCloudHub/GetWpCloudCancelPlanData';

        let responseData: WpCloudPlanCancelDataResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('WpCloudHub', 'GetWpCloudCancelPlanData', 'WpCloudPlanCancelDataRequestHubDto', 'WpCloudPlanCancelDataResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('WpCloudHub', 'GetWpCloudCancelPlanData', 'WpCloudPlanCancelDataRequestHubDto', 'WpCloudPlanCancelDataResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('WpCloudHub', 'GetWpCloudCancelPlanData', 'WpCloudPlanCancelDataRequestHubDto', 'WpCloudPlanCancelDataResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public CancelWpCloudPlan = (requestData: WpCloudPlanCancelRequestHubDto, receiveCallBack: (data: WpCloudPlanCancelResponseHubDto) => void, receiveErrorCallBack: (data: WpCloudPlanCancelResponseHubDto) => void, noSessionCallBack: (data: WpCloudPlanCancelResponseHubDto) => void, completeCallBack: (data: WpCloudPlanCancelResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: WpCloudPlanCancelResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: WpCloudPlanCancelResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/WpCloudHub/CancelWpCloudPlan';

        let responseData: WpCloudPlanCancelResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('WpCloudHub', 'CancelWpCloudPlan', 'WpCloudPlanCancelRequestHubDto', 'WpCloudPlanCancelResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('WpCloudHub', 'CancelWpCloudPlan', 'WpCloudPlanCancelRequestHubDto', 'WpCloudPlanCancelResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('WpCloudHub', 'CancelWpCloudPlan', 'WpCloudPlanCancelRequestHubDto', 'WpCloudPlanCancelResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetWpCloudAddOptions = (requestData: WpCloudOptionAddGetRequestHubDto, receiveCallBack: (data: HostingAddOptionSelectListResponseHubDto) => void, receiveErrorCallBack: (data: HostingAddOptionSelectListResponseHubDto) => void, noSessionCallBack: (data: HostingAddOptionSelectListResponseHubDto) => void, completeCallBack: (data: HostingAddOptionSelectListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: HostingAddOptionSelectListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: HostingAddOptionSelectListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/WpCloudHub/GetWpCloudAddOptions';

        let responseData: HostingAddOptionSelectListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('WpCloudHub', 'GetWpCloudAddOptions', 'WpCloudOptionAddGetRequestHubDto', 'HostingAddOptionSelectListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('WpCloudHub', 'GetWpCloudAddOptions', 'WpCloudOptionAddGetRequestHubDto', 'HostingAddOptionSelectListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('WpCloudHub', 'GetWpCloudAddOptions', 'WpCloudOptionAddGetRequestHubDto', 'HostingAddOptionSelectListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetAddOptionSslDomains = (requestData: WpCloudAddOptionGetSslDomainsRequestHubDto, receiveCallBack: (data: WpCloudAddOptionGetSslDomainsResponseHubDto) => void, receiveErrorCallBack: (data: WpCloudAddOptionGetSslDomainsResponseHubDto) => void, noSessionCallBack: (data: WpCloudAddOptionGetSslDomainsResponseHubDto) => void, completeCallBack: (data: WpCloudAddOptionGetSslDomainsResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: WpCloudAddOptionGetSslDomainsResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: WpCloudAddOptionGetSslDomainsResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/WpCloudHub/GetAddOptionSslDomains';

        let responseData: WpCloudAddOptionGetSslDomainsResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('WpCloudHub', 'GetAddOptionSslDomains', 'WpCloudAddOptionGetSslDomainsRequestHubDto', 'WpCloudAddOptionGetSslDomainsResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('WpCloudHub', 'GetAddOptionSslDomains', 'WpCloudAddOptionGetSslDomainsRequestHubDto', 'WpCloudAddOptionGetSslDomainsResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('WpCloudHub', 'GetAddOptionSslDomains', 'WpCloudAddOptionGetSslDomainsRequestHubDto', 'WpCloudAddOptionGetSslDomainsResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ValidateAddOptionSslInfo = (requestData: WpCloudAddOptionValidateSslInfoRequestHubDto, receiveCallBack: (data: WpCloudAddOptionValidateSslInfoResponseHubDto) => void, receiveErrorCallBack: (data: WpCloudAddOptionValidateSslInfoResponseHubDto) => void, noSessionCallBack: (data: WpCloudAddOptionValidateSslInfoResponseHubDto) => void, completeCallBack: (data: WpCloudAddOptionValidateSslInfoResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: WpCloudAddOptionValidateSslInfoResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: WpCloudAddOptionValidateSslInfoResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/WpCloudHub/ValidateAddOptionSslInfo';

        let responseData: WpCloudAddOptionValidateSslInfoResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('WpCloudHub', 'ValidateAddOptionSslInfo', 'WpCloudAddOptionValidateSslInfoRequestHubDto', 'WpCloudAddOptionValidateSslInfoResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('WpCloudHub', 'ValidateAddOptionSslInfo', 'WpCloudAddOptionValidateSslInfoRequestHubDto', 'WpCloudAddOptionValidateSslInfoResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('WpCloudHub', 'ValidateAddOptionSslInfo', 'WpCloudAddOptionValidateSslInfoRequestHubDto', 'WpCloudAddOptionValidateSslInfoResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ValidateAddOptionWordpress = (requestData: HostingOptionWpSiteInfoRequestHubDto, receiveCallBack: (data: WpCloudAddOptionValidateWordpressResponseHubDto) => void, receiveErrorCallBack: (data: WpCloudAddOptionValidateWordpressResponseHubDto) => void, noSessionCallBack: (data: WpCloudAddOptionValidateWordpressResponseHubDto) => void, completeCallBack: (data: WpCloudAddOptionValidateWordpressResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: WpCloudAddOptionValidateWordpressResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: WpCloudAddOptionValidateWordpressResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/WpCloudHub/ValidateAddOptionWordpress';

        let responseData: WpCloudAddOptionValidateWordpressResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('WpCloudHub', 'ValidateAddOptionWordpress', 'HostingOptionWpSiteInfoRequestHubDto', 'WpCloudAddOptionValidateWordpressResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('WpCloudHub', 'ValidateAddOptionWordpress', 'HostingOptionWpSiteInfoRequestHubDto', 'WpCloudAddOptionValidateWordpressResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('WpCloudHub', 'ValidateAddOptionWordpress', 'HostingOptionWpSiteInfoRequestHubDto', 'WpCloudAddOptionValidateWordpressResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetAddOptionSitelockDomains = (requestData: WpCloudAddOptionSiteLockDomainsGetRequestHubDto, receiveCallBack: (data: WpCloudAddOptionGetSitelockDomainsResponseHubDto) => void, receiveErrorCallBack: (data: WpCloudAddOptionGetSitelockDomainsResponseHubDto) => void, noSessionCallBack: (data: WpCloudAddOptionGetSitelockDomainsResponseHubDto) => void, completeCallBack: (data: WpCloudAddOptionGetSitelockDomainsResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: WpCloudAddOptionGetSitelockDomainsResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: WpCloudAddOptionGetSitelockDomainsResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/WpCloudHub/GetAddOptionSitelockDomains';

        let responseData: WpCloudAddOptionGetSitelockDomainsResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('WpCloudHub', 'GetAddOptionSitelockDomains', 'WpCloudAddOptionSiteLockDomainsGetRequestHubDto', 'WpCloudAddOptionGetSitelockDomainsResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('WpCloudHub', 'GetAddOptionSitelockDomains', 'WpCloudAddOptionSiteLockDomainsGetRequestHubDto', 'WpCloudAddOptionGetSitelockDomainsResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('WpCloudHub', 'GetAddOptionSitelockDomains', 'WpCloudAddOptionSiteLockDomainsGetRequestHubDto', 'WpCloudAddOptionGetSitelockDomainsResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetAddOptionWpCloudIpInfo = (requestData: WpCloudAddOptionGetWpCloudIpInfoRequestHubDto, receiveCallBack: (data: WpCloudAddOptionGetWpCloudIpInfosResponseHubDto) => void, receiveErrorCallBack: (data: WpCloudAddOptionGetWpCloudIpInfosResponseHubDto) => void, noSessionCallBack: (data: WpCloudAddOptionGetWpCloudIpInfosResponseHubDto) => void, completeCallBack: (data: WpCloudAddOptionGetWpCloudIpInfosResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: WpCloudAddOptionGetWpCloudIpInfosResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: WpCloudAddOptionGetWpCloudIpInfosResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/WpCloudHub/GetAddOptionWpCloudIpInfo';

        let responseData: WpCloudAddOptionGetWpCloudIpInfosResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('WpCloudHub', 'GetAddOptionWpCloudIpInfo', 'WpCloudAddOptionGetWpCloudIpInfoRequestHubDto', 'WpCloudAddOptionGetWpCloudIpInfosResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('WpCloudHub', 'GetAddOptionWpCloudIpInfo', 'WpCloudAddOptionGetWpCloudIpInfoRequestHubDto', 'WpCloudAddOptionGetWpCloudIpInfosResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('WpCloudHub', 'GetAddOptionWpCloudIpInfo', 'WpCloudAddOptionGetWpCloudIpInfoRequestHubDto', 'WpCloudAddOptionGetWpCloudIpInfosResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetAddOptionCountriesAndPrefectures = (receiveCallBack: (data: HostingCountriesAndPrefecturesResponseHubDto) => void, receiveErrorCallBack: (data: HostingCountriesAndPrefecturesResponseHubDto) => void, noSessionCallBack: (data: HostingCountriesAndPrefecturesResponseHubDto) => void, completeCallBack: (data: HostingCountriesAndPrefecturesResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: HostingCountriesAndPrefecturesResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: HostingCountriesAndPrefecturesResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/WpCloudHub/GetAddOptionCountriesAndPrefectures';

        let responseData: HostingCountriesAndPrefecturesResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('WpCloudHub', 'GetAddOptionCountriesAndPrefectures', null, 'HostingCountriesAndPrefecturesResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('WpCloudHub', 'GetAddOptionCountriesAndPrefectures', null, 'HostingCountriesAndPrefecturesResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('WpCloudHub', 'GetAddOptionCountriesAndPrefectures', null, 'HostingCountriesAndPrefecturesResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetWpCloudAddOptionDetail = (requestData: HostingAddOptionRequestHubDto, receiveCallBack: (data: WpCloudOptionAddDetailGetResponseHubDto) => void, receiveErrorCallBack: (data: WpCloudOptionAddDetailGetResponseHubDto) => void, noSessionCallBack: (data: WpCloudOptionAddDetailGetResponseHubDto) => void, completeCallBack: (data: WpCloudOptionAddDetailGetResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: WpCloudOptionAddDetailGetResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: WpCloudOptionAddDetailGetResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/WpCloudHub/GetWpCloudAddOptionDetail';

        let responseData: WpCloudOptionAddDetailGetResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('WpCloudHub', 'GetWpCloudAddOptionDetail', 'HostingAddOptionRequestHubDto', 'WpCloudOptionAddDetailGetResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('WpCloudHub', 'GetWpCloudAddOptionDetail', 'HostingAddOptionRequestHubDto', 'WpCloudOptionAddDetailGetResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('WpCloudHub', 'GetWpCloudAddOptionDetail', 'HostingAddOptionRequestHubDto', 'WpCloudOptionAddDetailGetResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public AddWpCloudAddOptions = (requestData: HostingAddOptionRequestHubDto, receiveCallBack: (data: WpCloudOptionAddResponseHubDto) => void, receiveErrorCallBack: (data: WpCloudOptionAddResponseHubDto) => void, noSessionCallBack: (data: WpCloudOptionAddResponseHubDto) => void, completeCallBack: (data: WpCloudOptionAddResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: WpCloudOptionAddResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: WpCloudOptionAddResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/WpCloudHub/AddWpCloudAddOptions';

        let responseData: WpCloudOptionAddResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('WpCloudHub', 'AddWpCloudAddOptions', 'HostingAddOptionRequestHubDto', 'WpCloudOptionAddResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('WpCloudHub', 'AddWpCloudAddOptions', 'HostingAddOptionRequestHubDto', 'WpCloudOptionAddResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('WpCloudHub', 'AddWpCloudAddOptions', 'HostingAddOptionRequestHubDto', 'WpCloudOptionAddResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetWpCloudCancelOptionDetail = (requestData: WpCloudOptionCancelDetailRequestHubDto, receiveCallBack: (data: WpCloudOptionCancelDetailResponseHubDto) => void, receiveErrorCallBack: (data: WpCloudOptionCancelDetailResponseHubDto) => void, noSessionCallBack: (data: WpCloudOptionCancelDetailResponseHubDto) => void, completeCallBack: (data: WpCloudOptionCancelDetailResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: WpCloudOptionCancelDetailResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: WpCloudOptionCancelDetailResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/WpCloudHub/GetWpCloudCancelOptionDetail';

        let responseData: WpCloudOptionCancelDetailResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('WpCloudHub', 'GetWpCloudCancelOptionDetail', 'WpCloudOptionCancelDetailRequestHubDto', 'WpCloudOptionCancelDetailResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('WpCloudHub', 'GetWpCloudCancelOptionDetail', 'WpCloudOptionCancelDetailRequestHubDto', 'WpCloudOptionCancelDetailResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('WpCloudHub', 'GetWpCloudCancelOptionDetail', 'WpCloudOptionCancelDetailRequestHubDto', 'WpCloudOptionCancelDetailResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public CancelWpCloudCancelOption = (requestData: WpCloudOptionCancelRequestHubDto, receiveCallBack: (data: WpCloudOptionCancelResponseHubDto) => void, receiveErrorCallBack: (data: WpCloudOptionCancelResponseHubDto) => void, noSessionCallBack: (data: WpCloudOptionCancelResponseHubDto) => void, completeCallBack: (data: WpCloudOptionCancelResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: WpCloudOptionCancelResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: WpCloudOptionCancelResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/WpCloudHub/CancelWpCloudCancelOption';

        let responseData: WpCloudOptionCancelResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('WpCloudHub', 'CancelWpCloudCancelOption', 'WpCloudOptionCancelRequestHubDto', 'WpCloudOptionCancelResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('WpCloudHub', 'CancelWpCloudCancelOption', 'WpCloudOptionCancelRequestHubDto', 'WpCloudOptionCancelResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('WpCloudHub', 'CancelWpCloudCancelOption', 'WpCloudOptionCancelRequestHubDto', 'WpCloudOptionCancelResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetServerRedirection = (requestData: ServerRedirectionRequestHubDto, receiveCallBack: (data: ServerRedirectionResponseHubDto) => void, receiveErrorCallBack: (data: ServerRedirectionResponseHubDto) => void, noSessionCallBack: (data: ServerRedirectionResponseHubDto) => void, completeCallBack: (data: ServerRedirectionResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: ServerRedirectionResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: ServerRedirectionResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/WpCloudHub/GetServerRedirection';

        let responseData: ServerRedirectionResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('WpCloudHub', 'GetServerRedirection', 'ServerRedirectionRequestHubDto', 'ServerRedirectionResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('WpCloudHub', 'GetServerRedirection', 'ServerRedirectionRequestHubDto', 'ServerRedirectionResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('WpCloudHub', 'GetServerRedirection', 'ServerRedirectionRequestHubDto', 'ServerRedirectionResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }

}



export class MailMarketingHubManager {
    /**
     * コンストラクタ
     */
    constructor(private http: HttpClient) {
    }

	// リソース破棄
    public Dispose = (): void => {
    }



    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public MailMarketingList = (requestData: MailMarketingListRequestHubDto, receiveCallBack: (data: MailMarketingListResponseHubDto) => void, receiveErrorCallBack: (data: MailMarketingListResponseHubDto) => void, noSessionCallBack: (data: MailMarketingListResponseHubDto) => void, completeCallBack: (data: MailMarketingListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: MailMarketingListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: MailMarketingListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/MailMarketingHub/MailMarketingList';

        let responseData: MailMarketingListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('MailMarketingHub', 'MailMarketingList', 'MailMarketingListRequestHubDto', 'MailMarketingListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('MailMarketingHub', 'MailMarketingList', 'MailMarketingListRequestHubDto', 'MailMarketingListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('MailMarketingHub', 'MailMarketingList', 'MailMarketingListRequestHubDto', 'MailMarketingListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetMailMarketingDetail = (requestData: MailMarketingDetailRequestHubDto, receiveCallBack: (data: MailMarketingDetailResponseHubDto) => void, receiveErrorCallBack: (data: MailMarketingDetailResponseHubDto) => void, noSessionCallBack: (data: MailMarketingDetailResponseHubDto) => void, completeCallBack: (data: MailMarketingDetailResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: MailMarketingDetailResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: MailMarketingDetailResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/MailMarketingHub/GetMailMarketingDetail';

        let responseData: MailMarketingDetailResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('MailMarketingHub', 'GetMailMarketingDetail', 'MailMarketingDetailRequestHubDto', 'MailMarketingDetailResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('MailMarketingHub', 'GetMailMarketingDetail', 'MailMarketingDetailRequestHubDto', 'MailMarketingDetailResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('MailMarketingHub', 'GetMailMarketingDetail', 'MailMarketingDetailRequestHubDto', 'MailMarketingDetailResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetMailMarketingDetailControlPanel = (requestData: MailMarketingDetailControlPanelRequestHubDto, receiveCallBack: (data: MailMarketingDetailControlPanelResponseHubDto) => void, receiveErrorCallBack: (data: MailMarketingDetailControlPanelResponseHubDto) => void, noSessionCallBack: (data: MailMarketingDetailControlPanelResponseHubDto) => void, completeCallBack: (data: MailMarketingDetailControlPanelResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: MailMarketingDetailControlPanelResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: MailMarketingDetailControlPanelResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/MailMarketingHub/GetMailMarketingDetailControlPanel';

        let responseData: MailMarketingDetailControlPanelResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('MailMarketingHub', 'GetMailMarketingDetailControlPanel', 'MailMarketingDetailControlPanelRequestHubDto', 'MailMarketingDetailControlPanelResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('MailMarketingHub', 'GetMailMarketingDetailControlPanel', 'MailMarketingDetailControlPanelRequestHubDto', 'MailMarketingDetailControlPanelResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('MailMarketingHub', 'GetMailMarketingDetailControlPanel', 'MailMarketingDetailControlPanelRequestHubDto', 'MailMarketingDetailControlPanelResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ValidateMailMarketingDetailChangePlan = (requestData: MailMarketingDetailValidateChangePlanRequestHubDto, receiveCallBack: (data: MailMarketingDetailValidateChangePlanResponseHubDto) => void, receiveErrorCallBack: (data: MailMarketingDetailValidateChangePlanResponseHubDto) => void, noSessionCallBack: (data: MailMarketingDetailValidateChangePlanResponseHubDto) => void, completeCallBack: (data: MailMarketingDetailValidateChangePlanResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: MailMarketingDetailValidateChangePlanResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: MailMarketingDetailValidateChangePlanResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/MailMarketingHub/ValidateMailMarketingDetailChangePlan';

        let responseData: MailMarketingDetailValidateChangePlanResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('MailMarketingHub', 'ValidateMailMarketingDetailChangePlan', 'MailMarketingDetailValidateChangePlanRequestHubDto', 'MailMarketingDetailValidateChangePlanResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('MailMarketingHub', 'ValidateMailMarketingDetailChangePlan', 'MailMarketingDetailValidateChangePlanRequestHubDto', 'MailMarketingDetailValidateChangePlanResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('MailMarketingHub', 'ValidateMailMarketingDetailChangePlan', 'MailMarketingDetailValidateChangePlanRequestHubDto', 'MailMarketingDetailValidateChangePlanResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ValidateMailMarketingDetailCancelPlan = (requestData: MailMarketingDetailValidateCancelPlanRequestHubDto, receiveCallBack: (data: MailMarketingDetailValidateCancelPlanResponseHubDto) => void, receiveErrorCallBack: (data: MailMarketingDetailValidateCancelPlanResponseHubDto) => void, noSessionCallBack: (data: MailMarketingDetailValidateCancelPlanResponseHubDto) => void, completeCallBack: (data: MailMarketingDetailValidateCancelPlanResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: MailMarketingDetailValidateCancelPlanResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: MailMarketingDetailValidateCancelPlanResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/MailMarketingHub/ValidateMailMarketingDetailCancelPlan';

        let responseData: MailMarketingDetailValidateCancelPlanResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('MailMarketingHub', 'ValidateMailMarketingDetailCancelPlan', 'MailMarketingDetailValidateCancelPlanRequestHubDto', 'MailMarketingDetailValidateCancelPlanResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('MailMarketingHub', 'ValidateMailMarketingDetailCancelPlan', 'MailMarketingDetailValidateCancelPlanRequestHubDto', 'MailMarketingDetailValidateCancelPlanResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('MailMarketingHub', 'ValidateMailMarketingDetailCancelPlan', 'MailMarketingDetailValidateCancelPlanRequestHubDto', 'MailMarketingDetailValidateCancelPlanResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ValidateMailMarketingDetailRevokeCancelPlan = (requestData: MailMarketingDetailValidateRevokeCancelPlanRequestHubDto, receiveCallBack: (data: MailMarketingDetailValidateRevokeCancelPlanResponseHubDto) => void, receiveErrorCallBack: (data: MailMarketingDetailValidateRevokeCancelPlanResponseHubDto) => void, noSessionCallBack: (data: MailMarketingDetailValidateRevokeCancelPlanResponseHubDto) => void, completeCallBack: (data: MailMarketingDetailValidateRevokeCancelPlanResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: MailMarketingDetailValidateRevokeCancelPlanResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: MailMarketingDetailValidateRevokeCancelPlanResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/MailMarketingHub/ValidateMailMarketingDetailRevokeCancelPlan';

        let responseData: MailMarketingDetailValidateRevokeCancelPlanResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('MailMarketingHub', 'ValidateMailMarketingDetailRevokeCancelPlan', 'MailMarketingDetailValidateRevokeCancelPlanRequestHubDto', 'MailMarketingDetailValidateRevokeCancelPlanResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('MailMarketingHub', 'ValidateMailMarketingDetailRevokeCancelPlan', 'MailMarketingDetailValidateRevokeCancelPlanRequestHubDto', 'MailMarketingDetailValidateRevokeCancelPlanResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('MailMarketingHub', 'ValidateMailMarketingDetailRevokeCancelPlan', 'MailMarketingDetailValidateRevokeCancelPlanRequestHubDto', 'MailMarketingDetailValidateRevokeCancelPlanResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetMailMarketingPlan = (requestData: MailMarketingPlanRequestHubDto, receiveCallBack: (data: MailMarketingPlanResponseHubDto) => void, receiveErrorCallBack: (data: MailMarketingPlanResponseHubDto) => void, noSessionCallBack: (data: MailMarketingPlanResponseHubDto) => void, completeCallBack: (data: MailMarketingPlanResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: MailMarketingPlanResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: MailMarketingPlanResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/MailMarketingHub/GetMailMarketingPlan';

        let responseData: MailMarketingPlanResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('MailMarketingHub', 'GetMailMarketingPlan', 'MailMarketingPlanRequestHubDto', 'MailMarketingPlanResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('MailMarketingHub', 'GetMailMarketingPlan', 'MailMarketingPlanRequestHubDto', 'MailMarketingPlanResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('MailMarketingHub', 'GetMailMarketingPlan', 'MailMarketingPlanRequestHubDto', 'MailMarketingPlanResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetMailMarketingPlanChangedDetail = (requestData: MailMarketingPlanChangedDetailRequestHubDto, receiveCallBack: (data: MailMarketingPlanChangedDetailResponseHubDto) => void, receiveErrorCallBack: (data: MailMarketingPlanChangedDetailResponseHubDto) => void, noSessionCallBack: (data: MailMarketingPlanChangedDetailResponseHubDto) => void, completeCallBack: (data: MailMarketingPlanChangedDetailResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: MailMarketingPlanChangedDetailResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: MailMarketingPlanChangedDetailResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/MailMarketingHub/GetMailMarketingPlanChangedDetail';

        let responseData: MailMarketingPlanChangedDetailResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('MailMarketingHub', 'GetMailMarketingPlanChangedDetail', 'MailMarketingPlanChangedDetailRequestHubDto', 'MailMarketingPlanChangedDetailResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('MailMarketingHub', 'GetMailMarketingPlanChangedDetail', 'MailMarketingPlanChangedDetailRequestHubDto', 'MailMarketingPlanChangedDetailResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('MailMarketingHub', 'GetMailMarketingPlanChangedDetail', 'MailMarketingPlanChangedDetailRequestHubDto', 'MailMarketingPlanChangedDetailResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ChangeMailMarketingPlan = (requestData: MailMarketingPlanChangeRequestHubDto, receiveCallBack: (data: MailMarketingPlanChangeResponseHubDto) => void, receiveErrorCallBack: (data: MailMarketingPlanChangeResponseHubDto) => void, noSessionCallBack: (data: MailMarketingPlanChangeResponseHubDto) => void, completeCallBack: (data: MailMarketingPlanChangeResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: MailMarketingPlanChangeResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: MailMarketingPlanChangeResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/MailMarketingHub/ChangeMailMarketingPlan';

        let responseData: MailMarketingPlanChangeResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('MailMarketingHub', 'ChangeMailMarketingPlan', 'MailMarketingPlanChangeRequestHubDto', 'MailMarketingPlanChangeResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('MailMarketingHub', 'ChangeMailMarketingPlan', 'MailMarketingPlanChangeRequestHubDto', 'MailMarketingPlanChangeResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('MailMarketingHub', 'ChangeMailMarketingPlan', 'MailMarketingPlanChangeRequestHubDto', 'MailMarketingPlanChangeResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetMailMarketingCancelPlanData = (requestData: MailMarketingPlanCancelDataRequestHubDto, receiveCallBack: (data: MailMarketingPlanCancelDataResponseHubDto) => void, receiveErrorCallBack: (data: MailMarketingPlanCancelDataResponseHubDto) => void, noSessionCallBack: (data: MailMarketingPlanCancelDataResponseHubDto) => void, completeCallBack: (data: MailMarketingPlanCancelDataResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: MailMarketingPlanCancelDataResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: MailMarketingPlanCancelDataResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/MailMarketingHub/GetMailMarketingCancelPlanData';

        let responseData: MailMarketingPlanCancelDataResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('MailMarketingHub', 'GetMailMarketingCancelPlanData', 'MailMarketingPlanCancelDataRequestHubDto', 'MailMarketingPlanCancelDataResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('MailMarketingHub', 'GetMailMarketingCancelPlanData', 'MailMarketingPlanCancelDataRequestHubDto', 'MailMarketingPlanCancelDataResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('MailMarketingHub', 'GetMailMarketingCancelPlanData', 'MailMarketingPlanCancelDataRequestHubDto', 'MailMarketingPlanCancelDataResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public CancelMailMarketingPlan = (requestData: MailMarketingPlanCancelRequestHubDto, receiveCallBack: (data: MailMarketingPlanCancelResponseHubDto) => void, receiveErrorCallBack: (data: MailMarketingPlanCancelResponseHubDto) => void, noSessionCallBack: (data: MailMarketingPlanCancelResponseHubDto) => void, completeCallBack: (data: MailMarketingPlanCancelResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: MailMarketingPlanCancelResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: MailMarketingPlanCancelResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/MailMarketingHub/CancelMailMarketingPlan';

        let responseData: MailMarketingPlanCancelResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('MailMarketingHub', 'CancelMailMarketingPlan', 'MailMarketingPlanCancelRequestHubDto', 'MailMarketingPlanCancelResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('MailMarketingHub', 'CancelMailMarketingPlan', 'MailMarketingPlanCancelRequestHubDto', 'MailMarketingPlanCancelResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('MailMarketingHub', 'CancelMailMarketingPlan', 'MailMarketingPlanCancelRequestHubDto', 'MailMarketingPlanCancelResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetServerRedirection = (requestData: ServerRedirectionRequestHubDto, receiveCallBack: (data: ServerRedirectionResponseHubDto) => void, receiveErrorCallBack: (data: ServerRedirectionResponseHubDto) => void, noSessionCallBack: (data: ServerRedirectionResponseHubDto) => void, completeCallBack: (data: ServerRedirectionResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: ServerRedirectionResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: ServerRedirectionResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/MailMarketingHub/GetServerRedirection';

        let responseData: ServerRedirectionResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('MailMarketingHub', 'GetServerRedirection', 'ServerRedirectionRequestHubDto', 'ServerRedirectionResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('MailMarketingHub', 'GetServerRedirection', 'ServerRedirectionRequestHubDto', 'ServerRedirectionResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('MailMarketingHub', 'GetServerRedirection', 'ServerRedirectionRequestHubDto', 'ServerRedirectionResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetMailMarketingMessages = (receiveCallBack: (data: MailMarketingMessageResponseHubDto) => void, receiveErrorCallBack: (data: MailMarketingMessageResponseHubDto) => void, noSessionCallBack: (data: MailMarketingMessageResponseHubDto) => void, completeCallBack: (data: MailMarketingMessageResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: MailMarketingMessageResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: MailMarketingMessageResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/MailMarketingHub/GetMailMarketingMessages';

        let responseData: MailMarketingMessageResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('MailMarketingHub', 'GetMailMarketingMessages', null, 'MailMarketingMessageResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('MailMarketingHub', 'GetMailMarketingMessages', null, 'MailMarketingMessageResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('MailMarketingHub', 'GetMailMarketingMessages', null, 'MailMarketingMessageResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }

}



export class MailHubManager {
    /**
     * コンストラクタ
     */
    constructor(private http: HttpClient) {
    }

	// リソース破棄
    public Dispose = (): void => {
    }



    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public MailList = (requestData: MailListRequestHubDto, receiveCallBack: (data: MailListResponseHubDto) => void, receiveErrorCallBack: (data: MailListResponseHubDto) => void, noSessionCallBack: (data: MailListResponseHubDto) => void, completeCallBack: (data: MailListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: MailListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: MailListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/MailHub/MailList';

        let responseData: MailListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('MailHub', 'MailList', 'MailListRequestHubDto', 'MailListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('MailHub', 'MailList', 'MailListRequestHubDto', 'MailListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('MailHub', 'MailList', 'MailListRequestHubDto', 'MailListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetMailDetail = (requestData: MailDetailRequestHubDto, receiveCallBack: (data: MailDetailResponseHubDto) => void, receiveErrorCallBack: (data: MailDetailResponseHubDto) => void, noSessionCallBack: (data: MailDetailResponseHubDto) => void, completeCallBack: (data: MailDetailResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: MailDetailResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: MailDetailResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/MailHub/GetMailDetail';

        let responseData: MailDetailResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('MailHub', 'GetMailDetail', 'MailDetailRequestHubDto', 'MailDetailResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('MailHub', 'GetMailDetail', 'MailDetailRequestHubDto', 'MailDetailResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('MailHub', 'GetMailDetail', 'MailDetailRequestHubDto', 'MailDetailResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetMailDetailControlPanel = (requestData: MailDetailControlPanelRequestHubDto, receiveCallBack: (data: MailDetailControlPanelResponseHubDto) => void, receiveErrorCallBack: (data: MailDetailControlPanelResponseHubDto) => void, noSessionCallBack: (data: MailDetailControlPanelResponseHubDto) => void, completeCallBack: (data: MailDetailControlPanelResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: MailDetailControlPanelResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: MailDetailControlPanelResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/MailHub/GetMailDetailControlPanel';

        let responseData: MailDetailControlPanelResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('MailHub', 'GetMailDetailControlPanel', 'MailDetailControlPanelRequestHubDto', 'MailDetailControlPanelResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('MailHub', 'GetMailDetailControlPanel', 'MailDetailControlPanelRequestHubDto', 'MailDetailControlPanelResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('MailHub', 'GetMailDetailControlPanel', 'MailDetailControlPanelRequestHubDto', 'MailDetailControlPanelResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetMailDetailOption = (requestData: MailDetailOptionRequestHubDto, receiveCallBack: (data: MailDetailOptionResponseHubDto) => void, receiveErrorCallBack: (data: MailDetailOptionResponseHubDto) => void, noSessionCallBack: (data: MailDetailOptionResponseHubDto) => void, completeCallBack: (data: MailDetailOptionResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: MailDetailOptionResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: MailDetailOptionResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/MailHub/GetMailDetailOption';

        let responseData: MailDetailOptionResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('MailHub', 'GetMailDetailOption', 'MailDetailOptionRequestHubDto', 'MailDetailOptionResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('MailHub', 'GetMailDetailOption', 'MailDetailOptionRequestHubDto', 'MailDetailOptionResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('MailHub', 'GetMailDetailOption', 'MailDetailOptionRequestHubDto', 'MailDetailOptionResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public RevokeMailDetailCancelPlan = (requestData: MailDetailCancelPlanRevokeRequestHubDto, receiveCallBack: (data: MailDetailCancelPlanRevokeResponseHubDto) => void, receiveErrorCallBack: (data: MailDetailCancelPlanRevokeResponseHubDto) => void, noSessionCallBack: (data: MailDetailCancelPlanRevokeResponseHubDto) => void, completeCallBack: (data: MailDetailCancelPlanRevokeResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: MailDetailCancelPlanRevokeResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: MailDetailCancelPlanRevokeResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/MailHub/RevokeMailDetailCancelPlan';

        let responseData: MailDetailCancelPlanRevokeResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('MailHub', 'RevokeMailDetailCancelPlan', 'MailDetailCancelPlanRevokeRequestHubDto', 'MailDetailCancelPlanRevokeResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('MailHub', 'RevokeMailDetailCancelPlan', 'MailDetailCancelPlanRevokeRequestHubDto', 'MailDetailCancelPlanRevokeResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('MailHub', 'RevokeMailDetailCancelPlan', 'MailDetailCancelPlanRevokeRequestHubDto', 'MailDetailCancelPlanRevokeResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetMailDetailCancelOptionDetail = (requestData: MailDetailCancelOptionDetailRequestHubDto, receiveCallBack: (data: MailDetailCancelOptionDetailResponseHubDto) => void, receiveErrorCallBack: (data: MailDetailCancelOptionDetailResponseHubDto) => void, noSessionCallBack: (data: MailDetailCancelOptionDetailResponseHubDto) => void, completeCallBack: (data: MailDetailCancelOptionDetailResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: MailDetailCancelOptionDetailResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: MailDetailCancelOptionDetailResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/MailHub/GetMailDetailCancelOptionDetail';

        let responseData: MailDetailCancelOptionDetailResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('MailHub', 'GetMailDetailCancelOptionDetail', 'MailDetailCancelOptionDetailRequestHubDto', 'MailDetailCancelOptionDetailResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('MailHub', 'GetMailDetailCancelOptionDetail', 'MailDetailCancelOptionDetailRequestHubDto', 'MailDetailCancelOptionDetailResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('MailHub', 'GetMailDetailCancelOptionDetail', 'MailDetailCancelOptionDetailRequestHubDto', 'MailDetailCancelOptionDetailResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public RevokeMailDetailCancelOption = (requestData: MailDetailCancelOptionRevokeRequestHubDto, receiveCallBack: (data: MailDetailCancelOptionRevokeResponseHubDto) => void, receiveErrorCallBack: (data: MailDetailCancelOptionRevokeResponseHubDto) => void, noSessionCallBack: (data: MailDetailCancelOptionRevokeResponseHubDto) => void, completeCallBack: (data: MailDetailCancelOptionRevokeResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: MailDetailCancelOptionRevokeResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: MailDetailCancelOptionRevokeResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/MailHub/RevokeMailDetailCancelOption';

        let responseData: MailDetailCancelOptionRevokeResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('MailHub', 'RevokeMailDetailCancelOption', 'MailDetailCancelOptionRevokeRequestHubDto', 'MailDetailCancelOptionRevokeResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('MailHub', 'RevokeMailDetailCancelOption', 'MailDetailCancelOptionRevokeRequestHubDto', 'MailDetailCancelOptionRevokeResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('MailHub', 'RevokeMailDetailCancelOption', 'MailDetailCancelOptionRevokeRequestHubDto', 'MailDetailCancelOptionRevokeResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetMailDetailDomain = (requestData: MailDetailDomainRequestHubDto, receiveCallBack: (data: MailDetailDomainResponseHubDto) => void, receiveErrorCallBack: (data: MailDetailDomainResponseHubDto) => void, noSessionCallBack: (data: MailDetailDomainResponseHubDto) => void, completeCallBack: (data: MailDetailDomainResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: MailDetailDomainResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: MailDetailDomainResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/MailHub/GetMailDetailDomain';

        let responseData: MailDetailDomainResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('MailHub', 'GetMailDetailDomain', 'MailDetailDomainRequestHubDto', 'MailDetailDomainResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('MailHub', 'GetMailDetailDomain', 'MailDetailDomainRequestHubDto', 'MailDetailDomainResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('MailHub', 'GetMailDetailDomain', 'MailDetailDomainRequestHubDto', 'MailDetailDomainResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public CancelMailDetailDomain = (requestData: MailDetailDomainCancelRequestHubDto, receiveCallBack: (data: MailDetailDomainCancelResponseHubDto) => void, receiveErrorCallBack: (data: MailDetailDomainCancelResponseHubDto) => void, noSessionCallBack: (data: MailDetailDomainCancelResponseHubDto) => void, completeCallBack: (data: MailDetailDomainCancelResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: MailDetailDomainCancelResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: MailDetailDomainCancelResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/MailHub/CancelMailDetailDomain';

        let responseData: MailDetailDomainCancelResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('MailHub', 'CancelMailDetailDomain', 'MailDetailDomainCancelRequestHubDto', 'MailDetailDomainCancelResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('MailHub', 'CancelMailDetailDomain', 'MailDetailDomainCancelRequestHubDto', 'MailDetailDomainCancelResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('MailHub', 'CancelMailDetailDomain', 'MailDetailDomainCancelRequestHubDto', 'MailDetailDomainCancelResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ValidateMailDetailChangePlan = (requestData: MailDetailValidateChangePlanRequestHubDto, receiveCallBack: (data: MailDetailValidateChangePlanResponseHubDto) => void, receiveErrorCallBack: (data: MailDetailValidateChangePlanResponseHubDto) => void, noSessionCallBack: (data: MailDetailValidateChangePlanResponseHubDto) => void, completeCallBack: (data: MailDetailValidateChangePlanResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: MailDetailValidateChangePlanResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: MailDetailValidateChangePlanResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/MailHub/ValidateMailDetailChangePlan';

        let responseData: MailDetailValidateChangePlanResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('MailHub', 'ValidateMailDetailChangePlan', 'MailDetailValidateChangePlanRequestHubDto', 'MailDetailValidateChangePlanResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('MailHub', 'ValidateMailDetailChangePlan', 'MailDetailValidateChangePlanRequestHubDto', 'MailDetailValidateChangePlanResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('MailHub', 'ValidateMailDetailChangePlan', 'MailDetailValidateChangePlanRequestHubDto', 'MailDetailValidateChangePlanResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ValidateMailDetailCancelPlan = (requestData: MailDetailValidateCancelPlanRequestHubDto, receiveCallBack: (data: MailDetailValidateCancelPlanResponseHubDto) => void, receiveErrorCallBack: (data: MailDetailValidateCancelPlanResponseHubDto) => void, noSessionCallBack: (data: MailDetailValidateCancelPlanResponseHubDto) => void, completeCallBack: (data: MailDetailValidateCancelPlanResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: MailDetailValidateCancelPlanResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: MailDetailValidateCancelPlanResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/MailHub/ValidateMailDetailCancelPlan';

        let responseData: MailDetailValidateCancelPlanResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('MailHub', 'ValidateMailDetailCancelPlan', 'MailDetailValidateCancelPlanRequestHubDto', 'MailDetailValidateCancelPlanResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('MailHub', 'ValidateMailDetailCancelPlan', 'MailDetailValidateCancelPlanRequestHubDto', 'MailDetailValidateCancelPlanResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('MailHub', 'ValidateMailDetailCancelPlan', 'MailDetailValidateCancelPlanRequestHubDto', 'MailDetailValidateCancelPlanResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ValidateMailDetailRevokeCancelPlan = (requestData: MailDetailValidateRevokeCancelPlanRequestHubDto, receiveCallBack: (data: MailDetailValidateRevokeCancelPlanResponseHubDto) => void, receiveErrorCallBack: (data: MailDetailValidateRevokeCancelPlanResponseHubDto) => void, noSessionCallBack: (data: MailDetailValidateRevokeCancelPlanResponseHubDto) => void, completeCallBack: (data: MailDetailValidateRevokeCancelPlanResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: MailDetailValidateRevokeCancelPlanResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: MailDetailValidateRevokeCancelPlanResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/MailHub/ValidateMailDetailRevokeCancelPlan';

        let responseData: MailDetailValidateRevokeCancelPlanResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('MailHub', 'ValidateMailDetailRevokeCancelPlan', 'MailDetailValidateRevokeCancelPlanRequestHubDto', 'MailDetailValidateRevokeCancelPlanResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('MailHub', 'ValidateMailDetailRevokeCancelPlan', 'MailDetailValidateRevokeCancelPlanRequestHubDto', 'MailDetailValidateRevokeCancelPlanResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('MailHub', 'ValidateMailDetailRevokeCancelPlan', 'MailDetailValidateRevokeCancelPlanRequestHubDto', 'MailDetailValidateRevokeCancelPlanResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ValidateMailDetailAddOption = (requestData: MailDetailValidateAddOptionRequestHubDto, receiveCallBack: (data: MailDetailValidateAddOptionResponseHubDto) => void, receiveErrorCallBack: (data: MailDetailValidateAddOptionResponseHubDto) => void, noSessionCallBack: (data: MailDetailValidateAddOptionResponseHubDto) => void, completeCallBack: (data: MailDetailValidateAddOptionResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: MailDetailValidateAddOptionResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: MailDetailValidateAddOptionResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/MailHub/ValidateMailDetailAddOption';

        let responseData: MailDetailValidateAddOptionResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('MailHub', 'ValidateMailDetailAddOption', 'MailDetailValidateAddOptionRequestHubDto', 'MailDetailValidateAddOptionResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('MailHub', 'ValidateMailDetailAddOption', 'MailDetailValidateAddOptionRequestHubDto', 'MailDetailValidateAddOptionResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('MailHub', 'ValidateMailDetailAddOption', 'MailDetailValidateAddOptionRequestHubDto', 'MailDetailValidateAddOptionResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ValidateMailDetailCancelOption = (requestData: MailDetailValidateCancelOptionRequestHubDto, receiveCallBack: (data: MailDetailValidateCancelOptionResponseHubDto) => void, receiveErrorCallBack: (data: MailDetailValidateCancelOptionResponseHubDto) => void, noSessionCallBack: (data: MailDetailValidateCancelOptionResponseHubDto) => void, completeCallBack: (data: MailDetailValidateCancelOptionResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: MailDetailValidateCancelOptionResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: MailDetailValidateCancelOptionResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/MailHub/ValidateMailDetailCancelOption';

        let responseData: MailDetailValidateCancelOptionResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('MailHub', 'ValidateMailDetailCancelOption', 'MailDetailValidateCancelOptionRequestHubDto', 'MailDetailValidateCancelOptionResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('MailHub', 'ValidateMailDetailCancelOption', 'MailDetailValidateCancelOptionRequestHubDto', 'MailDetailValidateCancelOptionResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('MailHub', 'ValidateMailDetailCancelOption', 'MailDetailValidateCancelOptionRequestHubDto', 'MailDetailValidateCancelOptionResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ValidateMailDetailRevokeCancelOption = (requestData: MailDetailValidateRevokeCancelOptionRequestHubDto, receiveCallBack: (data: MailDetailValidateRevokeCancelOptionResponseHubDto) => void, receiveErrorCallBack: (data: MailDetailValidateRevokeCancelOptionResponseHubDto) => void, noSessionCallBack: (data: MailDetailValidateRevokeCancelOptionResponseHubDto) => void, completeCallBack: (data: MailDetailValidateRevokeCancelOptionResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: MailDetailValidateRevokeCancelOptionResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: MailDetailValidateRevokeCancelOptionResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/MailHub/ValidateMailDetailRevokeCancelOption';

        let responseData: MailDetailValidateRevokeCancelOptionResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('MailHub', 'ValidateMailDetailRevokeCancelOption', 'MailDetailValidateRevokeCancelOptionRequestHubDto', 'MailDetailValidateRevokeCancelOptionResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('MailHub', 'ValidateMailDetailRevokeCancelOption', 'MailDetailValidateRevokeCancelOptionRequestHubDto', 'MailDetailValidateRevokeCancelOptionResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('MailHub', 'ValidateMailDetailRevokeCancelOption', 'MailDetailValidateRevokeCancelOptionRequestHubDto', 'MailDetailValidateRevokeCancelOptionResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ValidateMailDetailCancelDomain = (requestData: MailDetailValidateCancelDomainRequestHubDto, receiveCallBack: (data: MailDetailValidateCancelDomainResponseHubDto) => void, receiveErrorCallBack: (data: MailDetailValidateCancelDomainResponseHubDto) => void, noSessionCallBack: (data: MailDetailValidateCancelDomainResponseHubDto) => void, completeCallBack: (data: MailDetailValidateCancelDomainResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: MailDetailValidateCancelDomainResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: MailDetailValidateCancelDomainResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/MailHub/ValidateMailDetailCancelDomain';

        let responseData: MailDetailValidateCancelDomainResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('MailHub', 'ValidateMailDetailCancelDomain', 'MailDetailValidateCancelDomainRequestHubDto', 'MailDetailValidateCancelDomainResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('MailHub', 'ValidateMailDetailCancelDomain', 'MailDetailValidateCancelDomainRequestHubDto', 'MailDetailValidateCancelDomainResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('MailHub', 'ValidateMailDetailCancelDomain', 'MailDetailValidateCancelDomainRequestHubDto', 'MailDetailValidateCancelDomainResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetMailPlan = (requestData: MailPlanRequestHubDto, receiveCallBack: (data: MailPlanResponseHubDto) => void, receiveErrorCallBack: (data: MailPlanResponseHubDto) => void, noSessionCallBack: (data: MailPlanResponseHubDto) => void, completeCallBack: (data: MailPlanResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: MailPlanResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: MailPlanResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/MailHub/GetMailPlan';

        let responseData: MailPlanResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('MailHub', 'GetMailPlan', 'MailPlanRequestHubDto', 'MailPlanResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('MailHub', 'GetMailPlan', 'MailPlanRequestHubDto', 'MailPlanResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('MailHub', 'GetMailPlan', 'MailPlanRequestHubDto', 'MailPlanResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ChangeMailPlan = (requestData: MailPlanChangeRequestHubDto, receiveCallBack: (data: MailPlanChangeResponseHubDto) => void, receiveErrorCallBack: (data: MailPlanChangeResponseHubDto) => void, noSessionCallBack: (data: MailPlanChangeResponseHubDto) => void, completeCallBack: (data: MailPlanChangeResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: MailPlanChangeResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: MailPlanChangeResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/MailHub/ChangeMailPlan';

        let responseData: MailPlanChangeResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('MailHub', 'ChangeMailPlan', 'MailPlanChangeRequestHubDto', 'MailPlanChangeResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('MailHub', 'ChangeMailPlan', 'MailPlanChangeRequestHubDto', 'MailPlanChangeResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('MailHub', 'ChangeMailPlan', 'MailPlanChangeRequestHubDto', 'MailPlanChangeResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetMailCancelPlanData = (requestData: MailPlanCancelDataRequestHubDto, receiveCallBack: (data: MailPlanCancelDataResponseHubDto) => void, receiveErrorCallBack: (data: MailPlanCancelDataResponseHubDto) => void, noSessionCallBack: (data: MailPlanCancelDataResponseHubDto) => void, completeCallBack: (data: MailPlanCancelDataResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: MailPlanCancelDataResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: MailPlanCancelDataResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/MailHub/GetMailCancelPlanData';

        let responseData: MailPlanCancelDataResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('MailHub', 'GetMailCancelPlanData', 'MailPlanCancelDataRequestHubDto', 'MailPlanCancelDataResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('MailHub', 'GetMailCancelPlanData', 'MailPlanCancelDataRequestHubDto', 'MailPlanCancelDataResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('MailHub', 'GetMailCancelPlanData', 'MailPlanCancelDataRequestHubDto', 'MailPlanCancelDataResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public CancelMailPlan = (requestData: MailPlanCancelRequestHubDto, receiveCallBack: (data: MailPlanCancelResponseHubDto) => void, receiveErrorCallBack: (data: MailPlanCancelResponseHubDto) => void, noSessionCallBack: (data: MailPlanCancelResponseHubDto) => void, completeCallBack: (data: MailPlanCancelResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: MailPlanCancelResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: MailPlanCancelResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/MailHub/CancelMailPlan';

        let responseData: MailPlanCancelResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('MailHub', 'CancelMailPlan', 'MailPlanCancelRequestHubDto', 'MailPlanCancelResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('MailHub', 'CancelMailPlan', 'MailPlanCancelRequestHubDto', 'MailPlanCancelResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('MailHub', 'CancelMailPlan', 'MailPlanCancelRequestHubDto', 'MailPlanCancelResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetMailAddOptions = (requestData: MailOptionAddGetRequestHubDto, receiveCallBack: (data: MailOptionAddSelectListResponseHubDto) => void, receiveErrorCallBack: (data: MailOptionAddSelectListResponseHubDto) => void, noSessionCallBack: (data: MailOptionAddSelectListResponseHubDto) => void, completeCallBack: (data: MailOptionAddSelectListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: MailOptionAddSelectListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: MailOptionAddSelectListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/MailHub/GetMailAddOptions';

        let responseData: MailOptionAddSelectListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('MailHub', 'GetMailAddOptions', 'MailOptionAddGetRequestHubDto', 'MailOptionAddSelectListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('MailHub', 'GetMailAddOptions', 'MailOptionAddGetRequestHubDto', 'MailOptionAddSelectListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('MailHub', 'GetMailAddOptions', 'MailOptionAddGetRequestHubDto', 'MailOptionAddSelectListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetMailAddOptionDetail = (requestData: MailOptionAddDetailGetRequestHubDto, receiveCallBack: (data: MailOptionAddDetailGetResponseHubDto) => void, receiveErrorCallBack: (data: MailOptionAddDetailGetResponseHubDto) => void, noSessionCallBack: (data: MailOptionAddDetailGetResponseHubDto) => void, completeCallBack: (data: MailOptionAddDetailGetResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: MailOptionAddDetailGetResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: MailOptionAddDetailGetResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/MailHub/GetMailAddOptionDetail';

        let responseData: MailOptionAddDetailGetResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('MailHub', 'GetMailAddOptionDetail', 'MailOptionAddDetailGetRequestHubDto', 'MailOptionAddDetailGetResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('MailHub', 'GetMailAddOptionDetail', 'MailOptionAddDetailGetRequestHubDto', 'MailOptionAddDetailGetResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('MailHub', 'GetMailAddOptionDetail', 'MailOptionAddDetailGetRequestHubDto', 'MailOptionAddDetailGetResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetMailCancelOptionDetail = (requestData: MailOptionCancelDetailRequestHubDto, receiveCallBack: (data: MailOptionCancelDetailResponseHubDto) => void, receiveErrorCallBack: (data: MailOptionCancelDetailResponseHubDto) => void, noSessionCallBack: (data: MailOptionCancelDetailResponseHubDto) => void, completeCallBack: (data: MailOptionCancelDetailResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: MailOptionCancelDetailResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: MailOptionCancelDetailResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/MailHub/GetMailCancelOptionDetail';

        let responseData: MailOptionCancelDetailResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('MailHub', 'GetMailCancelOptionDetail', 'MailOptionCancelDetailRequestHubDto', 'MailOptionCancelDetailResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('MailHub', 'GetMailCancelOptionDetail', 'MailOptionCancelDetailRequestHubDto', 'MailOptionCancelDetailResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('MailHub', 'GetMailCancelOptionDetail', 'MailOptionCancelDetailRequestHubDto', 'MailOptionCancelDetailResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public CancelMailCancelOption = (requestData: MailOptionCancelRequestHubDto, receiveCallBack: (data: MailOptionCancelResponseHubDto) => void, receiveErrorCallBack: (data: MailOptionCancelResponseHubDto) => void, noSessionCallBack: (data: MailOptionCancelResponseHubDto) => void, completeCallBack: (data: MailOptionCancelResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: MailOptionCancelResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: MailOptionCancelResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/MailHub/CancelMailCancelOption';

        let responseData: MailOptionCancelResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('MailHub', 'CancelMailCancelOption', 'MailOptionCancelRequestHubDto', 'MailOptionCancelResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('MailHub', 'CancelMailCancelOption', 'MailOptionCancelRequestHubDto', 'MailOptionCancelResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('MailHub', 'CancelMailCancelOption', 'MailOptionCancelRequestHubDto', 'MailOptionCancelResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetMailAddDomain = (requestData: MailAddDomainGetRequestHubDto, receiveCallBack: (data: MailAddDomainGetResponseHubDto) => void, receiveErrorCallBack: (data: MailAddDomainGetResponseHubDto) => void, noSessionCallBack: (data: MailAddDomainGetResponseHubDto) => void, completeCallBack: (data: MailAddDomainGetResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: MailAddDomainGetResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: MailAddDomainGetResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/MailHub/GetMailAddDomain';

        let responseData: MailAddDomainGetResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('MailHub', 'GetMailAddDomain', 'MailAddDomainGetRequestHubDto', 'MailAddDomainGetResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('MailHub', 'GetMailAddDomain', 'MailAddDomainGetRequestHubDto', 'MailAddDomainGetResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('MailHub', 'GetMailAddDomain', 'MailAddDomainGetRequestHubDto', 'MailAddDomainGetResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ValidateMailAddDomainAccount = (requestData: MailAddDomainAccountValidateRequestHubDto, receiveCallBack: (data: MailAddDomainAccountValidateResponseHubDto) => void, receiveErrorCallBack: (data: MailAddDomainAccountValidateResponseHubDto) => void, noSessionCallBack: (data: MailAddDomainAccountValidateResponseHubDto) => void, completeCallBack: (data: MailAddDomainAccountValidateResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: MailAddDomainAccountValidateResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: MailAddDomainAccountValidateResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/MailHub/ValidateMailAddDomainAccount';

        let responseData: MailAddDomainAccountValidateResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('MailHub', 'ValidateMailAddDomainAccount', 'MailAddDomainAccountValidateRequestHubDto', 'MailAddDomainAccountValidateResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('MailHub', 'ValidateMailAddDomainAccount', 'MailAddDomainAccountValidateRequestHubDto', 'MailAddDomainAccountValidateResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('MailHub', 'ValidateMailAddDomainAccount', 'MailAddDomainAccountValidateRequestHubDto', 'MailAddDomainAccountValidateResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public AddMailAddOnamaeDomain = (requestData: MailAddDomainOnamaeAddRequestHubDto, receiveCallBack: (data: MailAddDomainOnamaeAddResponseHubDto) => void, receiveErrorCallBack: (data: MailAddDomainOnamaeAddResponseHubDto) => void, noSessionCallBack: (data: MailAddDomainOnamaeAddResponseHubDto) => void, completeCallBack: (data: MailAddDomainOnamaeAddResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: MailAddDomainOnamaeAddResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: MailAddDomainOnamaeAddResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/MailHub/AddMailAddOnamaeDomain';

        let responseData: MailAddDomainOnamaeAddResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('MailHub', 'AddMailAddOnamaeDomain', 'MailAddDomainOnamaeAddRequestHubDto', 'MailAddDomainOnamaeAddResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('MailHub', 'AddMailAddOnamaeDomain', 'MailAddDomainOnamaeAddRequestHubDto', 'MailAddDomainOnamaeAddResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('MailHub', 'AddMailAddOnamaeDomain', 'MailAddDomainOnamaeAddRequestHubDto', 'MailAddDomainOnamaeAddResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetMailAddOnamaeDomain = (requestData: MailAddDomainOnamaeGetRequestHubDto, receiveCallBack: (data: MailAddDomainOnamaeGetResponseHubDto) => void, receiveErrorCallBack: (data: MailAddDomainOnamaeGetResponseHubDto) => void, noSessionCallBack: (data: MailAddDomainOnamaeGetResponseHubDto) => void, completeCallBack: (data: MailAddDomainOnamaeGetResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: MailAddDomainOnamaeGetResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: MailAddDomainOnamaeGetResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/MailHub/GetMailAddOnamaeDomain';

        let responseData: MailAddDomainOnamaeGetResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('MailHub', 'GetMailAddOnamaeDomain', 'MailAddDomainOnamaeGetRequestHubDto', 'MailAddDomainOnamaeGetResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('MailHub', 'GetMailAddOnamaeDomain', 'MailAddDomainOnamaeGetRequestHubDto', 'MailAddDomainOnamaeGetResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('MailHub', 'GetMailAddOnamaeDomain', 'MailAddDomainOnamaeGetRequestHubDto', 'MailAddDomainOnamaeGetResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public PreValidationMailAddOnamaeDomain = (requestData: MailAddDomainOnamaePreValidationRequestHubDto, receiveCallBack: (data: MailAddDomainOnamaeValidationResponseHubDto) => void, receiveErrorCallBack: (data: MailAddDomainOnamaeValidationResponseHubDto) => void, noSessionCallBack: (data: MailAddDomainOnamaeValidationResponseHubDto) => void, completeCallBack: (data: MailAddDomainOnamaeValidationResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: MailAddDomainOnamaeValidationResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: MailAddDomainOnamaeValidationResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/MailHub/PreValidationMailAddOnamaeDomain';

        let responseData: MailAddDomainOnamaeValidationResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('MailHub', 'PreValidationMailAddOnamaeDomain', 'MailAddDomainOnamaePreValidationRequestHubDto', 'MailAddDomainOnamaeValidationResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('MailHub', 'PreValidationMailAddOnamaeDomain', 'MailAddDomainOnamaePreValidationRequestHubDto', 'MailAddDomainOnamaeValidationResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('MailHub', 'PreValidationMailAddOnamaeDomain', 'MailAddDomainOnamaePreValidationRequestHubDto', 'MailAddDomainOnamaeValidationResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ValidationMailAddOnamaeDomain = (requestData: MailAddDomainOnamaeValidationRequestHubDto, receiveCallBack: (data: MailAddDomainOnamaeValidationResponseHubDto) => void, receiveErrorCallBack: (data: MailAddDomainOnamaeValidationResponseHubDto) => void, noSessionCallBack: (data: MailAddDomainOnamaeValidationResponseHubDto) => void, completeCallBack: (data: MailAddDomainOnamaeValidationResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: MailAddDomainOnamaeValidationResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: MailAddDomainOnamaeValidationResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/MailHub/ValidationMailAddOnamaeDomain';

        let responseData: MailAddDomainOnamaeValidationResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('MailHub', 'ValidationMailAddOnamaeDomain', 'MailAddDomainOnamaeValidationRequestHubDto', 'MailAddDomainOnamaeValidationResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('MailHub', 'ValidationMailAddOnamaeDomain', 'MailAddDomainOnamaeValidationRequestHubDto', 'MailAddDomainOnamaeValidationResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('MailHub', 'ValidationMailAddOnamaeDomain', 'MailAddDomainOnamaeValidationRequestHubDto', 'MailAddDomainOnamaeValidationResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public AddMailAddOtherCompanyDomain = (requestData: MailAddDomainOtherCompanyAddRequestHubDto, receiveCallBack: (data: MailAddDomainOtherCompanyAddResponseHubDto) => void, receiveErrorCallBack: (data: MailAddDomainOtherCompanyAddResponseHubDto) => void, noSessionCallBack: (data: MailAddDomainOtherCompanyAddResponseHubDto) => void, completeCallBack: (data: MailAddDomainOtherCompanyAddResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: MailAddDomainOtherCompanyAddResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: MailAddDomainOtherCompanyAddResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/MailHub/AddMailAddOtherCompanyDomain';

        let responseData: MailAddDomainOtherCompanyAddResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('MailHub', 'AddMailAddOtherCompanyDomain', 'MailAddDomainOtherCompanyAddRequestHubDto', 'MailAddDomainOtherCompanyAddResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('MailHub', 'AddMailAddOtherCompanyDomain', 'MailAddDomainOtherCompanyAddRequestHubDto', 'MailAddDomainOtherCompanyAddResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('MailHub', 'AddMailAddOtherCompanyDomain', 'MailAddDomainOtherCompanyAddRequestHubDto', 'MailAddDomainOtherCompanyAddResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetServerRedirection = (requestData: ServerRedirectionRequestHubDto, receiveCallBack: (data: ServerRedirectionResponseHubDto) => void, receiveErrorCallBack: (data: ServerRedirectionResponseHubDto) => void, noSessionCallBack: (data: ServerRedirectionResponseHubDto) => void, completeCallBack: (data: ServerRedirectionResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: ServerRedirectionResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: ServerRedirectionResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/MailHub/GetServerRedirection';

        let responseData: ServerRedirectionResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('MailHub', 'GetServerRedirection', 'ServerRedirectionRequestHubDto', 'ServerRedirectionResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('MailHub', 'GetServerRedirection', 'ServerRedirectionRequestHubDto', 'ServerRedirectionResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('MailHub', 'GetServerRedirection', 'ServerRedirectionRequestHubDto', 'ServerRedirectionResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetDomainUseMailApplication = (requestData: MailOnamaeDomainSearchRequestHubDto, receiveCallBack: (data: MailOnamaeDomainSearchResponseHubDto) => void, receiveErrorCallBack: (data: MailOnamaeDomainSearchResponseHubDto) => void, noSessionCallBack: (data: MailOnamaeDomainSearchResponseHubDto) => void, completeCallBack: (data: MailOnamaeDomainSearchResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: MailOnamaeDomainSearchResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: MailOnamaeDomainSearchResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/MailHub/GetDomainUseMailApplication';

        let responseData: MailOnamaeDomainSearchResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('MailHub', 'GetDomainUseMailApplication', 'MailOnamaeDomainSearchRequestHubDto', 'MailOnamaeDomainSearchResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('MailHub', 'GetDomainUseMailApplication', 'MailOnamaeDomainSearchRequestHubDto', 'MailOnamaeDomainSearchResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('MailHub', 'GetDomainUseMailApplication', 'MailOnamaeDomainSearchRequestHubDto', 'MailOnamaeDomainSearchResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ChangeDetailSubDomain = (requestData: MailDetailSubDomainChangeRequestHubDto, receiveCallBack: (data: MailDetailSubDomainChangeResponseHubDto) => void, receiveErrorCallBack: (data: MailDetailSubDomainChangeResponseHubDto) => void, noSessionCallBack: (data: MailDetailSubDomainChangeResponseHubDto) => void, completeCallBack: (data: MailDetailSubDomainChangeResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: MailDetailSubDomainChangeResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: MailDetailSubDomainChangeResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/MailHub/ChangeDetailSubDomain';

        let responseData: MailDetailSubDomainChangeResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('MailHub', 'ChangeDetailSubDomain', 'MailDetailSubDomainChangeRequestHubDto', 'MailDetailSubDomainChangeResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('MailHub', 'ChangeDetailSubDomain', 'MailDetailSubDomainChangeRequestHubDto', 'MailDetailSubDomainChangeResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('MailHub', 'ChangeDetailSubDomain', 'MailDetailSubDomainChangeRequestHubDto', 'MailDetailSubDomainChangeResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetMailMessages = (receiveCallBack: (data: MailMessageResponseHubDto) => void, receiveErrorCallBack: (data: MailMessageResponseHubDto) => void, noSessionCallBack: (data: MailMessageResponseHubDto) => void, completeCallBack: (data: MailMessageResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: MailMessageResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: MailMessageResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/MailHub/GetMailMessages';

        let responseData: MailMessageResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('MailHub', 'GetMailMessages', null, 'MailMessageResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('MailHub', 'GetMailMessages', null, 'MailMessageResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('MailHub', 'GetMailMessages', null, 'MailMessageResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }

}



export class DomainHubManager {
    /**
     * コンストラクタ
     */
    constructor(private http: HttpClient) {
    }

	// リソース破棄
    public Dispose = (): void => {
    }



    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetDomainRegistrationPriceWithRs = (requestData: DomainRegistrationPriceWithRsRequestHubDto, receiveCallBack: (data: DomainRegistrationPriceWithRsResponseHubDto) => void, receiveErrorCallBack: (data: DomainRegistrationPriceWithRsResponseHubDto) => void, noSessionCallBack: (data: DomainRegistrationPriceWithRsResponseHubDto) => void, completeCallBack: (data: DomainRegistrationPriceWithRsResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainRegistrationPriceWithRsResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainRegistrationPriceWithRsResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainHub/GetDomainRegistrationPriceWithRs';

        let responseData: DomainRegistrationPriceWithRsResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainHub', 'GetDomainRegistrationPriceWithRs', 'DomainRegistrationPriceWithRsRequestHubDto', 'DomainRegistrationPriceWithRsResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainHub', 'GetDomainRegistrationPriceWithRs', 'DomainRegistrationPriceWithRsRequestHubDto', 'DomainRegistrationPriceWithRsResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainHub', 'GetDomainRegistrationPriceWithRs', 'DomainRegistrationPriceWithRsRequestHubDto', 'DomainRegistrationPriceWithRsResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public OnetimePasswordValidator = (requestData: OnetimePasswordValidatorRequestHubDto, receiveCallBack: (data: DomainOnetimePasswordValidatorResponseHubDto) => void, receiveErrorCallBack: (data: DomainOnetimePasswordValidatorResponseHubDto) => void, noSessionCallBack: (data: DomainOnetimePasswordValidatorResponseHubDto) => void, completeCallBack: (data: DomainOnetimePasswordValidatorResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainOnetimePasswordValidatorResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainOnetimePasswordValidatorResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainHub/OnetimePasswordValidator';

        let responseData: DomainOnetimePasswordValidatorResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainHub', 'OnetimePasswordValidator', 'OnetimePasswordValidatorRequestHubDto', 'DomainOnetimePasswordValidatorResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainHub', 'OnetimePasswordValidator', 'OnetimePasswordValidatorRequestHubDto', 'DomainOnetimePasswordValidatorResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainHub', 'OnetimePasswordValidator', 'OnetimePasswordValidatorRequestHubDto', 'DomainOnetimePasswordValidatorResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public OwnershipDomainDelete = (receiveCallBack: (data: OwnershipDomainDeleteResponseHubDto) => void, receiveErrorCallBack: (data: OwnershipDomainDeleteResponseHubDto) => void, noSessionCallBack: (data: OwnershipDomainDeleteResponseHubDto) => void, completeCallBack: (data: OwnershipDomainDeleteResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: OwnershipDomainDeleteResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: OwnershipDomainDeleteResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainHub/OwnershipDomainDelete';

        let responseData: OwnershipDomainDeleteResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainHub', 'OwnershipDomainDelete', null, 'OwnershipDomainDeleteResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainHub', 'OwnershipDomainDelete', null, 'OwnershipDomainDeleteResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainHub', 'OwnershipDomainDelete', null, 'OwnershipDomainDeleteResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public DomainName = (requestData: DomainNameRequestHubDto, receiveCallBack: (data: DomainNameResponseHubDto) => void, receiveErrorCallBack: (data: DomainNameResponseHubDto) => void, noSessionCallBack: (data: DomainNameResponseHubDto) => void, completeCallBack: (data: DomainNameResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainNameResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainNameResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainHub/DomainName';

        let responseData: DomainNameResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainHub', 'DomainName', 'DomainNameRequestHubDto', 'DomainNameResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainHub', 'DomainName', 'DomainNameRequestHubDto', 'DomainNameResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainHub', 'DomainName', 'DomainNameRequestHubDto', 'DomainNameResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public DomainList = (requestData: DomainListRequestHubDto, receiveCallBack: (data: DomainListResponseHubDto) => void, receiveErrorCallBack: (data: DomainListResponseHubDto) => void, noSessionCallBack: (data: DomainListResponseHubDto) => void, completeCallBack: (data: DomainListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainHub/DomainList';

        let responseData: DomainListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainHub', 'DomainList', 'DomainListRequestHubDto', 'DomainListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainHub', 'DomainList', 'DomainListRequestHubDto', 'DomainListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainHub', 'DomainList', 'DomainListRequestHubDto', 'DomainListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public DomainListRecommend = (requestData: DomainListRecommendRequestHubDto, receiveCallBack: (data: DomainListRecommendResponseHubDto) => void, receiveErrorCallBack: (data: DomainListRecommendResponseHubDto) => void, noSessionCallBack: (data: DomainListRecommendResponseHubDto) => void, completeCallBack: (data: DomainListRecommendResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainListRecommendResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainListRecommendResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainHub/DomainListRecommend';

        let responseData: DomainListRecommendResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainHub', 'DomainListRecommend', 'DomainListRecommendRequestHubDto', 'DomainListRecommendResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainHub', 'DomainListRecommend', 'DomainListRecommendRequestHubDto', 'DomainListRecommendResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainHub', 'DomainListRecommend', 'DomainListRecommendRequestHubDto', 'DomainListRecommendResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public DomainRsRegistList = (requestData: DomainListRecommendRequestHubDto, receiveCallBack: (data: DomainRsRegistListRecommendResponseHubDto) => void, receiveErrorCallBack: (data: DomainRsRegistListRecommendResponseHubDto) => void, noSessionCallBack: (data: DomainRsRegistListRecommendResponseHubDto) => void, completeCallBack: (data: DomainRsRegistListRecommendResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainRsRegistListRecommendResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainRsRegistListRecommendResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainHub/DomainRsRegistList';

        let responseData: DomainRsRegistListRecommendResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainHub', 'DomainRsRegistList', 'DomainListRecommendRequestHubDto', 'DomainRsRegistListRecommendResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainHub', 'DomainRsRegistList', 'DomainListRecommendRequestHubDto', 'DomainRsRegistListRecommendResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainHub', 'DomainRsRegistList', 'DomainListRecommendRequestHubDto', 'DomainRsRegistListRecommendResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public DomainDetail = (requestData: DomainDetailRequestHubDto, receiveCallBack: (data: DomainDetailResponseHubDto) => void, receiveErrorCallBack: (data: DomainDetailResponseHubDto) => void, noSessionCallBack: (data: DomainDetailResponseHubDto) => void, completeCallBack: (data: DomainDetailResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainDetailResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainDetailResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainHub/DomainDetail';

        let responseData: DomainDetailResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainHub', 'DomainDetail', 'DomainDetailRequestHubDto', 'DomainDetailResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainHub', 'DomainDetail', 'DomainDetailRequestHubDto', 'DomainDetailResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainHub', 'DomainDetail', 'DomainDetailRequestHubDto', 'DomainDetailResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public UpdateAuthCode = (requestData: AuthCodeUpdateRequsetHubDto, receiveCallBack: (data: AuthCodeUpdateResponseHubDto) => void, receiveErrorCallBack: (data: AuthCodeUpdateResponseHubDto) => void, noSessionCallBack: (data: AuthCodeUpdateResponseHubDto) => void, completeCallBack: (data: AuthCodeUpdateResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: AuthCodeUpdateResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: AuthCodeUpdateResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainHub/UpdateAuthCode';

        let responseData: AuthCodeUpdateResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainHub', 'UpdateAuthCode', 'AuthCodeUpdateRequsetHubDto', 'AuthCodeUpdateResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainHub', 'UpdateAuthCode', 'AuthCodeUpdateRequsetHubDto', 'AuthCodeUpdateResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainHub', 'UpdateAuthCode', 'AuthCodeUpdateRequsetHubDto', 'AuthCodeUpdateResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public DomainWhoisMail = (requestData: DomainWhoisMailRequestHubDto, receiveCallBack: (data: DomainWhoisMailResponseHubDto) => void, receiveErrorCallBack: (data: DomainWhoisMailResponseHubDto) => void, noSessionCallBack: (data: DomainWhoisMailResponseHubDto) => void, completeCallBack: (data: DomainWhoisMailResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainWhoisMailResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainWhoisMailResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainHub/DomainWhoisMail';

        let responseData: DomainWhoisMailResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainHub', 'DomainWhoisMail', 'DomainWhoisMailRequestHubDto', 'DomainWhoisMailResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainHub', 'DomainWhoisMail', 'DomainWhoisMailRequestHubDto', 'DomainWhoisMailResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainHub', 'DomainWhoisMail', 'DomainWhoisMailRequestHubDto', 'DomainWhoisMailResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public DomainWhoisAccurecyReSendMail = (requestData: DomainWhoisAccurecyReSendMailRequestHubDto, receiveCallBack: (data: DomainWhoisAccurecyReSendMailResponseHubDto) => void, receiveErrorCallBack: (data: DomainWhoisAccurecyReSendMailResponseHubDto) => void, noSessionCallBack: (data: DomainWhoisAccurecyReSendMailResponseHubDto) => void, completeCallBack: (data: DomainWhoisAccurecyReSendMailResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainWhoisAccurecyReSendMailResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainWhoisAccurecyReSendMailResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainHub/DomainWhoisAccurecyReSendMail';

        let responseData: DomainWhoisAccurecyReSendMailResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainHub', 'DomainWhoisAccurecyReSendMail', 'DomainWhoisAccurecyReSendMailRequestHubDto', 'DomainWhoisAccurecyReSendMailResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainHub', 'DomainWhoisAccurecyReSendMail', 'DomainWhoisAccurecyReSendMailRequestHubDto', 'DomainWhoisAccurecyReSendMailResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainHub', 'DomainWhoisAccurecyReSendMail', 'DomainWhoisAccurecyReSendMailRequestHubDto', 'DomainWhoisAccurecyReSendMailResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public DomainNameServerUpdateList = (requestData: DomainListRequestHubDto, receiveCallBack: (data: DomainNameServerUpdateListResponseHubDto) => void, receiveErrorCallBack: (data: DomainNameServerUpdateListResponseHubDto) => void, noSessionCallBack: (data: DomainNameServerUpdateListResponseHubDto) => void, completeCallBack: (data: DomainNameServerUpdateListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainNameServerUpdateListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainNameServerUpdateListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainHub/DomainNameServerUpdateList';

        let responseData: DomainNameServerUpdateListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainHub', 'DomainNameServerUpdateList', 'DomainListRequestHubDto', 'DomainNameServerUpdateListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainHub', 'DomainNameServerUpdateList', 'DomainListRequestHubDto', 'DomainNameServerUpdateListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainHub', 'DomainNameServerUpdateList', 'DomainListRequestHubDto', 'DomainNameServerUpdateListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public DomainNameServerWithRs = (requestData: DomainListRequestHubDto, receiveCallBack: (data: DomainNameServerRenewListResponseHubDto) => void, receiveErrorCallBack: (data: DomainNameServerRenewListResponseHubDto) => void, noSessionCallBack: (data: DomainNameServerRenewListResponseHubDto) => void, completeCallBack: (data: DomainNameServerRenewListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainNameServerRenewListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainNameServerRenewListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainHub/DomainNameServerWithRs';

        let responseData: DomainNameServerRenewListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainHub', 'DomainNameServerWithRs', 'DomainListRequestHubDto', 'DomainNameServerRenewListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainHub', 'DomainNameServerWithRs', 'DomainListRequestHubDto', 'DomainNameServerRenewListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainHub', 'DomainNameServerWithRs', 'DomainListRequestHubDto', 'DomainNameServerRenewListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public TemplateNameServerList = (receiveCallBack: (data: TemplateNameServerListResponseHubDto) => void, receiveErrorCallBack: (data: TemplateNameServerListResponseHubDto) => void, noSessionCallBack: (data: TemplateNameServerListResponseHubDto) => void, completeCallBack: (data: TemplateNameServerListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: TemplateNameServerListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: TemplateNameServerListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainHub/TemplateNameServerList';

        let responseData: TemplateNameServerListResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainHub', 'TemplateNameServerList', null, 'TemplateNameServerListResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainHub', 'TemplateNameServerList', null, 'TemplateNameServerListResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainHub', 'TemplateNameServerList', null, 'TemplateNameServerListResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public DomainNameServerUpdate = (requestData: DomainNameServerUpdateRequestHubDto, receiveCallBack: (data: DomainNameServerUpdateResponseHubDto) => void, receiveErrorCallBack: (data: DomainNameServerUpdateResponseHubDto) => void, noSessionCallBack: (data: DomainNameServerUpdateResponseHubDto) => void, completeCallBack: (data: DomainNameServerUpdateResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainNameServerUpdateResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainNameServerUpdateResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainHub/DomainNameServerUpdate';

        let responseData: DomainNameServerUpdateResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainHub', 'DomainNameServerUpdate', 'DomainNameServerUpdateRequestHubDto', 'DomainNameServerUpdateResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainHub', 'DomainNameServerUpdate', 'DomainNameServerUpdateRequestHubDto', 'DomainNameServerUpdateResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainHub', 'DomainNameServerUpdate', 'DomainNameServerUpdateRequestHubDto', 'DomainNameServerUpdateResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public DomainEmailForwardUpdate = (requestData: DomainEmailForwardUpdateRequestHubDto, receiveCallBack: (data: DomainEmailForwardUpdateResponseHubDto) => void, receiveErrorCallBack: (data: DomainEmailForwardUpdateResponseHubDto) => void, noSessionCallBack: (data: DomainEmailForwardUpdateResponseHubDto) => void, completeCallBack: (data: DomainEmailForwardUpdateResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainEmailForwardUpdateResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainEmailForwardUpdateResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainHub/DomainEmailForwardUpdate';

        let responseData: DomainEmailForwardUpdateResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainHub', 'DomainEmailForwardUpdate', 'DomainEmailForwardUpdateRequestHubDto', 'DomainEmailForwardUpdateResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainHub', 'DomainEmailForwardUpdate', 'DomainEmailForwardUpdateRequestHubDto', 'DomainEmailForwardUpdateResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainHub', 'DomainEmailForwardUpdate', 'DomainEmailForwardUpdateRequestHubDto', 'DomainEmailForwardUpdateResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public DomainStatusGet = (requestData: DomainGetStatusRequestHubDto, receiveCallBack: (data: DomainGetStatusResponseHubDto) => void, receiveErrorCallBack: (data: DomainGetStatusResponseHubDto) => void, noSessionCallBack: (data: DomainGetStatusResponseHubDto) => void, completeCallBack: (data: DomainGetStatusResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainGetStatusResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainGetStatusResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainHub/DomainStatusGet';

        let responseData: DomainGetStatusResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainHub', 'DomainStatusGet', 'DomainGetStatusRequestHubDto', 'DomainGetStatusResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainHub', 'DomainStatusGet', 'DomainGetStatusRequestHubDto', 'DomainGetStatusResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainHub', 'DomainStatusGet', 'DomainGetStatusRequestHubDto', 'DomainGetStatusResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public DomainAutoRenewList = (requestData: DomainAutoRenewListRequestHubDto, receiveCallBack: (data: DomainAutoRenewListResponseHubDto) => void, receiveErrorCallBack: (data: DomainAutoRenewListResponseHubDto) => void, noSessionCallBack: (data: DomainAutoRenewListResponseHubDto) => void, completeCallBack: (data: DomainAutoRenewListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainAutoRenewListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainAutoRenewListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainHub/DomainAutoRenewList';

        let responseData: DomainAutoRenewListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainHub', 'DomainAutoRenewList', 'DomainAutoRenewListRequestHubDto', 'DomainAutoRenewListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainHub', 'DomainAutoRenewList', 'DomainAutoRenewListRequestHubDto', 'DomainAutoRenewListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainHub', 'DomainAutoRenewList', 'DomainAutoRenewListRequestHubDto', 'DomainAutoRenewListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public DomainAutoRenewUpdate = (requestData: DomainAutoRenewUpdateListRequestHubDto, receiveCallBack: (data: DomainAutoRenewUpdateListResponseHubDto) => void, receiveErrorCallBack: (data: DomainAutoRenewUpdateListResponseHubDto) => void, noSessionCallBack: (data: DomainAutoRenewUpdateListResponseHubDto) => void, completeCallBack: (data: DomainAutoRenewUpdateListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainAutoRenewUpdateListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainAutoRenewUpdateListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainHub/DomainAutoRenewUpdate';

        let responseData: DomainAutoRenewUpdateListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainHub', 'DomainAutoRenewUpdate', 'DomainAutoRenewUpdateListRequestHubDto', 'DomainAutoRenewUpdateListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainHub', 'DomainAutoRenewUpdate', 'DomainAutoRenewUpdateListRequestHubDto', 'DomainAutoRenewUpdateListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainHub', 'DomainAutoRenewUpdate', 'DomainAutoRenewUpdateListRequestHubDto', 'DomainAutoRenewUpdateListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public DomainAutoRenewPaymentValidator = (receiveCallBack: (data: DomainAutoRenewPaymentValidatorResponseHubDto) => void, receiveErrorCallBack: (data: DomainAutoRenewPaymentValidatorResponseHubDto) => void, noSessionCallBack: (data: DomainAutoRenewPaymentValidatorResponseHubDto) => void, completeCallBack: (data: DomainAutoRenewPaymentValidatorResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainAutoRenewPaymentValidatorResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainAutoRenewPaymentValidatorResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainHub/DomainAutoRenewPaymentValidator';

        let responseData: DomainAutoRenewPaymentValidatorResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainHub', 'DomainAutoRenewPaymentValidator', null, 'DomainAutoRenewPaymentValidatorResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainHub', 'DomainAutoRenewPaymentValidator', null, 'DomainAutoRenewPaymentValidatorResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainHub', 'DomainAutoRenewPaymentValidator', null, 'DomainAutoRenewPaymentValidatorResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public DomainGroupList = (requestData: DomainGroupListRequestHubDto, receiveCallBack: (data: DomainGroupListResponseHubDto) => void, receiveErrorCallBack: (data: DomainGroupListResponseHubDto) => void, noSessionCallBack: (data: DomainGroupListResponseHubDto) => void, completeCallBack: (data: DomainGroupListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainGroupListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainGroupListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainHub/DomainGroupList';

        let responseData: DomainGroupListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainHub', 'DomainGroupList', 'DomainGroupListRequestHubDto', 'DomainGroupListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainHub', 'DomainGroupList', 'DomainGroupListRequestHubDto', 'DomainGroupListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainHub', 'DomainGroupList', 'DomainGroupListRequestHubDto', 'DomainGroupListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public DomainGroupDelete = (requestData: DomainGroupDeleteRequestHubDto, receiveCallBack: (data: DomainGroupDeleteResponseHubDto) => void, receiveErrorCallBack: (data: DomainGroupDeleteResponseHubDto) => void, noSessionCallBack: (data: DomainGroupDeleteResponseHubDto) => void, completeCallBack: (data: DomainGroupDeleteResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainGroupDeleteResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainGroupDeleteResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainHub/DomainGroupDelete';

        let responseData: DomainGroupDeleteResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainHub', 'DomainGroupDelete', 'DomainGroupDeleteRequestHubDto', 'DomainGroupDeleteResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainHub', 'DomainGroupDelete', 'DomainGroupDeleteRequestHubDto', 'DomainGroupDeleteResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainHub', 'DomainGroupDelete', 'DomainGroupDeleteRequestHubDto', 'DomainGroupDeleteResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public DomainGroupDitail = (requestData: DomainGroupDetailRequestHubDto, receiveCallBack: (data: DomainGroupDitailResponseHubDto) => void, receiveErrorCallBack: (data: DomainGroupDitailResponseHubDto) => void, noSessionCallBack: (data: DomainGroupDitailResponseHubDto) => void, completeCallBack: (data: DomainGroupDitailResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainGroupDitailResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainGroupDitailResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainHub/DomainGroupDitail';

        let responseData: DomainGroupDitailResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainHub', 'DomainGroupDitail', 'DomainGroupDetailRequestHubDto', 'DomainGroupDitailResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainHub', 'DomainGroupDitail', 'DomainGroupDetailRequestHubDto', 'DomainGroupDitailResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainHub', 'DomainGroupDitail', 'DomainGroupDetailRequestHubDto', 'DomainGroupDitailResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public DomainGroupDomainList = (requestData: DomainGroupDomainListRequestHubDto, receiveCallBack: (data: DomainGroupDomainListResponseHubDto) => void, receiveErrorCallBack: (data: DomainGroupDomainListResponseHubDto) => void, noSessionCallBack: (data: DomainGroupDomainListResponseHubDto) => void, completeCallBack: (data: DomainGroupDomainListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainGroupDomainListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainGroupDomainListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainHub/DomainGroupDomainList';

        let responseData: DomainGroupDomainListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainHub', 'DomainGroupDomainList', 'DomainGroupDomainListRequestHubDto', 'DomainGroupDomainListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainHub', 'DomainGroupDomainList', 'DomainGroupDomainListRequestHubDto', 'DomainGroupDomainListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainHub', 'DomainGroupDomainList', 'DomainGroupDomainListRequestHubDto', 'DomainGroupDomainListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public DomainGroupCreate = (requestData: DomainGroupCreateRequestHubDto, receiveCallBack: (data: DomainGroupCreateResponseHubDto) => void, receiveErrorCallBack: (data: DomainGroupCreateResponseHubDto) => void, noSessionCallBack: (data: DomainGroupCreateResponseHubDto) => void, completeCallBack: (data: DomainGroupCreateResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainGroupCreateResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainGroupCreateResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainHub/DomainGroupCreate';

        let responseData: DomainGroupCreateResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainHub', 'DomainGroupCreate', 'DomainGroupCreateRequestHubDto', 'DomainGroupCreateResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainHub', 'DomainGroupCreate', 'DomainGroupCreateRequestHubDto', 'DomainGroupCreateResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainHub', 'DomainGroupCreate', 'DomainGroupCreateRequestHubDto', 'DomainGroupCreateResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public DomainGroupUpdate = (requestData: DomainGroupUpdateRequestHubDto, receiveCallBack: (data: DomainGroupUpdateResponseHubDto) => void, receiveErrorCallBack: (data: DomainGroupUpdateResponseHubDto) => void, noSessionCallBack: (data: DomainGroupUpdateResponseHubDto) => void, completeCallBack: (data: DomainGroupUpdateResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainGroupUpdateResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainGroupUpdateResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainHub/DomainGroupUpdate';

        let responseData: DomainGroupUpdateResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainHub', 'DomainGroupUpdate', 'DomainGroupUpdateRequestHubDto', 'DomainGroupUpdateResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainHub', 'DomainGroupUpdate', 'DomainGroupUpdateRequestHubDto', 'DomainGroupUpdateResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainHub', 'DomainGroupUpdate', 'DomainGroupUpdateRequestHubDto', 'DomainGroupUpdateResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public DomainHistoryList = (requestData: DomainHistoryListRequestHubDto, receiveCallBack: (data: DomainHistoryListResponseHubDto) => void, receiveErrorCallBack: (data: DomainHistoryListResponseHubDto) => void, noSessionCallBack: (data: DomainHistoryListResponseHubDto) => void, completeCallBack: (data: DomainHistoryListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainHistoryListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainHistoryListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainHub/DomainHistoryList';

        let responseData: DomainHistoryListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainHub', 'DomainHistoryList', 'DomainHistoryListRequestHubDto', 'DomainHistoryListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainHub', 'DomainHistoryList', 'DomainHistoryListRequestHubDto', 'DomainHistoryListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainHub', 'DomainHistoryList', 'DomainHistoryListRequestHubDto', 'DomainHistoryListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public DomainUpdateTransferCancel = (requestData: DomainUpdateTransferCancelRequestHubDto, receiveCallBack: (data: DomainUpdateTransferCancelResponseHubDto) => void, receiveErrorCallBack: (data: DomainUpdateTransferCancelResponseHubDto) => void, noSessionCallBack: (data: DomainUpdateTransferCancelResponseHubDto) => void, completeCallBack: (data: DomainUpdateTransferCancelResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainUpdateTransferCancelResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainUpdateTransferCancelResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainHub/DomainUpdateTransferCancel';

        let responseData: DomainUpdateTransferCancelResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainHub', 'DomainUpdateTransferCancel', 'DomainUpdateTransferCancelRequestHubDto', 'DomainUpdateTransferCancelResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainHub', 'DomainUpdateTransferCancel', 'DomainUpdateTransferCancelRequestHubDto', 'DomainUpdateTransferCancelResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainHub', 'DomainUpdateTransferCancel', 'DomainUpdateTransferCancelRequestHubDto', 'DomainUpdateTransferCancelResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public DomainTransferInList = (requestData: DomainTransferInListRequestHubDto, receiveCallBack: (data: DomainTransferInListResponseHubDto) => void, receiveErrorCallBack: (data: DomainTransferInListResponseHubDto) => void, noSessionCallBack: (data: DomainTransferInListResponseHubDto) => void, completeCallBack: (data: DomainTransferInListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainTransferInListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainTransferInListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainHub/DomainTransferInList';

        let responseData: DomainTransferInListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainHub', 'DomainTransferInList', 'DomainTransferInListRequestHubDto', 'DomainTransferInListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainHub', 'DomainTransferInList', 'DomainTransferInListRequestHubDto', 'DomainTransferInListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainHub', 'DomainTransferInList', 'DomainTransferInListRequestHubDto', 'DomainTransferInListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public DomainTransferOutList = (requestData: DomainTransferOutListRequestHubDto, receiveCallBack: (data: DomainTransferOutListResponseHubDto) => void, receiveErrorCallBack: (data: DomainTransferOutListResponseHubDto) => void, noSessionCallBack: (data: DomainTransferOutListResponseHubDto) => void, completeCallBack: (data: DomainTransferOutListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainTransferOutListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainTransferOutListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainHub/DomainTransferOutList';

        let responseData: DomainTransferOutListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainHub', 'DomainTransferOutList', 'DomainTransferOutListRequestHubDto', 'DomainTransferOutListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainHub', 'DomainTransferOutList', 'DomainTransferOutListRequestHubDto', 'DomainTransferOutListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainHub', 'DomainTransferOutList', 'DomainTransferOutListRequestHubDto', 'DomainTransferOutListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public DomainTransferDitailList = (requestData: DomainTransferDitailListRequestHubDto, receiveCallBack: (data: DomainTransferDitailListResponseHubDto) => void, receiveErrorCallBack: (data: DomainTransferDitailListResponseHubDto) => void, noSessionCallBack: (data: DomainTransferDitailListResponseHubDto) => void, completeCallBack: (data: DomainTransferDitailListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainTransferDitailListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainTransferDitailListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainHub/DomainTransferDitailList';

        let responseData: DomainTransferDitailListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainHub', 'DomainTransferDitailList', 'DomainTransferDitailListRequestHubDto', 'DomainTransferDitailListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainHub', 'DomainTransferDitailList', 'DomainTransferDitailListRequestHubDto', 'DomainTransferDitailListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainHub', 'DomainTransferDitailList', 'DomainTransferDitailListRequestHubDto', 'DomainTransferDitailListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public DomainTransferResendMail = (requestData: DomainTransferResendMailRequestHubDto, receiveCallBack: (data: DomainTransferResendMailResponseHubDto) => void, receiveErrorCallBack: (data: DomainTransferResendMailResponseHubDto) => void, noSessionCallBack: (data: DomainTransferResendMailResponseHubDto) => void, completeCallBack: (data: DomainTransferResendMailResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainTransferResendMailResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainTransferResendMailResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainHub/DomainTransferResendMail';

        let responseData: DomainTransferResendMailResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainHub', 'DomainTransferResendMail', 'DomainTransferResendMailRequestHubDto', 'DomainTransferResendMailResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainHub', 'DomainTransferResendMail', 'DomainTransferResendMailRequestHubDto', 'DomainTransferResendMailResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainHub', 'DomainTransferResendMail', 'DomainTransferResendMailRequestHubDto', 'DomainTransferResendMailResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public DomainTransferCancel = (requestData: DomainTransferCancelRequestHubDto, receiveCallBack: (data: DomainTransferCancelResponseHubDto) => void, receiveErrorCallBack: (data: DomainTransferCancelResponseHubDto) => void, noSessionCallBack: (data: DomainTransferCancelResponseHubDto) => void, completeCallBack: (data: DomainTransferCancelResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainTransferCancelResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainTransferCancelResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainHub/DomainTransferCancel';

        let responseData: DomainTransferCancelResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainHub', 'DomainTransferCancel', 'DomainTransferCancelRequestHubDto', 'DomainTransferCancelResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainHub', 'DomainTransferCancel', 'DomainTransferCancelRequestHubDto', 'DomainTransferCancelResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainHub', 'DomainTransferCancel', 'DomainTransferCancelRequestHubDto', 'DomainTransferCancelResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public DomainTransferDitailAuthCode = (requestData: DomainTransferDitailAuthCodeRequestHubDto, receiveCallBack: (data: DomainTransferDitailAuthCodeResponseHubDto) => void, receiveErrorCallBack: (data: DomainTransferDitailAuthCodeResponseHubDto) => void, noSessionCallBack: (data: DomainTransferDitailAuthCodeResponseHubDto) => void, completeCallBack: (data: DomainTransferDitailAuthCodeResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainTransferDitailAuthCodeResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainTransferDitailAuthCodeResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainHub/DomainTransferDitailAuthCode';

        let responseData: DomainTransferDitailAuthCodeResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainHub', 'DomainTransferDitailAuthCode', 'DomainTransferDitailAuthCodeRequestHubDto', 'DomainTransferDitailAuthCodeResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainHub', 'DomainTransferDitailAuthCode', 'DomainTransferDitailAuthCodeRequestHubDto', 'DomainTransferDitailAuthCodeResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainHub', 'DomainTransferDitailAuthCode', 'DomainTransferDitailAuthCodeRequestHubDto', 'DomainTransferDitailAuthCodeResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public DomainTransferModifyAuthCode = (requestData: DomainTransferModifyAuthCodeRequestHubDto, receiveCallBack: (data: DomainTransferModifyAuthCodeResponseHubDto) => void, receiveErrorCallBack: (data: DomainTransferModifyAuthCodeResponseHubDto) => void, noSessionCallBack: (data: DomainTransferModifyAuthCodeResponseHubDto) => void, completeCallBack: (data: DomainTransferModifyAuthCodeResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainTransferModifyAuthCodeResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainTransferModifyAuthCodeResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainHub/DomainTransferModifyAuthCode';

        let responseData: DomainTransferModifyAuthCodeResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainHub', 'DomainTransferModifyAuthCode', 'DomainTransferModifyAuthCodeRequestHubDto', 'DomainTransferModifyAuthCodeResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainHub', 'DomainTransferModifyAuthCode', 'DomainTransferModifyAuthCodeRequestHubDto', 'DomainTransferModifyAuthCodeResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainHub', 'DomainTransferModifyAuthCode', 'DomainTransferModifyAuthCodeRequestHubDto', 'DomainTransferModifyAuthCodeResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public DomainTransferLockList = (requestData: DomainTransferLockListRequestHubDto, receiveCallBack: (data: DomainTransferLockListResponseHubDto) => void, receiveErrorCallBack: (data: DomainTransferLockListResponseHubDto) => void, noSessionCallBack: (data: DomainTransferLockListResponseHubDto) => void, completeCallBack: (data: DomainTransferLockListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainTransferLockListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainTransferLockListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainHub/DomainTransferLockList';

        let responseData: DomainTransferLockListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainHub', 'DomainTransferLockList', 'DomainTransferLockListRequestHubDto', 'DomainTransferLockListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainHub', 'DomainTransferLockList', 'DomainTransferLockListRequestHubDto', 'DomainTransferLockListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainHub', 'DomainTransferLockList', 'DomainTransferLockListRequestHubDto', 'DomainTransferLockListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public DomainTransferLockUpdate = (requestData: DomainTransferLockUpdateListRequestHubDto, receiveCallBack: (data: DomainTransferLockUpdateListResponseHubDto) => void, receiveErrorCallBack: (data: DomainTransferLockUpdateListResponseHubDto) => void, noSessionCallBack: (data: DomainTransferLockUpdateListResponseHubDto) => void, completeCallBack: (data: DomainTransferLockUpdateListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainTransferLockUpdateListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainTransferLockUpdateListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainHub/DomainTransferLockUpdate';

        let responseData: DomainTransferLockUpdateListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainHub', 'DomainTransferLockUpdate', 'DomainTransferLockUpdateListRequestHubDto', 'DomainTransferLockUpdateListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainHub', 'DomainTransferLockUpdate', 'DomainTransferLockUpdateListRequestHubDto', 'DomainTransferLockUpdateListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainHub', 'DomainTransferLockUpdate', 'DomainTransferLockUpdateListRequestHubDto', 'DomainTransferLockUpdateListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public DomainDeleteMailSend = (requestData: DomainDeleteMailSendRequestHubDto, receiveCallBack: (data: DomainDeleteMailSendResponseHubDto) => void, receiveErrorCallBack: (data: DomainDeleteMailSendResponseHubDto) => void, noSessionCallBack: (data: DomainDeleteMailSendResponseHubDto) => void, completeCallBack: (data: DomainDeleteMailSendResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainDeleteMailSendResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainDeleteMailSendResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainHub/DomainDeleteMailSend';

        let responseData: DomainDeleteMailSendResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainHub', 'DomainDeleteMailSend', 'DomainDeleteMailSendRequestHubDto', 'DomainDeleteMailSendResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainHub', 'DomainDeleteMailSend', 'DomainDeleteMailSendRequestHubDto', 'DomainDeleteMailSendResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainHub', 'DomainDeleteMailSend', 'DomainDeleteMailSendRequestHubDto', 'DomainDeleteMailSendResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public RecommendDomainInfoByDomainId = (requestData: RecommendDomainInfoRequestHubDto, receiveCallBack: (data: RecommendDomainInfoResponseHubDto) => void, receiveErrorCallBack: (data: RecommendDomainInfoResponseHubDto) => void, noSessionCallBack: (data: RecommendDomainInfoResponseHubDto) => void, completeCallBack: (data: RecommendDomainInfoResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: RecommendDomainInfoResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: RecommendDomainInfoResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainHub/RecommendDomainInfoByDomainId';

        let responseData: RecommendDomainInfoResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainHub', 'RecommendDomainInfoByDomainId', 'RecommendDomainInfoRequestHubDto', 'RecommendDomainInfoResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainHub', 'RecommendDomainInfoByDomainId', 'RecommendDomainInfoRequestHubDto', 'RecommendDomainInfoResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainHub', 'RecommendDomainInfoByDomainId', 'RecommendDomainInfoRequestHubDto', 'RecommendDomainInfoResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public RecommendList = (requestData: RecommendListRequestHubDto, receiveCallBack: (data: RecommendListResponseHubDto) => void, receiveErrorCallBack: (data: RecommendListResponseHubDto) => void, noSessionCallBack: (data: RecommendListResponseHubDto) => void, completeCallBack: (data: RecommendListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: RecommendListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: RecommendListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainHub/RecommendList';

        let responseData: RecommendListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainHub', 'RecommendList', 'RecommendListRequestHubDto', 'RecommendListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainHub', 'RecommendList', 'RecommendListRequestHubDto', 'RecommendListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainHub', 'RecommendList', 'RecommendListRequestHubDto', 'RecommendListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public DomainCartList = (receiveCallBack: (data: DomainCartListResponseHubDto) => void, receiveErrorCallBack: (data: DomainCartListResponseHubDto) => void, noSessionCallBack: (data: DomainCartListResponseHubDto) => void, completeCallBack: (data: DomainCartListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainCartListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainCartListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainHub/DomainCartList';

        let responseData: DomainCartListResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainHub', 'DomainCartList', null, 'DomainCartListResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainHub', 'DomainCartList', null, 'DomainCartListResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainHub', 'DomainCartList', null, 'DomainCartListResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public DomainCartSearchStringList = (requestData: DomainCartSearchStringListRequestHubDto, receiveCallBack: (data: DomainCartSearchStringListResponseHubDto) => void, receiveErrorCallBack: (data: DomainCartSearchStringListResponseHubDto) => void, noSessionCallBack: (data: DomainCartSearchStringListResponseHubDto) => void, completeCallBack: (data: DomainCartSearchStringListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainCartSearchStringListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainCartSearchStringListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainHub/DomainCartSearchStringList';

        let responseData: DomainCartSearchStringListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainHub', 'DomainCartSearchStringList', 'DomainCartSearchStringListRequestHubDto', 'DomainCartSearchStringListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainHub', 'DomainCartSearchStringList', 'DomainCartSearchStringListRequestHubDto', 'DomainCartSearchStringListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainHub', 'DomainCartSearchStringList', 'DomainCartSearchStringListRequestHubDto', 'DomainCartSearchStringListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetBackOrderDomainList = (receiveCallBack: (data: DomainBackOrderListResponseHubDto) => void, receiveErrorCallBack: (data: DomainBackOrderListResponseHubDto) => void, noSessionCallBack: (data: DomainBackOrderListResponseHubDto) => void, completeCallBack: (data: DomainBackOrderListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainBackOrderListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainBackOrderListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainHub/GetBackOrderDomainList';

        let responseData: DomainBackOrderListResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainHub', 'GetBackOrderDomainList', null, 'DomainBackOrderListResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainHub', 'GetBackOrderDomainList', null, 'DomainBackOrderListResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainHub', 'GetBackOrderDomainList', null, 'DomainBackOrderListResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetBackOrderList = (requestData: DomainBackOrderRequestHubDto, receiveCallBack: (data: BackOrderListResponseHubDto) => void, receiveErrorCallBack: (data: BackOrderListResponseHubDto) => void, noSessionCallBack: (data: BackOrderListResponseHubDto) => void, completeCallBack: (data: BackOrderListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: BackOrderListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: BackOrderListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainHub/GetBackOrderList';

        let responseData: BackOrderListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainHub', 'GetBackOrderList', 'DomainBackOrderRequestHubDto', 'BackOrderListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainHub', 'GetBackOrderList', 'DomainBackOrderRequestHubDto', 'BackOrderListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainHub', 'GetBackOrderList', 'DomainBackOrderRequestHubDto', 'BackOrderListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetBackOrderHistoryList = (requestData: BackOrderHistoryListRequestHubDto, receiveCallBack: (data: BackOrderHistoryListResponseHubDto) => void, receiveErrorCallBack: (data: BackOrderHistoryListResponseHubDto) => void, noSessionCallBack: (data: BackOrderHistoryListResponseHubDto) => void, completeCallBack: (data: BackOrderHistoryListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: BackOrderHistoryListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: BackOrderHistoryListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainHub/GetBackOrderHistoryList';

        let responseData: BackOrderHistoryListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainHub', 'GetBackOrderHistoryList', 'BackOrderHistoryListRequestHubDto', 'BackOrderHistoryListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainHub', 'GetBackOrderHistoryList', 'BackOrderHistoryListRequestHubDto', 'BackOrderHistoryListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainHub', 'GetBackOrderHistoryList', 'BackOrderHistoryListRequestHubDto', 'BackOrderHistoryListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public DeleteBackOrderDomain = (requestData: BackOrderDeleteDomainRequestHubDto, receiveCallBack: (data: BackOrderDeleteDomainResponseHubDto) => void, receiveErrorCallBack: (data: BackOrderDeleteDomainResponseHubDto) => void, noSessionCallBack: (data: BackOrderDeleteDomainResponseHubDto) => void, completeCallBack: (data: BackOrderDeleteDomainResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: BackOrderDeleteDomainResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: BackOrderDeleteDomainResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainHub/DeleteBackOrderDomain';

        let responseData: BackOrderDeleteDomainResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainHub', 'DeleteBackOrderDomain', 'BackOrderDeleteDomainRequestHubDto', 'BackOrderDeleteDomainResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainHub', 'DeleteBackOrderDomain', 'BackOrderDeleteDomainRequestHubDto', 'BackOrderDeleteDomainResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainHub', 'DeleteBackOrderDomain', 'BackOrderDeleteDomainRequestHubDto', 'BackOrderDeleteDomainResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetDomainMessages = (receiveCallBack: (data: DomainMessageResponseHubDto) => void, receiveErrorCallBack: (data: DomainMessageResponseHubDto) => void, noSessionCallBack: (data: DomainMessageResponseHubDto) => void, completeCallBack: (data: DomainMessageResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainMessageResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainMessageResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainHub/GetDomainMessages';

        let responseData: DomainMessageResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainHub', 'GetDomainMessages', null, 'DomainMessageResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainHub', 'GetDomainMessages', null, 'DomainMessageResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainHub', 'GetDomainMessages', null, 'DomainMessageResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetServerRedirection = (requestData: ServerRedirectionRequestHubDto, receiveCallBack: (data: ServerRedirectionResponseHubDto) => void, receiveErrorCallBack: (data: ServerRedirectionResponseHubDto) => void, noSessionCallBack: (data: ServerRedirectionResponseHubDto) => void, completeCallBack: (data: ServerRedirectionResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: ServerRedirectionResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: ServerRedirectionResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainHub/GetServerRedirection';

        let responseData: ServerRedirectionResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainHub', 'GetServerRedirection', 'ServerRedirectionRequestHubDto', 'ServerRedirectionResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainHub', 'GetServerRedirection', 'ServerRedirectionRequestHubDto', 'ServerRedirectionResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainHub', 'GetServerRedirection', 'ServerRedirectionRequestHubDto', 'ServerRedirectionResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetRsDiscountDomain = (requestData: RsDiscountDomainRequestHubDto, receiveCallBack: (data: RsDiscountDomainResponseHubDto) => void, receiveErrorCallBack: (data: RsDiscountDomainResponseHubDto) => void, noSessionCallBack: (data: RsDiscountDomainResponseHubDto) => void, completeCallBack: (data: RsDiscountDomainResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: RsDiscountDomainResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: RsDiscountDomainResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainHub/GetRsDiscountDomain';

        let responseData: RsDiscountDomainResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainHub', 'GetRsDiscountDomain', 'RsDiscountDomainRequestHubDto', 'RsDiscountDomainResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainHub', 'GetRsDiscountDomain', 'RsDiscountDomainRequestHubDto', 'RsDiscountDomainResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainHub', 'GetRsDiscountDomain', 'RsDiscountDomainRequestHubDto', 'RsDiscountDomainResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetSecondaryDomainNameList = (requestData: SecondaryDomainNameRequestHubDto, receiveCallBack: (data: SecondaryDomainNameResponseHubDto) => void, receiveErrorCallBack: (data: SecondaryDomainNameResponseHubDto) => void, noSessionCallBack: (data: SecondaryDomainNameResponseHubDto) => void, completeCallBack: (data: SecondaryDomainNameResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SecondaryDomainNameResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SecondaryDomainNameResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainHub/GetSecondaryDomainNameList';

        let responseData: SecondaryDomainNameResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainHub', 'GetSecondaryDomainNameList', 'SecondaryDomainNameRequestHubDto', 'SecondaryDomainNameResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainHub', 'GetSecondaryDomainNameList', 'SecondaryDomainNameRequestHubDto', 'SecondaryDomainNameResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainHub', 'GetSecondaryDomainNameList', 'SecondaryDomainNameRequestHubDto', 'SecondaryDomainNameResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetSecondaryDomainNameListForDomainSearch = (requestData: SecondaryDomainNameRequestHubDto, receiveCallBack: (data: SecondaryDomainNameForDomainSearchResponseHubDto) => void, receiveErrorCallBack: (data: SecondaryDomainNameForDomainSearchResponseHubDto) => void, noSessionCallBack: (data: SecondaryDomainNameForDomainSearchResponseHubDto) => void, completeCallBack: (data: SecondaryDomainNameForDomainSearchResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SecondaryDomainNameForDomainSearchResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SecondaryDomainNameForDomainSearchResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainHub/GetSecondaryDomainNameListForDomainSearch';

        let responseData: SecondaryDomainNameForDomainSearchResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainHub', 'GetSecondaryDomainNameListForDomainSearch', 'SecondaryDomainNameRequestHubDto', 'SecondaryDomainNameForDomainSearchResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainHub', 'GetSecondaryDomainNameListForDomainSearch', 'SecondaryDomainNameRequestHubDto', 'SecondaryDomainNameForDomainSearchResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainHub', 'GetSecondaryDomainNameListForDomainSearch', 'SecondaryDomainNameRequestHubDto', 'SecondaryDomainNameForDomainSearchResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetSecondaryDomainList = (requestData: SecondaryDomainRequestHubDto, receiveCallBack: (data: SecondaryDomainResponseHubDto) => void, receiveErrorCallBack: (data: SecondaryDomainResponseHubDto) => void, noSessionCallBack: (data: SecondaryDomainResponseHubDto) => void, completeCallBack: (data: SecondaryDomainResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SecondaryDomainResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SecondaryDomainResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainHub/GetSecondaryDomainList';

        let responseData: SecondaryDomainResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainHub', 'GetSecondaryDomainList', 'SecondaryDomainRequestHubDto', 'SecondaryDomainResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainHub', 'GetSecondaryDomainList', 'SecondaryDomainRequestHubDto', 'SecondaryDomainResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainHub', 'GetSecondaryDomainList', 'SecondaryDomainRequestHubDto', 'SecondaryDomainResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public IsJpAuctionBannerTargetAccount = (receiveCallBack: (data: DomainAuctionBannerTargetAccountResponseHubDto) => void, receiveErrorCallBack: (data: DomainAuctionBannerTargetAccountResponseHubDto) => void, noSessionCallBack: (data: DomainAuctionBannerTargetAccountResponseHubDto) => void, completeCallBack: (data: DomainAuctionBannerTargetAccountResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainAuctionBannerTargetAccountResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainAuctionBannerTargetAccountResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainHub/IsJpAuctionBannerTargetAccount';

        let responseData: DomainAuctionBannerTargetAccountResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainHub', 'IsJpAuctionBannerTargetAccount', null, 'DomainAuctionBannerTargetAccountResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainHub', 'IsJpAuctionBannerTargetAccount', null, 'DomainAuctionBannerTargetAccountResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainHub', 'IsJpAuctionBannerTargetAccount', null, 'DomainAuctionBannerTargetAccountResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GmoponValidator = (requestData: GmoPonDomainRequestHubDto, receiveCallBack: (data: GmoponValidatorResponseHubDto) => void, receiveErrorCallBack: (data: GmoponValidatorResponseHubDto) => void, noSessionCallBack: (data: GmoponValidatorResponseHubDto) => void, completeCallBack: (data: GmoponValidatorResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: GmoponValidatorResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: GmoponValidatorResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainHub/GmoponValidator';

        let responseData: GmoponValidatorResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainHub', 'GmoponValidator', 'GmoPonDomainRequestHubDto', 'GmoponValidatorResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainHub', 'GmoponValidator', 'GmoPonDomainRequestHubDto', 'GmoponValidatorResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainHub', 'GmoponValidator', 'GmoPonDomainRequestHubDto', 'GmoponValidatorResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public DomainSwitchIdGetDomainList = (requestData: DomainSwitchIdGetDomainListRequestHubDto, receiveCallBack: (data: DomainSwitchIdGetDomainListResponseHubDto) => void, receiveErrorCallBack: (data: DomainSwitchIdGetDomainListResponseHubDto) => void, noSessionCallBack: (data: DomainSwitchIdGetDomainListResponseHubDto) => void, completeCallBack: (data: DomainSwitchIdGetDomainListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainSwitchIdGetDomainListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainSwitchIdGetDomainListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainHub/DomainSwitchIdGetDomainList';

        let responseData: DomainSwitchIdGetDomainListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainHub', 'DomainSwitchIdGetDomainList', 'DomainSwitchIdGetDomainListRequestHubDto', 'DomainSwitchIdGetDomainListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainHub', 'DomainSwitchIdGetDomainList', 'DomainSwitchIdGetDomainListRequestHubDto', 'DomainSwitchIdGetDomainListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainHub', 'DomainSwitchIdGetDomainList', 'DomainSwitchIdGetDomainListRequestHubDto', 'DomainSwitchIdGetDomainListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public DomainSwitchIdValidation = (requestData: DomainSwitchIdValidateRequestHubDto, receiveCallBack: (data: DomainSwitchIdValidateResponseHubDto) => void, receiveErrorCallBack: (data: DomainSwitchIdValidateResponseHubDto) => void, noSessionCallBack: (data: DomainSwitchIdValidateResponseHubDto) => void, completeCallBack: (data: DomainSwitchIdValidateResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainSwitchIdValidateResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainSwitchIdValidateResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainHub/DomainSwitchIdValidation';

        let responseData: DomainSwitchIdValidateResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainHub', 'DomainSwitchIdValidation', 'DomainSwitchIdValidateRequestHubDto', 'DomainSwitchIdValidateResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainHub', 'DomainSwitchIdValidation', 'DomainSwitchIdValidateRequestHubDto', 'DomainSwitchIdValidateResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainHub', 'DomainSwitchIdValidation', 'DomainSwitchIdValidateRequestHubDto', 'DomainSwitchIdValidateResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public DomainSwitchIdToExistingAccount = (requestData: DomainSwitchIdToExistingAccountRequestHubDto, receiveCallBack: (data: DomainSwitchIdResponseHubDto) => void, receiveErrorCallBack: (data: DomainSwitchIdResponseHubDto) => void, noSessionCallBack: (data: DomainSwitchIdResponseHubDto) => void, completeCallBack: (data: DomainSwitchIdResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainSwitchIdResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainSwitchIdResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainHub/DomainSwitchIdToExistingAccount';

        let responseData: DomainSwitchIdResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainHub', 'DomainSwitchIdToExistingAccount', 'DomainSwitchIdToExistingAccountRequestHubDto', 'DomainSwitchIdResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainHub', 'DomainSwitchIdToExistingAccount', 'DomainSwitchIdToExistingAccountRequestHubDto', 'DomainSwitchIdResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainHub', 'DomainSwitchIdToExistingAccount', 'DomainSwitchIdToExistingAccountRequestHubDto', 'DomainSwitchIdResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public DomainSwitchIdToNewAccount = (requestData: DomainSwitchIdToNewAccountRequestHubDto, receiveCallBack: (data: DomainSwitchIdResponseHubDto) => void, receiveErrorCallBack: (data: DomainSwitchIdResponseHubDto) => void, noSessionCallBack: (data: DomainSwitchIdResponseHubDto) => void, completeCallBack: (data: DomainSwitchIdResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainSwitchIdResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainSwitchIdResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainHub/DomainSwitchIdToNewAccount';

        let responseData: DomainSwitchIdResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainHub', 'DomainSwitchIdToNewAccount', 'DomainSwitchIdToNewAccountRequestHubDto', 'DomainSwitchIdResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainHub', 'DomainSwitchIdToNewAccount', 'DomainSwitchIdToNewAccountRequestHubDto', 'DomainSwitchIdResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainHub', 'DomainSwitchIdToNewAccount', 'DomainSwitchIdToNewAccountRequestHubDto', 'DomainSwitchIdResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public DomainIeraeNetDeShindanCreate = (requestData: DomainIeraeNetDeShindanCreateRequestHubDto, receiveCallBack: (data: DomainIeraeNetDeShindanCreateResponseHubDto) => void, receiveErrorCallBack: (data: DomainIeraeNetDeShindanCreateResponseHubDto) => void, noSessionCallBack: (data: DomainIeraeNetDeShindanCreateResponseHubDto) => void, completeCallBack: (data: DomainIeraeNetDeShindanCreateResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainIeraeNetDeShindanCreateResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainIeraeNetDeShindanCreateResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainHub/DomainIeraeNetDeShindanCreate';

        let responseData: DomainIeraeNetDeShindanCreateResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainHub', 'DomainIeraeNetDeShindanCreate', 'DomainIeraeNetDeShindanCreateRequestHubDto', 'DomainIeraeNetDeShindanCreateResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainHub', 'DomainIeraeNetDeShindanCreate', 'DomainIeraeNetDeShindanCreateRequestHubDto', 'DomainIeraeNetDeShindanCreateResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainHub', 'DomainIeraeNetDeShindanCreate', 'DomainIeraeNetDeShindanCreateRequestHubDto', 'DomainIeraeNetDeShindanCreateResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public DomainIeraeNetDeShindanUrlGet = (requestData: DomainIeraeNetDeShindanUrlGetRequestHubDto, receiveCallBack: (data: DomainIeraeNetDeShindanUrlGetResponseHubDto) => void, receiveErrorCallBack: (data: DomainIeraeNetDeShindanUrlGetResponseHubDto) => void, noSessionCallBack: (data: DomainIeraeNetDeShindanUrlGetResponseHubDto) => void, completeCallBack: (data: DomainIeraeNetDeShindanUrlGetResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainIeraeNetDeShindanUrlGetResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainIeraeNetDeShindanUrlGetResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainHub/DomainIeraeNetDeShindanUrlGet';

        let responseData: DomainIeraeNetDeShindanUrlGetResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainHub', 'DomainIeraeNetDeShindanUrlGet', 'DomainIeraeNetDeShindanUrlGetRequestHubDto', 'DomainIeraeNetDeShindanUrlGetResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainHub', 'DomainIeraeNetDeShindanUrlGet', 'DomainIeraeNetDeShindanUrlGetRequestHubDto', 'DomainIeraeNetDeShindanUrlGetResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainHub', 'DomainIeraeNetDeShindanUrlGet', 'DomainIeraeNetDeShindanUrlGetRequestHubDto', 'DomainIeraeNetDeShindanUrlGetResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public DomainIeraeNetDeShindanList = (requestData: DomainIeraeNetDeShindanListRequestHubDto, receiveCallBack: (data: DomainIeraeNetDeShindanListResponseHubDto) => void, receiveErrorCallBack: (data: DomainIeraeNetDeShindanListResponseHubDto) => void, noSessionCallBack: (data: DomainIeraeNetDeShindanListResponseHubDto) => void, completeCallBack: (data: DomainIeraeNetDeShindanListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainIeraeNetDeShindanListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainIeraeNetDeShindanListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainHub/DomainIeraeNetDeShindanList';

        let responseData: DomainIeraeNetDeShindanListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainHub', 'DomainIeraeNetDeShindanList', 'DomainIeraeNetDeShindanListRequestHubDto', 'DomainIeraeNetDeShindanListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainHub', 'DomainIeraeNetDeShindanList', 'DomainIeraeNetDeShindanListRequestHubDto', 'DomainIeraeNetDeShindanListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainHub', 'DomainIeraeNetDeShindanList', 'DomainIeraeNetDeShindanListRequestHubDto', 'DomainIeraeNetDeShindanListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetDomainTransferApprovalMessages = (receiveCallBack: (data: DomainTransferMessageResponseHubDto) => void, receiveErrorCallBack: (data: DomainTransferMessageResponseHubDto) => void, noSessionCallBack: (data: DomainTransferMessageResponseHubDto) => void, completeCallBack: (data: DomainTransferMessageResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainTransferMessageResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainTransferMessageResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainHub/GetDomainTransferApprovalMessages';

        let responseData: DomainTransferMessageResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainHub', 'GetDomainTransferApprovalMessages', null, 'DomainTransferMessageResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainHub', 'GetDomainTransferApprovalMessages', null, 'DomainTransferMessageResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainHub', 'GetDomainTransferApprovalMessages', null, 'DomainTransferMessageResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public BulkOrderList = (requestData: BulkOrderListRequestHubDto, receiveCallBack: (data: BulkOrderListResponseHubDto) => void, receiveErrorCallBack: (data: BulkOrderListResponseHubDto) => void, noSessionCallBack: (data: BulkOrderListResponseHubDto) => void, completeCallBack: (data: BulkOrderListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: BulkOrderListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: BulkOrderListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainHub/BulkOrderList';

        let responseData: BulkOrderListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainHub', 'BulkOrderList', 'BulkOrderListRequestHubDto', 'BulkOrderListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainHub', 'BulkOrderList', 'BulkOrderListRequestHubDto', 'BulkOrderListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainHub', 'BulkOrderList', 'BulkOrderListRequestHubDto', 'BulkOrderListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public BulkOrderDetail = (requestData: BulkOrderDetailRequestHubDto, receiveCallBack: (data: BulkOrderDetailResponseHubDto) => void, receiveErrorCallBack: (data: BulkOrderDetailResponseHubDto) => void, noSessionCallBack: (data: BulkOrderDetailResponseHubDto) => void, completeCallBack: (data: BulkOrderDetailResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: BulkOrderDetailResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: BulkOrderDetailResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainHub/BulkOrderDetail';

        let responseData: BulkOrderDetailResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainHub', 'BulkOrderDetail', 'BulkOrderDetailRequestHubDto', 'BulkOrderDetailResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainHub', 'BulkOrderDetail', 'BulkOrderDetailRequestHubDto', 'BulkOrderDetailResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainHub', 'BulkOrderDetail', 'BulkOrderDetailRequestHubDto', 'BulkOrderDetailResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public BulkOrderDomainList = (requestData: BulkOrderDetailRequestHubDto, receiveCallBack: (data: BulkOrderDetailResponseHubDto) => void, receiveErrorCallBack: (data: BulkOrderDetailResponseHubDto) => void, noSessionCallBack: (data: BulkOrderDetailResponseHubDto) => void, completeCallBack: (data: BulkOrderDetailResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: BulkOrderDetailResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: BulkOrderDetailResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainHub/BulkOrderDomainList';

        let responseData: BulkOrderDetailResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainHub', 'BulkOrderDomainList', 'BulkOrderDetailRequestHubDto', 'BulkOrderDetailResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainHub', 'BulkOrderDomainList', 'BulkOrderDetailRequestHubDto', 'BulkOrderDetailResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainHub', 'BulkOrderDomainList', 'BulkOrderDetailRequestHubDto', 'BulkOrderDetailResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public BulkOrderPaymentUpdate = (requestData: BulkOrderPaymentUpdateRequestHubDto, receiveCallBack: (data: BulkOrderPaymentUpdateResponseHubDto) => void, receiveErrorCallBack: (data: BulkOrderPaymentUpdateResponseHubDto) => void, noSessionCallBack: (data: BulkOrderPaymentUpdateResponseHubDto) => void, completeCallBack: (data: BulkOrderPaymentUpdateResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: BulkOrderPaymentUpdateResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: BulkOrderPaymentUpdateResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainHub/BulkOrderPaymentUpdate';

        let responseData: BulkOrderPaymentUpdateResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainHub', 'BulkOrderPaymentUpdate', 'BulkOrderPaymentUpdateRequestHubDto', 'BulkOrderPaymentUpdateResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainHub', 'BulkOrderPaymentUpdate', 'BulkOrderPaymentUpdateRequestHubDto', 'BulkOrderPaymentUpdateResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainHub', 'BulkOrderPaymentUpdate', 'BulkOrderPaymentUpdateRequestHubDto', 'BulkOrderPaymentUpdateResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public BulkOrderReCreate = (requestData: BulkOrderDetailRequestHubDto, receiveCallBack: (data: BulkReCreateInputResponseHubDto) => void, receiveErrorCallBack: (data: BulkReCreateInputResponseHubDto) => void, noSessionCallBack: (data: BulkReCreateInputResponseHubDto) => void, completeCallBack: (data: BulkReCreateInputResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: BulkReCreateInputResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: BulkReCreateInputResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainHub/BulkOrderReCreate';

        let responseData: BulkReCreateInputResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainHub', 'BulkOrderReCreate', 'BulkOrderDetailRequestHubDto', 'BulkReCreateInputResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainHub', 'BulkOrderReCreate', 'BulkOrderDetailRequestHubDto', 'BulkReCreateInputResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainHub', 'BulkOrderReCreate', 'BulkOrderDetailRequestHubDto', 'BulkReCreateInputResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public BulkOrderDomainValidator = (requestData: BulkOrderDomainValidatorRequestHubDto, receiveCallBack: (data: BulkOrderDomainValidatorResponseHubDto) => void, receiveErrorCallBack: (data: BulkOrderDomainValidatorResponseHubDto) => void, noSessionCallBack: (data: BulkOrderDomainValidatorResponseHubDto) => void, completeCallBack: (data: BulkOrderDomainValidatorResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: BulkOrderDomainValidatorResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: BulkOrderDomainValidatorResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainHub/BulkOrderDomainValidator';

        let responseData: BulkOrderDomainValidatorResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainHub', 'BulkOrderDomainValidator', 'BulkOrderDomainValidatorRequestHubDto', 'BulkOrderDomainValidatorResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainHub', 'BulkOrderDomainValidator', 'BulkOrderDomainValidatorRequestHubDto', 'BulkOrderDomainValidatorResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainHub', 'BulkOrderDomainValidator', 'BulkOrderDomainValidatorRequestHubDto', 'BulkOrderDomainValidatorResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public BulkOrderUpdateValidator = (requestData: BulkOrderUpdateValidatorRequestHubDto, receiveCallBack: (data: BulkOrderUpdateValidatorResponseHubDto) => void, receiveErrorCallBack: (data: BulkOrderUpdateValidatorResponseHubDto) => void, noSessionCallBack: (data: BulkOrderUpdateValidatorResponseHubDto) => void, completeCallBack: (data: BulkOrderUpdateValidatorResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: BulkOrderUpdateValidatorResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: BulkOrderUpdateValidatorResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainHub/BulkOrderUpdateValidator';

        let responseData: BulkOrderUpdateValidatorResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainHub', 'BulkOrderUpdateValidator', 'BulkOrderUpdateValidatorRequestHubDto', 'BulkOrderUpdateValidatorResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainHub', 'BulkOrderUpdateValidator', 'BulkOrderUpdateValidatorRequestHubDto', 'BulkOrderUpdateValidatorResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainHub', 'BulkOrderUpdateValidator', 'BulkOrderUpdateValidatorRequestHubDto', 'BulkOrderUpdateValidatorResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public BulkOrderPriceForTldr = (requestData: BulkOrderPriceForTldRequestHubDto, receiveCallBack: (data: BulkOrderPriceForTldResponseHubDto) => void, receiveErrorCallBack: (data: BulkOrderPriceForTldResponseHubDto) => void, noSessionCallBack: (data: BulkOrderPriceForTldResponseHubDto) => void, completeCallBack: (data: BulkOrderPriceForTldResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: BulkOrderPriceForTldResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: BulkOrderPriceForTldResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainHub/BulkOrderPriceForTldr';

        let responseData: BulkOrderPriceForTldResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainHub', 'BulkOrderPriceForTldr', 'BulkOrderPriceForTldRequestHubDto', 'BulkOrderPriceForTldResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainHub', 'BulkOrderPriceForTldr', 'BulkOrderPriceForTldRequestHubDto', 'BulkOrderPriceForTldResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainHub', 'BulkOrderPriceForTldr', 'BulkOrderPriceForTldRequestHubDto', 'BulkOrderPriceForTldResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public BulkOrderUpdate = (requestData: BulkOrderUpdateValidatorRequestHubDto, receiveCallBack: (data: BulkOrderUpdateValidatorResponseHubDto) => void, receiveErrorCallBack: (data: BulkOrderUpdateValidatorResponseHubDto) => void, noSessionCallBack: (data: BulkOrderUpdateValidatorResponseHubDto) => void, completeCallBack: (data: BulkOrderUpdateValidatorResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: BulkOrderUpdateValidatorResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: BulkOrderUpdateValidatorResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainHub/BulkOrderUpdate';

        let responseData: BulkOrderUpdateValidatorResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainHub', 'BulkOrderUpdate', 'BulkOrderUpdateValidatorRequestHubDto', 'BulkOrderUpdateValidatorResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainHub', 'BulkOrderUpdate', 'BulkOrderUpdateValidatorRequestHubDto', 'BulkOrderUpdateValidatorResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainHub', 'BulkOrderUpdate', 'BulkOrderUpdateValidatorRequestHubDto', 'BulkOrderUpdateValidatorResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public BulkOrderCommit = (requestData: BulkOrderUpdateValidatorRequestHubDto, receiveCallBack: (data: BulkOrderUpdateValidatorResponseHubDto) => void, receiveErrorCallBack: (data: BulkOrderUpdateValidatorResponseHubDto) => void, noSessionCallBack: (data: BulkOrderUpdateValidatorResponseHubDto) => void, completeCallBack: (data: BulkOrderUpdateValidatorResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: BulkOrderUpdateValidatorResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: BulkOrderUpdateValidatorResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/DomainHub/BulkOrderCommit';

        let responseData: BulkOrderUpdateValidatorResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('DomainHub', 'BulkOrderCommit', 'BulkOrderUpdateValidatorRequestHubDto', 'BulkOrderUpdateValidatorResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('DomainHub', 'BulkOrderCommit', 'BulkOrderUpdateValidatorRequestHubDto', 'BulkOrderUpdateValidatorResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('DomainHub', 'BulkOrderCommit', 'BulkOrderUpdateValidatorRequestHubDto', 'BulkOrderUpdateValidatorResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }

}



export class LoggerHubManager {
    /**
     * コンストラクタ
     */
    constructor(private http: HttpClient) {
    }

	// リソース破棄
    public Dispose = (): void => {
    }


}



export class AuthHubManager {
    /**
     * コンストラクタ
     */
    constructor(private http: HttpClient) {
    }

	// リソース破棄
    public Dispose = (): void => {
    }



    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public Login = (requestData: LoginRequestHubDto, receiveCallBack: (data: LoginResponseHubDto) => void, receiveErrorCallBack: (data: LoginResponseHubDto) => void, noSessionCallBack: (data: LoginResponseHubDto) => void, completeCallBack: (data: LoginResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: LoginResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: LoginResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/AuthHub/Login';

        let responseData: LoginResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('AuthHub', 'Login', 'LoginRequestHubDto', 'LoginResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('AuthHub', 'Login', 'LoginRequestHubDto', 'LoginResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('AuthHub', 'Login', 'LoginRequestHubDto', 'LoginResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public LoginFromOther = (requestData: LoginFromOtherRequestHubDto, receiveCallBack: (data: LoginResponseHubDto) => void, receiveErrorCallBack: (data: LoginResponseHubDto) => void, noSessionCallBack: (data: LoginResponseHubDto) => void, completeCallBack: (data: LoginResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: LoginResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: LoginResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/AuthHub/LoginFromOther';

        let responseData: LoginResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('AuthHub', 'LoginFromOther', 'LoginFromOtherRequestHubDto', 'LoginResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('AuthHub', 'LoginFromOther', 'LoginFromOtherRequestHubDto', 'LoginResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('AuthHub', 'LoginFromOther', 'LoginFromOtherRequestHubDto', 'LoginResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public LoginAccountActionCheck = (receiveCallBack: (data: LoginAccountActionCheckResponseHubDto) => void, receiveErrorCallBack: (data: LoginAccountActionCheckResponseHubDto) => void, noSessionCallBack: (data: LoginAccountActionCheckResponseHubDto) => void, completeCallBack: (data: LoginAccountActionCheckResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: LoginAccountActionCheckResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: LoginAccountActionCheckResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/AuthHub/LoginAccountActionCheck';

        let responseData: LoginAccountActionCheckResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('AuthHub', 'LoginAccountActionCheck', null, 'LoginAccountActionCheckResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('AuthHub', 'LoginAccountActionCheck', null, 'LoginAccountActionCheckResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('AuthHub', 'LoginAccountActionCheck', null, 'LoginAccountActionCheckResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ChangeProvisionalPassword = (requestData: ChangeProvisionalPasswordRequestHubDto, receiveCallBack: (data: LoginResponseHubDto) => void, receiveErrorCallBack: (data: LoginResponseHubDto) => void, noSessionCallBack: (data: LoginResponseHubDto) => void, completeCallBack: (data: LoginResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: LoginResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: LoginResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/AuthHub/ChangeProvisionalPassword';

        let responseData: LoginResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('AuthHub', 'ChangeProvisionalPassword', 'ChangeProvisionalPasswordRequestHubDto', 'LoginResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('AuthHub', 'ChangeProvisionalPassword', 'ChangeProvisionalPasswordRequestHubDto', 'LoginResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('AuthHub', 'ChangeProvisionalPassword', 'ChangeProvisionalPasswordRequestHubDto', 'LoginResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public Logout = (receiveCallBack: (data: LogoutResponseHubDto) => void, receiveErrorCallBack: (data: LogoutResponseHubDto) => void, noSessionCallBack: (data: LogoutResponseHubDto) => void, completeCallBack: (data: LogoutResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: LogoutResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: LogoutResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/AuthHub/Logout';

        let responseData: LogoutResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('AuthHub', 'Logout', null, 'LogoutResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('AuthHub', 'Logout', null, 'LogoutResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('AuthHub', 'Logout', null, 'LogoutResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public SendIdReminderMail = (requestData: SendIdReminderMailRequestHubDto, receiveCallBack: (data: SendIdReminderMailResponseHubDto) => void, receiveErrorCallBack: (data: SendIdReminderMailResponseHubDto) => void, noSessionCallBack: (data: SendIdReminderMailResponseHubDto) => void, completeCallBack: (data: SendIdReminderMailResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SendIdReminderMailResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SendIdReminderMailResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/AuthHub/SendIdReminderMail';

        let responseData: SendIdReminderMailResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('AuthHub', 'SendIdReminderMail', 'SendIdReminderMailRequestHubDto', 'SendIdReminderMailResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('AuthHub', 'SendIdReminderMail', 'SendIdReminderMailRequestHubDto', 'SendIdReminderMailResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('AuthHub', 'SendIdReminderMail', 'SendIdReminderMailRequestHubDto', 'SendIdReminderMailResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public SendPwReminderMail = (requestData: SendPwReminderMailRequestHubDto, receiveCallBack: (data: SendPwReminderMailResponseHubDto) => void, receiveErrorCallBack: (data: SendPwReminderMailResponseHubDto) => void, noSessionCallBack: (data: SendPwReminderMailResponseHubDto) => void, completeCallBack: (data: SendPwReminderMailResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SendPwReminderMailResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SendPwReminderMailResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/AuthHub/SendPwReminderMail';

        let responseData: SendPwReminderMailResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('AuthHub', 'SendPwReminderMail', 'SendPwReminderMailRequestHubDto', 'SendPwReminderMailResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('AuthHub', 'SendPwReminderMail', 'SendPwReminderMailRequestHubDto', 'SendPwReminderMailResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('AuthHub', 'SendPwReminderMail', 'SendPwReminderMailRequestHubDto', 'SendPwReminderMailResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GmoIdLoginUrl = (requestData: GmoIdLoginUrlRequestHubDto, receiveCallBack: (data: GmoIdLoginUrlResponseHubDto) => void, receiveErrorCallBack: (data: GmoIdLoginUrlResponseHubDto) => void, noSessionCallBack: (data: GmoIdLoginUrlResponseHubDto) => void, completeCallBack: (data: GmoIdLoginUrlResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: GmoIdLoginUrlResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: GmoIdLoginUrlResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/AuthHub/GmoIdLoginUrl';

        let responseData: GmoIdLoginUrlResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('AuthHub', 'GmoIdLoginUrl', 'GmoIdLoginUrlRequestHubDto', 'GmoIdLoginUrlResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('AuthHub', 'GmoIdLoginUrl', 'GmoIdLoginUrlRequestHubDto', 'GmoIdLoginUrlResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('AuthHub', 'GmoIdLoginUrl', 'GmoIdLoginUrlRequestHubDto', 'GmoIdLoginUrlResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GmoIdLoginMembers = (requestData: GmoIdLoginMemberRequestHubDto, receiveCallBack: (data: GmoIdLoginMemberResponseHubDto) => void, receiveErrorCallBack: (data: GmoIdLoginMemberResponseHubDto) => void, noSessionCallBack: (data: GmoIdLoginMemberResponseHubDto) => void, completeCallBack: (data: GmoIdLoginMemberResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: GmoIdLoginMemberResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: GmoIdLoginMemberResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/AuthHub/GmoIdLoginMembers';

        let responseData: GmoIdLoginMemberResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('AuthHub', 'GmoIdLoginMembers', 'GmoIdLoginMemberRequestHubDto', 'GmoIdLoginMemberResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('AuthHub', 'GmoIdLoginMembers', 'GmoIdLoginMemberRequestHubDto', 'GmoIdLoginMemberResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('AuthHub', 'GmoIdLoginMembers', 'GmoIdLoginMemberRequestHubDto', 'GmoIdLoginMemberResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GmoIdCallBack = (requestData: GmoIdCallBackRequestHubDto, receiveCallBack: (data: GmoIdCallBackResponseHubDto) => void, receiveErrorCallBack: (data: GmoIdCallBackResponseHubDto) => void, noSessionCallBack: (data: GmoIdCallBackResponseHubDto) => void, completeCallBack: (data: GmoIdCallBackResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: GmoIdCallBackResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: GmoIdCallBackResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/AuthHub/GmoIdCallBack';

        let responseData: GmoIdCallBackResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('AuthHub', 'GmoIdCallBack', 'GmoIdCallBackRequestHubDto', 'GmoIdCallBackResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('AuthHub', 'GmoIdCallBack', 'GmoIdCallBackRequestHubDto', 'GmoIdCallBackResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('AuthHub', 'GmoIdCallBack', 'GmoIdCallBackRequestHubDto', 'GmoIdCallBackResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GmoIdLogin = (requestData: GmoIdLoginRequestHubDto, receiveCallBack: (data: GmoIdLoginResponseHubDto) => void, receiveErrorCallBack: (data: GmoIdLoginResponseHubDto) => void, noSessionCallBack: (data: GmoIdLoginResponseHubDto) => void, completeCallBack: (data: GmoIdLoginResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: GmoIdLoginResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: GmoIdLoginResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/AuthHub/GmoIdLogin';

        let responseData: GmoIdLoginResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('AuthHub', 'GmoIdLogin', 'GmoIdLoginRequestHubDto', 'GmoIdLoginResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('AuthHub', 'GmoIdLogin', 'GmoIdLoginRequestHubDto', 'GmoIdLoginResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('AuthHub', 'GmoIdLogin', 'GmoIdLoginRequestHubDto', 'GmoIdLoginResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GmoIdLogoutCallBack = (requestData: GmoIdLogoutCallBackRequestHubDto, receiveCallBack: (data: GmoIdLogoutCallBackResponseHubDto) => void, receiveErrorCallBack: (data: GmoIdLogoutCallBackResponseHubDto) => void, noSessionCallBack: (data: GmoIdLogoutCallBackResponseHubDto) => void, completeCallBack: (data: GmoIdLogoutCallBackResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: GmoIdLogoutCallBackResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: GmoIdLogoutCallBackResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/AuthHub/GmoIdLogoutCallBack';

        let responseData: GmoIdLogoutCallBackResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('AuthHub', 'GmoIdLogoutCallBack', 'GmoIdLogoutCallBackRequestHubDto', 'GmoIdLogoutCallBackResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('AuthHub', 'GmoIdLogoutCallBack', 'GmoIdLogoutCallBackRequestHubDto', 'GmoIdLogoutCallBackResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('AuthHub', 'GmoIdLogoutCallBack', 'GmoIdLogoutCallBackRequestHubDto', 'GmoIdLogoutCallBackResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetRecommendHtml = (receiveCallBack: (data: RecommendMessageResponseHubDto) => void, receiveErrorCallBack: (data: RecommendMessageResponseHubDto) => void, noSessionCallBack: (data: RecommendMessageResponseHubDto) => void, completeCallBack: (data: RecommendMessageResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: RecommendMessageResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: RecommendMessageResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/AuthHub/GetRecommendHtml';

        let responseData: RecommendMessageResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('AuthHub', 'GetRecommendHtml', null, 'RecommendMessageResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('AuthHub', 'GetRecommendHtml', null, 'RecommendMessageResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('AuthHub', 'GetRecommendHtml', null, 'RecommendMessageResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetLoginTopMessages = (receiveCallBack: (data: BannerMessageResponseHubDto) => void, receiveErrorCallBack: (data: BannerMessageResponseHubDto) => void, noSessionCallBack: (data: BannerMessageResponseHubDto) => void, completeCallBack: (data: BannerMessageResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: BannerMessageResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: BannerMessageResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/AuthHub/GetLoginTopMessages';

        let responseData: BannerMessageResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('AuthHub', 'GetLoginTopMessages', null, 'BannerMessageResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('AuthHub', 'GetLoginTopMessages', null, 'BannerMessageResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('AuthHub', 'GetLoginTopMessages', null, 'BannerMessageResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetPhoneAuthenticationDetail = (requestData: HostingItemRequestHubDto, receiveCallBack: (data: HostingPhoneAuthenticationDetailResponseHubDto) => void, receiveErrorCallBack: (data: HostingPhoneAuthenticationDetailResponseHubDto) => void, noSessionCallBack: (data: HostingPhoneAuthenticationDetailResponseHubDto) => void, completeCallBack: (data: HostingPhoneAuthenticationDetailResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: HostingPhoneAuthenticationDetailResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: HostingPhoneAuthenticationDetailResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/AuthHub/GetPhoneAuthenticationDetail';

        let responseData: HostingPhoneAuthenticationDetailResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('AuthHub', 'GetPhoneAuthenticationDetail', 'HostingItemRequestHubDto', 'HostingPhoneAuthenticationDetailResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('AuthHub', 'GetPhoneAuthenticationDetail', 'HostingItemRequestHubDto', 'HostingPhoneAuthenticationDetailResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('AuthHub', 'GetPhoneAuthenticationDetail', 'HostingItemRequestHubDto', 'HostingPhoneAuthenticationDetailResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public PhoneAuthentication = (requestData: HostingPhoneAuthenticationRequestHubDto, receiveCallBack: (data: HostingPhoneAuthenticationResponseHubDto) => void, receiveErrorCallBack: (data: HostingPhoneAuthenticationResponseHubDto) => void, noSessionCallBack: (data: HostingPhoneAuthenticationResponseHubDto) => void, completeCallBack: (data: HostingPhoneAuthenticationResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: HostingPhoneAuthenticationResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: HostingPhoneAuthenticationResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/AuthHub/PhoneAuthentication';

        let responseData: HostingPhoneAuthenticationResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('AuthHub', 'PhoneAuthentication', 'HostingPhoneAuthenticationRequestHubDto', 'HostingPhoneAuthenticationResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('AuthHub', 'PhoneAuthentication', 'HostingPhoneAuthenticationRequestHubDto', 'HostingPhoneAuthenticationResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('AuthHub', 'PhoneAuthentication', 'HostingPhoneAuthenticationRequestHubDto', 'HostingPhoneAuthenticationResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public SendLoginMfaToken = (requestData: AuthSendLoginMfaTokenRequestHubDto, receiveCallBack: (data: AuthSendLoginMfaTokenResponseHubDto) => void, receiveErrorCallBack: (data: AuthSendLoginMfaTokenResponseHubDto) => void, noSessionCallBack: (data: AuthSendLoginMfaTokenResponseHubDto) => void, completeCallBack: (data: AuthSendLoginMfaTokenResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: AuthSendLoginMfaTokenResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: AuthSendLoginMfaTokenResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/AuthHub/SendLoginMfaToken';

        let responseData: AuthSendLoginMfaTokenResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('AuthHub', 'SendLoginMfaToken', 'AuthSendLoginMfaTokenRequestHubDto', 'AuthSendLoginMfaTokenResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('AuthHub', 'SendLoginMfaToken', 'AuthSendLoginMfaTokenRequestHubDto', 'AuthSendLoginMfaTokenResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('AuthHub', 'SendLoginMfaToken', 'AuthSendLoginMfaTokenRequestHubDto', 'AuthSendLoginMfaTokenResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public VerifyLoginMfaToken = (requestData: AuthVerifyLoginMfaTokenRequestHubDto, receiveCallBack: (data: AuthVerifyLoginMfaTokenResponseHubDto) => void, receiveErrorCallBack: (data: AuthVerifyLoginMfaTokenResponseHubDto) => void, noSessionCallBack: (data: AuthVerifyLoginMfaTokenResponseHubDto) => void, completeCallBack: (data: AuthVerifyLoginMfaTokenResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: AuthVerifyLoginMfaTokenResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: AuthVerifyLoginMfaTokenResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/AuthHub/VerifyLoginMfaToken';

        let responseData: AuthVerifyLoginMfaTokenResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('AuthHub', 'VerifyLoginMfaToken', 'AuthVerifyLoginMfaTokenRequestHubDto', 'AuthVerifyLoginMfaTokenResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('AuthHub', 'VerifyLoginMfaToken', 'AuthVerifyLoginMfaTokenRequestHubDto', 'AuthVerifyLoginMfaTokenResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('AuthHub', 'VerifyLoginMfaToken', 'AuthVerifyLoginMfaTokenRequestHubDto', 'AuthVerifyLoginMfaTokenResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public VerifyLoginVoaciToken = (requestData: AuthVerifyLoginVoaciTokenRequestHubDto, receiveCallBack: (data: AuthVerifyLoginVoaciTokenResponseHubDto) => void, receiveErrorCallBack: (data: AuthVerifyLoginVoaciTokenResponseHubDto) => void, noSessionCallBack: (data: AuthVerifyLoginVoaciTokenResponseHubDto) => void, completeCallBack: (data: AuthVerifyLoginVoaciTokenResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: AuthVerifyLoginVoaciTokenResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: AuthVerifyLoginVoaciTokenResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/AuthHub/VerifyLoginVoaciToken';

        let responseData: AuthVerifyLoginVoaciTokenResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('AuthHub', 'VerifyLoginVoaciToken', 'AuthVerifyLoginVoaciTokenRequestHubDto', 'AuthVerifyLoginVoaciTokenResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('AuthHub', 'VerifyLoginVoaciToken', 'AuthVerifyLoginVoaciTokenRequestHubDto', 'AuthVerifyLoginVoaciTokenResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('AuthHub', 'VerifyLoginVoaciToken', 'AuthVerifyLoginVoaciTokenRequestHubDto', 'AuthVerifyLoginVoaciTokenResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public MfaTokenCreate = (requestData: MfaTokenCreateRequestHubDto, receiveCallBack: (data: MfaTokenCreateResponseHubDto) => void, receiveErrorCallBack: (data: MfaTokenCreateResponseHubDto) => void, noSessionCallBack: (data: MfaTokenCreateResponseHubDto) => void, completeCallBack: (data: MfaTokenCreateResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: MfaTokenCreateResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: MfaTokenCreateResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/AuthHub/MfaTokenCreate';

        let responseData: MfaTokenCreateResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('AuthHub', 'MfaTokenCreate', 'MfaTokenCreateRequestHubDto', 'MfaTokenCreateResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('AuthHub', 'MfaTokenCreate', 'MfaTokenCreateRequestHubDto', 'MfaTokenCreateResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('AuthHub', 'MfaTokenCreate', 'MfaTokenCreateRequestHubDto', 'MfaTokenCreateResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public VerifyMfaCode = (requestData: VerifyMfaCodeRequestHubDto, receiveCallBack: (data: VerifyMfaCodeResponseHubDto) => void, receiveErrorCallBack: (data: VerifyMfaCodeResponseHubDto) => void, noSessionCallBack: (data: VerifyMfaCodeResponseHubDto) => void, completeCallBack: (data: VerifyMfaCodeResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: VerifyMfaCodeResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: VerifyMfaCodeResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/AuthHub/VerifyMfaCode';

        let responseData: VerifyMfaCodeResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('AuthHub', 'VerifyMfaCode', 'VerifyMfaCodeRequestHubDto', 'VerifyMfaCodeResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('AuthHub', 'VerifyMfaCode', 'VerifyMfaCodeRequestHubDto', 'VerifyMfaCodeResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('AuthHub', 'VerifyMfaCode', 'VerifyMfaCodeRequestHubDto', 'VerifyMfaCodeResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public MfaMethodUpdate = (requestData: MfaMethodUpdateRequestHubDto, receiveCallBack: (data: MfaMethodUpdateResponseHubDto) => void, receiveErrorCallBack: (data: MfaMethodUpdateResponseHubDto) => void, noSessionCallBack: (data: MfaMethodUpdateResponseHubDto) => void, completeCallBack: (data: MfaMethodUpdateResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: MfaMethodUpdateResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: MfaMethodUpdateResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/AuthHub/MfaMethodUpdate';

        let responseData: MfaMethodUpdateResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('AuthHub', 'MfaMethodUpdate', 'MfaMethodUpdateRequestHubDto', 'MfaMethodUpdateResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('AuthHub', 'MfaMethodUpdate', 'MfaMethodUpdateRequestHubDto', 'MfaMethodUpdateResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('AuthHub', 'MfaMethodUpdate', 'MfaMethodUpdateRequestHubDto', 'MfaMethodUpdateResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public MfaReset = (requestData: MfaResetRequestHubDto, receiveCallBack: (data: MfaResetResponseHubDto) => void, receiveErrorCallBack: (data: MfaResetResponseHubDto) => void, noSessionCallBack: (data: MfaResetResponseHubDto) => void, completeCallBack: (data: MfaResetResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: MfaResetResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: MfaResetResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/AuthHub/MfaReset';

        let responseData: MfaResetResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('AuthHub', 'MfaReset', 'MfaResetRequestHubDto', 'MfaResetResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('AuthHub', 'MfaReset', 'MfaResetRequestHubDto', 'MfaResetResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('AuthHub', 'MfaReset', 'MfaResetRequestHubDto', 'MfaResetResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public MfaCheckAuthenticated = (receiveCallBack: (data: MfaCheckAuthenticatedResponseHubDto) => void, receiveErrorCallBack: (data: MfaCheckAuthenticatedResponseHubDto) => void, noSessionCallBack: (data: MfaCheckAuthenticatedResponseHubDto) => void, completeCallBack: (data: MfaCheckAuthenticatedResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: MfaCheckAuthenticatedResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: MfaCheckAuthenticatedResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/AuthHub/MfaCheckAuthenticated';

        let responseData: MfaCheckAuthenticatedResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('AuthHub', 'MfaCheckAuthenticated', null, 'MfaCheckAuthenticatedResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('AuthHub', 'MfaCheckAuthenticated', null, 'MfaCheckAuthenticatedResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('AuthHub', 'MfaCheckAuthenticated', null, 'MfaCheckAuthenticatedResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public OnetimePasswordValidator = (requestData: OnetimePasswordValidatorRequestHubDto, receiveCallBack: (data: OnetimePasswordValidatorResponseHubDto) => void, receiveErrorCallBack: (data: OnetimePasswordValidatorResponseHubDto) => void, noSessionCallBack: (data: OnetimePasswordValidatorResponseHubDto) => void, completeCallBack: (data: OnetimePasswordValidatorResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: OnetimePasswordValidatorResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: OnetimePasswordValidatorResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/AuthHub/OnetimePasswordValidator';

        let responseData: OnetimePasswordValidatorResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('AuthHub', 'OnetimePasswordValidator', 'OnetimePasswordValidatorRequestHubDto', 'OnetimePasswordValidatorResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('AuthHub', 'OnetimePasswordValidator', 'OnetimePasswordValidatorRequestHubDto', 'OnetimePasswordValidatorResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('AuthHub', 'OnetimePasswordValidator', 'OnetimePasswordValidatorRequestHubDto', 'OnetimePasswordValidatorResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public PwReminderInfo = (requestData: PwReminderInfoRequestHubDto, receiveCallBack: (data: PwReminderInfoResponseHubDto) => void, receiveErrorCallBack: (data: PwReminderInfoResponseHubDto) => void, noSessionCallBack: (data: PwReminderInfoResponseHubDto) => void, completeCallBack: (data: PwReminderInfoResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: PwReminderInfoResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: PwReminderInfoResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/AuthHub/PwReminderInfo';

        let responseData: PwReminderInfoResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('AuthHub', 'PwReminderInfo', 'PwReminderInfoRequestHubDto', 'PwReminderInfoResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('AuthHub', 'PwReminderInfo', 'PwReminderInfoRequestHubDto', 'PwReminderInfoResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('AuthHub', 'PwReminderInfo', 'PwReminderInfoRequestHubDto', 'PwReminderInfoResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ChangePasswordReminder = (requestData: ChangePasswordReminderRequestHubDto, receiveCallBack: (data: ChangePasswordReminderResponseHubDto) => void, receiveErrorCallBack: (data: ChangePasswordReminderResponseHubDto) => void, noSessionCallBack: (data: ChangePasswordReminderResponseHubDto) => void, completeCallBack: (data: ChangePasswordReminderResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: ChangePasswordReminderResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: ChangePasswordReminderResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/AuthHub/ChangePasswordReminder';

        let responseData: ChangePasswordReminderResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('AuthHub', 'ChangePasswordReminder', 'ChangePasswordReminderRequestHubDto', 'ChangePasswordReminderResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('AuthHub', 'ChangePasswordReminder', 'ChangePasswordReminderRequestHubDto', 'ChangePasswordReminderResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('AuthHub', 'ChangePasswordReminder', 'ChangePasswordReminderRequestHubDto', 'ChangePasswordReminderResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }

}



export class VpsHubManager {
    /**
     * コンストラクタ
     */
    constructor(private http: HttpClient) {
    }

	// リソース破棄
    public Dispose = (): void => {
    }



    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public VpsList = (requestData: VpsListRequestHubDto, receiveCallBack: (data: VpsListResponseHubDto) => void, receiveErrorCallBack: (data: VpsListResponseHubDto) => void, noSessionCallBack: (data: VpsListResponseHubDto) => void, completeCallBack: (data: VpsListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: VpsListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: VpsListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/VpsHub/VpsList';

        let responseData: VpsListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('VpsHub', 'VpsList', 'VpsListRequestHubDto', 'VpsListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('VpsHub', 'VpsList', 'VpsListRequestHubDto', 'VpsListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('VpsHub', 'VpsList', 'VpsListRequestHubDto', 'VpsListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetVpsDetail = (requestData: VpsDetailRequestHubDto, receiveCallBack: (data: VpsDetailResponseHubDto) => void, receiveErrorCallBack: (data: VpsDetailResponseHubDto) => void, noSessionCallBack: (data: VpsDetailResponseHubDto) => void, completeCallBack: (data: VpsDetailResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: VpsDetailResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: VpsDetailResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/VpsHub/GetVpsDetail';

        let responseData: VpsDetailResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('VpsHub', 'GetVpsDetail', 'VpsDetailRequestHubDto', 'VpsDetailResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('VpsHub', 'GetVpsDetail', 'VpsDetailRequestHubDto', 'VpsDetailResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('VpsHub', 'GetVpsDetail', 'VpsDetailRequestHubDto', 'VpsDetailResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetVpsDetailControlPanel = (requestData: VpsDetailControlPanelRequestHubDto, receiveCallBack: (data: VpsDetailControlPanelResponseHubDto) => void, receiveErrorCallBack: (data: VpsDetailControlPanelResponseHubDto) => void, noSessionCallBack: (data: VpsDetailControlPanelResponseHubDto) => void, completeCallBack: (data: VpsDetailControlPanelResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: VpsDetailControlPanelResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: VpsDetailControlPanelResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/VpsHub/GetVpsDetailControlPanel';

        let responseData: VpsDetailControlPanelResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('VpsHub', 'GetVpsDetailControlPanel', 'VpsDetailControlPanelRequestHubDto', 'VpsDetailControlPanelResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('VpsHub', 'GetVpsDetailControlPanel', 'VpsDetailControlPanelRequestHubDto', 'VpsDetailControlPanelResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('VpsHub', 'GetVpsDetailControlPanel', 'VpsDetailControlPanelRequestHubDto', 'VpsDetailControlPanelResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetVpsDetailServer = (requestData: VpsDetailServerRequestHubDto, receiveCallBack: (data: VpsDetailServerResponseHubDto) => void, receiveErrorCallBack: (data: VpsDetailServerResponseHubDto) => void, noSessionCallBack: (data: VpsDetailServerResponseHubDto) => void, completeCallBack: (data: VpsDetailServerResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: VpsDetailServerResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: VpsDetailServerResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/VpsHub/GetVpsDetailServer';

        let responseData: VpsDetailServerResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('VpsHub', 'GetVpsDetailServer', 'VpsDetailServerRequestHubDto', 'VpsDetailServerResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('VpsHub', 'GetVpsDetailServer', 'VpsDetailServerRequestHubDto', 'VpsDetailServerResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('VpsHub', 'GetVpsDetailServer', 'VpsDetailServerRequestHubDto', 'VpsDetailServerResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetVpsDetailOption = (requestData: VpsDetailOptionRequestHubDto, receiveCallBack: (data: VpsDetailOptionResponseHubDto) => void, receiveErrorCallBack: (data: VpsDetailOptionResponseHubDto) => void, noSessionCallBack: (data: VpsDetailOptionResponseHubDto) => void, completeCallBack: (data: VpsDetailOptionResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: VpsDetailOptionResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: VpsDetailOptionResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/VpsHub/GetVpsDetailOption';

        let responseData: VpsDetailOptionResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('VpsHub', 'GetVpsDetailOption', 'VpsDetailOptionRequestHubDto', 'VpsDetailOptionResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('VpsHub', 'GetVpsDetailOption', 'VpsDetailOptionRequestHubDto', 'VpsDetailOptionResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('VpsHub', 'GetVpsDetailOption', 'VpsDetailOptionRequestHubDto', 'VpsDetailOptionResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public RevokeVpsDetailCancelPlan = (requestData: VpsDetailCancelPlanRevokeRequestHubDto, receiveCallBack: (data: VpsDetailCancelPlanRevokeResponseHubDto) => void, receiveErrorCallBack: (data: VpsDetailCancelPlanRevokeResponseHubDto) => void, noSessionCallBack: (data: VpsDetailCancelPlanRevokeResponseHubDto) => void, completeCallBack: (data: VpsDetailCancelPlanRevokeResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: VpsDetailCancelPlanRevokeResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: VpsDetailCancelPlanRevokeResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/VpsHub/RevokeVpsDetailCancelPlan';

        let responseData: VpsDetailCancelPlanRevokeResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('VpsHub', 'RevokeVpsDetailCancelPlan', 'VpsDetailCancelPlanRevokeRequestHubDto', 'VpsDetailCancelPlanRevokeResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('VpsHub', 'RevokeVpsDetailCancelPlan', 'VpsDetailCancelPlanRevokeRequestHubDto', 'VpsDetailCancelPlanRevokeResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('VpsHub', 'RevokeVpsDetailCancelPlan', 'VpsDetailCancelPlanRevokeRequestHubDto', 'VpsDetailCancelPlanRevokeResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ValidateVpsDetailChangePlan = (requestData: VpsDetailValidateChangePlanRequestHubDto, receiveCallBack: (data: VpsDetailValidateChangePlanResponseHubDto) => void, receiveErrorCallBack: (data: VpsDetailValidateChangePlanResponseHubDto) => void, noSessionCallBack: (data: VpsDetailValidateChangePlanResponseHubDto) => void, completeCallBack: (data: VpsDetailValidateChangePlanResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: VpsDetailValidateChangePlanResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: VpsDetailValidateChangePlanResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/VpsHub/ValidateVpsDetailChangePlan';

        let responseData: VpsDetailValidateChangePlanResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('VpsHub', 'ValidateVpsDetailChangePlan', 'VpsDetailValidateChangePlanRequestHubDto', 'VpsDetailValidateChangePlanResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('VpsHub', 'ValidateVpsDetailChangePlan', 'VpsDetailValidateChangePlanRequestHubDto', 'VpsDetailValidateChangePlanResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('VpsHub', 'ValidateVpsDetailChangePlan', 'VpsDetailValidateChangePlanRequestHubDto', 'VpsDetailValidateChangePlanResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ValidateVpsDetailCancelPlan = (requestData: VpsDetailValidateCancelPlanRequestHubDto, receiveCallBack: (data: VpsDetailValidateCancelPlanResponseHubDto) => void, receiveErrorCallBack: (data: VpsDetailValidateCancelPlanResponseHubDto) => void, noSessionCallBack: (data: VpsDetailValidateCancelPlanResponseHubDto) => void, completeCallBack: (data: VpsDetailValidateCancelPlanResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: VpsDetailValidateCancelPlanResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: VpsDetailValidateCancelPlanResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/VpsHub/ValidateVpsDetailCancelPlan';

        let responseData: VpsDetailValidateCancelPlanResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('VpsHub', 'ValidateVpsDetailCancelPlan', 'VpsDetailValidateCancelPlanRequestHubDto', 'VpsDetailValidateCancelPlanResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('VpsHub', 'ValidateVpsDetailCancelPlan', 'VpsDetailValidateCancelPlanRequestHubDto', 'VpsDetailValidateCancelPlanResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('VpsHub', 'ValidateVpsDetailCancelPlan', 'VpsDetailValidateCancelPlanRequestHubDto', 'VpsDetailValidateCancelPlanResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ValidateVpsDetailRevokeCancelPlan = (requestData: VpsDetailValidateRevokeCancelPlanRequestHubDto, receiveCallBack: (data: VpsDetailValidateRevokeCancelPlanResponseHubDto) => void, receiveErrorCallBack: (data: VpsDetailValidateRevokeCancelPlanResponseHubDto) => void, noSessionCallBack: (data: VpsDetailValidateRevokeCancelPlanResponseHubDto) => void, completeCallBack: (data: VpsDetailValidateRevokeCancelPlanResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: VpsDetailValidateRevokeCancelPlanResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: VpsDetailValidateRevokeCancelPlanResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/VpsHub/ValidateVpsDetailRevokeCancelPlan';

        let responseData: VpsDetailValidateRevokeCancelPlanResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('VpsHub', 'ValidateVpsDetailRevokeCancelPlan', 'VpsDetailValidateRevokeCancelPlanRequestHubDto', 'VpsDetailValidateRevokeCancelPlanResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('VpsHub', 'ValidateVpsDetailRevokeCancelPlan', 'VpsDetailValidateRevokeCancelPlanRequestHubDto', 'VpsDetailValidateRevokeCancelPlanResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('VpsHub', 'ValidateVpsDetailRevokeCancelPlan', 'VpsDetailValidateRevokeCancelPlanRequestHubDto', 'VpsDetailValidateRevokeCancelPlanResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetVpsControlPanels = (requestData: VpsControlPanelsRequestHubDto, receiveCallBack: (data: VpsControlPanelsResponseHubDto) => void, receiveErrorCallBack: (data: VpsControlPanelsResponseHubDto) => void, noSessionCallBack: (data: VpsControlPanelsResponseHubDto) => void, completeCallBack: (data: VpsControlPanelsResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: VpsControlPanelsResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: VpsControlPanelsResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/VpsHub/GetVpsControlPanels';

        let responseData: VpsControlPanelsResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('VpsHub', 'GetVpsControlPanels', 'VpsControlPanelsRequestHubDto', 'VpsControlPanelsResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('VpsHub', 'GetVpsControlPanels', 'VpsControlPanelsRequestHubDto', 'VpsControlPanelsResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('VpsHub', 'GetVpsControlPanels', 'VpsControlPanelsRequestHubDto', 'VpsControlPanelsResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public OperateSeparationVpsControlPanel = (requestData: VpsControlPanelOperateSeparationRequestHubDto, receiveCallBack: (data: VpsControlPanelOperateSeparationResponseHubDto) => void, receiveErrorCallBack: (data: VpsControlPanelOperateSeparationResponseHubDto) => void, noSessionCallBack: (data: VpsControlPanelOperateSeparationResponseHubDto) => void, completeCallBack: (data: VpsControlPanelOperateSeparationResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: VpsControlPanelOperateSeparationResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: VpsControlPanelOperateSeparationResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/VpsHub/OperateSeparationVpsControlPanel';

        let responseData: VpsControlPanelOperateSeparationResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('VpsHub', 'OperateSeparationVpsControlPanel', 'VpsControlPanelOperateSeparationRequestHubDto', 'VpsControlPanelOperateSeparationResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('VpsHub', 'OperateSeparationVpsControlPanel', 'VpsControlPanelOperateSeparationRequestHubDto', 'VpsControlPanelOperateSeparationResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('VpsHub', 'OperateSeparationVpsControlPanel', 'VpsControlPanelOperateSeparationRequestHubDto', 'VpsControlPanelOperateSeparationResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public OperateJoinsVpsControlPanel = (requestData: VpsControlPanelOperateJoinsRequestHubDto, receiveCallBack: (data: VpsControlPanelOperateJoinsResponseHubDto) => void, receiveErrorCallBack: (data: VpsControlPanelOperateJoinsResponseHubDto) => void, noSessionCallBack: (data: VpsControlPanelOperateJoinsResponseHubDto) => void, completeCallBack: (data: VpsControlPanelOperateJoinsResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: VpsControlPanelOperateJoinsResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: VpsControlPanelOperateJoinsResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/VpsHub/OperateJoinsVpsControlPanel';

        let responseData: VpsControlPanelOperateJoinsResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('VpsHub', 'OperateJoinsVpsControlPanel', 'VpsControlPanelOperateJoinsRequestHubDto', 'VpsControlPanelOperateJoinsResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('VpsHub', 'OperateJoinsVpsControlPanel', 'VpsControlPanelOperateJoinsRequestHubDto', 'VpsControlPanelOperateJoinsResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('VpsHub', 'OperateJoinsVpsControlPanel', 'VpsControlPanelOperateJoinsRequestHubDto', 'VpsControlPanelOperateJoinsResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetVpsPlan = (requestData: VpsPlanRequestHubDto, receiveCallBack: (data: VpsPlanResponseHubDto) => void, receiveErrorCallBack: (data: VpsPlanResponseHubDto) => void, noSessionCallBack: (data: VpsPlanResponseHubDto) => void, completeCallBack: (data: VpsPlanResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: VpsPlanResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: VpsPlanResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/VpsHub/GetVpsPlan';

        let responseData: VpsPlanResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('VpsHub', 'GetVpsPlan', 'VpsPlanRequestHubDto', 'VpsPlanResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('VpsHub', 'GetVpsPlan', 'VpsPlanRequestHubDto', 'VpsPlanResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('VpsHub', 'GetVpsPlan', 'VpsPlanRequestHubDto', 'VpsPlanResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetVpsPlanChangedDetail = (requestData: VpsPlanChangedDetailRequestHubDto, receiveCallBack: (data: VpsPlanChangedDetailResponseHubDto) => void, receiveErrorCallBack: (data: VpsPlanChangedDetailResponseHubDto) => void, noSessionCallBack: (data: VpsPlanChangedDetailResponseHubDto) => void, completeCallBack: (data: VpsPlanChangedDetailResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: VpsPlanChangedDetailResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: VpsPlanChangedDetailResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/VpsHub/GetVpsPlanChangedDetail';

        let responseData: VpsPlanChangedDetailResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('VpsHub', 'GetVpsPlanChangedDetail', 'VpsPlanChangedDetailRequestHubDto', 'VpsPlanChangedDetailResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('VpsHub', 'GetVpsPlanChangedDetail', 'VpsPlanChangedDetailRequestHubDto', 'VpsPlanChangedDetailResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('VpsHub', 'GetVpsPlanChangedDetail', 'VpsPlanChangedDetailRequestHubDto', 'VpsPlanChangedDetailResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ChangeVpsPlan = (requestData: VpsPlanChangeRequestHubDto, receiveCallBack: (data: VpsPlanChangeResponseHubDto) => void, receiveErrorCallBack: (data: VpsPlanChangeResponseHubDto) => void, noSessionCallBack: (data: VpsPlanChangeResponseHubDto) => void, completeCallBack: (data: VpsPlanChangeResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: VpsPlanChangeResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: VpsPlanChangeResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/VpsHub/ChangeVpsPlan';

        let responseData: VpsPlanChangeResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('VpsHub', 'ChangeVpsPlan', 'VpsPlanChangeRequestHubDto', 'VpsPlanChangeResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('VpsHub', 'ChangeVpsPlan', 'VpsPlanChangeRequestHubDto', 'VpsPlanChangeResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('VpsHub', 'ChangeVpsPlan', 'VpsPlanChangeRequestHubDto', 'VpsPlanChangeResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetVpsCancelPlanData = (requestData: VpsPlanCancelDataRequestHubDto, receiveCallBack: (data: VpsPlanCancelDataResponseHubDto) => void, receiveErrorCallBack: (data: VpsPlanCancelDataResponseHubDto) => void, noSessionCallBack: (data: VpsPlanCancelDataResponseHubDto) => void, completeCallBack: (data: VpsPlanCancelDataResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: VpsPlanCancelDataResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: VpsPlanCancelDataResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/VpsHub/GetVpsCancelPlanData';

        let responseData: VpsPlanCancelDataResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('VpsHub', 'GetVpsCancelPlanData', 'VpsPlanCancelDataRequestHubDto', 'VpsPlanCancelDataResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('VpsHub', 'GetVpsCancelPlanData', 'VpsPlanCancelDataRequestHubDto', 'VpsPlanCancelDataResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('VpsHub', 'GetVpsCancelPlanData', 'VpsPlanCancelDataRequestHubDto', 'VpsPlanCancelDataResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public CancelVpsPlan = (requestData: VpsPlanCancelRequestHubDto, receiveCallBack: (data: VpsPlanCancelResponseHubDto) => void, receiveErrorCallBack: (data: VpsPlanCancelResponseHubDto) => void, noSessionCallBack: (data: VpsPlanCancelResponseHubDto) => void, completeCallBack: (data: VpsPlanCancelResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: VpsPlanCancelResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: VpsPlanCancelResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/VpsHub/CancelVpsPlan';

        let responseData: VpsPlanCancelResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('VpsHub', 'CancelVpsPlan', 'VpsPlanCancelRequestHubDto', 'VpsPlanCancelResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('VpsHub', 'CancelVpsPlan', 'VpsPlanCancelRequestHubDto', 'VpsPlanCancelResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('VpsHub', 'CancelVpsPlan', 'VpsPlanCancelRequestHubDto', 'VpsPlanCancelResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetServerRedirection = (requestData: ServerRedirectionRequestHubDto, receiveCallBack: (data: ServerRedirectionResponseHubDto) => void, receiveErrorCallBack: (data: ServerRedirectionResponseHubDto) => void, noSessionCallBack: (data: ServerRedirectionResponseHubDto) => void, completeCallBack: (data: ServerRedirectionResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: ServerRedirectionResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: ServerRedirectionResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/VpsHub/GetServerRedirection';

        let responseData: ServerRedirectionResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('VpsHub', 'GetServerRedirection', 'ServerRedirectionRequestHubDto', 'ServerRedirectionResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('VpsHub', 'GetServerRedirection', 'ServerRedirectionRequestHubDto', 'ServerRedirectionResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('VpsHub', 'GetServerRedirection', 'ServerRedirectionRequestHubDto', 'ServerRedirectionResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetVpsMessages = (receiveCallBack: (data: VpsMessageResponseHubDto) => void, receiveErrorCallBack: (data: VpsMessageResponseHubDto) => void, noSessionCallBack: (data: VpsMessageResponseHubDto) => void, completeCallBack: (data: VpsMessageResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: VpsMessageResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: VpsMessageResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/VpsHub/GetVpsMessages';

        let responseData: VpsMessageResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('VpsHub', 'GetVpsMessages', null, 'VpsMessageResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('VpsHub', 'GetVpsMessages', null, 'VpsMessageResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('VpsHub', 'GetVpsMessages', null, 'VpsMessageResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public VpsSwitchIdValidation = (requestData: VpsSwitchIdValidationRequestHubDto, receiveCallBack: (data: VpsSwitchIdValidationResponseHubDto) => void, receiveErrorCallBack: (data: VpsSwitchIdValidationResponseHubDto) => void, noSessionCallBack: (data: VpsSwitchIdValidationResponseHubDto) => void, completeCallBack: (data: VpsSwitchIdValidationResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: VpsSwitchIdValidationResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: VpsSwitchIdValidationResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/VpsHub/VpsSwitchIdValidation';

        let responseData: VpsSwitchIdValidationResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('VpsHub', 'VpsSwitchIdValidation', 'VpsSwitchIdValidationRequestHubDto', 'VpsSwitchIdValidationResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('VpsHub', 'VpsSwitchIdValidation', 'VpsSwitchIdValidationRequestHubDto', 'VpsSwitchIdValidationResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('VpsHub', 'VpsSwitchIdValidation', 'VpsSwitchIdValidationRequestHubDto', 'VpsSwitchIdValidationResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public VpsSwitchId = (requestData: VpsSwitchIdRequestHubDto, receiveCallBack: (data: VpsSwitchIdResponseHubDto) => void, receiveErrorCallBack: (data: VpsSwitchIdResponseHubDto) => void, noSessionCallBack: (data: VpsSwitchIdResponseHubDto) => void, completeCallBack: (data: VpsSwitchIdResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: VpsSwitchIdResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: VpsSwitchIdResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/VpsHub/VpsSwitchId';

        let responseData: VpsSwitchIdResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('VpsHub', 'VpsSwitchId', 'VpsSwitchIdRequestHubDto', 'VpsSwitchIdResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('VpsHub', 'VpsSwitchId', 'VpsSwitchIdRequestHubDto', 'VpsSwitchIdResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('VpsHub', 'VpsSwitchId', 'VpsSwitchIdRequestHubDto', 'VpsSwitchIdResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }

}



export class Office365HubManager {
    /**
     * コンストラクタ
     */
    constructor(private http: HttpClient) {
    }

	// リソース破棄
    public Dispose = (): void => {
    }



    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public Office365List = (requestData: Office365ListRequestHubDto, receiveCallBack: (data: Office365ListResponseHubDto) => void, receiveErrorCallBack: (data: Office365ListResponseHubDto) => void, noSessionCallBack: (data: Office365ListResponseHubDto) => void, completeCallBack: (data: Office365ListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: Office365ListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: Office365ListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/Office365Hub/Office365List';

        let responseData: Office365ListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('Office365Hub', 'Office365List', 'Office365ListRequestHubDto', 'Office365ListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('Office365Hub', 'Office365List', 'Office365ListRequestHubDto', 'Office365ListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('Office365Hub', 'Office365List', 'Office365ListRequestHubDto', 'Office365ListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetOffice365Detail = (requestData: Office365DetailRequestHubDto, receiveCallBack: (data: Office365DetailResponseHubDto) => void, receiveErrorCallBack: (data: Office365DetailResponseHubDto) => void, noSessionCallBack: (data: Office365DetailResponseHubDto) => void, completeCallBack: (data: Office365DetailResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: Office365DetailResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: Office365DetailResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/Office365Hub/GetOffice365Detail';

        let responseData: Office365DetailResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('Office365Hub', 'GetOffice365Detail', 'Office365DetailRequestHubDto', 'Office365DetailResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('Office365Hub', 'GetOffice365Detail', 'Office365DetailRequestHubDto', 'Office365DetailResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('Office365Hub', 'GetOffice365Detail', 'Office365DetailRequestHubDto', 'Office365DetailResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetOffice365DetailControlPanel = (requestData: Office365DetailControlPanelRequestHubDto, receiveCallBack: (data: Office365DetailControlPanelResponseHubDto) => void, receiveErrorCallBack: (data: Office365DetailControlPanelResponseHubDto) => void, noSessionCallBack: (data: Office365DetailControlPanelResponseHubDto) => void, completeCallBack: (data: Office365DetailControlPanelResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: Office365DetailControlPanelResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: Office365DetailControlPanelResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/Office365Hub/GetOffice365DetailControlPanel';

        let responseData: Office365DetailControlPanelResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('Office365Hub', 'GetOffice365DetailControlPanel', 'Office365DetailControlPanelRequestHubDto', 'Office365DetailControlPanelResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('Office365Hub', 'GetOffice365DetailControlPanel', 'Office365DetailControlPanelRequestHubDto', 'Office365DetailControlPanelResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('Office365Hub', 'GetOffice365DetailControlPanel', 'Office365DetailControlPanelRequestHubDto', 'Office365DetailControlPanelResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetOffice365DetailOption = (requestData: Office365DetailOptionRequestHubDto, receiveCallBack: (data: Office365DetailOptionResponseHubDto) => void, receiveErrorCallBack: (data: Office365DetailOptionResponseHubDto) => void, noSessionCallBack: (data: Office365DetailOptionResponseHubDto) => void, completeCallBack: (data: Office365DetailOptionResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: Office365DetailOptionResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: Office365DetailOptionResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/Office365Hub/GetOffice365DetailOption';

        let responseData: Office365DetailOptionResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('Office365Hub', 'GetOffice365DetailOption', 'Office365DetailOptionRequestHubDto', 'Office365DetailOptionResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('Office365Hub', 'GetOffice365DetailOption', 'Office365DetailOptionRequestHubDto', 'Office365DetailOptionResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('Office365Hub', 'GetOffice365DetailOption', 'Office365DetailOptionRequestHubDto', 'Office365DetailOptionResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public RevokeOffice365DetailCancelPlan = (requestData: Office365DetailCancelPlanRevokeRequestHubDto, receiveCallBack: (data: Office365DetailCancelPlanRevokeResponseHubDto) => void, receiveErrorCallBack: (data: Office365DetailCancelPlanRevokeResponseHubDto) => void, noSessionCallBack: (data: Office365DetailCancelPlanRevokeResponseHubDto) => void, completeCallBack: (data: Office365DetailCancelPlanRevokeResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: Office365DetailCancelPlanRevokeResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: Office365DetailCancelPlanRevokeResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/Office365Hub/RevokeOffice365DetailCancelPlan';

        let responseData: Office365DetailCancelPlanRevokeResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('Office365Hub', 'RevokeOffice365DetailCancelPlan', 'Office365DetailCancelPlanRevokeRequestHubDto', 'Office365DetailCancelPlanRevokeResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('Office365Hub', 'RevokeOffice365DetailCancelPlan', 'Office365DetailCancelPlanRevokeRequestHubDto', 'Office365DetailCancelPlanRevokeResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('Office365Hub', 'RevokeOffice365DetailCancelPlan', 'Office365DetailCancelPlanRevokeRequestHubDto', 'Office365DetailCancelPlanRevokeResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetOffice365DetailCancelOptionDetail = (requestData: Office365DetailCancelOptionDetailRequestHubDto, receiveCallBack: (data: Office365DetailCancelOptionDetailResponseHubDto) => void, receiveErrorCallBack: (data: Office365DetailCancelOptionDetailResponseHubDto) => void, noSessionCallBack: (data: Office365DetailCancelOptionDetailResponseHubDto) => void, completeCallBack: (data: Office365DetailCancelOptionDetailResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: Office365DetailCancelOptionDetailResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: Office365DetailCancelOptionDetailResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/Office365Hub/GetOffice365DetailCancelOptionDetail';

        let responseData: Office365DetailCancelOptionDetailResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('Office365Hub', 'GetOffice365DetailCancelOptionDetail', 'Office365DetailCancelOptionDetailRequestHubDto', 'Office365DetailCancelOptionDetailResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('Office365Hub', 'GetOffice365DetailCancelOptionDetail', 'Office365DetailCancelOptionDetailRequestHubDto', 'Office365DetailCancelOptionDetailResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('Office365Hub', 'GetOffice365DetailCancelOptionDetail', 'Office365DetailCancelOptionDetailRequestHubDto', 'Office365DetailCancelOptionDetailResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public RevokeOffice365DetailCancelOption = (requestData: Office365DetailCancelOptionRevokeRequestHubDto, receiveCallBack: (data: Office365DetailCancelOptionRevokeResponseHubDto) => void, receiveErrorCallBack: (data: Office365DetailCancelOptionRevokeResponseHubDto) => void, noSessionCallBack: (data: Office365DetailCancelOptionRevokeResponseHubDto) => void, completeCallBack: (data: Office365DetailCancelOptionRevokeResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: Office365DetailCancelOptionRevokeResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: Office365DetailCancelOptionRevokeResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/Office365Hub/RevokeOffice365DetailCancelOption';

        let responseData: Office365DetailCancelOptionRevokeResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('Office365Hub', 'RevokeOffice365DetailCancelOption', 'Office365DetailCancelOptionRevokeRequestHubDto', 'Office365DetailCancelOptionRevokeResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('Office365Hub', 'RevokeOffice365DetailCancelOption', 'Office365DetailCancelOptionRevokeRequestHubDto', 'Office365DetailCancelOptionRevokeResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('Office365Hub', 'RevokeOffice365DetailCancelOption', 'Office365DetailCancelOptionRevokeRequestHubDto', 'Office365DetailCancelOptionRevokeResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ValidateOffice365DetailChangePlan = (requestData: Office365DetailValidateChangePlanRequestHubDto, receiveCallBack: (data: Office365DetailValidateChangePlanResponseHubDto) => void, receiveErrorCallBack: (data: Office365DetailValidateChangePlanResponseHubDto) => void, noSessionCallBack: (data: Office365DetailValidateChangePlanResponseHubDto) => void, completeCallBack: (data: Office365DetailValidateChangePlanResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: Office365DetailValidateChangePlanResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: Office365DetailValidateChangePlanResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/Office365Hub/ValidateOffice365DetailChangePlan';

        let responseData: Office365DetailValidateChangePlanResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('Office365Hub', 'ValidateOffice365DetailChangePlan', 'Office365DetailValidateChangePlanRequestHubDto', 'Office365DetailValidateChangePlanResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('Office365Hub', 'ValidateOffice365DetailChangePlan', 'Office365DetailValidateChangePlanRequestHubDto', 'Office365DetailValidateChangePlanResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('Office365Hub', 'ValidateOffice365DetailChangePlan', 'Office365DetailValidateChangePlanRequestHubDto', 'Office365DetailValidateChangePlanResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ValidateOffice365DetailCancelPlan = (requestData: Office365DetailValidateCancelPlanRequestHubDto, receiveCallBack: (data: Office365DetailValidateCancelPlanResponseHubDto) => void, receiveErrorCallBack: (data: Office365DetailValidateCancelPlanResponseHubDto) => void, noSessionCallBack: (data: Office365DetailValidateCancelPlanResponseHubDto) => void, completeCallBack: (data: Office365DetailValidateCancelPlanResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: Office365DetailValidateCancelPlanResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: Office365DetailValidateCancelPlanResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/Office365Hub/ValidateOffice365DetailCancelPlan';

        let responseData: Office365DetailValidateCancelPlanResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('Office365Hub', 'ValidateOffice365DetailCancelPlan', 'Office365DetailValidateCancelPlanRequestHubDto', 'Office365DetailValidateCancelPlanResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('Office365Hub', 'ValidateOffice365DetailCancelPlan', 'Office365DetailValidateCancelPlanRequestHubDto', 'Office365DetailValidateCancelPlanResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('Office365Hub', 'ValidateOffice365DetailCancelPlan', 'Office365DetailValidateCancelPlanRequestHubDto', 'Office365DetailValidateCancelPlanResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ValidateOffice365DetailRevokeCancelPlan = (requestData: Office365DetailValidateRevokeCancelPlanRequestHubDto, receiveCallBack: (data: Office365DetailValidateRevokeCancelPlanResponseHubDto) => void, receiveErrorCallBack: (data: Office365DetailValidateRevokeCancelPlanResponseHubDto) => void, noSessionCallBack: (data: Office365DetailValidateRevokeCancelPlanResponseHubDto) => void, completeCallBack: (data: Office365DetailValidateRevokeCancelPlanResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: Office365DetailValidateRevokeCancelPlanResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: Office365DetailValidateRevokeCancelPlanResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/Office365Hub/ValidateOffice365DetailRevokeCancelPlan';

        let responseData: Office365DetailValidateRevokeCancelPlanResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('Office365Hub', 'ValidateOffice365DetailRevokeCancelPlan', 'Office365DetailValidateRevokeCancelPlanRequestHubDto', 'Office365DetailValidateRevokeCancelPlanResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('Office365Hub', 'ValidateOffice365DetailRevokeCancelPlan', 'Office365DetailValidateRevokeCancelPlanRequestHubDto', 'Office365DetailValidateRevokeCancelPlanResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('Office365Hub', 'ValidateOffice365DetailRevokeCancelPlan', 'Office365DetailValidateRevokeCancelPlanRequestHubDto', 'Office365DetailValidateRevokeCancelPlanResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ValidateOffice365DetailAddOption = (requestData: Office365DetailValidateAddOptionRequestHubDto, receiveCallBack: (data: Office365DetailValidateAddOptionResponseHubDto) => void, receiveErrorCallBack: (data: Office365DetailValidateAddOptionResponseHubDto) => void, noSessionCallBack: (data: Office365DetailValidateAddOptionResponseHubDto) => void, completeCallBack: (data: Office365DetailValidateAddOptionResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: Office365DetailValidateAddOptionResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: Office365DetailValidateAddOptionResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/Office365Hub/ValidateOffice365DetailAddOption';

        let responseData: Office365DetailValidateAddOptionResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('Office365Hub', 'ValidateOffice365DetailAddOption', 'Office365DetailValidateAddOptionRequestHubDto', 'Office365DetailValidateAddOptionResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('Office365Hub', 'ValidateOffice365DetailAddOption', 'Office365DetailValidateAddOptionRequestHubDto', 'Office365DetailValidateAddOptionResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('Office365Hub', 'ValidateOffice365DetailAddOption', 'Office365DetailValidateAddOptionRequestHubDto', 'Office365DetailValidateAddOptionResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ValidateOffice365DetailCancelOption = (requestData: Office365DetailValidateCancelOptionRequestHubDto, receiveCallBack: (data: Office365DetailValidateCancelOptionResponseHubDto) => void, receiveErrorCallBack: (data: Office365DetailValidateCancelOptionResponseHubDto) => void, noSessionCallBack: (data: Office365DetailValidateCancelOptionResponseHubDto) => void, completeCallBack: (data: Office365DetailValidateCancelOptionResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: Office365DetailValidateCancelOptionResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: Office365DetailValidateCancelOptionResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/Office365Hub/ValidateOffice365DetailCancelOption';

        let responseData: Office365DetailValidateCancelOptionResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('Office365Hub', 'ValidateOffice365DetailCancelOption', 'Office365DetailValidateCancelOptionRequestHubDto', 'Office365DetailValidateCancelOptionResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('Office365Hub', 'ValidateOffice365DetailCancelOption', 'Office365DetailValidateCancelOptionRequestHubDto', 'Office365DetailValidateCancelOptionResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('Office365Hub', 'ValidateOffice365DetailCancelOption', 'Office365DetailValidateCancelOptionRequestHubDto', 'Office365DetailValidateCancelOptionResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ValidateOffice365DetailRevokeCancelOption = (requestData: Office365DetailValidateRevokeCancelOptionRequestHubDto, receiveCallBack: (data: Office365DetailValidateRevokeCancelOptionResponseHubDto) => void, receiveErrorCallBack: (data: Office365DetailValidateRevokeCancelOptionResponseHubDto) => void, noSessionCallBack: (data: Office365DetailValidateRevokeCancelOptionResponseHubDto) => void, completeCallBack: (data: Office365DetailValidateRevokeCancelOptionResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: Office365DetailValidateRevokeCancelOptionResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: Office365DetailValidateRevokeCancelOptionResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/Office365Hub/ValidateOffice365DetailRevokeCancelOption';

        let responseData: Office365DetailValidateRevokeCancelOptionResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('Office365Hub', 'ValidateOffice365DetailRevokeCancelOption', 'Office365DetailValidateRevokeCancelOptionRequestHubDto', 'Office365DetailValidateRevokeCancelOptionResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('Office365Hub', 'ValidateOffice365DetailRevokeCancelOption', 'Office365DetailValidateRevokeCancelOptionRequestHubDto', 'Office365DetailValidateRevokeCancelOptionResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('Office365Hub', 'ValidateOffice365DetailRevokeCancelOption', 'Office365DetailValidateRevokeCancelOptionRequestHubDto', 'Office365DetailValidateRevokeCancelOptionResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetOffice365MessageSetting = (receiveCallBack: (data: Office365MessageResponseHubDto) => void, receiveErrorCallBack: (data: Office365MessageResponseHubDto) => void, noSessionCallBack: (data: Office365MessageResponseHubDto) => void, completeCallBack: (data: Office365MessageResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: Office365MessageResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: Office365MessageResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/Office365Hub/GetOffice365MessageSetting';

        let responseData: Office365MessageResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('Office365Hub', 'GetOffice365MessageSetting', null, 'Office365MessageResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('Office365Hub', 'GetOffice365MessageSetting', null, 'Office365MessageResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('Office365Hub', 'GetOffice365MessageSetting', null, 'Office365MessageResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetOffice365Plan = (requestData: Office365PlanRequestHubDto, receiveCallBack: (data: Office365PlanResponseHubDto) => void, receiveErrorCallBack: (data: Office365PlanResponseHubDto) => void, noSessionCallBack: (data: Office365PlanResponseHubDto) => void, completeCallBack: (data: Office365PlanResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: Office365PlanResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: Office365PlanResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/Office365Hub/GetOffice365Plan';

        let responseData: Office365PlanResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('Office365Hub', 'GetOffice365Plan', 'Office365PlanRequestHubDto', 'Office365PlanResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('Office365Hub', 'GetOffice365Plan', 'Office365PlanRequestHubDto', 'Office365PlanResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('Office365Hub', 'GetOffice365Plan', 'Office365PlanRequestHubDto', 'Office365PlanResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetOffice365PlanChangedDetail = (requestData: Office365PlanChangedDetailRequestHubDto, receiveCallBack: (data: Office365PlanChangedDetailResponseHubDto) => void, receiveErrorCallBack: (data: Office365PlanChangedDetailResponseHubDto) => void, noSessionCallBack: (data: Office365PlanChangedDetailResponseHubDto) => void, completeCallBack: (data: Office365PlanChangedDetailResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: Office365PlanChangedDetailResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: Office365PlanChangedDetailResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/Office365Hub/GetOffice365PlanChangedDetail';

        let responseData: Office365PlanChangedDetailResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('Office365Hub', 'GetOffice365PlanChangedDetail', 'Office365PlanChangedDetailRequestHubDto', 'Office365PlanChangedDetailResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('Office365Hub', 'GetOffice365PlanChangedDetail', 'Office365PlanChangedDetailRequestHubDto', 'Office365PlanChangedDetailResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('Office365Hub', 'GetOffice365PlanChangedDetail', 'Office365PlanChangedDetailRequestHubDto', 'Office365PlanChangedDetailResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ChangeOffice365Plan = (requestData: Office365PlanChangeRequestHubDto, receiveCallBack: (data: Office365PlanChangeResponseHubDto) => void, receiveErrorCallBack: (data: Office365PlanChangeResponseHubDto) => void, noSessionCallBack: (data: Office365PlanChangeResponseHubDto) => void, completeCallBack: (data: Office365PlanChangeResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: Office365PlanChangeResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: Office365PlanChangeResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/Office365Hub/ChangeOffice365Plan';

        let responseData: Office365PlanChangeResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('Office365Hub', 'ChangeOffice365Plan', 'Office365PlanChangeRequestHubDto', 'Office365PlanChangeResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('Office365Hub', 'ChangeOffice365Plan', 'Office365PlanChangeRequestHubDto', 'Office365PlanChangeResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('Office365Hub', 'ChangeOffice365Plan', 'Office365PlanChangeRequestHubDto', 'Office365PlanChangeResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetOffice365CancelPlanData = (requestData: Office365PlanCancelDataRequestHubDto, receiveCallBack: (data: Office365PlanCancelDataResponseHubDto) => void, receiveErrorCallBack: (data: Office365PlanCancelDataResponseHubDto) => void, noSessionCallBack: (data: Office365PlanCancelDataResponseHubDto) => void, completeCallBack: (data: Office365PlanCancelDataResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: Office365PlanCancelDataResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: Office365PlanCancelDataResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/Office365Hub/GetOffice365CancelPlanData';

        let responseData: Office365PlanCancelDataResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('Office365Hub', 'GetOffice365CancelPlanData', 'Office365PlanCancelDataRequestHubDto', 'Office365PlanCancelDataResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('Office365Hub', 'GetOffice365CancelPlanData', 'Office365PlanCancelDataRequestHubDto', 'Office365PlanCancelDataResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('Office365Hub', 'GetOffice365CancelPlanData', 'Office365PlanCancelDataRequestHubDto', 'Office365PlanCancelDataResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public CancelOffice365Plan = (requestData: Office365PlanCancelRequestHubDto, receiveCallBack: (data: Office365PlanCancelResponseHubDto) => void, receiveErrorCallBack: (data: Office365PlanCancelResponseHubDto) => void, noSessionCallBack: (data: Office365PlanCancelResponseHubDto) => void, completeCallBack: (data: Office365PlanCancelResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: Office365PlanCancelResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: Office365PlanCancelResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/Office365Hub/CancelOffice365Plan';

        let responseData: Office365PlanCancelResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('Office365Hub', 'CancelOffice365Plan', 'Office365PlanCancelRequestHubDto', 'Office365PlanCancelResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('Office365Hub', 'CancelOffice365Plan', 'Office365PlanCancelRequestHubDto', 'Office365PlanCancelResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('Office365Hub', 'CancelOffice365Plan', 'Office365PlanCancelRequestHubDto', 'Office365PlanCancelResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetOffice365AddOptions = (requestData: Office365OptionAddGetRequestHubDto, receiveCallBack: (data: Office365OptionAddSelectListResponseHubDto) => void, receiveErrorCallBack: (data: Office365OptionAddSelectListResponseHubDto) => void, noSessionCallBack: (data: Office365OptionAddSelectListResponseHubDto) => void, completeCallBack: (data: Office365OptionAddSelectListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: Office365OptionAddSelectListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: Office365OptionAddSelectListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/Office365Hub/GetOffice365AddOptions';

        let responseData: Office365OptionAddSelectListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('Office365Hub', 'GetOffice365AddOptions', 'Office365OptionAddGetRequestHubDto', 'Office365OptionAddSelectListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('Office365Hub', 'GetOffice365AddOptions', 'Office365OptionAddGetRequestHubDto', 'Office365OptionAddSelectListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('Office365Hub', 'GetOffice365AddOptions', 'Office365OptionAddGetRequestHubDto', 'Office365OptionAddSelectListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetOffice365AddOptionDetail = (requestData: Office365OptionAddDetailGetRequestHubDto, receiveCallBack: (data: Office365OptionAddDetailGetResponseHubDto) => void, receiveErrorCallBack: (data: Office365OptionAddDetailGetResponseHubDto) => void, noSessionCallBack: (data: Office365OptionAddDetailGetResponseHubDto) => void, completeCallBack: (data: Office365OptionAddDetailGetResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: Office365OptionAddDetailGetResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: Office365OptionAddDetailGetResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/Office365Hub/GetOffice365AddOptionDetail';

        let responseData: Office365OptionAddDetailGetResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('Office365Hub', 'GetOffice365AddOptionDetail', 'Office365OptionAddDetailGetRequestHubDto', 'Office365OptionAddDetailGetResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('Office365Hub', 'GetOffice365AddOptionDetail', 'Office365OptionAddDetailGetRequestHubDto', 'Office365OptionAddDetailGetResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('Office365Hub', 'GetOffice365AddOptionDetail', 'Office365OptionAddDetailGetRequestHubDto', 'Office365OptionAddDetailGetResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetOffice365CancelOptionDetail = (requestData: Office365OptionCancelDetailRequestHubDto, receiveCallBack: (data: Office365OptionCancelDetailResponseHubDto) => void, receiveErrorCallBack: (data: Office365OptionCancelDetailResponseHubDto) => void, noSessionCallBack: (data: Office365OptionCancelDetailResponseHubDto) => void, completeCallBack: (data: Office365OptionCancelDetailResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: Office365OptionCancelDetailResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: Office365OptionCancelDetailResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/Office365Hub/GetOffice365CancelOptionDetail';

        let responseData: Office365OptionCancelDetailResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('Office365Hub', 'GetOffice365CancelOptionDetail', 'Office365OptionCancelDetailRequestHubDto', 'Office365OptionCancelDetailResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('Office365Hub', 'GetOffice365CancelOptionDetail', 'Office365OptionCancelDetailRequestHubDto', 'Office365OptionCancelDetailResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('Office365Hub', 'GetOffice365CancelOptionDetail', 'Office365OptionCancelDetailRequestHubDto', 'Office365OptionCancelDetailResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public CancelOffice365CancelOption = (requestData: Office365OptionCancelRequestHubDto, receiveCallBack: (data: Office365OptionCancelResponseHubDto) => void, receiveErrorCallBack: (data: Office365OptionCancelResponseHubDto) => void, noSessionCallBack: (data: Office365OptionCancelResponseHubDto) => void, completeCallBack: (data: Office365OptionCancelResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: Office365OptionCancelResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: Office365OptionCancelResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/Office365Hub/CancelOffice365CancelOption';

        let responseData: Office365OptionCancelResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('Office365Hub', 'CancelOffice365CancelOption', 'Office365OptionCancelRequestHubDto', 'Office365OptionCancelResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('Office365Hub', 'CancelOffice365CancelOption', 'Office365OptionCancelRequestHubDto', 'Office365OptionCancelResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('Office365Hub', 'CancelOffice365CancelOption', 'Office365OptionCancelRequestHubDto', 'Office365OptionCancelResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetOffice365Messages = (receiveCallBack: (data: Office365MessageResponseHubDto) => void, receiveErrorCallBack: (data: Office365MessageResponseHubDto) => void, noSessionCallBack: (data: Office365MessageResponseHubDto) => void, completeCallBack: (data: Office365MessageResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: Office365MessageResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: Office365MessageResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/Office365Hub/GetOffice365Messages';

        let responseData: Office365MessageResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('Office365Hub', 'GetOffice365Messages', null, 'Office365MessageResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('Office365Hub', 'GetOffice365Messages', null, 'Office365MessageResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('Office365Hub', 'GetOffice365Messages', null, 'Office365MessageResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetServerRedirection = (requestData: ServerRedirectionRequestHubDto, receiveCallBack: (data: ServerRedirectionResponseHubDto) => void, receiveErrorCallBack: (data: ServerRedirectionResponseHubDto) => void, noSessionCallBack: (data: ServerRedirectionResponseHubDto) => void, completeCallBack: (data: ServerRedirectionResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: ServerRedirectionResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: ServerRedirectionResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/Office365Hub/GetServerRedirection';

        let responseData: ServerRedirectionResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('Office365Hub', 'GetServerRedirection', 'ServerRedirectionRequestHubDto', 'ServerRedirectionResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('Office365Hub', 'GetServerRedirection', 'ServerRedirectionRequestHubDto', 'ServerRedirectionResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('Office365Hub', 'GetServerRedirection', 'ServerRedirectionRequestHubDto', 'ServerRedirectionResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }

}



export class SdHubManager {
    /**
     * コンストラクタ
     */
    constructor(private http: HttpClient) {
    }

	// リソース破棄
    public Dispose = (): void => {
    }



    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public SdList = (requestData: SdListRequestHubDto, receiveCallBack: (data: SdListResponseHubDto) => void, receiveErrorCallBack: (data: SdListResponseHubDto) => void, noSessionCallBack: (data: SdListResponseHubDto) => void, completeCallBack: (data: SdListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdHub/SdList';

        let responseData: SdListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdHub', 'SdList', 'SdListRequestHubDto', 'SdListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdHub', 'SdList', 'SdListRequestHubDto', 'SdListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdHub', 'SdList', 'SdListRequestHubDto', 'SdListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetSdDetail = (requestData: SdDetailRequestHubDto, receiveCallBack: (data: SdDetailResponseHubDto) => void, receiveErrorCallBack: (data: SdDetailResponseHubDto) => void, noSessionCallBack: (data: SdDetailResponseHubDto) => void, completeCallBack: (data: SdDetailResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdDetailResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdDetailResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdHub/GetSdDetail';

        let responseData: SdDetailResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdHub', 'GetSdDetail', 'SdDetailRequestHubDto', 'SdDetailResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdHub', 'GetSdDetail', 'SdDetailRequestHubDto', 'SdDetailResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdHub', 'GetSdDetail', 'SdDetailRequestHubDto', 'SdDetailResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetSdDetailControlPanel = (requestData: SdDetailControlPanelRequestHubDto, receiveCallBack: (data: SdDetailControlPanelResponseHubDto) => void, receiveErrorCallBack: (data: SdDetailControlPanelResponseHubDto) => void, noSessionCallBack: (data: SdDetailControlPanelResponseHubDto) => void, completeCallBack: (data: SdDetailControlPanelResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdDetailControlPanelResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdDetailControlPanelResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdHub/GetSdDetailControlPanel';

        let responseData: SdDetailControlPanelResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdHub', 'GetSdDetailControlPanel', 'SdDetailControlPanelRequestHubDto', 'SdDetailControlPanelResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdHub', 'GetSdDetailControlPanel', 'SdDetailControlPanelRequestHubDto', 'SdDetailControlPanelResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdHub', 'GetSdDetailControlPanel', 'SdDetailControlPanelRequestHubDto', 'SdDetailControlPanelResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetSdDetailBusinessMailControlPanel = (requestData: SdDetailBusinessMailControlPanelRequestHubDto, receiveCallBack: (data: SdDetailBusinessMailControlPanelResponseHubDto) => void, receiveErrorCallBack: (data: SdDetailBusinessMailControlPanelResponseHubDto) => void, noSessionCallBack: (data: SdDetailBusinessMailControlPanelResponseHubDto) => void, completeCallBack: (data: SdDetailBusinessMailControlPanelResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdDetailBusinessMailControlPanelResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdDetailBusinessMailControlPanelResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdHub/GetSdDetailBusinessMailControlPanel';

        let responseData: SdDetailBusinessMailControlPanelResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdHub', 'GetSdDetailBusinessMailControlPanel', 'SdDetailBusinessMailControlPanelRequestHubDto', 'SdDetailBusinessMailControlPanelResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdHub', 'GetSdDetailBusinessMailControlPanel', 'SdDetailBusinessMailControlPanelRequestHubDto', 'SdDetailBusinessMailControlPanelResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdHub', 'GetSdDetailBusinessMailControlPanel', 'SdDetailBusinessMailControlPanelRequestHubDto', 'SdDetailBusinessMailControlPanelResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetSdDetailOption = (requestData: SdDetailOptionRequestHubDto, receiveCallBack: (data: SdDetailOptionResponseHubDto) => void, receiveErrorCallBack: (data: SdDetailOptionResponseHubDto) => void, noSessionCallBack: (data: SdDetailOptionResponseHubDto) => void, completeCallBack: (data: SdDetailOptionResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdDetailOptionResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdDetailOptionResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdHub/GetSdDetailOption';

        let responseData: SdDetailOptionResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdHub', 'GetSdDetailOption', 'SdDetailOptionRequestHubDto', 'SdDetailOptionResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdHub', 'GetSdDetailOption', 'SdDetailOptionRequestHubDto', 'SdDetailOptionResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdHub', 'GetSdDetailOption', 'SdDetailOptionRequestHubDto', 'SdDetailOptionResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetSdSubDomain = (requestData: SdDetailRequestHubDto, receiveCallBack: (data: SdSubDomainResponseHubDto) => void, receiveErrorCallBack: (data: SdSubDomainResponseHubDto) => void, noSessionCallBack: (data: SdSubDomainResponseHubDto) => void, completeCallBack: (data: SdSubDomainResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdSubDomainResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdSubDomainResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdHub/GetSdSubDomain';

        let responseData: SdSubDomainResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdHub', 'GetSdSubDomain', 'SdDetailRequestHubDto', 'SdSubDomainResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdHub', 'GetSdSubDomain', 'SdDetailRequestHubDto', 'SdSubDomainResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdHub', 'GetSdSubDomain', 'SdDetailRequestHubDto', 'SdSubDomainResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public RevokeSdDetailCancelPlan = (requestData: SdDetailCancelPlanRevokeRequestHubDto, receiveCallBack: (data: SdDetailCancelPlanRevokeResponseHubDto) => void, receiveErrorCallBack: (data: SdDetailCancelPlanRevokeResponseHubDto) => void, noSessionCallBack: (data: SdDetailCancelPlanRevokeResponseHubDto) => void, completeCallBack: (data: SdDetailCancelPlanRevokeResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdDetailCancelPlanRevokeResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdDetailCancelPlanRevokeResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdHub/RevokeSdDetailCancelPlan';

        let responseData: SdDetailCancelPlanRevokeResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdHub', 'RevokeSdDetailCancelPlan', 'SdDetailCancelPlanRevokeRequestHubDto', 'SdDetailCancelPlanRevokeResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdHub', 'RevokeSdDetailCancelPlan', 'SdDetailCancelPlanRevokeRequestHubDto', 'SdDetailCancelPlanRevokeResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdHub', 'RevokeSdDetailCancelPlan', 'SdDetailCancelPlanRevokeRequestHubDto', 'SdDetailCancelPlanRevokeResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetSdDetailCancelOptionDetail = (requestData: SdDetailCancelOptionDetailRequestHubDto, receiveCallBack: (data: SdDetailCancelOptionDetailResponseHubDto) => void, receiveErrorCallBack: (data: SdDetailCancelOptionDetailResponseHubDto) => void, noSessionCallBack: (data: SdDetailCancelOptionDetailResponseHubDto) => void, completeCallBack: (data: SdDetailCancelOptionDetailResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdDetailCancelOptionDetailResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdDetailCancelOptionDetailResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdHub/GetSdDetailCancelOptionDetail';

        let responseData: SdDetailCancelOptionDetailResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdHub', 'GetSdDetailCancelOptionDetail', 'SdDetailCancelOptionDetailRequestHubDto', 'SdDetailCancelOptionDetailResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdHub', 'GetSdDetailCancelOptionDetail', 'SdDetailCancelOptionDetailRequestHubDto', 'SdDetailCancelOptionDetailResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdHub', 'GetSdDetailCancelOptionDetail', 'SdDetailCancelOptionDetailRequestHubDto', 'SdDetailCancelOptionDetailResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public RevokeSdDetailCancelOption = (requestData: SdDetailCancelOptionRevokeRequestHubDto, receiveCallBack: (data: SdDetailCancelOptionRevokeResponseHubDto) => void, receiveErrorCallBack: (data: SdDetailCancelOptionRevokeResponseHubDto) => void, noSessionCallBack: (data: SdDetailCancelOptionRevokeResponseHubDto) => void, completeCallBack: (data: SdDetailCancelOptionRevokeResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdDetailCancelOptionRevokeResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdDetailCancelOptionRevokeResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdHub/RevokeSdDetailCancelOption';

        let responseData: SdDetailCancelOptionRevokeResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdHub', 'RevokeSdDetailCancelOption', 'SdDetailCancelOptionRevokeRequestHubDto', 'SdDetailCancelOptionRevokeResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdHub', 'RevokeSdDetailCancelOption', 'SdDetailCancelOptionRevokeRequestHubDto', 'SdDetailCancelOptionRevokeResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdHub', 'RevokeSdDetailCancelOption', 'SdDetailCancelOptionRevokeRequestHubDto', 'SdDetailCancelOptionRevokeResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ChangeSdDetailSubDomain = (requestData: SdDetailSubDomainChangeRequestHubDto, receiveCallBack: (data: SdDetailSubDomainChangeResponseHubDto) => void, receiveErrorCallBack: (data: SdDetailSubDomainChangeResponseHubDto) => void, noSessionCallBack: (data: SdDetailSubDomainChangeResponseHubDto) => void, completeCallBack: (data: SdDetailSubDomainChangeResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdDetailSubDomainChangeResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdDetailSubDomainChangeResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdHub/ChangeSdDetailSubDomain';

        let responseData: SdDetailSubDomainChangeResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdHub', 'ChangeSdDetailSubDomain', 'SdDetailSubDomainChangeRequestHubDto', 'SdDetailSubDomainChangeResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdHub', 'ChangeSdDetailSubDomain', 'SdDetailSubDomainChangeRequestHubDto', 'SdDetailSubDomainChangeResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdHub', 'ChangeSdDetailSubDomain', 'SdDetailSubDomainChangeRequestHubDto', 'SdDetailSubDomainChangeResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetSdDetailDomain = (requestData: SdDetailDomainRequestHubDto, receiveCallBack: (data: SdDetailDomainResponseHubDto) => void, receiveErrorCallBack: (data: SdDetailDomainResponseHubDto) => void, noSessionCallBack: (data: SdDetailDomainResponseHubDto) => void, completeCallBack: (data: SdDetailDomainResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdDetailDomainResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdDetailDomainResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdHub/GetSdDetailDomain';

        let responseData: SdDetailDomainResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdHub', 'GetSdDetailDomain', 'SdDetailDomainRequestHubDto', 'SdDetailDomainResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdHub', 'GetSdDetailDomain', 'SdDetailDomainRequestHubDto', 'SdDetailDomainResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdHub', 'GetSdDetailDomain', 'SdDetailDomainRequestHubDto', 'SdDetailDomainResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetAllRegisteredDomains = (requestData: SdDetailRequestHubDto, receiveCallBack: (data: SdDetailDomainResponseHubDto) => void, receiveErrorCallBack: (data: SdDetailDomainResponseHubDto) => void, noSessionCallBack: (data: SdDetailDomainResponseHubDto) => void, completeCallBack: (data: SdDetailDomainResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdDetailDomainResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdDetailDomainResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdHub/GetAllRegisteredDomains';

        let responseData: SdDetailDomainResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdHub', 'GetAllRegisteredDomains', 'SdDetailRequestHubDto', 'SdDetailDomainResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdHub', 'GetAllRegisteredDomains', 'SdDetailRequestHubDto', 'SdDetailDomainResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdHub', 'GetAllRegisteredDomains', 'SdDetailRequestHubDto', 'SdDetailDomainResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public CancelSdDetailDomain = (requestData: SdDetailDomainCancelRequestHubDto, receiveCallBack: (data: SdDetailDomainCancelResponseHubDto) => void, receiveErrorCallBack: (data: SdDetailDomainCancelResponseHubDto) => void, noSessionCallBack: (data: SdDetailDomainCancelResponseHubDto) => void, completeCallBack: (data: SdDetailDomainCancelResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdDetailDomainCancelResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdDetailDomainCancelResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdHub/CancelSdDetailDomain';

        let responseData: SdDetailDomainCancelResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdHub', 'CancelSdDetailDomain', 'SdDetailDomainCancelRequestHubDto', 'SdDetailDomainCancelResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdHub', 'CancelSdDetailDomain', 'SdDetailDomainCancelRequestHubDto', 'SdDetailDomainCancelResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdHub', 'CancelSdDetailDomain', 'SdDetailDomainCancelRequestHubDto', 'SdDetailDomainCancelResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ValidateSdDetailChangePlan = (requestData: SdDetailValidateChangePlanRequestHubDto, receiveCallBack: (data: SdDetailValidateChangePlanResponseHubDto) => void, receiveErrorCallBack: (data: SdDetailValidateChangePlanResponseHubDto) => void, noSessionCallBack: (data: SdDetailValidateChangePlanResponseHubDto) => void, completeCallBack: (data: SdDetailValidateChangePlanResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdDetailValidateChangePlanResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdDetailValidateChangePlanResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdHub/ValidateSdDetailChangePlan';

        let responseData: SdDetailValidateChangePlanResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdHub', 'ValidateSdDetailChangePlan', 'SdDetailValidateChangePlanRequestHubDto', 'SdDetailValidateChangePlanResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdHub', 'ValidateSdDetailChangePlan', 'SdDetailValidateChangePlanRequestHubDto', 'SdDetailValidateChangePlanResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdHub', 'ValidateSdDetailChangePlan', 'SdDetailValidateChangePlanRequestHubDto', 'SdDetailValidateChangePlanResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ValidateSdDetailCancelPlan = (requestData: SdDetailValidateCancelPlanRequestHubDto, receiveCallBack: (data: SdDetailValidateCancelPlanResponseHubDto) => void, receiveErrorCallBack: (data: SdDetailValidateCancelPlanResponseHubDto) => void, noSessionCallBack: (data: SdDetailValidateCancelPlanResponseHubDto) => void, completeCallBack: (data: SdDetailValidateCancelPlanResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdDetailValidateCancelPlanResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdDetailValidateCancelPlanResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdHub/ValidateSdDetailCancelPlan';

        let responseData: SdDetailValidateCancelPlanResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdHub', 'ValidateSdDetailCancelPlan', 'SdDetailValidateCancelPlanRequestHubDto', 'SdDetailValidateCancelPlanResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdHub', 'ValidateSdDetailCancelPlan', 'SdDetailValidateCancelPlanRequestHubDto', 'SdDetailValidateCancelPlanResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdHub', 'ValidateSdDetailCancelPlan', 'SdDetailValidateCancelPlanRequestHubDto', 'SdDetailValidateCancelPlanResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ValidateSdDetailCancellationGuidePlan = (requestData: SdDetailValidateCancelPlanRequestHubDto, receiveCallBack: (data: SdDetailValidateCancellationGuidePlanResponseHubDto) => void, receiveErrorCallBack: (data: SdDetailValidateCancellationGuidePlanResponseHubDto) => void, noSessionCallBack: (data: SdDetailValidateCancellationGuidePlanResponseHubDto) => void, completeCallBack: (data: SdDetailValidateCancellationGuidePlanResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdDetailValidateCancellationGuidePlanResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdDetailValidateCancellationGuidePlanResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdHub/ValidateSdDetailCancellationGuidePlan';

        let responseData: SdDetailValidateCancellationGuidePlanResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdHub', 'ValidateSdDetailCancellationGuidePlan', 'SdDetailValidateCancelPlanRequestHubDto', 'SdDetailValidateCancellationGuidePlanResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdHub', 'ValidateSdDetailCancellationGuidePlan', 'SdDetailValidateCancelPlanRequestHubDto', 'SdDetailValidateCancellationGuidePlanResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdHub', 'ValidateSdDetailCancellationGuidePlan', 'SdDetailValidateCancelPlanRequestHubDto', 'SdDetailValidateCancellationGuidePlanResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ValidateSdDetailRevokeCancellationGuidePlan = (requestData: SdDetailRequestHubDto, receiveCallBack: (data: SdDetailValidateCancellationGuidePlanResponseHubDto) => void, receiveErrorCallBack: (data: SdDetailValidateCancellationGuidePlanResponseHubDto) => void, noSessionCallBack: (data: SdDetailValidateCancellationGuidePlanResponseHubDto) => void, completeCallBack: (data: SdDetailValidateCancellationGuidePlanResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdDetailValidateCancellationGuidePlanResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdDetailValidateCancellationGuidePlanResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdHub/ValidateSdDetailRevokeCancellationGuidePlan';

        let responseData: SdDetailValidateCancellationGuidePlanResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdHub', 'ValidateSdDetailRevokeCancellationGuidePlan', 'SdDetailRequestHubDto', 'SdDetailValidateCancellationGuidePlanResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdHub', 'ValidateSdDetailRevokeCancellationGuidePlan', 'SdDetailRequestHubDto', 'SdDetailValidateCancellationGuidePlanResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdHub', 'ValidateSdDetailRevokeCancellationGuidePlan', 'SdDetailRequestHubDto', 'SdDetailValidateCancellationGuidePlanResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ValidateSdDetailRevokeCancelPlan = (requestData: SdDetailValidateRevokeCancelPlanRequestHubDto, receiveCallBack: (data: SdDetailValidateRevokeCancelPlanResponseHubDto) => void, receiveErrorCallBack: (data: SdDetailValidateRevokeCancelPlanResponseHubDto) => void, noSessionCallBack: (data: SdDetailValidateRevokeCancelPlanResponseHubDto) => void, completeCallBack: (data: SdDetailValidateRevokeCancelPlanResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdDetailValidateRevokeCancelPlanResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdDetailValidateRevokeCancelPlanResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdHub/ValidateSdDetailRevokeCancelPlan';

        let responseData: SdDetailValidateRevokeCancelPlanResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdHub', 'ValidateSdDetailRevokeCancelPlan', 'SdDetailValidateRevokeCancelPlanRequestHubDto', 'SdDetailValidateRevokeCancelPlanResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdHub', 'ValidateSdDetailRevokeCancelPlan', 'SdDetailValidateRevokeCancelPlanRequestHubDto', 'SdDetailValidateRevokeCancelPlanResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdHub', 'ValidateSdDetailRevokeCancelPlan', 'SdDetailValidateRevokeCancelPlanRequestHubDto', 'SdDetailValidateRevokeCancelPlanResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ValidateSdDetailAddOption = (requestData: SdDetailValidateAddOptionRequestHubDto, receiveCallBack: (data: SdDetailValidateAddOptionResponseHubDto) => void, receiveErrorCallBack: (data: SdDetailValidateAddOptionResponseHubDto) => void, noSessionCallBack: (data: SdDetailValidateAddOptionResponseHubDto) => void, completeCallBack: (data: SdDetailValidateAddOptionResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdDetailValidateAddOptionResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdDetailValidateAddOptionResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdHub/ValidateSdDetailAddOption';

        let responseData: SdDetailValidateAddOptionResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdHub', 'ValidateSdDetailAddOption', 'SdDetailValidateAddOptionRequestHubDto', 'SdDetailValidateAddOptionResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdHub', 'ValidateSdDetailAddOption', 'SdDetailValidateAddOptionRequestHubDto', 'SdDetailValidateAddOptionResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdHub', 'ValidateSdDetailAddOption', 'SdDetailValidateAddOptionRequestHubDto', 'SdDetailValidateAddOptionResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ValidateSdDetailChangeOption = (requestData: SdDetailValidateChangeOptionRequestHubDto, receiveCallBack: (data: SdDetailValidateChangeOptionResponseHubDto) => void, receiveErrorCallBack: (data: SdDetailValidateChangeOptionResponseHubDto) => void, noSessionCallBack: (data: SdDetailValidateChangeOptionResponseHubDto) => void, completeCallBack: (data: SdDetailValidateChangeOptionResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdDetailValidateChangeOptionResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdDetailValidateChangeOptionResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdHub/ValidateSdDetailChangeOption';

        let responseData: SdDetailValidateChangeOptionResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdHub', 'ValidateSdDetailChangeOption', 'SdDetailValidateChangeOptionRequestHubDto', 'SdDetailValidateChangeOptionResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdHub', 'ValidateSdDetailChangeOption', 'SdDetailValidateChangeOptionRequestHubDto', 'SdDetailValidateChangeOptionResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdHub', 'ValidateSdDetailChangeOption', 'SdDetailValidateChangeOptionRequestHubDto', 'SdDetailValidateChangeOptionResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ValidateSdDetailCancelOption = (requestData: SdDetailValidateCancelOptionRequestHubDto, receiveCallBack: (data: SdDetailValidateCancelOptionResponseHubDto) => void, receiveErrorCallBack: (data: SdDetailValidateCancelOptionResponseHubDto) => void, noSessionCallBack: (data: SdDetailValidateCancelOptionResponseHubDto) => void, completeCallBack: (data: SdDetailValidateCancelOptionResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdDetailValidateCancelOptionResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdDetailValidateCancelOptionResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdHub/ValidateSdDetailCancelOption';

        let responseData: SdDetailValidateCancelOptionResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdHub', 'ValidateSdDetailCancelOption', 'SdDetailValidateCancelOptionRequestHubDto', 'SdDetailValidateCancelOptionResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdHub', 'ValidateSdDetailCancelOption', 'SdDetailValidateCancelOptionRequestHubDto', 'SdDetailValidateCancelOptionResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdHub', 'ValidateSdDetailCancelOption', 'SdDetailValidateCancelOptionRequestHubDto', 'SdDetailValidateCancelOptionResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ValidateSdDetailRevokeCancelOption = (requestData: SdDetailValidateRevokeCancelOptionRequestHubDto, receiveCallBack: (data: SdDetailValidateRevokeCancelOptionResponseHubDto) => void, receiveErrorCallBack: (data: SdDetailValidateRevokeCancelOptionResponseHubDto) => void, noSessionCallBack: (data: SdDetailValidateRevokeCancelOptionResponseHubDto) => void, completeCallBack: (data: SdDetailValidateRevokeCancelOptionResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdDetailValidateRevokeCancelOptionResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdDetailValidateRevokeCancelOptionResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdHub/ValidateSdDetailRevokeCancelOption';

        let responseData: SdDetailValidateRevokeCancelOptionResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdHub', 'ValidateSdDetailRevokeCancelOption', 'SdDetailValidateRevokeCancelOptionRequestHubDto', 'SdDetailValidateRevokeCancelOptionResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdHub', 'ValidateSdDetailRevokeCancelOption', 'SdDetailValidateRevokeCancelOptionRequestHubDto', 'SdDetailValidateRevokeCancelOptionResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdHub', 'ValidateSdDetailRevokeCancelOption', 'SdDetailValidateRevokeCancelOptionRequestHubDto', 'SdDetailValidateRevokeCancelOptionResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ValidateSdDetailCancelDomain = (requestData: SdDetailValidateCancelDomainRequestHubDto, receiveCallBack: (data: SdDetailValidateCancelDomainResponseHubDto) => void, receiveErrorCallBack: (data: SdDetailValidateCancelDomainResponseHubDto) => void, noSessionCallBack: (data: SdDetailValidateCancelDomainResponseHubDto) => void, completeCallBack: (data: SdDetailValidateCancelDomainResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdDetailValidateCancelDomainResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdDetailValidateCancelDomainResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdHub/ValidateSdDetailCancelDomain';

        let responseData: SdDetailValidateCancelDomainResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdHub', 'ValidateSdDetailCancelDomain', 'SdDetailValidateCancelDomainRequestHubDto', 'SdDetailValidateCancelDomainResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdHub', 'ValidateSdDetailCancelDomain', 'SdDetailValidateCancelDomainRequestHubDto', 'SdDetailValidateCancelDomainResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdHub', 'ValidateSdDetailCancelDomain', 'SdDetailValidateCancelDomainRequestHubDto', 'SdDetailValidateCancelDomainResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ValidateSdChangeSubDomain = (requestData: SdDetailSubDomainChangeRequestHubDto, receiveCallBack: (data: SdValidateChangeSubDomainmResponseHubDto) => void, receiveErrorCallBack: (data: SdValidateChangeSubDomainmResponseHubDto) => void, noSessionCallBack: (data: SdValidateChangeSubDomainmResponseHubDto) => void, completeCallBack: (data: SdValidateChangeSubDomainmResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdValidateChangeSubDomainmResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdValidateChangeSubDomainmResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdHub/ValidateSdChangeSubDomain';

        let responseData: SdValidateChangeSubDomainmResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdHub', 'ValidateSdChangeSubDomain', 'SdDetailSubDomainChangeRequestHubDto', 'SdValidateChangeSubDomainmResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdHub', 'ValidateSdChangeSubDomain', 'SdDetailSubDomainChangeRequestHubDto', 'SdValidateChangeSubDomainmResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdHub', 'ValidateSdChangeSubDomain', 'SdDetailSubDomainChangeRequestHubDto', 'SdValidateChangeSubDomainmResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetSdPlan = (requestData: SdPlanRequestHubDto, receiveCallBack: (data: SdPlanResponseHubDto) => void, receiveErrorCallBack: (data: SdPlanResponseHubDto) => void, noSessionCallBack: (data: SdPlanResponseHubDto) => void, completeCallBack: (data: SdPlanResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdPlanResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdPlanResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdHub/GetSdPlan';

        let responseData: SdPlanResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdHub', 'GetSdPlan', 'SdPlanRequestHubDto', 'SdPlanResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdHub', 'GetSdPlan', 'SdPlanRequestHubDto', 'SdPlanResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdHub', 'GetSdPlan', 'SdPlanRequestHubDto', 'SdPlanResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetSdPlanChangedDetail = (requestData: SdPlanChangedDetailRequestHubDto, receiveCallBack: (data: SdPlanChangedDetailResponseHubDto) => void, receiveErrorCallBack: (data: SdPlanChangedDetailResponseHubDto) => void, noSessionCallBack: (data: SdPlanChangedDetailResponseHubDto) => void, completeCallBack: (data: SdPlanChangedDetailResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdPlanChangedDetailResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdPlanChangedDetailResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdHub/GetSdPlanChangedDetail';

        let responseData: SdPlanChangedDetailResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdHub', 'GetSdPlanChangedDetail', 'SdPlanChangedDetailRequestHubDto', 'SdPlanChangedDetailResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdHub', 'GetSdPlanChangedDetail', 'SdPlanChangedDetailRequestHubDto', 'SdPlanChangedDetailResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdHub', 'GetSdPlanChangedDetail', 'SdPlanChangedDetailRequestHubDto', 'SdPlanChangedDetailResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ChangeSdPlan = (requestData: SdPlanChangeRequestHubDto, receiveCallBack: (data: SdPlanChangeResponseHubDto) => void, receiveErrorCallBack: (data: SdPlanChangeResponseHubDto) => void, noSessionCallBack: (data: SdPlanChangeResponseHubDto) => void, completeCallBack: (data: SdPlanChangeResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdPlanChangeResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdPlanChangeResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdHub/ChangeSdPlan';

        let responseData: SdPlanChangeResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdHub', 'ChangeSdPlan', 'SdPlanChangeRequestHubDto', 'SdPlanChangeResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdHub', 'ChangeSdPlan', 'SdPlanChangeRequestHubDto', 'SdPlanChangeResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdHub', 'ChangeSdPlan', 'SdPlanChangeRequestHubDto', 'SdPlanChangeResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetSdCancelPlanData = (requestData: SdPlanCancelDataRequestHubDto, receiveCallBack: (data: SdPlanCancelDataResponseHubDto) => void, receiveErrorCallBack: (data: SdPlanCancelDataResponseHubDto) => void, noSessionCallBack: (data: SdPlanCancelDataResponseHubDto) => void, completeCallBack: (data: SdPlanCancelDataResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdPlanCancelDataResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdPlanCancelDataResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdHub/GetSdCancelPlanData';

        let responseData: SdPlanCancelDataResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdHub', 'GetSdCancelPlanData', 'SdPlanCancelDataRequestHubDto', 'SdPlanCancelDataResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdHub', 'GetSdCancelPlanData', 'SdPlanCancelDataRequestHubDto', 'SdPlanCancelDataResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdHub', 'GetSdCancelPlanData', 'SdPlanCancelDataRequestHubDto', 'SdPlanCancelDataResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public CancelSdPlan = (requestData: SdPlanCancelRequestHubDto, receiveCallBack: (data: SdPlanCancelResponseHubDto) => void, receiveErrorCallBack: (data: SdPlanCancelResponseHubDto) => void, noSessionCallBack: (data: SdPlanCancelResponseHubDto) => void, completeCallBack: (data: SdPlanCancelResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdPlanCancelResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdPlanCancelResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdHub/CancelSdPlan';

        let responseData: SdPlanCancelResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdHub', 'CancelSdPlan', 'SdPlanCancelRequestHubDto', 'SdPlanCancelResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdHub', 'CancelSdPlan', 'SdPlanCancelRequestHubDto', 'SdPlanCancelResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdHub', 'CancelSdPlan', 'SdPlanCancelRequestHubDto', 'SdPlanCancelResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetSdAddOptions = (requestData: SdOptionAddGetRequestHubDto, receiveCallBack: (data: SdAddOptionSelectListResponseHubDto) => void, receiveErrorCallBack: (data: SdAddOptionSelectListResponseHubDto) => void, noSessionCallBack: (data: SdAddOptionSelectListResponseHubDto) => void, completeCallBack: (data: SdAddOptionSelectListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdAddOptionSelectListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdAddOptionSelectListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdHub/GetSdAddOptions';

        let responseData: SdAddOptionSelectListResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdHub', 'GetSdAddOptions', 'SdOptionAddGetRequestHubDto', 'SdAddOptionSelectListResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdHub', 'GetSdAddOptions', 'SdOptionAddGetRequestHubDto', 'SdAddOptionSelectListResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdHub', 'GetSdAddOptions', 'SdOptionAddGetRequestHubDto', 'SdAddOptionSelectListResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetAddOptionSslDomains = (requestData: SdAddOptionGetSslDomainsRequestHubDto, receiveCallBack: (data: SdAddOptionGetSslDomainsResponseHubDto) => void, receiveErrorCallBack: (data: SdAddOptionGetSslDomainsResponseHubDto) => void, noSessionCallBack: (data: SdAddOptionGetSslDomainsResponseHubDto) => void, completeCallBack: (data: SdAddOptionGetSslDomainsResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdAddOptionGetSslDomainsResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdAddOptionGetSslDomainsResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdHub/GetAddOptionSslDomains';

        let responseData: SdAddOptionGetSslDomainsResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdHub', 'GetAddOptionSslDomains', 'SdAddOptionGetSslDomainsRequestHubDto', 'SdAddOptionGetSslDomainsResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdHub', 'GetAddOptionSslDomains', 'SdAddOptionGetSslDomainsRequestHubDto', 'SdAddOptionGetSslDomainsResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdHub', 'GetAddOptionSslDomains', 'SdAddOptionGetSslDomainsRequestHubDto', 'SdAddOptionGetSslDomainsResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ValidateAddOptionSslInfo = (requestData: SdAddOptionValidateSslInfoRequestHubDto, receiveCallBack: (data: SdAddOptionValidateSslInfoResponseHubDto) => void, receiveErrorCallBack: (data: SdAddOptionValidateSslInfoResponseHubDto) => void, noSessionCallBack: (data: SdAddOptionValidateSslInfoResponseHubDto) => void, completeCallBack: (data: SdAddOptionValidateSslInfoResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdAddOptionValidateSslInfoResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdAddOptionValidateSslInfoResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdHub/ValidateAddOptionSslInfo';

        let responseData: SdAddOptionValidateSslInfoResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdHub', 'ValidateAddOptionSslInfo', 'SdAddOptionValidateSslInfoRequestHubDto', 'SdAddOptionValidateSslInfoResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdHub', 'ValidateAddOptionSslInfo', 'SdAddOptionValidateSslInfoRequestHubDto', 'SdAddOptionValidateSslInfoResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdHub', 'ValidateAddOptionSslInfo', 'SdAddOptionValidateSslInfoRequestHubDto', 'SdAddOptionValidateSslInfoResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetSdAddOptionSslInfoValidation = (requestData: SdOptionAddSslInfoValidationRequestHubDto, receiveCallBack: (data: SdOptionAddSslInfoValidationResponseHubDto) => void, receiveErrorCallBack: (data: SdOptionAddSslInfoValidationResponseHubDto) => void, noSessionCallBack: (data: SdOptionAddSslInfoValidationResponseHubDto) => void, completeCallBack: (data: SdOptionAddSslInfoValidationResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdOptionAddSslInfoValidationResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdOptionAddSslInfoValidationResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdHub/GetSdAddOptionSslInfoValidation';

        let responseData: SdOptionAddSslInfoValidationResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdHub', 'GetSdAddOptionSslInfoValidation', 'SdOptionAddSslInfoValidationRequestHubDto', 'SdOptionAddSslInfoValidationResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdHub', 'GetSdAddOptionSslInfoValidation', 'SdOptionAddSslInfoValidationRequestHubDto', 'SdOptionAddSslInfoValidationResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdHub', 'GetSdAddOptionSslInfoValidation', 'SdOptionAddSslInfoValidationRequestHubDto', 'SdOptionAddSslInfoValidationResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetAddOptionSitelockDomains = (requestData: SdAddOptionSiteLockDomainsGetRequestHubDto, receiveCallBack: (data: SdAddOptionGetSitelockDomainsResponseHubDto) => void, receiveErrorCallBack: (data: SdAddOptionGetSitelockDomainsResponseHubDto) => void, noSessionCallBack: (data: SdAddOptionGetSitelockDomainsResponseHubDto) => void, completeCallBack: (data: SdAddOptionGetSitelockDomainsResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdAddOptionGetSitelockDomainsResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdAddOptionGetSitelockDomainsResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdHub/GetAddOptionSitelockDomains';

        let responseData: SdAddOptionGetSitelockDomainsResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdHub', 'GetAddOptionSitelockDomains', 'SdAddOptionSiteLockDomainsGetRequestHubDto', 'SdAddOptionGetSitelockDomainsResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdHub', 'GetAddOptionSitelockDomains', 'SdAddOptionSiteLockDomainsGetRequestHubDto', 'SdAddOptionGetSitelockDomainsResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdHub', 'GetAddOptionSitelockDomains', 'SdAddOptionSiteLockDomainsGetRequestHubDto', 'SdAddOptionGetSitelockDomainsResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetAddOptionWpCloudIpInfo = (requestData: SdAddOptionGetWpCloudIpInfoRequestHubDto, receiveCallBack: (data: SdAddOptionGetWpCloudIpInfosResponseHubDto) => void, receiveErrorCallBack: (data: SdAddOptionGetWpCloudIpInfosResponseHubDto) => void, noSessionCallBack: (data: SdAddOptionGetWpCloudIpInfosResponseHubDto) => void, completeCallBack: (data: SdAddOptionGetWpCloudIpInfosResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdAddOptionGetWpCloudIpInfosResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdAddOptionGetWpCloudIpInfosResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdHub/GetAddOptionWpCloudIpInfo';

        let responseData: SdAddOptionGetWpCloudIpInfosResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdHub', 'GetAddOptionWpCloudIpInfo', 'SdAddOptionGetWpCloudIpInfoRequestHubDto', 'SdAddOptionGetWpCloudIpInfosResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdHub', 'GetAddOptionWpCloudIpInfo', 'SdAddOptionGetWpCloudIpInfoRequestHubDto', 'SdAddOptionGetWpCloudIpInfosResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdHub', 'GetAddOptionWpCloudIpInfo', 'SdAddOptionGetWpCloudIpInfoRequestHubDto', 'SdAddOptionGetWpCloudIpInfosResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetSdAddOptionSiteLockInfoValidation = (requestData: SdOptionAddSiteLockInfoValidationRequestHubDto, receiveCallBack: (data: SdOptionAddSiteLockInfoValidationResponseHubDto) => void, receiveErrorCallBack: (data: SdOptionAddSiteLockInfoValidationResponseHubDto) => void, noSessionCallBack: (data: SdOptionAddSiteLockInfoValidationResponseHubDto) => void, completeCallBack: (data: SdOptionAddSiteLockInfoValidationResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdOptionAddSiteLockInfoValidationResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdOptionAddSiteLockInfoValidationResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdHub/GetSdAddOptionSiteLockInfoValidation';

        let responseData: SdOptionAddSiteLockInfoValidationResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdHub', 'GetSdAddOptionSiteLockInfoValidation', 'SdOptionAddSiteLockInfoValidationRequestHubDto', 'SdOptionAddSiteLockInfoValidationResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdHub', 'GetSdAddOptionSiteLockInfoValidation', 'SdOptionAddSiteLockInfoValidationRequestHubDto', 'SdOptionAddSiteLockInfoValidationResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdHub', 'GetSdAddOptionSiteLockInfoValidation', 'SdOptionAddSiteLockInfoValidationRequestHubDto', 'SdOptionAddSiteLockInfoValidationResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetAddOptionCountriesAndPrefectures = (receiveCallBack: (data: HostingCountriesAndPrefecturesResponseHubDto) => void, receiveErrorCallBack: (data: HostingCountriesAndPrefecturesResponseHubDto) => void, noSessionCallBack: (data: HostingCountriesAndPrefecturesResponseHubDto) => void, completeCallBack: (data: HostingCountriesAndPrefecturesResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: HostingCountriesAndPrefecturesResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: HostingCountriesAndPrefecturesResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdHub/GetAddOptionCountriesAndPrefectures';

        let responseData: HostingCountriesAndPrefecturesResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdHub', 'GetAddOptionCountriesAndPrefectures', null, 'HostingCountriesAndPrefecturesResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdHub', 'GetAddOptionCountriesAndPrefectures', null, 'HostingCountriesAndPrefecturesResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdHub', 'GetAddOptionCountriesAndPrefectures', null, 'HostingCountriesAndPrefecturesResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetSdAlphaSslCsrInfo = (requestData: HostingItemRequestHubDto, receiveCallBack: (data: HostingOptionAlphaSslCsrResponseHubDto) => void, receiveErrorCallBack: (data: HostingOptionAlphaSslCsrResponseHubDto) => void, noSessionCallBack: (data: HostingOptionAlphaSslCsrResponseHubDto) => void, completeCallBack: (data: HostingOptionAlphaSslCsrResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: HostingOptionAlphaSslCsrResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: HostingOptionAlphaSslCsrResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdHub/GetSdAlphaSslCsrInfo';

        let responseData: HostingOptionAlphaSslCsrResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdHub', 'GetSdAlphaSslCsrInfo', 'HostingItemRequestHubDto', 'HostingOptionAlphaSslCsrResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdHub', 'GetSdAlphaSslCsrInfo', 'HostingItemRequestHubDto', 'HostingOptionAlphaSslCsrResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdHub', 'GetSdAlphaSslCsrInfo', 'HostingItemRequestHubDto', 'HostingOptionAlphaSslCsrResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetAddOptionWeeblyDomains = (requestData: SdAddOptionGetWeeblyDomainsRequestHubDto, receiveCallBack: (data: SdAddOptionGetWeeblyDomainsResponseHubDto) => void, receiveErrorCallBack: (data: SdAddOptionGetWeeblyDomainsResponseHubDto) => void, noSessionCallBack: (data: SdAddOptionGetWeeblyDomainsResponseHubDto) => void, completeCallBack: (data: SdAddOptionGetWeeblyDomainsResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdAddOptionGetWeeblyDomainsResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdAddOptionGetWeeblyDomainsResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdHub/GetAddOptionWeeblyDomains';

        let responseData: SdAddOptionGetWeeblyDomainsResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdHub', 'GetAddOptionWeeblyDomains', 'SdAddOptionGetWeeblyDomainsRequestHubDto', 'SdAddOptionGetWeeblyDomainsResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdHub', 'GetAddOptionWeeblyDomains', 'SdAddOptionGetWeeblyDomainsRequestHubDto', 'SdAddOptionGetWeeblyDomainsResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdHub', 'GetAddOptionWeeblyDomains', 'SdAddOptionGetWeeblyDomainsRequestHubDto', 'SdAddOptionGetWeeblyDomainsResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetSdAddOptionDetail = (requestData: HostingAddOptionRequestHubDto, receiveCallBack: (data: SdOptionAddDetailGetResponseHubDto) => void, receiveErrorCallBack: (data: SdOptionAddDetailGetResponseHubDto) => void, noSessionCallBack: (data: SdOptionAddDetailGetResponseHubDto) => void, completeCallBack: (data: SdOptionAddDetailGetResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdOptionAddDetailGetResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdOptionAddDetailGetResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdHub/GetSdAddOptionDetail';

        let responseData: SdOptionAddDetailGetResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdHub', 'GetSdAddOptionDetail', 'HostingAddOptionRequestHubDto', 'SdOptionAddDetailGetResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdHub', 'GetSdAddOptionDetail', 'HostingAddOptionRequestHubDto', 'SdOptionAddDetailGetResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdHub', 'GetSdAddOptionDetail', 'HostingAddOptionRequestHubDto', 'SdOptionAddDetailGetResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public AddSdAddOptions = (requestData: HostingAddOptionRequestHubDto, receiveCallBack: (data: SdOptionAddResponseHubDto) => void, receiveErrorCallBack: (data: SdOptionAddResponseHubDto) => void, noSessionCallBack: (data: SdOptionAddResponseHubDto) => void, completeCallBack: (data: SdOptionAddResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdOptionAddResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdOptionAddResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdHub/AddSdAddOptions';

        let responseData: SdOptionAddResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdHub', 'AddSdAddOptions', 'HostingAddOptionRequestHubDto', 'SdOptionAddResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdHub', 'AddSdAddOptions', 'HostingAddOptionRequestHubDto', 'SdOptionAddResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdHub', 'AddSdAddOptions', 'HostingAddOptionRequestHubDto', 'SdOptionAddResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetSdChangeOptions = (requestData: SdOptionChangePlansGetRequestHubDto, receiveCallBack: (data: SdOptionChangePlansGetResponseHubDto) => void, receiveErrorCallBack: (data: SdOptionChangePlansGetResponseHubDto) => void, noSessionCallBack: (data: SdOptionChangePlansGetResponseHubDto) => void, completeCallBack: (data: SdOptionChangePlansGetResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdOptionChangePlansGetResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdOptionChangePlansGetResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdHub/GetSdChangeOptions';

        let responseData: SdOptionChangePlansGetResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdHub', 'GetSdChangeOptions', 'SdOptionChangePlansGetRequestHubDto', 'SdOptionChangePlansGetResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdHub', 'GetSdChangeOptions', 'SdOptionChangePlansGetRequestHubDto', 'SdOptionChangePlansGetResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdHub', 'GetSdChangeOptions', 'SdOptionChangePlansGetRequestHubDto', 'SdOptionChangePlansGetResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetSdChangeOptionDetail = (requestData: SdOptionChangePlansDetailGetRequestHubDto, receiveCallBack: (data: SdOptionChangePlansDetailGetResponseHubDto) => void, receiveErrorCallBack: (data: SdOptionChangePlansDetailGetResponseHubDto) => void, noSessionCallBack: (data: SdOptionChangePlansDetailGetResponseHubDto) => void, completeCallBack: (data: SdOptionChangePlansDetailGetResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdOptionChangePlansDetailGetResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdOptionChangePlansDetailGetResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdHub/GetSdChangeOptionDetail';

        let responseData: SdOptionChangePlansDetailGetResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdHub', 'GetSdChangeOptionDetail', 'SdOptionChangePlansDetailGetRequestHubDto', 'SdOptionChangePlansDetailGetResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdHub', 'GetSdChangeOptionDetail', 'SdOptionChangePlansDetailGetRequestHubDto', 'SdOptionChangePlansDetailGetResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdHub', 'GetSdChangeOptionDetail', 'SdOptionChangePlansDetailGetRequestHubDto', 'SdOptionChangePlansDetailGetResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ChangeSdChangeOption = (requestData: SdOptionChangePlanRequestHubDto, receiveCallBack: (data: SdOptionChangePlanResponseHubDto) => void, receiveErrorCallBack: (data: SdOptionChangePlanResponseHubDto) => void, noSessionCallBack: (data: SdOptionChangePlanResponseHubDto) => void, completeCallBack: (data: SdOptionChangePlanResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdOptionChangePlanResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdOptionChangePlanResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdHub/ChangeSdChangeOption';

        let responseData: SdOptionChangePlanResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdHub', 'ChangeSdChangeOption', 'SdOptionChangePlanRequestHubDto', 'SdOptionChangePlanResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdHub', 'ChangeSdChangeOption', 'SdOptionChangePlanRequestHubDto', 'SdOptionChangePlanResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdHub', 'ChangeSdChangeOption', 'SdOptionChangePlanRequestHubDto', 'SdOptionChangePlanResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetSdCancelOptionDetail = (requestData: SdOptionCancelDetailRequestHubDto, receiveCallBack: (data: SdOptionCancelDetailResponseHubDto) => void, receiveErrorCallBack: (data: SdOptionCancelDetailResponseHubDto) => void, noSessionCallBack: (data: SdOptionCancelDetailResponseHubDto) => void, completeCallBack: (data: SdOptionCancelDetailResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdOptionCancelDetailResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdOptionCancelDetailResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdHub/GetSdCancelOptionDetail';

        let responseData: SdOptionCancelDetailResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdHub', 'GetSdCancelOptionDetail', 'SdOptionCancelDetailRequestHubDto', 'SdOptionCancelDetailResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdHub', 'GetSdCancelOptionDetail', 'SdOptionCancelDetailRequestHubDto', 'SdOptionCancelDetailResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdHub', 'GetSdCancelOptionDetail', 'SdOptionCancelDetailRequestHubDto', 'SdOptionCancelDetailResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public CancelSdCancelOption = (requestData: SdOptionCancelRequestHubDto, receiveCallBack: (data: SdOptionCancelResponseHubDto) => void, receiveErrorCallBack: (data: SdOptionCancelResponseHubDto) => void, noSessionCallBack: (data: SdOptionCancelResponseHubDto) => void, completeCallBack: (data: SdOptionCancelResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdOptionCancelResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdOptionCancelResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdHub/CancelSdCancelOption';

        let responseData: SdOptionCancelResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdHub', 'CancelSdCancelOption', 'SdOptionCancelRequestHubDto', 'SdOptionCancelResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdHub', 'CancelSdCancelOption', 'SdOptionCancelRequestHubDto', 'SdOptionCancelResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdHub', 'CancelSdCancelOption', 'SdOptionCancelRequestHubDto', 'SdOptionCancelResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ValidateSdAddDomainAccount = (requestData: SdAddDomainAccountValidateRequestHubDto, receiveCallBack: (data: SdAddDomainAccountValidateResponseHubDto) => void, receiveErrorCallBack: (data: SdAddDomainAccountValidateResponseHubDto) => void, noSessionCallBack: (data: SdAddDomainAccountValidateResponseHubDto) => void, completeCallBack: (data: SdAddDomainAccountValidateResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdAddDomainAccountValidateResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdAddDomainAccountValidateResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdHub/ValidateSdAddDomainAccount';

        let responseData: SdAddDomainAccountValidateResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdHub', 'ValidateSdAddDomainAccount', 'SdAddDomainAccountValidateRequestHubDto', 'SdAddDomainAccountValidateResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdHub', 'ValidateSdAddDomainAccount', 'SdAddDomainAccountValidateRequestHubDto', 'SdAddDomainAccountValidateResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdHub', 'ValidateSdAddDomainAccount', 'SdAddDomainAccountValidateRequestHubDto', 'SdAddDomainAccountValidateResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetSdAddDomain = (requestData: SdAddDomainGetRequestHubDto, receiveCallBack: (data: SdAddDomainGetResponseHubDto) => void, receiveErrorCallBack: (data: SdAddDomainGetResponseHubDto) => void, noSessionCallBack: (data: SdAddDomainGetResponseHubDto) => void, completeCallBack: (data: SdAddDomainGetResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdAddDomainGetResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdAddDomainGetResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdHub/GetSdAddDomain';

        let responseData: SdAddDomainGetResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdHub', 'GetSdAddDomain', 'SdAddDomainGetRequestHubDto', 'SdAddDomainGetResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdHub', 'GetSdAddDomain', 'SdAddDomainGetRequestHubDto', 'SdAddDomainGetResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdHub', 'GetSdAddDomain', 'SdAddDomainGetRequestHubDto', 'SdAddDomainGetResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public AddSdAddOnamaeDomain = (requestData: SdAddDomainOnamaeAddRequestHubDto, receiveCallBack: (data: SdAddDomainOnamaeAddResponseHubDto) => void, receiveErrorCallBack: (data: SdAddDomainOnamaeAddResponseHubDto) => void, noSessionCallBack: (data: SdAddDomainOnamaeAddResponseHubDto) => void, completeCallBack: (data: SdAddDomainOnamaeAddResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdAddDomainOnamaeAddResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdAddDomainOnamaeAddResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdHub/AddSdAddOnamaeDomain';

        let responseData: SdAddDomainOnamaeAddResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdHub', 'AddSdAddOnamaeDomain', 'SdAddDomainOnamaeAddRequestHubDto', 'SdAddDomainOnamaeAddResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdHub', 'AddSdAddOnamaeDomain', 'SdAddDomainOnamaeAddRequestHubDto', 'SdAddDomainOnamaeAddResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdHub', 'AddSdAddOnamaeDomain', 'SdAddDomainOnamaeAddRequestHubDto', 'SdAddDomainOnamaeAddResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetSdAddOnamaeDomain = (requestData: SdAddDomainOnamaeGetRequestHubDto, receiveCallBack: (data: SdAddDomainOnamaeGetResponseHubDto) => void, receiveErrorCallBack: (data: SdAddDomainOnamaeGetResponseHubDto) => void, noSessionCallBack: (data: SdAddDomainOnamaeGetResponseHubDto) => void, completeCallBack: (data: SdAddDomainOnamaeGetResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdAddDomainOnamaeGetResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdAddDomainOnamaeGetResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdHub/GetSdAddOnamaeDomain';

        let responseData: SdAddDomainOnamaeGetResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdHub', 'GetSdAddOnamaeDomain', 'SdAddDomainOnamaeGetRequestHubDto', 'SdAddDomainOnamaeGetResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdHub', 'GetSdAddOnamaeDomain', 'SdAddDomainOnamaeGetRequestHubDto', 'SdAddDomainOnamaeGetResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdHub', 'GetSdAddOnamaeDomain', 'SdAddDomainOnamaeGetRequestHubDto', 'SdAddDomainOnamaeGetResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ValidationSdAddOnamaeDomain = (requestData: SdAddDomainOnamaeValidationRequestHubDto, receiveCallBack: (data: SdAddDomainOnamaeValidationResponseHubDto) => void, receiveErrorCallBack: (data: SdAddDomainOnamaeValidationResponseHubDto) => void, noSessionCallBack: (data: SdAddDomainOnamaeValidationResponseHubDto) => void, completeCallBack: (data: SdAddDomainOnamaeValidationResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdAddDomainOnamaeValidationResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdAddDomainOnamaeValidationResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdHub/ValidationSdAddOnamaeDomain';

        let responseData: SdAddDomainOnamaeValidationResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdHub', 'ValidationSdAddOnamaeDomain', 'SdAddDomainOnamaeValidationRequestHubDto', 'SdAddDomainOnamaeValidationResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdHub', 'ValidationSdAddOnamaeDomain', 'SdAddDomainOnamaeValidationRequestHubDto', 'SdAddDomainOnamaeValidationResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdHub', 'ValidationSdAddOnamaeDomain', 'SdAddDomainOnamaeValidationRequestHubDto', 'SdAddDomainOnamaeValidationResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public AddSdAddOtherCompanyDomain = (requestData: SdAddDomainOtherCompanyAddRequestHubDto, receiveCallBack: (data: SdAddDomainOtherCompanyAddResponseHubDto) => void, receiveErrorCallBack: (data: SdAddDomainOtherCompanyAddResponseHubDto) => void, noSessionCallBack: (data: SdAddDomainOtherCompanyAddResponseHubDto) => void, completeCallBack: (data: SdAddDomainOtherCompanyAddResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdAddDomainOtherCompanyAddResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdAddDomainOtherCompanyAddResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdHub/AddSdAddOtherCompanyDomain';

        let responseData: SdAddDomainOtherCompanyAddResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdHub', 'AddSdAddOtherCompanyDomain', 'SdAddDomainOtherCompanyAddRequestHubDto', 'SdAddDomainOtherCompanyAddResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdHub', 'AddSdAddOtherCompanyDomain', 'SdAddDomainOtherCompanyAddRequestHubDto', 'SdAddDomainOtherCompanyAddResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdHub', 'AddSdAddOtherCompanyDomain', 'SdAddDomainOtherCompanyAddRequestHubDto', 'SdAddDomainOtherCompanyAddResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public ValidationSdAddOtherCompanyDomain = (requestData: SdAddDomainOtherCompanyValidationRequestHubDto, receiveCallBack: (data: SdAddDomainOtherCompanyValidationResponseHubDto) => void, receiveErrorCallBack: (data: SdAddDomainOtherCompanyValidationResponseHubDto) => void, noSessionCallBack: (data: SdAddDomainOtherCompanyValidationResponseHubDto) => void, completeCallBack: (data: SdAddDomainOtherCompanyValidationResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdAddDomainOtherCompanyValidationResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdAddDomainOtherCompanyValidationResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdHub/ValidationSdAddOtherCompanyDomain';

        let responseData: SdAddDomainOtherCompanyValidationResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdHub', 'ValidationSdAddOtherCompanyDomain', 'SdAddDomainOtherCompanyValidationRequestHubDto', 'SdAddDomainOtherCompanyValidationResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdHub', 'ValidationSdAddOtherCompanyDomain', 'SdAddDomainOtherCompanyValidationRequestHubDto', 'SdAddDomainOtherCompanyValidationResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdHub', 'ValidationSdAddOtherCompanyDomain', 'SdAddDomainOtherCompanyValidationRequestHubDto', 'SdAddDomainOtherCompanyValidationResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public SdDomainSearchTldList = (receiveCallBack: (data: DomainSearchTldListResponseHubDto) => void, receiveErrorCallBack: (data: DomainSearchTldListResponseHubDto) => void, noSessionCallBack: (data: DomainSearchTldListResponseHubDto) => void, completeCallBack: (data: DomainSearchTldListResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: DomainSearchTldListResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: DomainSearchTldListResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdHub/SdDomainSearchTldList';

        let responseData: DomainSearchTldListResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdHub', 'SdDomainSearchTldList', null, 'DomainSearchTldListResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdHub', 'SdDomainSearchTldList', null, 'DomainSearchTldListResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdHub', 'SdDomainSearchTldList', null, 'DomainSearchTldListResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetSdMessages = (receiveCallBack: (data: SdMessageResponseHubDto) => void, receiveErrorCallBack: (data: SdMessageResponseHubDto) => void, noSessionCallBack: (data: SdMessageResponseHubDto) => void, completeCallBack: (data: SdMessageResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdMessageResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdMessageResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdHub/GetSdMessages';

        let responseData: SdMessageResponseHubDto = null;
		this.http.post<any>(url, null, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken} })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdHub', 'GetSdMessages', null, 'SdMessageResponseHubDto', null, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdHub', 'GetSdMessages', null, 'SdMessageResponseHubDto', null, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdHub', 'GetSdMessages', null, 'SdMessageResponseHubDto', null, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public GetServerRedirection = (requestData: ServerRedirectionRequestHubDto, receiveCallBack: (data: ServerRedirectionResponseHubDto) => void, receiveErrorCallBack: (data: ServerRedirectionResponseHubDto) => void, noSessionCallBack: (data: ServerRedirectionResponseHubDto) => void, completeCallBack: (data: ServerRedirectionResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: ServerRedirectionResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: ServerRedirectionResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdHub/GetServerRedirection';

        let responseData: ServerRedirectionResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdHub', 'GetServerRedirection', 'ServerRedirectionRequestHubDto', 'ServerRedirectionResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdHub', 'GetServerRedirection', 'ServerRedirectionRequestHubDto', 'ServerRedirectionResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdHub', 'GetServerRedirection', 'ServerRedirectionRequestHubDto', 'ServerRedirectionResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public SdSwitchIdValidation = (requestData: SdSwitchIdValidationRequestHubDto, receiveCallBack: (data: SdSwitchIdValidationResponseHubDto) => void, receiveErrorCallBack: (data: SdSwitchIdValidationResponseHubDto) => void, noSessionCallBack: (data: SdSwitchIdValidationResponseHubDto) => void, completeCallBack: (data: SdSwitchIdValidationResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdSwitchIdValidationResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdSwitchIdValidationResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdHub/SdSwitchIdValidation';

        let responseData: SdSwitchIdValidationResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdHub', 'SdSwitchIdValidation', 'SdSwitchIdValidationRequestHubDto', 'SdSwitchIdValidationResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdHub', 'SdSwitchIdValidation', 'SdSwitchIdValidationRequestHubDto', 'SdSwitchIdValidationResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdHub', 'SdSwitchIdValidation', 'SdSwitchIdValidationRequestHubDto', 'SdSwitchIdValidationResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }


    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public SdSwitchId = (requestData: SdSwitchIdRequestHubDto, receiveCallBack: (data: SdSwitchIdResponseHubDto) => void, receiveErrorCallBack: (data: SdSwitchIdResponseHubDto) => void, noSessionCallBack: (data: SdSwitchIdResponseHubDto) => void, completeCallBack: (data: SdSwitchIdResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: SdSwitchIdResponseHubDto) => void, reciveSubMaintenanceModeCallBack:(data: SdSwitchIdResponseHubDto) => void): void => {
	    const url = location.protocol + "//" + location.host + '/api/SdHub/SdSwitchId';

        let responseData: SdSwitchIdResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        fireEventHubsManagerCallbackError('SdHub', 'SdSwitchId', 'SdSwitchIdRequestHubDto', 'SdSwitchIdResponseHubDto', requestData, responseData);
                        receiveErrorCallBack(responseData);
                    } else if (responseData.SubMaintenanceMode){
                        reciveSubMaintenanceModeCallBack(responseData);
                    }else {
                        fireEventHubsManagerCallbackOK('SdHub', 'SdSwitchId', 'SdSwitchIdRequestHubDto', 'SdSwitchIdResponseHubDto', requestData, responseData);
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        fireEventHubsManagerCallbackComplete('SdHub', 'SdSwitchId', 'SdSwitchIdRequestHubDto', 'SdSwitchIdResponseHubDto', requestData, responseData);
                        completeCallBack(responseData);
                    }
                }
            );
    }

}




