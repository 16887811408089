<div class="modal" id="modalEnquete" modal [openFlg]="isShowModalSurvey" (click)="onClickOutsideCloseModal($event)">
  <form #modalSurveyForm="ngForm" novalidate>
    <div class="modal-Dialog is-Wide">
        <div class="modal-Dialog-Header is-Note">
            <h3 class="modal-Dialog-Header-Title pl0 text-Center">移管先・移管検討理由について教えてください</h3>
        </div><!-- /.modal-Dialog-Header -->
        <div class="modal-Dialog-Body">
            <ng-container *ngIf="isShowModalSurvey && !isLoading && pages.length > 0">
                <p class="size-15 text-Bold mt24 mb0">01. 移管先を教えてください。<span class="form-Required">必須</span></p>
                <section class="section mt12">
                    <div class="box-Border is-Thick">
                        <ul class="list-Checkable is-Col4 is-Inline">
                            <li *ngFor="let option of pages[0].options">
                                <label> <input type="radio" name="radio1" id="radio1" value="{{option.id}}" [(ngModel)]="option.isCheck" (change)="onChangeRadio(pages[0],option.id)">
                                <span class="form-Radio" tabindex="0"></span>{{option.content}}</label>
                                <ng-container *ngIf="option.isOther && isValidateOther1">
                                    <input placeholder="移管先" type="text" class="form-Text is-Small mt8 ml30 is-Show" [class.form-Error]="isValidateOther1 && (v_otherText1.errors && (v_otherText1.dirty || v_otherText1.touched))"
                                            name="otherText1" #v_otherText1="ngModel" [(ngModel)]="pages[0].otherText"
                                            [required]="isValidateOther1" max="100">
                                    <div *ngIf="isValidateOther1 && (v_otherText1.errors && (v_otherText1.dirty || v_otherText1.touched))">
                                      <label *ngIf="v_otherText1.errors != null && v_otherText1.errors.required" class="form-Error ml30">
                                        必須項目です
                                      </label>
                                      <label *ngIf=" isValidateOther1 && v_otherText1.errors != null && v_otherText1.errors.max" class="form-Error ml30">
                                        最大文字数：100
                                      </label>
                                    </div>
                                </ng-container>
                            </li>
                        </ul>
                      </div><!-- /.box-Border -->
                </section>
            </ng-container>
            <ng-container *ngIf="isShowModalSurvey && !isLoading && pages.length > 1">
                <p class="size-15 text-Bold mt24 mb0">02. 移管を検討されたきっかけは何ですか？（複数選択可）<span class="form-Required">必須</span></p>
                <section class="section mt12">
                    <div class="box-Border is-Thick">
                        <ul class="list-Checkable is-Col2 is-Inline">
                            <li *ngFor="let option of pages[1].options">
                                <label for="checkbox1-{{option.id}}" class="js-Question-Checkbox">
                                        <input type="checkbox" name="checkbox{{option.id}}" id="checkbox1-{{option.id}}" value="{{option.id}}" [(ngModel)]="option.isCheck" (change)="onChangeCheckBox(1)">
                                        <span class="form-Checkbox" tabindex="0"></span>
                                        <span>{{option.content}}</span>
                                </label>
                                <ng-container *ngIf="option.isOther && isValidateOther2">
                                    <span>
                                        <input placeholder="移管を検討されたきっかけ" type="text" class="form-Text is-w334 mt8 ml30 is-Show" [class.form-Error]="isValidateOther2 && (v_otherText2.errors && (v_otherText2.dirty || v_otherText2.touched))"
                                               name="otherText2" #v_otherText2="ngModel" [(ngModel)]="pages[1].otherText"
                                               [required]="isValidateOther2 === true" max="1000">
                                    </span>
                                    <div *ngIf="isValidateOther2 && (v_otherText2.errors && (v_otherText2.dirty || v_otherText2.touched))">
                                        <label *ngIf="v_otherText2.errors != null && v_otherText2.errors.required" class="form-Error ml30">
                                            必須項目です
                                        </label>
                                        <label *ngIf="v_otherText2.errors != null && v_otherText2.errors.max" class="form-Error ml30">
                                          最大文字数：1000
                                        </label>
                                    </div>
                                </ng-container>
                            </li>
                            </ul>
                    </div><!-- /.box-Border -->
                </section><!-- /.section -->
            </ng-container>
            <ng-container *ngIf="isShowModalSurvey && !isLoading && pages.length > 2 && pages[1].options[0].isCheck">
                <p class="size-15 text-Bold mt24 mb0">03. 特にわかりにくいと感じた点は何ですか？（複数選択可）<span class="form-Required">必須</span></p>
                <section class="section mt12">
                    <div class="box-Border is-Thick">
                        <ul class="list-Checkable is-Col2 is-Inline">
                            <li *ngFor="let option2 of pages[2].options">
                                <label for="checkbox2-{{option2.id}}" class="js-Question-Checkbox">
                                    <input type="checkbox" name="checkbox{{option2.id}}" id="checkbox2-{{option2.id}}" value="{{option2.id}}" [(ngModel)]="option2.isCheck" (change)="onChangeCheckBox(2)">
                                    <span class="form-Checkbox" tabindex="0"></span>
                                    <span>{{option2.content}}</span>
                                </label>
                                <ng-container *ngIf="option2.isOther && isValidateOther3">
                                    <span>
                                        <input placeholder="特にわかりにくいと感じた点" type="text" class="form-Text is-w334 mt8 ml30 is-Show" [class.form-Error]="isValidateOther3 &&(v_otherText.errors && (v_otherText.dirty || v_otherText.touched))"
                                                name="otherText" #v_otherText="ngModel" [(ngModel)]="pages[2].otherText"
                                                [required]="isValidateOther3 === true" max="1000">
                                    </span>
                                    <div *ngIf="isValidateOther3 && (v_otherText.errors && (v_otherText.dirty || v_otherText.touched))">
                                        <label *ngIf="v_otherText.errors != null && v_otherText.errors.required" class="form-Error ml30">
                                            必須項目です
                                        </label>
                                        <label *ngIf="v_otherText.errors != null && v_otherText.errors.max" class="form-Error ml30">
                                            最大文字数：1000
                                        </label>
                                    </div>
                                </ng-container>
                                </li>
                            </ul>
                    </div><!-- /.box-Border -->
                </section><!-- /.section -->
            </ng-container>
            <ng-container *ngIf="isShowModalSurvey && !isLoading && pages.length > 4">
                <p class="size-15 text-Bold mt24 mb0">{{pages[1].options[0].isCheck ? "04.": "03."}} サービスに対するご意見をお聞かせください。<span class="form-Required">必須</span></p>
                <section class="section mt12">
                    <div class="box-Border is-Thick">
                        <span>
                            <textarea placeholder="ご意見がない場合は「特になし」とご記入ください。" class="form-Textarea" [class.form-Error]="(v_textArea.errors && (v_textArea.dirty || v_textArea.touched))"
                                    name="textArea" #v_textArea="ngModel" [(ngModel)]="pages[3].textarea"
                                    required max="1000"></textarea>
                        </span>
                        <div *ngIf="v_textArea.errors && (v_textArea.dirty || v_textArea.touched)">
                            <label *ngIf="v_textArea.errors != null && v_textArea.errors.required" class="form-Error">
                                必須項目です
                            </label>
                            <label *ngIf="v_textArea.errors != null && v_textArea.errors.max" class="form-Error">
                                最大文字数：1000
                            </label>
                        </div>
                    </div><!-- /.box-Border -->
                </section><!-- /.section -->
            </ng-container>
        </div><!-- /.modal-Dialog-Body -->
        <div class="modal-Dialog-Footer">
            <ul class="list-Btn is-Right mb0">
                <li><button type="button" class="btn gtmclick" data-dismiss="modal" (click)="onClickClose()" data-gtmvalue="authmodal_authmodalcancel">キャンセル</button></li>
                <li><button type="button" class="btn is-Primary gtmclick" data-gtmvalue="authmodal_authmodaldone" data-dismiss="modal" data-change="changed" [class.is-Disabled]="!isShowButtonNext" (click)="onClickFinishSurvey(modalSurveyForm)">次へ</button></li>
            </ul><!-- /.list-Btn --> 
        </div><!-- /.modal-Dialog-Footer -->
        <button type="button" class="modal-Close" data-dismiss="modal" (click)="onClickClose()"><i class="icon icon-Clear gtmclick" aria-label="閉じる" data-gtmvalue="authmodal_authmodalclose"></i></button>
    </div><!-- /.modal-Dialog -->
    </form>
   <div *ngIf="isLoading" class="loading" style="display:block">
        <div class="loading-Loader">
            <div class="loading-Loader-Blade"></div>
            <div class="loading-Loader-Blade"></div>
            <div class="loading-Loader-Blade"></div>
            <div class="loading-Loader-Blade"></div>
            <div class="loading-Loader-Blade"></div>
            <div class="loading-Loader-Blade"></div>
            <div class="loading-Loader-Blade"></div>
            <div class="loading-Loader-Blade"></div>
            <div class="loading-Loader-Blade"></div>
            <div class="loading-Loader-Blade"></div>
            <div class="loading-Loader-Blade"></div>
            <div class="loading-Loader-Blade"></div>
        </div>
    </div>
</div>
