

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    template: 'リダイレクト処理中...'
})
export class RedirectComponent implements OnInit {
    public constructor(protected router: Router) {
        // noop.
    }

    public ngOnInit() {
        let url = this.router.url;
        url = url.replace(/^\/sp/, '');
        this.router.navigateByUrl(url);
    }
}

