<ng-container *ngFor="let notify of notifyMessageViewModel.notifyInfoMessageList">
    <div *ngIf="notify.notifySubMessages.length === 0" class="box-Safe">
        <p class="box-Safe-Text">
            <i class="icon icon-Checked"></i><span [innerHtml]="notify.notifyMessage"></span>
        </p>
        <button type="button" class="box-Safe-Close" (click)="ButtonInfoClose(notify.notifyId)"><i class="icon icon-Clear" aria-label="閉じる"></i></button>
    </div>
    <div *ngIf="notify.notifySubMessages.length > 0" class="box-Safe" [class.is-Open]="notify.isShowNotifySubMessages">
        <a href="javascript:void(0)" class="box-Safe-More" (click)="onClickShowInfo(notify.notifyId)">
            <i class="icon icon-ArrowDown"></i>
            完了しました。
        </a>
        <button type="button" class="box-Safe-Close" (click)="ButtonInfoClose(notify.notifyId)"><i class="icon icon-Clear" aria-label="閉じる"></i></button>
        <ul *ngIf="notify.isShowNotifySubMessages" class="list-Normal">
            <li *ngFor="let notifySubMessage of notify.notifySubMessages" [innerHtml]="notifySubMessage"></li>
        </ul>
    </div>
</ng-container>

<ng-container *ngFor="let notify of notifyMessageViewModel.notifyErrorMessageList">
    <div *ngIf="notify.notifySubMessages.length === 0" class="box-Error">
        <p class="box-Error-Text" [innerHtml]="notify.notifyMessage"></p>
        <button type="button" class="box-Error-Close gtmclick" data-gtmvalue="alertbanner" (click)="ButtonErrorClose(notify.notifyId)"><i class="icon icon-Clear" aria-label="閉じる"></i></button>
    </div>
    <div *ngIf="notify.notifySubMessages.length > 0" class="box-Error" [class.is-Open]="notify.isShowNotifySubMessages">
        <a href="javascript:void(0)" class="box-Error-More" (click)="onClickShowError(notify.notifyId)">
            <i class="icon icon-ArrowDown"></i>
            {{notify.notifyMessage ? notify.notifyMessage : notify.notifySubMessages.length + "件のお申込みに失敗しました。"}}
        </a>
        <button type="button" class="box-Error-Close gtmclick" data-gtmvalue="alertbanner" (click)="ButtonErrorClose(notify.notifyId)"><i class="icon icon-Clear" aria-label="閉じる"></i></button>
        <ul *ngIf="notify.isShowNotifySubMessages" class="list-Normal">
            <li *ngFor="let notifySubMessage of notify.notifySubMessages" [innerHtml]="notifySubMessage"></li>
        </ul>
    </div>
</ng-container>
