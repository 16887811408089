<div class="modal" modal [openFlg]="isDisplay" data-dismiss="modal" (click)="onClickOutsideCloseModal($event)">
    <!-- RSリコメンド -->
    <div *ngIf="hasTarget && !hasRSOM" class="modal-Dialog is-Wide is-NoBorder is-Appeal">
        <div class="modal-Dialog-Header">
            <h3 class="modal-Dialog-Header-Title">ドメインを利用するためにレンタルサーバーをお申し込みください</h3>
            <p class="modal-Dialog-Header-Text mb0">ホームページ・ブログ・メールアドレスの作成にはレンタルサーバーが必要です。</p>
        </div>
        <div class="modal-Dialog-Body has-serverImage">
            <div class="modal-Dialog-Appeal-Container">
                <p class="modal-Dialog-Appeal-Heading">＼ お名前.comで申し込むメリット ／</p>
                <ul class="modal-Dialog-Appeal-List">
                    <li class="modal-Dialog-Appeal-List-Item">
                        <div class="modal-Dialog-Appeal-List-Item-Box">
                            <span>初期費用無料 &amp; 月額最大2ヶ月無料</span><br>
                            <span class="is-Emphasize">初回0円で利用開始</span>
                        </div>
                    </li>
                    <li class="modal-Dialog-Appeal-List-Item">
                        <div class="modal-Dialog-Appeal-List-Item-Box">
                            <span>お申込みから利用開始まで</span><br>
                            <span class="is-Emphasize">最短5分</span>
                        </div>
                    </li>
                    <li class="modal-Dialog-Appeal-List-Item">
                        <div class="modal-Dialog-Appeal-List-Item-Box">
                            <span>ドメインとサーバーが</span><br>
                            <span class="is-Emphasize">まとめて管理できる</span>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="modal-Dialog-Bottom">
            <div class="modal-Dialog-Appeal-Box pt0 pb8">
                <div class="modal-Dialog-Appeal-Box-Overlay">
                    対象ドメインならサーバーの利用中ドメインが<span>永久に無料！</span>
                </div>
                <div class="modal-Dialog-Appeal-Box-Left mt28">
                    <p class="modal-Dialog-Appeal-Box-Heading">レンタルサーバー<span class="size-13">(登録済みドメインを利用) </span>のお申込みはこちら</p>
                    <p class="modal-Dialog-Appeal-Box-Text">登録済みのドメインをレンタルサーバーに紐づけてお申込みできます。</p>
                </div>
                <button type="button" class="btn is-Green gtmclick" [attr.data-gtmvalue]="domainApplyButtonId" (click)="onDomainApplyButtonClicked()">お申込み</button>
            </div>
            <div class="modal-Dialog-Appeal-Box is-Yellow mt12">
                <div class="modal-Dialog-Appeal-Box-Left">
                    <p class="modal-Dialog-Appeal-Box-Heading">レンタルサーバーのお申込みはこちら</p>
                    <p class="modal-Dialog-Appeal-Box-Text">レンタルサーバーの通常のお申込み方法となります（ドメイン紐づけなし、後から紐づけ可）。</p>
                </div>
                <button type="button" class="btn is-Green gtmclick" [attr.data-gtmvalue]="rsApplyButtonId" data-realtimefired="true" (click)="onRsApplyButtonClicked()">お申込み</button>
            </div>
        </div>
        <button type="button" class="modal-Close gtmclick" [attr.data-gtmvalue]="closeButtonId" (click)="onCloseButtonClicked()" data-dismiss="modal"><i class="icon icon-Clear gtmclick" [attr.data-gtmvalue]="closeButtonId" aria-label="閉じる"></i></button>
    </div>
    <!-- お名前メール(RS)のみ保持しているアカウントへのRSリコメンド -->
    <div *ngIf="hasRSOM" class="modal-Dialog is-Wide is-NoBorder is-Appeal">
        <div class="modal-Dialog-Header">
            <p class="modal-Dialog-Header-Text size-18 mt0 mb4">お名前メールの「エコノミープラン」「ベーシックプラン」をご利用中で</p>
            <h3 class="modal-Dialog-Header-Title mb0">ホームページ・ブログの運用を検討している方</h3>
        </div>
        <div class="modal-Dialog-Body has-serverImage">
            <div class="modal-Dialog-Appeal-Container">
                <p class="modal-Dialog-Appeal-Heading">＼ レンタルサーバーへのプラン変更がおトク! ／</p>
                <ul class="modal-Dialog-Appeal-List">
                    <li class="modal-Dialog-Appeal-List-Item">
                        <div class="modal-Dialog-Appeal-List-Item-Box">
                            <span>お名前メールで使用していた</span><br>
                            <span class="is-Emphasize">メール設定はそのまま利用可能</span>
                        </div>
                    </li>
                    <li class="modal-Dialog-Appeal-List-Item">
                        <div class="modal-Dialog-Appeal-List-Item-Box">
                            <span>容量(SSD)は</span><br>
                            <span class="is-Emphasize">400GBに</span>
                        </div>
                    </li>
                    <li class="modal-Dialog-Appeal-List-Item">
                        <div class="modal-Dialog-Appeal-List-Item-Box">
                            <span>プラン変更月は</span><br>
                            <span class="is-Emphasize">月額料金が0円</span>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="modal-Dialog-Bottom text-Center pt18">
            <button type="button" class="btn is-Green is-Large gtmclick" [attr.data-gtmvalue]="rsApplyButtonId_rsom" (click)="onClickChangePlan_rsom()">プラン変更はこちら</button>
        </div>
        <button type="button" class="modal-Close gtmclick" [attr.data-gtmvalue]="closeButtonId_rsom" (click)="onCloseButtonClicked()" data-dismiss="modal"><i class="icon icon-Clear gtmclick" [attr.data-gtmvalue]="closeButtonId_rsom" aria-label="閉じる"></i></button>
    </div>
</div>
