import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

/**
 * Atoms
 * */
import { IconPencilComponent } from 'Content/script/components/share/Atoms/icon/icon_pencil/icon_pencil.component';
import { NormalAnchorComponent } from 'Content/script/components/share/Atoms/anchor/normal_anchor/normal_anchor.component';
import { IconOriginComponent } from 'Content/script/components/share/Atoms/button/icon_origin/icon_origin.component';
import { HdnLev3Component } from 'Content/script/components/share/Atoms/h2/hdn_lev3/hdn_lev3.component';
import { TblColGroupComponent } from 'Content/script/components/share/Atoms/col_group/tbl_col_group/tbl_col_group.component';
import { BoxBorderComponent } from 'Content/script/components/share/Atoms/box/box_border/box_border.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
    ],
    declarations: [
        IconPencilComponent,
        NormalAnchorComponent,
        IconOriginComponent,
        HdnLev3Component,
        TblColGroupComponent,
        BoxBorderComponent
    ],
    exports: [
        IconPencilComponent,
        NormalAnchorComponent,
        IconOriginComponent,
        HdnLev3Component,
        TblColGroupComponent,
        BoxBorderComponent
    ]
})

export class AtomsModule { }