import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// ルーティングモジュール
import { RootRoutingModule } from 'Content/script/routing/root-routing.module';
import { RouterEventService } from 'Content/common/script/service/router_event_service';

// PC, SP
import { AppPcModule } from 'Content/script/components/app/app.module';
import { AppSpModule } from 'Content/sp/script/components/app/app.module';

// コンポーネント
import { AppComponent } from './app.component';

import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from '../store/reducers';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { OnamaeErrorHandler } from '../handler/onamae_error_handler';
import { provideCharts, withDefaultRegisterables } from 'ng2-charts';

@NgModule({
    imports: [
        BrowserModule,
        HttpClientModule,
        RootRoutingModule,
        CommonModule,
        BrowserAnimationsModule,
        StoreModule.forRoot(reducers, { metaReducers }),
        !environment.production ? StoreDevtoolsModule.instrument({connectInZone: true}) : [],
        AppSpModule,
        AppPcModule
    ],
    declarations: [
        AppComponent
    ],
    exports: [],
    providers: [
        RouterEventService,
        { provide: ErrorHandler, useClass: OnamaeErrorHandler },
        provideCharts(withDefaultRegisterables())
    ],
    bootstrap: [AppComponent]
})

export class AppModule {
}
