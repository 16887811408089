import { CardHolderNameUtil } from 'Content/script/libs/utils/cardHolderName.util';
export class MultiPaymentManager
{
    public HOLDERNAME_ERROR: string = '-1';

    private ERROR_TOKENOBJECT: getTokenObject = {
        isSecurityCodeSet: null,
        maskedCardNo: null,
        toBeExpiredAt: null,
        token: ''
    }; 
   
   
    public constructor(shopId: string)
    {
        Multipayment.init(shopId);
    }

    public Send(AppRequest: RequestFormData, AppGetTokenCallBack: (res: getTokenResponse) => void): void {

        // カード名義人からtoken化に必要ない文字を除外
        const adjustedHolderName = CardHolderNameUtil.adjustForTokenJs(AppRequest.holdername);

        // 余計な文字を除外した結果カード名義人が0文字になった場合はエラー
        if (adjustedHolderName.length === 0) {
            AppGetTokenCallBack({
                resultCode: this.HOLDERNAME_ERROR,
                tokenObject: this.ERROR_TOKENOBJECT
            });
            return;
        }

        // コールバック関数の定義
        window.GetTokenCallBack
            = (r: getTokenResponse) => {

                // resultCodeが'000'以外のときは、token.jsのgetToken()でエラーが起こっている
                if (r.resultCode !== '000') {
                    r.tokenObject = this.ERROR_TOKENOBJECT;
                }

                AppGetTokenCallBack(r);
            };

        //getToken()をコール
        const request: RequestFormData = {
            cardno: AppRequest.cardno,
            expire: AppRequest.expire,
            holdername: adjustedHolderName,
            tokennumber: AppRequest.tokennumber,
            securitycode: AppRequest.securitycode,
        };
        Multipayment.getToken(request, "window.GetTokenCallBack");
    }
}
