import { NgZone } from '@angular/core';

import { MultipleSendManager } from 'Content/script/managers/MultipleSendManager';
import { HubsManager } from 'Content/script/managers/HubsManager';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

export class HubsService {
    public multipleSendManager: MultipleSendManager;
    public hubsManager: HubsManager;

    public constructor(private readonly ngZone: NgZone, private http: HttpClient, private router: Router)
    {
        this.multipleSendManager = MultipleSendManager.CreateInstance(this.ngZone);
        this.hubsManager = HubsManager.CreateInstance(http);
    }

    /**********************************************************************
     * 任意のタイミングでコンストラクタの生成が出来ないので初期化メソッドを作成
     **********************************************************************/
    public Initialization(): void {
        this.multipleSendManager = MultipleSendManager.CreateInstance(this.ngZone);
        this.hubsManager = HubsManager.CreateInstance(this.http);
    }
}
