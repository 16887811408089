import { Component, Input, ContentChildren, QueryList, AfterContentInit } from '@angular/core';
import { AbstractControlDirective } from '@angular/forms';
import { FormErrorLabelComponent } from './errorLabel.component'

@Component({
    selector: 'form-error-labels',
    template: `
<div *ngIf="isShow">
    <ng-content></ng-content>
</div>
`,
})
export class FormErrorLabelsComponent implements AfterContentInit {
    @Input() public value: AbstractControlDirective;
    @Input() public condOr: boolean;
    @ContentChildren(FormErrorLabelComponent) errorLabels: QueryList<FormErrorLabelComponent>;

    public get isShow(): boolean {
        if (this.condOr) {
            return this.value.dirty || this.value.touched;
        } else {
            return !!this.value.errors && (this.value.dirty || this.value.touched);
        }
    }

    public ngAfterContentInit(): void {
        this.errorLabels.forEach(x => x.assign(this.value));
    }

    public onClick() {
        this.errorLabels.forEach(x => {
            console.log(x);
            console.log(x.isShow);
        });
    }
}