export const GetBulkOrderStatusDropdown: Function = (): DropDownSelectItem[] => {
    return [
        {
            key: '',
            value: ''
        } as DropDownSelectItem,
        {
            key: 'PENDING_REGIST',
            value: '取得可否 確認中'
        } as DropDownSelectItem,
        {
            key: 'TEMP_SUCCESS',
            value:'取得不可 (再申請)'
        } as DropDownSelectItem,
        {
            key: 'PENDING_REGIST_WAIT',
            value: '登録中 '
        } as DropDownSelectItem,
        {
            key: 'PAYMENT_WAIT',
            value: '入金待ち'
        } as DropDownSelectItem,
        {
            key: 'AUTH_FAIL',
            value: '支払い方法 変更'
        } as DropDownSelectItem,
        {
            key: 'BILLING_FAIL',
            value: 'カード支払い 失敗'
        } as DropDownSelectItem,
        {
            key: 'CANCEL',
            value: 'キャンセル'
        } as DropDownSelectItem,
        {
            key: 'COMPLETE',
            value: '登録完了'
        } as DropDownSelectItem,
        {
            key: 'ERROR',
            value: '処理失敗'
        }
    ];
};

export const GetBulkOrderPaymentTypeIdDropdown: Function = (): DropDownSelectItem[] => {
    return [
        {
            key: '',
            value: ''
        } as DropDownSelectItem,
        {
            key: '1',
            value: 'カード'
        } as DropDownSelectItem,
        {
            key: '3',
            value: '銀行振り込み'
        } as DropDownSelectItem,
        {
            key: '5',
            value: 'コンビ二'
        } as DropDownSelectItem,
        {
            key: '6',
            value: '月次課金'
        } as DropDownSelectItem
    ];
};

export class DropDownSelectItem {
    public constructor(public key: string, public value: string) { }
}
