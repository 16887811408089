import { Component, Input, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';

@Component({
    selector: 'normal-pagination',
    template: `
    <ul *ngIf="totalPages > 1" class="nav-Pagination" [class.mt8]="!isCenter" [class.mt40]="isCenter">
        <li>
            <a href="javascript:void(0)" [class.is-Disabled]="currentPage === 1" (click)="onClickPageNo(currentPage - 1)"><i class="icon icon-ArrowLeft"></i>前</a>
        </li>
        <li *ngFor="let pageNo of pages">
            <a href="javascript:void(0)" (click)="onClickPageNo(pageNo)" [class.is-Current]="pageNo == currentPage">{{pageNo}}</a>
        </li>
        <li>
            <a href="javascript:void(0)" [class.is-Disabled]="currentPage === totalPages" (click)="onClickPageNo(currentPage + 1)">次<i class="icon icon-ArrowRight"></i></a>
        </li>
    </ul>
    `
})

export class NormalPaginationComponent implements OnChanges {
    @Input() totalItem: number;
    @Input() currentPage: number;
    @Input() pageSize: number;
    @Input() isCenter: boolean = false;

    @Output() onClickPageNoEvent: EventEmitter<number> = new EventEmitter();

    public ngOnChanges(changes: SimpleChanges): void {
        this.getPager();
    }

    public totalPages: number;
    public pages: number[];

    public getPager(): void {
        // calculate total pages
        this.totalPages = Math.ceil(this.totalItem /this.pageSize);

        var startPage: number, endPage: number;
        if (this.totalPages <= 10) {
            // less than 10 total pages so show all
            startPage = 1;
            endPage = this.totalPages;
        } else {
            // more than 10 total pages so calculate start and end pages
            if (this.currentPage <= 6) {
                startPage = 1;
                endPage = 10;
            } else if (this.currentPage + 4 >= this.totalPages) {
                startPage = this.totalPages - 9;
                endPage = this.totalPages;
            } else {
                startPage = this.currentPage - 5;
                endPage = this.currentPage + 4;
            }
        }

        // calculate start and end item indexes
        var startIndex = (this.currentPage - 1) * this.pageSize;

        // create an array of pages to ng-repeat in the pager control
        this.pages = [];
        for (var index = startPage; index < endPage + 1; index++) {
            this.pages.push(index);
        }
    }

    public onClickPageNo(page: number): void {
        if (page === this.currentPage || 0 >= page || this.totalPages < page) {
            return;
        }

        this.onClickPageNoEvent.emit(page);
    }
}
