import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'localDateString',
})
export class LocalDateStringPipe implements PipeTransform {
    transform(value: any): string {

        let temp: string = value.toString();
        let tempDate = new Date(temp);
        if (temp == '' || tempDate == null) return temp;
        return tempDate.toLocaleDateString("ja", {
            year: "numeric",
            month: "2-digit",
            day: "numeric"
        });

    }
}
