/// <reference path='../../../definitions/window.d.ts' />

import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';

import { ServiceContainer } from 'Content/script/service/service_container';
import { PagerViewModel, PageView } from 'Content/script/components/view_models/pager_view_model';
import { ValidationRegexsDefine } from 'Content/script/libs/define/validation_regexs.define';
import { StringUtil } from 'Content/script/libs/utils/string.util';

/**
 * ドメイン情報コピーモーダル
 */
@Component({
    selector: 'domain-copy-modal',
    templateUrl: './domain_copy_modal.html'
})

export class DomainCopyModalComponent {
    public viewModel: DomainCopyModalViewModel;

    public constructor(private router: Router, private serviceContainer: ServiceContainer) {
        const queueId = serviceContainer.HubsService.multipleSendManager.GetComponentId();
        this.viewModel = new DomainCopyModalViewModel(this.router, queueId, this.serviceContainer);
    }
}

export class DomainCopyModalViewModel extends PagerViewModel {
    private _getDomainInfoCallBack: (domainInfo: DomainInfo) => void;
    private _getOrgDomainInfoCallBack: (orgDomainInfo: OrgDomainInfo) => void;
    private _allLoadingCallBack: (isAllLoading: boolean) => void;

    private _isAttributeJp: boolean;
    private _contactType: DomainContactType;

    public isShowModal: boolean;

    public searchCondition: SearchCondition;

    protected displayNumList: Array<number>;
    public pageNoTotal: number;
    protected pageIndexs: number[];
    protected pageNo: number;
    protected lineCnt: number;

    protected sortPriorityList: string[];
    public sortList: { [key: string]: number };

    public domainList: Domain[];

    protected readonly spJpItems: { value: string, text: string }[];
    public readonly rDomainPattern: string;

    public constructor(
        private _router: Router,
        private _queueId: number,
        private _serviceContainer: ServiceContainer) {
        super(_queueId, _router, _serviceContainer);

        this._getDomainInfoCallBack = null;
        this._getOrgDomainInfoCallBack = null;
        this._allLoadingCallBack = null;
        
        this.isShowModal = false;

        this.searchCondition = new SearchCondition();

        this._getDomainGroupNames();

        this._isAttributeJp = false;
        this._contactType = DomainContactType.Non;

        this.displayNumList = new Array<number>(5, 10, 20, 50, 100);
        this.pageNoTotal = 0;
        this.pageIndexs = [];
        this.pageNo = 1;
        this.lineCnt = 5;

        this.sortPriorityList = ['domainNameML'];
        this.sortList = { 'domainNameML': 2 };

        this.domainList = [];

        //this.spJpItems = DropDownItemsDefine.spJpItemList;

        this.rDomainPattern = ValidationRegexsDefine.rDomainPattern;
    }

    /**
     *  (外部公開用) コールバック関数を設定
     * @param getDomainInfoCallBack ドメイン情報をコピー
     * @param getOrgDomainInfoCallBack ドメイン情報をコピー(組織情報の場合)
     * @param allLoadingCallBack ローディング時
     */
    public setCallBack(
        getDomainInfoCallBack: (domainInfo: DomainInfo) => void,
        getOrgDomainInfoCallBack: (orgDomainInfo: OrgDomainInfo) => void,
        allLoadingCallBack: (isAllLoading: boolean) => void
    ): void {
        this._getDomainInfoCallBack = getDomainInfoCallBack;
        this._getOrgDomainInfoCallBack = getOrgDomainInfoCallBack;
        this._allLoadingCallBack = allLoadingCallBack;
    }

    /**
     *  (外部公開用) 取得するドメインリストを属性JPに絞るか設定
     */
    public set attributeJpFlg(isAttributeJp: boolean) {
        this._isAttributeJp = isAttributeJp;
    }

    /**
     *  (外部公開用) 取得するコンタクトタイプを設定
     */
    public set contactType(contactType: DomainContactType) {
        this._contactType = contactType;
    }

    /**
     *  (外部公開用) モーダルの表示時の初期化処理
     */
    public showInitModal(): void {
        if (this._isAttributeJp === true) {
            this._requestGetJpDomainList();
        }
        else {
            this._requestGetDomainList();
        }
    }

    /**
     * 全体ローディング処理コールバック
     * @param isAllLoading
     */
    private _allLoading(isAllLoading: boolean): void {
        if (this._allLoadingCallBack) {
            this._allLoadingCallBack(isAllLoading);
        }
    }

    /**
     * ドメイン情報コールバック
     * @param domainInfo
     */
    private _getDomainInfo(domainInfo: DomainInfo): void {
        if (this._getDomainInfoCallBack) {
            this._getDomainInfoCallBack(domainInfo);
        }
    }

    /**
     * ドメイン情報コールバック(組織情報)
     * @param orgDomainInfo
     */
    private _getOrgDomainInfo(orgDomainInfo: OrgDomainInfo): void {
        if (this._getOrgDomainInfoCallBack) {
            this._getOrgDomainInfoCallBack(orgDomainInfo);
        }
    }

    /**
     * ドメイン情報一覧を取得
     */
    private _requestGetDomainList(): void {
        this._allLoading(true);

        const sortList: DomainListSortRequestHubDto[] = [];
        for (let key in this.sortList) {
            sortList.push({ Key: key, Type: this.sortList[key] });
        }

        const requestData: DomainListRequestHubDto = {
            LineCnt: this.lineCnt,
            PageNo: this.pageNo,
            DomainIds: '',
            DomainNames: this.searchCondition.domainNames,
            GroupName: this.searchCondition.domainGroupName,
            CreatedDateFrom: this.searchCondition.createdDateFrom,
            CreatedDateTo: this.searchCondition.createdDateTo,
            ExpirationDateFrom: this.searchCondition.expirationDateFrom,
            ExpirationDateTo: this.searchCondition.expirationDateTo,
            SortList: sortList
        };

        this.serviceContainer.HubsService.multipleSendManager.Send(
            this.queueId,
            this.serviceContainer.HubsService.hubsManager.domainHubManager.DomainList,
            requestData,
            (resHubDto: DomainListResponseHubDto) => {
                this.domainList = [];
                for (let domain of resHubDto.DomainList) {
                    let domainInfo: Domain = new Domain();
                    domainInfo.domainId = domain.DomainId;
                    domainInfo.domainNameMl = domain.DomainNameMl;
                    this.domainList.push(domainInfo);
                }

                //ドメインが一件の場合チェック状態にする
                if (this.domainList.length === 1) {
                    this.domainList[0].isChecked = true;
                }

                const pageView: PageView = super.getPager(resHubDto.TotalCount, this.pageNo, this.lineCnt);
                this.pageNoTotal = pageView.totalPages;
                this.pageIndexs = pageView.pages;

                this.isShowModal = true;
                this._allLoading(false);
            },
            (resHubDto: DomainListResponseHubDto) => {
                this.domainList = [];
                this.isShowModal = true;
                this._allLoading(false);
            },
            (resHubDto: DomainListResponseHubDto) => {
                this.domainList = [];
                this.isShowModal = true;
                this._allLoading(false);
            },
            null,
            (resHubDto: DomainListResponseHubDto) => {
                this.domainList = [];
                this.isShowModal = true;
                this._allLoading(false);
            }
        );
    }

    /**
     * 属性jpドメイン情報一覧を取得
     */
    private _requestGetJpDomainList(): void {
        this._allLoading(true);

        const sortList: DomainListSortRequestHubDto[] = [];
        for (let key in this.sortList) {
            sortList.push({ Key: key, Type: this.sortList[key] });
        }

        const requestData: OrgJpDomainOrganizationChangeListRequestHubDto = {
            LineCnt: this.lineCnt,
            PageNo: this.pageNo,
            DomainIds: '',
            DomainNames: this.searchCondition.domainNames,
            GroupName: this.searchCondition.domainGroupName,
            CreatedDateFrom: this.searchCondition.createdDateFrom,
            CreatedDateTo: this.searchCondition.createdDateTo,
            ExpirationDateFrom: this.searchCondition.expirationDateFrom,
            ExpirationDateTo: this.searchCondition.expirationDateTo,
            SortList: sortList
        };

        this.serviceContainer.HubsService.multipleSendManager.Send(
            this.queueId,
            this.serviceContainer.HubsService.hubsManager.domainWhoisHubManager.OrgJpDomainOrganizationChangeList,
            requestData,
            (resHubDto: OrgJpDomainOrganizationChangeListResponseHubDto) => {
                this.domainList = [];
                for (let domain of resHubDto.OrgJpDomainOrganizationChangeList) {
                    const jpDomain: Domain = new Domain();
                    jpDomain.domainId = domain.DomainId;
                    jpDomain.domainNameMl = domain.DomainNameMl;
                    this.domainList.push(jpDomain);
                }

                //ドメインが一件の場合チェック状態にする
                if (this.domainList.length === 1) {
                    this.domainList[0].isChecked = true;
                }

                const pageView: PageView = super.getPager(resHubDto.TotalCount, this.pageNo, this.lineCnt);
                this.pageNoTotal = pageView.totalPages;
                this.pageIndexs = pageView.pages;

                this.isShowModal = true;
                this._allLoading(false);
            },
            (resHubDto: OrgJpDomainOrganizationChangeListResponseHubDto) => {
                this.domainList = [];
                this.isShowModal = true;
                this._allLoading(false);
            },
            (resHubDto: OrgJpDomainOrganizationChangeListResponseHubDto) => {
                this.domainList = [];
                this.isShowModal = true;
                this._allLoading(false);
            },
            null,
            (resHubDto: OrgJpDomainOrganizationChangeListResponseHubDto) => {
                this.domainList = [];
                this.isShowModal = true;
                this._allLoading(false);
            }
        );
    }

    /**
     * 検索用のグループ名を取得する
     */
    private _getDomainGroupNames(): void {
        const req: DomainGroupListRequestHubDto = {
            CurrentPage: 1,
            PageSize: 100,
            GroupName: ''
        };

        this.serviceContainer.HubsService.multipleSendManager.Send(
            this.queueId,
            this.serviceContainer.HubsService.hubsManager.domainHubManager.DomainGroupList,
            req,
            (data: DomainGroupListResponseHubDto) => {
                this.searchCondition.groupNameSelectItems = [];
                for (let domainGroup of data.DomainGroupList) {
                    const domainSearchSelectItem: DomainSearchSelectItem = new DomainSearchSelectItem(domainGroup.GroupId, domainGroup.GroupName);
                    this.searchCondition.groupNameSelectItems.push(domainSearchSelectItem);
                }
            },
            (data: DomainGroupListResponseHubDto) => {
                this.searchCondition.groupNameSelectItems = [];
            },
            null,
            null,
            null
        );
    }

    /**
     * 検索
     */
    public searchDomain(form: NgForm): void {
        if (form.valid === false) {
            for (let key in form.controls) {
                form.controls[key].markAsDirty();
            }
            return;
        }

        this.pageNo = 1;
        if (this._isAttributeJp === true) {
            this._requestGetJpDomainList();
        }
        else {
            this._requestGetDomainList();
        }
    }

    /**
     * グループ名選択
     * @param event
     */
    public onChangeSearchDomainGroup(value: string): void {
        const domainGroupId: string = value;
        this.searchCondition.domainGroupId = domainGroupId;

        const groupNameSelectItems: DomainSearchSelectItem = this.searchCondition.groupNameSelectItems
            .find(groupNameSelectItem => {
                return groupNameSelectItem.value === domainGroupId
            });
        this.searchCondition.domainGroupName = groupNameSelectItems !== void 0 ? groupNameSelectItems.text : '';
    }

    /**
     * カレンダー(登録日)イベントハンドラ
     */
    public onChangeCreatedDateFrom(value: string): void {
        this.searchCondition.createdDateFrom = value;
    }

    /**
     * カレンダー(登録日)イベントハンドラ
     */
    public onChangeCreatedDateTo(value: string): void {
        this.searchCondition.createdDateTo = value;
    }

    /**
     * カレンダー(登録期限日)イベントハンドラ
     */
    public onChangeExpirationDateFrom(value: string): void {
        this.searchCondition.expirationDateFrom = value;
    }

    /**
     * カレンダー(登録日期限日)イベントハンドラ
     */
    public onChangeExpirationDateTo(value: string): void {
        this.searchCondition.expirationDateTo = value;
    }

    /**
     * 検索条件のクリア
     */
    public clearSearchCondition(): void {
        this.searchCondition.domainNames = '';
        this.searchCondition.domainGroupId = '';
        this.searchCondition.domainGroupName = '';
        this.searchCondition.createdDateFrom = '';
        this.searchCondition.createdDateTo = '';
        this.searchCondition.expirationDateFrom = '';
        this.searchCondition.expirationDateTo = '';
    }

    /**
     * ソート
     * @param sortKey
     */
    public onClickSortDomain(sortKey: string): void {
        if (this.sortList[sortKey] === 1) {
            this.sortList[sortKey] = 2;
        } else {
            this.sortList[sortKey] = 1;
        }

        if (this._isAttributeJp === true) {
            this._requestGetJpDomainList();
        }
        else {
            this._requestGetDomainList();
        }
    }

    /**
     * ページャーのイベント
     */
    protected onPageNoClick(displayNum: number): void {
        if (displayNum === this.pageNo || 0 >= displayNum || this.pageNoTotal < displayNum) {
            return;
        }
        this.pageNo = displayNum;
        if (this._isAttributeJp === true) {
            this._requestGetJpDomainList();
        }
        else {
            this._requestGetDomainList();
        }
    }

    /**
     * 各ドメイン名をチェック時
     */
    protected checkedDomain(index: number): void {
        for (let i = 0; i < this.domainList.length; i++) {
            if (i === index) {
                this.domainList[i].isChecked = true;
            } else {
                this.domainList[i].isChecked = false;
            }
        }
    }

    /**
     * ドメイン情報コピー時
     */
    public async onClickCopyDomainInfo() {
        let domainId: string = '';
        for (let domain of this.domainList) {
            if (domain.isChecked === true) {
                domainId = domain.domainId.toString();
            }
        }

        // ドメインが選択されていなかったらモーダルを閉じる
        if (domainId === '') {
            this.isShowModal = false;
            return;
        }
          
        //親コンポーネントにドメイン情報を表示
        this._allLoading(true);
        const util = new DomainCopyModalUtil(this._queueId, this._serviceContainer);
        let domainInfo = await util.requestGetDomainInfoAsync(domainId, this._contactType);
        if (domainInfo !== void 0) {
            //contactTypeが'Ago'(=公開連絡窓口)のときnullが正常値として返ってくる場合がある
            (domainInfo instanceof DomainInfo || domainInfo === null) ? this._getDomainInfo(<DomainInfo>domainInfo) : this._getOrgDomainInfo(<OrgDomainInfo>domainInfo);
        };

        this.isShowModal = false;
        this._allLoading(false);
    }

    /**
     * キャンセル
     */
    public onClickCancel(): void {
        this.isShowModal = false;
    }
}

export class Domain {
    public isChecked: boolean;
    public domainId: number;
    public domainNameMl: string;

    public constructor() {
        this.isChecked = false;
        this.domainId = 0;
        this.domainNameMl = '';
    }
}

export class SearchCondition {
    public domainNames: string;
    public domainGroupId: string;
    public domainGroupName: string;
    public createdDateFrom: string;
    public createdDateTo: string;
    public expirationDateFrom: string;
    public expirationDateTo: string;
    public groupNameSelectItems: DomainSearchSelectItem[];

    public constructor() {
        this.domainNames = '';
        this.domainGroupId = '';
        this.domainGroupName = '';
        this.createdDateFrom = '';
        this.createdDateTo = '';
        this.expirationDateFrom = '';
        this.expirationDateTo = '';
        this.groupNameSelectItems = [];
    }
}

export class DomainSearchSelectItem {
    public constructor(public value: string, public text: string) { }
}

export class DomainInfo {
    public role: string;
    public displayRole: string;
    public lnameMl: string;
    public lname: string;
    public fnameMl: string;
    public fname: string;
    public organizationMl: string;
    public organization: string;
    public cc: string;
    public displayCc: string;
    public pcJp1: string;
    public pcJp2: string;
    public pc: string;
    public spJp: string;
    public displaySpJp: string;
    public spMl: string;
    public sp: string;
    public cityMl: string;
    public city: string;
    public street1Ml: string;
    public street1: string;
    public street1TownMl: string;
    public street1Town: string;
    public street1NumberMl: string;
    public street1Number: string;
    public street2Ml: string;
    public street2: string;
    public phone: string;
    public fax: string;
    public divisionMl: string;
    public division: string;
    public titleMl: string;
    public title: string;
    public email: string;

    public constructor() {
        this.role = 'I';
        this.displayRole = '';
        this.lnameMl = '';
        this.lname = '';
        this.fnameMl = '';
        this.fname = '';
        this.organizationMl = '';
        this.organization = '';
        this.cc = 'JP';
        this.displayCc = '';
        this.pcJp1 = '';
        this.pcJp2 = '';
        this.pc = '';
        this.spJp = '01';
        this.displaySpJp = '';
        this.spMl = '';
        this.sp = '';
        this.cityMl = '';
        this.city = '';
        this.street1Ml = '';
        this.street1 = '';
        this.street1TownMl = '';
        this.street1Town = '';
        this.street1NumberMl = '';
        this.street1Number = '';
        this.street2Ml = '';
        this.street2 = '';
        this.phone = '';
        this.fax = '';
        this.divisionMl = '';
        this.division = '';
        this.titleMl = '';
        this.title = '';
        this.email = '';
    }
}

export class OrgDomainInfo {
    public organization: string;
    public organizationMl: string;
    public organizationMlKana: string;
    public organizationType: string;
    public pcJp1: string;
    public pcJp2: string;
    public spJp: string;
    public city: string;
    public cityMl: string;
    public street1: string;
    public street1Ml: string;
    public street2: string;
    public street2Ml: string;
    public lname: string;
    public lnameMl: string;
    public fname: string;
    public fnameMl: string;
    public division: string;
    public divisionMl: string;
    public phone: string;
    public fax: string;
    public email: string;
    public title: string;
    public titleMl: string;
    public registrationDate: string;
    public registrationSpJp: string;
    public registrationAddress: string;
    public representFname: string;
    public representFnameMl: string;
    public representLname: string;
    public representLnameMl: string;
    public representTitleMl: string;

    public constructor() {
        this.organization = '';
        this.organizationMl = '';
        this.organizationMlKana = '';
        this.organizationType = '';
        this.pcJp1 = '';
        this.pcJp2 = '';
        this.spJp = '';
        this.city = '';
        this.cityMl = '';
        this.street1 = '';
        this.street1Ml = '';
        this.street2 = '';
        this.street2Ml = '';
        this.lname = '';
        this.lnameMl = '';
        this.fname = '';
        this.fnameMl = '';
        this.division = '';
        this.divisionMl = '';
        this.phone = '';
        this.fax = '';
        this.email = '';
        this.title = '';
        this.titleMl = '';
        this.registrationDate = '';
        this.registrationSpJp = '';
        this.registrationAddress = '';
        this.representFname = '';
        this.representFnameMl = '';
        this.representLname = '';
        this.representLnameMl = '';
        this.representTitleMl = '';
    }
}

export enum DomainContactType {
    Non = 0,
    Reg,
    Adm,
    Bil,
    Tec,
    Ago,
    Org
}

/**
 * ↓ドメインIDからwhois登録者情報を取得するクラス(6個)　外部モジュールからもアクセスできるようにするためクラス化
 *     RequestGetRegDomainInfoCommand, RequestGetAdmDomainInfoCommand, RequestGetBilDomainInfoCommand,
 *     RequestGetTecDomainInfoCommand, RequestGetAgoDomainInfoCommand, RequestGetOrgDomainInfoCommand
 * ↓外部からアクセスするためのutilクラスを作成
 *     DomainCopyModalUtil
 */

//外部からドメイン情報取得処理を呼ぶためのクラス
export class DomainCopyModalUtil<T extends (DomainInfo | OrgDomainInfo)> {
    public constructor(
        private _queueId: number,
        private _serviceContainer: ServiceContainer
    ) { }

    public requestGetDomainInfoAsync(domainId: string, contactType: DomainContactType): Promise<T> {

        //contactTypeが'Ago'(=公開連絡窓口)のときnullが正常値として返ってくる場合があるので、エラー値はvoid(0)にする。
        const errorOccured = () => void 0;

        const requestData: DomainInfoRequestHubDto = {
            DomainId: domainId
        };

        switch (contactType) {
            case DomainContactType.Reg:
                let cmdreg = new RequestGetRegDomainInfoCommand(this._queueId, this._serviceContainer);
                return cmdreg.run(requestData).catch(errorOccured);
            case DomainContactType.Adm:
                let cmdadm = new RequestGetAdmDomainInfoCommand(this._queueId, this._serviceContainer);
                return cmdadm.run(requestData).catch(errorOccured);
            case DomainContactType.Bil:
                let cmdbil = new RequestGetBilDomainInfoCommand(this._queueId, this._serviceContainer);
                return cmdbil.run(requestData).catch(errorOccured);
            case DomainContactType.Tec:
                let cmdtec = new RequestGetTecDomainInfoCommand(this._queueId, this._serviceContainer);
                return cmdtec.run(requestData).catch(errorOccured);
            case DomainContactType.Ago:
                let cmdago = new RequestGetAgoDomainInfoCommand(this._queueId, this._serviceContainer);
                return cmdago.run(requestData).catch(errorOccured);
            case DomainContactType.Org:
                let cmdorg = new RequestGetOrgDomainInfoCommand(this._queueId, this._serviceContainer);
                return cmdorg.run(requestData).catch(errorOccured);
            default:
                console.log('該当情報がありません');
                return void 0;
        }
    }
}

class RequestGetRegDomainInfoCommand {
    public constructor(
        private _queueId: number,
        private _serviceContainer: ServiceContainer
    ) { }

    /**
     * ドメイン情報を取得(登録者)
     * @param domainId
     */
    public run(requestData: DomainInfoRequestHubDto): Promise<DomainInfo> {

        return new Promise<DomainInfo>((resolve, reject) =>
            this._serviceContainer.HubsService.multipleSendManager.Send(
                this._queueId,
                this._serviceContainer.HubsService.hubsManager.domainWhoisHubManager.GetRegDomainInfo,
                requestData,
                (resHubDto: DomainInfoResponseHubDto) => {
                    const domainInfo: DomainInfo = new DomainInfo();
                    domainInfo.lname = resHubDto.Lname;
                    domainInfo.lnameMl = resHubDto.LnameMl;
                    domainInfo.fname = resHubDto.Fname;
                    domainInfo.fnameMl = resHubDto.FnameMl;
                    domainInfo.organization = resHubDto.Organization;
                    domainInfo.organizationMl = resHubDto.OrganizationMl;
                    domainInfo.cc = resHubDto.Cc;
                    domainInfo.pcJp1 = resHubDto.PcJp1;
                    domainInfo.pcJp2 = resHubDto.PcJp2;
                    domainInfo.pc = resHubDto.Pc;
                    domainInfo.sp = resHubDto.Sp;
                    domainInfo.spMl = resHubDto.SpMl;
                    domainInfo.spJp = resHubDto.SpJp;
                    domainInfo.city = resHubDto.City;
                    domainInfo.cityMl = resHubDto.CityMl;
                    domainInfo.street1 = resHubDto.Street1;
                    domainInfo.street1Ml = resHubDto.Street1Ml;
                    domainInfo.street1Town = resHubDto.Street1Town;
                    domainInfo.street1TownMl = resHubDto.Street1TownMl;
                    domainInfo.street1Number = resHubDto.Street1Number;
                    domainInfo.street1NumberMl = resHubDto.Street1NumberMl;
                    domainInfo.street2 = StringUtil.IfNull(resHubDto.Street2, '');
                    domainInfo.street2Ml = resHubDto.Street2Ml;
                    domainInfo.phone = resHubDto.Phone;
                    domainInfo.fax = StringUtil.IfNull(resHubDto.Fax, '');
                    domainInfo.email = resHubDto.Email;
                    domainInfo.role = resHubDto.Role;

                    resolve(domainInfo);
                },
                (resHubDto: DomainInfoResponseHubDto) => {
                    reject();
                },
                (resHubDto: DomainInfoResponseHubDto) => {
                    reject();
                },
                null,
                (resHubDto: DomainInfoResponseHubDto) => {
                    reject();
                }
            )
        );
    }
}

class RequestGetAdmDomainInfoCommand {
    public constructor(
        private _queueId: number,
        private _serviceContainer: ServiceContainer
    ) { }

    /**
     * ドメイン情報を取得(管理者)
     * @param domainId
     */
    public run(requestData: DomainInfoRequestHubDto): Promise<DomainInfo> {

        return new Promise<DomainInfo>((resolve, reject) =>
            this._serviceContainer.HubsService.multipleSendManager.Send(
                this._queueId,
                this._serviceContainer.HubsService.hubsManager.domainWhoisHubManager.GetAdmDomainInfo,
                requestData,
                (resHubDto: DomainInfoResponseHubDto) => {
                    const domainInfo: DomainInfo = new DomainInfo();
                    domainInfo.lname = resHubDto.Lname;
                    domainInfo.lnameMl = resHubDto.LnameMl;
                    domainInfo.fname = resHubDto.Fname;
                    domainInfo.fnameMl = resHubDto.FnameMl;
                    domainInfo.organization = resHubDto.Organization;
                    domainInfo.organizationMl = resHubDto.OrganizationMl;
                    domainInfo.cc = resHubDto.Cc;
                    domainInfo.pcJp1 = resHubDto.PcJp1;
                    domainInfo.pcJp2 = resHubDto.PcJp2;
                    domainInfo.pc = resHubDto.Pc;
                    domainInfo.sp = resHubDto.Sp;
                    domainInfo.spMl = resHubDto.SpMl;
                    domainInfo.spJp = resHubDto.SpJp;
                    domainInfo.city = resHubDto.City;
                    domainInfo.cityMl = resHubDto.CityMl;
                    domainInfo.street1 = resHubDto.Street1;
                    domainInfo.street1Ml = resHubDto.Street1Ml;
                    domainInfo.street1Town = resHubDto.Street1Town;
                    domainInfo.street1TownMl = resHubDto.Street1TownMl;
                    domainInfo.street1Number = resHubDto.Street1Number;
                    domainInfo.street1NumberMl = resHubDto.Street1NumberMl;
                    domainInfo.street2 = StringUtil.IfNull(resHubDto.Street2, '');
                    domainInfo.street2Ml = resHubDto.Street2Ml;
                    domainInfo.phone = resHubDto.Phone;
                    domainInfo.fax = StringUtil.IfNull(resHubDto.Fax, '');
                    domainInfo.email = resHubDto.Email;
                    domainInfo.role = resHubDto.Role;

                    resolve(domainInfo);
                },
                (resHubDto: DomainInfoResponseHubDto) => {
                    reject();
                },
                (resHubDto: DomainInfoResponseHubDto) => {
                    reject();
                },
                null,
                (resHubDto: DomainInfoResponseHubDto) => {
                    reject();
                }
            )
        );

    }
}

class RequestGetBilDomainInfoCommand {
    public constructor(
        private _queueId: number,
        private _serviceContainer: ServiceContainer
    ) { }

    /**
     * ドメイン情報を取得(経営者)
     * @param domainId
     */
    public run(requestData: DomainInfoRequestHubDto): Promise<DomainInfo> {

        return new Promise<DomainInfo>((resolve, reject) =>
            this._serviceContainer.HubsService.multipleSendManager.Send(
                this._queueId,
                this._serviceContainer.HubsService.hubsManager.domainWhoisHubManager.GetBilDomainInfo,
                requestData,
                (resHubDto: DomainInfoResponseHubDto) => {
                    const domainInfo: DomainInfo = new DomainInfo();
                    domainInfo.lname = resHubDto.Lname;
                    domainInfo.lnameMl = resHubDto.LnameMl;
                    domainInfo.fname = resHubDto.Fname;
                    domainInfo.fnameMl = resHubDto.FnameMl;
                    domainInfo.organization = resHubDto.Organization;
                    domainInfo.organizationMl = resHubDto.OrganizationMl;
                    domainInfo.cc = resHubDto.Cc;
                    domainInfo.pcJp1 = resHubDto.PcJp1;
                    domainInfo.pcJp2 = resHubDto.PcJp2;
                    domainInfo.pc = resHubDto.Pc;
                    domainInfo.sp = resHubDto.Sp;
                    domainInfo.spMl = resHubDto.SpMl;
                    domainInfo.spJp = resHubDto.SpJp;
                    domainInfo.city = resHubDto.City;
                    domainInfo.cityMl = resHubDto.CityMl;
                    domainInfo.street1 = resHubDto.Street1;
                    domainInfo.street1Ml = resHubDto.Street1Ml;
                    domainInfo.street1Town = resHubDto.Street1Town;
                    domainInfo.street1TownMl = resHubDto.Street1TownMl;
                    domainInfo.street1Number = resHubDto.Street1Number;
                    domainInfo.street1NumberMl = resHubDto.Street1NumberMl;
                    domainInfo.street2 = StringUtil.IfNull(resHubDto.Street2, '');
                    domainInfo.street2Ml = resHubDto.Street2Ml;
                    domainInfo.phone = resHubDto.Phone;
                    domainInfo.fax = StringUtil.IfNull(resHubDto.Fax, '');
                    domainInfo.email = resHubDto.Email;
                    domainInfo.role = resHubDto.Role;

                    resolve(domainInfo);
                },
                (resHubDto: DomainInfoResponseHubDto) => {
                    reject();
                },
                (resHubDto: DomainInfoResponseHubDto) => {
                    reject();
                },
                null,
                (resHubDto: DomainInfoResponseHubDto) => {
                    reject();
                }
            )
        );
    }
}

class RequestGetTecDomainInfoCommand {
    public constructor(
        private _queueId: number,
        private _serviceContainer: ServiceContainer
    ) { }

    /**
     * ドメイン情報を取得(技術者)
     * @param domainId
     */
    public run(requestData: DomainInfoRequestHubDto): Promise<DomainInfo> {

        return new Promise<DomainInfo>((resolve, reject) =>
            this._serviceContainer.HubsService.multipleSendManager.Send(
                this._queueId,
                this._serviceContainer.HubsService.hubsManager.domainWhoisHubManager.GetTecDomainInfo,
                requestData,
                (resHubDto: DomainInfoResponseHubDto) => {
                    const domainInfo: DomainInfo = new DomainInfo();
                    domainInfo.lname = resHubDto.Lname;
                    domainInfo.lnameMl = resHubDto.LnameMl;
                    domainInfo.fname = resHubDto.Fname;
                    domainInfo.fnameMl = resHubDto.FnameMl;
                    domainInfo.organization = resHubDto.Organization;
                    domainInfo.organizationMl = resHubDto.OrganizationMl;
                    domainInfo.cc = resHubDto.Cc;
                    domainInfo.pcJp1 = resHubDto.PcJp1;
                    domainInfo.pcJp2 = resHubDto.PcJp2;
                    domainInfo.pc = resHubDto.Pc;
                    domainInfo.sp = resHubDto.Sp;
                    domainInfo.spMl = resHubDto.SpMl;
                    domainInfo.spJp = resHubDto.SpJp;
                    domainInfo.city = resHubDto.City;
                    domainInfo.cityMl = resHubDto.CityMl;
                    domainInfo.street1 = resHubDto.Street1;
                    domainInfo.street1Ml = resHubDto.Street1Ml;
                    domainInfo.street1Town = resHubDto.Street1Town;
                    domainInfo.street1TownMl = resHubDto.Street1TownMl;
                    domainInfo.street1Number = resHubDto.Street1Number;
                    domainInfo.street1NumberMl = resHubDto.Street1NumberMl;
                    domainInfo.street2 = StringUtil.IfNull(resHubDto.Street2, '');
                    domainInfo.street2Ml = resHubDto.Street2Ml;
                    domainInfo.phone = resHubDto.Phone;
                    domainInfo.fax = StringUtil.IfNull(resHubDto.Fax, '');
                    domainInfo.email = resHubDto.Email;
                    domainInfo.role = resHubDto.Role;
                    domainInfo.division = resHubDto.Division;
                    domainInfo.divisionMl = resHubDto.DivisionMl;
                    domainInfo.title = resHubDto.Title;
                    domainInfo.titleMl = resHubDto.TitleMl;

                    resolve(domainInfo);
                },
                (resHubDto: DomainInfoResponseHubDto) => {
                    reject();
                },
                (resHubDto: DomainInfoResponseHubDto) => {
                    reject();
                },
                null,
                (resHubDto: DomainInfoResponseHubDto) => {
                    reject();
                }
            )
        );
    }
}

class RequestGetAgoDomainInfoCommand {
    public constructor(
        private _queueId: number,
        private _serviceContainer: ServiceContainer
    ) { }

    /**
     * ドメイン情報を取得(公開連絡窓口)
     * @param domainId
     */
    public run(requestData: DomainInfoRequestHubDto): Promise<DomainInfo> {

        return new Promise<DomainInfo>((resolve, reject) =>
            this._serviceContainer.HubsService.multipleSendManager.Send(
                this._queueId,
                this._serviceContainer.HubsService.hubsManager.domainWhoisHubManager.GetAgoDomainInfo,
                requestData,
                (resHubDto: DomainInfoResponseHubDto) => {

                    if (resHubDto.HasContactInfo === false) {

                        resolve(null);

                    } else {
                        const domainInfo: DomainInfo = new DomainInfo();
                        domainInfo.lname = resHubDto.Lname;
                        domainInfo.lnameMl = resHubDto.LnameMl;
                        domainInfo.fname = resHubDto.Fname;
                        domainInfo.fnameMl = resHubDto.FnameMl;
                        domainInfo.organization = resHubDto.Organization;
                        domainInfo.organizationMl = resHubDto.OrganizationMl;
                        domainInfo.cc = resHubDto.Cc;
                        domainInfo.pcJp1 = resHubDto.PcJp1;
                        domainInfo.pcJp2 = resHubDto.PcJp2;
                        domainInfo.pc = resHubDto.Pc;
                        domainInfo.sp = resHubDto.Sp;
                        domainInfo.spMl = resHubDto.SpMl;
                        domainInfo.spJp = resHubDto.SpJp;
                        domainInfo.city = resHubDto.City;
                        domainInfo.cityMl = resHubDto.CityMl;
                        domainInfo.street1 = resHubDto.Street1;
                        domainInfo.street1Ml = resHubDto.Street1Ml;
                        domainInfo.street1Town = resHubDto.Street1Town;
                        domainInfo.street1TownMl = resHubDto.Street1TownMl;
                        domainInfo.street1Number = resHubDto.Street1Number;
                        domainInfo.street1NumberMl = resHubDto.Street1NumberMl;
                        domainInfo.street2 = StringUtil.IfNull(resHubDto.Street2, '');
                        domainInfo.street2Ml = resHubDto.Street2Ml;
                        domainInfo.phone = resHubDto.Phone;
                        domainInfo.fax = StringUtil.IfNull(resHubDto.Fax, '');
                        domainInfo.email = resHubDto.Email;
                        domainInfo.role = resHubDto.Role;

                        resolve(domainInfo);
                    }
                },
                (resHubDto: DomainInfoResponseHubDto) => {
                    reject();
                },
                (resHubDto: DomainInfoResponseHubDto) => {
                    reject();
                },
                null,
                (resHubDto: DomainInfoResponseHubDto) => {
                    reject();
                }
            )
        );
    }
}

class RequestGetOrgDomainInfoCommand {
    public constructor(
        private _queueId: number,
        private _serviceContainer: ServiceContainer
    ) { }

    /**
     * ドメイン情報を取得(組織情報)
     * @param domainId
     */
    public run(requestData: DomainInfoRequestHubDto): Promise<OrgDomainInfo> {

        return new Promise<OrgDomainInfo>((resolve, reject) =>
            this._serviceContainer.HubsService.multipleSendManager.Send(
                this._queueId,
                this._serviceContainer.HubsService.hubsManager.domainWhoisHubManager.OrgJpDomainTransferGetDomainInfo,
                requestData,
                (resHubDto: OrgJpDomainTransferDomainInfoResponseHubDto) => {
                    const domainInfo = new OrgDomainInfo();
                    domainInfo.organization = resHubDto.Organization;
                    domainInfo.organizationMl = resHubDto.OrganizationMl;
                    domainInfo.organizationMlKana = resHubDto.OrganizationMlKana;
                    domainInfo.organizationType = resHubDto.OrganizationType;
                    domainInfo.pcJp1 = resHubDto.PcJp1;
                    domainInfo.pcJp2 = resHubDto.PcJp2;
                    domainInfo.spJp = resHubDto.SpJp;
                    domainInfo.city = resHubDto.City;
                    domainInfo.cityMl = resHubDto.CityMl;
                    domainInfo.street1 = resHubDto.Street1;
                    domainInfo.street1Ml = resHubDto.Street1Ml;
                    domainInfo.street2 = StringUtil.IfNull(resHubDto.Street2, '');
                    domainInfo.street2Ml = resHubDto.Street2Ml;
                    domainInfo.lname = resHubDto.Lname;
                    domainInfo.lnameMl = resHubDto.LnameMl;
                    domainInfo.fname = resHubDto.Fname;
                    domainInfo.fnameMl = resHubDto.FnameMl;
                    domainInfo.division = resHubDto.Division;
                    domainInfo.divisionMl = resHubDto.DivisionMl;
                    domainInfo.phone = resHubDto.Phone;
                    domainInfo.fax = StringUtil.IfNull(resHubDto.Fax, '');
                    domainInfo.email = resHubDto.Email;
                    domainInfo.title = resHubDto.Title;
                    domainInfo.titleMl = resHubDto.TitleMl;
                    domainInfo.registrationDate = resHubDto.RegistrationDate;
                    domainInfo.registrationSpJp = resHubDto.RegistrationSpJp;
                    domainInfo.registrationAddress = resHubDto.RegistrationAddress;
                    domainInfo.representFname = resHubDto.RepresentFname;
                    domainInfo.representFnameMl = resHubDto.RepresentFnameMl;
                    domainInfo.representLname = resHubDto.RepresentLname;
                    domainInfo.representLnameMl = resHubDto.RepresentLnameMl;
                    domainInfo.representTitleMl = resHubDto.RepresentTitleMl;

                    resolve(domainInfo);
                },
                (resHubDto: DomainInfoResponseHubDto) => {
                    reject();
                },
                (resHubDto: DomainInfoResponseHubDto) => {
                    reject();
                },
                null,
                (resHubDto: DomainInfoResponseHubDto) => {
                    reject();
                }
            )
        );
    }
}
