import { Component, Output, EventEmitter, Input } from '@angular/core';
import { ValidationRegexsDefine } from 'Content/script/libs/define/validation_regexs.define';
import { NgForm } from '@angular/forms';

@Component({
    selector: 'domain-search-dropdown-for-domain-deletion',
    templateUrl: './domain_search_dropdown_for_domain_deletion.html'
})

export class DomainSearchDropdownForDomainDeletionComponent {
    @Output() onDecideSearchConditionEvent: EventEmitter<DomainSearchConditionForDomainDeletion> = new EventEmitter();

    public readonly rDomainPattern: string;
    public searchCondition: DomainSearchConditionForDomainDeletion;

    public constructor() {
        this.rDomainPattern = ValidationRegexsDefine.rDomainPattern;
        this.searchCondition = new DomainSearchConditionForDomainDeletion();
    }

    public onChangeSearchDomainGroup(event: any) {
        this.searchCondition.status = event.target.value;
    }

    public onDecideSearchCondition(form: NgForm): void {
        if (!form.valid) {
            for (let key in form.controls) {
                form.controls[key].markAsDirty();
            }
            return;
        }

        this.onDecideSearchConditionEvent.emit(this.searchCondition);
    }

    public onClickSearchConditionClear(): void {
        this.searchCondition.clear();
    }
}

export class DomainSearchConditionForDomainDeletion {
    public constructor(
        public domainNames: string = '',
        public status: string = '',
        public statusSelectItems: ReportSearchSelectItem[] = [
            new ReportSearchSelectItem('ACTIVE', '稼働中'),
            new ReportSearchSelectItem('PENDING_DELETE', '廃止待ち'),
            new ReportSearchSelectItem('PENDING_REGIST', '設定待ち'),
            new ReportSearchSelectItem('PENDING_RELEASE', '解除中'),
            new ReportSearchSelectItem('PENDING_RENEW', '更新待ち')
        ]) { }

    public clear() {
        this.domainNames = '';
        this.status = '';
    }
}

class ReportSearchSelectItem {
    public constructor(public value: string, public text: string) { }
}
