<div class="modal" id="contactCopy" modal [openFlg]="viewModel.isShowModal">
    <div class="modal-Dialog">
        <div class="modal-Dialog-Header">
            <h3 class="modal-Dialog-Header-Title">コンタクト情報をコピー</h3>
        </div>
        <div class="modal-Dialog-Body">
            <table class="tbl">
                <colgroup>
                    <col width="">
                    <col width="">
                    <col width="">
                    <col width="">
                    <col width="">
                    <col width="">
                </colgroup>
                <thead>
                    <tr>
                        <th class="text-Center">コンタクト情報名</th>
                        <th class="text-Center">登録者</th>
                        <th class="text-Center">管理担当者</th>
                        <th class="text-Center">経理担当者</th>
                        <th class="text-Center">技術担当者</th>
                        <th class="text-Center">公開連絡窓口</th>
                    </tr>
                </thead>
                <tbody *ngIf="viewModel.contactRecords.length === 0">
                    <tr>
                        <td colspan="6" class="text-Center">登録されたコンタクト情報がありません。</td>
                    </tr>
                </tbody>
                <tbody *ngIf="viewModel.contactRecords.length !== 0">
                    <tr *ngFor="let contactRecord of viewModel.contactRecords; let i = index;">
                        <td>
                            {{contactRecord.contactName}}
                        </td>
                        <td *ngFor="let contact of contactRecord.contacts" class="text-Center">
                            <ng-container *ngIf="contact.hasData">
                                <a href="javascript:void(0)" class="text-Underline gtmclick" [attr.data-gtmvalue]="'s_selected_' + contactRecord.contactName + '_' + contact.contactType" (click)="viewModel.onClickDetailSelected(contactRecord.contactName, contact.contactType)">選択</a>
                                /
                                <a href="javascript:void(0)" class="text-Underline gtmclick" [attr.data-gtmvalue]="'s_display_' + contactRecord.contactName + '_' + contact.contactType" (click)="viewModel.onClickDetailDisplay(contactRecord.contactName, contact.contactType)">詳細</a>
                            </ng-container>
                        </td>
                    </tr>
                </tbody>
            </table>
            <table *ngIf="viewModel.isShowContactView" class="tbl-Form">
                <colgroup>
                    <col width="220">
                    <col width="">
                    <col width="">
                </colgroup>
                <tbody>
                    <tr>
                        <th colspan="3" class="text-Left">{{viewModel.contactView.typeText}}</th>
                    </tr>
                    <tr>
                        <th><span class="tbl-Form-Label">種別 </span></th>
                        <td colspan="2">{{viewModel.contactView.roleText}}</td>
                    </tr>
                    <tr>
                        <th><span class="tbl-Form-Label">姓 </span></th>
                        <td>{{viewModel.contactView.lnameML}}</td>
                        <td>{{viewModel.contactView.lname}}</td>
                    </tr>
                    <tr>
                        <th><span class="tbl-Form-Label">名 </span></th>
                        <td>{{viewModel.contactView.fnameML}}</td>
                        <td>{{viewModel.contactView.fname}}</td>
                    </tr>
                    <tr *ngIf="viewModel.contactView.role === 'R'">
                        <th><span class="tbl-Form-Label">組織名 </span></th>
                        <td>{{viewModel.contactView.organizationML}}</td>
                        <td>{{viewModel.contactView.organization}}</td>
                    </tr>
                    <tr>
                        <th><span class="tbl-Form-Label">国</span></th>
                        <td colspan="2">{{viewModel.contactView.ccText}}</td>
                    </tr>
                    <tr *ngIf="viewModel.contactView.cc === 'JP'">
                        <th><span class="tbl-Form-Label">郵便番号</span></th>
                        <td colspan="2">{{viewModel.contactView.pcJp1}}-{{viewModel.contactView.pcJp2}}</td>
                    </tr>
                    <tr *ngIf="viewModel.contactView.cc !== 'JP'">
                        <th><span class="tbl-Form-Label">郵便番号</span></th>
                        <td colspan="2">{{viewModel.contactView.pc}}</td>
                    </tr>
                    <tr *ngIf="viewModel.contactView.cc === 'JP'">
                        <th><span class="tbl-Form-Label">都道府県 </span></th>
                        <td colspan="2">{{viewModel.contactView.spJpText}}</td>
                    </tr>
                    <tr *ngIf="viewModel.contactView.cc !== 'JP'">
                        <th><span class="tbl-Form-Label">都道府県</span></th>
                        <td>{{viewModel.contactView.spML}}</td>
                        <td>{{viewModel.contactView.sp}}</td>
                    </tr>
                    <tr>
                        <th><span class="tbl-Form-Label">市区</span></th>
                        <td>{{viewModel.contactView.cityML}}</td>
                        <td>{{viewModel.contactView.city}}</td>
                    </tr>
                    <tr>
                        <th><span class="tbl-Form-Label">町村</span></th>
                        <td>{{viewModel.contactView.street1TownML}}</td>
                        <td>{{viewModel.contactView.street1Town}}</td>
                    </tr>
                    <tr>
                        <th><span class="tbl-Form-Label">番地</span></th>
                        <td>{{viewModel.contactView.street1NumberML}}</td>
                        <td>{{viewModel.contactView.street1Number}}</td>
                    </tr>
                    <tr>
                        <th><span class="tbl-Form-Label">建物名</span></th>
                        <td>{{viewModel.contactView.street2ML}}</td>
                        <td>{{viewModel.contactView.street2}}</td>
                    </tr>
                    <tr>
                        <th><span class="tbl-Form-Label">電話番号</span></th>
                        <td colspan="2">{{viewModel.contactView.phone}}</td>
                    </tr>
                    <tr>
                        <th><span class="tbl-Form-Label">FAX番号</span></th>
                        <td colspan="2">{{viewModel.contactView.fax}}</td>
                    </tr>
                    <tr>
                        <th><span class="tbl-Form-Label">連絡先メールアドレス</span></th>
                        <td colspan="2">{{viewModel.contactView.email}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="modal-Dialog-Footer">
            <ul class="list-Btn is-Right mb0">
                <li>
                    <a href="javascript:void(0)" class="btn gtmclick" data-gtmvalue="s_cancel" (click)="viewModel.onClickCancel()">キャンセル</a>
                </li>
            </ul>
        </div>
    </div>
</div>