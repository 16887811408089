import { Directive, Input, forwardRef, OnInit } from '@angular/core';
import { NG_VALIDATORS, Validator, ValidatorFn, AbstractControl, Validators, FormGroup } from '@angular/forms';

const MAX_VALIDATOR: any = {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => MaxValidator),
    multi: true
};

@Directive({
    selector: '[max][formControlName],[max][formControl],[max][ngModel]',
    providers: [MAX_VALIDATOR]
})
export class MaxValidator implements Validator, OnInit {
    @Input() max: number;

    private validator: ValidatorFn;

    ngOnInit() {
        this.validator = CustomValidators.max(this.max);
    }

    validate(c: AbstractControl): { [key: string]: any } {
        return this.validator(c);
    }
}

export class CustomValidators {
    static max(max: number): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            if (isPresent(Validators.required(control))) return null;

            let v: string = control.value;
            return v.length <= max ? null : { 'max': true };
        };
    }
}

export function isPresent(obj: any): boolean {
    return obj !== undefined && obj !== null;
}