import { ComponentFactory, ComponentFactoryResolver, ViewContainerRef, Renderer2, Type } from '@angular/core';

export interface IFlexView {
    canAttach: boolean;
    attachDirective: () => void;
}

export abstract class AbstractFlexView<T> implements IFlexView {

    constructor(
        protected _factory: ComponentFactory<T>,
        protected _viewContainerRef: ViewContainerRef,
        protected _renderer: Renderer2
    ) {
    };

    public abstract get canAttach(): boolean;
    public abstract attachDirective();
}