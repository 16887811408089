import { HubsService } from 'Content/script/service/hubs_service';
import { ReceiveResultOfAccountInfo } from 'Content/script/service/tracking_service';

export class NewsService {
    private queueId: number;
    private _hubService: HubsService;

    private promise: Promise<void>;

    constructor(hubService: HubsService) {
        this._hubService = hubService;
        this.promise = null;

        this.queueId = this._hubService.multipleSendManager.GetComponentId();
    }

    public GetTotalUnreadCount(caller: string = null): Promise<void> {
        if (this.promise != null) {
            return this.promise;
        }

        var sortList: MessageByPageSortRequestDto[] = [];
        var requestData: MessageListRequestHubDto = {
            LineCnt: 0,
            PageNo: 1,
            SortList: sortList,
            LocationIds:[]
        };

        this.promise = new Promise<void>((resolva, reject) => {
            this._hubService.multipleSendManager.Send(
                this.queueId,
                this._hubService.hubsManager.topHubManager.GetTotalUnreadCount,
                requestData,
                (data: TotalUnreadCountResponseHubDto) => {
                    window.TotalUnreadCount = data.TotalUnreadCount;
                    window.ImportantTotalUnreadCount = data.ImportantUnreadCount;
                    window.ServiceTotalUnreadCount = data.ServiceUnreadCount;
                    resolva();
                },
                (data: TotalUnreadCountResponseHubDto) => {
                    ReceiveResultOfAccountInfo.error();
                    window.TotalUnreadCount = 0;
                    window.ImportantTotalUnreadCount = 0;
                    window.ServiceTotalUnreadCount = 0;
                    let val = !!caller ? caller : "Error Caller";
                    console.log("Error : location is " + val);
                    console.log(data.ErrorMessage);
                    reject(Service.ErrorType.Error);
                },
                (data: TotalUnreadCountResponseHubDto) => {
                    ReceiveResultOfAccountInfo.error();
                    window.TotalUnreadCount = 0;
                    window.ImportantTotalUnreadCount = 0;
                    window.ServiceTotalUnreadCount = 0;
                    let val = !!caller ? caller : "NoSession Caller";
                    console.log("NoSession : location is " + val);
                    console.log(data.ErrorMessage);
                    reject(Service.ErrorType.NoSession);
                },
                null,
                (data: TotalUnreadCountResponseHubDto) => {
                    ReceiveResultOfAccountInfo.error();
                    window.TotalUnreadCount = 0;
                    window.ImportantTotalUnreadCount = 0;
                    window.ServiceTotalUnreadCount = 0;
                    let val = !!caller ? caller : "Unexpected Caller";
                    console.log("UnExcepted : location is " + val);
                    console.log(data.ErrorMessage);
                    reject(Service.ErrorType.UnExcepted);
                }
            );
        });

        return this.promise;
    }

    public Reset(): void {
        if (this.promise != void (0) && this.promise != null) {
            this.promise.then(this._deletePromise).catch(this._deletePromise);
        }
    }

    private _deletePromise = (): void => {
        this.promise = null;
    }
}


export namespace Service {
    export enum ErrorType {
        Error,
        NoSession,
        UnExcepted
    }
}
