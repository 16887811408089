import { createAction, props } from '@ngrx/store';
import { DomainWithDefaultNameServerState } from '../states/domain-with-defaultname-server';

export const setDomainWithDefaultNameServer = createAction(
    '[DomainWithDefaultNameServer] Set DomainWithDefaultNameServerState',
    props<DomainWithDefaultNameServerState>()
);

export const resetDomainWithDefaultNameServer = createAction(
    '[DomainWithDefaultNameServer] Reset DomainWithDefaultNameServerState',
);
