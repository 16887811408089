import { HubsService } from 'Content/script/service/hubs_service.js';
import { SystemComponentId } from 'Content/script/managers/MultipleSendManager';
import { ReceiveResultOfAccountInfo } from 'Content/script/service/tracking_service';

export class AccountInfoService
{
    private _accountInfo: Service.AccountInfo;

    private _hubService: HubsService;

    private promise: Promise<Service.AccountInfo>;

    private _callback: (result: boolean, data: AccountLayoutDataResponseHubDto, errorType?: Service.ErrorType) => void;

    constructor(hubService: HubsService)
    {
        this._accountInfo = new Service.AccountInfo();
        this._hubService = hubService;
        this.promise = null;
        this._callback = null;
    }

    public GetAccountInfo(caller: string = null): Promise<Service.AccountInfo>
    {
        if (this.promise != null)
        {
            return this.promise;
        }

        this.promise = new Promise<Service.AccountInfo>((resolva, reject) => {
            this._hubService.multipleSendManager.Send(
                SystemComponentId.ServiceAccountInfo,
                this._hubService.hubsManager.accountHubManager.AccountLayoutData,
                null,
                (data: AccountLayoutDataResponseHubDto) => {
                    window.IsDomainNewsFlg = data.IsDomainNewsFlg;
                    window.IsDomainNewsTarget = data.IsDomainNewsTarget;
                    window.ProxyId = data.ProxyId;
                    window.OnamaeShopId = data.ShopId;
                    window.HostingShopId = data.HostingShopId;

                    if (this._callback) {
                        this._callback(true, data);
                    }

                    var accountInfo = this.mappingAccountInfo(data);

                    ReceiveResultOfAccountInfo.ok();
                    resolva(accountInfo);
                },
                (data: AccountLayoutDataResponseHubDto) => {
                    if (this._callback) {
                        this._callback(false, data, Service.ErrorType.Error);
                    }
                    ReceiveResultOfAccountInfo.error();
                    let val = !!caller ? caller : "Error Caller";
                    console.log("Error : location is " + val);
                    console.log(data.ErrorMessage);
                    reject(Service.ErrorType.Error);
                },
                (data: AccountLayoutDataResponseHubDto) => {
                    if (this._callback) {
                        this._callback(false, data, Service.ErrorType.NoSession);
                    }
                    ReceiveResultOfAccountInfo.error();
                    let val = !!caller ? caller : "NoSession Caller";
                    console.log("NoSession : location is " + val);
                    console.log(data.ErrorMessage);
                    reject(Service.ErrorType.NoSession);
                },
                null,
                (data: AccountLayoutDataResponseHubDto) => {
                    if (this._callback) {
                        this._callback(false, data, Service.ErrorType.UnExcepted);
                    }
                    ReceiveResultOfAccountInfo.error();
                    let val = !!caller ? caller : "Unexpected Caller";
                    console.log("UnExcepted : location is " + val);
                    console.log(data);
                    reject(Service.ErrorType.UnExcepted);
                }
            );
        });

        return this.promise; 
    }

    public Reset(): void {
        if(this.promise != void(0) && this.promise != null)
        {
            this.promise.then(this._deletePromise).catch(this._deletePromise);
        }
    }

    public bindCallback(callback: (result: boolean, data: AccountLayoutDataResponseHubDto, errorType: Service.ErrorType) => void) {
        this._callback = callback;
    }

    private _deletePromise = (): void =>  {
        this.promise = null;
    }

    public mappingAccountInfo(data: AccountLayoutDataResponseHubDto): Service.AccountInfo {
        return {
            LaginName: data.LoginName,
            Email: data.Email,
            ViewHiddenInvoice: data.ViewHiddenInvoice,
            IsChangePassword: data.IsChangePassword,
            IsTmpRegist: data.IsTmpRegist,
            ElectricFlg: data.ElectricFlg,
            ProxyId: data.ProxyId,
            HasDomain: data.HasDomain,
            RoleId: data.RoleId,
            IsSurchargeTarget: data.IsSurchargeTarget,
            Cc: data.Cc,
            ShowSitemapCreate: data.ShowSitemapCreate,
            ShowSitemapPremiumCreate: data.ShowSitemapPremiumCreate,
            ShowSitemapGtldAuction: data.ShowSitemapGtldAuction,
            ShowSitemapJpAuction: data.ShowSitemapJpAuction,
            ShowSitemapBulkApplicationList: data.ShowSitemapBulkApplicationList,
            ShowSitemapGtldBackorder: data.ShowSitemapGtldBackorder,
            ShowSitemapJpBackorder: data.ShowSitemapJpBackorder,
            ShowSitemapMonitoring: data.ShowSitemapMonitoring,
            ShowSitemapJpCreateCorrection: data.ShowSitemapJpCreateCorrection,
            ShowSitemapCojpRegistration: data.ShowSitemapCojpRegistration,
            ShowSitemapTransferIn: data.ShowSitemapTransferIn,
            ShowDomainTransferStatusList: data.ShowDomainTransferStatusList,
            ShowSitemapTransferLock: data.ShowSitemapTransferLock,
            ShowSitemapNsChange: data.ShowSitemapNsChange,
            ShowSitemapDnsSetting: data.ShowSitemapDnsSetting,
            ShowSitemapDsRecord: data.ShowSitemapDsRecord,
            ShowSitemapWhoisInfoChange: data.ShowSitemapWhoisInfoChange,
            ShowSitemapWhoisProxy: data.ShowSitemapWhoisProxy,
            ShowSitemapWhoisMailForward: data.ShowSitemapWhoisMailForward,
            ShowSitemapJpInfoChange: data.ShowSitemapJpInfoChange,
            ShowSitemapTmch: data.ShowSitemapTmch,
            ShowSitemapIdReplace: data.ShowSitemapIdReplace,
            ShowSitemapDomainProtectInput: data.ShowSitemapDomainProtectInput,
            ShowSitemapDomainProtectUpdateInput: data.ShowSitemapDomainProtectUpdateInput,
            ShowSitemapDomainEns: data.ShowSitemapDomainEns,
            ShowSitemapMailForward: data.ShowSitemapMailForward,
            ShowSitemapUrlForward: data.ShowSitemapUrlForward,
            ShowSitemapSetting_Ssl: data.ShowSitemapSetting_Ssl,
            ShowSitemapSslManage: data.ShowSitemapSslManage,
            ShowSitemapPaymentInfoDomain: data.ShowSitemapPaymentInfoDomain,
            ShowSitemapEvidence: data.ShowSitemapEvidence,
            ShowSitemapDelService: data.ShowSitemapDelService,
            ShowSitemapDelServiceReport: data.ShowSitemapDelServiceReport
        } as Service.AccountInfo;
    }
}


export namespace Service
{
    export class AccountInfo
    {
        public LaginName: string;
        public Email: string;
        public ViewHiddenInvoice: boolean;
        public IsChangePassword: boolean;
        public IsTmpRegist: boolean;
        public ElectricFlg: string;
        public ProxyId: string;
        public HasDomain: boolean;
        public RoleId: string;
        public IsSurchargeTarget: boolean;
        public Cc: string;
        public ShowSitemapCreate: boolean;
        public ShowSitemapPremiumCreate: boolean;
        public ShowSitemapGtldAuction: boolean;
        public ShowSitemapJpAuction: boolean;
        public ShowSitemapBulkApplicationList: boolean;
        public ShowSitemapGtldBackorder: boolean;
        public ShowSitemapJpBackorder: boolean;
        public ShowSitemapMonitoring: boolean;
        public ShowSitemapJpCreateCorrection: boolean;
        public ShowSitemapCojpRegistration: boolean;
        public ShowSitemapTransferIn: boolean;
        public ShowDomainTransferStatusList: boolean;
        public ShowSitemapTransferLock: boolean;
        public ShowSitemapNsChange: boolean;
        public ShowSitemapDnsSetting: boolean;
        public ShowSitemapDsRecord: boolean;
        public ShowSitemapWhoisInfoChange: boolean;
        public ShowSitemapWhoisProxy: boolean;
        public ShowSitemapWhoisMailForward: boolean;
        public ShowSitemapJpInfoChange: boolean;
        public ShowSitemapTmch: boolean;
        public ShowSitemapIdReplace: boolean;
        public ShowSitemapDomainProtectInput: boolean;
        public ShowSitemapDomainProtectUpdateInput: boolean;
        public ShowSitemapDomainEns: boolean;
        public ShowSitemapMailForward: boolean;
        public ShowSitemapUrlForward: boolean;
        public ShowSitemapSetting_Ssl: boolean;
        public ShowSitemapSslManage: boolean;
        public ShowSitemapPaymentInfoDomain: boolean;
        public ShowSitemapEvidence: boolean;
        public ShowSitemapDelService: boolean;
        public ShowSitemapDelServiceReport: boolean;

        constructor()
        {
            this.ViewHiddenInvoice = true;
            this.LaginName = '';
            this.ProxyId = '';
        }
    }

    export enum ErrorType
    {
        Error,
        NoSession,
        UnExcepted
    }
}
