<ul class="nav-Breadcrumb">
  <li><a href="javascript:void(0)" [onamaeRouterLink]="'/domain'">ドメイン</a></li>
  <li><a href="javascript:void(0)" [onamaeRouterLink]="'/domain/setting'">ドメイン機能一覧</a></li>
  <li>「セット割」申請管理 一覧</li>
</ul><!-- /.nav-Breadcrumb -->

<div class="nav-Group mb0">
  <h1 class="hdn-Lev1 mb0 pt8">「セット割」申請管理 一覧</h1>
  <h2 class="hdn-Lev3 mt20 mb0">申請内容</h2>
  <div class="nav-Group mt20">
    <div class="nav-Group-Left">
      <bulk-order-search-dropdown #bulkOrderSearchDropdownComponent></bulk-order-search-dropdown>
    </div>
    <div class="nav-Group-Right" *ngIf="model.bulkOrderList.length > 0 && !model.isLoading">
      <dl class="list-DefInline mt2">
        <dt>表示件数</dt>
        <dd>
          <span class="form-Select is-Auto">
            <select class="gtmclick" name="pageSizeList" (change)="model.onChangePageSize($event)">
              <option *ngFor="let pageSize of model.pageSizeList" [selected]="pageSize === model.pageSize"
                      value="{{pageSize}}">
                {{pageSize}}
              </option>
            </select>
          </span><!-- /.form-Select -->
        </dd>
      </dl>
    </div>
  </div>
  <table class="tbl-Striped mt12 text-NoWrap">
    <colgroup>
      <col width="129">
      <col width="141">
      <col width="154">
      <col width="155" *ngIf="model.roleId == 2">
      <col width="113">
      <col width="192">
      <col width="132">
    </colgroup>
    <thead>
      <tr>
        <th class="vertical-middle pl16 pr16 pt8 pb8text-Left">
          申請番号
          <button *ngIf="model.totalCount > 1" type="button" class="btn-Icon ml8" [class.is-DESC]="model.sortList['bulkOrderId'] === 1"
                  (click)="model.onSortClick('bulkOrderId')">
            <i class="icon icon-DropDown"></i>
          </button>
        </th>
        <th class="vertical-middle pl16 pr16 pt8 pb8">
          申請状況
          <button *ngIf="model.totalCount > 1" type="button" class="btn-Icon ml8" [class.is-DESC]="model.sortList['status'] === 1"
                  (click)="model.onSortClick('status')">
            <i class="icon icon-DropDown"></i>
          </button>
        </th>
        <th class="vertical-middle pl16 pr16 pt8 pb8">
          決済種別
          <button *ngIf="model.totalCount > 1" type="button" class="btn-Icon ml8" [class.is-DESC]="model.sortList['paymentType'] === 1"
                  (click)="model.onSortClick('paymentType')">
            <i class="icon icon-DropDown"></i>
          </button>
        </th>
        <th class="vertical-middle pl16 pr16 pt8 pb8" *ngIf="model.roleId == 2">
          金額
        </th>
        <th class="vertical-middle pl16 pr16 pt8 pb8">
          件数
        </th>
        <th class="vertical-middle pl16 pr16 pt8 pb8">
          申請日時
          <button *ngIf="model.totalCount > 1" type="button" class="btn-Icon ml8" [class.is-DESC]="model.sortList['createdDate'] === 1"
                  (click)="model.onSortClick('createdDate')">
            <i class="icon icon-DropDown"></i>
          </button>
        </th>
        <th class="vertical-middle pl16 pr16 pt8 pb8">
          申請/詳細
        </th>
      </tr>
    </thead>
    <tbody *ngIf="model.bulkOrderList.length === 0 && !model.isLoading">
      <tr>
        <td colspan="7" class="text-Center">該当アカウントのデータはありません。</td>
      </tr>
    </tbody>
    <tbody *ngIf="model.bulkOrderList.length > 0">
      <tr *ngFor="let bulkOrder of model.bulkOrderList">
        <td class="vertical-middle pl16 pr16 pt8 pb8">{{bulkOrder.bulkOrderId}}</td>
        <td [ngSwitch]="bulkOrder.status" class="vertical-middle pl16 pr16 pt8 pb8">
          <a class="text-Em" *ngSwitchCase="'AUTH_FAIL'" href="javascript:void(0)" (click)="model.submitAction('change',bulkOrder.bulkOrderId)">
            {{bulkOrder.statusStr}}
          </a>
          <a class="text-Em" *ngSwitchCase="'BILLING_FAIL'" href="javascript:void(0)" [onamaeRouterLink]="'/payment/invoice/list/domain'">
            {{bulkOrder.statusStr}}
          </a>
          <ng-container *ngSwitchDefault >
            <span [class.text-Em]="bulkOrder.status =='PAYMENT_WAIT' || bulkOrder.status == 'TEMP_SUCCESS' || bulkOrder.status=='ERROR'">{{bulkOrder.statusStr}}</span>
          </ng-container>
        </td>
        <td class="vertical-middle pl16 pr16 pt8 pb8">{{bulkOrder.paymentTypeStr}}</td>
        <td class="vertical-middle pl16 pr16 pt8 pb8" *ngIf="model.roleId == 2">{{bulkOrder.totalDiscountedTaxedValue}}円</td>
        <td class="vertical-middle pl16 pr16 pt8 pb8">{{bulkOrder.sumDomain}}件</td>
        <td class="vertical-middle pl16 pr16 pt8 pb8">{{bulkOrder.createdDate}}</td>
        <td class="vertical-middle pl16 pr16 pt8 pb8">
          <ng-container *ngIf="bulkOrder.status === 'TEMP_SUCCESS'">
            <button type="button" class="btn is-Small" (click)="model.submitAction('recreate',bulkOrder.bulkOrderId)">申請</button>
          </ng-container>
          <ng-container *ngIf="bulkOrder.isShowButtonDetail">
            <button type="button" class="btn is-Small is-Primary" (click)="model.submitAction('detail',bulkOrder.bulkOrderId)">詳細</button>
          </ng-container>
        </td>
      </tr>
    </tbody>
  </table>
  <ul *ngIf="model.maxPage > 1" class="nav-Pagination mt8">
    <li>
      <a href="javascript:void(0)" class="gtmclick" [class.is-Disabled]="model.currentPage === 1"
         (click)="model.onClickPageNo(model.currentPage - 1)"><i class="icon icon-ArrowLeft"></i>前</a>
    </li>
    <li *ngFor="let pageNo of model.pageNoList">
      <a href="javascript:void(0)" class="gtmclick" (click)="model.onClickPageNo(pageNo)"
         [class.is-Current]="pageNo == model.currentPage">{{pageNo}}</a>
    </li>
    <li>
      <a href="javascript:void(0)" class="gtmclick" [class.is-Disabled]="model.currentPage === model.maxPage"
         (click)="model.onClickPageNo(model.currentPage + 1)">次<i class="icon icon-ArrowRight"></i></a>
    </li>
  </ul>
</div>

<loading [loading]="model.isLoading" [entireLoading]="model.isAllLoading"></loading>
