import { Component, Output, EventEmitter, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceContainer } from 'Content/script/service/service_container';
import { IPageTracking } from '../../../../service/tracking_service';

@Component({
    selector: 'domain-detail-abolition-deterrence-modal',
    templateUrl: './domain_detail_abolition_deterrence_modal.html'
})

export class DomainDetailAbolitionDeterrenceModalComponent{
    private _trackingService: IPageTracking;
    public queueId: number;

    @Output() onClickAbolitionClose = new EventEmitter<any>();
    @Output() onCloseAbolitionModalWithoutShowAuthCode = new EventEmitter<any>();
    @Output() noSessionEvent = new EventEmitter<any>();
    @Output() onErrorEvent = new EventEmitter<any>();
    @Output() onUnExceptedErrorEvent = new EventEmitter<any>();
    @Output() onClickToRedirectToDomainNavi = new EventEmitter<any>();

    @Input() public isShowAbolitionModal: boolean = false;
    @Input() public domainId: string;

    public constructor(
        private router: Router,
        private route: ActivatedRoute,
        private serviceContainer: ServiceContainer
    ) {
        this.queueId = this.serviceContainer.HubsService.multipleSendManager.GetComponentId();
        this._trackingService = this.serviceContainer.TrackingService.provideServiceForModalComponent().create('DomainDetailModalComponent');

    }

    public onClickClose(): void {
        this.onClickAbolitionClose.emit();
        this.isShowAbolitionModal = false;
    }

    public onClickOutsideCloseModal(event: Event) {
        if (event.currentTarget === event.target) {
            this.onCloseAbolitionModalWithoutShowAuthCode.emit();
            this.isShowAbolitionModal = false;
        }
    }

    public onClickRedirectToDomainNavi(): void {
        this.onClickToRedirectToDomainNavi.emit();
    }
}
