import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ServiceContainer } from 'Content/script/service/service_container';
import { QuestionnaireInfo, QuestionnaireOptionHasImage, QuestionnairePage, QuestionnaireType } from '../../../top/index/top_enquete_popup/top_enquete_popup.component';
import { IPageTracking } from '../../../../service/tracking_service';
import { NgForm, NgModel } from '@angular/forms';
import { NotifyMessage, NotifyType } from 'Content/script/service/notify_service';


/**
 * ネットde診断のアンケートポップアップ
 */
@Component({
    selector: 'domain-net-de-shindan-enquete-popup',
    templateUrl: './domain_net_de_shindan_enquete_popup.html',
})

export class DomainNetDeShindanEnquetePopupComponent implements OnChanges {
    private _trackingService: IPageTracking;
    public isLoading: boolean;
    public queueId: number;
    public questionnaireInfo: QuestionnaireInfo;
    public pages: QuestionnairePage[];
    private _isShowModalSurvey: boolean = false;
    public hasSurveyData: boolean;
    public validateOtherList: string[] = [];
    public answeredList: boolean[] = [];
    public isErrorValidate: boolean = false;

    @Output() onCloseNetDeShindanEnquetePopup = new EventEmitter<any>();
    @Input() public domainId: string;
    @Input()
    public get isShowModalSurvey(): boolean {
        return this._isShowModalSurvey;
    }
    public set isShowModalSurvey(v: boolean) {
        this._isShowModalSurvey = v;
        if (this._isShowModalSurvey) {
            this._trackingService.start();
        } else {
            this._trackingService.dispose();
        }
    }

    public get isShowButtonNext(): boolean {
        return this.answeredList.filter(x => x === true).length === 13;
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.isShowModalSurvey.currentValue) {
            this._requestGetQuestionnaireData();
        }
    }

    /**
     * コンストラクタ
     */
    public constructor(private serviceContainer: ServiceContainer) {
        this.queueId = this.serviceContainer.HubsService.multipleSendManager.GetComponentId();
        this._trackingService = this.serviceContainer.TrackingService.provideServiceForModalComponent().create('DomainNetDeShindanEnquetePopupComponent');
    }

    /**
     * アンケートデータ取得
     */
    private _requestGetQuestionnaireData(): void {
        this.isLoading = true;
        let pattern: number = 0;

        var answerReqHub: QuestionnaireAnswerAllServicesRequestHubDto = {
            AnswerHubList: [],
            UserAgent: window.navigator.userAgent.toLowerCase(),
            Pattern: pattern,
            Screen: "NetDeShindan_Questionnaire",
        };
        this.serviceContainer.HubsService.multipleSendManager.Send(
            this.queueId,
            this.serviceContainer.HubsService.hubsManager.questionnaireHubManager.GetQuestionnaireInfoAllServices,
            answerReqHub,
            (resHub: QuestionnaireInfoAllServicesResponseHubDto) => {
                var pageNo = 0;
                var questionnaireArr: QuestionnairePage[] = [];
                resHub.QuestionnaireHubList.map(x => {
                    var questionnaire: QuestionnairePage = new QuestionnairePage();
                    questionnaire.id = x.Id;
                    questionnaire.content = x.Content;
                    questionnaire.order = x.Order;
                    questionnaire.questionNo = x.QuestionNo;
                    questionnaire.requiredFlg = x.RequiredFlg;
                    questionnaire.type = x.Type;

                    x.OptionHubList.map(y => {
                        var option: QuestionnaireOptionHasImage = new QuestionnaireOptionHasImage();
                        option.id = y.Id;
                        option.content = y.Content;
                        option.order = y.Order;
                        questionnaire.options.push(option);
                    });
                    if (questionnaire.type == QuestionnaireType.RadioButtonWithOther) {
                        var option: QuestionnaireOptionHasImage = new QuestionnaireOptionHasImage();
                        option.id = questionnaire.options[questionnaire.options.length - 1].id + 1;
                        option.content = 'その他';
                        option.order = questionnaire.options.length;
                        option.isOther = true;
                        questionnaire.options.push(option);
                    }
                    if (questionnaire.type == QuestionnaireType.CheckBoxWithOther) {
                        var option: QuestionnaireOptionHasImage = new QuestionnaireOptionHasImage();
                        option.id = questionnaire.options[questionnaire.options.length - 1].id + 1;
                        option.content = 'その他';
                        option.order = questionnaire.options.length;
                        option.isOther = true;
                        questionnaire.options.push(option);
                    }
                    questionnaire.pageNo = pageNo++;
                    questionnaireArr.push(questionnaire);
                });

                questionnaireArr[0].isShow = true;

                //最後のページ（アンケートではない）
                var lastPage = new QuestionnairePage();
                lastPage.type = QuestionnaireType.NotQuestionnaire;
                lastPage.pageNo = pageNo++;
                questionnaireArr.push(lastPage);

                this.questionnaireInfo = new QuestionnaireInfo(questionnaireArr);
                this.questionnaireInfo.title = resHub.Title;
                this.pages = this.questionnaireInfo.questionnaires;
                this.validateOtherList = new Array(this.pages.length).fill('');
                this.answeredList = new Array(this.pages.length).fill(false);
                this.isLoading = false;
            },
            null,
            null,
            null,
            null
        );
    }

    /**
     * アンケートモーダルを閉じる
     */
    public onClickClose(): void {
        this.onCloseNetDeShindanEnquetePopup.emit();
    }

    /**
     *　ラジオボタン押下時
     */
    public onChangeRadio(page: QuestionnairePage, optionId: number, index: number) {
        this.answeredList[index] = true;
        this.pages.find(x => x.id === page.id).selecltedId = optionId;
        if (this.pages.length > 0) {
            let numberRadioChecked = 0;
            this.pages[index].options.forEach((x, i) => {
                if (x.id === this.pages[index].selecltedId) {
                    numberRadioChecked++;
                    if (x.isOther) {
                        this.validateOtherList[index] = 'Yes';
                    } else {
                        this.validateOtherList[index] = 'No';
                    }
                }
            });
        }
    }

    public onChangeRadioEither(index: number, required:number) {
        if (required === 1) {
            this.answeredList[index] = true;
        }
    }

    public onChangeInput(index: number) {
        this.answeredList[index] = true;
    }

    public onChangeCheckBox(index: number) {
        let numberCheckBoxChecked: number = 0;
        this.answeredList[index] = true;
        this.pages[index].options.forEach(x => {
            if (x.isCheck) {
                numberCheckBoxChecked++;
                if (x.isOther) {
                    this.validateOtherList[index] = 'Yes';
                } else {
                    this.validateOtherList[index] = 'No';
                }
            }
        });
    }

    /**
     * アンケートデータ送信
     */
    public onClickFinishSurvey(form: NgForm): void {
        this.transmitSurvey(form);
        if (!this.isErrorValidate) {
            this.onCloseNetDeShindanEnquetePopup.emit();
            
        }
    }

    public transmitSurvey(form: NgForm) {
        if (!form.valid && this.validateOtherList.filter(x=>x === 'Yes')) {
            for (var key in form.controls) {
                form.controls[key].markAsDirty();
            }
            this.isErrorValidate = true;
            return;
        }
        if (!this.isShowButtonNext) {
            this.isErrorValidate = true;
            return;
        }
        this.isErrorValidate = false;
        var answerReqHub: QuestionnaireAnswerAllServicesRequestHubDto = {
            AnswerHubList: [],
            UserAgent: window.navigator.userAgent.toLowerCase(),
            Pattern: 0,
            Screen: "NetDeShindan_Questionnaire"
        };
        var answerList = this._getAnswerHubList();
        if (answerList != null && answerList != undefined) {
            answerReqHub.AnswerHubList = answerList;
            this._transmit(answerReqHub);

        }
    }

    private _transmit(requestHubDto: QuestionnaireAnswerAllServicesRequestHubDto) {
        this.serviceContainer.HubsService.multipleSendManager.Send(
            this.queueId,
            this.serviceContainer.HubsService.hubsManager.questionnaireHubManager.AnswerQuestionnaireAllServices,
            requestHubDto,
            (answerResHub: QuestionnaireAnswerAllServicesResponseHubDto) => {
                var notify: NotifyMessage = new NotifyMessage(this.serviceContainer.NotifyService.GetNotifyId(), NotifyType.Info, "アンケートにご協力いただき、誠にありがとうございました。");
                this.serviceContainer.NotifyService.AddNotifyMessages([notify]);	
            },
            null,
            null,
            null,
            null
        );
    }

    private _getAnswerHubList(): QuestionnaireAnswerHubDto[] {
        var answerHubList: QuestionnaireAnswerHubDto[] = [];
        var answer: QuestionnaireAnswerHubDto;
        for (var index = 0; index < this.pages.length; index++) {
            var page = this.pages[index];
            switch (page.type) {
                case QuestionnaireType.CheckBox:
                case QuestionnaireType.CheckBoxWithOther:
                    for (let option of page.options) {
                        if (option.isCheck) {
                            if (option.isOther) {
                                answer = {
                                    QuestionId: page.id,
                                    Type: page.type,
                                    Option: '',
                                    Other: page.otherText,
                                };
                            } else {
                                answer = {
                                    QuestionId: page.id,
                                    Type: page.type,
                                    Option: (option.id === 0) ? option.content : option.id.toString(),
                                    Other: 'no_other',
                                };
                            }
                            answerHubList.push(answer);
                        }
                    }
                    break;

                case QuestionnaireType.RadioButton:
                case QuestionnaireType.RadioButtonWithOther:
                    for (let option of page.options) {
                        if (option.id === page.selecltedId) {
                            if (option.isOther) {
                                answer = {
                                    QuestionId: page.id,
                                    Type: page.type,
                                    Option: '',
                                    Other: page.otherText,
                                };
                            } else {
                                answer = {
                                    QuestionId: page.id,
                                    Type: page.type,
                                    Option: (option.id === 0) ? option.content : option.id.toString(),
                                    Other: 'no_other',
                                };
                            }
                            answerHubList.push(answer);
                        }
                    }
                    break;

                case QuestionnaireType.Text:
                    var option = page.text;
                    if (option) {
                        answer = {
                            QuestionId: page.id,
                            Type: page.type,
                            Option: option,
                            Other: 'no_other',
                        }
                        answerHubList.push(answer);
                    }
                    break;

                case QuestionnaireType.Textarea:
                    if (page.textarea.length > 0) {
                        answer = {
                            QuestionId: page.id,
                            Type: page.type,
                            Option: page.textarea.replace(/<("[^"]*"|'[^']*'|[^'">])*>/g, '\\n'),
                            Other: 'no_other',
                        }
                        answerHubList.push(answer);
                    }
                    break;
                case QuestionnaireType.Either:
                    answer = {
                        QuestionId: page.id,
                        Type: page.type,
                        Option: page.isEitherSelect === '1' ? "はい" : "いいえ",
                        Other: 'no_other',
                    }
                    answerHubList.push(answer);
                    break;
            }
        }
        return answerHubList;
    }

    private getAnswerOptionContent(content: string): string {
        if (this.isInternalQuestion(content)) {
            let target = content.replace('[internal:', '').replace(new RegExp('\].*'), '');
            switch (target) {
                case 'domainId':
                    return this.domainId;
                default:
                    return "";
            }
        } else {
            return "";
        }
    }

    /**
     * 画面上で表示させず、コード内で値をセットする項目であるか
     * @param content
     */
    public isInternalQuestion(content: string): boolean {
        return RegExp('\\[internal:.*\\].*').test(content);
    }
}
