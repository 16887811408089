import { Component, Input } from '@angular/core';

@Component({
    selector: 'head-notification',
    template: `
<div class="head-Notification" [class.has-GlobalSideNavi]="hasGlobalSideNavi">
    <div class="head-Notification-Inner">
        <ng-content></ng-content>
    </div>
</div>
`
})

export class HeadNotificationComponent {
    @Input() public hasGlobalSideNavi: boolean;
    
}
