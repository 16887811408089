import { Injectable, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

import { HeaderComponentService } from 'Content/script/service/header_navigation_service';
import { LayoutService } from 'Content/script/service/layout_service';
import { DomainCartService } from 'Content/script/service/domain_cart_service';
import { NewsService } from 'Content/script/service/news_service';
import { ABCookieService } from 'Content/script/service/ab_cookie_service';
import { ModalForDomainRenewService } from 'Content/script/service/modal_for_domain_renew_service';
import { NPSEnqueteService } from 'Content/script/service/nps_enquete_service';
import { DomainWhoisService } from 'Content/script/service/domain_whois_service';
import { BaseServiceContainer } from 'Content/common/script/service/base_service_container';
import { VIEWS } from '../store/states/view';
import { Store } from '@ngrx/store';

@Injectable()
export class ServiceContainer extends BaseServiceContainer {
    public constructor(ngZone: NgZone, http: HttpClient, router: Router, location: Location, store: Store) {
        super(ngZone, http, router, location, store);

        this.ABCookieService = new ABCookieService(http);
        this.HeaderComponentService = new HeaderComponentService();
        this.LayoutService = new LayoutService();
        this.DomainCartService = new DomainCartService(this.HubsService);
        this.ModalForDomainRenewService = new ModalForDomainRenewService();
        this.NPSEnqueteService = new NPSEnqueteService(this.HubsService);
        this.DomainWhoisService = new DomainWhoisService(http);
        this.NewsService = new NewsService(this.HubsService);
    }

    public GetViewMode() {
        return VIEWS.PC;
    }
}
