<header class="header js-header has-GlobalSideNavi is-Tm58776" [class.has-HeadNotification]="hasHeadNotification" role="banner">
    <div class="header-Bar">
        <div class="header-Logo2">
            <ng-container *ngIf="!isDisplayGlobalNavi">
                <a href="javascript:void(0)" [onamaeRouterLink]="'/top'" class="gtmclick" data-gtmvalue="header_onamaetop">
                    <img class="gtmclick" data-gtmvalue="header_onamaetop" src="{{CacheBusting('/Content/images/common/logo.svg')}}" alt="ドメインとるならお名前.com by GMO">
                </a>
                <span class="header-Logo2-Text">お名前.com Navi</span>
            </ng-container>
            <ng-container *ngIf="isDisplayGlobalNavi">
                <a href="javascript:void(0)" [onamaeRouterLink]="'/top'" class="gtmclick" data-gtmvalue="header_onamaetop">
                    <div class="header-Logo2-Img" aria-label="ドメインとるならお名前.com by GMO"></div>
                </a>
                <span class="header-Logo2-Text">
                    <span class="size-13 text-Black text-weight-normal">契約管理画面</span>
                    <span class="size-13 text-Black">&nbsp;｜&nbsp;</span>
                    <span>お名前.com Navi</span>
                </span>
            </ng-container>
        </div>
        <ul class="header-Function">
            <ng-container *ngIf="isDisplayGlobalNavi">
                <li class="header-Function-Item">
                    <p class="size-14 mb0 mr6">お名前ID：{{headerViewModel.onamaeId}} [<a href="javascript:void(0)" (click)="headerViewModel.clickLogoutConfirm()">ログアウト</a>]</p>
                    <div class="header-Function-Wrap">
                        <div class="form-TextWithIcon is-SearchInNavi" absolute-dropdown target="#globalSideNaviSearch" adjust-position="n">
                            <button type="button" class="form-TextWithIcon-Btn" id="searchBtn"><i class="icon icon-Magnifier"></i></button>
                            <input type="text" class="form-Text" id="searchInput" (keyup)="keyupSearch($event)" placeholder="キーワードからNavi内を検索" [(ngModel)]="headerViewModel.searchInputWord">
                        </div>
                        <div class="dropdown is-HeaderFunctionSearch" id="globalSideNaviSearch">
                            <div class="dropdown-Main">
                                <p *ngIf="headerViewModel.searchInputWord == ''" class="dropdown-Hdn mb0">キーワードを入力してください。</p>
                                <p *ngIf="headerViewModel.searchInputWord != ''" class="dropdown-Hdn">お名前.com Navi 内の検索結果</p>
                                <p *ngIf="headerViewModel.isNoSuggestResult" class="mb0 mt16">「<b>{{headerViewModel.searchInputWord}}</b>」を含む検索結果はありません。</p>
                                <ul class="mt16 is-ScrollY">
                                    <li *ngFor="let suggest of headerViewModel.showSuggests">
                                        <i class="icon icon-ArrowCircleRight"></i><a href="javascript:void(0)" (click)="headerViewModel.onClickSuggestLinkTransitionPage(suggest, false)">{{suggest.name}}</a>
                                    </li>
                                </ul>
                                <button *ngIf="headerViewModel.isShowAllSuggestBtn" type="button" class="btn is-Block" (click)="headerViewModel.onClickShowAllSuggest()">すべて表示</button>
                            </div>
                            <div *ngIf="headerViewModel.showSuggests.length == 0 || headerViewModel.showHelpPageSuggests.length > 0" class="dropdown-Footer">
                                <ng-container *ngIf="headerViewModel.showSuggests.length == 0">
                                    <p class="hdn-HorizontalLine mb0">よく検索されているキーワード</p>
                                    <ul class="list is-Btn">
                                        <li><button type="button" class="btn is-Small" (click)="headerViewModel.onClickFrequentlySearchWord('ネームサーバー')">ネームサーバー</button></li>
                                        <li><button type="button" class="btn is-Small" (click)="headerViewModel.onClickFrequentlySearchWord('DNS')">DNS</button></li>
                                        <li><button type="button" class="btn is-Small" (click)="headerViewModel.onClickFrequentlySearchWord('Whois')">Whois</button></li>
                                        <li><button type="button" class="btn is-Small" (click)="headerViewModel.onClickFrequentlySearchWord('メール')">メール</button></li>
                                        <li><button type="button" class="btn is-Small" (click)="headerViewModel.onClickFrequentlySearchWord('請求')">請求</button></li>
                                    </ul>
                                </ng-container>
                                <ng-container *ngIf="headerViewModel.showHelpPageSuggests.length > 0">
                                    <p class="hdn-HorizontalLine mt24">よくあるご質問</p>
                                    <ul class="list is-Text mt12">
                                        <li *ngFor="let helpSuggest of headerViewModel.showHelpPageSuggests">
                                            <b>Q：</b><a href="javascript:void(0)" (click)="headerViewModel.onClickSuggestLinkTransitionPage(helpSuggest, true)">{{helpSuggest.name}}</a>
                                        </li>
                                    </ul>
                                </ng-container>
                            </div>
                        </div>
                    </div>

                    <div class="header-Function-Wrap" *ngIf="!isABNew_HeaderInfoDropDownMenu">
                        <a class="header-Function-Button" href="javascript:void(0)" absolute-dropdown target="#globalSideNaviInfo" close="a" adjust-position="n">
                            <img class="header-Function-Button-Icon" src="{{CacheBusting('/Content/images/common/header-Function-Button-Info-icon.svg')}}" width="16" height="16" alt="">
                            お知らせ
                            <span class="badge" *ngIf="headerViewModel.totalUnreadCount > 0">{{headerViewModel.totalUnreadCount}}</span>
                            <i class="icon icon-ArrowDown"></i>
                        </a>
                        <div class="dropdown is-HeaderFunctionInfo" id="globalSideNaviInfo">
                            <div class="dropdown-Function-Header">
                                <p>お知らせ</p>
                            </div>
                            <div class="dropdown-Function-Body">
                                <div class="dropdown-Function-Body-Container">
                                    <ul class="dropdown-Function-List">
                                        <li class="dropdown-Function-List-Item">
                                            <button class="dropdown-Function-Button gtmclick" data-gtmvalue="header_message_important" [onamaeRouterLink]="'/top/message/category/important'" [ngClass]="{'text-Bold': headerViewModel.importantTotalUnreadCount > 0}">重要なお知らせ<span class="badge" *ngIf="headerViewModel.importantTotalUnreadCount > 0">{{headerViewModel.importantTotalUnreadCount}}</span></button>
                                        </li>
                                        <li class="dropdown-Function-List-Item">
                                            <button class="dropdown-Function-Button gtmclick" data-gtmvalue="header_message_deals" [onamaeRouterLink]="'/top/message/category/deals'" [ngClass]="{'text-Bold': headerViewModel.serviceTotalUnreadCount > 0}">サービス情報<span class="badge" *ngIf="headerViewModel.serviceTotalUnreadCount > 0">{{headerViewModel.serviceTotalUnreadCount}}</span></button>
                                        </li>
                                        <li class="dropdown-Function-List-Item">
                                            <button class="dropdown-Function-Button gtmclick" data-gtmvalue="header_message_maintenance" [onamaeRouterLink]="'/top/message/category/maintenance'">メンテナンス情報</button>
                                        </li>
                                        <li class="dropdown-Function-List-Item">
                                            <button class="dropdown-Function-Button gtmclick" data-gtmvalue="header_message_systemFailure" [onamaeRouterLink]="'/top/message/category/systemFailure'">障害情報</button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="header-Function-Wrap" *ngIf="isABNew_HeaderInfoDropDownMenu">
                        <a class="header-Function-Button" href="javascript:void(0)" id="headerInfoDropDownB" absolute-dropdown target="#globalSideNaviInfoB" adjust-position="n" (click)="getForYouInfo()">
                            <img class="header-Function-Button-Icon" src="{{CacheBusting('/Content/images/common/header-Function-Button-Info-icon.svg')}}" width="16" height="16" alt="">
                            お知らせ
                            <span class="badge" *ngIf="headerViewModel.totalUnreadCountB > 0">{{headerViewModel.totalUnreadCountB}}</span>
                            <i class="icon icon-ArrowDown"></i>
                        </a>
                        <div class="dropdown is-HeaderFunctionInfo is-Tab" id="globalSideNaviInfoB">
                            <div class="dropdown-Function-Contents">
                                <div class="nav-Tab">
                                    <ul class="nav-Tab-List">
                                        <li><a href="javascript:void(0)" (click)="headerViewModel.onClickMessageTab()" [class.is-Active]="headerViewModel.showForYouTab">あなた宛<span class="badge" *ngIf="headerViewModel.forYouInfoCount > 0" style="right:65px">{{headerViewModel.forYouInfoCount}}</span></a></li>
                                        <li><a href="javascript:void(0)" (click)="headerViewModel.onClickMessageTab()" [class.is-Active]="!headerViewModel.showForYouTab">お知らせ<span class="badge" *ngIf="headerViewModel.totalUnreadCount > 0" style="right:65px">{{headerViewModel.totalUnreadCount}}</span></a></li>
                                    </ul>
                                    <div class="nav-Tab-Content">
                                        <div *ngIf="headerViewModel.forYouInfoCount > 0" id="tabId1-1" class="nav-Tab-Content-Pane" [class.is-Show]="headerViewModel.showForYouTab">
                                            <div class="dropdown-Function-Body">
                                                <div class="dropdown-Function-Body-Inner">
                                                    <div *ngIf="headerViewModel.isShowDomainRenew" class="box-Message">
                                                        <div class="box-Message-Heading">
                                                            <div class="box-Message-Heading-Icon">
                                                                <img src="{{CacheBusting('/Content/images/common/icon-caution.svg')}}" class="img-Caution" alt="(!)">
                                                            </div>
                                                            <p class="box-Message-Heading-Text">更新期限が迫っているドメインがあります。こちらから更新手続きをしてください。</p>
                                                        </div>
                                                        <div class="is-BgWhite pt12 pr12 pb12 pl12 mt8" *ngFor="let domain of headerViewModel.renewDomainList">
                                                            <table class="tbl-Normal">
                                                                <colgroup>
                                                                    <col width="188">
                                                                    <col width="">
                                                                </colgroup>
                                                                <tbody>
                                                                    <tr class="vertical-Top">
                                                                        <td>
                                                                            <span class="text-Xsmall">ドメイン名</span><br>
                                                                            <span>{{domain.domainName}}</span>
                                                                        </td>
                                                                        <td>
                                                                            <span class="text-Xsmall">更新期限</span><br>
                                                                            <span class="text-Danger">
                                                                                {{domain.renewalDeadLineDate}}
                                                                                <ng-container *ngIf="domain.renewalDeadLineDays > 0; then day_remain else hour_remain"></ng-container>
                                                                                <ng-template #day_remain><span class="text-Small">（残{{domain.renewalDeadLineDays}}日）</span></ng-template>
                                                                                <ng-template #hour_remain><span class="text-Small">（残{{domain.renewalDeadLineHours}}時間）</span></ng-template>
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            <a href="javascript:void(0)" class="btn is-Small is-Primary is-Block mt8 header-Info-Contents gtmclick" data-gtmvalue="info_foryou_domainrenew" (click)="headerViewModel.onClickInfoBtn('/domain/setting/renew/list/'+domain.domainId)">更新する</a>
                                                        </div>
                                                        <p class="text-Center mt12 mb0"><a href="javascript:void(0)" class="link header-Info-Contents gtmclick" data-gtmvalue="info_foryou_domainrenew_list" (click)="headerViewModel.onClickInfoBtn('/domain/setting/renew/list')">ドメイン契約更新一覧</a></p>
                                                    </div>
                                                    <div *ngIf="headerViewModel.isShowRestoreDomain" class="box-Message is-Promotion mt8">
                                                        <div class="box-Message-Heading">
                                                            <div class="box-Message-Heading-Icon">
                                                                <i class="icon icon-Bell"></i>
                                                            </div>
                                                            <p class="box-Message-Heading-Text">廃止済みドメインがあります。こちらから復旧手続きをしてください。</p>
                                                        </div>
                                                        <a href="javascript:void(0)" class="btn is-Small is-Primary is-Block mt8 header-Info-Contents gtmclick" data-gtmvalue="info_foryou_restore" (click)="headerViewModel.onClickInfoBtn('/domain/setting/restore')">手続きに進む</a>
                                                    </div>
                                                    <div *ngIf="headerViewModel.isShowUnpaid" class="box-Message mt8">
                                                          <div class="box-Message-Heading">
                                                              <div class="box-Message-Heading-Icon">
                                                                  <img src="{{CacheBusting('/Content/images/common/icon-caution.svg')}}" class="img-Caution" alt="(!)">
                                                              </div>
                                                              <p class="box-Message-Heading-Text">入金が必要なご請求があります。こちらからご確認ください。</p>
                                                          </div>
                                                          <a href="javascript:void(0)" class="btn is-Small is-Primary is-Block mt8 header-Info-Contents gtmclick" data-gtmvalue="info_foryou_payment" (click)="headerViewModel.onClickInfoBtn(headerViewModel.unpaidTransitionPath)">手続きに進む</a>
                                                    </div>
                                                    <div *ngIf="headerViewModel.isShowCojpDefine" class="box-Message is-Promotion mt8">
                                                        <div class="box-Message-Heading">
                                                            <div class="box-Message-Heading-Icon">
                                                                <i class="icon icon-Bell"></i>
                                                            </div>
                                                            <p class="box-Message-Heading-Text">仮登録状態のドメインがあります。本登録申請を行ってください。</p>
                                                        </div>
                                                        <a href="javascript:void(0)" class="btn is-Small is-Primary is-Block mt8 header-Info-Contents gtmclick" data-gtmvalue="info_foryou_cojpdomain" (click)="headerViewModel.onClickInfoBtn('/domain/setting/cojpdomain/select')">手続きに進む</a>
                                                    </div>
                                                    <div *ngIf="headerViewModel.isShowSSL" class="box-Message is-Promotion mt8">
                                                        <div class="box-Message-Heading">
                                                            <div class="box-Message-Heading-Icon">
                                                                <i class="icon icon-Bell"></i>
                                                            </div>
                                                            <p class="box-Message-Heading-Text">SSL証明書の発行が完了しておりません。{{headerViewModel.sslPrepaymentExpirationDate}}までにこちらからお手続きください。</p>
                                                        </div>
                                                        <a class="btn is-Small is-Primary is-Block mt8 header-Info-Contents" href="javascript:void(0)" (click)="headerViewModel.onClickSslNew()">手続きに進む</a>
                                                    </div>
                                                    <div *ngIf="headerViewModel.isShowWithDefaultNameServer" class="box-Message is-Normal mt8">
                                                        <div class="box-Message-Heading">
                                                            <div class="box-Message-Heading-Icon">
                                                                <div class="box-Message-Heading-Icon-Circle">
                                                                    <i class="icon icon-domain"></i>
                                                                </div>
                                                            </div>
                                                            <p class="box-Message-Heading-Text text-weight-normal">ご利用されていないドメインがあります。</p>
                                                        </div>
                                                        <a href="javascript:void(0)" class="btn is-Small is-Primary is-Block mt8 header-Info-Contents gtmclick" data-gtmvalue="info_foryou_ns" (click)="headerViewModel.onClickInfoBtn('/domain')">ドメインを利用する</a>
                                                    </div>
                                                    <div class="box-Message is-Normal mt8" *ngIf="headerViewModel.isShowOshiraseShindan">
                                                        <div class="box-Message-Heading">
                                                            <div class="box-Message-Heading-Icon">
                                                                <img src="{{CacheBusting('/Content/images/common/icon-caution.svg')}}" class="img-Caution" alt="(!)">
                                                            </div>
                                                        </div>
                                                        <p class="box-Message-Heading-Text text-weight-normal pt4 pb4">Webサイトのセキュリティ対策が充分でない可能性が！</p>
                                                        <p class="box-Message-Heading-Text text-weight-normal">ネットde診断で無料&簡単1クリックで確認開始！</p>
                                                        <a class="btn is-Small is-Primary is-Block mt8 header-Info-Contents" href="javascript:void(0)" (click)="headerViewModel.onClickHiddenOshiraseShindan()">詳細をチェックする</a>
                                                    </div>
                                                    <div class="box-Message" *ngIf="headerViewModel.isShowCreditCardUpdate" [class.mt8]="headerViewModel.isShowDomainRenew || headerViewModel.isShowUnpaid || headerViewModel.isShowRestoreDomain || headerViewModel.isShowCojpDefine || headerViewModel.isShowSSL || headerViewModel.isShowWithDefaultNameServer || headerViewModel.isShowOshiraseShindan || headerViewModel.isShowRebillingNotice">
                                                        <div class="box-Message-Heading">
                                                            <div class="box-Message-Heading-Icon">
                                                                <img src="{{CacheBusting('/Content/images/common/icon-caution.svg')}}" class="img-Caution" alt="(!)">
                                                            </div>
                                                            <p class="box-Message-Heading-Text pt4 pb4">クレジットカードの有効期限が近い、または期限が過ぎております。<br/>
                                                            下記ボタンからクレジットカード情報の更新、または別のお支払い方法への変更をお願いします。</p>
                                                        </div>
                                                        <a class="btn is-Small is-Primary is-Block mt8 header-Info-Contents" href="javascript:void(0)" (click)="headerViewModel.onClickInfoBtn('/payment/detail/hosting')">お支払い情報の確認/変更</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="headerViewModel.forYouInfoCount === 0" id="tabId1-1" class="nav-Tab-Content-Pane" [class.is-Show]="headerViewModel.showForYouTab">
                                            <div class="dropdown-Function-Body">
                                                <div class="dropdown-Function-Body-Container is-DisplayFlex is-AlignItemsCenter is-justifyContentCenter">
                                                    <p class="mb0">あなた宛の通知はありません</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="tabId1-2" class="nav-Tab-Content-Pane" [class.is-Show]="!headerViewModel.showForYouTab">
                                            <div class="dropdown-Function-Body">
                                                <div class="dropdown-Function-Body-Container">
                                                    <ul class="dropdown-Function-List">
                                                        <li class="dropdown-Function-List-Item">
                                                            <button class="dropdown-Function-Button gtmclick" data-gtmvalue="header_message_important" (click)="headerViewModel.onClickInfoBtn('/top/message/category/important')" [ngClass]="{'text-Bold': headerViewModel.importantTotalUnreadCount > 0}">重要なお知らせ<span class="badge" *ngIf="headerViewModel.importantTotalUnreadCount > 0">{{headerViewModel.importantTotalUnreadCount}}</span></button>
                                                        </li>
                                                        <li class="dropdown-Function-List-Item">
                                                            <button class="dropdown-Function-Button gtmclick" data-gtmvalue="header_message_deals" (click)="headerViewModel.onClickInfoBtn('/top/message/category/deals')" [ngClass]="{'text-Bold': headerViewModel.serviceTotalUnreadCount > 0}">サービス情報<span class="badge" *ngIf="headerViewModel.serviceTotalUnreadCount > 0">{{headerViewModel.serviceTotalUnreadCount}}</span></button>
                                                        </li>
                                                        <li class="dropdown-Function-List-Item">
                                                            <button class="dropdown-Function-Button gtmclick" data-gtmvalue="header_message_maintenance" (click)="headerViewModel.onClickInfoBtn('/top/message/category/maintenance')">メンテナンス情報</button>
                                                        </li>
                                                        <li class="dropdown-Function-List-Item">
                                                            <button class="dropdown-Function-Button gtmclick" data-gtmvalue="header_message_systemFailure" (click)="headerViewModel.onClickInfoBtn('/top/message/category/systemFailure')">障害情報</button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="header-Function-Wrap">
                        <a href="javascript:void(0)" class="header-Function-Button" (click)="headerViewModel.onClickAccountManagement()"><i class="icon icon-Mypage size-20 ml0 mr4"></i>お客様情報管理</a>
                        <div class="header-Balloon is-Mypage" *ngIf="headerViewModel.isShowHeaderBalloon">
                            <div class="header-Balloon-Inner">
                                <button type="button" class="header-Balloon-Close"><i class="icon icon-Clear" aria-label="閉じる" (click)="headerViewModel.onClickCloseHeaderBalloon()"></i></button>
                                <p>会員情報・ご請求情報の確認/変更はこちら</p>
                            </div>
                        </div>
                    </div>
                </li>
            </ng-container>
            <ng-container *ngIf="!isDisplayGlobalNavi">
                <li class="header-Function-Item">
                    <p class="size-14 mb0 mr6">お名前ID：{{headerViewModel.onamaeId}} [<a href="javascript:void(0)" (click)="headerViewModel.clickLogoutConfirm()">ログアウト</a>]</p>
                    <div class="header-Function-Wrap is-NarrowHidden">
                        <div class="form-TextWithIcon is-SearchInNavi" absolute-dropdown target="#search" adjust-position="n">
                            <button type="button" class="form-TextWithIcon-Btn" id="searchBtn"><i class="icon icon-Magnifier"></i></button>
                            <input type="text" class="form-Text" id="searchInput" (keyup)="keyupSearch($event)" placeholder="キーワードからNavi内を検索" [(ngModel)]="headerViewModel.searchInputWord">
                        </div>
                        <div class="dropdown is-HeaderFunctionSearch" id="search">
                            <div class="dropdown-Main">
                                <p *ngIf="headerViewModel.searchInputWord == ''" class="dropdown-Hdn mb0">キーワードを入力してください。</p>
                                <p *ngIf="headerViewModel.searchInputWord != ''" class="dropdown-Hdn">お名前.com Navi 内の検索結果</p>
                                <p *ngIf="headerViewModel.isNoSuggestResult" class="mb0 mt16">「<b>{{headerViewModel.searchInputWord}}</b>」を含む検索結果はありません。</p>
                                <ul class="mt16 is-ScrollY">
                                    <li *ngFor="let suggest of headerViewModel.showSuggests">
                                        <i class="icon icon-ArrowCircleRight"></i><a href="javascript:void(0)" (click)="headerViewModel.onClickSuggestLinkTransitionPage(suggest, false)">{{suggest.name}}</a>
                                    </li>
                                </ul>
                                <button *ngIf="headerViewModel.isShowAllSuggestBtn" type="button" class="btn is-Block" (click)="headerViewModel.onClickShowAllSuggest()">すべて表示</button>
                            </div>
                            <div *ngIf="headerViewModel.showSuggests.length == 0 || headerViewModel.showHelpPageSuggests.length > 0" class="dropdown-Footer">
                                <ng-container *ngIf="headerViewModel.showSuggests.length == 0">
                                    <p class="hdn-HorizontalLine mb0">よく検索されているキーワード</p>
                                    <ul class="list is-Btn">
                                        <li><button type="button" class="btn is-Small" (click)="headerViewModel.onClickFrequentlySearchWord('ネームサーバー')">ネームサーバー</button></li>
                                        <li><button type="button" class="btn is-Small" (click)="headerViewModel.onClickFrequentlySearchWord('DNS')">DNS</button></li>
                                        <li><button type="button" class="btn is-Small" (click)="headerViewModel.onClickFrequentlySearchWord('Whois')">Whois</button></li>
                                        <li><button type="button" class="btn is-Small" (click)="headerViewModel.onClickFrequentlySearchWord('メール')">メール</button></li>
                                        <li><button type="button" class="btn is-Small" (click)="headerViewModel.onClickFrequentlySearchWord('請求')">請求</button></li>
                                    </ul>
                                </ng-container>
                                <ng-container *ngIf="headerViewModel.showHelpPageSuggests.length > 0">
                                    <p class="hdn-HorizontalLine mt24">よくあるご質問</p>
                                    <ul class="list is-Text mt12">
                                        <li *ngFor="let helpSuggest of headerViewModel.showHelpPageSuggests">
                                            <b>Q：</b><a href="javascript:void(0)" (click)="headerViewModel.onClickSuggestLinkTransitionPage(helpSuggest, true)">{{helpSuggest.name}}</a>
                                        </li>
                                    </ul>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                        
                    <div class="header-Function-Wrap" *ngIf="!isABNew_HeaderInfoDropDownMenu">
                        <a class="header-Function-Button" id="headerInfoDropDown" href="javascript:void(0)" absolute-dropdown target="#info" close="a" adjust-position="n">
                            <img class="header-Function-Button-Icon" src="{{CacheBusting('/Content/images/common/header-Function-Button-Info-icon.svg')}}" width="16" height="16" alt="">
                            お知らせ
                            <span class="badge" *ngIf="headerViewModel.totalUnreadCount > 0">{{headerViewModel.totalUnreadCount}}</span>
                            <i class="icon icon-ArrowDown"></i>
                        </a>
                        <!--お知らせAパターン -->
                        <div class="dropdown is-HeaderFunctionInfo" id="info">
                          <div class="dropdown-Function-Header">
                            <p>お知らせ</p>
                          </div>
                          <div class="dropdown-Function-Body">
                            <div class="dropdown-Function-Body-Container">
                              <ul class="dropdown-Function-List">
                                <li class="dropdown-Function-List-Item">
                                  <button class="dropdown-Function-Button gtmclick" data-gtmvalue="header_message_important" [onamaeRouterLink]="'/top/message/category/important'" [ngClass]="{'text-Bold': headerViewModel.importantTotalUnreadCount > 0}">重要なお知らせ<span class="badge" *ngIf="headerViewModel.importantTotalUnreadCount > 0">{{headerViewModel.importantTotalUnreadCount}}</span></button>
                                </li>
                                <li class="dropdown-Function-List-Item">
                                  <button class="dropdown-Function-Button gtmclick" data-gtmvalue="header_message_deals" [onamaeRouterLink]="'/top/message/category/deals'" [ngClass]="{'text-Bold': headerViewModel.serviceTotalUnreadCount > 0}">サービス情報<span class="badge" *ngIf="headerViewModel.serviceTotalUnreadCount > 0">{{headerViewModel.serviceTotalUnreadCount}}</span></button>
                                </li>
                                <li class="dropdown-Function-List-Item">
                                  <button class="dropdown-Function-Button gtmclick" data-gtmvalue="header_message_maintenance" [onamaeRouterLink]="'/top/message/category/maintenance'">メンテナンス情報</button>
                                </li>
                                <li class="dropdown-Function-List-Item">
                                  <button class="dropdown-Function-Button gtmclick" data-gtmvalue="header_message_systemFailure" [onamaeRouterLink]="'/top/message/category/systemFailure'">障害情報</button>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div><!-- /.dropdown -->
                        <!--お知らせAパターン -->
                    </div>
                          
                    <div class="header-Function-Wrap" *ngIf="isABNew_HeaderInfoDropDownMenu">
                        <a class="header-Function-Button" id="headerInfoDropDownB" href="javascript:void(0)" absolute-dropdown adjust-position="n" target="#infoB" (click)="getForYouInfo()">
                            <img class="header-Function-Button-Icon" src="{{CacheBusting('/Content/images/common/header-Function-Button-Info-icon.svg')}}" width="16" height="16" alt="">
                            お知らせ
                            <span class="badge" *ngIf="headerViewModel.totalUnreadCountB > 0">{{headerViewModel.totalUnreadCountB}}</span>
                            <i class="icon icon-ArrowDown"></i>
                        </a>
                        <!--お知らせBパターン -->
                        <div class="dropdown is-HeaderFunctionInfo is-Tab" id="infoB">
                            <div class="dropdown-Function-Contents">
                                <div class="nav-Tab">
                                    <ul class="nav-Tab-List">
                                        <li><a href="javascript:void(0)" (click)="headerViewModel.onClickMessageTab()" [class.is-Active]="headerViewModel.showForYouTab">あなた宛<span class="badge" *ngIf="headerViewModel.forYouInfoCount > 0" style="right:65px">{{headerViewModel.forYouInfoCount}}</span></a></li>
                                        <li><a href="javascript:void(0)" (click)="headerViewModel.onClickMessageTab()" [class.is-Active]="!headerViewModel.showForYouTab">お知らせ<span class="badge" *ngIf="headerViewModel.totalUnreadCount > 0" style="right:65px">{{headerViewModel.totalUnreadCount}}</span></a></li>
                                    </ul><!-- /.nav-Tab-List -->
                                    <div class="nav-Tab-Content">
                                        <div *ngIf="headerViewModel.forYouInfoCount > 0" id="tabId1-1" class="nav-Tab-Content-Pane" [class.is-Show]="headerViewModel.showForYouTab">
                                            <div class="dropdown-Function-Body">
                                                <div class="dropdown-Function-Body-Inner">
                                                    <div *ngIf="headerViewModel.isShowDomainRenew" class="box-Message">
                                                        <div class="box-Message-Heading">
                                                            <div class="box-Message-Heading-Icon">
                                                                <img src="{{CacheBusting('/Content/images/common/icon-caution.svg')}}" class="img-Caution" alt="(!)">
                                                            </div>
                                                            <p class="box-Message-Heading-Text">更新期限が迫っているドメインがあります。こちらから更新手続きをしてください。</p>
                                                        </div>
                                                        <div class="is-BgWhite pt12 pr12 pb12 pl12 mt8" *ngFor="let domain of headerViewModel.renewDomainList">
                                                            <table class="tbl-Normal">
                                                                <colgroup>
                                                                    <col width="188">
                                                                    <col width="">
                                                                </colgroup>
                                                                <tbody>
                                                                    <tr class="vertical-Top">
                                                                        <td>
                                                                            <span class="text-Xsmall">ドメイン名</span><br>
                                                                            <span>{{domain.domainName}}</span>
                                                                        </td>
                                                                        <td>
                                                                            <span class="text-Xsmall">更新期限</span><br>
                                                                            <span class="text-Danger">
                                                                                {{domain.renewalDeadLineDate}}
                                                                                <ng-container *ngIf="domain.renewalDeadLineDays > 0; then day_remain else hour_remain"></ng-container>
                                                                                <ng-template #day_remain><span class="text-Small">（残{{domain.renewalDeadLineDays}}日）</span></ng-template>
                                                                                <ng-template #hour_remain><span class="text-Small">（残{{domain.renewalDeadLineHours}}時間）</span></ng-template>
                                                                            </span>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            <a href="javascript:void(0)" class="btn is-Small is-Primary is-Block mt8 header-Info-Contents gtmclick" data-gtmvalue="info_foryou_domainrenew" (click)="headerViewModel.onClickInfoBtn('/domain/setting/renew/list/'+domain.domainId)">更新する</a>
                                                        </div>
                                                        <p class="text-Center mt12 mb0"><a href="javascript:void(0)" class="link header-Info-Contents gtmclick" data-gtmvalue="info_foryou_domainrenew_list" (click)="headerViewModel.onClickInfoBtn('/domain/setting/renew/list')">ドメイン契約更新一覧</a></p>
                                                    </div>
                                                    <div *ngIf="headerViewModel.isShowRestoreDomain" class="box-Message is-Promotion" [class.mt8]="headerViewModel.isShowDomainRenew">
                                                        <div class="box-Message-Heading">
                                                            <div class="box-Message-Heading-Icon">
                                                                <i class="icon icon-Bell"></i>
                                                            </div>
                                                            <p class="box-Message-Heading-Text">廃止済みドメインがあります。こちらから復旧手続きをしてください。</p>
                                                        </div>
                                                        <a href="javascript:void(0)" class="btn is-Small is-Primary is-Block mt8 header-Info-Contents gtmclick" data-gtmvalue="info_foryou_restore" (click)="headerViewModel.onClickInfoBtn('/domain/setting/restore')">手続きに進む</a>
                                                    </div>
                                                    <div *ngIf="headerViewModel.isShowUnpaid" class="box-Message" [class.mt8]="headerViewModel.isShowDomainRenew || headerViewModel.isShowRestoreDomain">
                                                        <div class="box-Message-Heading">
                                                            <div class="box-Message-Heading-Icon">
                                                                <img src="{{CacheBusting('/Content/images/common/icon-caution.svg')}}" class="img-Caution" alt="(!)">
                                                            </div>
                                                            <p class="box-Message-Heading-Text">入金が必要なご請求があります。こちらからご確認ください。</p>
                                                        </div>
                                                        <a href="javascript:void(0)" class="btn is-Small is-Primary is-Block mt8 header-Info-Contents gtmclick" data-gtmvalue="info_foryou_payment" (click)="headerViewModel.onClickInfoBtn(headerViewModel.unpaidTransitionPath)">手続きに進む</a>
                                                    </div>
                                                    <div *ngIf="headerViewModel.isShowCojpDefine" class="box-Message is-Promotion" [class.mt8]="headerViewModel.isShowDomainRenew || headerViewModel.isShowUnpaid || headerViewModel.isShowRestoreDomain">
                                                        <div class="box-Message-Heading">
                                                            <div class="box-Message-Heading-Icon">
                                                                <i class="icon icon-Bell"></i>
                                                            </div>
                                                            <p class="box-Message-Heading-Text">仮登録状態のドメインがあります。本登録申請を行ってください。</p>
                                                        </div>
                                                        <a href="javascript:void(0)" class="btn is-Small is-Primary is-Block mt8 header-Info-Contents gtmclick" data-gtmvalue="info_foryou_cojpdomain" (click)="headerViewModel.onClickInfoBtn('/domain/setting/cojpdomain/select')">手続きに進む</a>
                                                    </div>
                                                    <div *ngIf="headerViewModel.isShowSSL" class="box-Message is-Promotion" [class.mt8]="headerViewModel.isShowDomainRenew || headerViewModel.isShowUnpaid || headerViewModel.isShowRestoreDomain">
                                                        <div class="box-Message-Heading">
                                                            <div class="box-Message-Heading-Icon">
                                                                <i class="icon icon-Bell"></i>
                                                            </div>
                                                            <p class="box-Message-Heading-Text">SSL証明書の発行が完了しておりません。{{headerViewModel.sslPrepaymentExpirationDate}}までにこちらからお手続きください。</p>
                                                        </div>
                                                        <a class="btn is-Small is-Primary is-Block mt8 header-Info-Contents" href="javascript:void(0)" (click)="headerViewModel.onClickSslNew()">手続きに進む</a>
                                                    </div>
                                                    <div *ngIf="headerViewModel.isShowWithDefaultNameServer" class="box-Message is-Normal" [class.mt8]="headerViewModel.isShowDomainRenew || headerViewModel.isShowUnpaid || headerViewModel.isShowRestoreDomain || headerViewModel.isShowCojpDefine || headerViewModel.isShowSSL">
                                                        <div class="box-Message-Heading">
                                                            <div class="box-Message-Heading-Icon">
                                                                <div class="box-Message-Heading-Icon-Circle">
                                                                    <i class="icon icon-domain"></i>
                                                                </div>
                                                            </div>
                                                            <p class="box-Message-Heading-Text text-weight-normal">ご利用されていないドメインがあります。</p>
                                                        </div>
                                                        <a href="javascript:void(0)" class="btn is-Small is-Primary is-Block mt8 header-Info-Contents gtmclick" data-gtmvalue="info_foryou_ns" (click)="headerViewModel.onClickInfoBtn('/domain')">ドメインを利用する</a>
                                                    </div>
                                                    <div class="box-Message is-Normal" *ngIf="headerViewModel.isShowOshiraseShindan" [class.mt8]="headerViewModel.isShowDomainRenew || headerViewModel.isShowUnpaid || headerViewModel.isShowRestoreDomain || headerViewModel.isShowCojpDefine || headerViewModel.isShowSSL || headerViewModel.isShowWithDefaultNameServer">
                                                        <div class="box-Message-Heading">
                                                            <div class="box-Message-Heading-Icon">
                                                                <img src="{{CacheBusting('/Content/images/common/icon-caution.svg')}}" class="img-Caution" alt="(!)">
                                                            </div>
                                                        </div>
                                                        <p class="box-Message-Heading-Text text-weight-normal pt4 pb4">Webサイトのセキュリティ対策が充分でない可能性が！</p>
                                                        <p class="box-Message-Heading-Text text-weight-normal">ネットde診断で無料&簡単1クリックで確認開始！</p>
                                                        <a class="btn is-Small is-Primary is-Block mt8 header-Info-Contents" href="javascript:void(0)" (click)="headerViewModel.onClickHiddenOshiraseShindan()">詳細をチェックする</a>
                                                    </div>
                                                    <div class="box-Message" *ngIf="headerViewModel.isShowRebillingNotice" [class.mt8]="headerViewModel.isShowDomainRenew || headerViewModel.isShowUnpaid || headerViewModel.isShowRestoreDomain || headerViewModel.isShowCojpDefine || headerViewModel.isShowSSL || headerViewModel.isShowWithDefaultNameServer || headerViewModel.isShowOshiraseShindan">
                                                        <div class="box-Message-Heading">
                                                            <div class="box-Message-Heading-Icon">
                                                                <img src="{{CacheBusting('/Content/images/common/icon-caution.svg')}}" class="img-Caution" alt="(!)">
                                                            </div>
                                                            <p class="box-Message-Heading-Text pt4 pb4">【ご注意】 申込時のカード処理が請求確定しておりません。「変更」ボタンにてお支払情報更新をしてください。更新後に再請求いたします。</p>
                                                        </div>
                                                        <a class="btn is-Small is-Primary is-Block mt8 header-Info-Contents" href="javascript:void(0)" (click)="headerViewModel.onClickRedirectPaymentHosting()">変更</a>
                                                    </div>
                                                    <div class="box-Message" *ngIf="headerViewModel.isShowCreditCardUpdate" [class.mt8]="headerViewModel.isShowDomainRenew || headerViewModel.isShowUnpaid || headerViewModel.isShowRestoreDomain || headerViewModel.isShowCojpDefine || headerViewModel.isShowSSL || headerViewModel.isShowWithDefaultNameServer || headerViewModel.isShowOshiraseShindan || headerViewModel.isShowRebillingNotice">
                                                        <div class="box-Message-Heading">
                                                            <div class="box-Message-Heading-Icon">
                                                                <img src="{{CacheBusting('/Content/images/common/icon-caution.svg')}}" class="img-Caution" alt="(!)">
                                                            </div>
                                                            <p class="box-Message-Heading-Text pt4 pb4">
                                                              クレジットカードの有効期限が近い、または期限が過ぎております。<br />
                                                              下記ボタンからクレジットカード情報の更新、または別のお支払い方法への変更をお願いします。
                                                            </p>
                                                        </div>
                                                        <a class="btn is-Small is-Primary is-Block mt8 header-Info-Contents" href="javascript:void(0)" (click)="headerViewModel.onClickInfoBtn('/payment/detail/hosting')">お支払い情報の確認/変更</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div><!-- /.nav-Tab-Content-Pane -->
                                        <div *ngIf="headerViewModel.forYouInfoCount === 0" id="tabId1-1" class="nav-Tab-Content-Pane" [class.is-Show]="headerViewModel.showForYouTab">
                                            <div class="dropdown-Function-Body">
                                                <div class="dropdown-Function-Body-Container is-DisplayFlex is-AlignItemsCenter is-justifyContentCenter">
                                                    <p class="mb0">あなた宛の通知はありません</p>
                                                </div>
                                            </div>
                                        </div><!-- /.nav-Tab-Content-Pane -->
                                        <div id="tabId1-2" class="nav-Tab-Content-Pane" [class.is-Show]="!headerViewModel.showForYouTab">
                                            <div class="dropdown-Function-Body">
                                                <div class="dropdown-Function-Body-Container">
                                                    <ul class="dropdown-Function-List">
                                                        <li class="dropdown-Function-List-Item">
                                                            <button class="dropdown-Function-Button gtmclick" data-gtmvalue="header_message_important" (click)="headerViewModel.onClickInfoBtn('/top/message/category/important')" [ngClass]="{'text-Bold': headerViewModel.importantTotalUnreadCount > 0}">重要なお知らせ<span class="badge" *ngIf="headerViewModel.importantTotalUnreadCount > 0">{{headerViewModel.importantTotalUnreadCount}}</span></button>
                                                        </li>
                                                        <li class="dropdown-Function-List-Item">
                                                            <button class="dropdown-Function-Button gtmclick" data-gtmvalue="header_message_deals" (click)="headerViewModel.onClickInfoBtn('/top/message/category/deals')" [ngClass]="{'text-Bold': headerViewModel.serviceTotalUnreadCount > 0}">サービス情報<span class="badge" *ngIf="headerViewModel.serviceTotalUnreadCount > 0">{{headerViewModel.serviceTotalUnreadCount}}</span></button>
                                                        </li>
                                                        <li class="dropdown-Function-List-Item">
                                                            <button class="dropdown-Function-Button gtmclick" data-gtmvalue="header_message_maintenance" (click)="headerViewModel.onClickInfoBtn('/top/message/category/maintenance')">メンテナンス情報</button>
                                                        </li>
                                                        <li class="dropdown-Function-List-Item">
                                                            <button class="dropdown-Function-Button gtmclick" data-gtmvalue="header_message_systemFailure" (click)="headerViewModel.onClickInfoBtn('/top/message/category/systemFailure')">障害情報</button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div><!-- /.nav-Tab-Content-Pane -->
                                    </div><!-- /.nav-Tab-Content -->
                                </div><!-- /.nav-Tab -->
                            </div>
                        </div><!-- /.dropdown -->
                        <!--お知らせBパターン -->
                    </div>
                          
                    <div class="header-Function-Wrap">
                        <a href="javascript:void(0)" class="header-Function-Button" (click)="headerViewModel.onClickAccountManagement()"><i class="icon icon-Mypage size-20 ml0 mr4"></i>お客様情報管理</a>
                        <div class="header-Balloon is-Mypage" *ngIf="headerViewModel.isShowHeaderBalloon">
                            <div class="header-Balloon-Inner">
                                <button type="button" class="header-Balloon-Close"><i class="icon icon-Clear" aria-label="閉じる" (click)="headerViewModel.onClickCloseHeaderBalloon()"></i></button>
                                <p>会員情報・ご請求情報の確認/変更はこちら</p>
                            </div>
                        </div>
                    </div>
                </li>
            </ng-container>
        </ul>
    </div>
</header>

<nav class="nav-GlobalSideNavi is-Tm58776" [class.has-HeadNotification]="hasHeadNotification" *ngIf="isDisplayGlobalNavi">
    <a class="nav-GlobalSideNavi-Link is-GlobalSideNaviTop gtmclick" data-gtmvalue="menutub_top" href="javascript:void(0)" [class.is-Current]="globalNaviViewModel.CurrentNavigationName == 'top'" [onamaeRouterLink]="'/top'">
        <i class="icon icon-GlobalNavTop gtmclick" data-gtmvalue="menutub_top"></i>
        <span class="nav-GlobalSideNavi-Text gtmclick" data-gtmvalue="menutub_top">TOP</span>
    </a>
    <p class="nav-GlobalSideNavi-Heading"><span>サービス・設定一覧</span></p>
    <ul class="nav-GlobalSideNavi-List">
        <li class="nav-GlobalSideNavi-List-Item is-GlobalSideNaviDomain">
            <a class="nav-GlobalSideNavi-Link is-GlobalSideNaviTop js-navGlobalSideNaviLink gtmclick" [class.is-Current]="globalNaviViewModel.CurrentNavigationName == 'domain'" data-gtmvalue="menutub_domain" dropdownHover-b data-toggle="popup" data-placement="right" data-action="hover" data-module="globalSideNaviDomain" data-target-b="#globalSideNaviDomain" id="globalSideNaviDomainBtn">
                <i class="icon icon-GlobalNavDomain gtmclick" data-gtmvalue="menutub_domain"></i>
                <span class="nav-GlobalSideNavi-Text gtmclick" data-gtmvalue="menutub_domain">ドメイン</span>
            </a>
        </li>
        <li class="nav-GlobalSideNavi-List-Item is-GlobalSideNaviDns">
            <a class="nav-GlobalSideNavi-Link js-navGlobalSideNaviLink gtmclick" [class.is-Current]="globalNaviViewModel.CurrentNavigationName == 'dns'" data-gtmvalue="menutub_dns" dropdownHover-b data-toggle="popup" data-placement="right" data-action="hover" data-module="globalSideNaviDomain" data-target-b="#globalSideNaviDNS" id="globalSideNaviDNSBtn">
                <i class="icon icon-Dns gtmclick" data-gtmvalue="menutub_dns"></i>
                <span class="nav-GlobalSideNavi-Text gtmclick" data-gtmvalue="menutub_dns">ネーム<br>サーバー<span>設定</span></span>
            </a>
        </li>
        <li class="nav-GlobalSideNavi-List-Item has-Budge is-GlobalSideNaviRentalserver">
            <span class="nav-GlobalSideNavi-Budge text-Bold is-Rentalserver">Webサイト&メールのご利用ならこちら</span>
            <a class="nav-GlobalSideNavi-Link js-navGlobalSideNaviLink gtmclick" [class.is-Current]="globalNaviViewModel.CurrentNavigationName == 'rs'" data-gtmvalue="menutub_sd" dropdownHover-b data-toggle="popup" data-placement="right" data-action="hover" data-module="globalSideNaviDomain" data-target-b="#globalSideNaviRentalServer" id="globalSideNaviRentalServerBtn">
                <i class="icon icon-GlobalNavRentalserver2 gtmclick" data-gtmvalue="menutub_sd"></i>
                <span class="nav-GlobalSideNavi-Text gtmclick" data-gtmvalue="menutub_sd">レンタル<br>サーバー</span>
            </a>
        </li>
        <li class="nav-GlobalSideNavi-List-Item is-GlobalSideNaviEmail">
            <a class="nav-GlobalSideNavi-Link js-navGlobalSideNaviLink gtmclick" [class.is-Current]="globalNaviViewModel.CurrentNavigationName == 'mail'" data-gtmvalue="menutub_mail" dropdownHover-b data-toggle="popup" data-placement="right" data-action="hover" data-module="globalSideNaviDomain" data-target-b="#globalSideNaviEmail" id="globalSideNaviEmailBtn">
                <i class="icon icon-GlobalNavEmail gtmclick" data-gtmvalue="menutub_mail"></i>
                <span class="nav-GlobalSideNavi-Text gtmclick" data-gtmvalue="menutub_mail">お名前メール</span>
            </a>
        </li>
        <li class="nav-GlobalSideNavi-List-Item is-GlobalSideNaviOffice">
            <a class="nav-GlobalSideNavi-Link js-navGlobalSideNaviLink gtmclick" [class.is-Current]="globalNaviViewModel.CurrentNavigationName == 'office365'" data-gtmvalue="menutub_office" dropdownHover-b data-toggle="popup" data-placement="right" data-action="hover" data-module="globalSideNaviDomain" data-target-b="#globalSideNaviMicrosotf365" id="globalSideNaviMicrosotf365Btn">
                <i class="icon icon-GlobalNavOffice gtmclick" data-gtmvalue="menutub_office"></i>
                <span class="nav-GlobalSideNavi-Text gtmclick" data-gtmvalue="menutub_office">Office</span>
            </a>
        </li>
        <li class="nav-GlobalSideNavi-List-Item is-GlobalSideNaviSsl">
            <a class="nav-GlobalSideNavi-Link js-navGlobalSideNaviLink gtmclick" [class.is-Current]="globalNaviViewModel.CurrentNavigationName == 'ssl'" data-gtmvalue="menutub_ssl" dropdownHover-b data-toggle="popup" data-placement="right" data-action="hover" data-module="globalSideNaviDomain" data-target-b="#globalSideNaviSSL" id="globalSideNaviSSLBtn">
                <i class="icon icon-GlobalNavSsl gtmclick" data-gtmvalue="menutub_ssl"></i>
                <span class="nav-GlobalSideNavi-Text gtmclick" data-gtmvalue="menutub_ssl">SSL<span>証明書</span></span>
            </a>
        </li>
        <li class="nav-GlobalSideNavi-List-Item has-Budge is-GlobalSideNaviDesktopCloud">
            <span class="nav-GlobalSideNavi-Budge text-Bold is-DesktopCloud">FX自動売買専用サーバーはこちら</span>
            <a class="nav-GlobalSideNavi-Link js-navGlobalSideNaviLink gtmclick" [class.is-Current]="globalNaviViewModel.CurrentNavigationName == 'desktopcloud' || globalNaviViewModel.CurrentNavigationName == 'desktopcloudoption'" data-gtmvalue="menutub_desktopcloud" dropdownHover-b data-toggle="popup" data-placement="right" data-action="hover" data-module="globalSideNaviDomain" data-target-b="#globalSideNaviDC" id="globalSideNaviDCBtn">
                <i class="icon icon-DesktopCloud gtmclick" data-gtmvalue="menutub_desktopcloud"></i>
                <span class="nav-GlobalSideNavi-Text gtmclick" data-gtmvalue="menutub_desktopcloud">デスクトップ<br>クラウド</span>
            </a>
        </li>
        <li class="nav-GlobalSideNavi-List-Item is-GlobalSideNaviEtc">
            <a class="nav-GlobalSideNavi-Link js-navGlobalSideNaviLink gtmclick" [class.is-Current]="globalNaviViewModel.CurrentNavigationName == 'other' || globalNaviViewModel.CurrentNavigationName == 'mailmarketing'" data-gtmvalue="menutub_other" dropdownHover-b data-toggle="popup" data-placement="right" data-action="hover" data-module="globalSideNaviDomain" data-target-b="#globalSideNaviEtc" id="globalSideNaviEtcBtn">
                <i class="icon icon-GlobalNavEtc gtmclick" data-gtmvalue="menutub_other"></i>
                <span class="nav-GlobalSideNavi-Text gtmclick" data-gtmvalue="menutub_other">その他</span>
            </a>
        </li>
    </ul>

    <p class="nav-GlobalSideNavi-Heading"><span>サポート・その他</span></p>
    <ul class="nav-GlobalSideNavi-List">
        <li class="nav-GlobalSideNavi-List-Item is-GlobalSideNaviSupport">
            <a class="nav-GlobalSideNavi-Link js-navGlobalSideNaviLink gtmclick" dropdownHover-b data-toggle="popup" data-placement="right" data-action="hover" data-module="globalSideNaviDomain" data-target-b="#globalSideNaviSupport" data-gtmvalue="header_support" data-realtimefired="true" id="globalSideNaviSupportBtn">
                <i class="icon icon-QuestionCircle gtmclick" data-gtmvalue="header_support" data-realtimefired="true"></i>
                <span class="nav-GlobalSideNavi-Text gtmclick" data-gtmvalue="header_support" data-realtimefired="true">お客様<br class="is-NavNarrow">サポート</span>
            </a>
        </li>
        <li class="nav-GlobalSideNavi-List-Item is-GlobalSideNaviMenu">
            <a href="javascript:void(0)" class="nav-GlobalSideNavi-Link js-navGlobalSideNaviLink gtmclick" data-gtmvalue="header_lookingpg" data-realtimefired="true" (click)="headerViewModel.onClickButtonAtMenuListModal()">
                <i class="icon icon-Servicelist"></i>
                <span class="nav-GlobalSideNavi-Text">メニュー<span class="is-NavNarrow">一覧</span></span>
            </a>
        </li>
    </ul>

    <p class="nav-GlobalSideNavi-Heading"><span>おすすめ情報</span></p>
    <p class="nav-GlobalSideNavi-Banner">
        <a href="javascript:void(0)" class="text-Nodeco text-Black" [onamaeRouterLink]="'/domain/setting/netdeshindan/domainselect'">
            <img class="gtmclick" data-gtmvalue="navi_gm_nds_bnr" src="{{CacheBusting('/Content/images/netDiagnosis/netDeShindan_header_banner.png')}}" alt="">
            <span class="nav-GlobalSideNavi-BannerText pt10 is-DisplayBlock text-Left gtmclick" data-gtmvalue="navi_gm_nds_bnr">
                セキュリティ診断ツール「ネットde診断」はこちらをクリック
            </span>
        </a>
    </p>
</nav>

<div class="popup is-GlobalSideNavi" dropdownFocusout-b id="globalSideNaviDomain" parent="#globalSideNaviDomainBtn">
    <div class="popup-Arrow"></div>
    <div class="popup-Content">
        <div class="popup-Body">
            <ul class="list mb0">
                <li><a href="javascript:void(0)" class="gtmclick" data-gtmvalue="navi_gm_renew" [onamaeRouterLink]="'/domain/setting/renew/list'"><i class="icon icon-ArrowCircleRight"></i>ドメイン更新</a></li>
                <li><a href="javascript:void(0)" class="gtmclick" data-gtmvalue="navi_gm_create" [onamaeRouterLink]="'/domain/search'"><i class="icon icon-ArrowCircleRight"></i>ドメイン登録</a></li>
                <li><a href="javascript:void(0)" class="gtmclick" data-gtmvalue="navi_gm_setting" [onamaeRouterLink]="'/domain/setting'"><i class="icon icon-ArrowCircleRight"></i>ドメイン機能一覧</a></li>
                <li><a href="javascript:void(0)" class="gtmclick" data-gtmvalue="navi_gm_domain" [onamaeRouterLink]="'/domain'"><i class="icon icon-ArrowCircleRight"></i>利用ドメイン一覧</a></li>
            </ul>
        </div>
    </div><!-- /.popup-Content -->
</div><!-- /.popup -->
<div class="popup is-GlobalSideNavi" dropdownFocusout-b id="globalSideNaviEmail" parent="#globalSideNaviEmailBtn">
    <div class="popup-Arrow"></div>
    <div class="popup-Content">
        <div class="popup-Body">
            <ul class="list mb0">
                <li><a href="javascript:void(0)" class="gtmclick" data-gtmvalue="navi_gm_om_pd_list" [onamaeRouterLink]="'/mail'"><i class="icon icon-ArrowCircleRight"></i>利用お名前メール 一覧</a></li>
            </ul>
        </div>
        <div class="popup-Footer">
            <p class="popup-Footer-Title"><span>サービス新規お申込み</span></p>
            <button type="button" class="btn is-Block is-Primary gtmclick" [attr.data-gtmvalue]="'navi_gm_om_pd_order'" (click)="onClickMailApplayButton()"><i class="icon icon-Plus"></i>お申込み</button>
        </div><!-- /.popup-Footer -->
    </div><!-- /.popup-Content -->
</div><!-- /.popup -->
<div class="popup is-GlobalSideNavi" dropdownFocusout-b id="globalSideNaviMicrosotf365" parent="#globalSideNaviMicrosotf365Btn">
    <div class="popup-Arrow"></div>
    <div class="popup-Content">
        <div class="popup-Body">
            <ul class="list mb0">
                <li><a href="javascript:void(0)" class="gtmclick" data-gtmvalue="navi_gm_m365_pd_list" [onamaeRouterLink]="'/office365'"><i class="icon icon-ArrowCircleRight"></i>ご利用中のOffice・プラン</a></li>
            </ul>
        </div>
        <div class="popup-Footer">
            <p class="popup-Footer-Title"><span>サービス新規お申込み</span></p>
            <button type="button" class="btn is-Block is-Primary gtmclick" [attr.data-gtmvalue]="'navi_gm_m365_pd_order'" (click)="onClickMicrosoftApplyButton()"><i class="icon icon-Plus"></i>お申込み</button>
        </div><!-- /.popup-Footer -->
    </div><!-- /.popup-Content -->
</div><!-- /.popup -->
<div class="popup is-GlobalSideNavi" dropdownFocusout-b id="globalSideNaviDNS" parent="#globalSideNaviDNSBtn">
    <div class="popup-Arrow"></div>
    <div class="popup-Content">
        <div class="popup-Body">
            <ul class="list mb0">
                <li><a href="javascript:void(0)" class="gtmclick" data-gtmvalue="navi_gm_dns_pd_dns_ns" (click)="onClickRedirectToNs()"><i class="icon icon-ArrowCircleRight"></i>ネームサーバーの変更</a></li>
                <li><a href="javascript:void(0)" class="gtmclick" data-gtmvalue="navi_gm_dns_pd_dns_manage" (click)="onClickDnsSettingButton()"><i class="icon icon-ArrowCircleRight"></i>ドメインのDNSレコード設定</a></li>
                <li><a href="javascript:void(0)" class="gtmclick" data-gtmvalue="navi_gm_dns_pd_dns_sd" [onamaeRouterLink]="'/dns/sd'"><i class="icon icon-ArrowCircleRight"></i>レンタルサーバーのDNSレコード設定</a></li>
            </ul>
        </div>
    </div><!-- /.popup-Content -->
</div><!-- /.popup -->
<div class="popup is-GlobalSideNavi" dropdownFocusout-b id="globalSideNaviRentalServer" parent="#globalSideNaviRentalServerBtn">
    <div class="popup-Arrow"></div>
    <div class="popup-Content">
        <div class="popup-Body">
            <ul class="list mb0">
                <li><a href="javascript:void(0)" class="gtmclick" data-gtmvalue="navi_gm_rs_pd_list" [onamaeRouterLink]="'/rs'"><i class="icon icon-ArrowCircleRight"></i>利用レンタルサーバー 一覧</a></li>
            </ul>
        </div>
        <div class="popup-Footer">
            <p class="popup-Footer-Title"><span>サービス新規お申込み</span></p>
            <button type="button" class="btn is-Block is-Primary gtmclick" [attr.data-gtmvalue]="'navi_gm_rs_pd_order'" (click)="onClickRsApplyButton()"><i class="icon icon-Plus"></i>お申込み</button>
        </div><!-- /.popup-Footer -->
    </div><!-- /.popup-Content -->
</div><!-- /.popup -->
<div class="popup is-GlobalSideNavi" dropdownFocusout-b id="globalSideNaviSSL" parent="#globalSideNaviSSLBtn">
    <div class="popup-Arrow"></div>
    <div class="popup-Content">
        <div class="popup-Body">
            <ul class="list mb0">
                <li><a href="javascript:void(0)" class="gtmclick" data-gtmvalue="navi_gm_ssl_pd_ssl_sd" [onamaeRouterLink]="'/ssl/sd'"><i class="icon icon-ArrowCircleRight"></i>レンタルサーバーSSLオプション</a></li>
                <li><a href="javascript:void(0)" class="gtmclick" data-gtmvalue="navi_gm_ssl_pd_ssl_new" (click)="onClickSslIssueButton()"><i class="icon icon-ArrowCircleRight"></i>利用ドメインSSL証明書発行</a></li>
                <li><a href="javascript:void(0)" class="gtmclick" data-gtmvalue="navi_gm_ssl_pd_order" [onamaeRouterLink]="'/ssl/coupon'"><i class="icon icon-ArrowCircleRight"></i>SSL証明書クーポン</a></li>
            </ul>
        </div>
    </div><!-- /.popup-Content -->
</div><!-- /.popup -->
<div class="popup is-GlobalSideNavi" dropdownFocusout-b id="globalSideNaviDC" parent="#globalSideNaviDCBtn">
    <div class="popup-Arrow"></div>
    <div class="popup-Content">
        <div class="popup-Body">
            <ul class="list mb0">
                <li><a href="javascript:void(0)"  class="gtmclick" data-gtmvalue="navi_gm_dc_pd_list" [onamaeRouterLink]="'/desktopcloud'"><i class="icon icon-ArrowCircleRight"></i>利用デスクトップクラウド 一覧</a></li>
                <li><a href="javascript:void(0)" [onamaeRouterLink]="'/desktopcloudoption'"><i class="icon icon-ArrowCircleRight"></i>デスクトップクラウドオプション</a></li>
            </ul>
        </div>
        <div class="popup-Footer">
            <p class="popup-Footer-Title"><span>サービス新規お申込み</span></p>
            <button type="button" class="btn is-Block is-Primary gtmclick" [attr.data-gtmvalue]="'navi_gm_dc_pd_order'" (click)="onClickDesktopApplyButton()"><i class="icon icon-Plus"></i>お申込み</button>
        </div><!-- /.popup-Footer -->
    </div><!-- /.popup-Content -->
</div><!-- /.popup -->
<div class="popup is-GlobalSideNavi is-GlobalSideNaviEtc" dropdownFocusout-b id="globalSideNaviEtc" parent="#globalSideNaviEtcBtn">
    <div class="popup-Arrow"></div>
    <div class="popup-Content">
        <div class="popup-Body">
            <ul class="list mb0">
                <li><a href="javascript:void(0)" [onamaeRouterLink]="'/mailmarketing'" class="gtmclick" data-gtmvalue="menutub_mm"><i class="icon icon-ArrowCircleRight"></i>メールマーケティング</a></li>
                <li><a href="javascript:void(0)" [onamaeRouterLink]="'/vps'" class="gtmclick" data-gtmvalue="menutub_vps"><i class="icon icon-ArrowCircleRight"></i>VPS（KVM）</a></li>
                <li><a href="javascript:void(0)" [onamaeRouterLink]="'/wpcloud'" class="gtmclick" data-gtmvalue="menutub_wpcloud"><i class="icon icon-ArrowCircleRight"></i>WP Cloud</a></li>
                <li><a href="javascript:void(0)" [onamaeRouterLink]="'/gs'" class="gtmclick" data-gtmvalue="menutub_gs"><i class="icon icon-ArrowCircleRight"></i>共用サーバー GS</a></li>
                <li><a href="javascript:void(0)" [onamaeRouterLink]="'/sitecreation'" class="gtmclick" data-gtmvalue="menutub_sitecreation"><i class="icon icon-ArrowCircleRight"></i>サイトクリエイションJr.</a></li>
                <li><a href="javascript:void(0)" [onamaeRouterLink]="'/wptemplate'" class="gtmclick" data-gtmvalue="menutub_wptemplate"><i class="icon icon-ArrowCircleRight"></i>WordPressテーマ</a></li>
                <li><a href="javascript:void(0)" [onamaeRouterLink]="'/sdweebly'" class="gtmclick" data-gtmvalue="menutub_sdweebly"><i class="icon icon-ArrowCircleRight"></i>ホームページエディター</a></li>
            </ul>
        </div>
    </div><!-- /.popup-Content -->
</div><!-- /.popup -->
<div class="popup is-GlobalSideNavi is-GlobalSideNaviSupport" dropdownFocusout-b id="globalSideNaviSupport" parent="#globalSideNaviSupportBtn">
    <div class="popup-Arrow"></div>
    <div class="popup-Content">
        <div class="popup-Body">
            <p class="dropdown-Hdn">お名前.com Naviガイド内を検索</p>
            <form (ngSubmit)="headerViewModel.onSearchGuideButtonClicked(guideForm)" #guideForm="ngForm" id="guideWordFormNaviSupport" novalidate >
                <div class="form-TextSearch">
                    <input type="text" class="form-Text" name="guideWord" placeholder="何かお困りですか？"
                           [(ngModel)]="headerViewModel.guideWord"
                           #guideWord="ngModel"
                           required
                           pattern="{{headerViewModel.rGuideFormPattern}}">
                    <button type="submit" class="form-TextSearch-Btn" [disabled]="guideForm.invalid"><i class="icon icon-Magnifier"></i></button>
                </div>
                <div *ngIf="(guideWord.errors && (guideWord.dirty || guideWord.touched))">
                    <label *ngIf="guideWord.errors != null && guideWord.errors.pattern" class="form-Error">
                        入力形式を確認してください
                    </label>
                </div>
            </form>
            <ul class="list mt8">
                <li><a href="javascript:void(0)" (click)="openUrl('https://www.onamae.com/guide/')" class="gtmclick" data-gtmvalue="newnavi_top_header_guide" data-realtimefired="true"><i class="icon icon-ArrowCircleRight gtmclick" data-gtmvalue="newnavi_top_header_guide" data-realtimefired="true"></i>お名前.com Naviガイド</a></li>
                <li><a href="javascript:void(0)" (click)="openUrl('https://www.onamae.com/help/')" class="gtmclick" data-gtmvalue="newnavi_top_header_help" data-realtimefired="true"><i class="icon icon-ArrowCircleRight gtmclick" data-gtmvalue="newnavi_top_header_help" data-realtimefired="true"></i>ヘルプサポート</a></li>
            </ul>
        </div>
    </div>
</div>

<div class="modal" id="modalMenuList" data-dismiss="modal" modal [openFlg]="headerViewModel.isShowMenuListModal" (click)="headerViewModel.onOutsideModalClicked($event)">
  <div class="modal-Dialog is-MenuList">
    <div class="modal-Dialog-Body">
      <div class="modal-Dialog-Grid">
        <div class="modal-Dialog-Grid-Column">
          <p class="modal-Dialog-MenuList-Title">TOP</p>
          <ul class="list-Normal">
            <li><a href="javascript:void(0)" [onamaeRouterLink]="'/top'" class="gtmclick" data-gtmvalue="header_allmenu_top">TOP</a></li>
            <li><a href="javascript:void(0)" [onamaeRouterLink]="'/top/message/category/important'" class="gtmclick" data-gtmvalue="header_allmenu_allmessage">お知らせ一覧</a></li>
          </ul>
          <p class="modal-Dialog-MenuList-Title">サービス一覧</p>
          <ul class="list-Normal">
            <li><a href="javascript:void(0)" [onamaeRouterLink]="'/domain'" class="gtmclick" data-gtmvalue="newnavi_top_header_allmenu_domain">ドメイン</a></li>
            <li><a href="javascript:void(0)" [onamaeRouterLink]="'/rs'" class="gtmclick" data-gtmvalue="newnavi_top_header_allmenu_sd">レンタルサーバー</a></li>
            <li><a href="javascript:void(0)" [onamaeRouterLink]="'/desktopcloud'" class="gtmclick" data-gtmvalue="newnavi_top_header_allmenu_desktopcloud">デスクトップクラウド</a></li>
            <li><a href="javascript:void(0)" [onamaeRouterLink]="'/office365'" class="gtmclick" data-gtmvalue="newnavi_top_header_allmenu_office365">Office</a></li>
            <li><a href="javascript:void(0)" [onamaeRouterLink]="'/mail'" class="gtmclick" data-gtmvalue="newnavi_top_header_allmenu_mail">お名前メール</a></li>
            <li><a href="javascript:void(0)" [onamaeRouterLink]="'/vps'" class="gtmclick" data-gtmvalue="newnavi_top_header_allmenu_vps">VPS（KVM）</a></li>
            <li><a href="javascript:void(0)" [onamaeRouterLink]="'/ssl'" class="gtmclick" data-gtmvalue="newnavi_top_header_allmenu_menutub_ssl">SSL証明書</a></li>
            <li><a href="javascript:void(0)" [onamaeRouterLink]="'/mailmarketing'" class="gtmclick" data-gtmvalue="newnavi_top_header_allmenu_mm">メールマーケティング</a></li>
            <li><a href="javascript:void(0)" [onamaeRouterLink]="'/wpcloud'" class="gtmclick" data-gtmvalue="newnavi_top_header_allmenu_wpcloud">WP Cloud</a></li>
            <li><a href="javascript:void(0)" [onamaeRouterLink]="'/gs'" class="gtmclick" data-gtmvalue="newnavi_top_header_allmenu_gs">共用サーバー GS</a></li>
            <li><a href="javascript:void(0)" [onamaeRouterLink]="'/sitecreation'" class="gtmclick" data-gtmvalue="newnavi_top_header_allmenu_sitecreation">サイトクリエイションJr.</a></li>
            <li><a href="javascript:void(0)" [onamaeRouterLink]="'/wptemplate'" class="gtmclick" data-gtmvalue="newnavi_top_header_allmenu_wptemplate">WordPressテーマ</a></li>
            <li><a href="javascript:void(0)" [onamaeRouterLink]="'/sdweebly'" class="gtmclick" data-gtmvalue="newnavi_top_header_allmenu_sdweebly">ホームページエディター</a></li>
          </ul>
        </div>
        <div class="modal-Dialog-Grid-Column">
          <p class="modal-Dialog-MenuList-Title">ドメイン</p>
          <div class="modal-Dialog-SubGrid">
            <div class="modal-Dialog-SubGrid-Column">
              <p class="modal-Dialog-MenuList-SubTitle">更新する</p>
              <ul class="list-Normal">
                <li><a href="javascript:void(0)" [onamaeRouterLink]="'/domain/setting/renew/list'" class="gtmclick" data-gtmvalue="header_allmenu_renew">ドメインの更新</a></li>
                <li><a href="javascript:void(0)" (click)="onClickDomainAutoRenew()" class="gtmclick">ドメイン自動更新設定</a></li>
                <li><a href="javascript:void(0)" [onamaeRouterLink]="'/domain/setting/restore'" class="gtmclick" data-gtmvalue="header_allmenu_restoration">廃止済みドメインの復旧</a></li>
              </ul>
              <p class="modal-Dialog-MenuList-SubTitle">登録する</p>
              <ul class="list-Normal">
                <li *ngIf="headerViewModel.ShowSitemapCreate">
                  <a href="javascript:void(0)" [onamaeRouterLink]="'/domain/search'" class="gtmclick" data-gtmvalue="header_allmenu_create">ドメインの登録</a>
                </li>
                <li *ngIf="headerViewModel.ShowSitemapPremiumCreate">
                  <a href="javascript:void(0)" (click)="onClickPremiumDomainCreate()" class="gtmclick" data-gtmvalue="header_allmenu_premium" data-realtimefired="true">プレミアムドメインの登録</a>
                </li>
                <li *ngIf="headerViewModel.ShowSitemapGtldAuction || headerViewModel.ShowSitemapJpAuction">
                  <a href="javascript:void(0)" (click)="openUrl('https://www.onamae.com/campaign/auction/')" class="gtmclick" data-gtmvalue="header_allmenu_domainauction" data-realtimefired="true">ドメインオークション</a>
                </li>
                <li *ngIf="headerViewModel.ShowSitemapBulkApplicationList">
                  <a href="javascript:void(0)" (click)="onClickBulkApplication()" class="gtmclick" data-gtmvalue="header_allmenu_bulksetmanage">「セット割」申請管理</a>
                </li>
                <li *ngIf="headerViewModel.ShowSitemapGtldBackorder">
                  <a href="javascript:void(0)" (click)="openUrl('https://www.onamae.com/backorder/')" class="gtmclick" data-gtmvalue="header_allmenu_gtldbackorder" data-realtimefired="true">ドメインの事前予約</a>
                </li>
                <li *ngIf="headerViewModel.ShowSitemapJpBackorder">
                  <a href="javascript:void(0)" (click)="openUrl('https://www.onamae.com/campaign/backorder/')" class="gtmclick" data-gtmvalue="header_allmenu_jpbackorder" data-realtimefired="true">.jpドメインの事前予約</a>
                </li>
                <li *ngIf="headerViewModel.ShowSitemapMonitoring">
                    <a href="javascript:void(0)" (click)="onClickDomainMonitoring()" class="gtmclick" data-gtmvalue="header_allmenu_moniter">ドメインモニタリング</a>
                 </li>
                <li *ngIf="headerViewModel.ShowSitemapEvidence">
                  <a href="javascript:void(0)" [onamaeRouterLink]="'/domain/setting/evidence/list'" class="gtmclick" data-gtmvalue="header_allmenu_evidence">Whois情報正確性確認</a>
                </li>
              </ul>
              <p class="modal-Dialog-MenuList-SubTitle">移管する</p>
              <ul class="list-Normal">
                <li *ngIf="headerViewModel.ShowSitemapTransferIn">
                  <a href="javascript:void(0)" [onamaeRouterLink]="'/domain/setting/transfer/approval'" class="gtmclick" data-gtmvalue="header_allmenu_transferin">お名前.comへの移管申請</a>
                </li>
                <li *ngIf="headerViewModel.ShowDomainTransferStatusList">
                  <a href="javascript:void(0)" [onamaeRouterLink]="'/domain/setting/transfer/list'" class="gtmclick" data-gtmvalue="header_allmenu_transferstatuslist">移管状況確認</a>
                </li>
                <li *ngIf="headerViewModel.ShowSitemapTransferLock">
                  <a href="javascript:void(0)" [onamaeRouterLink]="'/domain/setting/transfer/lock'" class="gtmclick" data-gtmvalue="header_allmenu_transferlock">ドメイン移管ロック</a>
                </li>
              </ul>
              <p class="modal-Dialog-MenuList-SubTitle">属性型JPドメインの設定</p>
              <ul class="list-Normal">
                <li *ngIf="headerViewModel.ShowSitemapCojpRegistration">
                  <a href="javascript:void(0)" [onamaeRouterLink]="'/domain/setting/cojpdomain/select'" class="gtmclick" data-gtmvalue="header_allmenu_cojpregistration">co.jpドメイン本登録申請</a>
                </li>
                <li *ngIf="headerViewModel.ShowSitemapJpInfoChange">
                  <a href="javascript:void(0)" [onamaeRouterLink]="'/domain/setting/jpdomainchange/select'" class="gtmclick" data-gtmvalue="header_allmenu_jpinfochange">属性型JPドメイン情報変更</a>
                </li>
                <li *ngIf="headerViewModel.ShowSitemapJpCreateCorrection">
                  <a href="javascript:void(0)" [onamaeRouterLink]="'/domain/setting/jpdomain/select'" class="gtmclick" data-gtmvalue="header_allmenu_jpcreatecorrection">属性型JP登録申請修正</a>
                </li>
              </ul>
            </div>
            <div class="modal-Dialog-SubGrid-Column">
              <p class="modal-Dialog-MenuList-SubTitle">ネームサーバー</p>
              <ul class="list-Normal">
                <li *ngIf="headerViewModel.ShowSitemapNsChange">
                  <a href="javascript:void(0)" (click)="onClickRedirectToNs()" class="gtmclick" data-gtmvalue="header_allmenu_nschange" data-realtimefired="true">ネームサーバーの変更</a>
                </li>
                <li *ngIf="headerViewModel.ShowSitemapDnsSetting">
                  <a href="javascript:void(0)" (click)="onClickDnsManage()" class="gtmclick" data-gtmvalue="header_allmenu_dnsmanage">DNS関連機能の設定</a>
                </li>
                <li *ngIf="headerViewModel.ShowSitemapDsRecord">
                  <a href="javascript:void(0)" (click)="onClickDsRecord()" class="gtmclick" data-gtmvalue="header_allmenu_dsrecord">DSレコード設定</a>
                </li>
              </ul>
              <p class="modal-Dialog-MenuList-SubTitle">登録情報</p>
              <ul class="list-Normal">
                <li *ngIf="headerViewModel.ShowSitemapWhoisInfoChange">
                  <a href="javascript:void(0)" [onamaeRouterLink]="'/domain/setting/whoischange/domainselect'" class="gtmclick" data-gtmvalue="header_allmenu_whoisinfochange">Whois情報変更</a>
                </li>
                <li *ngIf="headerViewModel.ShowSitemapTmch">
                  <a href="javascript:void(0)" (click)="onClickTmch()" class="gtmclick" data-gtmvalue="header_allmenu_tmch">TMCH管理</a>
                </li>
                <li *ngIf="headerViewModel.ShowSitemapIdReplace">
                  <a href="javascript:void(0)" [onamaeRouterLink]="'/domain/setting/switchid/input'" class="gtmclick" data-gtmvalue="header_allmenu_idswitch">お名前ID付け替え</a>
                </li>
              </ul>
              <p class="modal-Dialog-MenuList-SubTitle">Whois情報公開代行</p>
              <ul class="list-Normal">
                <li *ngIf="headerViewModel.ShowSitemapWhoisProxy">
                  <a href="javascript:void(0)" [onamaeRouterLink]="'/domain/setting/whoisprotect/list'" class="gtmclick" data-gtmvalue="header_allmenu_whoisproxy">Whois情報公開代行</a>
                </li>
                <li *ngIf="headerViewModel.ShowSitemapWhoisMailForward">
                  <a href="javascript:void(0)" [onamaeRouterLink]="'/domain/setting/whoisprotect/mailforward/list'" class="gtmclick" data-gtmvalue="header_allmenu_whoismailforward">メール転送オプション</a>
                </li>
              </ul>
              <p class="modal-Dialog-MenuList-SubTitle">ドメインプロテクション</p>
              <ul class="list-Normal">
                <li *ngIf="headerViewModel.ShowSitemapDomainProtectInput">
                  <a href="javascript:void(0)" (click)="onClickDomainProtectInput()" class="gtmclick" data-gtmvalue="header_allmenu_protection">申請/解除</a>
                </li>
                <li *ngIf="headerViewModel.ShowSitemapDomainProtectUpdateInput">
                  <a href="javascript:void(0)" (click)="onClickDomainProtectUpdateInput()" class="gtmclick" data-gtmvalue="header_allmenu_protectiondetail">詳細設定</a>
                </li>
              </ul>
              <p class="modal-Dialog-MenuList-SubTitle">転送Plus</p>
              <ul class="list-Normal">
                <li *ngIf="headerViewModel.ShowSitemapMailForward">
                  <a href="javascript:void(0)" (click)="onClickMailForward()" class="gtmclick" data-gtmvalue="header_allmenu_mailforward">メール転送</a>
                </li>
                <li *ngIf="headerViewModel.ShowSitemapUrlForward">
                  <a href="javascript:void(0)" (click)="onClickURLForward()" class="gtmclick" data-gtmvalue="header_allmenu_webforward">URL転送</a>
                </li>
              </ul>
              <p class="modal-Dialog-MenuList-SubTitle">SSLサーバー証明書</p>
              <ul class="list-Normal">
                <li *ngIf="headerViewModel.ShowSitemapSetting_Ssl">
                  <a href="javascript:void(0)" (click)="onClickSslNew()" class="gtmclick" data-gtmvalue="header_allmenu_sslcreate" data-realtimefired="true">証明書発行</a>
                </li>
                <li *ngIf="headerViewModel.ShowSitemapSslManage">
                  <a href="javascript:void(0)" (click)="onClickSslSelect()" class="gtmclick" data-gtmvalue="header_allmenu_sslmanage">証明書管理</a>
                </li>
              </ul>
              <p class="modal-Dialog-MenuList-SubTitle">あんしん廃止サービス</p>
              <ul class="list-Normal">
                <li *ngIf="headerViewModel.ShowSitemapDelService">
                  <a href="javascript:void(0)" [onamaeRouterLink]="'/domain/setting/delservice/list'" class="gtmclick" data-gtmvalue="header_allmenu_delservice">申込み</a>
                </li>
                <li *ngIf="headerViewModel.ShowSitemapDelServiceReport">
                  <a href="javascript:void(0)" [onamaeRouterLink]="'/domain/setting/delservice/report/list'" class="gtmclick" data-gtmvalue="header_allmenu_delservice_report">レポート</a>
                </li>
              </ul>
              <p class="modal-Dialog-MenuList-SubTitle">ネットde診断</p>
              <ul class="list-Normal">
                <li><a href="javascript:void(0)" [onamaeRouterLink]="'/domain/setting/netdeshindan/domainselect'" class="gtmclick" data-gtmvalue="header_allmenu_nds">診断画面</a></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="modal-Dialog-Grid-Column">
          <p class="modal-Dialog-MenuList-Title">お客様情報管理</p>
          <p class="modal-Dialog-MenuList-SubTitle">会員情報</p>
          <ul class="list-Normal">
            <li><a href="javascript:void(0)" [onamaeRouterLink]="'/account/detail/edit'" class="gtmclick" data-gtmvalue="header_allmenu_account_accountinfo">会員情報の確認/変更</a></li>
            <li *ngIf="headerViewModel.isShowDeliverySettingLink"><a href="javascript:void(0)" [onamaeRouterLink]="'/account/detail/edit/deliverySetting'" class="gtmclick" data-gtmvalue="header_allmenu_account_deliverysetting">お知らせ配信設定</a></li>
            <li><a href="javascript:void(0)" [onamaeRouterLink]="'/account/contact'" class="gtmclick" data-gtmvalue="header_allmenu_account_contactinfo">コンタクト情報の変更</a></li>
          </ul>
          <p class="modal-Dialog-MenuList-SubTitle">ログイン情報</p>
          <ul class="list-Normal">
            <li><a href="javascript:void(0)" [onamaeRouterLink]="'/account/password'" class="gtmclick" data-gtmvalue="header_allmenu_account_pwinfo">パスワードの変更</a></li>
            <li><a href="javascript:void(0)" [onamaeRouterLink]="'/account/loginsecurity'" class="gtmclick" data-gtmvalue="header_allmenu_account_loginsecurity">ログインセキュリティ</a></li>
          </ul>
          <p class="modal-Dialog-MenuList-SubTitle">お支払い・請求情報（ドメイン）</p>
          <ul class="list-Normal">
            <li *ngIf="headerViewModel.ShowSitemapPaymentInfoDomain">
              <a href="javascript:void(0)" [onamaeRouterLink]="'/payment/detail/domain'" class="gtmclick" data-gtmvalue="header_allmenu_paymentinfo_domain">お支払情報</a>
            </li>
            <li><a href="javascript:void(0)" [onamaeRouterLink]="'/payment/invoice/list/domain'" class="gtmclick" data-gtmvalue="header_allmenu_invoicelist_domain">ご請求情報</a></li>
          </ul>
          <p class="modal-Dialog-MenuList-SubTitle">お支払い・請求情報（サーバー）</p>
          <ul class="list-Normal">
            <li><a href="javascript:void(0)" [onamaeRouterLink]="'/payment/detail/hosting'" class="gtmclick" data-gtmvalue="header_allmenu_paymentinfo_hosting">お支払情報</a></li>
            <li><a href="javascript:void(0)" [onamaeRouterLink]="'/payment/invoice/list/hosting'" class="gtmclick" data-gtmvalue="header_allmenu_invoicelist_hosting">ご請求情報</a></li>
            <li><a href="javascript:void(0)" [onamaeRouterLink]="'/payment/lumpsum/list'" class="gtmclick" data-gtmvalue="header_allmenu_onetimepaymentsinfo_hosting">まとめ払い設定</a></li>
          </ul>
          <p class="modal-Dialog-MenuList-Title">サポート</p>
          <ul class="list-Normal">
            <li><a href="javascript:void(0)" (click)="openUrl('https://www.onamae.com/guide/')" class="gtmclick" data-gtmvalue="header_allmenu_guide" data-realtimefired="true">お名前.comガイド</a></li>
            <li><a href="javascript:void(0)" (click)="openUrl('https://help.onamae.com/')" class="gtmclick" data-gtmvalue="header_allmenu_help" data-realtimefired="true">ヘルプ</a></li>
          </ul>
          <p class="modal-Dialog-MenuList-Title">その他</p>
          <ul class="list-Normal">
            <li><a href="javascript:void(0)" [onamaeRouterLink]="'/secondhanddealerlicense'" class="gtmclick" data-gtmvalue="header_allmenu_secondhand_dealer_license">古物商許可申請（利用証明書発行）</a></li>
            <li><a href="javascript:void(0)" [onamaeRouterLink]="'/domain/service'" class="gtmclick" data-gtmvalue="header_allmenu_serviceregist">新規サービス申込み</a></li>
            <li><a href="javascript:void(0)" [onamaeRouterLink]="'/webacademy/bookmark'" class="gtmclick" data-gtmvalue="newnavi_top_header_allmenu_webacademy_bookmark">Web学園お気に入り一覧</a></li>
          </ul>
        </div>
      </div>
    </div><!-- /.modal-Dialog-Body -->

    <button type="button" class="modal-Close" data-dismiss="modal" (click)="headerViewModel.onClickCloseButtonAtMenuListModal()"><i class="icon icon-Clear" aria-label="閉じる"></i></button>
  </div><!-- /.modal-Dialog -->
  </div>

  <div class="dropdown" id="accountMenu">
    <div class="dropdown-Main">
      <dl class="list-Def mb0">
        <dt>会員情報変更</dt>
        <dd>
          <ul class="list-Link mb0">
            <li><a href="javascript:void(0)" [onamaeRouterLink]="'/account/detail/edit'" class="gtmclick" data-gtmvalue="header_account_accountinfo">会員情報の確認/変更</a></li>
            <li *ngIf="headerViewModel.isShowDeliverySettingLink"><a href="javascript:void(0)" [onamaeRouterLink]="'/account/detail/edit/deliverySetting'" class="gtmclick" data-gtmvalue="header_account_deliverysetting">お知らせ/メール配信設定</a></li>
            <li><a href="javascript:void(0)" [onamaeRouterLink]="'/account/password'" class="gtmclick" data-gtmvalue="header_account_pwinfo">パスワードの変更</a></li>
            <li><a href="javascript:void(0)" [onamaeRouterLink]="'/account/contact'" class="gtmclick" data-gtmvalue="header_account_contactinfo">コンタクト情報の変更</a></li>
          </ul>
        </dd>
        <dt *ngIf="headerViewModel.viewHiddenInvoice == false">お支払情報（ドメイン）</dt>
        <dd *ngIf="headerViewModel.viewHiddenInvoice == false">
          <ul class="list-Link mb0">
            <li><a href="javascript:void(0)" [onamaeRouterLink]="'/payment/detail/domain'" class="gtmclick" data-gtmvalue="header_account_paymentinfo_domain">お支払情報</a></li>
            <li>
              <a href="javascript:void(0)" [onamaeRouterLink]="'/payment/invoice/list/domain'" class="gtmclick" data-gtmvalue="header_account_invoicelist_domain">ご請求情報</a>
            </li>
          </ul>
        </dd>
        <dt *ngIf="headerViewModel.viewHiddenInvoice == false">お支払情報（サーバー）</dt>
        <dd *ngIf="headerViewModel.viewHiddenInvoice == false">
          <ul class="list-Link mb0">
            <li><a href="javascript:void(0)" [onamaeRouterLink]="'/payment/detail/hosting'" class="gtmclick" data-gtmvalue="header_account_paymentinfo_hosting">お支払情報</a></li>
            <li>
              <a href="javascript:void(0)" [onamaeRouterLink]="'/payment/invoice/list/hosting'" class="gtmclick" data-gtmvalue="header_account_invoicelist_hosting">ご請求情報</a>
            </li>
            <li><a href="javascript:void(0)" [onamaeRouterLink]="'/payment/lumpsum/confirm'" class="gtmclick" data-gtmvalue="header_account_onetimepaymentsinfo_hosting">まとめ払い設定</a></li>
          </ul>
        </dd>
      </dl>
    </div>
    <div class="dropdown-Footer text-Center">
      <a href="javascript:void(0)" class="text-Nodeco text-Bold gtmclick" data-gtmvalue="header_account_logout" (click)="headerViewModel.clickLogoutConfirm()"><i class="icon icon-Logout mr8"></i>ログアウト</a>
    </div>
  </div>

  <div class="dropdown is-Search" id="supportMenu">
    <div class="dropdown-Main">
      <p class="dropdown-Hdn">お名前.com Naviガイド内を検索</p>
      <form (ngSubmit)="headerViewModel.onSearchGuideButtonClicked(guideForm)" #guideForm="ngForm" novalidate>
        <div class="form-TextSearch">
          <input type="text" class="form-Text" name="guideWord" placeholder="何かお困りですか？"
                 [(ngModel)]="headerViewModel.guideWord"
                 #guideWord="ngModel"
                 required
                 pattern="{{headerViewModel.rGuideFormPattern}}">
          <button type="submit" class="form-TextSearch-Btn" [disabled]="guideForm.invalid"><i class="icon icon-Magnifier"></i></button>
        </div>
        <div *ngIf="(guideWord.errors && (guideWord.dirty || guideWord.touched))">
          <label *ngIf="guideWord.errors != null && guideWord.errors.pattern" class="form-Error">
            入力形式を確認してください
          </label>
        </div>
      </form>
      <ul class="list-Link mt10 mb0">
        <li><a href="javascript:void(0)" (click)="openUrl('https://www.onamae.com/guide/')" class="gtmclick" data-gtmvalue="newnavi_top_header_guide">お名前.com Naviガイド</a></li>
        <li><a href="javascript:void(0)" (click)="openUrl('https://www.onamae.com/help/')" class="gtmclick" data-gtmvalue="newnavi_top_header_help">ヘルプ</a></li>
      </ul>
    </div>
  </div>

  <div class="dropdown is-HeaderFunctionAdmin" id="accountMenuABCTestB">
    <div class="dropdown-Main">
      <p><a href="javascript:void(0)" (click)="headerViewModel.onClickAccountManagement()"><i class="icon icon-ArrowCircleRight"></i>お客様情報管理</a></p>
      <p><a href="javascript:void(0)" (click)="headerViewModel.clickLogoutConfirm()"><i class="icon icon-ArrowCircleRight"></i>ログアウト</a></p>
    </div>
  </div>

  <!--その他 -->
  <div class="popup is-GlobalNav is-Bottom dropdown is-Hover" dropdownFocusout parent="#globalEtcBtn" id="globalNavEtc">
    <div class="popup-Content">
      <div class="popup-Body">
        <ul class="list mb0">
          <li><a href="javascript:void(0)" [onamaeRouterLink]="'/mailmarketing'" class="gtmclick" data-gtmvalue="menutub_mm"><i class="icon icon-ArrowCircleRight"></i>メールマーケティング</a></li>
          <li><a href="javascript:void(0)" [onamaeRouterLink]="'/vps'" class="gtmclick" data-gtmvalue="menutub_vps"><i class="icon icon-ArrowCircleRight"></i>VPS（KVM）</a></li>
          <li><a href="javascript:void(0)" [onamaeRouterLink]="'/wpcloud'" class="gtmclick" data-gtmvalue="menutub_wpcloud"><i class="icon icon-ArrowCircleRight"></i>WP Cloud</a></li>
          <li><a href="javascript:void(0)" [onamaeRouterLink]="'/gs'" class="gtmclick" data-gtmvalue="menutub_gs"><i class="icon icon-ArrowCircleRight"></i>共用サーバー GS</a></li>
          <li><a href="javascript:void(0)" [onamaeRouterLink]="'/sitecreation'" class="gtmclick" data-gtmvalue="menutub_sitecreation"><i class="icon icon-ArrowCircleRight"></i>サイトクリエイションJr.</a></li>
          <li><a href="javascript:void(0)" [onamaeRouterLink]="'/wptemplate'" class="gtmclick" data-gtmvalue="menutub_wptemplate"><i class="icon icon-ArrowCircleRight"></i>WordPressテーマ</a></li>
          <li><a href="javascript:void(0)" [onamaeRouterLink]="'/sdweebly'" class="gtmclick" data-gtmvalue="menutub_sdweebly"><i class="icon icon-ArrowCircleRight"></i>ホームページエディター</a></li>
        </ul>
      </div>
    </div><!-- /.popup-Content -->
  </div><!-- /.popup -->
  <!--その他 -->
  <!--レンタルサーバー -->
  <div class="popup is-GlobalNav dropdown is-Hover" dropdownFocusout parent="#globalRsBtn" id="globalNavRentalServer">
    <div class="popup-Content">
      <div class="popup-Body">
        <ul class="list mb0">
          <li><a href="javascript:void(0)" class="gtmclick" data-gtmvalue="navi_gm_rs_pd_list" [onamaeRouterLink]="'/rs'"><i class="icon icon-ArrowCircleRight"></i>利用レンタルサーバー 一覧</a></li>
        </ul>
      </div>
      <div class="popup-Footer">
        <p class="popup-Footer-Title"><span>サービス新規お申込み</span></p>
        <button type="button" (click)="onClickRsApplyButton()" class="btn is-Block is-Primary gtmclick" [attr.data-gtmvalue]="'navi_gm_rs_pd_order'"><i class="icon icon-Plus"></i>お申込み</button>
      </div><!-- /.popup-Footer -->
    </div><!-- /.popup-Content -->
  </div><!-- /.popup -->
  <!--レンタルサーバー -->
  <!--ドメイン -->
  <div class="popup is-GlobalNav dropdown is-Hover" id="globalNav2Domain" dropdownFocusout parent="#globalDomainBtn" id="globalNavDomain">
    <div class="popup-Content">
      <div class="popup-Body">
        <ul class="list mb0">
          <li><a href="javascript:void(0)" class="gtmclick" data-gtmvalue="navi_gm_renew" [onamaeRouterLink]="'/domain/setting/renew/list'"><i class="icon icon-ArrowCircleRight"></i>ドメイン更新</a></li>
          <li><a href="javascript:void(0)" class="gtmclick" data-gtmvalue="navi_gm_create" [onamaeRouterLink]="'/domain/search'"><i class="icon icon-ArrowCircleRight"></i>ドメイン登録</a></li>
          <li><a href="javascript:void(0)" class="gtmclick" data-gtmvalue="navi_gm_setting" [onamaeRouterLink]="'/domain/setting'"><i class="icon icon-ArrowCircleRight"></i>ドメイン機能一覧</a></li>
          <li><a href="javascript:void(0)" class="gtmclick" data-gtmvalue="navi_gm_domain" [onamaeRouterLink]="'/domain'"><i class="icon icon-ArrowCircleRight"></i>利用ドメイン一覧</a></li>
        </ul>
      </div>
    </div><!-- /.popup-Content -->
  </div><!-- /.popup -->
  <!--ドメイン -->
  <!--デスクトップクラウド -->
  <div class="popup is-GlobalNav dropdown is-Hover" dropdownFocusout parent="#globalDcBtn" id="globalNavDC">
    <div class="popup-Content">
      <div class="popup-Body">
        <ul class="list mb0">
          <li><a href="javascript:void(0)" class="gtmclick" data-gtmvalue="navi_gm_dc_pd_list" [onamaeRouterLink]="'/desktopcloud'"><i class="icon icon-ArrowCircleRight"></i>利用デスクトップクラウド 一覧</a></li>
          <li><a href="javascript:void(0)" [onamaeRouterLink]="'/desktopcloudoption'"><i class="icon icon-ArrowCircleRight"></i>デスクトップクラウドオプション</a></li>
        </ul>
      </div>
      <div class="popup-Footer">
        <p class="popup-Footer-Title"><span>サービス新規お申込み</span></p>
        <button type="button" (click)="onClickDesktopApplyButton()" class="btn is-Block is-Primary gtmclick" [attr.data-gtmvalue]="'navi_gm_dc_pd_order'"><i class="icon icon-Plus"></i>お申込み</button>
      </div><!-- /.popup-Footer -->
    </div><!-- /.popup-Content -->
  </div><!-- /.popup -->
  <!--デスクトップクラウド -->
  <!--DNS -->
  <div class="popup is-GlobalNav dropdown is-Hover" dropdownFocusout parent="#globalDnsBtn" id="globalNavDNS">
    <div class="popup-Content">
      <div class="popup-Body">
        <ul class="list mb0">
          <li><a href="javascript:void(0)" class="gtmclick" data-gtmvalue="navi_gm_dns_pd_dns_manage" (click)="onClickDnsSettingButton()"><i class="icon icon-ArrowCircleRight"></i>ドメインのDNS設定</a></li>
          <li><a href="javascript:void(0)" class="gtmclick" data-gtmvalue="navi_gm_dns_pd_dns_sd" [onamaeRouterLink]="'/dns/sd'"><i class="icon icon-ArrowCircleRight"></i>レンタルサーバー利用ドメインのDNS設定</a></li>
          <li><a href="javascript:void(0)" class="gtmclick" data-gtmvalue="navi_gm_dns_pd_dns_ns" (click)="onClickRedirectToNs()"><i class="icon icon-ArrowCircleRight"></i>ネームサーバーの設定</a></li>
        </ul>
      </div>
    </div><!-- /.popup-Content -->
  </div><!-- /.popup -->
  <!--DNS -->
  <!--Mail -->
  <div class="popup is-GlobalNav dropdown is-Hover" dropdownFocusout parent="#globalMailBtn" id="globalNavMail">
    <div class="popup-Content">
      <div class="popup-Body">
        <ul class="list mb0">
          <li><a href="javascript:void(0)" class="gtmclick" data-gtmvalue="navi_gm_om_pd_list" [onamaeRouterLink]="'/mail'"><i class="icon icon-ArrowCircleRight"></i>利用お名前メール 一覧</a></li>
        </ul>
      </div>
      <div class="popup-Footer">
        <p class="popup-Footer-Title"><span>サービス新規お申込み</span></p>
        <button type="button" (click)="onClickMailApplayButton()" class="btn is-Block is-Primary gtmclick" [attr.data-gtmvalue]="'navi_gm_om_pd_order'"><i class="icon icon-Plus"></i>お申込み</button>
      </div><!-- /.popup-Footer -->
    </div><!-- /.popup-Content -->
  </div><!-- /.popup -->
  <!--Mail -->
  <!--SSL -->
  <div class="popup is-GlobalNav dropdown is-Hover" dropdownFocusout parent="#globalSslBtn" id="globalNavSSL">
    <div class="popup-Content">
      <div class="popup-Body">
        <ul class="list mb0">
          <li><a href="javascript:void(0)" class="gtmclick" data-gtmvalue="navi_gm_ssl_pd_ssl_sd" [onamaeRouterLink]="'/ssl/sd'"><i class="icon icon-ArrowCircleRight"></i>レンタルサーバーSSLオプション</a></li>
          <li><a href="javascript:void(0)" class="gtmclick" data-gtmvalue="navi_gm_ssl_pd_ssl_new" (click)="onClickSslIssueButton()"><i class="icon icon-ArrowCircleRight"></i>利用ドメインSSL証明書発行</a></li>
          <li><a href="javascript:void(0)" class="gtmclick" data-gtmvalue="navi_gm_ssl_pd_order" [onamaeRouterLink]="'/ssl/coupon'"><i class="icon icon-ArrowCircleRight"></i>SSL証明書クーポン</a></li>
        </ul>
      </div>
    </div><!-- /.popup-Content -->
  </div><!-- /.popup -->
  <!--Microsoft Office -->
  <div class="popup is-GlobalNav dropdown is-Hover" dropdownFocusout parent="#globalMsBtn" id="globalNavMS">
    <div class="popup-Content">
      <div class="popup-Body">
        <ul class="list mb0">
          <li><a href="javascript:void(0)" class="gtmclick" data-gtmvalue="navi_gm_m365_pd_list" [onamaeRouterLink]="'/office365'"><i class="icon icon-ArrowCircleRight"></i>ご利用中のOffice・プラン</a></li>
        </ul>
      </div>
      <div class="popup-Footer">
        <p class="popup-Footer-Title"><span>サービス新規お申込み</span></p>
        <button type="button" (click)="onClickMicrosoftApplyButton()" class="btn is-Block is-Primary gtmclick" [attr.data-gtmvalue]="'navi_gm_m365_pd_order'"><i class="icon icon-Plus"></i>お申込み</button>
      </div><!-- /.popup-Footer -->
    </div><!-- /.popup-Content -->
  </div><!-- /.popup -->
  <!--Microsoft Office -->

  <withdrawal-prevention-modal [isDisplay]="headerViewModel.isShowWithdrawalPreventionModal"
                               [domainList]="headerViewModel.domainList"
                               (onClickReturn)="headerViewModel.onClickReturnButton()"
                               (onClickConfirm)="headerViewModel.onClickConfirmButton()"
                               (onClickClose)="headerViewModel.onClickCloseButtonAtWithdrawalPreventionModal()"></withdrawal-prevention-modal>

  <loading [loading]="headerViewModel.isLoading || isLoading" [entireLoading]="isAllLoading"></loading>
