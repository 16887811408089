/// <reference path='../../../definitions/window.d.ts' />

import { AfterViewInit, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';

import { ServiceContainer } from 'Content/script/service/service_container';
import { ValidationRegexsDefine } from 'Content/script/libs/define/validation_regexs.define';

/**
 * ドメイン 検索 ドロップダウン
 */
@Component({
    selector: 'domain_search_dropdown',
    templateUrl: './domain_search_dropdown.html'
})

export class DomainSearchDropdownComponent implements OnChanges, AfterViewInit {
    public viewModel: DomainSearchDropdownViewModel;
    @Input() isOwnedMoreThanFiveDomain: boolean = false;
    @Input() isSslSearch: boolean = false;
    @Input() isQuick: boolean = false;

    public constructor(private router: Router, private serviceContainer: ServiceContainer) {
        const queueId = serviceContainer.HubsService.multipleSendManager.GetComponentId();
        this.viewModel = new DomainSearchDropdownViewModel(this.router, queueId, this.serviceContainer);
    }
    ngAfterViewInit(): void {
        this.viewModel.isQuick = this.isQuick;
        if (!this.viewModel.isQuick && !this.viewModel.isInit) {
            this.viewModel.getDomainGroupNames();
            this.viewModel.isInit = true;
        } 
    }
    ngOnChanges(changes: SimpleChanges): void {
        this.viewModel.isQuick = this.isQuick;
        if (!this.viewModel.isQuick && !this.viewModel.isInit) {
            this.viewModel.getDomainGroupNames();
            this.viewModel.isInit = true;
        } 
    }
}

export class DomainSearchDropdownViewModel {
    private _searchCallBack: () => void;
 
    public isDisableView: boolean;

    public isDisableExpirationDate: boolean = false;

    protected searchConditionConfig: DomainSearchConditionConfig;

    protected searchCondition: DomainSearchCondition;

    protected readonly rDomainPattern: string = ValidationRegexsDefine.rDomainPattern;

    protected isClickNewOptionSearch: boolean = false;
    public domainNameGtm: string = '';
    public groupNameGtm: string = '';
    public whoIsGtm: string = '';
    public createDateGtm: string = '';
    public expDateGtm: string = '';

    public titleDomainSearch: string = '';
    public titleExDateSearch: string = '';
    public titleCreateDateSearch: string = '';

    public isDisableExDateTo: boolean = false;
    public isDisableExDateFrom: boolean = false;

    public isQuick: boolean = false;
    public isInit: boolean = false;
    public constructor(
        private router: Router,
        private queueId: number,
        private serviceContainer: ServiceContainer
    ) {
        this._searchCallBack = null;

        this.isDisableView = false;

        this.searchConditionConfig = new DomainSearchConditionConfig();

        this.searchCondition = new DomainSearchCondition();
       
        this._getDomainWhoisProxyStatus();
    }

    /**
     * 検索時のコールバック関数を設定
     * @param searchCallBack 検索を実行した際のコールバック関数
     */
    public setCallBack(searchCallBack: () => void): void {
        this._searchCallBack = searchCallBack;
    }

    /**
     * 検索を無効にするかを指定
     * @param isDisableView
     */
    public setIsDisableView(isDisableView: boolean): void {
        this.isDisableView = isDisableView;
    }

    /**
    * 検索条件の対象を設定
    * @param searchConditionConfig
    */
    public setDomainSearchConditionConfig(searchConditionConfig: DomainSearchConditionConfig): void {
        this.searchConditionConfig = searchConditionConfig;
    }

    /**
     * 検索する条件を設定
     * @param searchCondition
     */
    public setDomainSearchCondition(searchCondition: DomainSearchCondition): void {
        this.searchCondition = searchCondition;
    }

    /**
    * 検索する条件を設定
    */
    public setAnotherSelectList(list: DomainSearchSelectItem[], text: string) {
        this.searchCondition.anotherSelectItem = new AnotherSelectItem(list, text);
    }

    /**
    * 検索する条件を設定
    */
    public setTitleSearch(titleDomain: string, titleExDate: string, titleCreateDate: string) {
        this.titleDomainSearch = titleDomain;
        this.titleCreateDateSearch = titleCreateDate;
        this.titleExDateSearch = titleExDate;
    }

    /**
    * 検索する条件を設定
    */
    public setDisableExDate(from: boolean, to: boolean) {
        this.isDisableExDateFrom = from;
        this.isDisableExDateTo = to;
    }

    /**
     * 検索する条件を取得
     */
    public getDomainSearchCondition(): DomainSearchCondition {
        return this.searchCondition;
    }

    /**
    * 検索する条件を取得
    */
    public getNewOptionSearch(): boolean {
        return this.isClickNewOptionSearch;
    }

    /**
     * 検索
     * @param searchCondition
     */
    private _search(): void {
        if (this._searchCallBack) {
            this._searchCallBack();
        }
    }

    /**
     * 検索用のグループ名を取得する
     */
    public getDomainGroupNames(): void {
        const req: DomainGroupListRequestHubDto = {
            CurrentPage: 1,
            PageSize: 100,
            GroupName: ''
        };

        this.serviceContainer.HubsService.multipleSendManager.Send(
            this.queueId,
            this.serviceContainer.HubsService.hubsManager.domainHubManager.DomainGroupList,
            req,
            (data: DomainGroupListResponseHubDto) => {
                this.searchCondition.groupNameSelectItems = [];
                for (let domainGroup of data.DomainGroupList) {
                    const domainSearchSelectItem: DomainSearchSelectItem = new DomainSearchSelectItem(domainGroup.GroupId, domainGroup.GroupName);
                    this.searchCondition.groupNameSelectItems.push(domainSearchSelectItem);
                }
            },
            (data: DomainGroupListResponseHubDto) => {
                this.searchCondition.groupNameSelectItems = [];
            },
            null,
            null,
            null
        );
    }

    /**
     * 検索用のWhois公開代行を取得する
     */
    private _getDomainWhoisProxyStatus(): void {
        this.searchCondition.whoisProxyStatusSelectItems = [
            new DomainSearchSelectItem('BOTH', ''),
            new DomainSearchSelectItem('ACTIVE', '設定あり'),
            new DomainSearchSelectItem('INVALID', '設定なし'),
        ];

        this.searchCondition.whoisProxyStatus = this.searchCondition.whoisProxyStatusSelectItems[0].value;
    }

    /**
     * グループ名選択
     * @param event
     */
    public onChangeSearchDomainGroup(event: Event): void {
        const currentElement: HTMLInputElement = event.currentTarget as HTMLInputElement
        const domainGroupId: string = currentElement.value;
        this.searchCondition.domainGroupId = domainGroupId;

        const groupNameSelectItems: DomainSearchSelectItem = this.searchCondition.groupNameSelectItems
            .find(groupNameSelectItem => {
                return groupNameSelectItem.value === domainGroupId
            });
        this.searchCondition.domainGroupName = groupNameSelectItems !== void 0 ? groupNameSelectItems.text : '';
        this.groupNameGtm = '';
        if (this.searchCondition.domainGroupName && this.searchCondition.domainGroupName !== '') {
            this.groupNameGtm = 'groupname';
        }
    }

    /**
     * Whois公開代行選択
     * @param event
     */
    public onChangeSearchWhoisProxyStatus(event: Event): void {
        const currentElement: HTMLInputElement = event.currentTarget as HTMLInputElement
        const whoisProxyStatus: string = currentElement.value;
        this.searchCondition.whoisProxyStatus = whoisProxyStatus;
        this.whoIsGtm = '';
        if (this.searchCondition.whoisProxyStatus && this.searchCondition.whoisProxyStatus === 'ACTIVE') {
            this.whoIsGtm = 'whois';
        }
    }

    /**
    * 条件から項目を選ぶ
    * @param event
    */
    public onChangeSearchAnotherSelectItemStatus(event: Event): void {
        const currentElement: HTMLInputElement = event.currentTarget as HTMLInputElement
        const anotherSelectItemStr: string = currentElement.value;
        this.searchCondition.anotherSelectItemValue = anotherSelectItemStr;
    }

    /**
     * カレンダー(登録日)
     * @param event
     */
    protected onChangeCreatedDateFrom(event: Event): void {
        const currentElement: HTMLInputElement = event.currentTarget as HTMLInputElement
        this.searchCondition.createdDateFrom = currentElement.value;
        this.createDateGtm = '';
        if (this.searchCondition.createdDateFrom && this.searchCondition.createdDateFrom !== '') {
            this.createDateGtm = 'createdate';
        }
    }

    /**
     * カレンダー(登録日)
     * @param event
     */
    protected onChangeCreatedDateTo(event: Event): void {
        const currentElement: HTMLInputElement = event.currentTarget as HTMLInputElement
        this.searchCondition.createdDateTo = currentElement.value;
        this.createDateGtm = '';
        if (this.searchCondition.createdDateTo && this.searchCondition.createdDateTo !== '') {
            this.createDateGtm = 'createdate';
        }
    }

    /**
     * カレンダー(登録期限日)
     * @param event
     */
    protected onChangeExpirationDateFrom(event: Event): void {
        const currentElement: HTMLInputElement = event.currentTarget as HTMLInputElement
        this.searchCondition.expirationDateFrom = currentElement.value;
        this.expDateGtm = '';
        if (this.searchCondition.expirationDateFrom && this.searchCondition.expirationDateFrom !== '') {
            this.expDateGtm = 'expdate';
        }
    }

    /**
     * カレンダー(登録期限日)
     * @param event
     */
    protected onChangeExpirationDateTo(event: Event): void {
        const currentElement: HTMLInputElement = event.currentTarget as HTMLInputElement
        this.searchCondition.expirationDateTo = currentElement.value;
        this.expDateGtm = '';
        if (this.searchCondition.expirationDateTo && this.searchCondition.expirationDateTo !== '') {
            this.expDateGtm = 'expdate';
        }
    }

    /**
     * カレンダー(申請日)
     * @param event
     */
    protected onChangeRequestDateFrom(event: Event): void {
        const currentElement: HTMLInputElement = event.currentTarget as HTMLInputElement
        this.searchCondition.requestDateFrom = currentElement.value;
    }

    /**
     * カレンダー(申請日)
     * @param event
     */
    protected onChangeRequestDateTo(event: Event): void {
        const currentElement: HTMLInputElement = event.currentTarget as HTMLInputElement
        this.searchCondition.requestDateTo = currentElement.value;
    }

    /**
     * クリア
     */
    protected onClickSearchConditionClear(): void {
        this.searchCondition.clear()
    }

    /**
     * 検索
     * @param form
     */
    protected onSubmitSearchConditionSearch(form: NgForm): void {
        if (form.valid === false) {
            for (let key in form.controls) {
                form.controls[key].markAsDirty();
            }
            return;
        }
        this.isClickNewOptionSearch = false;
        this._search();
    }

    protected onClickSearchButton(form: NgForm): void {
        if (form.valid === false) {
            for (let key in form.controls) {
                form.controls[key].markAsDirty();
            }
            return;
        }
        this.isClickNewOptionSearch = true;
        this._search();
    }

    protected onChangeCheckedDeadlineWithin30Day(): void {
        if (this.searchCondition.isDeadlineWithin30Day) {
            this.searchCondition.expirationDateFrom = '';
            this.searchCondition.expirationDateTo = '';
        }
        this.isDisableExpirationDate = this.searchCondition.isDeadlineWithin30Day;
    }

    protected onChangeSearchDomainNames() {
        this.domainNameGtm = '';
        if (this.searchCondition.domainNames) {
            this.domainNameGtm = 'domain' + this.searchCondition.domainNames.replace(/\n|\r\n|\r/, '\n').trim().split('\n').length.toString();
        }
    }
}

/**
 * 検索条件の設定
 */
export class DomainSearchConditionConfig {
    public constructor(
        public isEnableDomainNames: boolean = true,
        public isEnableGroupName: boolean = true,
        public isEnableCreatedDate: boolean = true,
        public isEnableExpirationDate: boolean = true,
        public isEnableRequestDate: boolean = false,
        public isEnableWhoisProxyStatus: boolean = false,
        public isEnableNewOptionSearch: boolean = false,
        public isEnableAnotherSearch: boolean = false) {
    }
}

/**
 * 検索条件
 */
export class DomainSearchCondition {
    public constructor(
        public domainNames: string = '',
        public domainGroupId: string = '',
        public domainGroupName: string = '',
        public createdDateFrom: string = '',
        public createdDateTo: string = '',
        public expirationDateFrom: string = '',
        public expirationDateTo: string = '',
        public requestDateFrom: string = '',
        public requestDateTo: string = '',
        public groupNameSelectItems: DomainSearchSelectItem[] = [],
        public whoisProxyStatus: string = '',
        public whoisProxyStatusSelectItems: DomainSearchSelectItem[] = [],
        public isDeadlineWithin30Day: boolean = false,
        public anotherSelectItemValue: string = '',
        public anotherSelectItem: AnotherSelectItem = new AnotherSelectItem([], '')) {
    }

    public clear(): void {
        this.domainNames = '';
        this.domainGroupId = '';
        this.domainGroupName = '';
        this.createdDateFrom = '';
        this.createdDateTo = '';
        this.expirationDateFrom = '';
        this.expirationDateTo = '';
        this.requestDateFrom = '';
        this.requestDateTo = '';
        this.whoisProxyStatus = this.whoisProxyStatusSelectItems.length > 0 ? this.whoisProxyStatusSelectItems[0].value : '';
        this.isDeadlineWithin30Day = false;
        this.anotherSelectItemValue = '';
    }
}

export class DomainSearchSelectItem {
    public constructor(public value: string, public text: string) { }
}

export class AnotherSelectItem {
    public listItem: DomainSearchSelectItem[];
    public text: string
    constructor(listItem: DomainSearchSelectItem[], text: string) {
        this.listItem = listItem;
        this.text = text;
    }
}
