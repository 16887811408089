/// <reference path='../../../../../script/definitions/window.d.ts' />

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { getAccountInfo } from 'Content/script/store/reducers/account-info';
import { AccountInfoState } from 'Content/script/store/states/account-info';
import { SpServiceContainer } from 'Content/sp/script/service/service_container';
import { getLayout } from '../../../store/reducers';
import { LayoutState } from '../../../store/states/layout';
import { SpBaseLayoutComponent, SpBaseLayoutViewModel } from '../base_layout.component';



/**
 *
 */
@Component({
    selector: 'sp-footer',
    templateUrl: './footer.html'
})
export class SpFooterComponent extends SpBaseLayoutComponent implements OnInit, OnDestroy {
    public viewModel: SpFooterViewModel;

    public constructor(
        private router: Router,
        private serviceContainer: SpServiceContainer,
        private storeLayout: Store<LayoutState>,
        private storeAccountInfo: Store<AccountInfoState>
    ) {
        super();
        this.viewModel = new SpFooterViewModel(router, serviceContainer);
    }

    public ngOnInit() {
        this.isInited = true;
        this.reset();
        this.addStoreSubscribe(this.storeLayout.select(getLayout).subscribe(state => {
            this.viewModel.isShowFooterMenu = state.isShowFooterMenu;
        }));
        this.addStoreSubscribe(this.storeAccountInfo.select(getAccountInfo).subscribe(state => {
            const accountInfo = this.serviceContainer.AccountInfoService.mappingAccountInfo(state);
            this.viewModel.isShowDomainPayment = accountInfo.ShowSitemapPaymentInfoDomain;
            this.viewModel.isViewHiddenInvoice = accountInfo.ViewHiddenInvoice;
        }));
    }

    public ngOnDestroy() {
        this.reset();
        this.clearStoreSubscribes();
    }

    public reset(): void {
        if (this.isInited) {
            this.viewModel.isOpenAccountMenu = false;
            this.viewModel.isOpenPaymentMenu = false;
            this.viewModel.isOpenSupportMenu = false;
            this.viewModel.isShowDomainPayment = false;
        }
    }
}

class SpFooterViewModel extends SpBaseLayoutViewModel {
    public year: number;
    public isShowFooterMenu: boolean = false;

    public isOpenSupportMenu: boolean;

    public constructor(
        private router: Router,
        serviceContainer: SpServiceContainer) {
        super(router, serviceContainer);
        this.year = new Date().getFullYear();
    }

    public onToggleSupportMenu(): void {
        if (this.isOpenSupportMenu) {
            this.isOpenSupportMenu = false;
        } else {
            this.isOpenSupportMenu = true;
        }
    }

    /*
     * PC版を表示
     */
    public onClickChangePcMode(): void {
        let url = this.router.url;
        window.location.href = url + '?forceView=pc';
    }

    public logout() {
        sessionStorage.setItem("isSpFirstViewDomainModal", "y");
        sessionStorage.setItem("isSpFirstShowModalSd", "y");
        sessionStorage.removeItem('isSpShowRsRecommend');
        sessionStorage.removeItem('firstViewAfterLogin');
        sessionStorage.removeItem('receivedFirstDayRegistrationRs');
        this._logout();
    }

    public openUrl(url: string): void {
        window.open(url, '_blank', 'noopener,noreferrer');
    }
}

