import { HttpClient } from "@angular/common/http";

declare var checkToken: any;

export class DomainWhoisService {
    public constructor(private http: HttpClient) { }

    /**
     * リクエスト
     * @param requestData
     * @param receiveCallBack
     */
    public CompleteEvidence = (requestData: any, receiveCallBack: (data: EvidenceRequestCompleteResponseHubDto) => void, receiveErrorCallBack: (data: EvidenceRequestCompleteResponseHubDto) => void, noSessionCallBack: (data: EvidenceRequestCompleteResponseHubDto) => void, completeCallBack: (data: EvidenceRequestCompleteResponseHubDto) => void = null, receiveUnExceptedErrorCallBack: (data: EvidenceRequestCompleteResponseHubDto) => void): void => {
        const url = location.protocol + "//" + location.host + '/DomainWhois/CompleteEvidence';

        let responseData: EvidenceRequestCompleteResponseHubDto = null;
        this.http.post<any>(url, requestData, { responseType: "json", headers: { "X-XSRF-TOKEN" : checkToken } })
            .subscribe(
                (res: any) => {
                    responseData = res;

                    if (!!responseData.MaintenanceMode) {
                        location.href = "/";
                        return;
                    }

                    if (responseData.NoSession) {
                        noSessionCallBack(responseData);
                    } else if (responseData.IsUnexpectedError) {
                        receiveUnExceptedErrorCallBack(responseData);
                    } else if (responseData.IsError) {
                        receiveErrorCallBack(responseData);
                    } else {
                        receiveCallBack(responseData);
                    }
                },
                e => {
                    receiveUnExceptedErrorCallBack(responseData);
                },
                () => {
                    if (!!completeCallBack) {
                        completeCallBack(responseData);
                    }
                }
            );
    }
}
