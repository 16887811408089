import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { UiModule } from 'Content/script/modules/ui.module';
import { ValidationModule } from 'Content/script/modules/validator.module';

import { DomainPaymentComponent } from 'Content/script/components/share/domain_payment/domain_payment.component';
import { ContactCopyModalComponent } from 'Content/script/components/share/contact_copy_modal/contact_copy_modal.component';
import { DomainCopyModalComponent } from 'Content/script/components/share/domain_copy_modal/domain_copy_modal.component';
import { HostingPaymentComponent } from 'Content/script/components/share/hosting_payment/hosting_payment.component';
import { GmoPointModalComponent } from 'Content/script/components/share/gmo_point_modal/gmo_point_modal.component';
import { PaymentDisplayComponent } from 'Content/script/components/share/payment_display/payment_display.component';
import { InvoiceCancelModalComponent } from 'Content/script/components/payment/invoice/cancel/_invoice_cancel_modal/invoice_cancel_modal.component';

/**
 * Atoms
 * */
import { AtomsModule } from 'Content/script/modules/atoms.module';

/**
 * Molecules
 * */
import { MoleculesModule } from 'Content/script/modules/molecules.module';

/**
 * Organisms
 * */
import { OrganismsModule } from 'Content/script/modules/organisms.module';

/**
 * Templates
 * */
import { TemplatesModule } from 'Content/script/modules/templates.module';
import { AweSomeModule } from './awesome.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        UiModule,
        ValidationModule,
        AtomsModule,
        MoleculesModule,
        OrganismsModule,
        TemplatesModule,
        AweSomeModule
    ],
    declarations: [
        DomainPaymentComponent,
        ContactCopyModalComponent,
        DomainCopyModalComponent,
        HostingPaymentComponent,
        GmoPointModalComponent,
        PaymentDisplayComponent,
        InvoiceCancelModalComponent,
    ],
    exports: [
        DomainPaymentComponent,
        ContactCopyModalComponent,
        DomainCopyModalComponent,
        HostingPaymentComponent,
        GmoPointModalComponent,
        PaymentDisplayComponent,
        InvoiceCancelModalComponent,
        AtomsModule,
        MoleculesModule,
        OrganismsModule,
        TemplatesModule
    ]
})

export class SharedModule { }
