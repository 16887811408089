 <div class="modal" id="invoiceCancel" modal [openFlg]="isShowModal">
        <div class="modal-Dialog is-Wide">
            <div class="modal-Dialog-Header">
                <h3 class="modal-Dialog-Header-Title is-Caution"><img alt="(!)" class="img-Caution" src="/Content/images/common/icon-caution.svg">ご確認</h3>
            </div><!-- /.modal-Dialog-Header -->
            <div class="modal-Dialog-Body">
                <p class="modal-Dialog-Body-Title">ご請求キャンセル手続きについて<br>下記内容をご確認ください。</p>
                <div class="box-Border is-Thick">
                    <div class="box-Border-Inner">
                        <p class="text-Left">■ 請求キャンセルは、銀行振込、コンビニエンスストア支払いの入金待ちのみ手続きが可能です。</p>
                        <p class="text-Left">■ クレジットカード決済、および入金済みは受け付けできません。</p>
                    </div>
                </div>
            </div><!-- /.modal-Dialog-Body -->
            <div class="modal-Dialog-Footer">
                <ul class="list-Btn is-Right mb0">
                    <li><button type="button" class="btn" (click)="onClickClose()">戻る</button></li>
                    <li><button type="button" class="btn is-Primary" data-dismiss="modal" (click)="onClickNext()">次へ</button></li>
                </ul><!-- /.list-Btn -->
            </div><!-- /.modal-Dialog-Footer -->
            <button type="button" class="modal-Close" data-dismiss="modal" (click)="onClickClose()"><i class="icon icon-Clear" aria-label="閉じる" (click)="onClickClose()"></i></button>
        </div><!-- /.modal-Dialog -->
</div><!-- /.modal -->