/// <reference path='../../../definitions/window.d.ts' />

import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';

import { StringUtil } from 'Content/script/libs/utils/string.util';
import { ValidationRegexsDefine } from 'Content/script/libs/define/validation_regexs.define';

/**
 * GMOポイント消費モーダルコンポーネント
 */
@Component({
    selector: 'gmo-point-modal',
    templateUrl: './gmo_point_modal.html'
})

export class GmoPointModalComponent {
    public viewModel: GmoPointModalViewModel;

    public constructor() {
        this.viewModel = new GmoPointModalViewModel();
    }
}

export class GmoPointModalViewModel {
    // モーダルのキャンセル時のコールバック関数
    private _cancelCallBack: () => void;
    // モーダルの実行時のコールバック関数
    private _doneCallBack: (useGmoPoint: number) => void;

    // 独自バリデーション (請求価格オーバー)の確認を行うか
    private _checkValidOverPaymentPrice: boolean;

    // 現在の所持GMOポイント (1,000)
    public currentPointwithComma: string;
    // 請求価格 (1,000)
    public paymentPricewithComma: string;

    private _currentPoint: number;
    private _paymentPrice: number;

    public useGmoPointType: UseGmoPointType;
    protected useGmoPoint: string;

    // 独自バリデーション (所持GMOポイントオーバー)
    protected isValidOverCurrentPoint: boolean;
    // 独自バリデーション (請求価格オーバー)
    protected isValidOverPaymentPrice: boolean;
    // 独自バリデーション (1回に使用できるポイントオーバー)
    protected isValidOverOneTimeUsePointLimit: boolean;

    public readonly UseGmoPointType;
    protected readonly oneTimeUsePointLimit: number;
    public readonly oneTimeUsePointLimitwithComma: string;
    protected readonly rNumberPattern: string;

    public constructor() {
        this._cancelCallBack = null;
        this._doneCallBack = null;

        this._checkValidOverPaymentPrice = true;

        this.currentPointwithComma = '-';
        this.paymentPricewithComma = '-';

        this._currentPoint = 0;
        this._paymentPrice = 0;

        this.useGmoPointType = UseGmoPointType.None;
        this.useGmoPoint = '0';

        this.isValidOverCurrentPoint = false;
        this.isValidOverPaymentPrice = false;
        this.isValidOverOneTimeUsePointLimit = false;

        this.UseGmoPointType = UseGmoPointType;
        this.oneTimeUsePointLimit = 30000;
        this.oneTimeUsePointLimitwithComma = StringUtil.NumberToStringWithComma(this.oneTimeUsePointLimit);
        this.rNumberPattern = ValidationRegexsDefine.rNumberPattern;
    }

    /**
     * モーダルないのコールバック関数を設定
     * @param cancelCallBack
     * @param doneCallBack
     */
    public setCallBack(cancelCallBack: () => void, doneCallBack: (useGmoPoint: number) => void): void {
        this._cancelCallBack = cancelCallBack;
        this._doneCallBack = doneCallBack;
    }

    /**
     * 独自バリデーション (請求価格オーバー)の確認を行うかの設定
     * @param checkValidOverPaymentPrice
     */
    public setCheckValidOverPaymentPrice(checkValidOverPaymentPrice: boolean): void {
        this._checkValidOverPaymentPrice = checkValidOverPaymentPrice;
    }

    /**
     * キャンセルのコールバック関数を実行
     */
    protected _cancel(): void {
        if (this._cancelCallBack) {
            this._cancelCallBack();
        }
    }

    /**
     * 実行のコールバック関数を実行
     * @param useGmoPoint
     */
    protected _done(useGmoPoint: number): void {
        if (this._doneCallBack) {
            this._doneCallBack(useGmoPoint);
        }
    }

    /**
     * ポイントと金額を設定
     * @param currentPointwithComma 現在の所持GMOポイント
     * @param paymentPricewithComma 請求金額
     */
    public setPrice(currentPointwithComma: string, paymentPricewithComma: string = '0'): void {
        let currentPoint: number = StringUtil.StringWithCommaToNumber(currentPointwithComma);
        if (currentPoint < 0) {
            currentPoint = 0;
        }
        this.currentPointwithComma = StringUtil.NumberToStringWithComma(currentPoint);
        this._currentPoint = currentPoint;

        let paymentPrice: number = StringUtil.StringWithCommaToNumber(paymentPricewithComma);
        if (paymentPrice < 0) {
            paymentPrice = 0;
        }
        this.paymentPricewithComma = StringUtil.NumberToStringWithComma(paymentPrice);
        this._paymentPrice = paymentPrice;
    }

    /**
     * ポイント消費種類選択
     * @param value
     */
    public onChangeUseGmoPointType(value: UseGmoPointType): void {
        this.useGmoPointType = value;
        if (this.useGmoPointType === UseGmoPointType.Part) {
            this.useGmoPoint = '0';
            this.isValidOverCurrentPoint = false;
            this.isValidOverPaymentPrice = false;
            this.isValidOverOneTimeUsePointLimit = false;
        }
    }

    /**
     * ポイント入力
     * @param value
     */
    protected onChangeUsePoint(value: string): void {
        const number: number = parseInt(value);
        this.isValidOverCurrentPoint = number > this._currentPoint;
        this.isValidOverPaymentPrice = this._checkValidOverPaymentPrice ? number > this._paymentPrice: false;
        this.isValidOverOneTimeUsePointLimit = number > this.oneTimeUsePointLimit;
    }

    /**
     * キャンセル
     */
    public onClickCancelButton(): void {
        this._cancel();
    }

    /**
     * 実行
     * @param form
     */
    public onClickExecuteButton(form: NgForm): void {
        // バリデーション
        if (this.useGmoPointType === UseGmoPointType.Part) {
            // 通常のバリデーション
            if (form.valid === false) {
                for (let key in form.controls) {
                    form.controls[key].markAsDirty();
                }
                return;
            }

            // 独自バリデーション
            if (this.isValidOverCurrentPoint || this.isValidOverPaymentPrice || this.isValidOverOneTimeUsePointLimit) {
                return;
            }
        }

        // 利用ポイントの確定
        let usePoint: number = 0;
        switch (this.useGmoPointType)
        {
            case UseGmoPointType.All:
                usePoint = this._currentPoint;
                break;
            case UseGmoPointType.Part:
                usePoint = StringUtil.StringWithCommaToNumber(this.useGmoPoint);
                break;
            case UseGmoPointType.None:
                usePoint = 0;
                break;
            default:
                usePoint = 0;
                break;
        }

        // 請求金額より大きい場合の補正
        if (this._checkValidOverPaymentPrice) {
            if (usePoint > this._paymentPrice) {
                usePoint = this._paymentPrice;
            }
        }

        // 1度に利用可能のポイント上限より大きい場合の補正
        if (usePoint > this.oneTimeUsePointLimit) {
            usePoint = this.oneTimeUsePointLimit;
        }

        this._done(usePoint);
    }
}

enum UseGmoPointType {
    All = 1,
    Part,
    None,
}
