/// <reference path= '../../definitions/window.d.ts' />

import { Directive, AfterViewChecked, DoCheck, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Service } from 'Content/script/service/account_info_service';
import { ServiceContainer } from 'Content/script/service/service_container';
import { isShowHeaderFooterFn } from 'Content/script/components/app/app.component';

/**
 * ログイン中とそうでないときで、angularのライフサイクルフックを呼び分けるためのクラス
 * （派生クラスはで基本、ログイン中だけ処理をするようにする）
 **/
@Directive()
export class AfterLoginBaseDirective implements OnChanges, DoCheck, AfterViewChecked {

    @Input('serviceContainer') _serviceContainer: ServiceContainer;
    @Input('referrerPath') _referrerPath: string;
    @Input('routerPath') _routerPath: string;

    private __changeDetected: boolean = false;
    private __detectedList: Array<{ key: number, newValue: any }> = [];

    private __oldServiceContainer: any = null;
    private __oldAccountInfoService: any = null;
    private __oldProxyId: string = void(0);
    private __oldRouterPath: string = void(0);

    //派生クラスで１つの情報を使い回すためシングルトンにする
    private __afterLogin: AfterLoginSingleton;

    constructor() {
        this.__afterLogin = AfterLoginSingleton.getInstance();
    }
    
    ngOnChanges(changes: SimpleChanges) {
        if (!!changes._serviceContainer) {
            this.__afterLogin.serviceContainer = changes._serviceContainer.currentValue || null;
        }

        //ログイン中とそうでないときでイベントを呼び分け
        if (!!this.__afterLogin.accountInfo) {
            if (this._isValidAccountInfo(this.__afterLogin.accountInfo)) {
                this.onChangesAfterLogin(changes);
            } else {
                this.onChangesOutsideLogin(changes);
            }
        } else {
            this.onChangesOutsideLogin(changes);
        }
    }

    //手動で変更検知
    ngDoCheck() {

        //変更検知の対象
        enum DetectTargets {
            SERVICE_CONTAINER = 1,      //ServiceContainer
            ACCOUNT_INFO_SERVICE = 2,   //ServiceContainer.AccountInfoService
            PROXY_ID = 3,               //proxy id
            ROUTER_PATH = 4,            //router path
        }

        //検知 - ServiceContainer
        if (this.__oldServiceContainer !== this.__afterLogin.serviceContainer) {
            this.__changeDetected = true;

            this.__detectedList.push({
                key: DetectTargets.SERVICE_CONTAINER,
                newValue: this.__afterLogin.serviceContainer
            });

        } else if (this.__afterLogin.serviceContainer) {

            //検知 - ServiceContainer.AccountInfoService
            if (this.__oldAccountInfoService !== (this.__afterLogin.serviceContainer.AccountInfoService || null)) {
                this.__changeDetected = true;

                this.__detectedList.push({
                    key: DetectTargets.ACCOUNT_INFO_SERVICE,
                    newValue: this.__afterLogin.serviceContainer.AccountInfoService
                });
            }
        }

        //検知 - proxy id
        if (this.__oldProxyId !== window.ProxyId) {
            this.__changeDetected = true;

            this.__detectedList.push({
                key: DetectTargets.PROXY_ID,
                newValue: window.ProxyId
            });
        }

        //検知 - router path
        if (this.__oldRouterPath !== this._routerPath) {
            this.__changeDetected = true;

            this.__detectedList.push({
                key: DetectTargets.ROUTER_PATH,
                newValue: this._routerPath,
            });
        }

        //検知後の処理
        if (this.__changeDetected) {
            this.__detectedList.forEach(detected => {
                switch (detected.key) {

                    //ServiceContainer
                    case DetectTargets.SERVICE_CONTAINER:
                        this.__oldServiceContainer = detected.newValue;
                        break;

                    //ServiceContainer.AccountInfoService
                    case DetectTargets.ACCOUNT_INFO_SERVICE:
                        this.__oldAccountInfoService = detected.newValue;
                        break;

                    //proxy id
                    case DetectTargets.PROXY_ID:
                        this.__oldProxyId = detected.newValue;
                        break;

                    //router path
                    case DetectTargets.ROUTER_PATH:
                        //サービスコンテナとAccountInfoService読込済、かつ、アカウント情報が空、かつ、契約管理画面に遷移するときアカウント情報取得を行う。
                        //ログイン後やF5キー押下後にAccountInfoを実行する
                        if ((this.__afterLogin.serviceContainer.AccountInfoService === this.__oldAccountInfoService)
                            && !this.__afterLogin.accountInfo
                            && this._isMarchandisePath(detected.newValue))
                        {
                            const promise = this.__afterLogin.serviceContainer.AccountInfoService.GetAccountInfo("after_login_base_directive");
                            promise
                                .then(x => {
                                    this.__afterLogin.accountInfo = x;
                                })
                                .catch(y => {
                                    this.__afterLogin.accountInfo = null;
                                });
                        }

                        this.__oldRouterPath = detected.newValue;
                        break;
                }
            });

            this.__detectedList = [];
            this.__changeDetected = false;
        }

        //ログイン中とそうでないときでイベントを呼び分け
        if (this.__afterLogin.accountInfo) {
            if (this._isValidAccountInfo(this.__afterLogin.accountInfo)) {
                this.doCheckAfterLogin();
            } else {
                this.doCheckOutsideLogin();
            }
        } else {
            this.doCheckOutsideLogin();
        }
    }

    ngAfterViewChecked() {
        //ログイン中とそうでないときでイベントを呼び分け
        if (!!this.__afterLogin.accountInfo) {
            if (this._isValidAccountInfo(this.__afterLogin.accountInfo)) {
                this.afterViewCheckedAfterLogin();
            } else {
                this.afterViewCheckedOutsideLogin();
            }
        } else {
            this.afterViewCheckedOutsideLogin();
        }
    }

    protected onChangesOutsideLogin(changes: SimpleChanges) { }
    protected onChangesAfterLogin(changes: SimpleChanges) { }

    protected doCheckOutsideLogin() { }
    protected doCheckAfterLogin() { }

    protected afterViewCheckedOutsideLogin() { }
    protected afterViewCheckedAfterLogin() { }

    /**
     * 契約管理画の場合はtrue、そうでない画面（ログイン画面やエラー画面など）の場合はfalseを返す
     **/
    protected _isMarchandisePath(path: string): boolean {
        const notMarchandiseList: Array<string> = [
            //'/',
            '/account',
            '/payment',
            '/navigate',
        ];

        const pathArr = (path + '/').split('/');
        let rootMenu: string = '/';
        rootMenu = pathArr[0] === '' ? pathArr[1] : pathArr[0];

        return !notMarchandiseList.some(x => x.startsWith(path)) && '/' !== path && isShowHeaderFooterFn(rootMenu);
    }

    private _isValidAccountInfo(ac: Service.AccountInfo | Service.ErrorType): boolean {
        return !(ac === Service.ErrorType.Error || ac === Service.ErrorType.NoSession || ac === Service.ErrorType.UnExcepted);
    }
}

class AfterLoginSingleton {

    private static _instance: AfterLoginSingleton = null;
    public serviceContainer: ServiceContainer = null;
    public accountInfo: Service.AccountInfo | Service.ErrorType = null;

    private constructor() { }

    public static getInstance(): AfterLoginSingleton {
        if (!AfterLoginSingleton._instance) {
            AfterLoginSingleton._instance = new AfterLoginSingleton();
        }
        return AfterLoginSingleton._instance;
    }
}
