import { HubsService } from 'Content/script/service/hubs_service.js';
import { SystemComponentId } from 'Content/script/managers/MultipleSendManager.js';

enum ErrorType {
    Error,
    NoSession,
    UnExcepted
}

export class DomainWithDefaultNameServerService {

    private _hubService: HubsService;

    private promise: Promise<DomainWithDefaultNameServerResponseHubDto>;

    private queueId: number;

    constructor(hubService: HubsService) {
        this._hubService = hubService;
        this.promise = null;
        this.queueId = hubService.multipleSendManager.GetComponentId();
    }

    public GetDomainWithDefaultNameServer(caller: string = null): Promise<DomainWithDefaultNameServerResponseHubDto> {
        if (this.promise != null) {
            return this.promise;
        }

        this.promise = new Promise<DomainWithDefaultNameServerResponseHubDto>((resolve, reject) => {
            const request: DomainWithDefaultNameServerRequestHubDto = {
                PageNo: 1,
                LineCnt: 100,
                CreatedDateFrom: '',
                CreatedDateTo: '',
                ExpirationDateFrom: '',
                ExpirationDateTo: ''
            };
            this._hubService.multipleSendManager.Send(
                this.queueId,
                this._hubService.hubsManager.promotionHubManager.DomainWithDefaultNameServer,
                request,
                (data: DomainWithDefaultNameServerResponseHubDto) => {
                   resolve(data);
                },
                (data: DomainWithDefaultNameServerResponseHubDto) => {
                   
                    let val = !!caller ? caller : "Error Caller";
                    console.log("Error : location is " + val);
                    console.log(data.ErrorMessage);
                    reject(ErrorType.Error);
                },
                (data: DomainWithDefaultNameServerResponseHubDto) => {
                    
                    let val = !!caller ? caller : "NoSession Caller";
                    console.log("NoSession : location is " + val);
                    console.log(data.ErrorMessage);
                    reject(ErrorType.NoSession);
                },
                null,
                (data: DomainWithDefaultNameServerResponseHubDto) => {
                   
                    let val = !!caller ? caller : "Unexpected Caller";
                    console.log("UnExcepted : location is " + val);
                    console.log(data);
                    reject(ErrorType.UnExcepted);
                }
            );
        });

        return this.promise;
    }

    public Reset(): void {
        if (this.promise != void (0) && this.promise != null) {
            this.promise.then(this._deletePromise).catch(this._deletePromise);
        }
    }

    private _deletePromise = (): void => {
        this.promise = null;
    }
}
