/// <reference path='../../../definitions/window.d.ts' />

import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import {  GmoPointModalViewModel } from 'Content/script/components/share/gmo_point_modal/gmo_point_modal.component';

/**
 * GMOポイント消費モーダルコンポーネント2
 * 作成した理由
 * 　　@ViewChild宣言されているコンポーネントの中で更に@ViewChild宣言してGmoPointModalComponentを使おうとしたが、使えなかったため
 * 既存のコンポーネントとの相違点
 * 　　・親コンポーネントからこのコンポーネントを参照させるには@ViewChildを使わず、constructedイベントのハンドラを親コンポーネントに実装する
 * 　　　（@ViewChildでインスタンスが参照できる場合は↑の方法でする必要はありません）
 * 　　・キャンセルボタン押下時の処理の定義にsetCallBackを使わず、clickCancelのハンドラを親コンポーネントに実装する
 * 　　・確定ボタン押下時の処理の定義にsetCallBackを使わず、clickDoneイベントのハンドラを親コンポーネントに実装する
 */
@Component({
    selector: 'gmo-point-modal-2',
    templateUrl: './gmo_point_modal.html'
})

export class GmoPointModal2Component implements OnInit {
    public  viewModel: GmoPointModal2ViewModel;

    @Input() set useGmoPoint(newUseGmoPoint: number) {
        this.viewModel.updateUseGmoPoint(newUseGmoPoint);
    }
    @Output() constructed: EventEmitter<any> = new EventEmitter();
    @Output() clickCancel: EventEmitter<any> = new EventEmitter();
    @Output() clickDone: EventEmitter<any> = new EventEmitter();

    public constructor() {
        this.viewModel = new GmoPointModal2ViewModel(this);
    }

    ngOnInit() {
        this.constructed.emit(this);
    }

    public setPrice(currentPointwithComma: string, paymentPricewithComma: string = '0'): void {
        this.viewModel.setPrice(currentPointwithComma, paymentPricewithComma);
    }
}

export class GmoPointModal2ViewModel extends GmoPointModalViewModel {
    public constructor(protected _componentRef: GmoPointModal2Component) {
        super();
    }

    public setCallBack(cancelCallBack: () => void, doneCallBack: (useGmoPoint: number) => void): void {
        throw new Error("not supported");        
    }

    /**
     * キャンセルのコールバック関数を実行
     */
    protected _cancel(): void {
        this._componentRef.clickCancel.emit(null);
    }

    /**
     * 実行のコールバック関数を実行
     * @param useGmoPoint
     */
    protected _done(useGmoPoint: number): void {
        this._componentRef.clickDone.emit(useGmoPoint);
    }

    /**
     * 外部から利用ポイントを更新する
     * @param newUseGmoPoint
     */
    public updateUseGmoPoint(newUseGmoPoint: number): void {
        const value = newUseGmoPoint || 0;
        this.onChangeUsePoint(value.toString());
   } 
}
