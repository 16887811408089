import { Component, Input, EventEmitter, Output,OnChanges,SimpleChanges} from '@angular/core';

import { docCookies, FutureDate } from 'Content/script/libs/third_party/cookies';
import { ServiceContainer } from 'Content/script/service/service_container';
import { NgForm } from '@angular/forms';
import { IPageTracking } from '../../../../service/tracking_service';
import { QuestionnaireInfo, QuestionnaireType, QuestionnairePage, QuestionnaireOptionHasImage } from '../../../top/index/top_enquete_popup/top_enquete_popup.component';
import { CustomizedEvents } from '../../../../libs/abtest/custom-event.util';

/**
 * ナビゲーション
 */
@Component({
    selector: 'whoisprotect-cancel-enquete-popup',
    templateUrl: './whoisprotect_cancel_enquete_popup.html',
})

export class WhoisprotectCancelEnquetePopupComponent implements OnChanges {
    private _trackingService: IPageTracking;
    @Input() public domainName: string;
    @Input() public domainId: string;
    @Output() onTransmitSurvey = new EventEmitter<ServiceInfoSelected>();
    @Output() onCloseModal = new EventEmitter<any>();
    private _isShowModalSurvey: boolean = false;
    @Input()
    public get isShowModalSurvey(): boolean {
        return this._isShowModalSurvey;
    }
    public set isShowModalSurvey(v: boolean) {
        this._isShowModalSurvey = v;
        if (this._isShowModalSurvey) {
            this._trackingService.start();
        } else {
            this._trackingService.dispose();
        }
    }
    public isLoading: boolean;
    public questionnaireInfo: QuestionnaireInfo;
    public pages: QuestionnairePage[];
    public isValidateOther1: boolean = false;
    public isValidateOther2: boolean = false;
    public isShowCouponModal: boolean = false;
    public isErrorValidate: boolean = false;
    public isAnswered: boolean = false;
    public isAnsweredQ2: boolean = false;
    public isChoiseTransferApplication: boolean = false;
    public isChoiseOtherQ2: boolean = false;
    public queueId: number;

    public serviceName: string = '';
    public price: string = '';

    public readonly detailPackage: Array<{ serviceName: string, price: string }> = [
        { serviceName: 'Xserverドメイン', price: '3,103' },
        { serviceName: 'スタードメイン', price: '6,100' },
        { serviceName: 'さくらのドメイン', price: '7,964' },
        { serviceName: 'ムームードメイン', price: '8,026' },
        { serviceName: 'Value Domain', price: '7,498' },
    ];
    /**
     * コンストラクタ
     */
    public constructor(private serviceContainer: ServiceContainer) {
        this.queueId = this.serviceContainer.HubsService.multipleSendManager.GetComponentId();
        this._trackingService = this.serviceContainer.TrackingService.provideServiceForModalComponent().create('DomainDetailEnquetePopupComponent');
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.isShowModalSurvey && changes.isShowModalSurvey.currentValue) {
            if (this.isShowModalSurvey) {
                this._requestGetQuestionnaireData();
            }
        }
    }
   

    /**
     * アンケートデータ取得
     */
    private _requestGetQuestionnaireData(): void {
        this.isLoading = true;
        let pattern: number = 0;

        var answerReqHub: QuestionnaireAnswerAllServicesRequestHubDto = {
            AnswerHubList: [],
            UserAgent: window.navigator.userAgent.toLowerCase(),
            Pattern: pattern,
            Screen: "WhoisProtect_Cancel",
        };
        this.serviceContainer.HubsService.multipleSendManager.Send(
            this.queueId,
            this.serviceContainer.HubsService.hubsManager.questionnaireHubManager.GetQuestionnaireInfoAllServices,
            answerReqHub,
            (resHub: QuestionnaireInfoAllServicesResponseHubDto) => {
                var pageNo = 0;
                var questionnaireArr: QuestionnairePage[] = [];
                resHub.QuestionnaireHubList.map(x => {
                    var questionnaire: QuestionnairePage = new QuestionnairePage();
                    questionnaire.id = x.Id;
                    questionnaire.content = x.Content;
                    questionnaire.order = x.Order;
                    questionnaire.questionNo = x.QuestionNo;
                    questionnaire.requiredFlg = x.RequiredFlg;
                    questionnaire.type = x.Type;

                    x.OptionHubList.map(y => {
                        var option: QuestionnaireOptionHasImage = new QuestionnaireOptionHasImage();
                        option.id = y.Id;
                        option.content = y.Content;
                        option.order = y.Order;
                        questionnaire.options.push(option);
                    });
                    if (questionnaire.type == QuestionnaireType.RadioButtonWithOther) {
                        var option: QuestionnaireOptionHasImage = new QuestionnaireOptionHasImage();
                        option.id = questionnaire.options[questionnaire.options.length - 1].id + 1;
                        option.content = 'その他';
                        option.order = questionnaire.options.length + 1;
                        option.isOther = true;
                        questionnaire.options.push(option);
                    }
                    questionnaire.pageNo = pageNo++;
                    questionnaireArr.push(questionnaire);
                });

                questionnaireArr[0].isShow = true;

                //最後のページ（アンケートではない）
                var lastPage = new QuestionnairePage();
                lastPage.type = QuestionnaireType.NotQuestionnaire;
                lastPage.pageNo = pageNo++;
                questionnaireArr.push(lastPage);

                this.questionnaireInfo = new QuestionnaireInfo(questionnaireArr);
                this.questionnaireInfo.title = resHub.Title;
                this.pages = this.questionnaireInfo.questionnaires;
                this.isLoading = false;
            },
            null,
            null,
            null,
            null
        );
    }

    public onClickClose(): void {
        this.onCloseModal.emit();
        this.isShowModalSurvey = false;
        if (event) {
            event.stopPropagation();
        }
        this.questionnaireInfo.qustionnairePager.reset();
    }

    public onClickFinishSurvey(form: NgForm): void {
        this.isShowCouponModal = this.isChoiseTransferApplication && !this.isChoiseOtherQ2 && this.isAnsweredQ2;
        let cookieCanShowCouponModal = this.domainName + "canShowCouponModalWhoIsProtect";
        docCookies.setItem(cookieCanShowCouponModal, this.isShowCouponModal ? "1" : "0", FutureDate.afterDays(30));
        this.transmitSurvey(form);
        if (!this.isErrorValidate) {
            if (this.isShowCouponModal) {
                CustomizedEvents.dispatchWhoisCancelModal();
            }
            this.onTransmitSurvey.emit({ isShowCouponModal: this.isShowCouponModal, serviceName: this.serviceName, price: this.price });
            let cookieKey = this.domainName + "isDoneSurveyWhoIsProtect";
            docCookies.setItem(cookieKey, true, FutureDate.afterDays(30));
            this.isShowModalSurvey = false;
        }
    }
    public transmitSurvey(form: NgForm) {
        if (!form.valid && (this.isValidateOther1 || this.isValidateOther2)) {
            for (var key in form.controls) {
                form.controls[key].markAsDirty();
            }
            this.isErrorValidate = true;
            return;
        }

        if (!this.isAnswered) {
            this.isErrorValidate = true;
            return;
        }
        this.isErrorValidate = false;
        var answerReqHub: QuestionnaireAnswerAllServicesRequestHubDto = {
            AnswerHubList: [],
            UserAgent: window.navigator.userAgent.toLowerCase(),
            Pattern: 0,
            Screen: "WhoisProtect_Cancel"
        };
        var answerList = this._getAnswerHubList();
        if (answerList != null && answerList != undefined) {
            answerReqHub.AnswerHubList = answerList;
            this._transmit(answerReqHub);

        }
    }

    public onChangeRadioQuestion1(page: QuestionnairePage, optionId: number) {
        let numberRadioChecked = 0;
        this.pages.find(x => x.id === page.id).selecltedId = optionId;
        this.pages[0].options.forEach((x, i) => {
            if (x.id === this.pages[0].selecltedId) {
                numberRadioChecked++;
                if (i === 0) {
                    this.isChoiseTransferApplication = true;
                }
                this.isValidateOther1 = x.isOther ? true : false;
            }
        });
        this.isAnswered = numberRadioChecked > 0 ? true : false;
    }

    public onChangeRadioQuestion2(page: QuestionnairePage, optionId: number) {
        this.isAnsweredQ2 = false;
        this.pages.find(x => x.id === page.id).selecltedId = optionId;
        this.pages[1].options.forEach((x, i) => {
            if (x.id === this.pages[1].selecltedId) {
                this.isAnsweredQ2 = true;
                this.isChoiseOtherQ2 = x.isOther;
                this.isValidateOther2 = x.isOther ? true : false;
                if (!this.isChoiseOtherQ2) {
                    this.serviceName = x.content;
                    let price = this.detailPackage.filter(y => y.serviceName == this.serviceName)[0]?.price;
                    if (price) {
                        this.price = price;
                    } else {
                        this.isShowCouponModal = false;
                    }
                }
            }
        });
    }

    private _getAnswerHubList(): QuestionnaireAnswerHubDto[] {
        var answerHubList: QuestionnaireAnswerHubDto[] = [];
        var answer: QuestionnaireAnswerHubDto;
        for (var index = 0; index < this.pages.length; index++) {
            var page = this.pages[index];
            switch (page.type) {
                case QuestionnaireType.CheckBox:
                case QuestionnaireType.CheckBoxWithOther:
                    for (let option of page.options) {
                        if (option.isCheck) {
                            if (option.isOther) {
                                answer = {
                                    QuestionId: page.id,
                                    Type: page.type,
                                    Option: '',
                                    Other: page.otherText,
                                };
                            } else {
                                answer = {
                                    QuestionId: page.id,
                                    Type: page.type,
                                    Option: (option.id === 0) ? option.content : option.id.toString(),
                                    Other: 'no_other',
                                };
                            }
                            answerHubList.push(answer);
                        }
                    }
                    break;

                case QuestionnaireType.RadioButton:
                case QuestionnaireType.RadioButtonWithOther:
                    for (let option of page.options) {
                        if (option.id === page.selecltedId) {
                            if (option.isOther) {
                                answer = {
                                    QuestionId: page.id,
                                    Type: page.type,
                                    Option: '',
                                    Other: page.otherText,
                                };
                            } else {
                                answer = {
                                    QuestionId: page.id,
                                    Type: page.type,
                                    Option: (option.id === 0) ? option.content : option.id.toString(),
                                    Other: 'no_other',
                                };
                            }
                            answerHubList.push(answer);
                        }
                    }
                    break;

                case QuestionnaireType.Text:
                    var option = this.getAnswerOptionContent(page.content);
                    if (option) {
                        answer = {
                            QuestionId: page.id,
                            Type: page.type,
                            Option: option,
                            Other: 'no_other',
                        }
                        answerHubList.push(answer);
                    }
                    break;

                case QuestionnaireType.Textarea:
                    if (page.textarea.length > 0) {
                        answer = {
                            QuestionId: page.id,
                            Type: page.type,
                            Option: page.textarea.replace(/<("[^"]*"|'[^']*'|[^'">])*>/g, '\\n'),
                            Other: 'no_other',
                        }
                        answerHubList.push(answer);
                    }
                    break;
            }
        }
        return answerHubList;
    }

    private _transmit(requestHubDto: QuestionnaireAnswerAllServicesRequestHubDto) {
        this.serviceContainer.HubsService.multipleSendManager.Send(
            this.queueId,
            this.serviceContainer.HubsService.hubsManager.questionnaireHubManager.AnswerQuestionnaireAllServices,
            requestHubDto,
            (answerResHub: QuestionnaireAnswerAllServicesResponseHubDto) => {
            },
            null,
            null,
            null,
            null
        );
    }

    /**
     * 画面上で表示させず、コード内で値をセットする項目であるか
     * @param content
     */
    public isInternalQuestion(content: string): boolean {
        return RegExp('\\[internal:.*\\].*').test(content);
    }

    /**
     * 該当する値をセットする
     * @param content
     */
    private getAnswerOptionContent(content: string): string {
        if (this.isInternalQuestion(content)) {
            let target = content.replace('[internal:', '').replace(new RegExp('\].*'), '');
            switch (target) {
                case 'domainId':
                    return this.domainId;
                default:
                    return "";
            }
        } else {
            return "";
        }
    }
}

export class ServiceInfoSelected {
    public isShowCouponModal: boolean;
    public serviceName: string;
    public price: string;

    public constructor() {
        this.isShowCouponModal = false;
        this.serviceName = '';
        this.price = '';
    }
}

