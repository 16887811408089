import { NgModule } from '@angular/core';

import { SafePipe } from 'Content/script/pipes/safe.pipe';
import { CommasNumberPipe } from '../pipes/commasNumber.pipe';
import { LocalDateStringPipe } from '../pipes/localDateString.pipe';

@NgModule({
    declarations: [
        SafePipe,
        CommasNumberPipe,
        LocalDateStringPipe,
    ],
    exports: [
        SafePipe,
        CommasNumberPipe,
        LocalDateStringPipe
    ]
})
export class SafePipeModule { }
