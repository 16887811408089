import { createAction, props } from '@ngrx/store';
import { ViewState } from '../states/view';

export const setViewState = createAction(
    '[ViewMode] Set View State',
    props<ViewState>()
);

export const setViewMode = createAction(
    '[ViewMode] Set View Mode',
    props<{ value: string }>()
);

export const setViewKeep = createAction(
    '[ViewMode] Set View Keep',
    props<{ value: boolean }>()
);

export const setViewLoading = createAction(
    '[ViewMode] Set View Loading',
    props<{ value: boolean }>()
);

export const setViewCurrent = createAction(
    '[ViewMode] Set View Current',
    props<{ value: string }>()
);
