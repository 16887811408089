/**
 * ページ遷移間で使用するデータ転送サービス
 */
export class DataTransferService {
    public readonly AccountDeleteKey: string = 'AccountDeleteKey';
    public readonly AccountKey: string = 'AccountKey';
    public readonly ApplyCouponDomainDetailKey: string = 'ApplyCouponDomainDetailKey';
    public readonly BillContactKey: string = 'BillContactDtoBillContactKey';
    public readonly DomainTransferKey: string = 'DomainTransferKey';
    public readonly QuestionnaireAnswerKey: string = 'QuestionnaireAnswerKey';
    public readonly HostingChangePlanKey: string = 'HostingChangePlanKey';
    public readonly HostingChangePlanNextRenewFeeKey: string = 'HostingChangePlanNextRenewFeeKey';
    public readonly HostingCancelPlanKey: string = 'HostingCancelPlanKey';
    public readonly HostingAddOptionKey: string = 'HostingAddOptionKey';
    public readonly HostingChangeOptionKey: string = 'HostingChangeOptionKey';
    public readonly HostingCancelOptionKey: string = 'HostingCancelOptionKey';
    public readonly HostintSwitchIdKey: string = 'HostintSwitchIdKey'
    public readonly HostingAddDomainKey: string = 'HostingAddDomainKey';
    public readonly HostingSslAllocationInfoKey: string = 'HostingSslAllocationInfoKey';
    public readonly HostingDnsAllocationInfoKey: string = 'HostingDnsAllocationInfoKey';
    public readonly HostingPaymentInvoiceInfoKey: string = 'HostingPaymentInvoiceInfoKey';
    public readonly HostingPaymentChangeToLumpsumKey: string = 'HostingPaymentChangeToLumpsumKey';
    public readonly HostingDesktopCloudCancellationKey: string = 'HostingDesktopCloudCancellationKey';
    public readonly WhoisProtectListKey: string = "WhoisProtectListKey";
    public readonly WhoisProtectCancelKey: string = 'WhoisProtectCancelKey';
    public readonly WhoisProtectApplyKey: string = 'WhoisProtectApplyKey';
    public readonly WhoisProtectDoneKey: string = 'WhoisProtectDoneKey';
    public readonly WhoisProtectMailForwardKey: string = 'WhoisProtectMailForwardKey';
    public readonly JpChangeDomainPriceKey: string = 'JpChangeDomainPriceKey';
    public readonly JpChangeDomainNameSelectKey: string = 'JpChangeDomainNameSelectKey';
    public readonly JpChangeDomainOrganizationSelectKey: string = 'JpChangeDomainOrganizationSelectKey';
    public readonly JpChangeDomainTransferSelectKey: string = 'JpChangeDomainTransferSelectKey';
    public readonly JpChangeDomainContactSelectKey: string = 'JpChangeDomainContactSelectKey';
    public readonly WhoisChangeSelectKey: string = 'WhoisChangeSelectKey';
    public readonly WhoisChangeContactKey: string = 'WhoisChangeContactKey';
    public readonly NetDeShindanSelectKey: string = 'NetDeShindanSelectKey';
    public readonly CojpRegistSelectKey: string = 'CojpRegistSelectKey';
    public readonly CojpRegistInputKey: string = 'CojpRegistInputKey';
    public readonly JpDomainKey: string = 'JpDomainKey';
    public readonly WhoisChangeOwnershipKey: string = 'WhoisChangeOwnershipKey';
    public readonly PhoneAuthKey: string = 'PhoneAuthKey';
    public readonly DomainAutoRenewSpecificDomainInfoKey: string = 'DomainAutoRenewSpecificDomainInfoKey';
    public readonly DomainRenewKey: string = 'DomainRenewKey';
    public readonly DomainRenewDoneKey: string = 'DomainRenewDoneKey';
    public readonly DomainRestoreKey: string = 'DomainRestoreKey';
    public readonly DomainDetailEditKey: string = 'DomainDetailEditKey';
    public readonly HostingChangeSubDomainKey: string = 'HostingChangeSubDomainKey';
    public readonly LinkResetKey: string = 'LinkResetKey';
    public readonly LinkDomainAddOtherKey: string = 'LinkDomainAddOtherKey';
    public readonly LinkDomainAddRegdKey: string = 'LinkDomainAddRegdKey';
    public readonly LinkSimpleOptionKey: string = 'LinkSimpleOptionKey';
    public readonly SdPlanChangeKey: string = 'SdPlanChangeKey';
    public readonly DomainDeleteKey: string = 'DomainDeleteKey';
    public readonly DomainDeleteErrorKey: string = 'DomainDeleteErrorKey';
    public readonly BackOrderReleaseKey: string = 'BackOrderReleaseKey';
    public readonly UnKeepPageCountKey: string = 'UnKeepPageCountKey';
    public readonly LoginsecurityMfaKey: string = 'LoginsecurityMfa';
    public readonly DomainRenewFromDoneKey: string = 'DomainRenewFromDoneKey';
    public readonly DomainRenewFromWithdrawalPreventionModal: string = 'DomainRenewFromWithdrawalPreventionModal';
    public readonly DelServiceApplyKey: string = 'DelServiceApplyKey';
    public readonly DelServiceCancellationKey: string = 'DelServiceCancellationKey';
    public readonly DomainEvidenceKey: string = 'DomainEvidenceKey';
    public readonly InvoiceCancelConfirmKey: string = 'InvoiceCancelConfirmKey';
    public readonly ContactInfoKey: string = 'ContactInfoKey';
    public readonly ShowRecommendMfaKey: string = 'ShowRecommendMfaKey';
    public readonly SecondhandDealerLicenseKey: string = 'SecondhandDealerLicenseKey';
    public readonly SpecialCancellationKey: string = 'SpecialCancellationKey';
    public readonly DomainRefundKey: string = 'DomainRefundKey';
    public readonly TempcoJpDomainRestoreKey: string = 'TempcoJpDomainRestoreKey';
    public readonly DsRecordKey: string = 'DsRecordKey';
    public readonly DomainSwitchIdKey: string = 'DomainSwitchIdKey';
    public readonly DomainSwitchIdDoneKey: string = 'DomainSwitchIdDoneKey';
    public readonly DomainMonitoringKey: string = 'DomainMonitoringKey';
    public readonly DesktopCloudNpsKey: string = 'DesktopCloudNpsKey';
    public readonly DesktopCloudInfoKey: string = 'DesktopCloudInfoKey';
    public readonly OwnershipDomainDeleteErrorKey: string = 'OwnershipDomainDeleteErrorKey';
    public readonly DomainProtectApplyKey: string = 'DomainProtectApplyKey';
    public readonly DomainProtectApplyDoneKey: string = 'DomainProtectApplyDoneKey';
    public readonly DomainProtectUpdateKey: string = 'DomainProtectUpdateKey';
    public readonly DomainProtectUpdateDoneKey: string = 'DomainProtectUpdateDoneKey';
    public readonly DomainProtectApprovalKey: string = 'DomainProtectApprovalKey';
    public readonly DomainProtectApprovalErrorKey: string = 'DomainProtectApprovalErrorKey';
    public readonly AuthPwChangeInput: string = 'AuthPwChangeInput';
    public readonly SslKey: string = 'SslKey';
    public readonly SellDomainInput: string = 'SellDomainInput';
    public readonly SellDomainSeekBulk: string = 'SellDomainSeekBulk';
    public readonly SellDomainCreate: string = 'SellDomainCreate';
    public readonly SellDomainCreateSet: string = 'SellDomainCreateSet';
    public readonly SellDomainCreateConfirm: string = 'SellDomainCreateConfirm';
    public readonly SellDomainPayout: string = 'SellDomainPayout';
    public readonly SellDomainHistory: string = 'SellDomainHistory';
    public readonly TmchDetail: string = 'TmchDetail';
    public readonly TmchInfo: string = 'TmchInfo';
    public readonly TmchCorrectResult: string = 'TmchCorrectDone';
    public readonly TmchRenew: string = 'TmchRenew';
    public readonly TmchRenewResult: string = 'TmchRenewResult';
    public readonly DomainBulkPayment: string = 'DomainBulkPayment';
    public readonly DomainBulkReCreate: string = 'DomainBulkReCreate';
    public readonly QuickOptionWhoistProtect: string = 'QuickOptionWhoistProtect';
    public readonly QuickOptionSettingCredit: string = 'QuickOptionSettingCredit';
    public readonly RefundInvoiceHosting: string = 'RefundInvoiceHosting';
    public readonly DesktopCloudCampaignKey: string = 'DesktopCloudCampaignKey';
    public readonly DesktopCloudCashBackKey: string = 'DesktopCloudCashBackKey';
    public readonly QuickRsregistDomain: string = 'QuickRsregistDomain';
    public readonly QuickDnsRegisKey: string = 'QuickDnsRegisKey';
    public readonly DomainParkingDomainCreateKey: string = 'DomainParkingDomainCreateKey';
    public readonly DomainParkingDomainCreateDoneKey: string = 'DomainParkingDomainCreateDoneKey';
    public readonly DomainParkingDomainDeleteKey: string = 'DomainParkingDomainDeleteKey';
    public readonly DomainParkingDomainDeleteDoneKey: string = 'DomainParkingDomainDeleteDoneKey';
    public readonly DomainParkingCancelKey: string = 'DomainParkingCancelKey';
    public readonly DomainParkingRealizeCreateKey: string = 'DomainParkingRealizeCreateKey';
    public readonly DomainParkingRealizeDoneKey: string = 'DomainParkingRealizeDoneKey';
    public readonly DomainParkingDomainReportKey: string = 'DomainParkingDomainReportKey';
    private _transferKey: string;
    private _transferData: Object;

    constructor() {
        this._transferKey = null;
        this._transferData = null;
    }

    /**
     * 画面遷移前に設定してデータをキーを指定して取得します。
     */
    public getData<T>(transferkey: string): T {
        if (this._transferKey === transferkey) {
            return this._transferData as T;
        }
        return null;
    }

    /**
     * 画面遷移後に引き継ぎたいデータをキーを指定して設定します。
     */
    public setData<T>(transferData: T, transferkey: string): void {
        this._transferKey = transferkey;
        this._transferData = transferData;
    }

    /**
     * 画面遷移後に引き継ぎたいデータが存在するか確認します。
     * @param transferkey
     */
    public hasData(transferkey: string): boolean {
        return this._transferKey === transferkey;
    }

    /**
     * データをクリアします。
     */
    public clear(): void {
        this._transferKey = null;
        this._transferData = null;
    }
}
