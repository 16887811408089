import { NgModule } from '@angular/core';

import { MaxValidator } from 'Content/script/directives/max_validator.directive';
import { DateValidator } from 'Content/script/directives/date_validator.directive';
import { MaxByteValidator } from 'Content/script/directives/max-byte_validator.directive';
import { MinByteValidator } from 'Content/script/directives/min-byte_validator.directive';
import { CityInvalidValidator } from 'Content/script/directives/city_invalid_validator.directive';
import { PhoneInvalidValidator } from 'Content/script/directives/phone_invalid_validator.directive';
import { StreetNumberInvalidValidator } from 'Content/script/directives/street_number_invalid_validator.directive';
import { MaxRowsCountValidator } from 'Content/script/directives/max-rows-count_validator.directive';
import { MaxRowBytesValidator } from 'Content/script/directives/max-row-bytes_validator.directive';
import { MustMatchValidator } from 'Content/script/directives/must-match_validator.directive';
import { MaxTotalBytesValidator } from 'Content/script/directives/max-total_bytes_validator.directive';

@NgModule({
    declarations: [
        MaxValidator,
        DateValidator,
        MaxByteValidator,
        MinByteValidator,
        CityInvalidValidator,
        PhoneInvalidValidator,
        StreetNumberInvalidValidator,
        MaxRowsCountValidator,
        MaxRowBytesValidator,
        MustMatchValidator,
        MaxTotalBytesValidator
    ],
    exports: [
        MaxValidator,
        DateValidator,
        MaxByteValidator,
        MinByteValidator,
        CityInvalidValidator,
        PhoneInvalidValidator,
        StreetNumberInvalidValidator,
        MaxRowsCountValidator,
        MaxRowBytesValidator,
        MustMatchValidator,
        MaxTotalBytesValidator
    ]
})

export class ValidationModule { }
