import { createReducer, createFeatureSelector, createSelector, on } from '@ngrx/store';
import { ViewState, VIEWS } from '../states/view';
import { setViewState, setViewCurrent, setViewMode, setViewKeep, setViewLoading } from '../actions/view';

export const viewFeatureKey = 'view';

const initialState: ViewState = {
    mode: (window.isMobileViewMode ? VIEWS.SP : VIEWS.PC),
    keep: false,
    loading: true,
    current: (window.isMobileViewMode ? VIEWS.SP : VIEWS.PC)
};

export const reducer = createReducer(
    initialState,
    on(setViewState, (state: ViewState, viewState: ViewState) => (viewState)),
    on(setViewMode, (state: ViewState, obj) => ({
        ...state,
        mode: obj.value
    })),
    on(setViewKeep, (state: ViewState, obj) => ({
        ...state,
        keep: obj.value
    })),
    on(setViewLoading, (state: ViewState, obj) => ({
        ...state,
        loading: obj.value
    })),
    on(setViewCurrent, (state: ViewState, obj) => ({
        ...state,
        current: obj.value
    }))
);

const getState = createFeatureSelector<ViewState>(viewFeatureKey);

export const getViewState = createSelector(
    getState,
    state => state
);

export const getViewMode = createSelector(
    getState,
    state => state.mode
);

export const getViewKeep = createSelector(
    getState,
    state => state.keep
);

export const getViewLoading = createSelector(
    getState,
    state => state.loading
);

export const getViewCurrent = createSelector(
    getState,
    state => state.current
);



