<!-- modal -->
<div class="modal" id="domainDetail_modalDomainAbolitionDeterrence" modal [openFlg]="isShowAbolitionModal" data-dismiss="modal" (click)="onClickOutsideCloseModal($event)">
  <div class="modal-Dialog is-EmailAuthentication">
    <div class="modal-Dialog-Header">
      <h3 class="modal-Dialog-Header-Title size-17 is-Center">ドメインの解約をご希望の場合</h3>
    </div><!-- /.modal-Dialog-Header -->
    <div class="modal-Dialog-Body pt28 pr24 pb28 pl24">
      <section class="section">
        <p>対象ドメインの「自動更新設定」を「設定中」から「未設定」に変更することで、有効期限をもって解約となります。<br>ご希望の場合は以下より設定変更を行ってください。</p>
        <ul class="list-TextLink">
          <li><a href="javascript:void(0)" (click)="onClickRedirectToDomainNavi()"><i class="icon icon-ArrowCircleRight size-14"></i><span class="size-15">ドメイン自動更新設定</span></a></li>
        </ul>
        <div class="box-Appeal text-Left mt16 mb0">
          <p class="box-Appeal-Text text-weight-normal text-Indent size-13 pl14 mb0">※すでに「未設定」となっている場合はお手続きは不要です。</p>
          <p class="box-Appeal-Text text-weight-normal text-Indent size-13 pl14 mb0">※ご利用料金のご入金待ち、各種お手続きの処理中につきましては、自動更新設定の解除を行うことができかねますため、あらかじめご注意ください。</p>
        </div>
      </section>
    </div><!-- /.modal-Dialog-Body -->

    <button type="button" class="modal-Close gtmclick" data-dismiss="modal" (click)="onClickClose()" data-gtmvalue="detail_cancelmodal_close"><i class="icon icon-Clear gtmclick" aria-label="閉じる" data-gtmvalue="detail_cancelmodal_close"></i></button>
  </div><!-- /.modal-Dialog -->
</div><!-- /.modal -->
