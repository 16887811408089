import { Directive, ElementRef, AfterContentInit, OnDestroy } from '@angular/core';
import * as Pikaday from 'pikaday';

@Directive({
    selector: '[pikaday]'
})
export class PikadayDirective implements AfterContentInit, OnDestroy {
    private _el: HTMLElement;

    private pikaDay: Pikaday;

    constructor(private _elementRef: ElementRef) {
        this._el = _elementRef.nativeElement;
    }

    public ngAfterContentInit() {
        var selector = this._el.querySelector("input");
        var trigger = this._el.querySelector<HTMLElement>('.js-datepicker');
        var checkEnableWeeks = this._el.querySelector<HTMLElement>('.date-enable-week');
        var dateArr: string[] = [];
        if (checkEnableWeeks) {
            let dateNow = new Date();
            for (let i = 1; i <= 7; i++) {
                var currentDate = dateNow.getDate();
                dateNow.setDate(+currentDate + + 1);
                dateArr.push(dateNow.toDateString())
            }
        }
        this.pikaDay = new Pikaday(
            {
                field: selector,
                trigger: trigger,
                format: 'YYYY/MM/DD',
                position: 'right bottom',
                disableDayFn: (date) => {
                    if (!checkEnableWeeks) return false;
                    else if (dateArr.indexOf(date.toDateString()) > -1) {
                        return false;
                    } else {
                        return true;
                    }
                   
                } ,
                showDaysInNextAndPreviousMonths: true,
                i18n: {
                    previousMonth: '前月',
                    nextMonth: '来月',
                    months: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
                    weekdays: ['日曜日', '月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日'],
                    weekdaysShort: ['日', '月', '火', '水', '木', '金', '土']
                },
            });
    }

    public ngOnDestroy() {
        if (this.pikaDay) {
            this.pikaDay.destroy();
        }
    }
}
