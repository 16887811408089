import { DropDownItemsDefine } from 'Content/script/libs/define/drop_down_items.define';

export class DomainUtil {

    /**
    * ドメインのTLDを除いた文字列のみを抽出する
    * @param domainName ドメイン名
    */
    public static ExtractionDomainString(domainName: string): string {
        var endIndex = domainName.indexOf('.');
        return domainName.substring(0, endIndex);
    }

    /**
    * 属性型JPドメイン判断
    * @param tld TLD名
    */
    public static DetectionOrganizationalTypeJp(tld: string): boolean {
        return (tld === "co.jp" || tld === "or.jp" || tld === "ac.jp" || tld === "ed.jp" || tld === "go.jp" || tld === "ne.jp");
    }

    /**
    * 属性型JPドメイン判断
    * @param tld TLD名
    */
    public static DetectionGeneralUseJp(tld: string): boolean {
        return (tld === "ascii.jp");
    }

    /**
    * 郵便番号をハイフンで分割
    * @param pc 郵便番号
    */
    public static separatePc(pc: string): string[] {
        let _pcJp: string[] = [];
        let _pcJp1: string = "";
        let _pcJp2: string = "";
        if (pc) {
            let _pcs: string[] = pc.split("-");
            if (_pcs[0]) {
                _pcJp1 = _pcs[0];
            }
            if (_pcs[1]) {
                _pcJp2 = _pcs[1];
            }
            _pcJp[0] = _pcJp1;
            _pcJp[1] = _pcJp2;
        }
        return _pcJp;
    }

    /**
    * 都道府県名称から都道府県コードを引く
    * @param sp 都道府県名称(Ascii)
    */
    public static classifySp(sp: string): string {
        let _spCode = "01";
        if (DropDownItemsDefine.spAsciiItemList.find(x => x.text === sp)) {
            _spCode = DropDownItemsDefine.spAsciiItemList.find(x => x.text === sp).value;
        }
        return _spCode;
    }
} 
