import { Directive, ElementRef, Input, HostListener } from '@angular/core';

@Directive({
    selector: '[dropdownFocusout-b]'
})
export class DropdownFocusoutBDirective {
    private _el: HTMLElement;

    private parentElement: HTMLElement;

    @Input('parent')
    public parent: string;

    constructor(private _elementRef: ElementRef) {
        this._el = _elementRef.nativeElement;
    }
    @HostListener('mouseenter', ['$event'])
    public onMouseEnter(event: Event) {
        this.parentElement = document.querySelector(this.parent) as HTMLElement;
        this.parentElement.parentElement.classList.add('is-Active');
        this._el.classList.add('is-Active');
        event.stopImmediatePropagation();
        event.stopPropagation();

        return false;
    }

    @HostListener('mouseleave', ['$event'])
    public onBlur(event: MouseEvent) {
        const relatedTarget = event.relatedTarget as HTMLElement;

        if (relatedTarget === null && event.type !== "click") {
            return false;
        }
        this._el.classList.remove('is-Show');
        if (!this.parentElement.parentElement.classList.contains('.is-Active')) {
            this.parentElement.parentElement.classList.remove('is-Active');
        }
        this._el.classList.remove('is-Active');

        event.stopImmediatePropagation();
        event.stopPropagation();

        return false;
    }

    @HostListener('click', ['$event'])
    public OnClick(event: MouseEvent) {
        const clickedElement = event.target as HTMLElement;
        const guideWordFormElement = document.getElementById('guideWordFormNaviSupport');
        
        if (!(guideWordFormElement && clickedElement.closest('#guideWordFormNaviSupport') && this.parent === "#globalSideNaviSupportBtn")) {
            this.onBlur(event);
            return false;
        }
    }
}
