/// <reference path='../../../definitions/window.d.ts' />

import { Component } from '@angular/core';

import { NotifyMessageViewModel, NotifyMessage } from 'Content/script/service/notify_service';
import { ServiceContainer } from 'Content/script/service/service_container';

@Component({
    selector: 'notify_area',
    templateUrl: './notify.html'
})

export class NotifyComponent {
    public notifyMessageViewModel: NotifyMessageViewModel;

    public constructor(private serviceContainer: ServiceContainer) {
        this.notifyMessageViewModel = serviceContainer.NotifyService.notifyMessageViewModel;
    }

    public ButtonInfoClose(notifyId: number): void {
        this.serviceContainer.NotifyService.RemoveInfoNotifyMessage(notifyId);
    }

    public ButtonErrorClose(notifyId: number): void {

        var index: number = this.notifyMessageViewModel.notifyErrorMessageList.findIndex((notifyMessage: NotifyMessage) => {
            return notifyMessage.notifyId == notifyId
        });
        if (index != -1) {
            var notify = this.notifyMessageViewModel.notifyErrorMessageList[index];
            switch (notify.notifyMessage)
            {
                case "お名前IDまたはパスワードが間違っています。":
                    window.dataLayerPush("pwfalse");
                    break;
            }
        }

        this.serviceContainer.NotifyService.RemoveErrorNotifyMessage(notifyId);
    }

    public onClickShowInfo(notifyId: number): void {
        this.serviceContainer.NotifyService.changeShowInfo(notifyId);
    }

    public onClickShowError(notifyId: number): void {
        this.serviceContainer.NotifyService.changeShowError(notifyId);
    }
}
