/// <reference path='../../../definitions/window.d.ts' />

import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { DomainItem } from 'Content/script/components/share/Templates/withdrawal_prevention_modal/withdrawal_prevention_modal.component';
import { AnalyticsUtil } from 'Content/script/libs/adobeanalytics/analytics.util';
import { ValidationRegexsDefine } from 'Content/script/libs/define/validation_regexs.define';
import { serverOrderRedirection } from 'Content/script/libs/redirection/orderRedirection';
import { Service } from 'Content/script/service/account_info_service';
import { GlobalNaviViewModel } from "Content/script/service/header_navigation_service";
import { NotifyMessage, NotifyType } from 'Content/script/service/notify_service';
import { ServiceContainer } from 'Content/script/service/service_container';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { getAccountInfo } from '../../../store/reducers/account-info';
import { AccountInfoState } from '../../../store/states/account-info';
import { BaseLayoutComponent } from '../base_layout.component';
import { DomainUtil } from '../../../libs/utils/domain.util';
import { CustomizedEvents } from '../../../libs/abtest/custom-event.util';

@Component({
    selector: 'header-menu',
    templateUrl: './header.html'
})

/******************
 * ヘッダーメニュー
 ******************/
export class HeaderMenuComponent extends BaseLayoutComponent implements AfterViewInit, OnDestroy {
    @Input() public isDisplayGlobalNavi: boolean = true;
    @Input() public hasHeadNotification: boolean = false;
    @Output() onChangeLoadingFlagEvent = new EventEmitter<any>();

    public headerViewModel: HeaderViewModel;
    public globalNaviViewModel: GlobalNaviViewModel;

    public isLoading: boolean;
    public isAllLoading: boolean;
    // TODO: メニューを表示する座標をいれる
    //posX: number = 0;

    public parallelRenovationGlobalNaviSubmenu: string = '';
    private _queueId: number;
    public get isABNew_HeaderInfoDropDownMenu(): boolean {
        return window.isABNew_HeaderInfoDropDownMenu;
    }

    /**
     * コンストラクタ
     * @param router
     * @param serviceContainer
     */
    public constructor(
        protected router: Router,
        protected serviceContainer: ServiceContainer,
        protected http: HttpClient,
        private storeAccountInfo: Store<AccountInfoState>
    ) {
        super();
        this.headerViewModel = new HeaderViewModel(router, serviceContainer, http);

        this.headerViewModel.navigationName = "top";
        if (serviceContainer.HeaderComponentService)
        {
            this.globalNaviViewModel = serviceContainer.HeaderComponentService.GlobalNaviViewModel;
        }
        
        this._queueId = this.serviceContainer.HubsService.multipleSendManager.GetComponentId();

        this.isLoading = false;
        this.isAllLoading = false;

        this.addStoreSubscribe(this.storeAccountInfo.select(getAccountInfo).subscribe(state => {
            const accountInfo = this.serviceContainer.AccountInfoService.mappingAccountInfo(state);
            this.headerViewModel.onamaeId = accountInfo.LaginName;
            this.headerViewModel.viewHiddenInvoice = accountInfo.ViewHiddenInvoice;
            this.headerViewModel.roleId = accountInfo.RoleId;
            this.headerViewModel.hasDomain = accountInfo.HasDomain;

            this.headerViewModel.ShowSitemapCreate = accountInfo.ShowSitemapCreate;
            this.headerViewModel.ShowSitemapPremiumCreate = accountInfo.ShowSitemapPremiumCreate;
            this.headerViewModel.ShowSitemapGtldAuction = accountInfo.ShowSitemapGtldAuction;
            this.headerViewModel.ShowSitemapJpAuction = accountInfo.ShowSitemapJpAuction;
            this.headerViewModel.ShowSitemapBulkApplicationList = accountInfo.ShowSitemapBulkApplicationList;
            this.headerViewModel.ShowSitemapGtldBackorder = accountInfo.ShowSitemapGtldBackorder;
            this.headerViewModel.ShowSitemapJpBackorder = accountInfo.ShowSitemapJpBackorder;
            this.headerViewModel.ShowSitemapMonitoring = accountInfo.ShowSitemapMonitoring;
            this.headerViewModel.ShowSitemapJpCreateCorrection = accountInfo.ShowSitemapJpCreateCorrection;
            this.headerViewModel.ShowSitemapCojpRegistration = accountInfo.ShowSitemapCojpRegistration;
            this.headerViewModel.ShowSitemapTransferIn = accountInfo.ShowSitemapTransferIn;
            this.headerViewModel.ShowDomainTransferStatusList = accountInfo.ShowDomainTransferStatusList;
            this.headerViewModel.ShowSitemapTransferLock = accountInfo.ShowSitemapTransferLock;
            this.headerViewModel.ShowSitemapNsChange = accountInfo.ShowSitemapNsChange;
            this.headerViewModel.ShowSitemapDnsSetting = accountInfo.ShowSitemapDnsSetting;
            this.headerViewModel.ShowSitemapDsRecord = accountInfo.ShowSitemapDsRecord;
            this.headerViewModel.ShowSitemapWhoisInfoChange = accountInfo.ShowSitemapWhoisInfoChange;
            this.headerViewModel.ShowSitemapWhoisProxy = accountInfo.ShowSitemapWhoisProxy;
            this.headerViewModel.ShowSitemapWhoisMailForward = accountInfo.ShowSitemapWhoisMailForward;
            this.headerViewModel.ShowSitemapJpInfoChange = accountInfo.ShowSitemapJpInfoChange;
            this.headerViewModel.ShowSitemapTmch = accountInfo.ShowSitemapTmch;
            this.headerViewModel.ShowSitemapIdReplace = accountInfo.ShowSitemapIdReplace;
            this.headerViewModel.ShowSitemapDomainProtectInput = accountInfo.ShowSitemapDomainProtectInput;
            this.headerViewModel.ShowSitemapDomainProtectUpdateInput = accountInfo.ShowSitemapDomainProtectUpdateInput;
            this.headerViewModel.ShowSitemapDomainEns = accountInfo.ShowSitemapDomainEns;
            this.headerViewModel.ShowSitemapMailForward = accountInfo.ShowSitemapMailForward;
            this.headerViewModel.ShowSitemapUrlForward = accountInfo.ShowSitemapUrlForward;
            this.headerViewModel.ShowSitemapSetting_Ssl = accountInfo.ShowSitemapSetting_Ssl;
            this.headerViewModel.ShowSitemapSslManage = accountInfo.ShowSitemapSslManage;
            this.headerViewModel.ShowSitemapPaymentInfoDomain = accountInfo.ShowSitemapPaymentInfoDomain;
            this.headerViewModel.ShowSitemapEvidence = accountInfo.ShowSitemapEvidence;
            this.headerViewModel.ShowSitemapDelService = accountInfo.ShowSitemapDelService;
            this.headerViewModel.ShowSitemapDelServiceReport = accountInfo.ShowSitemapDelServiceReport;
        }));

        this._getHeaderInfo();
    }

    public async ngOnInit() {
        const AB = this.serviceContainer.ABCookieService;

        await AB.RenovationGlobalNaviSubmenu.resolveAsyncAll();
        this.parallelRenovationGlobalNaviSubmenu = await AB.RenovationGlobalNaviSubmenu.valueAsync();
        AB.RenovationGlobalNaviSubmenu.dispatchEvent_sendCookiesBaggage(null);
        
        this.headerViewModel.isSearchHelpArticles = await AB.SearchSuggest.tests.SearchSuggest.isNewAsync();

        window.isABNew_HeaderInfoDropDownMenu = await AB.HeaderInfoDropDownMenu.tests.HeaderInfoDropDownMenu.isNewAsync();
        window.ForYouInfoCount = 0;
    }

    public keyupSearch(event: any) {
        window.setTimeout(() => {
            var target = event.target;
            if (!(target instanceof HTMLInputElement)) {
                return;
            }
            this.headerViewModel.searchSuggest(target.value);
        }, 400);
    }

    public ngAfterViewInit() {
        // サジェスト検索時のObservableを作成
        fromEvent(document.getElementById('searchInput'), 'keyup').pipe(
            debounceTime(400)
        ).subscribe((event: InputEvent) => {
            var target = event.target;
            if (!(target instanceof HTMLInputElement)) {
                return;
            }
            this.headerViewModel.searchSuggest(target.value);
        });
    }

    public ngOnDestroy() {
        this.clearStoreSubscribes();
        this.serviceContainer.HubsService.multipleSendManager.DeleteComponentId(this._queueId);
    }

    public CacheBusting(uri: string): string {
        return window.CacheBusting(uri);
    }

    private _getHeaderInfo() {
        /*****************************************************
         * Promiseクラスで処理をする事によって、
         * コールバックを渡さずに独自の処理を実装出来るので
         * Promiseをサービスから返すように実装しています
         *****************************************************/
        var accountLayoutPromise: Promise<Service.AccountInfo> = this.serviceContainer.AccountInfoService.GetAccountInfo("header.component");
        accountLayoutPromise.then((accountInfo) => {
            this.headerViewModel.onamaeId = accountInfo.LaginName;
            this.headerViewModel.viewHiddenInvoice = accountInfo.ViewHiddenInvoice;
            this.headerViewModel.roleId = accountInfo.RoleId;
            this.headerViewModel.hasDomain = accountInfo.HasDomain;

            this.headerViewModel.ShowSitemapCreate = accountInfo.ShowSitemapCreate;
            this.headerViewModel.ShowSitemapPremiumCreate = accountInfo.ShowSitemapPremiumCreate;
            this.headerViewModel.ShowSitemapGtldAuction = accountInfo.ShowSitemapGtldAuction;
            this.headerViewModel.ShowSitemapJpAuction = accountInfo.ShowSitemapJpAuction;
            this.headerViewModel.ShowSitemapBulkApplicationList = accountInfo.ShowSitemapBulkApplicationList;
            this.headerViewModel.ShowSitemapGtldBackorder = accountInfo.ShowSitemapGtldBackorder;
            this.headerViewModel.ShowSitemapJpBackorder = accountInfo.ShowSitemapJpBackorder;
            this.headerViewModel.ShowSitemapMonitoring = accountInfo.ShowSitemapMonitoring;
            this.headerViewModel.ShowSitemapJpCreateCorrection = accountInfo.ShowSitemapJpCreateCorrection;
            this.headerViewModel.ShowSitemapCojpRegistration = accountInfo.ShowSitemapCojpRegistration;
            this.headerViewModel.ShowSitemapTransferIn = accountInfo.ShowSitemapTransferIn;
            this.headerViewModel.ShowDomainTransferStatusList = accountInfo.ShowDomainTransferStatusList;
            this.headerViewModel.ShowSitemapTransferLock = accountInfo.ShowSitemapTransferLock;
            this.headerViewModel.ShowSitemapNsChange = accountInfo.ShowSitemapNsChange;
            this.headerViewModel.ShowSitemapDnsSetting = accountInfo.ShowSitemapDnsSetting;
            this.headerViewModel.ShowSitemapDsRecord = accountInfo.ShowSitemapDsRecord;
            this.headerViewModel.ShowSitemapWhoisInfoChange = accountInfo.ShowSitemapWhoisInfoChange;
            this.headerViewModel.ShowSitemapWhoisProxy = accountInfo.ShowSitemapWhoisProxy;
            this.headerViewModel.ShowSitemapWhoisMailForward = accountInfo.ShowSitemapWhoisMailForward;
            this.headerViewModel.ShowSitemapJpInfoChange = accountInfo.ShowSitemapJpInfoChange;
            this.headerViewModel.ShowSitemapTmch = accountInfo.ShowSitemapTmch;
            this.headerViewModel.ShowSitemapIdReplace = accountInfo.ShowSitemapIdReplace;
            this.headerViewModel.ShowSitemapDomainProtectInput = accountInfo.ShowSitemapDomainProtectInput;
            this.headerViewModel.ShowSitemapDomainProtectUpdateInput = accountInfo.ShowSitemapDomainProtectUpdateInput;
            this.headerViewModel.ShowSitemapDomainEns = accountInfo.ShowSitemapDomainEns;
            this.headerViewModel.ShowSitemapMailForward = accountInfo.ShowSitemapMailForward;
            this.headerViewModel.ShowSitemapUrlForward = accountInfo.ShowSitemapUrlForward;
            this.headerViewModel.ShowSitemapSetting_Ssl = accountInfo.ShowSitemapSetting_Ssl;
            this.headerViewModel.ShowSitemapSslManage = accountInfo.ShowSitemapSslManage;
            this.headerViewModel.ShowSitemapPaymentInfoDomain = accountInfo.ShowSitemapPaymentInfoDomain;
            this.headerViewModel.ShowSitemapEvidence = accountInfo.ShowSitemapEvidence;
            this.headerViewModel.ShowSitemapDelService = accountInfo.ShowSitemapDelService;
            this.headerViewModel.ShowSitemapDelServiceReport = accountInfo.ShowSitemapDelServiceReport;

            window.isFirstRender = false;
            this.serviceContainer.ModalControlService.HideFullScreenLoading();
            this.headerViewModel.frequentlyViewedPages = this.serviceContainer.HeaderComponentService.FrequentlyViewedPages;

            if (accountInfo.IsTmpRegist) {
                // 仮登録の場合は仮登録情報編集画面へ
                // 仮登録関連の画面の場合は遷移の制御をしない
                let paths: string[] = this._splitPaths(this.router.url);
                let currentPath = (paths.length < 1) ? '' : paths[1];
                if (currentPath !== 'provisionalaccount') {
                    this.router.navigateByUrl('provisionalaccount/detail/edit');
                    return;
                }
            }
        }).catch((errorType: Service.ErrorType) => {
            let url = this.headerViewModel.directLinkWithError(location.pathname);
            switch (errorType) {
                case Service.ErrorType.Error:
                    this.serviceContainer.SessionService.SetCallBackUrl(location.pathname);
                    this.router.navigateByUrl(url);
                    return;

                case Service.ErrorType.NoSession:
                    this.serviceContainer.SessionService.SetCallBackUrl(location.pathname);
                    const queryparams = AnalyticsUtil.getQueryParamsOnFirstRender(window.RouterPath);
                    this.router.navigate([url], { queryParams: queryparams });
                    return;

                case Service.ErrorType.UnExcepted:
                    location.href = '/error/server';
                    return;
            }
        });

        //未読数取得
        var newsPromise: Promise<void> = this.serviceContainer.NewsService.GetTotalUnreadCount("header.component");
        newsPromise.then(() => {
            window.isFirstRender = false;
            this.serviceContainer.ModalControlService.HideFullScreenLoading();
            this.headerViewModel.frequentlyViewedPages = this.serviceContainer.HeaderComponentService.FrequentlyViewedPages;
        }).catch((errorType: Service.ErrorType) => {
            switch (errorType) {
                case Service.ErrorType.Error:
                    return;

                case Service.ErrorType.NoSession:
                    return;

                case Service.ErrorType.UnExcepted:
                    return;
            }
        });

        // あなた宛アラート
        const domainInfoForNotification: Promise<void> = this.headerViewModel.getDomainInfoForNotification();
        const domainWithDefaultNameServer: Promise<void> = this.headerViewModel.getDomainWithDefaultNameServer();

        const promises = [
            domainInfoForNotification,
            domainWithDefaultNameServer
        ];

        Promise.all(promises)
            .then(() => {
                if (this.headerViewModel.isShowDomainRenew || this.headerViewModel.isShowWithDefaultNameServer) {
                    let showMessage = "";

                    // 発火時文字列を条件によって変更する
                    if (this.headerViewModel.isShowDomainRenew && this.headerViewModel.isShowWithDefaultNameServer) {
                        showMessage = "domain_rs";
                    } else if (this.headerViewModel.isShowDomainRenew && !this.headerViewModel.isShowWithDefaultNameServer) {
                        showMessage = "domain";
                    } else {
                        showMessage = "rs";
                    }

                    showMessage = this.isABNew_HeaderInfoDropDownMenu ? showMessage + "_B" : showMessage + "_A";
                    const AB = this.serviceContainer.ABCookieService;
                    AB.HeaderInfoDropDownMenu.tests.HeaderInfoDropDownMenu.dispatchEvent_sendCookiesBaggage({ condition: showMessage });
                    window.ForYouInfoCount = Number(this.headerViewModel.isShowCojpDefine) + Number(this.headerViewModel.isShowSSL) + Number(this.headerViewModel.isShowDomainRenew) + Number(this.headerViewModel.isShowRestoreDomain) + Number(this.headerViewModel.isShowUnpaid) + Number(this.headerViewModel.isShowWithDefaultNameServer);
                    this.headerViewModel.openInfoDropDown();
                }
                if (this.isABNew_HeaderInfoDropDownMenu) {
                    const invoiceUnpaid: Promise<void> = this.headerViewModel.getInvoiceUnpaid();
                    const itemPaymentFailureList: Promise<void> = this.headerViewModel.getItemPaymentFailureList();
                    const checkCreditCard: Promise<void> = this.headerViewModel.getCheckCreditCard();

                    const promisesB = [
                        invoiceUnpaid,
                        itemPaymentFailureList,
                        checkCreditCard
                    ];

                    Promise.all(promisesB)
                        .then(async () => {
                            window.ForYouInfoCount = Number(this.headerViewModel.isShowCojpDefine)
                                                    + Number(this.headerViewModel.isShowSSL)
                                                    + Number(this.headerViewModel.isShowDomainRenew)
                                                    + Number(this.headerViewModel.isShowRestoreDomain)
                                                    + Number(this.headerViewModel.isShowUnpaid)
                                                    + Number(this.headerViewModel.isShowWithDefaultNameServer)
                                                    + Number(this.headerViewModel.isExistPaymentFailure)
                                                    + Number(this.headerViewModel.isShowOshiraseShindan)
                                                    + Number(this.headerViewModel.isShowCreditCardUpdate);
                            if (window.ForYouInfoCount > 0 && (!this.headerViewModel.isShowDomainRenew && !this.headerViewModel.isShowWithDefaultNameServer)) {
                                this.headerViewModel.openInfoDropDown();
                            }
                        })
                        .catch((error) => {
                            // エラーハンドリング
                            console.error(error);
                        });
                }
            })
            .catch((error) => {
                // エラーハンドリング
                console.error(error);
            });
    }


    /**
     * パスの分割処理
     * IE9でhashが入って来る為に、処理が必要となった
     */
    private _splitPaths(targetPath: string): string[] {
        let path: string = targetPath;
        if (window.browserType == 'IE9') {
            path = location.hash;
        }
        let paths: string[] = path.split('/');
        return paths;
    }

    /**
     * シングルサインオン
    */
    private doRedirection(navClass: string, queryParams: NavigationQueryHubDto[] = []): void {
        this.isAllLoading = true;

        var request: NavigationRequestHubDto = {
            Host: "DOMAIN_NAVI",
            NavigationClass: navClass,
            NavigationParams: queryParams
        };

        this.serviceContainer.HubsService.multipleSendManager.Send(
            this._queueId,
            this.serviceContainer.HubsService.hubsManager.navigationHubManager.TokenUrl,
            request,
            (response: NavigationResponseHubDto) => {
                window.open(response.TokenUrl, '_self');
            },
            (response: NavigationResponseHubDto) => {
                this.isAllLoading = false;
            },
            (response: NavigationResponseHubDto) => {
                this.isLoading = false;
                this.router.navigateByUrl('/top');
                return false;
            },
            null,
            (response: NavigationResponseHubDto) => {
                this.isLoading = false;
                this.router.navigateByUrl('/error/server');
                return false;
            },
        );
    }

    /**
     * 旧ナビのドメイン自動更新へ遷移する処理
     * ex https://onamae.test/domain/navi/auto_renew/input
     */
    public onClickDomainAutoRenew(): void {
        var transferData: IDataTransferDomainAutoRenewSpecificDomainInfoDto = {
            isSpecific: false,
            domainName: '',
            btnId: 'newnavi_setting__oldnavi_autorenew',
        } as IDataTransferDomainAutoRenewSpecificDomainInfoDto;

        const transferKey = this.serviceContainer.DataTransferService.DomainAutoRenewSpecificDomainInfoKey;
        this.serviceContainer.DataTransferService.setData(transferData, transferKey);

        this.isLoading = true;
        this.router.navigateByUrl('domain/setting/autorenew/');
    }

    /**
     * 旧ナビの廃止済みドメインの復旧へ遷移する処理
     * ex https://onamae.test/domain/navi/domain_restore/input
     */
    public onClickDomainRestore(): void {
        this.doRedirection("DOMAIN_RESTORE");
    }

    /**
     * 旧ナビのプレミアムドメイン登録へ遷移する処理
     * ex http://www.onamae-premium.com/domain/onamae/premium/
     */
    public onClickPremiumDomainCreate(): void {
        var queryParams: NavigationQueryHubDto[] = [
            {
                QueryKey: "btn_id",
                QueryValue: "header_allmenu_premium"
            }
        ];
        this.doRedirection("DOMAIN_PREMIUM", queryParams);
    }

    /**
     * 旧ナビの「セット割」申請管理へ遷移する処理
     * ex https://onamae.test/domain/navi/bulk_applicationlist
     */
    public onClickBulkApplication(): void {
        this.router.navigateByUrl('domain/setting/bulk/applicationlist');
    }

    /**
     * 旧ナビの「ドメインモニタリング」申請管理へ遷移する処理
     * ex https://www.onamae.test/domain/navi/domainmonitoring/monitoring_list
     */
    public onClickDomainMonitoring(): void {
        var queryParams: NavigationQueryHubDto[] = [
            {
                QueryKey: "btn_id",
                QueryValue: "header_allmenu_moniter"
            }
        ];
        this.doRedirection("DOMAIN_MONITORING", queryParams);
    }

    /**
     * 属性型JP登録申請修正画面へ遷移
     */
    public onClickJpDomainRevision(): void {
        this.router.navigate(['/domain/setting/jpdomain/select/']);
    }

    /**
     * 旧ナビの.co.jpドメイン本登録申請へ遷移する処理
     * ex https://onamae.test/domain/navi/jp_domain_definitive/input
     */
    public onClickJpDomainDefinitive(): void {
        this.doRedirection("DOMAIN_JP_DEFINITIVE");
    }

    /**
     * 旧ナビのDNS関連機能の設定へ遷移する処理
     * ex https://onamae.test/domain/navi/dns_manage
     */
    public onClickDnsManage(): void {
        var queryParams: NavigationQueryHubDto[] = [
            {
                QueryKey: "btn_id",
                QueryValue: "header_allmenu_dnsmanage"
            }
        ];
        this.doRedirection("DOMAIN_DNS_MANAGE", queryParams);
    }

    /**
     * DSレコード設定へ遷移
     */
    public onClickDsRecord(): void {
        this.router.navigate(['/domain/setting/dsrecord/select']);
    }

    /**
     * 旧ナビのドメインWhois情報変更へ遷移する処理
     * ex https://onamae.test/domain/navi/domain_update/input
     */
    public onClickDomainContactUpdate(): void {
        //this.doRedirection("DOMAIN_DOMAIN_UPDATE");
        this.router.navigate(['/domain/setting/whoischange/domainselect']);
    }

    /**
     * 旧ナビのWhois情報公開代行へ遷移する処理
     * ex https://onamae.test/domain/navi/whois_protect/input
     */
    public onClickWhoisProtect(): void {
        // this.doRedirection("DOMAIN_WHOIS_PROTECT");
        this.isAllLoading = true;
        this.router.navigateByUrl(`domain/setting/whoisprotect/list`);
    }

    /**
     * 旧ナビのWhois情報公開代行メール転送オプションへ遷移する処理
     * ex https://onamae.test/domain/navi/whois_protect_mailforward/input
     */
    public onClickWhoisProtectMailForward(): void {
        this.doRedirection("DOMAIN_WHOIS_PROTECT_MAILFORWARD");
    }

    public onClickTmch(): void {
        this.router.navigate(['/domain/setting/tmch']);
    }

    /**
    * 旧ナビの転送Plus(メール)遷移する処理
    * ex https://onamae.test/domain/navi/mail_forward/select
    */
    public onClickMailForward(): void {
        var queryParams: NavigationQueryHubDto[] = [
            {
                QueryKey: "btn_id",
                QueryValue: "header_allmenu_mailforward"
            }
        ];
        this.doRedirection("DOMAIN_MAIL_FORWARD", queryParams);
    }

    /**
     * 旧ナビの転送Plus(URL)遷移する処理
     * ex https://onamae.test/domain/navi/web_forward/select
     */
    public onClickURLForward(): void {
        var queryParams: NavigationQueryHubDto[] = [
            {
                QueryKey: "btn_id",
                QueryValue: "header_allmenu_webforward"
            }
        ];
        this.doRedirection("DOMAIN_WEB_FORWARD", queryParams);
    }

    /**
     * SSLサーバー証明書発行へ遷移する処理
     */
    public onClickSslNew(): void {
        var queryParams: NavigationQueryHubDto[] = [
            {
                QueryKey: "btn_id",
                QueryValue: "header_allmenu_sslcreate"
            }
        ];
        this.doRedirection("DOMAIN_SSL_NEW");
    }

    /**
     * SSLサーバー証明書管理へ遷移する処理
     */
    public onClickSslSelect(): void {
        var queryParams: NavigationQueryHubDto[] = [
            {
                QueryKey: "btn_id",
                QueryValue: "header_allmenu_sslmanage"
            }
        ];
        this.doRedirection("DOMAIN_SSL_SELECT", queryParams);
    }

    /**
     * ドメイン名売買サービス 上場申請への移行の流れ
    */
    public onClickSellDomainCreate(): void {
        this.router.navigateByUrl('/auth/selldomain/')
    }

    public onClickSellDomainHistory(): void {
        this.router.navigateByUrl('/domain/selldomain/history')
    }

    /**
     * ドメインパーキング ドメイン追加・削除へ遷移する処理
     */
    public onClickParkingDomainRegist(): void {
        this.router.navigate(['/domain/setting/parking/domainregist/input']);
    }

    /**
     * ドメインパーキング レポートへ遷移する処理
     */
    public onClickParkingReport(): void {
        this.router.navigate(['/domain/setting/parking/report/account']);
    }

    /**
     * ドメインパーキング 残高照会・払出へ遷移する処理
     */
    public onClickParkingPayoutInput(): void {
        this.router.navigate(['/domain/setting/parking/payout/input']);
    }

    /**
     * ドメインパーキング 出金履歴へ遷移する処理
     */
    public onClickParkingPayoutHistory(): void {
        this.router.navigate(['/domain/setting/parking/payout/history']);
    }

    /**
     * バックオーダー一覧
     */
    public onClickBackOrderList(): void {
          this.router.navigate(['/domain/setting/backorder']);
    }

    /**
     * バックオーダー履歴
     */
    public onClickBackOrderHistory(): void {
        this.router.navigate(['/domain/setting/backorder/history']);
    }

    /**
     * 旧ナビのドメインプロテクション申込へ遷移する処理
     * ex https://onamae.test/domain/navi/domainprotect/input
     */
    public onClickDomainProtectInput(): void {
        this.router.navigate(['domain/setting/protect/apply/input']);
    }

    public onClickDomainProtectUpdateInput(): void {
        this.router.navigate(['domain/setting/protect/update/input']);
    }

    ///**
    // * 旧ナビのENS設定へ遷移する処理
    // * ex https://www.onamae.com/domain/navi/ens/input
    // */
    //public onClickDomainEns(): void {
    //    this.doRedirection("DOMAIN_ENS");
    //}

    /**
     * 旧ナビのDNS関連機能の設定へ遷移する処理
     * ex https://onamae.test/domain/navi/dns_manage
     * */
    public onClickDnsSettingButton(): void {
        var queryParams: NavigationQueryHubDto[] = [
            {
                QueryKey: "btn_id",
                QueryValue: "navi_gm_dns_pd_dns_manage"
            }
        ];

        this.doRedirection("DOMAIN_DNS_MANAGE", queryParams);
    }

    public onClickRedirectToNs(): void {
        this.router.navigate(['/domain/setting/ns']);
    }

    /**
     * 旧ナビのSSLサーバー証明書発行へ遷移する処理
     * ex https://onamae.test/domain/navi/ssl_new/select
     */
    public onClickSslIssueButton(): void {
        var queryParams: NavigationQueryHubDto[] = [
            {
                QueryKey: "btn_id",
                QueryValue: "navi_gm_ssl_pd_ssl_new"
            }
        ];

        this.doRedirection("DOMAIN_SSL_NEW", queryParams);
    }

    /**
     * 商材申し込みのシングルサインオン
     */
    public doRedirection_Maradona(orderClass: string): void {
        this.isLoading = false;
        this.isAllLoading = true;

        var request: ServerOrderRedirectionRequestHubDto = {
            NavigationClass: orderClass,
            RedirectionParams: []
        };

        serverOrderRedirection(
            document,
            this.serviceContainer,
            this.serviceContainer.HubsService.multipleSendManager,
            this.router,
            this._queueId,
            request
        );
    }

    /**
     * マラドーナのDC申し込みへ遷移
     * ex https://cart.onamae.com/server/order/dcmt4
     * */
    public onClickDesktopApplyButton(): void {
        this.doRedirection_Maradona("DESKTOPCLOUD2_ORDER_MT4_GLOBAL_NAVI");
    }

    /**
    * マラドーナのSSL申し込みへ遷移
    * ex https://cart.onamae.com/server/order/ssl
    * */
    public onClickSslApplyButton(): void {
        this.doRedirection_Maradona("SSL_ORDER_GLOBAL_NAVI");
    }

    /**
     * マラドーナのRS申し込みへ遷移
     * ex https://cart.onamae.com/server/order/rs_navi
     * */
    public onClickRsApplyButton(): void {
        this.doRedirection_Maradona("SD_ORDER_FROM_GLOBAL_NAVI");
    }

    /**
     * マラドーナのMicrosoft申し込みへ遷移
     * ex https://cart.onamae.com/server/order/office
     * */
    public onClickMicrosoftApplyButton(): void {
        this.doRedirection_Maradona("OFFICE365_ORDER_GLOBAL_NAVI");
    }

    /**
     * お名前メール申込みへ遷移
     * */
    public onClickMailApplayButton(): void {
        if (this.headerViewModel.hasDomain) {
            this.router.navigate(['mail/add/select']);
        } else {
            this.doRedirection_Maradona("MAIL_ORDER")
        }
    }

    public openUrl(url:string) {
        window.open(url,'_blank', 'noopener,noreferrer');
    }

    /**
     * お知らせ再読み込み
     * TODO:B寄せ時にStoreでアラート表示の管理を行う mizunaga
     */
    public getForYouInfo(): void {
        let _el: HTMLElement = document.getElementById("headerInfoDropDownB");

        if (_el === null) {
            return;
        }

        if (_el.classList.contains("is-Open")) {
            const domainInfoForNotification: Promise<void> = this.headerViewModel.getDomainInfoForNotification();
            const domainWithDefaultNameServer: Promise<void> = this.headerViewModel.getDomainWithDefaultNameServer();
            const invoiceUnpaid: Promise<void> = this.headerViewModel.getInvoiceUnpaid();
            const itemPaymentFailureList: Promise<void> = this.headerViewModel.getItemPaymentFailureList();

            const promises = [
                domainInfoForNotification,
                domainWithDefaultNameServer,
                invoiceUnpaid,
                itemPaymentFailureList
            ];

            Promise.all(promises)
                .then(async () => {
                    window.ForYouInfoCount = Number(this.headerViewModel.isShowCojpDefine)
                                            + Number(this.headerViewModel.isShowSSL)
                                            + Number(this.headerViewModel.isShowDomainRenew)
                                            + Number(this.headerViewModel.isShowRestoreDomain)
                                            + Number(this.headerViewModel.isShowUnpaid)
                                            + Number(this.headerViewModel.isShowWithDefaultNameServer)
                                            + Number(this.headerViewModel.isExistPaymentFailure)
                                            + Number(this.headerViewModel.isShowOshiraseShindan)
                                            + Number(this.headerViewModel.isShowCreditCardUpdate);
                })
                .catch((error) => {
                    // エラーハンドリング
                    console.error(error);
                });
        }
    }
}

/*******************************************
 * ビューモデルバインディングで使用するモデル
 *******************************************/
export class HeaderViewModel {
    private isProduct: Boolean;

    // お名前ID
    public onamaeId: string;

    public navigationName: string;

    public frequentlyViewedPages: Array<FrequentlyViewedPage>;

    private queueId: number;
    private router: Router;
    private serviceContainer: ServiceContainer;
    private http: HttpClient;
    public isLoading: boolean;

    public viewHiddenInvoice: boolean;
    public roleId: string;
    public hasDomain: boolean;

    public ShowSitemapCreate: boolean;
    public ShowSitemapPremiumCreate: boolean;
    public ShowSitemapGtldAuction: boolean;
    public ShowSitemapJpAuction: boolean;
    public ShowSitemapBulkApplicationList: boolean;
    public ShowSitemapGtldBackorder: boolean;
    public ShowSitemapJpBackorder: boolean;
    public ShowSitemapMonitoring: boolean;
    public ShowSitemapJpCreateCorrection: boolean;
    public ShowSitemapCojpRegistration: boolean;
    public ShowSitemapTransferIn: boolean;
    public ShowDomainTransferStatusList: boolean;
    public ShowSitemapTransferLock: boolean;
    public ShowSitemapNsChange: boolean;
    public ShowSitemapDnsSetting: boolean;
    public ShowSitemapDsRecord: boolean;
    public ShowSitemapWhoisInfoChange: boolean;
    public ShowSitemapWhoisProxy: boolean;
    public ShowSitemapWhoisMailForward: boolean;
    public ShowSitemapJpInfoChange: boolean;
    public ShowSitemapTmch: boolean;
    public ShowSitemapIdReplace: boolean;
    public ShowSitemapDomainProtectInput: boolean;
    public ShowSitemapDomainProtectUpdateInput: boolean;
    public ShowSitemapDomainEns: boolean;
    public ShowSitemapMailForward: boolean;
    public ShowSitemapUrlForward: boolean;
    public ShowSitemapSetting_Ssl: boolean;
    public ShowSitemapSslManage: boolean;
    public ShowSitemapPaymentInfoDomain: boolean;
    public ShowSitemapEvidence: boolean;
    public ShowSitemapDelService: boolean;
    public ShowSitemapDelServiceReport: boolean;

    public isNoSuggestResult: boolean;
    public isNoHelpSuggestResult: boolean;
    public isShowAllSuggestBtn: boolean;
    public searchInputWord: string = "";
    public suggestData: ISuggestItemModel[] = [];
    public showSuggests: ISuggestItemModel[] = [];
    public suggestAllResults: ISuggestItemModel[] = [];
    public showHelpPageSuggests: ISuggestItemModel[] = [];

    public isSearchHelpArticles: boolean;

    //メニュー一覧フラグ
    public isShowMenuListModal: boolean;

    // お知らせ系
    public showForYouTab: boolean;
    public isShowDomainRenew: boolean; //ドメイン更新期限間近
    public renewDomainList: NearExpirationDomain[];
    public isShowCojpDefine: boolean; //cojp仮登録
    public isShowSSL: boolean; //SSL証明書発行未完了
    public sslPrepaymentExpirationDate: string;
    public isShowRestoreDomain: boolean; //廃止済みドメイン
    public isShowUnpaid: boolean; //未入金
    public unpaidTransitionPath: string;
    public isShowWithDefaultNameServer: boolean; //NSがデフォルト
    public isExistPaymentFailure: boolean;
    public isShowHeaderBalloon: boolean;

    // 未読数の各変数
    public get totalUnreadCount(): number {
        return window.TotalUnreadCount;
    }

    // 未読数の各変数_B
    public get totalUnreadCountB(): number {
        return window.TotalUnreadCount + window.ForYouInfoCount;
    }

    public get forYouInfoCount(): number {
        return window.ForYouInfoCount;
    }

    public get importantTotalUnreadCount(): number {
        return window.ImportantTotalUnreadCount;
    }

    public get serviceTotalUnreadCount(): number {
        return window.ServiceTotalUnreadCount;
    }

    public get isShowOshiraseShindan(): boolean {
        return new Date() < new Date('2023/11/13') && sessionStorage.getItem('oshiraseShindanClicked') !== '1';
    }

    public get isShowRebillingNotice(): boolean {
        return this.isExistPaymentFailure;
    }

    public get isShowCreditCardUpdate(): boolean {
        return window.IsCreditCardUpdateRequired;
    }

    public guideWord: string;
    public readonly rGuideFormPattern: string;

    public domainList: DomainItem[];
    public isShowWithdrawalPreventionModal: boolean;

    public get isShowDeliverySettingLink(): boolean {
        return window.IsDomainNewsTarget;
    }

    constructor(
        router: Router,
        serviceContainer: ServiceContainer,
        http: HttpClient
    ) {
        this.router = router;
        this.serviceContainer = serviceContainer;
        this.http = http;
        this.queueId = this.serviceContainer.HubsService.multipleSendManager.GetComponentId();

        this.isProduct = (window.productFlg === "Product" || window.productFlg === "Staging");

        this.frequentlyViewedPages = new Array<FrequentlyViewedPage>();

        this.viewHiddenInvoice = true;

        this.guideWord = "";
        this.rGuideFormPattern = ValidationRegexsDefine.rGuideFormPattern;

        this.isShowWithdrawalPreventionModal = false;

        this.showForYouTab = false;
        this.isShowCojpDefine = false;
        this.isShowSSL = false;
        this.sslPrepaymentExpirationDate = "";
        this.isShowDomainRenew = false;
        this.isShowRestoreDomain = false;
        this.isShowUnpaid = false;
        this.isShowWithDefaultNameServer = false;
        this.isShowHeaderBalloon = true;
    }

    //ログアウトのイベント
    public clickLogoutConfirm(): void {
        this.isLoading = true;
        this.hiddentAccountMenu();

        // ログアウトする際は、ドメイン更新レコメンドモーダルを表示
        this.serviceContainer.ModalForDomainRenewService.isSkipDomainRenewPageModal = true;
        this._getRenewDomains();
        sessionStorage.setItem("isFirstShowModalMail", "y");
        sessionStorage.removeItem('firstViewAfterLogin');
        sessionStorage.removeItem('oshiraseShindanClicked');
        sessionStorage.removeItem('receivedFirstDayRegistrationRs');
        return;
    }

    private _logout(): void {
        var headerSearchWords = sessionStorage.getItem('headerSearchWordsKey');
        if (headerSearchWords) {
            dataLayer.push({
                'event': 'event_sendHeaderSearchWords',
                'headerSearchWords': headerSearchWords
            });
            sessionStorage.removeItem('headerSearchWordsKey');
        }

        this.serviceContainer.HubsService.multipleSendManager.Send(
            this.queueId,
            this.serviceContainer.HubsService.hubsManager.authHubManager.Logout,
            null,
            (responseObject: LogoutResponseHubDto) => {
                this.isLoading = false;
                this.router.navigateByUrl('/logout');
                return;
            },
            (responseObject) => {
                this.isLoading = false;
                this.router.navigateByUrl('/logout');
                return;
            },
            (responseObject) => {
                this.isLoading = false;
                this.router.navigateByUrl('/logout');
                return;
            },
            null,
            (responseObject) => {
                this.isLoading = false;
                this.router.navigateByUrl('/error/server');
                return;
            },
        )
    }

    public onSearchGuideButtonClicked(guideForm: NgForm): void {
        if (!this.guideWord) {
            return;
        }

        if (guideForm.invalid) {
            for (var key in guideForm.controls) {
                guideForm.controls[key].markAsDirty();
            }
            return;
        }

        try {
            let url = this.isProduct ? "https://www.onamae.com/guide/search/?keyword=" : "https://www.onamae.test/guide/search/?keyword=";
            let encodeUrl = encodeURI(url + this.guideWord + "&from=onamaenavi");
            window.open(encodeUrl, "_blank", 'noopener,noreferrer');
        } catch (error) {
            let notify: NotifyMessage = new NotifyMessage(this.serviceContainer.NotifyService.GetNotifyId(), NotifyType.Error, '不正な検索文字です');
            this.serviceContainer.NotifyService.AddNotifyMessages([notify]);
        }
    }

    private _getRenewDomains(): void {
        var reqHubDto: DomainRenewListRequestHubDto = {
            CurrentPage: 1,
            PageSize: 100,
            SearchedType: 'STANDARD',
            SearchCondition: {
                DomainIds: '',
                DomainNames: '',
                GroupName: '',
                CreatedDateFrom: '',
                CreatedDateTo: '',
                ExpirationDateFrom: this._expirationDateFrom(),
                ExpirationDateTo: this._expirationDateTo(),
                WhoisProxyStatus: 'BOTH',
                CheckedCoupon: false
            } as DomainRenewListSearchCondition,
            SortList: [{
                Key: 'expirationDate',
                Type: 1
            } as DomainRenewListSortRequestHubDto]
        } as DomainRenewListRequestHubDto;

        this.serviceContainer.HubsService.multipleSendManager.Send(
            this.queueId,
            this.serviceContainer.HubsService.hubsManager.domainRenewHubManager.GetDomainRenewList,
            reqHubDto,
            (res: DomainRenewListResponseHubDto) => {
                let showModal = res.DomainRenewList.length > 0;
                if (showModal) {
                    this.domainList = res.DomainRenewList.map(x => new DomainItem(x.DomainNameML, x.DomainId, x.ExpirationDate.split(" ")[0]));
                    this.isShowWithdrawalPreventionModal = true;
                } else {
                    this._logout();
                }
            },
            (res: DomainRenewListResponseHubDto) => {
                this._logout();
            },
            (res: DomainRenewListResponseHubDto) => {
                this._logout();
            },
            () => {
                this.isLoading = false;
            },
            (res: DomainRenewListResponseHubDto) => {
                this._logout();
            }
        );
    }

    /**
     * 日付の取得（当日）
    **/
    private _expirationDateFrom() {
        const todayYmd = new Date();
        const x = todayYmd.getFullYear()
            + '/' + ('0' + (todayYmd.getMonth() + 1)).slice(-2)
            + '/' + ('0' + todayYmd.getDate()).slice(-2)
        return (x)
    }

    /**
     * 日付の取得（30日後）
    **/
    private _expirationDateTo() {
        const todayYmd = new Date(this._expirationDateFrom());
        todayYmd.setDate(todayYmd.getDate() + 30);
        const y = todayYmd.getFullYear()
            + '/' + ('0' + (todayYmd.getMonth() + 1)).slice(-2)
            + '/' + ('0' + todayYmd.getDate()).slice(-2)
        return (y)
    }

    public onClickConfirmButton(): void {
        this._logout();
    }

    public onClickCloseHeaderBalloon(): void {
        this.isShowHeaderBalloon = false;
    }

    public onClickReturnButton(): void {
        this.isShowWithdrawalPreventionModal = false;
        this.serviceContainer.ModalForDomainRenewService.isSkipDomainRenewPageModal = false;

        if (location.pathname == "/domain/setting/renew/list") {
            let event: CustomEvent;
            try {
                event = new CustomEvent('onclick_withdrawal_prevention_modal_return_button');
            } catch (ex) {
                event = window.document.createEvent('CustomEvent');
                event.initCustomEvent('onclick_withdrawal_prevention_modal_return_button', false, true, void (0));
            }
            const dom = window.document.getElementsByTagName('body')[0];
            try {
                dom.dispatchEvent(event);
            } catch (ex) {
                console.log('event dispatch error');
                return;
            }
        } else {
            this.router.navigate(['/domain/setting/renew/list']);
        }

        return;
    }

    public onClickCloseButtonAtWithdrawalPreventionModal(): void {
        this.isShowWithdrawalPreventionModal = false;
        this.serviceContainer.ModalForDomainRenewService.isSkipDomainRenewPageModal = false;
    }

    /**
    * メッセージ一覧のモーダルを開く
    */
    public onClickButtonAtMenuListModal(): void {
        this.isShowMenuListModal = true;
        window.dataLayerPush("header_lookingpg");
    }

    /**
    * メッセージ一覧のモーダルを閉じる
    */
    public onClickCloseButtonAtMenuListModal(): void {
        this.isShowMenuListModal = false;
    }

    /**
    * モーダル外をクリック
    */
    public onOutsideModalClicked(event: any): void {
        if (event.target.className == "modal") {
            this.isShowMenuListModal = false;
        }

        if (event.target.className == "gtmclick") {
            this.isShowMenuListModal = false;
        }
    }

    /**
     * サジェスト検索処理
     */
    public searchSuggest(word: string): void {
        this.isNoSuggestResult = false;
        this.isNoHelpSuggestResult = false;
        this.isShowAllSuggestBtn = false;
        var searchWord = word.trim();

        if (!searchWord) {
            this.showSuggests = [];
            this.showHelpPageSuggests = [];
        } else {
            if (this.suggestData.length > 0) {
                this.getShowSuggestData(searchWord);
            } else {
                this.http.get('Content/assets/suggest_list.json').subscribe((data: ISuggestListModel) => {
                    if (this.roleId !== '5' && this.roleId !== '6') {
                        data.suggestList = data.suggestList.filter(x => x.name != 'ドメイン廃止' && x.name != 'ドメイン廃止キャンセル' && x.name != 'APIドキュメントダウンロード');
                    }
                    this.suggestData = data.suggestList;
                    this.getShowSuggestData(searchWord);
                });
            }

            // ヘルプ記事検索APIへの負荷が想定より高かった場合に切り替えられるようABで実装
            if (this.isSearchHelpArticles) {
                this.getShowHelpPageSuggestData(searchWord);
            } else {
                this.setSearchWordAndResult(searchWord.concat(',').concat((!this.isNoSuggestResult).toString()).concat(','));
            }
        }

        var searchElement = document.getElementById('search');
        var isShowClass = searchElement.classList.contains('is-Show');
        if (!isShowClass) {
            // サジェスト一覧が表示されていなければ表示する
            document.getElementById('searchBtn').click();
        }
    }

    /**
     * 検索ワードを元にサジェスト一覧を取得
     */
    public getShowSuggestData(searchWord: string): void {
        var suggestResults = this.suggestData.filter(suggest => suggest.name.toLowerCase().indexOf(searchWord.toLowerCase()) >= 0);
        if (suggestResults.length == 0) {
            this.isNoSuggestResult = true;
            this.showSuggests = [];
        } else if (suggestResults.length > 5) {
            this.isShowAllSuggestBtn = true;
            this.showSuggests = suggestResults.slice(0, 5);
            this.suggestAllResults = suggestResults;
        } else {
            this.showSuggests = suggestResults;
        }
    }

    /**
     * 検索ワードを元にお名前ヘルプ記事検索
     * @param searchWord
     */
    public getShowHelpPageSuggestData(searchWord: string): void {
        var reqHubDto: SearchHelpArticlesRequestHubDto = {
            SearchWord: searchWord
        };

        this.serviceContainer.HubsService.multipleSendManager.Send(
            this.queueId,
            this.serviceContainer.HubsService.hubsManager.topHubManager.SearchOnamaeHelpArticles,
            reqHubDto,
            (res: SearchHelpArticlesResponseHubDto) => {
                this.showHelpPageSuggests = [];
                for (var article of res.HelpArticles) {
                    this.showHelpPageSuggests.push({
                        name: article.Title,
                        url: article.Url,
                        redirectionNaviClass: '',
                        HelpArticleId: article.ArticleId
                    });
                }

                if (res.HelpArticles.length == 0) {
                    this.isNoHelpSuggestResult = true;
                }
            },
            (res: SearchHelpArticlesResponseHubDto) => {
                this.showHelpPageSuggests = [];
            },
            (res: SearchHelpArticlesResponseHubDto) => {
                this.showHelpPageSuggests = [];
            },
            () => {
                let isNoSearchResults = this.isNoSuggestResult && this.isNoHelpSuggestResult;
                this.setSearchWordAndResult(searchWord.concat(',').concat((!isNoSearchResults).toString()).concat(','));
            },
            (res: SearchHelpArticlesResponseHubDto) => {
                this.showHelpPageSuggests = [];
            }
        );
    }

    /**
     * 検索ワード、結果を保持する
     * @param setData
     */
    private setSearchWordAndResult(setData: string) {
        var headerSearchWords = sessionStorage.getItem('headerSearchWordsKey');
        if (headerSearchWords) {
            setData = '|'.concat(setData);
            sessionStorage.setItem('headerSearchWordsKey', headerSearchWords.concat(setData));
        } else {
            sessionStorage.setItem('headerSearchWordsKey', setData);
        }
    }

    /**
     * サジェスト選択時の遷移処理
     */
    public onClickSuggestLinkTransitionPage(suggest: ISuggestItemModel, helpSupportFlg: boolean): void {
        let category: string = '';
        let item: string = '';
        let url: string = location.href;

        this.searchInputWord = "";
        this.showSuggests = [];
        this.showHelpPageSuggests = [];

        if (helpSupportFlg) {
            category = 'HelpSupport';
            item = suggest.HelpArticleId;
            window.open(suggest.url, "_blank", 'noopener,noreferrer');
        } else {
            category = 'Navi';
            item = suggest.name;
            if (suggest.url) {
                this.router.navigate([suggest.url]);
            } else if (suggest.redirectionNaviClass) {
                this.doRedirection(suggest.redirectionNaviClass);
            }
        }
        document.getElementById('search').classList.remove('is-Show');
        sessionStorage.setItem('headerSearchWordsKey', sessionStorage.getItem('headerSearchWordsKey').concat(item + ',' + category + ',' + url));
    }

    /**
     * サジェスト結果を全て表示
     */
    public onClickShowAllSuggest(): void {
        this.showHelpPageSuggests = [];
        this.isShowAllSuggestBtn = false;
        this.showSuggests = this.suggestAllResults;
    }

    /**
     * 良く検索されるワード押下時
     * @param word
     */
    public onClickFrequentlySearchWord(word: string): void {
        this.searchInputWord = word;
        this.searchSuggest(word);
    }

    /**
     * 旧ナビへリダイレクト
    */
    private doRedirection(navClass: string, queryParams: NavigationQueryHubDto[] = []): void {

        var request: NavigationRequestHubDto = {
            Host: "DOMAIN_NAVI",
            NavigationClass: navClass,
            NavigationParams: queryParams
        };

        this.serviceContainer.HubsService.multipleSendManager.Send(
            this.queueId,
            this.serviceContainer.HubsService.hubsManager.navigationHubManager.TokenUrl,
            request,
            (response: NavigationResponseHubDto) => {
                window.open(response.TokenUrl, '_self');
            },
            (response: NavigationResponseHubDto) => {
            },
            (response: NavigationResponseHubDto) => {
                this.isLoading = false;
                this.router.navigateByUrl('/top');
                return false;
            },
            null,
            (response: NavigationResponseHubDto) => {
                this.isLoading = false;
                this.router.navigateByUrl('/error/server');
                return false;
            },
        );
    }

    /**
     * お知らせのタブ切り替え
     */
    public onClickMessageTab(): void {
        this.showForYouTab = !this.showForYouTab;
    }

    /**
     * ドロップダウンメニュー（お知らせ）のボタン押下
     * @param domainId
     */
    public onClickInfoBtn(link: string) {
        this.router.navigateByUrl(link);
        let _el: HTMLElement = document.getElementById("headerInfoDropDownB");
        if (_el.classList.contains("is-Open")) {
            _el.click();
        }
    }

    public onClickHiddenOshiraseShindan() {
        sessionStorage.setItem('oshiraseShindanClicked', '1');
        let _el: HTMLElement = document.getElementById("headerInfoDropDownB");
        if (_el.classList.contains("is-Open")) {
            _el.click();
        }
        CustomizedEvents.dispatchOshiraseShindan();
        this.router.navigateByUrl('/domain');
    }

    /**
     * お知らせ(ドメイン更新期限間近、復旧可能廃止済みドメイン、cojp仮登録通知、SSL証明書発行)のドメイン情報取得
     */
    public getDomainInfoForNotification(): Promise<void> {
        const promise = new Promise<void>((resolva, reject) => {
            this.serviceContainer.HubsService.multipleSendManager.Send(
                this.queueId,
                this.serviceContainer.HubsService.hubsManager.topHubManager.GetDomainInfoForTopPageAsync,
                null,
                (resHubDto: TopPageDomainInfoResponseHubDto) => {
                    this.isShowDomainRenew = false;
                    const domainList: DomainNearingExpirationHubDto[] = resHubDto.DomainList || [];
                    if (0 < domainList.length) {
                        //ドメイン更新期限通知欄に表示するドメインを取得
                        this.renewDomainList = domainList.map(x => {
                            return new NearExpirationDomain(
                                x.RenewalDeadLine,
                                x.DomainNameML,
                                x.DomainName,
                                x.DomainId,
                                x.Tld,
                            )
                        });

                        this.isShowDomainRenew = true;
                    }

                    this.isShowRestoreDomain = false;
                    const restoreDomainList: RestoreDomainHubDto[] = resHubDto.RestoreDomainList || [];
                    if (0 < restoreDomainList.length) {
                        this.isShowRestoreDomain = true;
                    }

                    this.isShowCojpDefine = resHubDto.IsPreRegistDomainExist;

                    this.isShowSSL = false;
                    if (resHubDto.SslPrepaymentExpirationDate !== null) {
                        this.sslPrepaymentExpirationDate = resHubDto.SslPrepaymentExpirationDate;
                        this.isShowSSL = true;
                    }

                    resolva();
                },
                (resHubDto: TopPageDomainInfoResponseHubDto) => {
                    this.isShowDomainRenew = false;
                    this.isShowRestoreDomain = false;
                    this.isShowCojpDefine = false;
                    this.isShowSSL = false;
                    reject(Service.ErrorType.Error);
                },
                (resHubDto: TopPageDomainInfoResponseHubDto) => {
                    this.isShowDomainRenew = false;
                    this.isShowRestoreDomain = false;
                    this.isShowCojpDefine = false;
                    this.isShowSSL = false;
                    reject(Service.ErrorType.NoSession);
                },
                null,
                (resHubDto: TopPageDomainInfoResponseHubDto) => {
                    this.isShowDomainRenew = false;
                    this.isShowRestoreDomain = false;
                    this.isShowCojpDefine = false;
                    this.isShowSSL = false;
                    reject(Service.ErrorType.UnExcepted);
                }
            );
        });

        return promise;
    }

    /**
     * SSL証明書の発行
     */
    public onClickSslNew(): void {
        var request: NavigationRequestHubDto = {
            Host: "DOMAIN_NAVI",
            NavigationClass: "DOMAIN_SSL_NEW",
            NavigationParams: []
        };

        this.serviceContainer.HubsService.multipleSendManager.Send(
            this.queueId,
            this.serviceContainer.HubsService.hubsManager.navigationHubManager.TokenUrl,
            request,
            (response: NavigationResponseHubDto) => {
                window.dataLayerPush('info_foryou_ssl');
                window.open(response.TokenUrl, '_self');
            },
            (response: NavigationResponseHubDto) => {
            },
            (response: NavigationResponseHubDto) => {
                this.router.navigateByUrl('/top');
                return false;
            },
            null,
            (response: NavigationResponseHubDto) => {
                this.router.navigateByUrl('/error/server');
                return false;
            },
        );
    }

    /**
     * 未入金チェック
     */
    public getInvoiceUnpaid(): Promise<void> {
        const requestData: InvoiceHistoryIssueListRequestHubDto = {
            domain: {
                DomainName: '',
                LocalInvoiceId: '',
                InvoiceStartDate: '',
                InvoiceEndDate: '',
                LineCnt: 1,
                PageNo: 1,
                SortList: [{ Key: 'invoiceDate', Type: 2 }] as InvoiceDomainHistoryIssueListSortRequestHubDto[]
            },
            hosting: {
                LocalInvoiceId: '',
                StartDate: '',
                EndDate: '',
                LineCnt: 1,
                PageNo: 1,
                SortList: [{ Key: 'invoiceDate', Type: 2 }] as InvoiceServerHistoryIssueListSortRequestHubDto[]
            }
        }

        const promise = new Promise<void>((resolva, reject) => {
            this.serviceContainer.HubsService.multipleSendManager.Send(
                this.queueId,
                this.serviceContainer.HubsService.hubsManager.invoiceHubManager.CheckInvoiceUnpaid,
                requestData,
                (data: UnpaidInvoiceResponseHubDto) => {
                    this.isShowUnpaid = data.DomainResult || data.HostingResult;

                    if (this.isShowUnpaid) {
                        //どちらの請求一覧（ドメインorホスティング）を表示するか判定
                        this.unpaidTransitionPath = '/payment/invoice/list/' + (data.DomainResult ? 'domain' : 'hosting');
                    }
                    resolva();
                },
                (data: UnpaidInvoiceResponseHubDto) => {
                    this.isShowUnpaid = false;
                    reject(Service.ErrorType.Error);
                },
                (data: UnpaidInvoiceResponseHubDto) => {
                    this.isShowUnpaid = false;
                    reject(Service.ErrorType.NoSession);
                },
                null,
                (data: UnpaidInvoiceResponseHubDto) => {
                    this.isShowUnpaid = false;
                    reject(Service.ErrorType.UnExcepted);
                }
            );
        });

        return promise;
    }

    /**
     * ご入金待ち(手続き開始)：102092のアイテムを取得
     */
    public getItemPaymentFailureList(): Promise<void> {
        const promise = new Promise<void>((resolva, reject) => {

            this.serviceContainer.HubsService.multipleSendManager.Send(
                this.queueId,
                this.serviceContainer.HubsService.hubsManager.itemHubManager.CheckExistItemPaymentFailure,
                null,
                (resHubDto: CheckExistItemPaymentFailureResponseHubDto) => {
                    this.isExistPaymentFailure = resHubDto.hasItem;
                    resolva();
                },
                (resHubDto: CheckExistItemPaymentFailureResponseHubDto) => {
                    this.isExistPaymentFailure = false;
                    reject(Service.ErrorType.Error);
                },
                (resHubDto: CheckExistItemPaymentFailureResponseHubDto) => {
                    this.isExistPaymentFailure = false;
                    reject(Service.ErrorType.NoSession);
                },
                null,
                (resHubDto: CheckExistItemPaymentFailureResponseHubDto) => {
                    this.isExistPaymentFailure = false;
                    reject(Service.ErrorType.UnExcepted);
                }
            );
        });

        return promise;
    }

    /**
     * 未利用ドメイン取得
     */
    public getDomainWithDefaultNameServer(): Promise<void> {
        const promise = new Promise<void>((resolva, reject) => {
            this.serviceContainer.DomainWithDefaultNameServerService.GetDomainWithDefaultNameServer().then((resHubDto) => {
                this.isShowWithDefaultNameServer = false;
                if (resHubDto.DomainIds?.length > 0) {
                    this.isShowWithDefaultNameServer = true;
                }
                resolva();
            }).catch((error) => {
                this.isShowWithDefaultNameServer = false;
                reject(error);
            });
        });

        return promise;
    }

    /**
     * クレジットカード情報更新が必要かチェック
     */ 
    public getCheckCreditCard(): Promise<void> {
        const promise = new Promise<void>((resolva, reject) => {
            this.serviceContainer.HubsService.multipleSendManager.Send(
                this.queueId,
                this.serviceContainer.HubsService.hubsManager.invoiceHubManager.CheckCreditCardInfoUpdateRequiredAsync,
                null,
                (resHubDto: CheckCreditCardInfoUpdateRequiredResponseHubDto) => {
                    window.IsCreditCardUpdateRequired = resHubDto.IsCreditCardUpdateRequired;
                    resolva();
                },
                (resHubDto: CheckCreditCardInfoUpdateRequiredResponseHubDto) => {
                    window.IsCreditCardUpdateRequired = false;
                    reject(Service.ErrorType.Error);
                },
                (resHubDto: CheckCreditCardInfoUpdateRequiredResponseHubDto) => {
                    window.IsCreditCardUpdateRequired = false;
                    reject(Service.ErrorType.NoSession);
                },
                null,
                (resHubDto: CheckCreditCardInfoUpdateRequiredResponseHubDto) => {
                    window.IsCreditCardUpdateRequired = false;
                    reject(Service.ErrorType.UnExcepted);
                }
            );
        });

        return promise;
    }

    /**
     * あなた宛お知らせを表示する（初回のみ）
     */
    public openInfoDropDown(): void {
        this.showForYouTab = true;
        let _el: HTMLElement = document.getElementById('headerInfoDropDownB');

        if (_el === null) {
            return;
        }

        if (!_el.classList.contains('is-Open') && sessionStorage.getItem('IsFirstModalOpen') != '1' && sessionStorage.getItem('IsFirstHeaderInfo') != '0') {
            _el.click();
        } else if (!_el.classList.contains('is-Open') && sessionStorage.getItem('IsFirstModalOpen') === '1' && sessionStorage.getItem('IsFirstHeaderInfo') != '0') {
            if (document.body.classList.contains('is-ModalOpen')) {
                sessionStorage.setItem('IsFirstHeaderInfo', '0');
            }
        }
    }

    public directLinkWithError(localpath: string): string {
        if (localpath.startsWith('/domain/selldomain/create')) {
            return '/auth/selldomain';
        } else {
           
            return '/login'
        }

    }

    public onClickAccountManagement(): void {
        this.hiddentAccountMenu();
        this.router.navigateByUrl('/account/management');
    }

    public hiddentAccountMenu() {
        var $menu = $('.is-HeaderFunctionAdmin');
        var $target = $('.header-Function-Target');

        if ($target.length > 0) {
            $target.each(function () {
                if ($(this).hasClass('is-Open')) {
                    $(this).removeClass('is-Open');
                }
            });
        }
        if ($menu.length > 0) {
            $menu.each(function () {
                if ($(this).hasClass('is-Show')) {
                    $(this).removeClass('is-Show');
                }
            });
        }
    }
}

/**
 * サジェストリスト
 */
interface ISuggestListModel {
    suggestList: ISuggestItemModel[];
}

/**
 * サジェスト項目
 */
interface ISuggestItemModel {
    name: string;
    url: string;
    redirectionNaviClass: string;
    HelpArticleId: string;
}

class NearExpirationDomain {
    public renewalDeadLineDays: number;
    public renewalDeadLineHours: number;
    public expirationTime: string;

    public constructor(
        public readonly renewalDeadLineDate: string,
        public readonly domainNameML: string,
        public readonly domainName: string,
        public readonly domainId: string,
        public readonly tld: string,
    ) {
        this._setDiffFromDeadLine(this.renewalDeadLineDate, this.tld);
    }

    protected _setDiffFromDeadLine(renewalDeadLine: string, tld: string) {
        const dateArr: Array<string> = renewalDeadLine.split('/');
        let hour = 0;
        let isOrganizationalTypeJp = DomainUtil.DetectionOrganizationalTypeJp(tld);
        let isGeneralUseJp = DomainUtil.DetectionGeneralUseJp(tld);

        if (isOrganizationalTypeJp) {
            this.expirationTime = " 19:00";
            hour = -5;
        }
        if (isGeneralUseJp) {
            this.expirationTime = " 22:00";
            hour = -2;
        }
        var deadline = new Date(Number(dateArr[0]), Number(dateArr[1]) - 1, Number(dateArr[2]) + 1, hour, 0, 0);

        const current = new Date();
        let diff = deadline.getTime() - current.getTime();

        this.renewalDeadLineDays = Math.floor(diff / 86400000);
        diff = diff % 86400000;
        this.renewalDeadLineHours = Math.floor(diff / 3600000);
    }
}
