import { createReducer, createFeatureSelector, createSelector, on } from '@ngrx/store';
import { AccountInfoState, AccountDetailState, AccountHostingContractQuantityState } from '../states/account-info';
import { setAccountInfo, resetAccountInfo, setAccountDetail, resetAccountDetail, setAccountHostingContractQuantity, resetAccountHostingContractQuantity } from '../actions/account-info';

export const accountInfoFeatureKey = 'accountInfo';
export const accountDetailFeatureKey = 'accountDetail';
export const accountHostingContractQuantityFeatureKey = 'accountHostingContractQuantity';

export const initialAccountInfoState: AccountInfoState = {
    LoginName: '',
    Email: '',
    IsTmpRegist: false,
    IsChangePassword: false,
    ViewHiddenInvoice: true,
    ElectricFlg: '',
    ProxyId: '',
    HasDomain: false,
    IsDomainNewsTarget: false,
    IsDomainNewsFlg: false,
    RoleId: '',
    IsSurchargeTarget: true,
    Cc: '',
    ShowSitemapCreate: false,
    ShowSitemapPremiumCreate: false,
    ShowSitemapGtldAuction: false,
    ShowSitemapJpAuction: false,
    ShowSitemapBulkApplicationList: false,
    ShowSitemapGtldBackorder: false,
    ShowSitemapJpBackorder: false,
    ShowSitemapMonitoring: false,
    ShowSitemapJpCreateCorrection: false,
    ShowSitemapCojpRegistration: false,
    ShowSitemapTransferIn: false,
    ShowDomainTransferStatusList: false,
    ShowSitemapTransferLock: false,
    ShowSitemapNsChange: false,
    ShowSitemapDnsSetting: false,
    ShowSitemapDsRecord: false,
    ShowSitemapWhoisInfoChange: false,
    ShowSitemapWhoisProxy: false,
    ShowSitemapWhoisMailForward: false,
    ShowSitemapJpInfoChange: false,
    ShowSitemapTmch: false,
    ShowSitemapIdReplace: false,
    ShowSitemapDomainProtectInput: false,
    ShowSitemapDomainProtectUpdateInput: false,
    ShowSitemapDomainEns: false,
    ShowSitemapMailForward: false,
    ShowSitemapUrlForward: false,
    ShowSitemapSetting_Ssl: false,
    ShowSitemapSslManage: false,
    ShowSitemapPaymentInfoDomain: false,
    ShowSitemapEvidence: false,
    ShowSitemapDelService: false,
    ShowSitemapDelServiceReport: false,
    ShopId: '',
    HostingShopId: '',
    IsError: false,
    ErrorMessage: '',
    IsUnexpectedError: false,
    NoSession: false,
    MaintenanceMode: false,
    SubMaintenanceMode: false
};

const initialAccountDetailState: AccountDetailState = {
    LoginName: '',
    Role: '',
    LnameML: '',
    Lname: '',
    FnameML: '',
    Fname: '',
    OrganizationML: '',
    Organization: '',
    DivisionML: '',
    Division: '',
    Cc: '',
    Pc: '',
    PcJp1: '',
    PcJp2: '',
    SpML: '',
    Sp: '',
    SpJp: '',
    CityML: '',
    City: '',
    Street1TownML: '',
    Street1Town: '',
    Street1NumberML: '',
    Street1Number: '',
    Street2ML: '',
    Street2: '',
    Phone: '',
    Fax: '',
    Cellular: '',
    Email: '',
    CellularEmail: '',
    ShowDomainNewsFlg: false,
    DomainNewsFlg: '',
    ShowSendRenewMailFlg: false,
    DisableSendRenewMailFlg: false,
    SendRenewMailFlg: '',
    ShowSmsExcludeFlg: false,
    SmsExcludeFlg: '',
    ShowPostalMatterFlg: false,
    DisablePostalMatterFlg: false,
    PostalMatterFlg: '',
    LanguageCode: '',
    Question: '',
    Answer: '',
    IsTmpRegist: false,
    SendParkingMail: false,
    IsChangePassword: false,
    LoginAlertMailFlg: false,
    MfaMethod: '',
    IsError: false,
    ErrorMessage: '',
    IsUnexpectedError: false,
    NoSession: false,
    MaintenanceMode: false,
    VoaciMethod: '',
    UpdatedDate: '',
    CreatedDate: '',
    AutoNetDeShindanFlg: true,
    SubMaintenanceMode: false
};

const initialAccountHostingContractQuantityState: AccountHostingContractQuantityState = {
    isLoaded: false,
    ContractQuantityList: [],
    IsError: false,
    ErrorMessage: '',
    IsUnexpectedError: false,
    NoSession: false,
    MaintenanceMode: false,
    SubMaintenanceMode: false
};

export const reducerAccountInfo = createReducer(
    initialAccountInfoState,
    on(setAccountInfo, (state: AccountInfoState, newState: AccountInfoState) => ({ ...initialAccountDetailState, ...newState })),
    on(resetAccountInfo, (state: AccountInfoState) => (initialAccountInfoState))
);

export const reducerAccountDetail = createReducer(
    initialAccountDetailState,
    on(setAccountDetail, (state: AccountDetailState, newState: AccountDetailState) => ({ ...initialAccountDetailState, ...newState })),
    on(resetAccountDetail, (state: AccountDetailState) => (initialAccountDetailState))
);

export const reducerAccountHostingContractQuantity = createReducer(
    initialAccountHostingContractQuantityState,
    on(setAccountHostingContractQuantity, (state: AccountHostingContractQuantityState, newState: AccountHostingContractQuantityState) => ({ ...initialAccountHostingContractQuantityState, ...newState })),
    on(resetAccountHostingContractQuantity, (state: AccountHostingContractQuantityState) => (initialAccountHostingContractQuantityState))
);


const getAccountInfoState = createFeatureSelector<AccountInfoState>(accountInfoFeatureKey);
const getAccountDetailState = createFeatureSelector<AccountDetailState>(accountDetailFeatureKey);
const getAccountHostingContractQuantityState = createFeatureSelector<AccountHostingContractQuantityState>(accountHostingContractQuantityFeatureKey);

export const getAccountInfo = createSelector(
    getAccountInfoState,
    state => state
);

export const getAccountDetail = createSelector(
    getAccountDetailState,
    state => state
);

export const getAccountHostingContractQuantity = createSelector(
    getAccountHostingContractQuantityState,
    state => state
);


