import { Directive, ElementRef, Output, Input, EventEmitter, HostListener, OnDestroy } from '@angular/core';
import { Alert } from 'selenium-webdriver';

@Directive({
    selector: '[dropdown]'
})
export class DropDownDirective implements OnDestroy {
    private _el: HTMLElement;

    private targetElement: HTMLElement;

    private ignoreElements: HTMLElement[] = [];

    private closeElements: HTMLElement[] = [];

    private bodyEventLitner: EventListener;

    private hideElement: HTMLElement;

    @Input('align')
    public align: string;

    @Input('target')
    public target: string;

    @Input('close')
    public close: string;

    @Input('hide')
    public hide: string;

    @Output()
    public clickEvent = new EventEmitter();

    constructor(private _elementRef: ElementRef) {
        this._el = _elementRef.nativeElement;

        this.bodyEventLitner = (event: MouseEvent) => {
            if (this.targetElement) {
                if (this.hideElement) {
                    if (this.hideElement.classList.contains('is-Hide')) {
                        this.hideElement.classList.remove('is-Hide');
                    }
                }
                this.targetElement.classList.remove('is-Show');
                this._el.classList.remove("is-Open");

                this.targetElement.style.left = '';
                this.targetElement.style.top = '';
            }
        };

        document.body.addEventListener('click', this.bodyEventLitner);
    }

    public ngOnDestroy() {
        var body = document.querySelector('body') as HTMLBodyElement;
        if (body && this.bodyEventLitner) {
            body.removeEventListener("click", this.bodyEventLitner);
        }
    }

    @HostListener('click', ['$event'])
    public onClick(event: Event) {
        if (!this.targetElement) {
            this.targetElement = document.querySelector(this.target) as HTMLElement;
            this.hideElement = document.querySelector(this.hide) as HTMLElement;
            this.targetElement.addEventListener("click", (event: MouseEvent) => {
                event.stopImmediatePropagation();
                event.stopPropagation();
            });

            // ドロップダウン上のカレンダー(Pikaday)をクリックした際
            // ドロップダウン自体が非表示になってしまうため、カレンダー(Pikaday)に対してはバブリング停止のイベントを貼る
            var ignoreElements = document.querySelectorAll('div.pika-single.is-bound');
            for (var index = 0; index < ignoreElements.length; index++) {
                var ignoreElement = ignoreElements.item(index) as HTMLElement;
                ignoreElement.addEventListener("click", (event: MouseEvent) => {
                    event.stopImmediatePropagation();
                    event.stopPropagation();
                });
                this.ignoreElements.push(ignoreElement);
            }

            if (this.closeElements.length == 0 && this.close && this.close.length > 0) {
                var closeElements = this.targetElement.querySelectorAll(this.close);
                for (var index: number = 0; index < closeElements.length; index++) {
                    var element = closeElements[index] as HTMLElement
                    element.addEventListener("click", (event: MouseEvent) => {
                        if (this.hideElement) {
                            if (this.hideElement.classList.contains('is-Hide')) {
                                this.hideElement.classList.remove('is-Hide');
                            }
                        }
                        this._el.classList.remove('is-Open')
                        this.targetElement.classList.remove('is-Show');

                        this.targetElement.style.left = '';
                        this.targetElement.style.top = '';
                    });

                    this.closeElements.push(element);
                }
            }
        }

        // 自分のセレクタには無干渉
        var isShowSelector = document.querySelectorAll('.dropdown.is-Show');
        for (var index = 0; index < isShowSelector.length; index++) {
            var element = isShowSelector.item(index) as HTMLElement;
            if (element != this.targetElement) {
                isShowSelector.item(index).classList.remove('is-Show');
                this._el.classList.remove('is-Open');
            }
        }

        var isOpenSelector = document.querySelectorAll('.is-Open');
        for (var index = 0; index < isOpenSelector.length; index++) {
            var element = isOpenSelector.item(index) as HTMLElement;
            if (element != this._el && element.hasAttribute('dropdown')) {
                isOpenSelector.item(index).classList.remove('is-Open');
            }
        }

        var thisRect = this._el.getBoundingClientRect();
        var targetRect = this.targetElement.getBoundingClientRect();
        var top = 0;
        if (this._el.classList.contains("header-Function-Button") || this._el.classList.contains("header-Function-TextButton")) {
            var offsetTop = this._el.parentElement.offsetTop;
            top = offsetTop + this._el.offsetHeight;
        }
        else {
            top = this._el.offsetTop + this._el.offsetHeight;
        }        this.targetElement.style.top = top + 'px';

        if (this.targetElement.classList.contains('is-Show')) {
            if (this.hideElement) {
                if (this.hideElement.classList.contains('is-Hide')) {
                    this.hideElement.classList.remove('is-Hide');
                }
            }
            this.targetElement.classList.remove('is-Show');
            this._el.classList.remove('is-Open');

            this.targetElement.style.left = '';
            this.targetElement.style.top = '';
        }
        else {
            this.targetElement.classList.add('is-Show');
            if (this.hideElement) {
                this.hideElement.classList.add('is-Hide');
            }
            this._el.classList.add('is-Open');

            switch (this.align) {
                case 'left':
                    var left = this._el.offsetLeft;
                    this.targetElement.style.left = left.toString() + 'px';
                    break;
                case 'right':
                    //CSSの小数点以下を含めたwidthでleftを計算する                    
                    var targetStyle = document.defaultView.getComputedStyle(this.targetElement, '');
                    var targetWidth = Number(targetStyle.getPropertyValue("width").replace("px", ""));
                    var left = 0;
                    if (this._el.classList.contains("header-Function-Button") || this._el.classList.contains("header-Function-TextButton")) {
                        var offsetLeft = this._el.parentElement.offsetLeft;
                        left = offsetLeft + this._el.offsetWidth - targetWidth;
                    }
                    else {
                        left = this._el.offsetLeft + this._el.offsetWidth - targetWidth;
                    }
                    //IEのwidthが小さい場合があるため微調整
                    const anyStyle = <any>document.body.style;
                    var isIE = anyStyle.msTouchAction !== undefined;
                    if (isIE) {
                        left = left - 2;
                    }
                    this.targetElement.style.left = left.toString() + 'px';
                    break;
                case 'center':
                    var left = this._el.offsetLeft + (this._el.offsetWidth / 2) - (this.targetElement.offsetWidth / 2);
                    this.targetElement.style.left = left.toString() + 'px';
                    break;
            }
        }

        event.stopImmediatePropagation();
        event.stopPropagation();

        this.clickEvent.emit(this._elementRef.nativeElement);

        return false;
    }
}
