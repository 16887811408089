<div class="clearfix">
    <h1 class="hdn-Lev1 is-FloatLeft mb24">ドメイン契約更新</h1>
</div>

<!--お手続きを完了しました （GMOのでんき申請あり／なしで、文言やSSL申請のCSS属性が微妙に違う）-->

<div name="div-promotion-domainuse">
    <p class="text-Important pb0" name="nm-promotion-domainuse">
        <ng-container *ngIf="!viewModel.isGmoElectricNewApply">
            <i class="icon icon-Checked"></i>お手続きが完了しました。
        </ng-container>
        <ng-container *ngIf="viewModel.isGmoElectricNewApply">
            <i class="icon icon-Checked"></i>お手続きを受付いたしました。<br><span class="size-13">続いて以下のボタンより、「GMOのでんき」のお申込みへお進みください。</span>
        </ng-container>
    </p>
</div>

<ng-container *ngIf="viewModel.isGmoElectricNewApply; then notice_gmoelectric_ssl; else notice_ssl"></ng-container>
<ng-template #notice_gmoelectric_ssl>
    <ng-container *ngIf="viewModel.isSslNewApply">
        <div class="box-Single box-Border is-wide mb16">
            <div class="box-Border-Inner">
                <p>SSLサーバー証明書の本申込みは、ドメイン更新完了後にご希望のコモンネームでCSRを生成のうえ、<a href="javascript:void(0)" (click)="viewModel.onClickSslIssuePage()">SSL証明書発行ページ</a>からお手続きを行ってください。</p>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="viewModel.isHostingUnpaid">
        <div class="box-Message is-Attention is-NoShadow mb40">
            <div class="box-Message-Heading">
                <img src="/Content/images/common/icon-caution.svg" class="img-Caution" alt="(!)">
                <p class="box-Message-Heading-Text">サーバーサービスで入金が確認できかねている請求がございます。<a href="javascript:void(0)" (click)="viewModel.onClickHostingInvoicePage()">こちら</a>よりご請求情報の確認をお願いいたします。</p>
            </div>
        </div>
    </ng-container>
</ng-template>
<ng-template #notice_ssl>

    <ng-container *ngIf="viewModel.isSslNewApply">
        <div class="box-Single box-Border is-wide mb16">
            <div class="box-Border-Inner">
                <p>SSLサーバー証明書の本申込みは、ドメイン更新完了後にご希望のコモンネームでCSRを生成のうえ、<a href="javascript:void(0)" (click)="viewModel.onClickSslIssuePage()">SSL証明書発行ページ</a>からお手続きを行ってください。</p>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="viewModel.isHostingUnpaid">
        <div class="box-Message is-Attention is-NoShadow mb40">
            <div class="box-Message-Heading">
                <img src="/Content/images/common/icon-caution.svg" class="img-Caution" alt="(!)">
                <p class="box-Message-Heading-Text">サーバーサービスで入金が確認できかねている請求がございます。<a href="javascript:void(0)" (click)="viewModel.onClickHostingInvoicePage()">こちら</a>よりご請求情報の確認をお願いいたします。</p>
            </div>
        </div>
    </ng-container>
</ng-template>

<!--お申込み内容と合計金額-->
<section class="section">
    <!--お申込み内容-->
    <h2 class="hdn-Lev3 with-border">お申込み内容</h2>

    <!--ドメイン、コンビニ手数料、レンタルサーバー-->
    <table class="tbl tbl-Accordion is-Hoverable mb0">
        <colgroup>
            <col style="width:286px;">
            <col>
            <col style="width:286px;">
        </colgroup>
        <tbody>
            <ng-container *ngIf="viewModel.domains">
                <tr class="is-Open">
                    <td>ドメイン更新</td>
                    <td><i class="icon" [class.icon-ArrowUp]="viewModel.domains.isOpened" [class.icon-ArrowDown]="!viewModel.domains.isOpened" (click)="viewModel.domains.onClickIconArrow()"></i> 詳細はこちらをクリック（{{viewModel.domains.count}}件）</td>
                    <td class="text-Right"><b>合計{{viewModel.domains.totalPriceYen}}円</b></td>
                </tr>
                <tr *ngIf="viewModel.domains.isOpened" class="is-Accordion">
                    <td colspan="3">
                        <div calss="is-FloatRight">
                            <!-- ドメイン -->
                            <ng-container *ngFor="let domain of viewModel.domains.list">
                                <div class="list-DefTable-Complete">
                                    <ng-container *ngFor="let detail of domain.details">
                                        <dl>
                                            <ng-container *ngIf="detail.isTitle; then dt_domain_title; else dt_domain_item"></ng-container>
                                            <ng-template #dt_domain_title><dt><b>{{detail.content}}</b></dt></ng-template>
                                            <ng-template #dt_domain_item><dt [class.text-Danger]="detail.isDiscount">{{detail.content}}</dt></ng-template>
                                            <ng-container *ngIf="detail.isTitle; then dd_domain_title; else dd_domain_item"></ng-container>
                                            <ng-template #dd_domain_title><dd class="is-Primary"><i class="icon icon-Checked"></i>受付完了</dd></ng-template>
                                            <ng-template #dd_domain_item>
                                                <ng-container *ngIf="detail.isDiscount; then dt_domain_item_discount; else dt_domain_item_item"></ng-container>
                                                <ng-template #dt_domain_item_discount><dd class="text-Danger">{{detail.priceYen}}円</dd></ng-template>
                                                <ng-template #dt_domain_item_item><dd>{{detail.priceYen}}円</dd></ng-template>
                                            </ng-template>
                                        </dl>
                                    </ng-container>
                                </div>
                            </ng-container>
                            <!-- コンビニ支払い手数料 -->
                            <ng-container *ngIf="viewModel.fees.count > 0">
                                <ng-container *ngFor="let fee of viewModel.fees.list">
                                    <div class="list-DefTable-Complete">
                                        <ng-container *ngFor="let detail of fee.details">
                                            <dl>
                                                <dt>{{detail.content}}</dt>
                                                <dd>{{detail.priceYen}}円</dd>
                                            </dl>
                                        </ng-container>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>
                    </td>
                </tr>
            </ng-container>
            <ng-container *ngIf="viewModel.servers && viewModel.servers.count > 0">
                <tr class="is-Open">
                    <td class="is-BorderTop">レンタルサーバー</td>
                    <td class="is-BorderTop"><i class="icon" [class.icon-ArrowUp]="viewModel.servers.isOpened" [class.icon-ArrowDown]="!viewModel.servers.isOpened" (click)="viewModel.servers.onClickIconArrow()"></i> 詳細はこちらをクリック（{{viewModel.servers.count}}件）</td>
                    <td class="is-BorderTop text-Right"><b>合計{{viewModel.servers.totalPriceYen}}円</b></td>
                </tr>
                <tr *ngIf="viewModel.servers.isOpened" class="is-Accordion">
                    <td colspan="3">
                        <div calss="is-FloatRight">
                            <!-- レンタルサーバー -->
                            <ng-container *ngFor="let server of viewModel.servers.list">
                                <div class="list-DefTable-Complete">
                                    <ng-container *ngFor="let detail of server.details">
                                        <dl>
                                            <ng-container *ngIf="detail.isTitle; then dt_server_title; else dt_server_item"></ng-container>
                                            <ng-template #dt_server_title><dt><b>{{detail.content}}</b></dt></ng-template>
                                            <ng-template #dt_server_item><dt>{{detail.content}}</dt></ng-template>
                                            <ng-container *ngIf="detail.isTitle; then dd_server_title; else dd_server_item"></ng-container>
                                            <ng-template #dd_server_title><dd class="is-Primary"><i class="icon icon-Checked"></i>受付完了</dd></ng-template>
                                            <ng-template #dd_server_item><dd>{{detail.priceYen}}円</dd></ng-template>
                                        </dl>
                                    </ng-container>
                                </div>
                            </ng-container>
                        </div>
                    </td>
                </tr>
            </ng-container>
        </tbody>
    </table><!-- /.tbl-Striped -->
    <!--合計金額-->
    <table class="tbl mt8">
        <colgroup>
            <col width="">
            <col width="400px">
        </colgroup>
        <tbody>
            <ng-container *ngIf="viewModel.isShowGmoPoint">
                <tr class="has-borderSolid">
                    <td></td>
                    <td>
                        <dl class="clearfix size-11 text-Em">
                            <dt class="is-FloatLeft">GMOポイント</dt>
                            <dd class="is-FloatRight text-Right">{{viewModel.gmoPointPt}}pt（円）</dd>
                        </dl>
                    </td>
                </tr>
            </ng-container>
            <tr>
                <td class="size-15 text-Bold pl16">合計金額（税込）</td>
                <td class="size-19 text-Bold text-Right"><ng-container *ngIf="viewModel.isGmoElectricNewApply"><span class="size-13 text-Danger">キャッシュバック対象※</span></ng-container> {{viewModel.totalPriceYen}}円</td>
            </tr>
        </tbody>
    </table><!-- /.tbl-Border -->
    <ng-container *ngIf="viewModel.isGmoElectricNewApply">
        <ul class="list-Note">
            <li><span>※</span>キャッシュバックの上限は6,000円です。</li>
            <li><span>※</span>対象ドメインのお申込みのみキャッシュバックが適用されます。対象ドメインは<a href="javascript:void(0)" (click)="openUrl('https://www.onamae.com/option/denki/renew/images/#section-payment')" class="gtmclick" data-gtmvalue="denki_tld">こちら</a></li>
        </ul><!-- /.list-Normal -->
    </ng-container>
</section><!-- /.section -->
<!--お申込み内容と合計金額-->
<section class="section mt30" *ngIf="viewModel.hasFreeDomain && viewModel.isCreateDomainSuccess ">
    <!--お申込み内容-->
    <h2 class="hdn-Lev3 with-border">ドメイン登録</h2>

    <!--ドメイン、コンビニ手数料、レンタルサーバー-->
    <table class="tbl tbl-Accordion is-Hoverable mb0">
        <colgroup>
            <col style="width:286px;">
            <col>
            <col style="width:286px;">
        </colgroup>
        <tbody>
            <ng-container *ngIf="viewModel.freeDomains">
                <tr class="is-Open">
                    <td>ドメイン登録</td>
                    <td><i class="icon" [class.icon-ArrowUp]="viewModel.freeDomains.isOpened" [class.icon-ArrowDown]="!viewModel.freeDomains.isOpened" (click)="viewModel.freeDomains.onClickIconArrow()"></i> 詳細はこちらをクリック（{{viewModel.freeDomains.count}}件）</td>
                    <td class="text-Right"><b>合計{{viewModel.freeDomains.totalPriceYen}}円</b></td>
                </tr>
                <tr *ngIf="viewModel.freeDomains.isOpened" class="is-Accordion">
                    <td colspan="3">
                        <div calss="is-FloatRight">
                            <!-- ドメイン -->
                            <ng-container *ngFor="let domain of viewModel.freeDomains.list">
                                <div class="list-DefTable-Complete">
                                    <ng-container *ngFor="let detail of domain.details">
                                        <dl>
                                            <ng-container *ngIf="detail.isTitle; then dt_domain_title; else dt_domain_item"></ng-container>
                                            <ng-template #dt_domain_title><dt><b>{{detail.content}}</b></dt></ng-template>
                                            <ng-template #dt_domain_item><dt [class.text-Danger]="detail.isDiscount">{{detail.content}}</dt></ng-template>
                                            <ng-container *ngIf="detail.isTitle; then dd_domain_title; else dd_domain_item"></ng-container>
                                            <ng-template #dd_domain_title><dd class="is-Primary"><i class="icon icon-Checked"></i>受付完了</dd></ng-template>
                                            <ng-template #dd_domain_item>
                                                <ng-container *ngIf="detail.isDiscount; then dt_domain_item_discount; else dt_domain_item_item"></ng-container>
                                                <ng-template #dt_domain_item_discount><dd class="text-Danger">{{detail.priceYen}}円</dd></ng-template>
                                                <ng-template #dt_domain_item_item><dd>{{detail.priceYen}}円</dd></ng-template>
                                            </ng-template>
                                        </dl>
                                    </ng-container>
                                </div>
                            </ng-container>
                        </div>
                    </td>
                </tr>
            </ng-container>
        </tbody>
    </table><!-- /.tbl-Striped -->
    <!--合計金額-->
    <table class="tbl mt8">
        <colgroup>
            <col width="">
            <col width="400px">
        </colgroup>
        <tbody>
            <tr>
                <td class="size-15 text-Bold pl16">合計金額（税込）</td>
                <td class="size-19 text-Bold text-Right">{{viewModel.freeDomains.totalPriceYen}}円</td>
            </tr>
        </tbody>
    </table><!-- /.tbl-Border -->
    <ng-container *ngIf="viewModel.isGmoElectricNewApply">
        <ul class="list-Note">
            <li><span>※</span>キャッシュバックの上限は6,000円です。</li>
            <li><span>※</span>対象ドメインのお申込みのみキャッシュバックが適用されます。対象ドメインは<a href="javascript:void(0)" (click)="openUrl('https://www.onamae.com/option/denki/renew/images/#section-payment')" class="gtmclick" data-gtmvalue="denki_tld">こちら</a></li>
        </ul><!-- /.list-Normal -->
    </ng-container>
</section><!-- /.section -->
<section class="section mt30" *ngIf="viewModel.hasFreeDomain && !viewModel.isCreateDomainSuccess ">
    <div class="nav-Group mb8">
        <div class="nav-Group-Left">
          <hdn-lev3>ドメイン登録</hdn-lev3>
        </div><!-- -->
    </div>
    <box-border class="box-Border-mb30" *ngIf="viewModel.isCreditCardPayment; else tblContent">
        <span class="box-Border-Heading is-Primary">「{{viewModel.freeRegistDomainNameMl}}」のドメイン登録処理が正常に完了できませんでした。</span><br>
        「お名前ID」と「対象のドメイン名」をお書き添えのうえ、<a href="javascript:void(0)" (click)="viewModel.contactPageOpen()">お問い合わせフォーム</a>までお問い合わせください。
    </box-border>
</section>

<section class="section mt30" *ngIf="viewModel.isDisplayPaymentMethod">
    <div class="nav-Group mb8">
        <div class="nav-Group-Left">
            <hdn-lev3>{{viewModel.displayPaymentMethodTitle}}</hdn-lev3>
        </div><!-- -->
    </div>
    <box-border class="box-Border-mb30" *ngIf="viewModel.isCreditCardPayment; else tblContent">
        <span class="box-Border-Heading is-Primary"><i class="icon icon-Checked"></i>{{viewModel.displayBoxBorderText}}</span><br>
        別途ご請求明細メールをお送りしましたので、ご確認ください。
    </box-border>
    <ng-template #tblContent>
        <table class="tbl" *ngIf="viewModel.isBankPayment">
            <colgroup>
                <col style="width:206px;">
                <col>
            </colgroup>
            <tbody>
                <tr>
                    <th>振込先</th>
                    <td [innerHtml]="viewModel.bankPaymentDetails.payee"></td>
                </tr>
                <tr>
                    <th>振込期限</th>
                    <td>{{viewModel.bankPaymentDetails.paymentLimitDate}}</td>
                </tr>
                <tr>
                    <th>振込金額</th>
                    <td>{{viewModel.bankPaymentDetails.totalPrice}}円</td>
                </tr>
            </tbody>
        </table>
        <table class="tbl" *ngIf="viewModel.isConveniencePayment">
            <colgroup>
                <col style="width:160px;">
                <col>
                <col style="width:160px;">
                <col>
            </colgroup>
            <tbody [ngSwitch]="viewModel.conveniCode">
                <ng-container *ngSwitchCase="viewModel.DomainConvenienceType.FamilyMart">
                    <tr>
                        <th>振込先</th>
                        <td>コンビニエンスストア　{{viewModel.conveniencePaymentDetails.storeName}}</td>
                        <th>企業コード</th>
                        <td>{{viewModel.conveniencePaymentDetails.companyNumber}}</td>
                    </tr>
                    <tr>
                        <th>注文番号</th>
                        <td>{{viewModel.conveniencePaymentDetails.orderNumber}}</td>
                        <th>姓名</th>
                        <td>{{viewModel.conveniInfo.userNameKana}}</td>
                    </tr>
                    <tr>
                        <th>お支払い期限</th>
                        <td>{{viewModel.conveniencePaymentDetails.conveniLimitDate}}</td>
                    </tr>
                </ng-container>
                <ng-container *ngSwitchDefault>
                    <tr>
                        <th>振込先</th>
                        <td>{{viewModel.conveniencePaymentDetails.storeName}}</td>
                        <ng-container *ngIf="!!viewModel.conveniencePaymentDetails.paymentUrl; else emptyTd">
                            <th>お支払いURL</th>
                            <td><a href="javascript:void(0)" (click)="openUrl(viewModel.conveniencePaymentDetails.paymentUrl)">こちら</a></td>
                        </ng-container>
                        <ng-template #emptyTd>
                            <td></td>
                            <td></td>
                        </ng-template>
                    </tr>
                    <tr>
                        <th>注文番号</th>
                        <td>{{viewModel.conveniencePaymentDetails.orderNumber}}</td>
                        <th>お支払い期限</th>
                        <td>{{viewModel.conveniencePaymentDetails.conveniLimitDate}}</td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </ng-template>

    <ng-container *ngIf="viewModel.servers.count > 0">
        <div class="nav-Group mb8">
            <div class="nav-Group-Left">
                <hdn-lev3>{{viewModel.displayServerPaymentMethodTitle}}</hdn-lev3>
            </div><!-- -->
        </div>
        <box-border>
            <span class="box-Border-Heading is-Primary"><i class="icon icon-Checked"></i>{{viewModel.displayServerPaymentMethodText}}</span><br>
            別途ご請求明細メールをお送りします。
        </box-border>
    </ng-container>
</section>
<p></p>
<p></p>
<p></p>
<p class="img-Single mb30"><a href="javascript:void(0)" (click)="openUrl('https://www.onamae.com/line/?banner_id=pc_result_sNavi_line')"><img src="{{CacheBusting('https://cache.img.gmo.jp/onamae/top/banner/1600x240/line_20220915.png')}}" alt=""></a></p>
<section *ngIf="viewModel.isDisplayDomainMonitoringRecommend" class="section mb60">
    <div class="box-DomainBanner is-Type2">
        <div class="box-DomainBanner-Inner">
            <h2 class="box-DomainBanner-Hdn">そのドメインは社名・サービス名・商品名などではありませんか？</h2>
            <p class="box-DomainBanner-DomainName">{{viewModel.displayDomainRecommendedForMonitoring}}</p>
            <p class="box-DomainBanner-Text">
                類似ドメインの第三者取得によるブランド棄損リスクは甚大です。<br>
                ドメインモニタリングを設定いただければ、だれでも・かんたんにドメイン名の監視が可能です。
            </p>
            <ul class="list-Icon">
                <li><i class="icon icon-Checked"></i>文字列を設定するだけ!</li>
                <li><i class="icon icon-Checked"></i>稼働状況を一括チェック可能 !</li>
                <li><i class="icon icon-Checked"></i>第三者が取得済みのドメインもチェック可能!</li>
            </ul><!-- /.list-Icon -->
            <p class="list-Panel-Item-Content-Btn">
                <button type="button" class="btn is-Primary" (click)="viewModel.onClickDomainMonitoring()">設定はこちら</button>
            </p>
        </div>
    </div>
</section><!-- /.section -->
<section *ngIf="viewModel.isDisplayDomainProtectRecommend" class="section mb60">
    <div class="box-DomainBanner is-Type2">
        <div class="box-DomainBanner-Inner">
            <h2 class="box-DomainBanner-Hdn">第三者による不正操作被害を未然に防ぐには</h2>
            <p class="box-DomainBanner-DomainName">{{viewModel.displayDomainRecommendedForProtecting}}</p>
            <p class="box-DomainBanner-Text">
                世界にただ一つしかない大切なドメイン名を守るための【セキュリティ対策】は大変重要です。<br>
                ドメインプロテクションを設定いただければ、【第三者】による下記の操作変更を未然に防ぐことが可能です。
            </p>
            <ul class="list-Icon">
                <li><i class="icon icon-Checked"></i>文字列を設定するだけ!</li>
                <li><i class="icon icon-Checked"></i>ドメイン登録情報の書換え</li>
                <li><i class="icon icon-Checked"></i>意図しない管理会社変更手続き</li>
            </ul><!-- /.list-Icon -->
            <p class="list-Panel-Item-Content-Btn">
                <button type="button" class="btn is-Primary" (click)="viewModel.onClickDomainProtect()">設定はこちら</button>
            </p>
        </div>
    </div>
</section><!-- /.section -->

<section *ngIf="viewModel.renewDomains.length > 0" class="section mb30 mt30">
    <!--★更新漏れドメインがあればこのセクションを表示。条件で見る変数決まったら変える-->
    <ul class="list-Message is-Appeal is-Small is-NoShadow mb0">
        <li class="list-Message-item">
            <span class="list-Message-Content">
                <i class="icon icon-InfoCircle"></i>
                こちらのドメインも更新期限が迫っています。
            </span>
        </li>
    </ul>
    <table class="tbl-Striped is-Hoverable is-NoBorderTop">
        <colgroup>
            <col style="width:50px;">
            <col>
            <col style="width:200px;">
            <col style="width:80px;">
        </colgroup>
        <thead>
            <tr>
                <th class="cell-Checkbox">
                    <label>
                        <input type="checkbox" name="isCheckedDomainAll" class="gtmclick" [attr.data-gtmvalue]="'checked_domain_all_' + viewModel.renewDomainHeader.isCheckedDomainAll" [(ngModel)]="viewModel.renewDomainHeader.isCheckedDomainAll" (change)="viewModel.onChangeRenewDomainAll()" />
                        <span class="form-Checkbox is-Large" tabindex="0"></span>
                    </label>
                </th>
                <th>ドメイン名</th>
                <th>更新期限日</th>
                <th class="text-Right">更新年数</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let renewDomain of viewModel.renewDomains; let i = index;">
                <td class="cell-Checkbox">
                    <label *ngIf="renewDomain.isActiveDomain">
                        <input type="checkbox" name="isCheckedDomain" class="gtmclick" [attr.data-gtmvalue]="'checked_domain_' + i + '_' + renewDomain.isCheckedDomain" [(ngModel)]="renewDomain.isCheckedDomain" (change)="viewModel.onChangeRenewDomain()" />
                        <span class="form-Checkbox is-Large" tabindex="0"></span>
                    </label>
                </td>
                <!--★更新漏れドメインリスト分繰り返し。条件で見る変数決まったら変える-->
                <td><strong>{{renewDomain.domainNameMl}}</strong></td>
                <td class="text-Left">{{renewDomain.renewalDeadlineDate}}<span class="text-Danger">（残{{renewDomain.renewalDeadlineCount}}日）</span></td>
                <td class="text-Right">1年</td>
                <td class="text-Right"><button type="button" [class.is-Disabled]="viewModel.checkedRenewDomains.length < 1" class="btn is-Primary gtmclick" data-gtmvalue="domrenew_done_again" (click)="viewModel.onClickOneClickModalButton()">料金を確認する</button></td>
            </tr>
        </tbody>
    </table><!-- /.tbl-Striped -->
</section><!-- /.section -->
<p class="text-Center mb40"><a href="javascript:void(0)" class="link" (click)="viewModel.onClickSettingPage()">設定ページへ戻る</a></p>
<div class="box-Single">
    <ul class="img-BannerList is-Recommend">
        <li *ngFor="let recommendMessage of viewModel.recommendMessages" [innerHtml]="recommendMessage.InnerHtml"></li>
    </ul>
</div>

<div *ngIf="viewModel.isAllLoading" class="loading" style="position:absolute;display:block">
    <div class="loading-Loader">
        <div class="loading-Loader-Blade"></div>
        <div class="loading-Loader-Blade"></div>
        <div class="loading-Loader-Blade"></div>
        <div class="loading-Loader-Blade"></div>
        <div class="loading-Loader-Blade"></div>
        <div class="loading-Loader-Blade"></div>
        <div class="loading-Loader-Blade"></div>
        <div class="loading-Loader-Blade"></div>
        <div class="loading-Loader-Blade"></div>
        <div class="loading-Loader-Blade"></div>
        <div class="loading-Loader-Blade"></div>
        <div class="loading-Loader-Blade"></div>
    </div>
</div>
