/// <reference path='../definitions/window.d.ts' />
/// <reference path='../interface/interface_frequently_viewed_page.d.ts' />

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router'

import { BaseServiceContainer } from 'Content/common/script/service/base_service_container';
import { Service } from 'Content/script/service/account_info_service';
import { NotifyMessage, NotifyType } from 'Content/script/service/notify_service';

import { IDisposable } from 'Content/script/interface/interface_disposable';
import { IInitialization } from 'Content/script/interface/interface_initialization';

import { AnalyticsUtil } from 'Content/script/libs/adobeanalytics/analytics.util';

import { VIEWS } from '../store/states/view';

import { Subscription } from 'rxjs';
import { prototype } from 'stream';
import { CustomizedEvents } from '../libs/abtest/custom-event.util';
import { docCookies } from '../libs/third_party/cookies';


@Component({
    template: ''
})
export abstract class BaseComponent implements OnInit, OnDestroy, IDisposable, IInitialization {
    public queueId: number;
    protected isAliveSession: boolean;
    protected isDeleteDataTransfer: boolean;
    protected loginName: string;
    public proxyId: string;
    public static isFirstInit: boolean = false;

    protected frequentlyViewedPage: FrequentlyViewedPage = null;

    protected accountLayoutPromise: Promise<Service.AccountInfo>;

    private _storeSubscribes: Subscription[] = [];

    private _isInitialization: boolean;

    public constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected serviceContainer: BaseServiceContainer) {
        this.queueId = this.serviceContainer.HubsService.multipleSendManager.GetComponentId();
        this.isAliveSession = false;
        this.isDeleteDataTransfer = true;
        this._isInitialization = false;
    }

    protected get isFrequentlyView(): boolean {
        return this.frequentlyViewedPage !== null && this.frequentlyViewedPage !== void(0);
    }

    protected addStoreSubscribe(subscribe: Subscription): void {
        this._storeSubscribes.push(subscribe);
    }

    protected clearStoreSubscribes(): void {
        if (this._storeSubscribes) {
            this._storeSubscribes.forEach((subscribe) => {
                subscribe.unsubscribe();
            });
        }
        this._storeSubscribes = [];
    }

    public ngOnInit() {
        if (this._isInitialization) {
            return;
        }
        this._isInitialization = true;
        if (this.serviceContainer.ModalControlService.HideFullScreenLoading) {
            this.serviceContainer.ModalControlService.HideFullScreenLoading();
        }
        if (this.serviceContainer.GetViewMode() === VIEWS.PC && this.serviceContainer.isFirstViewAfterLogin) {
            this.serviceContainer.isFirstViewAfterLogin = false;
        }
        //var logRequest: SendLogRequestHubDto = {
        //    LogType: 3,
        //    Url: location.href,
        //    Message: 'コンポーネント遷移'
        //};

        /* 遷移情報が必要な為、画面遷移ログをサーバーに送信する */
        //this.serviceContainer.HubsService.multipleSendManager.Send(
        //    this.queueId,
        //    this.serviceContainer.HubsService.hubsManager.loggerHubManager.SendLog,
        //    logRequest,
        //    (res: SendLogResponseHubDto) => {
        //    },
        //    (res: SendLogResponseHubDto) => {
        //    },
        //    (res: SendLogResponseHubDto) => {
        //    });
        
        window.scrollTo(0, 0);

        this.Initialization();

        this.serviceContainer.NotifyService.LazyNotifyMessageMovement();

        this.serviceContainer.SessionService.SetCallBackUrl(location.pathname);

        this.accountLayoutPromise = this.serviceContainer.AccountInfoService.GetAccountInfo("base_component");
        this.accountLayoutPromise.then((accountInfo) => {
            this.loginName = accountInfo.LaginName;
            this.proxyId = accountInfo.ProxyId;
            this.UpdateFrequentlyViewedPages(accountInfo.ProxyId);

            if (accountInfo) {
                this.onAccountInfoUpdated(accountInfo);
            }
        }).catch((errorType: Service.ErrorType) => {
            let loginUrl = this._directLinkWithError(location.pathname);
           
            switch (errorType) {
                case Service.ErrorType.Error:
                    this.serviceContainer.SessionService.SetCallBackUrl(location.pathname);
                    this.router.navigateByUrl(loginUrl);
                    return;

                case Service.ErrorType.NoSession:
                    this.serviceContainer.SessionService.SetCallBackUrl(location.pathname);
                    let queryparams = AnalyticsUtil.getQueryParamsOnFirstRender(window.RouterPath);
                    if (this.serviceContainer.GetViewMode() === VIEWS.PC && window.isMobileDevice === true) {
                        window.isFirstRender = true;
                        queryparams["forceView"] = "pc";
                        queryparams["url"] = this.serviceContainer.SessionService.GetCallBackUrl();
                    }

                    if (window.isFirstRender) {
                        this.router.navigate([loginUrl], { queryParams: queryparams });
                    }
                    
                    return;

                case Service.ErrorType.UnExcepted:
                    location.href = '/error/server';
                    return;
            }
        });

        if (sessionStorage.getItem('firstViewAfterLogin') !== 'y' && !BaseComponent.isFirstInit) {
            this.serviceContainer.DomainWithDefaultNameServerService.GetDomainWithDefaultNameServer().then((resHubDto) => {
                sessionStorage.setItem('firstViewAfterLogin', 'y');
                let isDefaultNsDomain = resHubDto.DomainIds?.length > 0;
                if (isDefaultNsDomain && docCookies.getItem("transferType") !== "STAFF_LOGIN") {
                    CustomizedEvents.dispatchDefaultNsDomain();
                }
            })
            BaseComponent.isFirstInit = true;
        }
    }

    /*******************************************************
     * getAccountInfoが正常終了した後にviewを表示したいとき
     * viewを表示するのにアカウント情報が必須の場合には
     * このメソッドをオーバーライドします
     *******************************************************/
    protected onAccountInfoUpdated(accountInfo: Service.AccountInfo) {
    }

    /*******************************************************
     * ngOnInitをオーバーライドするとセッション確認を行いません
     *******************************************************/
    public Initialization(): void {
    }

    public ngOnDestroy() {
        this.clearStoreSubscribes();
        this.serviceContainer.HubsService.multipleSendManager.DeleteComponentId(this.queueId);
        this.serviceContainer.NotifyService.AllRemoveNotifyMessage();

        this.Dispose();
    }

    /***************************************************
     * オーバーライドを必ず行う
     * オーバーライドを行わないと派生クラスのプロパティが
     * 破棄されずにメモリリークが発生します
     ***************************************************/
    public abstract Dispose(): void;

    public deleteAllProperty(deleteObject: Object, ignoreServices: string[] = []) {
        if (this.isDeleteDataTransfer) {
            this.serviceContainer.DataTransferService.clear();
        }

        var propertyNames = Object.getOwnPropertyNames(deleteObject);
        propertyNames.forEach((propertyName) => {
            if (propertyName != 'serviceContainer') {
                var ignoreIndex = ignoreServices.indexOf(propertyName);
                if (ignoreIndex == -1) {
                    deleteObject[propertyName] = null;
                }
            }
        });
    }

    /**************************
     * キャッシュバースティング
     * @param uri
     **************************/
    public CacheBusting(uri: string): string {
        return window.CacheBusting(uri);
    }

    /*********************************
     * よく見るページの登録と更新を行う
     *********************************/
    public UpdateFrequentlyViewedPages: (proxyId: string) => void = (proxyId: string): void => {
        /*
        * よく見るページのロジック
        */
        if (this.isFrequentlyView) {
            if (this.serviceContainer.HeaderComponentService && this.serviceContainer.HeaderComponentService.UpdateFrequentlyViewedPages) {
                this.serviceContainer.HeaderComponentService.UpdateFrequentlyViewedPages(this.frequentlyViewedPage, proxyId);
            }
        }
    }

    /**
     * よく見るページ有効化
     */
    protected enableFrequently(title: string, url: string): void {
        this.frequentlyViewedPage = {
            PagePriority: 0,
            PageTitle: title,
            PageUrl: url,
            LoadCount: 1,
            Expire: (new Date).getTime() + 1000 * 1969200,
        };
    }

    public openUrl(url: string): void {
        window.open(url, '_blank', 'noopener,noreferrer');
    }

    private _directLinkWithError(localpath: string): string {
        if (localpath.startsWith('/selldomain')) {
            return '/sell';
        } else {
            if (this.serviceContainer.GetViewMode() === VIEWS.SP) {
                return '/sp/login'
            }
            return '/login'
        }

    }
}

export class BaseViewModel {
    public queueId: number;
    public router: Router;
    public serviceContainer: BaseServiceContainer;

    public constructor(queueId: number, router: Router, serviceContainer: BaseServiceContainer) {
        this.queueId = queueId;
        this.router = router;
        this.serviceContainer = serviceContainer;
    }

    public NoSession = (data: BaseResponseHubDto): void => {
        onNoSession(this.serviceContainer, this.router);
    }

    public Error = (data: BaseResponseHubDto): void => {
        onError(this.router);
    }

    public UnExceptedError = (data: BaseResponseHubDto): void => {
        onUnexpectedError(this.router);
    }

    public SubMaintenanceMode = (data: BaseResponseHubDto): void => {
        onSubMaintenanceMode(this.serviceContainer);
    }

    public dataLayerPush(pushData: string)
    {
        var domainRenewKey: string = "";
        if (window.IframePath != void (0) && window.IframePath != "") {
            domainRenewKey = "__" + window.IframePath;
        }

        if (window.IsGACheck != void (0) && window.IsGACheck) {
            console.log("ga: " + "newnavi_" + location.pathname.slice(1).replace(/\//g, "_") + "__" + pushData + domainRenewKey);
        }
        
        dataLayer.push({
            'event': 'event_cd006',
            'CD006 | btn_id': "newnavi_" + location.pathname.slice(1).replace(/\//g, "_") + "__" + pushData + domainRenewKey
        });
    }

    public openUrl(url: string): void {
        window.open(url, '_blank', 'noopener,noreferrer');
    }

    public adjustImageWidth(messageBanners: string): string {
            return messageBanners.replace(/width="\d+"/g, 'width="auto"');
    }
    private _directLinkWithError(localpath: string): string {
        if (localpath.startsWith('/selldomain')) {
            return '/sell';
        } else {
            if (this.serviceContainer.GetViewMode() === VIEWS.SP) {
                return '/sp/login'
            }
            return '/login'
        }

    }
}

export function onNoSession(serviceContainer: BaseServiceContainer, router: Router): void {
    serviceContainer.NotifyService.GetNotifyId();

    const notify: NotifyMessage = new NotifyMessage(serviceContainer.NotifyService.GetNotifyId(), NotifyType.Error,"セッションが切断されました。");
    serviceContainer.NotifyService.AddNotifyMessages([notify]);
    let loginUrl: string = '/login';
    if (serviceContainer.GetViewMode() === VIEWS.SP) {
        loginUrl = '/sp/login';
    }
    if (location.pathname.startsWith('/selldomain')) loginUrl = '/sell'
    setTimeout(() => {
        router.navigateByUrl(loginUrl);
    }, 3000);
}

export function onError(router: Router): void {
    router.navigateByUrl('/error/client');
}

export function onUnexpectedError(router: Router): void {
    router.navigateByUrl('/error/server');
}

export function onSubMaintenanceMode(serviceContainer: BaseServiceContainer): void {
    const notify: NotifyMessage = new NotifyMessage(serviceContainer.NotifyService.GetNotifyId(), NotifyType.Error, "メンテナンス中のため一部のお手続きができません。メンテナンス終了後に再度お手続きください。");
    serviceContainer.NotifyService.AddNotifyMessages([notify]);
}

class BaseResponseHubDto {
    /// <summary>
    /// エラー判定用
    /// </summary>
    public IsError: boolean;

    /// <summary>
    /// エラーメッセージ
    /// </summary>
    public ErrorMessage: string;

    /// <summary>
    /// 予期しないエラー
    /// </summary>
    public IsUnexpectedError: boolean;

    /// <summary>
    /// セッションがない
    /// </summary>
    public NoSession: boolean;
}
