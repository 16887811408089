/// <reference path='../../../definitions/window.d.ts' />

import { Component } from '@angular/core';
import { ServiceContainer } from 'Content/script/service/service_container';

@Component({
    selector: 'fake-header',
    templateUrl: './fake_header.html'
})
export class FakeHeaderComponent {
    public constructor(private readonly serviceContainer: ServiceContainer) {}

    public get hasLoginName(): boolean {
        return this.serviceContainer.LinkService.hasAccountInfo;
    }

    public get loginName(): string {
        const accountInfo = this.serviceContainer.LinkService.GetAccountInfo();
        if (accountInfo) {
            return accountInfo.loginName;
        } else {
            return '';
        }
    }

    public CacheBusting(uri: string): string {
        return window.CacheBusting(uri);
    }
}
