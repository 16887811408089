import { Component, Input } from '@angular/core';
import { AbstractControlDirective } from '@angular/forms';

@Component({
    selector: 'form-error-label',
    template: `
<label *ngIf="isShow" class="form-Error">
    <ng-content></ng-content>
</label>`
})
export class FormErrorLabelComponent {
    @Input() public validator: string;
    @Input() public condition: boolean;
    public controlDirective: AbstractControlDirective;

    public assign(controlDirective: AbstractControlDirective) {
        this.controlDirective = controlDirective;
    }

    public get isShow(): boolean {
        if (this.condition !== null && this.condition !== void 0) {
            return this.condition;
        } else {
            return !!this.controlDirective.errors && !!this.controlDirective.errors[this.validator];
        }
    }
}