import { CardHolderNameUtil } from 'Content/script/libs/utils/cardHolderName.util';

export class ValidationRegexsDefine {
    //#region Domain and Hosting

    // OnamaeId[PATTERN_01]
    public static readonly rOnamaeIdPattern = '^(?!\\s)+[0-9a-zA-Z-+._@/]*$';

    // OnamaeId[PATTERN_02]
    public static readonly rOnamaeIdPattern2 = '^(?!\\s)+[0-9a-zA-Z]*$';

    // OnamaePassword[PATTERN_10]
    public static readonly rOnamaePasswordPattern = '^(?!\\s)+[0-9a-zA-Z,.!_/`\'\"()\\[\\]+\\-=$#&@~;?%*:]*$';

    // Domain[EXP_DOMAIN]
    public static readonly rDomainPattern = '^(?!\\s)+(?!.*\\.{2,})[0-9０-９a-zａ-ｚA-ZＡ-Ｚぁ-んァ-ヶｦ-ﾟ一-龍-.*\n・ヽヾゝゞゞ々-ー]*$';

    public static readonly rDomainAndHostPattern = '^(?!http:\/\/)(?!www\.)(?:[a-zA-Z0-9０-９ぁ-んァ-ヶｦ-ﾟ一-龍ー々・ヽヾゝゞー-Ａ-Ｚ]{2,}\.){0,1}[a-zA-Zぁ-んァ-ンa-zA-Zﾞﾟ一-ﾟ]{2,}\.[a-zA-Zぁ-んァ-ンa-zA-Zﾞﾟ一-ﾟ]{2,}$';

    public static readonly rDomainPatternWithouTld = '^(?!\\s)+(?!.*\\.{2,})[0-9０-９a-zａ-ｚA-ZＡ-Ｚぁ-んァ-ヶｦ-ﾟ一-龍-*\n・ヽヾゝゞゞ々-ー]*$';
    // Number[@DesNumberType]
    public static readonly rNumberPattern = '^(?!\\s)+[0-9]*$';
    public static readonly rNumberPatternWithSpace = '^(?!\\s)+[0-9 ]*$';

    // ローマ字[PATTERN_04]
    public static readonly rEnglishPattern1 = '^(?!\\s)+[0-9a-zA-Z ,.!_/`\'\"()\\[\\]+\\-=$#&@~]*$';

    // クレジットカードのカード名義人に入力可能な文字種
    public static get rCreditHolder(): string {
        return '^[' + CardHolderNameUtil.getRegex() + ']*$';
    }

    // クレジットカードのブランドごとのセキュリティコードバリデーション
    public static readonly rAmericanExpressCreditSecurityCode = '^(?!\\s)+[0-9]{4}$';
    public static readonly rNotAmericanExpressCreditSecurityCode = '^(?!\\s)+[0-9]{3}$';
    
    // ローマ字[EXP_PATTERN2]
    public static readonly rEnglishPattern2 = '^(?!\\s)+[0-9a-zA-Z ,.!_/`\'\"()\\[\\]+-=$#&@~]*$';

    // 日本語[isValidAsJapanese]
    public static readonly rJapanesePattern = '^(?!\\s)+[0-9０-９a-zａ-ｚA-ZＡ-Ｚぁ-んァ-ヶｦ-ﾟ一-龍　 ,.!_/`\\\'\\"()\\[\\]+-=$#&@~、。，．・：；！゛゜´｀¨＾￣＿ヽヾゝゞ〃仝々〆〇ー―／＼‘’“”（）「」＋‐－×÷＝°′″℃￥＃＆＠※〒]*$';

    // ひらがな
    public static readonly rHiraganaPattern = "^(?!\\s)+[ぁ-ん　 \\-(),.:;?!`'\"、。・゛゜´¨ヽヾゝゞ〃ー～…‥°′″]*$";

    // 全角カタカナ[-]
    public static readonly rFullWidthKatakanaPattern = '^(?!\\s)+[ァ-ー　]*$';

    // Email[@DesEmail]
    public static readonly rEmailPattern = '^(?!\\s)+[0-9a-zA-Z_.\\-\\+]+@[0-9a-zA-Z_\\-\\+]+(\\.[0-9a-zA-Z_\\-\\+]+){1,}';

    // Phone/Fax[EXP_PHONE_FAX]
    public static readonly rPhoneAndFaxPattern = '^(?!\\s)+[0-9-‐+.]*$';
    public static readonly rPhoneAndFaxPatternForRegistries = '^(?!\\s)+[0-9-‐]*$';

    // 半角英数字とハイフン
    public static readonly rHarfWidthCharactorWithHyphen = '[0-9a-z-]*$';

    // 16進数と半角スペース
    public static readonly rHexadecimalWhitespacePattern = '^(?!\\s)+[0-9a-fA-F ]*$'

    // 検索条件：日付フォーマット（yyyy/mm/dd）
    // ※フロント側では正規表現と実在する日のチェックをdateディレクティブで行っている
    //public static readonly searchDateFormatPattern = "([0-9]{4})/(([1][0-2])|([0][1-9]))/(([0-2][0-9])|([3][0-1]))";

    //#endregion

    //#region Domain Only

    // Whois情報画面にて変更情報の選択を制御するTLD名（http://gmo-ticket.internal-gmo/issues/231391?SOOM）
    public static readonly rWhoisUnselectableContact  = '.*\\.co\\.uk$|.*\\.me\\.uk$|.*\\.org\\.uk$|.*\\.be$|.*\\.gs$|.*\\.am$|.*\\.to$|.*\\.fm$';

    // TODO:

    //#endregion

    //#region Hosting Only

    // 銀行・支店名
    public static readonly rBankNamePattern = "^(?!\\s)+[0-9０-９a-zａ-ｚA-ZＡ-Ｚぁ-んァ-ヶｦ-ﾟ一-龍ヽヾゝゞゞ々-ー^（）() 　\\.\\-\\/,、・]*$";
    
    // 口座名義カナ
    public static readonly rHostingAccountHolderKatakanaPattern = '[a-zA-Z0-9ァ-ヶｦ-ﾟー－^（）\\.\\-\\/]+';

    // 口座名義カナ(ドメイン返金)
    public static readonly rDomainAccountHolderKatakanaPattern = '[a-zA-Z0-9ァ-ヶｦ-ﾟー－^（）() 　\\.\\-\\/]+';

    // 口座名義(スペース必須)
    public static readonly rHostingAccountHolderBetweenSpacePattern = '(\\S)+\\s([^\\t])+';

    // 口座名義カナ(スペース必須)
    public static readonly rHostingAccountHolderKatakanaBetweenSpacePattern = ValidationRegexsDefine.rHostingAccountHolderKatakanaPattern + '\\s' + ValidationRegexsDefine.rHostingAccountHolderKatakanaPattern;

    // 口座名義（ドメインパーキング）：半角英字（小文字と大文字）、半角数字、半角カナ、括弧、ピリオド、ハイフン、スラッシュ、半角スペース
    public static readonly rDomainParkingAccountNamePattern = '^(?!\\s)+[a-zA-Z0-9ｦ-ﾟ()\\.\\-\\/ ]+$';

    // 半角ｶﾅ (ホスティング)
    //public static readonly rHostingHalfWidthPattern = '[^a-zA-Z0-9 ｱｲｳｴｵｶｷｸｹｺｻｼｽｾｿﾀﾁﾂﾃﾄﾅﾆﾇﾈﾉﾊﾋﾌﾍﾎﾏﾐﾑﾒﾓﾔﾕﾖﾗﾘﾙﾚﾛﾜｦﾝﾞﾟ()\.\-\/]';

    // 半角ｶﾅ (ホスティング)
    //public static readonly rHostingHalfWidthPattern = '^[0-9A-Za-z\(\)\[\]\+\.\$,!_/`\'=#&@~ -]*$';

    // 半角英数字とハイフン(-)
    public static readonly rHostingSubDomainPattern = '[0-9a-z-]*$';

    // 自由入力でのドメインのパターン
    public static readonly rHostingDomainFreeWritingPattern = '^[0-9a-zA-Z][0-9a-zA-Z-.]*[0-9a-zA-Z]$';

	// ワードプレスユーザー名
    public static readonly rHostingWordpressUsernamePattern = '[0-9a-z-_@.]*$';

    // ガイドフォーム（空白で始まらない＆終わらない＆必須入力）
    //public static readonly rGuideFormPattern = '^(?!\\s)+[0-9０-９a-zａ-ｚA-ZＡ-Ｚぁ-んァ-ヶ一-龍\\s]*';
    public static readonly rGuideFormPattern = '^(?!\\s)(?!.*\\s$)[0-9０-９a-zａ-ｚA-ZＡ-Ｚ\\u30a0-\\u30ff\\u3040-\\u309f\\u3005-\\u3006\\u30e0-\\u9fcf\\s]+$';

    public rEscapeEncodePattern: string[] = [
        '/&#32;/u',
        '/&#160;/u',
        "/[\\x{A0}]/u",
        '/&#([8-8][1-1][9-9][4-9]);/u', //&#8194; - &#8199;
        "/[\\x{2002}-\\x{2007}]/u",
        '/&#([8-8][2-2][0-0][0-3]);/u', //&#8200; - &#8203;
        "/[\\x{2008}-\\x{200B}]/u",
        '/&#12288;/u',
        "/[\\x{3000}]/u",
        '/&#65279;/u',
        "/[\\x{FEFF}]/u",
        "/&#([0-9]|1[0-9]|20);/u",
        "/[\\x{0}-\\x{9}]/u",
        "/[\\x{10}-\\x{20}]/u"
    ];
    //#endregion
}
