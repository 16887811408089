import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { IFlexView, AbstractFlexView } from 'Content/script/promotion/common/flexview';

@Component({
    template: 
        `<div class="box-DomainAppeal is-Small is-Center mt22 mb40">
            <span class="box-DomainAppeal-Icon">
                <i class="icon icon-domain"></i>
            </span>
            <div class="box-DomainAppeal-Body">
                <p class="box-DomainAppeal-Heading">利用されていないドメインがあります</p>
                <button type="button" class="btn is-Small is-Primary gtmclick"  data-gtmvalue="navidomrenew_rsapp" (click)="onClick()">{{!abTestAdjustTextButtonValue? "ドメインを利用する": "ドメインを確認する"}}</button>
            </div>
        </div>`,
})

export class PromotionDomainUseDomainSettingRenewDoneComponent {
    constructor(protected _router: Router) { }

    onClick() {
        this._router.navigateByUrl('/domain');
    }

    public get abTestAdjustTextButtonValue(): boolean {
        return window.isABNew_AdjustTextButton;
    }
}

export class DomainSettingRenewDoneFlexView extends AbstractFlexView<PromotionDomainUseDomainSettingRenewDoneComponent> {

    public get canAttach(): boolean {
        const el = window.document.getElementsByName('nm-promotion-domainuse');
        return el.length > 0;
    }

    public attachDirective() {
        const el = window.document.getElementsByName('nm-promotion-domainuse');
        this._viewContainerRef.clear();
        const componentRef = this._viewContainerRef.createComponent(this._factory);
        this._renderer.appendChild(el[0].parentElement, componentRef.location.nativeElement);
    }
}
