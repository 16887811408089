<!--
  GT#112738【お名前.com Navi】RS初回契約者向けモーダル表示の開発
  RS所持件数：1件
  RSステータス：ACTIVE
  Cookie：RsloggedInが保存されていないこと
  URL：/top
-->
<div class="modal modal-Tour" modal [openFlg]="isShowInstruct" data-dismiss="modal">
  <div class="modal-Dialog is-NoBorder is-Appeal">
    <div class="modal-Dialog-Body has-image">
      <div class="modal-Dialog-Appeal-Image">
        <img src="{{CacheBusting('/Content/images/top/loginTour_start.jpg')}}" alt="">
      </div>
    </div>
    <div class="modal-Dialog-Bottom pt32 pl22 pr22 pb24">
      <h2 class="hdn-Lev1 text-Center">お名前.com Naviへようこそ<br>機能を紹介します</h2>
      <h3 class="hdn-Lev3">お名前.com Naviで出来ること</h3>
      <div class="box-Border is-Thick mb0">
        <div class="box-Border-Inner  pl72 pr72">
          <ul class="list-Icon is-DisplayFlex is-justifyContentSpaceBetween mb0">
            <li><i class="icon icon-Checked"></i>会員情報の確認/変更</li>
            <li><i class="icon icon-Checked"></i>コントロールパネルへのログイン</li>
            <li><i class="icon icon-Checked"></i>ご契約やお支払い情報の確認/変更</li>
            <li><i class="icon icon-Checked"></i>メール配信設定の変更</li>
          </ul><!-- /.list-Icon -->

        </div><!-- .box-Border-Inner -->
      </div><!-- /.box-Border -->
    </div>
    <div class="modal-Dialog-Footer">
      <ul class="list-Btn is-Right mb0">
        <li><button type="button" class="btn is-Primary gtmclick" data-gtmvalue="firstmodal" (click)="onclickCloseInstructModal()">次に進む</button></li>
      </ul><!-- /.list-Btn -->
    </div><!-- /.modal-Dialog-Footer -->
  </div>
</div>
