import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { UiModule } from 'Content/script/modules/ui.module';
import { SafePipeModule } from 'Content/script/modules/safe.module';
import { SharedModule } from './shared.module';
import { AweSomeModule } from 'Content/script/modules/awesome.module';

import { HeaderMenuComponent } from 'Content/script/components/layout/header/header.component';
import { FakeHeaderComponent } from 'Content/script/components/layout/fake_header/fake_header.component';
import { FooterMenuComponent } from 'Content/script/components/layout/footer/footer.component';

@NgModule({
    imports: [

        CommonModule,
        RouterModule,
        FormsModule,
        BrowserAnimationsModule,
        UiModule,
        SafePipeModule,
        SharedModule,
        AweSomeModule
    ],
    declarations: [
        HeaderMenuComponent,
        FakeHeaderComponent,
        FooterMenuComponent,
    ],
    exports: [
        HeaderMenuComponent,
        FakeHeaderComponent,
        FooterMenuComponent,
        BrowserAnimationsModule,
        UiModule,
        SafePipeModule,
        SharedModule
    ]
})

export class LayoutModule { }
