export class NotifyService {
    public notifyMessageViewModel: NotifyMessageViewModel;

    private notifyId: number;

    private setTimeOutId: NodeJS.Timer;

    private static instance: NotifyService;

    public constructor() {
        this.notifyMessageViewModel = new NotifyMessageViewModel();
        this.notifyId = 0
    }

    /**
     * PC・SP共通で使うため、シングルトンにする.
     * @returns NotifyService
     */
    public static getInstance(): NotifyService {
        if (!NotifyService.instance) {
            NotifyService.instance = new NotifyService();
        }
        return NotifyService.instance;
    }

    /**
     * 表示ページでのメッセージを追加
     */
    private AddNotifyMessage(notifyMessage: NotifyMessage): void {
        // Infoとエラーでキューをわける
        if (notifyMessage.notifyType == NotifyType.Info) {
            this.notifyMessageViewModel.notifyInfoMessageList.push(notifyMessage);
        }
        else if (notifyMessage.notifyType == NotifyType.Error) {
            this.notifyMessageViewModel.notifyErrorMessageList.push(notifyMessage);
        }
    }

    /**
     * 表示ページでのメッセージを追加(複数)
     */
    public AddNotifyMessages(notifyMessages: NotifyMessage[]): void {
        this.AllRemoveNotifyMessage();
        for (var notifyMessage of notifyMessages) {
            this.AddNotifyMessage(notifyMessage);
        }
        window.scrollTo(0, 0);
    }

    /**
     * 表示ページでのメッセージを追加(複数、スクロールなし)
     */
    public AddNotifyMessagesWithoutScroll(notifyMessages: NotifyMessage[]): void {
        this.AllRemoveNotifyMessage();
        for (var notifyMessage of notifyMessages) {
            this.AddNotifyMessage(notifyMessage);
        }
    }

    /**
     * ページ遷移用のメッセージを追加
     */
    public AddLazyNotifyMessage(notifyMessage: NotifyMessage): void {
        this.notifyMessageViewModel.lazyNotifyMessageList.push(notifyMessage);
    }

    /**
     * 全通知データの初期化
     */
    public AllRemoveNotifyMessage(): void {
        this.notifyMessageViewModel.notifyInfoMessageList.splice(0);
        //this.notifyMessageViewModel.notifyInfoMessageList = new Array<NotifyMessage>();
        this.notifyMessageViewModel.notifyErrorMessageList.splice(0);
        //this.notifyMessageViewModel.notifyErrorMessageList = new Array<NotifyMessage>();
    }

    /**
     * 指定したnotifyIdのInfoを削除
     */
    public RemoveInfoNotifyMessage(notifyId: number): void {
        var index: number = this.notifyMessageViewModel.notifyInfoMessageList.findIndex((notifyMessage: NotifyMessage) => {
            return notifyMessage.notifyId == notifyId
        });
        if (index != -1) {
            this.notifyMessageViewModel.notifyInfoMessageList.splice(index, 1);
        }
    }

    /**
     * 指定したnotifyIdのErrorを削除
     */
    public RemoveErrorNotifyMessage(notifyId: number): void {
        var index: number = this.notifyMessageViewModel.notifyErrorMessageList.findIndex((notifyMessage: NotifyMessage) => {
            return notifyMessage.notifyId == notifyId
        });
        if (index != -1) {
            this.notifyMessageViewModel.notifyErrorMessageList.splice(index, 1);
        }
    }

    /**
     * ページ遷移時にデータのリストを入れ替える
     * ページを遷移した際にNotifyMessageViewModel.notifyMessageListのデータを全削除を行い
     * NotifyMessageViewModel.lazyNotifyMessageListのデータに格納する
     */
    public LazyNotifyMessageMovement(): void {
        // 全データを取り出す
        this.AllRemoveNotifyMessage();

        // 全データを取り出す
        for (var index: number = 0; index < this.notifyMessageViewModel.lazyNotifyMessageList.length; index++) {
            var lazyNotifyMessage: NotifyMessage = this.notifyMessageViewModel.lazyNotifyMessageList[index];

            // Infoとエラーでキューをわける
            if (lazyNotifyMessage.notifyType === NotifyType.Info) {
                this.notifyMessageViewModel.notifyInfoMessageList.push(lazyNotifyMessage);
            }
            else if (lazyNotifyMessage.notifyType === NotifyType.Error) {
                this.notifyMessageViewModel.notifyErrorMessageList.push(lazyNotifyMessage);
            }
        }

        //this.notifyMessageViewModel.lazyNotifyMessageList = new Array<NotifyMessage>();
        this.notifyMessageViewModel.lazyNotifyMessageList.splice(0);
    }

    public GetNotifyId(): number {
        return this.notifyId += 1;
    }

    public changeShowInfo(notifyId: number): void {
        var index: number = this.notifyMessageViewModel.notifyInfoMessageList.findIndex((notifyMessage: NotifyMessage) => {
            return notifyMessage.notifyId == notifyId
        });
        if (index != -1) {
            this.notifyMessageViewModel.notifyInfoMessageList[index].isShowNotifySubMessages = !this.notifyMessageViewModel.notifyInfoMessageList[index].isShowNotifySubMessages;
        }
    }

    public changeShowError(notifyId: number): void {
        var index: number = this.notifyMessageViewModel.notifyErrorMessageList.findIndex((notifyMessage: NotifyMessage) => {
            return notifyMessage.notifyId == notifyId
        });
        if (index != -1) {
            this.notifyMessageViewModel.notifyErrorMessageList[index].isShowNotifySubMessages = !this.notifyMessageViewModel.notifyErrorMessageList[index].isShowNotifySubMessages;
        }
    }
}

export enum NotifyType {
    Info = 0,
    Error = 1
}

export class NotifyMessageViewModel {
    public notifyInfoMessageList: Array<NotifyMessage>;
    public notifyErrorMessageList: Array<NotifyMessage>;
    public lazyNotifyMessageList: Array<NotifyMessage>;

    public constructor() {
        this.notifyInfoMessageList = new Array<NotifyMessage>();
        this.notifyErrorMessageList = new Array<NotifyMessage>();
        this.lazyNotifyMessageList = new Array<NotifyMessage>();
    }
}

export class MessageConst {
    public static readonly CREDIT_CARD_HAS_EXPIRED_NOTIFY: string = '登録済みのクレジットカードの有効期限が切れています。<br>クレジットカードの情報を更新してお手続きください。';
}

export class NotifyMessage extends MessageConst {
    public notifyId: number;

    public notifyType: NotifyType

    public notifyMessage: string;

    public isShowNotifySubMessages: boolean;

    public notifySubMessages: Array<string>;

    public constructor(notifyId: number, notifyType: NotifyType, notifyMessage: string, notifySubMessages: Array<string> = new Array<string>()) {
        super();  
        this.notifyId = notifyId;
        this.notifyType = notifyType;
        this.notifyMessage = notifyMessage;
        this.isShowNotifySubMessages = false;
        this.notifySubMessages = notifySubMessages;
    }
}
