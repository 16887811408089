import { Component, ViewChild } from '@angular/core';
import { ServiceContainer } from 'Content/script/service/service_container';
import { NotifyMessage, NotifyType } from 'Content/script/service/notify_service';
import { GmoPointModalViewModel, GmoPointModalComponent } from 'Content/script/components/share/gmo_point_modal/gmo_point_modal.component';
import { StringUtil } from 'Content/script/libs/utils/string.util';

@Component({
    selector: 'payment-display',
    templateUrl: './payment_display.html'
})

export class PaymentDisplayComponent {
    public viewModel: PaymentDisplayViewModel = new PaymentDisplayViewModel();

    @ViewChild(GmoPointModalComponent, { static: true })
    private _gmoPointModalComponent: GmoPointModalComponent;
    
    public init(serviceContainer: ServiceContainer, usePointChangedCallback: (x: number) => void): void {
        this.viewModel.init(this._gmoPointModalComponent.viewModel, serviceContainer, usePointChangedCallback);
    }

    public update(paymentInfo: PaymentDisplayInfo) {
        this.viewModel.update(paymentInfo);
    }
}

export class PaymentDisplayViewModel {
    private _serviceContainer: ServiceContainer;
    private _gmoPointModalViewModel: GmoPointModalViewModel;
    private _paymentInfo: PaymentDisplayInfo = new PaymentDisplayInfo("0", "0", "0", false, "0");
    private _isUpdateEvenOnce: boolean = false;
    private _totalPrice: string = "0";
    private _usePointChangedCallback: (x: number) => void = () => {}
    public isLoading: boolean = false;
    public isModalOpening: boolean = false;
    public usePoint: string = "0";

    public constructor() {
        this._adjustPrice(StringUtil.StringWithCommaToNumber(this.usePoint));
    }

    public get isUsablePoint(): boolean {
        return this._paymentInfo.isUsablePoint;
    }

    public get hasPoint(): boolean {
        return this._paymentInfo.hasPoint;
    }

    public get isExistGrantPoint(): boolean {
        return this._paymentInfo.isExistGrantPoint;
    }

    public get isGmoIdCollaborated(): boolean {
        return this._paymentInfo.isGmoIdCollaborated;
    }

    public get totalGrantPoint(): string {
        return this._paymentInfo.totalGrantPoint;
    }

    /**
     * 請求金額 一度も更新されてないときはblank
     * @returns {} 
     */
    public get totalPrice(): string {
        return this._isUpdateEvenOnce ? this._totalPrice : "";
    }

    public init(gmoPointModalViewModel: GmoPointModalViewModel, serviceContainer: ServiceContainer, usePointChangedCallback: (x: number) => void): void {
        this._serviceContainer = serviceContainer;
        this._usePointChangedCallback = usePointChangedCallback;
        this._gmoPointModalViewModel = gmoPointModalViewModel;
        this._gmoPointModalViewModel.setCallBack(this._onModalCloseButtonClicked, this._onModalChangeUsePointButtonClicked);
        this._gmoPointModalViewModel.setPrice(this._paymentInfo.gmoPointBalance, this._paymentInfo.totalProductPrice);
    }

    public update(paymentInfo: PaymentDisplayInfo) {
        this._isUpdateEvenOnce = true;
        this._paymentInfo = paymentInfo;
        this._adjustPrice(StringUtil.StringWithCommaToNumber(this.usePoint));
        this._gmoPointModalViewModel.setPrice(this._paymentInfo.gmoPointBalance, this._paymentInfo.totalProductPrice);
    }

    public onChangeButtonClicked(): void {
        if (this._paymentInfo.isFree) {
            const notify: NotifyMessage = new NotifyMessage(this._serviceContainer.NotifyService.GetNotifyId(), NotifyType.Error, '今回請求料金が発生していないため、GMOポイントの設定はできません。');
            this._serviceContainer.NotifyService.AddNotifyMessages([notify]);
        } else {
            this.isModalOpening = true;
        }
    }

    private _closeModal(): void {
        this.isModalOpening = false;
    }

    private _onModalCloseButtonClicked = () => {
        this._closeModal();
    }

    private _onModalChangeUsePointButtonClicked = (point: number) => {
        this._closeModal();
        this._adjustPrice(point);
        this._usePointChangedCallback(point);
    }

    private _adjustPrice(usePoint: number): void {
        this.usePoint = usePoint.toString();

        const totalProductPrice = StringUtil.StringWithCommaToNumber(this._paymentInfo.totalProductPrice);
        const totalPrice = StringUtil.StringWithCommaToNumber(this._paymentInfo.totalPrice);
        if (usePoint >= totalProductPrice) {
            this._totalPrice = "0";
        } else {
            this._totalPrice = StringUtil.NumberToStringWithComma(totalPrice - usePoint);
        }
    }
}

export class PaymentDisplayInfo {
    constructor(
        public totalPrice: string,
        public totalProductPrice: string,
        public totalGrantPoint: string,
        public isGmoIdCollaborated: boolean,
        public gmoPointBalance: string,
    ) {
    }

    public get isExistGrantPoint(): boolean {
        return this.totalGrantPoint !== "0";
    }
    /**
     * ポイントを利用できるか
     */
    public isUsablePoint: boolean;

    public get hasPoint(): boolean {
        return this.gmoPointBalance !== "0";
    }

    public get isFree(): boolean {
        return this.totalPrice === "0";
    }
}
