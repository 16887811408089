import { Directive, ElementRef, Input, HostListener } from '@angular/core';

@Directive({
    selector: '[show-category]'
})
export class SpShowCategoryDirective {
    private _el: HTMLElement;

    private targetElement: HTMLElement;

    private isScroll: boolean = false;

    constructor(private _elementRef: ElementRef) {
        this._el = _elementRef.nativeElement;
    }

    @Input('target')
    public target: string;

    @HostListener('touchend', ['$event'])
    public onTouchEnd(event: Event) {
        if (this.isScroll) {
            return;
        }
       
        if (!this.targetElement) {
            this.targetElement = document.querySelector(this.target) as HTMLElement;
        }
        if (this._el.classList.contains('is-active')) {
            this.targetElement.classList.remove('is-active');
            this._el.classList.remove('is-active');
            this._el.querySelector('.icon').classList.remove('icon-Minus');
            this._el.querySelector('.icon').classList.add('icon-Plus2');
        } else {
            this.targetElement.classList.add('is-active');
            this._el.classList.add('is-active');
            this._el.querySelector('.icon').classList.remove('icon-Plus2');
            this._el.querySelector('.icon').classList.add('icon-Minus');
        }
    }

    @HostListener('touchmove', ['$event'])
    public onScroll(event) {
        this.isScroll = true;
    }
    @HostListener('touchstart', ['$event'])
    public onTouchStart(event) {
        this.isScroll = false;
    }
}
