import { Directive, ElementRef, OnInit } from '@angular/core';

@Directive({
    selector: '[appAutofocus]'
})
export class AutofocusDirective implements OnInit {
    private _el: HTMLElement;

    constructor(private elementRef: ElementRef) {
        this._el = elementRef.nativeElement;
    }

    ngOnInit() {
        this._el.focus();
    }
}