import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'icon-origin',
    template: `
<button type="button" class="btn-IconOrigin" (click)='onClick()'><i class="icon icon-Clear"></i></button>
`
})

export class IconOriginComponent {
    @Output() clickEvent = new EventEmitter();

    public onClick() {
        this.clickEvent.emit();
    }
}