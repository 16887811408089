import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
    selector: 'domain-detail-modal-confirm',
    templateUrl: './domain_detail_modal_confirm.html'
})

export class DomainDetailModalConfirmComponent {
    @Input()
    public isShow: boolean = true;
    @Input()
    public isFreeDomainForever: boolean = false;

    @Output() onCloseModal = new EventEmitter<any>();
    @Output() onCloseModalWithoutShowAuthCode = new EventEmitter<any>();
    @Output() onClickConfirmShowAuthcode = new EventEmitter<any>();

    public onClickClose(): void {
        this.isShow = false;
        this.onCloseModalWithoutShowAuthCode.emit();
    }

    public onClickCloseAuthCode(): void {
        this.isShow = false;
        this.onCloseModal.emit();
    }

    public onClickOutsideCloseModal(event: Event) {
        if (event.currentTarget === event.target) {
            this.isShow = false;
            this.onCloseModalWithoutShowAuthCode.emit();
        }
    }

    public onClickShowAuthcode() {
        this.isShow = false;
        this.onClickConfirmShowAuthcode.emit();
    }
}
