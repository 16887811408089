import { createAction, props } from '@ngrx/store';
import { RefererButtonState } from '../states/referer-button';

export const setRefererButtonName = createAction(
    '[RefererButton] Set Name',
    props<RefererButtonState>()
);

export const resetRefererButtonName = createAction(
    '[RefererButton] Clear Name'
);
