<ng-container>
    <!-- 検索条件ボタン -->
    <button type="button" class="btn is-Small gtmclick" data-gtmvalue="s_conditions_search" dropdown align="left" target="#dropdownConditionsSearch" close="#search">検索条件<i class="icon icon-DropDown"></i></button>

    <!-- 検索ドロップダウン -->
    <form (ngSubmit)="onDecideSearchCondition(searchDomain)" #searchDomain="ngForm" novalidate>
        <div class="dropdown is-Large" id="dropdownConditionsSearch">
            <div class="dropdown-Main pt10 pb10">
                <table class="tbl-Dotted is-ValignTop">
                    <colgroup>
                        <col width="92">
                    </colgroup>
                    <tbody>
                        <tr>
                            <th class="is-ValignTop"><strong>ドメイン名</strong></th>
                            <td>
                                <textarea name="domainNames" class="form-Textarea" [class.form-Error]="(domainNames.errors && (domainNames.dirty || domainNames.touched))"
                                          [(ngModel)]="searchCondition.domainNames"
                                          #domainNames="ngModel" pattern="{{rDomainPattern}}" maxRowBytes="255" maxRowsCount="100"></textarea>
                                <label *ngIf="domainNames.errors != null && domainNames.errors.pattern" class="form-Error">
                                    入力形式を確認してください
                                </label>
                                <label *ngIf="domainNames.errors != null && domainNames.errors.maxRowBytes" class="form-Error">
                                    1ドメインにつき最大255文字までです。
                                </label>                                <label *ngIf="domainNames.errors != null && domainNames.errors.maxRowsCount" class="form-Error">
                                    一度に検索できる上限を超えています。
                                </label>
                            </td>
                        </tr>
                        <tr>
                            <th><strong>ステータス</strong></th>
                            <td>
                                <select name="status" (change)="onChangeSearchDomainGroup($event)">
                                    <option [selected]="searchCondition.status === ''" value="">ステータスを選択</option>
                                    <option *ngFor="let statusSelectItem of searchCondition.statusSelectItems" [selected]="searchCondition.status === statusSelectItem.value" value="{{statusSelectItem.value}}">{{statusSelectItem.text}}</option>
                                </select>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="dropdown-Footer text-Center">
                <ul class="list-Btn is-Right mb0">
                    <li><button type="button" class="btn is-Auto is-Small" (click)="onClickSearchConditionClear()">クリア</button></li>
                    <li>
                        <button type="submit" id="search" class="btn is-Primary is-Auto is-Small">上記の条件で検索</button>
                    </li>
                </ul>
            </div>
        </div>
    </form>
</ng-container>