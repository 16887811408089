import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { ErrorSimpleComponent } from 'Content/script/components/awesome/error/errorSimple.component';
import { FormErrorLabelsComponent } from 'Content/script/components/awesome/form/errorLabels.component';
import { FormErrorLabelComponent } from 'Content/script/components/awesome/form/errorLabel.component';
import { LoadingComponent } from 'Content/script/components/awesome/loading/loading.component';
import { PrimaryButtonComponent } from 'Content/script/components/awesome/button/primaryButton.component';
import { MessageInformationComponent } from 'Content/script/components/awesome/message/MessageInformation.component';
import { TwoStepVerificationModalComponent } from 'Content/script/components/awesome/two-step-verification/twoStepVerificationModal.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule
    ],
    declarations: [
        FormErrorLabelsComponent,
        FormErrorLabelComponent,
        LoadingComponent,
        PrimaryButtonComponent,
        ErrorSimpleComponent,
        MessageInformationComponent,
        TwoStepVerificationModalComponent
    ],
    exports: [
        FormErrorLabelsComponent,
        FormErrorLabelComponent,
        LoadingComponent,
        PrimaryButtonComponent,
        ErrorSimpleComponent,
        MessageInformationComponent,
        TwoStepVerificationModalComponent
    ]
})
export class AweSomeModule { }
