import { Directive, forwardRef, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';

const MUST_MATCH_VALIDATOR: any = {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => MustMatchValidator),
    multi: true
};

@Directive({
    selector: "[mustMatch][formControlName],[mustMatch][formControl],[mustMatch][ngModel]",
    providers: [MUST_MATCH_VALIDATOR]
})
export class MustMatchValidator implements Validator {
    @Input() mustMatch: string;

    private validator: ValidatorFn;

    validate(c: AbstractControl): { [key: string]: any } {
        this.validator = createMustMatchValidator(this.mustMatch);
        return this.validator(c);
    }
}

export function createMustMatchValidator(target: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const value = control.value;
        return value == target ? null : { mustMatch: true };
    }
}
