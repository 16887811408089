import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'hdn-lev3',
    template: `
<h2 class="hdn-Lev3 with-border mt2 mb0">
    <ng-content></ng-content>
</h2>
`
})

export class HdnLev3Component {
}