// CP情報を保存する際のKey
export const LOGIN_CAMPAIGN_PAGE_INFO_KEY: string = "GT#84736_LOGIN_CAMPAIGN_PAGE_INFO_KEY";
// CPにエントリー済みであるか保存する際のKey
export const LOGIN_CAMPAIGN_ENTRY_KEY: string = "GT#84736_LOGIN_CAMPAIGN_ENTRY_KEY";

// エントリー画面情報 locationID
export const LOGIN_CAMPAIGN_PAGE_INFO_LOCATION_ID_LIST: string = "8";

export enum PROMOTION_TYPE {
    RS,
    DC,
    LINE,
    NONE
}

export const CAMPAIGN_HIDDEN_LIST: string[] = [
    "/login",
    "/sp/login",
    "/logout",
    "/sp/logout",
    "/campaign/present",
    "/sp/campaign/present",
    "/error",
    "/Error",
    "/error/server",
    "/error/not_found",
    "/sp/quick",
    "/account/unsubscribe"
];
