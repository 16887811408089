/*\
 |*|
 |*|  :: cookies.js ::
 |*|
 |*|  A complete cookies reader/writer framework with full unicode support.
 |*|
 |*|  https://developer.mozilla.org/en-US/docs/DOM/document.cookie
 |*|
 |*|  Syntaxes:
 |*|
 |*|  * docCookies.setItem(name, value[, end[, path[, domain]]])
 |*|  * docCookies.getItem(name)
 |*|  * docCookies.removeItem(name[, path])
 |*|  * docCookies.hasItem(name)
 |*|  * docCookies.keys()
 |*|
 \*/

export class docCookies {
    public static getItem(sKey) {
        if (!sKey || !this.hasItem(sKey)) { return null; }
        return decodeURI(document.cookie.replace(new RegExp("(?:^|.*;\\s*)" + encodeURI(sKey).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=\\s*((?:[^;](?!;))*[^;]?).*"), "$1"));
    }

    public static setItem(sKey, sValue, vEnd, sPath = void (0), sDomain = void (0)) {
        if (!sKey || /^(?:expires|max\-age|path|domain|secure)$/i.test(sKey)) { return; }
        var sExpires = "";
        if (vEnd) {
            switch (vEnd.constructor) {
                case Number:
                    sExpires = vEnd === Infinity ? "; expires=Tue, 19 Jan 2038 03:14:07 GMT" : "; max-age=" + vEnd;
                    break;
                case String:
                    sExpires = "; expires=" + vEnd;
                    break;
                case Date:
                    sExpires = "; expires=" + vEnd.toGMTString();
                    break;
            }
        }
        document.cookie = encodeURI(sKey) + "=" + encodeURI(sValue) + sExpires + (sDomain ? "; domain=" + sDomain : "") + (sPath ? "; path=" + sPath : "") + ("; secure");
    }

    public static removeItem(sKey, sPath, sDomain = void (0)) {
        if (!sKey || !this.hasItem(sKey)) { return; }

        document.cookie = encodeURI(sKey) + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT" + (sPath ? "; path=" + sPath : "") + (sDomain ? "; domain=" + sDomain : "");
        //document.cookie = encodeURI(sKey) + "=; max-age=0";
        //document.cookie = encodeURI(sKey) + "=;";
    }

    public static hasItem(sKey) {
        return (new RegExp("(?:^|;\\s*)" + encodeURI(sKey).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=")).test(document.cookie);
    }

    public static keys/* optional method: you can safely remove it! */() {
        var aKeys = document.cookie.replace(/((?:^|\s*;)[^\=]+)(?=;|$)|^\s*|\s*(?:\=[^;]*)?(?:\1|$)/g, "").split(/\s*(?:\=[^;]*)?;\s*/);
        for (var nIdx = 0; nIdx < aKeys.length; nIdx++) { aKeys[nIdx] = decodeURI(aKeys[nIdx]); }
        return aKeys;
    }
}

export class FutureDate {
    static getNow(): Date {
        return new Date();
    }

    // n 秒後
    static afterSeconds(seconds: number): Date {
        var d: Date = FutureDate.getNow();
        d.setTime(d.getTime() + seconds * 1000);
        return d;
    }

    // n 分後
    static afterMinutes(minutes: number): Date {
        var d: Date = FutureDate.getNow();
        d.setTime(d.getTime() + minutes * 60 * 1000);
        return d;
    }

    // n 時間後
    static afterHours(hours: number): Date {
        var d: Date = FutureDate.getNow();
        d.setTime(d.getTime() + hours * 60 * 60 * 1000);
        return d;
    }

    // n 日後
    static afterDays(days: number): Date {
        var d: Date = FutureDate.getNow();
        d.setTime(d.getTime() + days * 24 * 60 * 60 * 1000);
        return d;
    }
}
