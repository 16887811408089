<ng-container *ngIf="viewModel.isDisableView === false" >
    <!-- 検索条件ボタン -->
    <button type="button" class="btn is-Small gtmclick" data-gtmvalue="s_conditions_search" dropdown align="left" target="#dropdownConditionsSearch" close="#search" hide="#additionSearch">検索条件<i class="icon icon-DropDown"></i></button>
    <!-- 検索ドロップダウン -->
    <form (ngSubmit)="viewModel.onSubmitSearchConditionSearch(searchDomain)" #searchDomain="ngForm" novalidate>
        <div class="dropdown is-Large" id="dropdownConditionsSearch">
            <div class="dropdown-Main pt10 pb10">
                <table class="tbl-Dotted is-ValignTop">
                    <colgroup>
                        <col width="108">
                    </colgroup>
                    <tbody>
                        <tr *ngIf="viewModel.searchConditionConfig.isEnableBulkOrderId">
                            <th class="is-ValignTop"><strong>申請番号</strong></th>
                            <td>
                                <input type="text" name="bulkOrderId" class="form-Text is-Small" [class.form-Error]="(bulkOrderId.errors && (bulkOrderId.dirty || bulkOrderId.touched))"
                                          [(ngModel)]="viewModel.searchCondition.bulkOrderId"
                                          #bulkOrderId="ngModel" pattern="{{viewModel.rNumberPattern}}">
                                <label *ngIf="bulkOrderId.errors != null && bulkOrderId.errors.pattern" class="form-Error">
                                    入力形式を確認してください
                                </label>
                            </td>
                        </tr>
                        <tr *ngIf="viewModel.searchConditionConfig.isEnableStatus">
                            <th><strong>申請状況	</strong></th>
                            <td>
                                <select name="status" (change)="viewModel.onChangeSearchStatus($event)">
                                    <option *ngFor="let statusSelectItem of viewModel.searchCondition.statusSelectItems" [selected]="viewModel.searchCondition.status === statusSelectItem.key" value="{{statusSelectItem.key}}">{{statusSelectItem.value}}</option>
                                </select>
                            </td>
                        </tr>
                        <tr *ngIf="viewModel.searchConditionConfig.isEnablePaymentTypeId">
                            <th><strong>決済種別	</strong></th>
                            <td>
                                <select name="paymentTypeId" (change)="viewModel.onChangeSearchPaymentType($event)">
                                  <option *ngFor="let paymentTypeIdSelectItem of viewModel.searchCondition.paymentTypeIdSelectItems" [selected]="viewModel.searchCondition.paymentTypeId === paymentTypeIdSelectItem.key" value="{{paymentTypeIdSelectItem.key}}">{{paymentTypeIdSelectItem.value}}</option>
                                </select>
                            </td>
                        </tr>
                        <tr *ngIf="viewModel.searchConditionConfig.isEnableCreatedDate">
                            <th><strong>登録日</strong></th>
                            <td>
                                <div pikaday class="form-TextWithIcon">
                                    <input type="text" class="form-Text is-Small" [class.form-Error]="(createdDateFrom.errors && (createdDateFrom.dirty || createdDateFrom.touched))"
                                           name="createdDateFrom" placeholder="指定なし" [(ngModel)]="viewModel.searchCondition.createdDateFrom" (change)="viewModel.onChangeCreatedDateFrom($event)"
                                           #createdDateFrom="ngModel" date>
                                    <button type="button" class="form-TextWithIcon-Btn js-datepicker"><i class="icon icon-Calendar" aria-label="カレンダー"></i></button>
                                </div> から
                                <div pikaday class="form-TextWithIcon">
                                    <input type="text" class="form-Text is-Small" [class.form-Error]="(createdDateTo.errors && (createdDateTo.dirty || createdDateTo.touched))"
                                           name="createdDateTo" placeholder="指定なし" [(ngModel)]="viewModel.searchCondition.createdDateTo" (change)="viewModel.onChangeCreatedDateTo($event)"
                                           #createdDateTo="ngModel" date>
                                    <button type="button" class="form-TextWithIcon-Btn js-datepicker"><i class="icon icon-Calendar" aria-label="カレンダー"></i></button>
                                </div> まで
                                <label *ngIf="createdDateFrom.errors != null && createdDateFrom.errors.date" class="form-Error">
                                    入力形式を確認してください
                                </label>
                                <label *ngIf="createdDateTo.errors != null && createdDateTo.errors.date" class="form-Error">
                                    入力形式を確認してください
                                </label>
                            </td>
                        </tr>
                        <tr *ngIf="viewModel.searchConditionConfig.isEnableCompleteDate">
                            <th><strong>登録完了日</strong></th>
                            <td>
                                <div pikaday class="form-TextWithIcon">
                                    <input type="text" class="form-Text is-Small" [class.form-Error]="(completeDateFrom.errors && (completeDateFrom.dirty || completeDateFrom.touched))"
                                           name="completeDateFrom" placeholder="指定なし" [(ngModel)]="viewModel.searchCondition.completeDateFrom" (change)="viewModel.onChangecompleteDateFrom($event)"
                                           #completeDateFrom="ngModel" date [disabled]="viewModel.isDisablecompleteDate">
                                    <button type="button" class="form-TextWithIcon-Btn js-datepicker" [disabled]="viewModel.isDisablecompleteDate"><i class="icon icon-Calendar" aria-label="カレンダー"></i></button>
                                </div> から
                                <div pikaday class="form-TextWithIcon">
                                    <input type="text" class="form-Text is-Small" [class.form-Error]="(completeDateTo.errors && (completeDateTo.dirty || completeDateTo.touched))"
                                           name="completeDateTo" placeholder="指定なし" [(ngModel)]="viewModel.searchCondition.completeDateTo" (change)="viewModel.onChangecompleteDateTo($event)"
                                           #completeDateTo="ngModel" date [disabled]="viewModel.isDisablecompleteDate">
                                    <button type="button" class="form-TextWithIcon-Btn js-datepicker" [disabled]="viewModel.isDisablecompleteDate"><i class="icon icon-Calendar" aria-label="カレンダー"></i></button>
                                </div> まで
                                <label *ngIf="completeDateFrom.errors != null && completeDateFrom.errors.date" class="form-Error">
                                    入力形式を確認してください
                                </label>
                                <label *ngIf="completeDateTo.errors != null && completeDateTo.errors.date" class="form-Error">
                                    入力形式を確認してください
                                </label>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="dropdown-Footer text-Center">
                <ul class="list-Btn is-Right mb0">
                    <li><button type="button" class="btn is-Auto is-Small gtmclick" data-gtmvalue="s_clear" (click)="viewModel.onClickSearchConditionClear()">クリア</button></li>
                    <li><button type="submit" class="btn is-Primary is-Auto is-Small" id="search">検索</button></li>
                </ul>
            </div>
        </div>
    </form>
</ng-container>
