import { Directive, ElementRef, Input, OnChanges } from '@angular/core';

@Directive({
    selector: '[floatingTooltip]'
})
export class FloatingTooltipDirective implements OnChanges {
    private _el: HTMLElement;

    private parentElement: HTMLElement;

    @Input('parent')
    public parent: string;

    @Input('distanceToMargin')
    public distanceToMargin: string;

    constructor(private _elementRef: ElementRef) {
        this._el = _elementRef.nativeElement;

    }

    public ngOnChanges(): void {
        this.adjustTooltipPosition();
    }

    public adjustTooltipPosition(): void {
        this.parentElement = document.querySelector(this.parent) as HTMLElement;
        if (!this._el || !this.parentElement) {
            return;
        }

        let top = 0;
        let right = 0;
        if (this.parentElement.tagName.toLowerCase() === 'a') {
            top = this.parentElement.offsetTop + this.parentElement.offsetHeight / 2;
            right = - (this._el.offsetWidth - Number(this.distanceToMargin));

        } else {
            var spanInParentElement = this.parentElement.querySelector('span.text-Bold') as HTMLElement;
            if (!spanInParentElement) return;

            const parentTable = this.parentElement?.offsetParent as HTMLElement;
            const halfLozenge = 8;
            const totalWidthOfTooltip = this._el.offsetWidth + halfLozenge;
            const distanceToTextSpan = 5;
            top = this.parentElement?.offsetTop + parentTable?.offsetTop + this.parentElement.offsetHeight / 2;
            right = this.parentElement?.offsetWidth - spanInParentElement?.offsetWidth - totalWidthOfTooltip - distanceToTextSpan;
        }
        this._el.style.right = right + 'px';
        this._el.style.top = top + 'px';
    }
}
