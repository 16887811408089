/// <reference path='../../../definitions/window.d.ts' />

import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ServiceContainer } from 'Content/script/service/service_container';

/**
 * ホスティング 支払方法 コンポーネント
 */
@Component({
    selector: 'hosting-payment',
    templateUrl: './hosting_payment.html'
})

export class HostingPaymentComponent {
    public viewModel: HostingPaymentViewModel;

    public constructor(protected router: Router, protected route: ActivatedRoute, protected sanitizer: DomSanitizer, protected serviceContainer: ServiceContainer) {
        const queueId = serviceContainer.HubsService.multipleSendManager.GetComponentId();
        this.viewModel = new HostingPaymentViewModel(queueId, this.router, this.sanitizer, this.serviceContainer);
    }
}

export class HostingPaymentViewModel {
    // 親のローディングを設定する
    private _loadingCallBack: (isLoading: boolean) => void;

    public hostingBasePayment: HostingBasePayment;
    protected hostingInitialPayment: HostingInitialPaymentHosting;
    protected hostingFuturePayment: HostingFuturePayment;

    protected isRegisteredDomainCreditCard: boolean;
    protected isRegisteredHostingCreditCard: boolean;

    public isBasePaymentWindow: boolean;
    public isFuturePaymentWindow: boolean;
    public isInitialPaymentWindow: boolean;

    public constructor(private queueId: number, private router: Router, private sanitizer: DomSanitizer, private serviceContainer: ServiceContainer) {
        this._loadingCallBack = null;

        this.hostingBasePayment = new HostingBasePayment();
        this.hostingInitialPayment = new HostingInitialPaymentHosting();
        this.hostingFuturePayment = new HostingFuturePayment();

        this.isRegisteredDomainCreditCard = false;
        this.isRegisteredHostingCreditCard = false;

        this.isBasePaymentWindow = false;
        this.isFuturePaymentWindow = false;
        this.isInitialPaymentWindow = false;
    }

    /**
     * コールバック関数を設定
     * @param loadingCallBack
     */
    public setCallBack(loadingCallBack: (isLoading: boolean) => void): void {
        this._loadingCallBack = loadingCallBack;
    }

    /**
     * ローディング処理
     * @param isLoading
     */
    private _loading(isLoading: boolean): void {
        if (this._loadingCallBack) {
            this._loadingCallBack(isLoading);
        }
    }

    /**
     * 支払情報を読み込みます
     */
    public loadHostingPaymentInfo(type?: string, token?: string): void {
        switch (type) {
            //お名前新共用
            case "systemLinkage":
                this._getSystemLinkagePaymentInfo(token);
            default:
                this._getHostingPaymentInfo();
        }
    }

    /**
     * サービスの現在のお支払い方法情報を取得
     */
    private _getHostingPaymentInfo(): void {
        this._loading(true);

        this.serviceContainer.HubsService.multipleSendManager.Send(
            this.queueId,
            this.serviceContainer.HubsService.hubsManager.invoiceHubManager.InvoiceHostingPaymentDetail,
            null,
            (res: InvoiceHostingPaymentDetailResponseHubDto) => {
                //BasePaymentとinitialPaymentとfuturePayment表示判定
                this.isBasePaymentWindow = res.HasBasePayment;
                this.isInitialPaymentWindow = res.HasInitialPayment;
                this.isFuturePaymentWindow = res.HasFuturePayment;

                //basePayment(ベース支払い方法)
                this.hostingBasePayment.paymentType = res.BasePaymentMethod.PaymentType;
                this.hostingBasePayment.paymentTypeName = res.BasePaymentMethod.PaymentTypeName;
                this.hostingBasePayment.paymentTypeNameML = res.BasePaymentMethod.PaymentTypeNameML;
                this.hostingBasePayment.fee = res.BasePaymentMethod.Fee;
                this.hostingBasePayment.taxedFee = res.BasePaymentMethod.TaxedFee;

                this.hostingBasePayment.creditPeriod = res.BasePaymentMethod.CardInfo.CreditExpire;
                //this.hostingBasePayment.creditHolder = res.BasePaymentMethod.CardInfo.CreditHolder;
                this.hostingBasePayment.creditNumber = res.BasePaymentMethod.CardInfo.CreditNumber;
                this.hostingBasePayment.canUseCredit = res.BasePaymentMethod.CardInfo.CanUseCredit;
                if (this.hostingBasePayment.creditPeriod != null) {
                    this.isRegisteredHostingCreditCard = true;
                }
                this.hostingBasePayment.bankId = res.BasePaymentMethod.BankInfo.BankId;
                this.hostingBasePayment.bankName = res.BasePaymentMethod.BankInfo.BankName;
                this.hostingBasePayment.branchId = res.BasePaymentMethod.BankInfo.BranchId;
                this.hostingBasePayment.branchName = res.BasePaymentMethod.BankInfo.BranchName;
                this.hostingBasePayment.accountName = res.BasePaymentMethod.BankInfo.AccountName;
                this.hostingBasePayment.accountTypeName = res.BasePaymentMethod.BankInfo.AccountTypeName;
                this.hostingBasePayment.accountNumber = res.BasePaymentMethod.BankInfo.AccountNumber;
                this.hostingBasePayment.isBillFlg = res.BasePaymentMethod.BankInfo.IsBillFlg;
                this.hostingBasePayment.isBankInfoFlg = res.BasePaymentMethod.BankInfo.IsBankInfoFlg;

                if (this.hostingBasePayment.paymentType === 'credit') {
                    this.isRegisteredDomainCreditCard = true;
                }

                //initialPayment(初回暫定支払い方法)
                this.hostingInitialPayment.paymentType = res.InitialPaymentMethod.PaymentType;
                this.hostingInitialPayment.paymentTypeName = res.InitialPaymentMethod.PaymentTypeName;
                this.hostingInitialPayment.paymentTypeNameML = res.InitialPaymentMethod.PaymentTypeNameML;

                this.hostingInitialPayment.creditPeriod = res.InitialPaymentMethod.CardInfo.CreditExpire;
                //this.hostingInitialPayment.creditHolder = res.InitialPaymentMethod.CardInfo.CreditHolder;
                this.hostingInitialPayment.creditNumber = res.InitialPaymentMethod.CardInfo.CreditNumber;
                this.hostingInitialPayment.canUseCredit = res.InitialPaymentMethod.CardInfo.CanUseCredit;

                this.hostingInitialPayment.bankId = res.InitialPaymentMethod.BankInfo.BankId;
                this.hostingInitialPayment.bankName = res.InitialPaymentMethod.BankInfo.BankName;
                this.hostingInitialPayment.branchId = res.InitialPaymentMethod.BankInfo.BranchId;
                this.hostingInitialPayment.branchName = res.InitialPaymentMethod.BankInfo.BranchName;
                this.hostingInitialPayment.accountName = res.InitialPaymentMethod.BankInfo.AccountName;
                this.hostingInitialPayment.accountTypeName = res.InitialPaymentMethod.BankInfo.AccountTypeName;
                this.hostingInitialPayment.accountNumber = res.InitialPaymentMethod.BankInfo.AccountNumber;
                this.hostingInitialPayment.isBillFlg = res.InitialPaymentMethod.BankInfo.IsBillFlg;
                this.hostingInitialPayment.isBankInfoFlg = res.InitialPaymentMethod.BankInfo.IsBankInfoFlg;

                //futurePayment(変更後支払い方法)
                this.hostingFuturePayment.paymentType = res.FuturePaymentMethod.PaymentType;
                this.hostingFuturePayment.paymentTypeName = res.FuturePaymentMethod.PaymentTypeName;
                this.hostingFuturePayment.paymentTypeNameML = res.FuturePaymentMethod.PaymentTypeNameML;

                this.hostingFuturePayment.creditPeriod = res.FuturePaymentMethod.CardInfo.CreditExpire;
                //this.hostingFuturePayment.creditHolder = res.FuturePaymentMethod.CardInfo.CreditHolder;
                this.hostingFuturePayment.creditNumber = res.FuturePaymentMethod.CardInfo.CreditNumber;
                this.hostingFuturePayment.canUseCredit = res.FuturePaymentMethod.CardInfo.CanUseCredit;

                this.hostingFuturePayment.bankId = res.FuturePaymentMethod.BankInfo.BankId;
                this.hostingFuturePayment.bankName = res.FuturePaymentMethod.BankInfo.BankName;
                this.hostingFuturePayment.branchId = res.FuturePaymentMethod.BankInfo.BranchId;
                this.hostingFuturePayment.branchName = res.FuturePaymentMethod.BankInfo.BranchName;
                this.hostingFuturePayment.accountName = res.FuturePaymentMethod.BankInfo.AccountName;
                this.hostingFuturePayment.accountTypeName = res.FuturePaymentMethod.BankInfo.AccountTypeName;
                this.hostingFuturePayment.accountNumber = res.FuturePaymentMethod.BankInfo.AccountNumber;
                this.hostingFuturePayment.isBillFlg = res.FuturePaymentMethod.BankInfo.IsBillFlg;
                this.hostingFuturePayment.isBankInfoFlg = res.FuturePaymentMethod.BankInfo.IsBankInfoFlg;

                this.hostingFuturePayment.startDate = res.DisplayStartDate;

                this._loading(false);
            },
            (res: InvoiceHostingPaymentDetailResponseHubDto) => {
                this._loading(false);
            },
            null,
            null,
            null
        );
    }
    /**
     * 新共用　サービスの現在のお支払い方法情報を取得
     */
    private _getSystemLinkagePaymentInfo(token: string): void {
        this._loading(true);

        const request: SystemLinkageAddOptionPaymentDetailRequestHubDto = {
            Token: token
        }

        this.serviceContainer.HubsService.multipleSendManager.Send(
            this.queueId,
            this.serviceContainer.HubsService.hubsManager.systemLinkageHubManager.GetAddOptionPaymentDetail,
            request,
            (res: SystemLinkageOptionAddConfirmPaymentDetailResponseHubDto) => {
                //BasePaymentとinitialPaymentとfuturePayment表示判定
                this.isBasePaymentWindow = res.HasBasePayment;
                this.isInitialPaymentWindow = res.HasInitialPayment;
                this.isFuturePaymentWindow = res.HasFuturePayment;

                //basePayment(ベース支払い方法)
                this.hostingBasePayment.paymentType = res.BasePaymentMethod.PaymentType;
                this.hostingBasePayment.paymentTypeName = res.BasePaymentMethod.PaymentTypeName;
                this.hostingBasePayment.paymentTypeNameML = res.BasePaymentMethod.PaymentTypeNameML;
                this.hostingBasePayment.fee = res.BasePaymentMethod.Fee;
                this.hostingBasePayment.taxedFee = res.BasePaymentMethod.TaxedFee;

                this.hostingBasePayment.creditPeriod = res.BasePaymentMethod.CardInfo.CreditExpire;
                //this.hostingBasePayment.creditHolder = res.BasePaymentMethod.CardInfo.CreditHolder;
                this.hostingBasePayment.creditNumber = res.BasePaymentMethod.CardInfo.CreditNumber;
                this.hostingBasePayment.canUseCredit = res.BasePaymentMethod.CardInfo.CanUseCredit;
                if (this.hostingBasePayment.creditPeriod != null) {
                    this.isRegisteredHostingCreditCard = true;
                }
                this.hostingBasePayment.bankId = res.BasePaymentMethod.BankInfo.BankId;
                this.hostingBasePayment.bankName = res.BasePaymentMethod.BankInfo.BankName;
                this.hostingBasePayment.branchId = res.BasePaymentMethod.BankInfo.BranchId;
                this.hostingBasePayment.branchName = res.BasePaymentMethod.BankInfo.BranchName;
                this.hostingBasePayment.accountName = res.BasePaymentMethod.BankInfo.AccountName;
                this.hostingBasePayment.accountTypeName = res.BasePaymentMethod.BankInfo.AccountTypeName;
                this.hostingBasePayment.accountNumber = res.BasePaymentMethod.BankInfo.AccountNumber;
                this.hostingBasePayment.isBillFlg = res.BasePaymentMethod.BankInfo.IsBillFlg;
                this.hostingBasePayment.isBankInfoFlg = res.BasePaymentMethod.BankInfo.IsBankInfoFlg;

                if (this.hostingBasePayment.paymentType === 'credit') {
                    this.isRegisteredDomainCreditCard = true;
                }

                //initialPayment(初回暫定支払い方法)
                this.hostingInitialPayment.paymentType = res.InitialPaymentMethod.PaymentType;
                this.hostingInitialPayment.paymentTypeName = res.InitialPaymentMethod.PaymentTypeName;
                this.hostingInitialPayment.paymentTypeNameML = res.InitialPaymentMethod.PaymentTypeNameML;

                this.hostingInitialPayment.creditPeriod = res.InitialPaymentMethod.CardInfo.CreditExpire;
                //this.hostingInitialPayment.creditHolder = res.InitialPaymentMethod.CardInfo.CreditHolder;
                this.hostingInitialPayment.creditNumber = res.InitialPaymentMethod.CardInfo.CreditNumber;
                this.hostingInitialPayment.canUseCredit = res.InitialPaymentMethod.CardInfo.CanUseCredit;

                this.hostingInitialPayment.bankId = res.InitialPaymentMethod.BankInfo.BankId;
                this.hostingInitialPayment.bankName = res.InitialPaymentMethod.BankInfo.BankName;
                this.hostingInitialPayment.branchId = res.InitialPaymentMethod.BankInfo.BranchId;
                this.hostingInitialPayment.branchName = res.InitialPaymentMethod.BankInfo.BranchName;
                this.hostingInitialPayment.accountName = res.InitialPaymentMethod.BankInfo.AccountName;
                this.hostingInitialPayment.accountTypeName = res.InitialPaymentMethod.BankInfo.AccountTypeName;
                this.hostingInitialPayment.accountNumber = res.InitialPaymentMethod.BankInfo.AccountNumber;
                this.hostingInitialPayment.isBillFlg = res.InitialPaymentMethod.BankInfo.IsBillFlg;
                this.hostingInitialPayment.isBankInfoFlg = res.InitialPaymentMethod.BankInfo.IsBankInfoFlg;

                //futurePayment(変更後支払い方法)
                this.hostingFuturePayment.paymentType = res.FuturePaymentMethod.PaymentType;
                this.hostingFuturePayment.paymentTypeName = res.FuturePaymentMethod.PaymentTypeName;
                this.hostingFuturePayment.paymentTypeNameML = res.FuturePaymentMethod.PaymentTypeNameML;

                this.hostingFuturePayment.creditPeriod = res.FuturePaymentMethod.CardInfo.CreditExpire;
                //this.hostingFuturePayment.creditHolder = res.FuturePaymentMethod.CardInfo.CreditHolder;
                this.hostingFuturePayment.creditNumber = res.FuturePaymentMethod.CardInfo.CreditNumber;
                this.hostingFuturePayment.canUseCredit = res.FuturePaymentMethod.CardInfo.CanUseCredit;

                this.hostingFuturePayment.bankId = res.FuturePaymentMethod.BankInfo.BankId;
                this.hostingFuturePayment.bankName = res.FuturePaymentMethod.BankInfo.BankName;
                this.hostingFuturePayment.branchId = res.FuturePaymentMethod.BankInfo.BranchId;
                this.hostingFuturePayment.branchName = res.FuturePaymentMethod.BankInfo.BranchName;
                this.hostingFuturePayment.accountName = res.FuturePaymentMethod.BankInfo.AccountName;
                this.hostingFuturePayment.accountTypeName = res.FuturePaymentMethod.BankInfo.AccountTypeName;
                this.hostingFuturePayment.accountNumber = res.FuturePaymentMethod.BankInfo.AccountNumber;
                this.hostingFuturePayment.isBillFlg = res.FuturePaymentMethod.BankInfo.IsBillFlg;
                this.hostingFuturePayment.isBankInfoFlg = res.FuturePaymentMethod.BankInfo.IsBankInfoFlg;

                this.hostingFuturePayment.startDate = res.DisplayStartDate;

                this._loading(false);
            },
            (res: InvoiceHostingPaymentDetailResponseHubDto) => {
                this._loading(false);
            },
            null,
            null,
            null
        );
    }
}

//現在の支払い方法
class HostingBasePayment {
    //支払い方法共通
    public paymentType: string;
    public paymentTypeName: string;
    public paymentTypeNameML: string;
    public fee: string;
    public taxedFee: string;

    //クレカ表示用
    public creditPeriod: string;
    public creditHolder: string;
    public creditNumber: string;
    public canUseCredit: boolean;

    //口座振替用
    public bankId: string;
    public bankName: string;
    public branchId: string;
    public branchName: string;
    public accountName: string;
    public accountTypeName: string;
    public accountNumber: string;
    public isBillFlg: boolean;
    public isBankInfoFlg: boolean;

    public constructor() {
        this.paymentType = '';
        this.paymentTypeName = '';
        this.paymentTypeNameML = '';
        this.fee = '';
        this.taxedFee = '';
        this.creditPeriod = '';
        this.creditHolder = '';
        this.creditNumber = '';
        this.canUseCredit = false;
        this.bankId = '';
        this.bankName = '';
        this.branchId = '';
        this.branchName = '';
        this.accountName = '';
        this.accountTypeName = '';
        this.accountNumber = '';
        this.isBillFlg = false;
        this.isBankInfoFlg = false;
    }
}

//先払い用支払い方法
class HostingInitialPaymentHosting extends HostingBasePayment {
}

//変更予定の支払い方法(口座振替)
class HostingFuturePayment extends HostingBasePayment {
    //支払い方法適用予定日
    public startDate: string;

    public constructor() {
        super();
        this.startDate = '';
    }
}
