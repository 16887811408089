import { StringUtil } from 'Content/script/libs/utils/string.util';
import { ServiceContainer } from 'Content/script/service/service_container';

export class AnalyticsUtil {

    constructor() { }

    /**
     * 初回レンダー時のクエリパラメータを取得する
     * @param url
     */
    public static getQueryParamsOnFirstRender(url: string): { [key: string]: string } {
        if (window.isFirstRender) {
            return StringUtil.getQueryParams(url);      
        }
        return {};
    }

    /**
     * ドメイン更新期限が最も早い稼働中のドメインを1件取得
     * @param serviceContainer
     */
    public static getDomainEarlyExpiration(serviceContainer: ServiceContainer) {

        serviceContainer.HubsService.multipleSendManager.Send(
            0,
            serviceContainer.HubsService.hubsManager.domainRenewHubManager.GetDomainEarlyExpiration,
            null,
            (data: DomainRenewListResponseHubDto) => {
                //OK時、下記イベントが起きる。
                //イベント名：event_HubsManagerOK_DomainRenewHub_getDomainEarlyExpiration
            },
            (data: DomainRenewListResponseHubDto) => {
                // エラー時
            },
            null, //this.NoSession,
            (data: MailResponseHubDto) => {
            },
            null //this.UnExceptedError
        );
    }
}
