import { NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Location } from '@angular/common';

import { ABCookieService } from 'Content/script/service/ab_cookie_service';
import { AccountInfoService } from 'Content/script/service/account_info_service';
import { DataTransferService } from 'Content/script/service/data_transfer_service';
import { DomainCartService } from 'Content/script/service/domain_cart_service';
import { DomainWhoisService } from 'Content/script/service/domain_whois_service';
import { HeaderComponentService } from 'Content/script/service/header_navigation_service';
import { HubsService } from 'Content/script/service/hubs_service';
import { JsLoadService } from 'Content/script/service/js_load_service';
import { LayoutService } from 'Content/script/service/layout_service';
import { LinkService } from 'Content/script/service/link_service';
import { ModalControlService } from 'Content/script/service/modal_control_service';
import { ModalForDomainRenewService } from 'Content/script/service/modal_for_domain_renew_service';
import { NotifyService } from 'Content/script/service/notify_service';
import { NPSEnqueteService } from 'Content/script/service/nps_enquete_service';
import { SpNPSEnqueteService } from 'Content/sp/script/service/nps_enquete_service';
import { SessionService } from 'Content/script/service/session_service';
import { TrackingService } from 'Content/script/service/tracking_service';
import { NewsService } from 'Content/script/service/news_service';
import { Router } from '@angular/router';
import { SpABCookieService } from 'Content/sp/script/service/ab_cookie_service';
import { AccountDetailService } from '../../../script/service/account_detail_service';
import { NavigationService } from 'Content/script/service/navigation_service';
import { DomainWithDefaultNameServerService } from 'Content/script/service/domain_with_default_name_server_service';
import { StoreService } from 'Content/script/service/store_service';
import { Store } from '@ngrx/store';

/**
 * 共通サービスコンテナー
 */
export abstract class BaseServiceContainer {
    public AccountInfoService: AccountInfoService;
    public AccountDetailService: AccountDetailService;
    public DataTransferService: DataTransferService;
    public DomainCartService: DomainCartService;
    public HeaderComponentService: HeaderComponentService;
    public HubsService: HubsService;
    public JsLoadService: JsLoadService;
    public LinkService: LinkService;
    public ModalControlService: ModalControlService;
    public NotifyService: NotifyService;
    public SessionService: SessionService;
    public TrackingService: TrackingService;
    public ABCookieService: ABCookieService
    public SpABCookieService: SpABCookieService;
    public LayoutService: LayoutService;
    public ModalForDomainRenewService: ModalForDomainRenewService;
    public NPSEnqueteService: NPSEnqueteService;
    public SpNPSEnqueteService: SpNPSEnqueteService;
    public DomainWhoisService: DomainWhoisService;
    public NewsService: NewsService;
    public NavigationService: NavigationService;
    public DomainWithDefaultNameServerService: DomainWithDefaultNameServerService;
    public StoreService: StoreService;
    public isFirstViewAfterLogin: boolean = true;

    public constructor(ngZone: NgZone, http: HttpClient, router: Router, location: Location, store: Store) {
        const hubsService: HubsService = new HubsService(ngZone, http, router);

        this.AccountInfoService = new AccountInfoService(hubsService);
        this.AccountDetailService = new AccountDetailService(hubsService);
        this.DataTransferService = new DataTransferService();
        this.HubsService = hubsService;
        this.JsLoadService = new JsLoadService();
        this.LinkService = new LinkService();
        this.ModalControlService = new ModalControlService();
        this.NotifyService = NotifyService.getInstance();
        this.SessionService = new SessionService();
        this.TrackingService = new TrackingService();
        this.NewsService = new NewsService(hubsService);
        this.NavigationService = new NavigationService(router, location);
        this.DomainWithDefaultNameServerService = new DomainWithDefaultNameServerService(hubsService);
        this.StoreService = new StoreService(store);
    }

    abstract GetViewMode(): string
}
