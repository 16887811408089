import { createAction, props } from '@ngrx/store';
import { LayoutState } from '../states/layout';

export const setLayout = createAction(
    '[Layout] Set SP Layout',
    props<LayoutState>()
);

export const resetLayout = createAction(
    '[SpLayout] Reset SP Layout'
);
