<!--ホスティング_現在のお支払い情報-->
<section class="section">
    <h2 class="hdn-Lev3">お支払い方法</h2>
    <table class="tbl">
        <colgroup>
            <col width="204">
            <col width="">
        </colgroup>
        <!--支払い方法が未設定-->
        <tbody *ngIf="viewModel.isBasePaymentWindow === false">
            <tr>
                <th class="is-ValignMiddle">お支払い方法</th>
                <td><span class="text-Danger" *ngIf="viewModel.isAllLoading == false">未設定</span></td>
            </tr>
        </tbody>
        <tbody *ngIf="viewModel.isBasePaymentWindow === true && viewModel.hostingBasePayment.paymentType === 'credit'">
            <tr>
                <th class="is-ValignMiddle">お支払い方法</th>
                <td *ngIf="viewModel.isRegisteredDomainCreditCard === true">登録済みのクレジットカード</td>
            </tr>
            <tr>
                <th class="is-ValignMiddle">カード番号</th>
                <td>{{viewModel.hostingBasePayment.creditNumber}}</td>
            </tr>
            <!--<tr>
                <th class="is-ValignMiddle">カード名義人</th>
                <td>{{viewModel.hostingBasePayment.creditHolder}}</td>
            </tr>-->
            <tr>
                <th class="is-ValignMiddle">有効期限</th>
                <td>{{viewModel.hostingBasePayment.creditPeriod}}</td>
            </tr>
        </tbody>

        <!--AmazonPay-->
        <tbody *ngIf="viewModel.isBasePaymentWindow === true && viewModel.hostingBasePayment.paymentType === 'amazonPay'">
            <tr>
                <th class="is-ValignMiddle">お支払い方法</th>
                <td>Amazon Pay</td>
            </tr>
        </tbody>

        <!--銀行振込-->
        <tbody *ngIf="viewModel.isBasePaymentWindow === true && viewModel.hostingBasePayment.paymentType === 'bankTransfer'">
            <tr>
                <th class="is-ValignMiddle">お支払い方法</th>
                <td>銀行振込</td>
            </tr>
            <tr>
                <th class="is-ValignMiddle">請求書の郵送</th>
                <td *ngIf="viewModel.hostingBasePayment.isBillFlg === false">なし</td>
                <td *ngIf="viewModel.hostingBasePayment.isBillFlg === true">あり</td>
            </tr>
        </tbody>

        <!--コンビニエンスストア-->
        <tbody *ngIf="viewModel.isBasePaymentWindow === true && viewModel.hostingBasePayment.paymentType === 'netcvs'">
            <tr>
                <th class="is-ValignMiddle">お支払い方法</th>
                <td>コンビニエンスストア</td>
            </tr>
            <tr>
                <th class="is-ValignMiddle"></th>
                <td>※1回のご請求につき{{viewModel.hostingBasePayment.taxedFee}}円（税込）のお支払い手数料がかかります。</td>
            </tr>
        </tbody>

        <!--口座振替-->
        <tbody *ngIf="viewModel.isBasePaymentWindow === true && viewModel.hostingBasePayment.paymentType === 'bank'">
            <tr>
                <th class="is-ValignMiddle">お支払い方法</th>
                <td>口座振替</td>
            </tr>
            <tr>
                <th class="is-ValignMiddle">請求書の郵送</th>
                <td *ngIf="viewModel.hostingBasePayment.isBillFlg === false">なし</td>
                <td *ngIf="viewModel.hostingBasePayment.isBillFlg === true">あり</td>
            </tr>
        </tbody>

        <!--請求書-->
        <tbody *ngIf="viewModel.isBasePaymentWindow === true && viewModel.isBasePaymentWindow === true && viewModel.hostingBasePayment.paymentType === 'cvs'">
            <tr>
                <th class="is-ValignMiddle">お支払い方法</th>
                <td>請求書</td>
            </tr>
            <tr>
                <th class="is-ValignMiddle"></th>
                <td>※1回のご請求につき{{viewModel.hostingBasePayment.taxedFee}}円（税込）のお支払い手数料がかかります。</td>
            </tr>
        </tbody>
    </table>
</section>

<!--ホスティング_InitialPaymentがある場合-->
<!--銀行振込-->
<section *ngIf="viewModel.isInitialPaymentWindow === true" class="section">
    <h2 class="hdn-Lev3">個別お申込時のお支払い方法</h2>
    <div>※サーバー追加、オプションサービス、プラン変更、まとめ払いの各お申込のお支払いは、『個別お申込時のお支払い方法』でお願いいたします。</div>
    <table class="tbl">
        <colgroup>
            <col width="204">
            <col width="">
        </colgroup>
        <tbody>
            <tr>
                <th class="is-ValignMiddle">お支払い方法</th>
                <td>銀行振込</td>
            </tr>
            <tr>
                <th class="is-ValignMiddle">請求書の郵送</th>
                <td *ngIf="viewModel.hostingInitialPayment.isBillFlg === false">なし</td>
                <td *ngIf="viewModel.hostingInitialPayment.isBillFlg === true">あり</td>
            </tr>
        </tbody>
    </table>
</section>

<!--ホスティング_FuturePaymentがある場合-->
<!--口座振替-->
<section *ngIf="viewModel.isFuturePaymentWindow === true" class="section">
    <h2 class="hdn-Lev3">{{viewModel.hostingFuturePayment.startDate}}より下記お支払い方法へ変更になります。</h2>
    <table class="tbl">
        <colgroup>
            <col width="204">
            <col width="">
        </colgroup>
        <tbody>
            <tr>
                <th class="is-ValignMiddle">お支払い方法</th>
                <td>口座振替</td>
            </tr>
        </tbody>
    </table>
</section>
