import { createReducer, createFeatureSelector, createSelector, on } from '@ngrx/store';
import { PopUpState } from '../states/popup';
import { setShowPopUpM365Status, resetShowPopUpState } from '../actions/popup';

export const popUpFeatureKey = 'popup';

const initialState: PopUpState = {
    showPopUpM365Status: false
};

export const reducer = createReducer(
    initialState,
    on(setShowPopUpM365Status, (state: PopUpState, obj) => ({
        ...state,
        showPopUpM365Status: obj.value
    })),
    on(resetShowPopUpState, (state: PopUpState) => (initialState))
);


const getState = createFeatureSelector<PopUpState>(popUpFeatureKey);

export const isShowPopUpM365Status = createSelector(
    getState,
    state => state.showPopUpM365Status
);


