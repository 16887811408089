import { Router } from '@angular/router';
import { ServiceContainer } from 'Content/script/service/service_container';
import { MultipleSendManager } from "Content/script/managers/MultipleSendManager";
import { onUnexpectedError, onError, onNoSession } from "Content/script/components/base_component";

/**
 * ドメイン系商材の申込み
 * @param document
 * @param serviceContainer
 * @param sender
 * @param router
 * @param queueId
 * @param request
 */
export function domainOrderRedirection(
    document: Document,
    serviceContainer: ServiceContainer,
    sender: MultipleSendManager,
    router: Router,
    queueId: number,
    request: NavigationRequestHubDto
) {
    sender.Send(
        queueId,
        serviceContainer.HubsService.hubsManager.navigationHubManager.GetDomainOrderRedirection,
        request,
        (data: NavigationResponseHubDto) => {
            let redirectionParams: RedirectionParameter[] = [];
            for (var rParam of data.RedirectionParams) {
                let redirectionParam: RedirectionParameter = {
                    RedirectionKey: rParam.RedirectionKey,
                    RedirectionValue: rParam.RedirectionValue
                };
                redirectionParams.push(redirectionParam);
            }
            redirectionOnSameWindow(document, data.RedirectionHost, redirectionParams);
        },
        (response: NavigationResponseHubDto) => {
            onError(router);
        },
        (response: NavigationResponseHubDto) => {
            onNoSession(serviceContainer, router);
        },
        null,
        (response: NavigationResponseHubDto) => {
            onUnexpectedError(null);
        }
    );
}

/**
 * サーバー系商材の申込み
 * @param document
 * @param serviceContainer
 * @param sender
 * @param router
 * @param queueId
 * @param request
 */
export function serverOrderRedirection(
    document: Document,
    serviceContainer: ServiceContainer,
    sender: MultipleSendManager,
    router: Router,
    queueId: number,
    request: ServerOrderRedirectionRequestHubDto
) {
    sender.Send(
        queueId,
        serviceContainer.HubsService.hubsManager.navigationHubManager.GetServerOrderRedirection,
        request,
        (data: ServerOrderRedirectionResponseHubDto) => {
            let redirectionParams: RedirectionParameter[] = [];
            for (var rParam of data.RedirectionParams) {
                let redirectionParam: RedirectionParameter = {
                    RedirectionKey: rParam.RedirectionKey,
                    RedirectionValue: rParam.RedirectionValue
                };
                redirectionParams.push(redirectionParam);
            }
            redirectionOnSameWindow(document, data.RedirectionHost, redirectionParams);
        },
        (response: ServerOrderRedirectionResponseHubDto) => {
            onError(router);
        },
        (response: ServerOrderRedirectionResponseHubDto) => {
            onNoSession(serviceContainer, router);
        },
        null,
        (response: ServerOrderRedirectionResponseHubDto) => {
            onUnexpectedError(null);
        }
    );
}

/**
 * マラドーナへのサービス連携
 * @param document
 * @param serviceContainer
 * @param sender
 * @param router
 * @param queueId
 * @param request
 */
export function serverRelateRedirection(
    document: Document,
    serviceContainer: ServiceContainer,
    sender: MultipleSendManager,
    router: Router,
    queueId: number,
    request: ServerRelateRedirectionRequestHubDto
) {
    sender.Send(
        queueId,
        serviceContainer.HubsService.hubsManager.navigationHubManager.GetServerRelateRedirection,
        request,
        (data: ServerRelateRedirectionResponseHubDto) => {
            let redirectionParams: RedirectionParameter[] = [];
            for (var rParam of data.RedirectionParams) {
                let redirectionParam: RedirectionParameter = {
                    RedirectionKey: rParam.RedirectionKey,
                    RedirectionValue: rParam.RedirectionValue
                };
                redirectionParams.push(redirectionParam);
            }
            redirectionOnSameWindow(document, data.RedirectionHost, redirectionParams);
        },
        (response: ServerRelateRedirectionResponseHubDto) => {
            onError(router);
        },
        (response: ServerRelateRedirectionResponseHubDto) => {
            onNoSession(serviceContainer, router);
        },
        null,
        (response: ServerRelateRedirectionResponseHubDto) => {
            onUnexpectedError(null);
        }
    );
}

/**
 * 同じタブでリダイレクトする
 * @param document
 * @param redirectionHost
 * @param redirectionParams
 */
function redirectionOnSameWindow(
    document: Document,
    redirectionHost: string,
    redirectionParams: RedirectionParameter[]
) {
    var form = document.createElement('form');

    for (var rParam of redirectionParams) {
        var reqElm = document.createElement('input');
        reqElm.type = 'hidden';
        reqElm.name = rParam.RedirectionKey;
        reqElm.value = rParam.RedirectionValue;
        form.appendChild(reqElm);
    }

    form.action = redirectionHost;
    form.method = 'get';
    document.body.appendChild(form);
    form.submit();
}

export interface RedirectionParameter {
    RedirectionKey: string;
    RedirectionValue: string;
}
