import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
// モジュール
import { UiModule } from 'Content/script/modules/ui.module';
import { ValidationModule } from 'Content/script/modules/validator.module';
import { AtomsModule } from 'Content/script/modules/atoms.module';
import { MoleculesModule } from 'Content/script/modules/molecules.module';
import { OrganismsModule } from 'Content/script/modules/organisms.module';
import { SafePipeModule } from 'Content/script/modules/safe.module';

/**
 * Tempate
 * */
import { DomainRenewPaymentComponent } from 'Content/script/components/share/Templates/domain_renew_payment/domain_renew_payment.component';
import { NewRsContractTourModalComponent } from 'Content/script/components/share/Templates/new_rs_contract_tour_modal/new_rs_contract_tour_modal.component';
import { GmoPointModal2Component } from 'Content/script/components/share/gmo_point_modal/gmo_point_modal_2.component';
import { RsRecommendComponent } from 'Content/script/components/share/Templates/rs_recommend/rs_recommend.component';
import { MfaModalComponent } from 'Content/script/components/share/multiFactorAuthentication_modal/mfa_modal.component';
import { VoaciModalComponent } from '../components/share/voaci_modal/voaci_modal.component';
import { WithdrawalPreventionModalComponent } from 'Content/script/components/share/Templates/withdrawal_prevention_modal/withdrawal_prevention_modal.component';
import { DomainDelserviceCancellationModalComponent } from 'Content/script/components/share/Templates/domain_delservice/domain_delsevice_cancellation_modal/domain_delservice_cancellatiom_modal.component';
import { DomainRenewModalNewComponent } from 'Content/script/components/share/Templates/domain_renew_modal_new/domain_renew_modal_new.component';
import { DomainDetailModalComponent } from 'Content/script/components/share/Templates/domain_detail_modal/domain_detail_modal.component';
import { DomainDetailAbolitionDeterrenceModalComponent } from 'Content/script/components/share/Templates/domain_detail_abolition_deterrence_modal/domain_detail_abolition_deterrence_modal';
import { HeaderNotificationAppealBannerComponent } from 'Content/script/components/share/Templates/header_notification_appeal_banner/header_notification_appeal_banner.component';
import { DomainDetailEnquetePopupComponent } from 'Content/script/components/share/Templates/domain_detail_authcode_enquete_popup/domain_detail_authcode_enquete_popup.component';
import { DomainNetDeShindanEnquetePopupComponent } from 'Content/script/components/share/Templates/domain_net_de_shindan_enquete_popup/domain_net_de_shindan_enquete_popup.component';

/* For RsRecommendComponent */
import { DomainSearchDropdownComponent } from 'Content/script/components/share/domain_search_dropdown/domain_search_dropdown.component';
import { DomainSettingRenewListComponent } from "../components/domain/setting/renew/list/list.component";
import { DomainSettingBulkApplicationListComponent } from 'Content/script/components/domain/setting/bulk/applicationlist/list.component';
import { AweSomeModule } from 'Content/script/modules/awesome.module';

import { NsDefaultComponent } from 'Content/script/components/share/Templates/ns_default/ns_default.component';
import { TopChangeMemberInformationComponent } from 'Content/script/components/share/Templates/top_change_member_information/top_change_member_information.component';
import { CreditCardListComponent } from '../components/share/Templates/creditcard_service/creditcard.component';
import { WhoisprotectCancelEnquetePopupComponent } from 'Content/script/components/share/Templates/whoisprotect_cancel_enquete_popup/whoisprotect_cancel_enquete_popup.component';
import { WhoisprotectCancelEnqueteCouponPopup } from 'Content/script/components/share/Templates/whoisprotect_cancel_enquete_coupon_popup/whoisprotect_cancel_enquete_coupon_popup.component';
import { NoteCancelItemService } from 'Content/script/components/share/Templates/note_cancel_item_service/note_cancel_item_service.component';
import { BackOrderSearchDropdownComponent } from '../components/domain/setting/backorder/search/backorder_search_dropdown.component';
import { DomainNetDeShindanModalComponent } from 'Content/script/components/share/Templates/domain_net_de_shindan_modal/domain_net_de_shindan_modal.component';
import { DomainDetailModalConfirmComponent } from 'Content/script/components/share/Templates/domain_detail_modal_confirm/domain_detail_modal_confirm.component';
import { BulkOderSearchDropdownComponent } from 'Content/script/components/share/bulk_order_search_dropdown/bulk_order_search_dropdown.component';
import { QuickPaymentComponent } from '../components/share/Templates/quick_payment/quick_payment.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        UiModule,
        ValidationModule,
        AtomsModule,
        MoleculesModule,
        OrganismsModule,
        AweSomeModule,
        SafePipeModule
    ],
    declarations: [
        DomainRenewPaymentComponent,
        DomainSearchDropdownComponent,
        BackOrderSearchDropdownComponent,
        NewRsContractTourModalComponent,
        DomainSettingRenewListComponent,
        GmoPointModal2Component,
        RsRecommendComponent,
        MfaModalComponent,
        VoaciModalComponent,
        WithdrawalPreventionModalComponent,
        DomainDelserviceCancellationModalComponent,
        DomainRenewModalNewComponent,
        DomainDetailModalComponent,
        DomainDetailAbolitionDeterrenceModalComponent,
        HeaderNotificationAppealBannerComponent,
        NsDefaultComponent,
        DomainDetailEnquetePopupComponent,
        DomainNetDeShindanEnquetePopupComponent,
        TopChangeMemberInformationComponent,
        CreditCardListComponent,
        WhoisprotectCancelEnquetePopupComponent,
        WhoisprotectCancelEnqueteCouponPopup,
        NoteCancelItemService,
        DomainNetDeShindanModalComponent,
        DomainDetailModalConfirmComponent,
        BulkOderSearchDropdownComponent,
        DomainSettingBulkApplicationListComponent,
        QuickPaymentComponent,
    ],
    exports: [
        DomainRenewPaymentComponent,
        DomainSearchDropdownComponent,
        BackOrderSearchDropdownComponent,
        BulkOderSearchDropdownComponent,
        NewRsContractTourModalComponent,
        GmoPointModal2Component,
        RsRecommendComponent,
        MfaModalComponent,
        VoaciModalComponent,
        WithdrawalPreventionModalComponent,
        DomainDelserviceCancellationModalComponent,
        DomainRenewModalNewComponent,
        DomainDetailModalComponent,
        DomainDetailAbolitionDeterrenceModalComponent,
        HeaderNotificationAppealBannerComponent,
        NsDefaultComponent,
        DomainDetailEnquetePopupComponent,
        DomainNetDeShindanEnquetePopupComponent,
        TopChangeMemberInformationComponent,
        CreditCardListComponent,
        WhoisprotectCancelEnquetePopupComponent,
        WhoisprotectCancelEnqueteCouponPopup,
        NoteCancelItemService,
        DomainNetDeShindanModalComponent,
        DomainDetailModalConfirmComponent,
        QuickPaymentComponent
    ]
})

export class TemplatesModule { }
