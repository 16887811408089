export class CardHolderNameUtil {


    /**
     * カード名義人に入力可能な文字種(正規表現)を返す
     * */
    public static getRegex(): string {

        //各仕様で入力可能な文字種
        const mulpay = ' ,.';       //半角スペース・カンマ・ピリオド（トークン決済サービス仕様書_1.08.pdfに記載）
        const maradona = '\\-_';    //ハイフン・アンダースコア
        const newNavi = '&';        //アンパサンド

        return '0-9a-zA-Z' + mulpay + maradona + newNavi;
    }


    /**
     * token.jsでエラーにならないカード名義人の文字列に変更する
     * @param holderName
     */
    public static adjustForTokenJs(holderName: string): string {

        //入力可能な文字種のうち、token化対象外のハイフン、アンダースコア、アンパサンドを削除
        return holderName.replace(/\-/g, '').replace(/_/g, '').replace(/&/g, '');

    }
}
