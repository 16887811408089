import { Directive, ElementRef, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';

/**
 * ネットde診断　診断画面ツアー専用directive
 * */
@Directive({
    selector: '[net-de-shindan-select-tour]'
})
export class NetDeShindanSelectTourDirective implements OnDestroy, OnChanges {
    private _el: HTMLElement;

    /**
     * ツアー時に挿入するポップアップ
     */
    private createPopupContent(): HTMLDivElement {
        let popup = document.createElement("div");
        popup.id = "tour-Popup";
        popup.className = "tour-Popup";
        popup.innerHTML = `<div class="tour-Popup-Background"></div>
                            <div class="tour-Popup-Inner">
                                <p class="size-18 mb0 text-Center">診断したいドメインを選択し、「診断」ボタンを押してください。</p>
                            </div>`;
        return popup;
    }

    /**
     * ツアー時に挿入する指アイコン
     */
    private createFingerContent(): HTMLDivElement {
        let finger = document.createElement("div");
        finger.id = "tour-Highlight-Finger";
        finger.className = "tour-Highlight-Finger";
        finger.innerHTML = '<img src="/Content/images/top/finger.gif" alt="">';
        return finger;
    }

    /**
     * ツアー時に挿入する全体マスク
     */
    private createMaskCotent(): HTMLDivElement {
        let mask = document.createElement("div");
        mask.id = "tour-Mask";
        mask.classList.add("tour-Mask");
        return mask;
    }

    @Input('net-de-shindan-select-tour')
    public isStart: boolean;

    /**
     * コンストラクタ
     * @param elementRef
     */
    public constructor(private elementRef: ElementRef) {
        this._el = elementRef.nativeElement;
    }

    /**
     * @inheritdoc
     * @param changes
     */
    public ngOnChanges(changes: SimpleChanges): void {
        for (const prop in changes) {
            let change = changes[prop];
            if (prop == "isStart") {
                if (change.currentValue) {
                    this.startTour();
                } else {
                    this.endTour();
                }
            }
        }
    }

    public ngOnDestroy(): void {
        this.endTour();
    }

    /**
     * ツアー開始
     * */
    private startTour(): void {
        // 全体マスク
        document.body.append(this.createMaskCotent());

        this._el.classList.add("tour-SuppressionHover");

        let childSectionTag: HTMLElement = this._el.querySelector("section");
        childSectionTag.classList.add("is-Tour", "pt0", "pb0", "pl0", "pr0", "tour-Highlight", "is-NetDiagnosisTopDetail");

        // 要素を挿入
        childSectionTag.append(this.createPopupContent());
        childSectionTag.append(this.createFingerContent());
    }

    /**
     * ツアー終了
     * */
    private endTour(): void {
        // 全体マスク
        let mask = document.body.querySelector("#tour-Mask");
        if (!!mask) {
            document.body.removeChild(mask);
        }

        this._el.classList.remove("tour-SuppressionHover", "is-Tour");

        let childSectionTag: HTMLElement = this._el.querySelector("section");
        childSectionTag.classList.remove("is-Tour", "pt0", "pb0", "pl0", "pr0", "tour-Highlight", "is-NetDiagnosisTopDetail");

        // popup要素を削除
        let popup = this._el.querySelector("#tour-Popup");
        if (!!popup) {
            popup.parentNode.removeChild(popup);
        }

        // finger要素を削除
        let finger = this._el.querySelector("#tour-Highlight-Finger");
        if (!!finger) {
            finger.parentNode.removeChild(finger);
        }
    }
}
