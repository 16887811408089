/// <reference path='../definitions/window.d.ts' />

import { ErrorHandler } from '@angular/core';

//import { ServiceContainer } from 'Content/script/service/service_container';

export class OnamaeErrorHandler extends ErrorHandler {
    public constructor() {
        super();
    }

    handleError(error: any) {
        if (window.errorCallBack) {
            window.errorCallBack(error);
        }

        super.handleError(error);
    }
}
