import { fireEventHubsManagerCallbackOK } from 'Content/script/managers/HubsManagerCustomEvent';

/**
 * 外部JSへメッセージを通知するためのカスタムイベント定義
 */
function fire(eventName: string, eventInit: any, initCustomEventDetailArg) {
    let event: CustomEvent;
    try {
        event = new CustomEvent(eventName, eventInit);
    } catch (ex) {
        event = window.document.createEvent('CustomEvent');
        event.initCustomEvent(eventName, false, true, initCustomEventDetailArg);
    }
    const dom = window.document.getElementsByTagName('body')[0];
    try {
        dom.dispatchEvent(event);
    } catch (ex) {
        console.log(eventName + 'のディスパッチに失敗しました');
    }
}

function fireBody(eventName: string) {
    fire(eventName, { 'bubbles': false, 'cancelable': true }, void 0);
}

function fireBodyWithDetail(eventName: string, detail: { [key: string]: any }) {
    fire(eventName, { 'bubbles': false, 'cancelable': true, detail }, detail);
}

export class CustomizedEvents {

    //ビューモデルのロードが正常終了したとき
    public static dispatchViewModelLoaded<I>(_viewModelClassName: string, _params: I) {
        if (_params) {
            fireBodyWithDetail('event_viewModelLoaded', { ViewModelClassName: _viewModelClassName, Params: _params });
        } else {
            fireBodyWithDetail('event_viewModelLoaded', { ViewModelClassName: _viewModelClassName });
        }
    }

    //商材ログインページから遷移してきてドメイン更新画面を表示されたとき
    public static dispatchAdLoginPageToDomainSettingRenewList() {
        fireBody('event_adLoginPageToDomainSettingRenewList');
    }

    //ドメイン更新完了画面のリコメンド枠が表示されたとき
    public static dispatchAppealDomainRenew(_params: IAdobeEVar114) {
        fireBodyWithDetail('event_appealDomainRenew', _params);
    }

    //ドメイン更新画面で初期表示時、更新期限30日以内のドメインが読み込まれたとき
    public static dispatchExistDomainMightExpire(_params: IAdobeEVar20) {
        fireBodyWithDetail('event_existDomainMightExpire', _params);
    }

    //ログイン通知画面で設定が正常終了したとき
    public static dispatchSetLoginAlert(_params: IAdobeEvent328Event329) {
        fireBodyWithDetail('event_setLoginAlert', _params);
    }

    // ドメイン更新モーダル(新デザイン)を開いたときにマイクロソフトオプションが申し込み可能であるとき
    public static dispatchMicrosoftOption() {
        fireBody('event_canApplyMicrosoftOption');
    }

    //ドメイン更新画面のモーダルが表示された
    public static dispatchDomainConfirmModalsLoaded(_params: IAdobeEvent220) {
        fireBodyWithDetail('event_domainConfirmModalsLoaded', _params);
    }

    // Top画面にオプションサービス(ドメイン）枠が表示された
    public static dispatchDomainOption() {
        fireBody('event_displayOptionPanels');
    }

    // ドメイン一覧 プレミアムドメインリコメンドメッセージ追加
    public static dispatchDomainPremiumDomainMessage() {
        fireBody('event_hasPremiumDomainDisplay');
    }

    // 申込み1年以内のドメインを持っているかどうか
    // TODO: _paramsの型定義が必要
    public static dispatchHasDomainAppliedWithinOneYear(_params: IAdobeEVar155) {
        fireBodyWithDetail('event_hasDomainAppliedWithinOneYear', _params);
    }

    //「デスクトップクラウド for MT4」ボタン押下した場合、eVar135を発火させる
    public static dispatchRegistDCMT4(_params: IAdobeEVar135) {
        fireBodyWithDetail('event_RegistDCMT4', _params);
    }

    // TOPページ表示時、デスクトップクラウド及びいくつかの商材の契約有無を出し分ける
    public static dispatchDCNewOrExist(_params: IAdobeEVar182) {
        fireBodyWithDetail('event_DCNewOrExist', _params);
    }

    public static dispatchFilterPremiumDomainDisplay(_param: IAdobeEVar104) {
        fireBodyWithDetail('event_filterPremiumDomainDisplay', _param);
    }

    public static dispatchDomainUpdateBlockPopup() {
        fireBody('event_DomainUpdateBlockPopup');
    }
    
    public static dispatchRsCancelValidate() {
        fireBody('event_rsCancelValidate');
    }

    public static dispatchRsCancelDone(_param: IAdobeEvent384Event427) {
        fireBodyWithDetail('event_rsCancelDone', _param);
    }

    // ドメイン一覧 プレミアムドメインリコメンドメッセージ追加
    public static dispatchDisplayWhoIsChangePremiumDomain(_param: IAdobeEVar93) {
        fireBodyWithDetail('event_DisplayWhoIsChangePremiumDomain', _param);
    }

    public static dispatchDomainSearchModal(_param: IAdobeEVar38) {
        fireBodyWithDetail('event_DomainSearchModal', _param);
    }

    public static dispatchCheckAllNSDefault(_param: IAdobeEVar33) {
        fireBodyWithDetail('event_CheckAllNSDefault', _param);
    }

    public static dispatchLoginCampaignPromotion(_param: IAdobeEVar188) {
        fireBodyWithDetail('event_LoginCampaignPromotion', _param);
    }

    public static dispatchFireEvents(_params: IAdobeEvents) {
        fireBodyWithDetail('event_FireEvents', _params);
    }

    public static dispatchAuthModalOpenSurvey(_param: IAdobeEVar21) {
        fireBodyWithDetail('event_AuthModalOpenSurvey', _param);
    }

    public static dispatchAddEvarChangeCreditCardInfo(_params: IAdobeEvar177) {
        fireBodyWithDetail('event_AddEvarChangeCreditCardInfo', _params);
    }

    public static dispatchDomainDetailRsModal(_params: IAdobeEvar10) {
        fireBodyWithDetail('event_DomainDetailRsModal', _params);
    }

    // ネットde診断のTOP画面モーダル
    public static dispatchNetDeDiagnosis() {
        fireBody('event_dispatchNetDeDiagnosis');
    }

    public static dispatchShowDeShindanModalDomain() {
        fireBody('event_dispatchShowDeShindanModalDomain');
    }

    public static dispatchEventFiringAddition(_params: IAdobeEvents) {
        fireBodyWithDetail('event_EventFiringAddition', _params);
    }

    public static dispatchNetDeShindanRecommend() {
        fireBody('event_NetDeShindanRecommend');
    }

    public static dispatcFireEventSslResult() {
        fireBody('event_SslResult');
    }

    // プレミアムドメインがある場合のdomain/search画面のメソッド
    public static dispatchRecommendDomainPremium() {
        fireBody('event_RecommendDomainPremium');
    }

    // プレミアムドメインがない場合のdomain/search画面のメソッド
    public static dispatchRecommendDomainPremiumUnowned() {
        fireBody('event_RecommendDomainPremiumUnowned');
    }

    // completeCallbackが発生したとき
    public static dispatchHubsManagerComplete<T>(dtoTypeName: string, hubDto: T) {

        const hubDtoInfo: HubDtoInfo<T> = {
            dto: hubDto,
            pathName: location.pathname,
            dtoName: dtoTypeName
        };

        //①イベント名はhubDtoごとにユニーク
        fireBodyWithDetail(`event_HubsManagerCompleteAbolish_${dtoTypeName}`, hubDtoInfo);
        //②イベント名は固定
        fireBodyWithDetail('event_HubsManagerCompleteAbolish', hubDtoInfo);
    }

    public static dispatchHubsManagerOK<T1, T2>(hubName: string, methodName: string, reqHubDtoName: string, resHubDtoName: string, reqHubDto: T1, resHubDto: T2) {
        fireEventHubsManagerCallbackOK(hubName, methodName, reqHubDtoName, resHubDtoName, reqHubDto, resHubDto);
    }
    
    public static dispatchDisplayPremiumDomain(_param: IAdobeEVar105) {
        fireBodyWithDetail('event_DisplayPremiumDomain', _param);
    }

    public static dispatchClickPhoneAuth(_param: IAdobeEVar30) {
        fireBodyWithDetail('event_ClickPhoneAuth', _param);
    }

    public static dispatchCheckPhoneAuthStatus(_param: IAdobeEVar31) {
        fireBodyWithDetail('event_CheckPhoneAuthStatus', _param);
    }

    // ドメインが更新されたときに、ネームサーバーがデフォルト（onamae.com）のドメインだとeventが発火する
    public static dispatchRenewDomainHaveNsDefault(_param: IAdobeEvent581Event582) {
        fireBodyWithDetail('event_RenewDomainHaveNsDefault', _param);
    }

    public static dispatchRenewDoneFireEvent() {
        fireBody('event_RenewDoneFireEvent');
    }

    public static dispatchDisplayModalAppealLine () {
        fireBody('event_DisplayModalAppealLine');
    }

    public static dispatchLineIdConnectDone() {
        fireBody('event_LineIdConnectDone');
    }

    public static dispatchWhoisCancelModal() {
        fireBody('event_WhoisCancelModal');
    }
    
    public static dispatchClickSubmitSurveyModal(_param: IAdobeEVar21GT128066) {
        fireBodyWithDetail('event_SubmitSurveyModal', _param);
    }

    public static dispatchSurveyModal(_param: IAdobeEvent487Event488) {
        fireBodyWithDetail('event_SurveyModal', _param);
    }

    public static dispatchDefaultNsDomain() {
        fireBody('event_DefaultNsDomain');
    }

    public static dispatchSelectAutoCreateDomain(_params: IAdobeEvar184) {
        fireBodyWithDetail('event_SelectAutoCreateDomain', _params);
    }

    public static dispatchDomainMonitoringCreateModal() {
        fireBody('event_DomainMonitoringCreateModal');
    }

    public static dispatchDomainMonitoringCreate(_params: IAdobeEvent321Event339) {
        fireBodyWithDetail('event_DomainMonitoringCreate', _params);
    }

    public static dispatchChangeAutoCreateDomain() {
        fireBody('event_ChangeAutoCreateDomain');
    }

    public static dispatchDomainMonitoringDelete() {
        fireBody('event_DomainMonitoringDelete');
    }

    public static dispatchOshiraseShindan() {
        fireBody('event_OshiraseShindan');
    }

    public static dispatchDomainMonitoringSeeDetails() {
        fireBody('event_DomainMonitoringSeeDetails');
    }

    public static dispatchNetDeShindanExecuteButton() {
        fireBody('event_NetDeShindanExecuteButton');
    }

    /**
     * ネットde診断に関連するボタン押下時に発火させるイベント
     * @param _eventType
     */
    public static dispatchIeraeNetDeShindanButtonEvent(_eventType: EIeraeNetDeShidanEventType): void {
        fireBodyWithDetail('event_NetDeShindanButton', {
            EventType: _eventType
        } as IAdobeEventIeraeNetDeShindanButton);
    }

    /**
     * ネットde診断に関連するモーダル表示時に発火させるイベント
     * @param _eVarType
     */
    public static dispatchIeraeNetDeShindanModalEvent(_eVarType: EIeraeNetDeShindanModalEVarType): void {
        fireBodyWithDetail('event_NetDeShindanModal', {
            EVarType: _eVarType
        } as IAdobeEVarIeraeNetDeShindanModal);
    }

    // ドメインプロテクション申請 入力画面が表示された時
    public static dispatchDisplayDomainProtectApply() {
        fireBody('event_DisplayDomainProtectApply');
    }

    // ドメインプロテクション申請完了時
    public static dispatchDomainProtectApplyDone(_param: IAdobeEvent180Event183) {
        fireBodyWithDetail('event_DomainProtectApplyDone', _param);
    }

    /**
     * 新Navi/かんたんモニタリング　※テンプレ、旧仕様なし - event321,event324,event340
     * @param _param
     */
    public static dispatchQuickMonitoring(_params: IAdobeQuickMonitoring) {
        fireBodyWithDetail('event_QuickMonitoring', _params);
    }

    /**
    * 新Navi/かんたんモニタリング　※テンプレ、旧仕様なし - eVar118
    * @param _param
    */
    public static dispatchQuickMonitoringEvar118(_params: IAdobeQuickMonitoringeVar118) {
        fireBodyWithDetail('event_QuickMonitoring_eVar118', _params);
    }
    /**
     * event577が発火→event580が発火
     * @param _param
     */
    public static dispatchFireEventRsStatistics(_params: IAdobeEvents) {
        fireBodyWithDetail('event_FireEventRsStatistics', _params);
    }

    /**
    * event290,event291,event289,event172
    * @param _param
    */
    public static dispatchFireEventQuickOptionWhoisProtect(_params: IAdobeEvents) {
        fireBodyWithDetail('event_QuickWhois', _params);
    }

    /**
     * eVar 179
    * @param _param
    */
    public static dispatchFireeVarQuickOptionWhoisProtect(_params: IAdobeEVar179) {
        fireBodyWithDetail('event_QuickWhois_eVar179', _params);
    }

    /**
     * eVar 118
    * @param _param
    */
    public static dispatchFireeVarQuickOptionAuth(_params: IAdobeEvar118) {
        fireBodyWithDetail('event_QuickOptions_eVar118', _params);
    }

    /**
    * event617,event618
    * @param _param
    */
    public static dispatchFireEventRsRecommend(_params: IAdobeEvents) {
        fireBodyWithDetail('event_Rs_recommend', _params);
    }

    /***
     * eVar145
     */
    public static dispatchFireEventModalRenewCoupon() {
        fireBody('event_modal_renewcoupon');
    }

    /**
    * event621,event622
    * @param _param
    */
    public static dispatchFireEventModalRenewRs(_eventType: ERenewRsModalEventType): void {
        fireBodyWithDetail('event_Modal_Renew_Rs', {
            EventType: _eventType
        } as IAdobeEventRenewRsModal);
    }
}

/**
 * GT#135078
 * 各種アクションで発火させるイベント一覧
 */
export enum EIeraeNetDeShidanEventType {
    Event498 = 498,
    Event499 = 499,
    Event500 = 500,
    Event509 = 509,
    Event510 = 510,
    Event511 = 511,
    Event512 = 512,
    Event513 = 513,
    Event518 = 518,
    Event532 = 532,
    Event533 = 533,
    Event585 = 585,
}

/**
 * GT#135078
 * 各種モーダル表示時に発火させるeVar22一覧
 */
export enum EIeraeNetDeShindanModalEVarType {
    ShindanModalCount = "shindan_modal_count",
    NewNaviTopShindanModalCount = "newnavi_top__shindan_modal_count",
    InfoTopModalCount = "info_top_modal_count",
    InfoRenewModalCount = "info_renew_modal_count",
    RsTourModalCount = "rs_tour_modal_count",
    RsApproachModalCount = "rs_approach_modal_count",
    TopRenewModalNoCount = "top_renew_modal_nocount",
    NewnaviTopSpShindanModalCount = "newnavi_top_sp__shindan_modal_count"
}

/**
 * GT#134396
 * 新Navi/かんたんモニタリング
 */
export enum EIQuickMonitoringEventType {
    event324 = "event324",
    event340 = "event340",
    event321 = "event321",
}
/**
 * GT#135078
 */
export interface IAdobeEventIeraeNetDeShindanButton {
    EventType: EIeraeNetDeShidanEventType;
}

/**
 * GT#135078
 */
export interface IAdobeEVarIeraeNetDeShindanModal {
    EVarType: EIeraeNetDeShindanModalEVarType;
}

export enum ERenewRsModalEventType {
    Event621 = 621,
    Event622 = 622
}

export interface IAdobeEventRenewRsModal {
    EventType: ERenewRsModalEventType;
}

export interface IAdobeEVar21GT128066 {
    AnswerOne: string;
    AnswerTwo: string;
}

export interface IAdobeEvent226Event227 {
    DomainNewsFlg: string,
    SendRenewMailFlg: string,
    SmsExcludeFlg: string
}

export interface IAdobeEVar114 {
    domainMightExpireCount: number,
    isCreditCardPayment: boolean
}

export interface IAdobeEVar20 {
    ABTestKey: string,
    ABTestDate: string,
    PercentageA: string,
    PercentageB: string,
    IsABNew: boolean,
    IsExistDefCheckDomain: boolean,
}

export interface IAdobeEvent328Event329 {
    LoginAlertFlg: string
}

export interface IAdobeEvent220 {
    ViewModelName: string
}

export interface IAdobeEVar155 {
    proxyId: string;
    totalDomain: number;
    oneyearTotalDomain: number;
}
export interface IAdobeEVar104 {
    type: string
}

export interface IAdobeEVar93 {
    type: string
}

export interface IAdobeEVar385 {
    type: string
}

export interface IAdobeEVar182Key {
    domain: string;
    rs: string;
    onamaemail: string;
    office365: string;
    vps: string;
    desktopcloud: string;
};

export interface IAdobeEVar182 {
    products: IAdobeEVar182Key;
    quantities: { [key: string]: string };
}

export interface IAdobeEVar38 {
    isDeadlineWithin30Day: string;
    hasConditionSearch: string;
}

export interface IAdobeEVar135 {
    IsMobile: boolean;
}

export interface IAdobeEvent384Event427 {
    isRsPlan: boolean;
}

export interface IAdobeEvent487Event488 {
    isRsPlan: boolean;
}

export interface IAdobeEVar33 {
    screenIndex: string;
}

export interface IAdobeEVar188 {
    type: string;
}

export interface IAdobeEVar21 {
    createdDate: string;
    expirationDate: string;
}

export interface IAdobeEvent180Event183 {
    isDomainProtectOn: boolean;
    isDomainProtectOff: boolean;
}

interface HubDtoInfo<T> {
    dto: T;
    pathName: string; //location.pathname=現在表示されている画面
    dtoName: string;  //dtoの名前
}

/**
 * ここから　↓　[PMS16533]複数AB用CustomizedEvents
 **/
/**
 * ABCookieService.ts用のクラスやインタフェースの定義
 **/
export interface IABCookie {
    cookieName: string,
    abValue: string,
    abDate: string,
}

export interface IABParallelValue {
    parallelName: string,
    abValue: string,
}

export interface IABCookiesBaggage {
    parallelValue: IABParallelValue,
    test1: IABCookie,
    test2: IABCookie,
}

export interface IAdobeEVar105 {
    proxyId: string,
    domainName: string,
    domainValue: string
}

export interface IAdobeEVar30 {
    isMobileDevice: string
}
export interface IAdobeEVar179 {
    isMobileDevice: string
}

export interface IAdobeEVar31 {
    status: string,
    isMobileDevice: string
}

export interface IAdobeEvents {
    eventValue: string
}

export interface IAdobeEvar177 {
    evarValue: string
}
export interface IAdobeEvar118 {
    IsPersonalRole: string
}

export interface IAdobeEvar10 {
    isMobileDevice: string,
    domainId: string
}

export interface IAdobeEvar184 {
    Plan: string
}

export interface IAdobeEvent321Event339 {
    IsUseDomainAutoCreateService: boolean
}

export interface IAdobeEvent581Event582 {
    hasRs: boolean
}
export interface IAdobeQuickMonitoring {
    EventType: EIQuickMonitoringEventType;
}

export interface IAdobeQuickMonitoringeVar118 {
    IsPersonalRole: string;
}
/**
 * ABCookieService.ts用のカスタムイベント
 **/
export class CustomizedEventsForABCookieService {

    public static dispatchABCookieServiceEvent<IParams>(_eventName: string, _eventOrderName: string, _cookiesBaggage: IABCookiesBaggage, _params?: IParams) {
        //debugOut(`...${_eventName}...`);
        fireBodyWithDetail(_eventName, {
            event: _eventName,
            eventOrder: _eventOrderName,
            cookiesBaggage: _cookiesBaggage,
            params: _params || null,
        });
    }
}
/**
 * ここまで　↑　[PMS16533]複数AB用CustomizedEvents
 **/
