import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'sp-normal-anchor',
    templateUrl: './sp_normal_anchor.html'
})
export class SpNormalAnchorComponent {
    @Input() questionnaireMinute: string;

    @Output() questionnaireLink = new EventEmitter();
    @Output() questionnaireClose = new EventEmitter();

    public onClickQuestionnaireLink(): void {
        this.questionnaireLink.emit();
    }

    public onClickQuestionnaireClose(): void {
        this.questionnaireClose.emit();
    }
}
