import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'normal-anchor',
    styles: ['a { cursor: pointer; }'],
    template: `
<a class="gtmclick" [attr.data-gtmvalue]="gtmPrefix" [attr.href]="linkUrl" [attr.target]="frame" (click)="onClick()" rel="noopener noreferrer">
    <ng-content></ng-content>
</a>
`
})
export class NormalAnchorComponent {
    @Input() link: string;
    @Input() target: string;
    @Input() gtmPrefix: string
    @Output() clickEvent = new EventEmitter();

    public get linkUrl(): string {
        if (!!this.link) {
            return this.link;
        }

        return null;
    }

    public get frame(): string {
        switch (this.target) {
            case "blank":
                return "_blank";
            case "self":
                return "_self";
            case "parent":
                return "_parent";
            case "top":
                return "_top";
            default:
                return null;
        }
    }

    public onClick(): void {
        this.clickEvent.emit();
    }
}
