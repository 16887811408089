<div style="z-index: 2;" [class]="viewModel.productFlg == 'Staging' ? 'line_blue' : 'line_red'" *ngIf="viewModel.productFlg != 'Product'">
    <input class="check" type="checkbox" (click)="viewModel.NoneDisplayEnvironmentClick()"><div style="margin: 1px 20px; width:35px; font-weight: bolder;">{{viewModel.productFlg}}</div>
</div>
<div [class]="viewModel.productFlg != 'Product' ? 'line_margin' : 'line_green'" *ngIf="viewModel.isStaffLogin">
    <input class="check" type="checkbox" (click)="viewModel.NoneDisplayStaffToolLoginClick()"><div style="margin: 1px 20px; width: 200px; font-weight: bolder;">スタッフツールログイン中</div>
</div>

<div class="ng-wrap" [class.ng-is-loading]="viewModel.isLoading">
    <app-pc-root *ngIf="viewMode === 'pc'" [viewMode]="viewMode"></app-pc-root>
    <app-sp-root *ngIf="viewMode === 'sp'" [viewMode]="viewMode"></app-sp-root>
    <div class="loading" [style.display]="viewModel.isLoading ? 'block' : 'none'">
        <div class="loading-Loader">
            <div class="loading-Loader-Blade"></div>
            <div class="loading-Loader-Blade"></div>
            <div class="loading-Loader-Blade"></div>
            <div class="loading-Loader-Blade"></div>
            <div class="loading-Loader-Blade"></div>
            <div class="loading-Loader-Blade"></div>
            <div class="loading-Loader-Blade"></div>
            <div class="loading-Loader-Blade"></div>
            <div class="loading-Loader-Blade"></div>
            <div class="loading-Loader-Blade"></div>
            <div class="loading-Loader-Blade"></div>
            <div class="loading-Loader-Blade"></div>
        </div>
        <div class="loading-Message">
          ただいま画面遷移中です。<br>しばらくお待ちください。
        </div>
    </div>
    <div id="sitecatalyst_id"></div>
</div>

