import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GetDomainWithDefaultNameServerService } from 'Content/script/promotion/domainuse/service/get_domain_with_default_name_server.service';
import { PromotionDomainUseDomainSettingRenewDoneComponent } from 'Content/script/promotion/domainuse/component/domainsettingrenewdone';
import { PromotionDomainUseTopComponent } from 'Content/script/promotion/domainuse/component/top';
import { PromotionDomainUseEmptyComponent } from 'Content/script/promotion/domainuse/component/empty';
import { PromotionDomainUseOthersComponent } from 'Content/script/promotion/domainuse/component/others';
import { PromotionDomainUseDirective } from 'Content/script/promotion/domainuse/directive/domainuse.directive';

@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [
        PromotionDomainUseDirective,
        PromotionDomainUseDomainSettingRenewDoneComponent,
        PromotionDomainUseTopComponent,
        PromotionDomainUseEmptyComponent,
        PromotionDomainUseOthersComponent,
    ],
    exports: [
        PromotionDomainUseDirective,
    ],
    providers: [
        GetDomainWithDefaultNameServerService,
    ]
})

export class PromotionDomainUseModule { }