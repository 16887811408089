import { Injectable, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Location } from '@angular/common';

import { SpABCookieService } from 'Content/sp/script/service/ab_cookie_service';
import { BaseServiceContainer } from 'Content/common/script/service/base_service_container';
import { VIEWS } from 'Content/script/store/states/view';
import { Router } from '@angular/router';
import { SpNPSEnqueteService } from 'Content/sp/script/service/nps_enquete_service';
import { Store } from '@ngrx/store';

@Injectable()
export class SpServiceContainer extends BaseServiceContainer {

    public constructor(ngZone: NgZone, http: HttpClient, router: Router, location: Location, store: Store) {
        super(ngZone, http, router, location, store);
        
        this.SpABCookieService = new SpABCookieService(http);
        this.SpNPSEnqueteService = new SpNPSEnqueteService(this.HubsService);
    }

    public GetViewMode() {
        return VIEWS.SP;
    }
}
