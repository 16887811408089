import { Component, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { BaseComponent } from 'Content/script/components/base_component';
import { ServiceContainer } from 'Content/script/service/service_container';
import { NotifyMessage, NotifyType } from 'Content/script/service/notify_service';
import { PagerViewModel, PageView } from 'Content/script/components/view_models/pager_view_model';
import { LineNoAndPageNoAccessor } from 'Content/script/libs/common/lineno-pageno-accessor';
import { BulkOderSearchDropdownViewModel, BulkOderSearchDropdownComponent, BulkOderSearchConditionConfig, BulkOderSearchCondition } from 'Content/script/components/share/bulk_order_search_dropdown/bulk_order_search_dropdown.component';

/**
 * 「セット割」申請管理 一覧
 */
@Component({
    selector: 'domain-setting-bulk-applicationlist',
    templateUrl: './list.html'
})

export class DomainSettingBulkApplicationListComponent extends BaseComponent {
    public model: BulkApplicationListViewModel = null;

    @ViewChild("bulkOrderSearchDropdownComponent", { static: true })
    private _bulkOrderSearchDropdownComponent: BulkOderSearchDropdownComponent;

    private _lineNoAndPageNoAccessor = new LineNoAndPageNoAccessor();
    private _lineNoAndPageNo: LineCntAndPageNo = null;
    private _path: string = "";

    public constructor(
        protected router: Router,
        protected route: ActivatedRoute,
        protected serviceContainer: ServiceContainer) {
        super(router, route, serviceContainer);

        this._path = location.pathname;
    }

    public Initialization(): void {
        this.model = new BulkApplicationListViewModel(
            this.queueId,
            this.router,
            this.serviceContainer,
            this._bulkOrderSearchDropdownComponent.viewModel
        );

        var lineNoAndPageNo = this._lineNoAndPageNoAccessor.getItem(this._path);
        if (!!lineNoAndPageNo) {
            this.model.currentPage = lineNoAndPageNo.PageNo;
            if (this.model.pageSizeList.indexOf(lineNoAndPageNo.LineCnt) >= 0) {
                this.model.pageSize = lineNoAndPageNo.LineCnt;
            }
        }
        this.model.Initialization();
    }

    public Dispose(): void {
        this._lineNoAndPageNo = {
            path: this._path,
            LineCnt: this.model.pageSize,
            PageNo: this.model.currentPage
        };
        this._lineNoAndPageNoAccessor.setItem(this._lineNoAndPageNo, this._path);

        this.isDeleteDataTransfer = false;
        super.deleteAllProperty(this);
    }
}

export class BulkApplicationListViewModel extends PagerViewModel {
    public isLoading: boolean;
    public isAllLoading: boolean;
    public pageNo: number = 1;

    public pageSizeList: Array<number> = new Array<number>(10, 20, 50, 100);
    public pageSize: number;
    public currentPage: number;
    public maxPage: number;
    public pageNoList: number[];
    public sortList: { [key: string]: number };

    public sortPriorityList: string[] = ['bulkOrderId'];

    public searchCondition: SearchCondition = new SearchCondition();

    public bulkOrderList: BulkOrderItem[] = [];

    public totalCount: number;
    public roleId: number;
    private readonly _DEFAULT_PAGENO: number = 1;
    private readonly _DEFAULT_LINECNT: number = 10;
    private _isRetrying: boolean = false;
    public isInit: boolean = false;
    public constructor(
        _queueId: number,
        _router: Router,
        _serviceContainer: ServiceContainer,
        private _bulkOrderSearchDropdownViewModel: BulkOderSearchDropdownViewModel) {
        super(_queueId, _router, _serviceContainer);

        this.pageSize = this._DEFAULT_LINECNT;
        this.currentPage = this._DEFAULT_PAGENO;
        this.maxPage = 0;
        this.pageNoList = [];
        this.sortList = {
            'bulkOrderId': 2,
        }
    }

    public Initialization(): void {

        this._bulkOrderSearchDropdownViewModel.setCallBack(this._searchBulkOrder);
        this._bulkOrderSearchDropdownViewModel.setBulkOderSearchConditionConfig(new BulkOderSearchConditionConfig(true, false, true, true, true, false));
        this._bulkOrderSearchDropdownViewModel.setIsDisableView(false);
        this.isInit = true;
        this.requestBulkOrderList();
    }

    /**
     * バルク申請検索コンポーネントの検索を押した際の処理
     */
    private _searchBulkOrder = (): void => {
        this.currentPage = 1;
        this.requestBulkOrderList();
    }

    /**
     * 表示件数変更
     * @param pageSize
     */
    public onChangePageSize(event: Event): void {
        const currentElement: HTMLInputElement = event.currentTarget as HTMLInputElement
        this.pageSize = parseInt(currentElement.value);
        this.currentPage = 1;

        this.requestBulkOrderList();
    }

    /**
     * ページャー変更
     * @param pageNo
     */
    protected onClickPageNo(pageNo: number): void {
        if (pageNo === this.currentPage || 0 >= pageNo || this.maxPage < pageNo) {
            return;
        }
        this.currentPage = pageNo;
        this.requestBulkOrderList();
    }

    /**
     * 検索
     */
    public searchBulkOrder(form: NgForm): void {
        if (form.valid === false) {
            for (var key in form.controls) {
                form.controls[key].markAsDirty();
            }
            return;
        }
        this.pageNo = 1;
        this.requestBulkOrderList();
    }

    /**
     * ソートキーのイベントハンドラ
     */
    public onSortClick(sortKey: string): void {
        switch (sortKey) {
            case 'domainNameML':
                window.dataLayerPush('domainsort');
                break;
        }


        var index: number = this.sortPriorityList.indexOf(sortKey);
        this.sortPriorityList.splice(index, 1);
        this.sortPriorityList.unshift(sortKey);

        if (this.sortList[sortKey] === 1) {
            this.sortList[sortKey] = 2;
        } else {
            this.sortList[sortKey] = 1;
        }
        this.requestBulkOrderList();
    }

    public dataLayerPush(key: string): void {
        window.dataLayerPush(key);
    }

    public submitAction(type:string,bulkOrderId: string): void {
        switch (type) {
            case "detail":
                this.router.navigateByUrl('domain/setting/bulk/detail/' + bulkOrderId);
                break;
            case "change":
                this.router.navigateByUrl('domain/setting/bulk/paymentchange/input/' + bulkOrderId);
                break;
            case "recreate":
                this.router.navigateByUrl('domain/setting/bulk/recreate/input/' + bulkOrderId);
                break;
            default:
        }
    }

    /**
     * バルク申請一覧取得
     */
    public requestBulkOrderList(): void {
        this.isLoading = true;

        const searchCondition: BulkOderSearchCondition = this._bulkOrderSearchDropdownViewModel.getBulkOderSearchCondition();

        var sortList: BulkOrderSortRequestHubDto[] = [];
        for (var key of this.sortPriorityList) {
            sortList.push({ Key: key, Type: this.sortList[key] });
        }

        var requestData: BulkOrderListRequestHubDto = {
            LineCnt: this.pageSize,
            PageNo: this.currentPage,
            BulkOrderId: searchCondition.bulkOrderId,
            CreatedDateFrom: searchCondition.createdDateFrom,
            CreatedDateTo: searchCondition.createdDateTo,
            CompleteDateFrom: searchCondition.completeDateFrom,
            CompleteDateTo: searchCondition.completeDateTo,
            OrderType: searchCondition.orderType,
            PaymentTypeId: searchCondition.paymentTypeId,
            Status: searchCondition.status,
            SortList: sortList
        };

        this.serviceContainer.HubsService.multipleSendManager.Send(
            this.queueId,
            this.serviceContainer.HubsService.hubsManager.domainHubManager.BulkOrderList,
            requestData,
            (resHub: BulkOrderListResponseHubDto) => {
                this._retryrequestBulkOrderList(resHub);
                this.bulkOrderList = [];
                let index = 0;
                for (var bulkOrderItem of resHub.BulkOrderList) {
                    if([1, 2].includes(resHub.RoleId) && (bulkOrderItem.PaymentType === "monthly" || bulkOrderItem.PaymentType === "monthlyCredit")){
                        continue;
                    }
                    else if ([3, 5, 9].includes(resHub.RoleId) && (bulkOrderItem.PaymentType !== "monthly" && bulkOrderItem.PaymentType !== "monthlyCredit")){
                        continue;
                    }
                    var bulkOrder = new BulkOrderItem();
                    bulkOrder.bulkOrderId = bulkOrderItem.BulkOrderId;
                    bulkOrder.status = bulkOrderItem.Status;
                    bulkOrder.statusStr = bulkOrderItem.StatusStr;
                    bulkOrder.paymentType = bulkOrderItem.PaymentType;
                    bulkOrder.paymentTypeStr = bulkOrderItem.PaymentTypeStr;
                    bulkOrder.totalDiscountedTaxedValue = bulkOrderItem.TotalDiscountedTaxedValue;
                    bulkOrder.sumDomain = bulkOrderItem.SumDomain;
                    bulkOrder.createdDate = bulkOrderItem.CreatedDate;
                    bulkOrder.isShowButtonDetail = (['AUTH_FAIL', 'BILLING_FAIL', 'PENDING_REGIST_WAIT', 'PAYMENT_WAIT', 'CANCEL', 'COMPLETE', 'ERROR', 'WAIT_FOR_STAFF', 'DENY'].includes(bulkOrder.status));
                    this.bulkOrderList.push(bulkOrder);
                    index++;
                }
                this.roleId = resHub.RoleId;
                this.totalCount = resHub.TotalCount;
                if (this.totalCount == 0 && this.isInit) {
                    this._bulkOrderSearchDropdownViewModel.setIsDisableView(true);
                }
                this.isInit = false;
                const pageView: PageView = super.getPager(this.totalCount, this.currentPage, this.pageSize);
                this.maxPage = pageView.totalPages;
                this.pageNoList = pageView.pages;
                this.isLoading = false;
            },
            (resHub: BulkOrderListResponseHubDto) => {
                var notify: NotifyMessage = new NotifyMessage(this.serviceContainer.NotifyService.GetNotifyId(), NotifyType.Error, resHub.ErrorMessage);
                this.serviceContainer.NotifyService.AddNotifyMessages([notify]);
            },
            this.NoSession,
            (resHub: BulkOrderListResponseHubDto) => {
                if (this._isRetrying) {
                    return;
                }
                this.isLoading = false;
            },
            this.UnExceptedError
        );
    }

    /**
     * データ再取得判定
     */
    private _retryrequestBulkOrderList(resHub: BulkOrderListResponseHubDto): void {
        // 保持していたページNoのページがデータ数減少により消滅していた場合、
        // デフォルトの表示件数・ページNoでデータ取得し直す
        if (resHub.BulkOrderList.length === 0 && this.currentPage > 1) {
            this._isRetrying = true;
            this.pageSize = this._DEFAULT_LINECNT;
            this.currentPage = this._DEFAULT_PAGENO;
            this.requestBulkOrderList();
        } else {
            this._isRetrying = false;
        }
    }
}

class SearchCondition {
    public bulkOrderId: string = '';
    public status: string = '';
    public paymentTypeId: string = '';
    public createdDateFrom: string = '';
    public createdDateTo: string = '';
    public completeDateFrom: string = '';
    public completeDateTo: string = '';
}

export class BulkOrderItem {
    public bulkOrderId: string;
    public orderType: string;
    public orderTypeStr: string
    public status: string;
    public statusStr: string;
    public paymentType: string;
    public paymentTypeStr: string;
    public totalDiscountedTaxedValue: string;
    public sumDomain: string;
    public createdDate: string;
    public completeDate: string;
    public isProxyFlg: boolean;
    public isAutoRenew: boolean;
    public isShowButtonDetail: boolean;


    public constructor() {
        this.bulkOrderId = '';
        this.status = '';
        this.statusStr = '';
        this.paymentType = '';
        this.paymentTypeStr = '';
        this.totalDiscountedTaxedValue = '';
        this.sumDomain = '';
        this.createdDate = '';
        this.isProxyFlg = false;
        this.isAutoRenew = false;
        this.orderTypeStr = '';
        this.orderType = '';
        this.isShowButtonDetail = false;
    }
}
