import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { serverOrderRedirection } from 'Content/script/libs/redirection/orderRedirection';
import { SpServiceContainer } from '../../../service/service_container';
import { CustomizedEvents } from '../../../../../script/libs/abtest/custom-event.util';

@Component({
    selector: 'sp-rs-recommend',
    templateUrl: './sp_rs_recommend.html'
})

export class SpRsRecommendComponent implements OnChanges {
    @Input("routerPath") public routerPath: string;
    @Input("isABNew_RsRecommendWithNameServer") public isABNew_RsRecommendWithNameServer: boolean;
    
    @Output() doLoading = new EventEmitter();

    public hasTarget: boolean; // レコメンド表示対象となるアイテム、その他アカウント条件を保持しているか
    public hasRSOM: boolean; // RS基盤のお名前メールのみを保持しているか
    public isFirstView: boolean; // Top画面への初回遷移しか表示しない
    public isInitialSearch: boolean;
    public isEvarFired: boolean;

    public constructor(
        public router: Router,
        public route: ActivatedRoute,
        public serviceContainer: SpServiceContainer) {
        this.hasTarget = false;
        this.hasRSOM = false;
        this.isFirstView = true;
        this.isInitialSearch = true;
    }

    public CacheBusting(uri: string): string {
        return window.CacheBusting(uri);
    }

    public ngOnChanges(changes: SimpleChanges) {
        // routerPathの変更を検知して表示対象PathならviewModelのデータ取得メソッドを呼び出し
        let routerChange = changes["routerPath"];
        if (routerChange && routerChange.currentValue != undefined) {
            if (routerChange.currentValue.includes('/top') || routerChange.currentValue.includes('/domain/setting/renew/list')) {
                this.fetchRecommendInfo();
            }
        }
    }

    public get isDisplay(): boolean {
        let isDisplay =  this.isFirstView && (this.hasTarget || this.hasRSOM);
        if (!this.isEvarFired && isDisplay) {
            CustomizedEvents.dispatchFireEventRsRecommend({ eventValue: this.getEventValue });
            this.serviceContainer.SpABCookieService.AdjustRsRecommendWithNameServer.tests.AdjustRsRecommendWithNameServer.dispatchEvent_sendCookiesBaggage({ pathName: this.getPathname });
            this.isEvarFired = true;
        }
        return isDisplay;
    }

    public get rsApplyButtonId(): string {
        return location.pathname == "/sp/domain/setting/renew/list" ? "navi_sp_renew_rsmodal_domwith" : "navi_sp_top_rsmodal_domwith";
    }

    public get domainApplyButtonId(): string {
        return location.pathname == "/sp/domain/setting/renew/list" ? "navi_sp_renew_rsmodal_order" : "navi_sp_top_rsmodal_order";
    }

    public get getPathname() {
        return location.pathname == "/sp/domain/setting/renew/list" ? "navi_renew_" : "navi_top_";
    }

    public get getEventValue() {
        return location.pathname == "/sp/domain/setting/renew/list" ? "event618" : "event617";
    }

    public fetchRecommendInfo() {
        // 検索は1度しか実行しない
        if (!this.isInitialSearch) {
            return;
        }
        this.isInitialSearch = false;

        // お名前メール(RS)ユーザー向けのRSリコメンドの表示判定
        this.serviceContainer.HubsService.multipleSendManager.Send(
            this.serviceContainer.HubsService.multipleSendManager.GetComponentId(),
            this.serviceContainer.HubsService.hubsManager.topHubManager.GetRSRecommendationInfoForRSMailUser,
            null,
            (response: GetRSRecommendationInfoForRSMailUserResponseHubDto) => {
                this.hasRSOM = response.IsDisplay;
            },
            () => {
                this.hasRSOM = false;
            },
            null,
            null,
            () => {
                this.hasRSOM = false;
            }
        );

        // 表示優先度：お名前メール(RS)ユーザー向け ＞ 標準
        if (this.hasRSOM) {
            return;
        }

        this.serviceContainer.HubsService.multipleSendManager.Send(
            this.serviceContainer.HubsService.multipleSendManager.GetComponentId(),
            this.serviceContainer.HubsService.hubsManager.topHubManager.GetRsRecommendModalCondition,
            null,
            (response: GetRsRecommendModalConditionResponse) => {
                this.hasTarget = response.HaveDomainWithDefaultNameServer ||
                                 response.HasDomainWithRentalNameServer ||
                                 (this.isABNew_RsRecommendWithNameServer && response.HasDomainInNameServersList);
                if (this.isFirstView) {
                    if (this.hasTarget) {
                        sessionStorage.setItem("isSpShowRsRecommend", '1');
                    }
                }
            },
            (response: GetRsRecommendModalConditionResponse) => {
                this.hasTarget = false;
            },
            null,
            null,
            (response: GetRsRecommendModalConditionResponse) => {
                this.hasTarget = false;
            },
        );
    }

    public onRsApplyButtonClicked(): void {
        this._orderPlan();
        this._closeModal();
    }

    public onDomainApplyButtonClicked(): void {
        let domwithBtnId = location.pathname == "/sp/domain/setting/renew/list" ? "navi_sp_renew_rsmodal_domwith" : "navi_sp_top_rsmodal_domwith";
        window.dataLayerPush(domwithBtnId);
        this.router.navigate(['/sp/domain/rsregist']);
        this._closeModal();
    }

    public onChangePlanButtonClicked(): void {
        let orderBtnId = location.pathname == "/sp/domain/setting/renew/list" ? "navi_sp_renew_rsommodal" : "navi_sp_top_rsommodal";
        window.dataLayerPush(orderBtnId);
        this.router.navigate(['/mail']);
        this._closeModal();
    }

    public onCloseButtonClicked(): void {
        let closeBtnId = location.pathname == "/sp/domain/setting/renew/list" ? "navi_sp_renew_rsmodal_close" : "navi_sp_top_rsmodal_close";
        if (this.hasRSOM) {
            closeBtnId = location.pathname == "/sp/domain/setting/renew/list" ? "navi_sp_renew_rsommodal_close" : "navi_sp_top_rsommodal_close";
        }
        window.dataLayerPush(closeBtnId);
        this._closeModal();
    }

    public onClickOutsideCloseModal(event: Event) {
        if (event.currentTarget === event.target) {
            let closeBtnId = location.pathname == "/sp/domain/setting/renew/list" ? "navi_sp_renew_rsmodal_close" : "navi_sp_top_rsmodal_close";
            if (this.hasRSOM) {
                closeBtnId = location.pathname == "/sp/domain/setting/renew/list" ? "navi_sp_renew_rsommodal_close" : "navi_sp_top_rsommodal_close";
            }
            window.dataLayerPush(closeBtnId);
            this._closeModal();
        }
    }

    private _closeModal(): void {
        this.isFirstView = false;
        this.hasTarget = false;
        this.hasRSOM = false;
    }

    /**
     * 商材申し込みのシングルサインオン
     */
    private _orderPlan(): void {
        this.doLoading.emit();
        var navigationClass = "";
        switch (location.pathname) {
            case "/sp/domain/setting/renew/list":
                navigationClass = "SD_ORDER_FROM_DOMAIN_RENEW_MODAL";
                break;

            case "/sp/top":
                navigationClass = "SD_ORDER_FROM_TOP_MODAL";
                break;

            default:
                break;
        }

        if (!navigationClass) {
            return;
        }

        var request: ServerOrderRedirectionRequestHubDto = {
            NavigationClass: navigationClass,
            RedirectionParams: []
        };

        serverOrderRedirection(
            document,
            this.serviceContainer,
            this.serviceContainer.HubsService.multipleSendManager,
            this.router,
            this.serviceContainer.HubsService.multipleSendManager.GetComponentId(),
            request
        );
    }
}
