import { Directive, forwardRef } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, Validators } from '@angular/forms';

const DATE_VALIDATOR: any = {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => DateValidator),
    multi: true
};

@Directive({
    selector: '[date][formControlName],[date][formControl],[date][ngModel]',
    providers: [DATE_VALIDATOR]
})

export class DateValidator implements Validator {
    validate(c: AbstractControl): { [key: string]: any } {
        return CustomValidators.date(c);
    }
}

export class CustomValidators {

    static date(control: AbstractControl): { [key: string]: boolean } {
        if (isPresent(Validators.required(control))) return null;

        let v: string = control.value;

        // フォ－マット(yyyy/mm/dd)の確認
        let searchDateFormatPattern: string = '([0-9]{4})/(([1][0-2])|([0][1-9]))/(([0-2][0-9])|([3][0-1]))';
        if (new RegExp(searchDateFormatPattern).test(v) == false) {
            return { 'date': true };
        }

        // 実在する日付かどうかの確認
        var dateYear: number = parseInt(v.split('/')[0]);
        var dateMonth: number = parseInt(v.split('/')[1]) - 1;
        var dateDay: number = parseInt(v.split('/')[2]);
        //console.log('dateYear:', dateYear, ', dateMonth:', dateMonth, ', dateDay:', dateDay)
        var date: Date = new Date(dateYear, dateMonth, dateDay);
        if ((date.getFullYear() == dateYear && date.getMonth() == dateMonth && date.getDate() == dateDay) == false) {
            return { 'date': true };
        }

        return null;
    }
}

export function isPresent(obj: any): boolean {
    return obj !== undefined && obj !== null;
}