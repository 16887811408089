import { Component, Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { BaseComponent, BaseViewModel } from 'Content/script/components/base_component';
import { IConveniInfoDto, IDataTransferDomainRenewDoneDto, IDomainApplyingDto, IFreeDomainApplyingDto, IItemApplyingDto, IServerApplyingDto } from 'Content/script/dtos/data_transfer_domain_renew_done_dto.d';
import { CustomizedEvents } from 'Content/script/libs/abtest/custom-event.util';
import { AnalyticsUtil } from 'Content/script/libs/adobeanalytics/analytics.util';
import { BankAccountType } from 'Content/script/libs/common/bankAccountType';
import { DomainConvenienceType } from 'Content/script/libs/common/convenienceType';
import { DomainPaymentType } from 'Content/script/libs/common/domainPaymentType';
import { StringUtil } from 'Content/script/libs/utils/string.util';
import { Service } from 'Content/script/service/account_info_service';
import { ServiceContainer } from 'Content/script/service/service_container';

declare var $: JQueryStatic;

@Injectable()
export class ForDomainRenewDoneRoutingCanActivate  {
    public constructor(private serviceContainer: ServiceContainer, private router: Router) {}

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        let transferData: IDataTransferDomainRenewDoneDto = this.serviceContainer.DataTransferService.getData<IDataTransferDomainRenewDoneDto>(this.serviceContainer.DataTransferService.DomainRenewDoneKey);
        
        if (!transferData) {
            this.router.navigate(['/domain/setting/renew/list']);
            return false;
        }
        return true;
    }
}

/**
 * ドメイン契約更新 完了
 */

@Component({
    selector: 'domain-setting-renew-done',
    templateUrl: './done.html'
})

export class DomainSettingRenewDoneComponent extends BaseComponent {

    public viewModel: DomainSettingRenewDoneViewModel;

    public constructor(router: Router, route: ActivatedRoute, serviceContainer: ServiceContainer) {
        super(router, route, serviceContainer);
        const domainId: string = this.route.snapshot.params['domainId'];
        this.viewModel = new DomainSettingRenewDoneViewModel(domainId, this.queueId, this.router, this.serviceContainer);
    }

    public Initialization(): void {
        this.viewModel.init();
    }

    public Dispose(): void {
        this.serviceContainer.AccountInfoService.Reset();
        const accountInfoPromise = this.serviceContainer.AccountInfoService.GetAccountInfo();

        accountInfoPromise.then((accountInfo) => {
            this.isDeleteDataTransfer = false;
            super.deleteAllProperty(this);
        }).catch((errorType: Service.ErrorType) => {
            super.deleteAllProperty(this);
        });
    }
}

class DomainSettingRenewDoneViewModel extends BaseViewModel implements IDomainSettingRenewDoneViewModel {
    public readonly DomainPaymentType = DomainPaymentType;
    public readonly DomainConvenienceType = DomainConvenienceType;
    
    public recommendMessages: RecommendMessage[] = [];
    public paymentType: DomainPaymentType = DomainPaymentType.None;
    public isOnamaeUser: boolean = false;
    public conveniCode: DomainConvenienceType;
    public conveniInfo: IConveniInfoDto;
    public totalPriceYen: string = '';
    public totalPriceWithoutDiscountYen: string = '';
    public registeredMaskedCreditNumber: string;

    public isShowGmoPoint: boolean = false;
    public gmoPointPt: string;

    public isSslNewApply: boolean = false;

    public isGmoElectricNewApply: boolean = false;

    public isHostingUnpaid: boolean = false;

    public isLoading: boolean = false;
    public isProcessingFetchPaymentDetails: boolean = false;
    public isABNew: boolean = false;

    public domains: DomainsInList;
    public servers: ServersInList;
    public freeDomains: FreeDomainsInList;
    public hasFreeDomain: boolean = false;
    public isCreateDomainSuccess: boolean = false;
    public freeRegistDomainNameMl: string = '';
    public fees: FeesInList;

    public invoiceId: string;
    public hasPaymentDetailsError: boolean;

    public bankPaymentDetails = new BankPaymentDetails();

    public isProcessingFetchMonitoringTextInfo: boolean = false;
    
    public hasMonitoringTextInfoError: boolean;
    public monitoringTextInfo: string[] = [];
    public displayDomainRecommendedForMonitoring: string = '';
    public nondisplayDomainRecommendedForMonitoring: string = '';
    public isDisplayDomainMonitoringRecommend: boolean = false;
    public displayDomainRecommendedForMonitorings: string[] = [];
    public isDisplayDomainProtectRecommend: boolean = false;
    public displayDomainRecommendedForProtecting: string = '';
    public conveniencePaymentDetails = new ConveniencePaymentDetails();
    public renewDomains: RenewDomainItem[] = [];

    public renewDomainHeader: RenewDomainHeader = new RenewDomainHeader(false, false, '0');
    private _multiInquiryFlag = new Flag();
    public hostingAllowedDomainName: string = '';
    public hostingRecommendId: string = '';
    private _canRecommendHosting: Array<{ domainName: string, hasRecommend: boolean }> = [{ domainName: '', hasRecommend: false }];
    public checkedRenewDomains: RenewDomainItem[];

    public constructor(private readonly _domainId: string, queueId: number, router: Router, serviceContainer: ServiceContainer) {
        super(queueId, router, serviceContainer);
    }

    public async init() {
        const transferData = this.serviceContainer.DataTransferService.getData<IDataTransferDomainRenewDoneDto>(this.serviceContainer.DataTransferService.DomainRenewDoneKey);
        if (!transferData) {
            this.router.navigate(['/domain/setting/renew/list']);
            return;
        }
        if (transferData.isFireEvent413) {
            CustomizedEvents.dispatchRenewDoneFireEvent();
        }
        if (transferData.isFireEvent581) {
            CustomizedEvents.dispatchRenewDomainHaveNsDefault({ hasRs: false });
        }
        if (transferData.isFireEvent582) {
            CustomizedEvents.dispatchRenewDomainHaveNsDefault({ hasRs: true });
        }
        this._requestGetRecommendHtml();

        this.invoiceId = transferData.invoiceId;

        this.paymentType = transferData.domainPaymentType;
        this.conveniInfo = transferData.conveniInfo;
        this.conveniCode = !this.conveniInfo ? DomainConvenienceType.None : parseInt(this.conveniInfo.conveniCode);

        this.totalPriceYen = StringUtil.NumberToStringWithComma(transferData.totalPrice);
        this.totalPriceWithoutDiscountYen = StringUtil.NumberToStringWithComma(transferData.totalPrice + transferData.gmoPoint);

        this.registeredMaskedCreditNumber = transferData.registeredMaskedCreditNumber;

        this.isShowGmoPoint = transferData.isGmoCollaboration && !transferData.hasCoupon;
        this.gmoPointPt = StringUtil.NumberToStringWithComma(transferData.gmoPoint);

        this.isSslNewApply = transferData.isSslNewApply;
        this.isHostingUnpaid = transferData.isHostingUnpaid;
        this.isOnamaeUser = transferData.isOnamaeUser;
        const domainApplyingsDtos: IDomainApplyingDto[] = transferData.domainApplyings;

        this.domains = new DomainsInList(domainApplyingsDtos.map(x => new DomainApplyings(x)));

        const serverApplyingsDtos: IServerApplyingDto[] = transferData.serverApplyings;
        this.servers = new ServersInList(serverApplyingsDtos.map(x => new ServerApplyings(x)));
        
        this.isCreateDomainSuccess = transferData.isCreateFreeDomainSuccess;
        this.freeRegistDomainNameMl = transferData.freeDomainRegistNameMl;
        this.hasFreeDomain = transferData.hasFreeDomain;
        if (this.hasFreeDomain && this.isCreateDomainSuccess) {
            const freeDomainApplyingsDtos: IFreeDomainApplyingDto[] = transferData.domainFreeApplyings;
            this.freeDomains = new FreeDomainsInList(freeDomainApplyingsDtos.map(x => new FreeDomainApplyings(x)));
        }
        var dtoArr: IItemApplyingDto[];
        if (transferData.domainPaymentType === DomainPaymentType.Convenience && transferData.totalPrice > 0) {
            dtoArr = [{ contentType: ApplyingDetailContentType.ConveniFee, price: this.conveniInfo.price, unTaxPrice: this.conveniInfo.unTaxPrice } as IItemApplyingDto];
        } else {
            dtoArr = [];
        }
        this.fees = new FeesInList(dtoArr.map(x => new FeeApplyings(x)));

        this.hasPaymentDetailsError = false;

        this.fetchPaymentDetails();
        //クレジットカード払いの場合にドメイン更新訴求表示判定を行う
        this._requestGetRenewDomains(); 
        this.checkedRenewDomains = this.renewDomains.filter(x => x.isCheckedDomain);

        let promise = this.fetchMonitoringTextInfo();
        promise.then(x => {
            this.displayDomainRecommendedForMonitorings = this.getDomainRecommendedForMonitoring(domainApplyingsDtos);
            this.displayDomainRecommendedForMonitoring = this.displayDomainRecommendedForMonitorings[0];
            var splitString = this.displayDomainRecommendedForMonitorings[1].split(".");
            this.nondisplayDomainRecommendedForMonitoring = splitString[0];
            if (this.displayDomainRecommendedForMonitoring) {
                this.isDisplayDomainMonitoringRecommend = true;
            }
        });

        this.isDisplayDomainProtectRecommend = transferData.isShowDomainProtection;
        this.displayDomainRecommendedForProtecting = transferData.displayDomainRecommendedForProtecting;

        CustomizedEvents.dispatchViewModelLoaded<IDomainSettingRenewDoneViewModel>('DomainSettingRenewDoneViewModel', this);

        dataLayer.push({
            'event': 'event_sendDomainExpirationDaysRemaining',
            'domainExpirationDaysRemaining': transferData.domainExpirationDaysRemaining
        });
    }
    public isSkipModalConfirm: boolean = false;

    public get isCreditCardPayment(): boolean {
        return this.paymentType == DomainPaymentType.NewCreditCard || this.paymentType == DomainPaymentType.RegisteredCreditCard;
    }

    public get isBankPayment(): boolean {
        return this.paymentType == DomainPaymentType.Bank;
    }

    public get isConveniencePayment(): boolean {
        return this.paymentType == DomainPaymentType.Convenience;
    }

    public get isAllLoading(): boolean {
        return this.isLoading && this.isProcessingFetchPaymentDetails; 
    }

    public get displayPaymentMethodTitle(): string {
        switch(this.paymentType) {
            case DomainPaymentType.NewCreditCard:
            case DomainPaymentType.RegisteredCreditCard:
                return "ドメインのお支払い（クレジットカード決済）";
            case DomainPaymentType.Bank:
                return "ドメインのお支払い（銀行振込）";
            case DomainPaymentType.Convenience:
                return "ドメインのお支払い（コンビニ振込）";
            case null:
            default:
                return "";
        }
    }

    public get displayServerPaymentMethodTitle(): string {
        switch(this.paymentType) {
            case DomainPaymentType.NewCreditCard:
            case DomainPaymentType.RegisteredCreditCard:
                return "サーバーのお支払い（クレジットカード決済）";
            case DomainPaymentType.Bank:
                return "サーバーのお支払い（銀行振込）";
            case DomainPaymentType.Convenience:
                return "サーバーのお支払い（コンビニ振込）";
            case null:
            default:
                return "";
        }
    }

    public get displayServerPaymentMethodText(): string {
        switch(this.paymentType) {
            case DomainPaymentType.NewCreditCard:
            case DomainPaymentType.RegisteredCreditCard:
                return "ご指定のクレジットカードで登録が完了しました。";
            case DomainPaymentType.Bank:
                return "ご指定の銀行振込で登録が完了しました。";
            case DomainPaymentType.Convenience:
                return "ご指定のコンビニ振込で登録が完了しました。";
            case null:
            default:
                return "";
        }
    }

    public get displayBoxBorderText(): string {
        if (this.isCreditCardPayment) {
            return "ご指定のクレジットカード決済が完了しました。";
        }
        return "";
    }

    public get isDisplayPaymentMethod(): boolean {
        if (this.paymentType == DomainPaymentType.None ||
            this.paymentType == DomainPaymentType.Monthly ||
            !this.paymentType ||
            this.hasPaymentDetailsError
            ) {
                return false;
            }
        return true;
    }

    private _requestGetRecommendHtml(): void {
        this.isLoading = true;
        this.serviceContainer.HubsService.multipleSendManager.Send(
            this.queueId,
            this.serviceContainer.HubsService.hubsManager.authHubManager.GetRecommendHtml,
            null,
            (response: RecommendMessageResponseHubDto) => {
                this.recommendMessages = [];
                for (let recommendMessage of response.RecommendMessages) {
                    this.recommendMessages.push(recommendMessage);
                }
            },
            (response: RecommendMessageResponseHubDto) => {
                this.recommendMessages = [];
            },
            this.NoSession,
            (response: RecommendMessageResponseHubDto) => {
                this.isLoading = false;
            },
            this.UnExceptedError
        );
    }

    public fetchPaymentDetails(){
        if( !this.invoiceId || !this.paymentType){
            return;
        }

        switch(this.paymentType){
            case DomainPaymentType.Bank:
                this.fetchBankPaymentDetails();
                break;
            case DomainPaymentType.Convenience:
                this.fetchConveniencePaymentDetails();
                break;
            default:
                break;
        }
    }

    // 銀行支払い時のお支払情報取得
    private fetchBankPaymentDetails() {
        this.isProcessingFetchPaymentDetails = true;

        var req: GetPayeeBankInfoRequestHubDto = {
            InvoiceId: this.invoiceId,
        }

        this.serviceContainer.HubsService.multipleSendManager.Send(
            this.queueId,
            this.serviceContainer.HubsService.hubsManager.invoiceHubManager.GetPayeeBankInfo,
            req,
            (res: GetPayeeBankInfoResponseHubDto) => {
                let accountTypeNumber = parseInt(res.AccountType);
                let bankAccountType = accountTypeNumber == BankAccountType.Savings ? "普通" : "当座";
                let payee = res.BankName + "(" + res.BankId + ")<br>"
                        + res.BranchName + "(" + res.BranchId + ")<br>"
                        + bankAccountType + " " + res.AccountNumber + " " + res.AccountName;

                this.bankPaymentDetails = {
                    payee: payee,
                    paymentLimitDate: res.PaymentLimitDate,
                    totalPrice: this.totalPriceYen
                }
                
                this.isProcessingFetchPaymentDetails = false;
            },
            (res: GetPayeeBankInfoResponseHubDto) => {
                this.hasPaymentDetailsError = true;
                this.isProcessingFetchPaymentDetails = false;
            },
            this.NoSession,
            (res: GetPayeeBankInfoResponseHubDto) => {
                this.isProcessingFetchPaymentDetails = false;
            },
            this.UnExceptedError
        );
    }

    // コンビニエンスストア支払い時のお支払情報取得
    private fetchConveniencePaymentDetails(): void {
        this.isProcessingFetchPaymentDetails = true;

        var req: InvoiceDomainPaymentConvenienceDetailRequestHubDto = {
            LocalInvoiceId: this.invoiceId,
        };

        this.serviceContainer.HubsService.multipleSendManager.Send(
            this.queueId,
            this.serviceContainer.HubsService.hubsManager.invoiceHubManager.InvoiceDomainPaymentConvenienceDetail,
            req,
            (res: InvoiceDomainPaymentConvenienceDetailResponseHubDto) => {
                this.conveniencePaymentDetails.conveniCode = res.ConveniCode;
                this.conveniencePaymentDetails.storeName = res.StoreName;
                this.conveniencePaymentDetails.billingPrice = res.BillingPrice;
                this.conveniencePaymentDetails.paymentUrl = res.PaymentUrl;
                this.conveniencePaymentDetails.companyNumber = res.CompanyNumber;
                this.conveniencePaymentDetails.orderNumber = res.OrderNumber;
                this.conveniencePaymentDetails.conveniLimitDate = res.ConveniLimitDate;

                this.isProcessingFetchPaymentDetails = false;
            },
            (res: InvoiceDomainPaymentConvenienceDetailResponseHubDto) => {
                this.hasPaymentDetailsError = true;
                this.isProcessingFetchPaymentDetails = false;
            },
            this.NoSession,
            (res: InvoiceDomainPaymentConvenienceDetailResponseHubDto) => {
                this.isProcessingFetchPaymentDetails = false;
            },
            this.UnExceptedError
        );
    }

    public onClickSettingPage(): void {
        (this._domainId !== void 0 && this._domainId !== null) ?
            this.router.navigate(['/domain/setting/renew/list/' + this._domainId]) :
            this.router.navigate(['/domain/setting/renew/list']);
    }

    /**
     * ワンクリックモーダルへの遷移
    **/
    public onClickOneClickModalButton(): void {
        const transferData: IDataTransferUnKeepPageCountDto = {
            unKeepPageCount: true
        };
        let conveniCode: string = this.isConveniencePayment ? this.conveniCode.toString() : "";
        this.serviceContainer.DataTransferService.setData(transferData, this.serviceContainer.DataTransferService.UnKeepPageCountKey);
        const checkedRenewDomains: RenewDomainItem[] = this.renewDomains.filter(x => x.isCheckedDomain);
        this.isLoading = true;
        
        this.isProcessingFetchPaymentDetails = true;
        if (this.paymentType === DomainPaymentType.Convenience) {

            const paymentHubDto: DomainPaymentRequestHubDto = {
                PaymentType: parseInt(DomainPaymentType.Convenience.toString()),
                ConveniCode: this.conveniInfo.conveniCode,
                UserNameKana: this.conveniInfo.userNameKana,
                CrOrderId: '',
                CreditNumber: '',
                CreditHolder: '',
                CreditPeriodMonth: '',
                CreditPeriodYear: '',
                SecurityCode: '',
            };

            const req: DomainPaymnetValidatorRequestHubDto = {
                DomainPaymentRequestHubDto: paymentHubDto
            };

            this.serviceContainer.HubsService.multipleSendManager.Send(
                this.queueId,
                this.serviceContainer.HubsService.hubsManager.invoiceHubManager.DomainPaymentValidator,
                req,
                (res: DomainPaymnetValidatorResponseHubDto) => {
                    const domainRenewData: IDataTransferDomainRenewDto = {
                        unValidatedDomains: checkedRenewDomains.map(x => x.convertDto() as IDataTransferRenewDomainItemDto),
                        validatedDomains: checkedRenewDomains.map(x => {
                            return {
                                domainId: x.domainId,
                                domainName: x.domainName,
                                domainNameMl: x.domainNameMl,
                                tld: x.tld,
                                expirationDate: x.expirationDate,
                                isRegistryPremium: x.isRegistryPremium,
                                canSetAutoRenew: x.canSetAutoRenew,
                                updateYear: "1",
                                updateYearProductId: x.updateYearItems.find(y => y.year === x.selectedUpdateYear).productId,
                                isAppliedWhoisProxy: x.isAppliedWhoisProxy,
                                isWhoisProxy: false,
                                isAppliedWhoisMailFwd: x.isAppliedWhoisMailFwd,
                                isWhoisMailFwd: x.isAppliedWhoisMailFwd,
                                IsAppliedDomainProtect: x.isAppliedDomainProtect,
                                isDomainProtection: x.isAppliedDomainProtect,
                                isAppliedSsl: x.isAppliedSsl,
                                isSsl: false,
                                isHosting: false,
                                isProtectedWhois: x.isProtectedWhois,
                                isProtectedAutoRenew: x.isProtectedAutoRenew,
                                isAutoRenewActive: x.isAutoRenewActive,
                                couponCode: x.couponCode,
                            } as IDataTransferSelectedDomainDto;
                        }),
                        hostingRecommendId: this.hostingRecommendId,
                        hostingDomainName: this.hostingAllowedDomainName,
                        isHostingUnpaid: this.isHostingUnpaid,
                        isDisallowPaymentBank: checkedRenewDomains.some(x => x.isDisallowPaymentBank),
                        isDisallowPaymenNetCvs: checkedRenewDomains.some(x => x.isDisallowPaymenNetCvs),
                        hasSetAutoRenewPayment: false,
                        paymentType: this.paymentType,
                        conveniInfo: { ConveniCode: conveniCode, UserNameKana: this.conveniInfo.userNameKana } as DomainRenewUpdatePaymentConveniInfoRequestHubDto,
                        convenienceFee: res.ConvenienceFee,
                        convenienceUnTaxFee: res.ConvenienceUnTaxFee,
                        hasCoupon: checkedRenewDomains.some(x => x.couponCode.length > 0),
                        isOnamaeUser: this.isOnamaeUser
                    } as IDataTransferDomainRenewDto;
                    this.serviceContainer.DataTransferService.setData(domainRenewData, this.serviceContainer.DataTransferService.DomainRenewFromDoneKey);
                    this.router.navigate(['/domain/setting/renew/list']);
                },
                (res: DomainPaymnetValidatorResponseHubDto) => {

                },
                (res: DomainPaymnetValidatorResponseHubDto) => {
                    const queryparams = AnalyticsUtil.getQueryParamsOnFirstRender(window.RouterPath);
                    this.router.navigate(['/login'], { queryParams: queryparams });
                    return;
                },
                (res: DomainPaymnetValidatorResponseHubDto) => {
                },
                (res: DomainPaymnetValidatorResponseHubDto) => {
                    // 致命的エラー
                    this.router.navigateByUrl('/error/server');
                    return;
                }
            );
        } else {
            const domainRenewData: IDataTransferDomainRenewDto = {
                unValidatedDomains: checkedRenewDomains.map(x => x.convertDto() as IDataTransferRenewDomainItemDto),
                validatedDomains: checkedRenewDomains.map(x => {
                    return {
                        domainId: x.domainId,
                        domainName: x.domainName,
                        domainNameMl: x.domainNameMl,
                        tld: x.tld,
                        expirationDate: x.expirationDate,
                        isRegistryPremium: x.isRegistryPremium,
                        canSetAutoRenew: x.canSetAutoRenew,
                        updateYear: "1",
                        updateYearProductId: x.updateYearItems.find(y => y.year === x.selectedUpdateYear).productId,
                        isAppliedWhoisProxy: x.isAppliedWhoisProxy,
                        isWhoisProxy: false,
                        isAppliedWhoisMailFwd: x.isAppliedWhoisMailFwd,
                        isWhoisMailFwd: x.isAppliedWhoisMailFwd,
                        IsAppliedDomainProtect: x.isAppliedDomainProtect,
                        isDomainProtection: x.isAppliedDomainProtect,
                        isAppliedSsl: x.isAppliedSsl,
                        isSsl: false,
                        isHosting: false,
                        isProtectedWhois: x.isProtectedWhois,
                        isProtectedAutoRenew: x.isProtectedAutoRenew,
                        isAutoRenewActive: x.isAutoRenewActive,
                        couponCode: x.couponCode,
                    } as IDataTransferSelectedDomainDto;
                }),
                hostingRecommendId: this.hostingRecommendId,
                hostingDomainName: this.hostingAllowedDomainName,
                isHostingUnpaid: this.isHostingUnpaid,
                isDisallowPaymentBank: checkedRenewDomains.some(x => x.isDisallowPaymentBank),
                isDisallowPaymenNetCvs: checkedRenewDomains.some(x => x.isDisallowPaymenNetCvs),
                hasSetAutoRenewPayment: false,
                paymentType: this.paymentType,
                conveniInfo: { ConveniCode: conveniCode, UserNameKana: '' } as DomainRenewUpdatePaymentConveniInfoRequestHubDto,
                hasCoupon: checkedRenewDomains.some(x => x.couponCode.length > 0),
                isOnamaeUser: this.isOnamaeUser,
                registeredMaskedCreditNumber: this.registeredMaskedCreditNumber
            } as IDataTransferDomainRenewDto;
            this.serviceContainer.DataTransferService.setData(domainRenewData, this.serviceContainer.DataTransferService.DomainRenewFromDoneKey);
            this.router.navigate(['/domain/setting/renew/list']);
        }

    }
    private _getActiveDomains(): RenewDomainItem[] {
        return this.renewDomains.filter(x => x.isActiveDomain);
    }

    public _getCheckedDomains(): RenewDomainItem[] {
        return this._getActiveDomains().filter(x => x.isCheckedDomain);
    }

    /**
     * (すべての)ドメイン名のチェック時
     */
    public onChangeRenewDomainAll(): void {
        this._getActiveDomains().forEach(x => {
            x.isCheckedDomain = this.renewDomainHeader.isCheckedDomainAll;
            x.isEnableWhoisProduct = x.isCheckedDomain && x.canApplyWhoisProduct && x.isAppliedWhoisProxy === false && x.isAppliedWhoisMailFwd === false;
            if (this.renewDomainHeader.isCheckedDomainAll === false) {
                x.totalUnTaxPrice = '-';
            }
        });
        this._requestCheckDomainRenewHostingOrder();
        this._toggleDomainNameAll();
    }
    /**
    * ドメイン名のチェック時
    */
    public onChangeRenewDomain(): void {
        this._getActiveDomains().forEach(x => {
            if (x.isCheckedDomain === false) {
                x.totalUnTaxPrice = '-';
            }
        });

        this._requestCheckDomainRenewHostingOrder();
        this._toggleDomainNameAll();
    }
    /**
     * 全体ドメインの選択状態確認
     */
    private _toggleDomainNameAll(): void {
       
        this.checkedRenewDomains = this.renewDomains.filter(x => x.isCheckedDomain);
        this.renewDomainHeader.isCheckedDomainAll = this._getActiveDomains().length > 0 && this._getActiveDomains().every(x => x.isCheckedDomain);
    }

    /**
     * ホスティング同時申し込み可能か確認を行う。
     */
    private _requestCheckDomainRenewHostingOrder(): void {

        const targetDomains: RenewDomainItem[] = this.renewDomains.filter(x => x.isCheckedDomain);

        const reqHubDto: DomainRenewHostingOrderCheckRequestHubDto = {
            RenewDomains: targetDomains.map(x => {
                return {
                    DomainName: x.domainName,
                    Tld: x.tld
                } as DomainRenewHostingOrderCheckDomainRequestHubDto;
            })
        } as DomainRenewHostingOrderCheckRequestHubDto;

        this._multiInquiryFlag.raise();
        this.serviceContainer.HubsService.multipleSendManager.Send(
            this.queueId,
            this.serviceContainer.HubsService.hubsManager.domainRenewHubManager.CheckDomainRenewHostingOrder,
            reqHubDto,
            (resHubDto: DomainRenewHostingOrderCheckResponseHubDto) => {
                this.hostingAllowedDomainName = '';
                this.hostingRecommendId = '';
                if (resHubDto.IsAllowed) {
                    this.hostingAllowedDomainName = resHubDto.AllowedDomainName;
                    this.hostingRecommendId = resHubDto.HostingRecommendId;
                    const domainInfo: DomainRenewHostingInformation[] = resHubDto.DomainInfo;
                    this._canRecommendHosting = new Array<{ domainName: string, hasRecommend: boolean }>();
                    domainInfo.forEach(x => this._canRecommendHosting.push({ domainName: x.DomainName, hasRecommend: x.HasRecommend }));
                }
                this.isHostingUnpaid = resHubDto.IsUnpaid;
                this._multiInquiryFlag.down();
            },
            (resHubDto: DomainRenewHostingOrderCheckResponseHubDto) => {
                this._multiInquiryFlag.down();
                this.isSkipModalConfirm = true;
                this.Error(resHubDto);
            },
            (resHubDto: DomainRenewHostingOrderCheckResponseHubDto) => {
                this.isSkipModalConfirm = true;
                this.NoSession(resHubDto);
            },
            null,
            (resHubDto: DomainRenewHostingOrderCheckResponseHubDto) => {
                this.isSkipModalConfirm = true;
                this.UnExceptedError(resHubDto);
            }
        );
    }

    /**
     * 更新契約対象のドメイン一覧を5件取得
     */
    private _requestGetRenewDomains(): void {
        this.isLoading = true;

        const reqHubDto: DomainRenewListRequestHubDto = {
            CurrentPage: 1,
            PageSize: 1,
            SearchedType: "STANDARD",
            SearchCondition: {
                DomainIds: "",
                DomainNames: "",
                GroupName: "",
                CreatedDateFrom: "",
                CreatedDateTo: "",
                ExpirationDateFrom: this._expirationDateFrom(),
                ExpirationDateTo: this._expirationDateTo(),
                WhoisProxyStatus: "BOTH",
                CheckedCoupon: false
            } as DomainRenewListSearchCondition,
            SortList: [{
                Key: 'expirationDate',
                Type:1
            } as DomainRenewListSortRequestHubDto]

        } as DomainRenewListRequestHubDto;

        this.serviceContainer.HubsService.multipleSendManager.Send(
            this.queueId,
            this.serviceContainer.HubsService.hubsManager.domainRenewHubManager.GetDomainRenewList,
            reqHubDto,
            (resHubDto: DomainRenewListResponseHubDto) => {
                this.renewDomains = resHubDto.DomainRenewList.map(x => {
                    const isCheckedDomain: boolean = false;
                    const isEnableWhoisProduct: boolean = isCheckedDomain && x.CanApplyWhoisProduct && x.IsAppliedWhoisProxy === false && x.IsAppliedWhoisMailFwd === false;
                    let renewDomainItem = new RenewDomainItem(
                        x.IsActiveDomain,
                        isCheckedDomain,
                        x.DomainId,
                        x.DomainName,
                        x.DomainNameML,
                        x.Tld,
                        x.IsRegistryPremium,
                        x.CanSetAutoRenew,
                        x.IsDefCheckedDomain,
                        x.ExpirationDate,
                        x.RenewalDeadlineDate,
                        x.RenewalDeadlineCount,
                        '-',
                        '-',
                        x.DefRenewYear,
                        x.RenewYearProduct.map(y => {
                            return new UpdateYearItem(y.RenewYear, y.ProductId);
                        }),
                        x.IsAppliedWhoisProxy,
                        x.IsAppliedWhoisMailFwd,
                        x.IsAppliedSsl,
                        x.CanApplyWhoisProduct,
                        isEnableWhoisProduct,
                        false,
                        x.IsDomainProtect,
                        x.IsProtectedWhois,
                        x.IsProtectedAutoRenew,
                        x.IsAutoRenewActive,
                        x.CouponCode,
                        x.CouponDiscoutnUnTaxPrice,
                        x.CouponDiscountTaxInPrice,
                        x.CanApplyHosting,
                        x.IsDisallowPaymentBank,
                        x.IsDisallowPaymenNetCvs,
                        x.IsBillingWait,
                        x.CanApplySsl,
                        x.SslPrepaymentExpirationDate
                    );

                    return renewDomainItem;
                }).filter(x => {
                    // 入金待ちがあるドメインは表示対象外
                    return !x.isBillingWait;
                });

                this.renewDomains = !this.renewDomains ? [] : this.renewDomains;
                this._toggleDomainNameAll();
                //Launchに情報を送る
                CustomizedEvents.dispatchAppealDomainRenew({ domainMightExpireCount: this.renewDomains.length, isCreditCardPayment: this.isCreditCardPayment });
            },
            (resHubDto: DomainRenewListResponseHubDto) => {

                this.isLoading = false;
            },
            (resHubDto: DomainRenewListResponseHubDto) => {
                this.NoSession(resHubDto);
            },
            null,
            (resHubDto: DomainRenewListResponseHubDto) => {
                this.UnExceptedError(resHubDto);
            }
        );
    }

    /**
     * 日付の取得（当日）
    **/
    private _expirationDateFrom() {
        const todayYmd = new Date();
        const x = todayYmd.getFullYear()
            + '/' + ('0' + (todayYmd.getMonth() + 1)).slice(-2)
            + '/' + ('0' + todayYmd.getDate()).slice(-2)
        return (x)
    }

    /**
     * 日付の取得（30日後）
    **/
    private _expirationDateTo() {
        const todayYmd = new Date(this._expirationDateFrom());
        todayYmd.setDate(todayYmd.getDate() + 30);
        const y = todayYmd.getFullYear()
            + '/' + ('0' + (todayYmd.getMonth() + 1)).slice(-2)
            + '/' + ('0' + todayYmd.getDate()).slice(-2)
        return (y)
    }

    /**
     * ドメインモニタリング訴求対象のドメイン名取得
     **/
    private getDomainRecommendedForMonitoring(domainApplyingDto: IDomainApplyingDto[]): string[] {
        // 更新単価が高い順にソートしたドメイン一覧作成
        let domainUnitPriceList: DomainUnitPrice[] = [];

        domainApplyingDto.forEach(x => {
            const updateYearNum: number = parseInt(x.updateYear);
            const priceNum: number = parseInt(x.items.filter(y => y.contentType == ApplyingDetailContentType.DomainRenew).map(z => z.unTaxPrice).toString());

            let domainUnitPriceInfo: DomainUnitPrice = new DomainUnitPrice();
            domainUnitPriceInfo.domainNameMl = x.domainNameMl;
            domainUnitPriceInfo.domainName = x.domainName;
            domainUnitPriceInfo.unitPrice = priceNum / updateYearNum;
            domainUnitPriceList.push(domainUnitPriceInfo);
        });

        domainUnitPriceList.sort(function (a, b) {
            if (a.unitPrice < b.unitPrice) {
                return 1;
            } else {
                return -1;
            }
        });

        // モニタリング未申請のドメインをピックアップ
        let recommendDomainName: string[] = [];
        let recommendDomain = domainUnitPriceList.filter(x => !this.monitoringTextInfo.some(y => y === x.domainName.split('.')[0]))
        if (recommendDomain.length > 0) {
            recommendDomainName.push(recommendDomain[0].domainNameMl);
            recommendDomainName.push(recommendDomain[0].domainName);
        }
        return recommendDomainName;
    }

    /**
     * 旧ナビのSSL設定へ遷移する処理
     * ex https://www.onamae.com/navi/login/?link=domain/navi/ssl_new/select?btn_id=newnavi_complete_ssloption
     */
    public onClickSslIssuePage(): void {
        window.dataLayerPush("domrenew_done_ssl");

        var request: NavigationRequestHubDto = {
            Host: 'DOMAIN_NAVI',
            NavigationClass: 'DOMAIN_SSL_NEW',
            NavigationParams: [
                {
                    QueryKey: 'btn_id',
                    QueryValue: 'newnavi_complete_ssloption'
                }
            ] as NavigationQueryHubDto[]
        };

        this.serviceContainer.HubsService.multipleSendManager.Send(
            this.queueId,
            this.serviceContainer.HubsService.hubsManager.navigationHubManager.TokenUrl,
            request,
            (response: NavigationResponseHubDto) => {
                this.openUrl(response.TokenUrl);
            },
            (response: NavigationResponseHubDto) => {
            },
            this.NoSession,
            null,
            this.UnExceptedError
        );
    }

    /**
     * サーバーサービス請求情報一覧へ遷移する処理
     */
    public onClickHostingInvoicePage(): void {
        window.dataLayerPush("domrenew_done_invoice");

        this.router.navigate(['/payment/invoice/list/hosting']);
    }

    /**
     * ドメインモニタリング情報取得 
     */
    private fetchMonitoringTextInfo(): Promise<string[]> {
        return new Promise<string[]>((resolve, reject) => {
            this.isProcessingFetchMonitoringTextInfo = true;
            this.serviceContainer.HubsService.multipleSendManager.Send(
                this.queueId,
                this.serviceContainer.HubsService.hubsManager.domainRenewHubManager.GetMonitoringTextList,
                null,
                (response: DomainRenewMonitoringTextListResponseHubDto) => {
                    for (let a = 0; a < response.List.length; a++) {
                        this.monitoringTextInfo[a] = response.List[a].monitoringText;
                    }
                    this.isProcessingFetchMonitoringTextInfo = false;
                    resolve(this.monitoringTextInfo);
                },
                (response: DomainRenewMonitoringTextListResponseHubDto) => {
                },
                this.NoSession,
                (response: DomainRenewMonitoringTextListResponseHubDto) => {
                },
                this.UnExceptedError
            );
        });
    }

    /**
     * シングルサインオン
     */
    private doRedirection(navClass: string, isParamRequired = false): void {
        this.isLoading = true;
        var query: NavigationQueryHubDto = {
            QueryKey: "",
            QueryValue: ""
        };
        if (isParamRequired) {
            query = {
                QueryKey: "&monitoring_text",
                QueryValue: this.nondisplayDomainRecommendedForMonitoring
            };
        } else {
            query= {
                QueryKey: "btn_id",
                QueryValue: "PC_snavi_thanks_domainprotect"
            };
        }

        var request: NavigationRequestHubDto = {
            Host: "DOMAIN_NAVI",
            NavigationClass: navClass,
            NavigationParams: [query]
        };
        this.serviceContainer.HubsService.multipleSendManager.Send(
            this.queueId,
            this.serviceContainer.HubsService.hubsManager.navigationHubManager.TokenUrl,
            request,
            (response: NavigationResponseHubDto) => {
                window.open(response.TokenUrl, '_self');
            },
            (response: NavigationResponseHubDto) => {
                this.isLoading = false;
            },
            this.NoSession,
            null,
            this.UnExceptedError
        );
    }

    public contactPageOpen(): void {
        const isProduct: boolean = window.productFlg == "Product" || window.productFlg == "Staging";
        const url = isProduct ? "https://help.onamae.com/contact/" : "https://help.onamae.test/contact/";
        this.openUrl(url);
        return;
    }

    /**
     * 旧ナビの「ドメインモニタリング」申請管理へ遷移する処理
     * ex https://www.onamae.test/domain/navi/domainmonitoring/monitoring_list
     */
    public onClickDomainMonitoring(): void {
        this.doRedirection("DOMAIN_MONITORING_RENEW",true);
    }

    public onClickDomainProtect(): void {
        this.doRedirection("DOMAIN_PROTECT_RENEW", false);
    }
}

//タグ管理ツールへのイベント送信（Adobe）に使う
export interface IDomainSettingRenewDoneViewModel {
    isGmoElectricNewApply: boolean
}

export class BankPaymentDetails {
    public payee: string;
    public paymentLimitDate: string;
    public totalPrice: string;

    public constructor() {
        this.payee = '';
        this.paymentLimitDate = '';
        this.totalPrice = '';
    }
}

export class ConveniencePaymentDetails {
    public conveniCode : string;
    public storeName : string;
    public billingPrice : string;
    public paymentUrl : string;
    public companyNumber : string;
    public orderNumber : string;
    public phoneNumber : string;
    public conveniLimitDate : string;

    public constructor () {
        this.conveniCode = '';
        this.storeName = '';
        this.billingPrice = '';
        this.paymentUrl = '';
        this.companyNumber = '';
        this.orderNumber = '';
        this.phoneNumber = '';
        this.conveniLimitDate = "";
    }
}

class ListBase {
    public totalPriceYen: string;
    public totalUnTaxPriceYen: string;  
    public list: ApplyingsBase[];
    public count: number;

    public constructor(list: ApplyingsBase[]) {
        var totalPrice: number = 0;
        var totalUnTaxPrice: number = 0;
        this.list = list;
        this.count = list.length;

        list.forEach(applying => {
                applying.details.forEach(d => {
                totalPrice = totalPrice + (d.price || 0);
                totalUnTaxPrice = totalUnTaxPrice + (d.unTaxPrice || 0);
            });
        });
        this.totalPriceYen = StringUtil.NumberToStringWithComma(totalPrice > 0 ? totalPrice : 0);
        this.totalUnTaxPriceYen = StringUtil.NumberToStringWithComma(totalUnTaxPrice > 0 ? totalUnTaxPrice : 0);
    }
}

export class DomainsInList extends ListBase {
    public isOpened: boolean = false;

    public onClickIconArrow() {
        this.isOpened = !this.isOpened;
    }
}
export class FreeDomainsInList extends ListBase {
    public isOpened: boolean = false;
    public onClickIconArrow() {
        this.isOpened = !this.isOpened;
    }
}
export class ServersInList extends ListBase {
    public isOpened: boolean = false;

    public onClickIconArrow() {
        this.isOpened = !this.isOpened;
    }
}

export class FeesInList extends ListBase {
}

class ApplyingsBase {
    public details: ApplyingDetailBase[] = [];
    public count: number; 
    //totalPriceYen: string;
    //totalUnTaxPriceYen: string;
}

export class DomainApplyings extends ApplyingsBase {
    public constructor(domain: IDomainApplyingDto) {
        super();

        this.details.push(new TitleApplyingDetail(domain));

        domain.items.forEach(dto => {
            if (this._isNOTTargetContent(dto.contentType)) { return; }

            const detail = new ItemApplyingDetail(dto);
            if (detail.contentType === ApplyingDetailContentType.DomainRenew) {
                detail.content = detail.content.replace('{0}', domain.tld.match(/[^.]*$/)[0]).replace('{1}', domain.updateYear);
            }

            this.details.push(detail);
        });
    }

     // ホスティングは別枠表示するため除外
    private _isNOTTargetContent(contentType: ApplyingDetailContentType): boolean {
        let isHosting: boolean = contentType === ApplyingDetailContentType.Hosting;
        return isHosting;
    }
}
class FreeDomainApplyings extends ApplyingsBase {
    public constructor(domain: IFreeDomainApplyingDto) {
        super();

        this.details.push(new TitleApplyingDetail(domain));

        domain.details.forEach(dto => {
            const detail = new ItemApplyingDetail(dto);
            if (detail.contentType === ApplyingDetailContentType.DomainCreate) {
                detail.content = detail.content.replace('{0}', domain.tld.match(/[^.]*$/)[0]).replace('{1}', domain.updateYear);
            }
            this.details.push(detail);

        });
    }
}
export class ServerApplyings extends ApplyingsBase {
    public constructor(server: IServerApplyingDto) {
        super();

        this.details.push(new TitleApplyingDetail(server));

        server.details.forEach(dto => {
            if (this._isNOTTargetContent(dto.contentType)) { return; }
            if (ApplyingDetailContentType.HostingInitialCost === dto.contentType || ApplyingDetailContentType.HostingInitialCostPatternB1 === dto.contentType || ApplyingDetailContentType.HostingInitialCostPatternB2 === dto.contentType || ApplyingDetailContentType.HostingInitialSettingCost === dto.contentType || ApplyingDetailContentType.Surcharge === dto.contentType) {
                this.details.push(new ItemApplyingDetail(dto));
            }
        });
    }

    private _isNOTTargetContent(contentType: ApplyingDetailContentType): boolean {
        return contentType === ApplyingDetailContentType.HostingMonthlyFee;
    }
}

export class FeeApplyings extends ApplyingsBase {
    public constructor(feeDto: IItemApplyingDto) {
        super();
        this.details.push(new ItemApplyingDetail(feeDto));
    }
}

export enum ApplyingDetailContentType {
    Other = -1,
    DomainRenew = 1,
    WhoisProxy = 2,
    WhoisMailFwd = 3,
    Protection = 4,
    Ssl = 5,
    Hosting = 6,
    HostingInitialSettingCost = 7,
    HostingInitialCost = 8,
    HostingMonthlyFee = 9,
    ConveniFee = 10,
    Coupon = 11,
    DomainRenewWithRs = 12,
    DomainCreate = 14,
    HostingInitialCostPatternB1 = 15,
    HostingInitialCostPatternB2 = 16,
    Surcharge = 17
}

class ApplyingDetailBase implements IItemApplyingDto {
    public contentType: number;
    public content: string;
    public price: number = null;
    public priceYen: string = null;
    public unTaxPrice: number = null;
    public unTaxPriceYen: string = null;   
}

class TitleApplyingDetail extends ApplyingDetailBase {
    public isTitle: boolean = true;

    private static readonly _map: { key: ApplyingDetailContentType, value: string }[] = [
        { key: ApplyingDetailContentType.DomainRenew, value: '{0}' },
        { key: ApplyingDetailContentType.Hosting, value: 'レンタルサーバー' }
    ];

    public constructor(dto: IDomainApplyingDto | IServerApplyingDto  | IFreeDomainApplyingDto ) {
        super();
        this.contentType = this._getContentType(dto);
        this.content = TitleApplyingDetail._map.find(x => x.key === this.contentType).value;
        if (this.contentType === ApplyingDetailContentType.DomainRenew) {
            this.content = this.content.replace('{0}', dto.domainNameMl);
        }
    }

    private _isServerApplyingDto(dto: any): dto is IServerApplyingDto {
        return dto !== null && typeof dto !== void (0) && typeof dto.serverName === 'string';
    }

    private _getContentType(dto: any): ApplyingDetailContentType {
        if (this._isServerApplyingDto(dto)) {
            return ApplyingDetailContentType.Hosting;
        } else {
            return ApplyingDetailContentType.DomainRenew;
        }
    }
}

class ItemApplyingDetail extends ApplyingDetailBase {
    public isDiscount: boolean = false;

    private static readonly _map: { key: ApplyingDetailContentType, value: string }[]= [
        { key: ApplyingDetailContentType.DomainRenew, value: '.{0} {1}年更新' },
        { key: ApplyingDetailContentType.WhoisProxy, value: 'Whois情報公開代行' },
        { key: ApplyingDetailContentType.WhoisMailFwd, value: 'メール転送オプション' },
        { key: ApplyingDetailContentType.Protection, value: 'ドメインプロテクション' },
        { key: ApplyingDetailContentType.Ssl, value: 'SSLサーバー証明書' },
        //{ key: ApplyingDetailContentType.Hosting, value: 'レンタルサーバー' },
        { key: ApplyingDetailContentType.HostingInitialSettingCost, value: '初期設定費用' },
        { key: ApplyingDetailContentType.HostingInitialCost, value: '初回料金' },
        { key: ApplyingDetailContentType.HostingInitialCostPatternB1, value: '月額費用※3ヶ月30%OFF' },
        { key: ApplyingDetailContentType.HostingInitialCostPatternB2, value: '月額費用初回※' },
        //{ key: ApplyingDetailContentType.HostingMonthlyFee, value: '月額料金' },
        { key: ApplyingDetailContentType.ConveniFee, value: 'コンビニ支払い手数料' },
        { key: ApplyingDetailContentType.Coupon, value: 'クーポン利用' },
        { key: ApplyingDetailContentType.DomainRenewWithRs, value: '※レンタルサーバー同時対象' },
        { key: ApplyingDetailContentType.DomainCreate, value: '.{0} {1}年登録' },
        { key: ApplyingDetailContentType.Surcharge, value: 'サービス維持調整費' }
    ];

    public constructor(dto: IItemApplyingDto) {
        super();
        this.contentType = dto.contentType;
        this.content = ItemApplyingDetail._map.find(x => x.key === dto.contentType).value;
        this.price = dto.price;
        this.priceYen = StringUtil.NumberToStringWithComma(dto.price);
        this.unTaxPrice = dto.unTaxPrice;
        this.unTaxPriceYen = StringUtil.NumberToStringWithComma(dto.unTaxPrice);
        this.isDiscount = this.contentType == ApplyingDetailContentType.Coupon;
    }
}

export class DomainUnitPrice {
    public domainName: string;
    public domainNameMl: string;
    public unitPrice: number;

    public constructor() {
        this.domainName = '';
        this.domainNameMl = '';
        this.unitPrice = 0;
    }
}

enum ApplyingDetailType {
    None = -1,
    Title = 1,
    Item = 2,
    Discount = 3,
    Fee = 4
}
export class RenewDomainItem {

    public constructor(
        /** 選択不可能かどうか */
        public readonly isActiveDomain: boolean,
        /** 選択状態 */
        public isCheckedDomain: boolean,
        public readonly domainId: string,
        public readonly domainName: string,
        public readonly domainNameMl: string,
        public readonly tld: string,
        public readonly isRegistryPremium: boolean,
        public readonly canSetAutoRenew: boolean,
        /** 更新対象かどうか */
        public readonly isDefCheckedDomain: boolean,
        /** 更新期限日 yyyy/mm/dd */
        public readonly expirationDate: string,
        /** 更新可能な期限日 yyyy/mm/dd */
        public readonly renewalDeadlineDate: string,
        /** 新可能な期限日の日数 xxx */
        public readonly renewalDeadlineCount: string,
        /** 税抜合計金額 */
        public totalUnTaxPrice: string,
        /** 税込合計金額 */
        public totalTaxInPrice: string,
        /** 更新年数 */
        public selectedUpdateYear: string,
        /** 選択可能な更新年数 */
        public readonly updateYearItems: UpdateYearItem[],
        /** Whois情報公開代行を申請済みかどうか */
        public readonly isAppliedWhoisProxy: boolean,
        /** WHOISメール転送利用を申請済みかどうか */
        public readonly isAppliedWhoisMailFwd: boolean,
        /** SSL利用中か **/
        public readonly isAppliedSsl: boolean,
        /** Whois情報公開代行もしくはWhois情報公開代行(WHOISメール転送利用)の申し込み対象かどうか */
        public readonly canApplyWhoisProduct: boolean,
        /** Whois情報公開代行もしくはWhois情報公開代行(WHOISメール転送利用)のチェックボックスを表示するかどうか */
        public isEnableWhoisProduct: boolean,
        /** Whois情報公開代行もしくはWhois情報公開代行(WHOISメール転送利用)のチェック状態 */
        public isCheckedWhoisProduct: boolean,
        /** ドメインプロテクションを申し込み状態かどうか */
        public readonly isAppliedDomainProtect: boolean,
        /** Whoisがプロテクト状態かどうか */
        public readonly isProtectedWhois: boolean,
        /** 自動更新がプロテクト状態かどうか */
        public readonly isProtectedAutoRenew: boolean,
        /** 自動更新が有効かどうか　*/
        public readonly isAutoRenewActive: boolean,
        public readonly couponCode: string,
        public couponDiscoutnUnTaxPrice: string,
        public couponDiscountTaxInPrice: string,
        public readonly canApplyHosting: boolean,
        public readonly isDisallowPaymentBank: boolean,
        public readonly isDisallowPaymenNetCvs: boolean,
        /** 課金待ち状態かどうか */
        public readonly isBillingWait: boolean,
        /** SSL申込可能かどうか **/
        public readonly canApplySsl: boolean,
        /** SSL使用期限（利用中でなければ''） **/
        public readonly sslPrepaymentExpirationDate: string,
    ) { }

    public convertDto(): IDataTransferRenewDomainItemDto {
        const items: IDataTransferUpdateYearItemDto[] = [];
        this.updateYearItems.forEach(x => items.push(x.convertDto()));

        var retVal: IDataTransferRenewDomainItemDto = {
            /** 選択不可能かどうか */
            isActiveDomain: this.isActiveDomain,
            /** 選択状態 */
            isCheckedDomain: this.isCheckedDomain,
            domainId: this.domainId,
            domainName: this.domainName,
            domainNameMl: this.domainNameMl,
            tld: this.tld,
            isRegistryPremium: this.isRegistryPremium,
            canSetAutoRenew: this.canSetAutoRenew,
            /** 更新対象かどうか */
            isDefCheckedDomain: this.isDefCheckedDomain,
            /** 更新期限日 yyyy/mm/dd */
            expirationDate: this.expirationDate,
            /** 更新可能な期限日 yyyy/mm/dd */
            renewalDeadlineDate: this.renewalDeadlineDate,
            /** 新可能な期限日の日数 xxx */
            renewalDeadlineCount: this.renewalDeadlineCount,
            /** 税抜合計金額 */
            totalUnTaxPrice: this.totalUnTaxPrice,
            /** 税込合計金額 */
            totalTaxInPrice: this.totalTaxInPrice,
            /** 更新年数 */
            selectedUpdateYear: this.selectedUpdateYear,
            /** 選択可能な更新年数 */
            updateYearItems: this.updateYearItems.map(x => x.convertDto() as IDataTransferUpdateYearItemDto),
            /** Whois情報公開代行を申請済みかどうか */
            isAppliedWhoisProxy: this.isAppliedWhoisProxy,
            /** WHOISメール転送利用を申請済みかどうか */
            isAppliedWhoisMailFwd: this.isAppliedWhoisMailFwd,
            /** SSL利用中か **/
            isAppliedSsl: this.isAppliedSsl,
            /** Whois情報公開代行もしくはWhois情報公開代行(WHOISメール転送利用)の申し込み対象かどうか */
            canApplyWhoisProduct: this.canApplyWhoisProduct,
            /** Whois情報公開代行もしくはWhois情報公開代行(WHOISメール転送利用)のチェックボックスを表示するかどうか */
            isEnableWhoisProduct: this.isEnableWhoisProduct,
            /** Whois情報公開代行もしくはWhois情報公開代行(WHOISメール転送利用)のチェック状態 */
            isCheckedWhoisProduct: this.isCheckedWhoisProduct,
            /** ドメインプロテクションを申し込み状態かどうか */
            isAppliedDomainProtect: this.isAppliedDomainProtect,
            /** Whoisがプロテクト状態かどうか */
            isProtectedWhois: this.isProtectedWhois,
            /** 自動更新がプロテクト状態かどうか */
            isProtectedAutoRenew: this.isProtectedAutoRenew,
            /** 自動更新が有効かどうか　*/
            isAutoRenewActive: this.isAutoRenewActive,
            couponCode: this.couponCode,
            couponDiscoutnUnTaxPrice: this.couponDiscoutnUnTaxPrice,
            couponDiscountTaxInPrice: this.couponDiscountTaxInPrice,
            canApplyHosting: this.canApplyHosting,
            isDisallowPaymentBank: this.isDisallowPaymentBank,
            isDisallowPaymenNetCvs: this.isDisallowPaymenNetCvs,
            /** 課金待ち状態かどうか */
            isBillingWait: this.isBillingWait,
            /** SSL申込可能かどうか **/
            canApplySsl: this.canApplySsl,
            /** SSL使用期限（利用中でなければ''） **/
            sslPrepaymentExpirationDate: this.sslPrepaymentExpirationDate,
        } as IDataTransferRenewDomainItemDto;

        return retVal;
    }
}

export class UpdateYearItem {
    public constructor(
        public readonly year: string,
        public readonly productId: string,
    ) { }

    public convertDto(): IDataTransferUpdateYearItemDto {
        var retVal: IDataTransferUpdateYearItemDto = {
            year: this.year,
            productId: this.productId
        } as IDataTransferUpdateYearItemDto;

        return retVal;
    }
}

export class RenewDomainHeader {
    public constructor(
        public isCheckedDomainAll: boolean,
        public isActiveUpdateYearAll: boolean,
        public updateYearAll: string,
    ) { }
}
class Flag {
    protected _flag: boolean = false;
    protected _count: number = 0;

    public raise() {
        if (this._count == 0) {
            this._flag = true;
        }
        this._count++;
    }

    public down() {
        if (this._count == 0) {
            return;
        }

        this._count--;
        if (this._count == 0) {
            this._flag = false;
        }
    }

    public isRaise(): boolean {
        return this._count > 0;
    }
}
