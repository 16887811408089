/// <reference path='../definitions/window.d.ts' />

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

var isIE9: boolean = window.browserType == 'IE9';

let routes: Routes = [
    { path: 'login', loadChildren: () => import('./login-routing.module').then(m => m.LoginRoutingModule) },
    { path: 'logout', loadChildren: () => import('./logout-routing.module').then(m => m.LogoutRoutingModule) },
    { path: 'link', loadChildren: () => import('./link-routing.module').then(m => m.LinkRoutingModule) },
    { path: 'controlpanel', loadChildren: () => import('./controlpanel-routing.module').then(m => m.ControlPanelRoutingModule) },
    { path: 'withdrawal', loadChildren: () => import('./withdrawal-routing.module').then(m => m.WithdrawalRoutingModule) },
    { path: 'auth', loadChildren: () => import('./auth-routing.module').then(m => m.AuthRoutingModule) },
    { path: 'top', loadChildren: () => import('./top-routing.module').then(m => m.TopRoutingModule) },
    { path: 'provisionalaccount', loadChildren: () => import('./provisionalaccount-routing.module').then(m => m.ProvisionalAccountRoutingModule) },
    { path: 'provisionalpassword', loadChildren: () => import('./provisionalpassword-routing.module').then(m => m.ProvisionalPasswordRoutingModule) },
    { path: 'account', loadChildren: () => import('./account-routing.module').then(m => m.AccountRoutingModule) },
    { path: 'payment', loadChildren: () => import('./payment-routing.module').then(m => m.PaymentRoutingModule) },
    { path: 'domain/setting/renew/list', loadChildren: () => import('./domain_setting_renew-routing.module').then(m => m.DomainSettingRenewRoutingModule), pathMatch: 'full' },
    { path: 'domain', loadChildren: () => import('./domain-routing.module').then(m => m.DomainRoutingModule) },
    { path: 'dns', loadChildren: () => import('./dns-routing.module').then(m => m.DnsRoutingModule) },
    { path: 'rs', loadChildren: () => import('./sd-routing.module').then(m => m.SdRoutingModule) },
    { path: 'sd', redirectTo: 'rs' },
    { path: 'sdweebly', loadChildren: () => import('./sdweebly-routing.module').then(m => m.SdWeeblyRoutingModule) },
    { path: 'mail', loadChildren: () => import('./mail-routing.module').then(m => m.MailRoutingModule) },
    { path: 'mailmarketing', loadChildren: () => import('./mailmarketing-routing.module').then(m => m.MailMarketingRoutingModule) },
    { path: 'wpcloud', loadChildren: () => import('./wpcloud-routing.module').then(m => m.WpCloudRoutingModule) },
    { path: 'office365', loadChildren: () => import('./office365-routing.module').then(m => m.Office365RoutingModule) },
    { path: 'vps', loadChildren: () => import('./vps-routing.module').then(m => m.VpsRoutingModule) },
    { path: 'ssl', loadChildren: () => import('./ssl-routing.module').then(m => m.SslRoutingModule) },
    { path: 'sitecreation', loadChildren: () => import('./sitecreation-routing.module').then(m => m.SiteCreationRoutingModule) },
    { path: 'desktopcloud', loadChildren: () => import('./desktopcloud-routing.module').then(m => m.DesktopCloudRoutingModule) },
    { path: 'desktopcloudoption', loadChildren: () => import('./desktopcloudoption-routing.module').then(m => m.DesktopCloudOptionRoutingModule) },
    { path: 'wptemplate', loadChildren: () => import('./wptemplate-routing.module').then(m => m.WpTemplateRoutingModule) },
    { path: 'gs', loadChildren: () => import('./gs-routing.module').then(m => m.GsRoutingModule) },
    { path: 'navigate', loadChildren: () => import('./navigation-routing.module').then(m => m.NavigationRoutingModule) },
    { path: 'password', loadChildren: () => import('./password-routing.module').then(m => m.PasswordRoutingModule) },
    { path: 'mfareset', loadChildren: () => import('./mfareset-routing.module').then(m => m.MfaResetRoutingModule) },
    { path: 'phoneauth', loadChildren: () => import('./phoneauth-routing.module').then(m => m.PhoneAuthRoutingModule) },
    { path: 'cvs', loadChildren: () => import('./cvs-routing.module').then(m => m.CvsRoutingModule) }, 
    { path: 'error', loadChildren: () => import('./error-routing.module').then(m => m.ErrorRoutingModule) },
    { path: 'contact', loadChildren: () => import('./contact-routing.module').then(m => m.ContactRoutingModule) },
    { path: 'secondhanddealerlicense', loadChildren: () => import('./secondhand_dealer_license-routing.module').then(m => m.SecondhandDealerLicenseRoutingModule) },
    { path: 'campaign', loadChildren: () => import('./campaign_routing.module').then(m => m.CampaignRoutingModule) },
    { path: 'specialcancellation', loadChildren: () => import('./special-cancellation-routing.module').then(m => m.SpecialCancellationRoutingModule) },
    { path: 'domainrefund', loadChildren: () => import('./domain_refund-routing.module').then(m => m.DomainRefundRoutingModule) },
    { path: 'tempcojpdomainrestore', loadChildren: () => import('./tempcojpdomainrestore-routing.module').then(m => m.TempcoJpDomainRestoreRoutingModule) },
    { path: 'webacademy', loadChildren: () => import('./webacademy-routing.module').then(m => m.WebacademyRoutingModule) },
    { path: 'ownership', loadChildren: () => import('./ownership-routing.module').then(m => m.OwnershipRoutingModule) },
    { path: 'quick', loadChildren: () => import('./quick-routing.module').then(m => m.QuickRoutingModule) },
    { path: '**', redirectTo: 'error/not_found' }
];

if (isIE9) {
    routes = [
        //{ path: '', redirectTo: 'login', pathMatch: 'full' },
        //{ path: 'login', loadChildren: 'Content/script/routing/login-routing.module.js#LoginRoutingModule' },
        //{ path: 'logout', loadChildren: 'Content/script/routing/logout-routing.module.js#LogoutRoutingModule' },
        //{ path: 'withdrawal', loadChildren: 'Content/script/routing/withdrawal-routing.module.js#WithdrawalRoutingModule' },
        //{ path: 'auth', loadChildren: 'Content/script/routing/auth-routing.module.js#AuthRoutingModule' },
        //{ path: 'top', loadChildren: 'Content/script/routing/top-routing.module.js#TopRoutingModule' },
        //{ path: 'provisionalaccount', loadChildren: 'Content/script/routing/provisionalaccount-routing.module.js#ProvisionalAccountRoutingModule' },
        //{ path: 'account', loadChildren: 'Content/script/routing/account-routing.module.js#AccountRoutingModule' },
        //{ path: 'payment', loadChildren: 'Content/script/routing/payment-routing.module.js#PaymentRoutingModule' },
        //{ path: 'domain', loadChildren: 'Content/script/routing/domain-routing.module.js#DomainRoutingModule' },
        ////{ path: 'auction', loadChildren: 'Content/script/routing/auction-routing.module.js#AuctionRoutingModule' },
        ////{ path: 'sell', loadChildren: 'Content/script/routing/sell-routing.module.js#SellRoutingModule' },
        ////{ path: 'dnsplus', loadChildren: 'Content/script/routing/dnsplus-routing.module.js#DnsPlusRoutingModule' },
        ////{ path: 'sd', loadChildren: 'Content/script/routing/sd-routing.module.js#SdRoutingModule' },
        ////{ path: 'sdweebly', loadChildren: 'Content/script/routing/sdweebly-routing.module.js#SdWeeblyRoutingModule' },
        ////{ path: 'mail', loadChildren: 'Content/script/routing/mail-routing.module.js#MailRoutingModule' },
        ////{ path: 'mailmarketing', loadChildren: 'Content/script/routing/mailmarketing-routing.module.js#MailMarketingRoutingModule' },
        ////{ path: 'wpcloud', loadChildren: 'Content/script/routing/wpcloud-routing.module.js#WpCloudRoutingModule' },
        ////{ path: 'office365', loadChildren: 'Content/script/routing/office365-routing.module.js#Office365RoutingModule' },
        ////{ path: 'vps', loadChildren: 'Content/script/routing/vps-routing.module.js#VpsRoutingModule' },
        ////{ path: 'sitecreation', loadChildren: 'Content/script/routing/sitecreation-routing.module.js#SiteCreationRoutingModule' },
        ////{ path: 'desktopcloud', loadChildren: 'Content/script/routing/desktopcloud-routing.module.js#DesktopCloudRoutingModule' },
        ////{ path: 'wptemplate', loadChildren: 'Content/script/routing/wptemplate-routing.module.js#WpTemplateRoutingModule' },
        ////{ path: 'windowsdesktop', loadChildren: 'Content/script/routing/windowsdesktop-routing.module.js#WindowsDesktopRoutingModule' },
        ////{ path: 'gs', loadChildren: 'Content/script/routing/gs-routing.module.js#GsRoutingModule' },
        ////{ path: 'navigate', loadChildren: 'Content/script/routing/navigation-routing.module.js#NavigationRoutingModule' },
        { path: 'error', loadChildren: () => import('./error-routing.module').then(m => m.ErrorRoutingModule) },
        { path: '**', redirectTo: 'error/unsupported_browser' }
    ];
}

export const pcRoutes: Routes = routes;

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    declarations: [
    ],
    exports: [
        RouterModule
    ]
})

export class PcRoutingModule {
}
