import { createReducer, createFeatureSelector, createSelector, on } from '@ngrx/store';
import { RefererButtonState } from '../states/referer-button';
import { setRefererButtonName, resetRefererButtonName } from '../actions/referer-button';

export const refererButtonFeatureKey = 'refererButton';

const initialState: RefererButtonState = {
    name: null
};

export const reducer = createReducer(
    initialState,
    on(setRefererButtonName, (state: RefererButtonState, refererButtonState: RefererButtonState) => (refererButtonState)),
    on(resetRefererButtonName, (state: RefererButtonState) => (initialState))
);

const getState = createFeatureSelector<RefererButtonState>(refererButtonFeatureKey);

export const getRefererButtonName = createSelector(
    getState,
    state => state.name
);

