<div class="modal" modal [openFlg]="isShowDomainDetail" data-dismiss="modal" (click)="onClickOutsideCloseModal($event)">
  <div class="modal-Dialog is-Wide">
    <div class="modal-Dialog-Header is-Note is-NoBorderBottom pb0">
      <p class="text-Center size-17 mb0">＼ {{serviceName}}に移管したい方必見 ／</p>
      <h3 class="modal-Dialog-Header-Title text-Large text-Bold text-Danger text-Center line-Height-Middle mt8 pl0">2年継続で更新料金5,000円引き<br>{{serviceName}}よりお名前.comで更新した方がお得です</h3>
    </div><!-- /.modal-Dialog-Header -->
    <div class="modal-Dialog-Body pt20 pb32">
      <section class="section">
          <div class="box-Border is-Thick is-Yellow">
              <div class="box-TransferOutPrevention">
                  <p class="box-TransferOutPrevention-Heading">.comを2年間利用する場合の更新料金比較 <span class="size-12">※</span></p>
                  <div class="box-TransferOutPrevention-Contents">
                      <ul class="box-TransferOutPrevention-List">
                          <li class="box-TransferOutPrevention-List-Item">
                              <a href="javascript:void(0)" (click)="onClickRenew()" class="box-TransferOutPrevention-Panel">
                                  <div class="box-TransferOutPrevention-Panel-Popup">圧倒的にお得<span class="triangle"></span></div>
                                  <div class="box-TransferOutPrevention-Panel-Body">
                                      <p class="box-TransferOutPrevention-Panel-Heading">お名前.comで更新する</p>
                                      <p class="box-TransferOutPrevention-Panel-Period">２年間</p>
                                      <p class="box-TransferOutPrevention-Panel-Price text-Danger"><span>0</span><span class="size-16 ml4">円</span></p>
                                  </div>
                                  <div class="box-TransferOutPrevention-Panel-Footer">
                                      <p>詳細はこちら</p>
                                  </div>
                              </a>
                          </li>
                          <li class="box-TransferOutPrevention-List-Item">
                              <a href="javascript:void(0)" class="box-TransferOutPrevention-Panel" (click)="onClickCloseAuthCode()">
                                  <div class="box-TransferOutPrevention-Panel-Body gtmclick" [attr.data-gtmvalue]="gtmvalueAuthcode">
                                      <p class="box-TransferOutPrevention-Panel-Heading gtmclick" [attr.data-gtmvalue]="gtmvalueAuthcode">{{serviceName}}に移管する</p>
                                      <p class="box-TransferOutPrevention-Panel-Period gtmclick" [attr.data-gtmvalue]="gtmvalueAuthcode">２年間</p>
                                      <p class="box-TransferOutPrevention-Panel-Price gtmclick" [attr.data-gtmvalue]="gtmvalueAuthcode"><span class="gtmclick" [attr.data-gtmvalue]="gtmvalueAuthcode">{{price}}</span><span class="size-16 ml4 gtmclick" [attr.data-gtmvalue]="gtmvalueAuthcode">円</span></p>
                                  </div>
                                  <div class="box-TransferOutPrevention-Panel-Footer gtmclick" [attr.data-gtmvalue]="gtmvalueAuthcode">
                                      <p class="gtmclick" [attr.data-gtmvalue]="gtmvalueAuthcode">AuthCodeを取得する</p>
                                  </div>
                              </a>
                          </li>
                      </ul>
                  </div>
              </div>
          </div><!-- /.box-Border -->
      </section><!-- /.section -->
      <p class="text-Xsmall text-Gray mt20 mb0">※いずれも2023年10月2日時点の更新料金（税込）です。お名前.comの価格には、サービス維持費（2023年10月時点）が含まれております。０円はドメイン更新時にオプション契約料金が発生しない場合の総額となります。</p>
      <p class="text-Xsmall text-Gray mt4 mb0">※{{serviceName}}の価格には、移管手数料が含まれております。</p>
    </div><!-- /.modal-Dialog-Body -->
    <button type="button" class="modal-Close gtmclick" [attr.data-gtmvalue]="gtmvalueClose" (click)="onClickClose()"><i class="icon icon-Clear gtmclick" [attr.data-gtmvalue]="gtmvalueClose" type ="button" aria-label="閉じる"></i></button>
  </div>
</div><!-- /.modal-Dialog -->
