import { createAction, props } from '@ngrx/store';
import { AccountInfoState, AccountDetailState, AccountHostingContractQuantityState } from '../states/account-info';

export const setAccountInfo = createAction(
    '[AccountInfo] Set Account Info',
    props<AccountInfoState>()
);


export const resetAccountInfo = createAction(
    '[AccountInfo] Reset Account Info'
);

export const setAccountDetail = createAction(
    '[AccountInfo] Set Account Detail',
    props<AccountDetailState>()
);


export const resetAccountDetail = createAction(
    '[AccountInfo] Reset Account Detail'
);

export const setAccountHostingContractQuantity = createAction(
    '[AccountInfo] Set AccountHostingContractQuantity',
    props<AccountHostingContractQuantityState>()
);


export const resetAccountHostingContractQuantity = createAction(
    '[AccountInfo] Reset AccountHostingContractQuantity'
);
