<div class="modal" modal [openFlg]="isDisplay" data-dismiss="modal">
    <div class="modal-Dialog is-Narrow">
        <div class="modal-Dialog-Header">
            <h3 class="modal-Dialog-Header-Title is-Center">会員情報に変更や誤りはございませんか？</h3>
        </div><!-- /.modal-Dialog-Header -->
        <div class="modal-Dialog-Body pt28 pr20 pl20 pb28">
            <section class="section">
                <p>お名前.com Naviのログイン情報をお忘れの際や、ご本人様確認の際に重要な情報となりますので、定期的な確認と必要に応じた変更をお願いしております。</p>
                <div class="box-Border is-Thick pt20 pb12">
                    <table class="tbl is-BorderNone is-NoBorder mb0">
                        <colgroup>
                            <col style="width: 140px">
                            <col>
                        </colgroup>
                        <tbody>
                            <tr>
                                <th class="pr0 pl0">登録住所</th>
                                <td class="text-Indent">：{{Address}}</td>
                            </tr>
                            <tr>
                                <th class="pr0 pl0">メールアドレス</th>
                                <td class="text-Indent">：{{Email}}</td>
                            </tr>
                        </tbody>
                    </table><!-- /.tbl-Dotted -->
                </div>
            </section><!-- /.section -->
        </div><!-- /.modal-Dialog-Body -->
        <div class="modal-Dialog-Footer">
            <ul class="list-Btn is-Right mb0">
                <li><button type="button" class="btn" data-dismiss="modal" (click)="onClickIgnore()">変更しない</button></li>
                <li><button type="button" class="btn is-Primary" data-dismiss="modal" data-change="changed" (click)="onClickChange()">変更する</button></li>
            </ul><!-- /.list-Btn -->
        </div>
    </div><!-- /.modal-Dialog -->
</div>
