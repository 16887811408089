import { ParallelAB, TParallelAB, TAloneAB } from 'Content/script/libs/abtest/ab_cookie_service.util';
import { HttpClient } from '@angular/common/http';

/**
 * ABテスト対象
 **/
interface IABTargets<T> {
    LoginCampaign: T,
    RenovationGlobalNaviSubmenu: T,
    SearchSuggest: T,
    BankInputReccomend: T,
    AllServicesSurvey: T,
    AdjustTldFreeRenew: T,
    HeaderInfoDropDownMenu: T,
    AdjustNetDeDiagnosisModal: T,
    AdjustTextButton: T,
    AdjustDomainOptionAdd: T,
    AdjustShowHideOptionRenewModal: T,
    AdjustRenewCouponCode: T,
    AdjustRsRecommendWithNameServer: T,
    AdjustNetdeShindanModalTop: T,
    AdjustLoginNavigation: T,
    AddTotalPriceOfServiceTop: T
}

/** 各ABテスト対象に紐づく複数のテスト用のインターフェース */
interface ITests<T> {
    tests: T;
}

/**
 * 【お名前.com Navi】【全商材】Naviログイン増のためのプレゼントCP
 * https://ticket.g-system.io/issues/84736
 *
 * LoginCampaign_01　→　キャッシュデータを用いない「強制データ取得モード」切り替え用
 * LoginCampaign_02　→　訴求モーダルを50 : 50の比率で出す用（ABテストではない）
 */
interface ILoginCampaign {
    LoginCampaign_01: TAloneAB;
    LoginCampaign_02: TAloneAB;
}
class LoginCampaignAB extends ParallelAB implements ITests<ILoginCampaign> {
    public get tests(): ILoginCampaign {
        return {
            LoginCampaign_01: this.tiketId1Test,
            LoginCampaign_02: this.tiketId2Test,
        };
    }
}

interface IRenovationGlobalNaviSubmenu {
    RenovationGlobalNaviSubmenu_01: TAloneAB;
    RenovationGlobalNaviSubmenu_02: TAloneAB;
}
class RenovationGlobalNaviSubmenuAB extends ParallelAB implements ITests<IRenovationGlobalNaviSubmenu> {
    public get tests(): IRenovationGlobalNaviSubmenu {
        return {
            RenovationGlobalNaviSubmenu_01: this.tiketId1Test,
            RenovationGlobalNaviSubmenu_02: this.tiketId2Test
        };
    }
}

interface ISearchSuggest {
    SearchSuggest: TAloneAB;
}
class SearchSuggestAB extends ParallelAB implements ITests<ISearchSuggest>{
    public get tests(): ISearchSuggest {
        return {
            SearchSuggest: this.tiketId1Test
        };
    }
}

interface IBankInputReccomend {
    BankInputReccomend: TAloneAB;
}
class BankInputReccomendAB extends ParallelAB implements ITests<IBankInputReccomend>{
    public get tests(): IBankInputReccomend {
        return {
            BankInputReccomend: this.tiketId1Test
        };
    }
}

interface IAllServicesSurvey {
    AllServicesSurvey: TAloneAB;
}
class AllServicesSurveyAB extends ParallelAB implements ITests<IAllServicesSurvey> {
    public get tests(): IAllServicesSurvey {
        return {
            AllServicesSurvey: this.tiketId1Test
        };
    }
}

interface IAdjustTldFreeRenew {
    AdjustTldFreeRenew: TAloneAB;
}
class AdjustTldFreeRenew extends ParallelAB implements ITests<IAdjustTldFreeRenew>{
    public get tests(): IAdjustTldFreeRenew {
        {
            return {
                AdjustTldFreeRenew: this.tiketId1Test
            };
        }
    }
}

interface IHeaderInfoDropDownMenu {
    HeaderInfoDropDownMenu: TAloneAB;
}
class HeaderInfoDropDownMenuAB extends ParallelAB implements ITests<IHeaderInfoDropDownMenu>{
    public get tests(): IHeaderInfoDropDownMenu {
        {
            return {
                HeaderInfoDropDownMenu: this.tiketId1Test
            };
        }
    }
}

interface IAdjustNetDeDiagnosisModal {
    AdjustNetDeDiagnosisModal: TAloneAB;
}
class AdjustNetDeDiagnosisModalAB extends ParallelAB implements ITests<IAdjustNetDeDiagnosisModal>{
    public get tests(): IAdjustNetDeDiagnosisModal {
        {
            return {
                AdjustNetDeDiagnosisModal: this.tiketId1Test
            };
        }
    }
}

interface IAdjustTextButton {
    AdjustTextButton: TAloneAB;
}
class AdjustTextButtonAB extends ParallelAB implements ITests<IAdjustTextButton>{
    public get tests(): IAdjustTextButton {
        {
            return {
                AdjustTextButton: this.tiketId1Test
            };
        }
    }
}

interface IAdjustDomainOptionAdd {
    AdjustDomainOptionAdd: TAloneAB;
}
class AdjustDomainOptionAddAB extends ParallelAB implements ITests<IAdjustDomainOptionAdd>{
    public get tests(): IAdjustDomainOptionAdd {
        {
            return {
                AdjustDomainOptionAdd: this.tiketId1Test
            };
        }
    }
}

interface IAdjustShowHideOptionRenewModal {
    AdjustShowHideOptionRenewModal: TAloneAB;
}
class AdjustShowHideOptionRenewModalAB extends ParallelAB implements ITests<IAdjustShowHideOptionRenewModal> {
    public get tests(): IAdjustShowHideOptionRenewModal {
        {
            return {
                AdjustShowHideOptionRenewModal: this.tiketId1Test
            };
        }
    }
}

interface IAdjustRenewCouponCode {
    AdjustRenewCouponCode: TAloneAB;
}
class AdjustRenewCouponCodeAB extends ParallelAB implements ITests<IAdjustRenewCouponCode> {
    public get tests(): IAdjustRenewCouponCode {
        {
            return {
                AdjustRenewCouponCode: this.tiketId1Test
            };
        }
    }
}

interface IAdjustRsRecommendWithNameServer {
    AdjustRsRecommendWithNameServer: TAloneAB;
}
class AdjustRsRecommendWithNameServerAB extends ParallelAB implements ITests<IAdjustRsRecommendWithNameServer> {
    public get tests(): IAdjustRsRecommendWithNameServer {
        {
            return {
                AdjustRsRecommendWithNameServer: this.tiketId1Test
            };
        }
    }
}

interface IAdjustNetdeShindanModalTop {
    AdjustNetdeShindanModalTop: TAloneAB;
}
class AdjustNetdeShindanModalTopAB extends ParallelAB implements ITests<IAdjustNetdeShindanModalTop> {
    public get tests(): IAdjustNetdeShindanModalTop {
        {
            return {
                AdjustNetdeShindanModalTop: this.tiketId1Test
            };
        }
    }
}

interface IAdjustLoginNavigation {
    AdjustLoginNavigation: TAloneAB;
}
class AdjustLoginNavigationAB extends ParallelAB implements ITests<IAdjustLoginNavigation> {
    public get tests(): IAdjustLoginNavigation {
        {
            return {
                AdjustLoginNavigation: this.tiketId1Test
            };
        }
    }
}

interface IAddTotalPriceOfServiceTop {
    AddTotalPriceOfServiceTop: TAloneAB;
}
class AddTotalPriceOfServiceTopAB extends ParallelAB implements ITests<IAddTotalPriceOfServiceTop> {
    public get tests(): IAddTotalPriceOfServiceTop {
        {
            return {
                AddTotalPriceOfServiceTop: this.tiketId1Test
            };
        }
    }
}

/** オブジェクトの名前、イベント名などに使う。*/
class ABTargetNames implements IABTargets<string> {
    public readonly LoginCampaign = 'LoginCampaign';
    public readonly NavigateToTop = 'NavigateToTop';
    public readonly RenovationGlobalNaviSubmenu = 'RenovationGlobalNaviSubmenu';
    public readonly SearchSuggest = 'SearchSuggest';
    public readonly BankInputReccomend = 'BankInputReccomend';
    public readonly AllServicesSurvey = 'AllServicesSurvey';
    public readonly AdjustTldFreeRenew = 'AdjustTldFreeRenew';
    public readonly HeaderInfoDropDownMenu = 'HeaderInfoDropDownMenu';
    public readonly AdjustNetDeDiagnosisModal = 'AdjustNetDeDiagnosisModal';
    public readonly AdjustTextButton = 'AdjustTextButton';
    public readonly AdjustDomainOptionAdd = 'AdjustDomainOptionAdd';
    public readonly AdjustShowHideOptionRenewModal = 'AdjustShowHideOptionRenewModal';
    public readonly AdjustRenewCouponCode = 'AdjustRenewCouponCode';
    public readonly AdjustRsRecommendWithNameServer = 'AdjustRsRecommendWithNameServer';
    public readonly AdjustNetdeShindanModalTop = 'AdjustNetdeShindanModalTop';
    public readonly AdjustLoginNavigation = 'AdjustLoginNavigation';
    public readonly AddTotalPriceOfServiceTop = 'AddTotalPriceOfServiceTop';
}

/** クッキー名やパスの判定に使う */
enum Paths {
    DOMAIN_SETTING_RENEW_LIST = '/domain/setting/renew/list',
    DOMAIN = '/domain',
    TOP = '/top'
}

/** serviceContainerで公開されるServiceクラス
 * */
type TTests =
    ITests<
        | ILoginCampaign
        | ISearchSuggest
        | IBankInputReccomend
        | IAllServicesSurvey
        | IAdjustTldFreeRenew
        | IHeaderInfoDropDownMenu
        | IAdjustNetDeDiagnosisModal
        | IAdjustTextButton
        | IAdjustDomainOptionAdd
        | IAdjustShowHideOptionRenewModal
        | IAdjustRenewCouponCode
        | IAdjustRsRecommendWithNameServer
        | IAdjustNetdeShindanModalTop
        | IAdjustLoginNavigation
        | IAddTotalPriceOfServiceTop
    >;
export class ABCookieService implements IABTargets<TParallelAB | TTests> {

    protected key = new ABTargetNames();
    protected map: IABTargets<any>;

    constructor(protected _http: HttpClient) {

        this.map = {

            LoginCampaign: new LoginCampaignAB(
                this.key.LoginCampaign,
                { tiketId1: 'GT84736_1', tiketId2: 'GT84736_2' },
                this._http
            ),

            //レンタルサーバー未契約
            //McRex経由でABテスト実施する為、こちらの処理を残しておく。新しいABテスト実施する時はチケット番号を変更するだけで良い
            RenovationGlobalNaviSubmenu: new RenovationGlobalNaviSubmenuAB(
                this.key.RenovationGlobalNaviSubmenu,
                { tiketId1: 'GT53443_1', tiketId2: 'GT53443_2' },
                this._http
            ),

            SearchSuggest: new SearchSuggestAB(
                this.key.SearchSuggest,
                { tiketId1: 'GT60701' },
                this._http
            ),

            BankInputReccomend: new BankInputReccomendAB(
                this.key.BankInputReccomend,
                { tiketId1: 'GT63063' },
                this._http
            ),

            AllServicesSurvey: new AllServicesSurveyAB(
                this.key.AllServicesSurvey,
                { tiketId1: 'GT87209' },
                this._http
            ),

            AdjustTldFreeRenew: new AdjustTldFreeRenew(
                this.key.AdjustTldFreeRenew,
                { tiketId1: 'GT111646' },
                this._http
            ),
            HeaderInfoDropDownMenu: new HeaderInfoDropDownMenuAB(
                this.key.HeaderInfoDropDownMenu,
                { tiketId1: 'GT131574' },
                this._http
            ),

            AdjustNetDeDiagnosisModal: new AdjustNetDeDiagnosisModalAB(
                this.key.AdjustNetDeDiagnosisModal,
                { tiketId1: 'GT133916' },
                this._http
            ),
            AdjustTextButton: new AdjustTextButtonAB(
                this.key.AdjustTextButton,
                { tiketId1: 'GT149533' },
                this._http
            ),
            AdjustDomainOptionAdd: new AdjustDomainOptionAddAB(
                this.key.AdjustDomainOptionAdd,
                { tiketId1: 'GT149443' },
                this._http
            ),
            AdjustShowHideOptionRenewModal: new AdjustShowHideOptionRenewModalAB(
                this.key.AdjustShowHideOptionRenewModal,
                { tiketId1: 'GT170515' },
                this._http
            ),
            AdjustRenewCouponCode: new AdjustRenewCouponCodeAB(
                this.key.AdjustRenewCouponCode,
                { tiketId1: 'GT181551' },
                this._http
            ),
            AdjustRsRecommendWithNameServer: new AdjustRsRecommendWithNameServerAB(
                this.key.AdjustRsRecommendWithNameServer,
                { tiketId1: 'GT182882' },
                this._http
            ),
            AdjustNetdeShindanModalTop: new AdjustNetdeShindanModalTopAB(
                this.key.AdjustNetdeShindanModalTop,
                { tiketId1: 'GT182324' },
                this._http
            ),
            AdjustLoginNavigation: new AdjustLoginNavigationAB(
                this.key.AdjustLoginNavigation,
                { tiketId1: 'GT181883' },
                this._http
            ),
            AddTotalPriceOfServiceTop: new AddTotalPriceOfServiceTopAB(
                this.key.AddTotalPriceOfServiceTop,
                { tiketId1: 'GT167521' },
                this._http
            )
        };

        //cookieの生成タイミングがログイン直後（＝MC-REXのロケーションIDが「1」）のときはここで初期化
        (this.map.RenovationGlobalNaviSubmenu as ParallelAB).initAndDispatchFirst();
        (this.map.SearchSuggest as ParallelAB).initAndDispatchFirst();
        (this.map.BankInputReccomend as ParallelAB).initAndDispatchFirst();
        (this.map.LoginCampaign as ParallelAB).initAndDispatchFirst();
        (this.map.AllServicesSurvey as ParallelAB).initAndDispatchFirst();
        (this.map.HeaderInfoDropDownMenu as ParallelAB).initAndDispatchFirst();
        (this.map.AdjustTextButton as ParallelAB).initAndDispatchFirst();
        (this.map.AdjustShowHideOptionRenewModal as ParallelAB).initAndDispatchFirst();
        (this.map.AdjustRenewCouponCode as ParallelAB).initAndDispatchFirst();
        (this.map.AdjustRsRecommendWithNameServer as ParallelAB).initAndDispatchFirst();
        (this.map.AdjustLoginNavigation as ParallelAB).initAndDispatchFirst();
    }

    /**
    * ticket1: 'GT84736'
    * @param path
    */
    public get LoginCampaign(): ITests<ILoginCampaign> {
        return this.map.LoginCampaign;
    }

    /**
    * ticket1: 'GT43690'
    * @param path
    */
    public get RenovationGlobalNaviSubmenu(): TParallelAB {
        return this.map.RenovationGlobalNaviSubmenu;
    }

    /**
     * ticket1:'GT60701'
     * @param path
     */
    public get SearchSuggest(): ITests<ISearchSuggest> {
        return this.map.SearchSuggest;
    }

    /**
     * ticket1:'GT63063'
     * @param path
     */
    public get BankInputReccomend(): ITests<IBankInputReccomend> {
        return this.map.BankInputReccomend;
    }

    /**
     * ticket1:'GT87209'
     * @param path
     */
    public get AllServicesSurvey(): ITests<IAllServicesSurvey> {
        return this.map.AllServicesSurvey;
    }

    /**
    * ticket1:'GT111646'
    * @param path
    */
    public get AdjustTldFreeRenew(): ITests<IAdjustTldFreeRenew> {
        return this.map.AdjustTldFreeRenew;
    }

    /**
    * ticket1:'GT131574'
    * @param path
    */
    public get HeaderInfoDropDownMenu(): ITests<IHeaderInfoDropDownMenu> {
        return this.map.HeaderInfoDropDownMenu;
    }

    /**
    * ticket1:'GT133916'
    * @param path
    */
    public get AdjustNetDeDiagnosisModal(): ITests<IAdjustNetDeDiagnosisModal> {
        return this.map.AdjustNetDeDiagnosisModal;
    }

    /**
    * ticket1:'GT149533'
    * @param path
    */
    public get AdjustTextButton(): ITests<IAdjustTextButton> {
        return this.map.AdjustTextButton;
    }

    /**
    * ticket1: 'GT149443'
    * @param path
    */
    public get AdjustDomainOptionAdd(): ITests<IAdjustDomainOptionAdd> {
        return this.map.AdjustDomainOptionAdd;
    }

    /**
    * ticket1: 'GT170515'
    * @param path
    */
    public get AdjustShowHideOptionRenewModal(): ITests<IAdjustShowHideOptionRenewModal> {
        return this.map.AdjustShowHideOptionRenewModal;
    }

    /**
    * ticket1: 'GT181551'
    * @param path
    */
    public get AdjustRenewCouponCode(): ITests<IAdjustRenewCouponCode> {
        return this.map.AdjustRenewCouponCode;
    }

    /**
    * ticket1: 'GT182882'
    * @param path
    */
    public get AdjustRsRecommendWithNameServer(): ITests<IAdjustRsRecommendWithNameServer> {
        return this.map.AdjustRsRecommendWithNameServer;
    }

    /**
    * ticket1: 'GT182324'
    * @param path
    */
    public get AdjustNetdeShindanModalTop(): ITests<IAdjustNetdeShindanModalTop> {
        return this.map.AdjustNetdeShindanModalTop;
    }

    /**
    * ticket1: 'GT181883'
    * @param path
    */
    public get AdjustLoginNavigation(): ITests<IAdjustLoginNavigation> {
        return this.map.AdjustLoginNavigation;
    }

    /**
    * ticket1: 'GT167521'
    * @param path
    */
    public get AddTotalPriceOfServiceTop(): ITests<IAddTotalPriceOfServiceTop> {
        return this.map.AddTotalPriceOfServiceTop;
    }

    /**
     * cookieの生成タイミングが契約管理画面遷移前（MC-REXのロケーションIDが「2」）のときはここで初期化
     * routerのNavigationStartイベント発生時に実行される(app.component.ts).
     * @param path 遷移先パス
     */
    public async onRaiseRouterEvent_RoutesRecognized(path: string) {
        if (path.startsWith(Paths.TOP)) {
            (this.map.AdjustNetdeShindanModalTop as ParallelAB).initAndDispatchFirst();
            (this.map.AddTotalPriceOfServiceTop as ParallelAB).initAndDispatchFirst();
            return;
        }
        if (path.startsWith(Paths.DOMAIN_SETTING_RENEW_LIST)) {
            (this.map.AdjustTldFreeRenew as ParallelAB).initAndDispatchFirst();
            return;
        }
        if (path.startsWith(Paths.DOMAIN)) {
            (this.map.AdjustDomainOptionAdd as ParallelAB).initAndDispatchFirst();
            return;
        }
    }
}
