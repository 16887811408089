import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { SpUiModule } from './ui.module';
import { SafePipeModule } from 'Content/script/modules/safe.module';

import { SpHeaderComponent } from 'Content/sp/script/components/layout/header/header.component';
import { SpFooterComponent } from 'Content/sp/script/components/layout/footer/footer.component';
import { SpNsDefaultComponent } from 'Content/sp/script/components/awesome/sp_ns_default/sp_ns_default.component';
import { SpRsRecommendComponent } from 'Content/sp/script/components/awesome/sp_rs_recommend/sp_rs_recommend.component';
import { SpNormalAnchorComponent } from 'Content/sp/script/components/awesome/sp_normal_anchor/sp_normal_anchor.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        BrowserAnimationsModule,
        SpUiModule,
        SafePipeModule
    ],
    declarations: [
        SpHeaderComponent,
        SpFooterComponent,
        SpNsDefaultComponent,
        SpNormalAnchorComponent,
        SpRsRecommendComponent
    ],
    exports: [
        SpHeaderComponent,
        SpFooterComponent,
        BrowserAnimationsModule,
        SpUiModule,
        SafePipeModule,
        SpNsDefaultComponent,
        SpRsRecommendComponent,
        SpNormalAnchorComponent
    ]
})

export class SpLayoutModule { }
