import { Component, Input, OnChanges, SimpleChanges, OnInit, Output, EventEmitter, SimpleChange } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceContainer } from "Content/script/service/service_container";
import { ServiceAccountDetail } from '../../../../service/account_detail_service';
import { AnalyticsUtil } from '../../../../libs/adobeanalytics/analytics.util';
import { docCookies, FutureDate } from '../../../../libs/third_party/cookies';
import { DomainSettingRenewListComponent } from '../../../domain/setting/renew/list/list.component';
import { CustomizedEvents, EIeraeNetDeShindanModalEVarType } from '../../../../libs/abtest/custom-event.util';
import { IeraeNetDeShindanService } from '../../../../service/ierae_net_de_shindan_service';

@Component({
    selector: 'top-change-member-information',
    templateUrl: './top_change_member_information.html'
})

export class TopChangeMemberInformationComponent implements OnChanges {
    @Input("routerPath") public routerPath: string;
    @Input("componentRef") public componentRef: any;
    @Output() onChangeModalDisplayState = new EventEmitter<boolean>();

    private cookieKey: string = 'isIgnoreChangeInformation_';

    public hasTarget: boolean;
    public isFirstView: boolean;
    public Address: string;
    public Email: string;
    public isEventFired: boolean;
    
    public domainSettingRenewListComponent: DomainSettingRenewListComponent;

    public constructor(
        public router: Router,
        public route: ActivatedRoute,
        public serviceContainer: ServiceContainer) {
        this.isFirstView = true;
        this.hasTarget = false;
        this.isEventFired = false;
    }

    public ngOnChanges(changes: SimpleChanges) {
        let routerChange = changes["routerPath"];
        // componentRefの変更を検知して、ドメイン更新画面のインスタンスであればDomainSettingRenewListComponentに格納
        let componentChange = changes["componentRef"];
        if (componentChange && componentChange.currentValue && componentChange.currentValue._path == "/domain/setting/renew/list") {
            this.domainSettingRenewListComponent = componentChange.currentValue;
        }

        if (routerChange && routerChange.currentValue !== undefined && routerChange.currentValue !== "/" && routerChange.currentValue !== "/login" && routerChange.currentValue !== "/logout") {
            this._setCookie(routerChange);
        }
        
    }

    private _getAccountDetail(): void {
        var accountDetailPromise: Promise<ServiceAccountDetail.AccountDetail> = this.serviceContainer.AccountDetailService.GetAccountDetail("top_change_member_information.component");
        accountDetailPromise.then((accountDetail) => {
            this.hasTarget = false;
            if (accountDetail.UpdatedDate) {
                this.hasTarget = this._dateDiff(accountDetail.UpdatedDate);
               
            } else {
                if (accountDetail.CreatedDate) {
                    this.hasTarget = this._dateDiff(accountDetail.CreatedDate);
                }
            }
            if (this.hasTarget) {
                this.Address = accountDetail.Address;
                this.Email = accountDetail.Email;
            }
            this.serviceContainer.ModalControlService.HideFullScreenLoading();
            this.onChangeModalDisplayState.emit(this.isDisplay);
        }).catch((errorType: ServiceAccountDetail.ErrorType) => {
            switch (errorType) {
                case ServiceAccountDetail.ErrorType.Error:
                    this.serviceContainer.SessionService.SetCallBackUrl(location.pathname);
                    this.router.navigateByUrl('/login');
                    return;

                case ServiceAccountDetail.ErrorType.NoSession:
                    this.serviceContainer.SessionService.SetCallBackUrl(location.pathname);
                    const queryparams = AnalyticsUtil.getQueryParamsOnFirstRender(window.RouterPath);
                    this.router.navigate(['/login'], { queryParams: queryparams });
                    return;

                case ServiceAccountDetail.ErrorType.UnExcepted:
                    location.href = '/error/server';
                    return;
            }
        });
    }

    private _dateDiff(dateCheck: string): boolean {
        let currentDate = new Date();
        let dateConvert = new Date(dateCheck);

        return Math.floor((Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()) - Date.UTC(dateConvert.getFullYear(), dateConvert.getMonth(), dateConvert.getDate())) / (1000 * 60 * 60 * 24)) > 180;
    }

    public onClickChange(): void {
        this.isFirstView = false;
        // ドメイン更新画面の場合は離脱防止モーダルを解除
        if (this.domainSettingRenewListComponent) {
            this.domainSettingRenewListComponent.viewModel.isSkipModalConfirm = true;
        }
        this.router.navigate(['/account/detail/edit']);
    }

    public onClickIgnore(): void {
        this.isFirstView = false;
        docCookies.setItem(this.cookieKey, '1', FutureDate.afterDays(180), '/');
        this.onChangeModalDisplayState.emit(this.isDisplay);
    }

    public get isDisplay(): boolean {
        let isDisplay = this.isFirstView && this.hasTarget;

        if (!this.isEventFired && isDisplay) {
            // GT#135078【お名前.com Navi】【ネットde診断】eventとeVarの再設置
            let eVarType: EIeraeNetDeShindanModalEVarType = location.pathname == '/top' ? EIeraeNetDeShindanModalEVarType.InfoTopModalCount : EIeraeNetDeShindanModalEVarType.InfoRenewModalCount;
            IeraeNetDeShindanService.dispatchModalEvent(eVarType);

            this.isEventFired = true;
        }

        return isDisplay;
    }

    private _setCookie(routerChange: SimpleChange) {
        if (!window.ProxyId) {
            setTimeout(() => {
                this._setCookie(routerChange);
            }, 300);
        } else {
            // Naviの他の画面から遷移する
            if (routerChange && routerChange.previousValue != undefined) {
                // 前の画面は  / or /login or auth/signon or /auth/singlesignonである
                if (routerChange.previousValue === "/" || routerChange.previousValue === "/login" || routerChange.previousValue.includes("/auth/signon") || routerChange.previousValue.includes("/auth/singlesignon")) {
                    {
                        let _split = this.cookieKey.split('_');
                        if (_split.length > 1) {
                            if (_split[1] !== window.ProxyId) {
                                this.cookieKey = this.cookieKey + window.ProxyId;
                            }
                        } else {
                            this.cookieKey = this.cookieKey + window.ProxyId;
                        }
                    }
                }
            }
            // 画面をリロードする
            else {
                let _split = this.cookieKey.split('_');
                if (_split.length > 1) {
                    if (_split[1] !== window.ProxyId) {
                        this.cookieKey = this.cookieKey + window.ProxyId;
                    }
                } else {
                    this.cookieKey = this.cookieKey + window.ProxyId;
                }

            }
            if (routerChange && routerChange.currentValue != undefined) {
                if (routerChange.currentValue.includes('/domain/setting/renew/list') || routerChange.currentValue.includes("/top")) {
                    let isIgnoreChangeInformation = docCookies.getItem(this.cookieKey) == '1';
                    if (!isIgnoreChangeInformation) {
                        this._getAccountDetail();
                    }
                }
            }
        }
    }
}

