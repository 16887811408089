import { HubsService } from 'Content/script/service/hubs_service.js';
import { SystemComponentId } from 'Content/script/managers/MultipleSendManager';
import { ReceiveResultOfAccountInfo } from 'Content/script/service/tracking_service';

export class AccountDetailService {
    private _accountDetail: ServiceAccountDetail.AccountDetail;

    private _hubService: HubsService;

    private promise: Promise<ServiceAccountDetail.AccountDetail>;

    private _callback: (result: boolean, data: AccountDetailResponseHubDto, errorType?: ServiceAccountDetail.ErrorType) => void;

    constructor(hubService: HubsService) {
        this._accountDetail = new ServiceAccountDetail.AccountDetail();
        this._hubService = hubService;
        this.promise = null;
        this._callback = null;
    }

    public GetAccountDetail(caller: string = null): Promise<ServiceAccountDetail.AccountDetail> {
        if (this.promise != null) {
            return this.promise;
        }

        this.promise = new Promise<ServiceAccountDetail.AccountDetail>((resolva, reject) => {
            this._hubService.multipleSendManager.Send(
                SystemComponentId.ServiceAccountInfo,
                this._hubService.hubsManager.accountHubManager.AccountDetail,
                null,
                (data: AccountDetailResponseHubDto) => {
                    var accountInfo = this.mappingAccountDetail(data);

                    ReceiveResultOfAccountInfo.ok();
                    resolva(accountInfo);
                },
                (data: AccountDetailResponseHubDto) => {
                    if (this._callback) {
                        this._callback(false, data, ServiceAccountDetail.ErrorType.Error);
                    }
                    ReceiveResultOfAccountInfo.error();
                    reject(ServiceAccountDetail.ErrorType.Error);
                },
                (data: AccountDetailResponseHubDto) => {
                    if (this._callback) {
                        this._callback(false, data, ServiceAccountDetail.ErrorType.NoSession);
                    }
                    ReceiveResultOfAccountInfo.error();
                    reject(ServiceAccountDetail.ErrorType.NoSession);
                },
                null,
                (data: AccountDetailResponseHubDto) => {
                    if (this._callback) {
                        this._callback(false, data, ServiceAccountDetail.ErrorType.UnExcepted);
                    }
                    ReceiveResultOfAccountInfo.error();
                    reject(ServiceAccountDetail.ErrorType.UnExcepted);
                }
            );
        });

        return this.promise;
    }

    public Reset(): void {
        if (this.promise != void (0) && this.promise != null) {
            this.promise.then(this._deletePromise).catch(this._deletePromise);
        }
    }

    public bindCallback(callback: (result: boolean, data: AccountDetailResponseHubDto, errorType: ServiceAccountDetail.ErrorType) => void) {
        this._callback = callback;
    }

    private _deletePromise = (): void => {
        this.promise = null;
    }

    public mappingAccountDetail(data: AccountDetailResponseHubDto): ServiceAccountDetail.AccountDetail {
        return {
            LoginName: data.LoginName,
            Role: data.Role,
            Lname: data.Lname,
            LnameML: data.LnameML,
            Fname: data.Fname,
            FnameML: data.FnameML,
            Organization: data.Organization,
            OrganizationML: data.OrganizationML,
            UpdatedDate: data.UpdatedDate,
            CreatedDate: data.CreatedDate,
            Address: data.CityML + data.Street1TownML + data.Street1NumberML + data.Street2ML,
            Email: data.Email,

        } as ServiceAccountDetail.AccountDetail;
    }
}


export namespace ServiceAccountDetail {
    export class AccountDetail {
        public LoginName: string;
        public Role: string;
        public LnameML: string;
        public Lname: string;
        public FnameML: string;
        public Fname: string;
        public OrganizationML: string;
        public Organization: string;
        public UpdatedDate: string;
        public CreatedDate: string;
        public Address: string;
        public Email: string;
        constructor() {
            this.LoginName = '';
            this.Role = '';
            this.LnameML = '';
            this.Lname = '';
            this.FnameML = '';
            this.Fname = '';
            this.OrganizationML = '';
            this.Organization = '';
            this.UpdatedDate = '';
            this.CreatedDate = '';
            this.Address = '';
            this.Email = '';
        }
    }

    export enum ErrorType {
        Error,
        NoSession,
        UnExcepted
    }
}
