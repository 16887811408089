import { Component, Input, DoCheck } from '@angular/core';
import { Router } from "@angular/router";
import { NgForm, NgModel } from '@angular/forms';
import { ServiceContainer } from 'Content/script/service/service_container';
import { BaseViewModel } from "Content/script/components/base_component";

@Component({
    selector: 'voaci-modal',
    templateUrl: './voaci_modal.html'
})

export class VoaciModalComponent implements DoCheck {
    @Input('componentRef') _componentRef: any;

    public queueId: number;
    public viewModel: VoaciModalViewModel;

    public constructor(
        private router: Router,
        private serviceContainer: ServiceContainer) {
        this.queueId = serviceContainer.HubsService.multipleSendManager.GetComponentId();

        this.viewModel = new VoaciModalViewModel(
            this.queueId,
            router,
            serviceContainer);
    }

    ngDoCheck() {
        let _isShowVoaciModal: boolean = false;
        let _voaciMethod: string;
        let _destination: string;
        let _closeFunc: Function;
        let _passedVoaciFunc: Function;

        const component = this._componentRef;
        if (this.viewModel.isShow || !component) {
            return;
        }

        if (!this.isVoaciComponent(component)) {
            return;
        }

        for (var target of Object.entries(TargetPathName).map(([_, value]) => value))
        {
            if (location.pathname.startsWith(target)) {
                _isShowVoaciModal = component.model.isShowVoaciModal;
                _voaciMethod = component.model.voaciMethod;
                _destination = component.model.destination;
                _closeFunc = component.model.onClickCloseVoaciModal;
                _passedVoaciFunc = component.model.passedVoaci;
                break;
            }
        }

        if (_isShowVoaciModal && !!_voaciMethod) {
            this.viewModel.init(_voaciMethod, _closeFunc, _passedVoaciFunc, _destination);
        }
    }

    private isVoaciComponent(componentRef: any) {
        let model = componentRef.model || null;

        if (!model) {
            return false;
        }

        let flg = model.isShowVoaciModal || null;

        return flg !== null;
    }
}

class VoaciModalViewModel extends BaseViewModel {
    public isShow: boolean = false;
    public isError: boolean = false;
    public isRateLimitExceeded: boolean = false;
    public isTokenReissue: boolean = false;
    public isTokenCreate: boolean = true;
    public isProcessing: boolean = true;
    public modalTitle: string = '';
    public modalExplanation: string = '';
    public voaciCode: string = '';
    public gtmValuePreFix: string = '';
    public voaciMethod: string = '';
    public destination: string = '';
    private _onModalClose: Function;
    private _onPassedVoaci: Function;
    private _isInit: boolean = true;

    constructor(
        queueId: number,
        router: Router,
        serviceContainer: ServiceContainer
    ) {
        super(queueId, router, serviceContainer);
    }

    public init(voaciMethod: string, modalCloseFunc: Function, passedVoaciFunc: Function, destination: string) {
        this.voaciMethod = voaciMethod;
        this.destination = destination;
        this.setTitleAndExplanation(voaciMethod);
        this._onModalClose = modalCloseFunc;
        this._onPassedVoaci = passedVoaciFunc;
        this._isInit = true;
        this.voaciTokenCreate();
        this.isShow = true;
    }

    public setTitleAndExplanation(voaciMethod: string) {
        switch (location.pathname) {
            case location.pathname.startsWith(TargetPathName.AccountUpdate) && location.pathname:
                this.gtmValuePreFix = "accountupdate_";
                break;

            case location.pathname.startsWith(TargetPathName.ProvisionalaccountUpdate) && location.pathname:
                this.gtmValuePreFix = "provisionalaccountUpdate_";
                break;

            default:
                this.gtmValuePreFix = "";
                break;
        }

        switch (voaciMethod) {
            case "11":
                this.gtmValuePreFix = this.gtmValuePreFix + "mail_";
                this.modalTitle = "メールアドレス有効性確認";
                this.modalExplanation = "ご入力いただいている連絡先メールアドレス宛に認証コードを送信しました。受信した認証コードをご入力ください。しばらくお待ちいただいても届かない場合は、再発行ボタンをクリックしてください。（認証コードの有効期限は10分です。）";
                break;

            default:
                this.gtmValuePreFix = "";
                this.modalTitle = "";
                this.modalExplanation = "";
                break;
        }
    }

    /**
     * 次へボタン
     */
    public onClickNext(form: NgForm) {
        this.isError = false;
        this.isRateLimitExceeded = false;
        this.isTokenReissue = false;
        this.isTokenCreate = true;
        if (form.valid === false) {
            for (let key in form.controls) {
                form.controls[key].markAsDirty();
            }
            return;
        }

        this.verifyVoaciCode();

        form.resetForm();
    }

    /**
     * 認証処理
     */
    private verifyVoaciCode() {
        this.isProcessing = true;

        var requestObject: VerifyMfaCodeRequestHubDto = {
            MfaCode: this.voaciCode
        };

        this.serviceContainer.HubsService.multipleSendManager.Send(
            this.queueId,
            this.serviceContainer.HubsService.hubsManager.authHubManager.VerifyMfaCode,
            requestObject,
            (responseObject: VerifyMfaCodeResponseHubDto) => {
                this._onModalClose();
                this.isShow = false;
                this._onPassedVoaci();
            },
            (responseObject: VerifyMfaCodeResponseHubDto) => {
                if (responseObject.IsRateLimitExceeded) {
                    this.isRateLimitExceeded = true;
                }
                else {
                    this.isError = true;
                }
                this.isProcessing = false;
            },
            this.NoSession,
            null,
            this.UnExceptedError
        );
    }

    /**
     * 再発行ボタン
     */
    public onClickTokenCreate(model: NgModel) {
        this.isError = false;
        this.isRateLimitExceeded = false;
        this.isTokenReissue = false;
        this.isTokenCreate = true;
        model.reset();
        this.voaciTokenCreate();
    }

    /**
     * 認証トークン発行
     */
    private voaciTokenCreate() {
        this.isProcessing = true;

        var requestObject: MfaTokenCreateRequestHubDto = {
            MfaMethod: this.voaciMethod,
            //ログイン後の認証のためfalse
            IsVoaci: false,
            Destination: this.destination
        };

        this.serviceContainer.HubsService.multipleSendManager.Send(
            this.queueId,
            this.serviceContainer.HubsService.hubsManager.authHubManager.MfaTokenCreate,
            requestObject,
            (responseObject: MfaTokenCreateResponseHubDto) => {
                this.isProcessing = false;
                this.isTokenCreate = true;
            },
            (responseObject: MfaTokenCreateResponseHubDto) => {
                this.isProcessing = false;
                this.isTokenCreate = false;
            },
            this.NoSession,
            () => {
                if (!this._isInit) {
                    this.isTokenReissue = true;
                }
                this._isInit = false;
            },
            this.UnExceptedError
        );
    }

    /**
     * 閉じるボタン
     */
    public onClickClose() {
        this.isError = false;
        this.isRateLimitExceeded = false;
        this.isTokenReissue = false;
        this.isTokenCreate = true;
        this._onModalClose();
        this.isProcessing = true;
        this.isShow = false;
    }
}

enum TargetPathName {
    AccountUpdate = '/account/detail/edit',
    ProvisionalaccountUpdate = "/provisionalaccount/detail/edit"
}
