import { Directive, ElementRef, Input, HostListener } from '@angular/core';

@Directive({
    selector: '[dropdownHover-b]'
})
export class DropDownHoverBDirective {
    private _el: HTMLElement;

    private targetElement: HTMLElement;

    private bodyEventLitner: EventListener;


    @Input('data-target-b')
    public target: string;

    constructor(private _elementRef: ElementRef) {
        this._el = _elementRef.nativeElement;

        this.bodyEventLitner = (event: MouseEvent) => {
            if (this.targetElement) {
                this.targetElement.classList.remove('is-Show');

                this.targetElement.style.left = '';
                this.targetElement.style.top = '';
            }
        };
    }

    @HostListener('mouseenter', ['$event'])
    public onMouseEnter(event: Event) {
        var setLeft = 0;
        var setTop = 0;
        if (!this.targetElement) {
            this.targetElement = document.querySelector(this.target) as HTMLElement;
            this.targetElement.addEventListener("mouseenter", (event: MouseEvent) => {
                event.stopImmediatePropagation();
                event.stopPropagation();
            });
        }
        if (!this.targetElement.classList.contains('is-Show')) {
            this.targetElement.classList.add('is-Show');
        }
        
        var thisRect = this._el.getBoundingClientRect();
        var dataModule = this._el.getAttribute('data-module');
        var dataTarget = this._el.getAttribute('data-target-b');
        setTop = thisRect.top;
        setLeft = thisRect.width;
        if (dataModule === 'globalSideNaviDomain' && dataTarget !== '#globalSideNaviEtc') {
            setTop -= 14 - window.pageYOffset;
        } else if (dataTarget === '#globalSideNaviEtc') {
            setTop += thisRect.height - this.targetElement.offsetHeight + window.pageYOffset;
        } else {
            setTop += (thisRect.height - this.targetElement.offsetHeight) / 2 - window.pageYOffset;
        }
        if (dataModule === 'globalSideNaviDomain') {
            setLeft -= 20;
        }

        if (dataModule === 'globalSideNaviDomain' && dataTarget === '#globalSideNaviSupport') {
            setTop -= 24;
        }

        this.targetElement.style.top = setTop + 'px';
        this.targetElement.style.left = setLeft + 'px';

        event.stopImmediatePropagation();
        event.stopPropagation();

        return false;
    }

    @HostListener('mouseleave', ['$event'])
    public onMouseOut(event: Event) {
        if (!this.targetElement.classList.contains('is-Active')) {
            this.targetElement.classList.remove('is-Show');
        }
        event.stopImmediatePropagation();
        event.stopPropagation();

        return false;
    }


    @HostListener('window:scroll', ['$event'])
    public onScroll(event: Event) {
        let elements = document.querySelectorAll('.is-GlobalSideNavi');

        elements.forEach((element) => {
            if (element.classList.contains('is-Show')) {
                element.classList.remove('is-Show');
            }
        });

        event.stopImmediatePropagation();
        event.stopPropagation();

        return false;
    }

}
