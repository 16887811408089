<div class="modal" [class.modal-withPopupOption]="!isDisableHosting" id="modalUpdateDomain" modal [openFlg]="isShow">
  <div class="modal-Dialog is-QuickRenewal is-AutoWidth is-WithOptions is-DisplayInlineBlock">
        <div class="modal-Dialog-QuickRenewal">
            <div class="modal-Dialog-QuickRenewal-Option" *ngIf="domains.length > 0 && isDisableHosting">
                <div class="popup is-Top" id="popupQuickRenewal1" style="z-index:10">
                    <div class="popup-Arrow"></div>
                    <div class="popup-Content">
                        <p>ドメインオプションの<br>設定忘れはありませんか？</p>
                    </div><!-- /.popup-Content -->
                </div>
                <p class="modal-Dialog-QuickRenewal-Option-Title">オプション選択</p>
                <ul class="modal-Dialog-QuickRenewal-Option-List">
                    <ng-container *ngIf="!isABNew_AdjustShowHideOptionRenewModal">
                        <li class="modal-Dialog-QuickRenewal-Option-List-Item">
                            <label class="modal-Dialog-QuickRenewal-Option-Panel-Checkbox" [class.is-Disabled]="isDisableWhoisProxy"  [class.is-Active]="isCheckedWhoisProxy">
                                <input type="checkbox" name="whoisProxy" value="isCheckedWhoisProxy" class="gtmclick" [attr.data-gtmvalue]="'checked_whois_product_0_' + isCheckedWhoisProxy" [(ngModel)]="isCheckedWhoisProxy" (change)="onChangeWhoisProxyOption()" [disabled]="isDisableWhoisProxy">
                                <span class="form-Checkbox is-Large" [class.is-Disabled]="isDisableWhoisProxy" tabindex="0"></span>
                            </label>
                            <p class="modal-Dialog-QuickRenewal-Option-Panel-Text">Whois情報公開代行</p>
                            <div class="modal-Dialog-QuickRenewal-Option-Panel-Popup">
                                <button type="button" class="btn-IconOrigin ml8 gtmclick" data-gtmvalue="modal_whois" placement="top" (click)="onClickQuestionCircle('whoisProxy')"><i class="icon icon-QuestionCircle gtmclick" data-gtmvalue="modal_whois"></i></button>
                            </div>
                        </li>
                        <li class="modal-Dialog-QuickRenewal-Option-List-Item">
                            <label class="modal-Dialog-QuickRenewal-Option-Panel-Checkbox" [class.is-Disabled]="isDisableWhoisMailFwd" [class.is-Active]="isCheckedWhoisMailFwd">
                                <input type="checkbox" name="whoisMailFwd" value="isCheckedWhoisMailFwd" class="gtmclick" [attr.data-gtmvalue]="'checked_whois_mail_0_' + isCheckedWhoisMailFwd" [(ngModel)]="isCheckedWhoisMailFwd" (change)="onChangeWhoisMailFwdOption()" [disabled]="isDisableWhoisMailFwd">
                                <span class="form-Checkbox is-Large" [class.is-Disabled]="isDisableWhoisMailFwd" tabindex="0"></span>
                            </label>
                            <p class="modal-Dialog-QuickRenewal-Option-Panel-Text">Whois情報公開代行<br>メール転送オプション</p>
                            <div class="modal-Dialog-QuickRenewal-Option-Panel-Popup">
                                <button type="button" class="btn-IconOrigin ml8 gtmclick" data-gtmvalue="modal_whoisop" placement="top" (click)="onClickQuestionCircle('whoisProxyMailFwd')"><i class="icon icon-QuestionCircle gtmclick" data-gtmvalue="modal_whoisop"></i></button>
                            </div>
                        </li>
                        <li class="modal-Dialog-QuickRenewal-Option-List-Item">
                            <label class="modal-Dialog-QuickRenewal-Option-Panel-Checkbox" [class.is-Disabled]="isDisableProtection" [class.is-Active]="isCheckedProtection">
                                <input type="checkbox" name="protection" value="isCheckedProtection" class="gtmclick" [attr.data-gtmvalue]="'checked_protection_' + isCheckedProtection" [(ngModel)]="isCheckedProtection" [disabled]="isDisableProtection" (change)="onChangeProtectionOption()">
                                <span class="form-Checkbox is-Large" [class.is-Disabled]="isDisableProtection" tabindex="0"></span>
                            </label>
                            <p class="modal-Dialog-QuickRenewal-Option-Panel-Text">ドメインプロテクション</p>
                            <div class="modal-Dialog-QuickRenewal-Option-Panel-Popup">
                                <button type="button" class="btn-IconOrigin ml8 gtmclick" data-gtmvalue="modal_protection" placement="top" (click)="onClickQuestionCircle('protection')"><i class="icon icon-QuestionCircle gtmclick" data-gtmvalue="modal_protection"></i></button>
                            </div>
                        </li>
                        <li class="modal-Dialog-QuickRenewal-Option-List-Item">
                            <label class="modal-Dialog-QuickRenewal-Option-Panel-Checkbox" [class.is-Disabled]="isDisableSsl" [class.is-Active]="isCheckedSsl">
                                <input type="checkbox" name="ssl" value="isCheckedSsl" class="gtmclick" [attr.data-gtmvalue]="'checked_ssl_' + isCheckedSsl" [(ngModel)]="isCheckedSsl" (change)="onChangeSslOption()" [disabled]="isDisableSsl">
                                <span class="form-Checkbox is-Large" [class.is-Disabled]="isDisableSsl" tabindex="0"></span>
                            </label>
                            <p class="modal-Dialog-QuickRenewal-Option-Panel-Text">SSL証明書</p>
                            <div class="modal-Dialog-QuickRenewal-Option-Panel-Popup">
                                <button type="button" class="btn-IconOrigin ml8 gtmclick" data-gtmvalue="modal_ssl" placement="top" (click)="onClickQuestionCircle('ssl')"><i class="icon icon-QuestionCircle gtmclick" data-gtmvalue="modal_ssl"></i></button>
                            </div>
                        </li>
                    </ng-container>
                    <li class="modal-Dialog-QuickRenewal-Option-List-Item" *ngIf="isDisableHosting">
                      <label class="modal-Dialog-QuickRenewal-Option-Panel-Checkbox" [class.is-Disabled]="isDisableHosting" [class.is-Active]="isCheckedHosting">
                        <input type="checkbox" name="hosting" value="isCheckedHosting" class="gtmclick" [attr.data-gtmvalue]="'checked_hosting_' + isCheckedHosting" [(ngModel)]="isCheckedHosting" (change)="onChangeHostingOption()" [disabled]="isDisableHosting"><span class="form-Checkbox is-Large" [class.is-Disabled]="isDisableHosting" tabindex="0"></span>
                      </label>
                      <p class="modal-Dialog-QuickRenewal-Option-Panel-Text">レンタルサーバー</p>
                      <div class="modal-Dialog-QuickRenewal-Option-Panel-Popup">
                        <button type="button" class="btn-IconOrigin ml8 gtmclick" data-gtmvalue="modal_rs" onamaePopup placement="top" content="同時申込み限定で&lt;br&gt;ドメインが「1円」！&lt;br&gt;さらに利用料が月額最大2ヶ月無料！" (click)="onClickQuestionCircle('hosting')"><i class="icon icon-QuestionCircle gtmclick" data-gtmvalue="modal_rs"></i></button>
                      </div>
                    </li>
                </ul>
            </div>

            <div class="modal-Dialog-QuickRenewal-Confirm" *ngIf="isDisableHosting">
                <h3 class="modal-Dialog-QuickRenewal-Confirm-Title">お申込み内容の確認</h3>
                <div class="modal-Dialog-QuickRenewal-Confirm-Wrap">
                    <div class="modal-Dialog-QuickRenewal-Confirm-Box">
                        <h4 class="modal-Dialog-QuickRenewal-Confirm-Heading">ドメイン</h4>
                        <div class="modal-Dialog-QuickRenewal-Confirm-Order is-W400 mt12">
                            <div class="modal-Dialog-QuickRenewal-Confirm-Order-Footer" *ngIf="domains.length > 0">
                                <div class="modal-Dialog-QuickRenewal-Confirm-Order-Footer-GMOPoint" *ngIf="gmoPointSetting.isCollaboration && !hasCoupon && gmoPointSetting.currentPoint !== '0'">
                                    <p class="modal-Dialog-QuickRenewal-Confirm-Order-Footer-GMOPoint-Title">GMOポイント利用</p>
                                    <p class="modal-Dialog-QuickRenewal-Confirm-Order-Footer-GMOPoint-Num">{{gmoPointDiscountPrice}}(円)</p>
                                </div>
                                <div class="modal-Dialog-QuickRenewal-Confirm-Order-Footer-GMOPoint" *ngIf="isViewFee">
                                    <p class="modal-Dialog-QuickRenewal-Confirm-Order-Footer-GMOPoint-Title">お支払手数料</p>
                                    <p class="modal-Dialog-QuickRenewal-Confirm-Order-Footer-GMOPoint-Num">{{convenienceFee}}(円)</p>
                                </div>
                                <div class="modal-Dialog-QuickRenewal-Confirm-Order-Footer-Amount">
                                    <p class="modal-Dialog-QuickRenewal-Confirm-Order-Footer-Amount-Title">支払金額<span>(税込)</span></p>
                                    <p class="modal-Dialog-QuickRenewal-Confirm-Order-Footer-Amount-Num">{{totalPrice}}<span>円</span></p>
                                </div>
                            </div>
                            <div class="modal-Dialog-QuickRenewal-Confirm-Order-Contents" [class.is-H150]="isDisplayFreeRegistModal" [class.is-H400]="!isDisplayFreeRegistModal || domains.length === 0" [class.modal-Dialog-QuickRenewal-Confirm-Order-Contents-Lost]="domains.length === 0">
                                <p class="mb0" *ngIf="domains.length === 0">ドメインが選択されていません</p>
                                <div class="modal-Dialog-QuickRenewal-Confirm-Order-Contents-Item" *ngFor="let domain of domains">
                                    <div class="modal-Dialog-QuickRenewal-Confirm-Order-Contents-Header">
                                        <p class="modal-Dialog-QuickRenewal-Confirm-Order-Contents-Header-Text" [class.is-AutoWidth]="!isTotalPriceMoreThan5000 || isShowCouponDomainDetail">{{domain.domainNameMl}}</p>
                                        <div class="modal-Dialog-QuickRenewal-Confirm-Order-Contents-Header-Renewal" *ngIf="isTotalPriceMoreThan5000 && isSelectedOnlyOneDomain && !isShowCouponDomainDetail">
                                            <p class="modal-Dialog-QuickRenewal-Confirm-Order-Contents-Header-Renewal-Heading">更新年数</p>
                                            <div class="modal-Dialog-QuickRenewal-Confirm-Order-Contents-Header-Renewal-Select">
                                                <span class="form-Select is-Auto">
                                                    <select name="{{selectedUpdateYear + domain.domainId}}" [(ngModel)]="domain.updateYear" (change)="onChangeUpdateYear(domain.domainId,$event.target.value)">
                                                        <option *ngFor="let updateYearItem of updateYearItems" value="{{updateYearItem}}">
                                                            {{updateYearItem}}年
                                                        </option>
                                                    </select>
                                                </span>
                                            </div>
                                        </div>
                                        <ng-container *ngIf="!isSelectedOnlyOneDomain">
                                            <i class="icon icon-Clear" (click)="onClickRemoveDomain(domain.domainId)"></i>
                                        </ng-container>
                                    </div>
                                    <div class="modal-Dialog-QuickRenewal-Confirm-Order-Contents-Body">
                                        <div class="modal-Dialog-QuickRenewal-Confirm-Order-Contents-Detail">
                                            <ng-container *ngFor="let detail of domain.details">
                                                <ng-container [ngSwitch]="detail.contentType">
                                                    <dl *ngSwitchCase="ContentType.DomainRenew">
                                                        <dt>{{detail.content}} {{domain.updateYear}}年</dt>
                                                        <dd>{{detail.totalPrice}}円</dd>
                                                    </dl>
                                                    <dl *ngSwitchCase="ContentType.Other">
                                                        <dt>{{detail.content}}</dt>
                                                        <dd>{{detail.totalPrice}}円</dd>
                                                    </dl>
                                                    <dl *ngSwitchCase="ContentType.DomainRenewWithRs">
                                                        <dt>{{detail.content}}</dt>
                                                    </dl>
                                                </ng-container>
                                            </ng-container>
                                        </div>
                                        <ng-container *ngFor="let detail of domain.details">
                                            <div class="modal-Dialog-QuickRenewal-Confirm-Order-Contents-Detail" *ngIf="detail.contentType == ContentType.Coupon">
                                                <dl>
                                                    <dt class="text-Em">{{detail.content}}</dt>
                                                    <dd class="text-Em">{{detail.totalPrice}}円</dd>
                                                </dl>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h4 class="modal-Dialog-QuickRenewal-Confirm-Heading" *ngIf="isDisplayFreeRegistModal && domains.length > 0">ドメイン登録</h4>
                        <div class="modal-Dialog-QuickRenewal-Confirm-Order is-W400 mt12" *ngIf="isDisplayFreeRegistModal && domains.length > 0">
                            <div class="modal-Dialog-QuickRenewal-Confirm-Order-Footer">
                                <div class="modal-Dialog-QuickRenewal-Confirm-Order-Footer-Amount">
                                    <p class="modal-Dialog-QuickRenewal-Confirm-Order-Footer-Amount-Title">支払金額<span>(税込)</span></p>
                                    <p class="modal-Dialog-QuickRenewal-Confirm-Order-Footer-Amount-Num">{{totalPriceFreeRegist}}<span>円</span></p>
                                </div>
                            </div>
                            <div class="modal-Dialog-QuickRenewal-Confirm-Order-Contents is-H150">
                                <div class="modal-Dialog-QuickRenewal-Confirm-Order-Contents-Item" *ngFor="let freeDomain of freeDomains">
                                    <div class="modal-Dialog-QuickRenewal-Confirm-Order-Contents-Header">
                                        <p class="modal-Dialog-QuickRenewal-Confirm-Order-Contents-Header-Text is-AutoWidth">{{freeDomain.domainNameMl}}</p>
                                    </div>
                                    <div class="modal-Dialog-QuickRenewal-Confirm-Order-Contents-Body">
                                        <div class="modal-Dialog-QuickRenewal-Confirm-Order-Contents-Detail">
                                            <ng-container *ngFor="let detail of freeDomain.details">
                                                <ng-container [ngSwitch]="detail.contentType">
                                                    <dl *ngSwitchCase="ContentType.DomainRenew">
                                                        <dt>{{detail.content}} {{freeDomain.updateYear}}年</dt>
                                                        <dd>{{detail.totalPrice}}円</dd>
                                                    </dl>
                                                    <dl *ngSwitchCase="ContentType.Other">
                                                        <dt>{{detail.content}}</dt>
                                                        <dd>{{detail.totalPrice}}円</dd>
                                                    </dl>
                                                </ng-container>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h4 class="modal-Dialog-QuickRenewal-Confirm-Heading" *ngIf="(gmoPointSetting.isCollaboration && !hasCoupon && gmoPointSetting.currentPoint !== '0' && domains.length > 0) || isShowPaymentType">お支払い方法</h4>
                        <div class="modal-Dialog-QuickRenewal-Confirm-Payment is-AutoWidth mt12" *ngIf="isShowPaymentType">
                            <ng-container [ngSwitch]="paymentType">
                                <dl *ngSwitchCase="PaymentType.RegisteredCreditCard">
                                    <dt>登録済みのクレジットカード</dt>
                                    <dd>{{maskedCreditNumber}}</dd>
                                </dl>
                                <dl *ngSwitchCase="PaymentType.NewCreditCard">
                                    <dt>登録済みのクレジットカード</dt>
                                    <dd>{{maskedCreditNumber}}</dd>
                                </dl>
                                <dl *ngSwitchCase="PaymentType.Convenience">
                                    <dt>コンビニエンスストア</dt>
                                    <ng-container [ngSwitch]="conveniInfo.ConveniCode">
                                        <dd *ngSwitchCase="ConvenienceType.SevenEleven">セブンイレブン</dd>
                                        <dd *ngSwitchCase="ConvenienceType.FamilyMart">ファミリーマート（姓名 : {{conveniInfo.UserNameKana}}）</dd>
                                        <dd *ngSwitchCase="ConvenienceType.Lawson">ローソン</dd>
                                        <dd *ngSwitchCase="ConvenienceType.SeicoMart">セイコーマート</dd>
                                    </ng-container>
                                </dl>
                                <dl *ngSwitchCase="PaymentType.Bank">
                                    <dt>銀行振込</dt>
                                    <dd></dd>
                                </dl>
                            </ng-container>
                        </div>
                        <table class="tbl mt20 mb20" *ngIf="gmoPointSetting.isCollaboration && !hasCoupon && gmoPointSetting.currentPoint !== '0' && domains.length > 0">
                            <colgroup>
                                <col width="130">
                                <col width="">
                            </colgroup>
                            <tbody>
                                <tr>
                                    <th class="is-ValignMiddle">GMOポイント</th>
                                    <ng-container [ngSwitch]="gmoPointSetting.useType">
                                        <td class="text-Right is-BgWhite size-12" *ngSwitchCase="PointUsingType.NotCollaboration">
                                            GMOポイントと連携していません
                                        </td>
                                        <td class="text-Right is-BgWhite size-12" *ngSwitchCase="PointUsingType.NotHavePoint">
                                            利用できるポイントがありません
                                        </td>
                                        <td class="text-Right is-BgWhite size-12" *ngSwitchCase="PointUsingType.NotUsePoint">
                                            GMOポイントは使用できません
                                        </td>
                                        <td class="text-Right is-BgWhite" *ngSwitchCase="PointUsingType.NonUsePoint">
                                            <span class="size-11">使用しない</span>
                                            <button type="button" class="btn is-Xsmall ml16 gtmclick" data-gtmvalue="open_gmopointmodal" (click)="onClickOpenGmoModal()">変更</button>
                                        </td>
                                        <td class="text-Right is-BgWhite pl8" *ngSwitchCase="PointUsingType.UsePoint">
                                            <span class="size-11">使用する：{{gmoPointSetting.usePoint}}pt</span>
                                            <button type="button" class="btn is-Xsmall ml8 gtmclick" data-gtmvalue="open_gmopointmodal" (click)="onClickOpenGmoModal()">変更</button>
                                        </td>
                                    </ng-container>
                                </tr>
                            </tbody>
                        </table><!-- /.tbl -->
                    </div>
                    <div class="modal-Dialog-QuickRenewal-Confirm-Box">
                        <div class="modal-Dialog-QuickRenewal-Confirm-Box-Wrap">
                            <button type="button" class="btn is-Block is-Large is-Green gtmclick" [class.is-Green]="domains.length > 0" [class.is-Disabled]="domains.length == 0" (click)="onClickConfirm()" [attr.data-gtmvalue]="gtmTag" [attr.data-gtmvalue-for-aa]="purchasedInfoString">申し込む<i class="icon icon-ArrowRight"></i></button>
                            <div class="modal-Dialog-QuickRenewal-Confirm-Agreement">
                                <h4 class="modal-Dialog-QuickRenewal-Confirm-Heading">利用規約</h4>
                                <div class="box-Border">
                                    <div class="box-Border-Inner pb0 pl16 pr16 pt16">
                                        <ul class="list-Normal size-11">
                                            <li>私は<a href="javascript:void(0)" (click)="openUrl('https://www.onamae.com/agreement/a_ag/registration.html')" class="gtmclick" data-gtmvalue="agreement_registration">お名前.comドメイン登録規約</a>、<a href="javascript:void(0)" (click)="openUrl('https://www.onamae.com/agreement/a_ag/dispute.html')" class="gtmclick" data-gtmvalue="agreement_dispute">お名前.comドメイン紛争処理規定</a>、<a href="javascript:void(0)" (click)="openUrl('https://www.gmo.jp/csr/governance/privacy-policy/')" class="gtmclick" data-gtmvalue="privacy-policy_onamae">および個人情報の取り扱いに関する方針</a>に同意し上記内容を申請します。</li>
                                            <li>ドメイン契約更新完了後の料金の返金が行われないことに同意します。</li>
                                            <li *ngIf="isCheckedHosting">お名前.com レンタルサーバーを申し込む場合、<a href="javascript:void(0)" (click)="openUrl('https://www.onamae-server.com/agreement/')" class="gtmclick" data-gtmvalue="agreement_server">お名前.com レンタルサーバーサービス会員規約</a>に同意します。</li>
                                            <ng-container *ngIf="isCheckedSsl">
                                                <li>「SSLサーバー証明書」は1年の仮申込みとなります。ドメイン更新完了後に送信される完了通知に従い、別途「SSLサーバー証明書」の申請が必要です。ドメイン更新完了日から6か月以内に所定のお手続きがない場合、キャンセルとなります。</li>
                                                <li>お名前.comの「レンタルサーバー」でSSLをご利用の際は、「お名前.com Navi」よりレンタルサーバーの「独自SSL」をお申込みください。</li>
                                            </ng-container>
                                        </ul><!-- /.list-Normal -->
                                    </div><!-- /.box-Border-Inner -->
                                </div>
                            </div>
                            <div class="modal-Dialog-QuickRenewal-Confirm-Agreement" *ngIf="isShowSurcharge">
                                <h4 class="modal-Dialog-QuickRenewal-Confirm-Heading">注意事項</h4>
                                <div class="box-Border">
                                    <div class="box-Border-Inner pb0 pl16 pr16 pt16">
                                        <ul class="list-Normal size-11">
                                            <li>
                                                各種価格高騰の情勢に鑑み、誠に不本意ながら2023年2月1日以降サービス品質維持のため、対象となる料金に一定割合の「サービス維持調整費」をいただきます。なにとぞご理解ご協力をいただきたくお願い申し上げます。
                                                <br>割引が適用される場合は、割引後価格に基づいてご請求させていただきます。
                                            </li>
                                        </ul><!-- /.list-Normal -->
                                    </div><!-- /.box-Border-Inner -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal-Dialog-QuickRenewal-Confirm is-W600" *ngIf="!isDisableHosting">
                <div class='modal-Dialog-QuickRenewal-Confirm-Order is-NoBorder' [ngClass]="{'is-withPopup' : !isCheckedHosting,'is-FullWidth' : isCheckedHosting,'is-MultiDomain' : domains.length > 1}">
                    <h3 class="modal-Dialog-QuickRenewal-Confirm-Title">お申込み内容の確認</h3>
                    <h4 class="modal-Dialog-QuickRenewal-Confirm-Heading" [class.is-Relative]="isCheckedHosting">
                        内訳
                        <a href="javascript:void(0)" class="floatLink size-11" (click)="showPopupPreview()" *ngIf="isCheckedHosting">レンタルサーバーの申し込みを取り消す</a>
                    </h4>
                    <div class="modal-Dialog-QuickRenewal-Confirm-Order-Footer is-Dark is-NoBorder mt12"  *ngIf="domains.length > 0" [class.is-W400]="(domains.length > 1)">
                        <div class="modal-Dialog-QuickRenewal-Confirm-Order-Footer-GMOPoint" *ngIf="gmoPointSetting.isCollaboration && !hasCoupon && gmoPointSetting.currentPoint !== '0'">
                            <p class="modal-Dialog-QuickRenewal-Confirm-Order-Footer-GMOPoint-Title">GMOポイント利用</p>
                            <p class="modal-Dialog-QuickRenewal-Confirm-Order-Footer-GMOPoint-Num">{{gmoPointDiscountPrice}}(円)</p>
                        </div>
                        <div class="modal-Dialog-QuickRenewal-Confirm-Order-Footer-GMOPoint" *ngIf="isViewFee">
                            <p class="modal-Dialog-QuickRenewal-Confirm-Order-Footer-GMOPoint-Title">お支払手数料</p>
                            <p class="modal-Dialog-QuickRenewal-Confirm-Order-Footer-GMOPoint-Num">{{convenienceFee}}(円)</p>
                        </div>
                        <div class="modal-Dialog-QuickRenewal-Confirm-Order-Footer-Amount">
                            <p class="modal-Dialog-QuickRenewal-Confirm-Order-Footer-Amount-Title">支払金額<span>(税込)</span></p>
                            <p class="modal-Dialog-QuickRenewal-Confirm-Order-Footer-Amount-Num">{{totalPrice}}<span>円</span></p>
                        </div>
                    </div>
                    <div class="modal-Dialog-QuickRenewal-Confirm-Order-Contents mt8" [ngClass]="{'is-ScrollYAuto':domains.length == 1,'is-AutoHeight' : domains.length == 1 && isCheckedHosting,'has-Scroll': domains.length > 1}">
                        <ng-container *ngIf="domains.length === 1">
                            <div class="modal-Dialog-QuickRenewal-Confirm-Order-Contents-Item" *ngFor="let domain of domains; let i = index;">
                                <div type="button" class="modal-Dialog-QuickRenewal-Confirm-Order-Contents-Heading" *ngIf="isTotalPriceMoreThan5000"> 
                                    <p class="modal-Dialog-QuickRenewal-Confirm-Order-Contents-Heading-Text text-Bold" [class.is-W210]="!isCheckedHosting && !isShowCouponDomainDetail" [class.is-W440]="isCheckedHosting" showHideToggle>{{domain.domainNameMl}}</p>
                                    <div class="modal-Dialog-QuickRenewal-Confirm-Order-Contents-Toggle-Function" *ngIf="!isShowCouponDomainDetail">
                                        <span class="size-11">更新年数</span>
                                        <span class="form-Select is-Auto">
                                            <select name="{{selectedUpdateYear + domain.domainId}}" [(ngModel)]="domain.updateYear" (change)="onChangeUpdateYear(domain.domainId,$event.target.value)">
                                                <option *ngFor="let updateYearItem of updateYearItems" value="{{updateYearItem}}">
                                                    {{updateYearItem}}年
                                                </option>
                                            </select>
                                        </span>
                                    </div>
                                </div>
                                <ng-container *ngIf="!isTotalPriceMoreThan5000">
                                    <a href="javascript:void(0)" class="modal-Dialog-QuickRenewal-Confirm-Order-Contents-Toggle" showHideToggle>
                                        <p class="modal-Dialog-QuickRenewal-Confirm-Order-Contents-Toggle-Text text-Bold">{{domain.domainNameMl}}</p>
                                    </a>
                                </ng-container>
                                <div class="modal-Dialog-QuickRenewal-Confirm-Order-Contents-Body is-ShowHideToggle" [ngClass]="{'is-Yellow' : isCheckedHosting,'is-Hide' : i != 0}">
                                    <div class="modal-Dialog-QuickRenewal-Confirm-Order-Contents-Detail">
                                        <ng-container *ngFor="let detail of domain.details">
                                            <ng-container [ngSwitch]="detail.contentType">
                                                <dl *ngSwitchCase="ContentType.DomainRenew">
                                                    <dt>{{detail.content}} {{domain.updateYear}}年
                                                        <span class="modal-Dialog-QuickRenewal-Confirm-Order-Badge size-10 ml8" *ngIf="(domainNameMlFree === domain.domainNameMl) && isCheckedHosting"><span class="text-Em text-Bold">レンタルサーバー利用中は永久に無料！</span></span>
                                                    </dt>
                                                    <dd [ngClass]="{'text-Em text-Bold' : isCheckedHosting}">{{detail.totalPrice}}円</dd>
                                                </dl>
                                                <dl *ngSwitchCase="ContentType.Other">
                                                    <dt>{{detail.content}}</dt>
                                                    <dd [ngClass]="{'text-Em text-Bold' : isCheckedHosting}">{{detail.totalPrice}}円</dd>
                                                </dl>
                                                <dl *ngSwitchCase="ContentType.Coupon">
                                                    <dt>{{detail.content}}</dt>
                                                    <dd [ngClass]="{'text-Em text-Bold' : isCheckedHosting}">{{detail.totalPrice}}円</dd>
                                                </dl>
                                                <dl *ngSwitchCase="ContentType.DomainRenewWithRs">
                                                    <dt>{{detail.content}}</dt>
                                                </dl>
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="domains.length > 1">
                            <div class="modal-Dialog-QuickRenewal-Confirm-Order-Contents-Item" *ngFor="let domain of domains; let i = index;">
                                <div type="button" class="modal-Dialog-QuickRenewal-Confirm-Order-Contents-Toggle" showHideToggle>
                                    <p class="modal-Dialog-QuickRenewal-Confirm-Order-Contents-Toggle-Text text-Bold" [class.is-W300]="!isCheckedHosting" [class.is-W440]="isCheckedHosting" >{{domain.domainNameMl}}</p>
                                    <button class="btn-Clear" (click)="onClickRemoveDomain(domain.domainId)" *ngIf="domains.length > 1"><i class="icon icon-Clear"></i></button>
                                    <i class="icon icon-ArrowDown" *ngIf="domains.length > 1"></i>
                                 </div>
                                <div class="modal-Dialog-QuickRenewal-Confirm-Order-Contents-Body is-ShowHideToggle" [ngClass]="{'is-Yellow' : isCheckedHosting,'is-Hide' : i != 0}">
                                    <div class="modal-Dialog-QuickRenewal-Confirm-Order-Contents-Detail">
                                        <ng-container *ngFor="let detail of domain.details">
                                            <ng-container [ngSwitch]="detail.contentType">
                                                <dl *ngSwitchCase="ContentType.DomainRenew">
                                                    <dt>{{detail.content}} {{domain.updateYear}}年
                                                        <span class="modal-Dialog-QuickRenewal-Confirm-Order-Badge size-10 ml8" *ngIf="(domainNameMlFree === domain.domainNameMl) && isCheckedHosting"><span class="text-Em text-Bold">レンタルサーバー利用中は永久に無料！</span></span>
                                                    </dt>
                                                    <dd [ngClass]="{'text-Em text-Bold' : isCheckedHosting}">{{detail.totalPrice}}円</dd>
                                                </dl>
                                                <dl *ngSwitchCase="ContentType.Other">
                                                    <dt>{{detail.content}}</dt>
                                                    <dd [ngClass]="{'text-Em text-Bold' : isCheckedHosting}">{{detail.totalPrice}}円</dd>
                                                </dl>
                                                <dl *ngSwitchCase="ContentType.Coupon">
                                                    <dt>{{detail.content}}</dt>
                                                    <dd [ngClass]="{'text-Em text-Bold' : isCheckedHosting}">{{detail.totalPrice}}円</dd>
                                                </dl>
                                                <dl *ngSwitchCase="ContentType.DomainRenewWithRs">
                                                    <dt>{{detail.content}}</dt>
                                                </dl>
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <div class="popupOption" [ngClass]="{'is-MultiDomain' : domains.length > 1,'is-SingleDomain' : domains.length == 1}" *ngIf="!isCheckedHosting">
						            <p class="popupOption-Attention">断然お安く更新できます！</p>
						            <div class="mb12">
							              <label>
                                <input type="checkbox" name="hosting" value="isCheckedHosting" class="gtmclick" [attr.data-gtmvalue]="'checked_hosting_' + isCheckedHosting" [(ngModel)]="isCheckedHosting" (change)="onChangeHostingOption()" [disabled]="isDisableHosting">
                                <span class="form-Checkbox is-Large" tabindex="0"></span>
                                <span class="size-18 text-Bold ml8">レンタルサーバーを一緒に申込む</span>
							              </label>
							              <p class="size-11 text-Center mb0">
                                <a href="javascript:void(0)" (click)="openUrl('https://www.onamae.com/server/campaign/renewregist_navi/?btn_id=navi_renew_rswith_modal_lp')">詳細はこちら</a>
                            </p>
						            </div>
						            <div class="modal-Dialog-QuickRenewal-Confirm-Order-Footer is-Dark">
							              <div class="modal-Dialog-QuickRenewal-Confirm-Order-Footer-Amount">
								                <p class="modal-Dialog-QuickRenewal-Confirm-Order-Footer-Amount-Title">申し込み後の支払い金額<span>(税込)</span></p>
                                <p class="modal-Dialog-QuickRenewal-Confirm-Order-Footer-Amount-Num">{{totalPriceCheckedHosting}}<span>円</span></p>
							              </div>
						            </div>
						            <div class="modal-Dialog-QuickRenewal-Confirm-Order-Contents mt8 has-Scroll">
							              <div class="modal-Dialog-QuickRenewal-Confirm-Order-Contents-Item mt0" [class.is-NoBorderTop]="i > 0" *ngFor="let domain of domainsCheckedHosting; let i = index;">
								                <div class="modal-Dialog-QuickRenewal-Confirm-Order-Contents-Heading" *ngIf="domainsCheckedHosting.length == 1">
                                    <p class="modal-Dialog-QuickRenewal-Confirm-Order-Contents-Heading-Text text-Bold">{{domain.domainNameMl}}</p>
								                </div>
                                <ng-container *ngIf="domainsCheckedHosting.length > 1">
								                    <a href="javascript:void(0)" class="modal-Dialog-QuickRenewal-Confirm-Order-Contents-Toggle" showHideToggle>
                                        <p class="modal-Dialog-QuickRenewal-Confirm-Order-Contents-Toggle-Text text-Bold">{{domain.domainNameMl}}</p>
									                      <i class="icon icon-ArrowDown"></i>
								                    </a>
                                </ng-container>
                                    
								                <div class="modal-Dialog-QuickRenewal-Confirm-Order-Contents-Body is-Yellow is-ShowHideToggle" [class.is-Hide]="i != 0">
                                    <div class="modal-Dialog-QuickRenewal-Confirm-Order-Contents-Detail">
                                        <ng-container *ngFor="let detail of domain.details">
                                            <ng-container [ngSwitch]="detail.contentType">
                                                <dl *ngSwitchCase="ContentType.DomainRenew">
                                                    <dt>{{detail.content}} {{domain.updateYear}}年
                                                        <span class="modal-Dialog-QuickRenewal-Confirm-Order-Badge size-10 ml8" *ngIf="domainNameMlFree === domain.domainNameMl"><span class="text-Em text-Bold">レンタルサーバー利用中は永久に無料！</span></span>
                                                    </dt>
                                                    <dd class="text-Em text-Bold">{{detail.totalPrice}}円</dd>
                                                </dl>
                                                <dl *ngSwitchCase="ContentType.Other">
                                                    <dt>
                                                        {{detail.content}}
                                                    </dt>
                                                    <dd class="text-Em text-Bold">{{detail.totalPrice}}円</dd>
                                                </dl>
                                                <dl *ngSwitchCase="ContentType.Coupon">
                                                    <dt>
                                                      {{detail.content}}
                                                    </dt>
                                                    <dd class="text-Em text-Bold">{{detail.totalPrice}}円</dd>
                                                </dl>
                                                <dl *ngSwitchCase="ContentType.DomainRenewWithRs">
                                                    <dt>{{detail.content}}</dt>
                                                </dl>
                                            </ng-container>
                                        </ng-container>
                                    </div>
								                </div>
							              </div>
						            </div>
							          <div class="modal-Dialog-QuickRenewal-Confirm-Order-Contents-Item is-AutoHeight mt8 is-Border">
								            <div class="modal-Dialog-QuickRenewal-Confirm-Order-Contents-Heading">
									              <p class="modal-Dialog-QuickRenewal-Confirm-Order-Contents-Heading-Text text-Bold">レンタルサーバー</p>
								            </div>
								            <div class="modal-Dialog-QuickRenewal-Confirm-Order-Contents-Body is-Yellow">
									              <div class="modal-Dialog-QuickRenewal-Confirm-Order-Contents-Detail pb16">
										                <dl>
											                  <dt>初期費用</dt>
											                  <dd class="text-Em text-Bold">0円</dd>
										                </dl>
										                <dl>
											                  <dt>月額料金<span class="modal-Dialog-QuickRenewal-Confirm-Order-Badge size-10 ml8"><span class="text-Em text-Bold">最大2ヶ月間は無料！</span>（その後月額1,430円）</span></dt>
											                  <dd class="text-Em text-Bold">0円</dd>
										                </dl>
									              </div>
								            </div>
							          </div>
                    </div>
                    <div class="modal-Dialog-QuickRenewal-Confirm-Order-Contents-Item is-Border is-AutoHeight is-ScrollYAuto mt8" *ngIf="isCheckedHosting">
                        <div class="modal-Dialog-QuickRenewal-Confirm-Order-Contents-Heading">
                            <p class="modal-Dialog-QuickRenewal-Confirm-Order-Contents-Heading-Text text-Bold">レンタルサーバー</p>
                        </div>
                        <div class="modal-Dialog-QuickRenewal-Confirm-Order-Contents-Body is-Yellow">
                            <div class="modal-Dialog-QuickRenewal-Confirm-Order-Contents-Detail">
                                <dl>
                                    <dt>初期費用</dt>
                                    <dd class="text-Em text-Bold">0円</dd>
                                </dl>
                                <dl>
                                    <dt>月額料金<span class="modal-Dialog-QuickRenewal-Confirm-Order-Badge size-10 ml8"><span class="text-Em text-Bold">最大2ヶ月間は無料！</span>（その後月額1,430円）</span></dt>
                                    <dd class="text-Em text-Bold">0円</dd>
                                </dl>
                            </div>
                        </div>
                    </div>
                    <div class="modal-Dialog-QuickRenewal-Confirm-Order-Contents-Item is-Border is-AutoHeight is-ScrollYAuto mt8" *ngIf="isShowPriceCoupon || isShowPriceCouponDomainDetail && !isCheckedHosting">
                        <div class="modal-Dialog-QuickRenewal-Confirm-Order-Contents-Heading">
                            <p class="modal-Dialog-QuickRenewal-Confirm-Order-Contents-Heading-Text text-Bold">クーポン割引金額</p>
                        </div>
                        <div class="modal-Dialog-QuickRenewal-Confirm-Order-Contents-Body is-Yellow">
                            <div class="modal-Dialog-QuickRenewal-Confirm-Order-Contents-Detail" *ngIf="isShowPriceCoupon">
                                <dl>
                                    <dt>クーポン割引</dt>
                                    <dd class="text-Em text-Bold">{{gmoTaxedDiscount}}円</dd>
                                </dl>
                            </div>
                            <div class="modal-Dialog-QuickRenewal-Confirm-Order-Contents-Detail" *ngIf="isShowPriceCouponDomainDetail && !isCheckedHosting">
                                <dl>
                                    <dt>1回限り！更新2年値引きクーポン</dt>
                                    <dd class="text-Em text-Bold">{{-gmoTaxedDiscount}}円</dd>
                                </dl>
                            </div>
                        </div>
                    </div>
                </div>
                <h4 class="modal-Dialog-QuickRenewal-Confirm-Heading" *ngIf="isDisplayFreeRegistModal && domains.length > 0">ドメイン登録</h4>
                <div class="modal-Dialog-QuickRenewal-Confirm-Order is-FullWidth mt12 is-NoBorder" *ngIf="isDisplayFreeRegistModal && domains.length > 0">
                    <div class="modal-Dialog-QuickRenewal-Confirm-Order-Footer is-Dark">
                        <div class="modal-Dialog-QuickRenewal-Confirm-Order-Footer-Amount">
                            <p class="modal-Dialog-QuickRenewal-Confirm-Order-Footer-Amount-Title">支払金額<span>(税込)</span></p>
                            <p class="modal-Dialog-QuickRenewal-Confirm-Order-Footer-Amount-Num">{{totalPriceFreeRegist}}<span>円</span></p>
                        </div>
                    </div>
                    <div class="modal-Dialog-QuickRenewal-Confirm-Order-Contents is-Border is-AutoHeight is-ScrollYAuto mt8">
                        <div class="modal-Dialog-QuickRenewal-Confirm-Order-Contents-Item" *ngFor="let freeDomain of freeDomains">
                            <div class="modal-Dialog-QuickRenewal-Confirm-Order-Contents-Header">
                                <p class="modal-Dialog-QuickRenewal-Confirm-Order-Contents-Header-Text">{{freeDomain.domainNameMl}}</p>
                            </div>
                            <div class="modal-Dialog-QuickRenewal-Confirm-Order-Contents-Body">
                                <div class="modal-Dialog-QuickRenewal-Confirm-Order-Contents-Detail">
                                    <ng-container *ngFor="let detail of freeDomain.details">
                                        <ng-container [ngSwitch]="detail.contentType">
                                            <dl *ngSwitchCase="ContentType.DomainRenew">
                                                <dt>{{detail.content}} {{freeDomain.updateYear}}年</dt>
                                                <dd>{{detail.totalPrice}}円</dd>
                                            </dl>
                                            <dl *ngSwitchCase="ContentType.Other">
                                                <dt>{{detail.content}}</dt>
                                                <dd>{{detail.totalPrice}}円</dd>
                                            </dl>
                                        </ng-container>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <h4 class="modal-Dialog-QuickRenewal-Confirm-Heading" *ngIf="(gmoPointSetting.isCollaboration && !hasCoupon && gmoPointSetting.currentPoint !== '0' && domains.length > 0) || isShowPaymentType">お支払い方法</h4>
                <div class="modal-Dialog-QuickRenewal-Confirm-Payment is-AutoWidth mt12" *ngIf ="isShowPaymentType">
                    <ng-container [ngSwitch]="paymentType">
                        <dl *ngSwitchCase="PaymentType.RegisteredCreditCard">
                            <dt>登録済みのクレジットカード</dt>
                            <dd>{{maskedCreditNumber}}</dd>
                        </dl>
                        <dl *ngSwitchCase="PaymentType.NewCreditCard">
                            <dt>登録済みのクレジットカード</dt>
                            <dd>{{maskedCreditNumber}}</dd>
                        </dl>
                        <dl *ngSwitchCase="PaymentType.Convenience">
                            <dt>コンビニエンスストア</dt>
                            <ng-container [ngSwitch]="conveniInfo.ConveniCode">
                                <dd *ngSwitchCase="ConvenienceType.SevenEleven">セブンイレブン</dd>
                                <dd *ngSwitchCase="ConvenienceType.FamilyMart">ファミリーマート（姓名 : {{conveniInfo.UserNameKana}}）</dd>
                                <dd *ngSwitchCase="ConvenienceType.Lawson">ローソン</dd>
                                <dd *ngSwitchCase="ConvenienceType.SeicoMart">セイコーマート</dd>
                            </ng-container>
                        </dl>
                        <dl *ngSwitchCase="PaymentType.Bank">
                            <dt>銀行振込</dt>
                            <dd></dd>
                        </dl>
                    </ng-container>
                </div>
                <table class="tbl mt20 mb20" *ngIf="gmoPointSetting.isCollaboration && !hasCoupon && gmoPointSetting.currentPoint !== '0' && domains.length > 0">
                    <colgroup>
                        <col width="130">
                        <col width="">
                    </colgroup>
                    <tbody>
                        <tr>
                            <th class="is-ValignMiddle">GMOポイント</th>
                            <ng-container [ngSwitch]="gmoPointSetting.useType">
                                <td class="text-Right is-BgWhite size-12" *ngSwitchCase="PointUsingType.NotCollaboration">
                                  GMOポイントと連携していません
                                </td>
                                <td class="text-Right is-BgWhite size-12" *ngSwitchCase="PointUsingType.NotHavePoint">
                                  利用できるポイントがありません
                                </td>
                                <td class="text-Right is-BgWhite size-12" *ngSwitchCase="PointUsingType.NotUsePoint">
                                  GMOポイントは使用できません
                                </td>
                                <td class="text-Right is-BgWhite" *ngSwitchCase="PointUsingType.NonUsePoint">
                                  <span class="size-11">使用しない</span>
                                  <button type="button" class="btn is-Xsmall ml16 gtmclick" data-gtmvalue="open_gmopointmodal" (click)="onClickOpenGmoModal()">変更</button>
                                </td>
                                <td class="text-Right is-BgWhite pl8" *ngSwitchCase="PointUsingType.UsePoint">
                                  <span class="size-11">使用する：{{gmoPointSetting.usePoint}}pt</span>
                                  <button type="button" class="btn is-Xsmall ml8 gtmclick" data-gtmvalue="open_gmopointmodal" (click)="onClickOpenGmoModal()">変更</button>
                                </td>
                            </ng-container>
                        </tr>
                    </tbody>
                </table><!-- /.tbl -->
                <button type="button" class="btn is-Block is-Large is-Large is-DisplayBlock gtmclick" [class.is-Green]="domains.length > 0" [class.is-Disabled]="domains.length == 0" (click)="onClickConfirm()" [attr.data-gtmvalue]="gtmTag" [attr.data-gtmvalue-for-aa]="purchasedInfoString">申し込む<i class="icon icon-ArrowRight"></i></button>
                <div class="modal-Dialog-QuickRenewal-Confirm-Agreement">
                    <h4 class="modal-Dialog-QuickRenewal-Confirm-Heading">利用規約</h4>
                    <div class="box-Border">
                        <div class="box-Border-Inner pb0 pl16 pr16 pt16">
                            <ul class="list-Normal size-11">
                                <li>私は<a href="javascript:void(0)" (click)="openUrl('https://www.onamae.com/agreement/a_ag/registration.html')" class="gtmclick" data-gtmvalue="agreement_registration">お名前.comドメイン登録規約</a>、<a href="javascript:void(0)" (click)="openUrl('https://www.onamae.com/agreement/a_ag/dispute.html')" class="gtmclick" data-gtmvalue="agreement_dispute">お名前.comドメイン紛争処理規定</a>、<a href="javascript:void(0)" (click)="openUrl('https://www.gmo.jp/csr/governance/privacy-policy/')" class="gtmclick" data-gtmvalue="privacy-policy_onamae">および個人情報の取り扱いに関する方針</a>に同意し上記内容を申請します。</li>
                                <li>ドメイン契約更新完了後の料金の返金が行われないことに同意します。</li>
                                <li *ngIf="isCheckedHosting">お名前.com レンタルサーバーを申し込む場合、<a href="javascript:void(0)" (click)="openUrl('https://www.onamae-server.com/agreement/')" class="gtmclick" data-gtmvalue="agreement_server">お名前.com レンタルサーバーサービス会員規約</a>に同意します。</li>
                                <ng-container *ngIf="isCheckedSsl">
                                    <li>「SSLサーバー証明書」は1年の仮申込みとなります。ドメイン更新完了後に送信される完了通知に従い、別途「SSLサーバー証明書」の申請が必要です。ドメイン更新完了日から6か月以内に所定のお手続きがない場合、キャンセルとなります。</li>
                                    <li>お名前.comの「レンタルサーバー」でSSLをご利用の際は、「お名前.com Navi」よりレンタルサーバーの「独自SSL」をお申込みください。</li>
                                </ng-container>
                            </ul><!-- /.list-Normal -->
                        </div><!-- /.box-Border-Inner -->
                    </div>
                </div>
                <div class="modal-Dialog-QuickRenewal-Confirm-Agreement" *ngIf="isShowSurcharge">
                    <h4 class="modal-Dialog-QuickRenewal-Confirm-Heading">注意事項</h4>
                    <div class="box-Border">
                        <div class="box-Border-Inner pb0 pl16 pr16 pt16">
                            <ul class="list-Normal size-11">
                                <li>
                                  各種価格高騰の情勢に鑑み、誠に不本意ながら2023年2月1日以降サービス品質維持のため、対象となる料金に一定割合の「サービス維持調整費」をいただきます。なにとぞご理解ご協力をいただきたくお願い申し上げます。
                                  <br>割引が適用される場合は、割引後価格に基づいてご請求させていただきます。
                                </li>
                            </ul><!-- /.list-Normal -->
                        </div><!-- /.box-Border-Inner -->
                    </div>
                </div>
            </div>
        </div><!-- /.modal-Dialog-Contents -->
         <button type="button" class="modal-Close js-popupFixed gtmclick" data-gtmvalue="navi_modal_Cancel" data-dismiss="modal" (click)="onCloseButtonClicked()" (mouseenter)="onMouseEnter($event)" (mouseleave)="onMouseLeave($event)" data-toggle="popup" data-target="#popupDummy7">
            <i class="icon icon-Clear gtmclick" data-gtmvalue="navi_modal_Cancel" aria-label="閉じる"></i>
        </button>
    </div><!-- /.modal-Dialog -->
    <ng-container *ngIf="gmoPointSetting.isCollaboration && !hasCoupon">
        <div class="modal" id="gmoModal" modal [openFlg]="gmoPointSetting.isOpenedModal">
            <gmo-point-modal-2 [useGmoPoint]="useGmoPoint" (constructed)="onInitGmoModal($event)" (clickCancel)="onClickGmoModalCancel($event)" (clickDone)="onClickGmoModalDone($event)"></gmo-point-modal-2>
        </div>
    </ng-container>
    <div *ngIf="isLoading" class="loading" style="display:block">
        <div class="loading-Loader">
            <div class="loading-Loader-Blade"></div>
            <div class="loading-Loader-Blade"></div>
            <div class="loading-Loader-Blade"></div>
            <div class="loading-Loader-Blade"></div>
            <div class="loading-Loader-Blade"></div>
            <div class="loading-Loader-Blade"></div>
            <div class="loading-Loader-Blade"></div>
            <div class="loading-Loader-Blade"></div>
            <div class="loading-Loader-Blade"></div>
            <div class="loading-Loader-Blade"></div>
            <div class="loading-Loader-Blade"></div>
            <div class="loading-Loader-Blade"></div>
        </div>
    </div>
</div>

<div class="modal" id="modalCoupon" modal [openFlg]="isShowNewModalCoupon">
    <div class="modal-Dialog is-524">
        <div class="modal-Dialog-Body modal-Dialog-QuickRenewal-Confirm pt0 pr0 pb0 pl0" *ngIf="!isShowCoupon1500Yen">
            <img class="vertical-Bottom" src="{{CacheBusting('/Content/images/domain/renew/banner_57616.png')}}" width="524" height="400" loading="lazy" alt="通常料金から110円引き">
        </div>
        <div class="modal-Dialog-Body modal-Dialog-QuickRenewal-Confirm pt0 pr0 pb0 pl0" *ngIf="isShowCoupon1500Yen">
            <img class="vertical-Bottom" src="{{CacheBusting('/Content/images/domain/renew/banner_img02.png')}}" width="524" height="400" loading="lazy" alt="通常料金から1500円引き">
        </div>
        <div class="modal-Dialog-Footer is-BgWhite is-NoBorder pt0">
            <ul class="list-Btn is-Right mb0 is-AlignItemFlexStart is-DisplayFlex is-justifyContentEnd">
                <li><button type="button" class="btn is-Large" (click)="onCloseModalCoupon()">今は更新しない</button></li>
                <li class="text-Center">
                    <button type="button" class="btn is-Large is-Primary" (click)="onShowModalRenew()">{{isShowCoupon1500Yen ? '1500' : '110'}}円引きを利用して更新する</button>
                    <p class="text-Right mt8 size-10">※お申込み内容の確認画面が再表示されます</p>
                </li>
            </ul><!-- /.list-Btn -->
        </div><!-- /.modal-Dialog-Footer -->
        <button type="button" class="modal-Close" data-dismiss="modal" (click)="onCloseModalCoupon()"><i class="icon icon-Clear text-White" aria-label="閉じる"></i></button>
    </div><!-- /.modal-Dialog -->
</div>
