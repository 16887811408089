<div class="modal" modal [openFlg]="isDisplay">
    <div class="modal-Dialog is-Wide">
        <div class="modal-Dialog-Header">
            <h3 class="modal-Dialog-Header-Title is-Caution"><img alt="(!)" class="img-Caution" src="/Content/images/common/icon-caution.svg">期限間近のドメインがあります</h3>
        </div><!-- /.modal-Dialog-Header -->
        <div class="modal-Dialog-Body">
            <p class="modal-Dialog-Body-Title text-Danger">更新期限日を経過すると、サイトアクセスやメール機能などが<br>利用できなくなります。お早めに更新手続きをお済ませください。</p>
            <div class="modal-Dialog-DomainNearDeadline">
                <table class="tbl-Striped is-Hoverable">
                    <colgroup>
                        <col width="">
                        <col width="140">
                    </colgroup>
                    <thead>
                        <tr>
                            <th>ドメイン名</th>
                            <th>更新期限日</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let domain of displayDomains">
                            <td><strong>{{domain?.domainName}}</strong></td>
                            <td class="text-Left">{{domain?.expirationDate}}</td>
                        </tr>
                    </tbody>
                </table><!-- /.tbl-Striped -->
                <p class="text-Right mt12"><span *ngIf="isDisplayText">その他 {{textCount}}件</span></p>
            </div>
            <div class="box-Border is-Thick mb0">
                <div class="box-Border-Inner">
                    <p class="text-Left">■ ドメインを一度失効すると、取り戻すことは極めて困難です。</p>
                    <p class="text-Left">■ また、失効したドメインが再取得専門業者に流用され、ブランドイメージを毀損するトラブルが急増しています。</p>
                    <p class="text-Left">■ ドメインの更新期限日をご確認のうえ、更新が必要なドメインはお早めにお手続きされることをお勧めいたします。</p>
                </div>
            </div>
        </div><!-- /.modal-Dialog-Body -->
        <div class="modal-Dialog-Footer">
            <ul class="list-Btn is-Right mb0">
                <li><button type="button" class="btn gtmclick" data-dismiss="modal" (click)="onClickReturnButton()" data-gtmvalue="logoutrenewmodal_renewbtn">更新手続きを行う</button></li>
                <li><button type="button" class="btn is-Primary gtmclick" (click)="onClickConfirmButton()" data-gtmvalue="logoutrenewmodal_logoutbtn">ログアウトする</button></li>
            </ul><!-- /.list-Btn -->
        </div><!-- /.modal-Dialog-Footer -->
        <button type="button" class="modal-Close gtmclick" data-dismiss="modal" (click)="onClickCloseButton()" data-gtmvalue="logoutrenewmodal_closebtn"><i class="icon icon-Clear gtmclick" aria-label="閉じる" data-gtmvalue="logoutrenewmodal_closebtn"></i></button>
    </div><!-- /.modal-Dialog -->
</div>
