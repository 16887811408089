import { Component } from '@angular/core';

@Component({
    selector: 'error-simple',
    template: `
<p class="box-Auth-Result mt96">
    <i class="icon icon-Clear"></i>エラーが発生しました。
</p>
<div class="box-Single mt72">
    <p class="box-Auth-Lead">
        下記内容をご確認ください。
    </p>
    <div class="box-Border mt32">
        <div class="box-Border-Inner">
            <ul class="list-Note mb0">
                <ng-content></ng-content>
            </ul>
        </div>
    </div>
</div>
`
})
export class ErrorSimpleComponent {
}
