import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UiModule } from 'Content/script/modules/ui.module';
/**
 * Atoms
 * */
import { AtomsModule } from 'Content/script/modules/atoms.module';

/**
 * Organisms
 * */
import { TblComponent } from 'Content/script/components/share/Organisms/table/tbl/tbl.component';
import { NormalPaginationComponent } from 'Content/script/components/share/Organisms/pagination/normal_pagination.component';
import { LayoutPaginationComponent } from 'Content/script/components/share/Organisms/pagination/layout_pagination.component';
import { PageSizeListComponent } from 'Content/script/components/share/Organisms/pagination/page_size_list';
import { DomainSearchDropdownForDomainDeletionComponent } from 'Content/script/components/share/Organisms/domain_search_dropdown_for_domain_deletion/domain_search_dropdown_for_domain_deletion.component';
import { DomainSearchDropdownForApplyDomainDeletionComponent } from 'Content/script/components/share/Organisms/domain_search_dropdown_for_apply_domain_deletion/domain_search_dropdown_for_apply_domain_deletion.component';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        AtomsModule,
        UiModule
    ],
    declarations: [
        TblComponent,
        NormalPaginationComponent,
        LayoutPaginationComponent,
        PageSizeListComponent,
        DomainSearchDropdownForDomainDeletionComponent,
        DomainSearchDropdownForApplyDomainDeletionComponent
    ],
    exports: [
        TblComponent,
        NormalPaginationComponent,
        LayoutPaginationComponent,
        PageSizeListComponent,
        DomainSearchDropdownForDomainDeletionComponent,
        DomainSearchDropdownForApplyDomainDeletionComponent
    ]
})

export class OrganismsModule {}
