import { Component, Input, EventEmitter, Output,OnChanges,SimpleChanges} from '@angular/core';

import { docCookies, FutureDate } from 'Content/script/libs/third_party/cookies';
import { ServiceContainer } from 'Content/script/service/service_container';
import { NgForm } from '@angular/forms';
import { IPageTracking } from '../../../../service/tracking_service';
import { QuestionnaireInfo, QuestionnaireType, QuestionnairePage, QuestionnaireOptionHasImage } from '../../../top/index/top_enquete_popup/top_enquete_popup.component';
import { CustomizedEvents, IAdobeEVar21GT128066 } from '../../../../libs/abtest/custom-event.util';

/**
 * ドメイン移管アンケートモーダル
 */
@Component({
    selector: 'domain-detail-authcode-enquete-popup',
    templateUrl: './domain_detail_authcode_enquete_popup.html',
})

export class DomainDetailEnquetePopupComponent implements OnChanges {
    private _trackingService: IPageTracking;
    @Input() public domainName: string;
    @Input() public domainId: string;
    @Output() onCloseModalWithoutShowAuthCode = new EventEmitter<any>();
    @Output() onTransmitSurvey = new EventEmitter<ServiceInfoSelected>();
    @Output() onCloseModal = new EventEmitter<any>();
    private _isShowModalSurvey: boolean = false;
    @Input()
    public get isShowModalSurvey(): boolean {
        return this._isShowModalSurvey;
    }
    public set isShowModalSurvey(v: boolean) {
        this._isShowModalSurvey = v;
        if (this._isShowModalSurvey) {
            this._trackingService.start();
            CustomizedEvents.dispatchSurveyModal({ isRsPlan: true })
        } else {
            this._trackingService.dispose();
        }
    }
    public isLoading: boolean;
    public questionnaireInfo: QuestionnaireInfo;
    public pages: QuestionnairePage[] = [];
    public isValidateOther1: boolean = false;
    public isValidateOther2: boolean = false;
    public isValidateOther3: boolean = false;
    public isOtherChecked: boolean = false;
    public canShow2ndModal: boolean = false;
    public isErrorValidate: boolean = false;
    public isAnsweredQ1: boolean = false;
    public isAnsweredQ2: boolean = false;
    public isAnsweredQ3: boolean = false;
    public queueId: number;
    public serviceName: string = '';
    public price: string = '';

    public readonly detailPackage: Array<{ domainName: string, price: string }> = [
        { domainName: 'Xserverドメイン', price: '2,856' },
        { domainName: 'スタードメイン', price: '3,560' },
        { domainName: 'さくらのドメイン', price: '5,580' },
        { domainName: 'ムームードメイン', price: '4,216' },
        { domainName: 'Value Domain', price: '3,690' },
    ];

    /**
     * コンストラクタ
     */
    public constructor(private serviceContainer: ServiceContainer) {
        this.queueId = this.serviceContainer.HubsService.multipleSendManager.GetComponentId();
        this._trackingService = this.serviceContainer.TrackingService.provideServiceForModalComponent().create('DomainDetailEnquetePopupComponent');
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.isShowModalSurvey && changes.isShowModalSurvey.currentValue) {
            if (this.isShowModalSurvey) {
                this._requestGetQuestionnaireData();
            }
        }
    }
   

    /**
     * アンケートデータ取得
     */
    private _requestGetQuestionnaireData(): void {
        this.isLoading = true;
        let pattern: number = 0;

        var answerReqHub: QuestionnaireAnswerAllServicesRequestHubDto = {
            AnswerHubList: [],
            UserAgent: window.navigator.userAgent.toLowerCase(),
            Pattern: pattern,
            Screen: "Domain_Detail",
        };
        this.serviceContainer.HubsService.multipleSendManager.Send(
            this.queueId,
            this.serviceContainer.HubsService.hubsManager.questionnaireHubManager.GetQuestionnaireInfoAllServices,
            answerReqHub,
            (resHub: QuestionnaireInfoAllServicesResponseHubDto) => {
                var pageNo = 0;
                var questionnaireArr: QuestionnairePage[] = [];
                resHub.QuestionnaireHubList.map(x => {
                    var questionnaire: QuestionnairePage = new QuestionnairePage();
                    questionnaire.id = x.Id;
                    questionnaire.content = x.Content;
                    questionnaire.order = x.Order;
                    questionnaire.questionNo = x.QuestionNo;
                    questionnaire.requiredFlg = x.RequiredFlg;
                    questionnaire.type = x.Type;

                    x.OptionHubList.map(y => {
                        var option: QuestionnaireOptionHasImage = new QuestionnaireOptionHasImage();
                        option.id = y.Id;
                        option.content = y.Content;
                        option.order = y.Order;
                        questionnaire.options.push(option);
                    });
                    if (questionnaire.type == QuestionnaireType.RadioButtonWithOther) {
                        var option: QuestionnaireOptionHasImage = new QuestionnaireOptionHasImage();
                        option.id = questionnaire.options[questionnaire.options.length - 1].id + 1;
                        option.content = 'その他';
                        option.order = questionnaire.options.length;
                        option.isOther = true;
                        questionnaire.options.push(option);
                    }
                    if (questionnaire.type == QuestionnaireType.CheckBoxWithOther) {
                        var option: QuestionnaireOptionHasImage = new QuestionnaireOptionHasImage();
                        option.id = questionnaire.options[questionnaire.options.length - 1].id + 1;
                        option.content = 'その他';
                        option.order = questionnaire.options.length;
                        option.isOther = true;
                        questionnaire.options.push(option);
                    }
                    questionnaire.pageNo = pageNo++;
                    questionnaireArr.push(questionnaire);
                });

                questionnaireArr[0].isShow = true;

                //最後のページ（アンケートではない）
                var lastPage = new QuestionnairePage();
                lastPage.type = QuestionnaireType.NotQuestionnaire;
                lastPage.pageNo = pageNo++;
                questionnaireArr.push(lastPage);

                this.questionnaireInfo = new QuestionnaireInfo(questionnaireArr);
                this.questionnaireInfo.title = resHub.Title;
                this.pages = this.questionnaireInfo.questionnaires;
                this.isLoading = false;
            },
            null,
            null,
            null,
            null
        );
    }

    public onClickOutsideCloseModal(event: Event) {
        if (event.currentTarget === event.target) {
            this.onCloseModalWithoutShowAuthCode.emit();
            this.isShowModalSurvey = false;
            let cookieCanShow2ndModal = this.domainName + "canShow2ndModal";
            docCookies.setItem(cookieCanShow2ndModal, this.isOtherChecked ? "0" : "1", FutureDate.afterDays(30));
        }
    }

    public onClickClose(): void {
        this.onCloseModalWithoutShowAuthCode.emit();
        this.isShowModalSurvey = false;
        if (event) {
            event.stopPropagation();
        }
        this.questionnaireInfo.qustionnairePager.reset();
        let cookieCanShow2ndModal = this.domainName + "canShow2ndModal";
        docCookies.setItem(cookieCanShow2ndModal, this.isOtherChecked ? "0" : "1", FutureDate.afterDays(30));
    }
    public onClickFinishSurvey(form: NgForm): void {
        this.transmitSurvey(form);
        let getStatusDisplay = this.isOtherChecked ? "0" : "1";
        if (!this.isErrorValidate) {
            this.onTransmitSurvey.emit({ isShowCouponModal: getStatusDisplay, serviceName: this.serviceName, price: this.price });
            let cookieKey = this.domainName + "isDoneSurvey";
            docCookies.setItem(cookieKey, true, FutureDate.afterDays(30));
            let cookieCanShow2ndModal = this.domainName + "canShow2ndModal";
            docCookies.setItem(cookieCanShow2ndModal, this.isOtherChecked ? "0" : "1", FutureDate.afterDays(30));
            this._fireSurveyModal();
            this.isShowModalSurvey = false;
        }
    }
    public transmitSurvey(form: NgForm) {
        if (!form.valid && (this.isValidateOther1 || this.isValidateOther2 || this.isValidateOther3)) {
            for (var key in form.controls) {
                form.controls[key].markAsDirty();
            }
            this.isErrorValidate = true;
            return;
        }

        if (!this.isShowButtonNext) {
            this.isErrorValidate = true;
            return;
        }

        // Q2の1つ目の選択肢が選択されていない場合、Q3の選択肢のその他を選択
        if (!this.pages[1].options[0].isCheck) {
            this.pages[2].options.forEach(x => {
                if (x.isOther) {
                    x.isCheck = true;
                } else {
                    x.isCheck = false;
                }
            });

            this.pages[2].otherText = "Q3表示なし";
        } 

        var answerReqHub: QuestionnaireAnswerAllServicesRequestHubDto = {
            AnswerHubList: [],
            UserAgent: window.navigator.userAgent.toLowerCase(),
            Pattern: 0,
            Screen: "Domain_Detail"
        };
        var answerList = this._getAnswerHubList();
        if (answerList != null && answerList != undefined) {
            answerReqHub.AnswerHubList = answerList;
            this._transmit(answerReqHub);

        }
    }

    public onChangeRadio(page: QuestionnairePage, optionId: number) {
        this.pages.find(x => x.id === page.id).selecltedId = optionId;
        if (this.pages.length > 0) {
            let numberRadioChecked = 0;
            this.pages[0].options.forEach((x, i) => {
                if (x.id === this.pages[0].selecltedId) {
                    numberRadioChecked++;
                    if (x.isOther) {
                        this.isValidateOther1 = true;
                        this.isOtherChecked = true;
                    } else {
                        this.serviceName = x.content;
                        this.price = this.detailPackage.filter(y => y.domainName == this.serviceName)[0]?.price;
                        this.isOtherChecked = false;
                        this.isValidateOther1 = false;
                    }
                }
            });
           
            if (numberRadioChecked > 0) {
                this.isAnsweredQ1 = true;
            } else {
                this.isAnsweredQ1 = false;
            }
        }
    }

    public onChangeCheckBox(pageNo: number) {
        let numberCheckBoxChecked: number = 0;
        this.pages[pageNo].options.forEach(x => {
            if (x.isCheck) {
                numberCheckBoxChecked++;
                if (pageNo == 1) {
                    this.isValidateOther2 = x.isOther;
                } else {
                    this.isValidateOther3 = x.isOther;
                }
            }
        });

        if (pageNo == 1) {
            this.isAnsweredQ2 = numberCheckBoxChecked > 0;
        } else {
            this.isAnsweredQ3 = numberCheckBoxChecked > 0;
        }
    }

    public get isShowButtonNext(): boolean {
        // Q2の1つ目の選択肢が選択されいる時のみ、Q3の回答状況を確認
        if (this.pages.length > 0 && this.pages[1].options[0].isCheck) {
            return this.isAnsweredQ1 && this.isAnsweredQ2 && this.isAnsweredQ3 && this.pages[3].textarea != '';
        } else {
            return this.isAnsweredQ1 && this.isAnsweredQ2 && this.pages[3].textarea != '';
        }
    }

    private _getAnswerHubList(): QuestionnaireAnswerHubDto[] {
        var answerHubList: QuestionnaireAnswerHubDto[] = [];
        var answer: QuestionnaireAnswerHubDto;
        for (var index = 0; index < this.pages.length; index++) {
            var page = this.pages[index];
            switch (page.type) {
                case QuestionnaireType.CheckBox:
                case QuestionnaireType.CheckBoxWithOther:
                    for (let option of page.options) {
                        if (option.isCheck) {
                            if (option.isOther) {
                                answer = {
                                    QuestionId: page.id,
                                    Type: page.type,
                                    Option: '',
                                    Other: page.otherText,
                                };
                            } else {
                                answer = {
                                    QuestionId: page.id,
                                    Type: page.type,
                                    Option: (option.id === 0) ? option.content : option.id.toString(),
                                    Other: 'no_other',
                                };
                            }
                            answerHubList.push(answer);
                        }
                    }
                    break;

                case QuestionnaireType.RadioButton:
                case QuestionnaireType.RadioButtonWithOther:
                    for (let option of page.options) {
                        if (option.id === page.selecltedId) {
                            if (option.isOther) {
                                answer = {
                                    QuestionId: page.id,
                                    Type: page.type,
                                    Option: '',
                                    Other: page.otherText,
                                };
                            } else {
                                answer = {
                                    QuestionId: page.id,
                                    Type: page.type,
                                    Option: (option.id === 0) ? option.content : option.id.toString(),
                                    Other: 'no_other',
                                };
                            }
                            answerHubList.push(answer);
                        }
                    }
                    break;

                case QuestionnaireType.Text:
                    var option = this.getAnswerOptionContent(page.content);
                    if (option) {
                        answer = {
                            QuestionId: page.id,
                            Type: page.type,
                            Option: option,
                            Other: 'no_other',
                        }
                        answerHubList.push(answer);
                    }
                    break;

                case QuestionnaireType.Textarea:
                    if (page.textarea.length > 0) {
                        answer = {
                            QuestionId: page.id,
                            Type: page.type,
                            Option: page.textarea.replace(/<("[^"]*"|'[^']*'|[^'">])*>/g, '\\n'),
                            Other: 'no_other',
                        }
                        answerHubList.push(answer);
                    }
                    break;
            }
        }
        return answerHubList;
    }

    private _transmit(requestHubDto: QuestionnaireAnswerAllServicesRequestHubDto) {
        this.serviceContainer.HubsService.multipleSendManager.Send(
            this.queueId,
            this.serviceContainer.HubsService.hubsManager.questionnaireHubManager.AnswerQuestionnaireAllServices,
            requestHubDto,
            (answerResHub: QuestionnaireAnswerAllServicesResponseHubDto) => {
            },
            null,
            null,
            null,
            null
        );
    }

    private _fireSurveyModal() {
        let ans1 = this.pages[0].options.filter(x => x.isCheck)[0];
        let ans2 = this.pages[1].options.filter(x => x.isCheck);
        let st1 = ans1.isOther ? 'other' : this._getValueFireAnswerOne(ans1.content);
        let st2 = "";
        if (ans2.length > 0) {
            ans2.forEach((x) => {
                st2 += x.isOther ? 'other' : this._getValueFireAnswerTwo(x.content);
            })
        }
        CustomizedEvents.dispatchClickSubmitSurveyModal({ AnswerOne: st1, AnswerTwo: st2 })
    }
    private _getValueFireAnswerOne(str: string) {
        let rs = "";
        switch (str) {
            case "Xserverドメイン": rs = "xserver";
                break;
            case "スタードメイン": rs = "star";
                break;
            case "さくらのドメイン": rs = "sakura";
                break;
            case "ムームードメイン": rs = "mumu";
                break;
            case "Value Domain": rs = "value";
                break;
            case "その他": rs = "other";
                break;
            case "GoogleDomains": rs = "google";
                break;
            default: rs = "";
                break;
        }
        return rs;
    }

    private _getValueFireAnswerTwo(str: string) {
        let rs = "";
        switch (str) {
            case "ドメイン更新費用を抑えたい": rs = "renew	";
                break;
            case "webのリニューアル": rs = "web";
                break;
            case "社内体制の変更": rs = "structure";
                break;
            case "ドメイン管理をまとめたい": rs = "management";
                break;
            case "メール配信が多い": rs = "mail";
                break;
            case "その他": rs = "other";
                break;
            case "ドメイン管理の機能に不満がある": rs = "function";
                break;
            default: rs = "";
                break;

        }
        return rs;

    }

    /**
     * 画面上で表示させず、コード内で値をセットする項目であるか
     * @param content
     */
    public isInternalQuestion(content: string): boolean {
        return RegExp('\\[internal:.*\\].*').test(content);
    }

    /**
     * 該当する値をセットする
     * @param content
     */
    private getAnswerOptionContent(content: string): string {
        if (this.isInternalQuestion(content)) {
            let target = content.replace('[internal:', '').replace(new RegExp('\].*'), '');
            switch (target) {
                case 'domainId':
                    return this.domainId;
                default:
                    return "";
            }
        } else {
            return "";
        }
    }
}

export class ServiceInfoSelected {
    public isShowCouponModal: string;
    public serviceName: string;
    public price: string;

    public constructor() {
        this.isShowCouponModal = '';
        this.serviceName = '';
        this.price = '';
    }
}
