import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SpServiceContainer } from 'Content/sp/script/service/service_container';
import { CustomizedEvents } from '../../../../../script/libs/abtest/custom-event.util';
import { NsDefaultCookie } from '../../../../../script/components/share/Templates/ns_default/ns_default.component';

@Component({
    selector: 'sp-ns-default',
    template: ''
})

export class SpNsDefaultComponent implements OnChanges {
    @Input("routerPath") public routerPath: string;

    public constructor(
        public router: Router,
        public route: ActivatedRoute,
        public serviceContainer: SpServiceContainer) {
    }

    public ngOnChanges(changes: SimpleChanges) {
        let screenIndex = '-1';
        let routerChange = changes["routerPath"];
        if (routerChange && routerChange.currentValue != undefined) {
            if (routerChange.currentValue === "/" || routerChange.currentValue === "/login") {
                NsDefaultCookie.renewNsDefaultCookie(false);
            }
            if (!NsDefaultCookie.getNsDefaultCookie()) {
                if (routerChange.currentValue.includes('/sp/domain/setting/renew/list')) {
                    screenIndex = '0';
                }
                if (routerChange.currentValue.includes("/sp/top")) {
                    screenIndex = '1';
                }
                if (screenIndex === '0' || screenIndex === '1') {
                    this._checkAllNSDefaultDetail(screenIndex);
                }
            }
        }
    }

    private _checkAllNSDefaultDetail(screenIndex: string): void {

        this.serviceContainer.HubsService.multipleSendManager.Send(
            this.serviceContainer.HubsService.multipleSendManager.GetComponentId(),
            this.serviceContainer.HubsService.hubsManager.promotionHubManager.CheckAllNSDefault,
            null,
            (response: CheckAllNSDefaultResponseHubDto) => {
                if (response.IsAllNSDefault) {
                    CustomizedEvents.dispatchCheckAllNSDefault({ screenIndex: screenIndex });
                    NsDefaultCookie.renewNsDefaultCookie(true);
                }
            },
            null,
            null,
            (data: CheckAllNSDefaultResponseHubDto) => {
            },
            null
        );
    }
}
