import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { NgForm } from '@angular/forms';

@Component({
    selector: 'two-step-verification-modal',
    template: `
<div [class]="modalClass" id="mfa_modal" [ngStyle]="modalStyle">
    <form #mfaCodeForm="ngForm" novalidate>
        <div class="modal-Dialog is-EmailAuthentication">
            <div class="modal-Dialog-Header">
                <h3 class="modal-Dialog-Header-Title is-Size24 is-Center">{{title}}</h3>
            </div>
            <div class="modal-Dialog-Body pt12 pr20 pl20 pb20">
                <section class="section">
                    <p>{{explanation}}</p>
                    <div class="form-Container is-Center mt10">
                        <p *ngIf="(v_mfaCode.errors && v_mfaCode.dirty) && (v_mfaCode.errors != null && v_mfaCode.errors.required)" class="form-Error mb0">認証コードを入力してください。</p>
                        <p *ngIf="verificationFailed" class="form-Error mb0">認証コードが誤っています。再度ご入力ください。</p>
                        <p *ngIf="limitExceeded" class="form-Error mb0">現在、このリクエストにお応えできません。<br />しばらくしてからもう一度やり直してください。</p>
                        <p *ngIf="tokenCreateFailed" class="form-Error mb0">しばらくしてからもう一度やり直してください。</p>
                        <p *ngIf="tokenResend" class="form-Reissue mb0"><i class="icon icon-Checked"></i>再発行しました。</p>
                        <input type="text" name="authCodeInput" id="authCodeInput" class="form-Text mt10"
                               [class.form-Error]="(v_mfaCode.errors && v_mfaCode.dirty)" [(ngModel)]="mfaCode"
                               #v_mfaCode="ngModel" required appAutofocus />
                    </div>
                    <ul class="list-Btn is-Vertical is-Center mt10 mb0">
                        <li><button type="button" [disabled]="buttonDisabled" class="btn is-Primary gtmclick" [attr.data-gtmvalue]="gtmPrefix + '_2fa_next'" (click)="onNextButtonClicked(mfaCodeForm)">次へ</button></li>
                        <li><button type="button" [disabled]="buttonDisabled" class="btn is-Small mt20 gtmclick" [attr.data-gtmvalue]="gtmPrefix + '_2fa_reissue'" (click)="onTokenResendButtonClicked()">再発行</button></li>
                    </ul>
                    <p class="text-Center mt10 mb0">
                        <a class="gtmclick" [attr.data-gtmvalue]="gtmPrefix + '_2fa_faq'" href="javascript:void(0)" (click)="openUrl('https://help.onamae.com/answer/20320')">認証コードが受信できない場合</a>
                    </p>
                </section>
            </div>

            <button type="button" *ngIf="gtmPrefix != 'quick'" class="modal-Close" data-dismiss="modal" (click)="onCloseButtonClicked()"><i class="icon icon-Clear" aria-label="閉じる"></i></button>
        </div>
    </form>
</div>
`
})
export class TwoStepVerificationModalComponent implements OnInit {
    @Input() public gtmPrefix: string = '';
    @Input() public isOpen: boolean = false;
    @Input() public verificationFailed: boolean = false;
    @Input() public limitExceeded: boolean = false;
    @Input() public loading: boolean = false;
    @Input() public mfaMethod: string = '';
    @Input() public tokenResend: boolean = false;
    @Input() public tokenCreateFailed: boolean = false;
    
    @Output() nextButtonClicked = new EventEmitter();
    @Output() closeButtonClicked = new EventEmitter();
    @Output() sendButtonClicked = new EventEmitter();

    public mfaCode: string = '';

    public get buttonDisabled(): boolean {
        return this.loading;
    }

    public get title(): string {
        switch (this.mfaMethod) {
            case "1":
            case "21":
                return "メール認証";
            case "2":
            case "22":
                return "SMS認証";
            case "3":
                return "音声認証";
            case "4":
                return "Google Authenticator";
            default:
                return "";
        }
    }

    public get explanation(): string {
        switch (this.mfaMethod) {
            case "1":
            case"21":
                return "ご登録いただいている連絡先メールアドレス宛に認証コードを送信しました。受信した認証コードをご入力ください。しばらくお待ちいただいても届かない場合は、再発行ボタンをクリックしてください。（認証コードの有効期限は10分です。）";
            case "2":
            case "22":
                return "ご登録いただいている携帯電話番号宛に認証コードを送信しました。受信した認証コードをご入力ください。しばらくお待ちいただいても届かない場合は、再発行ボタンをクリックしてください。（認証コードの有効期限は10分です。）";
            case "3":
                return "";
            case "4":
                return "";
            default:
                return "";
        }
    }

    public get modalStyle() {
        if (this.isOpen) {
            return { display: "block", overflowY: "auto" };
        } else {
            return { display: "none" };
        }
    }

    public get modalClass() {
        if (this.isOpen) {
            return "modal is-ModalOpen";
        } else {
            return "modal";
        }
    }

    public ngOnInit(): void {
        
    }

    public onNextButtonClicked(form: NgForm) {
        if (form.valid === false) {
            for (let key in form.controls) {
                form.controls[key].markAsDirty();
            }
            return;
        }

        this.nextButtonClicked.emit(this.mfaCode);
    }

    public onCloseButtonClicked() {
        this.closeButtonClicked.emit();
    }

    public onTokenResendButtonClicked() {
        this.sendButtonClicked.emit();
    }

    public openUrl(url: string): void {
        window.open(url, '_blank', 'noopener,noreferrer');
    }
}
