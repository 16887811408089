import { Component, Output, EventEmitter, Input } from '@angular/core';
import { ServiceContainer } from 'Content/script/service/service_container';
import { IPageTracking } from '../../../../service/tracking_service';
import { CustomizedEvents } from '../../../../libs/abtest/custom-event.util';
import { docCookies } from '../../../../libs/third_party/cookies';
import { Router } from '@angular/router';

@Component({
    selector: 'domain-detail-modal',
    templateUrl: './domain_detail_modal.html'
})

export class DomainDetailModalComponent{
    private _trackingService: IPageTracking;
    public queueId: number;
    public serviceName: string = '';
    public price: string = '';
    
    @Output() noSessionEvent = new EventEmitter<any>();
    @Output() onErrorEvent = new EventEmitter<any>();
    @Output() onUnExceptedErrorEvent = new EventEmitter<any>();
    @Output() onCloseModal = new EventEmitter<any>();
    @Output() onCloseModalWithoutShowAuthCode = new EventEmitter<any>();

    private _isShowDomainDetail: boolean = false;
    @Input()
    public get isShowDomainDetail(): boolean {
        return this._isShowDomainDetail;
    }

    public set isShowDomainDetail(v: boolean) {
        this._isShowDomainDetail = v;
        if (this._isShowDomainDetail) {
            this._trackingService.start();
            let _createDate = this.createdDate.replaceAll("/", "");
            let _expirationDate = this.expirationDate.replaceAll("/", "");
            CustomizedEvents.dispatchAuthModalOpenSurvey({ createdDate: _createDate, expirationDate: _expirationDate });
            CustomizedEvents.dispatchSurveyModal({ isRsPlan: false });
            this.serviceName = docCookies.getItem(this.domainName + 'serviceName');
            this.price = docCookies.getItem(this.domainName + 'price');
        } else {
            this._trackingService.dispose();
        }
    }
    
    @Input()
    public domainId: string;
    @Input()
    public domainName: string;
    @Input()
    public expirationDate: string;
    @Input()
    public createdDate: string;

    public constructor(
        private serviceContainer: ServiceContainer,
        private router: Router
    ) {
        this.queueId = this.serviceContainer.HubsService.multipleSendManager.GetComponentId();
        this._trackingService = this.serviceContainer.TrackingService.provideServiceForModalComponent().create('DomainDetailModalComponent');
    }

    public onClickClose(): void {
        this.onCloseModalWithoutShowAuthCode.emit();
        this.isShowDomainDetail = false;
    }

    public onClickCloseAuthCode(): void {
        this.onCloseModal.emit();
        this.isShowDomainDetail = false;
    }

    public get gtmvalueClose(): string {
        return "domaindetail_authcopuponmodal_couponeclose";
    }

    public get gtmvalueAuthcode(): string {
        return "domaindetail_authcopuponmodal_couponeAuth";
    }

    public onClickOutsideCloseModal(event: Event) {
        if (event.currentTarget === event.target) {
            this.onCloseModalWithoutShowAuthCode.emit();
            this.isShowDomainDetail = false;
        }
    }

    public onClickRenew(): void {
        this.isShowDomainDetail = true;
        window.dataLayerPush("domaindetail_authcopuponmodal__couponedone");
        this.serviceContainer.DataTransferService.setData<boolean>(true, this.serviceContainer.DataTransferService.ApplyCouponDomainDetailKey);
        this.router.navigate(['/domain/setting/renew/list/' + this.domainId]);
    }

    /**************************
     * キャッシュバースティング
     * @param uri
     **************************/
    public CacheBusting(uri: string): string {
        return window.CacheBusting(uri);
    }
}
