
/// <reference path='../../../../../script/definitions/window.d.ts' />

import { Component, OnInit, OnDestroy } from '@angular/core';

import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { NotifyMessage, NotifyType } from 'Content/script/service/notify_service';
import { SpServiceContainer } from 'Content/sp/script/service/service_container';
import { ValidationRegexsDefine } from 'Content/script/libs/define/validation_regexs.define';
import { Store } from '@ngrx/store';
import { LayoutState } from '../../../store/states/layout';
import { AccountInfoState, AccountHostingContractQuantityState } from 'Content/script/store/states/account-info';
import { getLayout } from '../../../store/reducers';
import { getAccountInfo, getAccountHostingContractQuantity } from 'Content/script/store/reducers/account-info';
import { setAccountHostingContractQuantity } from '../../../../../script/store/actions/account-info';
import { SpBaseLayoutComponent, SpBaseLayoutViewModel } from '../base_layout.component';


/**
 *
 */
@Component({
    selector: 'sp-header',
    templateUrl: './header.html'
})
export class SpHeaderComponent extends SpBaseLayoutComponent implements OnInit, OnDestroy {
    public viewModel: SpHeaderViewModel;

    public constructor(
        protected router: Router,
        protected serviceContainer: SpServiceContainer,
        private storeLayout: Store<LayoutState>,
        private storeAccountInfo: Store<AccountInfoState>,
        private storeAccountHostingContractQuantity: Store<AccountHostingContractQuantityState>
    ) {
        super();
        this.viewModel = new SpHeaderViewModel(router, serviceContainer, storeAccountHostingContractQuantity);
    }

    public ngOnInit() {
        this.isInited = true;
        this.reset();

        this.addStoreSubscribe(this.storeLayout.select(getLayout).subscribe(state => {
            this.viewModel.isShowHeaderMenu = state.isShowHeaderMenu;
        }));

        this.addStoreSubscribe(this.storeAccountInfo.select(getAccountInfo).subscribe(state => {
            const accountInfo = this.serviceContainer.AccountInfoService.mappingAccountInfo(state);
            this.viewModel.loginName = accountInfo.LaginName;
            this.viewModel.isShowDomainPayment = accountInfo.ShowSitemapPaymentInfoDomain;
            this.viewModel.isViewHiddenInvoice = accountInfo.ViewHiddenInvoice;
        }));
        this.addStoreSubscribe(this.storeAccountHostingContractQuantity.select(getAccountHostingContractQuantity).subscribe(state => {
            if (state.isLoaded) {
                this.viewModel.hostingContractQuantity = state;
            } else {
                this.viewModel.hostingContractQuantity = null;
            }
        }));
    }

    public ngOnDestroy() {
        this.reset();
        this.clearStoreSubscribes();
    }

    public reset(): void {
        if (this.isInited) {
            this.viewModel.isOpenAccountMenu = false;
            this.viewModel.isOpenPaymentMenu = false;
            this.viewModel.isShowAllHostingContract = false;
            this.viewModel.isViewHiddenInvoice = true;
            this.viewModel.isShowDomainPayment = false;
            this.viewModel.toggleMenuBodyClassName();
        }
    }
}

class HostingContractModel {
    public Key: string;
    public Label: string;
    public Link: string;
    public Quantity: number;
    public GtmValue: string;
}


class SpHeaderViewModel extends SpBaseLayoutViewModel {
    private queueId: number;
    private router: Router;
    private serviceContainer: SpServiceContainer;

    public isShowAllHostingContract: boolean;

    public hostingContractModels: HostingContractModel[] = [
        {
            Key: 'DesktopCloud',
            Label: 'デスクトップクラウド',
            Link: '/desktopcloud',
            Quantity: 0,
            GtmValue: 'rightmenu_dc_pd_list'
        } as HostingContractModel,
        {
            Key: 'DesktopCloud',
            Label: 'デスクトップクラウドOP',
            Link: '/desktopcloudoption',
            Quantity: 0,
        } as HostingContractModel,
        {
            Key: 'SdMailServer',
            Label: 'お名前メール',
            Link: '/mail',
            Quantity: 0,
            GtmValue: 'rightmenu_om_pd_list'
        } as HostingContractModel,
        {
            Key: 'Office365',
            Label: 'Office',
            Link: '/office365',
            Quantity: 0,
            GtmValue: 'rightmenu_o365_pd_list'
        } as HostingContractModel,
        {
            Key: 'VPS',
            Label: 'VPS(KVM)',
            Link: '/vps',
            Quantity: 0,
            GtmValue: 'rightmenu_vps_pd_list'
        } as HostingContractModel,
        {
            Key: 'MailMarketing',
            Label: 'メールマーケティング',
            Link: '/mailmarketing',
            Quantity: 0,
            GtmValue: 'rightmenu_mm_pd_list'
        } as HostingContractModel,
        {
            Key: 'WpTemplate',
            Label: 'WordPressテーマ',
            Link: '/wptemplate',
            Quantity: 0,
            GtmValue: 'rightmenu_wptemplate_pd_list'
        } as HostingContractModel,
        {
            Key: 'SdWeebly',
            Label: 'ホームページエディター',
            Link: '/sdweebly',
            Quantity: 0,
            GtmValue: 'rightmenu_sdweebly_pd_list'
        } as HostingContractModel,
        {
            Key: 'WpCloud',
            Label: 'WPCloud',
            Link: '/wpcloud',
            Quantity: 0,
            GtmValue: 'rightmenu_wpcloud_pd_list'
        } as HostingContractModel,
        {
            Key: 'GsServer',
            Label: '共用サーバーGS',
            Link: '/gs',
            Quantity: 0,
            GtmValue: 'rightmenu_gs_pd_list'
        } as HostingContractModel,
        {
            Key: 'SiteCreation',
            Label: 'サイトクリエイションJr.',
            Link: '/sitecreation',
            Quantity: 0,
            GtmValue: 'rightmenu_scj_pd_list'
        } as HostingContractModel
    ];

    public isShowHeaderMenu: boolean = false;
    public isOpenHeaderMenu: boolean = false;

    public guideWord: string;
    public readonly rGuideFormPattern: string;
    public isShowGuideModal: boolean = false;

    public productFlg: string;
    public isProduct: boolean;

    public loginName: string = '';

    public hostingContractQuantity: AccountHostingContractQuantityState;

    private storeAccountHostingContractQuantity: Store<AccountHostingContractQuantityState>;

    public constructor(router: Router, serviceContainer: SpServiceContainer, storeAccountHostingContractQuantity: Store<AccountHostingContractQuantityState>) {
        super(router, serviceContainer);
        this.queueId = serviceContainer.HubsService.multipleSendManager.GetComponentId();
        this.router = router;
        this.serviceContainer = serviceContainer;
        this.storeAccountHostingContractQuantity = storeAccountHostingContractQuantity;

        this.guideWord = '';
        this.rGuideFormPattern = ValidationRegexsDefine.rGuideFormPattern;

        this.productFlg = window.productFlg;
        this.isProduct = (this.productFlg == "Product" || this.productFlg == "Staging");
    }

    /*public get hostingContractModels(): HostingContractModel[] {
        const models: HostingContractModel[] = [];
        if (this.hostingContractQuantity) {
            this.hostingContractQuantity.ContractQuantityList.forEach((value: HostingContractQuantityResponseHubDto) => {
                this.hostingContractQuantityLists.forEach((model: HostingContractModel) => {
                    if (model.Key === value.ProductName) {
                        model.Quantity = value.ContractQuantity;
                        models.push(model);
                    }
                });
            });
        }
        return models;
    }*/

    public onSearchGuideButtonClicked(guideForm: NgForm): void {
        if (!this.guideWord) {
            return;
        }

        if (guideForm.invalid) {
            for (var key in guideForm.controls) {
                guideForm.controls[key].markAsDirty();
            }
            return;
        }

        try {
            let url = this.isProduct ? "https://www.onamae.com/guide/search/?keyword=" : "https://www.onamae.test/guide/search/?keyword=";
            let encodeUrl = encodeURI(url + this.guideWord + "&from=onamaenavi");
            window.open(encodeUrl, "_blank", 'noopener,noreferrer');
        } catch (error) {
            let notify: NotifyMessage = new NotifyMessage(this.serviceContainer.NotifyService.GetNotifyId(), NotifyType.Error, '不正な検索文字です');
            this.serviceContainer.NotifyService.AddNotifyMessages([notify]);
        }
    }

    /*
     * PC版を表示
     */
    public onClickChangePcMode(): void {
        let url = this.router.url;
        window.location.href = url + '?forceView=pc';
        this.onCloseHeaderMenu();
    }

    public onShowGuideModal(): void {
        this.isShowGuideModal = true;
    }

    public onHideGuideModal(): void {
        this.isShowGuideModal = false;
    }

    public onOpenHeaderMenu(): void {
        /*
        if (this.hostingContractQuantity) {
            this.isOpenHeaderMenu = true;
        } else {
            this.getHostingContractQuantity(true);
        }*/
        this.isOpenHeaderMenu = true;
        this.toggleMenuBodyClassName(true);
    }

    public onCloseHeaderMenu(): void {
        this.isOpenHeaderMenu = false;
        this.isOpenAccountMenu = false;
        this.isOpenPaymentMenu = false;
        this.isShowAllHostingContract = false;
        this.toggleMenuBodyClassName();
    }

    public onShowAllHostingContract(): void {
        this.isShowAllHostingContract = true;
    }

    /*******************************
     * ホスティング関係の契約件数取得
     ********************************/
    private getHostingContractQuantity(isOpenHeaderMenu: boolean = false): void {
        this.isLoading = true;
        this.serviceContainer.HubsService.multipleSendManager.Send(
            this.queueId,
            this.serviceContainer.HubsService.hubsManager.topHubManager.GetHostingContractQuantity,
            null,
            (response: HostingContractQuantityListResponseHubDto) => {
                const accountHostingContractQuantity: AccountHostingContractQuantityState = { ...response, isLoaded: true };
                this.storeAccountHostingContractQuantity.dispatch(setAccountHostingContractQuantity(accountHostingContractQuantity));
            },
            (response: HostingContractQuantityListResponseHubDto) => {
                // Error.
                console.log(response.ErrorMessage);
            },
            (response: HostingContractQuantityListResponseHubDto) => {
                // No Session.
                console.log('NoSession.');
            },
            (response: HostingContractQuantityListResponseHubDto) => {
                this.isLoading = false;
                if (isOpenHeaderMenu) {
                    this.isOpenHeaderMenu = true;
                }
            },
            (response: HostingContractQuantityListResponseHubDto) => {
                // UnExceptedError.
                console.log('UnExceptedError.');
            }
        );
    }

    public toggleMenuBodyClassName(isOpen: boolean = false): void {
        const body: HTMLBodyElement = document.body as HTMLBodyElement;
        const bodyClassName: string = body.className;
        const className: string = 'is-navi-enabled';
        const regExp: RegExp = new RegExp('(\\s|^)' + className + '(\\s|$)');
        const hasClass: boolean = regExp.test(bodyClassName);
        let newClassName: string = bodyClassName;
        if (isOpen) {
            if (!hasClass) {
                newClassName = bodyClassName + ' ' + className;
            }
            
        } else {
            if (hasClass) {
                newClassName = bodyClassName.replace(regExp, '');
            }
        }
        body.className = newClassName;
    }

    public logout() {
        sessionStorage.setItem("isSpFirstViewDomainModal", "y");
        sessionStorage.setItem("isSpFirstShowModalSd", "y");
        sessionStorage.removeItem('isSpShowRsRecommend');
        sessionStorage.removeItem('firstViewAfterLogin');
        sessionStorage.removeItem('receivedFirstDayRegistrationRs');
        this.onCloseHeaderMenu();
        this._logout();
    }

    public openUrl(url: string): void {
        window.open(url, '_blank','noopener, noreferrer');
    }
}



