import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[tblWrapRefresh]'
})
export class TblWrapRefresh {
    private _el: HTMLTableRowElement;

    constructor(private _elementRef: ElementRef) {
        this._el = _elementRef.nativeElement;
    }
    @HostListener('change', ['$event'])
    public onScroll(event: Event) {
        this.refreshTable();
    }

    private refreshTable() {
        const tblWrapFixedDiv = document.querySelector('.tblWrapFixedTableTbody') as HTMLElement;
        let tblWrapScroll_height: number = 0;
        tblWrapFixedDiv.scrollTo({ top: tblWrapScroll_height, behavior: 'smooth' });
        const tblWrapTbodyDiv = document.querySelector('.tblWrapTableTbody') as HTMLElement;
        tblWrapTbodyDiv.scrollTo({ top: tblWrapScroll_height, behavior: 'smooth' });
        const tblWrapDiv = document.querySelector('.tbl-Wrap-Scroll') as HTMLElement;
        let tblWrapScroll_width: number = 0;
        tblWrapDiv.scrollTo({ left: tblWrapScroll_width, behavior: 'smooth'});
        const navigationBtnRight = document.querySelector('.navigationBtnRight') as HTMLElement;
        const navigationBtnLeft = document.querySelector('.navigationBtnLeft') as HTMLElement;
        if (navigationBtnLeft.classList.contains('is-Active')) {
            navigationBtnLeft.classList.remove('is-Active');
        }
        if (!navigationBtnRight.classList.contains('is-Active')) {
            navigationBtnRight.classList.add('is-Active');
        }
    }
}