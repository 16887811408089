import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[numbersOnly]'
})
export class NumbersOnlyDirective {

    constructor(private _el: ElementRef) { }

    @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
        if (
            // Allow: Ctrl+A
            (event.keyCode === 65 && (event.ctrlKey || event.metaKey)) ||
            // Allow: Ctrl+C
            (event.keyCode === 67 && (event.ctrlKey || event.metaKey)) ||
            // Allow: Ctrl+V
            (event.keyCode === 86 && (event.ctrlKey || event.metaKey)) ||
            // Allow: Ctrl+X
            (event.keyCode === 88 && (event.ctrlKey || event.metaKey)) ||
            // Allow numeric keys from 0 to 9
            (event.keyCode >= 48 && event.keyCode <= 57) ||
            // Allow numeric keypad keys from 0 to 9
            (event.keyCode >= 96 && event.keyCode <= 105) ||
            // Allow control keys like Backspace, Delete, Arrow keys, etc.
            [8, 9, 13, 27, 35, 36, 37, 39, 46].indexOf(event.keyCode) !== -1
        ) {
            // Allow the key press
            return true;
        } else {
            // Prevent the default action (i.event., prevent the key press)
            event.preventDefault();
            return false;
        }
    }
}
