import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomainSettingRenewListComponent } from 'Content/script/components/domain/setting/renew/list/list.component';
import { ServiceContainer } from "Content/script/service/service_container";
import { serverOrderRedirection } from 'Content/script/libs/redirection/orderRedirection';
import { CustomizedEvents, EIeraeNetDeShindanModalEVarType } from '../../../../libs/abtest/custom-event.util';
import { IeraeNetDeShindanService } from '../../../../service/ierae_net_de_shindan_service';

@Component({
    selector: 'rs-recommend',
    templateUrl: './rs_recommend.html'
})

export class RsRecommendComponent implements OnChanges {
    @Input("routerPath") public routerPath: string;
    @Input("componentRef") public componentRef: any;

    @Input("isDisplayRsRecommend") isDisplayRsRecommend: boolean = false;
    @Input("isABNew_RsRecommendWithNameServer") isABNew_RsRecommendWithNameServer: boolean = false;

    @Output() doLoading = new EventEmitter();

    public readonly DISPLAY_PATH: string[] = ["/top", "/domain/setting/renew/list"];

    public hasTarget: boolean; // レコメンド表示対象となるアイテム、その他アカウント条件を保持しているか
    public hasRSOM: boolean; // RS基盤のお名前メールのみを保持しているか
    public isFirstView: boolean; // Top画面への初回遷移しか表示しない
    public isInitialSearch: boolean;
    public isTargetPath: boolean;
    public isEventFired: boolean;

    public domainSettingRenewListComponent: DomainSettingRenewListComponent;

    public constructor(
        public router: Router,
        public route: ActivatedRoute,
        public serviceContainer: ServiceContainer) {
        this.hasTarget = false;
        this.hasRSOM = false;
        this.isFirstView = true;
        this.isInitialSearch = true;
        this.isTargetPath = false;
        this.isEventFired = false;
    }

    public CacheBusting(uri: string): string {
        return window.CacheBusting(uri);
    }

    public ngOnChanges(changes: SimpleChanges) {
        // componentRefの変更を検知して、ドメイン更新画面のインスタンスであればDomainSettingRenewListComponentに格納
        if (!!changes["componentRef"]) {
            let componentChange = changes["componentRef"].currentValue;
            if (!!componentChange && componentChange._path == "/domain/setting/renew/list") {
                this.domainSettingRenewListComponent = componentChange;
            } else {
                this.domainSettingRenewListComponent = null;
            }
        }
        // routerPathの変更を検知して表示対象PathならviewModelのデータ取得メソッドを呼び出し
        let routerChange = changes["routerPath"];
        if (!!routerChange) {
            let isViewModelDisplayPath = this.DISPLAY_PATH.indexOf(routerChange.currentValue) != -1;
            if (isViewModelDisplayPath) {
                this.isTargetPath = true;
                this.fetchRecommendInfo();
            } else {
                this.isTargetPath = false;
            }
        }
    }

    public get isDisplay(): boolean {
        let isDisplay: boolean = this.isDisplayRsRecommend && this.isFirstView && this.isTargetPath && (this.hasTarget || this.hasRSOM);

        if (!this.isEventFired && isDisplay) {
            // GT#135078【お名前.com Navi】【ネットde診断】eventとeVarの再設置
            IeraeNetDeShindanService.dispatchModalEvent(EIeraeNetDeShindanModalEVarType.RsApproachModalCount);
            CustomizedEvents.dispatchFireEventRsRecommend({ eventValue: this.getEventValue });
            this.serviceContainer.ABCookieService.AdjustRsRecommendWithNameServer.tests.AdjustRsRecommendWithNameServer.dispatchEvent_sendCookiesBaggage({ pathName: this.getPathname });
            this.isEventFired = true;
        }

        return isDisplay;
    }

    public get rsApplyButtonId(): string {
        return location.pathname == "/domain/setting/renew/list" ? "navi_domrenew_rs_modal_order" : "navi_top_rs_modal_order";
    }

    public get domainApplyButtonId(): string {
        return location.pathname == "/domain/setting/renew/list" ? "navi_domrenew_rs_modal_domwithorder" : "navi_top_rs_modal_domwithorder";
    }

    public get closeButtonId(): string {
        return location.pathname == "/domain/setting/renew/list" ? "navi_domrenew_rs_modal_delete" : "navi_top_rs_modal_delete";
    }

    public get rsApplyButtonId_rsom(): string {
        return location.pathname == "/domain/setting/renew/list" ? "navi_renew_rsommodal" : "navi_top_rsommodal";
    }

    public get closeButtonId_rsom(): string {
        return location.pathname == "/domain/setting/renew/list" ? "navi_renew_rsommodal_close" : "navi_top_rsommodal_close";
    }

    public get getPathname() {
        return location.pathname == "/domain/setting/renew/list" ? "navi_renew_" : "navi_top_";
    }

    public get getEventValue() {
        return location.pathname == "/domain/setting/renew/list" ? "event618" : "event617";
    }

    public fetchRecommendInfo() {
        // 検索は1度しか実行しない
        if (!this.isInitialSearch) {
            return;
        }
        this.isInitialSearch = false;

        // お名前メール(RS)ユーザー向けのRSリコメンドの表示判定
        this.serviceContainer.HubsService.multipleSendManager.Send(
            this.serviceContainer.HubsService.multipleSendManager.GetComponentId(),
            this.serviceContainer.HubsService.hubsManager.topHubManager.GetRSRecommendationInfoForRSMailUser,
            null,
            (response: GetRSRecommendationInfoForRSMailUserResponseHubDto) => {
                this.hasRSOM = response.IsDisplay;
            },
            () => {
                this.hasRSOM = false;
            },
            null,
            null,
            () => {
                this.hasRSOM = false;
            }
        );

        // 表示優先度：お名前メール(RS)ユーザー向け ＞ 標準のため
        if (this.hasRSOM) {
            return;
        }

        // 標準RSリコメンドの表示判定
        this.serviceContainer.HubsService.multipleSendManager.Send(
            this.serviceContainer.HubsService.multipleSendManager.GetComponentId(),
            this.serviceContainer.HubsService.hubsManager.topHubManager.GetRsRecommendModalCondition,
            null,
            (response: GetRsRecommendModalConditionResponse) => {
                this.hasTarget = response.HaveDomainWithDefaultNameServer ||
                                 response.HasDomainWithRentalNameServer ||
                                 (this.isABNew_RsRecommendWithNameServer && response.HasDomainInNameServersList);
            },
            (response: GetRsRecommendModalConditionResponse) => {
                this.hasTarget = false;
            },
            null,
            null,
            (response: GetRsRecommendModalConditionResponse) => {
                this.hasTarget = false;
            },
        );
    }

    public onRsApplyButtonClicked(): void {
        this._orderPlan();
        this._closeModal();
    }

    public onDomainApplyButtonClicked(): void {
        // ドメイン更新画面の場合は離脱防止モーダルを解除
        if (!!this.domainSettingRenewListComponent) {
            this.domainSettingRenewListComponent.viewModel.isSkipModalConfirm = true;
        }

        this.router.navigate(['/domain/rsregist']);
        
        this._closeModal();
    }

    public onClickChangePlan_rsom(): void {
        // ドメイン更新画面の場合は離脱防止モーダルを解除
        if (!!this.domainSettingRenewListComponent) {
            this.domainSettingRenewListComponent.viewModel.isSkipModalConfirm = true;
        }
        this.router.navigate(['/mail']);
        this._closeModal();
    }

    public onCloseButtonClicked(): void {
        this._closeModal();
    }

    public onClickOutsideCloseModal(event: Event) {
        if (event.currentTarget === event.target) {
            let closeBtnId = location.pathname == "/domain/setting/renew/list" ? "navi_domrenew_rs_modal_delete" : "navi_top_rs_modal_delete";
            if (this.hasRSOM) {
                closeBtnId = location.pathname == "/domain/setting/renew/list" ? "navi_renew_rsommodal_close" : "navi_top_rsommodal_close";
            }
            window.dataLayerPush(closeBtnId);
            this._closeModal();
        }
    }

    private _closeModal(): void {
        this.isFirstView = false;
        this.hasTarget = false;
        this.hasRSOM = false;
    }

    /**
     * 商材申し込みのシングルサインオン
     */
    private _orderPlan(): void {
        this.doLoading.emit();
        var navigationClass = "";
        switch (location.pathname) {
            case "/domain/setting/renew/list":
                navigationClass = "SD_ORDER_FROM_DOMAIN_RENEW_MODAL";
                break;

            case "/top":
                navigationClass = "SD_ORDER_FROM_TOP_MODAL";
                break;

            default:
                break;
        }

        if (!navigationClass) {
            return;
        }

        var request: ServerOrderRedirectionRequestHubDto = {
            NavigationClass: navigationClass,
            RedirectionParams: []
        };

        serverOrderRedirection(
            document,
            this.serviceContainer,
            this.serviceContainer.HubsService.multipleSendManager,
            this.router,
            this.serviceContainer.HubsService.multipleSendManager.GetComponentId(),
            request
        );
    }
}
