
export class Banner {
    public image: string;
    public link: string;
    public backgroundColor: string;
    public deleteButtonColor: string;
    public locationId: string;
    public campaignStartDate: string;
    public campaignEndDate: string;

    constructor() {
        this.image = "";
        this.link = "";
        this.backgroundColor = "";
        this.deleteButtonColor = "";
        this.locationId = "0";
        this.campaignStartDate = "";
        this.campaignEndDate = "";
    }

    public set(source: LoginCampaignBanner): void;
    public set(source: Banner): void;

    public set(source: LoginCampaignBanner | Banner): any {
        if (isLoginCampaignBanner(source)) {
            this.image = source.Image;
            this.link = source.Link;
            this.backgroundColor = source.BackGroudColor;
            this.deleteButtonColor = source.DeleteButtonColor;
            this.locationId = source.LocationId;
            this.campaignStartDate = source.CampaignStartDate;
            this.campaignEndDate = source.CampaignEndDate;
            return;
        }

        if (isBanner(source)) {
            this.image = source.image;
            this.link = source.link;
            this.backgroundColor = source.backgroundColor;
            this.deleteButtonColor = source.deleteButtonColor;
            this.locationId = source.locationId;
            this.campaignStartDate = source.campaignStartDate;
            this.campaignEndDate = source.campaignEndDate;
            return;
        }
    }
}

export class CampaignBannerLocalStorageController {
    public static save(key: string, source: Banner): void {
        localStorage.removeItem(key);

        localStorage.setItem(key, JSON.stringify(source));
    }

    public static get(key: string): Banner {
        if (!this.hasActiveCampaignData) {
            return;
        }

        let source = localStorage.getItem(key);
        let banner: Banner = JSON.parse(source);
        return banner;
    }

    public static hasActiveCampaignData(key: string): boolean {
        let source = localStorage.getItem(key);
        let banner: Banner = JSON.parse(source);

        if (!banner) {
            return false;
        }

        let currentDate = new Date();
        let endDate = new Date(banner.campaignEndDate);

        return endDate > currentDate;
    }
}

type UnknownObject<T extends object> = {
    [P in keyof T]: unknown;
};

function isLoginCampaignBanner(obj: unknown): obj is LoginCampaignBanner {
    if (typeof obj !== "object") {
        return false;
    }
    if (obj === null) {
        return false;
    }

    const {
        Image,
        Link,
        DeleteButtonColor,
        BackGroudColor,
        LocationId,
        CampaignStartDate,
        CampaignEndDate
    } = obj as UnknownObject<LoginCampaignBanner>;

    if (typeof Image !== "string") {
        return false;
    }
    if (typeof Link !== "string") {
        return false;
    }
    if (typeof DeleteButtonColor !== "string") {
        return false;
    }
    if (typeof BackGroudColor !== "string") {
        return false;
    }
    if (typeof LocationId !== "string") {
        return false;
    }
    if (typeof CampaignStartDate !== "string") {
        return false;
    }
    if (typeof CampaignEndDate !== "string") {
        return false;
    }

    return true;
}

function isBanner(obj: unknown): obj is Banner {
    if (typeof obj !== "object") {
        return false;
    }
    if (obj === null) {
        return false;
    }

    const {
        image,
        link,
        deleteButtonColor,
        backgroundColor,
        locationId,
        campaignStartDate,
        campaignEndDate
    } = obj as UnknownObject<Banner>;

    if (typeof image !== "string") {
        return false;
    }
    if (typeof link !== "string") {
        return false;
    }
    if (typeof deleteButtonColor !== "string") {
        return false;
    }
    if (typeof backgroundColor !== "string") {
        return false;
    }
    if (typeof locationId !== "string") {
        return false;
    }
    if (typeof campaignStartDate !== "string") {
        return false;
    }
    if (typeof campaignEndDate !== "string") {
        return false;
    }

    return true;
}
