<div class="modal is-Tour" modal [openFlg]="isDisplay" id="modalDomainRelease" style="display: block;" data-dismiss="modal" (click)="onClickOutsideCloseModal($event)">
    <div class="modal-Dialog">
        <div class="modal-Dialog-Body pt0 pr0 pb0 pl0">
            <div class="modal-Carousel-List">
                <div class="modal-Carousel-List-Item js-modalCarouselItem" [class.is-Active]="currentItemIndex === 0" [ngStyle]="{'transform' : valueTransform[0]}">
                    <div class="modal-Carousel-Image">
                        <img class="" src="{{CacheBusting('/Content/images/netDiagnosis/tour_image_02_01.png')}}" width="736" height="206" loading="lazy" alt="">
                    </div>
                    <div class="modal-Carousel-Body">
                        <p class="size-15 text-Bold">使用方法</p>
                        <p class="text-Large text-Bold">「ドメイン一覧画面」からかんたん3STEPで<br>ご利用いただけます！</p>
                    </div>
                </div>
                <div class="modal-Carousel-List-Item js-modalCarouselItem" [class.is-Active]="currentItemIndex === 1" [ngStyle]="{'transform' : valueTransform[1]}">
                    <div class="modal-Carousel-Image">
                        <img class="" src="{{CacheBusting('/Content/images/netDiagnosis/tour_image_02_02.png')}}" width="736" height="206" loading="lazy" alt="">
                    </div>
                    <div class="modal-Carousel-Body">
                        <p class="size-15 text-Bold">step1</p>
                        <p class="text-Large text-Bold">診断したいドメインをクリック後、<br>オプション情報まで画面をスクロールし「診断」をクリック</p>
                    </div>
                </div>
                <div class="modal-Carousel-List-Item js-modalCarouselItem" [class.is-Active]="currentItemIndex === 2" [ngStyle]="{'transform' : valueTransform[2]}">
                    <div class="modal-Carousel-Image">
                        <img class="" src="{{CacheBusting('/Content/images/netDiagnosis/tour_image_02_03.png')}}" width="736" height="206" loading="lazy" alt="">
                    </div>
                    <div class="modal-Carousel-Body">
                        <p class="size-15 text-Bold">step2</p>
                        <p class="text-Large text-Bold">最短10分でお名前.comのご登録メールアドレス宛に<br>診断結果が届きます</p>
                    </div>
                </div>
                <div class="modal-Carousel-List-Item js-modalCarouselItem" [class.is-Active]="currentItemIndex === 3" [ngStyle]="{'transform' : valueTransform[3]}">
                    <div class="modal-Carousel-Image">
                        <img class="" src="{{CacheBusting('/Content/images/netDiagnosis/tour_image_02_04.png')}}" width="736" height="206" loading="lazy" alt="">
                    </div>
                    <div class="modal-Carousel-Body">
                        <p class="size-15 text-Bold">step3</p>
                        <p class="text-Large text-Bold mt30">メールに記載されたURLにパスワードを<br>コピー＆ペーストして診断結果をチェック！</p>
                    </div>
                </div>
            </div>
            <ol class="modal-Carousel-Indicators">
                <li class="modal-Carousel-Indicators-Item" [class.is-Active]="currentItemIndex === 0" (click)="onClickChangeOverviewStep()">・</li>
                <li class="modal-Carousel-Indicators-Item" [class.is-Active]="currentItemIndex === 1" (click)="onClickChangeStepOne()">・</li>
                <li class="modal-Carousel-Indicators-Item" [class.is-Active]="currentItemIndex === 2" (click)="onClickChangeStepTwo()">・</li>
                <li class="modal-Carousel-Indicators-Item" [class.is-Active]="currentItemIndex === 3" (click)="onClickChangeStepThree()">・</li>
            </ol>
        </div><!-- /.modal-Dialog-Body -->
        <div class="modal-Dialog-Footer">
            <ul class="list-Btn is-Right mb0">
                <li *ngIf="isShowBackBtn"><button type="button" class="btn" (click)="onClickBack()">前に戻る</button></li>
                <li *ngIf="isShowNextBtn"><button type="button" class="btn is-Primary" (click)="onClickNext()">次に進む</button></li>
                <li *ngIf="isShowSeeDetailBtn"><button type="button" class="btn" (click)="onClickSeeDetail()">詳細を見る</button></li>
                <li *ngIf="isShowCompleteBtn"><button type="button" class="btn is-Primary" data-dismiss="modal" (click)="onClickComplete()">閉じる</button></li>
            </ul><!-- /.list-Btn -->
        </div><!-- /.modal-Dialog-Footer -->
    </div><!-- /.modal-Dialog -->
</div>
