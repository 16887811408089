import { Directive, ElementRef, Input, HostListener } from '@angular/core';
import { Router } from '@angular/router';

@Directive({
    selector: '[onamaeRouterLink]'
})
export class SpOnamaeRouterLinkDirective {

    constructor(protected router: Router) {
    }

    @Input('onamaeRouterLink') onamaeRouterLink: string;

    @HostListener('click', ['$event'])
    public OnClick(event: Event) {
        this.router.navigateByUrl(this.onamaeRouterLink);
    }
}