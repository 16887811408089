import { createReducer, on } from '@ngrx/store';
import { LayoutState } from '../states/layout';
import { setLayout, resetLayout } from '../actions/layout';

export const featureKey = 'layout';

const initialState: LayoutState = {
    isShowHeaderMenu: false,
    isShowFooterMenu: false
};


export const reducer = createReducer(
    initialState,
    on(setLayout, (state: LayoutState, newState) => ({
        ...state,
        ...newState
    })),
    on(resetLayout, (state: LayoutState) => (initialState))
);

