import { Subscription } from 'rxjs';

export class BaseLayoutComponent {
    private _storeSubscribes: Subscription[] = [];

    public constructor() {
        // noop.
    }

    protected addStoreSubscribe(subscribe: Subscription): void {
        this._storeSubscribes.push(subscribe);
    }

    protected clearStoreSubscribes(): void {
        if (this._storeSubscribes) {
            this._storeSubscribes.forEach((subscribe) => {
                subscribe.unsubscribe();
            });
        }
        
        this._storeSubscribes = [];
    }
}
