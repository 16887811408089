import { Component, Input, OnInit } from '@angular/core';
import { docCookies } from '../../../../libs/third_party/cookies';


@Component({
    selector: 'domain-net-de-shindan-modal',
    templateUrl: './domain_net_de_shindan_modal.html'
})

export class DomainNetDeShindanModalComponent implements OnInit {
    @Input()
    public isShow: boolean = false;
    public currentItemIndex: number = 0;
    public valueTransform: string[] = [];
    public isShowNextBtn: boolean = false;
    public isShowBackBtn: boolean = false;
    public isShowSeeDetailBtn: boolean = false;
    public isShowCompleteBtn: boolean = false;

    public ngOnInit() {
        this.handleDisplayFollowStep(this.currentItemIndex);
    }

    public get isDisplay(): boolean {
        const cookiesName = 'showDeShindanModalDomain';
        if (this.isShow && !docCookies.hasItem(cookiesName)) {
            var expires = new Date('9999,11,31');
            docCookies.setItem(cookiesName, true, expires)
        }
        return this.isShow;
    }

    public onClickOutsideCloseModal(event: Event) {
        if (event.currentTarget === event.target) {
            this.isShow = false;
            window.dataLayerPush('shindan_modaltour_close');
        }
    }

    public onClickComplete(): void {
        this.isShow = false;
        window.dataLayerPush('shindan_modaltour_complete');
    }

    public onClickNext(): void {
        window.dataLayerPush('shindan_modaltour');
        this.currentItemIndex++;
        this.handleDisplayFollowStep(this.currentItemIndex);
    }

    public onClickBack(): void {
        this.currentItemIndex--;
        this.handleDisplayFollowStep(this.currentItemIndex);
    }

    public handleDisplayFollowStep(currentItemIndex: number) {
        this.showButtonFollowStep(currentItemIndex);
        this.valueTransform = this.getValueTransform(currentItemIndex);
    }

    public showButtonFollowStep(currentItemIndex: number) {
        switch (currentItemIndex) {
            case 0:
                this.isShowNextBtn = true;
                this.isShowBackBtn = false;
                this.isShowSeeDetailBtn = false;
                this.isShowCompleteBtn = false;
                break;
            case 3:
                this.isShowNextBtn = false;
                this.isShowBackBtn = false;
                this.isShowSeeDetailBtn = true;
                this.isShowCompleteBtn = true;
                break;
            default:
                this.isShowNextBtn = true;
                this.isShowBackBtn = true;
                this.isShowSeeDetailBtn = false;
                this.isShowCompleteBtn = false;
        }
    }

    public getValueTransform(currentItemIndex: number): string[] {
        const valuesTransform: number[] = [0, 734, 1468, 2202];
        return valuesTransform.map(value => `translateX(${value - valuesTransform[currentItemIndex]}px)`);
    }

    public onClickChangeOverviewStep() {
        this.currentItemIndex = 0;
        this.handleDisplayFollowStep(this.currentItemIndex);
    }

    public onClickChangeStepOne() {
        this.currentItemIndex = 1;
        this.handleDisplayFollowStep(this.currentItemIndex);
    }

    public onClickChangeStepTwo() {
        this.currentItemIndex = 2;
        this.handleDisplayFollowStep(this.currentItemIndex);
    }

    public onClickChangeStepThree() {
        this.currentItemIndex = 3;
        this.handleDisplayFollowStep(this.currentItemIndex);
    }

    public onClickSeeDetail(): void {
        window.open('https://www.onamae.com/option/shindan/?banner_id=modal_tour', '_blank', 'noopener noreferrer');
    }

    public CacheBusting(uri: string): string {
        return window.CacheBusting(uri);
    }
}
