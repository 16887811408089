import { Session } from "protractor";
import { CustomizedEvents, EIeraeNetDeShindanModalEVarType } from "../libs/abtest/custom-event.util";
import { FutureDate, docCookies } from "../libs/third_party/cookies";

/**
 * GT#135078【お名前.com Navi】【ネットde診断】eventとeVarの再設置
 * 調査用イベント関連クラス
 */
export class IeraeNetDeShindanService {
    public static readonly Key: string = 'GT#135078_MODAL_OPENED';
    private static readonly Value: string = 'y';

    /**
     * GT#135078
     * ログイン後に表示されるモーダルが開いたらイベント発火
     * @param modalType
     */
    public static dispatchModalEvent(modalType: EIeraeNetDeShindanModalEVarType): void {
        CustomizedEvents.dispatchIeraeNetDeShindanModalEvent(modalType);

        // いずれかのモーダルが開いたかチェックするためのCookie保存
        if (!sessionStorage.getItem(IeraeNetDeShindanService.Key)) {
            sessionStorage.setItem(
                IeraeNetDeShindanService.Key,
                IeraeNetDeShindanService.Value
            );
        }
    }

    /**
     * GT#135078
     * ログイン後に表示されるモーダルがいずれも開かなかったら発火するイベントをセット
     */
    public static eventTimerSet(): void {
        setTimeout(() => {
            if (!sessionStorage.getItem(IeraeNetDeShindanService.Key)) {
                sessionStorage.removeItem(IeraeNetDeShindanService.Key);
                CustomizedEvents.dispatchIeraeNetDeShindanModalEvent(EIeraeNetDeShindanModalEVarType.TopRenewModalNoCount);
            }
        }, 15000);
    }
}
