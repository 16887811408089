export class Checker {
    public static nullOrUndefined(...selectors: (() => any)[]): boolean {
        for (let selector of selectors) {
            const parameter = selector();
            if (parameter === null || parameter === void (0)) {
                return true;
            }
        }

        return false;
    }
}