export class HeaderComponentService {
    public FrequentlyViewedPages: Array<FrequentlyViewedPage>;
    private FrequentlyViewedAccessor = new FrequentlyViewedAccessor();

    public GlobalNaviViewModel: GlobalNaviViewModel;

    constructor() {
        this.FrequentlyViewedPages = new Array<FrequentlyViewedPage>();

        this.UpdateFrequentlyViewedPages = (frequentlyViewedPage: FrequentlyViewedPage, proxyId: string) => {
            this.FrequentlyViewedAccessor.SetItem(frequentlyViewedPage, proxyId);

            this.FrequentlyViewedPages.splice(0, this.FrequentlyViewedPages.length);

            var items = this.FrequentlyViewedAccessor.GetDisplayItems(proxyId);
            for (var index: number = 0; index < items.length; index++) {
                this.FrequentlyViewedPages.push(items[index]);
            }
        };

        this.GlobalNaviViewModel = new GlobalNaviViewModel();
    }

    public UpdateNavigationName(navigationName: string): void {
        switch (navigationName) {
            case 'wpcloud':
            case 'sitecreation':
            case 'wptemplate':
            case 'gs':
            case 'sdweebly':
            case 'exo':
            case 'vps':
                this.GlobalNaviViewModel.CurrentNavigationName = "other";
                break;
            default:
                this.GlobalNaviViewModel.CurrentNavigationName = navigationName;
                break;
        }
    };

    public UpdateFrequentlyViewedPages: (frequentlyViewedPage: FrequentlyViewedPage, proxyId: string) => void;
}

export class GlobalNaviViewModel
{
    public CurrentNavigationName: string;

    public constructor()
    {
        this.CurrentNavigationName = "";
    }
}

/********************
 * よく見るページ機能
 ********************/
export class FrequentlyViewedAccessor {
    public readonly AccessKey: string;
    public readonly SaveLimit: number;
    public readonly DisplayLimit: number;

    public constructor() {
        this.AccessKey = "FrequentlyPageList";
        this.DisplayLimit = 5;
        this.SaveLimit = 100;
    }

    private GetItems(proxyId: string): Array<FrequentlyViewedPage> {
        var items = localStorage.getItem(this.AccessKey + "-" + proxyId);
        var frequentlyViewedPages = JSON.parse(items);
        if (frequentlyViewedPages) {
            return frequentlyViewedPages;
        }

        return [];
    }

    public GetDisplayItems(proxyId: string): Array<FrequentlyViewedPage> {
        var frequentlyViewedPages: Array<FrequentlyViewedPage> = this.GetItems(proxyId);
        return frequentlyViewedPages.sort(this.sort).slice(0, this.DisplayLimit);
    }

    public sort: (first: FrequentlyViewedPage, second: FrequentlyViewedPage) => number = (first: FrequentlyViewedPage, second: FrequentlyViewedPage) => {
        if (first.PagePriority > second.PagePriority) {
            return -1;
        }

        if (first.LoadCount > second.LoadCount) {
            return -1;
        }
        else if (first.LoadCount < second.LoadCount) {
            return 1;
        }

        return 0;
    }

    public SetItem(frequentlyViewedPage: FrequentlyViewedPage, proxyId: string): void {
        try {
            var frequentlyViewedPages: Array<FrequentlyViewedPage> = this.GetItems(proxyId);

            var index: number = -1;
            for (var pageIndex: number = 0; pageIndex < frequentlyViewedPages.length; pageIndex++) {
                if (frequentlyViewedPages[pageIndex].PageTitle == frequentlyViewedPage.PageTitle) {
                    index = pageIndex;
                    break;
                }
            }

            if (-1 != index) {
                if (frequentlyViewedPages[index].Expire < (new Date).getTime()) {
                    frequentlyViewedPages.splice(index, 1);
                    index = -1;
                }
                else {
                    frequentlyViewedPages[index].LoadCount++;
                    frequentlyViewedPages[index].Expire = frequentlyViewedPage.Expire;
                }
            }

            if (-1 == index) {
                frequentlyViewedPages.push(frequentlyViewedPage);

                if (frequentlyViewedPages.length > this.SaveLimit) {
                    frequentlyViewedPages.shift();
                }
            }

            var frequentlyVieweds = JSON.stringify(frequentlyViewedPages);
            localStorage.setItem(this.AccessKey + "-" + proxyId, frequentlyVieweds);
        }
        catch (ex)
        {
            console.error("Exception", ex.message);
        }
    }
}
