<div id="modalDefault01" class="modal is-visible is-presentcp" sp-modal [openFlg]="isDisplay" (click)="onClickOutsideCloseModal($event)">
    <!-- RSリコメンド -->
    <div *ngIf="hasTarget && !hasRSOM" class="modalContent">
        <div class="modalClose" (click)="onCloseButtonClicked()" data-closemodal="modalDefault01"><span class="icon icon-Clear colorWhite"></span></div>
        <div class="modalHeader pt0 pr0 pb0 pl0 bgColorBlue">
            <p class="modalHeaderText fontSize12 fontWeightBold colorWhite textAlignCenter ptM pbM">ドメインを利用するために<br>レンタルサーバーをお申し込みください</p>
        </div>
        <div class="modalBody has-serverImage">
            <div class="modalBodyMain">
                <p class="modalBodyMainHeading has-slash">
                    <span class="slash is-left"></span>お名前.comで申し込むメリット<span class="slash is-right"></span>
                </p>
                <ul class="modalBoxList">
                    <li class="modalBoxList_item">
                        <div class="modalBoxContainer">
                            <p>初期費用無料 &amp; 月額最大2ヶ月無料<br><span>初回0円で利用開始</span></p>
                        </div>
                    </li>
                    <li class="modalBoxList_item">
                        <div class="modalBoxContainer">
                            <p>お申込みから利用開始まで<br><span>最短5分</span></p>
                        </div>
                    </li>
                    <li class="modalBoxList_item">
                        <div class="modalBoxContainer">
                            <p>ドメインとサーバーが<br><span>まとめて管理できる</span></p>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="modalBodyBottom">
                <ul class="cardList">
                     <li class="cardList_item">
                        <div class="card card-rectangle card-rs has-header" role="button">
                            <div class="cardHeader">
                              対象ドメインなら<br>サーバーの利用中ドメインが<span>永久に無料！</span>
                            </div>
                            <div class="cardBody">
                                <p class="cardTitle textAlignLeft">レンタルサーバー<br><span class="fontSize12 fontWeightNormal">登録済みドメイン利用</span></p>
                                <button class="button button-green button-small" (click)="onDomainApplyButtonClicked()">お申込み</button>
                            </div>
                        </div>
                    </li>
                    <li class="cardList_item mt8">
                        <div class="card card-rectangle card-rs" role="button">
                            <p class="cardTitle textAlignLeft">レンタルサーバー<br><span class="fontSize12 fontWeightNormal">単体のお申込み</span></p>
                            <button class="button button-green button-small gtmclick" [attr.data-gtmvalue]="domainApplyButtonId" data-realtimefired="true" (click)="onRsApplyButtonClicked()">お申込み</button>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <!-- お名前メール(RS)のみ保持しているアカウントへのRSリコメンド -->
    <div *ngIf="hasRSOM" class="modalContent">
        <div class="modalClose" (click)="onCloseButtonClicked()" data-closemodal="modalDefault01"><span class="icon icon-Clear colorWhite"></span></div>
        <div class="modalHeader pt0 pr0 pb0 pl0 bgColorBlue">
            <p class="modalHeaderText fontSize12 fontWeightBold colorWhite textAlignCenter ptM pbM">お名前メールの「エコノミープラン」<br>「ベーシックプラン」をご利用中で<br>ホームページ・ブログの運用を検討している方</p>
        </div>
        <div class="modalBody has-serverImage">
            <div class="modalBodyMain">
                <p class="modalBodyMainHeading has-slash">
                    <span class="slash is-left"></span>レンタルサーバーへの<br>プラン変更がおトク!<span class="slash is-right"></span>
                </p>
                <ul class="modalBoxList">
                    <li class="modalBoxList_item">
                        <div class="modalBoxContainer">
                            <p>お名前メールで使用していた<br><span>メール設定はそのまま利用可能</span></p>
                        </div>
                    </li>
                    <li class="modalBoxList_item">
                        <div class="modalBoxContainer">
                            <p>容量(SSD)は<br><span>400GBに</span></p>
                        </div>
                    </li>
                    <li class="modalBoxList_item">
                        <div class="modalBoxContainer">
                            <p>プラン変更月は<br><span>月額料金が0円</span></p>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="modalBodyBottom">
                <button class="button button-green" (click)="onChangePlanButtonClicked()">プラン変更はこちら</button>
            </div>
        </div>
    </div>
    <button class="modalCloseButton" (click)="onCloseButtonClicked()">
        <p><span class="icon icon-Clear"></span>閉じる</p>
    </button>
</div>
