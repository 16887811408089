import { Directive, ElementRef, Input, OnDestroy, OnInit, EventEmitter, HostListener, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subscription, interval } from 'rxjs';

@Directive({
    selector: '[modal]'
})
export class ModalDirective implements OnDestroy, OnInit {
    private _el: HTMLElement;

    @Input('openFlg')
    public openFlg: boolean;

    @Output()
    public clickEvent = new EventEmitter();

    private subscription: Subscription;
    private observableObject: Object;

    constructor(private _elementRef: ElementRef) {
        this._el = _elementRef.nativeElement;
    }

    /**************************
     * 画面がバインドされる直前
     **************************/
    public ngOnInit() {
        /************************************************************************
        * openFlg属性が付与されている際はcomponent側でモーダルの開閉が任意に行えます
        ************************************************************************/
        var observer = interval(100);
        this.subscription = observer.subscribe(() => {
            this.changeDisplayModal();
        });
    }

    /**********************
     * 画面が破棄される直前
     **********************/
    public ngOnDestroy() {
        /*********************************
        * openFlgメンバ変数の検知を解除する
        **********************************/
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        

        /*****************************************************
        * 画面遷移対応
        * 画面遷移の際にはcomponentでバインドしたオブジェクトを
        * 変更しても検知される前に画面遷移で、ここの処理が実行され
        * document.bodyに付与した'is-ModalOpen'が剥がれない為
        ******************************************************/
        this.openFlg = false;
        this.changeDisplayModal();
    }

    /********************
     * モーダルの開閉処理
     ********************/
    private changeDisplayModal() {
        if (this.observableObject != this.openFlg) {
            if (this.openFlg) {
                document.body.classList.add('is-ModalOpen');
                this._el.style.display = 'block';
                this._el.style.overflowY = 'auto';

                if (!sessionStorage.getItem('IsFirstModalOpen')) {
                    sessionStorage.setItem('IsFirstModalOpen', '1');
                } else {
                    let count = parseInt(sessionStorage.getItem('IsFirstModalOpen')) + 1;
                    sessionStorage.removeItem('IsFirstModalOpen');
                    sessionStorage.setItem('IsFirstModalOpen', count.toString());
                }
            }
            else {
                document.body.classList.remove('is-ModalOpen');
                this._el.style.display = '';
                this._el.style.overflowY = '';
            }

            this.observableObject = this.openFlg;

            this.changeDisplayInfoDropDown();
        }
    }

    /**
     * モーダルが表示された場合の処理
     * @returns
     */
    private changeDisplayInfoDropDown() {
        let infoDropDown = document.getElementById('headerInfoDropDownB');
        if (infoDropDown === null) {
            return;
        }

        if (!this.openFlg) {
            // モーダルが閉じた際に、お知らせドロップダウンを表示
            if (!infoDropDown.classList.contains("is-Open") && window.ForYouInfoCount > 0 && sessionStorage.getItem('IsFirstModalOpen') === '1' && sessionStorage.getItem('IsFirstHeaderInfo') === '0') {
                sessionStorage.removeItem('IsFirstHeaderInfo')
                infoDropDown.click();
            }
        }
        else {
            // モーダルが開いた際に、お知らせドロップダウンを非表示
            if (infoDropDown.classList.contains("is-Open") && sessionStorage.getItem('IsFirstModalOpen') === '1') {
                sessionStorage.setItem('IsFirstHeaderInfo', '0');
                infoDropDown.click();
            } 
        }
    }
}
