/// <reference path='../../../../definitions/window.d.ts' />

import { Component, OnInit } from '@angular/core';

import { getTypeInfo, types as CardType } from 'credit-card-type'
import * as creditCardType from 'credit-card-type';
import * as valid from 'card-validator'

@Component({
    selector: 'creditcard',
    template: ''
})

export class CreditCardListComponent implements OnInit {

    public ngOnInit() {
        let partternsDinersClubInternational = [[3095], [36], [38,39]]
        let patternDinersClubInternationalfterFilter = this._setPatterns(partternsDinersClubInternational);
        creditCardType.addCard({
            niceType: "DinersClubInternational",
            type: "diners-club-international",
            patterns: patternDinersClubInternationalfterFilter,
            gaps: [4, 8, 12],
            lengths: [13, 16, 19],
            code: {
                name: "CVV",
                size: 3,
            },
        });
        let partternsJCB = [[3088, 3094], [3096, 3102], [3112, 3120], [3158, 3159], [3337, 3349], [3528, 3589]]
        let patternJCBAfterFilter = this._setPatterns(partternsJCB);
        creditCardType.addCard({
            niceType: "JcbCustom",
            type: "jcb-custom",
            patterns: patternJCBAfterFilter ,
            gaps: [4, 8, 12],
            lengths: [13, 16, 19],
            code: {
                name: "CVV",
                size: 3,
            },
        });
    }
    private _setPatterns(params: number[][]): number[] {
        let result = [];
        for (var i = 0; i < params.length; i++) {
            if (params[i].length == 1) {
                result.push(params[i][0]);
            } else {
                if (params[i].length == 2) {
                    for (var j = params[i][0]; j <= params[i][1]; j++) {
                        result.push(j);
                    }
                }
            }
        }
        return result;
    }
    public filterCardType(cardNumber: string): Cards {
        let cards = new Cards;
        cards.type = new creditCardTypes();
        var visaCards = creditCardType(cardNumber);
        if (visaCards.length > 0) {
            cards.niceType = visaCards[0].type;
            switch (visaCards[0].type) {
                case creditCardType.types.VISA:
                    cards.type.isVisa = true;
                    cards.length = 19;
                    cards.isValidCard = true;
                    break;
                case creditCardType.types.MASTERCARD:
                    cards.type.isMasterCard = true;
                    cards.isValidCard = true;
                    cards.length = 19;
                    break;
                case creditCardType.types.JCB:
                    cards.type.isJCB = true;
                    cards.isValidCard = true;
                    cards.length = 19;
                    break;
                case 'jcb-custom':
                    cards.type.isJCB = true;
                    cards.isValidCard = true;
                    cards.length = 19;
                    break;
                case creditCardType.types.DINERS_CLUB:
                    cards.type.isDinersClub = true;
                    cards.isValidCard = true;
                    cards.length = 16;
                    break;
                case "diners-club-international":
                    cards.type.isDinersClub = true;
                    cards.isValidCard = true;
                    cards.length = 19;
                    break;
                case creditCardType.types.AMERICAN_EXPRESS:
                    cards.type.isAmericanExpress = true;
                    cards.isValidCard = true;
                    cards.length = 17;
                    break;
                default:
            }
            let numberValidation = valid.number(cardNumber);
            cards.isValidCard = numberValidation.isValid;
        }
        return cards;
    }
    public prettyCardNumber(cardNumber: string, cardType: string) : string {
        cardNumber = cardNumber.replaceAll(" ", "");
        var card = getTypeInfo(cardType);

        if (card) {
            var offsets = [].concat(0, card.gaps, cardNumber.length);
            var components = [];

            for (var i = 0; offsets[i] < cardNumber.length; i++) {
                var start = offsets[i];
                var end = Math.min(offsets[i + 1], cardNumber.length);
                components.push(cardNumber.substring(start, end));
            }
            return components.join(" ");
        }

        return cardNumber;
    }
}
export class creditCardTypes {
    isVisa: boolean = false;
    isMasterCard: boolean = false;
    isJCB: boolean = false;
    isDinersClub: boolean = false;
    isAmericanExpress: boolean = false;
}
export class Cards {
    type: creditCardTypes
    length: number;
    niceType: string;
    isValidCard: boolean;
}
