import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UiModule } from 'Content/script/modules/ui.module';

/**
 * Atoms
 * */
import { AtomsModule } from 'Content/script/modules/atoms.module';

/**
 * Molecules
 * */
import { HeadNotificationComponent } from 'Content/script/components/share/Molecules/notification/headNotification.component';
import { ImageBannerNotificationComponent } from 'Content/script/components/share/Molecules/notification/imageBannerNotification.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        AtomsModule,
        UiModule
    ],
    declarations: [
        HeadNotificationComponent,
        ImageBannerNotificationComponent
    ],
    exports: [
        HeadNotificationComponent,
        ImageBannerNotificationComponent
    ]
})

export class MoleculesModule { }
