import { HubsService } from 'Content/script/service/hubs_service.js';

export class DomainCartService
{
    public viewModel: DomainCartViewModel;
    public queueId: number;

    public constructor(private hubService: HubsService)
    {
        this.viewModel = new DomainCartViewModel(hubService);
        this.queueId = this.hubService.multipleSendManager.GetComponentId();
    }

    public ShowView(): void
    {
        if (this.viewModel.IsDisplayClose)
        {
            return;
        }

        if (this.viewModel.IsDisplay == false)
        {
            this.viewModel.IsLoading = true;
            this.viewModel.CartList = [];

            var displayNone: Function = (res: DomainCartListResponseHubDto) => {
                this.viewModel.IsDisplay = false;
                this.viewModel.CartList = [];
                this.viewModel.IsDisplayClose = true;
            };

            this.hubService.multipleSendManager.Send(
                this.queueId,
                this.hubService.hubsManager.domainHubManager.DomainCartList,
                null,
                (res: DomainCartListResponseHubDto) => {
                    this.viewModel.CartList = [];
                    if (res.DomainList.length == 0)
                    {
                        this.viewModel.IsDisplay = false;
                        return;
                    }

                    this.viewModel.IsDisplay = true;

                    for (let index = 0; index < res.DomainList.length; index++)
                    {
                        this.viewModel.CartList.push(new RowItem(true, res.DomainList[index]));
                    }
                },
                displayNone,
                null,
                (res: DomainCartListResponseHubDto) => {
                    this.viewModel.IsLoading = false;
                },
                displayNone);
        }
    }

    public HideView(): void
    {
        this.viewModel.IsDisplay = false;
    }
}

export class DomainCartViewModel
{
    public IsDisplay: boolean;
    public IsDisplayClose: boolean;
    public IsLoading: boolean;

    public CartList: RowItem[];

    public constructor(private hubService: HubsService)
    {
        this.IsDisplay = false;
        this.CartList = [];
        this.IsDisplayClose = false;
        this.IsLoading = true;
    }

    public CheckEvent(domainName: string): void
    {
        for (var index = 0; index < this.CartList.length; index++)
        {
            if (this.CartList[index].DomainName == domainName)
            {
                this.CartList[index].IsCheck = !this.CartList[index].IsCheck;
                return;
            }
        }
    }

    public UrlNavigation(): void
    {
        let queueId = this.hubService.multipleSendManager.GetComponentId();

        let queryValue: string = this.CartList.filter((rowItem: RowItem) => {
            return rowItem.IsCheck;
        }).map(rowItem => encodeURIComponent(rowItem.DomainName)).join('%0D%0A');

        let queryKeyValue: NavigationQueryHubDto = { QueryKey: 'd', QueryValue: queryValue } as NavigationQueryHubDto;

        let queryBtnId: NavigationQueryHubDto = { QueryKey: 'btn_id', QueryValue: 'newnavi_cartrecommend_regist' } as NavigationQueryHubDto;

        let req: NavigationRequestHubDto = {
            Host: "DOMAIN_ADMISSION",
            NavigationClass: "DOMAIN_ORDER",
            NavigationParams: [queryKeyValue, queryBtnId]
        } as NavigationRequestHubDto;

        this.hubService.multipleSendManager.Send(
            queueId,
            this.hubService.hubsManager.navigationHubManager.TokenUrl,
            req,
            (response: NavigationResponseHubDto) => {
                location.href = response.TokenUrl;
                return;
            },
            (response: NavigationResponseHubDto) => {
                return;
            },
            null,null
        )
        return;
    }

    public CloseView(): void {
        this.IsDisplayClose = true;
        this.IsDisplay = false;
    }
}

export class RowItem
{
    public IsCheck: boolean;
    public DomainName: string;

    public constructor(check: boolean, domainName: string)
    {
        this.IsCheck = check;
        this.DomainName = domainName;
    }
}
