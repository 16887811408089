import { Component, EventEmitter, Input, Output } from '@angular/core';

/**
 * (ドメイン）請求キャンセルモーダル
 */
@Component({
    selector: 'invoice-cancel-modal',
    templateUrl: './invoice_cancel_modal.html'
})

export class InvoiceCancelModalComponent {

    @Input('isShow') public isShowModal: boolean;

    @Output('onClickNext') public clickNext = new EventEmitter<any>();
    @Output('onClickClose') public clickClose = new EventEmitter<any>();

    public constructor() { }

    //「次へ」
    onClickNext(param?: any) {
        this.clickNext.emit(param);
    }

    //「✕」「戻る」
    onClickClose(param?: any) {
        this.clickClose.emit(param);
    }
}
