export class ValidationInvalidListDefine {

    // 「国：JP（日本）」で、郵便番号に下記番号を入力した場合エラー判定
    public static readonly invalidPcJpList: string[] = [
        '000-0000',
        '111-1111',
        '222-2222',
        '333-3333',
        '444-4444',
        '555-5555',
        '666-6666',
        '777-7777',
        '888-8888',
        '999-9999',
        '000-0001',
        '000-0002',
        '000-0003',
        '000-0004',
        '000-0005',
        '000-0006',
        '000-0007',
        '000-0008',
        '000-0009'
    ];

    public static readonly invalidPhoneNumberList: string[] = [
        '00-0000-0000',
        '01-0000-0000',
        '02-0000-0000',
        '03-0000-0000',
        '04-0000-0000',
        '05-0000-0000',
        '06-0000-0000',
        '07-0000-0000',
        '08-0000-0000',
        '09-0000-0000',
        '00-0000-0001',
        '00-0000-0002',
        '00-0000-0003',
        '00-0000-0004',
        '00-0000-0005',
        '00-0000-0006',
        '00-0000-0007',
        '00-0000-0008',
        '00-0000-0009',
        '0000000000',
        '0100000000',
        '0200000000',
        '0300000000',
        '0400000000',
        '0500000000',
        '0600000000',
        '0700000000',
        '0800000000',
        '0900000000',
        '0000000001',
        '0000000002',
        '0000000003',
        '0000000004',
        '0000000005',
        '0000000006',
        '0000000007',
        '0000000008',
        '0000000009'
    ];
}