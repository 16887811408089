import { Directive, ElementRef, Output, Input, EventEmitter, HostListener, OnDestroy } from '@angular/core';

@Directive({
    selector: '[absolute-dropdown]'
})
export class AbsoluteDropdownDirective implements OnDestroy {
    private _el: HTMLElement;

    private targetElement: HTMLElement;

    private ignoreElements: HTMLElement[] = [];

    private closeElements: HTMLElement[] = [];

    private bodyEventLitner: EventListener;

    @Input('align')
    public align: string;

    @Input('target')
    public target: string;

    @Input('close')
    public close: string;

    @Input('adjust-position')
    public adjustPositon: string = 'y';

    @Output()
    public clickEvent = new EventEmitter();

    public constructor(private elementRef: ElementRef) {
        this._el = elementRef.nativeElement;

        this.bodyEventLitner = (event: MouseEvent) => {
            if (this.targetElement) {
                this.targetElement.classList.remove('is-Show');
                this._el.classList.remove("is-Open");

                this.targetElement.style.left = '';
                this.targetElement.style.top = '';
            }
        };

        document.body.addEventListener('click', this.bodyEventLitner);
    }

    public ngOnDestroy(): void {
        const body = document.querySelector('body') as HTMLBodyElement;
        if (body && this.bodyEventLitner) {
            body.removeEventListener("click", this.bodyEventLitner);
        }
    }

    @HostListener('click', ['$event'])
    public onClick(event: Event) {
        if (!this.targetElement) {
            this.targetElement = document.querySelector(this.target) as HTMLElement;
            this.targetElement.addEventListener("click", (event: MouseEvent) => {
                event.stopImmediatePropagation();
                event.stopPropagation();
            });

            // ドロップダウン上のカレンダー(Pikaday)をクリックした際
            // ドロップダウン自体が非表示になってしまうため、カレンダー(Pikaday)に対してはバブリング停止のイベントを貼る
            var ignoreElements = document.querySelectorAll('div.pika-single.is-bound');
            for (var index = 0; index < ignoreElements.length; index++) {
                var ignoreElement = ignoreElements.item(index) as HTMLElement;
                ignoreElement.addEventListener("click", (event: MouseEvent) => {
                    event.stopImmediatePropagation();
                    event.stopPropagation();
                });
                this.ignoreElements.push(ignoreElement);
            }

            if (this.closeElements.length == 0 && this.close && this.close.length > 0) {
                var closeElements = this.targetElement.querySelectorAll(this.close);
                for (var index: number = 0; index < closeElements.length; index++) {
                    var element = closeElements[index] as HTMLElement
                    element.addEventListener("click", (event: MouseEvent) => {
                        this._el.classList.remove('is-Open')
                        this.targetElement.classList.remove('is-Show');

                        this.targetElement.style.left = '';
                        this.targetElement.style.top = '';
                    });

                    this.closeElements.push(element);
                }
            }
        }

        // 自分のセレクタには無干渉
        var isShowSelector = document.querySelectorAll('.dropdown.is-Show');
        for (var index = 0; index < isShowSelector.length; index++) {
            var element = isShowSelector.item(index) as HTMLElement;
            if (element != this.targetElement) {
                isShowSelector.item(index).classList.remove('is-Show');
                this._el.classList.remove('is-Open');
            }
        }

        var isOpenSelector = document.querySelectorAll('.is-Open');
        for (var index = 0; index < isOpenSelector.length; index++) {
            var element = isOpenSelector.item(index) as HTMLElement;
            if (element != this._el && element.hasAttribute('dropdown')) {
                isOpenSelector.item(index).classList.remove('is-Open');
            }
        }

        if (this.targetElement.classList.contains('is-Show')) {
            this.targetElement.classList.remove('is-Show');
            this._el.classList.remove('is-Open');

            this.targetElement.style.left = '';
            this.targetElement.style.top = '';
        }
        else {
            this.targetElement.classList.add('is-Show');
            this._el.classList.add('is-Open');
            if (this.adjustPositon !== 'n') {
                this._setTargetPosition(this.align);
            }
        }

        event.stopImmediatePropagation();
        event.stopPropagation();

        this.clickEvent.emit(this.elementRef.nativeElement);

        return false;
    }

    @HostListener('window:resize', ['$event'])
    public onResize(event: Event) {
        if (this.targetElement && this.adjustPositon !== 'n') {
            this._setTargetPosition(this.align);
        }
    }

    /**
     * 渡されたalignを元に対象要素の位置を決定する
     * left   左端ぞろえ
     * right  右端ぞろえ
     * center 中央ぞろえ
     * @param align
     */
    private _setTargetPosition(align: string): void {
        var thisRect = this._el.getBoundingClientRect();
        this.targetElement.style.top = thisRect.top + thisRect.height + window.pageYOffset + 'px';

        switch (align) {
            case 'left':
                var left = thisRect.left;
                this.targetElement.style.left = thisRect.left.toString() + 'px';
                break;
            case 'right':
                var left = thisRect.right - this.targetElement.offsetWidth;
                this.targetElement.style.left = left.toString() + 'px';
                break;
            case 'center':
            default:
                var center = thisRect.left + (thisRect.width / 2);
                var left = center - (this.targetElement.offsetWidth / 2);
                this.targetElement.style.left = left.toString() + 'px';
                break;
        }
    }
}
