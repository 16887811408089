import { HttpClient } from '@angular/common/http';
import { docCookies } from "Content/script/libs/third_party/cookies";
import { IABCookie } from "Content/script/libs/abtest/custom-event.util";
import { ValidationRegexsDefine } from 'Content/script/libs/define/validation_regexs.define';

export enum ABTestName {
    RsGlobalMenuChange = 'GT223344',
}

export class ABTestUtil {
    /**
     * Cookie名先頭接尾文字列
     */
    public static readonly NaviABTestCookieNamePrefix = 'Navi_AB_';
    public static readonly NaviABTestCookieNameSufix = '_';

    /**
     * windowオブジェクトに保存してあるABテストデータを取得する
     * @param str
     */
    public static getAbSettingInfo(str: string): string {
        if (str.indexOf('/') < 0) {
            return ABTestUtil._getAbSettingInfo(str);
        }

        let pathList: string[] = str.split("/").filter(x => x != "" && !RegExp(ValidationRegexsDefine.rNumberPattern).test(x));
        let key: string = this.NaviABTestCookieNamePrefix + pathList.join("_") + this.NaviABTestCookieNameSufix;
        return window.abSettingInfo[key] || null;
    }

    /**
     * ABテスト用のcookieがなければ生成し、値を読み取る。
     * 戻り値：cookieが読めればcookieの値、cookieが存在せず生成もできなければ'-1'(文字列)を返す
     * @param str
     * @param http
     */
    public static trygetAbValuByHttpAynk(str: string, http: HttpClient): Promise<null | IABCookie> {
        const promise = ABTestUtil._getAbValuByHttpAynk(str, http);

        let result = promise.then((abval) => {
            return abval;
        }).catch((reason) => {
            console.log(reason);
            return null;
        });

        return result;
    }

    /**
     * Http 通信で ABTest の値を取得する
     * Cookie を参照して登録してある場合はそれを利用する
     * @param str
     * @param http
     */
    protected static _getAbValuByHttpAynk(str: string, http: HttpClient): Promise<IABCookie> {
        let abSettingInfo: string = this.getAbSettingInfo(str);
        let cookieName: string = "";
        let abDate: string = "";
        if (abSettingInfo != void 0 && abSettingInfo != null && abSettingInfo != '') {
            let testInfo = JSON.parse(abSettingInfo);
            cookieName = testInfo.ABTestKey + testInfo.ABTestDate;
            abDate = testInfo.ABTestDate;
        }
        else {
            return Promise.reject("Can't create ABTestCookie(path:" + str + ")");
        }

        const cookieValue = docCookies.getItem(cookieName);
        if (cookieValue) {
            window.abData[cookieName] = cookieValue;
            return Promise.resolve({ cookieName, abValue: cookieValue, abDate });
        }

        return new Promise<IABCookie>((resolve, reject) => {
            http.get<ICookieDevideAbResponseViewModel>(location.protocol + "//" + location.host + '/Cookie/DevideAb', {
                withCredentials: true,
                responseType: "json",
                params: {
                    Name: cookieName,
                    Info: abSettingInfo
                }
            }).subscribe((data: ICookieDevideAbResponseViewModel) => {
                window.abData[cookieName] = data.Value;
                resolve({ cookieName, abValue: data.Value, abDate });
            },
                e => { reject(e) }
            );
        });
    }

    protected static _getAbSettingInfo(str: string): string {
        let key = str + this.NaviABTestCookieNameSufix;
        
        return window.abSettingInfo[key] || null;
    }
}

interface ICookieDevideAbResponseViewModel {
    Value: string;
}
