import { Directive, Input, forwardRef, OnInit } from '@angular/core';
import { NG_VALIDATORS, Validator, ValidatorFn, AbstractControl, Validators } from '@angular/forms';

const CITY_INVALID_VALIDATOR: any = {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => CityInvalidValidator),
    multi: true
};

@Directive({
    selector: '[cityInvalid][formControlName],[cityInvalid][formControl],[cityInvalid][ngModel]',
    providers: [CITY_INVALID_VALIDATOR]
})

// 「国：JP（日本）」で、市区に「数字のみ」の場合エラー判定にする
export class CityInvalidValidator implements Validator {
    validate(c: AbstractControl): { [key: string]: any } {
        return CustomValidators.cityInvalid(c);
    }
}

export class CustomValidators {

    static cityInvalid(control: AbstractControl): { [key: string]: boolean } {
        if (isPresent(Validators.required(control))) return null;

        let v: string = control.value;

        // フォ－マット(数字のみ)の確認
        let rNumberPattern: string = '^(?!\\s)+[0-9]*$';

        // 数字のみの場合エラーにする
        if (new RegExp(rNumberPattern).test(v) == true) {
            return { 'cityInvalid': true };
        }
        return null;
    }
}

export function isPresent(obj: any): boolean {
    return obj !== undefined && obj !== null;
}