import { StringUtil } from "Content/script/libs/utils/string.util";

export class GmoPointSetting {
    public useType: PointUsingType = PointUsingType.NotCollaboration;
    public isCollaboration: boolean = false;
    public isOpenedModal: boolean = false;
    public currentPoint: string = '0';
    public usePoint: string = '0';

    public constructor() { }

    public getUsePointWithComma(): string {
        return StringUtil.NumberToStringWithComma(Number(this.usePoint.split(',').join('').trim()));
    }
}

export enum PointUsingType {
    /** 連携していない */
    NotCollaboration,
    /** ポイントがない */
    NotHavePoint,
    /** ポイントが使用できない(クーポン) */
    NotUsePoint,
    /** ポイントを使用しない */
    NonUsePoint,
    /** ポイントを使用する */
    UsePoint
}