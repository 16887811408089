<div class="footer-Inner">
    <div class="footer-Content">
        <h4 class="hdn-Lev4">管理画面一覧</h4>
        <div class="box-Grid is-Col5">
            <div class="box-Grid-Col">
                <dl class="list-Def mb0">
                    <dt>ドメイン</dt>
                    <dd>
                        <ul class="list-Link">
                            <li><a routerLink="/domain" onClick="window.dataLayerPush('footer_domain')">ドメイン一覧</a></li>
                        </ul><!-- /.list-Link -->
                    </dd>
                </dl><!-- /.list-Def -->
            </div><!-- /.box-Grid-Col -->
            <div class="box-Grid-Col">
                <dl class="list-Def mb0">
                    <dt>サーバー</dt>
                    <dd>
                        <ul class="list-Link">
                            <li><a routerLink="/sd" onClick="window.dataLayerPush('footer_sd')">レンタルサーバー</a></li>
                            <li><a routerLink="/mail" onClick="window.dataLayerPush('footer_mail')">お名前メール</a></li>
                            <li><a routerLink="/vps" onClick="window.dataLayerPush('footer_vps')">VPS（KVM）</a></li>
                            <li><a routerLink="/wptemplate" onClick="window.dataLayerPush('footer_keni')">WordPressテーマ</a></li>
                            <li><a routerLink="/desktopcloud" onClick="window.dataLayerPush('footer_desktopcloud')">デスクトップクラウド</a></li>
                        </ul><!-- /.list-Link -->
                    </dd>
                </dl><!-- /.list-Def -->
            </div><!-- /.box-Grid-Col -->
            <div class="box-Grid-Col">
                <dl class="list-Def mb0">
                    <dt>SaaS</dt>
                    <dd>
                        <ul class="list-Link">
                            <li><a routerLink="/office365" onClick="window.dataLayerPush('footer_office')">Office</a></li>
                            <li><a routerLink="/mailmarketing" onClick="window.dataLayerPush('footer_mm')">メールマーケティング</a></li>
                            <li><a routerLink="/sitecreation" onClick="window.dataLayerPush('footer_sitecreation')">サイトクリエイションJr.</a></li>
                        </ul><!-- /.list-Link -->
                    </dd>
                </dl><!-- /.list-Def -->
            </div><!-- /.box-Grid-Col -->
            <div class="box-Grid-Col">
                <dl class="list-Def mb0">
                    <dt>申込み終了サービス</dt>
                    <dd>
                        <ul class="list-Link">
                            <li><a routerLink="/gs" onClick="window.dataLayerPush('footer_gs')">共用サーバー GS</a></li>
                            <li><a routerLink="/wpcloud" onClick="window.dataLayerPush('footer_wpcloud')">WP Cloud</a></li>
                        </ul><!-- /.list-Link -->
                    </dd>
                </dl><!-- /.list-Def -->
            </div><!-- /.box-Grid-Col -->
        </div><!-- /.box-Grid -->
    </div><!-- /.footer-Content -->
</div><!-- /.footer-Inner -->
