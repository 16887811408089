import { Directive, Input, forwardRef, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { NG_VALIDATORS, Validator, ValidatorFn, AbstractControl, Validators } from '@angular/forms';

const MIN_BYTE_VALIDATOR: any = {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => MinByteValidator),
    multi: true
};

@Directive({
    selector: '[minByte][formControlName],[minByte][formControl],[minByte][ngModel]',
    providers: [MIN_BYTE_VALIDATOR]
})

export class MinByteValidator implements Validator, OnInit, OnChanges {
    @Input() minByte: number;

    private validator: ValidatorFn;

    ngOnInit() {
        this.validator = CustomValidators.minByte(this.minByte);
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.validator = CustomValidators.minByte(this.minByte);
    }

    validate(c: AbstractControl): { [key: string]: any } {
        return this.validator(c);
    }
}

export class CustomValidators {
    static minByte(minByte: number): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            if (isPresent(Validators.required(control))) return null;

            let v: string = control.value;
            let l: number = getBytes(v);

            //console.info('【入力バイト数最小チェック】' + '入力文字列:' + v + ' バイト数' + l);

            return l >= minByte ? null : { 'minByte': true };
        };
    }
}

export function isPresent(obj: any): boolean {
    return obj !== undefined && obj !== null;
}

// TODO: 厳密なバイト数では無いため取得の仕方要検討
export function getBytes(str: string): number {
    let len: number = encodeURIComponent(str).replace(/%../g, 'x').length;
    return len;
}