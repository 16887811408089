export class ModalForDomainRenewService {
    public isSkipDomainRenewPageModal: boolean;

    public domainIds: string[] = [];

    public setDomainIds(domainIdList: string[]) {
        this.domainIds = domainIdList;
    }

    public clearDomainIds() {
        this.domainIds = [];
    }
}