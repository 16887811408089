import { Component, ComponentRef } from '@angular/core';
import { Router } from '@angular/router';
import { IFlexView, AbstractFlexView } from 'Content/script/promotion/common/flexview';

@Component({
    template: 
        `<div class="box-DomainAppeal mt12">
            <span class="box-DomainAppeal-Icon">
                <i class="icon icon-domain"></i>
            </span>
            <p class="box-DomainAppeal-Heading">利用されていないドメインがあります</p>
            <button type="button" class="btn is-Small is-Primary gtmclick" data-gtmvalue="navitop_rsapp" (click)="onClick()">{{!abTestAdjustTextButtonValue? "ドメインを利用する": "ドメインを確認する"}}</button>
        </div>`,
})

export class PromotionDomainUseTopComponent {
    constructor(protected _router: Router) { }

    onClick() {
        this._router.navigateByUrl('/domain');
    }

    public get abTestAdjustTextButtonValue(): boolean {
        return window.isABNew_AdjustTextButton;
    }
}

export class TopFlexView extends AbstractFlexView<PromotionDomainUseTopComponent> {

    public get canAttach(): boolean {
        const el = window.document.getElementsByClassName('domainAppeal');
        return el.length > 0;
    }

    public attachDirective() {
        const el = window.document.getElementsByClassName('domainAppeal');
        this._viewContainerRef.clear();
        const componentRef = this._viewContainerRef.createComponent(this._factory);
        this._renderer.insertBefore(el[0].parentElement, componentRef.location.nativeElement, el[0]);
    }
}