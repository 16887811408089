import {
  ActionReducer,
  ActionReducerMap,
  MetaReducer
} from '@ngrx/store';
import { environment } from '../../environments/environment';

import * as refererButtonReducer from './referer-button';
import * as popUpReducer from './popup';
import * as viewReducer from './view';
import * as accountInfoReducer from './account-info';
import * as domainWithDefaultNameServerReducer from './domain-with-defaultname-server';
import * as refererButtonState from '../states/referer-button';
import * as popUpState from '../states/popup';
import * as viewState from '../states/view';
import * as AcountInfoState from '../states/account-info';
import * as domainWithDefaultNameServerState from '../states/domain-with-defaultname-server';


export interface State {
    [refererButtonReducer.refererButtonFeatureKey]: refererButtonState.RefererButtonState,
    [popUpReducer.popUpFeatureKey]: popUpState.PopUpState,
    [viewReducer.viewFeatureKey]: viewState.ViewState,
    [accountInfoReducer.accountInfoFeatureKey]: AcountInfoState.AccountInfoState,
    [accountInfoReducer.accountDetailFeatureKey]: AcountInfoState.AccountDetailState,
    [accountInfoReducer.accountHostingContractQuantityFeatureKey]: AcountInfoState.AccountHostingContractQuantityState,
    [domainWithDefaultNameServerReducer.domainWithDefaultNameServerFeatureKey]: domainWithDefaultNameServerState.DomainWithDefaultNameServerState
}

export const reducers: ActionReducerMap<State> = {
    [refererButtonReducer.refererButtonFeatureKey]: refererButtonReducer.reducer,
    [popUpReducer.popUpFeatureKey]: popUpReducer.reducer,
    [viewReducer.viewFeatureKey]: viewReducer.reducer,
    [accountInfoReducer.accountInfoFeatureKey]: accountInfoReducer.reducerAccountInfo,
    [accountInfoReducer.accountDetailFeatureKey]: accountInfoReducer.reducerAccountDetail,
    [accountInfoReducer.accountHostingContractQuantityFeatureKey]: accountInfoReducer.reducerAccountHostingContractQuantity,
    [domainWithDefaultNameServerReducer.domainWithDefaultNameServerFeatureKey]: domainWithDefaultNameServerReducer.reducerDomainWithDefaultNameServer
};

export const metaReducers: MetaReducer<State>[] = !environment.production ? [] : [];
