import { Component } from '@angular/core';
import { IFlexView, AbstractFlexView } from 'Content/script/promotion/common/flexview';

@Component({
    template: `<div style="display:none"></div>`
})
export class PromotionDomainUseEmptyComponent { }

export class EmptyFlexView extends AbstractFlexView<PromotionDomainUseEmptyComponent> {

    public get canAttach(): boolean {
        return true;
    }

    public attachDirective() {
        this._viewContainerRef.clear();
        this._viewContainerRef.createComponent(this._factory);
    }
}