/// <reference path='../definitions/window.d.ts' />

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RedirectComponent } from '../components/redirect/redirect.component';

const isIE9: boolean = window.browserType == 'IE9';
const isMobileViewMode: boolean = window.isMobileViewMode;

let routes: Routes = [
    { path: '', redirectTo: 'login', pathMatch: 'full' },
    {
        path: 'sp',
        children: [
            { path: '**', component: RedirectComponent }
        ]
    }
    
];

let spRoutes: Routes = [
    { path: '', redirectTo: 'sp', pathMatch: 'full' },
    { path: 'login', redirectTo: 'sp/login', pathMatch: 'full' },
    { path: 'logout', redirectTo: 'sp/logout', pathMatch: 'full' },
    { path: 'top', redirectTo: 'sp/top', pathMatch: 'full' },
    { path: 'mail', redirectTo: 'sp/mail', pathMatch: 'full' },
    { path: 'domain', redirectTo: 'sp/domain', pathMatch: 'full' },
    { path: 'domain/rsregist', redirectTo: 'sp/domain/rsregist', pathMatch: 'full' },
    { path: 'domain/search', redirectTo: 'sp/domain/search', pathMatch: 'full' },
    { path: 'domain/detail/:id', redirectTo: 'sp/domain/detail/:id', pathMatch: 'full' },
    { path: 'payment/detail/domain', redirectTo: 'sp/payment/detail/domain', pathMatch: 'full' },
    { path: 'payment/detail/hosting', redirectTo: 'sp/payment/detail/hosting', pathMatch: 'full' },
    { path: 'account/detail/edit', redirectTo: 'sp/account/detail/edit', pathMatch: 'full' },
    { path: 'account/detail/confirm', redirectTo: 'sp/account/detail/confirm', pathMatch: 'full' },
    { path: 'account/detail/lineid', redirectTo: 'sp/account/detail/lineid', pathMatch: 'full' },
    { path: 'sd', redirectTo: 'sp/rs', pathMatch: 'full' },
    { path: 'rs', redirectTo: 'sp/rs', pathMatch: 'full' },
    { path: 'payment/invoice/list/domain', redirectTo: 'sp/payment/invoice/list/domain', pathMatch: 'full' },
    { path: 'payment/invoice/list/hosting', redirectTo: 'sp/payment/invoice/list/hosting', pathMatch: 'full' },
    { path: 'desktopcloud', redirectTo: 'sp/desktopcloud', pathMatch: 'full' },
    { path: 'vps', redirectTo: 'sp/vps', pathMatch: 'full' },
    { path: 'auth/idreminder', redirectTo: 'sp/auth/idreminder', pathMatch: 'full' },
    { path: 'auth/pwreminder', redirectTo: 'sp/auth/pwreminder', pathMatch: 'full' },
    { path: 'auth/voaci', redirectTo: 'sp/auth/voaci', pathMatch: 'full' },
    { path: 'auth/email', redirectTo: 'sp/auth/email', pathMatch: 'full' },
    { path: 'domain/setting', redirectTo: 'sp/domain/setting', pathMatch: 'full' },
    { path: 'domain/configuration', redirectTo: 'sp/domain/configuration', pathMatch: 'full' },
    { path: 'domain/configuration/:id', redirectTo: 'sp/domain/configuration/:id', pathMatch: 'full' },
    { path: 'auth/pwreminder', redirectTo: 'sp/auth/pwreminder', pathMatch: 'full' },
    { path: 'domain/setting/renew/list', redirectTo: 'sp/domain/setting/renew/list', pathMatch: 'full' },
    { path: 'domain/setting/renew/list/:domainId', redirectTo: 'sp/domain/setting/renew/list/:domainId', pathMatch: 'full' },
    { path: 'domain/setting/renew/done', redirectTo: 'sp/domain/setting/renew/done', pathMatch: 'full' },
    { path: 'domain/setting/renew/done/:domainId', redirectTo: 'sp/domain/setting/renew/done/:domainId', pathMatch: 'full' },
    { path: 'link/status', redirectTo: 'sp/link/status', pathMatch: 'full' },
    { path: 'link/connect/done', redirectTo: 'sp/link/connect/done', pathMatch: 'full' },
    { path: 'campaign/present', redirectTo: 'sp/campaign/present', pathMatch: 'full' },
    { path: 'auth/pwreminder/pwchange', redirectTo: 'sp/auth/pwreminder/pwchange', pathMatch: 'full' },
    { path: 'auth/pwreminder/pwchange/error', redirectTo: 'sp/auth/pwreminder/pwchange/error', pathMatch: 'full' },
    { path: 'auth/pwreminder/pwchange/confirm', redirectTo: 'sp/auth/pwreminder/pwchange/confirm', pathMatch: 'full' },
    { path: 'auth/pwreminder/pwchange/done', redirectTo: 'sp/auth/pwreminder/pwchange/done', pathMatch: 'full' },
    { path: 'webacademy/bookmark', redirectTo: 'sp/webacademy/bookmark', pathMatch: 'full' },
    { path: 'campaign/rs6mfree_2', redirectTo: 'sp/campaign/rs6mfree_2', pathMatch: 'full' },
    { path: 'quick/error', redirectTo: 'sp/quick/error', pathMatch: 'full' },
    { path: 'quick/whoisprotect', redirectTo: 'sp/quick/whoisprotect', pathMatch: 'full' },
    { path: 'quick/whoisprotect/done', redirectTo: 'sp/whoisprotect/done', pathMatch: 'full' },
    { path: 'quick/monitoring/input', redirectTo: 'sp/quick/monitoring/input', pathMatch: 'full' },
    { path: 'quick/monitoring/done', redirectTo: 'sp/quick/monitoring/done', pathMatch: 'full' },
    { path: 'quick/dns', redirectTo: 'sp/quick/dns', pathMatch: 'full' },
    { path: 'quick/dns/done', redirectTo: 'sp/dns/done', pathMatch: 'full' },
    { path: 'quick/domain/rsregist', redirectTo: 'sp/quick/domain/rsregist', pathMatch: 'full' },
    { path: 'quick/domain/rsregist/done', redirectTo: 'sp/quick/domain/rsregist/done', pathMatch: 'full' },
    { path: 'quick/domain/rsregist/confirm', redirectTo: 'sp/quick/domain/rsregist/confirm', pathMatch: 'full' },
    { path: 'quick/settingpayment', redirectTo: 'sp/quick/settingpayment', pathMatch: 'full' },
    { path: 'quick/settingpayment/done', redirectTo: 'sp/quick/settingpayment/done', pathMatch: 'full' },
];

if (isMobileViewMode) {
    routes = spRoutes;
} 


export const rootRoutes: Routes = routes;
export const spRootRoutes: Routes = spRoutes;

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { useHash: isIE9 })
    ],
    declarations: [],
    exports: [
        RouterModule
    ]
})

export class RootRoutingModule {
}
