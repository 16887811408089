import { Directive, forwardRef, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';

const MAX_TOTAL_BYTES_VALIDATOR: any = {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => MaxTotalBytesValidator),
    multi: true
};

@Directive({
    selector: "[maxTotalBytes][formControlName],[maxTotalBytes][formControl],[maxTotalBytes][ngModel]",
    providers: [MAX_TOTAL_BYTES_VALIDATOR]
})
export class MaxTotalBytesValidator implements Validator {
    @Input('key1') key1: string;
    @Input('key2') key2: string;
    @Input() maxTotalBytes: number;

    private validator: ValidatorFn;

    validate(c: AbstractControl): { [key: string]: any } {
        this.validator = createMaxTotalBytesValidator(this.key1, this.key2, this.maxTotalBytes);
        return this.validator(c);
    }
}

export function createMaxTotalBytesValidator(key1: string, key2: string, maxBytes: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        let key1_v: AbstractControl = control.parent.controls[key1];
        let key2_v: AbstractControl = control.parent.controls[key2];

        if (!key1_v || !key2_v) {
            return null;
        }

        let totalBytes = _getBytes(key1_v.value + key2_v.value);
        let result: boolean = totalBytes < maxBytes;
        if (result) {
            // 指定バイト以下であるならエラー状態を除去
            if (key1_v.hasError("maxTotalBytes")) {
                delete key1_v.errors.maxTotalBytes;
                if (!Object.keys(key1_v.errors).length) {
                    key1_v.markAsPristine();
                    key1_v.markAsUntouched();
                }
            }
            if (key2_v.hasError("maxTotalBytes")) {
                delete key2_v.errors.maxTotalBytes;
                if (!Object.keys(key2_v.errors).length) {
                    key2_v.markAsPristine();
                    key2_v.markAsUntouched();
                }
            }
            return null;
        } else {
            // 指定バイト以上であるならエラー状態を付与
            key1_v.markAsDirty();
            key1_v.markAsTouched();
            if (!!key1_v.errors) {
                key1_v.errors.maxTotalBytes = true;
            } else {
                key1_v.setErrors({ maxTotalBytes: true });
            }

            key2_v.markAsDirty();
            key2_v.markAsTouched();
            if (!!key2_v.errors) {
                key2_v.errors.maxTotalBytes = true;
            } else {
                key2_v.setErrors({ maxTotalBytes: true });
            }
            return { maxTotalBytes: true };
        }
    }
}

/**
* 文字列のバイト長を返す
*/
export function _getBytes(str: string): number {
    return encodeURIComponent(str).replace(/%../g, 'x').length;
}
