import { BaseComponent, BaseViewModel } from 'Content/script/components/base_component';
import { Router } from '@angular/router';
import { ServiceContainer } from 'Content/script/service/service_container';

export class PageView {
    totalItem: number;
    currentPage: number;
    pageSize: number;
    totalPages: number;
    startPage: number;
    endPage: number;
    startIndex: number;
    endIndex: number;
    pages: number[];
}

export class PagerViewModel extends BaseViewModel {
    public constructor(
        quereId: number,
        router: Router,
        serviceContainer: ServiceContainer) {
        super(quereId, router, serviceContainer);
    }

    getPager(totalItem: number, currentPage: number = 1, pageSize: number = 10): PageView {
        // calculate total pages
        var totalPages = Math.ceil(totalItem / pageSize);

        var startPage: number, endPage: number;
        if (totalPages <= 10) {
            // less than 10 total pages so show all
            startPage = 1;
            endPage = totalPages;
        } else {
            // more than 10 total pages so calculate start and end pages
            if (currentPage <= 6) {
                startPage = 1;
                endPage = 10;
            } else if (currentPage + 4 >= totalPages) {
                startPage = totalPages - 9;
                endPage = totalPages;
            } else {
                startPage = currentPage - 5;
                endPage = currentPage + 4;
            }
        }

        // calculate start and end item indexes
        var startIndex = (currentPage - 1) * pageSize;
        var endIndex = Math.min(startIndex + pageSize - 1, totalItem - 1);

        // create an array of pages to ng-repeat in the pager control
        var pages: number[] = [];
        for (var index = startPage; index < endPage + 1; index++) {
            pages.push(index);
        }

        // return object with all pager properties required by the view
        var viewModel: PageView = {
            totalItem: totalItem,
            currentPage: currentPage,
            pageSize: pageSize,
            totalPages: totalPages,
            startPage: startPage,
            endPage: endPage,
            startIndex: startIndex,
            endIndex: endIndex,
            pages: pages
        }

        return viewModel as PageView;
    }
}
