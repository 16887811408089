import { HubsService } from 'Content/script/service/hubs_service.js';

export class NPSEnqueteService
{
    private queueId: number;
    private readonly productUrl: string = "https://www.onamae.com/domain/enquete?enq_id=";
    private readonly testUrl: string = "https://www.onamae.test/domain/enquete?enq_id=";

    private _enqueteId: string = null;
    public get enqueteId(): string {
        return this._enqueteId;
    }

    private _answerMinute: string = null;
    public get answerMinute(): string {
        return this._answerMinute;
    }

    public get isDuration(): boolean {
        return !!this._enqueteId;
    }

    public get URL(): string {
        return this.matchAppEnvironment(this.productUrl, this.testUrl) + this.enqueteId;
    }

    public constructor(private hubService: HubsService) {
        this.queueId = this.hubService.multipleSendManager.GetComponentId();
        if (window.RouterPath !== '/logout') {
            this.init();
        }
    } 

    public init() {

        var displayNone: Function = (res: DomainCartListResponseHubDto) => {
            this._enqueteId = null;
            this._answerMinute = null;
        };

        this.hubService.multipleSendManager.Send(
            this.queueId,
            this.hubService.hubsManager.topHubManager.GetNPSEnqueteInfo,
            null,
            (res: NPSEnqueteInfoResponseHubDto) => {
                this._enqueteId = res.EnqId;
                this._answerMinute = res.AnswerMinute;
            },
            displayNone, //error
            displayNone, //no session
            null,        //always
            displayNone  //unExpectedError
        );
    }

    private  matchAppEnvironment<T>(_isProduct: T, _isNOTProduct: T): T {
        const isProduct: boolean = (window.productFlg === "Product" || window.productFlg === "Staging");

        if (isProduct) {
            return _isProduct;
        } else {
            return _isNOTProduct;
        }
    }
}
