import { Component, Input } from '@angular/core';

@Component({
    selector: 'image-banner-notification',
    template: `
<head-notification>
    <ng-container>
        <img src="{{imageUrl}}">
    </ng-container>
</head-notification>
`
})

export class ImageBannerNotificationComponent {
    @Input() public imageUrl: string = "";
}
