import { Component, Input } from '@angular/core';
import { ServiceContainer } from 'Content/script/service/service_container';

@Component({
    selector: 'loading',
    template: `
<div *ngIf="isLoading" class="loading" style="position:absolute;display:block">
    <div class="loading-Loader">
        <div class="loading-Loader-Blade"></div>
        <div class="loading-Loader-Blade"></div>
        <div class="loading-Loader-Blade"></div>
        <div class="loading-Loader-Blade"></div>
        <div class="loading-Loader-Blade"></div>
        <div class="loading-Loader-Blade"></div>
        <div class="loading-Loader-Blade"></div>
        <div class="loading-Loader-Blade"></div>
        <div class="loading-Loader-Blade"></div>
        <div class="loading-Loader-Blade"></div>
        <div class="loading-Loader-Blade"></div>
        <div class="loading-Loader-Blade"></div>
    </div>
</div>

<div *ngIf="isEntireLoading" class="loading" style="display:block">
    <div class="loading-Loader">
        <div class="loading-Loader-Blade"></div>
        <div class="loading-Loader-Blade"></div>
        <div class="loading-Loader-Blade"></div>
        <div class="loading-Loader-Blade"></div>
        <div class="loading-Loader-Blade"></div>
        <div class="loading-Loader-Blade"></div>
        <div class="loading-Loader-Blade"></div>
        <div class="loading-Loader-Blade"></div>
        <div class="loading-Loader-Blade"></div>
        <div class="loading-Loader-Blade"></div>
        <div class="loading-Loader-Blade"></div>
        <div class="loading-Loader-Blade"></div>
    </div>
     <div class="loading-Message">
            ただいま画面遷移中です。<br>しばらくお待ちください。
     </div>
</div>
`
})
export class LoadingComponent {
    @Input('loading') public isLoading: boolean;
    @Input('entireLoading') public isEntireLoading: boolean;
    public queueId: number;

    /**
     * コンストラクタ
     * @param serviceContainer
     */
    public constructor(
        private serviceContainer: ServiceContainer
    ) {
        this.queueId = this.serviceContainer.HubsService.multipleSendManager.GetComponentId();
    }
}
