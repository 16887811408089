import { NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// モジュール
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { UiModule } from 'Content/script/modules/ui.module';
import { NotifyModule } from 'Content/script/modules/notify.module';
import { SafePipeModule } from 'Content/script/modules/safe.module';
import { LayoutModule } from 'Content/script/modules/layout.module';
import { SharedModule } from 'Content/script/modules/shared.module';
import { PromotionDomainUseModule } from 'Content/script/promotion/domainuse/module/domainuse.module';
import { PcRoutingModule } from 'Content/script/routing/pc-routing.module';
/**
 * Atoms
 * */
import { AtomsModule } from 'Content/script/modules/atoms.module';

import { ServiceContainer } from 'Content/script/service/service_container';

// コンポーネント
import { AppPcComponent } from './app.component';
import { AweSomeModule } from '../../modules/awesome.module';

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        HttpClientModule,
        ReactiveFormsModule,
        UiModule,
        NotifyModule,
        SafePipeModule,
        LayoutModule,
        SharedModule,
        AtomsModule,
        PromotionDomainUseModule,
        PcRoutingModule,
        AweSomeModule,
    ],
    declarations: [
        AppPcComponent
    ],
    exports: [
        AppPcComponent,
        //NotifyModule,
        //LayoutModule,
        //SharedModule,
        //AtomsModule
    ],
    providers: [
        ServiceContainer
    ]
})

export class AppPcModule {
    
}
