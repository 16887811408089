import { Injectable } from '@angular/core';
import { ServiceContainer } from 'Content/script/service/service_container';

@Injectable()
export class GetDomainWithDefaultNameServerService {

    public constructor(private _serviceContainer: ServiceContainer) {
        // noop.
    }

    public runAsync(): Promise<DomainWithDefaultNameServerResponseHubDto> {
        return new Promise<DomainWithDefaultNameServerResponseHubDto>((resolve, reject) => {
            this._serviceContainer.DomainWithDefaultNameServerService.GetDomainWithDefaultNameServer().then((resHubDto) => {
                resolve(resHubDto);
            }).catch(() => {
                reject();
            });
        });
    }
}
