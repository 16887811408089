import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'commasNumber'
})
export class CommasNumberPipe implements PipeTransform {
    transform(value: any): string {

        let temp: number = +value;
        if (Number.isNaN(temp)) {
            return value.toString();
        }
        return temp.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    }
}
