import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ServiceContainer } from 'Content/script/service/service_container';

@Component({
    selector: 'withdrawal-prevention-modal',
    templateUrl: './withdrawal_prevention_modal.html'
})

export class WithdrawalPreventionModalComponent {
    @Input() isDisplay: boolean = false;
    @Input() domainList: DomainItem[] = [ new DomainItem("", "", "") ];

    @Output() onClickReturn = new EventEmitter();
    @Output() onClickConfirm = new EventEmitter();
    @Output() onClickClose = new EventEmitter();

    public constructor(
        protected serviceContainer: ServiceContainer
    ) { }

    public get displayDomains(): DomainItem[] {
        if (!this.domainList) {
            return [];
        }

        return this.domainList.slice(0, 3);
    }

    public get isDisplayText(): boolean {
        if (!this.domainList) {
            return false;
        }

        return this.domainList.length > 3;
    }

    public get textCount(): string {
        if (!this.domainList) {
            return "";
        }

        return (this.domainList.length - 3).toString();
    }

    public onClickConfirmButton(): void {
        this.serviceContainer.ModalForDomainRenewService.clearDomainIds();
        this.onClickConfirm.emit();
    }

    public onClickReturnButton(): void {
        let domainIds: string[] = this.domainList.map(x => { return x.domainId });
        this.serviceContainer.ModalForDomainRenewService.setDomainIds(domainIds);

        this.onClickReturn.emit();
    }

    public onClickCloseButton(): void {
        this.onClickClose.emit();
    }
}

export class DomainItem {
    public constructor(
        public domainName: string,
        public domainId: string,
        public expirationDate: string,
    ) { }
}
