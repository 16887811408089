<div class="modal" id="modalEnquete" modal [openFlg] ="isShowModalSurvey">
  <form #modalSurveyForm="ngForm" novalidate>
    <div class="modal-Dialog is-Wide">
      <div class="modal-Dialog-Header is-Note">
        <h3>
          【複数回ご利用のお客様向け】ネットde診断に関するアンケート＜所要時間：3分>
        </h3>
      </div><!-- /.modal-Dialog-Header -->
      <div class="modal-Dialog-Body is-Scroll">
        <ng-container *ngIf="isShowModalSurvey && !isLoading && pages.length > 0">
          <ng-container *ngFor="let page of pages; let i = index;">
            <p class="size-15 text-Bold mt24 mb0">{{page.content}}<span class="form-Required" *ngIf="page.requiredFlg === 1">必須</span></p>

            <!--QuestionnaireType.RadioButton-->
            <section class="section mt12" *ngIf="page.type === 4">
              <div class="box-Border is-Thick">
                <ul class="list-Checkable is-Col2 is-Inline">
                  <li *ngFor="let option of page.options">
                    <label>
                      <input type="radio" [name]="'radio' + i" [id]="'radio' + i" value="{{option.id}}" [(ngModel)]="option.isCheck" (change)="onChangeRadio(page,option.id,i)">
                      <span class="form-Radio" tabindex="0"></span>{{option.content}}
                    </label>
                  </li>
                </ul>
              </div><!-- /.box-Border -->
            </section>

            <!--QuestionnaireType.RadioButtonWithOther-->
            <section class="section mt12" *ngIf="page.type === 5">
              <div class="box-Border is-Thick">
                <ul class="list-Checkable is-Col2 is-Inline">
                  <li *ngFor="let option of page.options">
                    <label>
                      <input type="radio" [name]="'radio' + i" [id]="'radio' + i" value="{{option.id}}" [(ngModel)]="option.isCheck" (change)="onChangeRadio(page,option.id,i)">
                      <span class="form-Radio" tabindex="0"></span>{{option.content}}
                    </label>
                    <ng-container *ngIf="option.isOther">
                      <input placeholder="その他" type="text" class="form-Text is-Small mt8 ml30 is-Show" [class.form-Error]="validateOtherList[i] ==='Yes' && (v_otherText.errors && (v_otherText.dirty || v_otherText.touched))"
                             [name]="'otherText'+i" #v_otherText="ngModel" [(ngModel)]="page.otherText"
                             [required]="validateOtherList[i] === 'Yes'" max="100">
                      <div *ngIf="validateOtherList[i] === 'Yes' && (v_otherText.errors && (v_otherText.dirty || v_otherText.touched))">
                        <label *ngIf="v_otherText.errors != null && v_otherText.errors.required" class="form-Error ml30">
                          必須項目です
                        </label>
                        <label *ngIf="validateOtherList[i] === 'Yes' && v_otherText.errors != null && v_otherText.errors.max" class="form-Error ml30">
                          最大文字数：100
                        </label>
                      </div>
                    </ng-container>
                  </li>
                </ul>
              </div><!-- /.box-Border -->
            </section>

            <!--QuestionnaireType.CheckBoxWithOther-->
            <section class="section mt12" *ngIf="page.type === 3">
              <div class="box-Border is-Thick">
                <ul class="list-Checkable is-Col2 is-Inline">
                  <li *ngFor="let option of page.options">
                    <label [for]="'checkbox' + option.id" class="js-Question-Checkbox">
                      <input type="checkbox" [name]="'checkbox'+ i" [id]="'checkbox' + option.id" value="{{option.id}}" [(ngModel)]="option.isCheck" (change)="onChangeCheckBox(i)">
                      <span class="form-Checkbox" tabindex="0"></span>
                      <span>{{option.content}}</span>
                    </label>
                    <ng-container *ngIf="option.isOther">
                      <input placeholder="その他" type="text" class="form-Text is-Small mt8 ml30 is-Show" [class.form-Error]="validateOtherList[i] === 'Yes' && (v_otherText.errors && (v_otherText.dirty || v_otherText.touched))"
                             [name]="'otherText'+ i" #v_otherText="ngModel" [(ngModel)]="page.otherText"
                             [required]="validateOtherList[i] === 'Yes'" max="100">
                      <div *ngIf="validateOtherList[i] === 'Yes' && (v_otherText.errors && (v_otherText.dirty || v_otherText.touched))">
                        <label *ngIf="v_otherText.errors != null && v_otherText.errors.required" class="form-Error ml30">
                          必須項目です
                        </label>
                        <label *ngIf="validateOtherList[i] === 'Yes' && v_otherText.errors != null && v_otherText.errors.max" class="form-Error ml30">
                          最大文字数：100
                        </label>
                      </div>
                    </ng-container>
                  </li>
                </ul>
              </div><!-- /.box-Border -->
            </section>

            <!--QuestionnaireType.Text-->
            <section class="section mt12" *ngIf="page.type === 6">
              <div class="box-Border is-Thick">
                <input type="text" [id]="'input' + i" class="is-FullWidth form-Text"
                       (ngModelChange)="onChangeInput(i)"
                       [class.form-Error]="(v_input.errors && (v_input.dirty || v_input.touched))"
                       [name]="'input'+ i" #v_input="ngModel" [(ngModel)]="page.text"
                       required max="500">
                <div *ngIf="v_input.errors && (v_input.dirty || v_input.touched)">
                  <label *ngIf="v_input.errors != null && v_input.errors.required" class="form-Error">
                    必須項目です
                  </label>
                  <label *ngIf="v_input.errors != null && v_input.errors.max" class="form-Error ml30">
                    最大文字数：500
                  </label>
                </div>
              </div><!-- /.box-Border -->
            </section>

            <!--QuestionnaireType.Either-->
            <section class="section mt12" *ngIf="page.type === 1">
              <div class="box-Border is-Thick">
                <ul class="list-Checkable is-Col2 is-Inline">
                  <li>
                    <label>
                      <input type="radio" [name]="'radio' + i" [id]="'radio' + i" value="1" [(ngModel)]="page.isEitherSelect" (change)="onChangeRadioEither(i, page.requiredFlg)">
                      <span class="form-Radio" tabindex="0"></span>はい
                    </label>
                  </li>
                  <li>
                    <label>
                      <input type="radio" [name]="'radio' + i" [id]="'radio' + i" value="0" [(ngModel)]="page.isEitherSelect" (change)="onChangeRadioEither(i, page.requiredFlg)">
                      <span class="form-Radio" tabindex="0"></span>いいえ
                    </label>
                  </li>
                </ul>
              </div><!-- /.box-Border -->
            </section>

            <!--QuestionnaireType.Textarea-->
            <section class="section mt12" *ngIf="page.type === 7">
              <div class="box-Border is-Thick">
                <textarea class="form-Textarea" [class.form-Error]="(v_textarea.errors && (v_textarea.dirty || v_textarea.touched))"
                          [name]="'textarea'+i" #v_textarea="ngModel" [(ngModel)]="page.textarea" [required]="page.requiredFlg === 1" max="500"></textarea>
                <div *ngIf="(v_textarea.errors && (v_textarea.dirty || v_textarea.touched))">
                  <label *ngIf="v_textarea.errors != null && v_textarea.errors.required" class="form-Error">
                    必須項目です
                  </label>
                  <label *ngIf="v_textarea.errors != null && v_textarea.errors.max" class="form-Error">
                    最大文字数：500
                  </label>
                </div>
              </div><!-- /.box-Border -->
            </section>
          </ng-container>
        </ng-container>

      </div><!-- /.modal-Dialog-Body -->
      <div class="modal-Dialog-Footer">
        <ul class="list-Btn is-Right mb0">
          <li><button type="button" class="btn gtmclick" data-dismiss="modal" data-gtmvalue="authmodal_authmodalcancel" (click)="onClickClose()">キャンセル</button></li>
          <li><button type="button" class="btn is-Primary gtmclick" data-gtmvalue="authmodal_authmodaldone" data-dismiss="modal" data-change="changed" [class.is-Disabled]="!isShowButtonNext" (click)="onClickFinishSurvey(modalSurveyForm)">回答を送信</button></li>
        </ul><!-- /.list-Btn -->
      </div><!-- /.modal-Dialog-Footer -->
      <button type="button" class="modal-Close" data-dismiss="modal" (click)="onClickClose()"><i class="icon icon-Clear gtmclick" aria-label="閉じる" data-gtmvalue="authmodal_authmodalclose"></i></button>
    </div><!-- /.modal-Dialog -->
  </form>
</div>
